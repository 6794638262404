import { StyleSheet, Font } from "@react-pdf/renderer";
import MyfontRoboto from "../../../assets/fonts/Roboto-Regular.ttf";
import MyfontRobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import MyfontRobotoMedium from "../../../assets/fonts/Roboto-Medium.ttf";
import MyfontRobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import MyfontRobotoLightItalic from "../../../assets/fonts/Roboto-LightItalic.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: MyfontRobotoLight,
      fontWeight: 300,
    },
    {
      src: MyfontRobotoLightItalic,
      fontWeight: 300,
      fontStyle: "italic",
    },
    {
      src: MyfontRoboto,
      fontWeight: 400,
    },
    {
      src: MyfontRobotoMedium,
      fontWeight: 500,
    },
    {
      src: MyfontRobotoBold,
      fontWeight: 700,
    },
  ],
});
/**
 * TODO: FONDO DE PANTALLA PARA LA PORTADA PDF BIEN PERRON
 */

export const stylesPortada = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
    whiteSpace: "nowrap", 
  },
  fondoPantalla: {
    width: "100%",
    height: "100vh",
    position: "relative",
  },
  fondoPuntos: {
    position: "absolute",
    width: "100%",
    height: "100vh",
  },
  fondoElipse: {
    position: "absolute",
    right: "0",
    top: "135px",
    width: "437px",
    height: "708px",
  },
  fondoMujer: {
    position: "absolute",
    width: "350px",
    height: "572px",
    left: "262px",
    top: "63px",
  },
  logoTeamWork: {
    position: "absolute",
    width: "220px",
    height: "42.5px",
    left: "352px",
    top: "37px",
  },
  sectionPortadaTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    verticalAlign: "middle",
    position: "absolute",
    maxWidth: "382px",
    height: "96px",
    left: "37px",
    top: "181px",
  },
  sectionPortadaTitleText: {
    display: "flex",
    position: "relative",
    color: "#FDFDFD",
    fontSize: "48px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  sectionPortadaSubtitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    verticalAlign: "middle",
    position: "absolute",
    width: "300px",
    maxWidth: "300px", 
    height: "75px",
    left: "37px",
    top: "277px",
  },
  sectionPortadaSubtitleText: {
    color: "#FDFDFD",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left", 
    textTransform: "uppercase",
  },
  sectionFooterPortada: {
    display: "flex",
    position: "absolute",
    width: "612px",
    height: "192px",
    backgroundColor: "#001935",
    top: "600px",
  },
  contentFooterPortada: {
    display: "flex",
    width: "341px",
    height: "184px",
    position: "absolute",
    top: "8px",
    left: "271px",
  },
  sectionFooterPortadaTextFecha: {
    display: "flex",
    color: "#FDFDFD",
    position: "absolute",
    textAlign: "right",
    fontSize: "16px",
    right: "32px",
    top: "48px",
  },
  sectionFooterPortadaTextCopyRight: {
    display: "flex",
    color: "#FDFDFD",
    position: "absolute",
    textAlign: "right",
    fontSize: "16px",
    right: "32px",
    top: "116px",
    textTransform: "capitalize",
  },
  sectionNamePortada: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "255px",
    height: "412px",
    backgroundColor: "#0065DD",
    top: "380px",
    left: "37px",
    padding: "48px 32px",
    gap: "24px",
  },
  contentNamePortada: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "8px",
  },
  contentNamePortadaText: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "16px",
    fontWeight: 600,
  },
  contentNamePortadaMessaje: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "16px",
    fontWeight: 400,
  },
});

export const stylesSinGraficoXl2 = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  fondoHeader: {
    position: "relative",
    width: "100%",
    height: "175px",
  },
  sectionTitlePostulante: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    width: "297px",
    height: "14px",
    top: "12px",
    left: "235px",
  },
  sectionTitlePostulanteText: {
    display: "flex",
    position: "absolute",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  sectionTitlePostulanteInfo: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    width: "457px",
    height: "129px",
    top: "34px",
    left: "155px",
  },
  contentTitlePostulanteSeparador: {
    display: "flex",
    position: "absolute",
    backgroundColor: "#FDFDFD",
    width: "2px",
    height: "168px",
    top: "5px",
    left: "240px",
  },
  contentTitlePostulanteTitleInfo: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    alignItems: "end",
    position: "absolute",
    width: "185px",
    top: "0px",
    left: "48px",
  },
  contentTitlePostulanteTitleInfoText: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
  },
  
  contentTitlePostulanteMessageInfo: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "start",
    position: "absolute",
    width: "185px",
    top: "0px",
    left: "248px",
  },
  contentTitlePostulanteMessageInfoText: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
  },
  logoTeamWork: {
    position: "absolute",
    width: "134px",
    top: "88px",
    right: "41px",
  },
  contentTitlePostulanteSeparadorAzul: {
    display: "flex",
    position: "absolute",
    backgroundColor: "#0065DD",
    width: "2px",
    height: "203px",
    top: "0px",
    left: "395px",
  },
  contentTitlePostulanteEducacion: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    width: "395px",
    height: "203px",
  },
  contentTitlePostulanteEducacionInfo: {
    display: "flex",
    flexDirection: "column",
    width: "299px",
    height: "171px",
    left: "48px",
  },
  contentTitlePostulanteEducacionInfoTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "13px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  contentTitlePostulanteEducacionInfoMessage: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 0px",
  },
  contentTitlePostulanteEducacionNombreEducacion: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  contentTitlePostulanteEducacionNombreEducacionText: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 500,
  },
  contentTitlePostulanteEducacionNombreEducacionAge: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 400,
  },
  contentTitlePostulanteEducacionInfoMessageExtra: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 400,
  },
  contentTitlePostulanteResena: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    gap: "16px",
    top: "378px",
    width: "100%",
    height: "202px",
    backgroundColor: "#0065DD",
    padding: "24px 48px",
  },
  contentTitlePostulanteResenaInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "516px",
    height: "69px",
  },
  contentTitlePostulanteResenaInfoTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  contentTitlePostulanteResenaInfoMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
  },
  contentPostulanteMotivacion: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    top: "596px",
    left: "40px",
    width: "532px",
    height: "180px",
    border: "2px solid #0065DD",
    padding: "16px 24px",
  },
  contentPostulanteMotivacionInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
    height: "148px",
    maxHeight: "150px",
  },
  contentPostulanteMotivacionTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  contentPostulanteMotivacionInfoMessage: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "8px",
  },
  contentPostulanteMotivacionColumnMessage: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // width: "50%",
    gap: "4px",
  },
  contentPostulanteMotivacionMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
  },
});

export const stylesSinGraficoXl3 = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  logoTeamWork: {
    position: "relative",
    width: "135px",
    height: "27px",
    left: "430px",
    top: "10px",
  },
  containerEvaluacion: {
    display: "flex",
    width: "564px",
    flexDirection: "column",
    position: "absolute",
    height: "411px",
    top: "60px",
    left: "24px",
    gap: "8px",
    backgroundColor: "#FDFDFD",
    padding: "16px 24px",
    border: "1px solid #B0C1D5",
  },
  containerEvaluacionGridTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "516px",
    height: "17px",
    textAlign: "center",
    backgroundColor: "#003D85",
  },
  containerEvaluacionTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
  },
  containerEvaluacionGridSubtitle: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "516px",
    padding: "0px 16px",
  },
  containerEvaluacionSubtitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "center",
  },
  containerEvaluacionGridMessage: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "484px",
    gap: "4px",
  },
  containerEvaluacionSubtitleMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontStyle: "italic",
    fontWeight: 300,
    textAlign: "left",
  },
  containerEvaluacionTableCompetencia: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "516px",
    height: "17px",
    textAlign: "center",
    backgroundColor: "#003D85",
    borderBottom: "1px solid #B0C1D5",
  },
  containerEvaluacionTableCompetenciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTrCompetencia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    height: "17px",
    marginTop: "-7px",
    borderBottom: "2px solid #B0C1D5",
  },
  containerEvaluacionTableTdCompetenciaTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#003D85",
    width: "110px",
    height: "17px",
  },
  containerEvaluacionTableTdCompetenciaTitleText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTdCompetenciaDescrition: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "381px",
    height: "17px",
    backgroundColor: "#FCFCFC",
  },
  containerEvaluacionTableTdCompetenciaDescritionText: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 300,
  },
  containerEvaluacionTableTdCompetenciaPoint: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "25px",
    height: "17px",
    backgroundColor: "#003D85",
  },
  containerEvaluacionTableTdCompetenciaPointText: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTrCompetenciaLaboral: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    height: "17px",
  },
  containerEvaluacionTableTdCompetenciaLaboralTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    backgroundColor: "#003D85",
    width: "391px",
    height: "15px",
    padding: "0px 16px",
  },
  containerEvaluacionTableTdCompetenciaLaboralTitleText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTdCompetenciaLaboralNum: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#003D85",
    width: "25px",
    height: "15px",
  },
  containerEvaluacionTableTdCompetenciaLaboralNumText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTrCompetenciaMessageWhite: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    backgroundColor: "#FAFAFA",
    height: "18px",
    border: "1px solid #B0C1D5",
    marginTop: "-9px",
  },
  containerEvaluacionTableTrCompetenciaMessage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    backgroundColor: "#D9E8FA",
    height: "18px",
    border: "1px solid #B0C1D5",
    marginTop: "-9px",
  },
  containerEvaluacionTableTdCompetenciaLaboralMessage: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    width: "391px",
    height: "15px",
    padding: "0px 16px",
  },
  containerEvaluacionTableTdCompetenciaLaboralMessageText: {
    display: "flex",
    color: "#404040",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTdCompetenciaLaboralOption: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    width: "25px",
    height: "15px",
    borderLeft: "1px solid #B0C1D5",
  },
  containerEvaluacionTableTdCompetenciaLaboralOptionText: {
    display: "flex",
    color: "#005BC7",
    fontSize: "11px",
    fontWeight: 700,
  },
  containerDescripcion: {
    display: "flex",
    width: "454px",
    height: "227px",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    top: "462px",
    left: "158px",
    backgroundColor: "#003D85",
    padding: "16px 0px",
  },
  containerDescripText: {
    display: "flex",
    flexDirection: "column",
    width: "454px",
    height: "99px",
    padding: "0px 48px",
    gap: "8px",
  },
  contentDescripTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentDescripMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "11px",
    fontWeight: 400,
    textAlign: "justify", 
  },
});

export const stylesSinGraficoXl4 = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  imgPortada: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "174px",
    top: "51px",
  },
  containerSugerencia: {
    display: "flex",
    position: "absolute",
    width: "291px",
    height: "364px",
    left: "273px",
    backgroundColor: "#0065DD",
    padding: "56px 32px",
  },
  containerSugerenciaText: {
    display: "flex",
    flexDirection: "column",
    width: "227px",
    height: "252px",
    padding: "0px 8px",
    gap: "8px",
    wordWrap: "break-word",
  },
  contentSugerenciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentSugerenciaMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "11px",
    fontWeight: 400,
    textAlign: "justify",
    textOverflow: "nowrap",
    wordWrap: "break-word",
  },

  containerExperiencia: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "273px",
    height: "174px",
    top: "51px",
    left: "0px",
    padding: "0px 32px 0px 48px",
    gap: "8px",
  },
  contentExperienciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
  },
  contentExperienciaMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify",
  },

  containerConocimiento: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "273px",
    height: "133px",
    top: "225px",
    left: "0px",
    padding: "0px 32px 0px 48px",
    gap: "8px",
  },
  contentConocimientoTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "justify",
  },
  contentConocimientoMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify",
  },
  containerFoda: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    width: "564px",
    height: "241px",
    top: "380px",
    left: "24px",
    padding: "16px 24px",
    gap: "24px",
    border: "2px solid #0065DD",
  },
  contentFodaCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "start",
    alignSelf: "center",
    verticalAlign: "middle", 
    width: "100%",
    gap: "24px",
  },
  contentContainerFodaTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    width: "100%",
    gap: "8px",
  },
  contentFodaCenterTextMessage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    gap: "8px",
  },
  contentFodaTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentTextMessage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    width: "100%",
    gap: "8px",
  },
  contentFodaMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "10px",
    fontWeight: 400,
  },
  logoTeamWork: {
    display: "flex",
    position: "absolute",
    width: "135px",
    height: "27px",
    left: "408px",
    top: "652px",
  },
  lineaConclusion: {
    display: "flex",
    position: "absolute",
    width: "203px",
    height: "2px",
    left: "0px",
    top: "692px",
    backgroundColor: "#0065DD",
  },
  containerConclusion: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "211px",
    height: "53px",
    top: "639px",
    left: "24px",
    padding: "0px 24px 8px 24px",
    gap: "8px",
  },
  contentConclusionTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentConclusionMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "11px",
    fontWeight: 400,
    textTransform: "capitalize",
  },

  containerFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "90px",
    top: "702px",
    padding: "0px 0px 16px 0px",
  },
  contentFooterTitle: {
    color: "#262626",
    fontSize: "10px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  contentFooternName: {
    color: "#262626",
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  contentFooternDescripcion: {
    color: "#262626",
    textAlign: "center",
    fontSize: "9px",
    fontWeight: 400,
    margin: "8px 24px 0px 24px",
  },
  containerFooterPie: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "240px",
    top: "768px",
    alignItems: "center",
    position: "absolute",
    width: "122px",
  },
  containerFooterPieText: {
    color: "#61738E",
    fontSize: "8px",
    fontWeight: 400,
  },
});

export const stylesSinGraficoEje = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  imgPortada: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "142px",
    top: "0px",
  },
  containerSugerencia: {
    display: "flex",
    position: "absolute",
    width: "241px",
    height: "329px",
    left: "32px",
    backgroundColor: "#0065DD",
    padding: "32px",
  },
  containerSugerenciaText: {
    display: "flex",
    flexDirection: "column",
    width: "177px",
    gap: "8px",
  },
  contentSugerenciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentSugerenciaMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify"
  },
  containerPostulante: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "339px",
    height: "142px",
    top: "0px",
    left: "273px",
    gap: "8px",
  },
  contentPostulanteTitle: {
    display: "flex",
    color: "#FDFDFD",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 700,
  },
  contenInfotPostulante: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    width: "100%",
    gap: "16px",
  },
  contentPostulante: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
  },
  postulanteTitle: {
    display: "flex",
    textAlign: "right",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 400,
  },
  postulanteInfo: {
    display: "flex",
    textAlign: "left",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 400,
  },
  containerDescripcion: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    position: "absolute",
    width: "339px",
    height: "187px",
    top: "142px",
    left: "273px",
    padding: "18px 48px",
    gap: "8px",
  },
  contentDescripcionTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "11px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentDescripcionMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify", 
    marginBottom: "-5px",
  },
  lineaDescripcion: {
    display: "flex",
    position: "absolute",
    width: "285px",
    height: "2px",
    right: "4px",
    top: "329px",
    backgroundColor: "#0065DD",
  },
  containerMotivacion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "484px",
    height: "111px",
    top: "329px",
    left: "64px",
    padding: "16px 0px",
    gap: "8px",
  },
  contentMotivacionTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentMotivacionMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    marginBottom: "-5px",
  },
  containerFoda: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "564px",
    height: "157px",
    top: "456px",
    left: "24px",
    padding: "16px 24px",
    gap: "24px",
    border: "2px solid #0065DD",
  },
  contentFoda: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: "8px",
  },
  contentFodaTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentFodaMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    marginBottom: "-5px",
  },
  logoTeamWork: {
    display: "flex",
    position: "absolute",
    width: "135px",
    height: "27px",
    left: "386px",
    top: "642px",
  },
  lineaConclusion: {
    display: "flex",
    position: "absolute",
    width: "203px",
    height: "2px",
    left: "0px",
    top: "683px",
    backgroundColor: "#0065DD",
  },
  containerConclusion: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "236px",
    height: "53px",
    top: "629px",
    padding: "0px 24px 8px 40px",
    gap: "8px",
  },
  contentConclusionTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentConclusionMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "11px",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  containerFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "90px",
    top: "702px",
    padding: "0px 0px 16px 0px",
  },
  contentFooterTitle: {
    color: "#262626",
    fontSize: "10px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  contentFooternName: {
    color: "#262626",
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "capitalize",
    marginTop: "6px",
  },
  contentFooternDescripcion: {
    color: "#262626",
    textAlign: "center",
    fontSize: "9px",
    fontWeight: 400,
    margin: "8px 24px 0px 24px",
  },
  containerFooterPie: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "240px",
    top: "768px",
    alignItems: "center",
    position: "absolute",
    width: "122px",
  },
  containerFooterPieText: {
    color: "#61738E",
    fontSize: "8px",
    fontWeight: 400,
    textTransform: "capitalize",
  },
});

export const stylesConGraficoEje = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  imgPortada: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "188px",
    top: "0px",
  },
  imgRadar: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "188px",
    top: "200px",
  },
  containerSugerencia: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    width: "241px",
    height: "302px",
    left: "24px",
    backgroundColor: "#0065DD",
    padding: "32px",
    gap: "16px",
  },
  containerSugerenciaText: {
    display: "flex",
    flexDirection: "column",
    width: "177px",
    gap: "4px",
  },
  contentSugerenciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentSugerenciaMessage: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify"
  },
  containerPostulante: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
    width: "347px",
    height: "188px",
    top: "0px",
    left: "265px",
    gap: "8px",
  },
  contentPostulanteTitle: {
    display: "flex",
    color: "#FDFDFD",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contenInfotPostulante: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    width: "100%",
    gap: "16px",
  },
  contentPostulante: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
  },
  postulanteTitle: {
    display: "flex",
    textAlign: "right",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  postulanteInfo: {
    display: "flex",
    textAlign: "left",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 400,
  },

  containerMotivacion: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    position: "absolute",
    width: "331px",
    height: "100px",
    top: "248px",
    right: "0px",
    padding: "16px 32px 16px 48px",
    gap: "8px",
  },
  contentMotivacionTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentMotivacionMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify"
  },

  containerEducacion: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    position: "absolute",
    width: "331px",
    height: "60px",
    top: "188px",
    right: "0px",
    gap: "8px",
    padding: "8px 48px",
  },
  contentEducacionTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentEducacionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    width: "100%", 
  },
  contentEducacionTitleProf: {
    display: "flex",
    color: "#262626",
    fontSize: "11px",
    fontWeight: 500,
    width: "100%", 
    maxHeight: "20px", 
    textOverflow: "ellipsis", 
  },
  contentEducacionMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "11px",
    fontWeight: 400,
  },
  contentEducacionInstitucionMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "11px",
    fontWeight: 400,
    textTransform: "capitalize",
    marginTop: "-8px"
  },
  lineaEducacion: {
    display: "flex",
    position: "absolute",
    width: "285px",
    height: "2px",
    right: "0px",
    top: "249px",
    backgroundColor: "#0065DD",
  },
  containerFoda: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", 
    alignContent: "center",
    position: "absolute",
    width: "241px",
    height: "280px",
    top: "326px",
    left: "24px",
    padding: "8px 16px",
    gap: "24px",
    border: "2px solid #0065DD",
  },
  contentFoda: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "8px",
    maxHeight: "140px", 
  },
  contentFodaTitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "12px",
    fontWeight: 700,
  },
  contentFodaMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    maxHeight: "98px", 
    marginBottom: "-5px",
    textAlign: "justify", 
  },
  containerGrafico: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FCFCFC", 
    alignContent: "center",
    position: "absolute",
    width: "299px",
    height: "277px",
    top: "359px",
    left: "296px",
    padding: "16px 8px",
    borderRadius: "16px", 
    border: "2px solid #eaeaea",
  },
  logoTeamWork: {
    display: "flex",
    position: "absolute",
    width: "135px",
    height: "27px",
    left: "386px",
    top: "655px",
  },
  lineaConclusion: {
    display: "flex",
    position: "absolute",
    width: "203px",
    height: "2px",
    left: "0px",
    top: "683px",
    backgroundColor: "#0065DD",
  },
  containerConclusion: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "236px",
    height: "53px",
    top: "629px",
    padding: "0px 24px 8px 40px",
    gap: "8px",
  },
  contentConclusionTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentConclusionMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "11px",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  containerFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "90px",
    top: "702px",
    padding: "0px 0px 16px 0px",
  },
  contentFooterTitle: {
    color: "#262626",
    fontSize: "10px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  contentFooternName: {
    color: "#262626",
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "capitalize",
    marginTop: "6px",
  },
  contentFooternDescripcion: {
    color: "#262626",
    textAlign: "center",
    fontSize: "9px",
    fontWeight: 400,
    margin: "8px 24px 0px 24px",
  },
  containerFooterPie: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "240px",
    top: "768px",
    alignItems: "center",
    position: "absolute",
    width: "122px",
  },
  containerFooterPieText: {
    color: "#61738E",
    fontSize: "8px",
    fontWeight: 400,
    textTransform: "capitalize",
  },
});

export const stylesConGraficoXl3 = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  logoTeamWork: {
    position: "relative",
    width: "135px",
    height: "27px",
    left: "430px",
    top: "10px",
  },
  containerEvaluacion: {
    display: "flex",
    width: "564px",
    flexDirection: "column",
    position: "absolute",
    height: "411px",
    top: "48px",
    left: "24px",
    gap: "8px",
    backgroundColor: "#FDFDFD",
    padding: "16px 24px",
    border: "1px solid #B0C1D5",
  },
  containerEvaluacionGridTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "516px",
    height: "17px",
    textAlign: "center",
    backgroundColor: "#0065DD",
  },
  containerEvaluacionTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "12px",
    fontWeight: 700,
  },
  containerEvaluacionGridSubtitle: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "516px",
    padding: "0px 16px",
  },
  containerEvaluacionSubtitle: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "center",
  },
  containerEvaluacionGridMessage: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "484px",
    gap: "4px",
  },
  containerEvaluacionSubtitleMessage: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontStyle: "italic",
    fontWeight: 300,
    textAlign: "left",
  },
  containerEvaluacionTableCompetencia: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "516px",
    height: "17px",
    textAlign: "center",
    backgroundColor: "#0065DD",
    borderBottom: "1px solid #B0C1D5",
  },
  containerEvaluacionTableCompetenciaTitle: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTrCompetencia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    height: "17px",
    marginTop: "-7px",
    borderBottom: "2px solid #B0C1D5",
  },
  containerEvaluacionTableTdCompetenciaTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#0065DD",
    width: "110px",
    height: "17px",
  },
  containerEvaluacionTableTdCompetenciaTitleText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTdCompetenciaDescrition: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "381px",
    height: "17px",
    backgroundColor: "#FCFCFC",
  },
  containerEvaluacionTableTdCompetenciaDescritionText: {
    display: "flex",
    color: "#48566B",
    fontSize: "10px",
    fontWeight: 300,
  },
  containerEvaluacionTableTdCompetenciaPoint: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "25px",
    height: "17px",
    backgroundColor: "#0065DD",
  },
  containerEvaluacionTableTdCompetenciaPointText: {
    display: "flex",
    color: "#FDFDFD",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTrCompetenciaLaboral: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    height: "17px",
  },
  containerEvaluacionTableTdCompetenciaLaboralTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    backgroundColor: "#0065DD",
    width: "391px",
    height: "15px",
    padding: "0px 16px",
  },
  containerEvaluacionTableTdCompetenciaLaboralTitleText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTdCompetenciaLaboralNum: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#0065DD",
    width: "25px",
    height: "15px",
  },
  containerEvaluacionTableTdCompetenciaLaboralNumText: {
    display: "flex",
    color: "#FAFAFA",
    fontSize: "10px",
    fontWeight: 500,
  },
  containerEvaluacionTableTrCompetenciaMessageWhite: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    backgroundColor: "#FAFAFA",
    height: "18px",
    border: "1px solid #B0C1D5",
    marginTop: "-9px",
  },
  containerEvaluacionTableTrCompetenciaMessage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "516px",
    backgroundColor: "#D9E8FA",
    height: "18px",
    border: "1px solid #B0C1D5",
    marginTop: "-9px",
  },
  containerEvaluacionTableTdCompetenciaLaboralMessage: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    width: "391px",
    height: "15px",
    padding: "0px 16px",
  },
  containerEvaluacionTableTdCompetenciaLaboralMessageText: {
    display: "flex",
    color: "#404040",
    fontSize: "10px",
    fontWeight: 400,
  },
  containerEvaluacionTableTdCompetenciaLaboralOption: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    width: "25px",
    height: "15px",
    borderLeft: "1px solid #B0C1D5",
  },
  containerEvaluacionTableTdCompetenciaLaboralOptionText: {
    display: "flex",
    color: "#005BC7",
    fontSize: "11px",
    fontWeight: 700,
  },

  containerDescripcion: {
    display: "flex",
    width: "228px",
    height: "304px",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "472px",
    left: "0px",
    padding: "16px 32px 16px 48px",
  },
  containerDescripText: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "8px",
  },
  contentDescripTitle: {
    display: "flex",
    color: "#262626",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  contentDescripMessage: {
    display: "flex",
    color: "#262626",
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "justify"
  },

  containerGrafico: {
    display: "flex",
    width: "336px",
    height: "303px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "472px",
    left: "228px",
    padding: "16px 8px",
    borderRadius: "16px", 
    border: "2px solid #eaeaea",
  },
});
