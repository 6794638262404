import { ReactNode, useState, useRef  } from "react";
import "../../../../assets/css/tooltip-tw.min.css";

interface ITooltip {
  text: string;
  children: ReactNode;
}

const Tooltip = ({ text, children }: ITooltip) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className="tooltip-black" ref={tooltipRef}>
          <div className="arrow-black" />
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
