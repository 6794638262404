import { useEffect, useState } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesPortada } from "../styles";
import imageMujer from "../../../../assets/images/pdfPsicolaboral/mujer.png";
import imagenFondo from "../../../../assets/images/pdfPsicolaboral/colorFondo.png";
import imagenElipse from "../../../../assets/images/pdfPsicolaboral/elipse.png";
import imagenPuntos from "../../../../assets/images/pdfPsicolaboral/puntos.png";
import imagenTeamwork from "../../../../assets/images/pdfPsicolaboral/logo-teamwork.png";
import { IPDFPagePortada } from "../../../../interfaces/ISeleccion";
import { formatDateToMMMMDDYYYY } from "../../../../functions/kit-mdtw/dates";
/**
 * TODO: FONDO DE PANTALLA PARA LA PORTADA PDF BIEN PERRON
 */

const PortadaPsicolaboral = (props: IPDFPagePortada) => {
  const { formList } = props;
  const fechactual = new Date();
  const [listRender, setListRender] = useState<any>({
    primerNombre: "",
    primerApellido: "",
    personaDNI: "",
    fechaNacimiento: "",
    estadoCivil: "",
    disponibilidad: "",
    reclutador: "",
    pretensionRenta: "",
    pruebasAplicadas: "",
    areaNegocioCod: "",
  });

  useEffect(() => {
    if (formList) {
      const { contenido, portada } = formList ?? { contenido: {}, portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page wrap size="LETTER">
          <View style={stylesPortada.body}>
            <Image src={imagenFondo} style={stylesPortada.fondoPantalla} />
            <Image src={imagenPuntos} style={stylesPortada.fondoPuntos} />
            <Image src={imagenElipse} style={stylesPortada.fondoElipse} />
            <Image src={imageMujer} style={stylesPortada.fondoMujer} />
            <Image src={imagenTeamwork} style={stylesPortada.logoTeamWork} />
            <View style={stylesPortada.sectionPortadaTitle}>
              <Text style={stylesPortada.sectionPortadaTitleText}>INFORME</Text>
              <Text style={stylesPortada.sectionPortadaTitleText}>
                PSICOLABORAL
              </Text>
            </View>

            <View style={stylesPortada.sectionPortadaSubtitle}>
              <Text style={stylesPortada.sectionPortadaSubtitleText}>
                PROCESO DE SELECCIÓN
              </Text>
              <Text style={stylesPortada.sectionPortadaSubtitleText}>
                {`${listRender.clienteNombre}`} {" - "}{" "}
                {listRender.areaNegocioCod}
              </Text>
            </View>

            <View style={stylesPortada.sectionFooterPortada}>
              <View style={stylesPortada.contentFooterPortada}>
                <Text style={stylesPortada.sectionFooterPortadaTextFecha}>
                  {formatDateToMMMMDDYYYY({
                    date: listRender.fechaEvaluacion,
                  }) ?? ""}
                </Text>
                <Text style={stylesPortada.sectionFooterPortadaTextCopyRight}>
                  Copyright {fechactual.getFullYear()} Teamwork Chile
                </Text>
              </View>
            </View>
            <View style={stylesPortada.sectionNamePortada}>
              <View style={stylesPortada.contentNamePortada}>
                <Text style={stylesPortada.contentNamePortadaText}>
                  CARGO SOLICITADO
                </Text>
                <Text style={stylesPortada.contentNamePortadaMessaje}>
                  {listRender.cargoNombre}
                </Text>
              </View>
              <View style={stylesPortada.contentNamePortada}>
                <Text style={stylesPortada.contentNamePortadaText}>
                  NOMBRE CANDIDATO
                </Text>
                <Text style={stylesPortada.contentNamePortadaMessaje}>
                  {listRender.primerNombre.toUpperCase()}{" "}
                  {listRender.primerApellido.toUpperCase()}
                </Text>
              </View>
              <View style={stylesPortada.contentNamePortada}>
                <Text style={stylesPortada.contentNamePortadaText}>
                  NOMBRE PSICOLOGO EVALUADOR
                </Text>
                <Text style={stylesPortada.contentNamePortadaMessaje}>
                  {listRender.reclutador.toUpperCase()}
                </Text>
              </View>
              <View style={stylesPortada.contentNamePortada}>
                <Text style={stylesPortada.contentNamePortadaText}>
                  PRUEBAS APLICADAS
                </Text>
                <Text style={stylesPortada.contentNamePortadaMessaje}>
                  {listRender.pruebasAplicadas ?? ""}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </>
  );
};

export default PortadaPsicolaboral;
