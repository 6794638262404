import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesSinGraficoEje } from "../styles";
import logoTeamworkAzul from "../../../../assets/images/pdfPsicolaboral/logoTeamworkAzul.png";
import portada2 from "../../../../assets/images/pdfPsicolaboral/imgPortada2.png";
import { IPDFPagePortada } from "../../../../interfaces/ISeleccion";
import {
  formatDateToDDMMAAAA,
} from "../../../../functions/kit-mdtw/dates";
import { useEffect, useState } from "react";
import { formatMoneyChilean } from "../../../../functions/kit-mdtw/moneys";
import {
  formatNameUpper,
  formatLetterCapitalize,
} from "../../../../functions/kit-mdtw/texts";

const SinGraficoEjecutivo2 = (props: IPDFPagePortada) => {
  const { formList } = props;
  const fechactual = new Date();
  const [fortalezas, setFortalezas] = useState<string[]>([]);
  const [oportunidades, setOportunidades] = useState<string[]>([]);
  const [listRender, setListRender] = useState<any>({
    primerNombre: "",
    primerApellido: "",
    personaDNI: "",
    fechaNacimiento: "",
    estadoCivil: "",
    disponibilidad: "",
    reclutador: "",
    pretensionRenta: "",
    conclusionInforme: "",
    pruebasAplicadas: "",
    fechaEvaluacion: "",
  });

  useEffect(() => {
    if (formList) {
      const { contenido } = formList ?? { contenido: {} };
      const { portada } = formList ?? { portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  useEffect(() => {
    if (listRender.fortalezas) {
      const arrayFortalezas = listRender?.fortalezas?.split("\n");
      const arrayOportunidades = listRender?.oportunidades?.split("\n");
      setFortalezas(arrayFortalezas?.filter((item: string) => item != ""));
      setOportunidades(
        arrayOportunidades?.filter((item: string) => item != "")
      );
    }
  }, [listRender]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page size="LETTER">
          <View style={stylesSinGraficoEje.body}>
            <Image src={portada2} style={stylesSinGraficoEje.imgPortada} />
            <View style={stylesSinGraficoEje.containerSugerencia}>
              <View style={stylesSinGraficoEje.containerSugerenciaText}>
                <Text style={stylesSinGraficoEje.contentSugerenciaTitle}>
                  SÍNTESIS Y SUGERENCIAS
                </Text>
                <Text style={stylesSinGraficoEje.contentSugerenciaMessage}>
                  {listRender.sintesis ?? ""}
                </Text>
              </View>
            </View>
            <View style={stylesSinGraficoEje.containerPostulante}>
              <Text style={stylesSinGraficoEje.contentPostulanteTitle}>
                IDENTIFICACIÓN DEL POSTULANTE
              </Text>
              <View style={stylesSinGraficoEje.contenInfotPostulante}>
                <View style={stylesSinGraficoEje.contentPostulante}>
                  <Text style={stylesSinGraficoEje.postulanteTitle}>
                    Nombre:
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteTitle}>Rut:</Text>
                  <Text style={stylesSinGraficoEje.postulanteTitle}>
                    Fecha De Nacimiento:
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteTitle}>
                    Disponibilidad:
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteTitle}>
                    Pretensión De Renta:
                  </Text>
                </View>
                <View style={stylesSinGraficoEje.contentPostulante}>
                  <Text style={stylesSinGraficoEje.postulanteInfo}>
                    {formatNameUpper({ texto: listRender.primerNombre })}{" "}
                    {formatNameUpper({ texto: listRender.primerApellido })}
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteInfo}>
                    {listRender.personaDNI || "-"}
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteInfo}>
                    {formatDateToDDMMAAAA({
                      date: listRender.fechaNacimiento,
                      format: "/",
                    }) || "-"}
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteInfo}>
                    {formatLetterCapitalize({
                      texto: listRender.disponibilidad,
                    }) || "-"}
                  </Text>
                  <Text style={stylesSinGraficoEje.postulanteInfo}>
                    {formatMoneyChilean({ texto: listRender.pretensionRenta })}
                    {".-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSinGraficoEje.containerDescripcion}>
              <Text style={stylesSinGraficoEje.contentDescripcionTitle}>
                DESCRIPCIÓN POR COMPETENCIAS GENERAL
              </Text>
              <Text style={stylesSinGraficoEje.contentDescripcionMessage}>
                {listRender.descripcionEvaluacion || "-"}
              </Text>
            </View>
            <View style={stylesSinGraficoEje.lineaDescripcion}></View>
            <View style={stylesSinGraficoEje.containerMotivacion}>
              <Text style={stylesSinGraficoEje.contentMotivacionTitle}>
                MOTIVACIÓN AL CARGO
              </Text>
              <Text style={stylesSinGraficoEje.contentMotivacionMessage}>
                {listRender.motivacion || "-"}
              </Text>
            </View>
            <View style={stylesSinGraficoEje.containerFoda}>
              <View style={stylesSinGraficoEje.contentFoda}>
                <Text style={stylesSinGraficoEje.contentFodaTitle}>
                  FORTALEZAS
                </Text>
                {fortalezas?.slice(0, 3).map((item: string, index: number) => (
                  <Text
                    style={stylesSinGraficoEje.contentFodaMessage}
                    key={index}
                  >{`${item}`}</Text>
                ))}
              </View>
              <View style={stylesSinGraficoEje.contentFoda}>
                <Text style={stylesSinGraficoEje.contentFodaTitle}>
                  OPORTUNIDADES DE MEJORA
                </Text>
                {oportunidades
                  ?.slice(0, 3)
                  .map((item: string, index: number) => (
                    <Text
                      style={stylesSinGraficoEje.contentFodaMessage}
                      key={index}
                    >{`${item}`}</Text>
                  ))}
              </View>
            </View>
            <View style={stylesSinGraficoEje.containerConclusion}>
              <Text style={stylesSinGraficoEje.contentConclusionTitle}>
                CONCLUSIÓN
              </Text>
              <Text style={stylesSinGraficoEje.contentConclusionMessage}>
                {listRender.conclusionInforme ?? " "}
              </Text>
            </View>
            <View style={stylesSinGraficoEje.lineaConclusion}></View>
            <Image
              src={logoTeamworkAzul}
              style={stylesSinGraficoEje.logoTeamWork}
            ></Image>
            <View style={stylesSinGraficoEje.containerFooter}>
              <Text style={stylesSinGraficoEje.contentFooterTitle}>
                Psicólogo Evaluador
              </Text>
              <Text style={stylesSinGraficoEje.contentFooternName}>
                {listRender.reclutador ?? " "}
              </Text>
              <Text style={stylesSinGraficoEje.contentFooternDescripcion}>
                La información contenida en este informe es de carácter
                confidencial; debe ser utilizada sólo para los fines de
                selección y por expresa autorización del Psicólogo firmante.
              </Text>
            </View>
            <View style={stylesSinGraficoEje.containerFooterPie}>
              <Text style={stylesSinGraficoEje.containerFooterPieText}>
                Copyright {fechactual.getFullYear()} Teamwork Chile
              </Text>
            </View>
          </View>
        </Page>
      )}
    </>
  );
};

export default SinGraficoEjecutivo2;
