import { useContext, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IconRegistroCompleto from "../../../../ui/icons-seleccion/candidatoValidado/IconRegistroCompleto";
import IconRegistroRechazado from "../../../../ui/icons-seleccion/candidatoValidado/IconRegistroRechazado";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconRegistroCandidato from "../../../../ui/icons-seleccion/IconRegistroCandidato";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ModalEncuesta from "../../../Campaign/ModalEncuesta";
import ModalUpdateCandidate from "../../../../ui/kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import {
  ICandidatoValidadoPersona,
  IMovilizacionTw,
} from "../../../../../interfaces/ISeleccion";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";

interface IHandleClickAddOrRemoveChoise {
  personaDNI: string;
}

interface ITablaCandidato {
  listaPersona: any;
  listaSeleccionProceso: string[];
  localStorageFilter: string;
  formFilter: any;
  listRefresh: Function;
  setListadoSeleccionado: Function;
  handleClickAddOrRemoveChoise: (values: IHandleClickAddOrRemoveChoise) => void;
}

const TablaCandidato = (props: ITablaCandidato) => {
  const {
    listaPersona,
    handleClickAddOrRemoveChoise,
    listaSeleccionProceso,
    localStorageFilter,
    formFilter,
    listRefresh,
    setListadoSeleccionado,
  } = props;
  const {
    listMovilizacionTw,
    putMetodoMovilizacion,
    putConfirmarEntrevista,
    getRutVerificado,
    getDocument,
    token,
  } = useContext(SeleccionContext);
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );

  const handleClickVerificarRut = (rowData: any) => {
    const { personaDNI, busPerId } = rowData;
    setCandidateSelect([personaDNI]);
    getRutVerificado({
      personaRut: personaDNI,
      busPerId,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
      token,
    });
    getDocument({ personaDNI: personaDNI, token });
  };

  const handleClickBloquearCandidato = (values: any) => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (values: any) => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalDescartar(true);
  };

  const handleClickConfirmarEntrevista = async (values: any) => {
    const { busPerId, newStatus } = values;
    const candidates = {
      busPerId,
      confirmaEntrevista: newStatus,
    };
    try {
      if (token) {
        await putConfirmarEntrevista({ token, personas: [candidates] });
        await listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickTransporteTw = async (values: any) => {
    const { busPerId, status } = values;
    try {
      if (token && listMovilizacionTw && status) {
        const currentState = listMovilizacionTw.find(
          ({ nombre }: IMovilizacionTw) => nombre !== status
        );
        const body = {
          busPerId,
          movilizacionId: currentState.movilizacionId ?? "",
        };
        await putMetodoMovilizacion({ token, body: [body] });
        await listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {listaPersona &&
        listaPersona.map((items: ICandidatoValidadoPersona) => {
          const {
            busPerId,
            personaDNI,
            primerNombre,
            fonoPrincipal,
            primerApellido,
            fechaValidacion,
            confirmaEntrevista,
            movilizacion,
            estadoRegistro,
          } = items;
          return (
            <>
              <tr
                className={`tr-table-registros ${
                  listaSeleccionProceso.includes(personaDNI) && "checkeado"
                }`}
                key={busPerId}
              >
                <td className="td-table-procesos">
                  <button
                    className="button-select"
                    onClick={() => handleClickAddOrRemoveChoise({ personaDNI })}
                  >
                    {listaSeleccionProceso.includes(personaDNI) ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </button>
                </td>
                <td className="td-table-procesos">
                  <section className="content-state">
                    {estadoRegistro === "COMPLETO" && (
                      <>
                        <IconRegistroCompleto fill="#06A502" />
                        <div className="content-state-text">
                          <label className="title registro-completo">
                            Registro Completo
                          </label>
                          <label className="message">
                            Datos requeridos <br></br>
                            completos
                          </label>
                        </div>
                      </>
                    )}
                    {estadoRegistro === "INCOMPLETO" && (
                      <>
                        <IconRegistroCompleto fill="#E3A000" />
                        <div className="content-state-text">
                          <label className="title registro-incompleto">
                            Registro Incompleto
                          </label>
                          <label className="message">
                            Datos requeridos <br></br>
                            incompleto
                          </label>
                        </div>
                      </>
                    )}
                    {estadoRegistro === "RECHAZADO" && (
                      <>
                        <IconRegistroRechazado fill="#BE2A2A" />
                        <div className="content-state-text">
                          <label className="title solicitud-rechazada">
                            Registro Rechazado
                          </label>
                          <label className="message">
                            Registro devuelto para <br></br>
                            corrección
                          </label>
                        </div>
                      </>
                    )}
                  </section>
                </td>
                <td className="td-table-procesos">
                  {primerNombre} {primerApellido}
                </td>
                <td className="td-table-procesos">{personaDNI}</td>
                <td className="td-table-procesos">{fonoPrincipal}</td>
                <td className="td-table-procesos">
                  {formatDateToDDMMAAAA({
                    date: fechaValidacion,
                    format: "/",
                  })}
                </td>
                <td className="td-table-procesos">
                  <div className="content-icon-table">
                    <button
                      className="button-content"
                      onClick={() =>
                        handleClickConfirmarEntrevista({
                          busPerId,
                          newStatus: !confirmaEntrevista,
                        })
                      }
                    >
                      {confirmaEntrevista ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </button>
                  </div>
                </td>
                <td className="td-table-procesos">
                  <div className="content-icon-table">
                    <button
                      className="button-content"
                      onClick={() =>
                        handleClickTransporteTw({
                          busPerId: busPerId,
                          status: movilizacion,
                        })
                      }
                    >
                      {movilizacion == "TEAMWORK" ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </button>
                  </div>
                </td>
                <td className="td-table-procesos">
                  <div className="content-icon-table">
                    <Tooltip text="Ver Registro">
                      <button
                        onClick={() =>
                          handleClickVerificarRut({ personaDNI, busPerId })
                        }
                        className="button-content"
                      >
                        <IconRegistroCandidato fill={"#0065DD"} />
                      </button>
                    </Tooltip>
                    <Tooltip text="Bloquear">
                      <button
                        onClick={() =>
                          handleClickBloquearCandidato({ personaDNI })
                        }
                        className="button-content"
                      >
                        <IconBloquear fill={"#BE2A2A"} />
                      </button>
                    </Tooltip>
                    <Tooltip text="Descartar">
                      <button
                        onClick={() =>
                          handleClickDescartarCandidato({ personaDNI })
                        }
                        className="button-content"
                      >
                        <IconDescarte fill={"#BE2A2A"} />
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            </>
          );
        })}

      {isOpenModalUpdateCandidate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdateCandidate}
          setResultBusperId={setResultBusperId}
          requerido
          token={token}
          isLoadingModal={isLoading}
          rut={candidateSelect[0]}
          getCandidates={listRefresh}
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => {
            setIsOpenModalCampaign(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            listSelect={listaPersona ?? []}
            localStorageFilter={localStorageFilter}
            setListadoSeleccionado={setListadoSeleccionado}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={candidateSelect}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={listRefresh}
            listSelect={listaPersona ?? []}
            setListadoSeleccionado={setListadoSeleccionado}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={candidateSelect}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaCandidato;
