import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputText } from "primereact/inputtext";
import InputDateCustomized from "../../../ui/kit-mdtw/InputDateCustomized";
import IconCheckfila from "../../../ui/kit-svg/IconCheckfila";
import {
  IListaCandidatos,
  Params,
} from "../../../../interfaces/IValidationKam";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
import DropDownListCustomized from "../../../ui/kit-mdtw/DropDownListCustomized";
import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";

interface IDetener {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  onChange?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
  token?: string | undefined;
  getToken: Function;
  rut?: string;
  busPerId?: string;
  idCliente?: string;
  onCloseModalFicha?: Function;
  ficha?:string;
  areaNegNewContrato?:string;
  codCargoNewContrato?:string;
  lugarTrabajoNewContrato?:string;
}

export default (props: IDetener) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    onChange,
    buttonBack,
    buttonGo,
    list,
    token,
    getToken,
    rut,
    busPerId,
    onCloseModalFicha,
    ficha,
    areaNegNewContrato,
    codCargoNewContrato,
    lugarTrabajoNewContrato
  } = props;
  const {
    getListCandidateEnviadoContrato,
    listCandidatoPendienteContrato,
    listaAContratos,
    listCandidatoEnviadoContrato,
    setListCandidatoEnviadoContrato,
    detenerSolicitudContrato,
    solicitudFueraPlazo,
    getSolicitudContrato,
  } = useContext(ValidationKamContext);

  const { idCliente } = useParams<Params>();
  const [checkAplyAll, setCheckAplyAll] = useState<boolean>(false);
  const [inputForAll, setInputForAll] = useState<any>({
    date: { value: undefined },
  });
  const [inputForInd, setInputForInd] = useState<any>({
    date: { value: undefined },
  });

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [objetoData, setObjtoData] = useState<any>("");
  const [openModalFueraPlazo, setOpenModalFueraPlazo] =
    useState<boolean>(false);
  const objetoSendFiltro = {};

  useEffect(() => {
    if (token) {
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
    }
  }, [token]);

  useEffect(() => {
    if (token && rut) {
      listCandidatoEnviadoContrato;
    }
  }, [token, rut]);
 

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCloseModalFicha = () => {
    typeof onCloseModalFicha == "function" && onCloseModalFicha();
  };

  const handleClickCheck = () => {
    setCheckAplyAll(checkAplyAll ? false : true);
  };

  const busqValidInputDet = () => {
    let validInput = 0;
    objetoData &&
      objetoData.forEach((value: any) =>
        value.motivo == "" ? validInput++ : validInput
      );
    return objetoData.length > 0 && validInput == 0;
  };

  const handleClickFueraPlazo = (objetoData: any) => {
    solicitudFueraPlazo({ objetoData, token, idCliente, objetoSendFiltro });

    const busPerId = listCandidatoPendienteContrato.map((item: any) => {
      return item.busPerId;
    });
    getSolicitudContrato({
      token,
      idCliente,
      busPerId:
        modalType === "FueraPlazoIndividual"
          ? objetoData[0].busPerId
          : busPerId,
      objetoSendFiltro,
    });
    handleClickCloseModal();
    handleClickCloseModalFicha();
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo ">
        <div className="modal-content-validatekam-Mo w-70">
          <div className="modal-title-Mo">
            <span
              className="modal-close"
              onClick={() =>
                typeof onChangeBack == "function" && onChangeBack()
              }
            >
              <CloseIcon />
            </span>
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo start px-5  ">
            <span>{subTitle}</span>
          </div>

          <>
            <div className="modal-subtitle-Mo start px-5">
              <span>{"Los datos"}</span>
            </div>
            <div
              className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5"
              style={{ marginBottom: "-18px" }}
            >
              <div className="col-12 md:col-11 m-0 p-0  borderColorGrey">
                <div className="row flex flex-column md:flex-row">
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener  headerHeight">
                      Nombre
                    </div>
                    <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorGrey textNormal border-bottom-2 border-Seg">
                      {list.personal.candidato.primerNombre +
                        "  " +
                        list.personal.candidato.primerApellido}
                    </div>
                  </div>
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Ficha
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {ficha}
                    </div>
                  </div>
                  <div className="col-12 md:col-1 p-0 mx-0">
                    <div className="col-6 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      División TW
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {list.contratacion.division}
                    </div>
                  </div>
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Área de Negocios
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {list.contratacion.areaNegocioCod}
                    </div>
                  </div>
                  <div className="col-12 md:col-1 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Cód. Cargo
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {list.contratacion.codCargo}
                    </div>
                  </div>
                  <div className="col-12 md:col-5 p-0 mx-0 pr-4">
                    <div className="col-6 md:col-12 bg-headerGrey text-center  borderColorGrey responsiveDivDetener headerHeight">
                      Lugar de Trabajo
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {list.contratacion.comunaSucursal +
                        "  " +
                        list.contratacion.direccionSucursal}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-subtitle-Mo start px-5 pt-6">
              <span>{"Deben actualizarse por los siguientes datos"}</span>
            </div>
            <div
              className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5"
              style={{ marginBottom: "-18px" }}
            >
              <div className="col-12 md:col-11 m-0 p-0  borderColorGrey">
                <div className="row flex flex-column md:flex-row">
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener  headerHeight">
                      Nombre
                    </div>
                    <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorGrey textNormal border-bottom-2 border-Seg">
                      {list.personal.candidato.primerNombre +
                        "  " +
                        list.personal.candidato.primerApellido}
                    </div>
                  </div>
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Ficha
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {ficha}
                    </div>
                  </div>
                  <div className="col-12 md:col-1 p-0 mx-0">
                    <div className="col-6 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      División TW
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {list.contratacion.division}
                    </div>
                  </div>
                  <div className="col-12 md:col-2 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Área de Negocios
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {areaNegNewContrato}
                    </div>
                  </div>
                  <div className="col-12 md:col-1 p-0 mx-0">
                    <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                      Cód. Cargo
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {codCargoNewContrato}
                    </div>
                  </div>
                  <div className="col-12 md:col-5 p-0 mx-0 pr-4">
                    <div className="col-6 md:col-12 bg-headerGrey text-center  borderColorGrey responsiveDivDetener headerHeight">
                      Lugar de Trabajo
                    </div>
                    <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                      {lugarTrabajoNewContrato}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          <>
            <div className="modal-message-Mo start mt-3 pl-5 text-headerWhite">
              <span style={{ float: "none" }}>
              En el caso de que el documento ya se encuentra firmado por el trabajador, éste no podrá ser editado y se <br /> deberá generar un addendum. <br />
Al confirmar, se le enviará una notificación con la solicitud al área correspondiente. Para continuar presione <br />Solicitar Actualización
              </span>
            </div>
          </>

          <div className="modal-button-content modal-button-content-in-modal center">
            <>
              <button
                className={`modal-button cancel`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={handleClickCloseModal}
              >
                {buttonBack ? buttonBack : "Cancelar"}
              </button>
              <button
                className={`modal-button ${"confirm"}`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={() => typeof onChangeGo == "function" && onChangeGo()}
                disabled={false}
              >
                {" "}
                {buttonGo ? buttonGo : "Solicitar Actualización"}
              </button>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
