interface IIconFichaPostulacion {
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconFichaPostulacion) => {
  const { fill, disabled } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 16"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M3.75 12.0417C3.91667 12.0417 4.0625 11.9792 4.1875 11.8542C4.3125 11.7292 4.375 11.5833 4.375 11.4167C4.375 11.25 4.3125 11.1042 4.1875 10.9792C4.0625 10.8542 3.91667 10.7917 3.75 10.7917C3.58333 10.7917 3.4375 10.8542 3.3125 10.9792C3.1875 11.1042 3.125 11.25 3.125 11.4167C3.125 11.5833 3.1875 11.7292 3.3125 11.8542C3.4375 11.9792 3.58333 12.0417 3.75 12.0417ZM3.75 8.625C3.91667 8.625 4.0625 8.5625 4.1875 8.4375C4.3125 8.3125 4.375 8.16667 4.375 8C4.375 7.83333 4.3125 7.6875 4.1875 7.5625C4.0625 7.4375 3.91667 7.375 3.75 7.375C3.58333 7.375 3.4375 7.4375 3.3125 7.5625C3.1875 7.6875 3.125 7.83333 3.125 8C3.125 8.16667 3.1875 8.3125 3.3125 8.4375C3.4375 8.5625 3.58333 8.625 3.75 8.625ZM3.75 5.20833C3.91667 5.20833 4.0625 5.14583 4.1875 5.02083C4.3125 4.89583 4.375 4.75 4.375 4.58333C4.375 4.41667 4.3125 4.27083 4.1875 4.14583C4.0625 4.02083 3.91667 3.95833 3.75 3.95833C3.58333 3.95833 3.4375 4.02083 3.3125 4.14583C3.1875 4.27083 3.125 4.41667 3.125 4.58333C3.125 4.75 3.1875 4.89583 3.3125 5.02083C3.4375 5.14583 3.58333 5.20833 3.75 5.20833ZM6.5 12.0417H11.5833V10.7917H6.5V12.0417ZM6.5 8.625H11.5833V7.375H6.5V8.625ZM6.5 5.20833H11.5833V3.95833H6.5V5.20833ZM1.25 15.5C0.916667 15.5 0.625 15.375 0.375 15.125C0.125 14.875 0 14.5833 0 14.25V1.75C0 1.41667 0.125 1.125 0.375 0.875C0.625 0.625 0.916667 0.5 1.25 0.5H13.75C14.0833 0.5 14.375 0.625 14.625 0.875C14.875 1.125 15 1.41667 15 1.75V14.25C15 14.5833 14.875 14.875 14.625 15.125C14.375 15.375 14.0833 15.5 13.75 15.5H1.25ZM1.25 14.25H13.75V1.75H1.25V14.25Z"
      />
    </svg>
  );
};
