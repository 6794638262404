interface IIconReasignar {
  width?: number;
  height?: number;
  fill?: string;
  style?: any;
  className?: any;
  color?: any;
}

export default (props: IIconReasignar) => {
  const { width, height, fill, style, className, color } = props;

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" className=""/>
      <path
        d="M24.8438 16.25C24.8438 15.214 24.4322 14.2204 23.6996 13.4879C22.9671 12.7553 21.9735 12.3438 20.9375 12.3438C19.9015 12.3438 18.9079 12.7553 18.1754 13.4879C17.4428 14.2204 17.0313 15.214 17.0313 16.25C17.0313 17.286 17.4428 18.2796 18.1754 19.0121C18.9079 19.7447 19.9015 20.1562 20.9375 20.1562C21.9735 20.1562 22.9671 19.7447 23.6996 19.0121C24.4322 18.2796 24.8438 17.286 24.8438 16.25ZM14.6875 16.25C14.6875 14.5924 15.346 13.0027 16.5181 11.8306C17.6902 10.6585 19.2799 10 20.9375 10C22.5951 10 24.1848 10.6585 25.3569 11.8306C26.529 13.0027 27.1875 14.5924 27.1875 16.25C27.1875 17.9076 26.529 19.4973 25.3569 20.6694C24.1848 21.8415 22.5951 22.5 20.9375 22.5C19.2799 22.5 17.6902 21.8415 16.5181 20.6694C15.346 19.4973 14.6875 17.9076 14.6875 16.25ZM12.4072 32.6562H29.4678C29.0332 29.5654 26.377 27.1875 23.1689 27.1875H18.7061C15.498 27.1875 12.8418 29.5654 12.4072 32.6562ZM10 33.5498C10 28.7402 13.8965 24.8437 18.7061 24.8437H23.1689C27.9785 24.8437 31.875 28.7402 31.875 33.5498C31.875 34.3506 31.2256 35 30.4248 35H11.4502C10.6494 35 10 34.3506 10 33.5498Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
      <path
        d="M28.6184 24.4803C28.0462 24.4803 27.584 24.0842 27.584 23.5939C27.584 23.1036 28.0462 22.7075 28.6184 22.7075C30.3317 22.7075 31.7217 21.5164 31.7217 20.0482V15.6161H30.6873C30.2703 15.6161 29.892 15.4 29.7304 15.0676C29.5688 14.7352 29.6593 14.3557 29.9535 14.1008L32.0223 12.328C32.4264 11.9817 33.0826 11.9817 33.4867 12.328L35.5555 14.1008C35.8529 14.3557 35.9402 14.7352 35.7786 15.0676C35.617 15.4 35.242 15.6161 34.825 15.6161H33.7906V20.0482C33.7906 22.497 31.476 24.4803 28.6184 24.4803Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
    </svg>
  );
};
