interface IconEye {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?:boolean;
  }
  
  export default (props: IconEye) => {
    const { width, height, stroke, style, className, color, fill,disabled } = props;
  
    return (
      <svg
        width={`${width ? width : "21"}`}
        height={`${height ? height : "21"}`}
        style={style}
        color={color}
        viewBox="0 0 21 21"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
      <path d="M3 2.25C2.5875 2.25 2.25 2.5875 2.25 3V18C2.25 18.4125 2.5875 18.75 3 18.75H18C18.4125 18.75 18.75 18.4125 18.75 18V3C18.75 2.5875 18.4125 2.25 18 2.25H3ZM0 3C0 1.34531 1.34531 0 3 0H18C19.6547 0 21 1.34531 21 3V18C21 19.6547 19.6547 21 18 21H3C1.34531 21 0 19.6547 0 18V3ZM7.125 9.375H13.875C14.4984 9.375 15 9.87656 15 10.5C15 11.1234 14.4984 11.625 13.875 11.625H7.125C6.50156 11.625 6 11.1234 6 10.5C6 9.87656 6.50156 9.375 7.125 9.375Z" 
     
      fill={disabled ? '#AEAEAE' :"#BE2A2A"}/>
      </svg>
    );
  };
 