import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import { ICandidatoSolRecibida } from "../../../../../interfaces/ISeleccion";

interface IModalRechazarRegistro {
  rut: any;
  solicitudes: any;
  setSolicitudes: Function;
}

export default (props: IModalRechazarRegistro) => {
  const { rut, solicitudes, setSolicitudes } = props;
  const { token, getToken, listaCandidatoSolRecibida } =
    useContext(SeleccionContext);
  const [currentData, setCurrentData] = useState<ICandidatoSolRecibida[]>([]);

  useEffect(() => {
    if (rut && listaCandidatoSolRecibida) {
      const current = listaCandidatoSolRecibida.filter(
        (item: ICandidatoSolRecibida) => rut.includes(item.personaDNI+'-'+item.solicitudTraspasoId)
      );
      setCurrentData(current);
      const sol = current.map(
        ({ solicitudTraspasoId }: ICandidatoSolRecibida) => {
          return {
            solicitudTraspasoId,
            aceptar: false,
          };
        }
      );
      setSolicitudes(sol);
    }
  }, [rut]);

  return (
    <>
      {rut &&
        currentData.length > 0 &&
        currentData.map((item: any) => {
          const {
            solicitudTraspasoId,
            busquedaId,
            personaDNI,
            primerApellido,
            primerNombre,
            solicitante,
            fechaSolicitud,
          } = item;
          return (
            <section className="content-table-restaurar">
              <table className="table-restaurar w-100">
                <thead className="thead-table-restaurar">
                  <tr className="tr-table-restaurar">
                    <th className="td-table-restaurar">Rut</th>
                    <th className="td-table-restaurar">Nombre</th>
                    <th className="td-table-restaurar">Fecha Solicitud</th>
                    <th className="td-table-restaurar">Reclutador</th>
                    <th className="td-table-restaurar">Estado</th>
                    <th className="td-table-restaurar">ID</th>
                  </tr>
                </thead>
                <tbody className="tbody-table-restaurar">
                  <tr className="tr-table-restaurar">
                    <td className="td-table-restaurar">{personaDNI}</td>
                    <td className="td-table-restaurar">{primerNombre} {primerApellido}</td>
                    <td className="td-table-restaurar">{formatDateToDDMMAAAA({
                        date: fechaSolicitud,
                        format: "/",
                      })}</td>
                    <td className="td-table-restaurar">{solicitante}</td>
                    <td className="td-table-restaurar">{"Validado"}</td>
                    <td className="td-table-restaurar">{busquedaId}</td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="w-100">
                <InputTextCustomized
                  layer="Motivo de Rechazo"
                  placeholder="Ingrese"
                  required={false}
                />
              </div> */}
            </section>
          );
        })}
    </>
  );
};
