import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaCandidatoRecibidas from "./CandidatoPostulacionesRecibidas/TablaCandidatoRecibidas";
import TablaCandidatoUltimoProcesado from "./CandidatoUltimoProcesado/TablaCandidatoUltimoProcesado";

const TablaRegistroPostulaciones = () => {
  const {
    toasts,
    getListaGeneral,
    getExclusiones,
    getBusquedaProcesos,
    getToken,
    token,
  } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getExclusiones({ token });
      getBusquedaProcesos({ token });
      if (localStorage.getItem("itemSelection")) {
        const tipoProceso: any = localStorage.getItem("itemSelection");
        getListaGeneral({ token, tipoProceso: tipoProceso.toUpperCase() });
      } else {
        getListaGeneral({ token });
      }
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaCandidatoRecibidas />
      <TablaCandidatoUltimoProcesado />
    </>
  );
};

export default TablaRegistroPostulaciones;
