import { useEffect, useRef, useState } from "react";
import "../../../../assets/css/pdf-grafico.min.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import html2canvas from "html2canvas";

interface IGraficoRadar {
  formList: any;
  setImage64: any;
}

const RadarChart = (props: IGraficoRadar) => {
  const { formList, setImage64 } = props;
  const [labels, setLabels] = useState<string[]>([]);
  const [alcanzado, setAlcanzado] = useState<number[]>([]);
  const [esperado, setEsperado] = useState<number[]>([]);
  let chartJs = useRef(null);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    CategoryScale
  );

  useEffect(() => {
    if (formList && formList.contenido) {
      if (!formList.contenido.competenciaseEsperadas) {
        formList.contenido.competenciaseEsperadas = [];
      }

      if (!formList.contenido.competenciasEvaluadas) {
        formList.contenido.competenciasEvaluadas = [];
      }

      const competenciaseEsperadas =
        formList?.contenido?.competenciaseEsperadas.sort((a: any, b: any) => {
          return parseInt(a.posicion) - parseInt(b.posicion);
        });
      const competenciasEvaluadas =
        formList?.contenido?.competenciasEvaluadas.sort((a: any, b: any) => {
          return parseInt(a.posicion) - parseInt(b.posicion);
        });
      const competenciasUnicas = new Set<string>();

      [...competenciasEvaluadas, ...competenciaseEsperadas].forEach(
        (item: any) => {
          competenciasUnicas.add(item.competenciaLaboral);
        }
      );
      const uniqueLabels: string[] = Array.from(competenciasUnicas);
      const esperadoArray: number[] = [];
      const alcanzadoArray: number[] = [];

      uniqueLabels?.forEach((label: any) => {
        const competenciaEsperada = competenciaseEsperadas.find(
          (item: any) => item.competenciaLaboral === label
        );
        const competenciaEvaluada = competenciasEvaluadas.find(
          (item: any) => item.competenciaLaboral === label
        );

        esperadoArray.push(
          competenciaEsperada ? parseFloat(competenciaEsperada.valor) : 0
        );
        alcanzadoArray.push(
          competenciaEvaluada ? parseFloat(competenciaEvaluada.valor) : 0
        );
      });

      setLabels(uniqueLabels.slice(0, 9));
      setEsperado(esperadoArray.slice(0, 9));
      setAlcanzado(alcanzadoArray.slice(0, 9));
    }
  }, [formList]);

  useEffect(() => {
    let timeoutId: number | undefined;

    if (labels.length > 0) {
      timeoutId = window.setTimeout(() => {
        downloadChartImage();
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [labels.length > 0]);

  const downloadChartImage = async () => {
    if (chartJs.current) {
      await html2canvas(chartJs.current).then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png");
        setImage64(dataUrl);
      });
    }
  };

  const data = {
    labels:
      labels?.map((element: any) => {
        if (element.includes(" ")) {
          let palabras = element.split(" ");
          return palabras;
        } else {
          return element;
        }
      }) ?? [],
    datasets: [
      {
        label: "Puntaje alcanzado",
        data: alcanzado ?? [],
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "#0065DD",
        pointBackgroundColor: "#0065DD",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#0065DD",
        pointRadius: 4,
      },
      {
        label: "Puntaje esperado",
        data: esperado ?? [],
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "#E33D00",
        pointBackgroundColor: "#E33D00",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#E33D00",
        pointRadius: 4,
      },
    ],
    options: {
      animation: false,
      // Otras opciones de configuración para el gráfico de radar...
    },
  };

  const RadarOptions = {
    responsive: true,
    options: {
      animation: false,
      // Otras opciones de configuración para el gráfico de radar...
    },
    elements: {
      line: {
        borderWidth: 3,
        tension: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        textAlign: "center",
        labels: {
          color: "#404040",
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 12,
          },
        },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        grid: {
          color: "rgba(0, 101, 221, 1)",
          circular: true,
          lineWidth: 1,
        },
        border: { dash: [2, 2] },

        angleLines: {
          color: "rgba(0, 101, 221, 1)",
          lineWidth: 2,
        },
        pointLabels: {
          font: {
            size: 11,
            family: "Inter",
            align: "center",
          },
          color: "rgba(64, 64, 64, 1)",
        },
      },
    },

    maintainAspectRatio: false,
  };

  return (
    <>
      <div ref={chartJs} className="container-grafico-pdf">
        <div className="content-title">
          <label className="title-pdf">NIVEL DE COMPETENCIAS PRESENTADO</label>
        </div>
        <Radar data={data} options={RadarOptions} />
      </div>
    </>
  );
};

export default RadarChart;
