import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconDescargaMasiva from "../../../../ui/kit-svg/iconDescargaMasiva";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconEye_VerDocu from "../../../../ui/kit-svg/icon-contratos/IconEye_VerDocu";
import Icon_DeleteDocu from "../../../../ui/kit-svg/icon-contratos/Icon_DeleteDocu";
import { height } from "@mui/system";

interface IAreaDocumentacion {
  list?: any;
  rut?: string;
  personaId?: string;
}

export default (props: IAreaDocumentacion) => {
  const { list, rut, personaId } = props;
  const { token, getToken, listDocument, getDocument, userId, deleteBateria } =
    useContext(SeleccionContext);
  const [hidden, setHidden] = useState<boolean>(true);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    ""
  );

  useEffect(() => {
    if (token && rut) {
      getToken();
      getDocument({ personaDNI: rut, token });
    }
  }, [token]);

  useEffect(() => {
    if (listDocument && listDocument.length > 0) {
      // Encontrar el primer documento con enlace no nulo
      const firstDocumentWithLink = listDocument.find(
        (item: any) =>
          item.documentos.find(
            (documento: any) => documento.enlace !== null
          ) !== undefined
      );

      if (firstDocumentWithLink) {
        const documentWithLink = firstDocumentWithLink.documentos.find(
          (documento: any) => documento.enlace !== null
        );

        if (documentWithLink) {
          setSelectedDocumentId(documentWithLink.documentoId);
        } else {
          // Si no se encuentra ningún documento con enlace, establecer un documentoId por defecto
          setSelectedDocumentId("");
        }
      }
    }
  }, [listDocument]);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };
  const handleViewDocument = (documentoId: string) => {
    setSelectedDocumentId(documentoId); // Actualizar el estado con el documentoId seleccionado
  };

  return (
    <>
      <div className={`area-table`} onClick={() => handleClickHidden()}>
        <span>ÁREA DOCUMENTACIÓN</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <div className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table">
          {listDocument.map((item: any) => (
            <>
              <div className="title">DOCUMENTOS {item.nombreSeccion}</div>

              {item.documentos.map((documento: any) => (
                <div className="content-table-row documento">
                  <span className="title-table-row dos">
                    {documento.nombreDocumento}
                  </span>
                  <div className="subtitle-table-row dos">
                    {item.nombreSeccion === "PREOCUPACIONAL" ? (
                      <button
                        onClick={() =>
                          deleteBateria({
                            objeto: { documentos: [documento.documentoId] },
                            token,
                            personaDNI: rut,
                            personaId: personaId,
                          })
                        }
                        className="button-plus"
                        disabled={documento.enlace !== null ? false : true}
                      >
                        <Icon_DeleteDocu
                          disabled={documento.enlace !== null ? false : true}
                          className={
                            documento.enlace !== null ? "" : "disabled"
                          }
                        />
                      </button>
                    ) : (
                      <button className="button-plus" disabled={true}>
                        <Icon_DeleteDocu
                          disabled={true}
                          className={"disabled"}
                        />
                      </button>
                    )}
                    <button
                      onClick={() => handleViewDocument(documento.documentoId)}
                      className="button-plus"
                      disabled={documento.enlace == null}
                    >
                      <IconEye_VerDocu
                        disabled={documento.enlace == null}
                        className={documento.enlace !== null ? "" : "disabled"}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
        <div className="project-table container-table ">
          <div className="title">DOCUMENTO</div>
        </div>

        <div
          className="col-12"
          style={{
            display: "flex",
            width: "100%",
            height: "300px",
            overflow: "hidden",
          }}
        >
          <iframe
            src={`http://pdf-qa.team-work.cl/Pdf/DocumentosCandidato?userid=${userId}&personadni=${rut}&glcid=${token}&documentoId=${selectedDocumentId}`} // ? `${selectedDocumentId}` : '198c77b8-7675-4362-9a0c-96ec4e45df1e'
            width="100%"
            height="400"
            title="Documentacion de personas"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};
