interface IIconEdit {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IIconEdit) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "27"}`}
        height={`${height ? height : "28"}`}
        viewBox="0 0 27 28"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path id="edit_square" d="M2.66384 27.5C1.94033 27.5 1.32074 27.2422 0.805078 26.7265C0.290291 26.2117 0.0328979 25.5926 0.0328979 24.8691V6.4525C0.0328979 5.72899 0.290291 5.1094 0.805078 4.59374C1.32074 4.07895 1.94033 3.82156 2.66384 3.82156H14.4044L11.7735 6.4525H2.66384V24.8691H21.0804V15.7266L23.7113 13.0956V24.8691C23.7113 25.5926 23.4539 26.2117 22.9392 26.7265C22.4235 27.2422 21.8039 27.5 21.0804 27.5H2.66384ZM17.3642 4.57795L19.2387 6.41961L10.5566 15.1017V16.9762H12.3983L21.1133 8.26127L22.9878 10.1029L13.5165 19.6072H7.92571V14.0164L17.3642 4.57795ZM22.9878 10.1029L17.3642 4.57795L20.6529 1.28928C21.1791 0.763094 21.8096 0.5 22.5445 0.5C23.2786 0.5 23.8977 0.763094 24.402 1.28928L26.2436 3.16382C26.7479 3.66809 27 4.28197 27 5.00548C27 5.72899 26.7479 6.34287 26.2436 6.84714L22.9878 10.1029Z" 
        fill="#48566B"/>
      </svg>
    );
  };
  