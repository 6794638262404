import "../../../../assets/css/filtro-generico.min.css"; 
import CloseIcon from "@mui/icons-material/Close"; 
import InputTextCustomizedFilter from "../../kit-mdtw/InputTextCustomizedFilter"; 

interface IInputFiltro {
  layer: string;
  typeFilter: "text" | "date" | "select" | "rut" | "number";
  onValueChange?: Function;
  onPropertyChange?: string | any;
  onPermaValues?: any;
}

export default (props: IInputFiltro) => {
  const { layer, typeFilter, onValueChange, onPermaValues, onPropertyChange } =
    props;

  let current = { ...onPropertyChange };

  const handleClickRemoveItem = (item: any) => {
    if (onPropertyChange && onValueChange && onPermaValues) {
      if (onPropertyChange[onPermaValues]?.includes(item)) {
        const current = { ...onPropertyChange };
        const position = current[onPermaValues].findIndex(
          (state: any) => state == item
        );
        current[onPermaValues].splice(position, 1);
        onValueChange(current);
      }
    }
  };

  return (
    <>
      <div className="section-filter">
        <span className="title">{layer}</span>
        {/* <div className="content-description-filter">
          <section>
            <span className="orden">Orden de A a Z</span>
            <IconOrdenAlfaAscendente />
          </section>
          <section>
            <span className="orden">Orden de Z a A</span>
            <IconOrdenAlfaDescenciente />
          </section>
        </div> */}
        <div className="content-input-filter">
          <InputTextCustomizedFilter
            placeholder="Buscar"
            typeFilter={typeFilter}
            onValueChange={onValueChange}
            onPropertyChange={onPropertyChange}
            onPermaValues={onPermaValues}
          />
          <div className="content-filter-aplicate">
            <div className="content-button-filter-aplicate">
              {current && current[onPermaValues]?.map((item: any) => {
                  return (
                    <button
                      onClick={() => handleClickRemoveItem(item)}
                      className="button-filter-aplicate"
                    >
                      {item}
                      <CloseIcon />
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
