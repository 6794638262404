import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropDownListCustomized from "../../DropDownListCustomized";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import InputTextCustomized from "../../InputTextCustomized";
import SeccionDetalleProceso from "./componentes/SeccionDetalleProceso";

interface IAreaCargoPostula {
  list?: any[];
  formCandidate: any;
  setFormCandidate: Function;
  requerido?: boolean;
  messageErrorProcess: string | undefined;
  hiddenSection?: boolean | undefined; 
}

export default (props: IAreaCargoPostula) => {
  const {
    list,
    formCandidate,
    setFormCandidate,
    requerido,
    messageErrorProcess, 
    hiddenSection
  } = props;
  const { token, listGroup, getProcesos, listProcesos } =
    useContext(SeleccionContext);
  const [hidden, setHidden] = useState<boolean>(hiddenSection ?? true);
  const [selectGrupo, setSelectGrupo] = useState<IOptionDropDownList[]>([]);
  const [selectProceso, setSelectProceso] = useState<IOptionDropDownList[]>([]);

  useEffect(() => {
    if (listGroup.length > 0) {
      const grupos = listGroup.map((item: any) => {
        const { cargoGroupId, cargoGroupName } = item;
        return {
          code: cargoGroupId,
          name: cargoGroupName,
        };
      });
      setSelectGrupo(grupos);
    }
  }, [listGroup]);

  useEffect(() => {
    if (formCandidate.grupo?.value?.code && listGroup) {
      const arrayList = listGroup
        .filter(
          ({ cargoGroupId }: any) =>
            cargoGroupId == formCandidate.grupo?.value?.code
        )
        .flat()
        .map(({ busquedas }: any) => busquedas)
        .flat()
        .map((item: any) => {
          const { busquedaId, cargoMod } = item;
          return {
            code: busquedaId,
            name: busquedaId + " - " + cargoMod,
          };
        });
      setSelectProceso(arrayList);
    }
  }, [formCandidate.grupo?.value?.code]);

  useEffect(() => {
    if (token && formCandidate.proceso?.value?.code) {
      const procesos = [formCandidate.proceso.value.code] ?? [];
      getProcesos({ token, procesos });
    }
  }, [formCandidate.proceso?.value?.code]);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">CARGO AL QUE POSTULA</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="process-content">
          <div className="w-30">
            <DropDownListCustomized
              list={selectGrupo}
              layer="Grupo"
              placeholder="Seleccione"
              errorPosition="down"
              onValueChange={setFormCandidate}
              onPermaValues={formCandidate}
              onPropertyChange="grupo"
              defaultValue={formCandidate.grupo?.value ?? ""}
              requerido={formCandidate.grupo?.value?.code ? false : true}
              datoRequerido={requerido}
            />
          </div>
          <div className="w-50">
            <DropDownListCustomized
              list={selectProceso}
              layer="Proceso"
              placeholder="83648 - FAL ADMINISTRATIVO TRL NOCHE C"
              errorPosition="down"
              onValueChange={setFormCandidate}
              onPermaValues={formCandidate}
              onPropertyChange="proceso"
              defaultValue={formCandidate.proceso?.value ?? ""}
              requerido={formCandidate.proceso?.value?.code ? false : true}
              datoRequerido={requerido}
            />
          </div>
          <div className="content-text-modal-update w-20">
            <InputTextCustomized
              layer="Turno"
              placeholder="Ingrese"
              required={false}
              errorPosition="down"
              onValueChange={setFormCandidate}
              onPermaValues={formCandidate}
              onPropertyChange="turno"
              defaultValue={formCandidate.turno?.value ?? ""}
            />
          </div>
        </div>

        {messageErrorProcess && (
          <>
            <label className="advertencia-label-proceso">
              {messageErrorProcess}
            </label>
          </>
        )}

        <label className="advertencia-label">
          *Al asignar proceso al registro se moverá a la lista de candidatos con
          proceso activo.
        </label>

        {listProcesos &&
          listProcesos.map((item: any) => {
            return (
              <SeccionDetalleProceso
                list={!formCandidate.proceso?.value?.code ? [] : item}
                listaMasiva={false}
                soloDetalle={false}
              />
            );
          })}
      </section>
    </>
  );
};
