import { useContext, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../../../../ui/kit-mdtw/InputTextCustomized";
import InputRutCustomized from "../../../../../ui/kit-mdtw/InputRutCustomized";
import InputDateCustomized from "../../../../../ui/kit-mdtw/InputDateCustomized";
import DropDownListCustomized from "../../../../../ui/kit-mdtw/DropDownListCustomized";
import LayerAgeCustomized from "../../../../../ui/kit-mdtw/LayerAgeCustomized";
import Hijos from "../../../../../ui/kit-mdtw/modals/modalUpdateCandidate/componentes/Hijos";
import InputNumberCustomized from "../../../../../ui/kit-mdtw/InputNumberCustomized";
import InputEdadHijoCustomized from "../../../../../ui/kit-mdtw/InputEdadHijoCustomized";
import InputTextAreaCustomized from "../../../../../ui/kit-mdtw/InputTextAreaCustomized";
import { InputTextarea } from "primereact/inputtextarea";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";

interface ICompetencias {
  list?: any[];
  rut?: string;
  formCandidate?: any;
  setFormCandidate?: Function;
  requerido?: boolean;
  contraerModal?: boolean;
  token?: string;
  busPerId?: string;
  setObjsendPsicolaboralAdicional?: any;
  setObjsendPsicolaboralConclusion?: any;
}

export default (props: ICompetencias) => {
  const {
    list,
    rut,
    formCandidate,
    setFormCandidate,
    requerido,
    contraerModal,
    token,
    busPerId,
    setObjsendPsicolaboralAdicional,
    setObjsendPsicolaboralConclusion,
  } = props;

  const {
    getEvaluacionPsicolaboral,
    listEvaluacionPsicolaboral,
    getListasPerfil,
    listConclusiones,
    getInformacionPsicolaboral,
    infoAntecedentes,
  } = useContext(SeleccionContext);

  const [selectDataConclusiones, setSelectDataConclusiones] = useState<any>({
    conclusiones: { code: undefined, name: undefined },
  });
  const [hidden, setHidden] = useState<boolean>(false);
  const dataArray = [
    {
      title: "Motivación al cargo",
      required: false,
      key: "motivacion",
      message:"",
      maxLength:500
    },
    
    {
      title: "Fortalezas",
      required: true,
      key: "fortalezas",
      message:"Separe las fortalezas, con (ENTER)",
      maxLength:400
    },
    {
      title: "Oportunidades de mejora",
      required: true,
      key: "oportunidades",
      message:"Separe las Oportunidades de mejora, con (ENTER)",
      maxLength:400
    },
    {
      title: "Síntesis y sugerencias:",
      required: false,
      key: "sintesis",
      message:"",
      maxLength:600
    },
  ];

  const [inputObjetos, setInputObjetos] = useState([""]);

  useEffect(() => {
    getListasPerfil({ token });
  }, [selectDataConclusiones]);

  useEffect(() => {
    if (token && busPerId) getInformacionPsicolaboral({ token, busPerId });
  }, []);

  useEffect(() => {
    if (infoAntecedentes && infoAntecedentes.evaluacion) {
      if (infoAntecedentes?.evaluacion) {
        setSelectDataConclusiones({
          conclusiones: {
            value: {
              code: infoAntecedentes.evaluacion.conclusionInformeId,
              name: infoAntecedentes.evaluacion.conclusionInforme,
            },
          },
        });
      }
    }
    if (infoAntecedentes && infoAntecedentes.adicional) {
      setInputObjetos(
        dataArray.map(
          (data) =>
            (infoAntecedentes?.adicional &&
              infoAntecedentes?.adicional[data.key]) ||
            ""
        )
      );
    }

  }, [infoAntecedentes]);

  //const maxLength = 500;
  const isRequired = true;

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  const handleChange = (index: number, value: string, maxLength:number) => {
    if (value.length <= maxLength) {
      const newInputObjetos = [...inputObjetos];
      newInputObjetos[index] = value; // Actualiza solo el valor en el índice
      setInputObjetos(newInputObjetos);
    }
  };

  const objetoSendInfoAdicional = useMemo(() => {
    return {
      motivacion: inputObjetos[0],
      fortalezas: inputObjetos[1],
      oportunidades: inputObjetos[2],
      sintesis: inputObjetos[3],
    };
  }, [inputObjetos]);

  useEffect(() => {
    if (setObjsendPsicolaboralAdicional && setObjsendPsicolaboralConclusion) {
      setObjsendPsicolaboralAdicional({ ...objetoSendInfoAdicional });
      setObjsendPsicolaboralConclusion(selectDataConclusiones);
    }
  }, [objetoSendInfoAdicional, selectDataConclusiones]);

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">EVALUACIÓN DESEMPEÑO</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}

      <div>
        {dataArray.map((data, index) => (
          <div
            className={`project-table pb-2 ${hidden ? "disabled" : ""}`}
            key={index}
          >
            <div className="col-12">
              <div className="titleInput">
                {data.title}
                {data.required ? " (*)" : ""}
              </div>
              <InputTextarea
                className="textInput"
                value={inputObjetos[index]}
                onChange={(e) => handleChange(index, e.target.value, data.maxLength)}
                autoResize
                rows={2}
                cols={15}
                placeholder="Ingresa"
                maxLength={data.maxLength}
                required={data.required}
              />
              <div style={{ marginTop: "8px", float: "right" }}>
                {inputObjetos[index] ? inputObjetos[index].length : 0}/
                {data.maxLength}
              </div>
              {/*<span className="mesageDown">{data.message}</span>*/}
            </div>
          </div>
        ))}

      </div>

      <div className={`project-table pt-2 pb-4 ${hidden ? "disabled" : ""}`}>
        <div
          className={`content-text-modal-update ${
            contraerModal ? " w-100 " : "w-45 "
          }`}
        >
          <DropDownListCustomized
            layer="Conclusión Evaluación"
            placeholder="Ingrese"
            required={true}
            errorPosition="down"
            onValueChange={setSelectDataConclusiones}
            onPermaValues={selectDataConclusiones}
            onPropertyChange="conclusiones"
            defaultValue={{
              code: selectDataConclusiones?.conclusiones?.value?.code,
              name: selectDataConclusiones?.conclusiones?.value?.name,
            }}
            datoRequerido={requerido}
            list={listConclusiones}
          />
        </div>
      </div>
    </>
  );
};
