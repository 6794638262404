import { IIconTw } from "../../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icono/generales1" clip-path="url(#clip0_82_30265)">
        <path
          id="Vector"
          d="M10.0938 4.25C10.0938 3.54552 9.8139 2.86989 9.31575 2.37175C8.81761 1.8736 8.14198 1.59375 7.4375 1.59375C6.73302 1.59375 6.05739 1.8736 5.55925 2.37175C5.0611 2.86989 4.78125 3.54552 4.78125 4.25C4.78125 4.95448 5.0611 5.63011 5.55925 6.12825C6.05739 6.6264 6.73302 6.90625 7.4375 6.90625C8.14198 6.90625 8.81761 6.6264 9.31575 6.12825C9.8139 5.63011 10.0938 4.95448 10.0938 4.25ZM3.1875 4.25C3.1875 3.12283 3.63527 2.04183 4.4323 1.2448C5.22933 0.447767 6.31033 0 7.4375 0C8.56467 0 9.64567 0.447767 10.4427 1.2448C11.2397 2.04183 11.6875 3.12283 11.6875 4.25C11.6875 5.37717 11.2397 6.45817 10.4427 7.2552C9.64567 8.05223 8.56467 8.5 7.4375 8.5C6.31033 8.5 5.22933 8.05223 4.4323 7.2552C3.63527 6.45817 3.1875 5.37717 3.1875 4.25ZM1.63691 15.4062H13.2381C12.9426 13.3045 11.1363 11.6875 8.95488 11.6875H5.92012C3.73867 11.6875 1.93242 13.3045 1.63691 15.4062ZM0 16.0139C0 12.7434 2.64961 10.0938 5.92012 10.0938H8.95488C12.2254 10.0938 14.875 12.7434 14.875 16.0139C14.875 16.5584 14.4334 17 13.8889 17H0.986133C0.441602 17 0 16.5584 0 16.0139Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_82_30265">
          <rect width="14.875" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
