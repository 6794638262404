interface IIconAsignar {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconAsignar) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Vector">
        <path
          d="M14.4991 11.4592H10.5003V7.46039L11.8999 7.46039V9.05991L11.9873 8.97243C12.8204 8.13935 13.2349 7.13766 13.2309 5.96734C13.2266 4.7967 12.8037 3.79067 11.9623 2.94926C11.7457 2.73266 11.5083 2.54521 11.25 2.38693C10.9918 2.22864 10.7127 2.09952 10.4128 1.99955V0.5C10.9126 0.633293 11.375 0.820735 11.7999 1.06233C12.2248 1.30392 12.6121 1.59967 12.962 1.94956C14.0784 3.06589 14.6407 4.40298 14.649 5.96085C14.6573 7.51871 14.1033 8.8558 12.987 9.97213L12.8995 10.0596H14.4991L14.4991 11.4592Z"
        />
        <path
          d="M8.55883 7.13292C8.55883 6.56011 8.33128 6.01075 7.92624 5.60571C7.5212 5.20067 6.97184 4.97312 6.39902 4.97312C5.82621 4.97312 5.27685 5.20067 4.87181 5.60571C4.46677 6.01075 4.23922 6.56011 4.23922 7.13292C4.23922 7.70574 4.46677 8.2551 4.87181 8.66014C5.27685 9.06518 5.82621 9.29273 6.39902 9.29273C6.97184 9.29273 7.5212 9.06518 7.92624 8.66014C8.33128 8.2551 8.55883 7.70574 8.55883 7.13292ZM2.94333 7.13292C2.94333 6.21642 3.30741 5.33745 3.95548 4.68938C4.60355 4.04131 5.48252 3.67723 6.39902 3.67723C7.31553 3.67723 8.1945 4.04131 8.84257 4.68938C9.49064 5.33745 9.85472 6.21642 9.85472 7.13292C9.85472 8.04943 9.49064 8.9284 8.84257 9.57647C8.1945 10.2245 7.31553 10.5886 6.39902 10.5886C5.48252 10.5886 4.60355 10.2245 3.95548 9.57647C3.30741 8.9284 2.94333 8.04943 2.94333 7.13292ZM1.68254 16.2041H11.1155C10.8752 14.4952 9.40656 13.1804 7.63281 13.1804H5.16523C3.39149 13.1804 1.92282 14.4952 1.68254 16.2041ZM0.351562 16.6982C0.351562 14.0389 2.50597 11.8845 5.16523 11.8845H7.63281C10.2921 11.8845 12.4465 14.0389 12.4465 16.6982C12.4465 17.1409 12.0874 17.5 11.6447 17.5H1.15339C0.710631 17.5 0.351562 17.1409 0.351562 16.6982Z"
        />
      </g>
    </svg>
  );
};
