import { ISetRedux } from "../../interfaces/IValidationKam";

export const setCountAssign = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetCountAssign",
    payload,
  });
};

export const setExclusiones = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetExclusiones",
    payload,
  });
};

export const setListaCandidatoConfirmado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoConfirmado",
    payload,
  });
};

export const setListaCandidatoPendiente = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPendiente",
    payload,
  });
};

export const setListaCandidatoPendienteValidacion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPendienteValidacion",
    payload,
  });
};

export const setListaCandidatoValidado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoValidado",
    payload,
  });
};

export const setListaCandidatoPendienteContrato = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPendienteContrato",
    payload,
  });
};

export const setListaCandidatoEnviadoContrato = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoEnviadoContrato",
    payload,
  });
};

/*export const setDatosContrato = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetDatosContrato",
    payload,
  });
};*/

export const setListaDataContrato = (props: ISetRedux) => {
  const {dispatch,  payload } = props;
  dispatch({
    type: "SetListaDataContrato",
    payload,
  });
};


