import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import { useParams } from "react-router-dom";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";
import IconCheckfila from "../../../../ui/kit-svg/IconCheckfila";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputText } from "primereact/inputtext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";

interface IDetener {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  onChange?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
  rut?: string;
  busPerId?: string;
  idCliente?: string;
  onCloseModalFicha?: Function;
  masiveRenovObjeto?: any;
  renovar?: boolean;
  masiveNewContratoObjeto?: any;
  nuevoContrato?: boolean;
  openOtherModal?: boolean;
  masive?: boolean;
}

export default (props: IDetener) => {
  const {
    modalType,
    title,
    subTitle,
    onChangeBack,
    onChangeGo,
    onChange,
    buttonBack,
    buttonGo,
    list,
    rut,
    onCloseModalFicha,
    masiveRenovObjeto,
    renovar,
    masiveNewContratoObjeto,
    nuevoContrato,
    openOtherModal,
  } = props;
  const {
    getListCandidateEnviadoContrato,
    listCandidatoPendienteContrato,
    listCandidatoEnviadoContrato,
    detenerSolicitudContrato,
    solicitudFueraPlazo,
    getSolicitudContrato,
  } = useContext(ValidationKamContext);

  const { solicitudRenovacion, detenerRenovacion, newContrato, token, getToken } = useContext(
    OperacionContratacionContext
  );

  const { idCliente } = useParams<Params>();
  const [checkAplyAll, setCheckAplyAll] = useState<boolean>(false);
  const [inputForAll, setInputForAll] = useState<any>({
    text: { value: undefined },
  });
  const [inputForInd, setInputForInd] = useState<any>({
    text: { value: undefined },
  });

  const [objetoData, setObjtoData] = useState<any>("");
  const objetoSendFiltro = {};

  useEffect(() => {
    if (token) {
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
    }
  }, [token]);

  useEffect(() => {
    if (token && rut) {
      listCandidatoEnviadoContrato;
    }
  }, [token, rut]);

  useEffect(() => {
    if (list) {
      const mappedDataObjects = list.map((item: IListaCandidatos) => {
        const { busPerId, division } = item;

        if (inputForAll.text.value == undefined) {
          setCheckAplyAll(false);
        }

        let motivoSolicitudValue = "";
        if (inputForAll.text.value !== undefined && checkAplyAll) {
          motivoSolicitudValue = inputForAll.text.value;
        } else {
          motivoSolicitudValue = inputForInd[busPerId] ?? "";
        }

        return {
          busPerId:
            modalType === "Renovacion" || modalType === "DetenerRenov"
              ? busPerId + "-" + division
              : busPerId,
          motivo: motivoSolicitudValue,
        };
      });
      setObjtoData(mappedDataObjects);
    }
  }, [list, inputForAll.text.value, checkAplyAll, onChange, inputForInd]);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCloseModalFicha = () => {
    typeof onCloseModalFicha == "function" && onCloseModalFicha();
  };

  const handleClickCheck = () => {
    setCheckAplyAll(checkAplyAll ? false : true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setCheckAplyAll(false);

    if (value === "") {
      setInputForInd((prevInput: any) => ({ ...prevInput, [name]: "" }));
    } else {
      setInputForInd((prevInput: any) => ({ ...prevInput, [name]: value }));
    }
  };

  const handleClickDetener = (objetoData: any) => {
    detenerSolicitudContrato({
      objetoData,
      token,
      idCliente,
      objetoSendFiltro,
    });
    handleClickCloseModal();
  };

  const handleClickDetenerRenov = (objetoData: any) => {
    const nuevoObjetoData = objetoData.map((elemento: any) => ({
      ficha: elemento.busPerId,
      motivo: elemento.motivo,
    }));
    detenerRenovacion({
      objetoData: nuevoObjetoData,
      token,
      objetoSendFiltro,
    });
    handleClickCloseModal();
  };

  const busqValidInputDet = () => {
    let validInput = 0;
    objetoData &&
      objetoData.forEach((value: any) =>
        value.motivo == "" ? validInput++ : validInput
      );
    return objetoData.length > 0 && validInput == 0;
  };

  const handleClickFueraPlazo = (objetoData: any) => {
    const property =
      modalType === "FueraPlazoIndividualRenov" ||
      nuevoContrato ||
      openOtherModal
        ? "ficha"
        : "busPerId";
    const objetoReestructurado = objetoData.map(
      (item: { busPerId: any[]; motivo: string }) => ({
        [property]:
          modalType === "FueraPlazoIndividual"
            ? objetoData[0].busPerId[0]
            : item.busPerId,
        motivo: item.motivo.trim(),
      })
    );

    solicitudFueraPlazo({
      objetoReestructurado,
      token,
      idCliente,
      objetoSendFiltro,
    });

    if (modalType === "FueraPlazoIndividualRenov") {
      solicitudRenovacion({
        token,
        objetoForRenovacion: renovar ? masiveRenovObjeto : objetoReestructurado,
        objetoSendFiltro,
      });
    } else if (nuevoContrato) {
      newContrato({
        token,
        objetoData: masiveNewContratoObjeto,
        objetoSendFiltro,
      });
    } else if (openOtherModal) {
      typeof onChangeGo === "function" && onChangeGo();
    } else {
      const busPerId = listCandidatoPendienteContrato.map((item: any) => {
        return item.busPerId;
      });

      getSolicitudContrato({
        token,
        idCliente,
        busPerId:
          modalType === "FueraPlazoIndividual"
            ? objetoData[0].busPerId
            : busPerId,
        objetoSendFiltro,
      });
    }
    handleClickCloseModal();
    handleClickCloseModalFicha();
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo ">
        <div className="modal-content-validatekam-Mo w-75">
          <div className="modal-title-Mo">
            <span
              className="modal-close"
              onClick={() =>
                typeof onChangeBack == "function" && onChangeBack()
              }
            >
              <CloseIcon />
            </span>
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo px-5 md:text-left">
            <span>{subTitle}</span>
          </div>

          <div className="col-12 md:flex" style={{ alignItems: "center" }}>
            <div
              className="col-12 md:col-8 md:mx-3 md:pl-1"
              style={{ float: "left" }}
            >
              <InputTextCustomized
                className="p-0 "
                required={undefined}
                layer={"Motivo de Solicitud"}
                placeholder={""}
                onValueChange={setInputForAll}
                onPermaValues={inputForAll}
                onPropertyChange="text"
              />
            </div>

            <div
              className="col-12 md:col-4 align-items-center flex"
              style={{ float: "left" }}
            >
              {checkAplyAll ? (
                <span
                  onClick={() => {
                    if (inputForAll.text.value !== undefined) {
                      handleClickCheck();
                    }
                  }}
                >
                  <IconCheckfila className="md:ml-0 md:mt-5 pl-1 mr-3 classSvg" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    if (inputForAll.text.value !== undefined) {
                      handleClickCheck();
                    }
                  }}
                >
                  <CheckBoxOutlineBlankIcon className="md:ml-0 md:mt-5 classSvg" />
                </span>
              )}

              <span className="md:mt-5">Asignar a todos</span>
            </div>
          </div>

          {list &&
            list.map((item: IListaCandidatos) => {
              const {
                busPerId,
                areaNegocioCod,
                ficha,
                personaDni,
                primerNombre,
                primerApellido,
                division,
                historial,
                tipoDocumentoValue,
                causal,
                causalLetra,
                letra,
              } = item;

              return (
                <div className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5">
                  <div className="col-12 md:col-8 m-0 p-0 border-solid border-1 borderColorGrey">
                    <div className="row flex flex-column md:flex-row">
                      <div
                        className={`col-12 ${
                          modalType === "Renovacion" ? "md:col-2" : "md:col-2"
                        } p-0 mx-0`}
                      >
                        <div
                          className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                        ${modalType === "Renovacion" ? "headerHeight" : ""}`}
                        >
                          {modalType === "Renovacion" ? "Nombre" : "Usuario"}
                        </div>
                        <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorGrey textNormal contentHeight">
                          {primerNombre + "  " + primerApellido}
                        </div>
                      </div>

                      {modalType === "Renovacion" ||
                      modalType === "DetenerRenov" ? (
                        <>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 md:col-12 bg-headerGrey text-center border-bottom-2 borderColorGrey responsiveDivDetener
                        ${modalType === "Renovacion" ? "headerHeight" : ""}`}
                            >
                              Ficha
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {ficha ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          ${modalType === "Renovacion" ? "headerHeight" : ""}`}
                            >
                              División TW
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {division ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-3 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-1 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          ${modalType === "Renovacion" ? "headerHeight" : ""}`}
                            >
                              Área de Negocios
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {areaNegocioCod ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-3 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          ${modalType === "Renovacion" ? "headerHeight" : ""}`}
                            >
                              Causal Contrato
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {letra ?? "-"}
                            </div>
                          </div>
                        </>
                      ) : modalType !== "Detener" ? (
                        <>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div className="col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                              {modalType === "FueraPlazoIndividual" ||
                              modalType === "FueraPlazo"
                                ? "Rut"
                                : "Ficha"}
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {(modalType === "FueraPlazoIndividual" ||
                              modalType === "FueraPlazo"
                                ? personaDni
                                : ficha) ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          `}
                            >
                              División TW
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {division ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-1 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          `}
                            >
                              Área de Negocios
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {areaNegocioCod ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          `}
                            >
                              Causal Contrato
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {modalType === "FueraPlazoIndividual" ||
                              modalType === "FueraPlazoMasive" ||
                              modalType === "FueraPlazo" ||
                              modalType === "FueraPlazoIndividualNewCont"
                                ? causal
                                : letra ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener
                          `}
                            >
                              Tipo Solicitud
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {tipoDocumentoValue ?? "-"}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div className="col-6 md:col-12 bg-headerGrey text-center border-bottom-2 borderColorGrey responsiveDivDetener">
                              Fecha Solicitud
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener contentHeight">
                              {formatDateToDDMMAAAA({
                                date: historial,
                                format: "/",
                              }) ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-3 p-0 mx-0">
                            <div className="col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                              Tipo Contrato
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener">
                              {tipoDocumentoValue ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-1 p-0 mx-0">
                            <div
                              className="col-6 border-bottom-2 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener"
                              style={{ paddingRight: "16px" }}
                            >
                              Causal Contrato
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener contentHeight">
                              {causalLetra ?? "-"}
                            </div>
                          </div>
                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-1 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener`}
                            >
                              Área de Negocios
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {areaNegocioCod ?? "-"}
                            </div>
                          </div>

                          <div className="col-12 md:col-2 p-0 mx-0">
                            <div
                              className={`col-6 md:col-12 bg-headerGrey text-center border-bottom-2 borderColorGrey responsiveDivDetener `}
                            >
                              Ficha
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal contentHeight">
                              {ficha ?? "-"}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-4 m-0 md:px-4 p-0">
                    <div className="row">
                      <div className="col-12 mx-0 p-0">
                        <div
                          className={`col-6 md:border-solid md:border-1 md:col-12 bg-headerBlue text-center borderColorGrey responsiveDivDetener
                       headerHeight`}
                        >
                          Motivo Solicitud
                        </div>
                        <div className="col-6 md:col-12 p-0 text-center borderColorGrey responsiveDivDetener">
                          <InputText
                            id={`arrMotivo-${busPerId}`}
                            type="text"
                            className="col-12 border-noround text-center"
                            style={{
                              fontSize: "14px",
                              height: "56px",
                            }}
                            value={
                              checkAplyAll &&
                              inputForAll.text.value !== undefined
                                ? inputForAll.text.value
                                : inputForInd[busPerId] || ""
                            }
                            onChange={handleInputChange} // Añadimos el event handler
                            name={busPerId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {modalType === "FueraPlazo" ||
          modalType === "FueraPlazoIndividual" ? (
            <>
              <div className="modal-message-Mo start">
                <p className="textError" style={{ float: "none" }}>
                  El envío de esta solicitud queda sujeto a la aprobación de la
                  jefatura correspondiente, pudiendo esta ser aprobada o
                  rechazada, lo que se le informará oportunamente
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="modal-message-Mo">
                <p className="modal-message-Mo">
                  Si selecciona detener, los datos del candidato volverán a la
                  lista de pendientes.
                </p>
              </div>
            </>
          )}

          <div className="modal-button-content modal-button-content-in-modal center">
            <>
              <button
                className={`modal-button cancel`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={handleClickCloseModal}
              >
                {buttonBack ? buttonBack : "Cancelar"}
              </button>
              <button
                className={`modal-button ${
                  busqValidInputDet() ||
                  (inputForAll.text.value !== undefined && checkAplyAll)
                    ? "delete"
                    : "disabled"
                }`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={() =>
                  modalType === "Detener"
                    ? handleClickDetener(objetoData)
                    : modalType === "DetenerRenov"
                    ? handleClickDetenerRenov(objetoData)
                    : handleClickFueraPlazo(objetoData)
                }
                disabled={
                  busqValidInputDet() || inputForAll.text.value !== undefined
                    ? false
                    : true
                }
              >
                {" "}
                {modalType === "Detener" ||
                modalType === "Renovacion" ||
                modalType === "DetenerRenov"
                  ? buttonGo
                    ? buttonGo
                    : "Detener"
                  : buttonGo
                  ? buttonGo
                  : "Solicitar aprobación"}
              </button>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
