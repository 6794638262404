import React, { useEffect, useState } from "react";
import api from "../services/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

interface ModalChangeIdProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisible: boolean;
  candidate: any;
  group: any;
  //closeFullModal: (flag:any) => void;
}

const ModalChangeId: React.FC<ModalChangeIdProps> = ({
  token,
  isModalVisible,
  onClose,
  onCloseSave,
  candidate,
  group,
}) => {
  const [positions, setPositions] = useState<any[]>([]);
  const [position, setPosition] = useState<any>("");

  const searchPositions = () => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId.concat(" - ").concat(busqueda.cargoNombre),
        };
        newArr = [...newArr, newObj];
      });
      setPositions(newArr);
    }
  };

  const updateIdBusqueda = () => {
    if (candidate.busquedaId != position) {
      api
        .put(`busqueda-persona-busqueda`, null, {
          params: { busPerId: candidate.busPerId, busquedaId: position },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: any) => {
          onCloseSave();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      onClose();
    }
  };

  // useEffect

  useEffect(() => {
    searchPositions();
    if (candidate) {
      setPosition(candidate.busquedaId);
    }
  }, [candidate]);

  return (
    <div>
      <Dialog
        header={null}
        visible={isModalVisible}
        onHide={onClose}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "589px", height: "372px" }}
        closable={false}
        draggable={false}
        footer={" "}
      >
        <div className="mt-3 mb-4 mr-4 ml-5 ">
          <div className="mb-3">
            <span
              className="material-symbols-outlined"
              style={{ color: "#2A63D5" }}
            >
              published_with_changes
            </span>
            <label
              className="ml-3"
              style={{
                fontFamily: "Roboto",
                fontStyle: "",
                fontSize: "23px",
                lineHeight: "26px",
                alignItems: "center",
                fontWeight: "600",
                color: "#333333",
              }}
            >
              Cambiar candidato de ID
            </label>
          </div>
          <div>
            <p className="text-justify">
              La ficha del candidato se mantendrá toda la información
              recopilada, no se perderán los datos.
            </p>
            <br></br>
            {candidate ? (
              <>
                <p className="text-justify mb-5">
                  <b>
                    Por favor ingresa el cargo para {candidate.fullname} ({" "}
                    {candidate.personaDni} ){" "}
                  </b>
                </p>
              </>
            ) : (
              ""
            )}
          </div>
          {positions ? (
            <div className="flex flex-column">
              <Dropdown
                value={position}
                options={positions}
                onChange={(e) => setPosition(e.target.value)}
                optionLabel="label"
                placeholder="Seleccione ID y Cargo"
                appendTo="self"
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="flex justify-content-between mt-6 mr-4 ml-5 ">
          <a
            className="cursor-pointer"
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "14px",
              lineHeight: "16px",
              alignItems: "center",
              fontWeight: "600",
              color: "#2A63D5",
              textDecoration: "underline",
            }}
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </a>
          <Button
            label="Mover"
            className="p-button-sucess"
            onClick={() => updateIdBusqueda()}
            style={{ width: "180px" }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ModalChangeId;
