import CloseIcon from "@mui/icons-material/Close";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import IconCausales from "../../../../ui/kit-svg/IconCausales";
import IconCausalSucursal from "../../../../ui/kit-svg/IconCausalSucursal";
import IconCausalFincion from "../../../../ui/kit-svg/IconCausalFincion";
import { Button } from "@mui/material";
import { TabPanel, TabView } from "primereact/tabview";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";
import InputDateCustomized from "../../../../ui/kit-mdtw/InputDateCustomized";
import InputTextAreaCustomized from "../../../../ui/kit-mdtw/InputTextAreaCustomized";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import InputRutCustomized from "../../../../ui/kit-mdtw/InputRutCustomized";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";

interface ICausales {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string[][];
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonGuarda?: string;
  buttonGo?: string;
  list?: any[];
  exclusiones?: any[];
  clearList?: Function;
  personas: any;
  onChangeGuarda: Function;
  setObjsendContrato?: any;
  setListBusperSolicitudContrato?: any;
  idCliente?: string;
}

export default (props: ICausales) => {
  const {
    modalStyle,
    modalType,
    onChangeBack,
    onChangeGo,
    buttonGuarda,
    onChangeGuarda,
    buttonGo,
    setObjsendContrato,
  } = props;
  const {
    //token,
    listaAreasNegCliente,
    listDivisiones,
    getListDatosContratos,
    listCausales,
    listMotivos,
    listSucursales,
    diasPorCausales,
    listMotivosFull,
    listCandidatoPendienteContrato,
    getListaAreasNegCliente,
  } = useContext(ValidationKamContext);

  const { token, getToken } =
    useContext(SeleccionContext);

  const { listDocumentosVigentes } = useContext(OperacionContratacionContext);

  const { getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  const [fechaTope, setFechaTope] = useState<any>([]);

  const [diasASumarDesdeCausal, setDiasASumarDesdeCausal] =
    useState<String>("");
  const [idCliente, setIdCliente] = useState<String>("");
  const [titleI, setTitleI] = useState("CAUSAL");
  const [valid, setValid] = useState<boolean>(true);

  useEffect(() => {
    if (token && listDocumentosVigentes && modalType === "contratacionNew") {
      getListDatosContratos({ token });
      getRegistroCandidato({
        personaRut: listDocumentosVigentes[0].personaDNI,
        token,
      });
      listaRegistroCandidato;
    } else {
      getListDatosContratos({ token });
      getRegistroCandidato({
        personaRut: listCandidatoPendienteContrato[0].personaDni,
        token,
      });
      listaRegistroCandidato;
    }
  }, [token]);

  useEffect(() => {
    if (token && idCliente) {
      getListaAreasNegCliente({ token, idCliente });
    }
  }, [token, idCliente]);

  const personaBusperIdList = listCandidatoPendienteContrato.map(
    (item: any) => item.busPerId
  );

  const [selectDataContrato, setSelectDataContrato] = useState<any>({
    division: { code: undefined, name: undefined },
    areaNegocio: { code: undefined, name: undefined },
    codCargo: { value: undefined },
    causal: { code: undefined, name: undefined },
    motivo: { code: undefined, name: undefined },
    sucursal: { code: undefined, name: undefined },
    nombre: { value: undefined },
    rut: { value: undefined },
    funciones: { undefined },
    fechaInicio: { value: undefined },
    fechaTermino: { value: undefined },
    horasTrabajadas: { value: undefined },
    candidatos: personaBusperIdList,
  });

  const [selectDataContratoStorage, setSelectDataContratoStorage] =
    useState<any>({});
  const [localStorageUpdatedCausal, setLocalStorageUpdatedCausal] =
    useState(false);

  useEffect(() => {
    if (token) {
      getListDatosContratos({ token });
    }
  }, [token]);

  useEffect(() => {
    if (selectDataContrato) {
      setObjsendContrato({ ...selectDataContrato });
      guardaStorage;
    }
    setIdCliente(listaRegistroCandidato.contratacion.clienteId);
  }, [selectDataContrato, listaRegistroCandidato]);

  useEffect(() => {
    if (
      selectDataContrato?.division?.value?.code === "TWEST" &&
      (selectDataContrato?.fechaTermino?.value == undefined ||
        selectDataContrato?.fechaTermino?.value == "")
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [
    selectDataContrato.causal,
    selectDataContrato.fechaInicio,
    selectDataContrato.fechaTermino,
  ]);

  useEffect(() => {
    if (localStorageUpdatedCausal) {
      const storedArray = localStorage.getItem("DatosContrato");
      if (storedArray) {
        const objetoRecuperado = JSON.parse(storedArray);
        setSelectDataContratoStorage(objetoRecuperado);
      }
      // Restablecer el estado intermedio para evitar ejecuciones continuas
      setLocalStorageUpdatedCausal(false);
    }
  }, [localStorageUpdatedCausal]);

  const guardaStorage = () => {
    const array = JSON.stringify(selectDataContrato);
    localStorage.setItem("DatosContrato", array);

    setLocalStorageUpdatedCausal(true);
  };

  useEffect(() => {
    if (listaRegistroCandidato && listSucursales) {
      const areaNegocioValue =
        modalType === "contratacionNew"
          ? {
              areaNegocio: {
                value: {
                  code:
                    listDocumentosVigentes[0].areaNegocioId === ""
                      ? selectDataContratoStorage?.areaNegocio?.value.code
                      : listDocumentosVigentes[0].areaNegocioId,
                  name: listaAreasNegCliente.find(
                    (item: any) =>
                      listDocumentosVigentes[0].areaNegocioId === item.code
                  )?.name,
                },
              },
            }
          : {};

      setSelectDataContrato({
        division: {
          value: {
            code:
              listaRegistroCandidato?.contratacion?.divisionId == ""
                ? selectDataContratoStorage?.division?.value.code
                : listaRegistroCandidato?.contratacion?.divisionId,
            name:
              listaRegistroCandidato?.contratacion?.division == ""
                ? selectDataContratoStorage?.division?.value.name
                : listaRegistroCandidato?.contratacion?.division,
          },
        },
        ...areaNegocioValue,

        codCargo: {
          value:
            listaRegistroCandidato?.contratacion?.codCargo == ""
              ? selectDataContratoStorage?.codCargo?.value
              : listaRegistroCandidato?.contratacion?.codCargo,
        },
        causal: {
          value: {
            code:
              listaRegistroCandidato?.contratacion?.causalId == ""
                ? selectDataContratoStorage?.causal?.value.code
                : listaRegistroCandidato?.contratacion?.causalId,
            name:
              listaRegistroCandidato?.contratacion?.causalId == ""
                ? selectDataContratoStorage?.causal?.value.name
                : listaRegistroCandidato?.contratacion?.causalId,
          },
        },
        motivo: { code: undefined, name: undefined },
        sucursal: {
          value: {
            code: listaRegistroCandidato?.contratacion?.SCITeamwork,
            name: listSucursales.find(
              (item: any) =>
                listaRegistroCandidato?.contratacion?.SCITeamwork === item.code
            )?.name,
          },
        },
        nombre: {
          value:
            selectDataContratoStorage?.nombre?.value == undefined
              ? listaRegistroCandidato?.contratacion?.nombre
              : selectDataContratoStorage?.nombre?.value,
        },
        rut: {
          value:
            selectDataContratoStorage?.rut?.value == undefined
              ? listaRegistroCandidato?.contratacion?.personaDNI
              : selectDataContratoStorage?.rut?.value,
        },
        funciones: { value: listaRegistroCandidato?.contratacion?.funciones },
        fechaInicio: {
          value:
            selectDataContratoStorage?.fechaInicio?.value == undefined
              ? formatDateToDDMMAAAA({
                  date: listaRegistroCandidato?.contratacion?.fechaInicio,
                  format: "/",
                })
              : selectDataContratoStorage?.fechaInicio?.value,
        },
        fechaTermino: {
          value:
            selectDataContratoStorage?.fechaTermino?.value == undefined
              ? formatDateToDDMMAAAA({
                  date: listaRegistroCandidato?.contratacion?.fechaTermino,
                  format: "/",
                }) ?? ""
              : selectDataContratoStorage?.fechaTermino?.value,
        },
        horasTrabajadas: {
          value:
            selectDataContratoStorage?.horasTrabajadas?.value == undefined
              ? listaRegistroCandidato?.contratacion?.horasTrabajadas
              : selectDataContratoStorage?.horasTrabajadas?.value,
        },
        candidatos: personaBusperIdList,
      });
    }
  }, [listaRegistroCandidato, listSucursales]);

  useEffect(() => {
    fechaIMasDiasCausal();
  }, [
    selectDataContrato.causal,
    selectDataContrato.fechaInicio.value,
    selectDataContrato.fechaTermino.value,
  ]);
  const fechaIMasDiasCausal = () => {
    if (diasPorCausales && selectDataContrato.causal.value) {
      const { name: dias } = diasPorCausales.find(
        (item: any) => selectDataContrato?.causal?.value.name === item.code
      ) ?? { name: 0 };

      setDiasASumarDesdeCausal(dias);
      setFechaTope("");
      if (selectDataContrato.fechaInicio.value && dias != "0" && dias != null) {
        const fechaIniSplit = selectDataContrato.fechaInicio.value.split("/");
        const fechaInicio = new Date(
          fechaIniSplit[2] + "/" + fechaIniSplit[1] + "/" + fechaIniSplit[0]
        );
        fechaInicio.setDate(fechaInicio.getDate() + (dias - 1));
        const dia = fechaInicio.getDate();
        const mes = fechaInicio.getMonth() + 1; // Los meses en JavaScript son indexados desde 0
        const year = fechaInicio.getFullYear();

        // Formatear los valores de día y mes a dos dígitos si es necesario
        const diaFormateado = dia < 10 ? `0${dia}` : dia;
        const mesFormateado = mes < 10 ? `0${mes}` : mes;

        const formattedDate = `${year}/${mesFormateado}/${diaFormateado}`;

        setFechaTope(formattedDate);
      }
    }
    validarFechaTermino();
  };

  const validarFechaTermino = () => {
    if (selectDataContrato.fechaTermino.value) {
      const fechaISplit = selectDataContrato.fechaInicio.value.split("/");
      const fechaInicio = new Date(
        fechaISplit[2] + "/" + fechaISplit[1] + "/" + fechaISplit[0]
      );

      const fechaTermSplit = selectDataContrato.fechaTermino.value.split("/");
      const fechaTerm = new Date(
        fechaTermSplit[2] + "/" + fechaTermSplit[1] + "/" + fechaTermSplit[0]
      );

      const dateTope = new Date(fechaTope);
      const dateEnd = new Date(fechaTerm);
      const dateIni = new Date(fechaInicio);
      let visa = false;
      if (listaRegistroCandidato?.personal?.candidato?.fechaVencimientoVisa) {
        const dateVisa = new Date(
          listaRegistroCandidato?.personal?.candidato?.fechaVencimientoVisa
        );
        visa = dateVisa != null && dateEnd > dateVisa;
      }

      return (
        (fechaTope != "" ? dateEnd > dateTope : false) || dateEnd < dateIni /*||
        visa*/
      );
    }
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo">
        <div className="modal-content-validatekam-Mo w-45">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title pb-0">
            <span>{titleI}</span>
          </div>

          <TabView>
            <TabPanel
              headerClassName="iconCausal"
              header={
                <div className="separate">
                  <Button
                    onClick={() => {
                      guardaStorage();
                      setTitleI("CAUSAL");
                    }}
                  >
                    <IconCausales />
                  </Button>
                  <span className="texButton"> Causal</span>
                </div>
              }
            >
              <div>
                <div className="content-text2 sm:col-4 col-12">
                  {modalType === "contratacionNew" ? (
                    <DropDownListCustomized
                      required={false}
                      layer={"División TW"}
                      placeholder={"Seleccione"}
                      disabled={false}
                      list={listDivisiones}
                      onValueChange={setSelectDataContrato}
                      onPermaValues={selectDataContrato}
                      onPropertyChange="division"
                      defaultValue={{
                        code: selectDataContrato.division.value?.code,
                        name: selectDataContrato.division.value?.name,
                      }}
                    />
                  ) : (
                    <InputTextCustomized
                      layer="División TW"
                      placeholder="Ingrese"
                      required={false}
                      errorPosition="down"
                      //onValueChange={setFormCandidate}
                      //onPermaValues={formCandidate}
                      //onPropertyChange="primerNombreCandidato"
                      defaultValue={
                        listaRegistroCandidato?.contratacion?.divisionId
                      }
                      disabled={modalType === "contratacionNew" ? false : true}
                    />
                  )}
                </div>
                <div className="content-text2 sm:col-4 col-12">
                  {modalType === "contratacionNew" ? (
                    <DropDownListCustomized
                      required={false}
                      layer={"Área de Negocio"}
                      placeholder={"Seleccione"}
                      disabled={false}
                      list={listaAreasNegCliente}
                      onValueChange={setSelectDataContrato}
                      onPermaValues={selectDataContrato}
                      onPropertyChange="areaNegocio"
                      defaultValue={{
                        code: selectDataContrato.areaNegocio.value?.code,
                        name: selectDataContrato.areaNegocio.value?.name,
                      }}
                    />
                  ) : (
                    <InputTextCustomized
                      layer="Área de Negocio"
                      placeholder="Ingrese"
                      required={false}
                      errorPosition="down"
                      //onValueChange={setFormCandidate}
                      //onPermaValues={formCandidate}
                      //onPropertyChange="primerNombreCandidato"
                      defaultValue={
                        listaRegistroCandidato?.contratacion?.areaNegocioCod
                      }
                      disabled={modalType === "contratacionNew" ? false : true}
                    />
                  )}
                </div>
                <div className="content-text2 sm:col-4 col-12">
                  <InputTextCustomized
                    layer="Cód. Cargo"
                    placeholder="Ingrese"
                    required={false}
                    errorPosition="down"
                    onValueChange={setSelectDataContrato}
                    onPermaValues={selectDataContrato}
                    onPropertyChange="codCargo"
                    defaultValue={
                      /*listaRegistroCandidato?.contratacion?.codCargo == ""
                        ? selectDataContratoStorage?.codCargo?.value
                        : listaRegistroCandidato?.contratacion?.codCargo*/
                      selectDataContratoStorage?.codCargo?.value == undefined
                        ? listaRegistroCandidato?.contratacion?.codCargo
                        : selectDataContratoStorage?.codCargo?.value
                    }
                    disabled={
                      listaRegistroCandidato?.contratacion?.codCargo == " "
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              <div className="content-text2 sm:col-6 col-12">
                <InputTextCustomized
                  layer="Tipo Documento"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  //onValueChange={setFormCandidate}
                  //onPermaValues={formCandidate}
                  //onPropertyChange="primerNombreCandidato"
                  defaultValue={
                    listaRegistroCandidato?.contratacion?.tipoDocumento
                  }
                  disabled={true}
                />
              </div>

              <div className="content-text2 sm:col-6 col-12">
                <InputTextCustomized
                  layer="Horas trabajadas"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  onPropertyChange="horasTrabajadas"
                  defaultValue={
                    //listaRegistroCandidato?.contratacion?.horasTrabajadas
                    selectDataContratoStorage?.horasTrabajadas?.value ==
                    undefined
                      ? listaRegistroCandidato?.contratacion?.horasTrabajadas
                      : selectDataContratoStorage?.horasTrabajadas?.value
                  }
                  disabled={modalType === "contratacionNew" ? false : true}
                />
              </div>
              {
                /*listaRegistroCandidato?.contratacion?.divisionId*/ selectDataContrato
                  ?.division?.value?.name === "TWEST" && (
                  <>
                    <div className="content-text2 md:col-6 col-12">
                      <DropDownListCustomized
                        list={listCausales}
                        layer="Causal"
                        placeholder="ingrese"
                        required={true}
                        errorPosition="down"
                        onValueChange={setSelectDataContrato}
                        onPermaValues={selectDataContrato}
                        onPropertyChange="causal"
                        defaultValue={{
                          code: selectDataContrato.causal.value?.code,
                          name: selectDataContrato.causal.value?.name,
                        }}
                      />
                    </div>

                    <div className="content-text2 md:col-6 col-12">
                      <DropDownListCustomized
                        list={listMotivos}
                        layer="Motivo Reemplazo"
                        placeholder="ingrese"
                        required={true}
                        errorPosition="down"
                        onValueChange={setSelectDataContrato}
                        onPermaValues={selectDataContrato}
                        onPropertyChange="motivo"
                        defaultValue={
                          selectDataContrato.causal.value
                            ? listMotivosFull
                              ? {
                                  code: listMotivosFull.find(
                                    (item: any) =>
                                      selectDataContrato?.causal?.value.code ===
                                      item.causalId
                                  )?.motivoId,
                                  name: listMotivosFull.find(
                                    (item: any) =>
                                      selectDataContrato?.causal?.value.code ===
                                      item.causalId
                                  )?.nombre,
                                }
                              : undefined
                            : listaRegistroCandidato?.contratacion?.causalId
                            ? {
                                code: listMotivosFull.find(
                                  (item: any) =>
                                    listaRegistroCandidato?.contratacion
                                      ?.causalId === item.causalId
                                )?.motivoId,
                                name: listMotivosFull.find(
                                  (item: any) =>
                                    listaRegistroCandidato?.contratacion
                                      ?.causalId === item.causalId
                                )?.nombre,
                              }
                            : undefined
                        }
                        disabled={
                          /*modalType === "contratacionNew" ? false :*/ true
                        }
                      />
                    </div>
                    {selectDataContrato?.causal?.value &&
                      (selectDataContrato?.causal?.value?.name === "C" ||
                        selectDataContrato?.causal?.value?.name === "A") && (
                        <>
                          <div
                            className={`content-text2 ${
                              selectDataContrato?.causal?.value?.name !== "C"
                                ? "md:col-6"
                                : ""
                            } col-12`}
                          >
                            <InputTextCustomized
                              layer={
                                "Nombre " +
                                (selectDataContrato?.causal?.value?.name === "C"
                                  ? "Proy"
                                  : "")
                              }
                              placeholder="Ingrese"
                              required={true}
                              errorPosition="down"
                              defaultValue={
                                selectDataContratoStorage?.nombre?.value ==
                                undefined
                                  ? listaRegistroCandidato?.contratacion?.nombre
                                  : selectDataContratoStorage?.nombre?.value
                              }
                              onValueChange={setSelectDataContrato}
                              onPermaValues={selectDataContrato}
                              onPropertyChange="nombre"
                            />
                          </div>
                          {selectDataContrato.causal.value &&
                          selectDataContrato.causal.value.name === "C" ? (
                            ""
                          ) : (
                            <div className="content-text2 md:col-6 col-12 ">
                              <InputRutCustomized
                                layer="Rut"
                                placeholder="Ingrese"
                                required={true}
                                errorPosition="down"
                                defaultValue={
                                  selectDataContratoStorage?.rut?.value ==
                                  undefined
                                    ? listaRegistroCandidato?.contratacion
                                        ?.personaDNI
                                    : selectDataContratoStorage?.rut?.value
                                }
                                onValueChange={setSelectDataContrato}
                                onPermaValues={selectDataContrato}
                                onPropertyChange="rut"
                                maxLength={12}
                              />
                            </div>
                          )}
                        </>
                      )}
                  </>
                )
              }
              <div className="content-text2 sm:col-6 col-12 dateStart">
                <InputDateCustomized
                  layer="Fecha Inicio"
                  placeholder="DD/MM/AAAA"
                  required={true}
                  maxLength={10}
                  errorPosition="down"
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  defaultValue={
                    selectDataContratoStorage?.fechaInicio?.value == undefined
                      ? formatDateToDDMMAAAA({
                          date: listaRegistroCandidato?.contratacion
                            ?.fechaInicio,
                          format: "/",
                        })
                      : selectDataContratoStorage?.fechaInicio?.value
                  }
                  onPropertyChange="fechaInicio"
                />
              </div>

              <div className="content-text2 sm:col-6 col-12">
                <InputDateCustomized
                  layer="Fecha Termino"
                  placeholder="DD/MM/AAAA"
                  required={
                    (selectDataContrato.causal.value &&
                      selectDataContrato?.causal?.value.name === "A") ||
                    (selectDataContrato.causal.value &&
                      selectDataContrato?.causal?.value.name === "F") ||
                    listaRegistroCandidato?.contratacion?.divisionId == "TWRRHH"
                      ? false
                      : true
                  }
                  maxLength={10}
                  errorPosition="down"
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  defaultValue={
                    selectDataContratoStorage?.fechaTermino?.value == undefined
                      ? formatDateToDDMMAAAA({
                          date: listaRegistroCandidato?.contratacion
                            ?.fechaTermino,
                          format: "/",
                        }) ?? ""
                      : selectDataContratoStorage?.fechaTermino?.value
                  }
                  onPropertyChange="fechaTermino"
                />
              </div>

              <div>
                {validarFechaTermino() ? (
                  <p className="textError" style={{ float: "none" }}>
                    {" "}
                    * La fecha de termino de contrato no puede ser menor a la
                    fecha de inicio ni mayor a la fecha tope legal para la
                    causal.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </TabPanel>
            <TabPanel
              headerClassName="iconCausal"
              header={
                <div className="separate">
                  <Button
                    style={{ paddingLeft: "27px" }}
                    onClick={() => {
                      guardaStorage();
                      setTitleI("LUGAR DE TRABAJO");
                    }}
                  >
                    <IconCausalSucursal />
                  </Button>
                  <span className="texButton"> Lugar de Trabajo </span>
                </div>
              }
            >
              <div>
                <div className="content-text2 sm:col-6 col-12">
                  {modalType === "contratacionNew" ? (
                    <DropDownListCustomized
                      required={false}
                      layer={"División TW"}
                      placeholder={"Seleccione"}
                      disabled={false}
                      list={listDivisiones}
                      onValueChange={setSelectDataContrato}
                      onPermaValues={selectDataContrato}
                      onPropertyChange="division"
                      defaultValue={{
                        code: selectDataContrato.division.value?.code,
                        name: selectDataContrato.division.value?.name,
                      }}
                    />
                  ) : (
                    <InputTextCustomized
                      layer="División TW"
                      placeholder="Ingrese"
                      required={false}
                      errorPosition="down"
                      //onValueChange={setFormCandidate}
                      //onPermaValues={formCandidate}
                      //onPropertyChange="primerNombreCandidato"
                      defaultValue={
                        listaRegistroCandidato?.contratacion?.divisionId
                      }
                      disabled={modalType === "contratacionNew" ? false : true}
                    />
                  )}
                </div>
                <div className="content-text2 sm:col-6 col-12">
                  <InputTextCustomized
                    layer="Área de Negocio"
                    placeholder="Ingrese"
                    required={false}
                    errorPosition="down"
                    //onValueChange={setFormCandidate}
                    //onPermaValues={formCandidate}
                    //onPropertyChange="primerNombreCandidato"
                    defaultValue={
                      modalType === "contratacionNew"
                        ? selectDataContrato?.areaNegocio?.value?.name
                        : listaRegistroCandidato?.contratacion?.areaNegocioCod
                    }
                    disabled={modalType === "contratacionNew" ? false : true}
                  />
                </div>
              </div>

              <div className="mb-7">
                <div className="content-text2 col-12 pt-2 pb-7 mb-7">
                  <DropDownListCustomized
                    list={listSucursales}
                    layer="Lugar de Trabajo "
                    required={true}
                    errorPosition="down"
                    onValueChange={setSelectDataContrato}
                    onPermaValues={selectDataContrato}
                    onPropertyChange="sucursal"
                    defaultValue={{
                      code: selectDataContrato.sucursal.value?.code,
                      name: selectDataContrato.sucursal.value?.name,
                    }}
                  />
                </div>
              </div>

              <div className="mb-7"></div>
            </TabPanel>

            <TabPanel
              headerClassName="iconCausal"
              header={
                <div className="separate">
                  <Button
                    onClick={() => {
                      guardaStorage();
                      setTitleI("FUNCIONES");
                    }}
                  >
                    <IconCausalFincion />
                  </Button>
                  <span className="texButton"> Funciones</span>
                </div>
              }
            >
              <div>
                <div className="content-text2 sm:col-6 col-12">
                  <InputTextCustomized
                    layer="Código Cargo"
                    placeholder="Ingrese"
                    required={true}
                    errorPosition="down"
                    onValueChange={setSelectDataContrato}
                    onPermaValues={selectDataContrato}
                    onPropertyChange="codCargo"
                    defaultValue={
                      modalType === "contratacionNew"
                        ? selectDataContrato?.codCargo?.value
                        : listaRegistroCandidato?.contratacion?.codCargo
                    }
                    disabled={
                      /*listaRegistroCandidato?.contratacion?.codCargo !== null
                        ? true
                        : false ||*/ modalType === "contratacionNew"
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="content-text2 sm:col-6 col-12">
                  <InputTextCustomized
                    layer="Hrs Trabajadas"
                    placeholder="Ingrese"
                    required={true}
                    errorPosition="down"
                    onValueChange={setSelectDataContrato}
                    onPermaValues={selectDataContrato}
                    onPropertyChange="horasTrabajadas"
                    defaultValue={selectDataContrato?.horasTrabajadas.value}
                    disabled={modalType === "contratacionNew" ? false : true}
                  />
                </div>
              </div>
              <div className="content-text2  col-12">
                <InputTextCustomized
                  layer="Horario"
                  placeholder="Ingrese"
                  required={true}
                  errorPosition="down"
                  //onValueChange={setFormCandidate}
                  //onPermaValues={formCandidate}
                  //onPropertyChange="primerNombreCandidato"
                  defaultValue={listaRegistroCandidato?.contratacion?.horario}
                  disabled={true}
                />
              </div>

              <div className="content-text2  col-12">
                <InputTextAreaCustomized
                  layer="Funciones:"
                  placeholder="Ingrese"
                  required={true}
                  errorPosition="down"
                  defaultValue={
                    selectDataContratoStorage?.funciones?.value == undefined
                      ? listaRegistroCandidato?.contratacion?.funciones
                      : selectDataContratoStorage?.funciones?.value
                  }
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  onPropertyChange="funciones"
                />
              </div>
            </TabPanel>
          </TabView>

          <div className="modal-button-content center my-7 mx-5 modalDisplay">
            {modalStyle && (
              <>
                <button
                  className={`${
                    modalType === "contratacionNew"
                      ? "modal-button cancel"
                      : "button-all continuar"
                  }  
                   sm:col-6 col-12 sm:mx-3 mb-2 mt-0`}
                  onClick={() =>
                    typeof onChangeGuarda == "function" && onChangeGuarda()
                  }
                >
                  {buttonGuarda ? buttonGuarda : "Guardar"}
                </button>
                <button
                  className={`modal-button Modal sm:col-6 col-12 sm:mx-3 mb-2 mt-0 ${
                    listCandidatoPendienteContrato.some(
                      (l: any) =>
                        l.estadoRegistro !== "COMPLETO" &&
                        l.estadoRegistro !== "DETENIDA"
                    ) ||
                    validarFechaTermino() ||
                    !valid
                      ? `navigation disabled ${
                          modalType === "contratacionNew" ? "font17" : ""
                        }`
                      : `confirm ${
                          modalType === "contratacionNew" ? "font17" : ""
                        }`
                  }`}
                  onClick={() =>
                    typeof onChangeGo == "function" && onChangeGo()
                  }
                  disabled={
                    listCandidatoPendienteContrato.some(
                      (l: any) =>
                        l.estadoRegistro !== "COMPLETO" &&
                        l.estadoRegistro !== "DETENIDA"
                    ) ||
                    validarFechaTermino() ||
                    !valid
                      ? true
                      : false
                  }
                >
                  {buttonGo ? buttonGo : "Solicitar Contrato"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
