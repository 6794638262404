import { useContext, useEffect, useState } from "react";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import Etiquetas from "./Etiquetas";
import TitleDashboard from "../../../ui/kit-mdtw/subMenu/TitleDashboard";
import CandidatosValidados from "./Graficos/Operaciones/CandidatosValidados";
import EstadosSolicitudes from "./Graficos/Operaciones/EstadosSolicitudes";
import UltimosIngresosProcesados from "./Graficos/Operaciones/UltimosIngresosProcesados";
import EstadoCandidatoValidado from "./Graficos/Operaciones/EstadoCandidatoValidado";
import EstadosIngresos from "./Graficos/JefeOperaciones/EstadosIngresos";
import IngresosPorCliente from "./Graficos/JefeOperaciones/IngresosPorCliente";
import EstadosDotacion from "./Graficos/JefeOperaciones/EstadosDotacion";
import IngresosPorAnalista from "./Graficos/JefeOperaciones/IngresosPorAnalista";
import { FILTER_PROFILE, LIST_ROLES } from "../../../../diccionary/Diccionary";

interface ITablaDashBoard {
  idCliente?: string;
}

const TablaDashboard = (props: ITablaDashBoard) => {
  const { getToken, toasts, token, roles, profile } = useContext(
    OperacionContratacionContext
  );
  const { idCliente } = props;
  const [objetoFilterGeneral, setObjetoFilterGeneral] = useState<any>();
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filterGeneralDashboard"
  );
  const [formFilter, setFormFilter] = useState<any>({
    ciclo: {
      value: undefined,
      valid: undefined,
    },
    cliente: {
      value: undefined,
      valid: undefined,
    },
    areaNegocio: {
      value: undefined,
      valid: undefined,
    },
    analista: {
      value: undefined,
      valid: undefined,
    },
    periodo: [],
  });

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const newFilter = JSON.parse(copyStorage);
      setObjetoFilterGeneral(newFilter);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      {toasts}
      <TitleDashboard
        idCliente={idCliente}
        localStorageFilter={localStorageFilter}
        formFilter={formFilter}
        setFormFilter={setFormFilter}
        title="Bienvenido a la Gestión de Contratación de Candidatos"
      />
      <Etiquetas
        objetoFilterGeneral={objetoFilterGeneral}
        localStorageFilter={localStorageFilter}
        idCliente={idCliente}
      />

      <section className="container-dashboard">
        {[
          FILTER_PROFILE.adm_tw,
          FILTER_PROFILE.admin_ops,
          FILTER_PROFILE.tw_ops,
        ].includes(profile) ? (
          <>
            {(roles && roles.includes(LIST_ROLES.JEFE_OPS)) ||
            [FILTER_PROFILE.admin_ops].includes(profile) ? (
              <>
                <div className="section-dashboard responsiveFlex">
                  <EstadoCandidatoValidado
                    heigthDonut={320}
                    widthDonut={380}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <EstadosIngresos
                    idCliente={idCliente}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <IngresosPorCliente
                    idCliente={idCliente}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                </div>
                <div className="section-dashboard responsiveFlex">
                  <EstadosDotacion
                    idCliente={idCliente}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <IngresosPorAnalista
                    idCliente={idCliente}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <EstadosSolicitudes
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="section-dashboard responsiveFlex">
                  <EstadosSolicitudes
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <CandidatosValidados
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                </div>
                <div className="section-dashboard responsiveFlex">
                  <UltimosIngresosProcesados
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                  <EstadoCandidatoValidado
                    heigthDonut={350}
                    widthDonut={400}
                    objetoFilterGeneral={objetoFilterGeneral}
                  />
                </div>
              </>
            )}
            <p className="MessageGraf">
              El total de los porcentajes no suma 100 ya que los valores no se
              presentan con decimales /Período Gráficos: Últimos 7 días{" "}
            </p>
          </>
        ) : (
          <>ERROR: SIN ACCESO A CONTRATOS</>
        )}
      </section>
    </>
  );
};

export default TablaDashboard;
