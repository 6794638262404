import { Button } from "primereact/button";
import { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../assets/css/login.min.css";
import { InputText } from "primereact/inputtext";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";
import { IToken } from "../../../interfaces/IAdminAssist";
interface IInputValid {
  value: string;
}
const InvitationExtProcessTableComponent = (props: IToken) => {
  const {token} = props; 
  const { getCodeValidate, getTokenSecret } = useContext(AsistenciaContext);
  const [error, setError] = useState<boolean | undefined>(undefined);
  const [valores, setValores] = useState<string[]>([]);
  const [errorCodeLogin, setErrorCodeLogin] = useState<string | undefined>(
    undefined
  );

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    getTokenSecret();
  }, [])

  const handleValidInput = (values: IInputValid) => {
    if (!values.value.match(/^([\dA-Za-z]{1})$/)) {
      setError(false);
      setErrorCodeLogin(
        "*El código ingresado es incorrecto, ingréselo nuevamente."
      );
      return false;
    }
    setError(undefined);
    setErrorCodeLogin(undefined);
  };

  const handleEventValidationCode = () => {
    const codigo = valores.join("");
    if(codigo === "" || codigo === null) {
      return setErrorCodeLogin("Favor ingrese código de invitación para ingresar");
    }
    getCodeValidate({ code: codigo, setError: setErrorCodeLogin, token });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
    const { value } = event.target;

    if (value.length >= event.target.maxLength) {
      const nextIndex = currentIndex + 1;

      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };

  return (
    <>
      <div className="container-login">
        <div className="content-login">
          <div className="content-flex">
            <div className="img-login">
              <img
                src={"/assets/layout/images/logo-teamwork-chile.png"}
                alt="logo"
                className="img-size"
              />
            </div>

            <div className="content-text">
              <span className="title">Ingreso al Portal de asistencia</span>
            </div>

            <div className="content-text">
              <span className="description">
                Por favor ingresa el código del proceso <br></br>
                para controlar asistencia
              </span>
            </div>

            <div className="content-text">
              <div className="content-inside">
                <span className="text-invitation">
                  Ingrese el código de invitación
                </span>
                <div
                  className={`content-password ${!error ? "" : "pass-error"}`}
                >
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[0] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[0] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,0);
                    }}
                  />
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[1] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[1] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,1);
                    }}
                  />
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[2] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[2] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,2);
                    }}
                  />
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[3] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[3] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,3);
                    }}
                  />
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[4] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[4] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,4);
                    }}
                  />
                  <InputText
                    className="text-pass"
                    ref={(el) => (inputRefs.current[5] = el)}
                    placeholder={"X"}
                    maxLength={1}
                    onChange={( e ) => {
                      handleValidInput({ value: e.target.value });
                      valores[5] = e.target.value.toLocaleUpperCase();
                      handleInputChange(e,5);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="content-button">
              <Button
                label="Ingresar"
                onClick={() => {
                  handleEventValidationCode();
                  // goToAsistencia();
                }}
                className="button-login"
              />
            </div>
            {errorCodeLogin ? (
              <span className="error-code">{errorCodeLogin}</span>
            ) : (
              ""
            )}
            <div className="content-button"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvitationExtProcessTableComponent;
