import "../../../assets/css/adminModal.min.css";
import { useState } from "react";
import { Button } from "primereact/button";
import CloseIcon from "@mui/icons-material/Close";
interface IDeleteServiceModal {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
  list?: any[];
}

export default (props: IDeleteServiceModal) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, width, list } =
    props;

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const styleTextAline: any = {
    textAlign: "start",
  };

  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span className="modal-close" onClick={() => handleClickCloseModal()}>
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle" style={styleTextAline}>
            <span>{subTitle}</span>
          </div>
          <div
            className="modal-message"
            style={{ ...styleTextAline, marginBottom: "0px" }}
          >
            <span>{message}</span>
          </div>
          <div className="modal-section-renewcode">
            {list && (
              <>
                {list.map((code, key) => (
                  <span key={key}>N°{code}</span>
                ))}
              </>
            )}
          </div>

          <div className="modal-message" style={styleTextAline}>
            <span>
              Si selecciona eliminar, se borrarán todos los datos de nuestra
              base de datos.{" "}
            </span>
          </div>
          <div className="button-content">
            <Button className="button-back" onClick={() => typeof onChangeBack == "function" && onChangeBack()}>
              Cancelar
            </Button>
            <Button
              className="button-go-delete"
              onClick={() => typeof onChangeGo == "function" && onChangeGo()}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
