import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import { FILTER_PROFILE } from "../../../../diccionary/Diccionary";
import { IClientes, Params } from "../../../../interfaces/IValidationKam";
import { useParams } from "react-router-dom";

interface ITitle {
  list?: any[];
  token: string | undefined; 
  setToken: Function;
  getToken: Function;
}

const Title = (props: ITitle) => {
  const {token, setToken, getToken} = props;
  const { profile, getCounts, countAssign } = useContext(ValidationKamContext);
  const [names, setNames] = useState<string | undefined>(undefined);
  const { idCliente } = useParams<Params>();

  useEffect(() => {
    if (countAssign.length > 0) {
      const { clienteNombre } = countAssign.find(
        (list: IClientes) => list.clienteId === idCliente
      );
      setNames(clienteNombre);
    }
  }, [countAssign]);

  useEffect(() => {
    if (token) {
      getCounts({token});
    }
  }, [token]);

  return (
    <>
      <span className="title-page">{names ?? "CARGANDO..."}</span>
      <div className="div-subtitle">
        <span className="subtitle-page">
          {[FILTER_PROFILE.adm_tw].includes(profile) && (
            <>
              En esta opción puedes validar la documentación de los candidatos y solicitar su contratación. 
            </>
          )}

          {[FILTER_PROFILE.tw_ops].includes(profile) && (
            <>
              En esta sección puedes pasar lista, validar y descartar o bloquear
              candidatos.
            </>
          )}

          {[FILTER_PROFILE.tw_cliente].includes(profile) && (
            <>
            En esta sección puedes seleccionar o descartar candidatos. <br></br>
            La lista de candidatos que avancen en el proceso pasarán al proceso de
              contratación para el cargo postulado.
            </>
          )}
        </span>
      </div>
    </>
  );
};

export default Title;
