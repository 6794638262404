interface IIconCirculoEstado {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconCirculoEstado) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <circle id="Ellipse 51" cx="7" cy="7" r="7" />
    </svg>
  );
};
