import { useEffect, useState } from "react";
import { IModalAnularSolicitud } from "../../../../interfaces/IOperacionesContratacion";
import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TablaAnularDocumento from "./TablaAnularDocumento";

export default (props: IModalAnularSolicitud) => {
  const {
    token,
    list,
    listData,
    setSelectCurrent,
    selectCurrent,
    reasonAll,
    setReasonAll,
  } = props;
  const [currentList, setCurrentList] = useState<any>();
  const [buttonAssignAll, setButtonAssignAll] = useState<boolean>(false);

  useEffect(() => {
    if (list && listData) {
      const formSelect: any = {};
      const arrayData = listData.filter(({ divisionId, ficha }: any) =>
        list.includes(divisionId + "-" + ficha)
      );
      arrayData.forEach((item: any) => {
        formSelect[item.ficha] = { value: undefined, valid: false };
      });
      setSelectCurrent(formSelect);
      setCurrentList(arrayData);
    }
  }, [list]);

  const handleClickAssingForAll = (buttonAssignAll: boolean) => {
    if (buttonAssignAll) {
      if (reasonAll.motivoAll?.value) {
        const formSelect: any = {};
        const arrayData = listData.filter(({ divisionId, ficha }: any) =>
          list.includes(divisionId + "-" + ficha)
        );
        arrayData.forEach((item: any) => {
          formSelect[item.ficha] = {
            value: reasonAll.motivoAll.value,
            valid: true,
          };
        });
        setSelectCurrent(formSelect);
      }
    }
  };

  return (
    <>
      <section className="modal-message start">
        <label>
          Vas a cancelar la solicitud de cálculo de baja para la siguiente
          selección:
        </label>
      </section>

      {list && list.length > 1 && (
        <section className="modal-section-motivo-detencion">
          <div className="section-detener-solicitud">
            <InputTextCustomized
              layer="Motivo de detención"
              placeholder=""
              required={false}
              onValueChange={setReasonAll}
              onPermaValues={reasonAll}
              onPropertyChange="motivoAll"
            />
          </div>
          <div
            className="section-checkall"
            onClick={() => {
              setButtonAssignAll(!buttonAssignAll);
              handleClickAssingForAll(!buttonAssignAll);
            }}
          >
            {buttonAssignAll ? (
              <CheckBoxIcon className="svg-detener-solicitud" />
            ) : (
              <CheckBoxOutlineBlankIcon className="svg-detener-solicitud" />
            )}
            <label className="label-detener-solicitud">Asignar a todos</label>
          </div>
        </section>
      )}
      <section className="content-modal-anular-documento">
        {currentList &&
          currentList.map((item: any) => {
            const {
              ficha,
              primerNombre,
              primerApellido,
              division,
              areaNegocioCod,
              articuloDesvinculacion,
              descripcionDesvinculacion,
              fechaTerminoContrato
            } = item;

            return (
              <TablaAnularDocumento
                ficha={ficha}
                primerNombre={primerNombre}
                primerApellido={primerApellido}
                division={division}
                areaNegocioCod={areaNegocioCod}
                articuloDesvinculacion={articuloDesvinculacion}
                descripcionDesvinculacion={descripcionDesvinculacion}
                fechaTerminoContrato={fechaTerminoContrato}
                selectCurrent={selectCurrent}
                setSelectCurrent={setSelectCurrent}
                buttonAssignAll={buttonAssignAll}
              />
            );
          })}
      </section>
      <footer className="modal-message start">
        <label>
          Al confirmar, los registros volverán a la lista de bajas confirmadas
          sin calcular. para continuar presione detener baja.
        </label>
      </footer>
    </>
  );
};
