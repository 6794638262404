import { useEffect, useState, useContext } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import {
  formatDateToDDMMAAAA,
  formatDateToDDMMYY,
} from "../../../../../functions/kit-mdtw/dates";
import { useParams } from "react-router-dom";
import {
  IListaCandidatos,
  IToken,
  Params,
} from "../../../../../interfaces/IValidationKam";
import FiltersTable from "./FiltersTable";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ButtonIcon from "../../../../ui/kit-mdtw/buttons/ButtonIcon";
import ModalDescartarBloquear from "../Modals/ModalDescartarBloquear";
import MediaQuery from "../../../../ui-responsive/MediaQuery";
import FooterTable from "./FooterTable";
import { FILTER_LIST, TYPE_LIST } from "../../../../../diccionary/Diccionary";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import ModalFicha from "../../AsistenciaValidacionKam/Modals/ModalFicha";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";

const TablaCandidatoPendienteAprobacion = () => {
  const {
    token,
    getToken,
    exclusiones,
    listaCandidatoPendienteValidacion,
    listCandidatoPendienteValidacionCliente,
    setListCandidatoPendienteValidacionCliente,
    setVisibleActionBlocking,
    getListCandidatePendingValidate,
  } = useContext(ValidationKamContext);
  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  const [openModalDescartar, setOpenModalDescartar] = useState<boolean>(false);
  const [openModalBloquear, setOpenModalBloquear] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [listDescartesBloqueo, setListDescarteBloqueo] = useState<any[]>([]);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [
    gridCandidatoPendienteValidacion,
    setGridCandidatoPendienteValidacion,
  ] = useState<IListaCandidatos[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);

  useEffect(() => {
    listCandidatoPendienteValidacionCliente.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listCandidatoPendienteValidacionCliente]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  useEffect(() => {
    if (token) {
      getListCandidatePendingValidate({ idCliente, token });
    }
  }, [token]);

  useEffect(() => {
    let arrayGridFilter = [...listaCandidatoPendienteValidacion];
    if (filterChange) {
      const { rut, areaNegocio, grupo, fechaValidacion } = filterChange;
      if (rut.value && rut.value.length > 0) {
        const rutArray = rut.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          rutArray.includes(item.personaDni)
        );
      }
      if (areaNegocio.value && areaNegocio.value.length > 0) {
        const areaNegocioArray = areaNegocio.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          areaNegocioArray.includes(item.areaNegocioId)
        );
      }
      if (grupo.value && grupo.value.length > 0) {
        const grupoArray = grupo.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          grupoArray.includes(item.busquedaId)
        );
      }
      if (fechaValidacion.value && fechaValidacion.value.length > 0) {
        const fechaValidacionArray = fechaValidacion.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          fechaValidacionArray.includes(
            formatDateToDDMMAAAA({ date: item.ingresadoFecha })
          )
        );
      }
    }

    setLengthFilters(arrayGridFilter.length);

    if (range) {
      const { initRange, endRange } = range;
      arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
    }

    setGridCandidatoPendienteValidacion(arrayGridFilter);
  }, [filterChange, listaCandidatoPendienteValidacion, range]);

  const clearListDescarteBloqueo = () => {
    setListDescarteBloqueo([]);
  };

  const handleClickAddListMasive = (personaDni: string) => {
    const currentService = [...listCandidatoPendienteValidacionCliente];
    if (!currentService.includes(personaDni)) {
      currentService.push(personaDni);
    } else {
      const positionDeleted = currentService.findIndex(
        (service) => service === personaDni
      );
      currentService.splice(positionDeleted, 1);
    }
    setListCandidatoPendienteValidacionCliente(currentService);
  };

  const handleClickDescarteGo = () => {
    setOpenModalDescartar(false);
  };

  const handleClickPersonIdMasive = () => {
    listCandidatoPendienteValidacionCliente.length > 0
      ? setListCandidatoPendienteValidacionCliente([])
      : setListCandidatoPendienteValidacionCliente(
          listaCandidatoPendienteValidacion.map((item: IListaCandidatos) => {
            return item.personaDni;
          })
        );
  };

  const handleClickModalDescartar = (values: any) => {
    const { personaDni } = values;
    setListDescarteBloqueo(personaDni);
    setOpenModalDescartar(true);
  };

  const handleClickModalBloquear = (values: any) => {
    const { personaDni } = values;
    setListDescarteBloqueo(personaDni);
    setOpenModalBloquear(true);
  };

  const handleClickModalFicha = (values: any) => {
    const { personaDni } = values;
    setRutCandidate(personaDni);
    setOpenModalFicha(true);
  };

  return (
    <>
      <FiltersTable
        list={listaCandidatoPendienteValidacion}
        setFilterChange={setFilterChange}
      />

      <div className="content-header-table">
        <span>Candidatos Pendientes de Validación</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th>
                <ButtonIcon onClick={() => handleClickPersonIdMasive()}>
                  {listCandidatoPendienteValidacionCliente.length ==
                    listaCandidatoPendienteValidacion.length &&
                  listCandidatoPendienteValidacionCliente.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ButtonIcon>
              </th>
              <th>Nombre</th>
              <th>Rut</th>
              {width <= sm ? (
                <>
                  <th>Área de Negocio</th>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>Teléfono</th>
                  <th>Área de Negocio</th>
                  <th>Proceso</th>
                  <th>Fecha Validación TW</th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoPendienteValidacion &&
              gridCandidatoPendienteValidacion.map((item: IListaCandidatos) => {
                const {
                  busquedaId,
                  personaDni,
                  primerNombre,
                  areaNegocioCod,
                  primerApellido,
                  ingresadoFecha,
                  wsp,
                } = item;
                return (
                  <>
                    <tr
                      key={personaDni}
                      className={
                        listCandidatoPendienteValidacionCliente.includes(
                          personaDni
                        ) && "checked"
                      }
                    >
                      <td>
                        <ButtonIcon
                          onClick={() => handleClickAddListMasive(personaDni)}
                        >
                          {listCandidatoPendienteValidacionCliente.includes(
                            personaDni
                          ) ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </ButtonIcon>
                      </td>
                      <td>
                        {primerNombre} {primerApellido}
                      </td>
                      <td>{personaDni}</td>
                      {width <= sm ? (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>
                            <div className="content-icon-table">
                              <button
                                onClick={() =>
                                  handleClickModalFicha({ personaDni })
                                }
                              >
                                <AssignmentIndIcon className="edit" />
                              </button>
                              <button
                                onClick={() =>
                                  handleClickModalBloquear({ personaDni })
                                }
                              >
                                <DoDisturbOutlinedIcon className="block" />
                              </button>
                              <button
                                onClick={() =>
                                  handleClickModalDescartar({ personaDni })
                                }
                              >
                                <CloseIcon className="cancel" />
                              </button>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{wsp}</td>
                          <td>{areaNegocioCod}</td>
                          <td>{busquedaId}</td>
                          <td>
                            {formatDateToDDMMYY({
                              date: ingresadoFecha,
                              format: "/",
                            })}
                          </td>
                          <td>
                            <div className="content-icon-table">
                              <Tooltip text="Ver Registro">
                                <button
                                  onClick={() =>
                                    handleClickModalFicha({ personaDni })
                                  }
                                >
                                  <AssignmentIndIcon className="edit" />
                                </button>
                              </Tooltip>
                              <Tooltip text="Descartar">
                                <button
                                  onClick={() =>
                                    handleClickModalBloquear({ personaDni })
                                  }
                                >
                                  <DoDisturbOutlinedIcon className="block" />
                                </button>
                              </Tooltip>
                              <Tooltip text="Bloquear">
                                <button
                                  onClick={() =>
                                    handleClickModalDescartar({ personaDni })
                                  }
                                >
                                  <CloseIcon className="cancel" />
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>

      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalFicha && (
        <ModalFicha
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setOpenModalFicha(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          areaContrato={false}
          setOpenModalFicha={setOpenModalFicha}
          rut={rutCandidate}
        />
      )}

      {openModalBloquear && (
        <ModalDescartarBloquear
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="¿Está seguro que desea Bloquear el candidato?"
          subTitle="Al bloquear un candidato, éste no podrá participar en procesos de selección para este cliente."
          message="Seleccione el motivo del bloqueo:"
          onChangeBack={() => setOpenModalBloquear(false)}
          onChangeGo={() => setOpenModalBloquear(false)}
          buttonBack="Cancelar"
          buttonGo="Bloquear"
          exclusiones={dataBloqueo}
          list={listDescartesBloqueo}
          clearList={clearListDescarteBloqueo}
          action="block"
        />
      )}

      {openModalDescartar && (
        <ModalDescartarBloquear
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="¿Está seguro que desea Descartar el candidato?"
          subTitle="Al descartar un candidato, éste no podrá continuar en el proceso de selección para este cargo."
          message="Seleccione el motivo del descarte:"
          onChangeBack={() => setOpenModalDescartar(false)}
          onChangeGo={() => handleClickDescarteGo()}
          buttonBack="Cancelar"
          buttonGo="Descartar"
          exclusiones={dataDescarte}
          list={listDescartesBloqueo}
          clearList={clearListDescarteBloqueo}
          action="discart"
        />
      )}
    </>
  );
};

export default TablaCandidatoPendienteAprobacion;
