import { useContext, useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import MediaQuery from "../../../ui-responsive/MediaQuery";
import ModalConfirm from "./Modals/ModalConfirm";
import ModalDescartarBloquear from "./Modals/ModalDescartarBloquear";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CloseIcon from "@mui/icons-material/Close";
import { TYPE_LIST } from "../../../../diccionary/Diccionary";
import Tooltip from "../../../ui/kit-mdtw/tooltip/Tooltip";
interface ISeccionAccion {
  dataDescarte?: any[];
  dataBloqueo?: any[];
}
const SeccionAccion = (props: ISeccionAccion) => {
  const { dataBloqueo, dataDescarte } = props;
  const { token, getToken, listCandidatoPendienteValidacionCliente } =
    useContext(ValidationKamContext);
  const { width, lg, md } = MediaQuery();
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listCandidatoPendienteValidacionCliente.length > 0 ? true : false
  );
  const [openModalDescartar, setOpenModalDescartar] = useState<boolean>(false);
  const [openModalBloquear, setOpenModalBloquear] = useState<boolean>(false);
  const [openModalContratacion, setOpenModalContratacion] =
    useState<boolean>(false);

  useEffect(() => {
    setButtonEnabled(listCandidatoPendienteValidacionCliente.length > 0);
  }, [listCandidatoPendienteValidacionCliente]);

  const handleClickContratacion = () => {
    setOpenModalContratacion(true);
  };

  const handleClickDescartar = () => {
    setOpenModalDescartar(true);
  };

  const handleClickBloquear = () => {
    setOpenModalBloquear(true);
  };

  return (
    <>
      <div className="content-actions actions-old">
        <div className="content-enviar">
          <button
            className={`navigation ${
              buttonEnabled ? "active-green" : "disabled"
            }`}
            onClick={() => handleClickContratacion()}
            disabled={buttonEnabled ? false : true}
          >
            Aprobar Contratación
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
          <Tooltip text="Descartar">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-cancel" : "disabled"
              }`}
              disabled={buttonEnabled ? false : true}
              onClick={() => handleClickDescartar()}
            >
              <CloseIcon />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={buttonEnabled ? false : true}
              onClick={() => handleClickBloquear()}
            >
              <DoDisturbAltIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      {openModalContratacion && (
        <ModalConfirm
          modalStyle="confirm"
          modalType="contratacion"
          title="CONFIRMACIÓN DE ENVÍO"
          subTitle={`Vas a enviar ${listCandidatoPendienteValidacionCliente.length} de candidato(s) a contratación. ¿Esta correcto?:`}
          message="Para confirmar presiona continuar"
          onChangeBack={() => setOpenModalContratacion(false)}
          onChangeGo={() => setOpenModalContratacion(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
        />
      )}

      {openModalDescartar && (
        <ModalDescartarBloquear
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="¿Está seguro que desea Descartar los candidatos?"
          subTitle="Al descartar candidatos, éstos no podrán continuar en el proceso de selección para este cargo."
          message="Seleccione el motivo del descarte:"
          onChangeBack={() => setOpenModalDescartar(false)}
          onChangeGo={() => setOpenModalDescartar(false)}
          buttonBack="Cancelar"
          buttonGo="Descartar"
          exclusiones={dataDescarte}
        />
      )}

      {openModalBloquear && (
        <ModalDescartarBloquear
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="¿Está seguro que desea Bloquear los candidatos?"
          subTitle="Al bloquear los candidatos, éstos no podrán participar en procesos de selección para este cliente."
          message="Seleccione el motivo del bloqueo:"
          onChangeBack={() => setOpenModalBloquear(false)}
          onChangeGo={() => setOpenModalBloquear(false)}
          buttonBack="Cancelar"
          buttonGo="Bloquear"
          exclusiones={dataBloqueo}
        />
      )}
    </>
  );
};

export default SeccionAccion;
