import "../../../../assets/css/footer-table.min.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import DropDownListCustomized from "../DropDownListCustomized";
import IconArrowDoubleLeft from "../../kit-svg/IconArrowDoubleLeft";
import IconArrowDoubleRight from "../../kit-svg/IconArrowDoubleRight";
import { IOptionDropDownList } from "../../../../interfaces/IDropDownList";

interface IFooterTable {
  offset: any;
  setOffset: Function;
  paginationData: {
    totalRecords: number;
    currentRecords: number;
    currentPage: number;
    totalPages: number | null;
    startIndex: number;
    endIndex: number;
    previousPage: number | null;
    nextPage: number | null;
    hasPrevPage: boolean;
    hasNextPage: boolean;
  };
}

export default function FooterTablePagination(props: IFooterTable) {
  const { paginationData, offset, setOffset } = props;
  const [listPageDefault] = useState<IOptionDropDownList[]>([
    { name: "10", code: "10" },
    { name: "20", code: "20" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ]);

  useEffect(() => {
    if (paginationData) { 
      setOffset({
        ...offset,
        totalRecords: paginationData.totalRecords ?? 1,
        currentPage: paginationData.currentPage ?? 1,
        currentRecords: paginationData.currentRecords ?? 1,
        totalPages: paginationData.totalPages ?? 1,
        startIndex: paginationData.startIndex ?? 1,
        endIndex: paginationData.endIndex ?? 1,
        previousPage: paginationData.previousPage ?? 1,
        nextPage: paginationData.nextPage ?? 1,
        hasPrevPage: paginationData.hasPrevPage ?? false,
        hasNextPage: paginationData.hasNextPage ?? false,
      });
    }
  }, [paginationData]);

  return (
    <>
      <footer className="footer-table">
        <div className="pagination">
          <label className="pagination-text">Filas por página</label>
          <div style={{ width: "80px", margin: "0px 10px" }}>
            <DropDownListCustomized
              placeholder={"10"}
              list={listPageDefault}
              onPermaValues={offset}
              onValueChange={setOffset}
              onPropertyChange="offset"
              defaultValue={{name: offset.currentRecords, code: offset.currentRecords}}
            />
          </div>
          <label className="pagination-text">
            {offset.startIndex} - {offset.endIndex} de {offset.totalRecords}
          </label>
        </div>
        <div className="pagination-number">
          <button
            onClick={() => setOffset({ ...offset, pages: 1 })}
            disabled={offset.currentPage < 2}
            className={offset.pages < 2 ? "disabled" : ""}
          >
            <IconArrowDoubleLeft disabled={offset.currentPage < 2} />
          </button>
          <button
            onClick={() => setOffset({ ...offset, pages: offset.pages - 1 })}
            disabled={!offset.hasPrevPage}
            className={!offset.hasPrevPage ? "disabled" : ""}
          >
            <KeyboardArrowLeftIcon />
          </button>
          <span className="number-pagination">{offset.pages}</span>
          <button
            onClick={() => setOffset({ ...offset, pages: offset.pages + 1 })}
            disabled={!offset.hasNextPage}
            className={!offset.hasNextPage ? "disabled" : ""}
          >
            <KeyboardArrowRightIcon />
          </button>
          <button
            onClick={() => setOffset({ ...offset, pages: offset.totalPages })}
            disabled={!offset.hasNextPage}
            className={!offset.hasNextPage ? "disabled" : ""}
          >
            <IconArrowDoubleRight disabled={!offset.hasNextPage} />
          </button>
        </div>
      </footer>
    </>
  );
}
