import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import { IFormFastIncomeNoRequired } from "../../../../interfaces/IFastIncome";
import { FILTER_LIST } from "../../../../diccionary/Diccionary";
import DropDownListCustomized from "../../kit-mdtw/DropDownListCustomized";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
import { IOptionDropDownList } from "../../../../interfaces/IDropDownList";

interface IModalDescartar {
  typeExclusion: "descarte" | "bloqueo";
  onChangeBack?: Function;
  list?: any[];
  getListRefresh?: Function;
  listSelect?: any;
  localStorageFilter?: string;
  localStorageSelect?: string; 
  setListadoSeleccionado?: Function;
  formFilter?: any;
}

export default (props: IModalDescartar) => {
  const {
    onChangeBack,
    list,
    typeExclusion,
    localStorageFilter,
    localStorageSelect,
    getListRefresh,
    formFilter,
    listSelect,
    setListadoSeleccionado,
  } = props;
  const { listaExclusion, token, postBlockCandidate } =
    useContext(SeleccionContext);
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  const [message, setMessage] = useState<boolean>(false);
  const [formFastIncomeNoRequired, setFormFastIncomeNoRequired] =
    useState<any>({
      id: { value: undefined, valid: undefined },
    });

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    if (listaExclusion.length > 0) {
      setDataBloqueo(
        listaExclusion.filter(({ exclusionLevel }: any) =>
          [FILTER_LIST.all].includes(exclusionLevel)
        )
      );
      setDataDescarte(
        listaExclusion.filter(({ exclusionLevel }: any) =>
          [FILTER_LIST.busqueda].includes(exclusionLevel)
        )
      );
    }
  }, [listaExclusion]);

  useEffect(() => {
    if (formFastIncomeNoRequired.id.value) {
      setMessage(formFastIncomeNoRequired.id.value?.code == "17")
    }
  }, [formFastIncomeNoRequired.id.value])

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickGoDescarteBloqueo = async (): Promise<void> => {
    const { value } = formFastIncomeNoRequired.id;
    const { code }: any = value;

    try {
      if (list && token && code && listSelect) {
        const current = listSelect
          ?.filter((item: any) => list.includes(item.personaDNI))
          .map((item: any) => item.personaId);
        const personas = current.map((item: any) => {
          return {
            personaId: item,
            excTipoId: code,
            comentario: "",
          };
        });
        await postBlockCandidate({ token, personas, typeExclusion });
        getListRefresh && (await getListRefresh());
        handleClickCloseModal();
        setListadoSeleccionado && setListadoSeleccionado([]);
        if (localStorageSelect) {
          localStorage.removeItem(localStorageSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <>
    {
         
       
 <div className="project-table-Mo">
            <div className="container-table-Mo">
              <table className="content-table-Mo">
                <thead>
                  <tr>
               
                      <th>Nombre</th>
                 
                    <th>Rut</th>
                    <th>Fecha Ingreso</th>
                    <th>Ultimo Proceso</th>
                    
                  </tr>
                </thead>
                <tbody>
                {listSelect
          .filter((item: any) => list && list.includes(item.personaDNI))
          .map((item: any) => (
            <tr
              key={item.personaDNI}
              className={list && list.includes(item.personaDNI) ? "" : ""}
            >
              <td>{item.primerNombre + " " + item.primerApellido}</td>
              <td>{item.personaDNI}</td>
              <td>
                { formatDateToDDMMAAAA({
                      date: item.fechaPostulacion,
                      format: "/",
                    }) }
              </td>
              <td>{(item.busquedaId !== undefined && item.cargoNombre !== undefined) ? `${item.division} - ${item.busquedaId} - ${item.areaNegocioCod}  ${item.cargoNombre}` 
              : 'sin Datos'}</td>

            </tr>
          ))}
                </tbody>
              </table>
            </div>
          </div>

 }

      <div className="modal-message start">
        {typeExclusion === "bloqueo" && (
          <span>Seleccione El Motivo Del Bloqueo:</span>
        )}
        {typeExclusion === "descarte" && (
          <span>Seleccione El Motivo Del Descarte:</span>
        )}
      </div>
      <div className="modal-message">
        <DropDownListCustomized
          list={
            typeExclusion == "bloqueo"
              ? dataBloqueo?.map((values: any) => {
                  const { excTipoId, excTipoNombre } = values;
                  return { code: excTipoId, name: excTipoNombre };
                })
              : typeExclusion == "descarte"
              ? dataDescarte?.map((values: any) => {
                  const { excTipoId, excTipoNombre } = values;
                  return { code: excTipoId, name: excTipoNombre };
                })
              : []
          }
          onValueChange={setFormFastIncomeNoRequired}
          onPropertyChange="id"
          onPermaValues={formFastIncomeNoRequired}
          placeholder="Seleccionar"
          required={false}
        />
      </div>

      {typeExclusion == "bloqueo" && message &&(
        <div className="modal-subtitle start">
          <label className="block-message">
            Con esta opción, los candidatos además quedarán bloqueados de
            Teamwork y todos sus clientes.
          </label>
        </div>
      )}

      <div className="modal-subtitle start">
        {typeExclusion === "bloqueo" && (
          <span>
            Al bloquear los candidatos, éstos no podrán participar en procesos
            de selección para este cliente.
          </span>
        )}
        {typeExclusion === "descarte" && (
          <span>
            Al descartar candidatos, éstos no podrán continuar en el proceso de
            selección para este cargo.
          </span>
        )}
      </div>
      <div className="modal-button-content">
        <button
          className={`modal-button cancel`}
          onClick={handleClickCloseModal}
        >
          Cancelar
        </button>
        <button
          className={`modal-button delete ${
            !formFastIncomeNoRequired.id?.value && "disabled"
          }`}
          onClick={handleClickGoDescarteBloqueo}
          disabled={!formFastIncomeNoRequired.id?.value}
        >
          {typeExclusion === "bloqueo" ? "Bloquear Candidatos" : "Descartar Candidatos"}
        </button>
      </div>
    </>
  );
};
