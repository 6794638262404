import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import ProcessRequestModalV2 from "./ProcessRequestModalV2";
import ProcessCreateGroupModal from "./ProcessCreateGroupModal";
import { Toast } from "primereact/toast";
import { useMsal } from "@azure/msal-react";

const ProcessV2 = () => {
  const [token, setToken] = useState<any>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [filterByArea, setfilterByArea] = useState<any[]>([]);
  const [groupsReload, setGroupsReload] = useState<any[]>([]);
  const [groupFilter, setGroupFilter] = useState("");
  const history = useHistory();
  const [busquedaModal, setBusquedaModal] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profile, setProfile] = useState<any>(null);
  const toast = useRef<Toast>(null);
  const [loadingManualProcess, setLoadingManualProcess] = useState(false);
  const { instance } = useMsal();
  const [isModalVisibleGroup, setIsModalVisibleGroup] = useState(false);
  const [allAreas, setAllAreas] = useState<any[]>([])
  
 
  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };
  
  

  const convertToDate = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const changeGroup = (event: any) => {
    setGroupFilter(event.target.value);
    if (event.value === "") {
      setfilterByArea(filterByArea);
    } else {
      const clientFilter = allAreas.filter(
        (area) => area.areaNegocioId === event.value
        
      );
      
      setAllAreas(clientFilter);
    }
  };

  
  

  //useEffect

  useEffect(() => {
    getToken();
  });

  useEffect(() => {
    getToken();
    if (token) {
      getGroups();
    }
  }, [token]);

  const getGroups = () => {
    api
      .get(`busqueda-unassigned?userId=${sessionStorage.getItem("usuarioId")}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroups(response.data.data[0]);
        setGroupsReload(response.data.data[0]);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  useEffect(() => {
    let newAll: any[] = [];
    let newFilter: any[] = [];
    groupsReload.map((group: any) => {
      group.busquedas.map((area:any)=>{
        const newObj2 = {
          value: area.areaNegocioId,
          label: area.areaNegocioNombre,
        };
        newAll = [...newAll, area];
        newFilter = [...newFilter, newObj2];
      })      
    });
    setAllAreas(newAll)
    console.log("ALLAreas: ", newAll)
    const newObj = {
      value: "",
      label: "Todos",
    };
    newFilter = [newObj, ...newFilter];
    console.log("filterByArea", newFilter)
    setfilterByArea(newFilter);
  }, [groupsReload]);

  const goToList = (areaId: any) => {
    history.push("/lista/"+areaId);
  };

  //main return//
  return (
    <>
      <div className="card">
       

        <span
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          En esta opción puede visualizar las areas de Negocio.
        </span>

        <span
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          Areas de Negocio.
        </span>

        <p
          className="flex justify-content-start"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#2A63D5",
          }}
        >
          Operaciones Activas.
        </p>        
              

        <div className="flex flex-row mb-5">
          <span className="material-symbols-outlined md-24 mt-1 iconBlue">
            filter_alt
          </span>
          <span className="mx-5 mt-2">Area de Negocio:</span>
          {filterByArea ? (
            <Dropdown
              value={groupFilter}
              options={filterByArea}
              onChange={changeGroup}
              optionLabel="label"
              placeholder="Seleccione area de Negocio"
              filterBy="label"
              filter
              showClear                
              appendTo="self"
            />
          ) : (
            ""
          )}
        </div>


        
        {allAreas
          ? 
          <>
          <div className="accordionActiveProcess mt-4 mr-4">
                  <Accordion activeIndex={0}>
                    <AccordionTab
                      header={
                        <div>
                          <span className="colorTextVacantes">
                            NOMBRE:{" "}
                            <span className="colorTextVacantesName">
                              {" "}
                              {groupFilter}
                            </span>
                          </span>
                        </div>
                      }
                    >
              {allAreas.map((areas: any) => {
              return (                
                    <div className="flex flex-column">
                        
                           
                                <>
                                  <div
                                    className="flex flex-wrap justify-content-between pt-3 border-round my-3 py-3 "
                                    style={{
                                      background: "#2A63D5",
                                      color: "#ffffff",
                                    }}
                                  >
                                    <div className="flex flex-row ml-8">
                                      {areas.busquedaUrgencia === 2 ? (
                                        <span className="circuloRed alignIcon"></span>
                                      ) : areas.busquedaUrgencia === 1 ? (
                                        <span className="circuloYellow alignIcon"></span>
                                      ) : areas.busquedaUrgencia === 0 ? (
                                        <span className="circuloGreen alignIcon"></span>
                                      ) : (
                                        ""
                                      )}

                                      <span className="ml-2">
                                        {areas.busquedaId} -{" "}
                                        {areas.areaNegocioNombre} -{" "}
                                      </span>
                                    </div>
                                  </div>

                                    <div className="overflow-x-auto">
                                    <table className="tablaInfo">
                                      <tr>
                                        <td className="tablasTitle ">ID:</td>
                                        <td>{areas.busquedaId}</td>
                                        <td className="tablasTitle">
                                          Empresa:
                                        </td>
                                        <td>{areas.empresa}</td>
                                        <td className="tablasTitle">
                                          Cliente:
                                        </td>
                                        <td>{areas.clienteNombre}</td>
                                        <td className="tablasTitle">
                                          Área de negocio
                                        </td>
                                        <td>{areas.areaNegocioNombre}</td>
                                      </tr>
                                      <tr>
                                        <td className="tablasTitle">KAM:</td>
                                        <td>En Desarrollo</td>
                                        <td className="tablasTitle">
                                          Q. cupos:
                                        </td>
                                        <td>{areas.busquedaCantidad}</td>
                                        <td className="tablasTitle">
                                          Fecha solicitud:
                                        </td>
                                        <td>
                                           {areas.busquedaInicio}
                                        </td>
                                        <td className="tablasTitle">
                                          Fecha entrega:
                                        </td>
                                        <td>{areas.busquedaFin}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>

                                  {profile &&
                                  (profile == "adm_tw" ||
                                    profile == "tw_admsel") ? (
                                    <div className="flex align-self-end mt-2">
                                      <Button
                                        className="p-button-success"
                                        style={{
                                          color: "#FFFFFF",
                                          background: "#06A502",
                                        }}
                                        onClick={() => goToList(areas.areaNegocioId)}
                                      >
                                        <span className="colorTextAsigAct">
                                          REVISAR ASISTENCIA
                                        </span>
                                      </Button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>  
                                  </div>
              );
            })
          }
            
              </AccordionTab>
            </Accordion>
            </div>
            </>
          : ""}
      </div>

     



      <Toast ref={toast} />
    </>
  );
};
    
  
  
export default ProcessV2;
