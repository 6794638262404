import React, { useEffect, useState } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { loginRequest } from "../../config/authConfig";
import ListaAsistencia from "../ui/V2/ListaAsistencia";
import AsistenciaState from "../../context/Assist/AsistenciaState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

const Asistencia: React.FC<IPage> = (props) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getToken(); 
  }, []); 
  
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      {/* <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
        >
      {profile ? (
        <MainTopbar
          profile={profile}
          onToggleMenuClick={onToggleMenuClick}
          active={"C"}
        />
      ) : (
        ""
      )} */}
      <AsistenciaState>
        <ListaAsistencia token={token} setToken={setToken} getToken={getToken} />
      </AsistenciaState>
      {/* </MsalAuthenticationTemplate> */}
    </>
  );
};

export default Asistencia;
