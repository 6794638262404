interface IconNewContrato {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconNewContrato) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "16"}`}
        height={`${height ? height : "17"}`}
        viewBox="0 0 15 16"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       
     <path d="M1.1236 14.0169C0.814607 14.0169 0.550094 13.9066 0.330056 13.686C0.110019 13.4654 0 13.2002 0 12.8905V1.62641C0 1.31665 0.110019 1.05147 0.330056 0.830882C0.550094 0.610294 0.814607 0.5 1.1236 0.5H12.3596C12.6685 0.5 12.9331 0.610294 13.1531 0.830882C13.3731 1.05147 13.4831 1.31665 13.4831 1.62641V7.80288C13.3084 7.70275 13.1242 7.6214 12.9307 7.55882C12.7372 7.49624 12.5468 7.44618 12.3596 7.40864V1.62641H1.1236V12.8905H6.91011C6.96005 13.0907 7.01637 13.2858 7.07908 13.4757C7.14178 13.6656 7.21654 13.846 7.30337 14.0169H1.1236ZM1.1236 12.0081V12.8905V1.62641V7.40864V7.33354V12.0081ZM2.99625 11.0131H6.94757C6.9975 10.8129 7.05368 10.6189 7.1161 10.4312C7.17853 10.2434 7.25968 10.062 7.35955 9.88673H2.99625V11.0131ZM2.99625 7.82165H9.4382C9.61298 7.73404 9.78152 7.66208 9.94382 7.60576C10.1061 7.54944 10.2871 7.49624 10.4869 7.44618V6.69524H2.99625V7.82165ZM2.99625 4.63016H10.4869V3.50375H2.99625V4.63016ZM11.47 15.5C10.5025 15.5 9.67541 15.1527 8.98876 14.4581C8.30212 13.7635 7.9588 12.9406 7.9588 11.9894C7.9588 11.0077 8.30206 10.1709 8.98858 9.47903C9.67509 8.78713 10.5054 8.44118 11.4794 8.44118C12.4407 8.44118 13.2678 8.78713 13.9607 9.47903C14.6536 10.1709 15 11.0077 15 11.9894C15 12.9406 14.6536 13.7635 13.9607 14.4581C13.2678 15.1527 12.4376 15.5 11.47 15.5ZM11.1985 14.3736H11.8165V12.3085H13.8764V11.689H11.8165V9.6239H11.1985V11.689H9.13858V12.3085H11.1985V14.3736Z"
       fill={fill}/> 
      </svg>



    );
  };
