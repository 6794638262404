interface IIconLoading {
  width?: string;
  height?: string;
  fill?: string;
  className?: any;
  color?: any;
  disabled?: boolean;
  iconInButom?:boolean;
}

export default (props: IIconLoading) => {
  const { width, height, fill, className, color, disabled, iconInButom } = props;

  return (
    <svg
      width={width ?  width : `48`}
      height={height ?  height : `66`}
      viewBox="0 0 48 66"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3 45.15C2.2 43.25 1.375 41.3 0.825 39.3C0.275 37.3 0 35.25 0 33.15C0 26.45 2.325 20.75 6.975 16.05C11.625 11.35 17.3 9 24 9H24.525L19.725 4.2L23.925 0L35.925 12L23.925 24L19.725 19.8L24.525 15H24C19 15 14.75 16.762 11.25 20.286C7.75 23.812 6 28.1 6 33.15C6 34.45 6.15 35.725 6.45 36.975C6.75 38.225 7.2 39.45 7.8 40.65L3.3 45.15ZM24.075 66L12.075 54L24.075 42L28.275 46.2L23.475 51H24C29 51 33.25 49.238 36.75 45.714C40.25 42.188 42 37.9 42 32.85C42 31.55 41.85 30.275 41.55 29.025C41.25 27.775 40.8 26.55 40.2 25.35L44.7 20.85C45.8 22.75 46.625 24.7 47.175 26.7C47.725 28.7 48 30.75 48 32.85C48 39.55 45.675 45.25 41.025 49.95C36.375 54.65 30.7 57 24 57H23.475L28.275 61.8L24.075 66Z"
        fill= {iconInButom ? "#FAFAFA":"#0065DD"}
      />
    </svg>
  );
};
