import { useEffect, useState } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesSinGraficoXl4 } from "../styles";
import logoTeamworkAzul from "../../../../assets/images/pdfPsicolaboral/logoTeamworkAzul.png";
import portada2 from "../../../../assets/images/pdfPsicolaboral/imgPortada2.png";
import { IPDFPagePortada } from "../../../../interfaces/ISeleccion";

const SinGraficoXl4 = (props: IPDFPagePortada) => {
  const { formList } = props;
  const fechactual = new Date();
  const [fortalezas, setFortalezas] = useState<string[]>([]);
  const [oportunidades, setOportunidades] = useState<string[]>([]);
  const [listRender, setListRender] = useState<any>({
    sintesis: "",
    experienciaRelevante: "",
    conocimientosTecnicos: "",
    fortalezas: "",
    oportunidades: "",
    conclusionInforme: "",
    reclutador: "",
  });

  useEffect(() => {
    if (formList) {
      const { contenido } = formList ?? { contenido: {} };
      const { portada } = formList ?? { portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  useEffect(() => {
    if (listRender.fortalezas) {
      const arrayFortalezas = listRender?.fortalezas?.split("\n");
      const arrayOportunidades = listRender?.oportunidades?.split("\n");
      setFortalezas(arrayFortalezas?.filter((item: string) => item != ""));
      setOportunidades(arrayOportunidades?.filter((item: string) => item != ""));
    }
  }, [listRender]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page size="LETTER">
          <View style={stylesSinGraficoXl4.body}>
            <Image src={portada2} style={stylesSinGraficoXl4.imgPortada} />
            <View style={stylesSinGraficoXl4.containerSugerencia}>
              <View style={stylesSinGraficoXl4.containerSugerenciaText}>
                <Text style={stylesSinGraficoXl4.contentSugerenciaTitle}>
                  SÍNTESIS Y SUGERENCIAS
                </Text>
                <Text
                  wrap={false}
                  style={stylesSinGraficoXl4.contentSugerenciaMessage}
                >
                  {listRender.sintesis}
                </Text>
              </View>
            </View>
            <View style={stylesSinGraficoXl4.containerExperiencia}>
              <Text style={stylesSinGraficoXl4.contentExperienciaTitle}>
                EXPERIENCIA RELEVANTE
              </Text>
              <Text style={stylesSinGraficoXl4.contentExperienciaMessage}>
                {listRender.experienciaRelevante}
              </Text>
            </View>
            <View style={stylesSinGraficoXl4.containerConocimiento}>
              <Text style={stylesSinGraficoXl4.contentConocimientoTitle}>
                CONOCIMIENTOS TÉCNICOS
              </Text>
              <Text style={stylesSinGraficoXl4.contentConocimientoMessage}>
                {listRender.conocimientosTecnicos}
              </Text>
            </View>

            <View style={stylesSinGraficoXl4.containerFoda}>
              <View style={stylesSinGraficoXl4.contentFodaCenter}>
                <View style={stylesSinGraficoXl4.contentContainerFodaTitle}>
                  <View
                    style={stylesSinGraficoXl4.contentFodaCenterTextMessage}
                  >
                    <Text style={stylesSinGraficoXl4.contentFodaTitle}>
                      FORTALEZAS
                    </Text>
                    <View style={stylesSinGraficoXl4.contentTextMessage}>
                      {fortalezas.map((item: string, index: number) => (
                        <Text
                          style={stylesSinGraficoXl4.contentFodaMessage}
                          key={index}
                        >{`${item}`}</Text>
                      ))}
                    </View>
                  </View>
                </View>
                <View style={stylesSinGraficoXl4.contentContainerFodaTitle}>
                  <View
                    style={stylesSinGraficoXl4.contentFodaCenterTextMessage}
                  >
                    <Text style={stylesSinGraficoXl4.contentFodaTitle}>
                      OPORTUNIDADES DE MEJORA
                    </Text>
                    <View style={stylesSinGraficoXl4.contentTextMessage}>
                      {oportunidades?.map((item: string, index: number) => (
                        <Text
                          style={stylesSinGraficoXl4.contentFodaMessage}
                          key={index}
                        >{`${item}`}</Text>
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={stylesSinGraficoXl4.containerConclusion}>
              <Text style={stylesSinGraficoXl4.contentConclusionTitle}>
                CONCLUSIÓN
              </Text>
              <Text style={stylesSinGraficoXl4.contentConclusionMessage}>
                {listRender.conclusionInforme}
              </Text>
            </View>
            <View style={stylesSinGraficoXl4.lineaConclusion}></View>
            <Image
              src={logoTeamworkAzul}
              style={stylesSinGraficoXl4.logoTeamWork}
            ></Image>
            <View style={stylesSinGraficoXl4.containerFooter}>
              <Text style={stylesSinGraficoXl4.contentFooterTitle}>
                Psicólogo Evaluador
              </Text>
              <Text style={stylesSinGraficoXl4.contentFooternName}>
                {listRender.reclutador}
              </Text>
              <Text style={stylesSinGraficoXl4.contentFooternDescripcion}>
                La información contenida en este informe es de carácter
                confidencial; debe ser utilizada sólo para los fines de
                selección y por expresa autorización del Psicólogo firmante.
              </Text>
            </View>
            <View style={stylesSinGraficoXl4.containerFooterPie}>
              <Text style={stylesSinGraficoXl4.containerFooterPieText}>
                Copyright{" "}
                {fechactual.getFullYear()}{" "}
                Teamwork Chile
              </Text>
            </View>
          </View>
        </Page>
      )}
    </>
  );
};

export default SinGraficoXl4;
