import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M21 10.5C21 16.3003 16.2986 21 10.5 21C4.70142 21 0 16.3003 0 10.5C0 4.70311 4.70142 0 10.5 0C16.2986 0 21 4.70311 21 10.5ZM10.5 12.6169C9.42439 12.6169 8.55242 13.4889 8.55242 14.5645C8.55242 15.6401 9.42439 16.5121 10.5 16.5121C11.5756 16.5121 12.4476 15.6401 12.4476 14.5645C12.4476 13.4889 11.5756 12.6169 10.5 12.6169ZM8.65094 5.6164L8.96501 11.3745C8.9797 11.6439 9.20249 11.8548 9.47231 11.8548H11.5277C11.7975 11.8548 12.0203 11.6439 12.035 11.3745L12.3491 5.6164C12.3649 5.32536 12.1332 5.08065 11.8418 5.08065H9.1582C8.86674 5.08065 8.63506 5.32536 8.65094 5.6164Z"
      />
    </svg>
  );
};
