import { useEffect, useState, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { v4 as uuidv4 } from "uuid";
import {
  IDropDownList,
  IHandleChangeSearchList,
  IHandleClickVisibleList,
  IOptionDropDownList,
} from "../../../interfaces/IDropDownList";
import { ICategoriesValidationInput } from "../../../interfaces/IInput";

interface IFieldValues {
  value: IOptionDropDownList | undefined;
  valid: boolean;
}

export default (props: IDropDownList) => {
  const {
    list,
    layer,
    onPermaValues,
    onPropertyChange,
    onValueChange,
    placeholder,
    defaultValue,
    disabled, 
  } = props;
  const [dropDownListActive, setDropdownListActive] = useState<boolean>(false);
  const [valueCustomized, setValueCustomized] = useState<string>("");
  const [options, setOptions] = useState<IOptionDropDownList[]>([]);
  const [optionSelected, setOptionSelected] = useState<
    IOptionDropDownList | null | undefined
  >(null);
  const [identifiedAborDDL] = useState<string>(uuidv4());
  const [classAbortDDL] = useState<string>("abort-dropdownlist");
  const [enabledSearch, setEnabledSearch] = useState<boolean>(false);
  const refDropDownListCustomized = useRef<any>();
  const [directionCollapse, setDirectionCollapse] = useState<
    string | undefined
  >(undefined);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [inUseDropDownList, setInUseDropDownList] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("click", handleEventClickWindow);
    window.addEventListener("scroll", handleEventScrollWindow);
    window.addEventListener("resize", handleEventReziseWindow);

    return () => {
      window.removeEventListener("click", handleEventClickWindow);
      window.removeEventListener("scroll", handleEventScrollWindow);
      window.removeEventListener("resize", handleEventReziseWindow);
    };
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setOptions([...list]);
    } else {
      setOptions([]);
    }
  }, [list]);

  useEffect(() => {
    if (defaultValue) {
      setOptionSelected(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!dropDownListActive) {
      if (list && list.length > 0) { 
        setOptions([...list]);
      } else {
        setOptions([]);
      }
      setEnabledSearch(false);
    }
  }, [dropDownListActive]);

  const handleEventScrollWindow = () => {
    handleEventDirectionDropDownList();
  };

  const handleEventReziseWindow = () => {
    handleEventDirectionDropDownList();
  };

  const handleEventClickWindow = (event: any) => {
    const classList = [...event.target.classList];

    if (!classList || classList.length == 0) {
      return setDropdownListActive(false);
    }

    if (!classList.includes(`${classAbortDDL}${identifiedAborDDL}`)) {
      setDropdownListActive(false);
    }
  };

  const handleClickVisibleList = (events: IHandleClickVisibleList) => {
    const { show } = events;

    if (!show) {
      handleEventDirectionDropDownList();
    }

    setInUseDropDownList(true);
    setDropdownListActive(show ? false : true);
  };

  const handleEventDirectionDropDownList = () => {
    try {
      const boxDropDownList = refDropDownListCustomized.current;
      const { height, top } = boxDropDownList.getBoundingClientRect();
      const { outerHeight } = window;
  
      setDirectionCollapse(
        top + 200 + height > outerHeight - height
          ? "apply-collapse-top"
          : undefined
      );
    } catch (error) {
      console.log("Error en dropdownlist", error)
    }
  };

  const handleChangeSearchList = (values: IHandleChangeSearchList) => {
    const { value } = values;
    setEnabledSearch(value !== "" ? true : false);

    const filters = list.filter(({ name }) =>
      name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setOptions(filters);
  };

  const handleClickSelectedOption = (values: IOptionDropDownList) => {
    setOptionSelected(values);
    setDropdownListActive(false);
    handleChangeExternalValue({ value: values, valid: true });
  };

  const handleClickDeletedOptionSelected = () => {
    setOptionSelected(null);
    handleChangeExternalValue({ value: undefined, valid: true });
  };

  const handleChangeExternalValue = (prompt: IFieldValues) => {
    const { value, valid } = prompt;
    if (onPermaValues && onValueChange && onPropertyChange) {
      if (typeof onPropertyChange === "string") {
        const permaValues = { ...onPermaValues };
        permaValues[onPropertyChange] = {
          value,
          valid,
        };
        onValueChange(permaValues);
      }
      if (typeof onPropertyChange === "object") {
        const permaValues = [...onPermaValues];
        const [position, key] = onPropertyChange;
        permaValues[position][key] = {
          value,
          valid,
        };
        onValueChange(permaValues);
      }
    }
  };

  return (
    <div
      className="cols-not-required"
    >
      <div>
        {layer && (
          <label className="layer">
            {layer}
          </label>
        )}
      </div>

      <div
        className={`dropdownlist-customized-box ${
          dropDownListActive
            ? "ddl-customized-box-show"
            : "ddl-customized-box-hidden"
        }`}
      >
        <input
          ref={refDropDownListCustomized}
          placeholder={placeholder ?? "Seleccione"}
          className={`input-dropdownlist-customized ${
            categoriesValidation[eventValidation]
          } ${classAbortDDL}${identifiedAborDDL} ${
            dropDownListActive
              ? "input-ddl-customized-show"
              : "input-ddl-customized-hidden"
          }`}
          readOnly={true}
          onClick={() => handleClickVisibleList({ show: dropDownListActive })}
          value={optionSelected?.name ?? ""}
          disabled={disabled}
        />

        <div
          className={`ddl-icons-options-list ${classAbortDDL}${identifiedAborDDL}`}
        >
          {optionSelected?.code && (
            <>
              <button
                onClick={handleClickDeletedOptionSelected}
                className={`button-delete-list-options ${classAbortDDL}${identifiedAborDDL}`}
              >
                <CloseIcon
                  className={`icon-delete-list-options ${classAbortDDL}${identifiedAborDDL}`}
                />
              </button>
              <span
                className={`clear-box ${classAbortDDL}${identifiedAborDDL}`}
              ></span>
            </>
          )}
          <button
            className={`button-showhidden-list-option ${
              categoriesValidation[eventValidation]
            } ${classAbortDDL}${identifiedAborDDL}`}
            onClick={() => {
              refDropDownListCustomized.current.focus();
              handleClickVisibleList({ show: dropDownListActive });
            }}
            disabled={disabled}
          >
            <KeyboardArrowDownIcon
              className={`arrow-list ${classAbortDDL}${identifiedAborDDL}`}
            />
          </button>
        </div>

        {dropDownListActive && (
          <div
            className={`dropdownlist-customized-list ${directionCollapse} ${classAbortDDL}${identifiedAborDDL}`}
          >
            <div
              className={`ddl-customized-search ${categoriesValidation[eventValidation]} ${classAbortDDL}${identifiedAborDDL}`}
            >
              <input
                placeholder="Buscar"
                className={`input-initial ${classAbortDDL}${identifiedAborDDL}`}
                onChange={({ target }) =>
                  handleChangeSearchList({ value: target.value })
                }
              />
              {enabledSearch && (
                <button
                  onClick={() => handleChangeSearchList({ value: "" })}
                  className={`button-delete-search ${classAbortDDL}${identifiedAborDDL}`}
                >
                  <CloseIcon
                    className={`icon-delete-search ${classAbortDDL}${identifiedAborDDL}`}
                  />
                </button>
              )}
            </div>
            <div
              className={`ddl-customized-overflow ${classAbortDDL}${identifiedAborDDL}`}
            >
              {options.map(({ name, code }, key) => (
                <div
                  key={key}
                  className={`ddl-customized-list-option ${classAbortDDL}${identifiedAborDDL} ${
                    optionSelected?.code === code &&
                    "ddl-customized-list-option-selected"
                  }`}
                  onClick={() => handleClickSelectedOption({ name, code })}
                >
                  {optionSelected?.code !== code ? (
                    <RadioButtonUncheckedIcon />
                  ) : (
                    <RadioButtonCheckedIcon />
                  )}{" "}
                  {name}
                </div>
              ))}
              {options.length == 0 && (
                <div
                  className={`ddl-customized-option-not-data ${classAbortDDL}${identifiedAborDDL}`}
                >
                  {enabledSearch ? (
                    <>No he encontrado resultados</>
                  ) : (
                    <>Sin resultados que mostrar</>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
