import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";

const ActiveProcessV2 = () => {
  const rightContents = () => {
    return (
      <>
        <div className="flex flex-row flex-wrap">
          <div className="">
            <a
              href="/procesosasignadosactivos"
              style={{
                width: "53px",
                height: "19px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                alignItems: "center",
                color: "#AEAEAE",
              }}
            >
              Activos
            </a>
          </div>
          <div className="ml-6">
            <a
              href="/procesosasignadoscerrados"
              style={{
                width: "53px",
                height: "19px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "18px",
                alignItems: "center",
                textDecorationLine: "underline",
                color: "#013776",
              }}
            >
              Finalizados
            </a>
          </div>
        </div>
      </>
    );
  };

  //main return//
  return (
    <>
      <div className="card">
        <Toolbar right={rightContents} className="mr-3"></Toolbar>

        <p
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          En esta opción puede visualizar los procesos de reclutamiento que le
          han sido asignados.
        </p>

        <p
          className="flex justify-content-start"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#2A63D5",
          }}
        >
          Procesos Finalizados EST
        </p>

        <div className="flex flex-row mb-5">
          <span className="material-symbols-outlined md-24 mt-1 iconBlue">
            filter_alt
          </span>
          <span className="mx-5 mt-2">Grupo:</span>
          <Dropdown //value={selectedCity1}
            //options={cities}
            //onChange={onCityChange}
            optionLabel="name"
            placeholder="Todos"
          />
        </div>

        <div className="accordionActiveProcess mt-4 mr-4">
          <Accordion activeIndex={0}>
            <AccordionTab
              header={
                <div>
                  <span className="colorTextVacantes">
                    BÚSQUEDA DE:{" "}
                    <span className="colorTextVacantesName"> AUXILIAR</span>
                  </span>
                </div>
              }
            >
              <div className="flex flex-column">
                <div className="flex flex-wrap justify-content-around surface-200 pt-3 border-round my-3">
                  <div className="flex flex-row">
                    <span className="circuloGrey alignIcon"></span>
                    <span className="ml-2">
                      5585 - <b>ARM AUXILIAR CLINICO - ARAMARK</b>
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <p className="vacantantesMsg"> Q. Vacantes abiertas : 30</p>
                    <p className="mx-3 vacantantesMsg">
                      {" "}
                      Q. Vacantes cubiertas: 8
                    </p>
                    <p className="vacantantesMsg">
                      {" "}
                      Fecha entrega : 20-10 - 2022
                    </p>
                  </div>
                </div>

                <div className="flex flex-wrap justify-content-around surface-200 pt-3 border-round my-3">
                  <div className="flex flex-row">
                    <span className="circuloGrey alignIcon"></span>
                    <span className="ml-2">
                      5585 - <b>ARM AUXILIAR CLINICO - ARAMARK</b>
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <p className="vacantantesMsg"> Q. Vacantes abiertas : 30</p>
                    <p className="mx-3 vacantantesMsg">
                      {" "}
                      Q. Vacantes cubiertas: 8
                    </p>
                    <p className="vacantantesMsg">
                      {" "}
                      Fecha entrega : 20-10 - 2022
                    </p>
                  </div>
                </div>

                <div className="flex flex-wrap justify-content-around surface-200 pt-3 border-round my-3">
                  <div className="flex flex-row">
                    <span className="circuloGrey alignIcon"></span>
                    <span className="ml-2">
                      5585 - <b>ARM AUXILIAR CLINICO - ARAMARK</b>
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <p className="vacantantesMsg"> Q. Vacantes abiertas : 30</p>
                    <p className="mx-3 vacantantesMsg">
                      {" "}
                      Q. Vacantes cubiertas: 8
                    </p>
                    <p className="vacantantesMsg">
                      {" "}
                      Fecha entrega : 20-10 - 2022
                    </p>
                  </div>
                </div>

                <div className="flex align-self-end">
                  <Button className="Todos" style={{ color: "#FFFFFF" }}>
                    <span>
                      <span className="colorTextVacantes">IR A RECLUTAR </span>
                      <span className="colorTextVacantesName">AUXILIARES</span>
                      <i className="pi pi-arrow-right ml-4"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ActiveProcessV2;
