interface IconDelCamp {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconDelCamp) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "31"}`}
        height={`${height ? height : "31"}`}
        viewBox="0 0 31 31"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <rect x="1" y="1" width="29" height="29" rx="14.5" fill="#FAFAFA"/>
<path d="M12.5516 7.5877C12.7309 7.22578 13.0994 7 13.5012 7H17.4988C17.9006 7 18.2691 7.22578 18.4484 7.5877L18.6875 8.0625H21.875C22.4627 8.0625 22.9375 8.5373 22.9375 9.125C22.9375 9.7127 22.4627 10.1875 21.875 10.1875H9.125C8.5373 10.1875 8.0625 9.7127 8.0625 9.125C8.0625 8.5373 8.5373 8.0625 9.125 8.0625H12.3125L12.5516 7.5877ZM9.125 11.25H21.875V21.875C21.875 23.0471 20.9221 24 19.75 24H11.25C10.0779 24 9.125 23.0471 9.125 21.875V11.25ZM12.3125 13.375C12.0203 13.375 11.7812 13.6141 11.7812 13.9062V21.3438C11.7812 21.6359 12.0203 21.875 12.3125 21.875C12.6047 21.875 12.8438 21.6359 12.8438 21.3438V13.9062C12.8438 13.6141 12.6047 13.375 12.3125 13.375ZM15.5 13.375C15.2078 13.375 14.9688 13.6141 14.9688 13.9062V21.3438C14.9688 21.6359 15.2078 21.875 15.5 21.875C15.7922 21.875 16.0312 21.6359 16.0312 21.3438V13.9062C16.0312 13.6141 15.7922 13.375 15.5 13.375ZM18.6875 13.375C18.3953 13.375 18.1562 13.6141 18.1562 13.9062V21.3438C18.1562 21.6359 18.3953 21.875 18.6875 21.875C18.9797 21.875 19.2188 21.6359 19.2188 21.3438V13.9062C19.2188 13.6141 18.9797 13.375 18.6875 13.375Z" 
fill="#0065DD"/>
<rect x="1" y="1" width="29" height="29" rx="14.5" stroke="#0065DD" stroke-width="2"/>
      </svg>



    );
  };
 