import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import SeccionDetalleProceso from "./SeccionDetalleProceso";

interface IModalDetalleProceso {
  title: string;
  onChangeBack?: Function;
  buttonBack?: string;
  list?: any[];
  token: string | undefined;
  getToken: Function;
}

export default (props: IModalDetalleProceso) => {
  const { title, onChangeBack, buttonBack, list, token } = props;
  const { getProcesos, listProcesos } = useContext(SeleccionContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token && list) {
      getProcesos({ token, procesos: list, setIsLoading });
    }
  }, [token]);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="modal-container-seleccion">
            <div
              className="modal-content-seleccion w-80"
            >
              <div className="modal-title">
                <span>{title}</span>
              </div>

              {list && (
                <>
                  {listProcesos &&
                    listProcesos.map((item: any) => {
                      return (
                        <SeccionDetalleProceso
                          list={item}
                          listaMasiva={false}
                          soloDetalle={true}
                        />
                      );
                    })}
                </>
              )}

              <div className="modal-button-content center">
                <>
                  <button
                    className={`modal-button cancel`}
                    onClick={handleClickCloseModal}
                  >
                    {buttonBack ? buttonBack : "Cerrar"}
                  </button>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
