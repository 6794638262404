import { Component } from "react";

interface IconPen {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconPen) => {
    const { width, height, stroke, style, className, color, fill } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "18"}`}
        style={style}
        color={color}
        viewBox="0 0 15 18"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.95 7.26H6.05C5.88984 7.26 5.76 7.38984 5.76 7.55V10.45C5.76 10.6102 5.88984 10.74 6.05 10.74H8.95C9.11016 10.74 9.24 10.6102 9.24 10.45V7.55C9.24 7.38984 9.11016 7.26 8.95 7.26Z" 
        fill="#BE2A2A"/>
<path d="M14 0.5H1C0.45 0.5 0 0.95 0 1.5V16.5C0 17.05 0.45 17.5 1 17.5H14C14.55 17.5 15 17.05 15 16.5V1.5C15 0.95 14.55 0.5 14 0.5ZM7.5 13.5C5.01 13.5 3 11.49 3 9C3 6.51 5.01 4.5 7.5 4.5C9.99 4.5 12 6.51 12 9C12 11.49 9.99 13.5 7.5 13.5Z"
 fill="#BE2A2A"/>
      </svg>



    );
  };

