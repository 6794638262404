import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import ProcessRequestModalV2 from "./ProcessRequestModalV2";
import ProcessCreateGroupModal from "./ProcessCreateGroupModal";
import { Toast } from "primereact/toast";
import { useMsal } from "@azure/msal-react";


const RequestsProcessTableComponent = () => {
  const [token, setToken] = useState<any>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [filterByGroup, setfilterByGroup] = useState<any[]>([]);
  const [groupsReload, setGroupsReload] = useState<any[]>([]);
  const [groupFilter, setGroupFilter] = useState("");
  const history = useHistory();
  const [busquedaModal, setBusquedaModal] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profile, setProfile] = useState<any>(null);
  const toast = useRef<Toast>(null);
  const [loadingManualProcess, setLoadingManualProcess] = useState(false);
  const { instance } = useMsal();
  const [isModalVisibleGroup, setIsModalVisibleGroup] = useState(false);

  // const rightContents = () => {
  //   return (
  //     <>
  //       <div className="flex flex-row flex-wrap">
  //         <div className="">
  //           <a
  //             href="/solicitudes"
  //             style={{
  //               width: "53px",
  //               height: "19px",
  //               fontFamily: "Roboto",
  //               fontStyle: "normal",
  //               fontWeight: "400",
  //               fontSize: "18px",
  //               lineHeight: "18px",
  //               alignItems: "center",
  //               textDecorationLine: "underline",
  //               color: "#013776",
  //             }}
  //           >
  //             Solicitudes
  //           </a>
  //         </div>
  //         <div className="ml-6">
  //           <a
  //             href="/procesosasignadosactivos"
  //             style={{
  //               width: "53px",
  //               height: "19px",
  //               fontFamily: "Roboto",
  //               fontStyle: "normal",
  //               fontWeight: "400",
  //               fontSize: "16px",
  //               lineHeight: "18px",
  //               alignItems: "center",
  //               color: "#AEAEAE",
  //             }}
  //           >
  //             Activos
  //           </a>
  //         </div>
  //         <div className="ml-6">
  //           <a
  //             href="/router"
  //             style={{
  //               width: "53px",
  //               height: "19px",
  //               fontFamily: "Roboto",
  //               fontStyle: "normal",
  //               fontWeight: "400",
  //               fontSize: "16px",
  //               lineHeight: "18px",
  //               alignItems: "center",
  //               color: "#AEAEAE",
  //             }}
  //           >
  //             Finalizados
  //           </a>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };
  const onBackdropClick = () => {
    setIsModalVisibleGroup(false);
    setIsModalVisible(false);
  };

  const onBackdropClickSave = () => {   
    setIsModalVisible(false);
    window.location.reload();
  };
  const closeFullModal = (flag: any) => {
    if (flag == 2) {
      creartToast(
        toast,
        "success",
        "Asignación",
        "Guardado correctamente",
        3000
      );
    }
    setIsModalVisible(false);
    getGroups();
  };

  const convertToDate = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const changeGroup = (event: any) => {
    setGroupFilter(event.target.value);
    if (event.value === "") {
      setGroups(groupsReload);
    } else {
      const clientFilter = groupsReload.filter(
        (group) => group.cargoGroupId === event.value
      );
      setGroups(clientFilter);
    }
  };

  const goToModal = (busqueda: any) => {
    setBusquedaModal(busqueda);
    setIsModalVisible(true);
  };

  const goToModalGroup = () => {
        
    setIsModalVisibleGroup(true);
  };

  //useEffect

  useEffect(() => {
    getToken();
  });

  useEffect(() => {
    getToken();
    if (token) {
      getGroups();
    }
  }, [token]);

  const getGroups = () => {
    api
      .get(`busqueda-unassigned?userId=${sessionStorage.getItem("usuarioId")}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroups(response.data.data[0]);
        setGroupsReload(response.data.data[0]);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  useEffect(() => {
    let newArr: any[] = [];
    groupsReload.map((group: any) => {
      const newObj = {
        value: group.cargoGroupId,
        label: group.cargoGroupName,
      };
      newArr = [...newArr, newObj];
    });
    const newObj = {
      value: "",
      label: "Todos",
    };
    newArr = [newObj, ...newArr];
    setfilterByGroup(newArr);
  }, [groups]);

  const getToRefresh = () => {
        setLoadingManualProcess(true)
    api
      .get(`/busqueda-proceso-solicitudes-manual`, { 
        params: { usuarioId: instance.getActiveAccount()?.localAccountId },
        headers: {
          Authorization: `Bearer ${token}`,          
        },
      })
      .then((response) => {        
        getGroups();
        creartToast(
          toast,
          "success",
          "Actualizado",
          "Creados:"+ (response.data.summary.creados)+ "  " +
          "Actualizado:"+(response.data.summary.updated) ,
          5000
        );
        
      })
      .catch((error) => {
        console.log("Error al descargar Actualizar");
      })
      .finally(() => setLoadingManualProcess(false));
  };

  //main return//
  return (
    <>
      <div className="card">
        {/* <Toolbar right={rightContents} className="mr-3"></Toolbar> */}

        <span
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          En esta opción puede visualizar las solicitudes de asignación de
          procesos de reclutamiento realizadas por los KAM.
        </span>

        <span
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          Asigne los equipos de trabajo y los cargo MOD según sea el caso.
        </span>

        <p
          className="flex justify-content-start"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#2A63D5",
          }}
        >
          Procesos Activos EST
        </p>        
        <div className=" align-self-end mr-5" style={{"float": "right"}}>
          <Button
            id="btnAddCtx2"            
            style={{ color: "white", background: `${ loadingManualProcess ? "#D5D5D5" : "#FFB400"}`, border: "none", "height": "40px" }}
            label="Actualizar"
            icon="pi pi-refresh"
            iconPos="right"
            loading={loadingManualProcess}            
            onClick={() => getToRefresh()}
            disabled={loadingManualProcess}
            
          />
          </div>

          <div className=" align-self-end mr-5" style={{"float": "right"}}>
            <Button
              id="btnContRegCtx1"
              style={{ "height": "40px"}}
              label="Crear grupo"
              icon="pi pi-plus-circle" 
              iconPos="right"
              className=".p-button-icon-right "
              onClick={() => goToModalGroup()}               
              autoFocus
            />
          </div>

        <div className="flex flex-row mb-5">
          <span className="material-symbols-outlined md-24 mt-1 iconBlue">
            filter_alt
          </span>
          <span className="mx-5 mt-2">Grupo:</span>
          {filterByGroup ? (
            <Dropdown
              value={groupFilter}
              options={filterByGroup}
              onChange={changeGroup}
              optionLabel="label"
              placeholder="Todos"
            />
          ) : (
            ""
          )}
        </div>

        {groups
          ? groups.map((group: any) => {
              return (
                <div className="accordionActiveProcess mt-4 mr-4">
                  <Accordion activeIndex={0}>
                    <AccordionTab
                      header={
                        <div>
                          <span className="colorTextVacantes">
                            BÚSQUEDA DE:{" "}
                            <span className="colorTextVacantesName">
                              {" "}
                              {group.cargoGroupName}
                            </span>
                          </span>
                        </div>
                      }
                    >
                      <div className="flex flex-column">
                        {group
                          ? group.busquedas.map((busqueda: any) => {
                              return (
                                <>
                                  <div
                                    className="flex flex-wrap justify-content-between pt-3 border-round my-3 py-3 "
                                    style={{
                                      background: "#2A63D5",
                                      color: "#ffffff",
                                    }}
                                  >
                                    <div className="flex flex-row ml-8">
                                      {busqueda.busquedaUrgencia === 2 ? (
                                        <span className="circuloRed alignIcon"></span>
                                      ) : busqueda.busquedaUrgencia === 1 ? (
                                        <span className="circuloYellow alignIcon"></span>
                                      ) : busqueda.busquedaUrgencia === 0 ? (
                                        <span className="circuloGreen alignIcon"></span>
                                      ) : (
                                        ""
                                      )}

                                      <span className="ml-2">
                                        {busqueda.busquedaId} -{" "}
                                        {busqueda.cargoNombre} -{" "}
                                        {busqueda.clienteNombre}{" "}
                                      </span>
                                    </div>
                                  </div>

                                  <div>
                                    <table className="tablaInfo">
                                      <tr>
                                        <td className="tablasTitle ">ID:</td>
                                        <td>{busqueda.busquedaId}</td>
                                        <td className="tablasTitle">
                                          Empresa:
                                        </td>
                                        <td>{busqueda.empresa}</td>
                                        <td className="tablasTitle">
                                          Cliente:
                                        </td>
                                        <td>{busqueda.clienteNombre}</td>
                                        <td className="tablasTitle">
                                          Área de negocio
                                        </td>
                                        <td>{busqueda.areaNegocioNombre}</td>
                                      </tr>
                                      <tr>
                                        <td className="tablasTitle">KAM:</td>
                                        <td>En Desarrollo</td>
                                        <td className="tablasTitle">
                                          Q. cupos:
                                        </td>
                                        <td>{busqueda.busquedaCantidad}</td>
                                        <td className="tablasTitle">
                                          Fecha solicitud:
                                        </td>
                                        <td>
                                          {convertToDate(
                                            busqueda.busquedaInicio
                                          )}
                                        </td>
                                        <td className="tablasTitle">
                                          Fecha entrega:
                                        </td>
                                        <td>
                                          {convertToDate(busqueda.busquedaFin)}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>

                                  {profile &&
                                  (profile == "adm_tw" ||
                                    profile == "tw_admsel" ||
                                    profile == "tw_admsel_prof") ? (
                                    <div className="flex align-self-end mt-2">
                                      <Button
                                        className="p-button-success"
                                        style={{
                                          color: "#FFFFFF",
                                          background: "#06A502",
                                        }}
                                        onClick={() => goToModal(busqueda)}
                                      >
                                        <span className="colorTextAsigAct">
                                          ASIGNAR Y ACTIVAR
                                        </span>
                                      </Button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          : ""}
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              );
            })
          : ""}
      </div>

      <ProcessRequestModalV2
        token={token}
        isModalVisible={isModalVisible}
        onClose={onBackdropClick}
        onCloseSave={onBackdropClickSave}
        busqueda={busquedaModal}
        closeFullModal={closeFullModal}
      />

<ProcessCreateGroupModal
        token={token}
        isModalVisibleGroup={isModalVisibleGroup}
        onClose={onBackdropClick}
        onCloseSave={onBackdropClickSave}
        busqueda={busquedaModal}
        
        
      />

      <Toast ref={toast} />
    </>
  );
};

export default RequestsProcessTableComponent;
