interface ITitleSistema {
  title: string; 
  subtitle: string; 
  note?: string; 
}

const TitleSistema = (props: ITitleSistema) => {
  const { title, subtitle, note } = props;

  return (
    <>
      <section className="section-title-page">
        <h1 className="title-page">{title}</h1>
        <p className="subtitle-page">{subtitle}</p>
        {note && (
          <p className="note-page">{note}</p>
        )}
      </section>
    </>
  );
};

export default TitleSistema;
