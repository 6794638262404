import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import "../../../assets/css/adminModal.min.css";
import Select from "../../ui/adminAssist/Select";
import SelectOption from "../../ui/adminAssist/SelectOption";
import { InputText } from "primereact/inputtext";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DropDownListCustomized from "../../ui/kit-mdtw/DropDownListCustomized";
import CloseIcon from "@mui/icons-material/Close";
import InputDateCustomized from "../../ui/kit-mdtw/InputDateCustomized";

interface ISendCodeModal {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  massive?: boolean;
  width?: string;
  list?: any[];
}

export default (props: ISendCodeModal) => {
  const {
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    massive,
    width,
    list,
  } = props;
  const [visibleInfo, setVisibleInfo] = useState<boolean>(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  const handleChangeVisibleInfo = () => {
    setVisibleInfo(visibleInfo === true ? false : true);
  };
  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div
            className="modal-subtitle"
            style={massive ? { textAlign: "center" } : { textAlign: "start" }}
          >
            <span>{subTitle}</span>
          </div>
          <div
            className="modal-message"
            style={
              massive
                ? { textAlign: "center" }
                : { textAlign: "start", fontSize: "15px" }
            }
          >
            <span>{message}</span>
          </div>
          {list ? (
            <>
              {list.map((code, key) => {
                return (
                  <>
                    {massive ? (
                      <>
                        <div key={key} className="modal-section-reasing">
                          <span>Servicio N°{code}</span>
                          <ButtonIcon onClick={handleChangeVisibleInfo}>
                            {visibleInfo ? (
                              <KeyboardArrowUpIcon
                                style={{ fontSize: "35px" }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                style={{ fontSize: "35px" }}
                              />
                            )}
                          </ButtonIcon>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {massive ? (
                      <>
                        <div
                          style={
                            visibleInfo
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div className="project-table">
                            <div className="content">
                              <div className="project-table-title">Nombre</div>
                              <div className="project-table-subtitle">
                                Olegario Cepeda Asenjo
                              </div>
                            </div>
                            <div className="content">
                              <div className="project-table-title">Correo</div>
                              <div className="project-table-subtitle">
                                nombre@correo.cl
                              </div>
                            </div>
                            <div className="content">
                              <div className="project-table-title">
                                N° de invitaciones
                              </div>
                              <div className="project-table-subtitle">
                                <DropDownListCustomized
                                  list={[{ code: "1", name: "1" }]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <div className="project-table">
                            <div className="content">
                              <div className="project-table-title">Nombre</div>
                              <div className="project-table-subtitle">
                                Olegario Cepeda Asenjo
                              </div>
                            </div>
                            <div className="content">
                              <div className="project-table-title">Correo</div>
                              <div className="project-table-subtitle">
                                nombre@correo.cl
                              </div>
                            </div>
                            <div className="content">
                              <div className="project-table-title">
                                N° de invitaciones
                              </div>
                              <div className="project-table-subtitle">
                                <DropDownListCustomized
                                  list={[{ code: "1", name: "1" }]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
                <div className="project-table">
                  <div className="content">
                    <div className="project-table-title">Nombre</div>
                    <div className="project-table-subtitle">
                      Olegario Cepeda Asenjo
                    </div>
                  </div>
                  <div className="content">
                    <div className="project-table-title">Correo</div>
                    <div className="project-table-subtitle">
                      nombre@correo.cl
                    </div>
                  </div>
                  <div className="content">
                    <div className="project-table-title">
                      N° de invitaciones
                    </div>
                    <div className="project-table-subtitle">
                      <DropDownListCustomized
                        list={[{ code: "1", name: "1" }]}
                      />
                    </div>
                  </div>
                </div>

                <div className="project-table">
                  <div className="content">
                    <div className="project-table-title">Nombre</div>
                    <div className="project-table-subtitle">
                      Olegario Cepeda Asenjo
                    </div>
                  </div>
                  <div className="content">
                    <div className="project-table-title">Correo</div>
                    <div className="project-table-subtitle">
                      nombre@correo.cl
                    </div>
                  </div>
                  <div className="content">
                    <div className="project-table-title">
                      N° de invitaciones
                    </div>
                    <div className="project-table-subtitle">
                      <DropDownListCustomized
                        list={[{ code: "1", name: "1" }]}
                      />
                    </div>
                  </div>
                </div>
            </>
          )}

          {!massive ? (
            <>
              <div className="content-bottom-send">
                <div className="content-text">
                  <span>
                    Si deseas programar el envío, selecciona una fecha y
                    presiona Enviar
                  </span>
                </div>
                <div className="content-text-date">
                <InputDateCustomized
                  placeholder="dd/mm/aaaa"
                  layer="Fecha"
                  required={true}
                  maxLength={10}
                />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) onChangeBack();
              }}
            >
              No Volver
            </Button>
            <Button
              className="button-go-confirm"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Enviar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
