import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { ICandidatoSolEnviada } from "../../../../../interfaces/ISeleccion";

interface IModalListadoSolicitarCandidato {
  rut: any[];
  solicitudes: any;
  setSolicitudes: Function;
  handleClickSolicitarCandidato: Function;
  onChangeBack: Function; 
}

export default (props: IModalListadoSolicitarCandidato) => {
  const { rut, solicitudes, setSolicitudes, handleClickSolicitarCandidato, onChangeBack } =
    props;
  const { listaCandidatoNoDisponible } = useContext(SeleccionContext);
  const [currentData, setCurrentData] = useState<ICandidatoSolEnviada[]>([]);
  const [solEnviada, setSolEnviada] = useState<boolean | undefined>(undefined);

  useEffect(() => { 
    if (rut && listaCandidatoNoDisponible) {
      console.log(listaCandidatoNoDisponible)
      const current = listaCandidatoNoDisponible.filter(
        (item: ICandidatoSolEnviada) => rut.includes(item.personaDNI)
      );
      setCurrentData(current);
      const soli = current.map(
        ({ solicitudTraspasoId }: ICandidatoSolEnviada) => {
          return {
            solicitudTraspasoId,
            aceptar: true,
          };
        }
      );
      setSolicitudes(soli);
    }
  }, [rut]);

  const handleClickRemoveList = (values: any) => {
    try {
      const { personaDNI } = values; 
      const current = currentData.filter((item: ICandidatoSolEnviada) => item.personaDNI !== personaDNI); 
      setCurrentData(current) 
    } catch (error) {
      console.log("", error)
    } 
  }

  return (
    <>
      {rut &&
        currentData.map((item: any) => {
          const {
            busquedaId,
            personaDNI,
            primerApellido,
            primerNombre,
            reclutador,
            fechaPosutlacion,
            busPerId,
          } = item;

          return (
            <>
              <tr className="tr-table-restaurar">
                <td className="td-table-restaurar">{personaDNI}</td>
                <td className="td-table-restaurar">
                  {primerNombre} {primerApellido}
                </td>
                <td className="td-table-restaurar">
                  {formatDateToDDMMAAAA({
                    date: fechaPosutlacion,
                    format: "/",
                  })}
                </td>
                <td className="td-table-restaurar">{reclutador}</td>
                <td className="td-table-restaurar">{busquedaId}</td>
                <td className="td-table-restaurar">
                  <div className="content-icon-table">
                    {!solEnviada ? (
                      <>
                        <button
                          onClick={() => {
                            handleClickSolicitarCandidato({procesoDestino: busPerId, setSolEnviada});
                            setSolEnviada(true); 
                          }}
                          className="button-solicitar-candidato solicitar"
                        >
                          Solicitar
                          <ArrowForwardIcon />
                        </button>
                        <button 
                        onClick={() => handleClickRemoveList({personaDNI})}
                          className="button-delete-table-restaurar">
                          <CloseIcon />
                        </button>
                      </>
                    ) : (
                      <label className="sol-enviada">Solicitud Enviada</label>
                    )}
                  </div>
                </td>
              </tr>
            </>
          );
        })}
    </>
  );
};
