import { useEffect, useState } from "react";
import InputFileDocumentCustomized from "../../InputFileDocumentCustomized";
import DropDownListCustomized from "../../DropDownListCustomized";
import InputDateCustomized from "../../InputDateCustomized";
import {
  IDocumentoCandidate,
  IListadoDocumentoUpdateCandidate,
} from "../../../../../interfaces/ISeleccion";
import IconDocLoaded from "../../../kit-svg/icon-loading-document/IconDocLoaded";
import IconEyeDocument from "../../../kit-svg/IconEyeDocument";
import IconTrasher from "../../../kit-svg/IconTrasher";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import IconDocLoading from "../../../kit-svg/icon-loading-document/IconDocLoading";
import IconDocNotLoaded from "../../../kit-svg/icon-loading-document/iconDocNotLoaded";
import IconDocNew from "../../../kit-svg/icon-loading-document/IconDocNew";
import IconDescarga from "../../../kit-svg/iconDescarga";
import IconDescargaNomina from "../../../icons-seleccion/IconDescargaNomina";

export default (props: IListadoDocumentoUpdateCandidate) => {
  const {
    list,
    formUpDocument,
    setFormUpDocument,
    documentFilter,
    acciones,
    documentosCargados,
    setSelectDocument,
    deleteDocument,
  } = props;

  const [newForm, setNewForm] = useState<IDocumentoCandidate>(list);
  const [dateDisabled, setDateDisabled] = useState<boolean>(true);
  const [tipoFormato, setTipoFormato] = useState<string>("");
  const [tipoExpira, setTipoExpira] = useState<boolean>(false);
  const [dateDefaultCustom] = useState<string | undefined>(
    formatDateToDDMMAAAA({ date: list.fechaExpiracion?.value })
  );

  useEffect(() => {
    if (newForm.tipoDocumentos) {
      documentFilter.find(({ code, expira, formato }: any) => {
        if (code === newForm.tipoDocumentos.value?.code) {
          setDateDisabled(!expira);
          setTipoFormato(formato);
          setTipoExpira(expira);
        }
      });
    }
  }, [newForm.tipoDocumentos]);

  useEffect(() => {
    if (newForm) {
      const updatedDocuments = formUpDocument.map(
        (item: IDocumentoCandidate) => {
          if (item.id === newForm.id) {
            return newForm;
          }
          return item;
        }
      );
      setFormUpDocument(updatedDocuments);
    }
  }, [newForm]);

  useEffect(() => {
    if (newForm.estado == "NUEVO") {
      const updatedDocuments = formUpDocument.map(
        (item: IDocumentoCandidate) => {
          if (item.id === newForm.id) {
            return { ...newForm, formato: tipoFormato, expira: tipoExpira };
          }
          return item;
        }
      );
      setFormUpDocument(updatedDocuments);
    }
  }, [newForm, tipoFormato]);

  // useEffect(() => {
  //   console.log({ formUpDocument });
  // }, [formUpDocument]);

  return (
    <>
      {newForm && (
        <>
          {documentosCargados ? (
            <>
              <div className="container-documentos">
                <div className="content-documentos">
                  <div className="content-input-cargar">
                    <div>
                      {newForm.estado === "ERROR" && (
                        <IconDocNotLoaded fill="#B02727" />
                      )}
                      {newForm.estado === "NUEVO" && (
                        <IconDocNew fill="#B1B1B1" />
                      )}
                      {newForm.estado === "CARGANDO" && (
                        <IconDocLoading fill="#0065DD" />
                      )}
                      {newForm.estado === "CARGADO" && (
                        <IconDocLoaded fill="#45AA43" />
                      )}
                    </div>
                    <div className="w-40">
                      <InputFileDocumentCustomized
                        required={false}
                        layer="Nombre Archivo"
                        placeholder={
                          newForm.nombreOriginal ??
                          newForm.tipoDocumentos?.value?.name ??
                          ""
                        }
                        maxSizeMb={10}
                        errorPosition="down"
                        iconFile
                        enlaceDescarga={newForm.file?.value}
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="file"
                        error={newForm.estado == "ERROR"}
                      />
                    </div>
                    <div className="w-40">
                      <DropDownListCustomized
                        layer="Tipo Documento"
                        list={documentFilter ?? []}
                        defaultValue={newForm.tipoDocumentos?.value}
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="tipoDocumentos"
                        error={newForm.estado == "ERROR"}
                      />
                    </div>
                    <div className="w-20">
                      <InputDateCustomized
                        required={!dateDisabled}
                        layer="Expiración"
                        maxLength={10}
                        placeholder="DD/MM/YYYY"
                        errorPosition="down"
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="fechaExpiracion"
                        defaultValue={!dateDisabled ? dateDefaultCustom : ""}
                        disabled={dateDisabled}
                        error={newForm.estado == "ERROR"}
                        enableDateValidation
                      />
                    </div>
                    {acciones && (
                      <div className="modal-button-content document-masive">
                        <a
                          className="modal-button document-masive cancel"
                          href={newForm.file.value ?? ""}
                          target="_blank"
                        >
                          {/* <IconEyeDocument fill="#0065DD" /> */}
                          <IconDescargaNomina fill="#0065DD" />
                        </a>
                        <button
                          className="modal-button document-masive delete-white"
                          onClick={() =>
                            deleteDocument &&
                            deleteDocument(
                              newForm.id,
                              newForm.tipoDocumentos?.value?.code
                            )
                          }
                        >
                          <IconTrasher fill="#B02727" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container-documentos">
                <div className="content-documentos">
                  <div className="content-input-cargar">
                    <div className="w-40">
                      <InputFileDocumentCustomized
                        required={false}
                        layer="Nombre Archivo"
                        placeholder={newForm.file?.value?.name ?? ""}
                        maxSizeMb={10}
                        errorPosition="down"
                        enlaceDescarga={newForm.file?.value?.path}
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="file"
                        iconFile
                      />
                    </div>
                    <div className="w-40">
                      <DropDownListCustomized
                        layer="Tipo Documento"
                        list={documentFilter ?? []}
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="tipoDocumentos"
                      />
                    </div>
                    <div className="w-20">
                      <InputDateCustomized
                        required={!dateDisabled}
                        layer="Expiración"
                        maxLength={10}
                        placeholder="DD/MM/YYYY"
                        errorPosition="down"
                        onPermaValues={newForm}
                        onValueChange={setNewForm}
                        onPropertyChange="fechaExpiracion"
                        disabled={dateDisabled}
                        enableDateValidation
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
