import { useState, useEffect, useRef } from "react";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { useHistory } from "react-router-dom";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import ModalDiscard from "../../../utils/ModalDiscard";
import { Toast } from "primereact/toast";
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Tooltip } from "@mui/material";
import { TabView, TabPanel } from "primereact/tabview";
import AddFormComponent from "./AddFormComponent";

//const & functions

const EditSpecialFieldsComponent = () => {
  const [fieldsArray, setFieldsArray] = useState<any[]>([]);
  const [token, setToken] = useState<any>(null);
  const [clientsNames, setClientsNames] = useState<any>([]);
  const [clientName, setClientName] = useState<any>([]);
  const [clientFields, setClientFields] = useState<any>([]);
  const [clientSpecialFields, setClientSpecialFields] = useState<any>([]);
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);
  const history = useHistory();
  const menu = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const [activeTabMenu, setActiveTabMenu] = useState(0); 

  const updateLabelOnArray = (value: string, pos: number) => {
    const newArr = [...fieldsArray];
    newArr[pos].label = value;
    setFieldsArray(newArr);
  };

  const updateOptionOnArray = (value: string, pos: number, j: number) => {
    const newArr = [...fieldsArray];
    newArr[pos].options[j] = value;
    setFieldsArray(newArr);
  };

  const processNames = (response: any) => {
    const newArr: any[] = [];
    response.forEach((element: any) => {
      element.newName = element.clienteId
        .concat(" - ")
        .concat(element.clienteNombre);
      newArr.push(element);
    });
    setClientsNames(newArr);
  };

  const CentralContainer = () => {
    return (
      <>
        <div className="flex justify-content-start">
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "15px",
              lineHeight: "156%",
              alignItems: "center",
              color: "#333333",
              fontWeight: "400"
            }}
          >
            
            <p
              style={{
                fontSize:"15px",
                color:"#333333",
                textAlign: "left",
                marginBottom: "2rem"
              }}
            >
              En esta opción puedes crear una nómina de clientes y sus
              requerimientos especiales.
            </p>
            <p
              style={{
                fontSize:"15px",
                color:"#2A63D5",
                textAlign: "left",
              }}
            >
              Para comenzar selecciona el área de negocios.
            </p>
          </div>
        </div>
      </>
    );
  };

  const addFieldInput = (type: string) => {
    const field = {
      label: "",
      type: type,
      min: 0,
      max: 0,
      options: [""],
    };
    const newArr = [...fieldsArray, field];
    setFieldsArray(newArr);
  };

  const addOption = (pos: number) => {
    const newArr = [...fieldsArray];
    newArr[pos].options.push("");
    setFieldsArray(newArr);
  };

  const fieldTypes = [
    { name: "Texto corto", code: "text" },
    { name: "Numero", code: "number" },
    { name: "Opciones", code: "Select" },
    { name: "Fecha", code: "Date" },
  ];

  const deleteField = (position: number) => {
    fieldsArray.splice(position, 1);
    const newArr = [...fieldsArray];
    setFieldsArray(newArr);
  };

  const deleteOption = (position: number, j: number) => {
    fieldsArray[position].options.splice(j, 1);
    const newArr = [...fieldsArray];
    setFieldsArray(newArr);
  };

  const cerrarModalDiscard = () => {
    setVisibleModalDiscard(false);
    // buscarCandidatos();
  };

  const onChangeBussinessZone = (e: any) => {
    setClientName(e.target.value);
    getClientFields(e.target.value);
    getClientSpecialFields(e.target.value);
  };

  const showText = (type: string) => {
    addFieldInput(type);
  };

const optionText = ()=>{showText("text")}
const optionNumero = ()=>{showText("number")}
const optionFecha = ()=>{showText("date")}
const optionOpciones = ()=>{showText("Select")}


  const optionsInputs = [
    {
      label: "Texto  ",
      command: () => showText("text"),
    },
    {
      label: "Numero",
      command: () => showText("number"),
    },
    {
      label: "Fecha",
      command: () => showText("date"),
    },
    {
      label: "Opciones",
      command: () => showText("Select"),
    },
  ];

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  //API Call(s)

  const saveSpecialFields = () => {
    const arr: any = [];
    fieldsArray.forEach((field: any, pos: number) => {
      let jSon: any[] = [];
      let min = 0;
      let max = 0;
      if (field.type == "text") {
        min = 0;
        max = 200;
      } else if (field.type == "number") {
        min = 0;
        max = 999999999;
      } else {
        min = 0;
        max = 0;
      }
      if (field.type == "Select" || field.type == "select") {
        field.options.forEach((option: string, i: number) => {
          const newOption = {
            codigo: i,
            valor: option,
          };
          jSon.push(newOption);
        });
      }

      const campo = {
        campEspAsigId: field.campEspAsigId,
        campEspId: field.campEspId,
        cargoId: null,
        clienteId: clientName,
        campTextMin: min,
        campTextMax: max,
        campSelectJson: JSON.stringify(jSon),
        campNumeroMin: min,
        campNumeroMax: max,
        campoFechaMin: "",
        campFechaoMax: "",
        campEspAsigOrden: pos,
        orgId: "",
        newCampoEspecial: {
          campEspNombre: field.label,
          tipoCampo:
            field.type == "text"
              ? "Text"
              : field.type == "number"
              ? "Number"
              : field.type == "date"
              ? "Date"
              : field.type == "Select"
              ? "Select"
              : field.type == "select"
              ? "Select"
              : "",
        },
      };
      arr.push(campo);
    });
    const specialFieldsData = {
      clienteId: clientName,
      assignments: arr,
    };
    
    api
      .put(`/campoEspecialAsigna-modify/`, specialFieldsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        setClientName("");
        setFieldsArray([]);
        creartToast(
          toast,
          "success",
          "Campos Especiales",
          "Guardado correctamente",
          3000
        );
      })
      .catch((error) => {
      });
  };

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {
        history.push("/");
      });
  };

  const getClientName = () => {
    getToken();
    api
      .get(`cliente`, {
        params: { limit: 1000 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        processNames(response.data.data);
      
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getClientFields = (clientId: string) => {
    getToken();
    api
      .get(`campoExcelAsigna-columns/?clienteId=${clientId}`, {
        params: { limit: 1000 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClientFields(response.data.data[0].columns);
     
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
   
  const getClientSpecialFields = (clientId: string) => {
    getToken();
    api
      .get(`campoEspecialValorAsigna/?clienteId=${clientId}`, {
        params: { limit: 1000 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClientSpecialFields(response.data.data);
       
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const cancelar = () => { 
    creartToast(
      toast,
      "error",
      "Campos cancelados",
      "No se han guardado los cambios",
      6000
    );
    getClientName();
    setClientsNames([])
    setFieldsArray([])
   
  };
  
  const itemsTab = [
    {label: "Crear campos especiales" },
    {label: "Configurar nomina"}
  ]
  //useEffect

  useEffect(() => {
    callMsGraphTokens()
      .then((response) => {
        setToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
        history.push("/");
      });
  }, []);

  useEffect(() => {
    if (clientSpecialFields) {
      const newArr: any[] = [];
      clientSpecialFields.forEach((element: any) => {
        const options: any[] = [];
        if (element.tipoCampo == "Select") {
          const json = element.campSelectJson;
          json.forEach((option: any) => {
            options.push(option.valor);
          });
        }
        const newElement = {
          label: element.campEspNombre,
          type: element.tipoCampo.toLowerCase(),
          campEspAsigId: element.campEspAsigId,
          campEspId: element.campEspId,
          min: 0,
          max: 0,
          options: options,
        };
        newArr.push(newElement);
      });
      setFieldsArray(newArr);
    }
  }, [clientSpecialFields]);

  useEffect(() => {
    getClientName();
  }, [token]);

  //main return//
  return (
    <>
      <div className="card layout-main-container">
        <div className="flex flex-column align-items-center card-container ">
          
        <p className="pb-7" style={{
                height: "42px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "22px",
                lineHeight: "41.5px",
                alignItems: "center",
                color: "#333333",
                marginTop:"5rem"
              }} >Especiales clientes</p>

        <div className="flex flex-column align-items-center card-container mb-8" style={{
                fontSize:"15px"}}>
          <p>Aquí puedes agregar campos a las nóminas existentes o configurarlas con</p>
          <p>requerimientos especiales nuevos. Una vez guardes los cambios, estarán</p>
          <p>disponibles para toda el área de reclutamiento masivo.</p>
        </div>
        
          
          <TabView className="col-12 md:col-8" activeIndex={activeTabMenu} onTabChange={(e) => setActiveTabMenu(e.index)}>
            <TabPanel headerClassName="col-6 m-0 pb-0 justify-content-center" headerStyle={{display: "inline-flex"}} header="Crear campos especiales">
             
              <div className="mb-5 col-12 ml-3 pb-0 mt-6">
                <CentralContainer />
              </div>          

              <div className="col-12 ml-3 pt-0" style={{float: "left"}}>
                <label className="pr-2">Cliente:</label>
          
                <Dropdown
                  value={clientName || ""}
                  options={clientsNames}
                  onChange={(e) => onChangeBussinessZone(e)}
                  optionValue="clienteId"
                  optionLabel="newName"
                  filter
                  placeholder="Seleccione cliente"
                  appendTo="self"
                />
              <div className="pt-5" >
              <label>¿Qué deseas agregar?</label>  
              </div>

              </div>

              
              {(clientName || clientName == "" ) ? (
                <>
                  <div className="col-12 justify-content-center card-container m-0 px-0 mb-2 mt-3 ml-3" >
                    <Menu model={optionsInputs} popup ref={menu} id="popup_menu" />
                
                <div className="flex justify-content-center md:justify-content-start md:col-3 sm:col-6 col-12" style={{float: "left"}}>
                <Tooltip title="Ej: Apellido " placement="bottom">
                    <Button className="" style={ (clientName && fieldsArray.find((item)=>item.type=="text")) ? 
                    { height:"55px", width:"167px", marginRight:"5px", backgroundColor:"#2A63D5", color:"#FFFFFF", fontSize:"13px"}:
                    { height:"55px", width:"167px", marginRight:"5px", backgroundColor:"#F1F1F1", color:"#333333" , borderColor:"#F1F1F1", fontSize:"13px"} }
                      label="Campos de Texto" 
                      icon={<FormatColorTextOutlinedIcon/> }
                      onClick={() => optionText()}                  
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                </Tooltip>    
                </div>
                <div className="flex justify-content-center md:col-3 sm:col-6 col-12" style={{float: "left"}}>
                <Tooltip title="Ej: N° de cta " placement="bottom">
                      <Button className="" style={ (clientName && fieldsArray.find((item)=>item.type=="number")) ? 
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#2A63D5", color:"#FFFFFF", fontSize:"13px"}:
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#F1F1F1", color:"#333333", border:"#F1F1F1", fontSize:"13px"} }
                      label="Números"
                      icon={<LooksOneOutlinedIcon/>}
                      onClick={() => optionNumero()}
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                    </Tooltip>
                </div>
                <div className="flex justify-content-center md:col-3 sm:col-6 col-12" style={{float: "left"}}>
                <Tooltip title="Ej: Fecha de Nacimiento " placement="bottom">
                      <Button className="" style={ (clientName && fieldsArray.find((item)=>item.type=="date")) ? 
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#2A63D5", color:"#FFFFFF", fontSize:"13px"}:
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#F1F1F1", color:"#333333", border:"#F1F1F1", fontSize:"13px"} }
                      label="Fechas"
                      icon={<CalendarMonthOutlinedIcon/>}
                      onClick={() => optionFecha()}
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                    </Tooltip>
                </div>
                <div className="flex justify-content-center md:justify-content-end md:col-3 sm:col-6 col-12" style={{float: "left"}}>
                <Tooltip title="Ej: Con o sin hijos " placement="bottom">
                      <Button className="mx-0 " style={ (clientName && fieldsArray.find((item)=>item.type=="Select" || item.type=="select")) ? 
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#2A63D5", color:"#FFFFFF", fontSize:"13px"}:
                    { height:"55px",width:"167px", marginRight:"5px", backgroundColor:"#F1F1F1", color:"#333333", border:"#F1F1F1", fontSize:"13px"} }
                      label="Selección múltiple "
                      icon={<CheckBoxOutlinedIcon/>}
                      onClick={() => optionOpciones()}
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                    </Tooltip>
                </div>
                  </div>

                  {fieldsArray.map((field, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="flex justify-content-center col-12  ml-3"
                        >
                          <div className="ml-2 col-12" style={{backgroundColor:"#F1F1F1", border: "1px solid #D5D5D5",borderRadius:"12px"}}>
                            <div className="col-12" style={{float:"left"}} >
                            <div className="flex col-12 md:col-4" style={{float:"left"}}>
                                {field.type == "text" ? (
                                  <FormatColorTextOutlinedIcon style={{float: "left", marginTop: "1.5rem", color:"#AEAEAE"}}/> 
                                 
                                ) : field.type == "number" ? (
                                  <LooksOneOutlinedIcon style={{float: "left", marginTop: "1.5rem", color:"#AEAEAE"}}/> 
                                 
                                ) : field.type == "Select" || field.type == "select" ? (
                                  <CheckBoxOutlinedIcon style={{float: "left", marginTop: "1.5rem", color:"#AEAEAE"}}/>
                                  
                                ) : field.type == "date" ? (
                                  <CalendarMonthOutlinedIcon style={{marginTop: "1.5rem", color:"#AEAEAE"}}/> 
                                  
                                  
                                ) : (
                                  ""
                                )}   
                                <label className="ml-1 mt-4">Nombre Campo:</label>
                              </div>
                              <div className="col-12 md:col-8" style={{float:"left"}}>
                                <div className="flex col-12 md:col-11 my-2" style={{float:"left"}} >
                                  <InputText
                                    className="col-11"
                                    value={field.label}
                                    onChange={(e) =>
                                      updateLabelOnArray(e.target.value, i)
                                    }
                                  />
                                  <span style={{color:"#AEAEAE"}}
                                  className="material-symbols-outlined cursor-pointer mt-1"
                                  onClick={() => deleteField(i)}
                                  >
                                    delete{" "}
                                  </span>
                                </div>
                              </div>                          
                            </div>

                            {field.type == "Select" || field.type == "select" ? (
                              <div className="col-12" >
                                <div className="flex col-12 md:col-4" style={{float:"left"}} > 
                                  <label className="">Nombre de alternativas:</label>
                                </div>
                              
                                <div className="col-12 md:col-8" style={{float:"left"}} >
                                {field.options.map((option: string, j: number) => {
                                  return (
                                    <>
                                      <div className="flex col-12 md:col-11 my-2" style={{float:"left"}} >
                                        <InputText
                                          className="col-11"
                                          value={option}
                                          onChange={(e) =>
                                            updateOptionOnArray(
                                              e.target.value,
                                              i,
                                              j
                                            )
                                          }
                                        />
                                        <span style={{color:"#AEAEAE"}}
                                          className="material-symbols-outlined cursor-pointer mt-1"  
                                          onClick={() => deleteOption(i, j)}
                                        >
                                          delete{" "}
                                        </span>

                                        
                                      </div>
                                      
                                    </>
                                  
                                  );
                                })}
                                  </div>
                                  
                              <div className="col-11 pr-4">
                                      <Button icon="pi pi-plus" 
                                      style={{color:"#AEAEAE", 
                                      float:"right",
                                      height:"1.6rem",
                                      width:"1.6rem",
                                      bottom:"12px",
                                      borderWidth:"3px"}}
                                          className="p-button-rounded p-button-info p-button-outlined mt-0 "
                                          onClick={() => addOption(i)}
                                        />                                      
                              </div>  
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>                    
                      </>
                    );                
                  })}
                </>
              ) : (
                ""
              )}      
        
          
          {(clientName != "" && fieldsArray.length > 0) ? ( 
              <><>
              <div className="flex justify-content-end col-12 px-0" style={{ float: "right"}}>
                        
                <div className="col-12 md:col-6 m-0 px-0" style={{ float: "right" }}>
                  <Button style={{ float: "right", display: "revert" }}
                    icon={<CheckCircleOutlineOutlinedIcon className="mr-0" style={{
                      float: "right"
                    }} ></CheckCircleOutlineOutlinedIcon>}
                    iconPos="right"
                    label="Crear"
                    className="p-button-success ml-3 mr-0"
                    onClick={saveSpecialFields} />
    
                    <a className="mt-2 cursor-pointer" onClick={cancelar} style={{ float: "right",color: "#333333",
                  textDecoration: "underline", }}>Cancelar</a>
                </div>
              </div>
              
              </></>
              ) : ""}
              <Toast ref={toast} />  
            </TabPanel>
            <TabPanel headerClassName="justify-content-center col-6 m-0 pb-0" headerStyle={{display: "inline-flex"}} header="Configurar nómina">
              <div className="col-12 m-0 pb-0" >
              <AddFormComponent/>
          </div>
            </TabPanel>
          </TabView>

        </div> 
      </div>
      
      
    </>


  );
};

export default EditSpecialFieldsComponent;

{
  /*

        {field.type == 'text' ?
                    <div className="flex flex-row mt-4 col-12">
                      <div className='col-4'>
                        <label className=''>Ingrese max. caracteres:</label>
                      </div>
                      <div className='col-7'>
                        <InputText className=''
                          value={''}
                          onChange={(e) => updateLabelOnArray(e.target.value, i)}

                        />
                      </div>
                    </div>

                    : field.type == 'number' ?
                      <div className="flex flex-row mt-4 col-12">
                        <div className='col-4'>
                          <label className=''>Ingrese max. y min.:</label>
                        </div>
                        <div className='col-7'>
                          <InputText className=''
                            value={''}
                            onChange={(e) => updateLabelOnArray(e.target.value, i)}
                            placeholder='30-40'
                          />
                        </div>

                      </div>
                      : field.type == 'Select' ?
                        <div className="flex flex-column mt-4 col-12">
                          <div className='col-4 mb-3'>
                            <label className=''>Opciones:</label>
                          </div>
              

                            {field.options.map((option: string, j: number) => {
                              return < >
                                <div className='col-7 flex align-items-center my-2'>
                                  <RadioButton inputId="city1"
                                    name="city"
                                    value="Chicago"
                                    className='mr-3  '
                                  //  onChange={(e) => setCity(e.value)} 
                                  //  checked={city === 'Chicago'} 
                                  />

                                  <InputText className='mx-3'
                                    value={''}
                                    onChange={(e) => updateLabelOnArray(e.target.value, i)}
                                  />

                                  <span className="material-symbols-outlined cursor-pointer "
                                    onClick={() => deleteOption(i, j)}
                                  >delete </span>

                                  <span className="material-symbols-outlined cursor-pointer ml-3"
                                    onClick={() => addOption(i)}
                                  >add </span>

                                </div>
                              </>
                            })}




                        </div>
                        : ''
                  }

                */
}
