import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";

interface ISectionActive {
  token?: string | null;
  setToken?: Function;
  getToken?: Function;
  localStorageFilter?: string;
  setFormFilter?: Function;
  formFilter?: any;
  idCliente?:string;
  objetoFilterGeneral?:any;
}

interface ObjetoSend {
  analista: boolean;
  cliente: boolean;
  limiteDias: number;
  filtros: {
    clientes: string[];
    areaNegocio: string[];
    analistas: any[];
    periodo: any[];
  };
}

const IngresosPorAnalista = (props: ISectionActive) => {

  const {
    idCliente,
    localStorageFilter,
    setFormFilter,
    formFilter,
    objetoFilterGeneral,
  } = props;

  const { postResumenAnalistaCliente,listaUsuarioAnalista, token,listaCliente,getDayOfWeek,
    postResumenIngresoDotacion, listaResumenAnalistaCliente } = useContext(OperacionContratacionContext); 

  
    const [dataChart, setDataChart] = useState<[]>([]);
    const [diasChart, setDiasChart] = useState<[]>([]);
    const [objetoSend, setObjetoSend] = useState<ObjetoSend>({
      analista: false,
      cliente: true,
      limiteDias: 7,
      filtros: {
        clientes: [""],
        areaNegocio: [""],
        analistas: [],
        periodo: [],
      },
    });

    useEffect(() => { 
      
      if(token){ 
            
          // Crear el objeto con los valores actualizados
          const objeto = {
            
            "analista": true,
            "cliente": false,
            "limiteDias": 7,
            "filtros": {
              "clientes": (objetoFilterGeneral && objetoFilterGeneral.cliente && objetoFilterGeneral.cliente[0] !== undefined)
              ? objetoFilterGeneral.cliente
              : (idCliente !== undefined ? [idCliente] : (listaCliente && listaCliente[0] && listaCliente[0].clienteId )),
              "areaNegocio": (objetoFilterGeneral?.areaNegocio !== undefined ) ? objetoFilterGeneral?.areaNegocio : [],
              "analistas": (objetoFilterGeneral && objetoFilterGeneral?.analista[0] !== undefined) ? objetoFilterGeneral?.analista : [],
              "periodo": []
            }
          }
          setObjetoSend(objeto)   
          postResumenAnalistaCliente({ token, objeto });       
        
    }
    }, [token, objetoFilterGeneral, localStorage.getItem("filterGeneralDashboard")]);
  
     useEffect(() => { 
      if (listaResumenAnalistaCliente && listaResumenAnalistaCliente.analista && listaResumenAnalistaCliente.analista.ingresos) {
      const dataChart = listaResumenAnalistaCliente?.analista?.ingresos
      .map((ingreso: { total: any; }) => ingreso.total);
  
    setDataChart(dataChart.reverse());

 
  
    const dias = listaResumenAnalistaCliente?.analista?.ingresos
      .map((ingreso: { fecha: any; }) => {
        const dia = getDayOfWeek(ingreso.fecha);
        if (dia === "MIÉRCOLES" || dia === "MARTES") {
          return dia.slice(0, 2);
        } else {
          return dia.slice(0, 1);
        }
      });
 
    setDiasChart(dias.reverse());
    }
     },[listaResumenAnalistaCliente])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = diasChart;

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true          
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
      datalabels: {
        display: false, 
       /* font: { size: 14 },
        backgroundColor: "transparent",
        color:"#262626"*/
    },
    },
  };

  const data: any = {
    labels,
    datasets: [
      {
        label: (objetoFilterGeneral?.analista[0] !== undefined) ? listaUsuarioAnalista.find((analista: { usuarioId: string | undefined; }) => 
        analista.usuarioId === objetoSend?.filtros.analistas[0])?.usuarioNombre : "Todos",
        data: dataChart,
        borderColor: "#53CC50",
        backgroundColor: "#53CC50",
      },
     
    ],
  };

  return (
    <div className="container-table-dashboard w-35 responsiveChart">
      <div className="content-title">
        <label className="title-table-dashboard">INGRESOS POR ANALISTA</label>
      </div>
      <Line options={options} data={data} height={200}/>
    </div>
  );
};

export default IngresosPorAnalista;
