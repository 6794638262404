import { useContext, useEffect, useState } from "react";
import "../../../../../assets/css/action-massive.min.css";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import IconDetenerSolicitud from "../../../../ui/kit-svg/icon-bajas-confirmadas/IconDetenerSolicitud";
import DetenerSolicitud from "../../Modals/DetenerSolicitud";
import ModalNoProcesado from "../../../../ui/kit-modal/noProcesado/ModalNoProcesado";

interface IActionMasive {
  listadoSeleccionado: string[];
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const { listadoSeleccionado, localStorageFilter, setFormFilter, formFilter } =
    props;
  const {
    token,
    listaNoProcesados,
    listaBajasConfirmadas,
    postDetenerBaja,
    getBajasConfirmadas,
  } = useContext(OperacionContratacionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isOpenModalDetenerSolicitud, setIsOpenModalDetenerSolicitud] =
    useState<boolean>(false);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [buttonDetener, setButtonDetener] = useState<boolean>(false);
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);
  const [isErrorPost, setIsErrorPost] = useState<boolean>(false);
  const [selectCurrent, setSelectCurrent] = useState<any>([]);
  const [reasonAll, setReasonAll] = useState<any>({
    motivoAll: { value: "" },
  });

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (selectCurrent) {
      const hayTextoEnValue = Object.values(selectCurrent).some(
        (item: any) => item.value && item.value.trim() !== ""
      );
      setButtonDetener(hayTextoEnValue);
    }
  }, [selectCurrent]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickDetenerSolicitud = () => {
    setIsOpenModalDetenerSolicitud(true);
  };

  const handleClickDetener = async () => {
    if (token) {
      const arregloTransformado = Object.keys(selectCurrent).map(
        (clave: any) => {
          const numeroFicha = clave;
          const motivo = selectCurrent[clave].value;

          return {
            ficha: numeroFicha,
            motivo: motivo,
          };
        }
      );
      try {
        await postDetenerBaja({
          token,
          contratos: arregloTransformado,
          setIsErrorPost,
          setIsLoadingPost,
          setIsOpenModalNoProcesado,
        });

        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          JSON.parse(copyStorage);
          getBajasConfirmadas({
            token,
            filtros: JSON.parse(copyStorage),
          });
        } else {
          getBajasConfirmadas({
            token,
            filtros: formFilter,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <section className="container-actions margin-0">
        <section className="content-actions-masive">
          <Tooltip text="Detener Solicitud">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickDetenerSolicitud}
            >
              <IconDetenerSolicitud fill="#BE2A2A" disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </section>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["APROBADA", "PROCESANDO", "RECHAZADA"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="FICHA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="ficha"
          />
          <InputFiltro
            layer="CÓDIGO CARGO"
            typeFilter="number"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="codCargo"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="TERMINO CONTRATO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaTerminoContrato"
          />
          <InputFiltro
            layer="CONFIRMACIÓN BAJA"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaConfirmacionBaja"
          />
          <StateFiltro
            layer="DIVISIÓN TW"
            listState={["TWEST", "TWC", "TWRRHH", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
        </FiltroGenerico>
      )}

      {isOpenModalDetenerSolicitud && (
        <ModalGenerico
          title="Detener Solicitud de Baja Confirmada"
          width="w-80"
          buttonBackText="Cancelar"
          buttonGoText="Detener Baja"
          buttonGoStyle="delete"
          buttonGoDisable={buttonDetener}
          onChangeBack={() => {
            setIsOpenModalDetenerSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickDetener();
            setIsOpenModalDetenerSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <DetenerSolicitud
            token={token}
            list={listadoSeleccionado}
            listData={listaBajasConfirmadas}
            reasonAll={reasonAll}
            setReasonAll={setReasonAll}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Solicitud NO procesada"
          iconTitle="danger"
          subtitle="La solicitud para los siguientes trabajadores no pudo ser procesada:"
          width="w-80"
          buttonBackText="Cerrar"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalNoProcesado list={listaNoProcesados} />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
