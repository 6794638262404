import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";

interface ISeccionAsignarPersonal {
  list: any[];
  listadoEquipo: any;
  setListadoEquipo: Function; 
  token: string | undefined; 
  getToken: Function;
}

const SeccionAsignarPersonal = (props: ISeccionAsignarPersonal) => {
  const { listadoEquipo, setListadoEquipo } = props;
  const { listaUsuarios } = useContext(SeleccionContext);
  const [roles] = useState<any>({
    accountManager: "Encargado", 
    analyst: "Analista",
  });

  useEffect(() => {
    if (listadoEquipo) {
      const uniqueArray = listadoEquipo.filter((item:any, index:any, self:any) => {
        return index === self.findIndex((obj:any) => (
          obj.usuarioId === item.usuarioId && obj.role === item.role
        ));
      });
      setListadoEquipo(uniqueArray); 
    }
  }, []);

  const handleClickRemoveTeam = (values: any) => {
    const {usuarioId} = values;
    if (listadoEquipo) {
      const currentArray = [...listadoEquipo]
      const position = currentArray?.findIndex((user:any) => user.usuarioId === usuarioId); 
      currentArray?.splice(position, 1)
      setListadoEquipo(currentArray); 
    }
  }


  return (
    <section className="seccion-table-asignar">
      <table className="table-asignar">
        <thead className="thead-table-asignar">
          <tr className="tr-table-asignar">
            <th className="td-table-asignar">Nombre</th>
            <th className="td-table-asignar">Rol</th>
            <th className="td-table-asignar">Acciones</th>
          </tr>
        </thead>
        <tbody className="tbody-table-asignar">
          {listadoEquipo && 
            listadoEquipo.map((item: any, index: number) => {
              const { usuarioId, role } = item;
              const { usuarioNombre } = listaUsuarios.find(
                (lista: any) => lista.usuarioId == usuarioId
                ) ?? { usuarioNombre: "no existe" };
              return (
                <>
                  <tr className="tr-table-asignar" key={index}>
                    <td className="td-table-asignar">{usuarioNombre}</td>
                    <td className="td-table-asignar">{roles[role]}</td>
                    <td className="td-table-asignar">
                      <div className="content-icon-table">
                        <button 
                          className="button-delete-table-asignar"
                          onClick={() => handleClickRemoveTeam({usuarioId})}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    </section>
  );
};

export default SeccionAsignarPersonal;
