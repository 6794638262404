import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M15.7414 5.18143C15.7625 5.20126 15.7818 5.22437 15.7988 5.25H11.625V1.07207C11.6574 1.09232 11.688 1.11674 11.7164 1.14522L15.5833 5.01211V5.03328L15.7414 5.18143ZM9.31833 1V6.07667C9.31833 6.88948 9.98552 7.55667 10.7983 7.55667H15.875V20.52C15.875 20.7844 15.6633 21 15.395 21H1.605C1.34059 21 1.125 20.7883 1.125 20.52V1.48C1.125 1.21948 1.34448 1 1.605 1H9.31833ZM14.9319 10.5531L14.0452 9.66645C13.605 9.22618 12.8917 9.22618 12.4514 9.66645L6.93667 15.1812L4.54855 12.7931C4.10829 12.3529 3.39504 12.3529 2.95478 12.7931L2.06811 13.6798C1.62785 14.12 1.62785 14.8333 2.06811 15.2736L6.13978 19.3452C6.58004 19.7855 7.29329 19.7855 7.73355 19.3452L14.9319 12.1469C15.3721 11.7066 15.3721 10.9934 14.9319 10.5531Z"
        fill="#06A502"
        stroke="#06A502"
      />
    </svg>
  );
};
