import { useContext, useEffect } from "react";
import TablaBloqueado from "./ConductaInadecuada/TablaBloqueado";
import TablaOtros from "./OtrosInhabilitados/TableOtros";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";

const TablaRegistroInhabilitado = () => {
  const { toasts, getToken } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {toasts}
      <TablaBloqueado />
      <TablaOtros />
    </>
  );
};

export default TablaRegistroInhabilitado;
