function firstUpperCasse(stringFirstUpperCasse: any) {
  let cadenaArregladaMayuscula = "";

  if (stringFirstUpperCasse != null) {
    let patron = /\w\S*/g;
    cadenaArregladaMayuscula = stringFirstUpperCasse.replace(
      patron,
      (p: any) => p.charAt(0).toUpperCase() + p.substring(1).toLowerCase()
    );
    return cadenaArregladaMayuscula;
  } else {
    return cadenaArregladaMayuscula;
  }
}

export default firstUpperCasse;
