interface IconInfoCompleta {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconInfoCompleta) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 15 18"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M14 0.5H1C0.45 0.5 0 0.95 0 1.5V16.5C0 17.05 0.45 17.5 1 17.5H14C14.55 17.5 15 17.05 15 16.5V1.5C15 0.95 14.55 0.5 14 0.5ZM6.74 14.76L4 12.02L4.81 11.21L6.74 13.12L10.19 9.67L11 10.5L6.74 14.76ZM6.74 8.59L4 5.85L4.81 5.04L6.74 6.95L10.19 3.5L11 4.33L6.74 8.59Z" 
       fill="#06A502"/>
      </svg>



    );
  };

