import { useEffect, useState } from "react";
import { formatPhone } from "../../../functions/kit-mdtw/phone";
import ErrorIcon from "@mui/icons-material/Error";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IInputPhone,
} from "../../../interfaces/IInput";

export default (props: IInputPhone) => {
  const {
    placeholder,
    layer,
    required,
    onValueChange, 
    onPropertyChange,
    onPermaValues,
    maxLength,
    errorPosition,
    defaultValue, 
    disabled, 
    requerido, 
    datoRequerido,
  } = props;

  const [areaCode] = useState<string[]>(["56"]);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(undefined);
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [numberPhone, setNumberPhone] = useState<string>("");

  useEffect(() => {
    if(defaultValue) {
      setNumberPhone(formatPhone(defaultValue))
    }
  }, [defaultValue])

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, defaultValue])

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";
    let numberInput: string = value.split(" ").join("");

    if (value.split("").includes("+")) {
      numberInput = value.replaceAll("+", "");
    }

    numberInput = formatPhone(numberInput);

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (datoRequerido) {
      if (requerido) {
        triggerRequired = value !== "" ? false : true;
        eventValidation = value !== "" ? "success" : "caution";
      }
    }

    if (required) {
      if (numberInput === "") {
        setMessageError(`Dato obligatorio`);
        trigger = true;
        eventValidation = "error";
      }
    }

    if (numberInput !== "") {
      if (
        !numberInput.match(
          /^([\d]{2})+(\s)+([\d]{1})+(\s)+([\d]{4})+(\s)+([\d]{4})$/
        )
      ) {
        setMessageError(`no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
      }

      if (!areaCode.includes(numberInput.substring(0, 2))) {
        setMessageError(`Código de área no es valido`);
        trigger = true;
        eventValidation = "error";
      }
    }
    setEventValidation(eventValidation);
    setTriggerError(trigger);
    setTriggerRequerido(triggerRequired);

    if (onPermaValues && onPropertyChange && onValueChange) {
      
      if(typeof onPropertyChange === "string"){
        const permaValues = { ...onPermaValues };
        permaValues[onPropertyChange] = {
          value: numberInput !== "" ? numberInput.split(" ").join("") : undefined,
          valid: trigger ? false : true,
        };
        onValueChange(permaValues);
      }
      if(typeof onPropertyChange === "object"){
        const permaValues = [ ...onPermaValues ];
        const [position, key] = onPropertyChange;
        permaValues[position][key] = {
          value: numberInput !== "" ? numberInput.split(" ").join("") : undefined,
          valid: trigger ? false : true,
        };
        onValueChange(permaValues);
      } 
    }

    setNumberPhone(numberInput);
  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      <div style={{ display: "flex" }}>
        <label className="layer">{layer}</label>
        {triggerError && ["up", undefined].includes(errorPosition) && (
          <span className="layer-error">{messageError}</span>
        )}
        {triggerRequerido && ["up", undefined].includes(errorPosition) && (
          <span className="layer-caution"><ErrorIcon />Dato Requerido</span>
        )}
      </div>

      <input
        type="text"
        placeholder={placeholder}
        className={categoriesValidation[eventValidation]}
        required={required}
        maxLength={maxLength}
        disabled={disabled}
        value={numberPhone}
        defaultValue={defaultValue}
        onChange={({ target }) =>
          handleChangeEventError({ value: target.value })
        }
        onBlur={({ target }) => handleChangeEventError({ value: target.value })}
      />
      {triggerError && errorPosition==="down" && (
        <span className="layer-error down">{messageError}</span>
      )}
      {triggerRequerido && errorPosition==="down" && (
        <span className="layer-caution down">
          <ErrorIcon />
          Dato Requerido
        </span>
      )}
    </div>
  );
};
