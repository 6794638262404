import MediaQuery from "../../ui-responsive/MediaQuery";

interface IIconArrowDoubleRight {
  className?: any;
  disabled?: boolean;
}

export default (props: IIconArrowDoubleRight) => {
  const { className, disabled } = props;
  const {width, sm} = MediaQuery();

  return (
    <svg
      width={width >= sm ? '23' : '16' }
      height={width >= sm ? '23' : '16' }
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5169 12.6511L4.30469 21.8633C3.66796 22.5 2.63836 22.5 2.0084 21.8633L0.477546 20.3324C-0.159182 19.6957 -0.159182 18.6661 0.477546 18.0362L7.0074 11.5063L0.477546 4.97644C-0.159182 4.33971 -0.159182 3.31011 0.477546 2.68016L2.00163 1.13575C2.63836 0.499021 3.66796 0.499021 4.29792 1.13575L13.5102 10.348C14.1537 10.9847 14.1537 12.0143 13.5169 12.6511ZM26.5225 10.348L17.3102 1.13575C16.6735 0.499021 15.6439 0.499021 15.0139 1.13575L13.4831 2.66661C12.8463 3.30334 12.8463 4.33294 13.4831 4.96289L20.0129 11.4928L13.4831 18.0226C12.8463 18.6593 12.8463 19.6889 13.4831 20.3189L15.0139 21.8497C15.6507 22.4865 16.6803 22.4865 17.3102 21.8497L26.5225 12.6375C27.1592 12.0143 27.1592 10.9847 26.5225 10.348Z"
        fill={!disabled ? '#48566B' : '#AEAEAE'}
      />
    </svg>
  );
};
