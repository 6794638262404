import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 15 15"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M1.95287 1.08959V6.41041V6.35593V13.4383V1.08959ZM3.75069 8.35351H7.11025C7.24343 8.1477 7.3887 7.954 7.54609 7.7724C7.70347 7.5908 7.87902 7.42131 8.07272 7.26392H3.75069V8.35351ZM3.75069 11.4407H6.58362C6.5473 11.2591 6.52006 11.0775 6.5019 10.8959C6.48374 10.7143 6.48071 10.5327 6.49282 10.3511H3.75069V11.4407ZM1.95287 14.5278C1.66231 14.5278 1.40808 14.4189 1.19016 14.201C0.97224 13.9831 0.863281 13.7288 0.863281 13.4383V1.08959C0.863281 0.799031 0.97224 0.544794 1.19016 0.326877C1.40808 0.108959 1.66231 0 1.95287 0H8.50856L12.4856 3.977V6.77361C12.3161 6.68886 12.1405 6.61622 11.9589 6.55569C11.7773 6.49516 11.5897 6.44673 11.396 6.41041V4.46731H7.96377V1.08959H1.95287V13.4383H7.67321C7.91534 13.6925 8.18471 13.9104 8.48132 14.092C8.77793 14.2736 9.10178 14.4189 9.45287 14.5278H1.95287ZM10.6696 12.5303C11.2386 12.5303 11.7138 12.3396 12.0951 11.9582C12.4765 11.5769 12.6672 11.1017 12.6672 10.5327C12.6672 9.96368 12.4765 9.4885 12.0951 9.10714C11.7138 8.72579 11.2386 8.53511 10.6696 8.53511C10.1006 8.53511 9.62539 8.72579 9.24403 9.10714C8.86268 9.4885 8.672 9.96368 8.672 10.5327C8.672 11.1017 8.86268 11.5769 9.24403 11.9582C9.62539 12.3396 10.1006 12.5303 10.6696 12.5303ZM14.3742 15L12.4281 13.0569C12.1759 13.2264 11.9014 13.3626 11.6048 13.4655C11.3082 13.5684 10.9965 13.6199 10.6696 13.6199C9.81203 13.6199 9.08312 13.3195 8.48283 12.7188C7.88255 12.1181 7.58241 11.3887 7.58241 10.5305C7.58241 9.67241 7.88276 8.9437 8.48346 8.34443C9.08415 7.74516 9.81357 7.44552 10.6717 7.44552C11.5299 7.44552 12.2586 7.74566 12.8578 8.34594C13.4571 8.94623 13.7567 9.67515 13.7567 10.5327C13.7567 10.8596 13.7053 11.1713 13.6024 11.4679C13.4995 11.7645 13.3633 12.039 13.1938 12.2912L15.1369 14.2373L14.3742 15Z"
      />
    </svg>
  );
};
