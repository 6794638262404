import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartOptions,
  CategoryScale,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";

interface IGraficoRadar {
  token?: string | null;
  setToken?: Function;
  getToken?: Function;
  localStorageFilter?: string;
  setFormFilter?: Function;
  formFilter?: any;
  idCliente?: string;
  objetoFilterGeneral?: any;
  widthDonut?: number;
  heigthDonut?: number;
  arrayCompetenciaAdicional: any;
  arrayCompetenciaSolicitada: any;
}

const RadarChart = (props: IGraficoRadar) => {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    CategoryScale
  );
  const {
    idCliente,
    objetoFilterGeneral,
    arrayCompetenciaAdicional,
    arrayCompetenciaSolicitada,
  } = props;

  const { listEvaluacionPsicolaboral, listaCompetenciasLaborales } =
    useContext(SeleccionContext);

  const filtrarPorId = (id: any) => {
    return (
      listaCompetenciasLaborales.find(
        (item: any) => item.competenciaLaboralId == id
      )?.nombreCompetencia ?? id
    );
  };

  const valorForId = (id: any) => {
    if (listEvaluacionPsicolaboral.categorias != undefined) {
      return (
        listEvaluacionPsicolaboral?.categorias.find(
          (item: any) => item.categoriaEvaluacionId == id
        )?.valor ?? id
      );
    }
  };

  const [hidden, setHidden] = useState<boolean>(false);
  const combinedArray = [
    ...new Set(
      [...arrayCompetenciaAdicional, ...arrayCompetenciaSolicitada].map(
        ({ evaluacionCompetenciaId }) => evaluacionCompetenciaId
      )
    ),
  ];

  const labelsForRadar = combinedArray.map((item: { item: string }) =>
    filtrarPorId(item)
  );

  /*const firstDatasetLength = arrayCompetenciaAdicional.length; 
    const dataPuntajeAlcanzado = combinedArray.slice(0, firstDatasetLength).map
    ((item: { categoriaEvaluacionId: string; }) => valorForId(item.categoriaEvaluacionId));
    
    const dataPuntajeEsperado = Array(firstDatasetLength).fill(0) // Rellenar con ceros
      .concat(
        combinedArray
          .slice(firstDatasetLength) // Obtener los elementos restantes
          .map((item: { categoriaEvaluacionId: string; }) => valorForId(item.categoriaEvaluacionId)) 
      );*/

  const array: Record<string, { value1: number; value2: number }> = {};

  labelsForRadar.forEach((key: string) => {
    array[key] = {
      value1: 0,
      value2: 0,
    };
  });

  arrayCompetenciaAdicional.forEach(
    ({ evaluacionCompetenciaId, categoriaEvaluacionId }: any) => {
      if (array[filtrarPorId(evaluacionCompetenciaId)]) {
        Object.assign(array[filtrarPorId(evaluacionCompetenciaId)], {
          value1: valorForId(categoriaEvaluacionId),
        });
      }
    }
  );

  arrayCompetenciaSolicitada.forEach(
    ({ evaluacionCompetenciaId, categoriaEvaluacionId }: any) => {
      if (array[evaluacionCompetenciaId]) {
        Object.assign(array[evaluacionCompetenciaId], {
          value2: valorForId(categoriaEvaluacionId),
        });
      }
    }
  );

 

  const entries = Object.entries(array);

  const keys = entries.map(([key]) => key);

  const labelForRadarUpper = keys.map((item: string) => {
    const upperCaseText = item.toUpperCase();
    const words = upperCaseText.split(" ");

    if (words.length > 1) {
      const firstPart = words.slice(0, Math.ceil(words.length / 2));
      const secondPart = words.slice(Math.ceil(words.length / 2));
      return [firstPart.join(" "), secondPart.join(" ")];
    } else if (words.length === 2) {
      return words;
    }

    return [upperCaseText];
  });
  const dataPuntajeAlcanzado = entries.map(([, value]) => value.value1);
  const dataPuntajeEsperado = entries.map(([, value]) => value.value2);

  //console.log("keys", keys);
  //console.log("dataPuntajeAlcanzado", dataPuntajeAlcanzado);
  //console.log("dataPuntajeEsperado", dataPuntajeEsperado);

 

  const data:any = {
    labels: labelForRadarUpper, //labelForRadarUpper,

    datasets: [
      {
        label: "Puntaje alcanzado",
        data: dataPuntajeAlcanzado,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
        pointRadius: 6,
      },
      {
        label: "Puntaje esperado",
        data: dataPuntajeEsperado,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
        pointRadius: 6,
      },
    ],
  };

  const RadarOptions:any = {
    responsive: true,
    elements: {
      line: {
        borderWidth: 2,
        tension: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        textAlign: "center",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        grid: {
          color: "rgba(0, 101, 221, 1)",
          circular: true,
          lineWidth: 2,
        },
        border: { dash: [5, 5] },

        angleLines: {
          color: "rgba(0, 101, 221, 1)",
          lineWidth: 2,
        },
        pointLabels: {
          font: {
            size: 11.57,
            family: "Inter",
            lineHeight: "15px",
            weight: "bold",
            align: "center",
          },
          color: "rgba(64, 64, 64, 1)",
        },
      },
    },

    maintainAspectRatio: true,
  };

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <section
        className={`seccion-title pb-6 `}
        onClick={() => handleClickHidden()}
      >
        <span className="span-title">EVALUACIÓN POR COMPETENCIAS</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo "></span>}
      <div
        className={`project-chartPsico sm:col-4 col-6 mx-auto ${
          hidden ? "disabled" : ""
        }`}
      >
        {
          <div className="container-table">
            <label className="title ">NIVEL DE COMPETENCIAS PRESENTADO</label>
          </div>
        }
        <Radar data={data} options={RadarOptions} />
      </div>
    </>
  );
};

export default RadarChart;
