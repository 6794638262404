import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ActionMassive from "./ActionMassive";
import {
  ICandidatoInhabilitado,
  ICandidatoPendiente,
} from "../../../../../interfaces/ISeleccion";
import IconRegistroCandidato from "../../../../ui/icons-seleccion/IconRegistroCandidato";
import IconAsignarProceso from "../../../../ui/icons-seleccion/IconAsignarProceso";
import ModalUpdateCandidate from "../../../../ui/kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import ModalEncuesta from "../../../Campaign/ModalEncuesta";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import NuevoCandidato from "../../../../ui/groups/NuevoCandidato";
import ModalAsignarProceso from "../../RegistroHabilitado/Modal/ModalAsignarProceso";
import IconCandidatoReclutado from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoReclutado";
import IconCandidatoPostulado from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoPostulado";
import IconCandidatoSugerido from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoSugerido";

const TablaCandidatoPendienteMasivo = () => {
  const {
    listaCandidatoPendienteMasivo,
    getCandidatoPendienteMasivo,
    getCandidatoValidadoMasivo,
    putAvanzarProceso,
    token,
    getRutVerificado,
    getDocument,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  const [gridCandidatoPendienteMasivo, setGridCandidatoPendienteMasivo] =
    useState<any[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoPendienteMasivo"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    fechaPostulacion: [],
    tipoProceso: ["MASIVO"],
    reclutador: [],
  });

  useEffect(() => {
    listRefresh();
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoPendienteMasivo) {
      const arrayList = listaCandidatoPendienteMasivo.map(
        (item: ICandidatoInhabilitado) => item.personaDNI
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaCandidatoPendienteMasivo]);

  useEffect(() => {
    if (listaCandidatoPendienteMasivo) {
      let arrayGridFilter = [...listaCandidatoPendienteMasivo];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoPendienteMasivo(arrayGridFilter);
    }
  }, [listaCandidatoPendienteMasivo, range]);

  const listRefresh = () => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        const copyStorageChange = JSON.parse(copyStorage);
        if (!copyStorageChange.tipoProceso.includes("MASIVO")) {
          copyStorageChange.tipoProceso.push("MASIVO");
        }
        getCandidatoPendienteMasivo({
          token,
          filtros: copyStorageChange,
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        getCandidatoPendienteMasivo({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  };

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { personaDNI } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];

    if (!arrayList.includes(personaDNI)) {
      arrayList.push(personaDNI);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === personaDNI
      );
      arrayList.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter(
          (personaDNI: string) => !listadoSeleccionado.includes(personaDNI)
        )
        .map((personaDNI: string) => arrayListado.push(personaDNI));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
  };

  const handleClickVerificarRut = (rowData: any): void => {
    const { personaDNI } = rowData;
    setCandidateSelect([personaDNI]);
    getRutVerificado({
      personaRut: personaDNI,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
      token,
    });
    getDocument({ personaDNI: personaDNI, token });
  };

  const handleClickAsignarProceso = (values: any): void => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalAsignarProceso(true);
  };

  const handleClickBloquearCandidato = (values: any): void => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (values: any): void => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalDescartar(true);
  };

  const handleAvanzarProceso = async (values: any): Promise<void> => {
    const { setIsOpenModalNoProcesado } = values;
    if (token) {
      await putAvanzarProceso({
        token,
        procesos: candidates,
        setIsOpenModalNoProcesado,
      });
      listRefresh();
      await getCandidatoValidadoMasivo({ token });
    }
  };

  return (
    <>
      <NuevoCandidato getCandidates={listRefresh} />
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          handleAvanzarProceso={handleAvanzarProceso}
          candidates={candidates}
          setCandidates={setCandidates}
          listRefresh={listRefresh}
          setListadoSeleccionado={setListadoSeleccionado}
        />
      </section>
      <section className="content-seleccion-multiple action-in-line">
        <div className="content-multiple">
          <label className="title-multiple">
            <i className="selects-table-registro">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
      </section>

      <section className="content-header-table">
        <label className="title-header-table">Candidatos Pendientes</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">
                        <button
                          onClick={handleClickAddAll}
                          className="button-select"
                        >
                          {listadoSeleccionado.length ==
                            currentCandidates.length &&
                          listadoSeleccionado.length > 0 ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </button>
                      </th>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">Proceso</th>
                      <th className="th-table-registros">Cliente</th>
                      <th className="th-table-registros">
                        Fecha <br></br>Postulación
                      </th>
                      <th className="th-table-registros">Reclutador</th>
                      <th className="th-table-registros">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoPendienteMasivo &&
                      gridCandidatoPendienteMasivo.map(
                        (item: ICandidatoPendiente) => {
                          const {
                            personaDNI,
                            primerNombre,
                            primerApellido,
                            clienteNombre,
                            busquedaId,
                            division,
                            reclutador,
                            fechaPostulacion,
                            origenRegistro,
                          } = item;
                          return (
                            <>
                              <tr
                                key={personaDNI}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(personaDNI) &&
                                  "checkeado"
                                }`}
                              >
                                <td className="td-table-registros">
                                  <button
                                    className="button-select"
                                    onClick={() =>
                                      handleClickAddOrRemoveChoise({
                                        personaDNI,
                                      })
                                    }
                                  >
                                    {listadoSeleccionado.includes(
                                      personaDNI
                                    ) ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </button>
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-state">
                                    <>
                                      {origenRegistro === "RECLUTADO" && (
                                        <>
                                          <IconCandidatoReclutado fill="#0065DD" />
                                          <div className="content-state-text">
                                            <label className="title registro-azul">
                                              Candidato Reclutado
                                            </label>
                                            <label className="message">
                                              Datos ingresados por<br></br>
                                              reclutador
                                            </label>
                                          </div>
                                        </>
                                      )}
                                      {origenRegistro === "POSTULADO" && (
                                        <>
                                          <IconCandidatoPostulado fill="#FF0081" />
                                          <div className="content-state-text">
                                            <label className="title registro-rosado">
                                              Candidato Postulado
                                            </label>
                                            <label className="message">
                                              Datos ingresados por<br></br>
                                              postulante
                                            </label>
                                          </div>
                                        </>
                                      )}
                                      {origenRegistro === "SUGERIDO" && (
                                        <>
                                          <IconCandidatoSugerido fill="#48566B" />
                                          <div className="content-state-text">
                                            <label className="title registro-gris">
                                              Candidato Sugerido
                                            </label>
                                            <label className="message">
                                              Candidato con xx%<br></br>
                                              efectividad de contacto
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  </section>
                                </td>
                                <td className="td-table-registros">
                                  {primerNombre} {primerApellido}
                                </td>
                                <td className="td-table-registros">
                                  {personaDNI}
                                </td>
                                <td className="td-table-registros">
                                  {division}
                                  {busquedaId}
                                </td>
                                <td className="td-table-registros">
                                  {clienteNombre}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaPostulacion,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {reclutador}
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-icon-table">
                                    <Tooltip text="Ver Registro">
                                      <button
                                        onClick={() =>
                                          handleClickVerificarRut({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconRegistroCandidato
                                          fill={"#0065DD"}
                                        />
                                      </button>
                                    </Tooltip>
                                    <Tooltip text="Asignar Proceso">
                                      <button
                                        onClick={() =>
                                          handleClickAsignarProceso({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconAsignarProceso fill={"#0065DD"} />
                                      </button>
                                    </Tooltip>
                                    <Tooltip text="Bloquear">
                                      <button
                                        onClick={() =>
                                          handleClickBloquearCandidato({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconBloquear fill={"#BE2A2A"} />
                                      </button>
                                    </Tooltip>
                                    <Tooltip text="Descartar">
                                      <button
                                        onClick={() =>
                                          handleClickDescartarCandidato({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconDescarte fill={"#BE2A2A"} />
                                      </button>
                                    </Tooltip>
                                  </section>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {isOpenModalUpdateCandidate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdateCandidate}
          setResultBusperId={setResultBusperId}
          token={token}
          rut={candidateSelect[0]}
          isLoadingModal={isLoading}
          getCandidates={listRefresh}
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => {
            setIsOpenModalCampaign(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}

      {isOpenModalAsignarProceso && (
        <ModalGenerico
          title="Asignar Proceso"
          width="w-70"
          subtitle="Selecciona Grupo y/o Proceso a asignar a la selección"
          onChangeBack={() => {
            setIsOpenModalAsignarProceso(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalAsignarProceso
            list={candidateSelect}
            listSelect={listaCandidatoPendienteMasivo}
            getListRefresh={listRefresh}
            localStorageFilter={localStorageFilter}
            setListadoSeleccionado={setListadoSeleccionado}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalAsignarProceso(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
          />
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPendienteMasivo ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={candidateSelect}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPendienteMasivo ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={candidateSelect}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaCandidatoPendienteMasivo;
