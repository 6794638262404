import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import AccionMasive from "./AccionMasiva";
import TablaCliente from "./TablaCliente";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IconLoading from "../../../ui/kit-svg/IconLoading";

const ListadoProcesos = () => {
  const {
    token,
    getToken,
    getBusquedaProcesos,
    getListaUsuarios,
    toasts,
    listProcesosActivos,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [localStorageSelect, setLocalStorageSelect] = useState<string>(
    "selectProcesoActivo"
  );
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroProcesoActivo"
  );
  const [currentProcesos, setCurrentProcesos] = useState<string[]>([]);
  const [currentListProcess, setCurrentListProcess] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [formFilter, setFormFilter] = useState<any>({
    division: [],
    cliente: [],
    grupo: [],
    proceso: [],
    tipoProceso: [],
    cargo: [],
    cuposRestantes: [],
    tipoExperiencia: [],
    fechaEntrega: [],
  });

  useEffect(() => {
    if (token) {
      getListaUsuarios({ token });
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        let curryData = JSON.parse(copyStorage);
        if (localStorage.getItem("itemSelection")) {
          const copyStorage: any = localStorage.getItem("itemSelection");
          let curryDataProceso = JSON.parse(copyStorage);
          curryData.tipoProceso = [curryDataProceso.toUpperCase()];
          getBusquedaProcesos({
            token,
            filtros: curryData,
            setIsErrorTable,
            setIsLoadingTable,
          });
        } else {
          curryData.tipoProceso = "MASIVO";
          getBusquedaProcesos({
            token,
            filtros: curryData,
            setIsErrorTable,
            setIsLoadingTable,
          });
        }
      } else {
        if (localStorage.getItem("itemSelection")) {
          const tipoProceso: any = localStorage.getItem("itemSelection");
          formFilter.tipoProceso = [JSON.parse(tipoProceso).toUpperCase()];
          getBusquedaProcesos({
            token,
            filtros: formFilter,
            setIsErrorTable,
            setIsLoadingTable,
          });
        } else {
          getBusquedaProcesos({
            token,
            filtros: formFilter,
            setIsErrorTable,
            setIsLoadingTable,
          });
        }
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    try {
      if (localStorage.getItem(localStorageSelect)) {
        if (currentProcesos.length > 0) {
          const multi: string = String(
            localStorage.getItem(localStorageSelect)
          );
          const listado: string[] = multi.split(",");
          setListadoSeleccionado(listado);
        } else {
          localStorage.removeItem(localStorageSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (listProcesosActivos && listProcesosActivos.data?.length > 0) {
      const { data: listProcesos } = listProcesosActivos;
      const arrayListProcesos: string[] = listProcesos
        .map(({ grupos }: any) => grupos)
        .flat()
        .map(({ procesos }: any) => procesos)
        .flat();
      const arrayProcesos = arrayListProcesos.map(
        ({ busquedaId }: any) => busquedaId
      );
      setCurrentListProcess(arrayListProcesos);
      setCurrentProcesos(arrayProcesos);
    }
  }, [listProcesosActivos]);

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];
    if (listadoSeleccionado.length < currentProcesos.length) {
      currentProcesos
        .filter(
          (busquedaId: string) => !listadoSeleccionado.includes(busquedaId)
        )
        .map((busquedaId: string) => arrayListado.push(busquedaId));
    } else {
      arrayListado.splice(0, currentProcesos.length);
    }
    localStorage.setItem(localStorageSelect, String(arrayListado));
    setListadoSeleccionado(arrayListado);
  };

  return (
    <>
      {toasts}
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div className="content-multiple">
          <button onClick={handleClickAddAll} className="button-multiple">
            {listadoSeleccionado.length == currentProcesos.length &&
            listadoSeleccionado.length > 0 ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </button>
          <label className="title-multiple">
            Seleccionar Todo{" "}
            <i className="selects">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <AccionMasive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          listaOriginal={currentListProcess}
          setFormData={setFormData}
          formData={formData}
        />
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <TablaCliente
              token={token}
              getToken={getToken}
              localStorageSelect={localStorageSelect}
              listadoSeleccionado={listadoSeleccionado}
              setListadoSeleccionado={setListadoSeleccionado}
              currentListProcess={currentListProcess}
              setFormData={setFormData}
              formData={formData}
            />
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}
    </>
  );
};

export default ListadoProcesos;
