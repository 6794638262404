import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import SeccionAccion from "./SeccionAccion";
import { FILTER_LIST } from "../../../../diccionary/Diccionary";
import TablaCandidatoPendienteAprobacion from "./CandidatoPendienteAprobacion/TablaCandidatoPendienteAprobacion";
import TablaCandidatoValidado from "./CandidatosValidados/TablaCandidatoValidado";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";

const SistemaValidacionCliente = () => {
  const { token, getExclusiones, exclusiones, toasts, getCounts } =
    useContext(ValidationKamContext);
  const { toasts: toastSelection } = useContext(SeleccionContext);
  const { getListaGeneral } = useContext(SeleccionContext);
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);

  useEffect(() => {
    if (token) {
      getExclusiones({ token });
      getCounts({ token });
      getListaGeneral({ token });
    }
  }, [token]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  return (
    <>
      {toasts}
      {toastSelection}
      <SeccionAccion dataBloqueo={dataBloqueo} dataDescarte={dataDescarte} />
      <TablaCandidatoPendienteAprobacion />
      <TablaCandidatoValidado />
    </>
  );
};

export default SistemaValidacionCliente;
