import axios from "axios";
import { useReducer, useState } from "react";
import { IValidationKamState, IState } from "../../interfaces/IValidationKam";
import ValidationKamContext from "./ValidationKamContext";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import ValidationKamReducer from "./ValidationKamReducer";
import {
  setCountAssign,
  setExclusiones,
  setListaCandidatoConfirmado,
  setListaCandidatoPendiente,
  setListaCandidatoPendienteValidacion,
  setListaCandidatoValidado,
  setListaCandidatoPendienteContrato,
  setListaCandidatoEnviadoContrato,
  setListaDataContrato,
} from "./ValidationKamSet";
import { TYPE_LIST, STATE_CANDIDATE } from "../../diccionary/Diccionary";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";
import styled from "@emotion/styled";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { IOptionDropDownList } from "../../interfaces/IDropDownList";
import { formatDateToYYYYMMDD } from "../../functions/kit-mdtw/dates";

export default (props: IValidationKamState) => {
  const { children } = props;
  const [listServicioId, setListServicioId] = useState<string[]>([]);
  const [listAsistenciaId, setListAsistenciaId] = useState<any[]>([]);
  const [
    listCandidatoPendienteValidacionCliente,
    setListCandidatoPendienteValidacionCliente,
  ] = useState<any[]>([]);
  const [
    listCandidatoValidadoValidacionCliente,
    setListCandidatoValidadoValidacionCliente,
  ] = useState<any[]>([]);
  const [listCandidatoPendienteContrato, setListCandidatoPendienteContrato] =
    useState<any[]>([]);
  const [listCandidatoEnviadoContrato, setListCandidatoEnviadoContrato] =
    useState<any[]>([]);

  const [listaSeleccionados, setListaSeleccionados] = useState<any[]>([]);
  const [listaBusPeIdRestaurar, setListaBusPeIdRestaurar] = useState<any>([]);
  const [visibleActionBlocking, setVisibleActionBlocking] = useState(false);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cleanFilter, setCleanFilter] = useState<boolean>(false);
  const { toasts, createToast } = useToast();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [listCausales, setListCausales] = useState<any>([]);
  const [listMotivos, setListMotivos] = useState<any>([]);
  const [listSucursales, setListSucursales] = useState<any>([]);
  const [diasPorCausales, setDiasPorCausales] = useState<any>([]);
  const [listMotivosFull, setListMotivosFull] = useState<any>([]);
  const [datosContrato, setDatosContrato] = useState<any>({});
  const [listDivisiones, setListDivisiones] = useState<any>([]);
  const [listaAreasNegCliente, setListaAreasNegCliente] = useState<any>([]);
  const [causalesDesvinculacion, setCausalesDesvinculacion] = useState<any>([]);
  const [listNoProcess, setListNoProcess] = useState<any>();
  const [openModalNoProcess, setOpenModalNoProcess] = useState<boolean>(false);
  const [inSolicitudContrato, setInSolicitudContrato] = useState<boolean>(false);
  const [errorFechaMenor, setErrorFechaMenor] = useState<boolean>(false);
  const initialState: IState = {
    userId: sessionStorage.getItem("usuarioId") as string,
    profile: sessionStorage.getItem("profile") as string,
    roles: JSON.parse(sessionStorage.getItem("roles") as string),
    rol: ["KAM"],
    transition: 1,
    listaCandidatoConfirmado: [],
    listaCandidatoPendiente: [],
    listaCandidatoPendienteValidacion: [],
    listaCandidatoValidado: [],
    listaCandidatoPendienteContrato: [],
    listaCandidatoEnviadoContrato: [],
    listaDataContrato: [],
    countAssign: [],
    exclusiones: [],
    menuProfile: [],
    subMenu: [
      "Administración Servicios de Transporte",
      "Solicitudes fuera de plazo",
      "Asistencia y Validación",
      "Contrataciones",
    ],
  };

  const [state, dispatch]: any = useReducer<any>(
    ValidationKamReducer,
    initialState
  );

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  const getCounts = async (values: any) => {
    const { token } = values;
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}cuentas-asignadas`,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setCountAssign({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getExclusiones = async (values: any) => {
    const { token } = values;
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/exclusion-tipo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = result;
      const { data: payload } = data;
      setExclusiones({ dispatch, payload });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // data tables
  const getListCandidateConfirmed = async (values: any) => {
    const { idCliente, token, filtros } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/cuentas-asignadas/candidatos`,
        { filtros },
        {
          params: {
            userId: initialState.userId,
            // estados: "CONFIRMADO, NOASISTIO, ASISTIO, VALIDADO, VALID_OPS",
            estados: "VALID_OPS",
            clientes: idCliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoConfirmado({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListCandidatePending = async (values: any) => {
    const { idCliente, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuentas-asignadas/candidatos`,
        {
          params: {
            userId: state.userId,
            estados: "PEND_SOL_CONTR", //"PEND_VAL_CLI", //
            clientes: idCliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoPendiente({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   *
   * TODO: use apis for validateClient
   */
  const getListCandidatePendingValidate = async (values: any) => {
    const { idCliente, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuentas-asignadas/candidatos`,
        {
          params: {
            userId: state.userId,
            // estados: "PEND_VAL_CLI, VALID_CLI",
            estados: "VALID_CLI",
            clientes: idCliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoPendienteValidacion({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListCandidateValidate = async (values: any) => {
    const { idCliente, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuentas-asignadas/candidatos`,
        {
          params: {
            userId: state.userId,
            estados: STATE_CANDIDATE.PEND_SOL_CONTR, //"PEND_SOL_CONTR", //
            clientes: idCliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoValidado({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * TODO: use apis for Contrataciones
   */
  const [disableButttonAction, setDisableButttonAction] =
    useState<boolean>(false);
  const disableButton = (state: boolean) => {
    setDisableButttonAction(state);
  };

  const [disableButttonTableBlock, setDisableButttonTableBlock] =
    useState<boolean>(listCandidatoEnviadoContrato.length > 0 ? true : false);
  const disableButtonBlock = (state: boolean) => {
    setDisableButttonTableBlock(state);
  };

  const [disButttonBlockOnly, setDisButttonBlockOnly] =
    useState<boolean>(false);
  const disableButtonBlockOnly = (state: boolean) => {
    setDisButttonBlockOnly(state);
  };

  const handleClickModalRestaurar = (
    primerNombre: string,
    primerApellido: string,
    busPerId: string
  ) => {
    setListaBusPeIdRestaurar([busPerId]);
    const currentRest = [];
    currentRest.push(primerNombre, "   ", primerApellido);
    setListaSeleccionados(currentRest);

    setOpenModalRestaurar(true);
  };

  const handleClickModalRestaurarMa = () => {
    const currentRest = [...listCandidatoPendienteContrato];

    const aRestaurar = currentRest.map((item: any) => (
      <div key={item.personaDni}>
        <p className="pt-3">
          {item.primerNombre + " " + " " + item.primerApellido}
        </p>
      </div>
    ));
    const aRestaurarBusperId = currentRest.map((item: any) => item.busPerId);

    setListaSeleccionados(aRestaurar);
    setListaBusPeIdRestaurar(aRestaurarBusperId);
    setOpenModalRestaurar(true);
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#404040",
      marginTop: "-0.41em !important",
      display: "flex",
      position: "relative",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#404040",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 500,
      lineHeight: "23px",
      fontStyle: "normal",
      height: "42px",
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
  }));

  const BootstrapTooltipEstado = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#262626",
      fill: "#fafafa",
      backgroundColor: "#fafafa",
      marginTop: "-0.41em !important",
      display: "flex",
      top: "-2px",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      width: "250px",
      height: "230px",
      border: "1px solid #262626",
      color: "#262626",
      backgroundColor: "#fafafa",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: "600",
      lineHeight: "20px",
      fontStyle: "normal",
      top: "-13px",
    },
  }));

  const getListDatosContratos = async (values: any) => {
    const { token } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/contrato`,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaDataContrato({ dispatch, payload: data });

      if (data && data.causales && data.causales.length > 0) {
        const causales: IOptionDropDownList[] = data.causales.map(
          (item: any) => {
            return {
              code: item.causalId,
              name: item.letra,
            };
          }
        );
        setListCausales(causales);

        const diasPorCausal: IOptionDropDownList[] = data.causales.map(
          (item: any) => {
            return {
              code: item.letra,
              name: item.topeDias,
            };
          }
        );

        setDiasPorCausales(diasPorCausal);

        const listCausalDesvinculacion: IOptionDropDownList[] =
          data.causalDesvinculacion.map((item: any) => {
            return {
              code: item.causalDesvinculacionId,
              name: item.articulo,
            };
          });

        setCausalesDesvinculacion(listCausalDesvinculacion);

        const divisiones: IOptionDropDownList[] = data.divisiones.map(
          (item: any) => {
            return {
              code: item.divisionId,
              name: item.abreviatura,
            };
          }
        );
        setListDivisiones(divisiones);

        const sucursales: IOptionDropDownList[] = data.sucursales.map(
          (item: any) => {
            return {
              code: item.SCITeamwork,
              name:
                item.nombre + "  -  " + item.direccion + "  -  " + item.comuna,
            };
          }
        );
        setListSucursales(sucursales);

        if (data && data.motivos && data.motivos.length > 0) {
          const motivos: IOptionDropDownList[] = data.motivos.map(
            (item: any) => {
              return {
                code: item.motivoId,
                name: item.nombre,
              };
            }
          );
          setListMotivos(motivos);
          setListMotivosFull(data.motivos);
        }
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListCandidatePendienteContrato = async (values: any) => {
    const { idCliente, token, objetoSendFiltro } = values;
    
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/contratacion/pendientes-solicitud?userId=${state.userId}&clientes=${idCliente}`,
        { filtros: objetoSendFiltro },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoPendienteContrato({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListCandidateEnviadoContrato = async (values: any) => {
    const { idCliente, token, objetoSendFiltro } = values;

    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}contratacion/enviados`,
        { filtros: objetoSendFiltro },
        {
          params: {
            userId: state.userId,
            estados: STATE_CANDIDATE.SOL_CONTR, 
            clientes: idCliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCandidatoEnviadoContrato({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDatosContratos = async (values: any) => {
    const { busPerId, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/registro-contrato?userId=`,
        {
          params: {
            userId: state.userId,
            busPerId: "039fc79e-a5b1-4f77-ac81-046b5a3fc50b", //busPerId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setDatosContrato(data); //{ dispatch, payload: data }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const [aBusPerId, setABusPerId] = useState<any[]>([]);

  const getRegistroContrato = async (values: any) => {
    const { objsendContrato, token, idCliente, objetoSendFiltro,formDocumentos , personId, setIsLoading} = values;
    const body = {
      candidatos: objsendContrato.candidatos,
      causalId: objsendContrato.causal.value?.code ?? "",
      fechaInicio:
        formatDateToYYYYMMDD({
          date: objsendContrato.fechaInicio.value,
          format: "-",
        }) ?? "",
      fechaTermino:
        formatDateToYYYYMMDD({
          date: objsendContrato.fechaTermino.value,
          format: "-",
        }) ?? "",
      funciones: objsendContrato.funciones.value ?? "",
      nombre: objsendContrato.nombre.value ?? "",
      personaDNI: objsendContrato.rut.value ?? "",
      SCITeamwork: objsendContrato.sucursal.value?.code ?? "",
      codCargo: objsendContrato.codCargo.value ?? "",
    };
    setIsLoading && setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/registro-contrato?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro, setIsLoading });
      setCleanFilter(true);
      if(formDocumentos && personId )  {
      const documentos = Object.entries(formDocumentos);
      const promesasSubida = documentos.map(async ([key, items]) => {
        const { value: file, fechaExpiracion }: any = items;
        if (file) {
          try {
            await createDocumentCandidate({
              file,
              personaId: personId,
              tipoDocumento: key,
              fechaExpiracion:
                formatDateToYYYYMMDD({ date: fechaExpiracion, format: "-" }) ??
                undefined,
              token,
            });
            //console.log(`Documento ${key} subido correctamente.`);

            const fechaActual = new Date()
            const fechaIngresadaObj = new Date(formatDateToYYYYMMDD({ date: fechaExpiracion, format: "-" }));
            const comparacion = ()=>{return(fechaIngresadaObj < fechaActual)}
            
            if(comparacion())
            {
              console.log("Error Fecha menor a la Actual")
              setErrorFechaMenor(true)
            
            }
          } catch (error) {
            console.error(`Error al subir el documento ${key}:`, error);
          }
        }
      });
    
      try {
        await Promise.all(promesasSubida);

        if(errorFechaMenor){
          createToast({
            title: "Error al Guardar Documento",
            message:
              "Error al guardar el documento, por favor intenta nuevamente",
            type: "danger",
          });
        }
      } catch (error) {
        console.error("Al menos un documento falló al subirse:", error);
      }
}
      createToast({
        title: "Asignación de datos correcta",
        message: "Datos guardados correctamente.",
        type: "success",
      });
    } catch (error) {
      createToast({
        title: "Error al guardar",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getSolicitudContrato = async (values: any) => {
    const { busPerId, token, idCliente, objetoSendFiltro } = values;

    const body = {
      personas: busPerId,
    };
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/solicitar-contrato?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;

      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
      setListCandidatoPendienteContrato([]);
      setInSolicitudContrato(true)
    const processed = data.results.processed;
      const noProcessed = data.results.noProcessed;
      if (processed.length > 0) {
       createToast({
            title: "Solicitud enviada",
            message: "Revisa en candidatos enviados a contrato",
            type: "success",
          });
      }
      if (noProcessed.length > 0 ) {
        setListNoProcess(noProcessed), setOpenModalNoProcess(true);
      }
     /* if (
        data.results &&
        data.results.enqueuedSuccess &&
        data.summary &&
        data.summary.totalEnqueuedSuccess !== undefined
      ) {
        if (
          (data.results.enqueuedSuccess.length === busPerId.length &&
            data.summary.totalEnqueuedSuccess === busPerId.length) ||
          data.results.outOfDeadline.length > 0
        ) {
          setCleanFilter(true);
          createToast({
            title: "Solicitud enviada",
            message: "Revisa en candidatos enviados a contrato",
            type: "success",
          });
        } else {
          createToast({
            title: "Error al enviar",
            message: "Por favor intenta nuevamente más tarde",
            type: "danger",
          });
        }
      }*/
    } catch (error) {
      createToast({
        title: "Error al enviar",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const detenerSolicitudContrato = async (values: any) => {
    const { objetoData, token, idCliente, objetoSendFiltro } = values;

    const body = {
      personas: objetoData,
    };
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/detener-solicitud-contrato?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
      setListCandidatoEnviadoContrato([])
      createToast({
        title: "Solicitud detenida",
        message: "La Solicitud de contrato detenida correctamente",
        type: "success",
      });
    } catch (error) {
      createToast({
        title: "Solicitud no procesada",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });

      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendActualizarCertificado = async (values: any) => {
    const { busPerId, token, idCliente, objetoSendFiltro } = values;

    const body = {
      solicitudes: busPerId,
    };
    setIsLoading(true);
    //console.log("Act solicitud certificado body", {body, token, idCliente,objetoSendFiltro})
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/contratacion/actualizar-certificado?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
      setListCandidatoEnviadoContrato([])
      createToast({
        title: "Solicitud de certificado Digital Actualizada",
        message: "Actualización de certificado Digital se hizo correctamente",
        type: "success",
      });
    } catch (error) {
      createToast({
        title: "Solicitud no procesada",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });

      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const solicitudFueraPlazo = async (values: any) => {
    const { objetoReestructurado, token, idCliente, objetoSendFiltro } = values;

    const body = {
      solicitudes: objetoReestructurado,
    };
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/contratacion/fuera-plazo?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
      /*createToast({
        title: "Solicitud detenida",
        message: "La Solicitud de contrato detenida correctamente",
        type: "success",
      });*/
    } catch (error) {
      /*createToast({
        title: "Solicitud no procesada",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });*/

      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const restaurarCandidato = async (values: any) => {
    const { objetoData, idCliente, token, objetoSendFiltro } = values;
    console.log("data in state restaurar",objetoData )
    setIsLoading(true);
    const body = { procesos: objetoData };

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/retroceder-proceso?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      console.log(" in state result data",data )
      const processed = data.results.processed;
      const noProcessed = data.results.noProcessed;
      if (processed.length > 0) {
        createToast({
          title: "Candidato Restaurado",
          message: "Candidato Devuelto a Reclutamiento",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        setListNoProcess(noProcessed), setOpenModalNoProcess(true);
      }
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
    } catch (error) {
      createToast({
        title: "Solicitud no procesada",
        message: "Por favor intenta nuevamente más tarde",
        type: "danger",
      });
    }
  };

  const updateCandidateState = async (values: any) => {
    const { busPerId, estado, idCliente, typeList, token } = values;
    setIsLoading(true);
    let arrayPadre: string[] = [];
    let arrayUpdate: any[] = [];
    if (!typeList) {
      console.log(
        "Debe enviar el tipo de listado que desea modificar: confirmado o pendiente"
      );
    }
    if (!busPerId) {
      if (typeList === TYPE_LIST.candidato_asistencia_confirmada) {
        arrayPadre = state.listaCandidatoConfirmado.filter((item: any) =>
          listServicioId.includes(item.personaDni)
        );
        setListServicioId([]);
      } else if (typeList === TYPE_LIST.candidato_pendiente_validacion) {
        arrayPadre = state.listaCandidatoPendiente.filter((item: any) =>
          listAsistenciaId.includes(item.personaDni)
        );
        setListAsistenciaId([]);
      } else {
        arrayPadre = [];
        setListAsistenciaId([]);
      }
      arrayUpdate = arrayPadre.map((item: any) => {
        return {
          busPerId: item.busPerId,
          newStatus: estado,
        };
      });
    } else {
      arrayUpdate = [{ busPerId, newStatus: estado }];
    }

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}cambiar-estado-candidato-proceso?userId=${initialState.userId}`,
        arrayUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const dataStatus = data.results.busPerUpdated[0].newStatus;
      getListCandidateConfirmed({ idCliente, token });
      getListCandidatePending({ idCliente, token });
      if (typeList === TYPE_LIST.candidato_asistencia_confirmada) {
        if (dataStatus === STATE_CANDIDATE.PEND_VAL_CLI) {
          createToast({
            title: "Envío Correcto",
            message: "Seleccion enviada a validación con éxito",
            type: "success",
          });
        }
        if (dataStatus === STATE_CANDIDATE.PEND_SOL_CONTR) {
          createToast({
            title: "Envío Correcto",
            message: "Seleccion enviada a contratación con éxito",
            type: "success",
          });
        }
        if (dataStatus === STATE_CANDIDATE.ASISTIO) {
          createToast({
            title: "Envío Correcto",
            message: "Candidato(s) con asistencia confirmada",
            type: "success",
          });
        }
      }

      if (typeList === TYPE_LIST.candidato_pendiente_validacion) {
        if (dataStatus === STATE_CANDIDATE.ASISTIO) {
          createToast({
            title: "Restauración Correcta",
            message: "Seleccion restaurada con éxito",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.log("ha ocurrido algo ", error);
      createToast({
        title: "Solicitud No Realizada",
        message: "Error en el envío, por favor intenta nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading(false);
      arrayPadre = [];
    }
  };

  const updateCandidateStateValidateClient = async (values: any) => {
    const { busPerId, estado, idCliente, typeList, token } = values;
    setIsLoading(true);
    let arrayPadre: string[] = [];
    let arrayUpdate: any[] = [];
    if (!typeList) {
      console.log(
        "Debe enviar el tipo de listado que desea modificar: confirmado o pendiente"
      );
    }
    if (!busPerId) {
      if (typeList === TYPE_LIST.candidato_pendiente_aprobacion) {
        arrayPadre = state.listaCandidatoPendienteValidacion.filter(
          (item: any) =>
            listCandidatoPendienteValidacionCliente.includes(item.personaDni)
        );
        setListCandidatoPendienteValidacionCliente([]);
      } else if (typeList === TYPE_LIST.candidato_validados) {
        arrayPadre = state.listaCandidatoValidado.filter((item: any) =>
          listCandidatoValidadoValidacionCliente.includes(item.personaDni)
        );
        setListCandidatoValidadoValidacionCliente([]);
      } else {
        arrayPadre = [];
        setListCandidatoPendienteValidacionCliente([]);
      }
      arrayUpdate = arrayPadre.map((item: any) => {
        return {
          busPerId: item.busPerId,
          newStatus: estado,
        };
      });
    } else {
      arrayUpdate = [{ busPerId, newStatus: estado }];
    }

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}cambiar-estado-candidato-proceso?userId=${state.userId}`,
        arrayUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const dataStatus = data.results.busPerUpdated[0].newStatus;
      getListCandidateValidate({ idCliente, token });
      getListCandidatePendingValidate({ idCliente, token });
      if (dataStatus === STATE_CANDIDATE.PEND_SOL_CONTR) {
        createToast({
          title: "Envío Correcto",
          message: "Seleccion enviada a contratación con éxito",
          type: "success",
        });
      }

      if (dataStatus === STATE_CANDIDATE.PEND_VAL_CLI) {
        createToast({
          title: "Restauración correcta",
          message: "Selección restaurada con éxito",
          type: "success",
        });
      }
    } catch (error) {
      console.log("ha ocurrido algo ", error);
      createToast({
        title: "Solicitud No Realizada",
        message: "Error en el envío, por favor intenta nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading(false);
      arrayPadre = [];
    }
  };

  /**
   *
   * TODO: REVISANDO EL ENDPOINT BLOQUEAR CANDIDATO
   */

  const createDiscartBlockCandidate = async (values: any) => {
    const { idCliente, dataCandidate, action, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/exclusion-persona-descarte`,
        {
          excPerList: dataCandidate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      getListCandidateConfirmed({ idCliente, token });
      getListCandidatePending({ idCliente, token });
      createToast({
        title: `${action === "block" ? "Bloqueo" : "Descarte"} Correcto`,
        message: `Selección ${
          action === "block" ? "bloqueada" : "descartada"
        } con éxito`,
        type: "success",
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: `Solicitud No Realizada`,
        message: `Error en el ${
          action === "block" ? "bloqueo" : "descarte"
        }, intenta nuevamente`,
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createDiscartBlockCandidateValidateClient = async (values: any) => {
    const { idCliente, dataCandidate, action, token } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/exclusion-persona-descarte`,
        {
          excPerList: dataCandidate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      getListCandidatePendingValidate({ idCliente, token });
      getListCandidateValidate({ idCliente, token });
      createToast({
        title: `${action === "block" ? "Bloqueo" : "Descarte"} Correcto`,
        message: `Selección ${
          action === "block" ? "bloqueada" : "descartada"
        } con éxito`,
        type: "success",
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: `Solicitud No Realizada`,
        message: `Error en el ${
          action === "block" ? "bloqueo" : "descarte"
        }, intenta nuevamente`,
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const putMarcarAsistencia = async (values: any) => {
    const { personas, token } = values;
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/marcar-asistencia`,
        { personas: personas },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      // createToast({
      //   title: "Asistencia confirmada",
      //   message: "Registro enviado correctamente",
      //   type: "success",
      // });
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error en registro",
        message: "Ha ocurrido un problema, intente nuevamente",
        type: "danger",
      });
    }
  };

  const getListaAreasNegCliente = async (values: any) => {
    const { token, idCliente } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/area-negocio-cliente?userId=${state.userId}&clienteId=${idCliente}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      if (data && data.length > 0) {
        const causales: IOptionDropDownList[] = data.map((item: any) => {
          return {
            code: item.areaNegocioId,
            name: item.areaNegocioCod,
          };
        });
        setListaAreasNegCliente(causales);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createDocumentCandidate = async (values: any): Promise<void> => {
    const { file, tipoDocumento, personaId, fechaExpiracion, token, setIsLoading } = values;
    const formData = new FormData();
    setIsLoading && setIsLoading(true);
    try {
      if (file) {
        formData.append("file", file[0]);
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/documentos`,
          formData,
          {
            params: {
              userId: state.userId,
              personaId,
              tipoDocumento, //dcuemtno por defecto
              fechaExpiracion,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const data = result.data;
        //console.log("Documento guardado con exito: ", data);
      }
    } catch (error: any) {
      const { data, status }: any = error.response;
      const { message } = data;
      if (status == 400) {
        createToast({
          title: "Error al guardar documentos",
          message: `${message}`,
          type: "danger",
        });
      }
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  return (
    <ValidationKamContext.Provider
      value={{
        ...state,
        toasts,
        token,
        listServicioId,
        listAsistenciaId,
        listCandidatoPendienteValidacionCliente,
        listCandidatoValidadoValidacionCliente,
        listCandidatoPendienteContrato,
        listCandidatoEnviadoContrato,
        isError,
        isLoading,
        disableButttonAction,
        disableButttonTableBlock,
        listaSeleccionados,
        openModalRestaurar,
        BootstrapTooltip,
        BootstrapTooltipEstado,
        disButttonBlockOnly,
        listCausales,
        listMotivos,
        listMotivosFull,
        listSucursales,
        diasPorCausales,
        datosContrato,
        aBusPerId,
        getToken,
        setABusPerId,
        detenerSolicitudContrato,
        getDatosContratos,
        setDiasPorCausales,
        setListCausales,
        setListMotivos,
        setListSucursales,
        getListDatosContratos,
        getRegistroContrato,
        getSolicitudContrato,
        disableButtonBlockOnly,
        setOpenModalRestaurar,
        handleClickModalRestaurar,
        handleClickModalRestaurarMa,
        setListaSeleccionados,
        listaBusPeIdRestaurar,
        cleanFilter,
        setCleanFilter,
        setListaBusPeIdRestaurar,
        sendActualizarCertificado,
        solicitudFueraPlazo,
        restaurarCandidato,
        disableButtonBlock,
        setDisableButttonTableBlock,
        disableButton,
        setIsError,
        setIsLoading,
        setListAsistenciaId,
        setVisibleActionBlocking,
        setListServicioId,
        setListCandidatoPendienteValidacionCliente,
        setListCandidatoValidadoValidacionCliente,
        setListCandidatoPendienteContrato,
        setListCandidatoEnviadoContrato,
        getCounts,
        getExclusiones,
        getListCandidateConfirmed,
        getListCandidatePending,
        getListCandidatePendingValidate,
        getListCandidateValidate,
        getListCandidatePendienteContrato,
        getListCandidateEnviadoContrato,
        updateCandidateState,
        updateCandidateStateValidateClient,
        createDiscartBlockCandidate,
        createDiscartBlockCandidateValidateClient,
        putMarcarAsistencia,
        listDivisiones,
        listaAreasNegCliente,
        getListaAreasNegCliente,
        causalesDesvinculacion,
        listNoProcess,
        openModalNoProcess,
        setOpenModalNoProcess,
        inSolicitudContrato,
         setInSolicitudContrato
      }}
    >
      {children}
    </ValidationKamContext.Provider>
  );
};
function translate3d(
  arg0: number,
  px: any,
  arg2: number,
  px1: any,
  arg4: number,
  px2: any
):
  | string[]
  | import("csstype").Property.Transform
  | import("csstype").Property.Transform[]
  | undefined {
  throw new Error("Function not implemented.");
}
