import { useContext, useEffect, useState } from "react";
import "../../../assets/css/sub-menu.min.css";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import {
  FILTER_PROFILE,
  LIST_ROLES,
  PROFILE_SEL_MASIVA,
  PROFILE_SEL_PROF,
} from "../../../diccionary/Diccionary";
import MediaQuery from "../../ui-responsive/MediaQuery";
import TitleSistema from "./Title";
import { IUserAccess } from "../../../interfaces/ISeleccion";
import { NavLink } from "react-router-dom";

interface ISubMenu {
  itemMenu: string | undefined;
  setItemMenu: Function;
  itemMenuFilter: string;
}

const SubMenu = (props: ISubMenu) => {
  const { itemMenu, setItemMenu, itemMenuFilter } = props;
  const {
    countAssign,
    getCounts,
    listUserAccess,
    getUserAccess,
    profile,
    getToken,
    token,
    roles,
  } = useContext(SeleccionContext);
  const [clienteName, setClienteName] = useState<string>("");
  const [primerLocation, setPrimerLocation] = useState<string>("");
  const [segundaLocation, setSegundaLocation] = useState<string>("");
  const [tercerLocation, setTercerLocation] = useState<string>("");
  const [itemMasivo, setItemMasivo] = useState<string>("");
  const [itemProf, setItemProf] = useState<string>("");
  const [itemSelection, setItemSelection] = useState<string>("itemSelection");
  const [currenFilterStorage, setCurrentFilterStorage] = useState<any>();
  const { width, sm } = MediaQuery();

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getUserAccess({ token });
      getCounts({ token });
    }
  }, [token]);

  useEffect(() => {
    if (segundaLocation) {
      const nameCli = countAssign.find(({ clienteId }: any) =>
        [segundaLocation, tercerLocation].includes(clienteId)
      );
      setClienteName(nameCli?.clienteNombre ?? "");
    }
  }, [segundaLocation, getCounts]);

  useEffect(() => {
    if (localStorage.getItem(itemSelection)) {
      const copyStorage: any = localStorage.getItem(itemSelection);
      setCurrentFilterStorage(JSON.parse(copyStorage));
    }
  }, [localStorage.getItem(itemSelection)]);

  useEffect(() => {
    if (listUserAccess) {
      setItemMasivo(listUserAccess.map(({ masivo }: IUserAccess) => masivo));
      setItemProf(
        listUserAccess.map(({ profesional }: IUserAccess) => profesional)
      );
    }
  }, [listUserAccess]);

  useEffect(() => {
    try {
      const url = window.location.pathname;
      const parts = url.split("/");
      const primerValor = parts[1];
      const segundoValor = parts[2] ?? "";
      const tercerValor = parts[3] ?? "";
      setPrimerLocation(primerValor);
      setSegundaLocation(segundoValor);
      setTercerLocation(tercerValor);
    } catch (error) {
      console.log("error en crear rutas");
    }
  }, [segundaLocation, itemMenu]);

  const handleClickGoRuta = (values: any) => {
    const { ruta, page, page2 } = values;
    // cleanStaorageFilterOperaciones();
    if (page2) {
      return `/${ruta}/${page}/${page2}`;
    }
    if (page) {
      return `/${ruta}/${page}`;
    } else {
      return `/${ruta}`;
    }
  };

  const cleanStaorageFilterOperaciones = () => {
    localStorage.setItem("Filtros2", "");
    localStorage.setItem("filterGeneralDashboard", "");
    localStorage.setItem("filtroProcesoActivo", "");
    localStorage.setItem("filtroDotacionVigente", "");
    localStorage.setItem("filtroContratosSolicitudPendientes", "");
    localStorage.setItem("filtroContratoSolProcesadas", "");
  };

  const sel_all =
    PROFILE_SEL_PROF.includes(profile) || PROFILE_SEL_MASIVA.includes(profile);

  return (
    <>
      {width >= sm && (
        <>
          {primerLocation === "registros" && (
            <>
              <section className="content-submenu">
                {sel_all && (
                  <>
                    <div className="content-page">
                      {currenFilterStorage &&
                      currenFilterStorage == "profesional" ? (
                        <>
                          <NavLink
                            to={handleClickGoRuta({
                              ruta: "seleccionprofesional",
                              page: "porvalidar",
                            })}
                            className="page-tag-nav"
                          >
                            Selección Profesional
                          </NavLink>
                          <label className="page-tag">/</label>
                        </>
                      ) : (
                        <>
                          <NavLink
                            className="page-tag-nav"
                            to={handleClickGoRuta({
                              ruta: "seleccionmasiva",
                              page: "porvalidar",
                            })}
                          >
                            Selección Masiva
                          </NavLink>
                          <label className="page-tag">/</label>
                        </>
                      )}
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: primerLocation,
                          page: "habilitados",
                        })}
                      >
                        Registros
                      </NavLink>
                      <label className="page-tag">/</label>
                      <label className="page-tag">
                        {segundaLocation.charAt(0).toUpperCase()}
                        {segundaLocation.slice(1).toLowerCase()}
                      </label>
                    </div>

                    <div className="content-nav">
                      {[
                        "Postulaciones",
                        "Inhabilitados",
                        "Habilitados",
                        "Traspasos",
                      ].map((item: any) => {
                        return (
                          <NavLink
                            key={item}
                            to={handleClickGoRuta({
                              ruta: primerLocation,
                              page: item.toLowerCase(),
                            })}
                            className={`nav-tag ${
                              item ==
                              segundaLocation.charAt(0).toUpperCase() +
                                segundaLocation.slice(1).toLowerCase()
                                ? "select"
                                : ""
                            }`}
                          >
                            {item}
                          </NavLink>
                        );
                      })}
                    </div>
                  </>
                )}
              </section>

              {segundaLocation && segundaLocation == "postulaciones" && (
                <TitleSistema
                  title="CANDIDATOS REGISTRADOS POR FORMULARIO"
                  subtitle="En esta sección puede ver candidatos que ingresaron sus datos directamente en el formulario de registro del postulante. Ver datos ingresados y asignar proceso para ingresar a selección."
                />
              )}

              {segundaLocation && segundaLocation == "inhabilitados" && (
                <TitleSistema
                  title="CANDIDATOS INHABILITADOS"
                  subtitle="En esta sección puedes visualizar los registros de candidatos que se encuentra inhabilitados para participar de procesos."
                />
              )}

              {segundaLocation && segundaLocation == "habilitados" && (
                <TitleSistema
                  title="CANDIDATOS HABILITADOS"
                  subtitle="En esta sección puede registrar candidatos, asignarlos a procesos activos, bloquearlos, y solicitar registros en uso a otros usuarios."
                />
              )}

              {segundaLocation && segundaLocation == "traspasos" && (
                <TitleSistema
                  title="TRASPASOS DE REGISTROS"
                  subtitle="En esta sección puede gestionar el traspaso de candidatos. Ceder o rechazar solicitudes recibidas y solicitar registro a otros reclutadores."
                />
              )}
            </>
          )}

          {primerLocation === "procesos" && (
            <>
              <section className="content-submenu">
                {sel_all && (
                  <>
                    <div className="content-page">
                      {currenFilterStorage &&
                      currenFilterStorage == "profesional" ? (
                        <>
                          <NavLink
                            className="page-tag-nav"
                            to={handleClickGoRuta({
                              ruta: "seleccionprofesional",
                              page: "porvalidar",
                            })}
                          >
                            Selección Profesional
                          </NavLink>
                          <label className="page-tag">/</label>
                        </>
                      ) : (
                        <>
                          <NavLink
                            className="page-tag-nav"
                            to={handleClickGoRuta({
                              ruta: "seleccionmasiva",
                              page: "porvalidar",
                            })}
                          >
                            Selección Masiva
                          </NavLink>
                          <label className="page-tag">/</label>
                        </>
                      )}
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: primerLocation,
                          page: "activos",
                        })}
                      >
                        Procesos
                      </NavLink>
                      <label className="page-tag">/</label>
                      <label className="page-tag">
                        {segundaLocation.charAt(0).toUpperCase()}
                        {segundaLocation.slice(1).toLowerCase()}
                      </label>
                    </div>

                    <div className="content-nav">
                      {["Solicitudes", "Activos"].map((item: any) => {
                        return (
                          <NavLink
                            key={item}
                            className={`nav-tag ${
                              item ==
                              segundaLocation.charAt(0).toUpperCase() +
                                segundaLocation.slice(1).toLowerCase()
                                ? "select"
                                : ""
                            }`}
                            to={handleClickGoRuta({
                              ruta: primerLocation,
                              page: item.toLowerCase(),
                            })}
                          >
                            {item}
                          </NavLink>
                        );
                      })}
                    </div>
                  </>
                )}
              </section>

              {segundaLocation && segundaLocation == "activos" && (
                <TitleSistema
                  title="PROCESOS ACTIVOS"
                  subtitle="En esta sección puedes visualizar los procesos de reclutamiento que le han sido asignados."
                />
              )}

              {segundaLocation && segundaLocation == "inactivos" && (
                <TitleSistema
                  title="PROCESOS INACTIVOS"
                  subtitle="En esta sección puedes visualizar los procesos de reclutamiento solicitados y sin asignación de equipo."
                />
              )}
            </>
          )}

          {primerLocation === "seleccionprofesional" && (
            <>
              <section className="content-submenu">
                {itemProf && (
                  <>
                    <div className="content-page">
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: "seleccionprofesional",
                          page: "porvalidar",
                        })}
                      >
                        Selección Profesional
                      </NavLink>
                      <label className="page-tag">/</label>
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: "seleccionprofesional",
                          page: "porvalidar",
                        })}
                      >
                        Selección
                      </NavLink>
                      <label className="page-tag">/</label>
                      <label className="page-tag">
                        {segundaLocation === "porvalidar"
                          ? "Por Validar"
                          : segundaLocation.charAt(0).toUpperCase() +
                            segundaLocation.slice(1).toLowerCase()}
                      </label>
                    </div>

                    <div className="content-nav">
                      {["Por Validar", "Preseleccionados", "Seleccionados"].map(
                        (item: any) => {
                          return (
                            <NavLink
                              key={item}
                              className={`nav-tag ${
                                item.replace(/\s/g, "").toLowerCase() ==
                                segundaLocation
                                  ? "select"
                                  : ""
                              }`}
                              to={handleClickGoRuta({
                                ruta: primerLocation,
                                page: item.replace(/\s/g, "").toLowerCase(),
                              })}
                            >
                              {item}
                            </NavLink>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </section>

              {segundaLocation && segundaLocation == "porvalidar" && (
                <TitleSistema
                  title="CANDIDATOS CON PROCESO ACTIVO"
                  subtitle="En esta sección puede completar registro de candidatos, enviarlos a primera validación de cliente y bloquearlos."
                />
              )}

              {segundaLocation && segundaLocation == "preseleccionados" && (
                <TitleSistema
                  title="CANDIDATOS PRESELECCIONADOS"
                  subtitle="En esta sección puede ver los candidatos con una primera validación del cliente. Puede avanzarlos o descartarlos del proceso, confirmar su entrevista y realizar el examen psico laboral."
                />
              )}

              {segundaLocation && segundaLocation == "seleccionados" && (
                <TitleSistema
                  title="CANDIDATOS SELECCIONADOS"
                  subtitle="En esta sección puede ver los candidatos con ambas validaciones del Cliente. Puede realizar el examen psico laboral, descartar y avanzarlos a operaciones para su contratación."
                  note={
                    "Nota: El registro del candidato debe contar con todos los datos obligatorios para enviar a Operaciones."
                  }
                />
              )}
            </>
          )}

          {primerLocation === "seleccionmasiva" && (
            <>
              <section className="content-submenu">
                {itemMasivo && (
                  <>
                    <div className="content-page">
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: "seleccionmasiva",
                          page: "porvalidar",
                        })}
                      >
                        Selección Masiva
                      </NavLink>
                      <label className="page-tag">/</label>
                      <NavLink
                        className="page-tag-nav"
                        to={handleClickGoRuta({
                          ruta: "seleccionmasiva",
                          page: "porvalidar",
                        })}
                      >
                        Selección
                      </NavLink>
                      <label className="page-tag">/</label>
                      <label className="page-tag">
                        {segundaLocation === "porvalidar"
                          ? "Por Validar"
                          : segundaLocation.charAt(0).toUpperCase() +
                            segundaLocation.slice(1).toLowerCase()}
                      </label>
                    </div>

                    <div className="content-nav">
                      {["Por Validar", "Validados"].map((item: any) => {
                        return (
                          <NavLink
                            key={item}
                            className={`nav-tag ${
                              item.replace(/\s/g, "").toLowerCase() ==
                              segundaLocation
                                ? "select"
                                : ""
                            }`}
                            to={handleClickGoRuta({
                              ruta: primerLocation,
                              page: item.replace(/\s/g, "").toLowerCase(),
                            })}
                          >
                            {item}
                          </NavLink>
                        );
                      })}
                    </div>
                  </>
                )}
              </section>

              {segundaLocation && segundaLocation == "porvalidar" && (
                <TitleSistema
                  title="CANDIDATOS CON PROCESO ACTIVO"
                  subtitle="En esta sección puede completar registro de candidatos, enviarlos a primera validación de cliente y bloquearlos."
                  note={
                    "Nota: El registro del candidato debe tener los datos requeridos por el área de negocio para avanzarlo a la siguiente etapa."
                  }
                />
              )}

              {segundaLocation && segundaLocation == "validados" && (
                <TitleSistema
                  title="CANDIDATOS VALIDADOS"
                  subtitle="En esta sección puede ver los candidatos con una primera validación del cliente. Puede avanzarlos o descartarlos del proceso, confirmar su entrevista y preferencia de transporte con Teamwork."
                  note={
                    "Nota: El registro del candidato debe contar con todos los datos obligatorios y confirmar entrevista para enviar a Operaciones."
                  }
                />
              )}
            </>
          )}

          {[
            FILTER_PROFILE.admin_ops,
            FILTER_PROFILE.tw_ops,
            FILTER_PROFILE.adm_tw,
          ].includes(profile) && (
            <>
              {(roles && roles.includes(LIST_ROLES.JEFE_OPS)) ||
              [FILTER_PROFILE.admin_ops].includes(profile) ? (
                <>
                  <section className="content-submenu">
                    {segundaLocation && (
                      <>
                        <div className="content-page">
                          <NavLink
                            className="page-tag-nav"
                            to={handleClickGoRuta({
                              ruta: "resumen",
                            })}
                          >
                            Gestión Contratación
                          </NavLink>
                          <label className="page-tag">/</label>
                          <NavLink
                            className="page-tag"
                            to={handleClickGoRuta({
                              ruta: "resumen",
                            })}
                          >
                            Inicio
                          </NavLink>
                        </div>
                      </>
                    )}
                  </section>
                </>
              ) : (
                <>
                  {/* SUBMENU RESUMEN */}
                  {primerLocation === "resumen" && (
                    <>
                      <section className="content-submenu">
                        {segundaLocation && (
                          <>
                            <div className="content-page">
                              <NavLink
                                className="page-tag-nav"
                                to={handleClickGoRuta({
                                  ruta: "contratacion",
                                })}
                              >
                                Clientes Asignados
                              </NavLink>
                              <label className="page-tag">/</label>
                              <label className="page-tag-nav">
                                {clienteName}
                              </label>
                              <label className="page-tag">/</label>
                              <NavLink
                                className="page-tag"
                                to={handleClickGoRuta({
                                  ruta: "resumen",
                                  page: segundaLocation,
                                })}
                              >
                                Resumen
                              </NavLink>
                            </div>

                            <div className="content-nav">
                              {[
                                "Resumen",
                                "Ingresos",
                                "Dotacion Activa",
                                "Bajas Confirmadas",
                              ].map((item: any) => {
                                return item == "Ingresos" ? (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: "contratacion",
                                      page: segundaLocation,
                                    })}
                                  >
                                    {item}
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: item
                                        .replace(/\s/g, "")
                                        .toLowerCase(),
                                      page: segundaLocation,
                                    })}
                                  >
                                    {item}
                                  </NavLink>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </section>

                      {/*segundaLocation && (
                          <TitleDashboard
                            title="Bienvenido a la Gestión de Contratación de Candidatos"
                          /> 
                      )*/}
                    </>
                  )}
                  {/* SUBMENU CONTRATACION */}
                  {primerLocation == "contratacion" && !segundaLocation && (
                    <>
                      <section className="content-submenu">
                        <>
                          <div className="content-page">
                            <NavLink
                              className="page-tag-nav"
                              to={handleClickGoRuta({
                                ruta: "contratacion",
                              })}
                            >
                              Clientes Asignados
                            </NavLink>
                          </div>
                        </>
                      </section>

                      {primerLocation == "contratacion" && !segundaLocation && (
                        <TitleSistema
                          title="CLIENTES ASIGNADOS PARA INGRESOS"
                          subtitle="Aquí puede revisar los clientes asignados, en cada uno se encuentra la lista de candidatos aprobados en espera de solicitud de contrato."
                        />
                      )}
                    </>
                  )}
                  {primerLocation === "contratacion" && (
                    <>
                      <section className="content-submenu">
                        {segundaLocation && (
                          <>
                            <div className="content-page">
                              <NavLink
                                className="page-tag-nav"
                                to={handleClickGoRuta({
                                  ruta: "contratacion",
                                })}
                              >
                                Clientes Asignados
                              </NavLink>
                              <label className="page-tag">/</label>
                              <label className="page-tag-nav">
                                {clienteName}
                              </label>
                              <label className="page-tag">/</label>
                              <NavLink
                                className="page-tag"
                                to={handleClickGoRuta({
                                  ruta: "contratacion",
                                  page: segundaLocation,
                                })}
                              >
                                Ingresos
                              </NavLink>
                            </div>

                            <div className="content-nav">
                              {[
                                "Resumen",
                                "Ingresos",
                                "Dotacion Activa",
                                "Bajas Confirmadas",
                              ].map((item: any) => {
                                return item == "Ingresos" ? (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      "contratacion" == primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: "contratacion",
                                      page: segundaLocation,
                                    })}
                                  >
                                    <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: item
                                        .replace(/\s/g, "")
                                        .toLowerCase(),
                                      page: segundaLocation,
                                    })}
                                  >
                                   <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </section>

                      {segundaLocation && (
                        <TitleSistema
                          title="INGRESOS"
                          subtitle="En esta opción puedes validar la documentación de los candidatos y solicitar su contratación. "
                        />
                      )}
                    </>
                  )}
                  {/* SUBMENU DOTACIONES ACTIVAS */}
                  {primerLocation === "dotacionactiva" && (
                    <>
                      <section className="content-submenu">
                        {segundaLocation && (
                          <>
                            <div className="content-page">
                              <NavLink
                                className="page-tag-nav"
                                to={handleClickGoRuta({
                                  ruta: "contratacion",
                                })}
                              >
                                Clientes Asignados
                              </NavLink>
                              <label className="page-tag">/</label>
                              <label className="page-tag-nav">
                                {clienteName}
                              </label>
                              <label className="page-tag">/</label>
                              <NavLink
                                className="page-tag"
                                to={handleClickGoRuta({
                                  ruta: "dotacionactiva",
                                  page: segundaLocation,
                                })}
                              >
                                Dotación Activa
                              </NavLink>
                            </div>

                            <div className="content-nav">
                              {[
                                "Resumen",
                                "Ingresos",
                                "Dotacion Activa",
                                "Bajas Confirmadas",
                              ].map((item: any) => {
                                return item == "Ingresos" ? (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: "contratacion",
                                      page: segundaLocation,
                                    })}
                                  >
                                    <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: item
                                        .replace(/\s/g, "")
                                        .toLowerCase(),
                                      page: segundaLocation,
                                    })}
                                  >
                                    <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </section>

                      {segundaLocation && (
                        <TitleSistema
                          title="DOTACIÓN ACTIVA"
                          subtitle="En esta opción podrás ver los contratos próximos a vencer, confirmar bajas, renovar y actualizar datos de contratos."
                        />
                      )}
                    </>
                  )}
                  {/* SUBMENU BAJAS CONFIRMADAS */}
                  {primerLocation === "bajasconfirmadas" && (
                    <>
                      <section className="content-submenu">
                        {segundaLocation && (
                          <>
                            <div className="content-page">
                              <NavLink
                                className="page-tag-nav"
                                to={handleClickGoRuta({
                                  ruta: "contratacion",
                                })}
                              >
                                Clientes Asignados
                              </NavLink>
                              <label className="page-tag">/</label>
                              <label className="page-tag-nav">
                                {clienteName}
                              </label>
                              <label className="page-tag">/</label>
                              <NavLink
                                className="page-tag"
                                to={handleClickGoRuta({
                                  ruta: "bajasconfirmadas",
                                  page: segundaLocation,
                                })}
                              >
                                Bajas Confirmadas
                              </NavLink>
                            </div>

                            <div className="content-nav">
                              {[
                                "Resumen",
                                "Ingresos",
                                "Dotacion Activa",
                                "Bajas Confirmadas",
                              ].map((item: any) => {
                                return item == "Ingresos" ? (
                                  <NavLink
                                    key={item}
                                    to={handleClickGoRuta({
                                      ruta: "contratacion",
                                      page: segundaLocation,
                                    })}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                  >
                                    <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    key={item}
                                    className={`nav-tag ${
                                      item.replace(/\s/g, "").toLowerCase() ==
                                      primerLocation
                                        ? "select"
                                        : ""
                                    }`}
                                    to={handleClickGoRuta({
                                      ruta: item
                                        .replace(/\s/g, "")
                                        .toLowerCase(),
                                      page: segundaLocation,
                                    })}
                                  >
                                    <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                  </NavLink>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </section>

                      {segundaLocation && (
                        <TitleSistema
                          title="BAJAS CONFIRMADAS"
                          subtitle="En esta opción podrás ver los contratos con la baja confirmada."
                        />
                      )}
                    </>
                  )}

                  {/* SUBMENU CONTRATACION */}
                  {primerLocation == "contratos" && !segundaLocation && (
                    <>
                      <section className="content-submenu">
                        <>
                          <div className="content-page">
                            <NavLink
                              className="page-tag-nav"
                              to={handleClickGoRuta({
                                ruta: "contratacion",
                              })}
                            >
                              Clientes Asignados
                            </NavLink>
                          </div>
                        </>
                      </section>

                      {primerLocation == "contratos" && !segundaLocation && (
                        <TitleSistema
                          title="CLIENTES ASIGNADOS PARA INGRESOS"
                          subtitle="Aquí puede revisar los clientes asignados, en cada uno se encuentra la lista de candidatos aprobados en espera de solicitud de contrato."
                        />
                      )}
                    </>
                  )}

                  {/* SUBMENU CONTRATO */}
                  {primerLocation === "contratos" &&
                    segundaLocation == "resumen" && (
                      <>
                        <section className="content-submenu">
                          {segundaLocation && (
                            <>
                              <div className="content-page">
                                <NavLink
                                  className="page-tag-nav"
                                  to={handleClickGoRuta({
                                    ruta: "contratos",
                                    page: "resumen",
                                  })}
                                >
                                  Contratos
                                </NavLink>
                              </div>

                              <div className="content-nav">
                                {["Resumen", "Pendientes", "Procesadas"].map(
                                  (item: any) => {
                                    return item == "resumen" ? (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: segundaLocation,
                                          page2: tercerLocation,
                                        })}
                                      >
                                        <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    ) : (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: item
                                            .replace(/\s/g, "")
                                            .toLowerCase(),
                                          page2: tercerLocation,
                                        })}
                                      >
                                        <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </section>
                      </>
                    )}

                  {/* SUBMENU CONTRATO */}
                  {primerLocation === "contratos" &&
                    segundaLocation == "pendientes" && (
                      <>
                        <section className="content-submenu">
                          {segundaLocation && (
                            <>
                              <div className="content-page">
                                <NavLink
                                  className="page-tag-nav"
                                  to={handleClickGoRuta({
                                    ruta: "contratos",
                                    page: "resumen",
                                  })}
                                >
                                  Clientes Asignados
                                </NavLink>
                                {clienteName && (
                                  <>
                                    <label className="page-tag">/</label>
                                    <label className="page-tag-nav">
                                      {clienteName}
                                    </label>
                                  </>
                                )}
                                <label className="page-tag">/</label>
                                <NavLink
                                  className="page-tag"
                                  to={handleClickGoRuta({
                                    ruta: primerLocation,
                                    page: segundaLocation,
                                    page2: tercerLocation,
                                  })}
                                >
                                  Pendientes
                                </NavLink>
                              </div>

                              <div className="content-nav">
                                {["Resumen", "Pendientes", "Procesadas"].map(
                                  (item: any) => {
                                    return item == "pendientes" ? (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: segundaLocation,
                                          page2: tercerLocation,
                                        })}
                                      >
                                        <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    ) : (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: item
                                            .replace(/\s/g, "")
                                            .toLowerCase(),
                                          page2: tercerLocation,
                                        })}
                                      >
                                       <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </section>

                        <TitleSistema
                          title="SOLICITUDES PENDIENTES"
                          subtitle="En esta sección podrás ver las solicitudes de contratos recibidas."
                        />
                      </>
                    )}

                  {/* SUBMENU PROCESADAS */}
                  {primerLocation == "contratos" &&
                    segundaLocation == "procesadas" && (
                      <>
                        <section className="content-submenu">
                          {segundaLocation && (
                            <>
                              <div className="content-page">
                                <NavLink
                                  className="page-tag-nav"
                                  to={handleClickGoRuta({
                                    ruta: "contratos",
                                    page: "resumen",
                                  })}
                                >
                                  Clientes Asignados
                                </NavLink>
                                {clienteName && (
                                  <>
                                    <label className="page-tag">/</label>
                                    <label className="page-tag-nav">
                                      {clienteName}
                                    </label>
                                  </>
                                )}
                                <label className="page-tag">/</label>
                                <NavLink
                                  className="page-tag"
                                  to={handleClickGoRuta({
                                    ruta: primerLocation,
                                    page: segundaLocation,
                                    page2: tercerLocation,
                                  })}
                                >
                                  Procesadas
                                </NavLink>
                              </div>

                              <div className="content-nav">
                                {["Resumen", "Pendientes", "Procesadas"].map(
                                  (item: any) => {
                                    return item == "procesadas" ? (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: segundaLocation,
                                          page2: tercerLocation,
                                        })}
                                      >
                                        <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    ) : (
                                      <NavLink
                                        key={item}
                                        className={`nav-tag ${
                                          item
                                            .replace(/\s/g, "")
                                            .toLowerCase() == segundaLocation
                                            ? "select"
                                            : ""
                                        }`}
                                        to={handleClickGoRuta({
                                          ruta: primerLocation,
                                          page: item
                                            .replace(/\s/g, "")
                                            .toLowerCase(),
                                          page2: tercerLocation,
                                        })}
                                      >
                                        <label onClick={() => cleanStaorageFilterOperaciones()}>{item}</label>
                                      </NavLink>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </section>

                        <TitleSistema
                          title="SOLICITUDES PROCESADAS"
                          subtitle="En esta sección podrás ver un histórico de las solicitudes procesadas."
                        />
                      </>
                    )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SubMenu;
