import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import { ICandidatoPendiente } from "../../../../interfaces/ISeleccion";

interface IModalAvanzarProceso {
  listCandidate: any[];
  listData: any[];
  setCandidates?: Function;
  etapa?: string; 
  listRefresh?: Function; 
  setListadoSeleccionado?: Function; 
}

export default (props: IModalAvanzarProceso) => {
  const { listCandidate, listData, setCandidates, etapa, listRefresh, setListadoSeleccionado } = props;
  const { token } = useContext(SeleccionContext);
  const [currentList, setCurrentList] = useState<any>();

  useEffect(() => {
    if (listCandidate && listData) {
      const arrayData = listData.filter(({ personaDNI }: any) =>
        listCandidate.includes(personaDNI)
      );
      setCurrentList(arrayData);
      const sol = arrayData.map(({ busPerId }: any) => {
        return busPerId;
      });
      setCandidates && setCandidates(sol);
    }
  }, [listCandidate]);

  return (
    <>
      <section className="content-table-restaurar">
        <table className="table-restaurar w-90">
          <thead className="thead-table-restaurar">
            <tr className="tr-table-restaurar">
              <th className="td-table-restaurar">Nombre</th>
              <th className="td-table-restaurar">Rut</th>
              <th className="td-table-restaurar">Proceso</th>
              <th className="td-table-restaurar">Cliente</th>
              <th className="td-table-restaurar">Destino</th>
            </tr>
          </thead>
          <tbody className="tbody-table-restaurar">
            {currentList &&
              currentList.map((item: any) => {
                const {
                  personaDNI,
                  primerNombre,
                  primerApellido,
                  busquedaId,
                  clienteNombre,
                } = item;
                return (
                  <>
                    <tr className="tr-table-restaurar" key={personaDNI}>
                      <td className="td-table-restaurar">
                        {primerNombre} {primerApellido}
                      </td>
                      <td className="td-table-restaurar">{personaDNI}</td>
                      <td className="td-table-restaurar">
                        {busquedaId}
                      </td>
                      <td className="td-table-restaurar">{clienteNombre}</td>
                      <td className="td-table-restaurar">
                        {etapa ? etapa : 'Candidatos Preseleccionados'}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </section>
      <div className="modal-message">
        <span>
          Al confirmar, la lista se moverá a la etapa siguiente correspondiente.
        </span>
      </div>
      <div className="modal-message">
        <span>Para continuar presione Aceptar</span>
      </div>
    </>
  );
};
