import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AreaPersonal from "../../../pages/SistemaValidacionGeneral/AsistenciaValidacionKam/Secciones/AreaPersonal";
import AreaLaboral from "../../../pages/SistemaValidacionGeneral/AsistenciaValidacionKam/Secciones/AreaLaboral";
import AreaDocumentacion from "../../../pages/SistemaValidacionGeneral/AsistenciaValidacionKam/Secciones/AreaDocumentacion";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import ModalDetener from "../../../pages/SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";

interface IModalFicha {
  title: string;
  onChangeBack?: Function;
  buttonBack?: string;
  list?: any[];
  areaContrato?: boolean;
  rut: string;
  busPerId?: any[];
  setOpenModalFicha?: Function;
  personaId?: string;
  idCliente?: string;
  comentario?: string;
  estado?: string;
  fechaValidacion?: any;
  onCloseModalFicha?: Function;
}
export default (props: IModalFicha) => {
  const { title, onChangeBack, buttonBack, rut, setOpenModalFicha } = props;

  const { token, getToken, getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  const [openModalFueraDePlazo, setOpenModalFueraDePlazo] =
    useState<boolean>(false);
  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);

  useEffect(() => {
    if (token && setOpenModalFicha && rut) {
      getToken();
      getRegistroCandidato({
        personaRut: rut,
        token,
        setOpenModal: setOpenModalFicha,
      });
    }
  }, [token, setOpenModalFicha]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  return (
    <>
      <div className="modal-container-validatekam edit">
        <div className="modal-content-validatekam w-80">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>

          <div className="secciones-modal">
            <AreaPersonal formCandidate={listaRegistroCandidato} />
          </div>

          <div className="separador-seccion"></div>

          <div className="secciones-modal">
            <AreaLaboral formCandidate={listaRegistroCandidato} />
          </div>

          <div className="separador-seccion"></div>

          <div className="secciones-modal">
            <AreaDocumentacion rut={rut} />
          </div>

          <div className="modal-button-content center">
            <button
              className={`modal-button cancel`}
              onClick={handleClickCloseModal}
            >
              {buttonBack ? buttonBack : "Cancelar"}
            </button>
          </div>

          {openModalFueraDePlazo && (
            <ModalDetener
              modalType="FueraPlazoIndividual"
              title="SOLICITUD FUERA DE PLAZO"
              subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
              buttonBack="Volver"
              onChangeBack={() => {
                setOpenModalFueraDePlazo(false);
              }}
              modalStyle={""}
              list={listFueraPlazoModal}
              onCloseModalFicha={handleClickCloseModal}
            />
          )}
        </div>
      </div>
    </>
  );
};
