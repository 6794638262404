import { useContext, useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconBloquearDescartar from "../../../../ui/icons-seleccion/IconBloquear";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";

interface IActionMasive {
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
  listSelect: any;
  listRefresh: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    listSelect,
    setListadoSeleccionado,
    listRefresh,
  } = props;
  const { listaCandidatoDisponible } = useContext(SeleccionContext);

  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <div className="container-actions">
        <div className="content-actions-masive">
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["DOC_CREADO_SIN_VALIDAR", "ACEPTADO_SIN_FIRMA", "RECHAZADO_FIRMA", 
            "RECHAZADO", "ACEPTADO_PEND_FIRMA"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="FECHA SOLICITUD"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaSolicitud"
          />
          <InputFiltro
            layer="TIPO DOCUMENTO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="tipoDocumento"
          />
          <InputFiltro
            layer="FICHA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="ficha"
          />
          <StateFiltro
            layer="DIVISIÓN TW"
            listState={["TWEST", "TWRRHH", "TWC", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIOS"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="FECHA REVISIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaRevision"
          />
          <InputFiltro
            layer="SOLICITANTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="solicitante"
          />
        </FiltroGenerico>
      )}
    </>
  );
};

export default ActionMassive;
