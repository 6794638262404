import { useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import { useParams } from "react-router-dom";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";

interface IModalConfirm {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
}

export default (props: IModalConfirm) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    list,
  } = props;
  const {
    token,
    getToken,
    listaCandidatoPendienteValidacion,
    listCandidatoValidadoValidacionCliente,
    listCandidatoPendienteValidacionCliente,
    listaCandidatoValidado,
    getListCandidatePendingValidate,
    getListCandidateValidate,
  } = useContext(ValidationKamContext);
  const { putAvanzarProceso, putRetrocederProceso } =
    useContext(SeleccionContext);
  const { idCliente } = useParams<Params>();

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  useEffect(() => {
    console.log(listCandidatoPendienteValidacionCliente);
  }, [listCandidatoPendienteValidacionCliente]);

  const handleClickValidateConfirm = () => {
    if (modalType) {
      if (modalType === "contratacion") {
        try {
          handleAvanzarProceso();
        } catch (error) {
          console.log(error);
        }
      }
    }
    typeof onChangeGo == "function" && onChangeGo();
  };

  const handleClickRestart = () => {
    if (modalType && token) {
      getToken();
      try {
        if (token) {
          if (modalType === "restaurar" && list) {
            putRetrocederProceso({ token, procesos: list });
            typeof onChangeGo == "function" && onChangeGo();
          } else {
            const current = listaCandidatoValidado
              ?.filter((item: any) =>
                listCandidatoValidadoValidacionCliente.includes(item.personaDni)
              )
              .map((item: any) => item.busPerId);
            putRetrocederProceso({ token, procesos: current });
            typeof onChangeGo == "function" && onChangeGo();
          }
          getListCandidatePendingValidate({ token, idCliente });
          getListCandidateValidate({ token, idCliente });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAvanzarProceso = async () => {
    if (
      token &&
      listaCandidatoPendienteValidacion &&
      listCandidatoPendienteValidacionCliente
    ) {
      // console.log({listaCandidatoPendienteValidacion, listCandidatoPendienteValidacionCliente})
      const current = listaCandidatoPendienteValidacion
        .filter((item: any) =>
          listCandidatoPendienteValidacionCliente.includes(item.personaDni)
        )
        .map((item: any) => item.busPerId);
      await putAvanzarProceso({ token, procesos: current });
      await getListCandidatePendingValidate({ token, idCliente });
      await getListCandidateValidate({ token, idCliente });
    }
  };

  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-50">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle">
            <span>{subTitle}</span>
          </div>
          {!list && modalType === "restaurar" && (
            <div className="modal-message-restaurar">
              {listaCandidatoValidado
                ?.filter((item: IListaCandidatos) =>
                  listCandidatoValidadoValidacionCliente.includes(
                    item.personaDni
                  )
                )
                .map((item: IListaCandidatos) => {
                  const { primerNombre, primerApellido } = item;
                  return (
                    <span>
                      {primerNombre} {primerApellido}
                    </span>
                  );
                })}
            </div>
          )}
          {message && (
            <>
              <div className="modal-message">
                <span>{message}</span>
              </div>
            </>
          )}
          <div className="modal-button-content separados">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "confirm"
                  }`}
                  onClick={() =>
                    modalType === "restaurar"
                      ? handleClickRestart()
                      : handleClickValidateConfirm()
                  }
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
