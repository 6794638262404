import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";

interface ITablaDetenerSolicitud {
  ficha: any;
  primerNombre: any;
  primerApellido: any;
  division: any;
  areaNegocioCod: any;
  articuloDesvinculacion: any;
  descripcionDesvinculacion: any;
  selectCurrent: any;
  setSelectCurrent: Function;
  buttonAssignAll: boolean;
}

export default (props: ITablaDetenerSolicitud) => {
  const {
    ficha,
    primerNombre,
    primerApellido,
    division,
    areaNegocioCod,
    articuloDesvinculacion,
    descripcionDesvinculacion,
    selectCurrent,
    setSelectCurrent,
    buttonAssignAll,
  } = props;

  return (
    <section className="content-table-restaurar anular-documento">
      <table className="table-restaurar w-100">
        <thead className="thead-table-restaurar">
          <tr className="tr-table-restaurar anular-documento">
            <th className="td-table-restaurar">Nombre</th>
            <th className="td-table-restaurar">Ficha</th>
            <th className="td-table-restaurar">División <br></br>TW</th>
            <th className="td-table-restaurar">Área de <br></br>Negocios</th>
            <th className="td-table-restaurar">Causal Baja</th>
          </tr>
        </thead>
        <tbody className="tbody-table-restaurar">
          <tr className="tr-table-restaurar" key={ficha}>
            <td className="td-table-restaurar anular-documento">
              {primerNombre} {primerApellido}
            </td>
            <td className="td-table-restaurar anular-documento">{ficha}</td>
            <td className="td-table-restaurar anular-documento">{division}</td>
            <td className="td-table-restaurar anular-documento">
              {areaNegocioCod}
            </td>
            <td className="td-table-restaurar anular-documento">
              {articuloDesvinculacion} {descripcionDesvinculacion}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table-restaurar w-30">
        <thead className="thead-table-restaurar">
          <tr className="tr-table-restaurar">
            <th className="td-table-restaurar">Motivo</th>
          </tr>
        </thead>
        <tbody className="tbody-table-restaurar">
          <tr className="tr-table-restaurar" key={ficha}>
            <td className="td-table-restaurar anular-documento">
              <InputTextCustomized
                layer=""
                placeholder=""
                required={false}
                onValueChange={setSelectCurrent}
                onPermaValues={selectCurrent}
                onPropertyChange={ficha}
                defaultValue={
                  buttonAssignAll ? selectCurrent[ficha].value ?? "" : undefined
                }
                disabled={buttonAssignAll}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
