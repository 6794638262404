import { useRef } from "react";
interface IIconNomina {
  width?: number;
  height?: number;
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconNomina) => {
  const { width, height, fill, disabled } = props;

  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill={disabled ? "#aeaeae" : "#327A30"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8984 -1.85773e-07C12.0705 -8.33078e-08 13.0234 0.95293 13.0234 2.125L13.0234 14.875C13.0234 16.0471 12.0705 17 10.8984 17L2.39844 17C1.22637 17 0.273436 16.0471 0.273436 14.875L0.273437 5.3125L4.52344 5.3125C5.11113 5.3125 5.58594 4.83769 5.58594 4.25L5.58594 -6.50207e-07L10.8984 -1.85773e-07ZM4.52344 -7.43094e-07L4.52344 4.25L0.273437 4.25L4.52344 -7.43094e-07ZM5.85156 7.70312L5.85156 11.0932L4.82226 10.0639C4.51016 9.75176 4.00547 9.75176 3.69668 10.0639C3.38789 10.376 3.38457 10.8807 3.69668 11.1895L6.0873 13.5801C6.39941 13.8922 6.9041 13.8922 7.21289 13.5801L9.60351 11.1895C9.91562 10.8773 9.91562 10.3727 9.60351 10.0639C9.29141 9.75508 8.78672 9.75176 8.47793 10.0639L7.44863 11.0932L7.44863 7.70312C7.44863 7.26152 7.09336 6.90625 6.65176 6.90625C6.21016 6.90625 5.85488 7.26152 5.85488 7.70312L5.85156 7.70312Z"
      />
    </svg>
  );
};
