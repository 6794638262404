import { useContext, useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import { formatPhone } from "../../../../../functions/kit-mdtw/phone";

interface IAreaPersonal {
  list?: any;
  formCandidate: any;
  areaContrato?: boolean;
}

export default (props: IAreaPersonal) => {
  const { list, formCandidate, areaContrato } = props;
  const { listaGeneral, listGroup } = useContext(SeleccionContext);

  const [hidden, setHidden] = useState<boolean>(areaContrato ? true : false);
  const [gentilicio, setGentilicio] = useState<string | undefined>(undefined);
  const [visaTipo, setVisaTipo] = useState<string | undefined>(undefined);
  const [estadoVisa, setEstadoVisa] = useState<string | undefined>(undefined);
  const [comuna, setComuna] = useState<string | undefined>(undefined);
  const [ciudad, setCiudad] = useState<string | undefined>(undefined);
  const [pais, setPais] = useState<string | undefined>(undefined);
  const [parentesco, setParentesco] = useState<string | undefined>(undefined);
  const [estadoCivil, setestadoCivil] = useState<string | undefined>(undefined);
  const [hijos, setHijos] = useState<any | undefined>(undefined);
  const [numeroCellHijos, setNumeroCellHijos] = useState<any | undefined>(
    undefined
  );
  const [numeroCargas, setNumeroCargas] = useState<number | undefined>(
    undefined
  );
  const [edad, setEdad] = useState<number | undefined>(undefined);
  const [mes, setMes] = useState<number | undefined>(undefined);
  const [sistemaSalud, setSistemaSalud] = useState<string | undefined>(
    undefined
  );
  const [afp, setAfp] = useState<string | undefined>(undefined);
  const [banco, setBanco] = useState<string | undefined>(undefined);
  const [tipoCuenta, setTipoCuenta] = useState<string | undefined>(undefined);
  const [cargo, setCargo] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    if (Object.keys(listaGeneral).length > 0) {
      if (listaGeneral && listGroup && formCandidate) {
        const { gentilicio: nameGentilicio } = listaGeneral.paises.find(
          (item: any) =>
            formCandidate.personal?.candidato.nacionalidadId == item.paisId
        ) ?? { gentilicio: undefined };
        setGentilicio(nameGentilicio ?? undefined);
  
        const { nombre: nameTipoVisa } = listaGeneral.tipoVisa.find(
          (item: any) =>
            formCandidate.personal?.candidato.tipoVisaId == item.tipoVisaId
        ) ?? { nombre: undefined };
        setVisaTipo(nameTipoVisa ?? undefined);
  
        const { nombre: nameVisaEstado } = listaGeneral.estadoVisa.find(
          (item: any) =>
            formCandidate.personal?.candidato.estadoVisaId == item.estadoVisaId
        ) ?? { nombre: undefined };
        setEstadoVisa(nameVisaEstado ?? undefined);
  
        const { comunaNombre: nameComuna } = listaGeneral.comunas.find(
          (item: any) =>
            formCandidate.personal?.direccion.comunaId == item.comunaId
        ) ?? { comunaNombre: undefined };
        setComuna(nameComuna ?? undefined);
  
        const { nombre: nameRegion } = listaGeneral.provincias.find(
          // aca cambiar a ciudad cuanto se integre
          (item: any) =>
            formCandidate.personal?.direccion.provinciaId == item.provinciaId
        ) ?? { nombre: undefined };
        setCiudad(nameRegion ?? undefined);
  
        const { nombre: namePais } = listaGeneral.paises.find(
          (item: any) => formCandidate.personal?.direccion.paisId == item.paisId
        ) ?? { nombre: undefined };
        setPais(namePais ?? undefined);
  
        const { nombre: nameParentesco } = listaGeneral.parentesco.find(
          (item: any) =>
            formCandidate.personal?.contactoEmergencia.parentescoId ==
            item.parentescoId
        ) ?? { nombre: undefined };
        setParentesco(nameParentesco ?? undefined);
  
        const { estadoCivil: namestadoCivil } = listaGeneral.estadoCivil.find(
          (item: any) =>
            formCandidate.personal?.candidato.estadoCivilId == item.estadoCivilId
        ) ?? { estadoCivil: undefined };
        setestadoCivil(namestadoCivil ?? undefined);
        numeroHijos();
  
        numeroDeCargas();
  
        calcularEdad(
          formatDateToDDMMAAAA({
            date: formCandidate.personal?.candidato.fechaNacimiento,
          })
        );
  
        const { nombre: nameSistemaSalud } = listaGeneral.salud.find(
          (item: any) =>
            formCandidate &&
            formCandidate.personal &&
            formCandidate.personal?.cotizaciones.sistemaSaludId == item.saludId
        ) ?? { nombre: undefined };
        setSistemaSalud(nameSistemaSalud ?? undefined);
  
        const { nombre: nameAfp } = listaGeneral.afp.find(
          (item: any) =>
            formCandidate &&
            formCandidate.personal &&
            formCandidate.personal.cotizaciones.afpId == item.afpId
        ) ?? { nombre: undefined };
        setAfp(nameAfp ?? undefined);
  
        const { nombre: nameBanco } = listaGeneral.bancos.find(
          (item: any) =>
            formCandidate &&
            formCandidate.personal &&
            formCandidate.personal.pagos.bancoId == item.bancoId
        ) ?? { nombre: undefined };
        setBanco(nameBanco ?? undefined);
  
        const { nombre: nameTipoCuentaBanco } =
          listaGeneral.tiposCuentaBanco.find(
            (item: any) =>
              formCandidate &&
              formCandidate.personal &&
              formCandidate.personal.pagos.tipoCuentaId == item.tipoCuentaBancoId
          ) ?? { nombre: undefined };
        setTipoCuenta(nameTipoCuentaBanco ?? undefined);
  
        const cargos = listGroup.flatMap((item: any) => item.busquedas);
        const { cargoNombre } = cargos.find(
          (item: any) => formCandidate?.laboral?.procesoId === item.busquedaId
        ) ?? { cargoNombre: undefined };
  
        setCargo(cargoNombre ?? undefined);
      }
    }
  }, [listaGeneral, listGroup, formCandidate]);

  const numeroHijos = (): void => {
    const hijos = formCandidate.personal?.hijos || [];
    const cantidadHijos: number = hijos.length;
    setHijos(cantidadHijos);
    const numeroCell = cantidadHijos / 6;
    setNumeroCellHijos(numeroCell);
  };

  const numeroDeCargas = (): void => {
    const cargas = formCandidate.personal?.cargas || [];
    const cantidadDeCargas: number = cargas.length;
    setNumeroCargas(cantidadDeCargas);
  };

  const calcularEdad = (fecha: any) => {
    fecha = fecha;
    let d = fecha.substring(0, 10).split("-");
    let dat = d[2] + "-" + d[1] + "-" + d[0];
    let hoy = new Date();
    let cumpleanos = new Date(dat);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
      m += 12;
    }
    setEdad(edad);
    setMes(m);
  };
  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <div className={`area-table`} onClick={() => handleClickHidden()}>
        <span>ÁREA PERSONAL</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <div className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table">
          <div className="title">DATOS PERSONALES</div>

          <div className="content-table-row">
            <span className="title-table-row">CARGO AL QUE POSTULA</span>
            <span className="subtitle-table-row">
              {cargo ?? <>-</>}
            </span>
          </div>

          <div className="content-table">
            <span className="title-table">NOMBRE</span>
            <span className="subtitle-table">
              {formCandidate.personal?.candidato.primerNombre ?? "-"}
            </span>
          </div>
          <div className="content-table">
            <span className="title-table">1° APELLIDO</span>
            <span className="subtitle-table">
              {formCandidate.personal?.candidato?.primerApellido ?? "-"}
            </span>
          </div>
          <div className="content-table">
            <span className="title-table">2° APELLIDO</span>
            <span className="subtitle-table">
              {formCandidate.personal?.candidato?.segundoApellido ?? "-"}
            </span>
          </div>
          <div className="content-table">
            <span className="title-table">GÉNERO</span>
            <span className="subtitle-table">
              {formCandidate.personal?.candidato.sexoId == "H"
                ? "HOMBRE"
                : "MUJER"}
            </span>
          </div>

          <div className="content-table cinco">
            <span className="title-table">RUT</span>
            <span className="subtitle-table">
              {formCandidate.personal?.candidato.DNI ?? "-"}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">F NACIMIENTO</span>
            <span className="subtitle-table">
              {formatDateToDDMMAAAA({
                date: formCandidate.personal?.candidato.fechaNacimiento,
              })}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">EDAD</span>
            <div className="subtitle-content-age">
              <span className="subtitle-table cinco">{edad ?? "-"}</span>
              <span className="subtitle-table cinco">AA</span>
              <span className="subtitle-table cinco">{mes ?? "-"}</span>
              <span className="subtitle-table cinco">MM</span>
            </div>
          </div>
          <div className="content-table cinco">
            <span className="title-table">NACIONALIDAD</span>
            <span className="subtitle-table">{gentilicio ?? <>-</>}</span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">TIPO VISA</span>
            <span className="subtitle-table">{visaTipo ?? "-"}</span>
          </div>

          <div className="content-table cinco">
            <span className="title-table">ESTADO VISA</span>
            <span className="subtitle-table">{estadoVisa ?? <>-</>}</span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">F VENCIMIENTO</span>
            <span className="subtitle-table">
              {formatDateToDDMMAAAA({
                date: formCandidate.personal?.candidato.fechaVencimientoVisa,
              })}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">EMAIL</span>
            <span className="subtitle-table">
              {formCandidate.personal?.contacto.correo !== "" ? formCandidate.personal?.contacto.correo : "-"}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">FONO 1</span>
            <span className="subtitle-table">
              {formCandidate.personal?.contacto?.fonoPrincipal ? formatPhone(formCandidate.personal?.contacto.fonoPrincipal) : "-"}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">FONO 2</span>
            <span className="subtitle-table">
              {formCandidate.personal?.contacto?.fonoSecundario ? formatPhone(formCandidate.personal?.contacto.fonoSecundario) : "-"}
            </span>
          </div>

          {/* DOMICIO */}
          <div className="title">DOMICILIO</div>
          <div className="content-table cinco">
            <span className="title-table">CALLE</span>
            <span className="subtitle-table">
              {formCandidate.personal?.direccion.calle ?? "-"}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">NÚMERO</span>
            <span className="subtitle-table">
              {formCandidate.personal?.direccion.numeroPrincipal ?? "-"}
            </span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">COMUNA</span>
            <span className="subtitle-table">{comuna ?? <>-</>}</span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">CIUDAD</span>
            <span className="subtitle-table">{ciudad ?? <>-</>}</span>
          </div>
          <div className="content-table cinco">
            <span className="title-table">PAÍS</span>
            <span className="subtitle-table">{pais ?? <>-</>}</span>
          </div>

          {/* CONTACTO EN CASO DE EMERGENCIA */}
          <div className="title">CONTACTO EN CASO DE EMERGENCIA</div>
          <div className="content-table tres">
            <span className="title-table">NOMBRE</span>
            <span className="subtitle-table">
              {formCandidate.personal?.contactoEmergencia.nombreCompleto ?? "-"}
            </span>
          </div>
          <div className="content-table tres">
            <span className="title-table">PARENTESCO</span>
            <span className="subtitle-table">{parentesco ?? <>-</>}</span>
          </div>
          <div className="content-table tres">
            <span className="title-table">FONO</span>
            <span className="subtitle-table">
              {formCandidate.personal?.contactoEmergencia?.fono ? formatPhone(formCandidate.personal?.contactoEmergencia?.fono) : "-"}
            </span>
          </div>

          {/* ESTADO CIVIL */}
          <div className="title">ESTADO CIVIL</div>
          <div className="content-table-row">
            <span className="title-table-row w-50">ESTADO CIVIL</span>
            <span className="subtitle-table-row w-50">
              {estadoCivil ?? <>-</>}
            </span>
          </div>

          {/* HIJOS */}
          <div className="title">HIJOS</div>
          <div className="content-table-row">
            <span className="title-table-row">NRO DE HIJOS</span>
            <span className="subtitle-table-row">{hijos ?? <>-</>}</span>
          </div>

          <div className="content-table-children">
            <div className="title-table-children">1</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 1
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>

          <div className="content-table-children">
            <div className="title-table-children">2</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 2
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>
          <div className="content-table-children">
            <div className="title-table-children">3</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 3
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>
          <div className="content-table-children">
            <div className="title-table-children">4</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 4
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>
          <div className="content-table-children">
            <div className="title-table-children">5</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 5
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>
          <div className="content-table-children">
            <div className="title-table-children">6</div>
            <div className="subtitle-table-children">
              <div>EDAD</div>
              <div>
                {formCandidate.personal?.hijos &&
                  (formCandidate.personal.hijos.find(
                    (hijo: any) => hijo.posicion === 6
                  )?.edad ??
                    "-")}
              </div>
            </div>
          </div>

          {numeroCellHijos > 1 && (
            <>
              <div className="content-table-children">
                <div className="title-table-children">7</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 7
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
              <div className="content-table-children">
                <div className="title-table-children">8</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 8
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
              <div className="content-table-children">
                <div className="title-table-children">9</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 9
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
              <div className="content-table-children">
                <div className="title-table-children">10</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 10
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
              <div className="content-table-children">
                <div className="title-table-children">11</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 11
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
              <div className="content-table-children">
                <div className="title-table-children">12</div>
                <div className="subtitle-table-children">
                  <div>EDAD</div>
                  <div>
                    {formCandidate.personal?.hijos &&
                      (formCandidate.personal.hijos.find(
                        (hijo: any) => hijo.posicion === 12
                      )?.edad ??
                        "-")}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* CARGAS FAMILIARES */}
          <div className="title">CARGAS FAMILIARES</div>
          <div className="content-table-row">
            <span className="title-table-row w-50">N° DE CARGAS</span>
            <span className="subtitle-table-row w-50">
              {numeroCargas ?? <>-</>}
            </span>
          </div>

          {formCandidate &&
            formCandidate.personal &&
            formCandidate.personal.cargas.map((carga: any) => (
              <>
                <div className="content-table-children cargas">
                  <div className="title-table-children">{carga.posicion}</div>
                  <div className="subtitle-table-children">
                    <div>NOMBRES</div>
                    <div>{carga.nombres ?? "-"}</div>
                  </div>
                </div>
                <div className="content-table-children cargas">
                  <div className="subtitle-table-children">
                    <div>1° APELLIDO</div>
                    <div>{carga.primerApellido ?? "-"}</div>
                  </div>
                </div>
                <div className="content-table-children cargas">
                  <div className="subtitle-table-children">
                    <div>2° APELLIDO</div>
                    <div>{carga.segundoApellido ?? "-"}</div>
                  </div>
                </div>
                <div className="content-table-children cargas">
                  <div className="subtitle-table-children">
                    <div>RUT</div>
                    <div>{carga.DNI ?? "-"}</div>
                  </div>
                </div>
                <div className="content-table-children cargas">
                  <div className="subtitle-table-children">
                    <div>PARENTESCO</div>
                    <div>
                      {" "}
                      {listaGeneral.parentesco &&
                        (listaGeneral.parentesco.find(
                          (item: any) =>
                            item.parentescoId === carga.parentescoId
                        )?.nombre ??
                          "-")}
                    </div>
                  </div>
                </div>
              </>
            ))}

          {/*<div className="content-button-plus">
            <button className="button-plus">
              <AddIcon />
            </button>
  </div>*/}

          {/* COTIZACIONES OBLIGATORIAS */}
          <div className="title">COTIZACIONES OBLIGATORIAS</div>
          <div className="content-table-row">
            <span className="title-table-row w-50">SISTEMA DE SALUD</span>
            <span className="subtitle-table-row w-50">
              {sistemaSalud ?? <>-</>}
            </span>
          </div>
          <div className="content-table-row">
            <span className="title-table-row w-50">
              VALOR PLAN DE SALUD (UF o %)
            </span>
            <span className="subtitle-table-row w-50">
              {formCandidate.personal?.cotizaciones?.valorPlan ?? "-"}
            </span>
          </div>
          <div className="content-table tres">
            <span className="title-table">AFP</span>
            <span className="subtitle-table">{afp ?? "-"}</span>
          </div>
          <div className="content-table tres">
            <span className="title-table">PENSIONADO</span>
            <span className="subtitle-table">
              {formCandidate &&
              formCandidate.personal &&
              formCandidate.personal.cotizaciones.pensionado == false
                ? "NO"
                : "SI"}
            </span>
          </div>
          <div className="content-table tres">
            <span className="title-table">EXENTO DE COTIZACION</span>
            <span className="subtitle-table">
              {formCandidate &&
              formCandidate.personal &&
              formCandidate.personal.cotizaciones.exentoCotizaciones == false
                ? "NO"
                : "SI"}
            </span>
          </div>

          {/* PAGO REMUNERACIONES */}
          <div className="title">PAGO REMUNERACIONES</div>
          <div className="content-table cuatro">
            <span className="title-table">BANCO</span>
            <span className="subtitle-table">{banco ?? "-"}</span>
          </div>
          <div className="content-table cuatro">
            <span className="title-table">TIPO DE CUENTA</span>
            <span className="subtitle-table">{tipoCuenta ?? "-"}</span>
          </div>
          <div className="content-table cuatro">
            <span className="title-table">N° CUENTA</span>
            <span className="subtitle-table">
              {formCandidate.personal?.pagos?.numeroCuenta ?? "-"}
            </span>
          </div>
          <div className="content-table cuatro ending">
            <span className="title-table">PAGO REMUNERACION EN CTA</span>
            <span className="subtitle-table">
              {formCandidate &&
              formCandidate.personal &&
              formCandidate.personal.pagos.pagoEnCuenta == false
                ? "NO"
                : "SI"}
            </span>
          </div>
          <div className="content-table uno">
            <span className="title-table">OBSERVACIONES</span>
            {/* <span className="subtitle-table">Cuenta con deuda, pagar solo por caja</span> */}
            <span className="subtitle-table">
              {formCandidate.personal?.pagos?.observacionesPago ?? "-"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
