import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M6.53199 4.75908C6.17587 5.11521 5.9758 5.59822 5.9758 6.10186C5.9758 6.6055 6.17587 7.08851 6.53199 7.44464C6.88812 7.80076 7.37113 8.00083 7.87477 8.00083C8.37841 8.00083 8.86142 7.80076 9.21755 7.44464C9.57367 7.08851 9.77374 6.6055 9.77374 6.10186C9.77374 5.59822 9.57367 5.11521 9.21755 4.75908C8.86142 4.40295 8.37841 4.20288 7.87477 4.20288C7.37113 4.20288 6.88812 4.40295 6.53199 4.75908Z"
        />
        <path
          d="M7.19678 8.71295C5.73546 8.71295 4.55157 9.89684 4.55157 11.3582C4.55157 11.6015 4.74888 11.7988 4.99219 11.7988H10.7574C11.0007 11.7988 11.198 11.6015 11.198 11.3582C11.198 9.89684 10.0141 8.71295 8.55276 8.71295H7.19678Z"
        />
        <path
          d="M14.9825 6.28042L15.5034 4.28041C15.5739 4.00976 15.8505 3.84752 16.1211 3.91802C16.3918 3.98852 16.554 4.26507 16.4835 4.53571L15.4808 8.385L11.878 6.12735C11.641 5.97885 11.5693 5.66634 11.7178 5.42936C11.8663 5.19237 12.1788 5.12064 12.4158 5.26915L13.9154 6.20887C13.122 3.51309 10.588 1.58366 7.64823 1.67405C4.15396 1.78149 1.4084 4.70125 1.51584 8.19551C1.62329 11.6898 4.54305 14.4353 8.03731 14.3279C9.67378 14.2776 11.145 13.6111 12.2372 12.557C12.4384 12.3628 12.759 12.3685 12.9532 12.5697C13.1475 12.7709 13.1418 13.0915 12.9405 13.2857C11.6744 14.5078 9.96609 15.2819 8.06844 15.3402C4.01509 15.4648 0.62817 12.28 0.503537 8.22664C0.378904 4.1733 3.56375 0.786374 7.6171 0.66174C11.1569 0.552899 14.1875 2.96756 14.9825 6.28042Z"
        />
      </g>
    </svg>
  );
};
