import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface IAlertAssists {
  message: string;
  success: boolean;
}

export default (props: IAlertAssists) => {
  const { message, success } = props;
  return (
    <>
      {success ? (
        <div className="message-check">
          <CheckCircleOutlineIcon className="icon-assists" />
          <span>{message}</span>
        </div>
      ) : (
        <div className="message-alert">
          <HighlightOffIcon className="icon-assists" />
          <span>{message}</span>
        </div>
      )}
    </>
  );
};
