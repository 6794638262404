import React, { useEffect, useRef, useState } from "react";
import api from "../services/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useMsal } from "@azure/msal-react";
import { Toast } from "primereact/toast";
import { callMsGraphTokens } from "../services/MsGraphTokens";

interface ModalDiscardProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisible: boolean;
  candidate: any[];
  group: any;
  //closeFullModal: (flag:any) => void;
}

const ModalDiscard: React.FC<ModalDiscardProps> = ({
  token,
  isModalVisible,
  onClose,
  onCloseSave,
  candidate,
  group,
  //closeFullModal,
}) => {
  const { instance } = useMsal();
  const toast = useRef<Toast>(null);
  const [expClasification, setExpClasification] = useState<any[]>([]);
  const [reasonsDiscard, setReasonsDiscard] = useState<any[]>([]);
  const [positions, setPositions] = useState<any[]>([]);
  const [position, setPosition] = useState<any>("");
  const [comment, setComment] = useState<any>("");
  const [selectedReason, setSelectedReason] = useState<any>("");
  const [enableMessageDuplicate, setEnableMessageDuplicate] =
    useState<boolean>(false);
  const buttonModalError = () => {
    return (
      <div className="flex justify-content-between mb-4 mr-4 ml-5 ">
        <a
          className="cursor-pointer"
          style={{
            fontFamily: "Roboto",
            fontStyle: "",
            fontSize: "14px",
            lineHeight: "16px",
            alignItems: "center",
            fontWeight: "600",
            color: "#2A63D5",
            textDecoration: "underline",
          }}
          onClick={() => {
            cerrarModal();
          }}
        >
          Cancelar
        </a>
        <Button
          label="Descartar"
          className="p-button-danger"
          onClick={() => {
            discardCandidate(candidate);
          }}
        />
      </div>
    );
  };

  const cerrarModal = () => {
    onClose();
    setPosition("");
    setSelectedReason("");
    setComment("");
    setEnableMessageDuplicate(false);
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  useEffect(() => {
    if (group && candidate && group.busquedas) {
      searchPositions();
      getReasons();
    }
  }, [candidate]);

  const searchPositions = () => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId.concat(" - ").concat(busqueda.cargoNombre),
        };
        newArr = [...newArr, newObj];
      });
      setPositions(newArr);
    }
  };

  const getReasons = () => {
    api
      .get(`/exclusion-tipo/`, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          limit: 1000,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const reasons = response.data.data.filter(
          (reason: any) => reason.exclusionLevel == "busqueda"
        );
        setReasonsDiscard(reasons);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  //call Apis

  const discardCandidate = (discardedCandidates: any[]) => {
    const discardData = discardedCandidates.map((item: any) => {
      return {
        busPerId: item.busPerId,
        excTipoId: selectedReason,
        usuarioId: instance.getActiveAccount()?.localAccountId,
        excPerComentario: comment,
        clienteId: item.clienteId,
        busquedaId: position,
      };
    });
    let request = {
      excPerList: discardData,
    };
    api
      .post(`/exclusion-persona-descarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPosition("");
        setSelectedReason("");
        setComment("");
        setEnableMessageDuplicate(false);
        onCloseSave();
      })
      .catch((error) => {
        setEnableMessageDuplicate(true);
      });
  };

  //main return

  return (
    <div>
      <Dialog
        header={null}
        visible={isModalVisible}
        onHide={onClose}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "454px", height: "520px" }}
        closable={false}
        draggable={false}
        footer={" "}
      >
        <div className="mt-3 mb-4 mr-4 ml-5 ">
          <div className="mb-3">
            <span
              className="material-symbols-outlined"
              style={{ color: "#D32F2F" }}
            >
              close
            </span>
            <label
              className="errorModalTittle ml-4"
              style={{
                fontFamily: "Roboto",
                fontStyle: "",
                fontSize: "23px",
                lineHeight: "26px",
                alignItems: "center",
                fontWeight: "600",
                color: "#333333",
              }}
            >
              Descartar candidato
            </label>
          </div>
          <div>
            <p className="text-justify">
              Al descartar un candidato este no podrá continuar en el proceso de
              selección para este cargo.
            </p>
            <br></br>
            {candidate ? (
              candidate.length == 1 ? (
                <p className="text-justify mb-5 line-height-2">
                  <b>
                    Por favor ingresa el motivo para descartar a{" "}
                    {candidate[0].fullname} ( {candidate[0].personaDni} )
                  </b>
                </p>
              ) : (
                <p className="text-justify mb-5 line-height-2 ">
                  <b>Por favor ingresa el motivo para descarte masivo</b>
                </p>
              )
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-column">
            <Dropdown
              value={position}
              options={positions}
              onChange={(e) => setPosition(e.target.value)}
              optionLabel="label"
              placeholder="Seleccione Id"
              appendTo="self"
            />

            <Dropdown
              value={selectedReason}
              options={reasonsDiscard}
              onChange={(e) => setSelectedReason(e.target.value)}
              optionValue="excTipoId"
              optionLabel="excTipoNombre"
              placeholder="Seleccione motivo"
              className="mt-3"
              appendTo="self"
            />

            <InputText
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="mt-3"
              placeholder="Observaciones"
            />
          </div>
          {enableMessageDuplicate ? (
            <p>Candidato ya se encuentra descartado</p>
          ) : (
            ""
          )}
        </div>

        <div className="flex justify-content-between mr-4 ml-5 mt-7">
          <a
            className="cursor-pointer"
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "14px",
              lineHeight: "16px",
              alignItems: "center",
              fontWeight: "600",
              color: "#2A63D5",
              textDecoration: "underline",
            }}
            onClick={() => {
              cerrarModal();
            }}
          >
            Cancelar
          </a>
          <Button
            label="Descartar"
            className="p-button-danger"
            onClick={() => {
              discardCandidate(candidate);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ModalDiscard;
