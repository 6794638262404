import {
  IListaNoProcesadosSeleccion,
  IModalNoProcesadoSeleccion,
} from "../../../../interfaces/ISeleccion";

export default (props: IModalNoProcesadoSeleccion) => {
  const { list } = props;


  return (
    <>
      <section className="content-table-restaurar">
        <table className="table-restaurar w-100">
          <thead className="thead-table-restaurar">
            <tr className="tr-table-restaurar">
              <th className="td-table-restaurar">NOMBRES</th>
              <th className="td-table-restaurar">RUT</th>
              <th className="td-table-restaurar">PROCESO</th>
              <th className="td-table-restaurar">ÁREA DE NEGOCIOS</th>
              <th className="td-table-restaurar">OBSERVACIÓN</th>
            </tr>
          </thead>
          <tbody className="tbody-table-restaurar">
            {list &&
              list.map((item: IListaNoProcesadosSeleccion) => {
                const {
                  busPerId,
                  nombreCompleto,
                  personaDNI,
                  procesoId,
                  areaNegocioCod,
                  observacion,
                } = item;
                return (
                  <>
                    <tr
                      className="tr-table-restaurar borderdashed"
                      key={busPerId}
                    >
                      <td className="td-table-restaurar borderdashed">
                        {nombreCompleto}
                      </td>
                      <td className="td-table-restaurar borderdashed">
                        {personaDNI}
                      </td>
                      <td className="td-table-restaurar borderdashed">
                        {procesoId}
                      </td>
                      <td className="td-table-restaurar borderdashed">
                        {areaNegocioCod}
                      </td>
                      <td className="td-table-restaurar borderdashed">
                        {observacion}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </section>
      <section className="modal-message">
        <label>Los datos se mantendrán en la lista sin modificación.</label>
      </section>
    </>
  );
};
