interface IconCausales {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconCausales) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "38"}`}
        height={`${height ? height : "38"}`}
        viewBox="0 0 38 38"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       
<rect x="0.5" y="0.5" width="37" height="37" rx="18.5" fill="#003D85"/>
<g clip-path="url(#clip0_3917_76544)">
<path d="M15.4391 14.1031C15.4391 13.3822 16.023 12.7983 16.7439 12.7983C17.4648 12.7983 18.0487 13.3822 18.0487 14.1031V18.9375C18.326 18.6895 18.6914 18.5395 19.0926 18.5395C19.9179 18.5395 20.5932 19.1756 20.6551 19.9846C20.9324 19.7334 21.301 19.5833 21.7023 19.5833C22.5276 19.5833 23.2028 20.2194 23.2648 21.0284C23.5421 20.7773 23.9107 20.6272 24.3119 20.6272C25.1763 20.6272 25.8777 21.3285 25.8777 22.193V25.3246C25.8777 27.6308 24.0085 29.5 21.7023 29.5H18.9197C18.7566 29.5 18.5968 29.4902 18.4402 29.4674C16.6363 29.2847 14.9759 28.3583 13.8733 26.8904L11.5246 23.7588C11.0908 23.1814 11.2082 22.3659 11.7856 21.932C12.363 21.4982 13.1785 21.6156 13.6123 22.193L15.4391 24.6297V14.1031Z" fill="#FAFAFA"/>
<path d="M15.5619 10.6491C15.5619 11.8361 14.5997 12.7983 13.4128 12.7983C12.2259 12.7983 11.2637 11.8361 11.2637 10.6491C11.2637 9.4622 12.2259 8.5 13.4128 8.5C14.5997 8.5 15.5619 9.4622 15.5619 10.6491Z" fill="#FAFAFA"/>
<path d="M21.1496 10.6491C21.1496 11.8361 20.1874 12.7982 19.0005 12.7982C17.8136 12.7982 16.8514 11.8361 16.8514 10.6491C16.8514 9.46219 17.8136 8.5 19.0005 8.5C20.1874 8.5 21.1496 9.46219 21.1496 10.6491Z" fill="#FAFAFA"/>
<path d="M26.7373 10.6491C26.7373 11.8361 25.7751 12.7983 24.5882 12.7983C23.4013 12.7983 22.4391 11.8361 22.4391 10.6491C22.4391 9.4622 23.4013 8.50001 24.5882 8.50001C25.7751 8.50001 26.7373 9.4622 26.7373 10.6491Z" fill="#FAFAFA"/>
</g>
<defs>
<clipPath id="clip0_3917_76544">
<rect width="15.4746" height="21" fill="white" transform="translate(11.2627 8.5)"/>
</clipPath>
</defs>
</svg>

   



    );
  };

  
