import { useContext, useEffect, useState } from "react";
import { Zoom } from "@mui/material";
import IconFilter from "../../../ui/kit-svg/IconFilter";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import ModalCausal from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalCausal";
import ModalSalir from "../../Campaign/ModalSalir";
import IconPageRenova from "../../../ui/icons-operaciones/IconPageRenova";
import IconPageBaja from "../../../ui/icons-operaciones/IconPageBaja";
import IconPageNewContrato from "../../../ui/icons-operaciones/IconPageNewContrato";
import ModalRenovacionMasive from "../Modals/ModalRenovacionMasive";
import ModalConfirmBaja from "../Modals/ModalConfirmBaja";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import IconFilterFull from "../../../ui/icons-seleccion/IconFilterFull";
import FiltroGenerico from "../../../ui/kit-modal/FiltroGenerico";
import StateFiltro from "../../../ui/kit-modal/Filtros-kit/StateFiltro";
import InputFiltro from "../../../ui/kit-modal/Filtros-kit/InputFiltro";
import { formatDateToYYYYMMDD } from "../../../../functions/kit-mdtw/dates";
import ModalconfirmaNuevodoc from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalconfirmaNuevodoc";
import { useParams } from "react-router-dom";
import { Params } from "../../../../interfaces/IValidationKam";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
interface ISectionActive {
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  token: string;
}

const SeccionAccionMasiveDotacion = (props: ISectionActive) => {
  const { localStorageFilter, setFormFilter, formFilter, token } = props;
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [openModalContratacion, setOpenModalContratacion] =
    useState<boolean>(false);
  const { idCliente } = useParams<Params>();
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [openModalCausal, setOpenModalCausal] = useState<boolean>(false);
  const [openModalSalir, setOpenModalSalir] = useState<boolean>(false);
  const {
    getToken,
    disableButttonAction,
    listCandidatoEnviadoContrato,
    BootstrapTooltip,
  } = useContext(ValidationKamContext);

  const { newContrato, listDocumentosVigentes } = useContext(
    OperacionContratacionContext
  );

  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listCandidatoEnviadoContrato.length > 0 ? true : false
  );
  const [objsendContrato, setObjsendContrato] = useState<any>();
  const [listBusperSolicitudContrato, setListBusperSolicitudContrato] =
    useState<any>();
  const objetoSendFiltro = {};
  const [openModalRenovar, setOpenModalRenovar] = useState<boolean>(false);
  const [isOpenModalBaja, setIsOpenModalBaja] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);
  const [fichaMasive, setFichaMasive] = useState<any>([]);
  const [objetoTablaConfirmacion, setObjetoTablaConfirmacion] = useState<any>();
  const [
    openModalFueraDePlazoNewContrato,
    setOpenModalFueraDePlazoNewContrato,
  ] = useState<boolean>(false);

  useEffect(() => {
    setButtonEnabled(listDocumentosVigentes.length > 0 ? true : false);
  }, [listDocumentosVigentes]);

  const { getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (token) {
      getToken();
      getRegistroCandidato({
        personaRut: objsendContrato?.rut?.value,
        token,
        setOpenModal: setOpenModalFicha,
      });
    }
  }, [token, setOpenModalFicha]);

  useEffect(() => {
    const fichas = listDocumentosVigentes.map((item: any) => {
      return item.ficha + "-" + item.division;
    });
    setFichaMasive(fichas);
    setObjetoTablaConfirmacion(objetoForTablaConfirmacion);
    //listFueraPlazo(listaRegistroCandidato, objsendContrato);
  }, [listDocumentosVigentes, objsendContrato]);

  useEffect(() => {
    setListFueraPlazoModal([]);
    listDocumentosVigentes.map((item: any) =>
      listFueraPlazo(item, objsendContrato)
    );
  }, [objsendContrato]);

  const handleClickRenovar = () => {
    setOpenModalRenovar(true);
    setHover1(false);
  };

  const handleClickModalNewContrato = () => {
    setOpenModalCausal(true);
    setHover3(false);
  };

  const handleClickModalConfirmBaja = () => {
    setIsOpenModalBaja(true);
    setHover2(false);
  };

  const objetoForContratoNuevo = {
    ficha: fichaMasive,
    areaNegocioId: objsendContrato?.areaNegocio?.value?.code,
    causalId: objsendContrato?.causal?.value?.code,
    codCargo: objsendContrato?.codCargo?.value,
    divisionId: objsendContrato?.division?.value?.code,
    fechaInicio: formatDateToYYYYMMDD({
      date: objsendContrato?.fechaInicio?.value,
      format: "-",
    }),
    fechaTermino: formatDateToYYYYMMDD({
      date: objsendContrato?.fechaTermino?.value,
      format: "-",
    }),
    funciones: objsendContrato?.funciones?.value,
    nombre: objsendContrato?.nombre?.value,
    rut: objsendContrato?.rut?.value,
    SCITeamwork: objsendContrato?.sucursal?.value?.code,
    horasTrabajadas: parseInt(objsendContrato?.horasTrabajadas?.value),
  };

  const objetoForTablaConfirmacion = {
    ficha: fichaMasive,
    areaNegocioId: objsendContrato?.areaNegocio?.value?.name,
    causalId: objsendContrato?.causal?.value?.code,
    codCargo: objsendContrato?.codCargo?.value,
    divisionId: objsendContrato?.division?.value?.code,
    fechaInicio: formatDateToYYYYMMDD({
      date: objsendContrato?.fechaInicio?.value,
      format: "-",
    }),
    fechaTermino: formatDateToYYYYMMDD({
      date: objsendContrato?.fechaTermino?.value,
      format: "-",
    }),
    funciones: objsendContrato?.funciones?.value,
    nombre: objsendContrato?.nombre?.value,
    rut: objsendContrato?.rut?.value,
    SCITeamwork: objsendContrato?.sucursal?.value?.code,
    horasTrabajadas: parseInt(objsendContrato?.horasTrabajadas?.value),
  };

  const listFueraPlazo = (item: any, objSendCt: any) => {
    let selectedDateInicio15: number | undefined;
    let selectedDateInicio30: number | undefined;

    if (objSendCt?.fechaInicio?.value?.length > 1) {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      let fechaInicioContrato;
      if (objSendCt?.fechaInicio?.value?.length > 1) {
        const splitDate = objSendCt.fechaInicio.value.split("/");
        fechaInicioContrato = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      } else {
        fechaInicioContrato = item?.contratacion?.fechaInicio;
      }

      const [yearInicio, monthInicio, dayInicio] =
        fechaInicioContrato.split("-");

      selectedDateInicio15 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) - 15
      ).setHours(0, 0, 0, 0);

      selectedDateInicio30 = new Date(
        Number(yearInicio), //yearInicio30
        Number(monthInicio) - 1, //monthInicio30
        Number(dayInicio) + 30 //dayInicio30
      ).setHours(0, 0, 0, 0);

      if (
        !(
          selectedDateInicio15 <= currentDate &&
          currentDate <= selectedDateInicio30
        )
      ) {
        setHayFueraplazo(true);
        setListFueraPlazoModal((prevList: any) => [
          ...prevList,
          {
            busPerId: [item.ficha],
            areaNegocioCod: objsendContrato?.areaNegocio?.value?.name,
            personaDni: item.personaDNI,
            primerApellido: item.primerApellido,
            primerNombre: item.primerNombre,
            division: objsendContrato?.division?.value?.code,
            tipoDocumentoValue:
              listaRegistroCandidato.contratacion.tipoDocumento,
            causal: objsendContrato?.causal?.value?.code,
            ficha: item.ficha,
          },
        ]);
      }
    }
  };
  const handleClickConfirmaContratoNuevo = async () => {
    setOpenModalFueraDePlazoNewContrato(false);
    setListFueraPlazoModal([]);

    if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
      setListFueraPlazoModal(listFueraPlazoModal);
      // setOpenModalFueraDePlazoEnRenov(true);
      setOpenModalFueraDePlazoNewContrato(true);
    } else {
      setOpenModalContratacion(true);
    }
  };

  const handleClickSolicitaContratoNuevo = async () => {
    /* console.log(
      "objetoForContratoNuevo en masive contrato",
      objetoForContratoNuevo
    );*/

    try {
      await newContrato({
        token,
        objetoData: objetoForContratoNuevo,
        objetoSendFiltro,
      });
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  const handleClickOpenModalConf = () => {
    setOpenModalContratacion(true);
  };
  return (
    <>
      <div className="content-actions-Dotacion contrato">
        <div className="content-actions-masiveDota">
          <BootstrapTooltip
            title="Renovar Contrato"
            TransitionComponent={Zoom}
            placement="bottom-end"
          >
            <button
              className={`button-actions ${
                buttonEnabled ? "active-datos" : "disabled"
              } ${hover1 ? "hover" : ""}`}
              onClick={() => handleClickRenovar()}
              onMouseEnter={() => setHover1(true)}
              onMouseLeave={() => setHover1(false)}
              disabled={disableButttonAction || buttonEnabled ? false : true}
            >
              <IconPageRenova
                fill={
                  hover1 ? "#FDFDFD" : buttonEnabled ? "#06A502" : "#A3A3A3"
                }
              />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip
            title="Confirmar Baja"
            TransitionComponent={Zoom}
            placement="top-end"
          >
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              } ${hover2 ? "hover" : ""}`}
              onMouseEnter={() => setHover2(true)}
              onMouseLeave={() => setHover2(false)}
              disabled={disableButttonAction || buttonEnabled ? false : true}
              onClick={() => handleClickModalConfirmBaja()}
            >
              <IconPageBaja
                fill={
                  hover2 ? "#FDFDFD" : buttonEnabled ? "#BE2A2A" : "#A3A3A3"
                }
              />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip
            title="Nuevo Contrato"
            TransitionComponent={Zoom}
            placement="bottom-start"
          >
            <button
              className={`button-actions ${
                buttonEnabled ? "active-restore" : "disabled"
              } ${hover3 ? "hover" : ""}`}
              onMouseEnter={() => setHover3(true)}
              onMouseLeave={() => setHover3(false)}
              onClick={() => handleClickModalNewContrato()}
              disabled={disableButttonAction || buttonEnabled ? false : true}
            >
              <IconPageNewContrato
                fill={
                  hover3 ? "#FDFDFD" : buttonEnabled ? "#0065DD" : "#A3A3A3"
                }
              />
            </button>
          </BootstrapTooltip>

          <button
            className={`button-actions active-filter`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#B0CFF4" />
            ) : (
              <IconFilter fill="#B0CFF4" />
            )}
          </button>
        </div>
      </div>

      {openModalContratacion && (
        <ModalconfirmaNuevodoc
          modalStyle="confirm"
          modalType="contratacion"
          title="CONFIRMACIÓN SOLICITUD NUEVO DOCUMENTO"
          subTitle="Estás por enviar al área de contrato la siguiente selección: "
          message={[listDocumentosVigentes]}
          onChangeBack={() => setOpenModalContratacion(false)}
          onChangeGo={() => {
            setOpenModalContratacion(false),
              handleClickSolicitaContratoNuevo(),
              setOpenModalCausal(false);
          }}
          buttonBack="Cancelar"
          buttonGo="Solicitar Contrato"
          objetoForContratoNuevo={objetoTablaConfirmacion}
        />
      )}

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={[
              "VIGENTE",
              "BAJA",
              "PROX-VENCER",
              "VIGENTE_BAJA_ANULADA",
              "VIGENTE_RENOVACION_DETENIDA",
              "VIGENTE_CONTRATO_DETENIDA",
              "VIGENTE_BAJA_DETENIDA",
              "PROX_VENCER_TOPELEGAL",
            ]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="FICHA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="ficha"
          />
          <InputFiltro
            layer="CÓDIGO CARGO"
            typeFilter="number"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="codCargo"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="FECHA INICIO CONTRATO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaInicio"
          />
          <InputFiltro
            layer="FECHA TERMINO CONTRATO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaTermino"
          />
          <InputFiltro
            layer="TOPE LEGAL"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="topeLegal"
          />
          <StateFiltro
            layer="DIVISIÓN TW"
            listState={["TWEST", "TWC", "TWRRHH", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
        </FiltroGenerico>
      )}

      {openModalCausal && (
        <ModalCausal
          modalStyle="confirm"
          modalType="contratacionNew"
          title="CAUSAL"
          subTitle=" "
          idCliente={idCliente}
          setObjsendContrato={setObjsendContrato}
          setListBusperSolicitudContrato={setListBusperSolicitudContrato}
          personas={listDocumentosVigentes}
          onChangeBack={() => setOpenModalSalir(true)}
          onChangeGuarda={() => {
            setOpenModalSalir(true);
          }}
          onChangeGo={() => {
            handleClickConfirmaContratoNuevo();
          }}
          buttonGuarda="Cancelar"
          buttonGo="Solicitar Nuevo Contrato"
        />
      )}
      {openModalSalir && (
        <ModalSalir
          modalStyle="salir"
          title="¿Está seguro qué desea salir de la página actual?"
          subTitle="Se perderán los cambios no guardados"
          onChangeBack={() => setOpenModalSalir(false)}
          onChangeGo={() => {
            setOpenModalCausal(false);
            setOpenModalSalir(false);
          }}
          buttonBack="Cancelar"
          buttonGo="Salir"
        />
      )}

      {openModalRenovar && (
        <ModalRenovacionMasive
          modalType="renovar"
          title="RENOVAR CONTRATOS"
          subTitle="Estás por enviar al área de contrato la siguiente selección: "
          buttonBack="Cancelar"
          onChangeBack={() => {
            setOpenModalRenovar(false);
          }}
          modalStyle={""}
          list={listDocumentosVigentes}
        />
      )}

      {isOpenModalBaja && (
        <ModalConfirmBaja
          modalType="baja"
          title="CONFIRMAR BAJA"
          subTitle="Estás por confirmar y solicitar cálculo de la baja de la siguiente selección:  "
          buttonBack="Cancelar"
          onChangeBack={() => {
            setIsOpenModalBaja(false);
          }}
          modalStyle={""}
          list={listDocumentosVigentes}
        />
      )}

      {openModalFueraDePlazoNewContrato && (
        <ModalDetener
          modalType="FueraPlazoMasive"
          title="SOLICITUD FUERA DE PLAZO"
          subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
          buttonBack="Volver"
          onChangeBack={() => {
            setOpenModalFueraDePlazoNewContrato(false);
          }}
          modalStyle={""}
          list={listFueraPlazoModal}
          onChangeGo={handleClickOpenModalConf}
          masiveNewContratoObjeto={objetoForContratoNuevo}
          nuevoContrato={false}
          openOtherModal={true}
          masive={true}
        />
      )}
    </>
  );
};

export default SeccionAccionMasiveDotacion;
