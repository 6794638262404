import React, { useEffect } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

const LoginPage: React.FC<IPage> = (props) => {
  let history = useHistory();

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, []);

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push("reclutamiento");
  };

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Login</div>
        </div>

        <div>
          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText id="email" type="text" className="w-full mb-3" />

          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText id="password" type="password" className="w-full mb-3" />

          <div className="flex align-items-center justify-content-between mb-6">
            <div className="flex align-items-center">
              <label htmlFor="rememberme">Remember me</label>
            </div>
            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
              Forgot your password?
            </a>
          </div>

          <Button
            onClick={buttonHandler}
            label="Sign In"
            icon="pi pi-user"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
