import { IFormatDate } from "../../interfaces/IInput";

interface IRangeAge {
  ages: string;
}

export const formatDateToDDMMAAAA = (values: IFormatDate) => {
  const { date, format } = values;
  let formatDate: string = "";
  let currentDate: string = date;
  const formato: string = !format ? "-" : format;

  if (!date) {
    return "-";
  }

  if (date.includes("T")) {
    const { 0: splitDate } = date.split("T");
    currentDate = splitDate;
  }

  const { 0: year, 1: month, 2: day } = currentDate.split("-");

  formatDate = `${day}${formato}${month}${formato}${year}`;

  return formatDate;
};

export const formatDateToYYYYMMDD = (values: IFormatDate) => {
  const { date, format = "/", formatSearch = "/" } = values;
  let formatDate: string = "";
  let currentDate: string = date;
  const formato: string = !format ? "-" : format;

  if (!date) {
    return "-";
  }
  if (date.includes("T")) {
    const { 0: splitDate } = date.split("T");
    currentDate = splitDate;
  }
  const { 0: day, 1: month, 2: year } = date.split(formatSearch);
  formatDate = `${year}${formato}${month}${formato}${day}`;
  return formatDate;
};

export const extractRangeAge = (values: IRangeAge): string | undefined => {
  const { ages } = values;

  if (!ages) {
    return undefined;
  }

  if (
    ["NO ESPECIFICADO", "Sin datos"].includes(ages) ||
    !ages.includes(" - ")
  ) {
    return undefined;
  }

  const [rangeInit, rangeEnd] = ages.split(" - ");

  if (["null"].includes(rangeInit) && ["null"].includes(rangeEnd)) {
    return undefined;
  }

  if (!["null"].includes(rangeInit) && !["null"].includes(rangeEnd)) {
    return `Desde ${rangeInit} hasta ${rangeEnd} años`;
  }

  if (!["null"].includes(rangeInit) && ["null"].includes(rangeEnd)) {
    return `Desde ${rangeInit} años`;
  }

  if (["null"].includes(rangeInit) && !["null"].includes(rangeEnd)) {
    return `Hasta ${rangeInit} años`;
  }
};

export const formatDate = (values: IFormatDate) => {
  const { date } = values;
  const includeBarra: number[] = [1, 3];
  const numberValidation: string[] = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  let formatDate: string = "";

  date.split("").map((character, index) => {
    formatDate += `${character}${includeBarra.includes(index) ? "/" : ""}`;
  });

  if (
    !numberValidation.includes(
      formatDate.slice(
        formatDate.split("").length - 1,
        formatDate.split("").length
      )
    )
  ) {
    formatDate = formatDate.slice(0, formatDate.split("").length - 1);
  }

  return formatDate;
};

export const validDate = (values: IFormatDate) => {
  const { date } = values;
  const { 0: day, 1: month, 2: year } = date.split("/");
  const lastDay = new Date(Number(year), Number(month), 0);

  if (Number(year) < 1900) {
    return false;
  }

  if (Number(month) < 1 || Number(month) > 12) {
    return false;
  }

  if (Number(day) < 1 || Number(day) > lastDay.getDate()) {
    return false;
  }

  return true;
};

export const formatAgeToAAMM = (values: IFormatDate) => {
  const { date } = values;
  const fechaNac = new Date(date);
  const fechaActual = new Date();
  const diferencia = fechaActual.getTime() - fechaNac.getTime();
  const edadEnAnios = Math.floor(diferencia / (1000 * 60 * 60 * 24 * 365.25));
  const mesesRestantes = Math.floor(
    (diferencia % (1000 * 60 * 60 * 24 * 365.25)) /
      (1000 * 60 * 60 * 24 * 30.44)
  );
  return { annos: edadEnAnios, meses: mesesRestantes };
};

export const formatDateToDDMMYY = (values: IFormatDate) => {
  const { date, format = "/" } = values;
  let formatDate: string = "";
  let currentDate: string = date;

  if (!date) {
    return "-";
  }

  if (date.includes("T")) {
    const { 0: splitDate } = date.split("T");
    currentDate = splitDate;
  }

  const { 0: year, 1: month, 2: day } = currentDate.split("-");

  formatDate = `${day}${format}${month}${format}${year.slice(-2)}`;

  return formatDate;
};

export const formatDateToMMMMDDYYYY = (values: IFormatDate) => {
  const { date } = values;
  const fecha: Date = new Date(date);

  const meses: string[] = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  const nombreMes: string = meses[fecha.getUTCMonth()];
  const dia: number = fecha.getUTCDate();
  const año: number = fecha.getUTCFullYear(); 
  const resultado: string = `${nombreMes} ${dia} DEL ${año}`;
  return resultado;
};

export const formatDateToYYYY = (values: IFormatDate) => {
  const { date } = values;
  const fecha: Date = new Date(date);
  const año: number = fecha.getUTCFullYear(); 
  const resultado: string = `${año}`;
  return resultado;
};
