interface IIconMas {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IIconMas) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "21"}`}
        height={`${height ? height : "21"}`}
        viewBox="0 0 21 21"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M12.1154 1.61538C12.1154 0.721875 11.3935 0 10.5 0C9.60649 0 8.88461 0.721875 8.88461 1.61538V8.88461H1.61538C0.721875 8.88461 0 9.60649 0 10.5C0 11.3935 0.721875 12.1154 1.61538 12.1154H8.88461V19.3846C8.88461 20.2781 9.60649 21 10.5 21C11.3935 21 12.1154 20.2781 12.1154 19.3846V12.1154H19.3846C20.2781 12.1154 21 11.3935 21 10.5C21 9.60649 20.2781 8.88461 19.3846 8.88461H12.1154V1.61538Z" 
       fill={fill}/>
      </svg>



    );
  };

