import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatAgeToAAMM, formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
interface IAreaPersonalFichaPotulacion {
  hiddenDefault: boolean;
  formCandidate: any;
}

export default (props: IAreaPersonalFichaPotulacion) => {
  const { hiddenDefault, formCandidate } = props;
  const [hidden, setHidden] = useState<boolean>(hiddenDefault);
  const [edad, setEdad] = useState<any>({
    annos: "", 
    meses: ""
  });

  useEffect(() => {
    if (formCandidate && Object.keys(formCandidate).length > 0) {
      setEdad(formatAgeToAAMM({date: formCandidate.fechaNacimiento ?? ""}));
    }
    // console.log({ formCandidate });
  }, [formCandidate]);

  return (
    <>
      {formCandidate ? (
        <>
          <div className={`area-table`} onClick={() => setHidden(!hidden)}>
            <span>ÁREA PERSONAL</span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </div>
          <div className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-table">
              <div className="title">DATOS PERSONALES</div>

              <div className="content-table">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">
                  {formCandidate.primerNombre} {formCandidate.segundoNombre}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">1° APELLIDO</span>
                <span className="subtitle-table">
                  {formCandidate.primerApellido}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">2° APELLIDO</span>
                <span className="subtitle-table">
                  {formCandidate.segundoApellido}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">GÉNERO</span>
                <span className="subtitle-table">{formCandidate.sexo}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">RUT</span>
                <span className="subtitle-table">{formCandidate.personaDNI}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">F. NACIMIENTO</span>
                <span className="subtitle-table">
                  {formatDateToDDMMAAAA({
                    date: formCandidate.fechaNacimiento,
                    format: "/",
                  })}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">EDAD</span>
                <div className="subtitle-content-age">
                  <span className="subtitle-table cinco">
                    {edad.annos ?? ""}
                  </span>
                  <span className="subtitle-table cinco">AA</span>
                  <span className="subtitle-table cinco">
                    {edad.meses ?? ""}
                  </span>
                  <span className="subtitle-table cinco">MM</span>
                </div>
              </div>
              <div className="content-table cinco">
                <span className="title-table">NACIONALIDAD</span>
                <span className="subtitle-table">
                  {formCandidate.nacionalidad ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">TIPO VISA</span>
                <span className="subtitle-table">
                  {formCandidate.tipoVisa ?? ""}
                </span>
              </div>

              <div className="content-table cinco">
                <span className="title-table">ESTADO VISA</span>
                <span className="subtitle-table">
                  {formCandidate.estadoVista ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">F VENCIMIENTO</span>
                <span className="subtitle-table">
                  {formCandidate.expiracionVisa ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">CORREO</span>
                <span className="subtitle-table">
                  {formCandidate.email ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">CELULAR</span>
                <span className="subtitle-table">
                  {formCandidate.fonoPrincipal ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">FONO 2</span>
                <span className="subtitle-table">
                  {formCandidate.fonoSecundario ?? ""}
                </span>
              </div>

              <div className="title">DOMICILIO</div>

              <div className="content-table cinco">
                <span className="title-table">CALLE</span>
                <span className="subtitle-table">
                  {formCandidate.calle ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">NÚMERO</span>
                <span className="subtitle-table">
                  {formCandidate.numeroCalle ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">COMUNA</span>
                <span className="subtitle-table">
                  {formCandidate.comuna ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">CIUDAD</span>
                <span className="subtitle-table">
                  {formCandidate.ciudad ?? ""}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">PAÍS</span>
                <span className="subtitle-table">
                  {formCandidate.pais ?? ""}
                </span>
              </div>

              <div className="title">CONTACTO EN CASO DE EMERGENCIA</div>

              <div className="content-table tres">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">
                  {formCandidate.nombreContactoEmergencia ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table">PARENTESCO</span>
                <span className="subtitle-table">
                  {formCandidate.parentescoContactoEmergencia ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table">FONO</span>
                <span className="subtitle-table">
                  {formCandidate.fonoContactoEmergencia ?? ""}
                </span>
              </div>

              <div className="title">ESTADO CIVIL</div>
              <div className="content-table-row">
                <span className="title-table-row">ESTADO CIVIL</span>
                <span className="subtitle-table-row">
                  {formCandidate.estadoCivil ?? ""}
                </span>
              </div>

              <div className="title">COTIZACIONES OBLIGATORIAS</div>
              <div className="content-table-row">
                <span className="title-table-row">SISTEMA DE SALUD</span>
                <span className="subtitle-table-row">
                  {formCandidate.salud ?? ""}
                </span>
              </div>
              <div className="content-table-row">
                <span className="title-table-row">VALOR PLAN DE SALUD</span>
                <span className="subtitle-table-row">
                  {formCandidate.valorPlan ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table">AFP</span>
                <span className="subtitle-table">
                  {formCandidate.afp ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table">PENSIONADO</span>
                <span className="subtitle-table">
                  {formCandidate.pensionado ? "SI" : "NO"}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table">EXENTO DE COTIZACION</span>
                <span className="subtitle-table">
                  {formCandidate.exentoCotizacion ? "SI" : "NO"}
                </span>
              </div>

              <div className="title">PAGO REMUNERACIONES</div>
              <div className="content-table">
                <span className="title-table">TIPO DE PAGO</span>
                <span className="subtitle-table">
                  {formCandidate.pagoEnCuenta ? formCandidate.pagoEnCuenta : "DEPÓSITO"}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">BANCO</span>
                <span className="subtitle-table">
                  {formCandidate.banco ?? ""}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">TIPO CUENTA</span>
                <span className="subtitle-table">
                {formCandidate.tipoCuentaBanco ?? ""}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">N° CUENTA</span>
                <span className="subtitle-table">
                  {formCandidate.numCuentaBanco ?? ""}
                </span>
              </div>

              <div className="content-table uno">
                <span className="title-table">OBSERVACIONES</span>
                <span className="subtitle-table">
                  {formCandidate.observacionPago ?? ""}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Error al cargar los datos 😨</h3>
        </div>
      )}
    </>
  );
};
