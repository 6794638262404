import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InputDateCustomized from "../../../ui/kit-mdtw/InputDateCustomized";
import IconCheckfila from "../../../ui/kit-svg/IconCheckfila";
import { IListaCandidatos } from "../../../../interfaces/IValidationKam";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYYMMDD,
} from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import DropDownListCustomized from "../../../ui/kit-mdtw/DropDownListCustomized";
import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";

interface IDetener {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  onChange?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
  rut?: string;
  busPerId?: string;
  idCliente?: string;
  onCloseModalFicha?: Function;
}

export default (props: IDetener) => {
  const {
    title,
    subTitle,
    onChangeBack,
    buttonBack,
    buttonGo,
    list,
    onCloseModalFicha,
  } = props;
  const { token, causalesDesvinculacion, getListDatosContratos } =
    useContext(ValidationKamContext);

  const { confirmarBaja } = useContext(OperacionContratacionContext);
  const [inputForBaja, setInputForBaja] = useState<any>([
    {
      causalDesvinculacion: { code: undefined, name: undefined },
      date: { value: undefined },
      diasNoConcurrencia: { value: undefined },
      motivo: { value: undefined },
      checkAply: false,
    },
  ]);

  const [objetoData, setObjtoData] = useState<any>([]);
  const objetoSendFiltro = {};

  useEffect(() => {
    if (token) {
      getListDatosContratos({ token });
    }
  }, [token]);

  useEffect(() => {
    objDataSend();
  }, [inputForBaja]);

  const handleValueChange = (
    sectionIndex: any,
    property: string,
    value: any
  ) => {
    const newSelectDataFormacion: any[] = [...inputForBaja];
    newSelectDataFormacion[sectionIndex] = {
      ...inputForBaja[sectionIndex],
      [property]: value[property],
    };
    setInputForBaja(newSelectDataFormacion);
  };

  const handleClickCheck = (index: number, value: any) => {
    const newSelectDataFormacion: any[] = [...inputForBaja];
    newSelectDataFormacion[index] = {
      ...inputForBaja[index],
      checkAply: value,
    };
    setInputForBaja(newSelectDataFormacion);
  };

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCloseModalFicha = () => {
    typeof onCloseModalFicha == "function" && onCloseModalFicha();
  };

  const objDataSend = () => {
    if (list) {
      const mappedDataObjects = list.map(
        (item: IListaCandidatos, index: number) => {
          const { ficha, fechaTerminoContrato, division } = item;
          let lucroCesante = false;

          let dateTerm = "";
          if (
            inputForBaja[index]?.causalDesvinculacion &&
            inputForBaja[index]?.causalDesvinculacion?.value?.name ===
              "ART.159N4"
          ) {
            dateTerm = fechaTerminoContrato;
          }

          return {
            ficha: division + "-" + ficha,
            causalDesvinculacionId:
              inputForBaja[index]?.causalDesvinculacion?.value?.code ?? "",
            lucroCesante: !lucroCesante
              ? inputForBaja[index]?.checkAply
              : lucroCesante,
            fechaDesvinculacion:
              dateTerm != ""
                ? dateTerm
                : formatDateToYYYYMMDD({
                    date: inputForBaja[index]?.date?.value,
                    format: "-",
                  }) ?? "",
            diasNoConcurrencia:
              inputForBaja[index]?.diasNoConcurrencia?.value ?? "",
            motivoInhabilitacion: inputForBaja[index]?.motivo?.value ?? "",
          };
        }
      );

      // Construir objetoData con la propiedad "bajas" que contiene todos los elementos
      const allBajas = mappedDataObjects.reduce(
        (accumulator: any[], current: any) => {
          accumulator.push(current);
          return accumulator;
        },
        []
      );

      setObjtoData({ contratos: allBajas });
    }
  };

  const handleClickConfirmaBaja = () => {
    objDataSend();

    confirmarBaja({
      token,
      objetoData,
      objetoSendFiltro,
    });
    handleClickCloseModal();
    handleClickCloseModalFicha();
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo ">
        <div className="modal-content-validatekam-Mo w-70">
          <div className="modal-title-Mo">
            <span
              className="modal-close"
              onClick={() =>
                typeof onChangeBack == "function" && onChangeBack()
              }
            >
              <CloseIcon />
            </span>
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo px-5 md:text-center">
            <span>{subTitle}</span>
          </div>

          {list &&
            list.map((item: IListaCandidatos, index: any) => {
              const {
                areaNegocioCod,
                primerNombre,
                primerApellido,
                fechaInicioContrato,
                fechaTerminoContrato,
              } = item;

              return (
                <>
                  <div
                    className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5"
                    style={{ marginBottom: "-18px" }}
                  >
                    <div className="col-12 md:col-11 m-0 p-0  borderColorGrey">
                      <div className="row flex flex-column md:flex-row">
                        <div className="col-12 md:col-3 p-0 mx-0">
                          <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener  headerHeight">
                            Nombre
                          </div>
                          <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorGrey textNormal border-bottom-2 border-Seg">
                            {primerNombre + "  " + primerApellido}
                          </div>
                        </div>
                        <div className="col-12 md:col-2 p-0 mx-0">
                          <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                            Ficha
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {"C123253695"}
                          </div>
                        </div>
                        <div className="col-12 md:col-1 p-0 mx-0">
                          <div className="col-6 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                            División TW
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {"TWEST"}
                          </div>
                        </div>
                        <div className="col-12 md:col-2 p-0 mx-0">
                          <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                            Área de Negocios
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {areaNegocioCod}
                          </div>
                        </div>
                        <div className="col-12 md:col-1 p-0 mx-0">
                          <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener headerHeight">
                            Causal Contrato
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {"C"}
                          </div>
                        </div>
                        <div className="col-12 md:col-2 p-0 mx-0">
                          <div className="col-6 md:col-12 bg-headerGrey text-center  borderColorGrey responsiveDivDetener headerHeight">
                            Fecha Inicio Contrato
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {formatDateToDDMMAAAA({
                              date: fechaInicioContrato,
                            })}
                          </div>
                        </div>
                        <div className="col-12 md:col-2 p-0 mx-0">
                          <div className="col-6 md:col-12 bg-headerGrey text-center  borderColorGrey responsiveDivDetener headerHeight">
                            Fecha Término Contrato
                          </div>
                          <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal border-bottom-2 border-Seg">
                            {formatDateToDDMMAAAA({
                              date: fechaTerminoContrato,
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 md:flex p-0 my-0"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="col-12 md:col-2 md:ml-3 md:pl-1"
                      style={{ float: "left" }}
                    >
                      <DropDownListCustomized
                        required={true}
                        layer={"Causal"}
                        placeholder={""}
                        onValueChange={(value: any) =>
                          handleValueChange(
                            index,
                            "causalDesvinculacion",
                            value
                          )
                        }
                        onPermaValues={inputForBaja}
                        onPropertyChange="causalDesvinculacion"
                        errorPosition="down"
                        list={causalesDesvinculacion}
                      />
                    </div>

                    <div
                      className=" xs:col-12 align-items-center flex "
                      style={{ float: "left", marginTop: "1.1rem" }}
                    >
                      {inputForBaja[index]
                        ?.checkAply /*|| inputForBaja[index]?.causalDesvinculacion?.value?.name === "ART.160N4"*/ ? (
                        <span
                          onClick={() => {
                            if (
                              inputForBaja[index]?.causalDesvinculacion
                                .value !== undefined
                            ) {
                              handleClickCheck(index, false);
                            }
                          }}
                        >
                          <IconCheckfila className="md:ml-0 md:mt-5 pl-1 mr-3 classSvg mr-0" />
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            if (
                              inputForBaja[index]?.causalDesvinculacion
                                .value !== undefined
                            ) {
                              handleClickCheck(index, true);
                            }
                          }}
                        >
                          <CheckBoxOutlineBlankIcon className="md:ml-0 md:mt-5 classSvg mr-0" />
                        </span>
                      )}

                      <span className="md:mt-5 textLucro">Lucro Cesante</span>
                    </div>

                    <div
                      className="col-12 md:col-2 md:ml-3 md:pl-2"
                      style={{ float: "left" }}
                    >
                      <InputDateCustomized
                        placeholder={"DD/MM/AAAA"}
                        layer="Fecha Termino"
                        onPropertyChange="date"
                        required={undefined}
                        maxLength={12}
                        onValueChange={(value: any) =>
                          handleValueChange(index, "date", value)
                        }
                        onPermaValues={
                          inputForBaja[index]?.causalDesvinculacion?.value
                            ?.name === "ART.159N4"
                            ? formatDateToDDMMAAAA({
                                date: fechaTerminoContrato,
                              })
                            : inputForBaja
                        }
                        defaultValue={
                          inputForBaja[index]?.causalDesvinculacion?.value
                            ?.name === "ART.159N4"
                            ? formatDateToDDMMAAAA({
                                date: fechaTerminoContrato,
                              })
                            : " "
                        }
                        errorPosition="down"
                        requerido={true}
                      />
                    </div>

                    <div
                      className="col-12 md:col-3 md:ml-3 md:pl-1"
                      style={{ float: "left" }}
                    >
                      <InputTextCustomized
                        required={
                          inputForBaja.causalDesvinculacion &&
                          inputForBaja[index]?.causalDesvinculacion?.value
                            ?.name === "ART.160N3"
                        }
                        layer={"Días de NO concurrencia"}
                        placeholder={"Ingrese"}
                        onValueChange={(value: any) =>
                          handleValueChange(index, "diasNoConcurrencia", value)
                        }
                        onPermaValues={inputForBaja}
                        onPropertyChange="diasNoConcurrencia"
                        errorPosition="down"
                      />
                    </div>

                    <div
                      className="col-12 md:col-3 md:ml-3 md:pl-1"
                      style={{ float: "left" }}
                    >
                      <InputTextCustomized
                        placeholder={"Ingrese"}
                        layer="Motivo Inhabilitación TW"
                        onPropertyChange="motivo"
                        required={undefined}
                        onValueChange={(value: any) =>
                          handleValueChange(index, "motivo", value)
                        }
                        onPermaValues={inputForBaja}
                        errorPosition="down"
                      />
                    </div>
                  </div>
                </>
              );
            })}

          <>
            <div className="modal-message-Mo mt-1 text-headerWhite">
              <span style={{ float: "none" }}>
                Al confirmar esta solicitud, los registros se moverán a bajas
                confirmadas. Para continuar presione Confirmar Baja.
              </span>
            </div>
          </>

          <div className="modal-button-content modal-button-content-in-modal center">
            <>
              <button
                className={`modal-button cancel`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={handleClickCloseModal}
              >
                {buttonBack ? buttonBack : "Cancelar"}
              </button>
              <button
                className={`modal-button ${
                  inputForBaja.some(
                    (item: {
                      causalDesvinculacion: { value: { name: undefined } };
                    }) => item?.causalDesvinculacion?.value?.name !== undefined
                  )
                    ? "delete"
                    : "disabled"
                }`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={handleClickConfirmaBaja}
                disabled={
                  inputForBaja.some(
                    (item: {
                      causalDesvinculacion: { value: { name: undefined } };
                    }) => item?.causalDesvinculacion?.value?.name !== undefined
                  )
                    ? false
                    : true
                }
              >
                {" "}
                {buttonGo ? buttonGo : "Confirmar Baja"}
              </button>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
