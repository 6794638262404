import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ContratosContext from "../../../../../../context/Contratos/ContratosContext";

interface ISolicitudesCliente {
  objetoFilterGeneral: any;
  width: string;
}

const SolicitudesCliente = (props: ISolicitudesCliente) => {
  const { objetoFilterGeneral, width } = props;
  const { token, listaSolicitudPorCliente, postSolicitudPorCliente } =
    useContext(ContratosContext);
  const [dataLabels, setDataLabels] = useState<string[]>([]);
  const [dataNumber, setDataNumber] = useState<string[]>([]);

  useEffect(() => {
    if (token) {
      const objeto = {
        contrato: true,
        filtros: {
          clientes: [objetoFilterGeneral?.cliente],
          areaNegocio: objetoFilterGeneral?.areaNegocio,
          analista: [],
        },
      };

      postSolicitudPorCliente({ token, filtros: objeto });
    }
  }, [token, objetoFilterGeneral]);

  useEffect(() => {
    if (listaSolicitudPorCliente) {
      const dataLabel =
        listaSolicitudPorCliente.data?.map((item: any) => [
          item.clienteNombre,
        ]) ?? [];
      const data =
        listaSolicitudPorCliente.data?.map((item: any) => [item.total]) ?? [];
      setDataLabels(dataLabel);
      setDataNumber(data);
    }
  }, [listaSolicitudPorCliente]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options: any = {
    responsive: true,
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        textAlign: "start",
      },
      datalabels: {
        display: false,
      },
    },
  };

  const data: any = {
    labels: dataLabels,
    datasets: [],
  };

  data.datasets.push({
    label: "Cantidad",
    data: dataNumber,
    backgroundColor: ["#FF4DAB", "#3B6597", "#FF774D"],
    borderColor: ["#FF4DAB", "#3B6597", "#FF774D"],
    borderRadius: [10],
    barThickness: 10,
  });

  return (
    <div className={`container-table-dashboard ${width} responsiveChart`}>
      <div className="content-title">
        <label className="title-table-dashboard">SOLICITUDES POR CLIENTE</label>
      </div>
      <Bar options={options} data={data} height={195} />
    </div>
  );
};

export default SolicitudesCliente;
