interface IconCheckblank {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconCheckblank) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "18"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 18 18"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M2.38889 17.2012C1.86944 17.2012 1.42461 17.0164 1.05439 16.6468C0.684796 16.2766 0.5 15.8317 0.5 15.3123V2.09006C0.5 1.57062 0.684796 1.12578 1.05439 0.755561C1.42461 0.385968 1.86944 0.201172 2.38889 0.201172H15.6111C16.1306 0.201172 16.5754 0.385968 16.9456 0.755561C17.3152 1.12578 17.5 1.57062 17.5 2.09006V15.3123C17.5 15.8317 17.3152 16.2766 16.9456 16.6468C16.5754 17.0164 16.1306 17.2012 15.6111 17.2012H2.38889ZM2.38889 15.3123H15.6111V2.09006H2.38889V15.3123Z" 
       fill={fill}/>
      </svg>



    );
  };
  