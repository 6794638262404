import { useEffect, useState, useContext } from "react";
import IconFilterFull from "../../icons-seleccion/IconFilterFull";
import FiltroGenerico from "../../kit-modal/FiltroGenerico";
import IconFilter from "../../icons-seleccion/IconFilter";
import DropDownListFiltro from "../../kit-modal/Filtros-kit/DropDownListFiltro";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import {
  IListaAreaNegocio,
  IListaClientes,
  IUsuarioAnalista,
} from "../../../../interfaces/IOperacionesContratacion";
import { FILTER_PROFILE } from "../../../../diccionary/Diccionary";

interface ITitleDashboard {
  title: string;
  setFormFilter: Function;
  formFilter: any;
  idCliente?: string;
  localStorageFilter: string;
}

const TitleDashboard = (props: ITitleDashboard) => {
  const { idCliente, title, localStorageFilter, formFilter, setFormFilter } =
    props;
  const {
    token,
    roles,
    getUserAnalist,
    listaUsuarioAnalista,
    getListClient,
    listaCliente,
    getListAreaNegocio,
    listaAreaNegocio,
    profile,
  } = useContext(OperacionContratacionContext);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [listaAnalistaFiltro, setListaAnalistaFiltro] = useState<any>({});
  const [listaClienteFiltro, setListaClienteFiltro] = useState<any>({});
  const [listaAreaNegocioFiltro, setListaAreaNegocioFiltro] = useState<any>({});
  const [idClienteAreaNegocio, setIdClienteAreaNegocio] =
    useState<any>(undefined);
  const listCiclo = [
    { name: "Activos", code: "Activos" },
    { name: "Ingresos", code: "Ingresos" },
  ];

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (token) {
      getUserAnalist({ token });
      getListClient({ token });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const filterDash = JSON.parse(
          localStorage.getItem(localStorageFilter) as string
        );
        setIdClienteAreaNegocio(filterDash?.cliente[0]);
      }
      if (formFilter?.cliente.value) {
        setIdClienteAreaNegocio(formFilter.cliente?.value?.code);
      }
      if (idCliente) {
        setIdClienteAreaNegocio(idCliente);
      }
    }
  }, [token, formFilter?.cliente.value]);

  useEffect(() => {
    if (token && idClienteAreaNegocio) {
      getListAreaNegocio({ token, cliente: idClienteAreaNegocio });
    }
  }, [token, idClienteAreaNegocio]);

  useEffect(() => {
    if (listaCliente.length > 0) {
      const filtro = listaCliente.map(
        ({ clienteId, clienteNombre }: IListaClientes) => {
          return {
            name: clienteNombre,
            code: clienteId,
          };
        }
      );
      setListaClienteFiltro(filtro);
    }
  }, [listaCliente]);

  useEffect(() => {
    if (listaAreaNegocio.length > 0) {
      const filtro = listaAreaNegocio.map(
        ({ areaNegocioId, areaNegocioCod }: IListaAreaNegocio) => {
          return {
            name: areaNegocioCod,
            code: areaNegocioId,
          };
        }
      );
      setListaAreaNegocioFiltro(filtro);
    }
  }, [listaAreaNegocio, idClienteAreaNegocio]);

  useEffect(() => {
    if (listaUsuarioAnalista.length > 0) {
      const filtro = listaUsuarioAnalista.map(
        ({ usuarioId, usuarioNombre }: IUsuarioAnalista) => {
          return {
            name: usuarioNombre,
            code: usuarioId,
          };
        }
      );
      setListaAnalistaFiltro(filtro);
    }
  }, [listaUsuarioAnalista]);

  return (
    <>
      <section className="section-title-page dashboard">
        <div className="content-title-dashboard">
          <h1 className="title-page-dashboard">{title}</h1>
        </div>
        <div className="content-title-dashboard">
          <>
            <label className="label-ciclo">Filtrar: </label>
            <button
              className={`active-filterDash`}
              onClick={() => setOpenModalFiltro(true)}
            >
              {dataObject ? (
                <IconFilterFull fill="#3B6597" />
              ) : (
                <IconFilter fill="#3B6597" />
              )}
            </button>
          </>
        </div>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => {
            setOpenModalFiltro(false);
          }}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
          medida={40}
        >
          {(roles && roles.includes("JEFE_OPS")) ||
          [FILTER_PROFILE.admin_ops].includes(profile) ? (
            <>
              <DropDownListFiltro
                list={listCiclo}
                layer="CICLO"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="ciclo"
              />
              <DropDownListFiltro
                list={listaClienteFiltro}
                layer="CLIENTE"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="cliente"
              />
              <DropDownListFiltro
                list={listaAreaNegocioFiltro}
                layer="ÁREA DE NEGOCIO"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="areaNegocio"
                disabled={!idClienteAreaNegocio}
              />
              <DropDownListFiltro
                list={listaAnalistaFiltro}
                layer="ANALISTAS"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="analista"
              />
            </>
          ) : (
            <>
              <DropDownListFiltro
                list={listCiclo}
                layer="CICLO"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="ciclo"
              />
              <DropDownListFiltro
                list={listaAreaNegocioFiltro}
                layer="ÁREA DE NEGOCIO"
                localStorageFilter={localStorageFilter}
                onValueChange={setFormFilter}
                onPropertyChange={formFilter}
                onPermaValues="areaNegocio"
              />
            </>
          )}
        </FiltroGenerico>
      )}
    </>
  );
};

export default TitleDashboard;
