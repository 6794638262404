import "../../../assets/css/adminModal.min.css";
import { useEffect, useState } from "react";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import IconEdit from "../../ui/kit-svg/iconEdit";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "primereact/button";
import ModalMessage from "../../ui/adminAssist/modals/ModalMessage";
import DropDownListCustomized from "../../ui/kit-mdtw/DropDownListCustomized";
import InputTextCustomized from "../../ui/kit-mdtw/InputTextCustomized";
import InputDateCustomized from "../../ui/kit-mdtw/InputDateCustomized";
import InputTimeCustomized from "../../ui/kit-mdtw/InputTimeCustomized";
import InputPhoneCustomized from "../../ui/kit-mdtw/InputPhoneCustomized";
import InputRutCustomized from "../../ui/kit-mdtw/InputRutCustomized";

interface IEditServiceModal {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
}

export default (props: IEditServiceModal) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, width } = props;
  const [isOpenDeleteData, setIsOpenDeleteData] = useState<boolean>(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          {subTitle ? (
            <>
              <div className="modal-subtitle">
                <span>{subTitle}</span>
              </div>
            </>
          ) : (
            ""
          )}
          <div
            className="modal-message"
          >
            <span>{message}</span>
          </div>
          <span className="modal-title-table">Proceso Asignado</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Proceso</div>
              <div className="project-table-subtitle">123456 - AUXILIAR DE OLLA TARDE</div>
            </div>
            <div className="content">
              <div className="project-table-title">Cliente</div>
              <div className="project-table-subtitle">CAROZZI</div>
            </div>
            <div className="content">
              <div className="project-table-title">Candidatos</div>
              <div className="project-table-subtitle">10</div>
            </div>
            <div className="content">
              <div className="project-table-title">Destino</div>
              <div className="project-table-subtitle">Av. El Salto 4447, Huechuraba, Región Metropolitana</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/23</div>
            </div>
            <div className="content">
              <div className="project-table-title">Acciones</div>
              <div className="project-table-subtitle">
                <ButtonIcon>
                  <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
                </ButtonIcon>
              </div>
            </div>
          </div>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <DropDownListCustomized
                list={[{ code: "123456", name: "654321" }]}
                layer="ID Proceso"
                placeholder="Seleccionar"
              />
            </div>
            <div className="content-inputs">
              <InputTextCustomized
                layer="Cliente"
                placeholder="Empresa"
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputTextCustomized
                layer="N de Candidatos"
                placeholder="Cantidad"
                required={true}
              />
            </div>
          </section>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <InputTextCustomized
                layer="Dirección"
                placeholder="Dirección"
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputDateCustomized
                layer="Fecha"
                required={true}
                placeholder="DD/MM/DDDD"
                maxLength={12}
              />
            </div>
            <div className="content-inputs">
              <InputTimeCustomized
                layer="Hora"
                required={true}
                placeholder="12:12"
                maxLength={5}
              />
            </div>
          </section>

          <span className="modal-title-table">Transporte Asignado</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Tipo</div>
              <div className="project-table-subtitle">Otro</div>
            </div>
            <div className="content">
              <div className="project-table-title">Nombre</div>
              <div className="project-table-subtitle">Olegario Cepeda Asenjo</div>
            </div>
            <div className="content">
              <div className="project-table-title">Rut</div>
              <div className="project-table-subtitle">21.140.802-2</div>
            </div>
            <div className="content">
              <div className="project-table-title">Correo</div>
              <div className="project-table-subtitle">nombre@correo.cl</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fono</div>
              <div className="project-table-subtitle">+569 12345678</div>
            </div>
            <div className="content">
              <div className="project-table-title">Acciones</div>
              <div className="project-table-subtitle">
                <ButtonIcon>
                  <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
                  <IconEdit color={"#404040"} width={32} height={32} />
                </ButtonIcon>
              </div>
            </div>
          </div>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <DropDownListCustomized
                list={[{ code: "123456", name: "Servicio Interno" }]}
                layer="Servicio de Transporte"
                placeholder="Servicio Externo"
              />
            </div>
            <div className="content-inputs">
              <DropDownListCustomized
                list={[{ code: "123456", name: "Otro" }]}
                layer="Tipo"
                placeholder="Otro"
              />
            </div>
          </section>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <InputTextCustomized
                layer="Nombre"
                placeholder="Nombre"
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputRutCustomized
                layer="Rut"
                placeholder="12345678-9"
                maxLength={12}
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputTextCustomized
                layer="Correo"
                placeholder="Correo"
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputPhoneCustomized
                layer="Fono"
                required={true}
                placeholder="56 9 1234 5678"
                maxLength={13}
              />
            </div>
          </section>

          <span className="modal-title-table">Ruta Asignada</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Inicio</div>
              <div className="project-table-subtitle">Av. El Salto 4447, Huechuraba, Región Metropolitana</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/23</div>
            </div>
            <div className="content">
              <div className="project-table-title">Hora</div>
              <div className="project-table-subtitle">14:15</div>
            </div>
            <div className="content">
              <div className="project-table-title">Destino</div>
              <div className="project-table-subtitle">Av. El Salto 4447, Huechuraba, Región Metropolitana</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/23</div>
            </div>
            <div className="content">
              <div className="project-table-title">Hora</div>
              <div className="project-table-subtitle">15:15</div>
            </div>
            <div className="content">
              <div className="project-table-title">Acciones</div>
              <div className="project-table-subtitle">
                <ButtonIcon>
                  <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
                  <IconEdit color={"#404040"} width={32} height={32} />
                </ButtonIcon>
              </div>
            </div>
          </div>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <InputTextCustomized
                layer="Inicio"
                placeholder="Buscar..."
                required={true}
              />
            </div>
            <div className="content-inputs">
              <InputDateCustomized
                layer="Día"
                placeholder="DD/MM/YYYY"
                required={true}
                maxLength={12}
              />
            </div>
            <div className="content-inputs">
              <InputTimeCustomized
                layer="Hora"
                placeholder="14:15"
                required={true}
                maxLength={5}
              />
            </div>
          </section>

          <section className="modal-content-edit">
            <div className="content-inputs">
              <InputTextCustomized
                layer="Destino"
                placeholder="Buscar..."
                required={false}
              />
            </div>
            <div className="content-inputs">
              <InputDateCustomized
                layer="Día"
                placeholder="DD/MM/YYYY"
                required={false}
                maxLength={12}
              />
            </div>
            <div className="content-inputs">
              <InputTimeCustomized
                layer="Hora"
                placeholder="14:15"
                required={false}
                maxLength={5}
              />
            </div>
          </section>

          <section className="modal-content-edit" style={{ width: "60%" }}>
            <div className="content-inputs">
              <InputDateCustomized
                layer="Día"
                placeholder="DD/MM/YYYY"
                required={true}
                maxLength={12}
              />
            </div>
            <div className="content-inputs">
              <InputTimeCustomized
                layer="Hora"
                placeholder="14:15"
                required={true}
                maxLength={5}
              />
            </div>
            <ButtonIcon>
              <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
              <IconEdit color={"#404040"} width={32} height={32} />
            </ButtonIcon>
          </section>

          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) setIsOpenDeleteData(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              className="button-go-confirm"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>

      {isOpenDeleteData ? (
        <ModalMessage
          title={"¿Está seguro qué desea salir de la página actual?"}
          message="Se perderán los cambios no guardados"
          onChangeBack={() => setIsOpenDeleteData(false)}
          onChangeGo={() => {
            setIsOpenDeleteData(false);
          }}
          confirm={false}
          width={width === "95%" ? "90%" : "40%"}
        />
      ) : (
        ""
      )}
    </>
  );
};
