import { useContext, useEffect, useState } from "react";
import IconAsignar from "../../../ui/icons-seleccion/IconAsignar";
import IconFilter from "../../../ui/icons-seleccion/IconFilter";
import ModalAsignacion from "./Modal/ModalAsignacion";
import FiltroGenerico from "../../../ui/kit-modal/FiltroGenerico";
import StateFiltro from "../../../ui/kit-modal/Filtros-kit/StateFiltro";
import InputFiltro from "../../../ui/kit-modal/Filtros-kit/InputFiltro";
import Tooltip from "../../../ui/kit-mdtw/tooltip/Tooltip";
import IconFilterFull from "../../../ui/icons-seleccion/IconFilterFull";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import { PROFILE_ADMIN_SEL } from "../../../../diccionary/Diccionary";
import IconEnlaceProceso from "../../../ui/icons-seleccion/IconEnlaceProceso";
import IconQr from "../../../ui/icons-seleccion/IconQr";
import {
  IAccionMasive,
  IEstadosExcel,
  IFiltrosExcel,
  IFiltrosExcelProceso,
} from "../../../../interfaces/ISeleccion";
import ModalGenerico from "../../../ui/kit-modal/ModalGenerico";
import ModalGenerarVinculo from "./Modal/ModalGenerarVinculo";
import IconCargando from "../../../ui/icons-seleccion/IconCargando";
import IconDescargaNomina from "../../../ui/icons-seleccion/IconDescargaNomina";

const AccionMasive = (props: IAccionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    listaOriginal,
    setFormData,
    formData,
  } = props;
  const {
    token,
    getToken,
    profile,
    createLinkProcessTxt,
    createCodeQrListProcess,
    putNombrePostulacion,
    dowloadExcelProcessActivos,
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalAsignar, setOpenModalAsignar] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isOpenModalQrEnlace, setIsOpenModalQrEnlace] =
    useState<boolean>(false);
  const [isOpenModalImageQr, setIsOpenModalImageQr] = useState<boolean>(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean | undefined>(
    undefined
  );
  const [filtrosExcel, setFiltrosExcel] = useState<any>({
    division: [],
    cliente: [],
    grupo: [],
    cargo: [],
    proceso: [],
    cuposRestantes: [],
    fechaCompromiso: [],
    analista: [],
    tipoProceso: [],
  });

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickOpenGenerarEnlace = () => {
    setIsOpenModalQrEnlace(true);
  };

  const handleClickOpenGenerarQr = () => {
    setIsOpenModalImageQr(true);
  };

  const handleClickDownloadNomina = async (): Promise<void> => {
    try {
      const tipoProceso = localStorage.getItem("itemSelection")
      if (token) {
        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          const dataObject = JSON.parse(copyStorage);
          filtrosExcel.division = [...dataObject.division];
          filtrosExcel.cliente = [...dataObject.cliente];
          filtrosExcel.grupo = [...dataObject.grupo];
          filtrosExcel.fechaCompromiso = [...dataObject.fechaEntrega];
          filtrosExcel.cargo = [...dataObject.cargo];
          filtrosExcel.cuposRestantes = [...dataObject.cuposRestantes];
        }
        filtrosExcel.tipoProceso = [JSON.parse(String(tipoProceso).toUpperCase())];
        filtrosExcel.proceso = listadoSeleccionado ?? [];
        setFiltrosExcel(filtrosExcel);
        await dowloadExcelProcessActivos({
          token,
          filtrosExcel,
          setIsLoadingExcel,
        });
      }
    } catch (error) {
      console.log("error descarga");
    }
  };

  return (
    <>
      <div className="container-actions">
        <div className="content-actions-masive">
          <Tooltip text="Descargar Nómina">
            <button
              className={`button-actions ${
                isLoadingExcel || !buttonEnabled
                  ? "disabled file"
                  : "active-file"
              }`}
              onClick={() => handleClickDownloadNomina()}
              disabled={!buttonEnabled}
            >
              {isLoadingExcel ? (
                <IconCargando fill="#327a30" />
              ) : (
                <IconDescargaNomina fill="#327a30" disabled={!buttonEnabled} />
              )}
            </button>
          </Tooltip>
          <Tooltip text="Asignar Equipo">
            {PROFILE_ADMIN_SEL.includes(profile) && (
              <button
                onClick={() => setOpenModalAsignar(true)}
                className={`button-actions ${
                  buttonEnabled ? "active-azul" : "disabled"
                }`}
                disabled={!buttonEnabled}
              >
                <IconAsignar fill="#0065DD" disabled={!buttonEnabled} />
              </button>
            )}
          </Tooltip>
          <Tooltip text="Generar QR">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-azul" : "disabled"
              }`}
              onClick={handleClickOpenGenerarQr}
            >
              <IconQr fill="#0065DD" />
            </button>
          </Tooltip>
          <Tooltip text="Enlace">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-rosado" : "disabled"
              }`}
              onClick={handleClickOpenGenerarEnlace}
            >
              <IconEnlaceProceso fill="#FF0081" />
            </button>
          </Tooltip>
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtro"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="DIVISION"
            listState={["TWEST", "TWRRHH", "TWC", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="GRUPO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="grupo"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="CARGO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cargo"
          />
          <InputFiltro
            layer="CUPOS RESTANTES"
            typeFilter="number"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cuposRestantes"
          />
          <InputFiltro
            layer="TIPO EXPERIENCIA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="tipoExperiencia"
          />
          <InputFiltro
            layer="FECHA ENTREGA"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaEntrega"
          />
        </FiltroGenerico>
      )}
      {openModalAsignar && (
        <ModalAsignacion
          title="ASIGNAR EQUIPO"
          token={token}
          getToken={getToken}
          onChangeBack={() => setOpenModalAsignar(false)}
          list={listadoSeleccionado}
        />
      )}

      {isOpenModalQrEnlace && (
        <ModalGenerico
          title="GENERAR VÍNCULO POR PROCESO"
          width="w-70"
          subtitle="Ingresa el nombre del proceso vínculo generado."
          positionSubTitle="start"
          messageBottom="Al confirmar, se descargará un archivo con los vínculo QR correspondiente(s). Para continuar presione Descargar vínculos"
          positionMessageBottom="start"
          onChangeBack={() => {
            setIsOpenModalQrEnlace(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            createLinkProcessTxt({ formData });
            putNombrePostulacion({ token, formData });
            setIsOpenModalQrEnlace(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cancelar"
          buttonGoText="Descargar Vínculos"
          buttonGoStyle="option"
        >
          <ModalGenerarVinculo
            list={listadoSeleccionado}
            listaOriginal={listaOriginal}
            setFormData={setFormData}
            formData={formData}
          />
        </ModalGenerico>
      )}

      {isOpenModalImageQr && (
        <ModalGenerico
          title="GENERAR CÓDIGO QR POR PROCESO"
          width="w-70"
          subtitle="Ingresa el nombre del proceso que vinculará el código QR generado."
          positionSubTitle="start"
          messageBottom="Al confirmar, se descargará un archivo con los códigos QR correspondientes. Para continuar presione generar código QR."
          positionMessageBottom="start"
          onChangeBack={() => {
            setIsOpenModalImageQr(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            createCodeQrListProcess({ formData });
            putNombrePostulacion({ token, formData });
            setIsOpenModalImageQr(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cancelar"
          buttonGoText="Generar Código QR"
          buttonGoStyle="confirm"
        >
          <ModalGenerarVinculo
            list={listadoSeleccionado}
            listaOriginal={listaOriginal}
            setFormData={setFormData}
            formData={formData}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default AccionMasive;
