interface IIconCancel {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconCancel) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M16.9969 3.40325C17.6606 2.7395 17.6606 1.66156 16.9969 0.997813C16.3331 0.334062 15.2552 0.334062 14.5914 0.997813L9 6.59456L3.40325 1.00312C2.7395 0.339372 1.66156 0.339372 0.997813 1.00312C0.334062 1.66687 0.334062 2.74481 0.997813 3.40856L6.59456 9L1.00312 14.5967C0.339372 15.2605 0.339372 16.3384 1.00312 17.0022C1.66687 17.6659 2.74481 17.6659 3.40856 17.0022L9 11.4054L14.5968 16.9969C15.2605 17.6606 16.3384 17.6606 17.0022 16.9969C17.6659 16.3331 17.6659 15.2552 17.0022 14.5914L11.4054 9L16.9969 3.40325Z"
      />
    </svg>
  );
};
