import { useContext, useEffect, useState, lazy, Suspense } from "react";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import TablaValidacionCliente from "./Validacion/TablaValidacionCliente";
import SeccionAccion from "./SeccionAccion";
import { FILTER_LIST } from "../../../../diccionary/Diccionary";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
const LazyTablaAsistenciaConfirmada = lazy(
  () => import("./Confirmacion/TablaAsistenciaConfirmada")
);

const SistemaValidacionKam = () => {
  const { token, getToken, getExclusiones, exclusiones, toasts } =
    useContext(ValidationKamContext);
  const { toasts: toastsSelection } = useContext(SeleccionContext);
  const { getListaGeneral } = useContext(SeleccionContext);
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getExclusiones({ token }); 
      getListaGeneral({ token });
    }
  }, [token]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  return (
    <>
      {toasts}
      {toastsSelection}
      <Suspense fallback={<div>Cargando...</div>}>
        <LazyTablaAsistenciaConfirmada
          dataBloqueo={dataBloqueo}
          dataDescarte={dataDescarte}
        />
      </Suspense>
      <TablaValidacionCliente
        dataBloqueo={dataBloqueo}
        dataDescarte={dataDescarte}
      />
    </>
  );
};

export default SistemaValidacionKam;
