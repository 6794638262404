import { useState, useEffect, useContext } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TablaProceso from "./TablaProceso";
import FooterTable from "../../../ui/kit-mdtw/table/FooterTable";

interface IItemGrupo {
  token: string | undefined;
  getToken: Function;
  procesos: any;
  cargoGroupName: string;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string; 
  currentListProcess: string[];
  setFormData: Function; 
  formData: any
}

interface ISelectMasive {
  busquedaId: string;
}

export default (props: IItemGrupo) => {
  const {
    token,
    getToken,
    procesos,
    cargoGroupName,
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    currentListProcess,
    setFormData, 
    formData,
  } = props;
  const [hiddenTable, setHiddenTable] = useState<boolean>(false);
  const [openModalAsignar, setOpenModalAsignar] = useState<boolean>(false);
  const [listaSeleccionProceso, setListaSeleccionProceso] = useState<string[]>(
    []
  );
  const [gridBusquedaProceso, setGridBusquedaProceso] = useState<any[]>(
    []
  );
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });

  useEffect(() => {
    if (localStorage.getItem(localStorageSelect)) {
      const multi: string = String(localStorage.getItem(localStorageSelect));
      setListaSeleccionProceso(createListMutate({ list: multi.split(",") }));
    }
  }, []);

  useEffect(() => {
    if (listadoSeleccionado) {
      setListaSeleccionProceso(createListMutate({ list: listadoSeleccionado }));
    }
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (procesos) {
      let arrayGridFilter = [...procesos];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridBusquedaProceso(arrayGridFilter);
    }
  }, [procesos, range]);

  const createListMutate = (values: any): string[] => {
    const { list } = values;
    const arrayProcesos: string[] = procesos.map(
      ({ busquedaId }: any) => busquedaId
    );
    return list.filter((busquedaId: string) =>
      arrayProcesos.includes(busquedaId)
    );
  };

  const handleClickMultiChoise = (): void => {
    const arrayListaProceso: string[] = [...listaSeleccionProceso];
    const arrayFull: string[] = [...listadoSeleccionado];
    if (procesos.length > arrayListaProceso.length) {
      procesos
        .map(({ busquedaId }: any) => busquedaId)
        .filter((busquedaId: string) => !arrayListaProceso.includes(busquedaId))
        .map((busquedaId: string) => {
          arrayListaProceso.push(busquedaId);
          arrayFull.push(busquedaId);
        });
    } else {
      arrayListaProceso.splice(0, procesos.length);
      procesos
        .map(({ busquedaId }: any) => busquedaId)
        .map((busquedaId: string) => {
          const position: number = arrayFull.findIndex(
            (item: string) => item === busquedaId
          );
          arrayFull.splice(position, 1);
        });
    }

    localStorage.setItem(localStorageSelect, String(arrayFull));
    setListadoSeleccionado(arrayFull);
    setListaSeleccionProceso(arrayListaProceso);
  };

  const handleClickAddOrRemoveChoise = (prompts: ISelectMasive) => {
    const { busquedaId } = prompts;
    const arrayFull: string[] = [...listadoSeleccionado];
    const arrayListaProceso = [...listaSeleccionProceso];

    if (!arrayListaProceso.includes(busquedaId)) {
      arrayListaProceso.push(busquedaId);
    } else {
      const position: number = arrayListaProceso.findIndex(
        (item: string) => item === busquedaId
      );
      arrayListaProceso.splice(position, 1);
    }

    if (!arrayFull.includes(busquedaId)) {
      arrayFull.push(busquedaId);
    } else {
      const position: number = arrayFull.findIndex(
        (item: string) => item === busquedaId
      );
      arrayFull.splice(position, 1);
    }

    localStorage.setItem(localStorageSelect, String(arrayFull));
    setListadoSeleccionado(arrayFull);
    setListaSeleccionProceso(arrayListaProceso);
  };

  return (
    <>
      <div className="content-header-table">
        <span className="title-header-table">
          <p className="text-grupo">{cargoGroupName}</p>
        </span>
        <button
          className={`button-hidden-table`}
          onClick={() => setHiddenTable(!hiddenTable)}
        >
          <KeyboardArrowDownIcon
            className={`svg-table-hidden ${
              hiddenTable ? "icon-down" : "icon-up"
            }`}
          />
        </button>
      </div>
      <div className={`container-table-list ${hiddenTable ? "hidden" : ""}`}>
        <table className="content-table">
          <thead className="thead-table-procesos">
            <tr>
              <th className="th-table-procesos">
                <button
                  className="button-select"
                  onClick={() => handleClickMultiChoise()}
                >
                  {listaSeleccionProceso.length == procesos.length &&
                  listaSeleccionProceso.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </button>
              </th>
              <th className="th-table-procesos">División <br></br>TW</th>
              <th className="th-table-procesos">ID</th> 
              <th className="th-table-procesos">Cargo</th>
              <th className="th-table-procesos">Lugar de Trabajo</th>
              <th className="th-table-procesos">Analista</th>
              <th className="th-table-procesos">Solicitados</th>
              <th className="th-table-procesos">Rebajados</th>
              <th className="th-table-procesos">Fecha <br></br>Entrega</th>
              <th className="th-table-procesos">Acciones</th>
            </tr>
          </thead>
          <tbody className="tbody-table-procesos">
            <TablaProceso
              token={token}
              getToken={getToken}
              listaProcesos={gridBusquedaProceso}
              handleClickAddOrRemoveChoise={handleClickAddOrRemoveChoise}
              listaSeleccionProceso={listaSeleccionProceso}
              currentListProcess={currentListProcess}
              setFormData={setFormData}
              formData={formData}
            />
          </tbody>
        </table>
      </div>
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />
    </>
  );
};
