import { IPublicClientApplication } from "@azure/msal-browser";

const authRequest = {
  scopes: ["User.Read"],
};

const me_url = "https://graph.microsoft.com/v1.0/me/";
const me_photo_url = "https://graph.microsoft.com/v1.0/me/photo/$value";

async function callMsGraph(
  url: string,
  msalInstance: IPublicClientApplication
) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...authRequest,
    account: account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

headers.append("Authorization", bearer);

const options = {
    method: "GET",
    headers: headers,
};

return await fetch(url, options);
}

export async function getMe(msalInstance: IPublicClientApplication) {
const response: any = await callMsGraph(me_url, msalInstance);
return await response.json();
}

export async function getPhoto(msalInstance: IPublicClientApplication) {
/*const response: any = await callMsGraph(me_photo_url, msalInstance);

if (!response.ok) return null;

const blob = await response.blob();
return URL.createObjectURL(blob);*/
return null;
}
