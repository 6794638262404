import { useContext } from "react";
import TablaGrupo from "./TablaGrupo";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { IListaFormatoPsicolaboral } from "../../../../../interfaces/ISeleccion";
interface ITablaCliente {
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string;
  localStorageFilter: string;
  formFilter: string;
  refreshTable: Function;
  selectCurrent: any;
  setSelectCurrent: Function;
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[];
}

const TablaCliente = (props: ITablaCliente) => {
  const {
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    localStorageFilter,
    formFilter,
    refreshTable,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
  } = props;
  const { listaCandidatoSeleccionado } = useContext(SeleccionContext);

  return (
    <>
      {listaCandidatoSeleccionado &&
        listaCandidatoSeleccionado.map((item: any, key: number) => {
          const { clienteId, clienteNombre, grupos } = item;
          return (
            <>
              <div className="content-header-cliente" key={key}>
                <span className="title-header-table">
                  <p className="text-cliente">{clienteNombre}</p>
                </span>
              </div>
              <TablaGrupo
                list={grupos}
                clienteNombre={clienteNombre}
                listadoSeleccionado={listadoSeleccionado}
                setListadoSeleccionado={setListadoSeleccionado}
                localStorageSelect={localStorageSelect}
                localStorageFilter={localStorageFilter}
                formFilter={formFilter}
                refreshTable={refreshTable}
                selectCurrent={selectCurrent}
                setSelectCurrent={setSelectCurrent}
                tipoDocumentoAll={tipoDocumentoAll}
                setTipoDocumentoAll={setTipoDocumentoAll}
                listaFormatoPsicolaboral={listaFormatoPsicolaboral}
              />
            </>
          );
        })}
    </>
  );
};

export default TablaCliente;
