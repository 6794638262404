import { useState, useEffect } from "react";

interface ISizes {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export default () => {
  const xs: number = 250,
    sm: number = 576,
    md: number = 768,
    lg: number = 960,
    xl: number = 1200;
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  return { width, height, xs, sm, md, lg, xl };
};
