import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaCandidatoPendiente from "./CandidatoPendiente/TablaCandidatoPendiente";
import TablaCandidatoEnviadoValidacion from "./CandidatoValidado/TablaCandidatoEnviadoValidacion";

const TablaRegistroPorValidar = () => {
  const {
    toasts,  
    getListaGeneral, 
    getExclusiones,
    getToken,
    token,
  } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getExclusiones({token});
      if (localStorage.getItem("itemSelection")) {
        const tipoProceso: any = localStorage.getItem("itemSelection");
        getListaGeneral({token, tipoProceso: tipoProceso.toUpperCase()});
      } else { 
        getListaGeneral({token});
      }
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaCandidatoPendiente />
      <TablaCandidatoEnviadoValidacion />
    </>
  );
};

export default TablaRegistroPorValidar;
