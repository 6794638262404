import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/modalmdtw.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import "../../assets/css/validationKam.min.css";
import "../../assets/css/loading.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import ValidationKamState from "../../context/ValidationKam/ValidationKamState";
import { Params } from "../../interfaces/IValidationKam";
import { FILTER_PROFILE } from "../../diccionary/Diccionary";
import SistemaValidacionKam from "./SistemaValidacionGeneral/AsistenciaValidacionKam/SistemaValidacionKam";
import SistemaContratacion from "./SistemaValidacionGeneral/Contratacion/SistemaContratacion";
import SeleccionState from "../../context/Seleccion/SeleccionState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import SeccionCliente from "./Operaciones/SeccionCliente";
import OperacionContratacionState from "../../context/Operaciones/OperacionContratacionState";
import SubMenu from "./Seleccion/SubMenu";

const Contratacion: React.FC<IPage> = (props) => {
  <Route path="/contratacion/:id" component={SistemaValidacionKam} />;
  const { idCliente } = useParams<Params>();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [itemMenu, setItemMenu] = useState<string | undefined>("seleccion");
  const [itemMenuFilter, setItemMenuFilter] = useState<string>("MASIVO");

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };

  const [profile, setProfile] = useState<any>();

  /**
   * TODO: DESCOMENTAR ANTES DE PASAR A PRODUCCION
   */
  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
            itemMenu={itemMenu}
            setItemMenu={setItemMenu}
            setItemMenuFilter={setItemMenuFilter}
          />
        ) : (
          ""
        )}

        <div className="container-validation-kam">
          <div className="content-validation-kam">
            <SeleccionState>
              <ValidationKamState>
                <OperacionContratacionState>
                  {[FILTER_PROFILE.adm_tw, FILTER_PROFILE.tw_ops].includes(
                    profile
                  ) && (
                    <>
                      {idCliente ? (
                        <>
                          <SubMenu
                            itemMenu={itemMenu}
                            setItemMenu={setItemMenu}
                            itemMenuFilter={itemMenuFilter}
                          />
                          <SistemaContratacion
                            token={token}
                            setToken={setToken}
                            getToken={getToken}
                          />
                        </>
                      ) : (
                        <>
                          <SubMenu
                            itemMenu={itemMenu}
                            setItemMenu={setItemMenu}
                            itemMenuFilter={itemMenuFilter}
                          />
                          <SeccionCliente />
                        </>
                      )}
                    </>
                  )}
                </OperacionContratacionState>
              </ValidationKamState>
            </SeleccionState>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default Contratacion;
