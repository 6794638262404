import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/modalmdtw.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import "../../assets/css/validationKam.min.css";
import "../../assets/css/OperacionContratacion.min.css";
import "../../assets/css/loading.min.css";
import "../../assets/css/etiquetas-tw.min.css";
import "../../assets/css/graficos-tw.min.css";
import "../../assets/css/dashboard-tw.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import ValidationKamState from "../../context/ValidationKam/ValidationKamState";
import { Params } from "../../interfaces/IValidationKam";
import { FILTER_PROFILE, LIST_ROLES } from "../../diccionary/Diccionary";
import SeleccionState from "../../context/Seleccion/SeleccionState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import OperacionContratacionState from "../../context/Operaciones/OperacionContratacionState";
import SistemaOperacionContratacion from "./Operaciones/SistemaOperacionContratacion";
import SubMenu from "./Seleccion/SubMenu";
import TablaDashboard from "./Operaciones/Resumen/TablaDashboard";

const OperacionResumen: React.FC<IPage> = (props) => {
  <Route
    path="/dotacionactiva/:idCliente"
    component={SistemaOperacionContratacion}
  />;
  const { idCliente } = useParams<Params>();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [itemMenu, setItemMenu] = useState<string | undefined>("seleccion");
  const [itemMenuFilter, setItemMenuFilter] = useState<string>("MASIVO");
  const [profile, setProfile] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    getToken();
  }, []);

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    const sessionRoles: string = window.sessionStorage.getItem(
      "roles"
    ) as string;
    setProfile(profileA);
    setRoles(JSON.parse(sessionRoles));
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
            itemMenu={itemMenu}
            setItemMenu={setItemMenu}
            setItemMenuFilter={setItemMenuFilter}
          />
        ) : (
          ""
        )}

        <div className="container-operaciones">
          <div className="content-operaciones">
            <SeleccionState>
              <ValidationKamState>
                <OperacionContratacionState>
                  {[
                    FILTER_PROFILE.adm_tw,
                    FILTER_PROFILE.tw_ops,
                    FILTER_PROFILE.admin_ops,
                  ].includes(profile) ? (
                    <>
                      {(roles && LIST_ROLES.JEFE_OPS) ||
                      [FILTER_PROFILE.admin_ops].includes(profile) ? (
                        <>
                          <SubMenu
                            itemMenu={itemMenu}
                            setItemMenu={setItemMenu}
                            itemMenuFilter={itemMenuFilter}
                          />
                          <TablaDashboard idCliente={idCliente} />
                        </>
                      ) : (
                        <>
                          {idCliente && (
                            <>
                              <SubMenu
                                itemMenu={itemMenu}
                                setItemMenu={setItemMenu}
                                itemMenuFilter={itemMenuFilter}
                              />
                              <TablaDashboard idCliente={idCliente} />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>ERROR: SIN ACCESO A CONTRATO</>
                  )}
                </OperacionContratacionState>
              </ValidationKamState>
            </SeleccionState>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default OperacionResumen;
