import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
       
       <path d="M1.68339 21.4738C1.26391 21.4738 0.896868 21.3165 0.582262 21.0019C0.267655 20.6873 0.110352 20.3202 0.110352 19.9007V2.07303C0.110352 1.65356 0.267655 1.28652 0.582262 0.97191C0.896868 0.657303 1.26391 0.5 1.68339 0.5H10.5972L16.8894 6.79213V21.5H15.3163V7.84082H9.54855V2.07303H1.68339V19.9007H15.3163V21.4738H1.68339Z" fill="#06A502"/>
      <path d="M12.0375 10.1854L9.79937 13.6686L12.0375 17.1517C12.2334 17.4454 12.0096 17.8371 11.6598 17.8371H10.3589C10.191 17.8371 10.0372 17.7532 9.96723 17.5993C9.23983 16.2564 8.61035 15.0394 8.61035 15.0394C8.37255 15.585 8.23266 15.7808 7.25347 17.5993C7.16954 17.7392 7.02965 17.8371 6.86179 17.8371H5.56087C5.21115 17.8371 5.00133 17.4454 5.18318 17.1517L7.42133 13.6686L5.18318 10.1854C4.98734 9.89168 5.21115 9.5 5.56087 9.5H6.86179C7.02965 9.5 7.18353 9.58393 7.25347 9.7378C8.21867 11.5563 7.99486 10.9828 8.61035 12.2837C8.61035 12.2837 8.83417 11.8501 9.96723 9.7378C10.0512 9.59792 10.191 9.5 10.3589 9.5H11.6598C12.0096 9.5 12.2194 9.89168 12.0375 10.1854Z" fill="#06A502"/>
    </svg>
  );
};

