import React, { useContext } from "react";
import "../../../assets/css/servicesAssists.min.css";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableService from "./TableService";
import TableServiceFilter from "./TableServiceFilter";
import AdminAssistContext from "../../../context/AdminAssist/AdminAssistContext";
import ActionMasive from "./ActionMasive";
import NewServiceTable from "./NewServiceTable";
import { IToken } from "../../../interfaces/IAdminAssist";

const Services = (props: IToken) => {
  const { token, setToken, getToken } = props;
  const {
    getAdminTable,
    getListadoProcesos,
    getListaTipoServicio,
    getListaUsuariosTransporte,
    toasts,
  } = useContext(AdminAssistContext);
  const [visibleNewService, setVisibleNewService] = useState<boolean>(true);
  const [visibleAdminService, setVisibleAdminService] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      getAdminTable({ token });
      getListadoProcesos({ token });
      getListaTipoServicio({ token });
      getListaUsuariosTransporte({ token });
    }
  }, [token]);

  const handleClickVisibleNewService = () => {
    visibleNewService
      ? setVisibleNewService(false)
      : setVisibleNewService(true);
  };

  const handleClickVisibleAdminService = () => {
    visibleAdminService
      ? setVisibleAdminService(false)
      : setVisibleAdminService(true);
  };

  return (
    <>
      {toasts}
      <div className="container-admin-assist">
        <div className="content-admin-assist">
          <span className="nav-tag">Home / Nivel</span>
          <span className="title">Servicios</span>
          <span className="sub-title">
            En esta opción puede crear y administrar los servicios de
            transporte.
          </span>
          <div className="line-separate"></div>
          <div
            className="content-new-service"
            onClick={handleClickVisibleNewService}
          >
            <span>Crear Nuevo Servicio</span>
            {visibleNewService ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          <div
            style={
              visibleNewService
                ? {
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "20px",
                  }
                : { display: "none" }
            }
          >
            <NewServiceTable
              token={token}
              setToken={setToken}
              getToken={getToken}
            />
          </div>

          <div className="line-separate"></div>
          <div
            className="content-new-service"
            onClick={handleClickVisibleAdminService}
          >
            <span>Administrar Servicio</span>
            {visibleAdminService ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          <div
            style={
              visibleAdminService
                ? { display: "block", width: "100%" }
                : { display: "none" }
            }
          >
            <ActionMasive
              token={token}
              setToken={setToken}
              getToken={getToken}
            />
            <TableService
              token={token}
              setToken={setToken}
              getToken={getToken}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
