import MediaQuery from "../../ui-responsive/MediaQuery";

interface IIconArrowDoubleLeft {
  className?: any;
  disabled?: boolean;
}

export default (props: IIconArrowDoubleLeft) => {
  const { className, disabled } = props;
  const {width, sm} = MediaQuery();

  return (
    <svg
      width={width >= sm ? '23' : '16' }
      height={width >= sm ? '23' : '16' }
      viewBox="0 0 27 23"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4793 10.3488L22.6895 1.13857C23.3261 0.501986 24.3554 0.501986 24.9853 1.13857L26.5158 2.6691C27.1524 3.30568 27.1524 4.33506 26.5158 4.96488L19.9941 11.5001L26.5226 18.0285C27.1591 18.6651 27.1591 19.6945 26.5226 20.3243L24.992 21.8616C24.3554 22.4982 23.3261 22.4982 22.6963 21.8616L13.486 12.6514C12.8427 12.0148 12.8427 10.9854 13.4793 10.3488ZM0.476598 12.6514L9.68682 21.8616C10.3234 22.4982 11.3528 22.4982 11.9826 21.8616L13.5131 20.331C14.1497 19.6945 14.1497 18.6651 13.5131 18.0353L6.99147 11.5001L13.5199 4.97165C14.1565 4.33506 14.1565 3.30568 13.5199 2.67587L11.9894 1.13857C11.3528 0.501986 10.3234 0.501986 9.69359 1.13857L0.483371 10.3488C-0.15999 10.9854 -0.15999 12.0148 0.476598 12.6514Z"
        fill={!disabled ? '#48566B' : '#AEAEAE'}
      />
    </svg>
  );
};
