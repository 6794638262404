import React, { useEffect, useState, useContext } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { loginRequest } from "../../config/authConfig";
import ListAssists from "../ui/V2/ListAssists";
import AssistsEnd from "../ui/V2/AssistsEnd";
import AsistenciaState from "../../context/Assist/AsistenciaState";
import AsistenciaContext from "../../context/Assist/AsistenciaContext";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

//login
const ListaAsistencia: React.FC<IPage> = (props) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getToken(); 
  }, []); 
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      {/* <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
        > */}
      {profile ? ( 
        <MainTopbar
          profile={profile}
          onToggleMenuClick={onToggleMenuClick}
          active={"C"}
        />
      ) : (
        ""
      )}

      <AsistenciaState>
        {profile ? (
          <ListAssists token={token} setToken={setToken} getToken={getToken} />
        ) : (
          <AssistsEnd />
        )}
      </AsistenciaState>

      {/* </MsalAuthenticationTemplate> */}
    </>
  );
};

export default ListaAsistencia;
