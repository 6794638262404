import { useState, useEffect, useContext, Dispatch } from "react";
import AlertAssists from "./alerts/AlertAssists";
import Filter from "./Filter";
import Target from "./Target";
import { IListaTarget } from "../../../interfaces/IAsistencias";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";
import { formatDateToDDMMAAAA } from "../../../functions/kit-mdtw/dates";
import { IToken } from "../../../interfaces/IAdminAssist";

interface IControlAsistencia {
  history?: any;
  token: string | undefined;
}

export default (props: IControlAsistencia) => {
  const { history , token } = props;
  const { listaTarget, asistenciaInterna, getListaTargetInterna,userId } = useContext(AsistenciaContext);
  const [target, setTarget] = useState<boolean>(true);
  const [message, setMessage] = useState<boolean>(false);
  const [messageAlert, setMessageAlert] = useState<boolean>(true);
  const searchParams = new URLSearchParams(location.search);
  const servicioId = searchParams.get('serviceId');
  const invitationId = searchParams.get('invitationId');

  const handleClickTargetState = () => {
    setTarget(!target ? true : false);
  };

  useEffect(() => {
    if (token) {
      getListaTargetInterna({token});
    }
  }, [token])

  return (
    <>
      <div className="content-body">
        <span className="nav-text">
          Asistencia / {target ? "Lista pendientes" : "Listas entregadas"}
        </span>
        <span className=" title-assists">Control de Asistencia</span>
        {message && messageAlert && (
          <AlertAssists
            message={"La asistencia fue entregada de forma exitosa."}
            success={true}
          />
        )}
        {message && !messageAlert && (
          <AlertAssists
            message={"La asistencia no fue entregada, por favor vuelve a intentarlo."}
            success={false}
          />
        )}
        
        <div className="content-section">
          <div
            className={`${
              target ? "section-active arrowBottom" : "section-inactive"
            }`}
            onClick={() => handleClickTargetState()}
          >
            <p>Pendientes</p>
          </div>
          <div
            className={`${
              !target ? "section-active arrowBottom" : "section-inactive"
            }`}
            onClick={() => handleClickTargetState()}
          >
            <p>Entregadas</p>
          </div>
        </div>
        <div className="content-section-blue"></div>

        <Filter />

        {listaTarget && (
          <>
            {target ? (
              <>
                {listaTarget.map((list: any) => {
                  if (list.estadoServicio !== "FINALIZADO" && list.activo) {
                    return (
                      <>
                        <div>
                          <p className="date-assist">{formatDateToDDMMAAAA({date: list.fechaCrecion})}</p>
                        </div>
                        <Target
                          servicioId={list.servicioId}
                          tipoServicio={list.tipoServicio}
                          estadoServicio={list.estadoServicio}
                          tipoTransporte={list.tipoTransporte}
                          fechaCrecion={list.fechaCrecion}
                          rutas={list.rutas}
                          procesos={list.procesos}
                          route={`/asistencia/lista?userId=${userId}&serviceId=${servicioId}&invitationId=${invitationId}`}
                          history={history}
                        />
                      </>
                    );
                  }
                })}
              </>
            ) : (
              <>
                {listaTarget.map((list: any) => {
                  if (list.estadoServicio === "FINALIZADO" && list.activo) {
                    return (
                      <>
                        <div>
                        <p className="date-assist">{formatDateToDDMMAAAA({date: list.fechaCrecion})}</p>
                        </div>
                        <Target
                          servicioId={list.servicioId}
                          tipoServicio={list.tipoServicio}
                          estadoServicio={list.estadoServicio}
                          tipoTransporte={list.tipoTransporte}
                          fechaCrecion={list.fechaCrecion}
                          rutas={list.rutas}
                          procesos={list.procesos}
                          route={`/asistencia/lista?userId=${userId}&serviceId=${servicioId}&invitationId=${invitationId}`}
                          history={history}
                        />
                      </>
                    );
                  }
                })}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
