import { useContext, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../../../../ui/kit-mdtw/InputTextCustomized";
import InputRutCustomized from "../../../../../ui/kit-mdtw/InputRutCustomized";
import InputDateCustomized from "../../../../../ui/kit-mdtw/InputDateCustomized";
import DropDownListCustomized from "../../../../../ui/kit-mdtw/DropDownListCustomized";
import LayerAgeCustomized from "../../../../../ui/kit-mdtw/LayerAgeCustomized";
import Hijos from "../../../../../ui/kit-mdtw/modals/modalUpdateCandidate/componentes/Hijos";
import InputNumberCustomized from "../../../../../ui/kit-mdtw/InputNumberCustomized";
import InputEdadHijoCustomized from "../../../../../ui/kit-mdtw/InputEdadHijoCustomized";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYYMMDD,
} from "../../../../../../functions/kit-mdtw/dates";
import { v4 as uuidv4 } from "uuid";
import { convertThousand } from "../../../../../../functions/kit-mdtw/number";

interface IAreaProfesional {
  list?: any[];
  rut?: string;
  formCandidate?: any;
  setFormCandidate?: Function;
  requerido?: boolean;
  contraerModal?: boolean;
  busPerId?: string;
  token?: string;
  setObjsendPsicolaboralProfesional?: any;
}

interface ICountTitle {
  cantidad: number;
}

export default (props: IAreaProfesional) => {
  const {
    list,
    rut,
    formCandidate,
    setFormCandidate,
    requerido,
    contraerModal,
    token,
    busPerId,
    setObjsendPsicolaboralProfesional,
  } = props;
  const [hidden, setHidden] = useState<boolean>(false);
  const [countTitle, setCountTitle] = useState<number>(0);
  const [countReference, setCountReference] = useState<number>(0);
  const [limit] = useState<number>(10);

  const [selectDataFormacion, setSelectDataFormacion] = useState<any>([
    {
      titulo: { value: undefined },
      institucion: { value: undefined },
      tipo: { code: undefined, name: undefined },
      year: { value: undefined },
    },
  ]);
  const [selectDataFormacionInicio, setSelectDataFormacionInicio] =
    useState<any>([
      {
        fechaEvaluacion: { value: undefined },
        pretensionRenta: { value: undefined },
        pruebasAplicadas: { value: undefined },
      },
    ]);

  const [selectDataFormacionReference, setSelectDataFormacionReference] =
    useState<any>([
      {
        nombre: { value: undefined },
        cargo: { value: undefined },
        empresa: { value: undefined },
        fono: { value: undefined },
      },
    ]);

  const { getInformacionPsicolaboral, infoAntecedentes, listTipoEducacion } =
    useContext(SeleccionContext);

  /*useEffect(() => {
    if (token && busPerId) {
      getInformacionPsicolaboral({ token, busPerId })
    };
  }, []);*/

  useEffect(() => {
    if (infoAntecedentes && infoAntecedentes.profesional) {
      setCountTitle(infoAntecedentes?.profesional?.totalTitulos);
      setCountReference(infoAntecedentes?.profesional?.totalReferencias);

      const tituloValues = infoAntecedentes?.profesional?.titulos.map(
        (titulo: any) => ({
          id: uuidv4(),
          titulo: { value: titulo?.nombre ?? "" },
          tipo: {
            value: {
              code: titulo?.tipoEducacionId ?? "",
              name: titulo?.tipoEducacion ?? "",
            },
          },
          institucion: { value: titulo?.institucion ?? "" },
          year: {
            value:
              formatDateToDDMMAAAA({
                date: titulo?.fechaEgreso,
                format: "/",
              }) ?? "",
          },
        })
      );

      setSelectDataFormacion(tituloValues);

      let referenciaValues: any[] = [];

      infoAntecedentes?.profesional?.referencias &&
        infoAntecedentes?.profesional?.referencias.map((referencias: any) =>
          referenciaValues.push({
            id: uuidv4(),
            nombre: { value: referencias.nombreCompleto },
            cargo: { value: referencias.cargo },
            fono: { value: referencias.fono },
            empresa: { value: referencias.empresa },
          })
        );
      setSelectDataFormacionReference(referenciaValues);

      setSelectDataFormacionInicio({
        fechaEvaluacion: {
          value:
            formatDateToDDMMAAAA({
              date: infoAntecedentes?.profesional?.fechaEvaluacion,
              format: "/",
            }) ?? "",
        },
        pretensionRenta: {
          value: 
            infoAntecedentes?.profesional?.pretensionRenta !== null
              ? (infoAntecedentes?.profesional?.pretensionRenta).toString() //parseInt(infoAntecedentes?.profesional?.pretensionRenta)
              : "",
        },
        pruebasAplicadas: {
          value:
            infoAntecedentes.profesional.pruebasAplicadas !== null
              ? infoAntecedentes.profesional.pruebasAplicadas
              : " ",
        }
      });
    }
  }, [infoAntecedentes]);


  
  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  const objetoSendInfoProfesional = useMemo(() => {
    return {
      fechaEvaluacion:
        formatDateToYYYYMMDD({
          date: selectDataFormacionInicio?.fechaEvaluacion?.value,
          format: "-",
        }) ?? "",
      pretensionRenta: (selectDataFormacionInicio?.pretensionRenta?.value ?? "").replaceAll(".",""),
      pruebasAplicadas: selectDataFormacionInicio?.pruebasAplicadas?.value ?? "",
      titulos: Object.values(selectDataFormacion).map((item: any) => ({
        nombre: item.titulo?.value ?? "",
        institucion: item.institucion?.value ?? "",
        tipoEducacionId: item.tipo?.value?.code ?? "",
        fechaEgreso:
          formatDateToYYYYMMDD({ date: item.year?.value, format: "-" }) ?? "",
      })),
      referencias: Object.values(selectDataFormacionReference).map(
        (item: any) => ({
          nombreCompleto: item.nombre?.value ?? "",
          cargo: item.cargo?.value ?? "",
          empresa: item.empresa?.value ?? "",
          fono: item.fono?.value ?? "",
        })
      ),
    };
  }, [
    selectDataFormacionInicio,
    selectDataFormacion,
    selectDataFormacionReference,
  ]);

  useEffect(() => {
    if (setObjsendPsicolaboralProfesional && objetoSendInfoProfesional) {
      setObjsendPsicolaboralProfesional({ ...objetoSendInfoProfesional });
    }
  }, [objetoSendInfoProfesional]);

  useEffect(() => {
    setCountReference(selectDataFormacionReference.length);
  }, [selectDataFormacionReference])

  useEffect(() => {
    setCountTitle(selectDataFormacion.length);
  }, [selectDataFormacion])

  const fechaFormat = (originalDate: any) => {
    if (originalDate) {
      const parts = originalDate.split("-");
      if (parts.length === 3) {
        const [day, month, yearTime] = parts;
        const [year, rest] = yearTime.split("T");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }
    }
  };

  const handleChangeCountTitle = (values: any) => {
    if (values > selectDataFormacion.length) {
      //agregando
      const addSelectDataFormacion: any[] = [...selectDataFormacion];
      for (
        let i = selectDataFormacion.length;
        i < (values <= limit ? values : limit);
        i++
      ) {
        addSelectDataFormacion.push({
          id: uuidv4(),
          titulo: { value: undefined },
          tipo: {
            value: {
              code: undefined,
              name: undefined,
            },
          },
          institucion: { value: undefined },
          year: {
            value: undefined,
          },
        });
      }
      setSelectDataFormacion(addSelectDataFormacion);
    } else {
      const updatedSelectDataFormacion: any[] = [...selectDataFormacion];
      updatedSelectDataFormacion.splice(selectDataFormacion.length - 1, 1);
      setSelectDataFormacion(updatedSelectDataFormacion);
    }
  };

  const handleChangeCountReference = (values: any) => {
    if (values > selectDataFormacionReference.length) {
      const addSelectDataFormacion: any[] = [...selectDataFormacionReference];
      for (
        let i = selectDataFormacionReference.length;
        i < (values <= limit ? values : limit);
        i++
      ) {
        addSelectDataFormacion.push({
          id: uuidv4(),
          nombre: { value: undefined },
          cargo: { value: undefined },
          empresa: { value: undefined },
          fono: { value: undefined },
        });
      }
      setSelectDataFormacionReference(addSelectDataFormacion);
    } else {
      const updatedSelectDataFormacion = selectDataFormacionReference;
      updatedSelectDataFormacion.splice(
        selectDataFormacionReference.length - 1,
        1
      );
      setSelectDataFormacionReference([...updatedSelectDataFormacion]);
    }
  };

  const handleValueChange = (
    sectionIndex: any,
    property: string,
    value: any
  ) => {
    const newSelectDataFormacion: any[] = [...selectDataFormacion];
    newSelectDataFormacion[sectionIndex] = {
      ...selectDataFormacion[sectionIndex],
      [property]: value[property],
    };
    setSelectDataFormacion(newSelectDataFormacion);
  };

  const handleValueChangeReference = (
    sectionIndex: number,
    property: string,
    value: any
  ) => {
    const newSelectDataFormacion: any[] = [...selectDataFormacionReference];
    newSelectDataFormacion[sectionIndex] = {
      ...selectDataFormacionReference[sectionIndex],
      [property]: value[property],
    };
    setSelectDataFormacionReference(newSelectDataFormacion);
  };

  const handleRemoveSection = (titleRemove: any) => {
    // Elimina la sección correspondiente en selectDataFormacion
    const updatedSelectDataFormacion = selectDataFormacion.filter(
      (section: any) => section.id !== titleRemove.id
    );
    setSelectDataFormacion([...updatedSelectDataFormacion]);
    // Actualiza el valor del contador
    setCountTitle(updatedSelectDataFormacion.length);
  };

    const handleRemoveSectionReference = (refRemove: any) => {
    // Elimina la sección correspondiente en selectDataFormacion
    const updatedSelectDataFormacion = selectDataFormacionReference.filter(
      (section: any) => section.id !== refRemove.id
    );
    setSelectDataFormacionReference([...updatedSelectDataFormacion]);
    // Actualiza el valor del contador
    setCountReference(updatedSelectDataFormacion.length);
  };

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">INFORMACIÓN PROFESIONAL</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}
      <section className={`project-table pb-2 ${hidden ? "disabled" : ""}`}>
        <div
          className="container-input psicolaboral"
          style={{ display: contraerModal ? "flow" : "flex" }}
        >
          <div
            className={`content-text-modal-update ${
              contraerModal ? "w-100" : "w-25"
            }`}
          >
            <InputDateCustomized
              layer="Fecha de Evaluación"
              placeholder="DD/MM/AAAA"
              required={true}
              maxLength={10}
              errorPosition="down"
              onValueChange={setSelectDataFormacionInicio}
              onPermaValues={selectDataFormacionInicio}
              onPropertyChange="fechaEvaluacion"
              defaultValue={
                selectDataFormacionInicio?.fechaEvaluacion?.value ?? ""
              }
              datoRequerido={requerido}
            />
          </div>

          <div
            className={`content-text-modal-update ${
              contraerModal ? "w-100" : "w-25"
            }`}
          >
            <InputTextCustomized
              layer="Pretensión de Renta"
              placeholder="Ingrese"
              required={true}
              errorPosition="down"
              onValueChange={setSelectDataFormacionInicio}
              onPermaValues={selectDataFormacionInicio}
              onPropertyChange="pretensionRenta"
              defaultValue={
                convertThousand( { number: String((selectDataFormacionInicio?.pretensionRenta?.value ?? "").replaceAll(".","")) })// selectDataFormacionInicio?.pretensionRenta?.value ?? ""
              }
              datoRequerido={requerido}
            />
            <span className="mesageDown">Solo valor numérico</span>
          </div>

          <div
            className={`content-text-modal-update ${
              contraerModal ? "w-100" : "w-45"
            }`}
          >
            <InputTextCustomized
              layer="Pruebas Aplicadas"
              placeholder="Ingrese"
              required={false}
              errorPosition="down"
              onValueChange={setSelectDataFormacionInicio}
              onPermaValues={selectDataFormacionInicio}
              onPropertyChange="pruebasAplicadas"
              defaultValue={
                selectDataFormacionInicio?.pruebasAplicadas?.value ?? ""
              }
              //datoRequerido={requerido}
            />
            <span className="mesageDown">Separe las pruebas con -</span>
          </div>
        </div>
      </section>

      <div className="project-table">
        <p className="subtitle">formación</p>
      </div>
      <span className="linea"></span>
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-input">
          <div className="content-input input-number">
            <InputNumberCustomized
              layer="Nro de Títulos:"
              placeholder="0"
              maxNumber={limit}
              onChange={handleChangeCountTitle}
              defaultValue={countTitle}
            />
          </div>
        </div>
      </section>
      {selectDataFormacion &&
        selectDataFormacion.map((title: any, index: any) => (
          <section
            className={`project-table ${hidden ? "disabled" : ""}`}
            key={title.id}
          >
            <div
              className="container-input psicolaboral"
              style={{ display: contraerModal ? "flow" : "flex" }}
            >
              <div
                className={`content-text-modal-update ${
                  contraerModal ? "w-100" : "w-25"
                }`}
              >
                <InputTextCustomized
                  layer="Título"
                  placeholder="Ingrese"
                  required={true}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChange(index, "titulo", value)
                  }
                  onPermaValues={selectDataFormacion}
                  onPropertyChange="titulo"
                  defaultValue={selectDataFormacion[index]?.titulo?.value ?? ""}
                  datoRequerido={requerido}
                />
              </div>
              <div
                className={`content-text-modal-update ${
                  contraerModal ? "w-100" : "w-25"
                }`}
              >
                <InputTextCustomized
                  layer="Institución "
                  placeholder="Ingrese"
                  required={true}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChange(index, "institucion", value)
                  }
                  onPermaValues={selectDataFormacion}
                  onPropertyChange="institucion"
                  defaultValue={
                    selectDataFormacion[index]?.institucion?.value ?? ""
                  }
                  datoRequerido={requerido}
                />
              </div>
              <div
                className={`content-text-modal-update ${
                  contraerModal ? " w-45" : "w-25"
                }`}
                style={{ float: contraerModal ? "left" : "left" }}
              >
                <DropDownListCustomized
                  layer="Tipo "
                  placeholder="Ingrese"
                  required={true}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChange(index, "tipo", value)
                  }
                  onPermaValues={selectDataFormacion}
                  onPropertyChange="tipo"
                  defaultValue={selectDataFormacion[index]?.tipo?.value ?? ""}
                  datoRequerido={requerido}
                  list={listTipoEducacion}
                />
              </div>

              <div
                className={`content-text-modal-update  ${
                  contraerModal ? " w-45" : "w-25"
                }`}
                style={{ float: contraerModal ? "right" : "right" }}
              >
                <InputDateCustomized
                  layer="Fecha de Titulación "
                  placeholder="DD/MM/AAAA"
                  required={true}
                  maxLength={10}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChange(index, "year", value)
                  }
                  onPermaValues={selectDataFormacion}
                  onPropertyChange="year"
                  defaultValue={selectDataFormacion[index]?.year?.value ?? ""}
                  datoRequerido={requerido}
                />
              </div>
              <div
                className="content-input"
                style={{ marginBlockStart: contraerModal ? "75px" : "" }}
              >
                <button
                  className="button-delete-psicolaboral"
                  onClick={() => handleRemoveSection(title)}
                >
                  X
                </button>
              </div>
            </div>
          </section>
        ))}

      <div className="project-table">
        <p className="subtitle">referencias laborales</p>
      </div>
      <span className="linea"></span>
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-input psicolaboral">
          <div className="content-input input-number">
            <InputNumberCustomized
              layer="Nro de Referencias:"
              placeholder="0"
              maxNumber={limit}
              onChange={handleChangeCountReference}
              defaultValue={countReference}
            />
          </div>
        </div>
      </section>

      {selectDataFormacionReference &&
        selectDataFormacionReference.map((ref: any, index: any) => (
          <section
            className={`project-table ${hidden ? "disabled" : ""}`}
            key={ref.id}
          >
            <div
              className="container-input psicolaboral"
              style={{ display: contraerModal ? "flow" : "flex" }}
            >
              <div
                className={`content-text-modal-update ${
                  contraerModal ? " w-45 " : "w-25"
                }`}
                style={{ float: contraerModal ? "left" : "left" }}
              >
                <InputTextCustomized
                  layer="Nombre"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChangeReference(index, "nombre", value)
                  }
                  onPermaValues={selectDataFormacionReference}
                  onPropertyChange="nombre"
                  defaultValue={
                    selectDataFormacionReference[index]?.nombre?.value ?? ""
                  }
                  datoRequerido={requerido}
                />
              </div>
              <div
                className={`content-text-modal-update ${
                  contraerModal ? " w-45 " : "w-25"
                }`}
                style={{ float: contraerModal ? "right" : "right" }}
              >
                <InputTextCustomized
                  layer="Cargo"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChangeReference(index, "cargo", value)
                  }
                  onPermaValues={selectDataFormacionReference}
                  onPropertyChange="cargo"
                  defaultValue={
                    selectDataFormacionReference[index]?.cargo?.value ?? ""
                  }
                  datoRequerido={requerido}
                />
              </div>

              <div
                className={`content-text-modal-update ${
                  contraerModal ? " w-45 " : "w-25"
                }`}
                style={{ float: contraerModal ? "left" : "left" }}
              >
                <InputTextCustomized
                  layer="Empresa"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChangeReference(index, "empresa", value)
                  }
                  onPermaValues={selectDataFormacionReference}
                  onPropertyChange="empresa"
                  defaultValue={
                    selectDataFormacionReference[index]?.empresa?.value ?? ""
                  }
                  datoRequerido={requerido}
                />
              </div>

              <div
                className={`content-text-modal-update  ${
                  contraerModal ? " w-45 " : "w-25"
                }`}
                style={{ float: contraerModal ? "right" : "right" }}
              >
                <InputTextCustomized
                  layer="Fono"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={(value: any) =>
                    handleValueChangeReference(index, "fono", value)
                  }
                  onPermaValues={selectDataFormacionReference}
                  onPropertyChange="fono"
                  defaultValue={
                    selectDataFormacionReference[index]?.fono?.value ?? ""
                  }
                  datoRequerido={requerido}
                />
              </div>

              <div
                className="content-input"
                style={{ marginBlockStart: contraerModal ? "141px" : "" }}
              >
                <button
                  className="button-delete-psicolaboral"
                  onClick={() => {
                    handleRemoveSectionReference(ref);
                  }}
                >
                  X
                </button>
              </div>
            </div>
          </section>
        ))}
    </>
  );
};
