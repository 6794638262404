import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";

interface ITablaDetenerSolicitud {
  baseContratoId: string;
  ficha: string;
  primerNombre: string;
  primerApellido: string;
  personaDNI: string;
  fechaSolicitud: string;
  selectCurrent: any;
  setSelectCurrent: Function;
}

export default (props: ITablaDetenerSolicitud) => {
  const {
    baseContratoId,
    ficha,
    primerNombre,
    primerApellido,
    fechaSolicitud,
    personaDNI,
    selectCurrent,
    setSelectCurrent,
  } = props;

  return (
    <section className="content-table-restaurar anular-documento">
      <table className="table-restaurar w-100">
        <thead className="thead-table-restaurar">
          <tr className="tr-table-restaurar anular-documento">
            <th className="td-table-restaurar">Ficha</th>
            <th className="td-table-restaurar">Nombre</th>
            <th className="td-table-restaurar">Rut</th>
            <th className="td-table-restaurar">Fecha Solicitud</th>
          </tr>
        </thead>
        <tbody className="tbody-table-restaurar">
          <tr className="tr-table-restaurar" key={ficha}>
            <td className="td-table-restaurar anular-documento">{ficha}</td>
            <td className="td-table-restaurar anular-documento">
              {primerNombre} {primerApellido}
            </td>
            <td className="td-table-restaurar anular-documento">
              {personaDNI}
            </td>
            <td className="td-table-restaurar anular-documento">
              {formatDateToDDMMAAAA({ date: fechaSolicitud, format: "/" })}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table-restaurar w-30">
        <thead className="thead-table-restaurar">
          <tr className="tr-table-restaurar">
            <th className="td-table-restaurar">Motivo</th>
          </tr>
        </thead>
        <tbody className="tbody-table-restaurar">
          <tr className="tr-table-restaurar" key={baseContratoId}>
            <td className="td-table-restaurar anular-documento">
              <InputTextCustomized
                layer=""
                placeholder=""
                required={false}
                onValueChange={setSelectCurrent}
                onPermaValues={selectCurrent}
                onPropertyChange={baseContratoId}
                defaultValue={selectCurrent[baseContratoId].value ?? ""}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
