import { useContext, useEffect, useState } from "react";
import IconDocumento from "../../../../../ui/kit-svg/icon-dashboard/graficos/IconDocumento";
import FooterTable from "../FooterTable";
import IconUser from "../../../../../ui/kit-svg/icon-dashboard/graficos/IconUser";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import {
  IUltimasSolicitudesProcesadas,
  IUltimosCandidatosValidados,
} from "../../../../../../interfaces/IOperacionesContratacion";
import { formatDateToDDMMAAAA } from "../../../../../../functions/kit-mdtw/dates";

interface ICandidatosValidados {
  objetoFilterGeneral: any;
}

const CandidatosValidados = (props: ICandidatosValidados) => {
  const { objetoFilterGeneral } = props;
  const {
    token,
    roles,
    listaUltimosCandidatosValidados,
    postUltimosCandidatosValidados,
    listaUltimasSolicitudesProcesadas,
    postUltimasSolicitudesProcesadas,
  } = useContext(OperacionContratacionContext);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 4,
  });
  const [gridList, setGridList] = useState<any[]>([]);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [formFilter, setFormFilter] = useState<any>({
    areaNegocio: [],
    analista: [],
    periodo: [],
  });

  useEffect(() => {
    if (token) {
      if (
        objetoFilterGeneral &&
        objetoFilterGeneral?.ciclo.includes("Activos")
      ) {
        postUltimasSolicitudesProcesadas({ token, filtros: formFilter });
      } else {
        postUltimosCandidatosValidados({ token, filtros: formFilter });
      }
    }
  }, [objetoFilterGeneral, token]);

  useEffect(() => {
    if (objetoFilterGeneral && objetoFilterGeneral?.ciclo.includes("Activos")) {
      if (listaUltimasSolicitudesProcesadas) {
        let arrayGridFilter = [...listaUltimasSolicitudesProcesadas];
        setLengthFilters(arrayGridFilter.length);
        if (range) {
          const { initRange, endRange } = range;
          arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
        }
        setGridList(arrayGridFilter);
      }
    } else {
      if (listaUltimosCandidatosValidados) {
        let arrayGridFilter = [...listaUltimosCandidatosValidados];
        setLengthFilters(arrayGridFilter.length);
        if (range) {
          const { initRange, endRange } = range;
          arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
        }
        setGridList(arrayGridFilter);
      }
    }
  }, [
    objetoFilterGeneral,
    listaUltimosCandidatosValidados,
    listaUltimasSolicitudesProcesadas,
    range,
  ]);

  return (
    <section className="container-table-dashboard w-65 responsiveChart">
      {objetoFilterGeneral && objetoFilterGeneral?.ciclo.includes("Activos") ? (
        <>
          <div className="content-title">
            <label className="title-table-dashboard">
              <>Últimas Solicitudes Procesadas</>
            </label>
          </div>
          <div className="content-table-dashboard ">
            <table className="table-dashboard">
              <thead>
                <tr>
                  <th></th>
                  <th>NOMBRE</th>
                  <th>FICHA</th>
                  <th>TIPO DOCUMENTO</th>
                  <th>FECHA PROCESO</th>
                </tr>
              </thead>
              <tbody>
                {gridList &&
                  gridList.map(
                    (item: IUltimasSolicitudesProcesadas, key: number) => {
                      const {
                        primerNombre,
                        primerApellido,
                        ficha,
                        tipoSolicitud,
                        fechaProceso,
                      } = item;

                      return (
                        <>
                          <tr key={key}>
                            <td>
                              <div className="content-name">
                                <IconDocumento fill="#06A502" />
                              </div>
                            </td>
                            <td>
                              {primerNombre} {primerApellido}
                            </td>
                            <td>{ficha}</td>
                            <td>{tipoSolicitud}</td>
                            <td>
                              {formatDateToDDMMAAAA({
                                date: fechaProceso,
                                format: "/",
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="content-title">
            <label className="title-table-dashboard">
              <>Últimos Candidatos Validados</>
            </label>
          </div>
          <div className="content-table-dashboard ">
            <table className="table-dashboard">
              <thead>
                <tr>
                  <th></th>
                  <th>NOMBRE</th>
                  <th>RUT</th>
                  <th>PROCESO</th>
                  <th>FECHA APROBACIÓN</th>
                </tr>
              </thead>
              <tbody>
                {gridList &&
                  gridList.map((item: IUltimosCandidatosValidados) => {
                    const {
                      primerNombre,
                      primerApellido,
                      personaDNI,
                      busquedaId,
                      clienteNombre,
                      fechaValidacion,
                    } = item;

                    return (
                      <>
                        <tr>
                          <td>
                            <div className="content-name">
                              <IconUser fill="#0065DD" />
                            </div>
                          </td>
                          <td>
                            {primerNombre} {primerApellido}
                          </td>
                          <td>{personaDNI}</td>
                          <td>
                            {busquedaId} - {clienteNombre}
                          </td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaValidacion,
                              format: "/",
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />
    </section>
  );
};

export default CandidatosValidados;
