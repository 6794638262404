import DropDownListCustomized from "../kit-mdtw/DropDownListCustomized";
import { IListaAsistencia } from "../../../interfaces/IAsistencias";
import { useEffect, useState } from "react";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";

interface IListado {
  listado?: IListaAsistencia[];
  asistenciaInterna?: boolean;
}

export default (props: IListado) => {
  const { listado, asistenciaInterna } = props;
  let busqueda: IOptionDropDownList[] | [] = [];
  let proceso: IOptionDropDownList[] | [] = [];

  if (listado) {
    busqueda = listado.map((list: any) => {
      return {
        code: `${list.personaPrimerNombre} ${list.personaSegundoNombre} ${list.personaPrimerApe} ${list.personaSegundoApe}`, 
        name: `${list.personaPrimerNombre} ${list.personaSegundoNombre} ${list.personaPrimerApe} ${list.personaSegundoApe}`, 
      };
    });
    proceso = listado.map((list: any) => {
      return {
        code: `${list.busquedaId}`, 
        name: `${list.busquedaId}`
      };
    });
  }

  return (
    <>
      {asistenciaInterna ? (
        <div className="content-filter">
          <div className="filtro-select">
            <DropDownListCustomized list={busqueda} placeholder="Buscar" />
          </div>
          <div className="filtro-select">
            <DropDownListCustomized list={proceso} placeholder="Proceso" />
          </div>
        </div>
      ) : (
        <div className="content-filter">
          <div className="filtro-select">
            <DropDownListCustomized list={busqueda} placeholder="Buscar" />
          </div>
          <div className="filtro-select">
            <DropDownListCustomized list={proceso} placeholder="Proceso" />
          </div>
        </div>
      )}
    </>
  );
};
