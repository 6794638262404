import { useRef } from "react";
interface IIconFilterOpen {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  disabled?: boolean;
}

export default (props: IIconFilterOpen) => {
  const { width, height, fill, className, disabled } = props;

  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.182569 1.07345C0.491947 0.417191 1.1482 0 1.87477 0H22.125C22.8515 0 23.5078 0.417191 23.8172 1.07345C24.1265 1.7297 24.0328 2.50315 23.5734 3.06565L18.5812 9.16883C14.8218 9.94228 11.9999 13.2657 11.9999 17.2502C11.9999 18.5345 12.2952 19.7533 12.8202 20.8361C12.7452 20.7986 12.6702 20.7518 12.5999 20.7002L9.59984 18.4502C9.22015 18.1689 8.99984 17.7236 8.99984 17.2502V13.5423L0.421634 3.06097C-0.0330578 2.50315 -0.131496 1.72502 0.182569 1.07345ZM20.2499 10.5001C22.0402 10.5001 23.7571 11.2113 25.023 12.4771C26.2888 13.743 27 15.4599 27 17.2502C27 19.0404 26.2888 20.7573 25.023 22.0232C23.7571 23.2891 22.0402 24.0002 20.2499 24.0002C18.4597 24.0002 16.7428 23.2891 15.4769 22.0232C14.211 20.7573 13.4999 19.0404 13.4999 17.2502C13.4999 15.4599 14.211 13.743 15.4769 12.4771C16.7428 11.2113 18.4597 10.5001 20.2499 10.5001ZM23.0297 15.5298C23.3203 15.2392 23.3203 14.7611 23.0297 14.4704C22.739 14.1798 22.2609 14.1798 21.9703 14.4704L20.2499 16.1908L18.5296 14.4704C18.239 14.1798 17.7609 14.1798 17.4702 14.4704C17.1796 14.7611 17.1796 15.2392 17.4702 15.5298L19.1906 17.2502L17.4702 18.9705C17.1796 19.2611 17.1796 19.7392 17.4702 20.0299C17.7609 20.3205 18.239 20.3205 18.5296 20.0299L20.2499 18.3095L21.9703 20.0299C22.2609 20.3205 22.739 20.3205 23.0297 20.0299C23.3203 19.7392 23.3203 19.2611 23.0297 18.9705L21.3093 17.2502L23.0297 15.5298Z"
        fill="#A1C0ED"
      />
    </svg>
  );
};
