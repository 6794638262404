import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ActionMassive from "./ActionMassive";
import {
  ICandidatoInhabilitado,
  ICandidatoPendiente,
} from "../../../../../interfaces/ISeleccion";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import IconPorValidarCheckProfesional from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconPorValidarCheckProfesional";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import IconCandidatoRechazado from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoRechazado";
import IconRegistroCompleto from "../../../../ui/icons-seleccion/candidatoValidado/IconRegistroCompleto";

const TablaCandidatoEnviadoValidacion = () => {
  const { listaCandidatoValidado, getCandidatoValidado, token } =
    useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridCandidatoValidado, setGridCandidatoValidado] = useState<any[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoValidado"
  );
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    fechaPostulacion: [],
    tipoProceso: ["PROFESIONAL"],
    reclutador: [],
  });

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        const copyStorageChange = JSON.parse(copyStorage);
        copyStorageChange.tipoProceso = ["PROFESIONAL"];
        getCandidatoValidado({
          token,
          filtros: copyStorageChange,
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        getCandidatoValidado({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoValidado) {
      const arrayList = listaCandidatoValidado.map(
        (item: ICandidatoInhabilitado) => item.personaDNI
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaCandidatoValidado]);

  useEffect(() => {
    if (listaCandidatoValidado) {
      let arrayGridFilter = [...listaCandidatoValidado];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoValidado(arrayGridFilter);
    }
  }, [listaCandidatoValidado, range]);

  return (
    <>
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div></div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
        />
      </section>

      <section className="content-header-table">
        <label className="title-header-table">
          Últimos Candidatos Procesados
        </label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">Proceso</th>
                      <th className="th-table-registros">Cliente</th>
                      <th className="th-table-registros">Fecha Envío</th>
                      <th className="th-table-registros">Reclutador</th>
                      <th className="th-table-registros">
                        Fecha <br></br>Validación
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoValidado &&
                      gridCandidatoValidado.map((item: ICandidatoPendiente) => {
                        const {
                          personaDNI,
                          reclutador,
                          primerNombre,
                          primerApellido,
                          estadoRegistro,
                          clienteNombre,
                          busquedaId,
                          fechaPostulacion,
                          fechaValidacion,
                        } = item;
                        return (
                          <>
                            <tr key={personaDNI} className="tr-table-registros">
                              <td className="td-table-registros">
                                <section className="content-state">
                                  {estadoRegistro === "COMPLETO" && (
                                    <>
                                      <IconPorValidarCheckProfesional fill="#06A502" />
                                      <div className="content-state-text">
                                        <label className="title registro-completo">
                                          Candidato Validado
                                        </label>
                                        <label className="message">
                                          Registro validado por<br></br> Cliente
                                        </label>
                                      </div>
                                    </>
                                  )}
                                  {estadoRegistro === "INCOMPLETO" && (
                                    <>
                                      <IconRegistroCompleto fill="#E3A000" />
                                      <div className="content-state-text">
                                        <label className="title registro-incompleto">
                                          Registro Incompleto
                                        </label>
                                        <label className="message">
                                          Datos requeridos <br></br>
                                          incompleto
                                        </label>
                                      </div>
                                    </>
                                  )}
                                  {estadoRegistro === "RECHAZADO" && (
                                    <>
                                      <IconCandidatoRechazado fill="#BE2A2A" />
                                      <div className="content-state-text">
                                        <label className="title registro-rojo">
                                          Candidato Descartado
                                        </label>
                                        <label className="message">
                                          Descartado de <br></br>proceso
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </section>
                              </td>
                              <td className="td-table-registros">
                                {primerNombre} {primerApellido}
                              </td>
                              <td className="td-table-registros">
                                {personaDNI}
                              </td>
                              <td className="td-table-registros">
                                {busquedaId}
                              </td>
                              <td className="td-table-registros">
                                {clienteNombre}
                              </td>
                              <td className="td-table-registros">
                                {formatDateToDDMMAAAA({
                                  date: fechaPostulacion,
                                  format: "/",
                                })}
                              </td>
                              <td className="td-table-registros">
                                {reclutador}
                              </td>
                              <td className="td-table-registros">
                                {formatDateToDDMMAAAA({
                                  date: fechaValidacion,
                                  format: "/",
                                })}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}
    </>
  );
};

export default TablaCandidatoEnviadoValidacion;
