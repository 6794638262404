import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import SeccionDetalleProceso from "../../Procesos/Modal/SeccionDetalleProceso";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import { ICandidatoHabilitado } from "../../../../../interfaces/ISeleccion";
import ListadoCandidatoSeleccionado from "../../RegistroSeleccionado/CandidatoSeleccionado/ListadoCandidatoSeleccionado";

interface IModalRestaurar {
  onChangeBack?: Function;
  buttonBack?: string;
  list?: any[];
  localStorageFilter: string;
  formFilter: string;
  getListRefresh: Function;
  listSelect: any;
  setListadoSeleccionado?: Function;
  getListRefreshTwo?: Function;
}

export default (props: IModalRestaurar) => {
  const {
    onChangeBack,
    buttonBack,
    list,
    getListRefresh,
    getListRefreshTwo,
    listSelect,
    localStorageFilter,
    formFilter,
    setListadoSeleccionado,
  } = props;
  const {
    token,
    listGroup,
    getProcesos,
    listProcesos,
    putAsignarProceso,
    listResultAsignaProceso,
  } = useContext(SeleccionContext);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [grupo, setGrupo] = useState<IOptionDropDownList[]>([]);
  const [proceso, setProceso] = useState<IOptionDropDownList[]>([]);
  const [formData, setFormData] = useState<any>({
    grupo: { value: undefined, valid: false },
    proceso: { value: undefined, valid: false },
  });

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    if (listResultAsignaProceso) {
      listResultAsignaProceso.splice(0, listResultAsignaProceso.length);
      listProcesos.splice(0, listProcesos.length);
    }
  }, []);

  useEffect(() => {
    if (listGroup.length > 0) {
      const grupos = listGroup.map((item: any) => {
        const { cargoGroupId, cargoGroupName } = item;
        return {
          code: cargoGroupId,
          name: cargoGroupName,
        };
      });
      setGrupo(grupos);
    }
  }, [listGroup]);

  useEffect(() => {
    if (formData.grupo?.value?.code && listGroup) {
      const arrayList = listGroup
        .filter(
          ({ cargoGroupId }: any) => cargoGroupId == formData.grupo?.value?.code
        )
        .flat()
        .map(({ busquedas }: any) => busquedas)
        .flat()
        .map((item: any) => {
          const { busquedaId, cargoMod } = item;
          return {
            code: busquedaId,
            name: busquedaId + " - " + cargoMod,
          };
        });
      setProceso(arrayList);
    }
  }, [formData.grupo?.value?.code]);

  useEffect(() => {
    if (token && formData.proceso?.value?.code) {
      const procesos = [formData.proceso.value.code] ?? [];
      getProcesos({ token, procesos });
    }
  }, [formData.proceso?.value?.code]);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickAsignarProceso = async () => {
    try {
      if (token && list && listSelect) {
        const current = listSelect
          .filter(({ personaDNI }: ICandidatoHabilitado) =>
            list.includes(personaDNI)
          )
          .map(({ personaId }: ICandidatoHabilitado) => personaId);
        await putAsignarProceso({
          token,
          personas: current,
          procesoId: formData.proceso?.value?.code,
          setIsLoading,
        });
        getListRefreshTwo && (await getListRefreshTwo({ token }));
        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          JSON.parse(copyStorage);
          await getListRefresh({
            token,
            filtros: JSON.parse(copyStorage),
          });
        } else {
          await getListRefresh({ token, filtros: formFilter });
        }

        setListadoSeleccionado && setListadoSeleccionado([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="project-table">
        <div className="process-content">
          <div className="w-25">
            <DropDownListCustomized
              list={grupo}
              layer="Grupo"
              placeholder="Seleccione"
              errorPosition="down"
              onValueChange={setFormData}
              onPermaValues={formData}
              onPropertyChange="grupo"
            />
          </div>
          <div className="w-55">
            <DropDownListCustomized
              list={proceso}
              layer="Proceso"
              placeholder="Seleccione"
              errorPosition="down"
              disabled={!formData.grupo?.value?.code}
              onValueChange={setFormData}
              onPermaValues={formData}
              onPropertyChange="proceso"
            />
          </div>
          <div className="div-button">
            <button
              disabled={!formData.proceso?.value?.code || isLoading}
              className={`button-accept ${
                !formData.proceso?.value?.code || isLoading
                  ? "disabled"
                  : "cancel"
              }`}
              onClick={handleClickAsignarProceso}
            >
              {isLoading ? "Procesando..." : "Asignar"}
            </button>
          </div>
        </div>
      </section>

      {listProcesos &&
        listProcesos.map((item: any) => {
          return (
            <SeccionDetalleProceso
              list={!formData.proceso?.value?.code ? [] : item}
              listaMasiva={false}
              soloDetalle={false}
            />
          );
        })}

      {/* <SeccionDetalleProceso list={[]} listaMasiva={false} soloDetalle={false} /> */}

      {listResultAsignaProceso && listResultAsignaProceso.length > 0 && (
        <>
          <section className="content-table-restaurar">
            <table className="table-restaurar w-90">
              <thead className="thead-table-restaurar">
                <tr className="tr-table-restaurar">
                  <th className="td-table-restaurar">Registro</th>
                  <th className="td-table-restaurar">Nombre</th>
                  <th className="td-table-restaurar">Asignó</th>
                  <th className="td-table-restaurar">Observación</th>
                </tr>
              </thead>
              <tbody className="tbody-table-restaurar">
                {listResultAsignaProceso.map((item: any) => {
                  const { personaDNI, nombre, asignado, observacion } = item;
                  return (
                    <>
                      <tr className="tr-table-restaurar">
                        <td className="td-table-restaurar">{personaDNI}</td>
                        <td className="td-table-restaurar">{nombre}</td>
                        <td
                          className={`td-table-restaurar ${
                            asignado ? "green" : "red"
                          }`}
                        >
                          {asignado ? "SI" : "NO"}
                        </td>
                        <td
                          className={`td-table-restaurar ${
                            asignado ? "green" : "red"
                          }`}
                        >
                          {observacion}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </section>
        </>
      )}

      <div className="modal-button-content center">
        <>
          <button
            className={`modal-button cancel`}
            onClick={handleClickCloseModal}
          >
            {buttonBack ? buttonBack : "Cerrar"}
          </button>
        </>
      </div>
    </>
  );
};
