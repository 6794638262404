import { useEffect, useRef, useState } from "react";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { Avatar } from "primereact/avatar";
import defaultAvatar from "../../../assets/imgs/defaultAvatar.png";
import { Link, NavLink } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Toolbar } from "primereact/toolbar";
import { useMsal } from "@azure/msal-react";
import { Menu } from "primereact/menu";
import { getMe, getPhoto } from "../../../services/MsGraphApiCall";
import ModalOption from "./ModalOption";
import { FILTER_PROFILE, LIST_ROLES } from "../../../diccionary/Diccionary";
import axios from "axios";
import { IUserAccess } from "../../../interfaces/ISeleccion";

type AppTopBarProps = {
  children?: JSX.Element | JSX.Element[];
  onToggleMenuClick: (event: React.MouseEvent) => void;
  active: string;
  profile: string;
  itemMenu?: string;
  setItemMenu?: Function;
  setItemMenuFilter?: Function;
};

type GraphData = {
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
};

const authRequest = {
  scopes: ["User.Read"],
};

const PreveTopbar: React.FC<AppTopBarProps> = ({
  itemMenu,
  setItemMenu,
  setItemMenuFilter,
}) => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const [roles, setRoles] = useState<string[] | undefined>();
  const [itemMasivo, setItemMasivo] = useState<boolean[] | undefined>(
    undefined
  );
  const [itemProf, setItemProf] = useState<boolean[] | undefined>(undefined);
  const usuarioId: string = window.sessionStorage.getItem(
    "usuarioId"
  ) as string;
  const token: string = window.sessionStorage.getItem("token") as string;
  const sessionStorageRoles: string = window.sessionStorage.getItem(
    "roles"
  ) as string;
  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  useEffect(() => {
    if (token && usuarioId) {
      getUserAccess({ token });
    }
  }, [token]);

  useEffect(() => {
    if (sessionStorageRoles) {
      setRoles(JSON.parse(sessionStorageRoles));
    }
  }, [sessionStorageRoles]);

  useEffect(() => {
    const acquireToken = () => {
      instance.acquireTokenRedirect({
        ...authRequest,
        account: instance.getActiveAccount() as AccountInfo,
      });
    };
    if (!graphData && inProgress === InteractionStatus.None) {
      getMe(instance)
        .then(setGraphData)
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) acquireToken();
        });
      getPhoto(instance)
        .then(setPhoto)
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) acquireToken();
        });
    }
  }, [instance, graphData, photo, inProgress]);

  const getUserAccess = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/usuario/${usuarioId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setItemMasivo(data.map(({ masivo }: IUserAccess) => masivo));
      setItemProf(data.map(({ profesional }: IUserAccess) => profesional));
    } catch (error) {
      console.log("Ha ocurrido algo ", { error });
    }
  };

  const menu = useRef<any>(null);

  const items = [
    {
      label: "Perfil",
      icon: "pi pi-fw pi-user",
      command: () => {},
    },
    {
      label: "Configuraciones",
      icon: "pi pi-fw pi-cog",
    },
    {
      separator: true,
    },
    {
      label: "Salir",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        handleLogout();
      },
    },
  ];

  const leftContents = () => {
    return (
      <>
        {profile &&
        [
          FILTER_PROFILE.tw_sel,
          FILTER_PROFILE.tw_admsel,
          FILTER_PROFILE.adm_tw,
          FILTER_PROFILE.admin_sel,
          FILTER_PROFILE.tw_admsel_prof,
        ].includes(profile) ? (
          <NavLink
            to="/registros/habilitados"
            className="layout-topbar-logo ml-4"
          >
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        ) : (
          ""
        )}

        {profile && profile == FILTER_PROFILE.tw_legal && (
          <NavLink
            to="/consultaexclusionrut"
            className="layout-topbar-logo ml-4"
          >
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        )}

        {profile && profile == FILTER_PROFILE.tw_cliente && (
          <NavLink to="/validacioncliente" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        )}

        {profile && profile == FILTER_PROFILE.admin_ops && (
          <NavLink to="/resumen" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        )}

        {profile && profile == FILTER_PROFILE.tw_ops && (
          <NavLink to="/contratacion" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        )}
        {profile && profile == FILTER_PROFILE.tw_contrato && (
          <NavLink to="/contratos" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </NavLink>
        )}
      </>
    );
  };

  const rightContents = () => {
    return (
      <>
        <div
          className="flex flex-direction-row"
          style={{ display: "flex", gap: "20px" }}
        >
          {profile && (
            <NavLink
              to="/consultaexclusionrut"
              className="flex align-items-center h-4rem"
            >
              <label
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                }}
              >
                Consulta de Rut
              </label>
            </NavLink>
          )}

          {(profile && profile == FILTER_PROFILE.adm_tw) ||
          profile == FILTER_PROFILE.tw_admsel ||
          profile == FILTER_PROFILE.admin_sel ? (
            <div className="flex align-items-center h-4rem ">
              <label
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Selección Masiva
                <ModalOption
                  itemMenu={itemMenu}
                  setItemMenu={setItemMenu}
                  selectionType={"masivo"}
                  setItemMenuFilter={setItemMenuFilter ?? undefined}
                />
              </label>
            </div>
          ) : (
            ""
          )}

          {(profile && profile == FILTER_PROFILE.adm_tw) ||
          profile == FILTER_PROFILE.tw_admsel_prof ||
          profile == FILTER_PROFILE.admin_sel ? (
            <div className="flex align-items-center h-4rem ">
              <label
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Selección Profesional
                <ModalOption
                  itemMenu={itemMenu}
                  setItemMenu={setItemMenu}
                  selectionType={"profesional"}
                  setItemMenuFilter={setItemMenuFilter ?? undefined}
                />
              </label>
            </div>
          ) : (
            ""
          )}

          {profile &&
            [FILTER_PROFILE.tw_sel].includes(profile) &&
            (itemProf?.includes(true) ? (
              <div className="flex align-items-center h-4rem ">
                <label
                  className=" with-menu"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "18px",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#013776",
                    cursor: "pointer",
                  }}
                >
                  Selección Profesional
                  <ModalOption
                    itemMenu={itemMenu}
                    setItemMenu={setItemMenu}
                    selectionType={"profesional"}
                    setItemMenuFilter={setItemMenuFilter ?? undefined}
                  />
                </label>
              </div>
            ) : (
              ""
            ))}

          {profile &&
            [FILTER_PROFILE.tw_sel].includes(profile) &&
            (itemMasivo?.includes(true) ? (
              <div className="flex align-items-center h-4rem ">
                <label
                  className=" with-menu"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "18px",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#013776",
                    cursor: "pointer",
                  }}
                >
                  Selección Masiva
                  <ModalOption
                    itemMenu={itemMenu}
                    setItemMenu={setItemMenu}
                    selectionType={"masivo"}
                    setItemMenuFilter={setItemMenuFilter ?? undefined}
                  />
                </label>
              </div>
            ) : (
              ""
            ))}

          {profile &&
          [
            FILTER_PROFILE.adm_tw,
            FILTER_PROFILE.tw_ops,
            FILTER_PROFILE.admin_ops,
          ].includes(profile) ? (
            <NavLink
              to="/validacion"
              className="flex align-items-center h-4rem "
            >
              <label
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Asistencia y Validación
              </label>
            </NavLink>
          ) : (
            ""
          )}

          {profile &&
          [
            FILTER_PROFILE.adm_tw,
            FILTER_PROFILE.tw_ops,
            FILTER_PROFILE.admin_ops,
          ].includes(profile) ? (
            <NavLink
              to={
                (roles && roles.includes(LIST_ROLES.JEFE_OPS)) ||
                [FILTER_PROFILE.admin_ops].includes(profile)
                  ? "/resumen"
                  : "/contratacion"
              }
              className="flex align-items-center h-4rem"
            >
              <label
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Gestión de Contratación
              </label>
            </NavLink>
          ) : (
            ""
          )}

          {profile &&
          [FILTER_PROFILE.tw_contrato, FILTER_PROFILE.adm_tw].includes(
            profile
          ) ? (
            <NavLink to="/contratos/resumen" className="flex align-items-center h-4rem">
              <label
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Contrato
              </label>
            </NavLink>
          ) : (
            ""
          )}

          <div className="flex align-items-center">
            <button
              className="p-link layout-topbar-button mx-4"
              onClick={(event) => menu.current.toggle(event)}
            >
              <Avatar
                image={photo || defaultAvatar}
                size="large"
                shape="circle"
              />
            </button>
          </div>
          <Menu model={items} popup ref={menu} id="popup_menu" />
        </div>
      </>
    );
  };

  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  //main return
  return (
    <>
      <header className="prevetopbar stickyFunction2">
        <Toolbar left={leftContents} right={rightContents} />
        <Divider type="dashed"></Divider>
      </header>
    </>
  );
};

export default PreveTopbar;
