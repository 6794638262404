interface IconStop {
    width?: string;
    height?: string;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?: boolean;
    onHover?: boolean;
  }
  
  export default (props: IconStop) => {
    const { width, height, stroke, style, className, color, fill, disabled, onHover } = props;
  
    return (
      <svg
        width={`${width ? width : "18"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 18 18"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M9 0.5C4.30444 0.5 0.5 4.30444 0.5 9C0.5 13.6956 4.30444 17.5 9 17.5C13.6956 17.5 17.5 13.6956 17.5 9C17.5 4.30444 13.6956 0.5 9 0.5ZM12.2903 11.7419C12.2903 12.0435 12.0435 12.2903 11.7419 12.2903H6.25806C5.95645 12.2903 5.70968 12.0435 5.70968 11.7419V6.25806C5.70968 5.95645 5.95645 5.70968 6.25806 5.70968H11.7419C12.0435 5.70968 12.2903 5.95645 12.2903 6.25806V11.7419Z" 
       
       fill={disabled ? '#AEAEAE' :  (onHover ? '#FAFAFA' : '#D32F2F')}
       /> 
       
      </svg>



    );
  };



