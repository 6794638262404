import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import ModalConfirm from "../Modals/ModalConfirm";
import IconNomina from "../../../../ui/kit-svg/IconNomina";

const AccionMasive = (props: any) => {
  const { dataDescarte, dataBloqueo } = props;
  const { token, getToken, listCandidatoValidadoValidacionCliente } =
    useContext(ValidationKamContext);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listCandidatoValidadoValidacionCliente.length > 0 ? true : false
  );

  useEffect(() => {
    setButtonEnabled(
      listCandidatoValidadoValidacionCliente.length > 0 ? true : false
    );
  }, [listCandidatoValidadoValidacionCliente]);

  const handleClickRestaurar = () => {
    setOpenModalRestaurar(true);
  };

  return (
    <>
      <div className="container-actions actions-old">
        <div className="content-actions-masive">
          <button
            className={`button-actions ${
              buttonEnabled ? "active-restore" : "disabled"
            }`}
            disabled={buttonEnabled ? false : true}
            onClick={() => handleClickRestaurar()}
            style={{ transform: "rotateY(180deg)" }}
          >
            <RefreshRoundedIcon />
          </button>

          <button
            className={`button-actions ${
              buttonEnabled ? "active-file" : "disabled"
            }`}
            disabled={!buttonEnabled}
          >
            <IconNomina disabled={!buttonEnabled} />
          </button>
        </div>
      </div>

      {openModalRestaurar && (
        <ModalConfirm
          modalStyle="confirm"
          modalType="restaurar"
          title="Restaurar Candidato"
          subTitle="Vas a restaurar a la lista de validación a los siguientes candidatos:"
          onChangeBack={() => setOpenModalRestaurar(false)}
          onChangeGo={() => setOpenModalRestaurar(false)}
          buttonBack="Cancelar"
          buttonGo="Aceptar"
        />
      )}
    </>
  );
};

export default AccionMasive;
