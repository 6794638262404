import { useReducer, useState } from "react";
import axios from "axios";
import { IContratos, IState } from "../../interfaces/IContratos";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import { useChangeStatus } from "../Set";
import ContratosContext from "./ContratosContext";
import ContratosReducer from "./ContratosReducer";

export default (props: IContratos) => {
  const { children } = props;
  const initialState: IState = {
    userId: sessionStorage.getItem("usuarioId") as string,
    profile: sessionStorage.getItem("profile") as string,
    roles: JSON.parse(sessionStorage.getItem("roles") as string),
    listaRegistroColaborador: [], 
    listaSolicitudesPendientes: [], 
    listaSolicitudesProcesadas: [], 
    listDocumentoColaborador: [], 
    listaDashboardResumen: [], 
    listaEstadoSolicitud: [], 
    listaUltimosContratosSolicitados: [], 
    listaUltimosRenovacionesSolicitados: [], 
    listaSolicitudProcesadaDonut: [], 
    listaSolicitudCliente: [], 
    listaSolicitudPorCliente: [], 
    subMenu: [], 
    transition: 0, 
    countAssign: [], 
  };
  const [token, setToken] = useState<string | undefined>(
    sessionStorage.getItem("token") as string
  );
  const { toasts, createToast } = useToast();

  const [state, dispatch]: any = useReducer<any>(
    ContratosReducer,
    initialState
  );

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
          sessionStorage.setItem("token", response.idToken);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const postRegistroColaborador = async (values: any) => {
    const { token, baseContratos, setIsLoading } = values;
    setIsLoading && setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}registro-colaborador`,
        {
          baseContratos,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaRegistroColaborador",
        dispatch,
        payload: data,
      });
    } catch (error) {
      createToast({
        title: "Error al Procesar Colaborador",
        message: "Por favor, intente nuevamente o inicie sesion",
        type: "danger",
      });
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const postSolicitudesPendientes = async (values: any) => {
    const { token, filtros, setIsErrorTable, setIsLoadingTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}solicitudes-contrato/pendientes`,
        {
          filtros,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaSolicitudesPendientes",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable(true); 
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const postSolicitudesProcesadas = async (values: any) => {
    const { token, filtros, setIsErrorTable, setIsLoadingTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}solicitudes-contrato/procesadas`,
        {
          filtros,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaSolicitudesProcesadas",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getDocumentoColaborador = async (values: any): Promise<void> => {
    const { personaDNI, setIsLoading, token } = values;
    setIsLoading && setIsLoading(true); 
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentos`,
        {
          params: {
            userId: state.userId,
            personaDNI: personaDNI,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      useChangeStatus({
        type: "SetListDocumentoColaborador",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false); 
    }
  };

  const putRechazarSolicitud = async (values: any) => {
    const {
      token,
      contratos,
    } = values;

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}solicitudes-contrato/rechazar`,
        { contratos: contratos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processed = result.data.results.contractUpdateStateReject;
      if (processed.length > 0) {
        createToast({
          title: "Solicitud Rechazada",
          message: "Revise lista de solicitudes pendientes",
          type: "success",
        });
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Error en rechazar solicitud",
        message: "Por favor intente realizando nuevamente",
        type: "danger",
      });
    } 
  };

  const putAprobarSolicitud = async (values: any) => {
    const { 
      token, 
      contratos, 
    } = values; 

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}solicitudes-contrato/aprobar`,
        { contratos: contratos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processed = result.data.results.contractUpdateStateApproved;
      if (processed.length > 0) {
        createToast({
          title: "Solicitud Aprobada",
          message: "Revise lista de solicitudes pendientes",
          type: "success",
        });
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Error en Aprobar solicitud",
        message: "Por favor intente realizando nuevamente",
        type: "danger",
      });
    } 
  };

  const getDashboardResumen = async (values: any) => {
    const { token, objeto, setIsLoading } = values;
    const body = objeto;
    setIsLoading && setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/general?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.contrato;
      useChangeStatus({
        type: "SetListaDashboardResumen",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const postResumenEstadoSolicitud = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/contrato-renovacion`,
        filtros,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetListaEstadoSolicitud",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postDashboardUltimosContratos = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/ultimas-solicitudes-contrato`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      useChangeStatus({
        type: "SetListaUltimosContratosSolicitados",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postDashboardUltimosRenovaciones = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/ultimas-renovaciones-contrato`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      useChangeStatus({
        type: "SetListaUltimosRenovacionesSolicitados",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  /**
   * todo: grafico de donas para la app
   */

  const postSolicitudesProcesadasDonut = async (values: any) => {
    const { token, objeto } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/resumen/solicitudes-contrato`,
        objeto,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetListaSolicitudProcesadaDonut",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postSolicitudAnalistaCliente = async (values: any) => {
    const { token, objeto } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/analista-cliente-contrato`,
        objeto,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetListaSolicitudCliente",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postSolicitudPorCliente = async (values: any) => {
    const { token, objeto } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/solicitudes-cliente`,
        objeto,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetListaSolicitudPorCliente",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  return (
    <ContratosContext.Provider
      value={{
        ...state,
        toasts,
        token,
        getToken,
        postRegistroColaborador,
        postSolicitudesPendientes,
        postSolicitudesProcesadas,
        getDocumentoColaborador,
        putRechazarSolicitud,
        putAprobarSolicitud,
        getDashboardResumen,
        postResumenEstadoSolicitud,
        postDashboardUltimosContratos, 
        postDashboardUltimosRenovaciones,
        postSolicitudesProcesadasDonut,
        postSolicitudAnalistaCliente,
        postSolicitudPorCliente, 
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};
