import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from 'react-chartjs-2';
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";

interface ISectionActive {
  token?: string | null;
  setToken?: Function;
  getToken?: Function;
  localStorageFilter?: string;
  setFormFilter?: Function;
  formFilter?: any;
  idCliente?:string;
  objetoFilterGeneral?:any;
}

const EstadosDotacion = (props: ISectionActive) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const {
    idCliente,
    localStorageFilter,
    setFormFilter,
    formFilter,
    objetoFilterGeneral,
  } = props;

  const { postResumenAnalistaCliente,getDayOfWeek,
    postResumenIngresoDotacion, listaResumenIngresoDotacion,token} = useContext(OperacionContratacionContext); 
  
  
  const [dataChart, setDataChart] = useState<[]>([]);
  const [diasChart, setDiasChart] = useState<[]>([]);
const [profile, setProfile] = useState<string|null>("");

useEffect(() => {
  if (token) {
    const profileSession = window.sessionStorage.getItem("roles");
    if (profileSession !== null) {
      setProfile(JSON.parse(profileSession));
    }
  }
 }, [token]); 

useEffect(() => {
  if (token && profile) {       

      const objeto = {
        "ingresos": false,
        "activos": true,
        "filtros": {
          "clientes": (objetoFilterGeneral && objetoFilterGeneral?.cliente[0] !== "") ? objetoFilterGeneral?.cliente  : [idCliente],
          "areaNegocio": objetoFilterGeneral?.areaNegocio,
          "analista": objetoFilterGeneral && objetoFilterGeneral?.analista,
          "periodo": {},
        },
      };

      postResumenIngresoDotacion({ token, filtros: objeto });
      
     
  }
}, [
  token, profile,
  localStorage.getItem("filterGeneralDashboard"),
  objetoFilterGeneral   
]);

useEffect(() => { 
  
  if (listaResumenIngresoDotacion && listaResumenIngresoDotacion?.dotacion ) {
    const dataChart = listaResumenIngresoDotacion?.dotacion
      .map((dotacion: { total: any; }) => dotacion);
    setDataChart(dataChart.reverse());

    const dias = listaResumenIngresoDotacion?.dotacion.map((dotacion: { fecha: any; }) => {
    const dia = getDayOfWeek(dotacion.fecha);
      if (dia === "MIÉRCOLES" || dia === "MARTES") {
        return dia.slice(0, 2);
      } else {
        return dia.slice(0, 1);
      }
    });

    setDiasChart(dias.reverse());
  }
  
 },[listaResumenIngresoDotacion])

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        maxWidth: 122,
        labels: { 
          usePointStyle: true,
          pointStyle: "circle",
         // padding: 25,
          font: { size: 11 }
        },
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Mostrar líneas de la escala en el eje X
        
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true, // Mostrar líneas de la escala en el eje X
          color: '#D4D4D4', // Color de las líneas de la escala
          lineWidth: 1, // Grosor de las líneas de la escala
          borderWidth: 2,
          borderDash: [1, 5],
        }
      },
    },
  };
  
  const labels = diasChart;
  
  const data = {
    labels,
    datasets: [
     
      {
        label: 'PRÓXIMO A VENCER',
        data: dataChart.map((item : any) => item.proximoVencimiento),
        backgroundColor: '#FF774D',
        barThickness: 25,
      },
      {
        label: 'CON BAJA CONFIR.',
        data: dataChart.map((item : any) => item.bajaConfirmada),
        backgroundColor: '#F1BFBF',
        barThickness: 25,
      }, 
      {
        label: 'VIGENTE',
        data: dataChart.map((item : any) => item.vigente),
        backgroundColor: '#CAEFC9',
        barThickness: 25,
      },
    ],
  };

  return (
    <div className="container-table-dashboard w-30 responsiveChart">
      <div className="content-title">
        <label className="title-table-dashboard">ESTADO DE DOTACIÓN</label>
      </div>
      <Bar options={options} data={data} height={250} />
    </div>
  );
};

export default EstadosDotacion;
