interface IIconAsignar {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
}

export default (props: IIconAsignar) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M0.205412 1.89428C0.5535 1.15591 1.29187 0.686523 2.10935 0.686523H24.8933C25.7107 0.686523 26.4491 1.15591 26.7972 1.89428C27.1453 2.63265 27.0398 3.50287 26.5229 4.13576L16.8767 15.9233V22.6266C16.8767 23.2648 16.5181 23.8502 15.9432 24.135C15.3683 24.4198 14.688 24.3618 14.1764 23.9767L10.801 21.4452C10.3738 21.1288 10.1259 20.6277 10.1259 20.095V15.9233L0.474389 4.13048C-0.037194 3.50287 -0.147949 2.62738 0.205412 1.89428Z"
      />
    </svg>
  );
};
