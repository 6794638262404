interface IIconEliminar {
  width?: number;
  height?: number;
  fill?: string;
  style?: any;
  className?: any;
  color?: any;
}

export default (props: IIconEliminar) => {
  const { width, height, fill, style, className, color } = props;

  return (
    <svg
      width={`${width ? width : "45"}`}
      height={`${height ? height : "46"}`}
      viewBox="0 0 45 46"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="23" r="22.5" className=""/>
      <path
        d="M16.6 31L22 25.6L27.4 31L29.5 28.9L24.1 23.5L29.5 18.1L27.4 16L22 21.4L16.6 16L14.5 18.1L19.9 23.5L14.5 28.9L16.6 31ZM22 38.5C19.925 38.5 17.975 38.106 16.15 37.318C14.325 36.531 12.7375 35.4625 11.3875 34.1125C10.0375 32.7625 8.969 31.175 8.182 29.35C7.394 27.525 7 25.575 7 23.5C7 21.425 7.394 19.475 8.182 17.65C8.969 15.825 10.0375 14.2375 11.3875 12.8875C12.7375 11.5375 14.325 10.4685 16.15 9.6805C17.975 8.8935 19.925 8.5 22 8.5C24.075 8.5 26.025 8.8935 27.85 9.6805C29.675 10.4685 31.2625 11.5375 32.6125 12.8875C33.9625 14.2375 35.031 15.825 35.818 17.65C36.606 19.475 37 21.425 37 23.5C37 25.575 36.606 27.525 35.818 29.35C35.031 31.175 33.9625 32.7625 32.6125 34.1125C31.2625 35.4625 29.675 36.531 27.85 37.318C26.025 38.106 24.075 38.5 22 38.5ZM22 35.5C25.35 35.5 28.1875 34.3375 30.5125 32.0125C32.8375 29.6875 34 26.85 34 23.5C34 20.15 32.8375 17.3125 30.5125 14.9875C28.1875 12.6625 25.35 11.5 22 11.5C18.65 11.5 15.8125 12.6625 13.4875 14.9875C11.1625 17.3125 10 20.15 10 23.5C10 26.85 11.1625 29.6875 13.4875 32.0125C15.8125 34.3375 18.65 35.5 22 35.5Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
    </svg>
  );
};
