interface IconEye {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?:boolean;
  }
  
  export default (props: IconEye) => {
    const { width, height, stroke, style, className, color, fill,disabled } = props;
  
    return (
      <svg
        width={`${width ? width : "22"}`}
        height={`${height ? height : "14"}`}
        style={style}
        color={color}
        viewBox="0 0 22 14"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
      <path d="M21.3731 6.46772C19.396 2.61006 15.4818 0 11 0C6.51817 0 2.6029 2.61188 0.626855 6.46808C0.543454 6.63306 0.5 6.81533 0.5 7.0002C0.5 7.18506 0.543454 7.36733 0.626855 7.53231C2.60399 11.39 6.51817 14 11 14C15.4818 14 19.3971 11.3881 21.3731 7.53194C21.4565 7.36696 21.5 7.18469 21.5 6.99983C21.5 6.81497 21.4565 6.6327 21.3731 6.46772ZM11 12.25C9.96165 12.25 8.94661 11.9421 8.08325 11.3652C7.21989 10.7884 6.54698 9.96842 6.14962 9.0091C5.75226 8.04979 5.64829 6.99419 5.85087 5.97579C6.05344 4.95738 6.55346 4.02192 7.28768 3.2877C8.02191 2.55347 8.95737 2.05345 9.97577 1.85088C10.9942 1.64831 12.0498 1.75228 13.0091 2.14964C13.9684 2.547 14.7883 3.2199 15.3652 4.08326C15.9421 4.94662 16.25 5.96166 16.25 7.00001C16.2503 7.68955 16.1148 8.37239 15.8511 9.0095C15.5873 9.64661 15.2006 10.2255 14.7131 10.7131C14.2255 11.2007 13.6466 11.5874 13.0095 11.8511C12.3724 12.1148 11.6895 12.2504 11 12.25ZM11 3.50001C10.6876 3.50437 10.3772 3.55085 10.0772 3.63818C10.3245 3.97421 10.4432 4.38773 10.4117 4.80374C10.3802 5.21975 10.2007 5.61071 9.9057 5.90571C9.6107 6.20072 9.21974 6.38023 8.80373 6.41171C8.38771 6.44318 7.9742 6.32452 7.63817 6.07725C7.44682 6.78221 7.48137 7.52942 7.73693 8.21371C7.9925 8.89801 8.45622 9.48493 9.06282 9.89188C9.66943 10.2988 10.3884 10.5053 11.1185 10.4822C11.8486 10.4592 12.5531 10.2077 13.1328 9.76331C13.7125 9.31889 14.1382 8.70386 14.3501 8.0048C14.562 7.30574 14.5493 6.55784 14.3138 5.86637C14.0784 5.17489 13.632 4.57467 13.0375 4.15017C12.4431 3.72568 11.7305 3.49829 11 3.50001Z" 
      fill={disabled ? '#AEAEAE' :"#0065DD"}/>
      </svg>
    );
  };
  
