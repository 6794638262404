interface IIconSolicitarCandidato {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconSolicitarCandidato) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M3.30535 4.04078C3.83318 4.04078 4.33169 3.83551 4.70557 3.46163C5.07945 3.08775 5.28472 2.58924 5.28472 2.0614C5.28472 1.53357 5.07945 1.03506 4.70557 0.661181C4.33169 0.2873 3.83318 0.0820312 3.30535 0.0820312C2.77752 0.0820312 2.27901 0.2873 1.90513 0.661181C1.53125 1.03506 1.32598 1.53357 1.32598 2.0614C1.32598 2.58924 1.53125 3.08775 1.90513 3.46163C2.27901 3.83551 2.77752 4.04078 3.30535 4.04078Z"
        />
        <path
          d="M3.80386 8.26344L5.78323 6.28406C5.85654 6.21075 5.93718 6.14478 6.01782 6.08613C5.57063 5.44833 4.8302 5.03046 3.99446 5.03046H2.6089C1.32598 5.03046 0.248319 6.02015 0.145685 7.30308H0.138354L0.0063956 8.82792C-0.0669145 9.7443 0.497573 10.558 1.31132 10.8586V14.4361C1.31132 15.2499 1.97111 15.917 2.79218 15.917H3.78187C4.60294 15.917 5.27006 15.2572 5.27006 14.4361V12.1855L3.79653 10.712C3.12207 10.0375 3.12207 8.93789 3.79653 8.26344H3.80386Z"
        />
        <path
          d="M13.7154 4.04078C14.2432 4.04078 14.7417 3.83551 15.1156 3.46163C15.4895 3.08775 15.6948 2.58924 15.6948 2.0614C15.6948 1.53357 15.4895 1.03506 15.1156 0.661181C14.7417 0.2873 14.2432 0.0820312 13.7154 0.0820312C13.1875 0.0820312 12.689 0.2873 12.3152 0.661181C11.9413 1.03506 11.736 1.53357 11.736 2.0614C11.736 2.58924 11.9413 3.08775 12.3152 3.46163C12.689 3.83551 13.1875 4.04078 13.7154 4.04078Z"
        />
        <path
          d="M16.9923 8.82059L16.8677 7.29574C16.7651 6.01282 15.6874 5.02313 14.4045 5.02313H13.0189C12.1905 5.02313 11.4428 5.441 10.9956 6.0788C11.0762 6.13744 11.1569 6.20342 11.2302 6.27673L13.2095 8.25611C13.884 8.93056 13.884 10.0302 13.2095 10.7047L11.736 12.1782V14.4288C11.736 15.2426 12.3958 15.9097 13.2169 15.9097H14.2066C15.0203 15.9097 15.6874 15.2499 15.6874 14.4288V10.8513C16.5085 10.558 17.073 9.7443 16.9923 8.82059Z"
        />
        <path
          d="M12.5059 8.95261L10.5265 6.97324C10.3066 6.76064 9.99134 6.69466 9.71276 6.81196C9.43418 6.92926 9.25824 7.2005 9.25824 7.50107V8.49076H7.75538V7.50107C7.75538 7.2005 7.57944 6.92926 7.30086 6.81196C7.02228 6.69466 6.69972 6.76064 6.48712 6.97324L4.50775 8.95261C4.21451 9.23852 4.21451 9.70771 4.50775 10.0009L6.48712 11.9803C6.70705 12.1929 7.02228 12.2589 7.30086 12.1416C7.57944 12.0243 7.75538 11.7531 7.75538 11.4525V10.4628H9.25824V11.4525C9.25824 11.7531 9.43418 12.0243 9.71276 12.1416C9.99134 12.2589 10.3139 12.1929 10.5265 11.9803L12.5059 10.0009C12.7991 9.71504 12.7991 9.24585 12.5059 8.95261Z"
        />
      </g>
    </svg>
  );
};
