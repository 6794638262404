import { Document, PDFViewer } from "@react-pdf/renderer";
import PortadaPsicolaboral from "./components/PortadaPsicolaboral";
import SinGraficoEjecutivo2 from "./components/SinGraficoEjecutivo2";
import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import { IPDFPsicolaboral } from "../../../interfaces/ISeleccion";

const SinGraficoEjecutivo = (props: IPDFPsicolaboral) => {
  const { busperid, formato, token } = props;
  const { listaReportePdfPsicolaboral, postReportePdfPsicolaboral } =
    useContext(SeleccionContext);
  const [formList, setFormList] = useState<any>({});
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [errorLoading, setErrorLoading] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (busperid && formato) {
      postReportePdfPsicolaboral({
        token,
        formato,
        busperid,
        setLoading,
        setErrorLoading,
      });
    }
  }, []);

  useEffect(() => {
    if (listaReportePdfPsicolaboral) {
      setFormList({ ...listaReportePdfPsicolaboral[busperid] });
    }
  }, [listaReportePdfPsicolaboral]);

  return (
    <>
      {!errorLoading ? (
        <>
          {loading ? (
            <div className="center-container">
              <div className="loading-container">
                <div className="loading-text">Cargando PDF</div>
                <div className="loading-dots">
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", overflow: "hidden" }}>
              <PDFViewer style={{ width: "100%", height: "100vh" }}>
                <Document>
                  <PortadaPsicolaboral formList={formList} />
                  <SinGraficoEjecutivo2 formList={formList} />
                </Document>
              </PDFViewer>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="center-container">
            <div className="loading-container">
              <div className="loading-text">Error al cargar PDF</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SinGraficoEjecutivo;
