import { useRef } from "react";
interface IIconFilter {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  disabled?: boolean;
}

export default (props: IIconFilter) => {
  const { width, height, fill, className, disabled } = props;

  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.205412 1.20776C0.5535 0.469391 1.29187 0 2.10935 0H24.8933C25.7107 0 26.4491 0.469391 26.7972 1.20776C27.1453 1.94613 27.0398 2.81635 26.5229 3.44923L16.8767 15.2367V21.9401C16.8767 22.5782 16.5181 23.1636 15.9432 23.4484C15.3683 23.7332 14.688 23.6752 14.1764 23.2902L10.801 20.7587C10.3738 20.4422 10.1259 19.9412 10.1259 19.4085V15.2367L0.474389 3.44396C-0.037194 2.81635 -0.147949 1.94085 0.205412 1.20776Z"
        fill="#A1C0ED"
      />
    </svg>
  );
};
