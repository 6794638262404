import { useEffect, useState } from "react";
import DropDownListCustomized from "../../kit-mdtw/DropDownListCustomized";
import "../../../../assets/css/asignar-proceso.min.css";

interface IModalAsignarProceso {
  token: string;
  candidates?: any[];
  listOriginal?: any[];
  listRefresh?: Function;
  listaProcesoPostulado: any;
  postProcesosPostulados: Function;
  setFormData: Function;
  formData: any;
}

export default (props: IModalAsignarProceso) => {
  const {
    token,
    candidates,
    listOriginal,
    listRefresh,
    listaProcesoPostulado,
    postProcesosPostulados,
    setFormData,
    formData,
  } = props;
  const [isLoadingProcesos, setIsLoadingProcesos] = useState<
    boolean | undefined
  >(undefined);
  const [gridCandidateSelect, setGridCandidateSelect] = useState<any>({});

  useEffect(() => {
    if (candidates && listOriginal) {
      const formSelect: any = {};
      const arrayData = listOriginal.filter(({ personaId }: any) =>
        candidates.includes(personaId)
      );
      arrayData.forEach((item: any) => {
        formSelect[item.personaId] = { value: undefined, valid: false };
      });
      setFormData(formSelect);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates && listOriginal) {
      const conjuntoUnico = new Set();
      const arrayPerson = listOriginal
        .filter(({ personaId }: any) => candidates.includes(personaId))
        .map(({ personaId, primerNombre, primerApellido, personaDNI }) => {
          const combinacion = `${personaId}${primerNombre}${primerApellido}${personaDNI}`;
          if (conjuntoUnico.has(combinacion)) {
            return null;
          }
          conjuntoUnico.add(combinacion);
          return {
            personaId,
            primerNombre,
            primerApellido,
            personaDNI,
          };
        })
        .filter(Boolean);
      setGridCandidateSelect(arrayPerson);
    }
  }, [candidates]);

  useEffect(() => {
    if (gridCandidateSelect && gridCandidateSelect.length > 0) {
      const candidatos = gridCandidateSelect.map(
        ({ personaId }: any) => personaId
      );
      try {
        if (token) {
          postProcesosPostulados({
            token,
            candidatos,
            setIsLoading: setIsLoadingProcesos,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [gridCandidateSelect]);

  const getProcessedData = (values: any) => {
    const { listaProcesoPostulado, personaId } = values;
    try {
      if (listaProcesoPostulado && personaId) {
        const processedData: string[] | [] =
          listaProcesoPostulado.processed[personaId];
        if (processedData) {
          const result: any[] = processedData.map((code) => ({
            name: code,
            code: code,
          }));
          return result;
        } else {
          return [];
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="container-table-asignar-proceso">
        <div className="content-table-asignar-proceso">
          <table className="table-asignar-proceso">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Rut</th>
                <th>Procesos postulados</th>
              </tr>
            </thead>
            <tbody>
              {gridCandidateSelect && gridCandidateSelect.length > 0 && (
                <>
                  {gridCandidateSelect.map((item: any) => {
                    const {
                      personaId,
                      primerApellido,
                      primerNombre,
                      personaDNI,
                    } = item;
                    return (
                      <tr key={personaId}>
                        <td>
                          {primerNombre} {primerApellido}
                        </td>
                        <td>{personaDNI}</td>
                        <td>
                          {!isLoadingProcesos ? (
                            <DropDownListCustomized
                              list={
                                getProcessedData({
                                  personaId,
                                  listaProcesoPostulado,
                                }) ?? []
                              }
                              placeholder="Seleccione"
                              onValueChange={setFormData}
                              onPermaValues={formData}
                              onPropertyChange={personaId}
                              required={false}
                            />
                          ) : (
                            <label>Cangando...</label>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
