import { IChangeEvent, IInputTextFilter } from "../../../interfaces/IInput";
import { formatDate, formatDateToYYYYMMDD } from "../../../functions/kit-mdtw/dates";
import { useState } from "react";
import { formatRut } from "../../../functions/kit-mdtw/rut";

export default (props: IInputTextFilter) => {
  const {
    placeholder,
    typeFilter,
    onValueChange,
    onPropertyChange,
    onPermaValues,
  } = props;
  const [valueCustomized, setValueCustomized] = useState<string>("");
  const [valid, setValid] = useState<boolean>(true);

  let dateInput: string = "";
  let rutInput: string = "";

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    setValid(true);

    if ((["text", "number"]).includes(typeFilter)) {
      return setValueCustomized(value);
    } 

    if (typeFilter == "date") {
      dateInput = formatDate({
        date: value.split("/").join("").split("-").join(""),
      });
  
      if (dateInput !== "") {
        if (
          !dateInput.match(/^([\d]{2})+([/]{1})+([\d]{2})+([/]{1})+([\d]{4})$/)
        ) {
          setValid(false);
        } else if (dateInput.length < 8) {
          setValid(false);
        }
      }
      setValueCustomized(dateInput);
    }

    if (typeFilter == "rut") {
      rutInput = formatRut({ rut: value.split("-").join("") });
      rutInput = rutInput.replace(/\./g, ""); 

      if (rutInput !== "") {
        if (
          !rutInput.match(/^[0-9]+-[0-9kK]{1}$/)
        ) {
          setValid(false);
        } else if (rutInput.length < 9) {
          setValid(false);
        }
      }
      setValueCustomized(rutInput);
    }

  };
  
  const handleChangeEventPress = (event: IChangeEvent) => {
    const { value } = event;
    
    if (onPermaValues && onPropertyChange && onValueChange) {
      if (onPropertyChange[onPermaValues].includes(value)) {
        return;
      }
      if (value !== "") {
        if (typeFilter == "date") {
          const dateInputFormat = formatDate({
            date: value.split("/").join("").split("-").join(""),
          });
          dateInput = formatDateToYYYYMMDD({date: dateInputFormat, format: "-"})
        }
        const current = { ...onPropertyChange };
        typeFilter == "date" && current[onPermaValues].push(dateInput)
        typeFilter == "text" && current[onPermaValues].push(value)
        typeFilter == "rut" && current[onPermaValues].push(value)
        typeFilter == "number" && current[onPermaValues].push(Number(value))
        onValueChange(current);
      }
    }
  };

  const handleKeyDown = (eventKey: any) => {
    const { key, target } = eventKey;
    if (key === "Enter") {
      if ((["date", "rut"]).includes(typeFilter)) {
        if (!valid) {
          return;
        }
        handleChangeEventPress({ value: target.value });
        target.value = "";
        setValueCustomized("")
        setValid(true) 
      } else {
        handleChangeEventPress({ value: target.value });
        target.value = "";
        setValueCustomized("")
        setValid(true)
      }
    }
  };

  return (
    <div className={`cols-not-required`}>
      <input
        type={typeFilter == "number" ? "number" : "text"}
        value={valueCustomized}
        onChange={({ target }) =>
        handleChangeEventError({ value: target.value })
        }
        placeholder={placeholder}
        className={`input-initial initial`}
        onKeyDown={(event) => handleKeyDown(event)}
      />
    </div>
  );
};
