import { IIconTw } from "../../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="description"
        d="M11.9 5.45H8.15V1.7V1.2H7.65H1.7H1.2V1.7V15.3V15.8H1.7H11.9H12.4V15.3V5.95V5.45H11.9ZM9.7 12.4V13.1H3.9V12.4H9.7ZM9.7 9V9.7H3.9V9H9.7ZM1.7 16.5C1.36604 16.5 1.09288 16.387 0.853353 16.1475C0.613083 15.9072 0.5 15.6337 0.5 15.3V1.7C0.5 1.36629 0.613083 1.09277 0.853353 0.852503C1.09288 0.61298 1.36604 0.5 1.7 0.5H8.29289L13.1 5.30711V15.3C13.1 15.6337 12.9872 15.9072 12.7477 16.1473C12.5075 16.387 12.2339 16.5 11.9 16.5H1.7Z"
        stroke={fill}
      />
    </svg>
  );
};
