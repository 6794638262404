import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ActionMassive from "./ActionMassive";
import { ICandidatoHabilitado } from "../../../../../interfaces/ISeleccion";
import IconRegistroCandidato from "../../../../ui/icons-seleccion/IconRegistroCandidato";
import IconAsignarProceso from "../../../../ui/icons-seleccion/IconAsignarProceso";
import IconBloquearDescartar from "../../../../ui/icons-seleccion/IconBloquear";
import ModalUpdateCandidate from "../../../../ui/kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import ModalEncuesta from "../../../Campaign/ModalEncuesta";
import ModalAsignarProceso from "../Modal/ModalAsignarProceso";
import FooterTablePagination from "../../../../ui/kit-mdtw/table/FooterTablePagination";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import NuevoCandidato from "../../../../ui/groups/NuevoCandidato";

const TablaCandidatoDisponible = () => {
  const {
    listaCandidatoDisponible,
    getCandidatoHabilitado,
    getCandidatoNoDisponible,
    token,
    getRutVerificado,
    getDocument,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [listadoSeleccionadoForPersonaId, setListadoSeleccionadoForPersonaId] =
    useState<string[]>([]);
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [personaIdForblock, setPersonaIdForblock] = useState<any[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [currentCandidatesPersonaId, setCurrentCandidatesPersonaId] = useState<
    string[]
  >([]);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  const [gridCandidatoDisponible, setGridCandidatoDisponible] = useState<any[]>(
    []
  );
  const [offset, setOffset] = useState<any>({
    offset: { value: undefined, valid: true },
    pages: 1,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoDisponible"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [paginationData, setPaginationData] = useState<any>({});
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    rut: [],
    fuenteReclutamiento: [],
    fechaPostulacion: [],
    reclutador: [],
  });

  useEffect(() => {
    listRefresh();
  }, [
    token,
    localStorage.getItem(localStorageFilter),
    offset.pages,
    offset.offset,
  ]);

  useEffect(() => {
    if (listaCandidatoDisponible) {
      const arrayList = listaCandidatoDisponible.map(
        (item: ICandidatoHabilitado) => item.personaDNI
      );
      setCurrentCandidates(arrayList);
      const arrayListPId = listaCandidatoDisponible.map(
        (item: ICandidatoHabilitado) => item.personaId
      );
      setCurrentCandidatesPersonaId(arrayListPId);
    }
  }, [listaCandidatoDisponible]);

  useEffect(() => {
    if (listaCandidatoDisponible) {
      let arrayGridFilter = [...listaCandidatoDisponible];
      setGridCandidatoDisponible(arrayGridFilter);
    }
  }, [listaCandidatoDisponible]);

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { personaDNI, personaId } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];
    const arrayListPersonaId: string[] = [...listadoSeleccionadoForPersonaId];

    if (!arrayList.includes(personaDNI)) {
      arrayList.push(personaDNI);
      arrayListPersonaId.push(personaId);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === personaDNI
      );
      arrayList.splice(position, 1);

      arrayListPersonaId.findIndex((item: string) => item === personaId);
      arrayListPersonaId.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
    setListadoSeleccionadoForPersonaId(arrayListPersonaId);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];
    const arrayListPersonaId: string[] = [...listadoSeleccionadoForPersonaId];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter(
          (personaDNI: string) => !listadoSeleccionado.includes(personaDNI)
        )
        .map((personaDNI: string) => arrayListado.push(personaDNI));

      currentCandidatesPersonaId
        .filter(
          (personaId: string) =>
            !listadoSeleccionadoForPersonaId.includes(personaId)
        )
        .map((personaId: string) => arrayListPersonaId.push(personaId));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
    setListadoSeleccionadoForPersonaId(arrayListPersonaId);
  };

  const listRefresh = () => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getCandidatoHabilitado({
          token,
          filtros: JSON.parse(copyStorage),
          setIsErrorTable,
          setIsLoadingTable,
          setPaginationData,
          page: offset.pages ?? 1,
          limit: offset.offset.value?.code ?? 10,
        });
      } else {
        getCandidatoHabilitado({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
          setPaginationData,
          page: offset.pages ?? 1,
          limit: offset.offset.value?.name ?? 10,
        });
      }
    }
  };

  const handleClickVerificarRut = (rowData: any) => {
    const { personaDNI } = rowData;
    setCandidateSelect([personaDNI]);
    getRutVerificado({
      token,
      personaRut: personaDNI,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
    });
    getDocument({ personaDNI: personaDNI, token });
  };

  const handleClickAsignarProceso = (values: any): void => {
    const { personaDNI } = values;
    setCandidateSelect([personaDNI]);
    setIsOpenModalAsignarProceso(true);
  };

  const handleClickBloquearCandidato = (values: any): void => {
    const { personaDNI, personaId } = values;
    setPersonaIdForblock(personaId);
    setCandidateSelect([personaDNI]);
    setIsOpenModalBloquear(true);
  };

  return (
    <>
      <NuevoCandidato getCandidates={listRefresh} />
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div className="content-multiple">
          <label className="title-multiple">
            <i className="selects-table-registro">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado ?? []}
          setListadoSeleccionado={setListadoSeleccionado}
          localStorageFilter={localStorageFilter}
          listRefresh={listRefresh}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
          listSelect={listaCandidatoDisponible}
        />
      </section>

      <section className="content-header-table">
        <label className="title-header-table">Candidatos Disponibles</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">
                        <button
                          onClick={handleClickAddAll}
                          className="button-select"
                        >
                          {listadoSeleccionado.length ==
                            currentCandidates.length &&
                          listadoSeleccionado.length > 0 ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </button>
                      </th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">Celular</th>
                      <th className="th-table-registros">
                        Fuente <br></br>Reclutamiento
                      </th>
                      <th className="th-table-registros">
                        Fecha Última <br></br>Postulación
                      </th>
                      <th className="th-table-registros">
                        Último <br/>Reclutador
                      </th>
                      <th className="th-table-registros">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoDisponible &&
                      gridCandidatoDisponible.map(
                        (item: ICandidatoHabilitado) => {
                          const {
                            personaId,
                            personaDNI,
                            primerNombre,
                            primerApellido,
                            fechaPostulacion,
                            fonoPrincipal,
                            fuenteReclutamiento,
                            reclutador,
                          } = item;
                          return (
                            <>
                              <tr
                                key={personaDNI}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(personaDNI) &&
                                  "checkeado"
                                }`}
                              >
                                <td className="td-table-registros">
                                  <button
                                    className="button-select"
                                    onClick={() =>
                                      handleClickAddOrRemoveChoise({
                                        personaDNI,
                                        personaId,
                                      })
                                    }
                                  >
                                    {listadoSeleccionado.includes(
                                      personaDNI
                                    ) ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </button>
                                </td>
                                <td className="td-table-registros">
                                  {primerNombre} {primerApellido}
                                </td>
                                <td className="td-table-registros">
                                  {personaDNI}
                                </td>
                                <td className="td-table-registros">
                                  {fonoPrincipal}
                                </td>
                                <td className="td-table-registros">
                                  {fuenteReclutamiento}
                                </td>

                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaPostulacion,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {reclutador}
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-icon-table">
                                    <Tooltip text="Ver Registro">
                                      <button
                                        onClick={() =>
                                          handleClickVerificarRut({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconRegistroCandidato
                                          fill={"#0065DD"}
                                        />
                                      </button>
                                    </Tooltip>

                                    <Tooltip text="Asignar Proceso">
                                      <button
                                        onClick={() =>
                                          handleClickAsignarProceso({
                                            personaDNI,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconAsignarProceso fill={"#0065DD"} />
                                      </button>
                                    </Tooltip>

                                    <Tooltip text="Bloquear">
                                      <button
                                        onClick={() =>
                                          handleClickBloquearCandidato({
                                            personaDNI,
                                            personaId,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconBloquearDescartar
                                          fill={"#BE2A2A"}
                                        />
                                      </button>
                                    </Tooltip>
                                  </section>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTablePagination
                paginationData={paginationData}
                offset={offset}
                setOffset={setOffset}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {isOpenModalUpdateCandidate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdateCandidate}
          setResultBusperId={setResultBusperId}
          token={token}
          rut={candidateSelect[0]}
          isLoadingModal={isLoading}
          getCandidates={listRefresh}
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => {
            setIsOpenModalCampaign(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}

      {isOpenModalAsignarProceso && (
        <ModalGenerico
          title="Asignar Proceso"
          width="w-70"
          subtitle="Selecciona Grupo y/o Proceso a asignar a la selección"
          onChangeBack={() => {
            setIsOpenModalAsignarProceso(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalAsignarProceso
            list={candidateSelect}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            listSelect={listaCandidatoDisponible}
            getListRefresh={getCandidatoHabilitado}
            getListRefreshTwo={getCandidatoNoDisponible}
            setListadoSeleccionado={setListadoSeleccionado}
            onChangeBack={() => {
              setIsOpenModalAsignarProceso(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
          />
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          tipo="bloqueo"
          width="w-60"
          subtitle="Estás bloqueando candidatos: "
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            setListadoSeleccionado={setListadoSeleccionado}
            getListRefresh={getCandidatoHabilitado}
            listSelect={listaCandidatoDisponible ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={candidateSelect}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaCandidatoDisponible;
