import { useEffect, useState } from "react";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IInputNumber,
} from "../../../interfaces/IInput";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ErrorIcon from "@mui/icons-material/Error";

export default (props: IInputNumber) => {
  const { required, layer, placeholder, maxNumber, onChange, defaultValue } = props;
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [valueNumber, setValueNumber] = useState<string>("");

  useEffect(() => {
    if(String(defaultValue)){
      setValueNumber(String(defaultValue));
    }
  }, [defaultValue])

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;

    validationField({ value });

    setValueNumber(value);
    if (onChange) {
      onChange(Number(value));
    }
  };

  const handleClickAddNumber = () => {
    const currentNumber: number = valueNumber !== "" ? Number(valueNumber) : 0;
    const subtractNumber: number = currentNumber + 1;

    validationField({ value: String(subtractNumber) });

    setValueNumber(String(subtractNumber));
    if (onChange) {
      onChange(subtractNumber);
    }
  };

  const handleClickSubtractNumber = () => {
    const currentNumber: number = valueNumber !== "" ? Number(valueNumber) : 0;
    const subtractNumber: number = currentNumber > 0 ? currentNumber - 1 : 0;

    validationField({ value: String(subtractNumber) });

    setValueNumber(String(subtractNumber));

    if (onChange) {
      onChange(subtractNumber);
    }
  };

  const validationField = (values: IChangeEvent) => {
    const { value } = values;
    let trigger: boolean = false;
    let eventValidation: string = "initial";

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (required) {
      if (value === "") {
        setMessageError(`${layer} es obligatorio`);
        trigger = true;
        eventValidation = "error";
      }
    }

    if (value !== "" && !trigger) {
      if (!value.match(/^[\d]{1,2}$/)) {
        setMessageError(`${layer} no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
      }
    }

    if (maxNumber && !trigger) {
      if (Number(value) > maxNumber) {
        setMessageError(
          `${layer} es mayor al máximo posible que es ${maxNumber}`
        );
        trigger = true;
        eventValidation = "error";
      }
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);
  };

  return (
    <div className={`${required && "cols-required"}`}>
      <label className="layer">{layer}</label>
      <div className="box-number">
        <button
          className="button-action-incre-decre button-remove"
          disabled={valueNumber !== "" && valueNumber !== "0" ? false : true}
          onClick={handleClickSubtractNumber}
        >
          <RemoveIcon className="svg-remove" />
        </button>
        <input
          type="number"
          placeholder={placeholder}
          className={categoriesValidation[eventValidation]}
          value={valueNumber}
          onChange={({ target }) =>
            handleChangeEventError({ value: target.value })
          }
          onBlur={({ target }) =>
            handleChangeEventError({ value: target.value })
          }
        />
        <button
          className="button-action-incre-decre"
          onClick={handleClickAddNumber}
        >
          <AddIcon className="svg-add" />
        </button>
      </div>
      {triggerError && (
        <span className="layer-error down">
          <ErrorIcon /> {messageError}
        </span>
      )}
    </div>
  );
};
