interface IconCausales {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconCausales) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "38"}`}
        height={`${height ? height : "38"}`}
        viewBox="0 0 38 38"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       
       
<rect x="0.5" width="37" height="37" rx="18.5" fill="#4BB848"/>
<g clip-path="url(#clip0_3917_76554)">
<path d="M24.8275 25.5271L22.0357 22.6152C21.868 22.4403 21.868 22.1567 22.0357 21.9817L22.6431 21.3482C22.8108 21.1732 23.0828 21.1732 23.2505 21.3482L25.1312 23.3097L29.1594 19.1083C29.3271 18.9334 29.5991 18.9334 29.7668 19.1083L30.3742 19.7418C30.5419 19.9168 30.5419 20.2004 30.3742 20.3754L25.4349 25.5271C25.2672 25.702 24.9952 25.702 24.8275 25.5271Z" fill="#FAFAFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.5238 13.2504C18.9967 13.2504 18.5693 13.6777 18.5693 14.2048V18.0087L15.6284 20.1093C15.1995 20.4157 15.1001 21.0118 15.4065 21.4408C15.7129 21.8697 16.309 21.9691 16.738 21.6627L20.0785 19.2765C20.3294 19.0974 20.4782 18.8081 20.4782 18.4999V14.2048C20.4782 13.6777 20.0509 13.2504 19.5238 13.2504Z" fill="#FAFAFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9656 11.5235C13.1126 12.4092 10.7072 16.2507 11.5929 20.1037C12.4786 23.9567 16.32 26.3621 20.173 25.4764C20.6867 25.3583 21.1989 25.6791 21.317 26.1928C21.4351 26.7065 21.1144 27.2187 20.6007 27.3368C15.7202 28.4587 10.8544 25.4118 9.73247 20.5313C8.61059 15.6509 11.6575 10.785 16.538 9.66314C21.4184 8.54125 26.2843 11.5882 27.4061 16.4686C27.5242 16.9824 27.2035 17.4945 26.6898 17.6126C26.1761 17.7307 25.6639 17.41 25.5458 16.8963C24.6601 13.0433 20.8186 10.6378 16.9656 11.5235Z" fill="#FAFAFA"/>
</g>
<defs>
<clipPath id="clip0_3917_76554">
<rect width="21" height="18.1386" fill="white" transform="translate(9.5 9.43066)"/>
</clipPath>
</defs>


</svg>

   



    );
  };
