import { useEffect, useState } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesSinGraficoXl2 } from "../styles";
import imagenPortada from "../../../../assets/images/pdfPsicolaboral/imgPortada.png";
import logoTeamworkAzul from "../../../../assets/images/pdfPsicolaboral/logoTeamworkAzul.png";
import {
  IPDFPagePortada,
  IPDFTitulosEducacion,
} from "../../../../interfaces/ISeleccion";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYY,
} from "../../../../functions/kit-mdtw/dates";
import { formatNameUpper, formatLetterCapitalize } from "../../../../functions/kit-mdtw/texts";
import { formatMoneyChilean } from "../../../../functions/kit-mdtw/moneys";

const SinGraficoXl2 = (props: IPDFPagePortada) => {
  const { formList } = props;
  const [listRender, setListRender] = useState<any>({
    primerNombre: "",
    primerApellido: "",
    personaDNI: "",
    email:"", 
    fono: "", 
    fechaNacimiento: "",
    estadoCivil: "",
    disponibilidad: "",
    reclutador: "",
    pretensionRenta: "",
  });

  useEffect(() => {
    if (formList) {
      const { contenido } = formList ?? { contenido: {} };
      const { portada } = formList ?? { portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page size="LETTER">
          <View style={stylesSinGraficoXl2.body}>
            <Image
              src={imagenPortada}
              style={stylesSinGraficoXl2.fondoHeader}
            />
            <View style={stylesSinGraficoXl2.sectionTitlePostulante}>
              <Text style={stylesSinGraficoXl2.sectionTitlePostulanteText}>
                IDENTIFICACIÓN DEL POSTULANTE
              </Text>
            </View>
            <View style={stylesSinGraficoXl2.sectionTitlePostulanteInfo}>
              <View style={stylesSinGraficoXl2.contentTitlePostulanteTitleInfo}>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Nombre:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Rut:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Fecha de Nacimiento:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Teléfono:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Mail:
                </Text>
                {listRender.email.length > 32 && (
                    <Text style={stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText}> </Text>
                  )}
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Comuna:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Estado Civil:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Disponibilidad:
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteTitleInfoText
                  }
                >
                  Pretensión De Renta:
                </Text>
              </View>
              <View
                style={stylesSinGraficoXl2.contentTitlePostulanteSeparador}
              ></View>
              <View
                style={stylesSinGraficoXl2.contentTitlePostulanteMessageInfo}
              >
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatNameUpper({texto: listRender.primerNombre})}{" "}
                  {formatNameUpper({texto: listRender.primerApellido})}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {listRender.personaDNI || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatDateToDDMMAAAA({
                    date: listRender.fechaNacimiento,
                    format: "/",
                  }) || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {listRender.fonoPrincipal || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {listRender.email.toLowerCase() || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatLetterCapitalize({texto: listRender.comuna}) || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatLetterCapitalize({texto: listRender.estadoCivil}) || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatLetterCapitalize({texto: listRender.disponibilidad}) || "-"}
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteMessageInfoText
                  }
                >
                  {formatMoneyChilean({ texto: listRender.pretensionRenta })}{".-"}
                </Text>
              </View>
            </View>
            <View>
              <View style={stylesSinGraficoXl2.contentTitlePostulanteEducacion}>
                <View
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteEducacionInfo
                  }
                >
                  <Text
                    style={
                      stylesSinGraficoXl2.contentTitlePostulanteEducacionInfoTitle
                    }
                  >
                    EDUCACIÓN
                  </Text>
                  {listRender.titulosEducacion &&
                    listRender.titulosEducacion.map(
                      (item: IPDFTitulosEducacion) => {
                        return (
                          <>
                            <View
                              style={
                                stylesSinGraficoXl2.contentTitlePostulanteEducacionInfoMessage
                              }
                            >
                              <View
                                style={
                                  stylesSinGraficoXl2.contentTitlePostulanteEducacionNombreEducacion
                                }
                              >
                                <Text
                                  style={
                                    stylesSinGraficoXl2.contentTitlePostulanteEducacionNombreEducacionText
                                  }
                                >
                                  {item.nombre ?? " "}
                                </Text>
                                <Text
                                  style={
                                    stylesSinGraficoXl2.contentTitlePostulanteEducacionNombreEducacionAge
                                  }
                                >
                                  {formatDateToYYYY({
                                    date: item.fechaEgreso,
                                  }) || "-"}
                                </Text>
                              </View>
                              <Text
                                style={
                                  stylesSinGraficoXl2.contentTitlePostulanteEducacionInfoMessageExtra
                                }
                              >
                                {item.institucion || "-"}
                              </Text>
                              <Text
                                style={
                                  stylesSinGraficoXl2.contentTitlePostulanteEducacionInfoMessageExtra
                                }
                              >
                                {formatLetterCapitalize({
                                  texto: item.tipoEducacion,
                                }) || "-"}
                              </Text>
                            </View>
                          </>
                        );
                      }
                    )}
                </View>
              </View>
              <Image
                src={logoTeamworkAzul}
                style={stylesSinGraficoXl2.logoTeamWork}
              />
              <View
                style={stylesSinGraficoXl2.contentTitlePostulanteSeparadorAzul}
              ></View>
            </View>
            <View style={stylesSinGraficoXl2.contentTitlePostulanteResena}>
              <View
                style={stylesSinGraficoXl2.contentTitlePostulanteResenaInfo}
              >
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteResenaInfoTitle
                  }
                >
                  RESEÑA INTELECTUAL, EMOCIONAL Y SOCIAL
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteResenaInfoMessage
                  }
                >
                  {listRender.reseniaIntelectual ?? " "}
                </Text>
              </View>
              <View
                style={stylesSinGraficoXl2.contentTitlePostulanteResenaInfo}
              >
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteResenaInfoTitle
                  }
                >
                  RESEÑA LABORAL
                </Text>
                <Text
                  style={
                    stylesSinGraficoXl2.contentTitlePostulanteResenaInfoMessage
                  }
                >
                  {listRender.reseniaLaboral ?? " "}
                </Text>
              </View>
            </View>
            <View style={stylesSinGraficoXl2.contentPostulanteMotivacion}>
              <View style={stylesSinGraficoXl2.contentPostulanteMotivacionInfo}>
                <Text
                  style={stylesSinGraficoXl2.contentPostulanteMotivacionTitle}
                >
                  MOTIVACIÓN AL CARGO
                </Text>
                <View
                  style={
                    stylesSinGraficoXl2.contentPostulanteMotivacionInfoMessage
                  }
                >
                  <View
                    style={
                      stylesSinGraficoXl2.contentPostulanteMotivacionColumnMessage
                    }
                  >
                    <Text
                      style={
                        stylesSinGraficoXl2.contentPostulanteMotivacionMessage
                      }
                    >
                      {listRender.motivacion ?? " "}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      )}
    </>
  );
};

export default SinGraficoXl2;
