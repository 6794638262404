import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import { STATE_CANDIDATE } from "../../../../../diccionary/Diccionary";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import ModalDetener from "./ModalDetener";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";

interface IModalConfSolicitud {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string[][];
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
  objetoForContratoNuevo?: any;
}

export default (props: IModalConfSolicitud) => {
  const {
    modalStyle,
    title,
    subTitle,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    list,
    objetoForContratoNuevo,
  } = props;
  const { token, listCandidatoPendienteContrato } =
    useContext(ValidationKamContext);

  const { listDocumentosVigentes } = useContext(OperacionContratacionContext);
  const [openModalFueraDePlazo, setOpenModalFueraDePlazo] =
    useState<boolean>(false);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);

  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);

  useEffect(() => {
    setListFueraPlazoModal([]);
    setHayFueraplazo(false);
    listCandidatoPendienteContrato.map((item: any) => listFueraPlazo(item));
  }, []);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickValidateConfirm = () => {
    typeof onChangeGo == "function" && onChangeGo();
  };

  const listFueraPlazo = (item: any) => {
    let selectedDateInicio15: number | undefined;
    let selectedDateInicio30: number | undefined;

    if (item.fechaInicio) {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      const fechaInicioContrato = item.fechaInicio;

      const [yearInicio, monthInicio, dayInicio] =
        fechaInicioContrato.split("-");
      selectedDateInicio15 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) - 15
      ).setHours(0, 0, 0, 0);

      const [yearInicio30, monthInicio30, dayInicio30] =
        fechaInicioContrato.split("-");
      selectedDateInicio30 = new Date(
        Number(yearInicio30),
        Number(monthInicio30) - 1,
        Number(dayInicio30) + 30
      ).setHours(0, 0, 0, 0);

      if (
        !(
          selectedDateInicio15 <= currentDate &&
          currentDate <= selectedDateInicio30
        )
      ) {
        setHayFueraplazo(true);
        setListFueraPlazoModal((prevList: any) => [...prevList, item]);
      }
    }
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo modalZ">
        <div className="modal-content-validatekam-Mo w-70">
          <div className="modal-title-Mo">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo">
            <span>{subTitle}</span>
          </div>

          <div className="project-table-Mo">
            <div className="container-table-Mo">
              <table className="content-table-Mo">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Ficha</th>
                    <th>División TW</th>
                    <th>Área de Negocios</th>

                    <th>Causal</th>
                    <th>Fecha de Inicio</th>
                    <th>Fecha Término</th>
                  </tr>
                </thead>
                <tbody>
                  {listDocumentosVigentes.map((item: any) => {
                    const {
                      personaDNI,
                      primerNombre,
                      primerApellido,
                      busPerStatus,
                      ficha,
                    } = item;
                    return (
                      <tr
                        key={personaDNI}
                        className={
                          listCandidatoPendienteContrato.includes(personaDNI) &&
                          (busPerStatus === STATE_CANDIDATE.VALIDADO
                            ? "validate"
                            : busPerStatus === STATE_CANDIDATE.CONFIRMADO
                            ? "selected"
                            : "selected")
                        }
                      >
                        <td>{primerNombre + " " + primerApellido}</td>
                        <td>{ficha}</td>
                        <td>{objetoForContratoNuevo?.divisionId}</td>
                        <td>{objetoForContratoNuevo?.areaNegocioId}</td>
                        <td>{objetoForContratoNuevo?.causalId}</td>
                        <td>
                          {formatDateToDDMMAAAA({
                            date: objetoForContratoNuevo?.fechaInicio,
                            format: "/",
                          })}
                        </td>
                        <td>
                          {formatDateToDDMMAAAA({
                            date: objetoForContratoNuevo?.fechaTermino,
                            format: "/",
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <>
            <div className="modal-message-Mo">
              <p className="modal-message-Mo">
                Al confirmar, se le enviará una notificación con la nomina al
                área correspondiente.
              </p>
              <p className="modal-message2-Mo">
                Para continuar presione Aceptar
              </p>
            </div>
          </>

          <div className="modal-button-content modal-button-content-in-modal center">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "confirm"
                  }`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={() => handleClickValidateConfirm()}
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
          {openModalFueraDePlazo && (
            <ModalDetener
              modalType="FueraPlazo"
              title="SOLICITUD FUERA DE PLAZO"
              subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
              buttonBack="Volver"
              onChangeBack={() => {
                setOpenModalFueraDePlazo(false);
              }}
              modalStyle={""}
              list={listFueraPlazoModal}
              onCloseModalFicha={handleClickCloseModal}
            />
          )}
        </div>
      </div>
    </>
  );
};
