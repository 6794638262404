import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaCandidatoPendiente from "./CandidatoPendiente/TablaCandidatoPendiente";
import TablaCandidatoPreSeleccion from "./CandidatoPreSeleccion/TablaCandidatoPreSeleccion";

const TablaRegistroPreseleccionado = () => {
  const {
    toasts, 
    getCandidatoPendiente, 
    getListaGeneral, 
    getExclusiones,
    getListasPerfil,
    getToken,
    token,
  } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getListaGeneral({token});
      getExclusiones({token});
      getListasPerfil({token});
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaCandidatoPendiente />
      <TablaCandidatoPreSeleccion />
    </>
  );
};

export default TablaRegistroPreseleccionado;
