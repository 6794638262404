import { useContext, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
//import "../../../../../assets/css/graficos-tw.min.css";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import { FILTER_PROFILE, LIST_ROLES } from "../../../../../../diccionary/Diccionary";

interface IGraficoDonut {
  token?: string | null;
  setToken?: Function;
  getToken?: Function;
  localStorageFilter?: string;
  setFormFilter?: Function;
  formFilter?: any;
  idCliente?: string;
  objetoFilterGeneral?: any;
  widthDonut?: number;
  heigthDonut?: number;
}

const EstadoCandidatoValidado = (props: IGraficoDonut) => {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
  const { idCliente, objetoFilterGeneral, widthDonut, heigthDonut } =
    props;

  const { postSolicitudesResumen, listaEstadosForGraficos,token, profile } = useContext(
    OperacionContratacionContext
  );

  const [ingresosForDataEtiqueta, setIngresosForDataEtiqueta] =
    useState<boolean>();
  const [activosForDataEtiqueta, setActivosForDataEtiqueta] =
    useState<boolean>();
  const [generalForDataEtiqueta, setGeneralForDataEtiqueta] =
    useState<boolean>();
  const [rol, setRol] = useState<string | null>("");

  useEffect(() => {
    if (token) {
      const rolSession = window.sessionStorage.getItem("roles");
      if (rolSession !== null) {
        setRol(JSON.parse(rolSession));
      }
    }
   }, [token]); 

  useEffect(() => {
    if (token && rol && profile) {
    
        // Actualizaciones de estado
        let generalValue = false;
        let ingresosValue = false;
        let activosValue = false;

        if (Array.isArray(rol) && rol.some(rol => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)) {
          generalValue = true;
        } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
          activosValue = true;
        } else {
          ingresosValue = true;
        }

        setGeneralForDataEtiqueta(generalValue);
        setIngresosForDataEtiqueta(ingresosValue);
        setActivosForDataEtiqueta(activosValue);

        // Crear el objeto con los valores actualizados

        const objeto = {
          ingresos: ingresosValue,
          activos: activosValue,
          general: generalValue,
          filtros: {
            clientes: [],
            areaNegocio: objetoFilterGeneral?.areaNegocio,
            analista: [],
            periodo: {},
          },
        };

        
          postSolicitudesResumen({ token, objeto });
       
      
    }
  }, [
    token, rol, profile,
    localStorage.getItem("filterGeneralDashboard"),
    objetoFilterGeneral,
    ingresosForDataEtiqueta,
    activosForDataEtiqueta,
    generalForDataEtiqueta,
  ]);

  const options = {
    responsive: false,
    cutout: "75%",
    layout: {
      padding: {
        left: 30,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right" as const,
        rtl: false,
        labels: {
          padding: 15,
        maxWidth: 100,
          usePointStyle: true,
          pointStyle: "circle",
          font: { size: 11 },
        },
      },
    },
  };

  let dataLabels;
  if (Array.isArray(rol) && rol.some(rol => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)) {
    dataLabels = [
      [" INGRESOS"],
      [" FUERA DE PLAZO"],
      [" RENOVACIONES"],
      [" BAJAS", " CONFIRMADAS"],
    ];
  } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
    dataLabels = [
      [" BAJAS ", " CONFIRMADAS"],
      [" VIGENTES"],
      [" POR VENCER EN ", "TOPE LEGAL"],
      [" PRÓXIMOS A ", " VENCER"],
    ];
  } else {
    dataLabels = [
      [" SOLICITUD DE", " INGRESO", " PENDIENTE"],
      [" INGRESO EN", " PROCESO"],
      [" SOLICITUD", " FUERA DE", " PLAZO"],
      [" SOLICITUD DE", " INGRESO", " RECHAZADA"],
    ];
  }

  const data: any = {
    labels: dataLabels,
    datasets: [],
  };

  if (Array.isArray(rol) && rol.some(rol => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)) {
    const { general, activos } = listaEstadosForGraficos ?? { general: undefined, activos: undefined };
    const { ingresos, fueraPlazo, renovaciones, bajasConfirmadas } =
      general ?? {
        ingresos: 0,
        fueraPlazo: 0,
        renovaciones: 0,
        bajasConfirmadas: 0,
      };

    data.datasets.push({
      label: "Cantidad",
      data: [
        ingresos, fueraPlazo, renovaciones, bajasConfirmadas
      ],
      backgroundColor: ["#FFCA4D", "#A1C0ED", "#53CC50", "#F67F53"],
      borderColor: ["#FFCA4D", "#A1C0ED", "#53CC50", "#F67F53"],
      borderWidth: 1,
      datalabels: {
        display: "true",
        align: "start",
        backgroundColor: "transparent",
        borderRadius: 3,
        font: {
          size: 14,
        },
        formatter: function (value: number, context: { dataIndex: string }) {
          // Calcula el porcentaje en función del valor real en los datos
          const total =
          ingresos + fueraPlazo + renovaciones + bajasConfirmadas;
          const percentage = (value / total) * 100;

          // Retorna el porcentaje formateado
          if (isNaN(percentage)) {
            return "0%";
          } else {
            return percentage.toFixed() + "%";
          }
        },
        anchor: "start",
      },
    });
  } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
    data.datasets.push({
      label: "Cantidad",
      data: [
        listaEstadosForGraficos?.activos?.bajasConfirmadas,
        listaEstadosForGraficos?.activos?.activos,
        listaEstadosForGraficos?.activos?.enTopeLegal,
        listaEstadosForGraficos?.activos?.proximoVencimiento,
      ],
      backgroundColor: ["#FFCA4D", "#53CC50", "#B0CFF4", "#F67F53"],
      borderColor: ["#FFCA4D", "#53CC50", "#B0CFF4", "#F67F53"],
      borderWidth: 1,
      datalabels: {
        display: "true",
        align: "start",
        backgroundColor: "transparent",
        borderRadius: 3,
        font: {
          size: 12,
        },
        formatter: function (value: number, context: { dataIndex: string }) {
          // Calcula el porcentaje en función del valor real en los datos
          const total =
            listaEstadosForGraficos?.activos?.bajasConfirmadas +
            listaEstadosForGraficos?.activos?.activos +
            listaEstadosForGraficos?.activos?.enTopeLegal +
            listaEstadosForGraficos?.activos?.proximoVencimiento;
          const percentage = (value / total) * 100;

          // Retorna el porcentaje formateado
          if (isNaN(percentage)) {
            return "0%";
          } else {
            return percentage.toFixed() + "%";
          }
        },
        anchor: "start",
      },
    });
  } else {
    data.datasets.push({
      label: "Cantidad",
      data: [
        listaEstadosForGraficos?.ingresos?.ingresoPendienteSolicitar,
        listaEstadosForGraficos?.ingresos?.ingresoSolicitado,
        listaEstadosForGraficos?.ingresos?.fueraPlazo,
        listaEstadosForGraficos?.ingresos?.solicitudRechazada,
      ],
      backgroundColor: ["#FFCA4D", "#FF4DAB", "#B0CFF4", "#F67F53"],
      borderColor: ["#FFCA4D", "#FF4DAB", "#B0CFF4", "#F67F53"],
      borderWidth: 1,
      datalabels: {
        display: "true",
        align: "start",
        backgroundColor: "transparent",
        borderRadius: 3,
        font: {
          size: 12,
        },
        formatter: function (value: number, context: { dataIndex: string }) {
          // Calcula el porcentaje en función del valor real en los datos
          const total =
            listaEstadosForGraficos?.ingresos?.ingresoPendienteSolicitar +
            listaEstadosForGraficos?.ingresos?.ingresoSolicitado +
            listaEstadosForGraficos?.ingresos?.fueraPlazo +
            listaEstadosForGraficos?.ingresos?.solicitudRechazada;
          const percentage = (value / total) * 100;

          // Retorna el porcentaje formateado
          if (isNaN(percentage)) {
            return "0%";
          } else {
            return percentage.toFixed() + "%";
          }
        },
        anchor: "start",
      },
    });
  }

  return (
    <div className="container-table-dashboard w-35 responsiveChart">
      <div className="content-title">
        <label
          className="title-table-dashboard"
          style={{ marginBottom: "-66px" }}
        >
          {(Array.isArray(rol) && rol.some(rol => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops))
            ? "SOLICITUDES GENERADAS"
            : objetoFilterGeneral?.ciclo[0] === "Activos"
            ? "DOTACIÓN ACTIVA"
            : "ESTADO CANDIDATOS VALIDADOS"}
        </label>
      </div>
      <Doughnut options={options} data={data} width={widthDonut} height={heigthDonut} />
    </div>
  );
};

export default EstadoCandidatoValidado;
