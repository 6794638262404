import { useContext, useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import InputDateCustomized from "../../../../ui/kit-mdtw/InputDateCustomized";
import InputTextAreaCustomized from "../../../../ui/kit-mdtw/InputTextAreaCustomized";
import IconEditSquare from "../../../../ui/kit-svg/IconEditSquare";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import { error } from "console";
import IconExclamacion from "../../../../ui/kit-svg/IconExclamacion";
import InputRutCustomized from "../../../../ui/kit-mdtw/InputRutCustomized";

interface IAreaContratacion {
  list?: any[];
  token?: string | undefined;
  getToken?: Function;
  busPerId?: any;
  formCandidate?: any;
  rut?: string;
  personaId?: string;
  setObjsendContrato?: any;
  comentario?: string;
  setFechaInicio?: any;
  setFechaTerminoRenov?: any;
  renovacion?: boolean;
  nuevoContrato?: boolean;
  editar?: boolean;
  onlyRead?: boolean;
  idCliente?: string;
  setDivisionNewContrato?: any;
  setAreaNegNewContrato?: any;
  setCodCargoNewContrato?: any;
  setLugarTrabajoNewContrato?: any;
  setTipoDocNewContrato?: any;
  setHorasTrabajdasNewContrato?: any;
  setCausalNewContrato?: any;
  setMotivoNewContrato?: any;
  setNombreNewContrato?: any;
  setRutNewContrato?: any;
  setFechaInicioNewContrato?: any;
  setFuncionesNewContrato?: any;
  setValidFechaContratacion?: any;
}

export default (props: IAreaContratacion) => {
  const {
    token,
    busPerId,
    rut,
    setObjsendContrato,
    comentario,
    setFechaInicio,
    setFechaTerminoRenov,
    renovacion,
    nuevoContrato,
    editar,
    onlyRead,
    idCliente,
    setValidFechaContratacion,
  } = props;
  const {
    getListDatosContratos,
    listCausales,
    listMotivos,
    listSucursales,
    diasPorCausales,
    listMotivosFull,
    listDivisiones,
    getListaAreasNegCliente,
    listaAreasNegCliente,
  } = useContext(ValidationKamContext);

  const { getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  const [hidden, setHidden] = useState<boolean>(true);
  const [fechaVisaVencida, setFechaVisaVencida] = useState<boolean>(false);

  const [fechaTope, setFechaTope] = useState<any>("");
  const [selectDataContrato, setSelectDataContrato] = useState<any>({
    division: { code: undefined, name: undefined },
    areaNegocio: { code: undefined, name: undefined },
    codCargo: { value: undefined },
    causal: { code: undefined, name: undefined },
    motivo: { code: undefined, name: undefined },
    sucursal: { code: undefined, name: undefined },
    horasTrabajadas: { value: undefined },
    nombre: { value: undefined },
    rut: { value: undefined },
    funciones: { undefined },
    fechaInicio: { value: undefined },
    fechaTermino: { value: undefined },
    candidatos: [busPerId],
  });
  const [diasASumarDesdeCausal, setDiasASumarDesdeCausal] =
    useState<String>("");
  const [funcionOn, setFuncionOn] = useState<boolean>(true);

  useEffect(() => {
    if (token && rut) {
      getListDatosContratos({ token });
      listaRegistroCandidato;
    }
  }, [token]);

  useEffect(() => {
    if (token && idCliente) {
      const clienteId = listaRegistroCandidato?.laboral?.clienteId;
      getListaAreasNegCliente({ token, idCliente });
      listaAreasNegCliente;
    }
  }, [token]);

  useEffect(() => {
    if (listaRegistroCandidato && listSucursales) {
      setSelectDataContrato({
        division: {
          value: {
            code: listaRegistroCandidato?.contratacion?.divisionId,
            name: listaRegistroCandidato?.contratacion?.division,
          },
        },
        areaNegocio: {
          value: {
            code: listaRegistroCandidato?.contratacion?.areaNegocioId,
            name: listaRegistroCandidato?.contratacion?.areaNegocioCod,
          },
        },
        codCargo: { value: listaRegistroCandidato?.contratacion?.codCargo },
        causal: {
          value: {
            code: listaRegistroCandidato?.contratacion?.causalId,
            name: listaRegistroCandidato?.contratacion?.causalId,
          },
        },
        motivo: { code: undefined, name: undefined },
        sucursal: {
          value: {
            code: listaRegistroCandidato?.contratacion?.SCITeamwork,
            name: listSucursales.find(
              (item: any) =>
                listaRegistroCandidato?.contratacion?.SCITeamwork === item.code
            )?.name,
          },
        },
        nombre: { value: listaRegistroCandidato?.contratacion?.nombre },
        rut: { value: listaRegistroCandidato?.contratacion?.personaDNI },
        funciones: { value: listaRegistroCandidato?.contratacion?.funciones },
        fechaInicio: {
          value: formatDateToDDMMAAAA({
            date: listaRegistroCandidato?.contratacion?.fechaInicio ?? "",
            format: "/",
          }),
        },
        fechaTermino: {
          value: formatDateToDDMMAAAA({
            date: listaRegistroCandidato?.contratacion?.fechaTermino ?? "",
            format: "/",
          }),
        },
        horasTrabajadas: {
          value: listaRegistroCandidato?.contratacion?.horasTrabajadas,
        },
        candidatos: busPerId,
      });
      if (listaRegistroCandidato?.contratacion?.fechaInicio) {
        setFechaInicio(listaRegistroCandidato?.contratacion?.fechaInicio);
      }
      if (nuevoContrato) {
        setFuncionOn(false);
      }
    }
  }, [listaRegistroCandidato, listSucursales]);

  useEffect(() => {
    if (setObjsendContrato) {
      setObjsendContrato({ ...selectDataContrato });
    }
  }, [selectDataContrato]);

  useEffect(() => {
    setFechaTerminoRenov(selectDataContrato?.fechaTermino?.value);
  }, [selectDataContrato, selectDataContrato.causal]);

  useEffect(() => {
    fechaIMasDiasCausal();
  }, [
    selectDataContrato.causal,
    selectDataContrato.fechaInicio.value,
    selectDataContrato.fechaTermino.value,
    listaRegistroCandidato,
  ]);

  const fechaIMasDiasCausal = () => {
    if (diasPorCausales && selectDataContrato.causal.value) {
      const { name: dias } = diasPorCausales.find(
        (item: any) => selectDataContrato?.causal?.value.name === item.code
      ) ?? { name: 0 };
      setDiasASumarDesdeCausal(dias);
      setFechaTope("");
      if (selectDataContrato.fechaInicio.value && dias != "0" && dias != null) {
        const fechaIniSplit = selectDataContrato.fechaInicio.value.split("/");
        const fechaInicio = new Date(
          fechaIniSplit[2] + "/" + fechaIniSplit[1] + "/" + fechaIniSplit[0]
        );
        fechaInicio.setDate(fechaInicio.getDate() + (dias - 1));

        const dia = fechaInicio.getDate();
        const mes = fechaInicio.getMonth() + 1;
        const year = fechaInicio.getFullYear();

        // Formatear los valores de día y mes a dos dígitos
        const diaFormateado = dia < 10 ? `0${dia}` : dia;
        const mesFormateado = mes < 10 ? `0${mes}` : mes;

        const formattedDate = `${year}/${mesFormateado}/${diaFormateado}`;

        setFechaTope(formattedDate);
        validarFechaTermino();

        // console.log("diasporCausal", diasASumarDesdeCausal);
        // console.log("fecha tope", formattedDate);
        // console.log("fechaTope", fechaTope);
        // console.log("fecha Termino: ", selectDataContrato.fechaTermino.value);
      }
    }
  };

  const validarFechaTermino = () => {
    if (selectDataContrato.fechaTermino.value) {
      const fechaISplit = selectDataContrato.fechaInicio.value.split("/");
      const fechaInicio = new Date(
        fechaISplit[2] + "/" + fechaISplit[1] + "/" + fechaISplit[0]
      );

      const fechaTermSplit = selectDataContrato.fechaTermino.value.split("/");
      const fechaTerm = new Date(
        fechaTermSplit[2] + "/" + fechaTermSplit[1] + "/" + fechaTermSplit[0]
      );

      const dateTope = new Date(fechaTope);
      const dateEnd = new Date(fechaTerm);
      const dateIni = new Date(fechaInicio);

      let visa = false;
      if (listaRegistroCandidato?.personal?.candidato?.fechaVencimientoVisa) {
        const dateVisa = new Date(
          listaRegistroCandidato?.personal?.candidato?.fechaVencimientoVisa
        );
        visa = dateVisa != null && dateEnd > dateVisa;
        //setFechaVisaVencida(visa)
      }
      setValidFechaContratacion(
        (fechaTope != "" ? dateEnd > dateTope : false) || dateEnd < dateIni
      );
      return (
        (fechaTope != "" ? dateEnd > dateTope : false) || dateEnd < dateIni /*||
        visa*/
      );
    }
  };

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };
  const handleClickFuncionOn = () => {
    setFuncionOn(funcionOn ? false : true);
  };

  return (
    <>
      <div className={`area-table`} onClick={() => handleClickHidden()}>
        <span>ÁREA CONTRATACIÓN</span>
        <span className="linea"></span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table container-table2 gapAreaContr">
          <div className="title">DATOS CONTRATO</div>
          <div className="content-table-rowFlex gapContentRow md:col-4 col-12 p-0 m-0">
            <span className={`title-table-rowResp md:col-9  col-6 `}>
              DIVISION TW
            </span>
            <span className={`md:col-4 col-6 p-0`}>
              {" "}
              {nuevoContrato ? (
                <DropDownListCustomized
                  required={false}
                  layer={""}
                  placeholder={"Seleccione"}
                  disabled={renovacion || onlyRead}
                  list={listDivisiones}
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  onPropertyChange="division"
                  defaultValue={{
                    code: selectDataContrato.division.value?.code,
                    name: selectDataContrato.division.value?.name,
                  }}
                />
              ) : (
                <InputTextCustomized
                  required={false}
                  layer={""}
                  placeholder={""}
                  defaultValue={
                    listaRegistroCandidato?.contratacion?.divisionId
                  }
                  disabled={true}
                />
              )}
            </span>
          </div>
          <div className="content-table-rowFlex gapContentRow md:col-4 col-12 p-0 m-0 md:pl-4">
            <span className={`title-table-rowResp md:col-8 md:ml-5 col-6 `}>
              ÁREA NEGOCIO
            </span>
            <span className={`md:col-4 md:ml-1 col-6 p-0`}>
              {" "}
              {nuevoContrato || editar ? (
                <DropDownListCustomized
                  required={false}
                  layer={""}
                  placeholder={"Seleccione"}
                  disabled={renovacion || onlyRead}
                  list={listaAreasNegCliente}
                  onValueChange={setSelectDataContrato}
                  onPermaValues={selectDataContrato}
                  onPropertyChange="areaNegocio"
                  defaultValue={{
                    code: selectDataContrato.areaNegocio.value?.code,
                    name: selectDataContrato.areaNegocio.value?.name,
                  }}
                />
              ) : (
                <InputTextCustomized
                  required={false}
                  layer={""}
                  placeholder={""}
                  defaultValue={
                    listaRegistroCandidato?.contratacion?.areaNegocioCod
                  }
                  disabled={nuevoContrato ? false : onlyRead ? true : true}
                />
              )}
            </span>
          </div>
          <div className="content-table-rowFlex gapContentRow md:col-4 col-12 p-0 m-0 md:pl-4">
            <span className={`title-table-rowResp md:col-8 md:ml-5 col-6 `}>
              CÓD. CARGO
            </span>
            <span className={`md:col-3 md:ml-0 col-6 p-0`}>
              {" "}
              <InputTextCustomized
                required={false}
                layer={""}
                placeholder={""}
                defaultValue={listaRegistroCandidato?.contratacion?.codCargo}
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                onPropertyChange="codCargo"
                disabled={
                  (listaRegistroCandidato?.contratacion?.codCargo == " "
                    ? true
                    : false) ||
                  renovacion ||
                  onlyRead
                }
              />
            </span>
          </div>

          <div className="content-table-row gapContentRow">
            <div className="title-table-rowResp md:col-3 col-6">
              <span>LUGAR DE TRABAJO</span>
            </div>
            <div className="md:col-9 col-6 p-0">
              {" "}
              <DropDownListCustomized
                required={false}
                layer={""}
                placeholder={"Seleccione"}
                disabled={renovacion || onlyRead}
                list={listSucursales}
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                onPropertyChange="sucursal"
                defaultValue={{
                  code: selectDataContrato.sucursal.value?.code,
                  name: selectDataContrato.sucursal.value?.name,
                }}
              />
            </div>
          </div>

          <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0">
            <span className="title-table-rowResp col-6">TIPO DOCUMENTO</span>
            <span className="col-6 p-0">
              {" "}
              <InputTextCustomized
                required={false}
                layer={""}
                placeholder={""}
                disabled={true}
                defaultValue={
                  listaRegistroCandidato?.contratacion?.tipoDocumento
                }
              />
            </span>
          </div>

          <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0 md:pl-4">
            <span className="title-table-rowResp col-6">HRS TRABAJADAS</span>
            <span className="col-6 p-0">
              {" "}
              <InputTextCustomized
                required={false}
                layer={""}
                placeholder={""}
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                onPropertyChange="horasTrabajadas"
                disabled={nuevoContrato ? false : onlyRead ? true : true}
                defaultValue={
                  listaRegistroCandidato?.contratacion?.horasTrabajadas
                }
              />
            </span>
          </div>
          {
            /*listaRegistroCandidato?.contratacion?.divisionId */ selectDataContrato
              ?.division?.value?.name === "TWEST" && (
              <>
                <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0">
                  <span className="title-table-rowResp col-6">CAUSAL</span>
                  <span className="col-6 p-0">
                    {" "}
                    <DropDownListCustomized
                      required={false}
                      layer={""}
                      placeholder={"Seleccione"}
                      disabled={renovacion || editar || onlyRead}
                      list={listCausales}
                      onValueChange={setSelectDataContrato}
                      onPermaValues={selectDataContrato}
                      onPropertyChange="causal"
                      defaultValue={{
                        code: selectDataContrato.causal.value?.code,
                        name: selectDataContrato.causal.value?.name,
                      }}
                    />
                  </span>
                </div>
                <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0 md:pl-4">
                  <span className="title-table-rowResp col-6">
                    MOTIVO REEMPLAZO
                  </span>
                  <span className="col-6 p-0">
                    {" "}
                    <DropDownListCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      list={
                        listMotivos
                        /* listMotivosFull.filter(
                      (item: any) => item.causalId === selectDataContrato?.causal?.value?.code
                      ).map((item:any) => { 
                        return {
                          code: item.motivoId,
                          name: item.nombre
                        }
                      })*/
                      }
                      onValueChange={setSelectDataContrato}
                      onPermaValues={selectDataContrato}
                      onPropertyChange="motivo"
                      defaultValue={
                        /*selectDataContrato.motivo?.value ?
                        {
                          code: selectDataContrato.motivo.value?.code,
                          name: listMotivosFull.find(
                            (item: any) =>
                              selectDataContrato?.motivo?.value?.code ===
                              item.code
                          )?.nombre
                        }
                      :*/
                        selectDataContrato.causal?.value
                          ? listMotivosFull
                            ? {
                                code: listMotivosFull.find(
                                  (item: any) =>
                                    selectDataContrato?.causal?.value.code ===
                                    item.causalId
                                )?.motivoId,
                                name: listMotivosFull.find(
                                  (item: any) =>
                                    selectDataContrato?.causal?.value.code ===
                                    item.causalId
                                )?.nombre,
                              }
                            : undefined
                          : listaRegistroCandidato?.contratacion?.causalId
                          ? {
                              code: listMotivosFull.find(
                                (item: any) =>
                                  listaRegistroCandidato?.contratacion
                                    ?.causalId === item.causalId
                              )?.motivoId,
                              name: listMotivosFull.find(
                                (item: any) =>
                                  listaRegistroCandidato?.contratacion
                                    ?.causalId === item.causalId
                              )?.nombre,
                            }
                          : undefined
                      }
                      //disabled={/*nuevoContrato ? false : */ onlyRead ?  true :  true}
                    />
                  </span>
                </div>

                {selectDataContrato?.causal?.value &&
                  (selectDataContrato?.causal?.value?.name === "C" ||
                    selectDataContrato?.causal?.value?.name === "A") && (
                    <>
                      <div
                        className={
                          "content-table-rowFlex gapContentRow" +
                          `${
                            selectDataContrato?.causal?.value?.name !== "C"
                              ? " md:col-6"
                              : ""
                          }` +
                          " col-12 p-0 m-0"
                        }
                      >
                        <span
                          className={
                            "title-table-rowResp " +
                            `${
                              selectDataContrato.causal.value &&
                              selectDataContrato.causal.value.name === "C"
                                ? " md:col-3"
                                : ""
                            }` +
                            " col-6 "
                          }
                        >
                          NOMBRE{" "}
                          {selectDataContrato.causal.value &&
                          selectDataContrato.causal.value.name === "C"
                            ? " PROY"
                            : ""}{" "}
                        </span>
                        <span
                          className={
                            `${
                              selectDataContrato.causal.value &&
                              selectDataContrato.causal.value.name === "C"
                                ? "md:col-9 "
                                : ""
                            }` + " col-6 "
                          }
                        >
                          {" "}
                          <InputTextCustomized
                            required={false}
                            layer={""}
                            placeholder={"Ingrese"}
                            defaultValue={
                              listaRegistroCandidato?.contratacion?.nombre
                            }
                            onValueChange={setSelectDataContrato}
                            onPermaValues={selectDataContrato}
                            onPropertyChange="nombre"
                            disabled={renovacion || editar || onlyRead}
                          />
                        </span>
                      </div>

                      {selectDataContrato.causal.value &&
                      selectDataContrato.causal.value.name === "C" ? (
                        " "
                      ) : (
                        <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0 md:pl-4">
                          <span className="title-table-rowResp col-6">RUT</span>
                          <span className="col-6 p-0">
                            {" "}
                            <InputRutCustomized
                              required={false}
                              layerInvisible={true}
                              placeholder={"Ingrese"}
                              defaultValue={
                                listaRegistroCandidato?.contratacion?.personaDNI
                              }
                              onValueChange={setSelectDataContrato}
                              onPermaValues={selectDataContrato}
                              onPropertyChange="rut"
                              disabled={renovacion || editar || onlyRead}
                              maxLength={12}
                              errorPosition="down"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}
              </>
            )
          }

          <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0">
            <span className="title-table-rowResp col-6">FECHA INICIO</span>
            <span className="col-6 p-0">
              {" "}
              <InputDateCustomized
                required={false}
                layer={""}
                placeholder={"DD/MM/AAAA"}
                disabled={renovacion || editar || onlyRead}
                maxLength={10}
                errorPosition="down"
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                defaultValue={formatDateToDDMMAAAA({
                  date: listaRegistroCandidato?.contratacion?.fechaInicio,
                  format: "/",
                })}
                onPropertyChange="fechaInicio"
              />
              <span className="">
                {validarFechaTermino() ||
                (comentario ?? "").includes("fecha") ? (
                  <p className="textErrorIcon" style={{ float: "none" }}>
                    {" "}
                    <IconExclamacion naranja={true} /> Error en Fecha
                  </p>
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>

          <div className="content-table-rowFlex gapContentRow md:col-6 col-12 p-0 m-0 md:pl-4">
            <span className="title-table-rowResp col-6">FECHA TERMINO</span>
            <span className="col-6 p-0">
              {" "}
              <InputDateCustomized
                required={
                  ((selectDataContrato.causal.value &&
                    selectDataContrato?.causal?.value.name === "A") ||
                    (selectDataContrato.causal.value &&
                      selectDataContrato?.causal?.value.name === "F")) &&
                  listaRegistroCandidato?.contratacion?.divisionId !== "TWEST"
                    ? false
                    : true
                }
                layer={""}
                placeholder={"DD/MM/AAAA"}
                disabled={editar || onlyRead ? true : false}
                maxLength={10}
                errorPosition="down"
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                defaultValue={
                  formatDateToDDMMAAAA({
                    date: listaRegistroCandidato?.contratacion?.fechaTermino,
                    format: "/",
                  }) ?? ""
                }
                onPropertyChange="fechaTermino"
              />
              <span className="">
                {validarFechaTermino() ||
                (comentario ?? "").includes("fecha") ? (
                  <>
                    <p className="textErrorIcon" style={{ float: "none" }}>
                      {" "}
                      <IconExclamacion naranja={true} /> Error en Fecha
                    </p>
                  </>
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>

          <div className="title">TURNO</div>
          <div className="content-table-row gapContentRow displayBlockResp sin-elign-center">
            <div className="title-table-rowResp md:col-3 col-12">
              <span>HORARIO</span>
            </div>
            <div className="md:col-9 col-12 p-0">
              {" "}
              <InputTextAreaCustomized
                required={false}
                layer={""}
                placeholder={""}
                defaultValue={listaRegistroCandidato?.contratacion?.horario}
                disabled={true}
              />
            </div>
          </div>

          <div className="title">FUNCIONES</div>
          <div className="content-table-row gapContentRow displayBlockResp sin-elign-center">
            <div className="title-table-rowResp md:col-3 col-12">
              <span>DESCRIPCIÓN FUNCIONES</span>
            </div>
            <div
              className={`${
                renovacion || nuevoContrato || editar ? "md:col-9 p-0" : "md:col-8 p-0"
              } col-12 p-0`}
            >
              {" "}
              <InputTextAreaCustomized
                required={false}
                layer={""}
                placeholder={""}
                defaultValue={listaRegistroCandidato?.contratacion?.funciones}
                onValueChange={setSelectDataContrato}
                onPermaValues={selectDataContrato}
                onPropertyChange="funciones"
                disabled={
                  funcionOn || renovacion || editar || onlyRead ? true : false
                }
              />
            </div>
            {!renovacion && !nuevoContrato && !editar && (
              <div className="iconSquare">
                <span onClick={() => handleClickFuncionOn()}>
                  <IconEditSquare />
                </span>
              </div>
            )}
          </div>

          <div className="col-12 mx-auto">
            {validarFechaTermino() ? (
              <p className="textError" style={{ float: "none" }}>
                * La fecha de termino de contrato no puede ser menor a la fecha
                de inicio ni mayor a la fecha tope legal para la causal.
              </p>
            ) : (
              ""
            )}
            {fechaVisaVencida ? (
              <p className="textError" style={{ float: "none" }}>
                * La fecha de vencimiento visa es menor a la fecha de termino de
                contrato.
              </p>
            ) : (
              ""
            )}
            {comentario != "" ? (
              <p className="textError" style={{ float: "none" }}>
                {comentario}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};
