import { useEffect, useState } from "react";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IFormatDate,
  IInputDate,
} from "../../../interfaces/IInput";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ErrorIcon from "@mui/icons-material/Error";
import { formatDate, validDate } from "../../../functions/kit-mdtw/dates";

export default (props: IInputDate) => {
  const {
    required,
    layer,
    placeholder,
    maxLength,
    layerLeft,
    onValueChange,
    onPropertyChange,
    onPermaValues,
    onValueError,
    errorPosition,
    defaultValue,
    disabled,
    requerido,
    datoRequerido,
    areaDocument,
    onKeyDown,
    onFocus,
    format,
    error,
    enableDateValidation, 
  } = props;

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(
    undefined
  );
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [dateCustomized, setDateCustomized] = useState<string>(
    defaultValue ?? ""
  );

  useEffect(() => {
    if (defaultValue) {
      setDateCustomized(
        formatDate({
          date: defaultValue.split("/").join("").split("-").join(""),
        })
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("initial");
      }
    }
  }, [requerido, defaultValue, dateCustomized]);

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";
    let dateInput: string = "";
    let valid: boolean = true;

    dateInput = formatDate({
      date: value.split("/").join("").split("-").join(""),
    });

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (datoRequerido) {
      if (requerido) {
        triggerRequired = value !== "" ? false : true;
        eventValidation = value !== "" ? "success" : "caution";
      }
    }

    if (required) {
      if (dateInput === "") {
        setMessageError(`Dato obligatorio`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    if (dateInput !== "") {
      if (
        !dateInput.match(/^([\d]{2})+([/]{1})+([\d]{2})+([/]{1})+([\d]{4})$/)
      ) {
        setMessageError(`no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    if (dateInput !== "" && !trigger) {
      if (!validDate({ date: dateInput })) {
        setMessageError(`no es valida como fecha`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    if(enableDateValidation) {
      const startDate = new Date();
      const inputDateParts = dateInput.split("/");
      const inputDate = new Date(
        parseInt(inputDateParts[2]),
        parseInt(inputDateParts[1]) - 1,
        parseInt(inputDateParts[0])
      );
  
      if (inputDate < startDate) {
        setMessageError(`La fecha debe ser mayor`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    setEventValidation(eventValidation);
    setTriggerRequerido(triggerRequired);
    setTriggerError(trigger);

    setDateCustomized(dateInput);

    if (onPermaValues && onValueChange && onPropertyChange) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: dateInput,
        valid,
      };
      onValueChange(permaValues);
    }

    if (onValueChange && !onPermaValues && !onPropertyChange) {
      onValueChange(dateInput);
      onValueError && onValueError(valid);
    }
  };

  return (
    <div
      className={`${required ? "cols-required" : "cols-not-required"}`}
      style={layerLeft ? { display: "flex" } : {}}
    >
      {layer && (
        <>
          <div style={{ display: "flex" }}>
            <label className="layer">{layer}</label>
            {triggerError && ["up", undefined].includes(errorPosition) && (
              <span className="layer-error">{messageError}</span>
            )}
            {triggerRequerido && ["up", undefined].includes(errorPosition) && (
              <span className="layer-caution">
                <ErrorIcon />
                Dato Requerido
              </span>
            )}
          </div>
        </>
      )}
      <div className="box-datepicker">
        <input
          type="text"
          placeholder={placeholder}
          className={
            error ? "input-error" : categoriesValidation[eventValidation]
          }
          maxLength={maxLength}
          value={dateCustomized}
          disabled={disabled}
          onChange={({ target }) =>
            handleChangeEventError({ value: target.value })
          }
          // onBlur={({ target }) =>
          //   handleChangeEventError({ value: target.value })
          // }
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        />
        <CalendarTodayIcon />
      </div>
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error down">{messageError}</span>
      )}
      {triggerRequerido &&
        errorPosition === "down" &&
        !disabled &&
        !areaDocument && (
          <span className="layer-caution down">
            <ErrorIcon />
            Dato Requerido
          </span>
        )}
    </div>
  );
};
