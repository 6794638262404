import { ISetRedux } from "../../interfaces/IOperacionesContratacion";

export const setCountAssign = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetCountAssign",
    payload,
  });
};

export const setListaCliente = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCliente",
    payload,
  });
};

export const setListaAreaNegocio = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaAreaNegocio",
    payload,
  });
};

export const setListaUsuarioAnalista = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaUsuarioAnalista",
    payload,
  });
};

export const setListaDocumentosVigentes = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaDocumentosVigentes",
    payload,
  });
};

export const setListaBajasConfirmadas = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaBajasConfirmadas",
    payload,
  });
};

export const setListaDocumentosSolicitados = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaDocumentosSolicitados",
    payload,
  });
};

export const setListaBajasCalculadas = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaBajasCalculadas",
    payload,
  });
};

export const setListaNoProcesados = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaNoProcesados",
    payload,
  });
};

export const setListaEstadisticaResumenGeneral = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaEstadisticaResumenGeneral",
    payload,
  });
};

export const setListaIngresosProcesados = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaIngresosProcesados",
    payload,
  });
};

export const setListaUltimosCandidatosValidados = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaUltimosCandidatosValidados",
    payload,
  });
};

export const setListaEstadosForGrafic = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaEstadosForGrafic",
    payload,
  });
};

export const setListaResumenEstadosOperaciones = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaResumenEstadosOperaciones",
    payload,
  });
};

export const setListaAnalistaCliente = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaAnalistaCliente",
    payload,
  });
};

export const setListaIngresoDotacion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaIngresoDotacion",
    payload,
  });
};

export const setListaUltimasSolicitudesProcesadas = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaUltimasSolicitudesProcesadas",
    payload,
  });
};

export const setListasUltimasBajasProcesadas = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListasUltimasBajasProcesadas",
    payload,
  });
};

