import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputText } from "primereact/inputtext";
import InputDateCustomized from "../../../ui/kit-mdtw/InputDateCustomized";
import IconCheckfila from "../../../ui/kit-svg/IconCheckfila";
import {
  IListaCandidatos,
  Params,
} from "../../../../interfaces/IValidationKam";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYYMMDD,
} from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";

interface IDevolver {
    modalStyle: string;
    modalType?: string;
    title: string;
    subTitle?: string;
    message?: string;
    onChangeBack?: Function;
    onChangeGo?: Function;
    onChange?: Function;
    buttonBack?: string;
    buttonGo?: string;
    list?: any;
    token?: string | undefined;
    setToken?: Function;
    getToken?: Function;
    rut?: string;
    busPerId?: string;
    idCliente?: string;
    onCloseModalFicha?: Function;
    listRefresh?: Function;
  
  }
  
  export default (props: IDevolver) => {
    const {
      modalStyle,
      modalType,
      title,
      subTitle,
      message,
      onChangeBack,
      onChangeGo,
      onChange,
      buttonBack,
      buttonGo,
      list,
      rut,
      busPerId,
      onCloseModalFicha,
      listRefresh
    } = props;
    const {
        restaurarCandidato,
    } = useContext(ValidationKamContext);

    const { token, getToken, setToken} =
    useContext(SeleccionContext);
  
    useEffect(() => {
        getToken();
    }, []);
  
    const { idCliente } = useParams<Params>();
    const [checkAplyAll, setCheckAplyAll] = useState<boolean>(false);
    const [inputForAll, setInputForAll] = useState<any>({
      text: { value: undefined },
    });
    const [inputForInd, setInputForInd] = useState<any>({
      text: { value: undefined },
    });
  
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
    const [objetoData, setObjtoData] = useState<any>("");
    const objetoSendFiltro = {};
  
   
  
    useEffect(() => {
      // Mapear la lista y generar los objetos
      if (list) { 
        const mappedDataObjects = list.map((item: IListaCandidatos) => {
          const {
            busPerId,
            areaNegocioId,
            areaNegocioCod,
            ficha,
            primerNombre,
            segundoNombre,
            primerApellido,
            division,
            estadoContrato,
            tipoDocumentoValue,
            causal,
          } = item;
  
          if (inputForAll.text.value == undefined) {
            setCheckAplyAll(false);
          }
  
          let motivoSolicitudValue = "";
          if (inputForAll.text.value !== undefined && checkAplyAll) {
            motivoSolicitudValue = inputForAll.text.value;
          } else {
            motivoSolicitudValue = inputForInd[busPerId] ?? "";
          }
  
          return {
            busPerId: busPerId,
            motivo: motivoSolicitudValue,
          };
        });
        setObjtoData(mappedDataObjects);
      }
    }, [list, inputForAll.text.value, checkAplyAll, onChange, inputForInd]);
 
    const handleClickCloseModal = () => {
      typeof onChangeBack == "function" && onChangeBack();
    };
  
    const handleClickCloseModalFicha = () => {
      typeof onCloseModalFicha == "function" && onCloseModalFicha();
    };
  
    const handleClickCheck = () => {
      setCheckAplyAll(checkAplyAll ? false : true);
    };
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let { name, value } = event.target;
      setCheckAplyAll(false);
  
      if (value === "") {
        setInputForInd((prevInput: any) => ({ ...prevInput, [name]: "" }));
      } else {
        setInputForInd((prevInput: any) => ({ ...prevInput, [name]: value }));
      }
    };
  
    const handleClickDevolver = async (objetoData: any) => {
       await restaurarCandidato({
            objetoData,
            idCliente,
            token,
            objetoSendFiltro,
          });
      handleClickCloseModal();
      listRefresh &&  listRefresh()
    };
  
    const handleClickRestaurarGo = () => {
        
      };
  
    const busqValidInputDet = () => {
      let validInput = 0;
      objetoData &&
        objetoData.forEach((value: any) =>
          value.motivo == "" ? validInput++ : validInput
        );
      return objetoData.length > 0 && validInput == 0;
    };
  
  
    return (
      <>
        <div className="modal-container-validatekam-Mo ">
          <div className="modal-content-validatekam-Mo w-75">
            <div className="modal-title-Mo">
              <span
                className="modal-close"
                onClick={() =>
                  typeof onChangeBack == "function" && onChangeBack()
                }
              >
                <CloseIcon />
              </span>
              <span>{title}</span>
            </div>
            <div className="modal-subtitle-Mo px-5 md:text-center">
              <span>{subTitle}</span>
            </div>
  
            <div className="col-12 md:flex" style={{ alignItems: "center" }}>
              <div
                className="col-12 md:col-9 md:mx-3 md:pl-1"
                style={{ float: "left" }}
              >
                <InputTextCustomized
                  className="p-0 "
                  required={undefined}
                  layer={"Motivo de retroceso"}
                  placeholder={""}
                  onValueChange={setInputForAll}
                  onPermaValues={inputForAll}
                  onPropertyChange="text"
                />
              </div>
  
              <div
                className="col-12 md:col-3 align-items-center flex justify-content-center"
                style={{ float: "left" }}
              >
                {checkAplyAll ? (
                  <span
                    onClick={() => {
                      if (inputForAll.text.value !== undefined) {
                        handleClickCheck();
                      }
                    }}
                  >
                    <IconCheckfila className="md:ml-0 md:mt-6 pl-1 mr-3 classSvg" />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      if (inputForAll.text.value !== undefined) {
                        handleClickCheck();
                      }
                    }}
                  >
                    <CheckBoxOutlineBlankIcon className="md:ml-0 md:mt-6 classSvg" />
                  </span>
                )}
  
                <span className="textAsig md:mt-6">Asignar a todos</span>
              </div>
            </div>
  
            {list &&
              list.map((item: IListaCandidatos) => {
                const {
                  busPerId,
                  areaNegocioCod,
                  ficha,
                  nombre,
                  personaDni,
                  primerNombre,
                  primerApellido,
                  division,
                  fechaValidacion,
                } = item;
  
                return (
                  <div className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5">
                    <div className="col-12 md:col-7 m-0 p-0 border-solid border-1 borderColorWhite">
                      <div className="row flex flex-column md:flex-row">
                       
                        <div
                        className={`col-12 ${ "md:col-3"} p-0 mx-0`}
                      >
                        <div
                          className={`col-6 border-bottom-2 md:col-12 bg-headerGrey2 text-center borderColorGrey responsiveDivDetener
                        ${"headerHeight2"}`}
                        >
                          {"Nombre"}
                        </div>
                        <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorWhite textNormal contentHeight2 borderdashed">
                          {primerNombre.toLowerCase() + "  "+ primerApellido.toLowerCase()}
                        </div>
                      </div>

                      <div className="col-12 md:col-3 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey2 text-center borderColorGrey responsiveDivDetener
                          ${"headerHeight2"}`}
                            >
                              Rut
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorWhite responsiveDivDetener textNormal contentHeight2 borderdashed">
                              {personaDni ?? "-"}
                            </div>
                          </div>

                          <div className="col-12 md:col-3 p-0 mx-0">
                            <div className="col-6 md:col-12 bg-headerGrey2 text-center border-bottom-2 borderColorGrey responsiveDivDetener headerHeight2">
                              Fecha De Envío
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorWhite responsiveDivDetener contentHeight2 borderdashed">
                              {formatDateToDDMMAAAA({ date: fechaValidacion, format: "/" })?? "-"}
                            </div>
                          </div>

                      <div className="col-12 md:col-3 p-0 mx-0">
                            <div
                              className={`col-6 border-bottom-2 md:col-12 bg-headerGrey2 text-center borderColorGrey responsiveDivDetener
                          ${"headerHeight2"}`}
                            >
                              Área de Negocios
                            </div>
                            <div className="col-6 md:col-12 text-center borderColorWhite responsiveDivDetener textNormal contentHeight2 borderdashed">
                              {areaNegocioCod ?? "-"}
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    <div className="col-12 md:col-5 m-0 md:px-4 p-0">
                      <div className="row">
                        <div className="col-12 mx-0 p-0">
                          <div
                            className={`col-6 md:border-solid md:border-1 md:col-12 bg-headerGrey2 text-center borderColorGrey responsiveDivDetener
                         headerHeight2`}
                          >
                            Motivo 
                          </div>
                          <div className="col-6 md:col-12 p-0 text-center borderColorGrey responsiveDivDetener ">
                            <InputText
                              id={`arrMotivo-${busPerId}`}
                              type="text"
                              className="col-12 border-noround text-center"
                              style={{
                                fontSize: "15px",
                                height: "28px",
                                border: "white",
                                borderBottom: "1px dashed #ccc"
                              }}
                              value={
                                checkAplyAll &&
                                inputForAll.text.value !== undefined
                                  ? inputForAll.text.value
                                  : inputForInd[busPerId] || ""
                              }
                              onChange={handleInputChange} // Añadimos el event handler
                              name={busPerId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
  
            
              <>
                <div className="modal-message-Mo">
                  <p className="modal-message-Mo">
                  Al confirmar, los datos desaparecerán de su lista de dotación. Para continuar presione Continuar
                  </p>
                </div>
              </>
            
  
            <div className="modal-button-content modal-button-content-in-modal center">
              <>
                <button
                  className={`modal-button cancel`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    busqValidInputDet() ||
                    (inputForAll.text.value !== undefined && checkAplyAll)
                      ? "confirm"
                      : "disabled"
                  }`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={() => handleClickDevolver(objetoData)
                      
                  }
                  disabled={
                    busqValidInputDet() || inputForAll.text.value !== undefined
                      ? false
                      : true
                  }
                >
                  {" "}
                  {"Devolver Candidato"}
                </button>
              </>
            </div>
          </div>
        </div>
      </>
    );
  };