import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import DropDownListCustomized from "../../ui/kit-mdtw/DropDownListCustomized";
import IconArrowDoubleLeft from "../../ui/kit-svg/IconArrowDoubleLeft";
import IconArrowDoubleRight from "../../ui/kit-svg/IconArrowDoubleRight";

interface IFooterTable {
  length: number;
  setRange: Function;
  pageCurrent?: number;
}
export default function FooterTable(props: IFooterTable) {
  const { length, setRange, pageCurrent } = props;
  const [page, setPage] = useState<number>(1);
  const [initRange, setInitRange] = useState<number>(0);
  const [endRange, setEndRange] = useState<number>(0);
  const [pages, setPages] = useState<number>(1);
  const [offset, setOffset] = useState<any>({
    // offset: { value: { code: "1", name: "1" }, valid: true},
    offset: { value: undefined, valid: true},
  });

  useEffect(() => { 
    const { offset: currentOffset } = offset;
    const {value} = currentOffset;

    const rowsForPage: number = Number(value?.code ?? 10); 

    const initRangeCurrent: number = page * rowsForPage - rowsForPage + 1;
    const endRangeCurrent: number = (page * rowsForPage < length) ? page * rowsForPage : length;

    setInitRange(initRangeCurrent);
    setEndRange(endRangeCurrent);
    
    setPages(parseInt(String(length / rowsForPage + (length % rowsForPage > 0 ? 1 : 0 ))));
    setRange({ initRange: initRangeCurrent- 1, endRange: endRangeCurrent });
  }, [offset, page, length]);

  useEffect(() => {
    if(pageCurrent){
      setPage(pageCurrent);
    }
  }, [pageCurrent])

  return (
    <>
    <div className="footer-table">
      <div className="pagination">
        <label className="pagination-text">Filas por página</label>
        <div style={{ width: "80px", margin: "0px 10px" }}>
          <DropDownListCustomized
            placeholder={"10"}
            list={[{ name: "10", code: "10" },{ name: "5", code: "5" }]}
            onPermaValues={offset}
            onValueChange={setOffset}
            onPropertyChange="offset"
          />
        </div>
        <label className="pagination-text">{initRange} - {endRange} de {length}</label>
      </div>
      
      <div className="pagination-number">
        <button onClick={() => setPage(1)} disabled={page < 2} className={page < 2 ? 'disabled' : ''} >
          <IconArrowDoubleLeft className="doble" />
        </button>
        <button onClick={() => setPage(page - 1)} disabled={page < 2} className={page < 2 ? 'disabled' : ''} >
          <KeyboardArrowLeftIcon />
        </button>
        <span className="number-pagination">{page}</span>
        <button onClick={() => setPage(page + 1)} disabled={page === pages} className={page === pages ? 'disabled' : ''}>
          <KeyboardArrowRightIcon />
        </button>
        <button onClick={() => setPage(pages)} disabled={page === pages} className={page === pages? 'disabled' : ''}>
          <IconArrowDoubleRight className="doble" />
        </button>
      </div>
    </div>
  </>
  )
}