import '../../../assets/css/adminComponentUi.min.css';

interface ISelect {
    key?: number;
    nameAttr?:string;
    idAttr?: string;
    required?: boolean;
    handleChange?: Function;
    onKeyUp?: Function;
    children?: any;
    extraClass?: any; 
    style?: any;
}

export default (props: ISelect) => {
  const { key, idAttr, children, nameAttr, required, handleChange, onKeyUp, extraClass, style } = props;

  return (
    <>
      <select
        style={style}
        key={key}
        name={nameAttr}
        id={idAttr}
        onKeyUp={() => {if (onKeyUp) onKeyUp()}}
        required={required}
        onChange={ () => {if(handleChange) handleChange()}}
        className={`input-select ${extraClass ? '' : extraClass}`}
      >
        {children}
      </select>
    </>
  );
};
