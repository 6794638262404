import { IFormatHour } from "../../interfaces/IInput";

export const formatHourToMMSS = (values: IFormatHour) => {
  const { date } = values;
  let formatDate: string = "";
  let currentDate: string = date;

  if (!date) {
    return "-";
  }

  if (date.includes("T")) {
    const { 1: splitDate } = date.split("T");
    currentDate = splitDate;
  }

  const { 0: hour, 1: min } = currentDate.split(":");

  formatDate = `${hour}:${min}`;

  return formatDate;
};