import { useContext, useEffect, useState } from "react";
import "../../../assets/css/loading.min.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ValidationKamContext from "../../../context/ValidationKam/ValidationKamContext";
import IconLoading from "../../ui/kit-svg/IconLoading";
import OperacionContratacionContext from "../../../context/Operaciones/OperacionContratacionContext";
import { IClientes } from "../../../interfaces/IOperacionesContratacion";

const SeccionCliente = () => {
  const { token, getToken } = useContext(OperacionContratacionContext);
  const { countAssign, getCounts, isLoading, isError, profile } =
    useContext(ValidationKamContext);
  const [pagesNav, setPagesNav] = useState<string>("");

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getCounts({ token });
    }
  }, [token]);

  useEffect(() => {
    const urlCompleta = window.location.href;
    const partes = urlCompleta.split("/");
    const primerValor = partes[3];
    setPagesNav(primerValor);
  }, [window.location.href]);

  const handleClickGo = (values: any) => {
    const { nav, ruta } = values;
    if (nav === "contratos") {
      return (window.location.href = `${nav}/pendientes/${ruta}`);
    }
    window.location.href = `${nav}/${ruta}`;
  };

  return (
    <>
      {isLoading && (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" />
        </div>
      )}

      {isError && (
        <div className="content-loading">
          <h3>Error al cargar los datos...</h3>
        </div>
      )}

      {countAssign &&
        countAssign?.map((values: IClientes) => {
          const { clienteId, clienteNombre } = values;
          return (
            <>
              <section
                key={clienteId}
                className="section-nav"
                onClick={() =>
                  handleClickGo({
                    nav: pagesNav == "contratacion" ? "resumen" : pagesNav,
                    ruta: clienteId,
                  })
                }
              >
                <div className="section-option">{clienteNombre}</div>
                <ArrowForwardIcon />
              </section>
            </>
          );
        })}
    </>
  );
};

export default SeccionCliente;
