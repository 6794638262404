import { useContext, useEffect, useState } from "react";
import ContratosContext from "../../../../../../context/Contratos/ContratosContext";
import { formatDateToDDMMAAAA } from "../../../../../../functions/kit-mdtw/dates";
import IconDocumento from "../../../../../ui/kit-svg/icon-dashboard/graficos/IconDocumento";
import FooterTable from "../FooterTable";
import { IListaUltimosContratosSolicitados } from "../../../../../../interfaces/IContratos";

interface IContratosSolicitados {
  objetoFilterGeneral: any;
}

const ContratosSolicitados = (props: IContratosSolicitados) => {
  const { objetoFilterGeneral } = props;
  const {
    token,
    listaUltimosContratosSolicitados,
    postDashboardUltimosContratos,
  } = useContext(ContratosContext);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 4,
  });
  const [gridList, setGridList] = useState<any[]>([]);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [formFilter, setFormFilter] = useState<any>({
    areaNegocio: [],
    analista: [],
    cliente: [],
  });

  useEffect(() => {
    if (objetoFilterGeneral) {
      setFormFilter({
        areaNegocio: objetoFilterGeneral?.areaNegocio,
        analista: objetoFilterGeneral && objetoFilterGeneral?.analista,
        cliente: objetoFilterGeneral?.cliente})
    }
  }, [objetoFilterGeneral]);

  useEffect(() => {
    if (token ) {
      postDashboardUltimosContratos({ token, filtros: formFilter });
    }
  }, [objetoFilterGeneral, token, formFilter]);

  useEffect(() => {
    if (listaUltimosContratosSolicitados) {
      let arrayGridFilter = [...listaUltimosContratosSolicitados];
      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridList(arrayGridFilter);
    }
  }, [objetoFilterGeneral, listaUltimosContratosSolicitados, range]);

  return (
    <section className="container-table-dashboard w-65 responsiveChart">
      {listaUltimosContratosSolicitados && (
        <>
          <div className="content-title">
            <label className="title-table-dashboard">
              <>Últimos Contratos Solicitados</>
            </label>
          </div>
          <div className="content-table-dashboard ">
            <table className="table-dashboard">
              <thead>
                <tr>
                  <th></th>
                  <th>USUARIO</th>
                  <th>FICHA</th>
                  <th>TIPO DOCUMENTO</th>
                  <th>FECHA SOLICITUD</th>
                </tr>
              </thead>
              <tbody>
                {gridList &&
                  gridList.map(
                    (item: IListaUltimosContratosSolicitados, key: number) => {
                      const {
                        solicitante,
                        ficha,
                        tipoDocumento,
                        fechaSolicitud,
                      } = item;

                      return (
                        <>
                          <tr key={key}>
                            <td>
                              <div className="content-name">
                                <IconDocumento fill="#0065DD" />
                              </div>
                            </td>
                            <td>{solicitante}</td>
                            <td>{ficha}</td>
                            <td>{tipoDocumento}</td>
                            <td>
                              {formatDateToDDMMAAAA({
                                date: fechaSolicitud,
                                format: "/",
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </>
      )}
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />
    </section>
  );
};

export default ContratosSolicitados;
