import { useContext } from "react";
import { useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  formatDateToDDMMAAAA,
  formatDateToDDMMYY,
} from "../../../../../functions/kit-mdtw/dates";
import AccionMasive from "./AccionMasiva";
import { useParams } from "react-router-dom";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import FiltersTable from "./FiltersTable";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import ButtonIcon from "../../../../ui/kit-mdtw/buttons/ButtonIcon";
import ReplyIcon from "@mui/icons-material/Reply";
import ModalConfirm from "../Modals/ModalConfirm";
import MediaQuery from "../../../../ui-responsive/MediaQuery";
import FooterTable from "./FooterTable";
import {
  FILTER_LIST,
  STATE_CANDIDATE,
} from "../../../../../diccionary/Diccionary";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import ModalFicha from "../../AsistenciaValidacionKam/Modals/ModalFicha";

const TablaCandidatoValidado = () => {
  const {
    token,
    getToken,
    exclusiones,
    listaCandidatoValidado,
    getListCandidateValidate,
    listCandidatoValidadoValidacionCliente,
    setListCandidatoValidadoValidacionCliente,
    setVisibleActionBlocking,
  } = useContext(ValidationKamContext);
  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [arraySeleccionado, setArraySeleccionado] = useState<any[]>([]);
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [gridCandidatoValidado, setGridCandidatoValidado] = useState<
    IListaCandidatos[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);

  useEffect(() => {
    listCandidatoValidadoValidacionCliente.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listCandidatoValidadoValidacionCliente]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  useEffect(() => {
    if (token) {
      getListCandidateValidate({ idCliente, token });
    }
  }, [token]);

  useEffect(() => {
    let arrayGridFilter = [...listaCandidatoValidado];
    if (filterChange) {
      const { rut, areaNegocio, grupo, fechaValidacion, estado } = filterChange;
      if (rut.value && rut.value.length > 0) {
        const rutArray = rut.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          rutArray.includes(item.personaDni)
        );
      }
      if (areaNegocio.value && areaNegocio.value.length > 0) {
        const areaNegocioArray = areaNegocio.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          areaNegocioArray.includes(item.areaNegocioId)
        );
      }
      if (grupo.value && grupo.value.length > 0) {
        const grupoArray = grupo.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          grupoArray.includes(item.busquedaId)
        );
      }
      if (fechaValidacion.value && fechaValidacion.value.length > 0) {
        const fechaValidacionArray = fechaValidacion.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          fechaValidacionArray.includes(
            formatDateToDDMMAAAA({ date: item.confirmadoFecha })
          )
        );
      }
      if (estado.value && estado.value.length > 0) {
        const estadoArray = estado.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          estadoArray.includes(
            item.busPerStatus == STATE_CANDIDATE.PEND_SOL_CONTR
              ? "A CONTRATACION"
              : "DESCARTADO / BLOQUEADO"
          )
        );
      }
    }

    setLengthFilters(arrayGridFilter.length);

    if (range) {
      const { initRange, endRange } = range;
      arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
    }
    setGridCandidatoValidado(arrayGridFilter);
  }, [filterChange, listaCandidatoValidado, range]);

  const handleClickAddListMasive = (personaDni: string) => {
    const currentService = [...listCandidatoValidadoValidacionCliente];

    if (!currentService.includes(personaDni)) {
      currentService.push(personaDni);
    } else {
      const positionDeleted = currentService.findIndex(
        (service) => service === personaDni
      );
      currentService.splice(positionDeleted, 1);
    }
    setListCandidatoValidadoValidacionCliente(currentService);
  };

  const handleClickPersonIdMasive = () => {
    listCandidatoValidadoValidacionCliente.length > 0
      ? setListCandidatoValidadoValidacionCliente([])
      : setListCandidatoValidadoValidacionCliente(
          listaCandidatoValidado.map((item: IListaCandidatos) => {
            return item.personaDni;
          })
        );
  };

  const handleClickModalRestaurar = (values: any) => {
    const { busPerId } = values;
    if (busPerId) {
      setArraySeleccionado(busPerId);
      setOpenModalRestaurar(true);
    }
  };

  const handleClickModalFicha = (values: any) => {
    const { personaDni } = values;
    setRutCandidate(personaDni);
    setOpenModalFicha(true);
  };

  return (
    <>
      <AccionMasive dataDescarte={dataDescarte} dataBloqueo={dataBloqueo} />
      <FiltersTable
        list={listaCandidatoValidado}
        setFilterChange={setFilterChange}
      />

      <div className="content-header-table">
        <span>Candidatos Validados</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th>
                <ButtonIcon onClick={() => handleClickPersonIdMasive()}>
                  {listCandidatoValidadoValidacionCliente.length ==
                    listaCandidatoValidado.length &&
                  listCandidatoValidadoValidacionCliente.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ButtonIcon>
              </th>
              <th>Estado</th>
              <th>Nombre</th>
              <th>Rut</th>
              {width <= sm ? (
                <>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>Teléfono</th>
                  <th>Área de Negocio</th>
                  <th>Proceso</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoValidado &&
              gridCandidatoValidado.map(
                (list: IListaCandidatos, index: any) => {
                  const {
                    busPerId,
                    busquedaId,
                    areaNegocioCod,
                    personaDni,
                    primerNombre,
                    primerApellido,
                    confirmadoFecha,
                    wsp,
                  } = list;
                  return (
                    <>
                      <tr
                        key={index}
                        className={
                          listCandidatoValidadoValidacionCliente.includes(
                            personaDni
                          ) && "checked"
                        }
                      >
                        <td>
                          <ButtonIcon
                            onClick={() => handleClickAddListMasive(personaDni)}
                          >
                            {listCandidatoValidadoValidacionCliente.includes(
                              personaDni
                            ) ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                          </ButtonIcon>
                        </td>
                        <td>
                          <div className={`validate-state`}>
                            <ReplyIcon className="validate" />
                          </div>
                        </td>
                        <td>
                          {primerNombre} {primerApellido}
                        </td>
                        <td>{personaDni}</td>

                        {width <= sm ? (
                          <>
                            <td>
                              <div className="content-icon-table">
                                <button
                                  onClick={() =>
                                    handleClickModalFicha({ personaDni })
                                  }
                                >
                                  <AssignmentIndIcon className={"edit"} />
                                </button>
                                <button
                                  onClick={() =>
                                    handleClickModalRestaurar({ personaDni })
                                  }
                                >
                                  <RefreshRoundedIcon
                                    className={"restore"}
                                    style={{ transform: "rotateY(180deg)" }}
                                  />
                                </button>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{wsp}</td>
                            <td>{areaNegocioCod}</td>
                            <td>{busquedaId}</td>
                            <td>
                              {formatDateToDDMMYY({
                                date: confirmadoFecha,
                                format: "/",
                              })}
                            </td>
                            <td>
                              <div className="content-icon-table">
                                <button
                                  onClick={() =>
                                    handleClickModalFicha({ personaDni })
                                  }
                                >
                                  <AssignmentIndIcon className={"edit"} />
                                </button>
                                <button
                                  onClick={() =>
                                    handleClickModalRestaurar({ busPerId })
                                  }
                                >
                                  <RefreshRoundedIcon
                                    className={"restore"}
                                    style={{ transform: "rotateY(180deg)" }}
                                  />
                                </button>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    </>
                  );
                }
              )}
          </tbody>
        </table>
      </div>

      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalFicha && (
        <ModalFicha
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setOpenModalFicha(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          rut={rutCandidate}
          setOpenModalFicha={setOpenModalFicha}
        />
      )}

      {openModalRestaurar && (
        <ModalConfirm
          modalStyle="confirm"
          modalType="restaurar"
          title="Restaurar Candidato"
          subTitle="¿Estas seguro que deseas restaurar al candidato?"
          message="Al aceptar, el candidato volverá a la lista de candidatos pendientes de validación. "
          onChangeBack={() => setOpenModalRestaurar(false)}
          onChangeGo={() => setOpenModalRestaurar(false)}
          buttonBack="Cancelar"
          buttonGo="Aceptar"
          list={arraySeleccionado}
        />
      )}
    </>
  );
};

export default TablaCandidatoValidado;
