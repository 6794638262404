import { useContext, useEffect, useState, lazy, Suspense } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconDescargaNomina from "../../../../ui/icons-seleccion/IconDescargaNomina";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalAvanzarProceso from "../../../../ui/kit-modal/avanzarProceso/ModalAvanzarProceso";
import IconCheck from "../../../../ui/icons-seleccion/IconCheck";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import {
  IEstadosExcel,
  IFiltrosExcel,
  IListaFormatoPsicolaboral,
} from "../../../../../interfaces/ISeleccion";
import IconCargando from "../../../../ui/icons-seleccion/IconCargando";
import IconDescargaPsicolaboral from "../../../../ui/kit-svg/icon-preseleccionado/IconDescargaPsicolaboral";
import ModalDownloadPsicolaboral from "../../../../ui/kit-modal/descargaPsicolaboral/ModalDownloadPsicolaboral";
const LazyNoProcesados = lazy(
  () => import("../../../../ui/kit-modal/noProcesado/ModalNoProcesadoSeleccion")
);
interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  handleAvanzarProceso: Function;
  candidates: any;
  setCandidates: Function;
  listRefresh: Function;
  setListadoSeleccionado: Function;
  selectCurrent: any; 
  setSelectCurrent: Function; 
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[]; 
  modalPdfDisabled: boolean; 
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    handleAvanzarProceso,
    setCandidates,
    listRefresh,
    setListadoSeleccionado,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
    modalPdfDisabled,
  } = props;
  const {
    listaCandidatoPreSeleccionPendiente,
    token,
    putEntrevistaRealizada,
    listaNoProcesadosSeleccion,
    postGenerarExcel,
    getListadoPdf, 
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [isOpenModalAvanzar, setIsOpenModalAvanzar] = useState<boolean>(false);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean | undefined>(
    undefined
  );
  const [isOpenModalDownPsicolaboral, setIsOpenModalDownPsicolaboral] =
    useState<boolean>(false);
  const estadosExcel: IEstadosExcel = {
    porValidar: false,
    preseleccionado: true,
    validado: false,
  };
  const filtrosExcel: IFiltrosExcel = {
    busPerIdList: [],
    reclutador: [],
    estadoRegistro: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    tipoProceso: ["PROFESIONAL"],
    fechaPostulacion: [],
    fechaValidacion: [],
    fechaPreseleccion: [],
    confirmaEntrevista: null,
    transporteTeamwork: null,
    entrevistaRealizada: null,
  };

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickBloquearCandidato = (): void => {
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (): void => {
    setIsOpenModalDescartar(true);
  };

  const handleClickAvanzarProceso = (): void => {
    setIsOpenModalAvanzar(true);
  };

  const handleAvanzarCandidate = (): void => {
    handleAvanzarProceso({ setIsOpenModalNoProcesado });
    setIsOpenModalAvanzar(false);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "auto";
    }
    if (token) {
      listRefresh();
      setListadoSeleccionado([]);
    }
  };

  const handleClickStatusEntrevista = async (): Promise<void> => {
    const arrayList = listaCandidatoPreSeleccionPendiente.filter((item: any) =>
      listadoSeleccionado.includes(item.personaDNI)
    );
    const personas = arrayList.map((item: any) => {
      const { busPerId, entrevistaRealizada } = item;
      return {
        busPerId,
        entrevistaRealizada: !entrevistaRealizada,
      };
    });
    if (token) {
      await putEntrevistaRealizada({ token, personas });
      listRefresh();
    }
  };

  const handleClickNomina = async (): Promise<void> => {
    if (token) {
      try {
        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          const dataObject = JSON.parse(copyStorage);
          filtrosExcel.proceso = dataObject.proceso?.map((valor: any) =>
            parseInt(valor)
          );
          filtrosExcel.cliente = [...dataObject.cliente];
          filtrosExcel.fechaPostulacion = [...dataObject.fechaPostulacion];
        }
        await postGenerarExcel({
          token,
          estadosExcel,
          filtrosExcel,
          setIsLoading: setIsLoadingExcel,
        });
      } catch (error) {
        console.log("error descarga");
      }
    }
  };

  const handleDownloadPsicolaboral = (): void => {
    setIsOpenModalDownPsicolaboral(true);
  };

  const handleClickViewPdf = () => {
    try {
      getListadoPdf({ selectCurrent });
      setIsOpenModalDownPsicolaboral(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container-actions w-100">
        <div className="content-button-masive">
          <button
            className={`button-navegation ${
              buttonEnabled ? "naranjo" : "disabled"
            }`}
            onClick={() => handleClickAvanzarProceso()}
            disabled={buttonEnabled ? false : true}
          >
            Avanzar Candidato
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
          <Tooltip text="Descarga Informe Psicolaboral">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-verde" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleDownloadPsicolaboral}
            >
              <IconDescargaPsicolaboral
                fill="#06A502"
                disabled={!buttonEnabled}
              />
            </button>
          </Tooltip>
          <Tooltip text="Entrevista Realizada">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-naranjo" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickStatusEntrevista}
            >
              <IconCheck fill="#FF4900" disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickBloquearCandidato}
            >
              <IconBloquear fill="#BE2A2A" disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Descartar">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickDescartarCandidato}
            >
              <IconDescarte fill="#BE2A2A" disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <Tooltip text="Descargar Nómina">
            <button
              className={`button-actions ${
                isLoadingExcel ? "disabled file" : "active-file"
              }`}
              onClick={() => handleClickNomina()}
            >
              {isLoadingExcel ? (
                <IconCargando fill="#327a30" />
              ) : (
                <IconDescargaNomina fill="#327a30" />
              )}
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["COMPLETO", "INCOMPLETO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="FECHA POSTULACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaPostulacion"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAvanzar && (
        <ModalGenerico
          title="CONFIRMAR MOVIMIENTO DE ETAPA"
          width="w-70"
          subtitle="Estás por mover de etapa a la siguiente selección: "
          onChangeBack={() => {
            setIsOpenModalAvanzar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleAvanzarCandidate()}
          buttonBackText="Cancelar"
          buttonGoText="Aceptar"
        >
          <ModalAvanzarProceso
            listCandidate={listadoSeleccionado}
            listData={listaCandidatoPreSeleccionPendiente}
            setCandidates={setCandidates}
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Candidatos No Enviados"
          iconTitle="danger"
          width="w-70"
          subtitle="Los siguiente candidatos no pudieron ser procesados:"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cerrar"
        >
          <Suspense fallback={<div>Cargando pruebitas...</div>}>
            <LazyNoProcesados list={listaNoProcesadosSeleccion ?? []} />
            {/* <ModalNoProcesadoSeleccion list={listaNoProcesadosSeleccion ?? []} /> */}
          </Suspense>
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPreSeleccionPendiente ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPreSeleccionPendiente ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDownPsicolaboral && (
        <ModalGenerico
          title="GENERAR INFORME PSICOLABORAL"
          width="w-70"
          subtitle="Estás por generar el informe psicolaboral para la siguiente selección de evaluaciones:"
          positionSubTitle="start"
          messageBottom="Puede encontrar los documentos en tu carpeta de descargas. Para continuar presione Descargar Informe."
          positionMessageBottom="start"
          buttonBackText="Cancelar"
          buttonGoText="Descargar Informe"
          buttonGoDisable={modalPdfDisabled}
          buttonDisabledTooltipText={"Favor seleccione el tipo de formato del pdf"}
          onChangeBack={() => {
            setIsOpenModalDownPsicolaboral(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickViewPdf();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDownloadPsicolaboral
            list={listadoSeleccionado}
            listOriginal={listaCandidatoPreSeleccionPendiente}
            token={token}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
            tipoDocumentoAll={selectCurrent}
            setTipoDocumentoAll={setSelectCurrent}
            listaFormatoPsicolaboral={listaFormatoPsicolaboral}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
