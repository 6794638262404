import React, { FC, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import "../../../assets/css/footer.min.css";
import { Box, Container, Grid, Typography } from "@mui/material";
import IconFooter from "../kit-svg/IconFooter";

export const Footer: FC = (): ReactElement => {
  let history = useHistory();

  const goToAsistencia = () => {
    //<Redirect to="/asistencia/lista"/>
    // history.push("/asistencia");
  };

  return (
    <Box className="box">
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography>
              <div
                className="justify-content-center flex"
                onClick={() => goToAsistencia()}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="col-4 justify-content-center flex"
                  style={{
                    alignItems: "center",
                    flexDirection: "column",
                    verticalAlign: "middle",
                  }}
                >
                  <IconFooter width={64} height={50} />
                </div>
              </div>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

/*
        <div className="flex col-12 my-0 py-0 mx-0 mt-0" style={{background:"#003D85", height:"100px"}}>    

<div className="flex col-12 justify-content-center mt-7">
<SettingsOutlinedIcon style={{fontSize:"3rem", color:"#ffffff"}}/>
<AccountCircleOutlinedIcon style={{fontSize:"3rem", color:"#ffffff"}}/>
<Groups2OutlinedIcon style={{fontSize:"3rem", color:"#ffffff"}}/>
         </div>


</div>*/
