interface IconStopW {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?: boolean;
  }
  
  export default (props: IconStopW) => {
    const { width, height, stroke, style, className, color, fill, disabled } = props;
  
    return (
      <svg
        width={`${width ? width : "17"}`}
        height={`${height ? height : "17"}`}
        viewBox="0 0 17 17"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M8.5 0C3.80444 0 0 3.80444 0 8.5C0 13.1956 3.80444 17 8.5 17C13.1956 17 17 13.1956 17 8.5C17 3.80444 13.1956 0 8.5 0ZM11.7903 11.2419C11.7903 11.5435 11.5435 11.7903 11.2419 11.7903H5.75806C5.45645 11.7903 5.20968 11.5435 5.20968 11.2419V5.75806C5.20968 5.45645 5.45645 5.20968 5.75806 5.20968H11.2419C11.5435 5.20968 11.7903 5.45645 11.7903 5.75806V11.2419Z" 
       fill="#FAFAFA"/>
       
      </svg>



    );
  };


