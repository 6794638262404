import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M16.0801 4.82L12.0701 0.79C11.8801 0.6 11.6401 0.5 11.3701 0.5H11.1301V5.75H16.3801V5.51C16.3801 5.25 16.2801 5 16.0901 4.82H16.0801Z" 
     fill="#FF4900"/>
<path d="M10.7901 7.06C10.2501 7.06 9.81012 6.62 9.81012 6.08V0.5H1.60012C1.06012 0.5 0.620117 0.94 0.620117 1.48V20.52C0.620117 21.07 1.06012 21.5 1.60012 21.5H15.3901C15.9401 21.5 16.3701 21.06 16.3701 20.52V7.06H10.7901ZM2.21012 12.39C2.21012 10 4.14012 8.06 6.53012 8.06C8.02012 8.06 9.33012 8.82 10.1101 9.96L8.66012 10.94C8.20012 10.25 7.42012 9.81 6.53012 9.81C5.11012 9.81 3.96012 10.96 3.96012 12.39C3.96012 13.82 5.11012 14.97 6.53012 14.97C7.29012 14.97 7.97012 14.64 8.44012 14.11L9.75012 15.28C8.96012 16.16 7.81012 16.72 6.53012 16.72C4.14012 16.72 2.21012 14.78 2.21012 12.39ZM10.3501 20.1C9.01012 20.1 7.71012 19.31 6.96012 18.4L8.31012 17.28C8.82012 17.89 9.66012 18.35 10.3501 18.35C11.8501 18.35 13.0301 17.33 13.0301 15.85C13.0301 14.26 11.8901 13.17 10.5001 13.17C9.62012 13.17 9.07012 13.51 8.58012 14.06L7.27012 12.89C8.01012 12.06 9.01012 11.41 10.5001 11.41C12.9101 11.41 14.7801 13.34 14.7801 15.84C14.7801 18.34 12.6501 20.1 10.3501 20.1Z" 
fill="#FF4900"/>

    </svg>
  );
};
