import { useEffect, useState } from "react";
import {
  ICategoriesValidationInput,
  IFormatDate,
  ILayerAge,
} from "../../../interfaces/IInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default (props: ILayerAge) => {
  const { layer, date, defaultValue } = props;
  const [age, setAge] = useState<string>("");
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    caution: "input-caution",
    initial: "input-initial",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if(defaultValue) {
      setAge(calculateAges({ date: defaultValue }));
    }
  },[defaultValue])

  useEffect(() => {
    setAge(date ? calculateAges({ date }) : "");
  }, [date]);

  useEffect(() => {
    if (age) {
      const validAges: number = Number(age);
      setMessageError(
        validAges >= 18
          ? undefined
          : "Precaución esta persona es menor de edad",
      );
      setTriggerError(validAges >= 18 ? false : true);
      setEventValidation(validAges >= 18 ? "initial" : "caution");
    }

    return () => {
      setMessageError(undefined);
    };
  }, [age, date]);

  const calculateAges = (values: IFormatDate) => {
    const { date } = values;
    const { 0: day, 1: month, 2: year } = date.split("/");
    const actualDate: Date = new Date();
    const currentDate: Date = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
    );
    let currentAge: number = 0;
    const diffYears =
      actualDate.getUTCFullYear() - currentDate.getUTCFullYear();

    for (let ages = 1; ages <= diffYears; ages++) {
      if (
        actualDate >=
        new Date(Number(year) + ages, Number(month) - 1, Number(day))
      ) {
        currentAge = currentAge + 1;
      }
    }

    return String(currentAge);
  };

  return (
    <div>
      <label className="layer">{layer}</label>
      <input
        type="text"
        value={age}
        className={`${categoriesValidation[eventValidation]} layer-input`}
        disabled={true}
        placeholder="0"
      />
      {triggerError && (
        <span className="layer-caution down">
          <ErrorOutlineIcon /> {messageError}
        </span>
      )}
    </div>
  );
};
