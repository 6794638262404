interface IconRegistroI {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconRegistroI) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "17"}`}
        height={`${height ? height : "20"}`}
        viewBox="0 0 15 18"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M4.1002 14.1H10.9002V12.4H4.1002V14.1ZM4.1002 10.7H10.9002V9H4.1002V10.7ZM2.4002 17.5C1.9327 17.5 1.53263 17.3337 1.2 17.0011C0.866795 16.6679 0.700195 16.2675 0.700195 15.8V2.2C0.700195 1.7325 0.866795 1.33215 1.2 0.99895C1.53263 0.666317 1.9327 0.5 2.4002 0.5H9.2002L14.3002 5.6V15.8C14.3002 16.2675 14.1339 16.6679 13.8012 17.0011C13.468 17.3337 13.0677 17.5 12.6002 17.5H2.4002ZM8.3502 6.45V2.2H2.4002V15.8H12.6002V6.45H8.3502Z" 
       fill={fill}/> 
      </svg>



    );
  };

