import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import { style } from "@mui/system";

interface CandidateModalProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisibleGroup: boolean;
  
  busqueda: any;
}

const ProcessCreateGroupModal: React.FC<CandidateModalProps> = ({
  token,
  isModalVisibleGroup,
  onClose,
  
  busqueda
}) => {
  const [loadingAddCandidate, setLoadingAddCandidate] = useState(false); 
  const [filterId, setFilterId] = useState<any>("");
  const [clientName, setClientName] = useState<any>("");
  const [group, setGroup] = useState<any>(null);
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [accountManager, setAccountManager] = useState<any>("");  
  const [groups, setGroups] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const [data, setData] = useState<any[]>([]);
  const [isVisibleC, setIsVisibleC] = useState(false);
  const [coincidences, setCoincidences] = useState<any>([]);
  const [isVisibleSuggested, setIsVisibleSuggested] = useState(false);
  const [groupsNoAsignaded, setGroupsNoAsignaded] = useState<any>([]);
  const [addProceses, setAddProceses]=useState<any[]>([]);
  const [searchGroup, setSearchGroup] = useState<any>("");
  const [newArrSearch, setNewArrSearch] = useState(false);
  const [idGroupSelect, setIdGroupSelect] = useState(0);
  

  //constantes y funciones 

  const getUsers = () => {
    api
      .get(`usuario`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsuarios(response.data.data);        
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getGroups = () => {
    api
      .get(`/cargoGroup/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroups(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getGroupsNoAsignaded = () => {
    api
      .get(`busqueda-unassigned?userId=${sessionStorage.getItem("usuarioId")}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {                
        const noGroup = response.data.data[0].filter((group: any) => group.cargoGroupId === "CG-000");
      
        let noGroupArr = (noGroup[0].busquedas)        
        let newArr: any[] = [];
        noGroupArr.map((ngroup: any) => {       
            const newObj = {
                busquedaId: ngroup.busquedaId,
                clienteNombre: ngroup.busquedaId + " - "+ ngroup.cargoNombre + " - "+ ngroup.clienteNombre
            };
            newArr = [...newArr, newObj];
        }); 
        setGroupsNoAsignaded(newArr);      
      })        
      .catch((error) => {
        console.log("Error al descargar No Asignados");
      });
  };

  
  
  const renderFooter = (name: string) => {
    return (
      <>
        <div className="justify-content-around col-12" style={{ width: "100%"}}>
          <div className="col-12 md:col-5 mx-3 pr-5" style={{float:"left"}}>
            <Button
              id="btnContRegCtx1"
              style={{ width: "100%", height:"45px", float: "left" }}
              label="Usar grupo sugerido y agregar proceso"              
              iconPos="right"
              onClick={updateGroup}
              loading={loadingAddCandidate}
              visible={coincidences.length == 0 ? false:true}
              autoFocus
            />
          </div>

          <div className="col-12 md:col-5 mx-3 pl-5"  style={{float:"right"}}>
            <Button
              id="btnContRegCtx1"
              style={{ width: "100%", height:"45px", background: `${ newArrSearch ? "#E5E5E5" : "#4BB848"}`}}
              label="Crear grupo y guardar"
              iconPos="right"
              onClick={createGroup}
              //loading={loadingAddCandidate}
              autoFocus
              disabled={newArrSearch} 
            />
          </div>
        </div>
      </>
    );
  };
 

  // useEffect
  useEffect(() => {
    if (token && isModalVisibleGroup) {
      getGroups();
      getGroupsNoAsignaded();
      getUsers();
      setGroup(busqueda.cargoGroupId);
      setAccountManager(busqueda.accountManager);      
      setAddProceses([]);
      moreProceses("")
      
    } 
  }, [token, isModalVisibleGroup]);

 
  const closeModal = () => {
    onClose();
    setFilterId(null);
    setClientName(null)
    setAddProceses([]);
    moreProceses("")
    setCoincidences([]);
    setIsVisibleC(false);
    setIsVisibleSuggested(false);
    setSearchGroup("");
  };

     
  const busqCoincidences = () => {

const cargoGroup = searchGroup

    api
    .post(`/cargo-group-sugerencias/`, cargoGroup, {
        params: {
            cargoGroup: searchGroup,            
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
    })
    .then((response: any) => {      
        let newArrSearch: any[] = [];
        newArrSearch = response.data.suggestions.filter((search: any)=> search.cargoGroupName === searchGroup )  
        arrSearchFilter(newArrSearch)

        setCoincidences(response.data.suggestions);
        setIsVisibleC(true);
        setIsVisibleSuggested(true);
      
    })
    .catch((error) => {
      console.log(error + error);
      
    });
  }

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const createGroup = () => {
    
    let arrNewProcesesGroup: any[] = [];
        addProceses.map((item: any) => {       
            const  busquedaId= item.busquedaId            
            
            arrNewProcesesGroup = [...arrNewProcesesGroup, busquedaId];
        });
        
    const groupData = {
        cargoGroupName:searchGroup,
        busquedas:arrNewProcesesGroup
    }    
        api
        .post(`/cargo-group`, groupData, {           
              headers: {
                Authorization: `Bearer ${token}`,
              },
        })
        .then((response: any) => {  
            creartToast(
                toast,
                "success",
                "Grupo Creado",
                "Grupo Creado con éxito",
                6000
              );
              setAddProceses([]);
              getGroupsNoAsignaded();
              moreProceses("")
              setCoincidences([]);
              setIsVisibleC(false);
              setIsVisibleSuggested(false);
              setSearchGroup("");
        })
        .catch((error) => {
          console.log(error + error);
          
        });
    }    

    const updateGroup = () => {
    
        let arrNewProcesesGroup: any[] = [];
        addProceses.map((item: any) => {       
            const  busquedaId= item.busquedaId            
            
            arrNewProcesesGroup = [...arrNewProcesesGroup, busquedaId];
        });
            
        const groupData = {
            cargoGroupId :idGroupSelect,
            busquedas:arrNewProcesesGroup
        }    
            api
            .put(`/cargo-group`, groupData, {           
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
            })
            .then((result: any) => {      
                creartToast(
                    toast,
                    "success",
                    "Grupo Actualizado",
                    "Grupo Actualizado con éxito",
                    6000
                  );                           
                 setAddProceses([]);
                 getGroupsNoAsignaded();
                 moreProceses("")
                 setCoincidences([]);
                 setIsVisibleC(false);
                 setIsVisibleSuggested(false);
                 setSearchGroup("");
                            
            })
            .catch((error) => {
              console.log(error + error);
              
            });
        }    
    
        

const arrSearchFilter = (newArrSearch:any) => { 
   if(newArrSearch.length > 0 ) {
    setNewArrSearch(true)   
    setIdGroupSelect(newArrSearch[0].cargoGroupId)  
    } else{
    setNewArrSearch(false)
    setIdGroupSelect(0) 
    }       
}


  const updateState = (index: any) => (e: any) => {      
    const newArray =addProceses.map((item, i) => {
   
      if (index === i )  {
        return { ...item, busquedaId: e.target.value };
      } else {
        return item;
      }
    });    
       
    setAddProceses(newArray)   
  };
  
  const moreProceses = (busquedaId: string) => {   
    const arr = [];
       const proceses = {
           busquedaId: busquedaId ?? "",
           id: addProceses.length
       }
       arr.push(proceses);       
       setAddProceses(current => [...current, proceses ]);             
      
  } 

  //main return
  return busqueda ? (
    <>
      <Dialog
        header=""
        visible={isModalVisibleGroup}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "75vw" }}
        footer={renderFooter("displayResponsive")}
        onHide={() => closeModal()}
      >
        <div className="flex flex-column">
          <h3 className="flex justify-content-center">Creación de Grupo</h3>
          <span className="flex justify-content-center">
          Puede crear un nuevo Grupo para incluir Cargos Mod o procesos que lo requieran.        
          </span>
          <span className="flex justify-content-center mb-6 mt-2">                 
         Asegurese de que no exista un grupo similar al que espera crear.
          </span>
          
        </div>
        <div className="grid mt-3 justify-content-around px-4">
          <div className="col-12">
            <div className="p-fluid formgrid grid">
              
            
            
                <div className="pr-7 mx-5"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textTransform: "uppercase",
                    color: "#002D63",
                    marginBottom: "0"
                  }}
                >
                  <b>CREAR GRUPO</b>
                </div>
              <Divider align="left" type="dashed"  style={{ marginTop: "0.5rem" }}>  
              </Divider>

              <label htmlFor="selectPrevisionCtx4" className="mt-5 my-3 ml-5" style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "23px",
                    color: "#262626"
                  }}>
                  Grupo
                </label>

                <div className="row col-12 px-3">
                    
                    <div className="col-12 lg:col-5" style={{float:"left" }} >  

                        <div className="col-10"  style={{float:"left" }}>              
                        <InputText style={{height:"45px" }}
                            id="inputGruopModal"
                            placeholder="Escriba nombre del grupo (Máximo 3 palabras)"
                            onChange={(e) => setSearchGroup((e.target.value).toUpperCase())}
                            maxLength={50}
                            value={searchGroup || ""}
                        />  
                        </div> 
                        <div className="col-2" style={{float:"right" }}>    
                                {coincidences && coincidences.length > 0  ? (                              
                                <SmsFailedOutlinedIcon  
                                style={{ color:"#E6A200", background:"white", fontSize: "2.5rem", marginTop: "10%" }} />): "" }

                                {coincidences && coincidences.length == 0 && isVisibleSuggested ? (  <i 
                                className="pi pi-check-circle .pi" 
                                style={{ color:"#53CC50", marginTop: "15%", fontSize: "2rem"}} />): "" }  

                        </div>  
                    </div>                                     
                                 
                    <div className="col-12 lg:col-5 pr-0" style={{float:"right"}} > 
                        <Button                    
                        id="btnSugerenciaModal"
                        style={{ height:"45px" }}
                        label="Buscar coincidencias"
                        iconPos="right"
                        onClick={busqCoincidences}
                        loading={loadingAddCandidate}
                        autoFocus
                        />
                    </div>
                </div>
                
                {coincidences && coincidences.length > 0 && (idGroupSelect == 0) ? ( 
                        <small className="p-0 mx-6" style={{ color:"#996C00"}}>Existen grupos similares. Considere utilizar sugerencias:</small>  
                        ) : <p></p> 
                } 
                {(coincidences && coincidences.length > 0 && (idGroupSelect !== 0))  ? ( 
                        <small className="p-0 mx-6" style={{ color:"#996C00"}}>Este grupo ya existe:</small>  
                        ) : <p></p> 
                } 
                {coincidences && coincidences.length == 0 && isVisibleSuggested ? 
                     <small className="p-0 mx-6" style={{ color:"#327A30"}}>No existen coincidencias, grupo viable.</small>  
                     : <p></p> 
                } 
                            

                { isVisibleC ?
                <div className=" col-12 row px-4 pt-4" style={{float:"left"}}>
                    {
                        coincidences && coincidences.map((item: any) =>{
                            return (
                                <>
                            <div className=" col-12 row" >
                                <div className=" md:col-4 mr-0 pt-2" style={{float:"left"}} >
                                <Button
                                id="btncoin"
                                style={{ color: " #404040", background:(item.cargoGroupId === idGroupSelect )?"#E6A200" : "#FFE8B0" , border: "none",
                                 height:"45px",  boxShadow:(item.cargoGroupId === idGroupSelect )?"0 0 0 0.2rem #a6d5fa" : ""}}
                                label={item.cargoGroupName}
                                value={item.cargoGroupId}
                                onClick={() => setIdGroupSelect(item.cargoGroupId)}  
                                autoFocus={(item.cargoGroupId === idGroupSelect )? true: false}                                            
                                />
                                </div>
                            </div>
                                </>
                            )
                        })
                    }
                </div>
                : <div className="col-12 pt-7"></div>
                }
                
                
                <div className="pr-7 mx-5"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textTransform: "uppercase",
                    color: "#002D63",
                    marginBottom: "0",
                    marginTop:"2rem"
                  }}
                >
                  <b>AGREGUE PROCESOS AL GRUPO</b>
                </div>
              <Divider align="left" type="dashed" style={{ marginTop: "0.5rem" }}>
              </Divider>

            
              <div className="field col-12 px-5">
                <label htmlFor="selectPrevisionCtx4" style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#262626"
                  }}>
                  Escoja de los procesos activos sin asignación
                </label>
                </div> 
                
              { addProceses.length > 0 &&
                  addProceses.map((item, index)=>{
                    return(
                <div className="field col-12 md:col-4 px-5">
                    <Dropdown
                  id="selectPrevisionCtx4"
                  placeholder="Seleccione ID" style={{color:"#A3A3A3",border: "1px solid #B9B9B9"}}
                  value={item.busquedaId}
                  options={groupsNoAsignaded}
                  onChange={updateState(index)}
                  optionLabel="clienteNombre"
                  optionValue="busquedaId"
                  optionDisabled={(option: any) => {
                    return (addProceses.find(o => o.busquedaId === option.busquedaId))?true: false                                                   
                  }}
                  filter
                  showClear
                  filterBy="clienteNombre"
                  appendTo="self"
                />
                </div>

                    )
                  })                   
              }   
                <div className="field col-12 md:col-4 px-5">
                <Button icon="pi pi-plus" 
                 tooltip="Agregar otro proceso"
                 className="p-button-rounded p-button-info p-button-outlined mt-4" style={{ color: "#2A63D5", border: "2px solid", bottom:"40%" }}
                 aria-label="User"
                 onClick={() => moreProceses("")}  
                  /> 
              </div>

              </div>
            </div>
        </div>
        
        
      </Dialog>

      <Toast ref={toast} />
    </>
  ) : (
    <></>
  );
};

export default ProcessCreateGroupModal;
