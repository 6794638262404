import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M5.02148 11.7708L7.50065 9.25L10.0007 11.7708L10.9798 10.7708L8.50065 8.25L10.9798 5.72917L10.0007 4.72917L7.50065 7.25L5.02148 4.72917L4.02148 5.72917L6.52148 8.25L4.02148 10.7708L5.02148 11.7708ZM2.93815 15.5C2.60482 15.5 2.31315 15.375 2.06315 15.125C1.81315 14.875 1.68815 14.5833 1.68815 14.25V2.375H0.833984V1.125H4.75065V0.5H10.2507V1.125H14.1673V2.375H13.3132V14.25C13.3132 14.5833 13.1882 14.875 12.9382 15.125C12.6882 15.375 12.3965 15.5 12.0632 15.5H2.93815ZM12.0632 2.375H2.93815V14.25H12.0632V2.375Z"
      />
    </svg>
  );
};
