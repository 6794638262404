import { useContext } from "react";
import SubMenu from "./Navegacion/SubMenu";
import Title from "./Navegacion/Title";
import ValidationKamContext from "../../../context/ValidationKam/ValidationKamContext";

function HeaderMenu() {
  const { token, setToken, getToken } = useContext(ValidationKamContext);
  return (
    <>
      <SubMenu token={token} setToken={setToken} getToken={getToken} />
      <Title token={token} setToken={setToken} getToken={getToken} />
    </>
  );
}

export default HeaderMenu;
