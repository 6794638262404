interface IIconVerProceso {
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconVerProceso) => {
  const { fill, disabled } = props;

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 16"
      fill={disabled ? '#aeaeae' : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path d="M14.0848 8.69512H12.9872V3.06098H11.8897V4.70732H3.10918V3.06098H2.01162V14.0366H6.84089V15.1341H2.01162C1.69333 15.1341 1.43357 15.0317 1.22504 14.8232C1.0165 14.6146 0.914062 14.3549 0.914062 14.0366V3.06098C0.914062 2.74268 1.0165 2.48293 1.22504 2.27439C1.43357 2.06585 1.69333 1.96341 2.01162 1.96341H5.70675C5.79089 1.53537 6.00309 1.1878 6.33601 0.909756C6.6726 0.635366 7.05675 0.5 7.49943 0.5C7.94211 0.5 8.32626 0.639024 8.65918 0.909756C8.99577 1.18415 9.20431 1.53537 9.29211 1.96341H12.9872C13.3055 1.96341 13.5653 2.06585 13.7738 2.27439C13.9824 2.48293 14.0848 2.74268 14.0848 3.06098V8.69512ZM7.49943 3.06098C7.70796 3.06098 7.87992 2.99146 8.01894 2.85244C8.15797 2.71341 8.23114 2.5378 8.23114 2.32927C8.23114 2.12073 8.16162 1.94878 8.01894 1.80976C7.87992 1.67073 7.70431 1.59756 7.49943 1.59756C7.29455 1.59756 7.11894 1.66707 6.97992 1.80976C6.84089 1.94878 6.76772 2.12439 6.76772 2.32927C6.76772 2.53415 6.83723 2.70976 6.97992 2.85244C7.11894 2.99146 7.29455 3.06098 7.49943 3.06098Z" />
        <path d="M7.44821 6.71951H3.42382C3.2226 6.71951 3.05797 6.88415 3.05797 7.08537C3.05797 7.28659 3.2226 7.45122 3.42382 7.45122H7.44821C7.64943 7.45122 7.81406 7.28659 7.81406 7.08537C7.81406 6.88415 7.64943 6.71951 7.44821 6.71951Z" />
        <path d="M9.27748 8.65122H3.42382C3.2226 8.65122 3.05797 8.81585 3.05797 9.01707C3.05797 9.21829 3.2226 9.38293 3.42382 9.38293H9.27748C9.4787 9.38293 9.64333 9.21829 9.64333 9.01707C9.64333 8.81585 9.4787 8.65122 9.27748 8.65122Z" />
        <path d="M5.98479 10.5829H3.42382C3.2226 10.5829 3.05797 10.7476 3.05797 10.9488C3.05797 11.15 3.2226 11.3146 3.42382 11.3146H5.98479C6.18601 11.3146 6.35065 11.15 6.35065 10.9488C6.35065 10.7476 6.18601 10.5829 5.98479 10.5829Z" />
        <path
          d="M13.5689 12.7561C13.5689 13.3488 13.3348 13.9159 12.9177 14.3329C12.5006 14.75 11.9336 14.9841 11.3409 14.9841C10.7482 14.9841 10.1811 14.75 9.76406 14.3329C9.34699 13.9159 9.11284 13.3488 9.11284 12.7561C9.11284 12.1634 9.34699 11.5963 9.76406 11.1793C10.1811 10.7622 10.7482 10.528 11.3409 10.528C11.9336 10.528 12.5006 10.7622 12.9177 11.1793C13.3348 11.5963 13.5689 12.1634 13.5689 12.7561ZM8.59699 12.7561C8.59699 13.4841 8.88601 14.1829 9.40187 14.6951C9.91772 15.211 10.6128 15.5 11.3409 15.5C12.0689 15.5 12.7677 15.211 13.2799 14.6951C13.7921 14.1793 14.0848 13.4841 14.0848 12.7561C14.0848 12.028 13.7958 11.3293 13.2799 10.8171C12.7641 10.3012 12.0689 10.0122 11.3409 10.0122C10.6128 10.0122 9.91406 10.3012 9.40187 10.8171C8.88601 11.3329 8.59699 12.028 8.59699 12.7561ZM11.0848 11.3V12.7561C11.0848 12.8402 11.1287 12.9207 11.1982 12.972L12.2263 13.6598C12.3433 13.7402 12.5043 13.7073 12.5848 13.5866C12.6653 13.4659 12.6324 13.3085 12.5116 13.228L11.597 12.6207V11.3C11.597 11.1573 11.4836 11.0439 11.3409 11.0439C11.1982 11.0439 11.0848 11.1573 11.0848 11.3Z"
        />
      </g>
    </svg>
  );
};
