import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import "../../../assets/css/login.min.css";
import Footer from "./Footer";

const AssistsEnd = () => {
  const { idGrupo }: any = useParams();
  const [group, setGroup] = useState<any>({});
  const [token, setToken] = useState<any>(null);
  const { instance } = useMsal();

  // Main return

  return (
    <>
      <div className="container-login" style={{ background: "#FAFAFA"}}>
        <div className="content-login" style={{ background: "#FAFAFA", height: "80vh", borderRadius: "0px", boxShadow: "none" }}>
          <div className="content-flex" style={{margin: "100px 0px"}}>
            <div className="img-login">
              <img
                src={"/assets/layout/images/logo-teamwork-chile.png"}
                alt="logo"
                className="img-size"
              />
            </div>

            <div className="content-text">
              <span className="title" style={{ color: "#06A502" , fontSize: "25px", margin: "40px 0px"}}>La asistencia se registró exitosamente</span>
            </div>

            <div className="content-text">
              <span className="correo" style={{textAlign: "center"}}>Gracias por tu colaboración y nos vemos la próxima vez</span>
            </div>

            <div className="content-text">
              <span className="info" style={{ marginTop: "25px", textAlign: "center"}}>
                Ya puedes cerrar la página de tu navegador
              </span>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AssistsEnd;