import { useState, useEffect, useRef } from "react";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { useHistory } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { PickList } from "primereact/picklist";
import ModalDiscard from "../../../utils/ModalDiscard";
import { Toast } from "primereact/toast";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

//const & functions

const AddFormComponent = () => {
  const [fieldsArray, setFieldsArray] = useState<any[]>([]);
  const [token, setToken] = useState<any>(null);
  const [clientsNames, setClientsNames] = useState<any>([]);
  const [clientName, setClientName] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [clientFields, setClientFields] = useState<any>([]);
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);
  const history = useHistory();
  const menu = useRef<any>(null);
  const toast = useRef<Toast>(null);

  const CentralContainer = () => {
    return (
      <>
        <div className="flex justify-content-start" >
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "15px",
              lineHeight: "156%",
              alignItems: "left",
              color: "#333333",
              fontWeight: "400"
            }}
          >
            
            <p
              style={{
                textAlign: "left",
              }}
            >
              En esta opción puedes agregar o eliminar campos de las nóminas que normalmente utilizan para cada cliente.
              Estos campos también aparecerán las fichas para tomar datos.
            </p>
           
            <p
              style={{
                fontSize:"15px",
                color:"#2A63D5",
                textAlign: "left",
                marginTop:"2rem"
                
              }}
            >
              Para comenzar selecciona el área de negocio que deseas configurar.
            </p>
          </div>
        </div>
      </>
    );
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const itemTemplateClient = (item: any) => {
    return (
      <div className="product-item">
        <div className="image-container">
          <p>{item.campExcelAsigNombre}</p>
        </div>
      </div>
    );
  };

  const cerrarModalDiscard = () => {
    setVisibleModalDiscard(false);
    // buscarCandidatos();
  };

  const onChangeBussinessZone = (e: any) => {
    getClientFields(e.target.value);
    setClientName(e.target.value);
    //getClientSpecialFields();
  };

  const onChange = (event: any) => {
    setClientFields(event.source);
    setSelectedFields(event.target);
  };

  const processNames = (response: any) => {
    const newArr: any[] = [];
    response.forEach((element: any) => {
      element.newName = element.clienteId
        .concat(" - ")
        .concat(element.clienteNombre);
      newArr.push(element);
    });
    setClientsNames(newArr);
  };

  //API Call(s)

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {
        history.push("/");
      });
  };

  const getClientName = () => {
    getToken();
    api
      .get(`cliente`, {
        params: { limit: 1000 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        processNames(response.data.data);
       
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const saveFields = () => {
    const arr: any = [];
    selectedFields.forEach((field: any, pos: number) => {
      const campo = {
        clienteId: clientName,
        campExcelAsigPos: pos,
        campExcelAsigNombre: field.campExcelAsigNombre,
        campExcelEsCampoEspecial: field.campExcelEsp,
        field: field.campExcelAsigNombre,
        campEspId: field.campEspId ? field.campEspId : null,
        orgId: "",
      };
      arr.push(campo);
    });
    const fieldsData = {
      columns: arr,
    };
    api
      .put(`/campoExcelAsigna_2-layout/`, fieldsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        setClientName("");
        setClientFields([]);
        setSelectedFields([]);
        creartToast(
          toast,
          "success",
          "Configurar Planilla",
          "Guardada correctamente",
          3000
        );
      })
      .catch((error) => {
      });
  };

  const getClientFields = (clientId: string) => {
    getToken();
    setClientFields([]);
    setSelectedFields([]);
    api
      .get(`campoExcelAsigna_2/?clienteId=${clientId}`, {
        params: { limit: 1000 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        processFields(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const processFields = (response: any) => {
    const newArr: any[] = [];
    response.unassignedColumns.forEach((column: any) => {
      let col = {};
      if (column.defaultField) {
        col = {
          campExcelAsigPos: 0,
          campExcelAsigNombre: column.data.fieldName,
          campExcelEsp: false,
        };
      } else {
        col = {
          campEspAsigId: column.data.campEspAsigId,
          campEspId: column.data.campEspId,
          clienteId: column.data.clienteId,
          campExcelAsigPos: 0,
          campExcelAsigNombre: column.data.campEspNombre,
          campExcelEsp: true,
        };
      }
      newArr.push(col);
    });
    const newArr2: any[] = [];
    response.assignedColumns.forEach((column: any) => {
      const col = {
        campEspAsigId: column.campEspAsigId,
        campEspId: column.campEspId,
        clienteId: column.clienteId,
        campExcelAsigPos: 0,
        campExcelAsigNombre: column.campExcelAsigNombre,
        campExcelEsp: column.campExcelEsCampoEspecial,
      };

      newArr2.push(col);
    });
    setClientFields(newArr);
    setSelectedFields(newArr2);
  };
const cancelar  = () =>  {
  creartToast(
    toast,
    "error",
    "Campos cancelados",
    "No se han guardado los cambios",
    6000
  );
  setClientName("");
  setClientFields([]);
  setSelectedFields([]);
}
  


  //useEffect

  useEffect(() => {
    callMsGraphTokens()
      .then((response) => {
        setToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
        history.push("/");
      });
  }, []);

  useEffect(() => {
    if (selectedFields) {
      const newArr: any[] = [];
      selectedFields.forEach((element: any) => {
        const newElement = {
          label: element.campEspNombre,
          type: element.tipoCampo,
          min: 0,
          max: 0,
          options: [""],
        };
        newArr.push(newElement);
      });
      setFieldsArray(newArr);
    }
  }, [selectedFields]);

  useEffect(() => {
    getClientName();
  }, [token]);

  //main return//
  return (
    
    <> <div className="col-12 m-0 pb-0 p-tabview-panel" aria-hidden="false" role="tabpanel" id="pr_id_1_content_1">
          <div className="mb-5 col-12  pb-0 mt-6">
            <CentralContainer />
          </div>

          <div className="col-12  pt-2" >
            <label className=" mr-2">Cliente:</label>
            <Dropdown
              value={clientName || ""}
              options={clientsNames}
              onChange={(e) => onChangeBussinessZone(e)}
              optionValue="clienteId"
              optionLabel="newName"
              filter
              placeholder="Seleccione cliente"
              appendTo="self"
            />
          </div>

          {clientName && clientName != "" ? (
            <>
              <PickList
                source={clientFields}
                target={selectedFields}
                itemTemplate={itemTemplateClient}
                sourceHeader="Campos fijos" 
                targetHeader="Campos seleccionados" 
                sourceStyle={{ height: "342px" }}
                targetStyle={{ height: "342px" }}
                onChange={onChange}
                filterBy="campExcelAsigNombre"
                sourceFilterPlaceholder="Search by name"
                targetFilterPlaceholder="Search by name"
                className="mt-5"
              />
              <div className="col-12 md:col-6 m-0 px-0" style={{ float: "right" }}>
             
              <Button style={{ float: "right", display: "revert" }}
                    icon={<CheckCircleOutlineOutlinedIcon className="mr-0 ml-1" style={{
                      float: "right"
                    }} ></CheckCircleOutlineOutlinedIcon>}
                    iconPos="right"
                label="Guardar cambios"
                className="p-button-success ml-3 mr-0"
                onClick={saveFields}
              />
              <a className="mt-2 cursor-pointer" onClick={cancelar} style={{ float: "right",color: "#333333",
                  textDecoration: "underline", }}>Cancelar</a>
              </div>
            </>
          ) : (
            ""
          )}
       
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default AddFormComponent;

{
  /*

        {field.type == 'text' ?
                    <div className="flex flex-row mt-4 col-12">
                      <div className='col-4'>
                        <label className=''>Ingrese max. caracteres:</label>
                      </div>
                      <div className='col-7'>
                        <InputText className=''
                          value={''}
                          onChange={(e) => updateLabelOnArray(e.target.value, i)}

                        />
                      </div>
                    </div>

                    : field.type == 'number' ?
                      <div className="flex flex-row mt-4 col-12">
                        <div className='col-4'>
                          <label className=''>Ingrese max. y min.:</label>
                        </div>
                        <div className='col-7'>
                          <InputText className=''
                            value={''}
                            onChange={(e) => updateLabelOnArray(e.target.value, i)}
                            placeholder='30-40'
                          />
                        </div>

                      </div>
                      : field.type == 'Select' ?
                        <div className="flex flex-column mt-4 col-12">
                          <div className='col-4 mb-3'>
                            <label className=''>Opciones:</label>
                          </div>
              

                            {field.options.map((option: string, j: number) => {
                              return < >
                                <div className='col-7 flex align-items-center my-2'>
                                  <RadioButton inputId="city1"
                                    name="city"
                                    value="Chicago"
                                    className='mr-3  '
                                  //  onChange={(e) => setCity(e.value)} 
                                  //  checked={city === 'Chicago'} 
                                  />

                                  <InputText className='mx-3'
                                    value={''}
                                    onChange={(e) => updateLabelOnArray(e.target.value, i)}
                                  />

                                  <span className="material-symbols-outlined cursor-pointer "
                                    onClick={() => deleteOption(i, j)}
                                  >delete </span>

                                  <span className="material-symbols-outlined cursor-pointer ml-3"
                                    onClick={() => addOption(i)}
                                  >add </span>

                                </div>
                              </>
                            })}




                        </div>
                        : ''
                  }

                */
}
