import "../../../../../assets/css/graficos-tw.min.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import IconArrowDoubleLeft from "../../../../ui/kit-svg/IconArrowDoubleLeft";
import IconArrowDoubleRight from "../../../../ui/kit-svg/IconArrowDoubleRight";

interface IFooterTable {
  length: number;
  setRange: Function;
  pageCurrent?: number;
}

export default function FooterTable(props: IFooterTable) {
  const { length, setRange, pageCurrent } = props;
  const [page, setPage] = useState<number>(1);
  const [initRange, setInitRange] = useState<number>(0);
  const [endRange, setEndRange] = useState<number>(0);
  const [pages, setPages] = useState<number>(1);
  const [offset, setOffset] = useState<any>({
    offset: { value: undefined, valid: true },
  });

  useEffect(() => {
    const { offset: currentOffset } = offset;
    const { value } = currentOffset;

    const rowsForPage: number = Number(value?.code ?? 4);

    const initRangeCurrent: number = page * rowsForPage - rowsForPage + 1;
    const endRangeCurrent: number =
      page * rowsForPage < length ? page * rowsForPage : length;

    setInitRange(initRangeCurrent);
    setEndRange(endRangeCurrent);

    setPages(
      parseInt(
        String(length / rowsForPage + (length % rowsForPage > 0 ? 1 : 0))
      )
    );
    setRange({ initRange: initRangeCurrent - 1, endRange: endRangeCurrent });
  }, [offset, page, length]);

  useEffect(() => {
    if (pageCurrent) {
      setPage(pageCurrent);
    }
  }, [pageCurrent]);

  return (
    <>
      <footer className="footer-table-dashboard">
        <div className="pagination-number">
          <button
            onClick={() => setPage(1)}
            disabled={page < 2}
            className={page < 2 ? "disabled" : ""}
          >
            <IconArrowDoubleLeft disabled={page < 2} />
          </button>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page < 2}
            className={page < 2 ? "disabled" : ""}
          >
            <KeyboardArrowLeftIcon />
          </button>
          <span className="number-pagination">{page}</span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={page === pages}
            className={page === pages ? "disabled" : ""}
          >
            <KeyboardArrowRightIcon />
          </button>
          <button
            onClick={() => setPage(pages)}
            disabled={page === pages}
            className={page === pages ? "disabled" : ""}
          >
            <IconArrowDoubleRight disabled={page === pages} />
          </button>
        </div>
      </footer>
    </>
  );
}
