import { useContext, useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconAsignarProceso from "../../../../ui/icons-seleccion/IconAsignarProceso";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ModalAsignarProceso from "../../../../ui/kit-modal/asignarProceso/ModalAsignarProceso";

interface IActionMasive {
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
  listSelect: any;
  setFormData: Function;
  formData: any;
  listRefresh: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    listSelect,
    setListadoSeleccionado,
    listRefresh,
    setFormData,
    formData,
  } = props;
  const {
    token,
    listaCandidatoDisponible,
    listaPostulacionRecibida,
    getPostulacionRecibida,
    postProcesosPostulados,
    listaProcesoPostulado,
    putAsignarProcesoPostulacion,
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [isLoadingProcess, setIsLoadingProcess] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickAsignarProceso = () => {
    setIsOpenModalAsignarProceso(true);
  };

  return (
    <>
      <div className="container-actions">
        <div className="content-actions-masive">
          <Tooltip text="Asignar Proceso">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-reasignar" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickAsignarProceso}
            >
              <IconAsignarProceso fill={"#0065DD"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="PROCESO POSTULACIÓN"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="procesoPostulacion"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="MEDIO POSTULACIÓN"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="medioPostulacion"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAsignarProceso && (
        <ModalGenerico
          title="Asignar Proceso"
          width="w-70"
          subtitle="Elige un Proceso para asignar a los candidatos seleccionados."
          positionSubTitle="start"
          messageBottom="Los registros asignados se moverán a la lista de candidatos con proceso activo."
          positionMessageBottom="start"
          buttonBackText="Cerrar"
          buttonGoText="Asignar Proceso"
          buttonGoStyle="option"
          onChangeBack={() => {
            setIsOpenModalAsignarProceso(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModalAsignarProceso(false);
            putAsignarProcesoPostulacion({
              token,
              formData,
              setIsLoading: setIsLoadingProcess,
              listRefresh,
            });
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalAsignarProceso
            token={token}
            candidates={listadoSeleccionado}
            listOriginal={listaPostulacionRecibida}
            listRefresh={listRefresh}
            postProcesosPostulados={
              postProcesosPostulados && postProcesosPostulados
            }
            listaProcesoPostulado={listaProcesoPostulado}
            setFormData={setFormData}
            formData={formData}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
