import '../../../assets/css/adminComponentUi.min.css';

interface ISelectOption {
    id?: number;
    nameAttr?:string;
    value?: any;
    disabled?: boolean;
    text?: any;
    selected?: boolean;
    className?: string;
    extraClass?: any;
}

export default (props: ISelectOption) => {
  const {
    id,
    value,
    text,
    disabled, 
    nameAttr,
    extraClass,
    selected,
    className,
  } = props;

  return (
    <option disabled={disabled ? true : false} key={id} value={value} selected={selected} className={`${className} ${extraClass ? '' : extraClass}`}>
      {text}
    </option>
  );
};
