import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import ListadoCandidatoValidado from "./CandidatoValidado/ListadoCandidatoValidado";

const TablaRegistroValidadoMasivo = () => {
  const { toasts, getListaGeneral, getExclusiones, getToken, token } =
    useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getListaGeneral({ token });
      getExclusiones({ token });
    }
  }, [token]);

  return (
    <>
      {toasts}
      <ListadoCandidatoValidado />
    </>
  );
};

export default TablaRegistroValidadoMasivo;
