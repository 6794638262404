import { useContext, useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ValidationKamContext from "../../../context/ValidationKam/ValidationKamContext";
import IconLoading from "../../ui/kit-svg/IconLoading";
import { IClientes } from "../../../interfaces/IValidationKam";

const SeccionCliente = () => {
  const { token, getToken, countAssign, getCounts, isLoading, isError } =
    useContext(ValidationKamContext);
  const [itemMenu, setItemMenu] = useState<string>("");
  const [pagesNav, setPagesNav] = useState<string>("");

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getCounts({ token });
    }
  }, [token]);

  useEffect(() => {
    const urlCompleta = window.location.href;
    const partes = urlCompleta.split("/");
    const primerValor = partes[3];
    setPagesNav(primerValor);
  }, [window.location.href]);

  const handleClickGo = (values: any) => {
    const { nav, ruta } = values;
    window.location.href = `${nav}/${ruta}`;
  };

  const handleClickGoSubMenu = (values: any) => {
    const { ruta } = values;
    window.location.href = `${ruta}`;
  };

  return (
    <>
      <ul className="content-nav">
        {["contratacion", "validacion"].includes(pagesNav) && (
          <>
            <li
              onClick={() => {
                handleClickGoSubMenu({
                  ruta: "validacion",
                });
              }}
              className={`nav-tag ${pagesNav == "validacion" && "active"}`}
            >
              Asistencia y Validación
            </li>
          </>
        )}
        {["validacioncliente"].includes(pagesNav) &&
          ["Asistencia y Validación"].map((item: any, key: number) => {
            return (
              <li
                key={item}
                onClick={() => {
                  setItemMenu(item);
                }}
                className={`nav-tag ${
                  pagesNav == "validacioncliente" && "active"
                }`}
              >
                {item}
              </li>
            );
          })}
      </ul>

      {["validacion"].includes(pagesNav) && (
        <>
          <h1 className="title-welcome">
            Bienvenido a la sección de Asistencia y Validación de Candidatos
          </h1>
          <h1 className="title-page-intro">RESUMEN CANDIDATOS POR CLIENTE</h1>
          <span className="subtitle-page">
            Aquí puede revisar los candidatos pendientes de validación y/o
            asistencia.
          </span>
        </>
      )}

      {["contratacion"].includes(pagesNav) && (
        <>
          <h1 className="title-welcome">
            Bienvenido a la sección de Contratación de Candidatos
          </h1>
          <h1 className="title-page-intro">RESUMEN CANDIDATOS POR CLIENTE</h1>
          <span className="subtitle-page">
            Aquí puede revisar los candidatos en etapa de contratación
          </span>
        </>
      )}

      {isLoading && (
        <div className="content-loading">
          <IconLoading className="loading" />
        </div>
      )}

      {isError && (
        <div className="content-loading">
          <h3>Error al cargar los datos...</h3>
        </div>
      )}

      {countAssign &&
        countAssign?.map((values: IClientes) => {
          const { clienteId, clienteNombre } = values;
          return (
            <>
              <section
                key={clienteId}
                className="section-nav"
                onClick={() =>
                  handleClickGo({
                    nav: pagesNav === "contratacion" ? "resumen" : pagesNav,
                    ruta: clienteId,
                  })
                }
              >
                <div className="section-option">{clienteNombre}</div>
                <ArrowForwardIcon />
              </section>
            </>
          );
        })}
    </>
  );
};

export default SeccionCliente;
