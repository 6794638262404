import { useEffect, useState } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesConGraficoEje } from "../styles";
import logoTeamworkAzul from "../../../../assets/images/pdfPsicolaboral/logoTeamworkAzul.png";
import portada from "../../../../assets/images/pdfPsicolaboral/imgPortada.png";
import {
  IPDFPagePortada,
  IPDFTitulosEducacion,
} from "../../../../interfaces/ISeleccion";
import { formatNameUpper } from "../../../../functions/kit-mdtw/texts";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYY,
} from "../../../../functions/kit-mdtw/dates";
import { formatMoneyChilean } from "../../../../functions/kit-mdtw/moneys";

const ConGraficoEjecutivo2 = (props: IPDFPagePortada) => {
  const { formList, grafico } = props;
  const fechactual = new Date();
  const [listRender, setListRender] = useState<any>({
    primerNombre: "",
    primerApellido: "",
    personaDNI: "",
    fechaNacimiento: "",
    estadoCivil: "",
    fonoPrincipal: "",
    email: "",
    disponibilidad: "",
    reclutador: "",
    pretensionRenta: "",
    pruebasAplicadas: "",
    areaNegocioCod: "",
    experienciaRelevante: "",
    conocimientosTecnicos: "",
    sintesis: "",
    conclusionInforme: "",
    fortalezas: "",
    oportunidades: "",
  });

  useEffect(() => {
    if (formList) {
      const { contenido } = formList ?? { contenido: {} };
      const { portada } = formList ?? { portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page size="LETTER">
          <View style={stylesConGraficoEje.body}>
            <Image src={portada} style={stylesConGraficoEje.imgPortada} />
            <View style={stylesConGraficoEje.containerSugerencia}>
              <View style={stylesConGraficoEje.containerSugerenciaText}>
                <Text style={stylesConGraficoEje.contentSugerenciaTitle}>
                  EXPERIENCIA RELEVANTE
                </Text>
                <Text style={stylesConGraficoEje.contentSugerenciaMessage}>
                  {listRender.experienciaRelevante}
                </Text>
              </View>
              <View style={stylesConGraficoEje.containerSugerenciaText}>
                <Text style={stylesConGraficoEje.contentSugerenciaTitle}>
                  CONOCIMIENTOS TÉCNICOS
                </Text>
                <Text style={stylesConGraficoEje.contentSugerenciaMessage}>
                  {listRender.conocimientosTecnicos}
                </Text>
              </View>
            </View>
            <View style={stylesConGraficoEje.containerPostulante}>
              <Text style={stylesConGraficoEje.contentPostulanteTitle}>
                IDENTIFICACIÓN DEL POSTULANTE
              </Text>
              <View style={stylesConGraficoEje.contenInfotPostulante}>
                <View style={stylesConGraficoEje.contentPostulante}>
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Nombre:
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>Rut:</Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Fecha De Nacimiento:
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>Teléfono:</Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>Mail:</Text>
                  {listRender.email.length > 28 && (
                    <Text style={stylesConGraficoEje.postulanteTitle}> </Text>
                  )}
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Comuna:
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Estado Civil:
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Disponibilidad:
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteTitle}>
                    Pretensión De Renta:
                  </Text>
                </View>
                <View style={stylesConGraficoEje.contentPostulante}>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatNameUpper({ texto: listRender.primerNombre })}{" "}
                    {formatNameUpper({ texto: listRender.primerApellido })}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {listRender.personaDNI || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatDateToDDMMAAAA({
                      date: listRender.fechaNacimiento,
                      format: "/",
                    }) || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {listRender.fonoPrincipal || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {listRender.email.toLowerCase() || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatNameUpper({ texto: listRender.comuna }) || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatNameUpper({ texto: listRender.estadoCivil }) || "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatNameUpper({ texto: listRender.disponibilidad }) ||
                      "-"}
                  </Text>
                  <Text style={stylesConGraficoEje.postulanteInfo}>
                    {formatMoneyChilean({ texto: listRender.pretensionRenta })}
                    {".-"}
                  </Text>
                </View>
              </View>
            </View>

            <View style={stylesConGraficoEje.containerEducacion}>
              <Text style={stylesConGraficoEje.contentEducacionTitle}>
                EDUCACIÓN
              </Text>

              {listRender.titulosEducacion &&
                listRender.titulosEducacion
                  .slice(0, 1)
                  .map((item: IPDFTitulosEducacion) => {
                    return (
                      <>
                        <View
                          style={stylesConGraficoEje.contentEducacionContent}
                        >
                          <Text
                            style={
                              stylesConGraficoEje.contentEducacionTitleProf
                            }
                          >
                            {item.nombre}
                          </Text>
                          <Text
                            style={stylesConGraficoEje.contentEducacionMessage}
                          >
                            {formatDateToYYYY({ date: item.fechaEgreso })}
                          </Text>
                        </View>
                        <View
                          style={stylesConGraficoEje.contentEducacionContent}
                        >
                          <Text
                            style={
                              stylesConGraficoEje.contentEducacionInstitucionMessage
                            }
                          >
                            {item.institucion}
                          </Text>
                        </View>
                      </>
                    );
                  })}
            </View>

            <View style={stylesConGraficoEje.containerMotivacion}>
              <Text style={stylesConGraficoEje.contentMotivacionTitle}>
                MOTIVACIÓN AL CARGO
              </Text>
              <Text style={stylesConGraficoEje.contentMotivacionMessage}>
                {listRender.motivacion}
              </Text>
            </View>
            <View style={stylesConGraficoEje.lineaEducacion}></View>
            <View style={stylesConGraficoEje.containerFoda}>
              <View style={stylesConGraficoEje.contentFoda}>
                <Text style={stylesConGraficoEje.contentFodaTitle}>
                  FORTALEZAS
                </Text>
                <Text style={stylesConGraficoEje.contentFodaMessage}>
                  {listRender.fortalezas ?? " "}
                </Text>
              </View>
              <View style={stylesConGraficoEje.contentFoda}>
                <Text style={stylesConGraficoEje.contentFodaTitle}>
                  OPORTUNIDADES DE MEJORA
                </Text>
                <Text style={stylesConGraficoEje.contentFodaMessage}>
                  {listRender.oportunidades ?? " "}
                </Text>
              </View>
            </View>

            {grafico && (
              <Image
                src={grafico}
                style={stylesConGraficoEje.containerGrafico}
              />
            )}

            <View style={stylesConGraficoEje.containerConclusion}>
              <Text style={stylesConGraficoEje.contentConclusionTitle}>
                CONCLUSIÓN
              </Text>
              <Text style={stylesConGraficoEje.contentConclusionMessage}>
                {listRender.conclusionInforme}
              </Text>
            </View>
            <View style={stylesConGraficoEje.lineaConclusion}></View>
            <Image
              src={logoTeamworkAzul}
              style={stylesConGraficoEje.logoTeamWork}
            ></Image>
            <View style={stylesConGraficoEje.containerFooter}>
              <Text style={stylesConGraficoEje.contentFooterTitle}>
                Psicólogo Evaluador
              </Text>
              <Text style={stylesConGraficoEje.contentFooternName}>
                {listRender.reclutador}
              </Text>
              <Text style={stylesConGraficoEje.contentFooternDescripcion}>
                La información contenida en este informe es de carácter
                confidencial; debe ser utilizada sólo para los fines de
                selección y por expresa autorización del Psicólogo firmante.
              </Text>
            </View>
            <View style={stylesConGraficoEje.containerFooterPie}>
              <Text style={stylesConGraficoEje.containerFooterPieText}>
                Copyright {fechactual.getFullYear()} Teamwork Chile
              </Text>
            </View>
          </View>
        </Page>
      )}
    </>
  );
};

export default ConGraficoEjecutivo2;
