import { useContext, useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconRegistroIndividual from "../../../../ui/kit-svg/icon-contratos/IconRegistroIndividual";
import ModalRegistroIndividual from "../../modals/ModalRegistroIndividual";
import RegistroIndividual from "../../modals/RegistroIndividual";
import ContratosContext from "../../../../../context/Contratos/ContratosContext";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import DetenerSolicitud from "../../modals/rechazarSolicitud/DetenerSolicitud";
import {
  IContratosColaborador,
  IListaSolicitudesPendientes,
} from "../../../../../interfaces/IContratos";

interface IActionMasive {
  listadoSeleccionado: string[];
  setListadoSeleccionado: Function;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: string;
  setCandidates: Function;
  listSelect: any;
  listRefresh: Function;
  selectCurrent: any;
  setSelectCurrent: Function;
  fechaSolicitud: string;
  setFechaSolicitud: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    listSelect,
    setListadoSeleccionado,
    listRefresh,
    selectCurrent,
    setSelectCurrent,
    fechaSolicitud,
    setFechaSolicitud,
  } = props;
  const {
    token,
    userId,
    listaRegistroColaborador,
    postRegistroColaborador,
    putAprobarSolicitud,
    putRechazarSolicitud,
  } = useContext(ContratosContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalRegistro, setIsOpenModalRegistro] =
    useState<boolean>(false);
  const [isOpenModalAceptarSolicitud, setIsOpenModalAceptarSolicitud] =
    useState<boolean>(false);
  const [isOpenModalRechazarSolicitud, setIsOpenModalRechazarSolicitud] =
    useState<boolean>(false);
  const [formCandidate, setFormCandidate] = useState<any>({});
  const [activeTab, setActiveTab] = useState<string>("");
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [listaAceptados, setListaAceptados] = useState<string[]>([]);

  useEffect(() => {
    try {
      const localString: string | null =
        localStorage.getItem(localStorageFilter);
      if (localString) {
        const dataObject: boolean = Object.values(JSON.parse(localString)).some(
          (value: any) => value?.length > 0
        );
        setDataObject(dataObject);
      }
    } catch (error) {
      console.log(error);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
    if (listaRegistroColaborador) {
      const [primerArray]: IContratosColaborador[] = Object.values(
        listaRegistroColaborador
      );
      setFormCandidate(primerArray ?? []);
      setActiveTab(primerArray?.baseContratoId ?? "");
    }
  }, [listaRegistroColaborador, listadoSeleccionado]);

  useEffect(() => {
    if (formCandidate) {
      const [fechSolici]: IListaSolicitudesPendientes[] = listSelect.filter(
        ({ baseContratoId, fechaSolicitud }: IListaSolicitudesPendientes) =>
          baseContratoId == formCandidate.baseContratoId && fechaSolicitud
      );
      setFechaSolicitud(fechSolici?.fechaSolicitud ?? "");
    }
  }, [formCandidate]);

  const handleClickModalRegistro = () => {
    try {
      if (token) {
        postRegistroColaborador({
          token,
          baseContratos: listadoSeleccionado,
          setIsLoading: setIsLoadingModal,
        });
      }
      setIsOpenModalRegistro(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRechazarSolicitud = async (): Promise<void> => {
    try {
      if (token) {
        const arregloTransformado = Object.keys(selectCurrent).map(
          (baseContratoId: string) => {
            const motivo: string = selectCurrent[baseContratoId].value;

            return {
              baseContratoId,
              motivo,
            };
          }
        );
        await putRechazarSolicitud({
          token,
          contratos: arregloTransformado,
        });
        setIsOpenModalRechazarSolicitud(false);
        setListadoSeleccionado(
          listadoSeleccionado.filter(
            (item: string) => item != formCandidate.baseContratoId
          )
        );
        delete listaRegistroColaborador[formCandidate.baseContratoId];
        listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickAprobarSolicitud = async (): Promise<void> => {
    try {
      setIsOpenModalAceptarSolicitud(false);
      if (!listaAceptados.includes(formCandidate.baseContratoId)) {
        setListaAceptados((listaAceptados) =>
          listaAceptados.concat(formCandidate.baseContratoId)
        );
        if (Object.keys(listaRegistroColaborador).length > 1) {
          delete listaRegistroColaborador[formCandidate.baseContratoId];
          const [primerArray]: IContratosColaborador[] = Object.values(
            listaRegistroColaborador
          );
          handleOpenTab({ baseContratoId: primerArray.baseContratoId ?? "" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDescargarAntecedentes = async (): Promise<void> => {
    try {
      if (token) {
        const nuevoList = listadoSeleccionado.filter(
          (item: string) => !listaAceptados.includes(item)
        );
        await putAprobarSolicitud({
          token,
          contratos: listaAceptados,
        });
        setIsOpenModalAceptarSolicitud(false);
        setListadoSeleccionado(nuevoList);
        listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenTab = (values: any) => {
    const { baseContratoId } = values;
    try {
      setIsLoadingModal(true);
      if (baseContratoId && listaRegistroColaborador) {
        setActiveTab(baseContratoId);
        const copiaArray: IContratosColaborador[] =
          Object.values(listaRegistroColaborador) ?? [];
        const copiaFiltrada: IContratosColaborador | undefined =
          copiaArray.find(
            (item: IContratosColaborador) =>
              item.baseContratoId == baseContratoId
          );
        setFormCandidate(copiaFiltrada ?? []);
      }
    } catch (error) {
      console.log("error al cambiar de candidato" + error);
    } finally {
      setTimeout(() => setIsLoadingModal(false), 200);
      // setIsLoadingModal(false);
    }
  };

  return (
    <>
      <div className="container-actions">
        <div className="content-actions-masive">
          <Tooltip text="Asignar Proceso">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-reasignar" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickModalRegistro}
            >
              <IconRegistroIndividual
                fill={"#0065DD"}
                disabled={!buttonEnabled}
              />
            </button>
          </Tooltip>
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["PEND_REVISION"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="FECHA SOLICITUD"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaSolicitud"
          />
          <InputFiltro
            layer="TIPO DOCUMENTO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="tipoDocumento"
          />
          <InputFiltro
            layer="FICHA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="ficha"
          />
          <StateFiltro
            layer="DIVISIÓN TW"
            listState={["TWEST", "TWRRHH", "TWC", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIOS"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="SOLICITANTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="solicitante"
          />
        </FiltroGenerico>
      )}

      {isOpenModalRegistro && (
        <ModalRegistroIndividual
          title="REGISTRO INDIVIDUAL COLABORADOR FICHA"
          tabs={Object.values(listaRegistroColaborador) ?? []}
          width="w-80"
          activeTab={activeTab}
          isLoading={isLoadingModal}
          listaAceptados={listaAceptados}
          handleOpenTab={handleOpenTab}
          buttonGoStyle="option"
          buttonGoText="Descargar Antecendentes"
          positionButton="end"
          masive
          onChangeClose={() => {
            setIsOpenModalRegistro(false);
            setListaAceptados([]);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickDescargarAntecedentes();
            setListaAceptados([]);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <RegistroIndividual
            formCandidate={formCandidate}
            isLoading={isLoadingModal}
            token={token}
            userId={userId}
            masive
            onChangeAprobar={() => setIsOpenModalAceptarSolicitud(true)}
            onChangeRechazar={() => setIsOpenModalRechazarSolicitud(true)}
          />
        </ModalRegistroIndividual>
      )}

      {isOpenModalAceptarSolicitud && (
        <ModalGenerico
          title={`Estás aceptando la solicitud, ¿Deseas continuar?.`}
          width="w-70"
          subtitle="Para aceptar la solicitud y continuar la revisión presione continuar revisión."
          positionSubTitle="start"
          message="Si desea aceptar y descargar los antecedentes de este registro presione descargar antecedentes, el registro se moverá a solicitudes procesadas y se descargará un archivo con los antecedentes correspondientes para crear el documento en firma digital."
          positionMessage="start"
          buttonBackText="Descargar antecendentes"
          buttonGoStyle="confirm"
          buttonGoText="Aceptar y continuar"
          onChangeBack={() => {
            setIsOpenModalAceptarSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickAprobarSolicitud();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        ></ModalGenerico>
      )}

      {isOpenModalRechazarSolicitud && (
        <ModalGenerico
          title="RECHAZAR SOLICITUD"
          width="w-70"
          subtitle="Estás rechazando la siguiente solicitud de contrato, indique el motivo: "
          messageBottom="Al continuar se le informará del rechazo y el motivo al solicitante. Para confirmar presione rechazar solicitud."
          buttonBackText="Cancelar"
          buttonGoStyle="delete"
          buttonGoText="Rechazar Solicitud"
          onChangeBack={() => {
            setIsOpenModalRechazarSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickRechazarSolicitud();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <DetenerSolicitud
            list={listadoSeleccionado}
            listData={[formCandidate]}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
            fechaSolicitud={fechaSolicitud}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
