import { IOptionDropDownList } from "../../interfaces/IDropDownList";

interface IUseCreateList {
  list: any[];
  keyCode: string;
  keyName: string;
}

export const useCreateListCustomized = (promps: IUseCreateList): IOptionDropDownList[] => {
  const { list, keyCode, keyName } = promps;
  return list.map((item: any) => {
    return {
      code: String(item[keyCode]),
      name: String(item[keyName])
    }
  })
}