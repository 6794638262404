import { useContext } from "react";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import "../../../../assets/css/OperacionContratacion.min.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import MediaQuery from "../../../ui-responsive/MediaQuery";
import {
  IListaCandidatos,
  Params,
} from "../../../../interfaces/IOperacionesContratacion";
import ButtonIcon from "../../../ui/adminAssist/ButtonIcon";
import IconSolicitudObservacion from "../../../ui/kit-svg/IconSolicitudObservacion";
import IconSolicitudAcept from "../../../ui/kit-svg/IconSolicitudAcept";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import IconPageRenova from "../../../ui/icons-operaciones/IconPageRenova";
import IconPageBaja from "../../../ui/icons-operaciones/IconPageBaja";
import IconPageNewContrato from "../../../ui/icons-operaciones/IconPageNewContrato";
import IconPageUpdate from "../../../ui/icons-operaciones/IconPageUpdate";
import IconBajaConfirm from "../../../ui/icons-operaciones/IconBajaConfirm";
import SeccionAccionMasiveDotacion from "./SeccionActionMasiveDotacion";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import ModalFicha from "../../SistemaValidacionGeneral/AsistenciaValidacionKam/Modals/ModalFicha";
import ModalConfirmBaja from "../Modals/ModalConfirmBaja";
import IconAnulado from "../../../ui/icons-operaciones/IconAnulado";
import IconDetenido from "../../../ui/icons-operaciones/IconDetenido";
import ModalNoProcesado from "../../../ui/kit-modal/noProcesado/ModalNoProcesado";
import ModalGenerico from "../../../ui/kit-modal/ModalGenerico";
import FooterTable from "../../../ui/kit-mdtw/table/FooterTable";
import IconProxTopeLegal from "../../../ui/icons-operaciones/IconProxTopeLegal";
import IconSolAceptadaDocSolicitado from "../../../ui/kit-svg/IconSolAceptadaDocSolicitado";

const TablaDotacionVigente = () => {
  const {
    documentosDotacionVigente,
    listaDocumentosVigentes,
    listDocumentosVigentes,
    setListDocumentosVigentes,
    listNoProcess,
    openModalNoProcess,
    setOpenModalNoProcess,
  } = useContext(OperacionContratacionContext);

  const {
    setVisibleActionBlocking,
    getListCandidateEnviadoContrato,
    BootstrapTooltip,
    setABusPerId,
  } = useContext(ValidationKamContext);

  const { token, getToken, getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [isOpenModalIndividual, setIsOpenModalIndividual] =
    useState<boolean>(false);
  const [gridDotacionActiva, setGridDotacionActiva] = useState<
    IListaCandidatos[]
  >([]);
  const [dataForBaja, setDataForBaja] = useState<any>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [ficha, setFicha] = useState<string>("");
  const [listDotacionActivaFull, setListDotacionActivaFull] = useState<any[]>(
    []
  );
  const [estadoContrato, setEstadoContrato] = useState<string>("");
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroDotacionVigente"
  );
  const [currentProcesos, setCurrentProcesos] = useState<string[]>([]);
  const [fueraPlazoForRenov, setFueraPlazoForRenov] = useState<boolean>(false);
  const objetoSendFiltro = {};

  useEffect(() => {
    listDocumentosVigentes.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listDocumentosVigentes]);
  const [estadoRegistro, setEstadoRegistro] = useState<string>("");
  const [fechaValidacion, setFechaValidacion] = useState<string>("");
  const [renovacion, setRenovacion] = useState<boolean>(false);
  const [nuevoContrato, setNuevoContrato] = useState<boolean>(false);
  const [editar, setEditar] = useState<boolean>(false);
  const [isOpenModalBaja, setIsOpenModalBaja] = useState<boolean>(false);
  const [formFilter, setFormFilter] = useState<any>({
    division: [],
    nombre: [],
    ficha: [],
    codCargo: [],
    areaNegocio: [],
    fechaInicio: [],
    fechaTermino: [],
    topeLegal: [],
    estado: [],
    cliente:[idCliente]
  });
  useEffect(() => {
    openModalNoProcess;
  }, [setOpenModalNoProcess, listNoProcess]);

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        documentosDotacionVigente({
          token,
          objetoSendFiltro: JSON.parse(copyStorage),
        });
      } else {
        documentosDotacionVigente({
          token,
          objetoSendFiltro: formFilter,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaDocumentosVigentes) {
      const arrayList = listaDocumentosVigentes.map(
        (item: any) => item.personaDNI
      );
      setCurrentProcesos(arrayList);
    }
  }, [listaDocumentosVigentes]);

  useEffect(() => {
    if (token) {
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
    }
  }, [token]);

  useEffect(() => {
    if (ficha) {
      setFicha(ficha);
    }
  }, [setFicha, ficha]);

  useEffect(() => {
    if (token && rutCandidate) {
      getToken();
      getRegistroCandidato({
        personaRut: rutCandidate,
        token,
      });
    }
  }, [token, listDotacionActivaFull, listDocumentosVigentes]);

  useEffect(() => {
    if (listaDocumentosVigentes) {
      let arrayGridFilter = [...listaDocumentosVigentes];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridDotacionActiva(arrayGridFilter);

      setListDotacionActivaFull(
        arrayGridFilter.map((item: IListaCandidatos) => {
          return {
            personaDNI: item.personaDNI,
            busPerId: item.busPerId,
            primerNombre: item.primerNombre,
            primerApellido: item.primerApellido,
            fechaValidacion: item.fechaValidacion,
            areaNegocioCod: item.areaNegocioCod,
            tipoContrato: listaRegistroCandidato?.contratacion?.tipoDocumento,
            estadoRegistro: item.estadoRegistro,
            division: item.division,
            ficha: item.ficha,
            causal: item.letra,
          };
        })
      );
    }
  }, [listaDocumentosVigentes, range]);

  const handleClickAddListMasive = (
    personaDNI: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    areaNegocioCod: string,
    division: String,
    tipoDocumentoValue: string,
    estadoRegistro: string,
    fechaInicioContrato: string,
    fechaTerminoContrato: string,
    ficha: string,
    fueraPlazo: boolean,
    letra: string,
    nuevaFechaInicio: string,
    areaNegocioId: string
  ) => {
    const currentService = [...listDocumentosVigentes];
    const dataObject = {
      personaDNI,
      busPerId,
      primerNombre,
      primerApellido,
      areaNegocioCod,
      division,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      estadoRegistro,
      fechaInicioContrato,
      fechaTerminoContrato,
      ficha,
      fueraPlazo,
      letra,
      nuevaFechaInicio,
      areaNegocioId,
    };

    if (!currentService.some((c: any) => c.personaDNI === personaDNI)) {
      currentService.push(dataObject);
    } else {
      const positionDeleted = currentService.findIndex(
        (data) => data.personaDNI === personaDNI
      );
      currentService.splice(positionDeleted, 1);
    }

    setListDocumentosVigentes(currentService);
  };

  const handleClickPersonIdMasive = (tipoDocumentoValue: string) => {
    listDocumentosVigentes.length == listDotacionActivaFull.length
      ? setListDocumentosVigentes([])
      : setListDocumentosVigentes(
          listDotacionActivaFull.map((item: IListaCandidatos) => {
            setRutCandidate(item.personaDNI);
            return {
              personaDNI: item.personaDNI,
              busPerId: item.busPerId,
              primerNombre: item.primerNombre,
              primerApellido: item.primerApellido,
              areaNegocioCod: item.areaNegocioCod,
              division: item.division,
              tipoDocumentoValue:
                item.division === "TWEST"
                  ? "CONTRATO PLAZO FIJO"
                  : "CONTRATO INDEFINIDO",
              estadoRegistro: item.estadoRegistro,
              fechaInicioContrato: item.fechaInicioContrato,
              fechaTerminoContrato: item.fechaTerminoContrato,
              ficha: item.ficha,
              fueraPlazo: item.fueraPlazo,
              causal: item.letra,
              nuevaFechaInicio: item.nuevaFechaInicio,
            };
          })
        );
  };

  const handleClickModalDatosIndividual = ({
    personaDNI,
    busPerId,
    ficha,
    estadoRegistro,
    fechaTerminoContrato,
    fueraPlazo,
  }: any) => {
    let arr = [];
    arr.push(busPerId);
    setABusPerId(arr);
    setRenovacion(true);
    setEstadoRegistro(estadoRegistro);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDNI);
    setFicha(ficha);
    setFechaValidacion(fechaTerminoContrato);
    setNuevoContrato(false);
    setEditar(false);
    setFueraPlazoForRenov(fueraPlazo);
  };

  const handleClickModalDatosIndividualEdit = ({
    personaDNI,
    busPerId,
    ficha,
    estadoRegistro,
    fechaValidacion,
  }: any) => {
    let arr = [];
    arr.push(busPerId);
    setABusPerId(arr);
    setRenovacion(false);
    setEstadoRegistro(estadoRegistro);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDNI);
    setFechaValidacion(fechaValidacion);
    setNuevoContrato(false);
    setEditar(true);
    setFicha(ficha);
  };

  const handleClickModalConfirmBaja = ({
    personaDNI,
    busPerId,
    primerNombre,
    primerApellido,
    fechaValidacion,
    areaNegocioCod,
    estadoRegistro,
    fechaInicioContrato,
    fechaTerminoContrato,
    ficha,
    division,
    causalLetra,
  }: any) => {
    const dataObject = {
      personaDNI,
      busPerId,
      primerNombre,
      primerApellido,
      fechaValidacion,
      areaNegocioCod,
      estadoRegistro,
      fechaInicioContrato,
      fechaTerminoContrato,
      ficha,
      division,
      causalLetra,
    };
    setDataForBaja(dataObject);
    setIsOpenModalBaja(true);
  };

  const handleClickModalNuevoContrato = ({
    personaDNI,
    busPerId,
    ficha,
    estadoRegistro,
    fechaValidacion,
  }: any) => {
    let arr = [];
    arr.push(busPerId);
    setABusPerId(arr);
    setRenovacion(false);
    setNuevoContrato(true);
    setEstadoRegistro(estadoRegistro);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDNI);
    setFechaValidacion(fechaValidacion);
    setEditar(false);
    setFicha(ficha);
  };

  return (
    <>
      <section className="content-seleccion-multipleDota action-in-line">
        <div className="content-multiple">
          <button
            className="button-multiple"
            onClick={() => {
              handleClickPersonIdMasive(
                listaRegistroCandidato?.contratacion?.tipoDocumento
              );
            }}
          >
            {listDocumentosVigentes.length == listDotacionActivaFull.length &&
            listDocumentosVigentes.length > 0 ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </button>
          <label className="title-multiple">
            Seleccionar Todo{" "}
            <i className="selects">
              Ítems Seleccionados: {listDocumentosVigentes.length}
            </i>
          </label>
        </div>
        <SeccionAccionMasiveDotacion
          token={token}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
        />
      </section>
      <div className="content-header-table">
        <span>Documentos Vigentes Dotación</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th></th>

              <th>Estado</th>
              <th>Nombre</th>
              <th>Ficha</th>
              {width <= sm ? (
                <>
                  <th>Área de Negocio</th>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>
                    División
                    <br /> TW
                  </th>
                  <th>
                    Código <br /> Cargo
                  </th>
                  <th>
                    Área de <br /> Negocio
                  </th>
                  <th>
                    Fecha <br /> Inicio
                  </th>
                  <th>
                    Fecha <br /> Termino
                  </th>
                  <th>
                    Tope <br /> Legal
                  </th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridDotacionActiva &&
              gridDotacionActiva.map((item: IListaCandidatos) => {
                const {
                  busPerId,
                  busquedaId,
                  areaNegocioId,
                  areaNegocioCod,
                  ficha,
                  personaDNI,
                  primerNombre,
                  segundoNombre,
                  primerApellido,
                  sesgundoApellido,
                  fechaInicioContrato,
                  fechaTerminoContrato,
                  comentario,
                  division,
                  historial,
                  estadoRegistro,
                  causalLetra,
                  codCargo,
                  topeLegal,
                  fueraPlazo,
                  letra,
                  nuevaFechaInicio,
                } = item;
                return (
                  <>
                    <tr
                      key={personaDNI}
                      className={
                        listDotacionActivaFull.length > 0 &&
                        listDocumentosVigentes.some(
                          (l: any) => l.personaDNI === personaDNI
                        )
                          ? "selected borderdashed"
                          : "borderdashed"
                      }
                    >
                      <td>
                        <ButtonIcon
                          onClick={() => {
                            setRutCandidate(personaDNI);
                            handleClickAddListMasive(
                              personaDNI,
                              busPerId,
                              primerNombre,
                              primerApellido,
                              areaNegocioCod,
                              division,
                              listaRegistroCandidato?.contratacion
                                ?.tipoDocumento,
                              estadoRegistro,
                              fechaInicioContrato,
                              fechaTerminoContrato,
                              ficha,
                              fueraPlazo,
                              letra,
                              nuevaFechaInicio,
                              areaNegocioId
                            );
                          }}
                        >
                          {listDocumentosVigentes.some(
                            (l: any) => l.personaDNI === personaDNI
                          ) ? (
                            <>
                              <CheckBoxIcon />
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                            </>
                          )}
                        </ButtonIcon>
                      </td>
                      <td>
                        {" "}
                        <div className="content-icon-table">
                          {estadoRegistro == "BAJA" ? (
                            <>
                              <IconBajaConfirm className="icon-estado" />
                              <span className="textStado">
                                Vigente con sol. de <br />
                                baja confirmada
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRegistro == "PROX-VENCER" ? (
                            <>
                              <IconSolicitudObservacion
                                fill={"#BE2A2A"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Documento vigente <br />
                                próximo a vencer
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRegistro == "PROX_VENCER_TOPELEGAL" ? (
                            <>
                              <IconProxTopeLegal
                                fill={"#BE2A2A"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Doc vigente próx. a <br />
                                vencer en tope legal
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRegistro == "VIGENTE" ? (
                            <>
                              <IconSolAceptadaDocSolicitado className="icon-estado" />
                              <span className="textStado">
                                Documento Vigente
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRegistro == "VIGENTE_BAJA_DETENIDA" ||
                          estadoRegistro == "VIGENTE_CONTRATO_DETENIDA" ||
                          estadoRegistro == "VIGENTE_RENOVACION_DETENIDA" ? (
                            <>
                              <IconDetenido className="icon-estado" />
                              <span className="textStado">
                                Vigente con sol. de <br />
                                {estadoRegistro == "VIGENTE_RENOVACION_DETENIDA"
                                  ? "renov."
                                  : ""}
                                {estadoRegistro == "VIGENTE_BAJA_DETENIDA"
                                  ? "baja"
                                  : ""}
                                {estadoRegistro == "VIGENTE_CONTRATO_DETENIDA"
                                  ? "contrato"
                                  : ""}{" "}
                                detenida
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRegistro == "VIGENTE_BAJA_ANULADA" ? (
                            <>
                              <IconAnulado className="icon-estado" />
                              <span className="textStado">
                                Vigente con sol. de
                                <br />
                                baja anulada
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        {primerNombre} {primerApellido}
                      </td>
                      <td>{ficha != null ? ficha : "-"}</td>
                      {width <= sm ? (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>
                            <div className="content-icon-table">
                              <BootstrapTooltip
                                title="Renovar Contrato"
                                placement="bottom-start"
                              >
                                <button>
                                  <IconPageRenova fill="#06A502" />
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Confirmar Baja"
                                placement="bottom-start"
                              >
                                <button disabled={estadoRegistro == "BAJA"}>
                                  <div>
                                    <IconPageBaja
                                      fill="#BE2A2A"
                                      className={`${
                                        estadoRegistro !== "BAJA"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Nuevo Contrato"
                                placement="bottom-start"
                              >
                                <button disabled={estadoRegistro == "BAJA"}>
                                  <div>
                                    <IconPageNewContrato
                                      className={`${
                                        estadoRegistro !== "BAJA"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                              <BootstrapTooltip
                                title="Actualizar datos de contrato"
                                placement="bottom-start"
                              >
                                <button disabled={estadoRegistro == "BAJA"}>
                                  <div>
                                    <IconPageUpdate
                                      fill="#0065DD"
                                      className={`${
                                        estadoRegistro !== "BAJA"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{division}</td>
                          <td>{codCargo}</td>
                          <td>{areaNegocioCod}</td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaInicioContrato,
                              format: "/",
                            })}
                          </td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaTerminoContrato,
                              format: "/",
                            })}
                          </td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: topeLegal,
                              format: "/",
                            })}
                          </td>

                          <td>
                            <div className="content-icon-table">
                              <BootstrapTooltip
                                title="Renovar Contrato"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDNI,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoContrato,
                                      ficha,
                                      fechaTerminoContrato,
                                      fueraPlazo,
                                      letra,
                                      nuevaFechaInicio,
                                    })
                                  }
                                  disabled={estadoRegistro == "BAJA"}
                                >
                                  <IconPageRenova
                                    className={`${
                                      estadoRegistro !== "BAJA"
                                        ? "button active-registro "
                                        : "disabled"
                                    }`}
                                  />
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Confirmar Baja"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalConfirmBaja({
                                      personaDNI,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      fechaValidacion,
                                      areaNegocioCod,
                                      estadoRegistro,
                                      fechaInicioContrato,
                                      fechaTerminoContrato,
                                      ficha,
                                      division,
                                      causalLetra,
                                    })
                                  }
                                  disabled={estadoRegistro == "BAJA"}
                                >
                                  <div>
                                    <IconPageBaja
                                      className={`${
                                        estadoRegistro !== "BAJA"
                                          ? "button active-block"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Nuevo Contrato"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalNuevoContrato({
                                      personaDNI,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoContrato,
                                      ficha,
                                    })
                                  }
                                >
                                  <div>
                                    <IconPageNewContrato
                                      // fill={"#0065DD"}
                                      className={`${"button active-restore"}`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                              <BootstrapTooltip
                                title="Actualizar datos de contrato"
                                placement="bottom-start"
                              >
                               <button
                                  onClick={() =>
                                    handleClickModalDatosIndividualEdit({
                                      personaDNI,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoContrato,
                                      ficha,
                                    })
                                  }
                                  disabled={
                                    estadoRegistro == "BAJA" ||
                                    estadoRegistro == "VIGENTE_BAJA_ANULADA" ||
                                    estadoRegistro == "VIGENTE_BAJA_DETENIDA" ||
                                    estadoRegistro ==
                                      "VIGENTE_CONTRATO_DETENIDA" ||
                                    estadoRegistro ==
                                      "VIGENTE_RENOVACION_DETENIDA"
                                  }
                                >
                                  <div>
                                    <IconPageUpdate
                                      className={`${
                                        estadoRegistro !== "BAJA" &&
                                        estadoRegistro !==
                                          "VIGENTE_BAJA_ANULADA" &&
                                        estadoRegistro !==
                                          "VIGENTE_BAJA_DETENIDA" &&
                                        estadoRegistro !==
                                          "VIGENTE_CONTRATO_DETENIDA" &&
                                        estadoRegistro !==
                                          "VIGENTE_RENOVACION_DETENIDA"
                                          ? "button active-edit"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>

      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />
      {isOpenModalIndividual && (
        <ModalFicha
          areaContrato={true}
          renovacion={renovacion}
          nuevoContrato={nuevoContrato}
          editar={editar}
          rut={rutCandidate}
          ficha={ficha}
          idCliente={idCliente}
          title="REGISTRO CONTRATO INDIVIDUAL "
          onChangeBack={() => {
            setIsOpenModalIndividual(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Cancelar"
          setOpenModalFicha={setOpenModalFicha}
          estado={estadoRegistro}
          fechaValidacion={fechaValidacion}
          fueraPlazoForRenov={fueraPlazoForRenov}
        />
      )}
      {isOpenModalBaja && (
        <ModalConfirmBaja
          modalType="baja"
          title="CONFIRMAR BAJA"
          subTitle="Estás por confirmar y solicitar cálculo de la baja de la siguiente selección:  "
          buttonBack="Cancelar"
          onChangeBack={() => {
            setIsOpenModalBaja(false);
          }}
          modalStyle={""}
          list={[dataForBaja]}
        />
      )}
      {openModalNoProcess && (
        <ModalGenerico
          title="Solicitud NO procesada"
          iconTitle="danger"
          subtitle="La solicitud para los siguientes trabajadores no pudo ser procesada:"
          width="w-70"
          buttonBackText="Cerrar"
          buttonGoStyle="delete"
          onChangeBack={() => {
            setOpenModalNoProcess(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalNoProcesado list={listNoProcess} />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaDotacionVigente;
