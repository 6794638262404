import { useContext, useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ActionMassive from "./ActionMassive";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import IconRegistroIndividual from "../../../../ui/kit-svg/icon-contratos/IconRegistroIndividual";
import IconFileExclamacion from "../../../../ui/kit-svg/icon-contratos/IconFileExclamacion";
import RegistroIndividual from "../../modals/RegistroIndividual";
import ContratosContext from "../../../../../context/Contratos/ContratosContext";
import ModalRegistroIndividual from "../../modals/ModalRegistroIndividual";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import { IContratosColaborador, IListaSolicitudesPendientes } from "../../../../../interfaces/IContratos";
import DetenerSolicitud from "../../modals/rechazarSolicitud/DetenerSolicitud";

const TablaSolicitudPendientes = () => {
  const {
    token,
    userId,
    postRegistroColaborador,
    listaRegistroColaborador,
    postSolicitudesPendientes,
    listaSolicitudesPendientes,
    putRechazarSolicitud,
    putAprobarSolicitud,
  } = useContext(ContratosContext);

  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [isOpenModalRegistro, setIsOpenModalRegistro] =
    useState<boolean>(false);
  const [isOpenModalAceptarSolicitud, setIsOpenModalAceptarSolicitud] =
    useState<boolean>(false);
  const [isOpenModalRechazarSolicitud, setIsOpenModalRechazarSolicitud] =
    useState<boolean>(false);
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridCandidatoDisponible, setGridCandidatoDisponible] = useState<any[]>(
    []
  );
  const [selectCurrent, setSelectCurrent] = useState<any>([]);
  const [buttonDetener, setButtonDetener] = useState<boolean>(false);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [formCandidate, setFormCandidate] = useState<any>({});
  const [activeTab, setActiveTab] = useState<string>("");
  const [currentBaseContrato, setCurrentBaseContrato] = useState<string[]>([]);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroContratosSolicitudPendientes"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [fechaSolicitud, setFechaSolicitud] = useState<string>("");
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    fechaSolicitud: [],
    tipoDocumento: [],
    ficha: [],
    division: [],
    areaNegocio: [],
    solicitante: [],
  });

  useEffect(() => {
    listRefresh();
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaRegistroColaborador) {
      const [primerArray]: IContratosColaborador[] = Object.values(listaRegistroColaborador);
      setFormCandidate(primerArray ?? []);
      setActiveTab(primerArray?.baseContratoId ?? "");
    }
  }, [listaRegistroColaborador]);

  useEffect(() => {
    if (listaSolicitudesPendientes) {
      const arrayList = listaSolicitudesPendientes.map(
        (item: IListaSolicitudesPendientes) => item.baseContratoId
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaSolicitudesPendientes]);

  useEffect(() => {
    if (listaSolicitudesPendientes) {
      let arrayGridFilter = [...listaSolicitudesPendientes];
      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoDisponible(arrayGridFilter);
    }
  }, [listaSolicitudesPendientes, range]);

  useEffect(() => {
    if (selectCurrent) {
      const hayTextoEnValue = Object.values(selectCurrent).some(
        (item: any) => item.value && item.value.trim() !== ""
      );
      setButtonDetener(hayTextoEnValue);
    }
  }, [selectCurrent]);

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { baseContratoId } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];

    if (!arrayList.includes(baseContratoId)) {
      arrayList.push(baseContratoId);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === baseContratoId
      );
      arrayList.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter(
          (baseContratoId: string) =>
            !listadoSeleccionado.includes(baseContratoId)
        )
        .map((baseContratoId: string) => arrayListado.push(baseContratoId));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
  };

  const handleClickModalRegistro = (values: any) => {
    const { baseContratoId, fechaSolicitud } = values;
    setCurrentBaseContrato([baseContratoId]);
    setFechaSolicitud(fechaSolicitud);
    if (token) {
      postRegistroColaborador({
        token,
        baseContratos: [baseContratoId],
        setIsLoading: setIsLoadingModal,
      });
    }
    setIsOpenModalRegistro(true);
  };

  const listRefresh = () => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        postSolicitudesPendientes({
          token,
          filtros: JSON.parse(copyStorage),
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        postSolicitudesPendientes({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  };

  const handleClickDetenerSolicitud = async (): Promise<void> => {
    if (token) {
      const arregloTransformado = Object.keys(selectCurrent).map(
        (baseContratoId: any) => {
          const numeroFicha = baseContratoId;
          const motivo = selectCurrent[baseContratoId].value;

          return {
            baseContratoId: numeroFicha,
            motivo: motivo,
          };
        }
      );
      try {
        await putRechazarSolicitud({
          token,
          contratos: arregloTransformado,
        });
        setIsOpenModalRechazarSolicitud(false);
        setIsOpenModalRegistro(false);
        listRefresh();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClickAprobarSolicitud = async (): Promise<void> => {
    if (token) {
      try {
        await putAprobarSolicitud({
          token,
          contratos: currentBaseContrato,
        });
        setIsOpenModalAceptarSolicitud(false);
        setIsOpenModalRegistro(false);
        listRefresh();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOpenTab = (values: any): void => {
    const { baseContratoId } = values;
    try {
      setIsLoadingModal(true);
      if (baseContratoId && listaRegistroColaborador) {
        setActiveTab(baseContratoId);
        const copiaArray = Object.values(listaRegistroColaborador) ?? [];
        const copiaFiltrada = copiaArray.find(
          (item: any) => item.baseContratoId == baseContratoId
        );
        setFormCandidate(copiaFiltrada ?? []);
      }
    } catch (error) {
      console.log("errir al cambiar de candidato" + error);
    } finally {
      // setIsLoadingModal(false);
      setTimeout(() => setIsLoadingModal(false), 300);
    }
  };

  useEffect(() => {
    // Código que se ejecuta después de la redirección
    setTimeout(() => {
      const tablaElement = document.getElementById("tablaSolicitudPendientes");

      if (tablaElement && localStorage.getItem("filtroContratosSolicitudPendientes")) {
        tablaElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  return (
    <>
      <section className="content-seleccion-multiple action-in-line">
        <div className="content-multiple">
          <label className="title-multiple">
            <i className="selects-table-registro">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado ?? []}
          setListadoSeleccionado={setListadoSeleccionado}
          localStorageFilter={localStorageFilter}
          listRefresh={listRefresh}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
          listSelect={listaSolicitudesPendientes}
          selectCurrent={selectCurrent}
          setSelectCurrent={setSelectCurrent}
          fechaSolicitud={fechaSolicitud}
          setFechaSolicitud={setFechaSolicitud}
        />
      </section>
      <section id="tablaSolicitudPendientes" className="content-header-table">
        <label className="title-header-table">Solicitudes recibidas</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">
                        <button
                          onClick={handleClickAddAll}
                          className="button-select"
                        >
                          {listadoSeleccionado.length ==
                            currentCandidates.length &&
                          listadoSeleccionado.length > 0 ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </button>
                      </th>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Fecha Solicitud</th>
                      <th className="th-table-registros">
                        Tipo de <br></br>Documento
                      </th>
                      <th className="th-table-registros">Ficha</th>
                      <th className="th-table-registros">
                        División <br></br>TW
                      </th>
                      <th className="th-table-registros">
                        Área de<br></br>Negocios
                      </th>
                      <th className="th-table-registros">Solicitante</th>
                      <th className="th-table-registros">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoDisponible &&
                      gridCandidatoDisponible.map(
                        (item: IListaSolicitudesPendientes) => {
                          const {
                            baseContratoId,
                            fechaSolicitud,
                            fechaRevision,
                            estado,
                            tipoDocumento,
                            ficha,
                            division,
                            areaNegocioCod,
                            solicitante,
                          } = item;
                          return (
                            <>
                              <tr
                                key={baseContratoId}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(
                                    baseContratoId
                                  ) && "checkeado"
                                }`}
                              >
                                <td className="td-table-registros">
                                  <button
                                    className="button-select"
                                    onClick={() =>
                                      handleClickAddOrRemoveChoise({
                                        baseContratoId,
                                      })
                                    }
                                  >
                                    {listadoSeleccionado.includes(
                                      baseContratoId
                                    ) ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </button>
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-state">
                                    {estado == "PEND_REVISION" && (
                                      <>
                                        <IconFileExclamacion fill="#E3A000" />
                                        <div className="content-state-text">
                                          <label className="title registro-negro">
                                            Solicitud Pendiente
                                          </label>
                                        </div>
                                      </>
                                    )}
                                  </section>
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaSolicitud,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {tipoDocumento}
                                </td>
                                <td className="td-table-registros">{ficha}</td>
                                <td className="td-table-registros">
                                  {division}
                                </td>

                                <td className="td-table-registros">
                                  {areaNegocioCod}
                                </td>
                                <td className="td-table-registros">
                                  {solicitante}
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-icon-table">
                                    <Tooltip text="Ver Registro">
                                      <button
                                        onClick={() =>
                                          handleClickModalRegistro({
                                            baseContratoId,
                                            fechaSolicitud, 
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconRegistroIndividual fill="#0065DD" />
                                      </button>
                                    </Tooltip>
                                  </section>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {isOpenModalRegistro && (
        <ModalRegistroIndividual
          title="REGISTRO INDIVIDUAL COLABORADOR FICHA"
          tabs={Object.values(listaRegistroColaborador) ?? []}
          width="w-80"
          activeTab={activeTab}
          handleOpenTab={handleOpenTab}
          buttonBackText="Rechazar Solicitud"
          buttonGoStyle="confirm"
          buttonGoText="Aceptar Solicitud"
          positionButton="end"
          isLoading={isLoadingModal}
          onChangeClose={() => {
            setIsOpenModalRegistro(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeBack={() => {
            setIsOpenModalRechazarSolicitud(true);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModalAceptarSolicitud(true);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <RegistroIndividual
            formCandidate={formCandidate}
            isLoading={isLoadingModal}
            token={token}
            userId={userId}
            masive={false}
          />
        </ModalRegistroIndividual>
      )}

      {isOpenModalAceptarSolicitud && (
        <ModalGenerico
          title={`ACEPTAR SOLICITUD`}
          width="w-70"
          subtitle="Estás aceptando la solicitud de documento, ¿deseas continuar?."
          positionSubTitle="start"
          message="Al confirmar, el registro se moverá a solicitudes procesadas y se descargará un archivo con los antecedentes correspondientes para crear el documento en firma digital."
          messageBottom="Para continuar presione descargar antecedentes."
          positionMessage="start"
          positionMessageBottom="start"
          buttonBackText="Cancelar"
          buttonGoStyle="option"
          buttonGoText="Descargar Antecedentes"
          onChangeBack={() => {
            setIsOpenModalAceptarSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickAprobarSolicitud();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        ></ModalGenerico>
      )}

      {isOpenModalRechazarSolicitud && (
        <ModalGenerico
          title="RECHAZAR SOLICITUD"
          width="w-70"
          subtitle="Estás rechazando la siguiente solicitud de contrato, indique el motivo: "
          messageBottom="Al continuar se le informará del rechazo y el motivo al solicitante. Para confirmar presione rechazar solicitud."
          buttonBackText="Cancelar"
          buttonGoStyle="delete"
          buttonGoText="Rechazar Solicitud"
          onChangeBack={() => {
            setIsOpenModalRechazarSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickDetenerSolicitud();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <DetenerSolicitud
            list={currentBaseContrato}
            listData={[formCandidate]}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
            fechaSolicitud={fechaSolicitud}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaSolicitudPendientes;
