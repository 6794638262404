import ModalListadoSolicitarRegistro from "./ModalListadoSolicitarRegistro";

interface IModalSolicitarCandidato {
  rut: any[];
  solicitudes: any;
  setSolicitudes: Function;
  handleClickSolicitarCandidato: Function;
  onChangeBack: Function; 
}

export default (props: IModalSolicitarCandidato) => {
  const { rut, solicitudes, setSolicitudes, handleClickSolicitarCandidato, onChangeBack } =
    props;

  return (
    <>
      <section className="content-table-restaurar">
        <table className="table-restaurar w-100">
          <thead className="thead-table-restaurar">
            <tr className="tr-table-restaurar">
              <th className="td-table-restaurar">Rut</th>
              <th className="td-table-restaurar">Nombre</th>
              <th className="td-table-restaurar">Fecha Ingreso</th>
              <th className="td-table-restaurar">Reclutador</th>
              <th className="td-table-restaurar">ID</th>
              <th className="td-table-restaurar">Acciones</th>
            </tr>
          </thead>
          <tbody className="tbody-table-restaurar">
            {rut &&
              rut.map((item: any) => (
                <ModalListadoSolicitarRegistro
                  rut={item}
                  solicitudes={solicitudes}
                  onChangeBack={onChangeBack}
                  setSolicitudes={setSolicitudes}
                  handleClickSolicitarCandidato={handleClickSolicitarCandidato}
                />
              ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
