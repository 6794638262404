import IState from "./stateModel";
import { dispathType, SET_CANDIDATE } from "./actionTypes";

export default function Reducers(
  state: IState,
  actions: { payload: any; type: dispathType }
) {
  switch (actions.type) {
    case SET_CANDIDATE:
      return {
        ...state,
        candidate: actions.payload,
      };
    default:
      return state;
  }
}
