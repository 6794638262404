import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropDownListCustomized from "../../../ui/kit-mdtw/DropDownListCustomized";
import InputTextCustomized from "../../../ui/kit-mdtw/InputTextCustomized";
import InputTextAreaCustomized from "../../../ui/kit-mdtw/InputTextAreaCustomized";
import InputDateCustomized from "../../../ui/kit-mdtw/InputDateCustomized";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";

interface IAreaContratacion {
  hiddenDefault: boolean;
  formCandidate: any;
}

export default (props: IAreaContratacion) => {
  const { hiddenDefault, formCandidate } = props;
  const [hidden, setHidden] = useState<boolean>(hiddenDefault);

  return (
    <>
      {formCandidate ? (
        <>
          <div className={`area-table`} onClick={() => setHidden(!hidden)}>
            <span>ÁREA CONTRATACIÓN</span>
            <span className="linea"></span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </div>
          <section className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-table contratos">
              <div className="title">DATOS CONTRATO</div>
              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">DIVISION TW</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.division ?? "-"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">ÁREA DE NEGOCIO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.areaNegocioCod ?? "-"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">CÓD. CARGO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.codCargo ?? "-"}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">LUGAR DE TRABAJO</label>
                  </div>
                  <div className="w-100">
                    <DropDownListCustomized
                      list={[]}
                      required={false}
                      layer={""}
                      placeholder={formCandidate.nombreSucursal ?? ""}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">TIPO DOCUMENTO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.tipoContrato ?? "-"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">TIPO DE ALTA</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.tipoSolicitud ?? "-"}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">HRS TRABAJADAS</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.horasTrabajadas ?? "-"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">CAUSAL</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.causalLetra ?? "-"}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">TIPO REEMPLAZO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.motivo ?? "-"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">NOMBRE REEMPLAZO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.nombre ?? "NO EXISTE"}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">RUT REEMPLAZO</label>
                  </div>
                  <div className="w-100">
                    <InputTextCustomized
                      required={false}
                      layer={""}
                      placeholder={""}
                      disabled={true}
                      defaultValue={formCandidate.nombreReemplazo ?? "NO EXISTE"}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">RECON. ANTIGÜEDAD</label>
                  </div>
                  <div className="w-100">
                    <InputDateCustomized
                      layer={""}
                      placeholder={"NO EXISTE"}
                      disabled={true}
                      maxLength={10}
                      errorPosition="down"
                      required={false}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">FECHA INICIO</label>
                  </div>
                  <div className="w-100">
                    <InputDateCustomized
                      layer={""}
                      placeholder={formatDateToDDMMAAAA({date: formCandidate.fechaInicio, format: "/"})}
                      disabled={true}
                      maxLength={10}
                      errorPosition="down"
                      required={false}
                    />
                  </div>
                </div>
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">FECHA TÉRMINO</label>
                  </div>
                  <div className="w-100">
                    <InputDateCustomized
                      layer={""}
                      placeholder={formatDateToDDMMAAAA({date: formCandidate.fechaTermino, format: "/"})}
                      disabled={true}
                      maxLength={10}
                      errorPosition="down"
                      required={false}
                    />
                  </div>
                </div>
              </div>

              <div className="title">TURNO</div>
              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">HORARIO</label>
                  </div>
                  <div className="w-100">
                    <InputTextAreaCustomized
                      required={false}
                      layer={""}
                      placeholder={formCandidate.horario ?? "-"}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">TIEMPO COLACIÓN</label>
                  </div>
                  <div className="w-100">
                    <DropDownListCustomized
                      list={[]}
                      required={false}
                      layer={""}
                      placeholder={formCandidate.tipoColacion ?? "NO EXISTE"}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="title">FUNCIONES</div>
              <div className="container-area-contratos">
                <div className="content-area-contratos">
                  <div className="section-title">
                    <label className="">DESCRIPCIÓN FUNCIONES</label>
                  </div>
                  <div className="w-100">
                    <InputTextAreaCustomized
                      required={false}
                      layer={""}
                      placeholder={formCandidate.funciones ?? "-"}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Error al cargar los datos 😨</h3>
        </div>
      )}
    </>
  );
};
