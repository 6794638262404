import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconUserBlock from "../../../../ui/icons-seleccion/IconUserBlock";
import ActionMassive from "./ActionMassive";
import { ICandidatoInhabilitado } from "../../../../../interfaces/ISeleccion";
import ModalRestaurar from "../Modal/ModalRestaurar";
import IconUserContratoActivo from "../../../../ui/icons-seleccion/IconUserContratoActivo";
import IconUserProcesoLegal from "../../../../ui/icons-seleccion/IconUserProcesoLegal";
import IconUserProcesoInterno from "../../../../ui/icons-seleccion/IconUserProcesoInterno";
import { PROFILE_ADMIN_SEL } from "../../../../../diccionary/Diccionary";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconLoading from "../../../../ui/kit-svg/IconLoading";

const TablaBloqueado = () => {
  const {
    profile,
    listaCandidatoInhabilitado,
    getCandidatoInhabilitado,
    token,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridCandidatoInhabilitado, setGridCandidatoInhabilitado] = useState<
    any[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoInhabilitado"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    tipo: [],
    nombre: [],
    rut: [],
    reclutador: [],
    fechaInhabilitacion: [],
  });

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getCandidatoInhabilitado({
          token,
          filtros: JSON.parse(copyStorage),
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        getCandidatoInhabilitado({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoInhabilitado) {
      const arrayList = listaCandidatoInhabilitado.map(
        (item: ICandidatoInhabilitado) => item.personaId
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaCandidatoInhabilitado]);

  useEffect(() => {
    if (listaCandidatoInhabilitado) {
      let arrayGridFilter = [...listaCandidatoInhabilitado];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoInhabilitado(arrayGridFilter);
    }
  }, [listaCandidatoInhabilitado, range]);

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { personaId } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];

    if (!arrayList.includes(personaId)) {
      arrayList.push(personaId);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === personaId
      );
      arrayList.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter((personaId: string) => !listadoSeleccionado.includes(personaId))
        .map((personaId: string) => arrayListado.push(personaId));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
  };

  const handleClickSelectPerson = (values: any): void => {
    const { personaId } = values;
    setCandidateSelect([personaId]);
    setOpenModalRestaurar(true);
  };

  return (
    <>
      {PROFILE_ADMIN_SEL.includes(profile) ? (
        <section className="content-seleccion-multiple action-in-line">
          <div className="content-multiple">
            <label className="title-multiple">
              <i className="selects-table-registro">
                Ítems Seleccionados: {listadoSeleccionado.length}
              </i>
            </label>
          </div>
          <ActionMassive
            listadoSeleccionado={listadoSeleccionado}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            setFormFilter={setFormFilter}
            candidates={candidates}
            setCandidates={setCandidates}
          />
        </section>
      ) : (
        <>
          <section className="content-seleccion-multiple action-in-line">
            <div className="content-multiple"></div>
            <ActionMassive
              listadoSeleccionado={listadoSeleccionado}
              localStorageFilter={localStorageFilter}
              formFilter={formFilter}
              setFormFilter={setFormFilter}
              candidates={candidates}
              setCandidates={setCandidates}
            />
          </section>
        </>
      )}

      <section className="content-header-table">
        <label className="title-header-table">
          Bloqueados por conducta inadecuada
        </label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      {PROFILE_ADMIN_SEL.includes(profile) && (
                        <th className="th-table-registros">
                          <button
                            onClick={handleClickAddAll}
                            className="button-select"
                          >
                            {listadoSeleccionado.length ==
                              currentCandidates.length &&
                            listadoSeleccionado.length > 0 ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                          </button>
                        </th>
                      )}
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">
                        Fecha Última <br></br>Postulación
                      </th>
                      <th className="th-table-registros">
                        Fecha <br></br>Inhabilitación
                      </th>
                      <th className="th-table-registros">Motivo</th>
                      {PROFILE_ADMIN_SEL.includes(profile) && (
                        <th className="th-table-registros">Acciones</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoInhabilitado &&
                      gridCandidatoInhabilitado.map(
                        (item: ICandidatoInhabilitado) => {
                          const {
                            personaId,
                            personaDNI,
                            primerNombre,
                            primerApellido,
                            fechaIngreso,
                            fechaInhabilitacion,
                            excPerComentario,
                            excTipoId,
                          } = item;
                          return (
                            <>
                              <tr
                                key={personaId}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(personaId) &&
                                  "checkeado"
                                }`}
                              >
                                {PROFILE_ADMIN_SEL.includes(profile) && (
                                  <td className="td-table-registros">
                                    <button
                                      className="button-select"
                                      onClick={() =>
                                        handleClickAddOrRemoveChoise({
                                          personaId,
                                        })
                                      }
                                    >
                                      {listadoSeleccionado.includes(
                                        personaId
                                      ) ? (
                                        <CheckBoxIcon />
                                      ) : (
                                        <CheckBoxOutlineBlankIcon />
                                      )}
                                    </button>
                                  </td>
                                )}
                                <td className="td-table-registros">
                                  <section className="content-state">
                                    {excTipoId === "17" && (
                                      <IconUserBlock
                                        fill="#BE2A2A"
                                        className="content-icon"
                                      />
                                    )}
                                    {excTipoId === "ET-06" && (
                                      <IconUserContratoActivo
                                        fill="#06A502"
                                        className="content-icon"
                                      />
                                    )}
                                    {["ET-01", "ET-04", "ET-05"].includes(
                                      excTipoId
                                    ) && (
                                      <IconUserProcesoInterno
                                        fill="#E3A000"
                                        className="content-icon"
                                      />
                                    )}
                                    {["ET-02", "ET-03", "15"].includes(
                                      excTipoId
                                    ) && (
                                      <IconUserProcesoLegal
                                        fill="#BE2A2A"
                                        className="content-icon"
                                      />
                                    )}
                                    <div className="content-state-text">
                                      {excTipoId === "17" && (
                                        <>
                                          <label className="title conducta-inadecuada">
                                            Conducta Inadecuada
                                          </label>
                                          <label className="message">
                                            Candidato Inhabilitado<br></br> para
                                            procesos
                                          </label>
                                        </>
                                      )}
                                      {excTipoId === "ET-06" && (
                                        <>
                                          <label className="title contrato-activo">
                                            Contrato Activo
                                          </label>
                                          <label className="message">
                                            Candidato Inhabilitado<br></br> para
                                            procesos
                                          </label>
                                        </>
                                      )}
                                      {["ET-01", "ET-04", "ET-05"].includes(
                                        excTipoId
                                      ) && (
                                        <>
                                          <label className="title proceso-interno">
                                            Proceso Interno
                                          </label>
                                          <label className="message">
                                            Candidato Inhabilitado<br></br> para
                                            procesos
                                          </label>
                                        </>
                                      )}
                                      {["ET-02", "ET-03", "15"].includes(
                                        excTipoId
                                      ) && (
                                        <>
                                          <label className="title proceso-legal">
                                            Proceso Legal
                                          </label>
                                          <label className="message">
                                            Candidato Inhabilitado<br></br> para
                                            procesos
                                          </label>
                                        </>
                                      )}
                                    </div>
                                  </section>
                                </td>
                                <td className="td-table-registros">
                                  {primerNombre} {primerApellido}
                                </td>
                                <td className="td-table-registros">
                                  {personaDNI}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaIngreso,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaInhabilitacion,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {excPerComentario}
                                </td>
                                {PROFILE_ADMIN_SEL.includes(profile) && (
                                  <td className="td-table-registros">
                                    <section className="content-icon-table">
                                      <Tooltip text="Restaurar">
                                        <button
                                          onClick={() =>
                                            handleClickSelectPerson({
                                              personaId,
                                            })
                                          }
                                          className="button-content"
                                        >
                                          <RefreshIcon className="restaurar" />
                                        </button>
                                      </Tooltip>
                                    </section>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {openModalRestaurar && (
        <ModalRestaurar
          list={candidateSelect}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          onChangeBack={() => setOpenModalRestaurar(false)}
          title="CONFIRMAR RESTAURAR CANDIDATOS INHABILITADOS"
          message="Estás por quitar inhabilidad a la siguiente selección:"
        />
      )}
    </>
  );
};

export default TablaBloqueado;
