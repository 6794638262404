import "../../../../../assets/css/ui-modal-session.min.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Button } from "primereact/button";
import ButtonIcon from "../../../adminAssist/ButtonIcon";
import { useEffect, useState } from "react";
interface ISessionExpired {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  titleButtonGo?: string;
  contador?: number;
  setContador?: Function;
}

export default (props: ISessionExpired) => {
  const {
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    contador,
    titleButtonGo,
    setContador,
  } = props;

  let [count, setCount] = useState<any>(contador);
  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (setContador && contador && count > 0) {
        setCount(count - 1);
        setContador(count - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  const getString = (value: any) => {
    const re: string = value.toString();
    return re;
  };

  return (
    <>
      <div className="modal-container" id="ModalTokenExpired">
        <div className="modal-content">
          {/* <span className="modal-close" onClick={() => typeof onChangeGo == "function" && onChangeGo()}>
            <ButtonIcon>
                <CloseIcon style={{color: "#48566B", fontSize: "36px"}}/>
            </ButtonIcon>
          </span> */}
          <div className="modal-title">
            <ButtonIcon>
              <AccessTimeIcon style={{ color: "#D32F2F", fontSize: "30px" }} />
            </ButtonIcon>
            <span>{title}</span>
          </div>

          {contador && (
            <>
              <div className="modal-subtitle">
                <span>{subTitle}</span>
              </div>
              <div className="inputs-min">
                <input
                  className={`${"disabled"}`}
                  type="number"
                  disabled
                  value={0}
                ></input>
                <input
                  className={`${"disabled"}`}
                  type="number"
                  disabled
                  value={0}
                ></input>
                <label className="label-point">:</label>
                <input
                  type="number"
                  disabled
                  value={`${
                    getString(count).length > 1 ? getString(count)[0] : "0"
                  }`}
                ></input>
                <input
                  type="number"
                  disabled
                  value={
                    getString(count).length > 1
                      ? getString(count)[1]
                      : getString(count)[0]
                  }
                ></input>
              </div>
              <div className="inputs-min-text">
                <div className="div-text">Minutos</div>
                <div className="div-text">Segundos</div>
              </div>
            </>
          )}

          <div className="modal-message">
            <span>{message}</span>
          </div>
          <div className="button-content">
            <Button className="button-out" onClick={handleClickCloseModal}>
              Cerrar Sesión
            </Button>
            <Button
              className="button-back"
              onClick={() => typeof onChangeGo == "function" && onChangeGo()}
            >
              {titleButtonGo ? titleButtonGo : "Ingresar"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
