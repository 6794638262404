import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface IAreaLaboralFichaPotulacion {
  hiddenDefault: boolean;
  formCandidate: any;
  experienciaEnCargo: string | undefined; 
  disponibilidad: string | undefined; 
}

export default (props: IAreaLaboralFichaPotulacion) => {
  const { hiddenDefault, formCandidate, experienciaEnCargo, disponibilidad } = props;
  const [hidden, setHidden] = useState<boolean>(hiddenDefault);

  return (
    <>
      {formCandidate ? (
        <>
          <div className={`area-table`} onClick={() => setHidden(!hidden)}>
            <span>ÁREA LABORAL</span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </div>
          <div className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-table">
              <div className="title">EXPERIENCIA</div>

              <div className="content-table uno">
                <span className="title-table">
                  EXPERIENCIA EN RELACION AL CARGO
                </span>
                <span className="subtitle-table">
                  {experienciaEnCargo ?? ""}
                </span>
              </div>

              <div className="title">DISPONIBILIDAD</div>
              <div className="content-table-row">
                <span className="title-table-row">
                  DISPONIBILIDAD PARA EL CARGO
                </span>
                <span className="subtitle-table-row">
                  {disponibilidad ?? ""}
                </span>
              </div>

              <div className="title">TALLA UNIFORME</div>

              <div className="content-table tres">
                <span className="title-table tres">POLERA</span>
                <span className="subtitle-table tres">
                  {formCandidate.tallaPolera ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table tres">PANTALÓN</span>
                <span className="subtitle-table tres">
                  {formCandidate.tallaPantalon ?? ""}
                </span>
              </div>
              <div className="content-table tres">
                <span className="title-table tres">ZAPATOS</span>
                <span className="subtitle-table tres">
                  {formCandidate.tallaZapato ?? ""}
                </span>
              </div>

              <div className="title">SALUD</div>
              <div className="content-table-row">
                <span className="title-table-row">DISCAPACIDAD</span>
                <span className="subtitle-table-row">
                  {formCandidate.discapacidad ? "SI" : "NO"}
                </span>
              </div>
              <div className="content-table-row">
                <span className="title-table-row">
                  ESQUEMA VACUNACION COVID
                </span>
                <span className="subtitle-table-row">
                  {formCandidate.esquemaCovidCompleto ? "SI" : "NO"}
                </span>
              </div>
              <div className="content-table-row">
                <span className="title-table-row">
                  REFUERZO VACUNACION COVID
                </span>
                <span className="subtitle-table-row">
                  {formCandidate.refuerzo ? "SI" : "NO"}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Error al cargar los datos 😨</h3>
        </div>
      )}
    </>
  );
};
