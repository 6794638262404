interface IIconArrownDown {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconArrownDown) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="21"
      height="13"
      viewBox="0 0 21 13"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="chevron_right"
        d="M2.83121e-08 2.77874L2.45 0.513674L10.5 7.95604L18.55 0.513672L21 2.77874L10.5 12.4862L2.83121e-08 2.77874Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};
