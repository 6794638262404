import { useState } from "react";
import Toast from "./Toast";
import IconCloseToast from "../../kit-svg/icon-toast/IconCloseToast";

interface IToast {
  title: string;
  message: string;
  type?: "success" | "danger" | "warning" | "help";
}

interface IValues {
  list: number;
}

export default function useToast() {
  const [list, setList] = useState<IToast[]>([]);

  const createToast = (options: IToast): void => {
    setList([...list, options]);
    setTimeout(() => {
      setList((l) => l.slice(1));
    }, 3000);
  };

  const handleClickClose = (): void => {
    setList((l) => l.slice(1));
  };

  const toasts = (
    <div className="container-toast">
      {list.map((item, i) => (
        <Toast type={item.type} key={i}>
          <div className="content-title-toast">
            <label className="title">{item.title}</label>
            <button 
              className="button-close-toast"
              onClick={handleClickClose}
            >
              {item.type == "success" && <IconCloseToast fill="#06A502" />}
              {item.type == "danger" && <IconCloseToast fill="#BE2A2A" />}
              {item.type == "warning" && <IconCloseToast fill="#FF4900" />}
              {item.type == "help" && <IconCloseToast fill="#ff0081" />}
            </button>
          </div>
          <label className="message">{item.message}</label>
        </Toast>
      ))}
    </div>
  );

  return { toasts, createToast };
}
