import { IsetRedux } from "../../interfaces/ICampaign";

export const setcampaign = (props: IsetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "setcampaign",
    payload,
  });
};

export const settypes = (props: IsetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "Settypes",
    payload,
  });
};

export const setencuesta = (props: IsetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "Setencuesta",
    payload,
  });
};



