import { createContext, ReactNode, useReducer, useContext } from "react";
import IActions from "./actionModel";
import candidateModel from "./candidateModel";
import reducers from "./reducer";
import IState from "./stateModel";
import { SET_CANDIDATE } from "./actionTypes";

interface PropTypes {
children: ReactNode;
}

let initialState: any;

const CandidateContext = createContext<[IState, IActions]>(initialState);

const Store = (props: PropTypes) => {
const [state, dispatch] = useReducer(reducers, initialState);
const actions: IActions = {
    setCandidate(candidate: candidateModel): void {
    dispatch({ type: SET_CANDIDATE, payload: candidate });
    },
  };
  return (
    <CandidateContext.Provider value={[state, { ...actions }]}>
      {props.children}
    </CandidateContext.Provider>
  );
};

export const useCandidateState = () => {
  return useContext(CandidateContext);
};

export default Store;
