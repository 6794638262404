interface IconBloquear {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IconBloquear) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M8 0.5C3.85788 0.5 0.5 3.85785 0.5 8C0.5 12.1421 3.85788 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85788 12.1421 0.5 8 0.5ZM11.9347 4.06528C13.914 6.04456 14.0517 9.06975 12.56 11.1914L4.80857 3.44C6.93167 1.94738 9.95653 2.08713 11.9347 4.06528ZM4.06528 11.9347C2.08601 9.95544 1.94835 6.93025 3.43997 4.8086L11.1914 12.56C9.06836 14.0526 6.04347 13.9129 4.06528 11.9347Z"
      />
    </svg>
  );
};
