interface IIconUserContratoActivo {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconUserContratoActivo) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="30"
      height="20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M14.0484 14.0732C13.6782 14.0732 13.3631 13.9393 13.1032 13.6794C12.8432 13.4195 12.7093 13.1044 12.7093 12.7342V11.4896C11.8035 10.9461 10.7479 10.6231 9.61365 10.6231H6.52588C3.2018 10.6231 0.5 13.3171 0.5 16.649C0.5 17.2004 0.948987 17.6494 1.50038 17.6494H14.6313C15.1827 17.6494 15.6317 17.2004 15.6317 16.649C15.6317 15.7274 15.419 14.8609 15.0488 14.0811H14.0484V14.0732ZM2.16204 16.0267C2.46137 13.8921 4.2967 12.2458 6.518 12.2458H9.60578C11.8271 12.2458 13.6624 13.8921 13.9617 16.0267H2.16204Z"
          // fill="#06A502"
        />
        <path
          d="M14.0484 12.5845V12.7342H14.1902C14.1429 12.679 14.0956 12.6318 14.0484 12.5845Z"
        />
        <path
          d="M8.06189 9.00047C9.21193 9.00047 10.3068 8.54361 11.1182 7.73228C11.9295 6.92095 12.3863 5.81818 12.3863 4.67602C12.3863 3.53386 11.9295 2.43108 11.1182 1.61975C10.3068 0.808427 9.20405 0.351562 8.06189 0.351562C6.91973 0.351562 5.81695 0.808427 5.00563 1.61975C4.1943 2.43108 3.73743 3.53386 3.73743 4.67602C3.73743 5.81818 4.1943 6.92095 5.00563 7.73228C5.81695 8.54361 6.91973 9.00047 8.06189 9.00047ZM6.15566 2.76191C6.65979 2.25779 7.35296 1.97422 8.06977 1.97422C8.78657 1.97422 9.47187 2.25779 9.98387 2.76191C10.4959 3.26604 10.7716 3.95921 10.7716 4.67602C10.7716 5.39282 10.488 6.07812 9.98387 6.59012C9.47974 7.10213 8.78657 7.37782 8.06977 7.37782C7.35296 7.37782 6.66767 7.09425 6.15566 6.59012C5.64366 6.086 5.36797 5.39282 5.36797 4.67602C5.36797 3.95921 5.65154 3.27392 6.15566 2.76191Z"
        />
        <path
          d="M15.356 11.4581H19.452V10.4341H15.356V11.4581ZM15.356 9.41008H19.452V8.38607H15.356V9.41008ZM14.332 13.5061C14.0484 13.5061 13.8121 13.4037 13.6073 13.2068C13.4104 13.0099 13.308 12.7657 13.308 12.4821V4.28217C13.308 3.9986 13.4104 3.76229 13.6073 3.55749C13.8042 3.36056 14.0484 3.25816 14.332 3.25816H18.428L21.5 6.33018V12.4821C21.5 12.7657 21.3976 13.002 21.2007 13.2068C21.0038 13.4037 20.7596 13.5061 20.476 13.5061H14.3241H14.332ZM17.916 6.84218V4.28217H14.332V12.4821H20.4839V6.85006H17.9239L17.916 6.84218Z"
        />
      </g>
    </svg>
  );
};
