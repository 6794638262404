import React, { ReactNode } from "react";

interface IToastProps {
  type?: "success" | "danger" | "warning" | "help";
  children: ReactNode;
}

function Toast(props: IToastProps) {
  const {type = "success", children} = props;
  return (
    <div className={`content-toast ${type}`}>{children}</div>
  )
}

export default Toast