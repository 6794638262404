import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import { Document, PDFViewer } from "@react-pdf/renderer";
import PortadaPsicolaboral from "./components/PortadaPsicolaboral";
import SinGraficoXl2 from "./components/SinGraficoXl2";
import ConGraficoXl3 from "./components/ConGraficoXl3";
import SinGraficoXl4 from "./components/SinGraficoXl4";
import { IPDFPsicolaboral } from "../../../interfaces/ISeleccion";
import RadarChart from "./components/RadarChart";

const ConGraficoLarge = (props: IPDFPsicolaboral) => {
  const { busperid, formato, token } = props;
  const { listaReportePdfPsicolaboral, postReportePdfPsicolaboral } =
    useContext(SeleccionContext);
  const [formList, setFormList] = useState<any>({});
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [image64, setImage64] = useState<any>(undefined);
  const [errorLoading, setErrorLoading] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (busperid && formato) {
      postReportePdfPsicolaboral({
        token,
        formato,
        busperid,
        setLoading,
        setErrorLoading,
      });
    }
  }, [token]);

  useEffect(() => {
    try {
      if (listaReportePdfPsicolaboral) {
        setFormList({ ...listaReportePdfPsicolaboral[busperid] });
      }
    } catch (error) {
      console.log(error);
    }
  }, [listaReportePdfPsicolaboral]);

  return (
    <>
      {!errorLoading ? (
        <>
          {!image64 ? (
            <>
              <div className="center-container">
                <div className="loading-container">
                  <div className="loading-text">Cargando PDF</div>
                  <div className="loading-dots">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                  </div>
                </div>
              </div>
              <RadarChart formList={formList} setImage64={setImage64} />
            </>
          ) : (
            <>
              {image64 && !loading ? (
                <div style={{ display: "flex", overflow: "hidden" }}>
                  <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    <Document>
                      <PortadaPsicolaboral formList={formList} />
                      <SinGraficoXl2 formList={formList} />
                      <ConGraficoXl3 formList={formList} grafico={image64} />
                      <SinGraficoXl4 formList={formList} />
                    </Document>
                  </PDFViewer>
                </div>
              ) : (
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  Error al cargar grafico.
                </h1>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className="center-container">
            <div className="loading-container">
              <div className="loading-text">Error al cargar PDF</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConGraficoLarge;
