import { useContext, useEffect, useState } from "react";
import "../../../assets/css/servicesAssists.min.css";
import "../../../assets/css/page-ui-recluiting.min.css";
import AdminAssistContext from "../../../context/AdminAssist/AdminAssistContext";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CircleIcon from "@mui/icons-material/Circle";
import IconExtenderTiempo from "../../ui/kit-svg/IconExtenderTiempo";
import IconEliminar from "../../ui/kit-svg/IconEliminar";
import IconEdit from "../../ui/kit-svg/iconEdit";
import ModalMessage from "../../ui/adminAssist/modals/ModalMessage";
import EditServiceModal from "./EditServiceModal";
import MediaQuery from "../../ui-responsive/MediaQuery";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoServiceMobileModal from "./InfoServiceMobileModal";
import ButtonFilter from "../../ui/adminAssist/ButtonFilter";
import { formatHourToMMSS } from "../../../functions/kit-mdtw/hours";
import { formatDateToDDMMAAAA } from "../../../functions/kit-mdtw/dates";
import FooterTable from "./FooterTable";
import TableServiceFilter from "./TableServiceFilter";
import {
  IAdminAssistState,
  IAdminTable,
} from "../../../interfaces/IAdminAssist";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";

interface ITableService {
  token: string | undefined;
  setToken: Function; 
  getToken: Function;  
}

export default (props: ITableService) => {
  const { token, setToken, getToken } = props; 
  const {
    adminTable,
    getAdminTable,
    listServicioId,
    setListServicioId,
    handleClickActive,
    isOpenToastState,
    toastState,
    handleEventToastState,
    handleClickViewActions,
    visibleActionBlocking,
    setVisibleActionBlocking,
    updateServiceState,
    deleteService,
    updateResetService,
  } = useContext(AdminAssistContext);
  const { width, lg, sm } = MediaQuery();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalValidCode, setIsOpenModalValidCode] =
    useState<boolean>(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false);
  const [isOpenModalMobileInfo, setIsOpenModalMobileInfo] =
    useState<boolean>(false);
  const [isOpenInfoPrioridad, setIsOpenInfoPrioridad] =
    useState<boolean>(false);
  const [isOpenInfoAcciones, setIsOpenInfoAcciones] = useState<boolean>(false);
  const [isOpenInfoEstados, setIsOpenInfoEstados] = useState<boolean>(false);
  const [servicioId, setServicioId] = useState<any[]>();
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [gridAdminTable, setGridAdminTable] = useState<IAdminTable[]>([]);

  useEffect(() => {
    if (listServicioId.length > 0) {
      let fullForm: boolean = false;
      const choise: any = adminTable.filter((service: any) =>
        listServicioId.includes(service.serviceId)
      );
      setVisibleActionBlocking(true);
    } else {
      setVisibleActionBlocking(false);
    }
  }, [listServicioId, visibleActionBlocking]);

  useEffect(() => {
    let arrayGridFilter = [...adminTable];
    if (filterChange) {
      const { idProceso, cliente, fecha, tipoTransporte, estado } = filterChange;
      if (idProceso.value && idProceso.value.length > 0) {
        const idProcesoArray = idProceso.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IAdminTable) =>
          idProcesoArray.includes(item.servicioId)
        );
      }

      if (cliente.value && cliente.value.length > 0) {
        const clienteArray = cliente.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IAdminTable) =>
        clienteArray.includes(item.clientes)
        );
      }

      if (fecha.value && fecha.value.length > 0) {
        const fechaArray = fecha.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IAdminTable) =>
          fechaArray.includes(item.fechaHoraInicio)
        );
      }

      if (tipoTransporte.value && tipoTransporte.value.length > 0) {
        const tipoTransporteArray = tipoTransporte.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IAdminTable) =>
          tipoTransporteArray.includes(item.fechaHoraInicio)
        );
      }

      if (estado.value && estado.value.length > 0) {
        const estadoArray = estado.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IAdminTable) =>
          estadoArray.includes(item.fechaHoraInicio)
        );
      }
    }

    setLengthFilters(arrayGridFilter.length);

    if (range) {
      const { initRange, endRange } = range;
      arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
    }

    setGridAdminTable(arrayGridFilter);
  }, [filterChange, adminTable, range]);

  const handleEventUpdateState = (values: any) => {
    const { index, activo } = values;
    getToken();
    updateServiceState({
      index,
      activo,
      token,
    });
  };

  const handleClickDeleteProcess = (values: any) => {
    const { servicioId } = values;
    setIsOpenModalDelete(false);
    deleteService({ servicioId, token });
    setServicioId([]);
  };

  const handleEventModalDelete = (values: any) => {
    const { servicioId } = values;
    setServicioId(servicioId);
    setIsOpenModalDelete(true);
  };

  const handleEventModalReset = (values: any) => {
    const { servicioId } = values;
    setServicioId(servicioId);
    setIsOpenModalValidCode(true);
  };

  const handleClickEditProcess = () => {
    setIsOpenModalEdit(false);
  };

  const handleClickValidateProcess = (values: any) => {
    const { servicioId } = values;
    setIsOpenModalValidCode(false);
    getToken();
    updateResetService({ servicioId, token });
    setServicioId([]);
  };

  const handleClickAddList = (servicioId: number) => {
    const currentService = [...listServicioId];

    if (!currentService.includes(servicioId)) {
      currentService.push(servicioId);
    } else {
      const positionDeleted = currentService.findIndex(
        (service) => service === servicioId
      );
      currentService.splice(positionDeleted, 1);
    }

    setListServicioId(currentService);
  };

  const handleClickOpenModalMobile = () => {
    isOpenModalMobileInfo
      ? setIsOpenModalMobileInfo(false)
      : setIsOpenModalMobileInfo(true);
  };

  const handleOpenInfoPrioridad = () => {
    setIsOpenInfoPrioridad(true);
  };

  const handleCloseInfoPrioridad = () => {
    setIsOpenInfoPrioridad(false);
  };

  const handleOpenInfoEstados = () => {
    setIsOpenInfoEstados(true);
  };

  const handleCloseInfoEstados = () => {
    setIsOpenInfoEstados(false);
  };

  const handleOpenInfoAcciones = () => {
    setIsOpenInfoAcciones(true);
  };

  const handleCloseInfoAcciones = () => {
    setIsOpenInfoAcciones(false);
  };

  return (
    <>
      <TableServiceFilter list={adminTable} setFilterChange={setFilterChange} />
      <div className="content-items" style={{ overflowX: "auto" }}>
        <table className="table-process-service">
          <thead>
            <tr>
              <th className="td-process-service-title">
                <ButtonIcon>
                  {listServicioId.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ButtonIcon>
              </th>
              <th
                className="td-process-service-title"
                onMouseEnter={handleOpenInfoPrioridad}
                onMouseLeave={handleCloseInfoPrioridad}
              >
                Prioridad
                <div
                  className={`modal-${
                    isOpenInfoPrioridad ? "show" : "show-hidden"
                  }`}
                >
                  <div className="container">
                    <div className="title-info">
                      Prioridad Estado Invitación
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="text-info">
                        <CircleIcon style={{ color: "#DEDEDE" }} />
                        Inactivo-Expirada
                      </div>
                      <div className="text-info">
                        <CircleIcon style={{ color: "#FFB400" }} />
                        Media-Cercana <a href="tel:+"></a> Expirar
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="text-info">
                        <CircleIcon style={{ color: "#D32F2F" }} />
                        Alta-Próximo a expirar
                      </div>
                      <div className="text-info">
                        <CircleIcon style={{ color: "#4BB848" }} />
                        Disponible-No activa áun
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th
                className="td-process-service-title"
                onMouseEnter={handleOpenInfoEstados}
                onMouseLeave={handleCloseInfoEstados}
              >
                Estado
                <div
                  className={`modal-${
                    isOpenInfoEstados ? "show" : "show-hidden"
                  }`}
                >
                  <div className="container">
                    <div className="title-info">
                      Estados Servicio/Invitación
                    </div>
                    <div className="text-info">
                      <ReportProblemIcon style={{ color: "#FFB400" }} />
                      RenovarTiempo Invitación
                    </div>
                    <div className="text-info">
                      <AccessTimeFilledIcon style={{ color: "#FF4900" }} />
                      Editar Servicio
                    </div>
                    <div className="text-info">
                      <MarkEmailReadIcon style={{ color: "#06A502" }} />
                      Eliminar Servicio
                    </div>
                  </div>
                </div>
              </th>
              <th className="td-process-service-title">ID Servicio</th>
              {width >= sm && (
                <>
                  <th className="td-process-service-title">Cliente</th>
                  <th className="td-process-service-title">Fecha</th>
                  <th className="td-process-service-title">Pasajeros</th>
                  <th className="td-process-service-title">Hora Inicio</th>
                  <th className="td-process-service-title">Hora Destino</th>
                  <th className="td-process-service-title">Expira Código</th>
                  <th className="td-process-service-title">
                    Activar/Desactivar
                  </th>
                </>
              )}
              <th
                className="td-process-service-title"
                onMouseEnter={handleOpenInfoAcciones}
                onMouseLeave={handleCloseInfoAcciones}
              >
                Acciones
                <div
                  className={`modal-${
                    isOpenInfoAcciones ? "show" : "show-hidden"
                  }`}
                >
                  <div className="container">
                    <div className="title-info">Acciones Disponibles</div>
                    <div className="text-info">
                      <IconExtenderTiempo
                        color={"#242424"}
                        width={30}
                        height={30}
                      />
                      RenovarTiempo Invitación
                    </div>
                    <div className="text-info">
                      <IconEdit color={"#242424"} width={30} height={30} />
                      Editar Servicio
                    </div>
                    <div className="text-info">
                      <IconEliminar color={"#242424"} width={30} height={30} />
                      Eliminar Servicio
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {adminTable
              ? adminTable.map((table: any, key: number) => {
                  const fechaCreacion = new Date(table.fechaCreacion);
                  const fechaExpiracion = new Date(table.fechaExpiracion);
                  const fechaHoraInicio = new Date(table.fechaHoraInicio);
                  const fechaHoraDestino = new Date(table.fechaHoraDestino);
                  return (
                    <>
                      <tr
                        className={`list-tr-table ${
                          listServicioId.includes(table.servicioId)
                            ? "selected "
                            : ""
                        }`}
                        key={key}
                      >
                        <td
                          className={`td-process-service-content ${
                            table.activo && "active"
                          }`}
                        >
                          <ButtonIcon
                            onClick={() => {
                              handleClickAddList(table.servicioId);
                            }}
                          >
                            {listServicioId.includes(table.servicioId) ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                          </ButtonIcon>
                        </td>
                        <td
                          className={`td-process-service-content ${
                            table.activo && "active"
                          }`}
                          onClick={() => {
                            width <= sm && handleClickOpenModalMobile();
                          }}
                        >
                          <ButtonIcon disabled={true}>
                            <CircleIcon
                              style={
                                table.activo
                                  ? { fontSize: "15px", color: "#D32F2F" }
                                  : { fontSize: "15px", color: "#AEAEAE" }
                              }
                            />
                          </ButtonIcon>
                        </td>
                        <td
                          className={`td-process-service-content ${
                            table.activo && "active"
                          }`}
                          onClick={() => {
                            width <= sm && handleClickOpenModalMobile();
                          }}
                        >
                          {table.estadoServicio === "CREADO" ? (
                            <ButtonIcon disabled={true}>
                              <ReportProblemIcon
                                style={
                                  table.activo
                                    ? { color: "#FFB400" }
                                    : { color: "#AEAEAE" }
                                }
                              />
                            </ButtonIcon>
                          ) : table.estadoServicio === "PROGRAMADO" ? (
                            <ButtonIcon disabled={true}>
                              <AccessTimeFilledIcon
                                style={
                                  table.activo
                                    ? { color: "#FF4900" }
                                    : { color: "#AEAEAE" }
                                }
                              />
                            </ButtonIcon>
                          ) : (
                            <ButtonIcon disabled={true}>
                              <MarkEmailReadIcon
                                style={
                                  table.activo
                                    ? { color: "#06A502" }
                                    : { color: "#AEAEAE" }
                                }
                              />
                            </ButtonIcon>
                          )}
                        </td>
                        <td
                          className={`td-process-service-content ${
                            table.activo && "active"
                          }`}
                          onClick={() => {
                            width <= sm && handleClickOpenModalMobile();
                          }}
                        >
                          {table.servicioId}
                        </td>
                        {width >= sm && (
                          <>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              <abbr title={table.clientes}>
                                {table.clientes[0]}
                              </abbr>
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              {formatDateToDDMMAAAA({
                                date: table.fechaCreacion,
                              })}
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              {table.totalCandidatos}
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              {formatHourToMMSS({
                                date: table.fechaHoraInicio,
                              })}
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              {formatHourToMMSS({
                                date: table.fechaHoraDestino,
                              })}
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              {formatDateToDDMMAAAA({
                                date: table.fechaExpiracion,
                              })}
                            </td>
                            <td
                              className={`td-process-service-content ${
                                table.activo && "active"
                              }`}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={table.activo ? true : false}
                                  onClick={({}) => {
                                    handleEventUpdateState({
                                      index: key,
                                      activo: table.activo ? false : true,
                                    });
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          </>
                        )}

                        <td
                          className={`td-process-service-content ${
                            table.activo && "active"
                          }`}
                        >
                          <div style={{ display: "flex" }}>
                            <ButtonFilter
                              onClick={() =>
                                handleEventModalReset({
                                  servicioId: table.servicioId,
                                })
                              }
                              disabled={!table.activo ? true : false}
                            >
                              <IconExtenderTiempo
                                color={`${
                                  table.activo ? "#404040" : "#AEAEAE"
                                }`}
                                width={32}
                                height={32}
                              />
                            </ButtonFilter>
                            <ButtonFilter
                              // onClick={() => setIsOpenModalEdit(true)}
                              disabled={!table.activo ? true : false}
                            >
                              <IconEdit
                                color={`${
                                  table.activo ? "#404040" : "#AEAEAE"
                                }`}
                                width={32}
                                height={32}
                              />
                            </ButtonFilter>
                            <ButtonFilter
                              onClick={() =>
                                handleEventModalDelete({
                                  servicioId: table.servicioId,
                                })
                              }
                              disabled={!table.activo ? true : false}
                            >
                              <IconEliminar
                                color={`${
                                  table.activo ? "#404040" : "#AEAEAE"
                                }`}
                                width={32}
                                height={32}
                              />
                            </ButtonFilter>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>

      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {isOpenModalValidCode === true && (
        <ModalMessage
          title="¿Desea renovar la validez del código de Invitación?"
          message="Al aceptar, el código se reactivará por 8 Hrs a partir del próximo ingreso del invitado."
          onChangeBack={() => {
            setIsOpenModalValidCode(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleClickValidateProcess({ servicioId })}
          success={false}
          confirm={true}
          width={width >= lg ? "40%" : "90%"}
        />
      )}

      {isOpenModalEdit === true && (
        <EditServiceModal
          title="Editar Servicio N°654321"
          subTitle="A continuación podrá editar los datos del servicio seleccionado."
          message="Para salvar la información presione guardar."
          width={width >= lg ? "80%" : "95%"}
          onChangeBack={() => {
            setIsOpenModalEdit(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickEditProcess();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {isOpenModalDelete === true && (
        <ModalMessage
          title="Esta seguro que desea eliminar el servicio"
          width={width >= lg ? "40%" : "90%"}
          message="Si selecciona eliminar, se borrarán todos los datos de nuestra base de datos."
          onChangeBack={() => {
            setIsOpenModalDelete(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleClickDeleteProcess({ servicioId })}
          success={false}
          confirm={false}
        />
      )}

      {isOpenModalMobileInfo === true && width <= lg && (
        <>
          <InfoServiceMobileModal
            title="Detalle Servicio"
            message=""
            onChangeBack={() => {
              setIsOpenModalMobileInfo(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            widthModal="95%"
          />
        </>
      )}
    </>
  );
};
