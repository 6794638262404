interface IconPagebaja {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconPagebaja) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "16"}`}
        viewBox="0 0 15 16"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M3.00038 4.62552H10.5013V3.50038H3.00038V4.62552ZM3.00038 7.81342H7.2384C7.46343 7.58839 7.70721 7.37836 7.96975 7.18334C8.23228 6.98831 8.50981 6.82329 8.79485 6.68827H3.00038V7.81342ZM3.00038 11.0013H5.67446C5.70446 10.8025 5.73822 10.6075 5.78322 10.42C5.82823 10.2325 5.88074 10.0524 5.94449 9.87617H3.00038V11.0013ZM1.12514 14.0017C0.813852 14.0017 0.551318 13.8929 0.33004 13.6716C0.108763 13.4504 0 13.1878 0 12.8765V1.62514C0 1.31385 0.108763 1.05132 0.33004 0.830041C0.551318 0.608764 0.813852 0.5 1.12514 0.5H12.3765C12.6878 0.5 12.9504 0.608764 13.1716 0.830041C13.3929 1.05132 13.5017 1.31385 13.5017 1.62514V6.59451C13.3254 6.5195 13.1454 6.45199 12.9579 6.38824C12.7703 6.32448 12.5753 6.27572 12.3765 6.23822V1.62514H1.12514V12.8765H5.73822C5.77572 13.0753 5.82448 13.2703 5.88824 13.4579C5.95199 13.6454 6.0195 13.8254 6.09451 14.0017H1.12514Z"
     fill={fill}/> 
     <path d="M14.7806 9.2836C15.0731 8.99106 15.0731 8.51475 14.7806 8.22222C14.4881 7.92968 14.0118 7.92968 13.7192 8.22222L11.2514 10.69L8.7836 8.22222C8.49106 7.92968 8.01475 7.92968 7.72222 8.22222C7.42968 8.51475 7.42968 8.99106 7.72222 9.2836L10.19 11.7514L7.72222 14.2192C7.42968 14.5118 7.42968 14.9881 7.72222 15.2806C8.01475 15.5731 8.49106 15.5731 8.7836 15.2806L11.2514 12.8128L13.7192 15.2806C14.0118 15.5731 14.4881 15.5731 14.7806 15.2806C15.0731 14.9881 15.0731 14.5118 14.7806 14.2192L12.3128 11.7514L14.7806 9.2836Z"
       fill={fill}/> 
      </svg>



    );
  };
