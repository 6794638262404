import { IModalNoProcesado } from "../../../../interfaces/IOperacionesContratacion";

export default (props: IModalNoProcesado) => {
  const { list, retroceder,inSolicitudContrato } = props;
  
  return (
    <>
      <section className="content-table-restaurar">
        <table className="table-restaurar w-100">
          <thead className="thead-table-restaurar">
          {retroceder ?(
            <tr className="tr-table-restaurar">
              <th className="td-table-restaurar">Nombre</th>
              <th className="td-table-restaurar">Rut</th>
              <th className="td-table-restaurar">ID Cargo</th>
              <th className="td-table-restaurar">Área de Negocio</th>
              <th className="td-table-restaurar">Tipo de Movimiento</th>
              <th className="td-table-restaurar">Observación</th>
            </tr>
) : (
  <tr className="tr-table-restaurar">
  <th className="td-table-restaurar">Nombre</th>
  <th className="td-table-restaurar">Ficha</th>
  <th className="td-table-restaurar">ID Cargo</th>
  <th className="td-table-restaurar">Área de Negocio</th>
  <th className="td-table-restaurar">Tipo de Solicitud</th>
  <th className="td-table-restaurar">Observación</th>
</tr>

  )} 

          </thead>
          <tbody className="tbody-table-restaurar">
            {list &&
              list.map((item: any) => {
                const {
                  nombreCompleto,
                  ficha,
                  codCargo,
                  areaNegocioCod,
                  tipoSolicitud,
                  observacion,
                  busPerId,
                  personaDNI,
                  procesoId
                } = item;
                return (
                  <>
                   

{retroceder ? (
 <tr className="tr-table-restaurar borderdashed" key={busPerId}>
 <td className="td-table-restaurar borderdashed">{nombreCompleto}</td>
 <td className="td-table-restaurar borderdashed">{personaDNI}</td>
 <td className="td-table-restaurar borderdashed">{procesoId}</td>
 <td className="td-table-restaurar borderdashed">{areaNegocioCod}</td>
 <td className="td-table-restaurar borderdashed">{inSolicitudContrato ? "Solicitud de contrato":"Retroceso seleccionado"}</td>
 <td className="td-table-restaurar borderdashed">{observacion}</td>
 </tr>

) : (
                      <tr className="tr-table-restaurar borderdashed" key={ficha}>
                      <td className="td-table-restaurar borderdashed">{nombreCompleto}</td>
                      <td className="td-table-restaurar borderdashed">{ficha}</td>
                      <td className="td-table-restaurar borderdashed">{codCargo}</td>
                      <td className="td-table-restaurar borderdashed">{areaNegocioCod}</td>
                      <td className="td-table-restaurar borderdashed">{tipoSolicitud}</td>
                      <td className="td-table-restaurar borderdashed">{observacion}</td>
                      </tr> 
)} 
                   
                  </>
                );
              })}
          </tbody>
        </table>
      </section>
      <section className="modal-message">
        <label>Los datos se mantendrán en la lista sin modificación.</label>
      </section>
    </>
  );
};
