import { IReducer } from "../../interfaces/IContratos";

const reduxState: any = {
  SetCountAssign: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, countAssign: payload };
  },

  SetListaRegistroColaborador: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaRegistroColaborador: payload };
  },
  
  SetListaSolicitudesPendientes: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaSolicitudesPendientes: payload };
  },

  SetListaSolicitudesProcesadas: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaSolicitudesProcesadas: payload };
  },

  SetListDocumentoColaborador: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listDocumentoColaborador: payload };
  },

  SetListaDashboardResumen: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaDashboardResumen: payload };
  },

  SetListaEstadoSolicitud: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaEstadoSolicitud: payload };
  },

  SetListaUltimosContratosSolicitados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUltimosContratosSolicitados: payload };
  },

  SetListaUltimosRenovacionesSolicitados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUltimosRenovacionesSolicitados: payload };
  },

  SetListaSolicitudProcesadaDonut: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaSolicitudProcesadaDonut: payload };
  },

  SetListaSolicitudCliente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaSolicitudCliente: payload };
  },

  SetListaSolicitudPorCliente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaSolicitudPorCliente: payload };
  },
};

export default (state: any, action: any) => {
  const { type, payload } = action;
  return reduxState[type]({ payload, state }) ?? undefined;
}; 

