import { useContext, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import ContratosContext from "../../../../../../context/Contratos/ContratosContext";

interface ISolicitudesProcesadas {
  objetoFilterGeneral?: any;
  widthDonut?: number;
  heigthDonut?: number;
}

const SolicitudesProcesadas = (props: ISolicitudesProcesadas) => {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
  const { objetoFilterGeneral, widthDonut, heigthDonut } = props;

  const {
    token,
    profile,
    roles,
    listaSolicitudProcesadaDonut,
    postSolicitudesProcesadasDonut,
  } = useContext(ContratosContext);

  useEffect(() => {
    if (token && roles && profile) {
      const objeto = {
        contrato: true,
        filtros: {
          clientes: [],
          areaNegocio: objetoFilterGeneral?.areaNegocio ?? [],
          analista: [],
        },
      };

      postSolicitudesProcesadasDonut({ token, objeto });
    }
  }, [token, objetoFilterGeneral]);

  const options = {
    responsive: false,
    cutout: "75%",
    layout: {
      padding: {
        left: 30,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right" as const,
        rtl: false,
        labels: {
          padding: 15,
          maxWidth: 100,
          usePointStyle: true,
          pointStyle: "circle",
          font: { size: 11 },
        },
      },
    },
  };

  let dataLabels;
  dataLabels = [
    [" CONTRATOS", " PENDIENTES"],
    [" RENOVACIONES", " PENDIENTES"],
    [" RECHAZADOS", " POR FIRMA", " DIGITAL"],
    [" RECHAZADOSS POR", " VISADOR"],
  ];

  const data: any = {
    labels: dataLabels,
    datasets: [],
  };

  const { contrato } = listaSolicitudProcesadaDonut ?? {
    general: undefined,
    activos: undefined,
  };
  const { contratosPendientes, renovacionesPendientes, rechazadoFirmaDigital, rechazadoVisador } = contrato ?? {
    ingresos: 0,
    fueraPlazo: 0,
    renovaciones: 0,
    bajasConfirmadas: 0,
  };

  data.datasets.push({
    label: "Cantidad",
    data: [contratosPendientes, renovacionesPendientes, rechazadoFirmaDigital, rechazadoVisador],
    backgroundColor: ["#FFCA4D", "#0065DD", "#E33D00", "#FF8DCC"],
    borderColor: ["#FFCA4D", "#0065DD", "#E33D00", "#FF8DCC"],
    borderWidth: 1,
    datalabels: {
      display: "true",
      align: "start",
      backgroundColor: "transparent",
      borderRadius: 3,
      font: {
        size: 14,
      },
      formatter: function (value: number, context: { dataIndex: string }) {
        // Calcula el porcentaje en función del valor real en los datos
        const total = contratosPendientes + renovacionesPendientes + rechazadoFirmaDigital + rechazadoVisador;
        const percentage = (value / total) * 100;

        // Retorna el porcentaje formateado
        if (isNaN(percentage)) {
          return "0%";
        } else {
          return percentage.toFixed() + "%";
        }
      },
      anchor: "start",
    },
  });

  return (
    <div className="container-table-dashboard w-35 responsiveChart">
      <div className="content-title">
        <label
          className="title-table-dashboard"
          style={{ marginBottom: "-66px" }}
        >
          SOLICITUDES PROCESADAS
        </label>
      </div>
      <Doughnut
        options={options}
        data={data}
        width={widthDonut}
        height={heigthDonut}
      />
    </div>
  );
};

export default SolicitudesProcesadas;
