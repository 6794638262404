import "../../../assets/css/servicesAssists.min.css";
import { useContext, useEffect, useState } from "react";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import AddIcon from "@mui/icons-material/Add";
import IconEdit from "../../ui/kit-svg/iconEdit";
import SendCodeModal from "./SendCodeModal";
import NewServiceModal from "./NewServiceModal";
import MediaQuery from "../../ui-responsive/MediaQuery";
import DropDownListCustomized from "../../ui/kit-mdtw/DropDownListCustomized";
import InputDateCustomized from "../../ui/kit-mdtw/InputDateCustomized";
import InputTextCustomized from "../../ui/kit-mdtw/InputTextCustomized";
import InputRutCustomized from "../../ui/kit-mdtw/InputRutCustomized";
import InputPhoneCustomized from "../../ui/kit-mdtw/InputPhoneCustomized";
import InputTimeCustomized from "../../ui/kit-mdtw/InputTimeCustomized";
import { IToken } from "../../../interfaces/IValidationKam";
import AdminAssistContext from "../../../context/AdminAssist/AdminAssistContext";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";

export default (props: IToken) => {
  const { token, setToken, getToken } = props;
  const {
    listadoProcesos,
    listadoTipoServicios,
    listadoUsuarioTransporte,
    crearServicio,
  } = useContext(AdminAssistContext);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { width, lg } = MediaQuery();
  const [isOpenModalSendCode, setIsOpenModalSendCode] =
    useState<boolean>(false);
  const [form, setForm] = useState<any[]>([{ cantidad: 2 }]);
  const [listProcess, setListProcess] = useState<IOptionDropDownList[]>([]);
  const [listTipoServicio, setListTipoServicio] = useState<
    IOptionDropDownList[]
  >([]);
  const [listUsuarioTransporte, setListUsuarioTransporte] = useState<
    IOptionDropDownList[]
  >([]);
  const [formData, setFormData] = useState<any>({
    procesoId: { value: undefined, valid: true },
    clienteNombre: { value: undefined, valid: true },
    totalCandidatos: { value: undefined, valid: true },
    tipoServicio: { value: undefined, valid: true },
    usuarioServicioGeneral: { value: undefined, valid: true },

    nombreExterno: { value: undefined, valid: true },
    rutExterno: { value: undefined, valid: true },
    correoExterno: { value: undefined, valid: true },
    fonoExterno: { value: undefined, valid: true },

    rutaInicio: { value: undefined, valid: true },
    fechaInicio: { value: undefined, valid: true },
    horaInicio: { value: undefined, valid: true },
    rutaDestino: { value: undefined, valid: true },
    fechaDestino: { value: undefined, valid: true },
    horaDestino: { value: undefined, valid: true },
    rutaInicioComuna: { value: undefined, valid: true },
    rutaDestinoComuna: { value: undefined, valid: true },
  });

  useEffect(() => {
    if (listadoProcesos) {
      const procesoId = listadoProcesos.map((item: any) => {
        const { busquedaId, clienteNombre } = item;
        return {
          code: busquedaId,
          name: busquedaId + " - " + clienteNombre,
        };
      });

      setListProcess(procesoId);
    }
  }, [listadoProcesos]);

  useEffect(() => {
    if (listadoTipoServicios) {
      const tipoServicio = listadoTipoServicios.map((item: any) => {
        const { tipoServicioId, nombre } = item;
        return {
          code: tipoServicioId,
          name: nombre,
        };
      });

      setListTipoServicio(tipoServicio);
    }
  }, [listadoTipoServicios]);

  useEffect(() => {
    if (listadoUsuarioTransporte) {
      const usuarioTransporte = listadoUsuarioTransporte.map((item: any) => {
        const { usuarioId, usuarioNombre } = item;
        return {
          code: usuarioId,
          name: usuarioNombre,
        };
      });

      setListUsuarioTransporte(usuarioTransporte);
    }
  }, [listadoUsuarioTransporte]);

  useEffect(() => {
    if (formData.procesoId?.value?.code) {
      const { clienteNombre } = listadoProcesos.find(
        (item: any) => item.busquedaId == formData.procesoId.value.code
      ) ?? { clienteNombre: undefined };

      const { totalCandidatos } = listadoProcesos.find(
        (item: any) => item.busquedaId == formData.procesoId.value.code
      ) ?? { totalCandidatos: undefined };
      setFormData({
        ...formData,
        clienteNombre: {
          value: { code: clienteNombre, name: clienteNombre },
          valid: true,
        },
        totalCandidatos: {
          value: { code: totalCandidatos, name: totalCandidatos },
          valid: true,
        },
      });
    }
  }, [formData.procesoId?.value?.code]);

  const handleClickViewModalSendCode = () => {
    if (token) {
      crearServicio({ token, formData });
    }
    // setIsOpenModalSendCode(true);
  };

  // const handleClickViewModal = () => {
  //   setIsOpenModal(true);
  // };

  useEffect(() => {
    const apiGoogleMapsActive = document.querySelector("#api-google-maps");
    const apikey = "AIzaSyBI5XtcPVYc6oTQcB1GZTRP6hV40kix4HE";
    if (!apiGoogleMapsActive) {
      const url = `https://maps.googleapis.com/maps/api/js?&language=ES&region=CL&key=${apikey}&libraries=places`;
      const scripts = document.createElement("script");
      scripts.src = url;
      scripts.type = "text/javascript";
      scripts.id = "api-google-maps";
      document.body.appendChild(scripts);
    }
  }, []);

  const handleEventGoogleApi = (value: any) => {
    const geocoder = new google.maps.Geocoder();

    if (!value) {
      return;
    }

    geocoder.geocode({ address: value }, (results, status) => {
      if (!["INVALID_REQUEST", "ZERO_RESULTS"].includes(status)) {
        const [address] = results;
        const { address_components, geometry } = address;
        const { location } = geometry;
        const comuna = address_components?.find((item: any) =>
          item.types.includes("locality")
        );
        setFormData({
          ...formData,
          rutaInicioComuna: {
            value: { code: comuna?.long_name, name: comuna?.long_name },
            valid: true,
          },
        });
      }
    });
  };

  const handleEventGoogleApiDestino = (value: any) => {
    const geocoder = new google.maps.Geocoder();

    if (!value) {
      return;
    }

    geocoder.geocode({ address: value }, (results, status) => {
      if (!["INVALID_REQUEST", "ZERO_RESULTS"].includes(status)) {
        const [address] = results;
        const { address_components, geometry } = address;
        const { location } = geometry;
        const comuna = address_components?.find((item: any) =>
          item.types.includes("locality")
        );
        setFormData({
          ...formData,
          rutaDestinoComuna: {
            value: { code: comuna?.long_name, name: comuna?.long_name },
            valid: true,
          },
        });
      }
    });
  };

  // setComuna(handleEventGoogleApi({values}))

  return (
    <>
      <div className="content-items">
        <div className="div-inputs w-30">
          <DropDownListCustomized
            list={listProcess}
            layer="ID Proceso"
            required={true}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="procesoId"
          />
          {/* <div style={{ marginTop: "38px" }}>
            <ButtonIcon>
              <AddIcon style={{ fontSize: "36px", color: "#AEAEAE" }}></AddIcon>
            </ButtonIcon>
          </div> */}
        </div>
        <div className="div-inputs w-40">
          <InputTextCustomized
            layer="Cliente"
            required={true}
            disabled={true}
            placeholder="Empresa"
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="clienteNombre"
            defaultValue={formData.clienteNombre?.value?.name ?? ""}
          />
        </div>
        <div className="div-inputs w-20">
          <InputTextCustomized
            layer="N° de Candidatos"
            required={true}
            disabled={true}
            placeholder="10"
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="totalCandidatos"
            defaultValue={formData.totalCandidatos?.value?.name ?? ""}
          />
        </div>
      </div>

      {/* <span className="title-table">Proceso Asignado</span>
      <div className="project-table">
        <div className="content">
          <div className="project-table-title">Proceso</div>
          <div className="project-table-subtitle">
            123456 - AUXILIAR DE OLLA TARDE
          </div>
        </div>
        <div className="content">
          <div className="project-table-title">Cliente</div>
          <div className="project-table-subtitle">CAROZZI</div>
        </div>
        <div className="content">
          <div className="project-table-title">Candidatos</div>
          <div className="project-table-subtitle">10</div>
        </div>
        <div className="content">
          <div className="project-table-title">Destino</div>
          <div className="project-table-subtitle">
            Av. El Salto 4447, Huechuraba, Región Metropolitana
          </div>
        </div>
        <div className="content">
          <div className="project-table-title">Fecha</div>
          <div className="project-table-subtitle">29/01/23</div>
        </div>
        <div className="content">
          <div className="project-table-title">Hora</div>
          <div className="project-table-subtitle">14:15</div>
        </div>
        <div className="content">
          <div className="project-table-title">Acciones</div>
          <div className="project-table-subtitle">
            <ButtonIcon>
              <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
            </ButtonIcon>
          </div>
        </div>
      </div> */}

      <div className="content-items">
        <div className="div-inputs w-30">
          <DropDownListCustomized
            list={listTipoServicio}
            layer="Servicio de Transporte"
            required={true}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="tipoServicio"
          />
        </div>
        <div className="div-inputs w-20">
          <DropDownListCustomized
            list={listUsuarioTransporte}
            required={false}
            disabled={
              formData.tipoServicio?.value?.name == "SERVICIOS GENERALES"
                ? false
                : true
            }
            layer="Nombre"
            placeholder="Nombre"
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="usuarioServicioGeneral"
          />
        </div>
        <div className="w-30"></div>
      </div>

      <div className="content-items">
        <div className="div-inputs w-30">
          <InputTextCustomized
            required={true}
            disabled={
              formData.tipoServicio?.value?.name == "SERVICIO EXTERNO"
                ? false
                : true
            }
            layer="Nombre"
            placeholder="Nombre"
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="nombreExterno"
          />
        </div>
        <div className="div-inputs w-15">
          <InputRutCustomized
            placeholder="12345678-9"
            layer="Rut"
            required={true}
            disabled={
              formData.tipoServicio?.value?.name == "SERVICIO EXTERNO"
                ? false
                : true
            }
            maxLength={12}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="rutExterno"
          />
        </div>
        <div className="div-inputs w-25">
          <InputTextCustomized
            required={true}
            disabled={
              formData.tipoServicio?.value?.name == "SERVICIO EXTERNO"
                ? false
                : true
            }
            layer="Correo"
            placeholder="correo@correo.cl"
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="correoExterno"
          />
        </div>
        <div className="div-inputs w-15">
          <InputPhoneCustomized
            required={true}
            disabled={
              formData.tipoServicio?.value?.name == "SERVICIO EXTERNO"
                ? false
                : true
            }
            placeholder="56 9 1234 5678"
            layer="Fono"
            maxLength={14}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="fonoExterno"
          />
          {/* <div style={{ marginTop: "38px" }}>
            <ButtonIcon>
              <AddIcon style={{ fontSize: "36px", color: "#AEAEAE" }}></AddIcon>
            </ButtonIcon>
          </div> */}
        </div>
      </div>

      {/* <span className="title-table">Transporte Asignado</span>
      <div className="project-table">
        <div className="content">
          <div className="project-table-title">Tipo</div>
          <div className="project-table-subtitle">Otro</div>
        </div>
        <div className="content">
          <div className="project-table-title">Nombre</div>
          <div className="project-table-subtitle">Olegario Cepeda Asenjo</div>
        </div>
        <div className="content">
          <div className="project-table-title">Rut</div>
          <div className="project-table-subtitle">44.444.444-4</div>
        </div>
        <div className="content">
          <div className="project-table-title">Correo</div>
          <div className="project-table-subtitle">nombre@correo.cl</div>
        </div>
        <div className="content">
          <div className="project-table-title">Fono</div>
          <div className="project-table-subtitle">+56 9 8997 3410</div>
        </div>
        <div className="content">
          <div className="project-table-title">Acciones</div>
          <div className="project-table-subtitle">
            <ButtonIcon>
              <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
            </ButtonIcon>
          </div>
        </div>
      </div> */}

      <div className="content-items">
        <div className="div-inputs w-50">
          <InputTextCustomized
            required={true}
            layer="Inicio"
            placeholder="Dirección"
            onBlur={handleEventGoogleApi}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="rutaInicio"
          />
        </div>
        <div className="div-inputs w-20">
          <InputDateCustomized
            placeholder="dd/mm/aaaa"
            layer="Fecha"
            required={true}
            maxLength={10}
            errorPosition="down"
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="fechaInicio"
          />
        </div>
        <div className="div-inputs w-20">
          <InputTimeCustomized
            placeholder="14:15"
            layer="Hora"
            required={true}
            errorPosition="down"
            maxLength={5}
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="horaInicio"
          />
          {/* <div style={{ marginTop: "38px" }}>
            <ButtonIcon>
              <AddIcon style={{ fontSize: "36px", color: "#AEAEAE" }}></AddIcon>
            </ButtonIcon>
          </div> */}
        </div>
      </div>

      <div className="content-items">
        <div className="div-inputs w-50">
          <InputTextCustomized
            required={true}
            disabled={false}
            layer="Destino"
            errorPosition="down"
            placeholder="Empresa"
            onBlur={handleEventGoogleApiDestino}
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="rutaDestino"
            defaultValue={formData.rutaDestino?.value?.name ?? ""}
          />
        </div>
        <div className="div-inputs w-20">
          <InputDateCustomized
            placeholder="dd/mm/aaaa"
            disabled={false}
            layer="Fecha"
            required={true}
            errorPosition="down"
            maxLength={10}
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="fechaDestino"
            defaultValue={formData.fechaDestino?.value?.name ?? ""}
          />
        </div>
        <div className="div-inputs w-20">
          <InputTimeCustomized
            placeholder="14:15"
            disabled={false}
            layer="Hora"
            required={true}
            errorPosition="down"
            maxLength={5}
            onValueChange={setFormData}
            onPermaValues={formData}
            onPropertyChange="horaDestino"
            defaultValue={formData.horaDestino?.value?.name ?? ""}
          />
        </div>
      </div>
      {/* 
      {rutaAsignada &&
        rutaAsignada.map((item: any) => {
          return (
            <>
              <span className="title-table">Ruta Asignada</span>
              <div className="project-table">
                <div className="content">
                  <div className="project-table-title">Inicio</div>
                  <div className="project-table-subtitle">
                    {item.inicio.value ?? "-"}
                  </div>
                </div>
                <div className="content">
                  <div className="project-table-title">Fecha</div>
                  <div className="project-table-subtitle">{item.fechaInicio.value ?? "-"}</div>
                </div>
                <div className="content">
                  <div className="project-table-title">Hora</div>
                  <div className="project-table-subtitle">{item.horaInicio.value ?? "-"}</div>
                </div>
                <div className="content">
                  <div className="project-table-title">Destino</div>
                  <div className="project-table-subtitle">
                    {item.destino.value ?? "-"}
                  </div>
                </div>
                <div className="content">
                  <div className="project-table-title">Fecha</div>
                  <div className="project-table-subtitle">{item.fechaDestino.value ?? "-"}</div>
                </div>
                <div className="content">
                  <div className="project-table-title">Hora</div>
                  <div className="project-table-subtitle">{item.horaDestino.value ?? "-"}</div>
                </div>
                <div className="content">
                  <div className="project-table-title">Acciones</div>
                  <div className="project-table-subtitle">
                    <ButtonIcon>
                      <DoDisturbOnRoundedIcon style={{ color: "#FE0000" }} />
                    </ButtonIcon>
                  </div>
                </div>
              </div>
            </>
          );
        })} */}

      <div className="line-separate" style={{ marginBottom: "-20px" }}></div>
      <div className="content-items">
        <div className="content-button-confirm">
          <button
            onClick={() => handleClickViewModalSendCode()}
            className="button-confirm"
            style={width >= lg ? {} : { width: "60%" }}
          >
            Crear Servicio
          </button>
        </div>
      </div>

      {isOpenModal && !isOpenModalSendCode && (
        <NewServiceModal
          title="Guardar Servicio N°654321"
          message="Has creado un nuevo servicio N°654321 para transporte externo. A continuación verás los datos de las personas a las que se enviará el correo con el código de acceso."
          width={width >= lg ? "60%" : "95%"}
          onChangeBack={() => {
            setIsOpenModal(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModal(false);
            setIsOpenModalSendCode(true);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {!isOpenModal && isOpenModalSendCode && (
        <SendCodeModal
          title="Enviar Invitación de acceso al Servicio N°654321"
          subTitle="Vas a Enviar el correo de acceso externo al servicio N°654321. A continuación el detalle de los destinatarios:"
          message="Selecciona la cantidad de códigos de acceso al servicio que desea asignar a cada destinatario."
          massive={false}
          width={width >= lg ? "60%" : "95%"}
          onChangeBack={() => {
            setIsOpenModalSendCode(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModalSendCode(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}
    </>
  );
};
