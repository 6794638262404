import { useContext, useEffect, useState } from "react";
import "../../../assets/css/seleccion-masiva.min.css";
import InputRutCustomized from "../kit-mdtw/InputRutCustomized";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ModalUpdateCandidate from "../kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import ModalEncuesta from "../../pages/Campaign/ModalEncuesta";
import IconArrowDown from "../icons-seleccion/IconArrowDown";
import CargaNomina from "./CargaNomina";
import ModalGenerico from "../kit-modal/ModalGenerico";
import ModalSolicitarRegistro from "../../pages/Seleccion/RegistroPorValidarMasivo/Modal/ModalSolicitarRegistro";
import CargaNominaNew from "./CargaNominaNew";
interface INuevoCandidato {
  cargarNomina?: Function;
  getCandidates?: Function;
}

export default (props: INuevoCandidato) => {
  const { getCandidates } = props;
  const {
    token,
    getToken,
    listaGeneral,
    getRutVerificado,
    postSolicitarCandidato,
    emptyMessage,
  } = useContext(SeleccionContext);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = useState<boolean>(false);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [isOpenModalSolicitar, setIsOpenModalSolicitar] =
    useState<boolean>(false);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [hidden, setHidden] = useState<boolean>(false);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isFreeSolicitar, setIsFreeSolicitar] = useState<boolean | undefined>(
    undefined
  );
  const [solicitudes, setSolicitudes] = useState<any>([]);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [solEnviada, setSolEnviada] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  const [rut, setRut] = useState<string | undefined>("");
  const [rutCopy, setRutCopy] = useState<string | undefined>("");

  useEffect(() => {
    if (resultBusperId) {
      setIsOpenModalCampaign(true);
    }
  }, [resultBusperId]);

  useEffect(() => {
    setRutCopy(rut);
  }, [rut]);

  useEffect(() => {
    if (rut !== rutCopy) {
      setMessageSpanish(
        "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
      );
      setIsFree(undefined);
      setIsFreeSolicitar(undefined);
    }
    setRutCopy(rut);
  }, [rut]);

  useEffect(() => {
    if (solEnviada) {
      setMessageSpanish(
        "* Solicitud de registro enviada, revisa en la sección Traspasos el estado de esta solicitud"
      );
    }
  }, [solEnviada]);

  const handleClickModalUpdate = () => {
    setIsOpenModalUpdate(true);
  };

  const handleClickCargarNomina = () => {
    setUploadModalVisible(true);
  };

  const handleClickVerificarRut = (values: any) => {
    const { personaRut, cargoGroup } = values;
    getRutVerificado({
      personaRut,
      setIsFree,
      setIsFreeSolicitar,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: true,
      token,
    });
  };

  const handleClickSolicitar = () => {
    setCandidateSelect([]);
    setIsOpenModalSolicitar(true);
    setCandidateSelect([rut]);
  };

  const handleClickSolicitarCandidato = async (values: any) => {
    const { procesoDestino } = values;
    if (token) {
      await postSolicitarCandidato({ token, procesoDestino });
    }
  };

  return (
    <>
      <div className="container-new-nuevo-candidato">
        <div
          className={`content-new-nuevo-candidato ${
            hidden && "hidden-section"
          }`}
        >
          <header className="content-title">
            <h4 className="title">Registro Nuevo Candidato</h4>
            <button
              onClick={() => setHidden(!hidden)}
              className={`button-arrow-down ${!hidden && "content-hidden"}`}
            >
              <IconArrowDown fill={"#1D1D1D"} />
            </button>
          </header>
          <section
            className={`container-action-nuevo-candidato ${
              hidden && "hidden-section"
            }`}
          >
            <section className={`content-accion`}>
              <section className="section-left">
                <InputRutCustomized
                  layer="Rut (*)"
                  placeholder="12345678-9"
                  validacion={setButtonDisabled}
                  setValue={setRut}
                  errorPosition="down"
                  maxLength={12}
                  required={false}
                />
                <button
                  className={`button-all ${
                    buttonDisabled ? "disabled" : "rut"
                  }`}
                  disabled={buttonDisabled}
                  onClick={() => handleClickVerificarRut({ personaRut: rut })}
                >
                  {!isLoading ? (
                    <>
                      <ArrowBackIcon />
                      Revisar Rut
                    </>
                  ) : (
                    <>Revisando...</>
                  )}
                </button>
              </section>
              <section className="section-rigth">
                <button
                  className={`button-all ${
                    isFreeSolicitar ? "solicitar" : "disabled"
                  }`}
                  onClick={handleClickSolicitar}
                  disabled={!isFreeSolicitar}
                >
                  Solicitar Candidato
                </button>
                <button
                  className={`button-all ${isFree ? "continuar" : "disabled"}`}
                  onClick={handleClickModalUpdate}
                  disabled={!isFree}
                  value={rut}
                >
                  Continuar Registro
                </button>
              </section>
            </section>
            <section className="content-message">
              <p
                className={`message message-${
                  isFree ?? "initial"
                    ? "initial"
                    : solEnviada
                    ? "success"
                    : "danger"
                }`}
              >
                {messageSpanish}
              </p>
            </section>

            <footer className="content-footer">
              <div className="content-section">
                <button
                  className="button-cargar"
                  onClick={handleClickCargarNomina}
                >
                  <UploadFileIcon />
                  Cargar Nómina
                </button>
                <span className="span-section">
                  Sólo se podrá agregar candidatos que tengan la información
                  completa (Nombre, Apellido, Rut y Teléfono).
                </span>
              </div>
            </footer>
          </section>
        </div>
      </div>

      {isOpenModalUpdate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdate}
          setResultBusperId={setResultBusperId}
          token={token}
          rut={rut}
          getCandidates={getCandidates}
          onChangeBack={() => {
            setIsOpenModalUpdate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => setIsOpenModalCampaign(false)}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}

      {isOpenModalSolicitar && (
        <ModalGenerico
          width="w-70"
          title=""
          subtitle="Selecciona la fuente de reclutamiento y luego el archivo correspondiente."
          onChangeBack={() => {
            setIsOpenModalSolicitar(false);
            setCandidateSelect([]);
          }}
          buttonBackText="Cerrar"
        >
          <ModalSolicitarRegistro
            rut={candidateSelect}
            solicitudes={solicitudes}
            setSolicitudes={setSolicitudes}
            handleClickSolicitarCandidato={handleClickSolicitarCandidato}
            onChangeBack={() => {
              setIsOpenModalSolicitar(false);
              setCandidateSelect([]);
            }}
          />
        </ModalGenerico>
      )}

      {uploadModalVisible && (
        <CargaNominaNew
          token={token}
          getToken={getToken}
          uploadModalVisible={uploadModalVisible}
          setUploadModalVisible={setUploadModalVisible}
          emptyMessage={emptyMessage}
        />
      )}

      {}
    </>
  );
};
