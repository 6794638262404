import { IIconTw } from "../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63 72.546C68.7909 72.546 73.7386 70.5414 77.8432 66.5323C81.9477 62.5233 84 57.6233 84 51.8324C84 46.8687 82.5045 42.446 79.5136 38.5642C76.5227 34.6823 72.6091 32.2005 67.7727 31.1187C66.1818 25.2642 63 20.5233 58.2273 16.896C53.4545 13.2687 48.0455 11.4551 42 11.4551C34.5545 11.4551 28.2386 14.0483 23.0523 19.2346C17.8659 24.421 15.2727 30.7369 15.2727 38.1823C10.8818 38.6914 7.23864 40.5846 4.34318 43.8619C1.44773 47.1392 -3.45479e-06 50.9733 -3.83865e-06 55.3642C-4.2559e-06 60.1369 1.67045 64.1937 5.01136 67.5346C8.35227 70.8755 12.4091 72.546 17.1818 72.546L38.1818 72.546C40.2818 72.546 42.0795 71.7983 43.575 70.3028C45.0704 68.8073 45.8182 67.0096 45.8182 64.9096L45.8182 45.246L51.9273 51.1642L57.2727 45.8187L42 30.546L26.7273 45.8187L32.0727 51.1642L38.1818 45.246L38.1818 64.9096L17.1818 64.9096C14.5091 64.9096 12.25 63.9869 10.4045 62.1414C8.55909 60.296 7.63636 58.0369 7.63636 55.3642C7.63636 52.6914 8.55909 50.4323 10.4045 48.5869C12.25 46.7414 14.5091 45.8187 17.1818 45.8187L22.9091 45.8187L22.9091 38.1823C22.9091 32.9005 24.7705 28.3983 28.4932 24.6755C32.2159 20.9528 36.7182 19.0914 42 19.0914C47.2818 19.0914 51.7841 20.9528 55.5068 24.6755C59.2295 28.3983 61.0909 32.9005 61.0909 38.1823L63 38.1823C66.6909 38.1823 69.8409 39.4869 72.45 42.096C75.0591 44.7051 76.3636 47.8551 76.3636 51.546C76.3636 55.2369 75.0591 58.3869 72.45 60.996C69.8409 63.6051 66.6909 64.9096 63 64.9096L53.4545 64.9096L53.4545 72.546L63 72.546Z"
      />
    </svg>
  );
};
