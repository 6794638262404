import "../../../assets/css/adminModal.min.css";
import { useEffect, useState, useContext } from "react";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import CircleIcon from "@mui/icons-material/Circle";
import IconEdit from "../../ui/kit-svg/iconEdit";
import CloseIcon from "@mui/icons-material/Close";
import IconExtenderTiempo from "../../ui/kit-svg/IconExtenderTiempo";
import IconEliminar from "../../ui/kit-svg/IconEliminar";
import WarningIcon from "@mui/icons-material/Warning";
import { Button } from "primereact/button";
import ModalMessage from "../../ui/adminAssist/modals/ModalMessage";
import AdminAssistContext from "../../../context/AdminAssist/AdminAssistContext";
import MediaQuery from "../../ui-responsive/MediaQuery";

interface IInfoServiceMobileModal {
  title: string;
  message: string;
  list?: any[];
  onChangeBack?: Function;
  onChangeGo?: Function;
  widthModal?: string;
}

export default (props: IInfoServiceMobileModal) => {
  const {width, lg} = MediaQuery();
  const { handleClickActive } = useContext(AdminAssistContext);
  const { title, message, onChangeBack, onChangeGo, widthModal, list } = props;
  const [isOpenDeleteData, setIsOpenDeleteData] = useState<boolean>(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  return (
    <>
      <div className="modal-container-admin">
        <div
          className="modal-content-admin"
          style={{ width: widthModal }}
        >
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon /> 
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>

          <div>
            <table className="modal-table-infoedit-mobile">
              <thead>
                <tr>
                  <th>Prioridad</th>
                  <th>Estado</th>
                  <th>ID Servicio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ButtonIcon>
                      <CircleIcon style={{ color: "#FE0000" }} />
                    </ButtonIcon>
                  </td>
                  <td>
                    <ButtonIcon>
                      <WarningIcon style={{ color: "#FFB400" }} />
                    </ButtonIcon>
                  </td>
                  <td>654321</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="modal-table-infoedit-mobile">
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CAROZZI</td>
                  <td>29/12/22</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="modal-table-infoedit-mobile">
              <thead>
                <tr>
                  <th>Transporte</th>
                  <th>Pasajeros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre</td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="modal-table-infoedit-mobile">
              <thead>
                <tr>
                  <th>Hora Inicio</th>
                  <th>Hora Destino</th>
                  <th>Expira Código</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>14:15</td>
                  <td>15:15</td>
                  <td>29/12/22 14:00</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="modal-table-infoedit-mobile">
              <thead>
                <tr>
                  <th>Activar/Desactivar</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        
                        onClick={({}) =>
                          handleClickActive({
                            value: true ? false : true,
                            index: 654321,
                          })
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                  <td>
                    <div className="content-button">
                      <ButtonIcon>
                        <IconExtenderTiempo
                          color={`#AEAEAE`}
                          width={32}
                          height={32}
                        />
                      </ButtonIcon>
                      <ButtonIcon>
                        <IconEdit color={`#AEAEAE`} width={32} height={32} />
                      </ButtonIcon>
                      <ButtonIcon>
                        <IconEliminar
                          color={`#AEAEAE`}
                          width={32}
                          height={32}
                        />
                      </ButtonIcon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="modal-message-bottom">
            <span>
              Para pasar el candidato a la siguiente etapa presiona Enviar a
              Validación cliente.
            </span>
          </div>

          <div className="modal-message-bottom">
            <span>
              Para pasar candidato a contratación directamente, deshabilita la
              opción de validación cliente y presiona Enviar a contratación.
            </span>
          </div>

          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) setIsOpenDeleteData(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              className="button-go-confirm"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>

      {isOpenDeleteData ? (
        <ModalMessage
          title="¿Está seguro qué desea salir de la página actual?"
          message="Se perderán los cambios no guardados"
          onChangeBack={() => setIsOpenDeleteData(false)}
          onChangeGo={() => {
            setIsOpenDeleteData(false);
          }}
          success={false}
          confirm={false}
          width={"90%"}
        />
      ) : (
        ""
      )}
    </>
  );
};
