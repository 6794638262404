import { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { convertThousand } from "../../../../../functions/kit-mdtw/number";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import { formatPhone } from "../../../../../functions/kit-mdtw/phone";

interface IAreaPersonal {
  list?: any;
  formCandidate: any;
}

export default (props: IAreaPersonal) => {
  const { list, formCandidate } = props;
  const { listaGeneral, listGroup } = useContext(SeleccionContext);
  const [hidden, setHidden] = useState<boolean>(true);
  const [renta, setRenta] = useState<string | undefined>(undefined);
  const [tallaPolera, setTallaPolera] = useState<string | undefined>(undefined);
  const [licencia, setLicencia] = useState<string | undefined>(undefined);
  const [tallaPantalon, setTallaPantalon] = useState<string | undefined>(
    undefined
  );
  const [tallaZapato, setTallaZapato] = useState<number | undefined>(undefined);
  const [disponibilidad, setDisponibilidad] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (Object.keys(listaGeneral).length > 0) {
      if (listaGeneral && listGroup && formCandidate) {
        const { nombre: nameDisponibilidad } = listaGeneral.disponibilidad.find(
          (item: any) =>
            formCandidate.laboral?.disponibilidadId == item.disponibilidadId
        ) ?? { nombre: undefined };
        setDisponibilidad(nameDisponibilidad);

        const cargos = listGroup.flatMap((item: any) => item.busquedas);
        const { busquedaRenta } = cargos.find(
          (item: any) => formCandidate?.laboral?.procesoId === item.busquedaId
        ) ?? { busquedaRenta: undefined };
        setRenta(busquedaRenta);

        const { tallaNombre: tallaPolera } = listaGeneral?.tallas.numerica.find(
          (item: any) =>
            formCandidate?.laboral?.uniforme.tallaPoleraId == item.tallaId
        ) ?? { tallaNombre: undefined };
        setTallaPolera(tallaPolera);

        const { tallaNombre: tallaPantalon } =
          listaGeneral?.tallas.numerica.find(
            (item: any) =>
              formCandidate?.laboral?.uniforme.tallaPantalonId == item.tallaId
          ) ?? { tallaNombre: undefined };
        setTallaPantalon(tallaPantalon);

        const { tallaNombre: tallaZapato } =
          listaGeneral?.tallas.alfanumerica.find(
            (item: any) =>
              formCandidate?.laboral?.uniforme.tallaZapatoId == item.tallaId
          ) ?? { tallaNombre: undefined };
        setTallaZapato(tallaZapato);

        const { nombre: tipoLicencia } =
          listaGeneral?.tipoLicenciaConducir.find(
            (item: any) =>
              formCandidate?.laboral?.licenciaConducir?.tipoLicenciaId ==
              item.tipoLicenciaConducirId
          ) ?? { nombre: undefined };
        setLicencia(tipoLicencia);
      }
    }
  }, [listaGeneral, listGroup, formCandidate]);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };
  return (
    <>
      <div className={`area-table`} onClick={() => handleClickHidden()}>
        <span>ÁREA LABORAL</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <div className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table">
          {/* EXPERIENCIA */}
          <div className="title">EXPERIENCIA</div>
          <div className="content-table-row">
            <span className="title-table-row">PRETENSIÓN DE RENTA</span>
            <span className="subtitle-table-row">
              {(renta && (
                <>${convertThousand({ number: String(renta) })}.-</>
              )) ?? <>-</>}
            </span>{" "}
            {/* Falta */}
          </div>
          <div className="content-table uno">
            <span className="title-table">
              EXPERIENCIA EN RELACION AL CARGO
            </span>
            <span className="subtitle-table">
              {formCandidate.laboral?.experienciaEnCargo ?? <>-</>}
            </span>
          </div>
          <div className="content-table uno">
            <span className="title-table">FEEDBACK CANDIDATO</span>
            <span className="subtitle-table">
              {formCandidate.laboral?.feedback ?? <>-</>}
            </span>
          </div>

          <div className="title">DISPONIBILIDAD</div>
          <div className="content-table-row">
            <span className="title-table-row">
              DISPONIBILIDAD PARA EL CARGO
            </span>
            <span className="subtitle-table-row">
              {disponibilidad ?? <>-</>}
            </span>
          </div>

          {/* REFERENCIAS */}
          <div className="title">REFERENCIAS</div>
          {formCandidate.laboral?.referencias?.length > 0 &&
            formCandidate &&
            formCandidate.laboral &&
            formCandidate.laboral?.referencias.map((referencia: any) => (
              <>
                <div className="content-table">
                  <span className="title-table">NOMBRE</span>
                  <span className="subtitle-table">
                    {referencia.nombreCompleto ?? <>-</>}
                  </span>
                </div>
                <div className="content-table">
                  <span className="title-table">CARGO</span>
                  <span className="subtitle-table">
                    {referencia.cargo ?? <>-</>}
                  </span>
                </div>
                <div className="content-table">
                  <span className="title-table">EMPRESA</span>
                  <span className="subtitle-table">
                    {referencia.empresa ?? <>-</>}
                  </span>
                </div>
                <div className="content-table">
                  <span className="title-table">FONO</span>
                  <span className="subtitle-table">
                    {formatPhone(referencia.fono) ?? <>-</>}
                  </span>
                </div>
              </>
            ))}
          {formCandidate.laboral?.referencias.length === 0 && (
            <>
              <div className="content-table">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
              <div className="content-table">
                <span className="title-table">CARGO</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
              <div className="content-table">
                <span className="title-table">EMPRESA</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
              <div className="content-table">
                <span className="title-table">FONO</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
            </>
          )}

          {/* <div className="content-button-plus">
            <button className="button-plus">
              <AddIcon />
            </button>
          </div> */}

          {/* REFERIR */}
          <div className="title">REFERIR</div>
          {formCandidate.laboral?.referidos?.length > 0 &&
            formCandidate &&
            formCandidate.laboral &&
            formCandidate.laboral?.referidos?.map((referido: any) => (
              <>
                <div className="content-table dos">
                  <span className="title-table">NOMBRE</span>
                  <span className="subtitle-table">
                    {referido?.nombreCompleto}
                  </span>
                </div>
                <div className="content-table dos">
                  <span className="title-table">FONO</span>
                  <span className="subtitle-table">
                    {formatPhone(referido?.fono)}
                  </span>
                </div>
              </>
            ))}
          {formCandidate.laboral?.referidos?.length === 0 && (
            <>
              <div className="content-table dos">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
              <div className="content-table dos">
                <span className="title-table">FONO</span>
                <span className="subtitle-table">{<>-</>}</span>
              </div>
            </>
          )}
          {/* <div className="content-button-plus">
            <button className="button-plus">
              <AddIcon />
            </button>
          </div> */}

          {/* TALLA UNIFORME */}
          <div className="title">TALLA UNIFORME</div>
          <div className="content-table tres">
            <span className="title-table">POLERA</span>
            <span className="subtitle-table">{tallaPolera ?? <>-</>}</span>
          </div>
          <div className="content-table tres">
            <span className="title-table">PANTALÓN</span>
            <span className="subtitle-table">{tallaPantalon ?? <>-</>}</span>
          </div>
          <div className="content-table tres">
            <span className="title-table">ZAPATOS</span>
            <span className="subtitle-table">{tallaZapato ?? <>-</>}</span>
          </div>

          {/* SALUD */}
          <div className="title">SALUD</div>
          <div className="content-table-row">
            <span className="title-table-row">ENFERMEDADES</span>
            <span className="subtitle-table-row">
              {formCandidate.laboral?.salud.enfermedades == false ? "NO" : "SI"}
            </span>
          </div>
          <div className="content-table-row">
            <span className="title-table-row">DISCAPACIDAD</span>
            <span className="subtitle-table-row">
              {formCandidate.laboral?.salud.discapacidad == false ? "NO" : "SI"}
            </span>
          </div>
          <div className="content-table-row">
            <span className="title-table-row">ESQUEMA VACUNACION COVID</span>
            <span className="subtitle-table-row">
              {formCandidate.laboral?.salud.vacunas.esquemaCovidCompleto ==
              false
                ? "INCOMPLETO"
                : "COMPLETO"}
            </span>
          </div>
          <div className="content-table-row">
            <span className="title-table-row">REFUERZO VACUNACION COVID</span>
            <span className="subtitle-table-row">
              {formCandidate.laboral?.salud.vacunas.refuerzo == false
                ? "NO"
                : "SI"}
            </span>
          </div>

          {/* LICENCIA DE CONDUCIR */}
          <div className="title">LICENCIA DE CONDUCIR</div>
          <div className="content-table">
            <span className="title-table">TIPO</span>
            <span className="subtitle-table">{licencia ?? <>-</>}</span>
          </div>
          <div className="content-table">
            <span className="title-table">CLASE</span>
            <span className="subtitle-table">
              {formCandidate.laboral?.licenciaConducir.claseLicenciaId ?? (
                <>-</>
              )}
            </span>
          </div>
          <div className="content-table">
            <span className="title-table">VIGENCIA</span>
            <span className="subtitle-table">
              {formatDateToDDMMAAAA({
                date: formCandidate.laboral?.licenciaConducir.fechaVigencia,
              })}
            </span>
          </div>
          <div className="content-table">
            <span className="title-table">FECHA RENOVACIÓN</span>
            <span className="subtitle-table">
              {formatDateToDDMMAAAA({
                date: formCandidate.laboral?.licenciaConducir.fechaRenovacion,
              })}
            </span>
          </div>

          {/* EXAMEN PREOCUPACIONAL */}
          <div className="title">EXAMEN PREOCUPACIONAL</div>
          <div className="content-table tres">
            <span className="title-table">POSEE</span>
            <span className="subtitle-table">
              {formCandidate.laboral?.examenPreocupacional
                .tieneExamenPreocupacional == false
                ? "NO"
                : "SI"}
            </span>
          </div>
          <div className="content-table tres">
            <span className="title-table">TIPO</span>
            <span className="subtitle-table">
              {formCandidate.laboral?.examenPreocupacional
                ?.tipoExamPreocupacional ?? "-"}
            </span>
          </div>
          <div className="content-table tres">
            <span className="title-table">VIGENCIA</span>
            <span className="subtitle-table">
              {formatDateToDDMMAAAA({
                date: formCandidate.laboral?.examenPreocupacional
                  .fechaVigenciaExam,
              })}
            </span>
          </div>

           INFORME PSICOLABORAL 
         <div className="title">INFORME PSICOLABORAL</div>
          <div className="content-table-row">
            <span className="title-table-row">CONCLUSION</span>
            <span className="subtitle-table-row">{formCandidate.laboral?.psicolaboral?.conclusion ?? "-"}</span>
          </div>
          <div className="content-table uno">
            <span className="title-table">OBSERVACIONES</span>
            <span className="subtitle-table">
            {formCandidate.laboral?.psicolaboral?.observaciones ?? "-"}
            </span>
          </div>
      
        </div>
      </div>
    </>
  );
};
