import { IConvertThousands } from "../../interfaces/IInput";

export const convertThousandRut = (values: IConvertThousands) => {
  const { number } = values;
  let thousand: string = "";

  let round = 0;
  const array = number.split("");

  for (let i = array.length - 1; i >= 0; i--) {
    thousand = `${String(array[i])}${round === 3 ? "" : ""}${thousand}`;
    round = (round === 3 ? 0 : round) + 1;
  }

  return thousand;
};

export const convertThousand = (values: IConvertThousands) => {
  const { number } = values;
  let thousand: string = "";

  let round = 0;
  const array = number.split("");

  for (let i = array.length - 1; i >= 0; i--) {
    thousand = `${String(array[i])}${round === 3 ? "." : ""}${thousand}`;
    round = (round === 3 ? 0 : round) + 1;
  }

  return thousand;
};
