import { IReducer } from "../../interfaces/IOperacionesContratacion";

const reduxState: any = {
  SetCountAssign: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, countAssign: payload };
  },

  SetListaCliente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCliente: payload };
  },
  
  SetListaAreaNegocio: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaAreaNegocio: payload };
  },

  SetListaUsuarioAnalista: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUsuarioAnalista: payload };
  },

  SetListaDocumentosVigentes: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaDocumentosVigentes: payload };
  },

  SetListaBajasConfirmadas: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaBajasConfirmadas: payload };
  },

  SetListaDocumentosSolicitados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaDocumentosSolicitados: payload };
  },

  SetListaBajasCalculadas: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaBajasCalculadas: payload };
  },
 
  SetListaNoProcesados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaNoProcesados: payload };
  },

  SetListaEstadisticaResumenGeneral: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaEstadisticaResumenGeneral: payload };
  },

  SetListaIngresosProcesados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaIngresosProcesados: payload };
  },

  SetListaUltimosCandidatosValidados: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUltimosCandidatosValidados: payload };
  },

  SetListaEstadosForGrafic: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaEstadosForGraficos: payload };
  },

  SetListaResumenEstadosOperaciones: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaResumenEstadosOperaciones: payload };
  },

  SetListaAnalistaCliente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaResumenAnalistaCliente: payload };
  },
  
  SetListaIngresoDotacion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaResumenIngresoDotacion: payload };
  },

  SetListaUltimasSolicitudesProcesadas: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUltimasSolicitudesProcesadas: payload };
  },

  SetListasUltimasBajasProcesadas: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listasUltimasBajasProcesadas: payload };
  },
};

export default (state: any, action: any) => {
  const { type, payload } = action;
  return reduxState[type]({ payload, state }) ?? undefined;
}; 

