import { useEffect, useState, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import {
  IDropDownList,
  IHandleChangeSearchList,
  IHandleClickVisibleList,
  IOptionDropDownList,
} from "../../../interfaces/IDropDownList";
import { ICategoriesValidationInput } from "../../../interfaces/IInput";

export default (props: IDropDownList) => {
  const {
    list,
    layer,
    onPermaValues,
    onPropertyChange,
    onValueChange,
    disabled
  } = props;
  const [dropDownListActive, setDropdownListActive] = useState<boolean>(false);
  const [options, setOptions] = useState<IOptionDropDownList[]>([]);
  const [optionSelected, setOptionSelected] = useState<IOptionDropDownList[]>(
    []
  );
  const [identifiedAborDDL] = useState<string>(uuidv4());
  const [classAbortDDL] = useState<string>("abort-dropdownlist");
  const [enabledSearch, setEnabledSearch] = useState<boolean>(false);
  const refDropDownListCustomized = useRef<any>();
  const [directionCollapse, setDirectionCollapse] = useState<
    string | undefined
  >(undefined);

  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
  });
  const [inUseDropDownList, setInUseDropDownList] = useState<boolean>(false);
  const [optionValueSelected, setOptionValueSelected] = useState<string>("");

  useEffect(() => {
    window.addEventListener("click", handleEventClickWindow);
    window.addEventListener("scroll", handleEventScrollWindow);
    window.addEventListener("resize", handleEventReziseWindow);

    return () => {
      window.removeEventListener("click", handleEventClickWindow);
      window.removeEventListener("scroll", handleEventScrollWindow);
      window.removeEventListener("resize", handleEventReziseWindow);
    };
  }, []);

  useEffect(() => {
    if (list) {
      setOptions([...list]);
    }
  }, [list]);

  useEffect(() => {
    if (!dropDownListActive) {
      setOptions([...list]);
      setEnabledSearch(false);
    }
  }, [dropDownListActive]);

  useEffect(() => {
    let valueOption: string = "";

    if (optionSelected.length > 0) {
      if (optionSelected.length > 1) {
        valueOption = `${optionSelected.length} selecciones`;
      } else {
        optionSelected
          .map((option) => option.name)
          .map((option) => {
            valueOption = option;
          });
      }
    }

    setOptionValueSelected(valueOption);
  }, [optionSelected]);

  const handleEventScrollWindow = () => {
    handleEventDirectionDropDownList();
  };

  const handleEventReziseWindow = () => {
    handleEventDirectionDropDownList();
  };

  const handleEventClickWindow = (event: any) => {
    const classList = [...event.target.classList];

    if (!classList || classList.length == 0) {
      return setDropdownListActive(false);
    }

    if (!classList.includes(`${classAbortDDL}${identifiedAborDDL}`)) {
      setDropdownListActive(false);
    }
  };

  const handleClickVisibleList = (events: IHandleClickVisibleList) => {
    const { show } = events;

    if (!show) {
      handleEventDirectionDropDownList();
    }

    setInUseDropDownList(true);
    setDropdownListActive(show ? false : true);
  };

  const handleEventDirectionDropDownList = () => {
    const boxDropDownList = refDropDownListCustomized.current;
    const { height, top } = boxDropDownList.getBoundingClientRect();
    const { outerHeight } = window;

    setDirectionCollapse(
      top + 200 + height > outerHeight - height
        ? "apply-collapse-top"
        : undefined
    );
  };

  const handleChangeSearchList = (values: IHandleChangeSearchList) => {
    const { value } = values;
    setEnabledSearch(value !== "" ? true : false);

    const filters = list.filter(({ name }: any) =>
      name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setOptions(filters);
  };

  const handleClickSelectedOption = (values: IOptionDropDownList) => {
    const currentOptions = [...optionSelected];
    const existOptionSelected = currentOptions
      .map((option) => option.code)
      .includes(values.code);
    if (!existOptionSelected) {
      currentOptions.push(values);
    } else {
      const positionDeleted = currentOptions.findIndex(
        (option) => option.code === values.code
      );
      currentOptions.splice(positionDeleted, 1);
    }
    setOptionSelected(currentOptions);

    if (onPermaValues && onValueChange && onPropertyChange) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: currentOptions,
        valid: true,
      };
      onValueChange(permaValues);
    }
  };

  const handleClickDeletedOptionSelected = () => {
    setOptionSelected([]);
    if (onPermaValues && onValueChange && onPropertyChange) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: [],
        valid: true,
      };
      onValueChange(permaValues);
    }
  };

  const handleClickSelectedAllOption = () => {
    const currentOptions =
      optionSelected.length !== list.length ? [...list] : [];
    setOptionSelected(currentOptions);

    if (onPermaValues && onValueChange && onPropertyChange) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: currentOptions,
        valid: true,
      };
      onValueChange(permaValues);
    }
  };

  return (
    <div
      className="cols-not-required"
    >
      {layer && (
        <label className="layer">
          {layer}
        </label>
      )}
      <div
        className={`dropdownlist-customized-box ${
          dropDownListActive
            ? "ddl-customized-box-show"
            : "ddl-customized-box-hidden"
        }`}
      >
        <input
          ref={refDropDownListCustomized}
          placeholder="Buscar"
          className={`input-dropdownlist-customized ${
            categoriesValidation[eventValidation]
          } ${classAbortDDL}${identifiedAborDDL} ${
            dropDownListActive
              ? "input-ddl-customized-show"
              : "input-ddl-customized-hidden"
          }`}
          readOnly={true}
          onClick={() => handleClickVisibleList({ show: dropDownListActive })}
          value={optionValueSelected}
          disabled={disabled}
        />
        <div
          className={`ddl-icons-options-list ${classAbortDDL}${identifiedAborDDL}`}
        >
          {optionSelected.length > 0 && (
            <>
              <button
                onClick={handleClickDeletedOptionSelected}
                className={`button-delete-list-options ${classAbortDDL}${identifiedAborDDL}`}
              >
                <CloseIcon
                  className={`icon-delete-list-options ${classAbortDDL}${identifiedAborDDL}`}
                />
              </button>
              <span
                className={`clear-box ${classAbortDDL}${identifiedAborDDL}`}
              ></span>
            </>
          )}
          <button
            className={`button-showhidden-list-option ${classAbortDDL}${identifiedAborDDL}`}
            onClick={() => {
              refDropDownListCustomized.current.focus();
              handleClickVisibleList({ show: dropDownListActive });
            }}
          >
            <KeyboardArrowDownIcon
              className={`arrow-list ${classAbortDDL}${identifiedAborDDL}`}
            />
          </button>
        </div>
        {dropDownListActive && (
          <div
            className={`dropdownlist-customized-list ${directionCollapse} ${classAbortDDL}${identifiedAborDDL}`}
          >
            <div
              className={`ddl-customized-search ${classAbortDDL}${identifiedAborDDL}`}
            >
              <input
                placeholder="Buscar"
                className={`input-initial ${classAbortDDL}${identifiedAborDDL}`}
                onChange={({ target }) =>
                  handleChangeSearchList({ value: target.value })
                }
              />
              {enabledSearch && (
                <button
                  onClick={() => handleChangeSearchList({ value: "" })}
                  className={`button-delete-search ${classAbortDDL}${identifiedAborDDL}`}
                >
                  <CloseIcon
                    className={`icon-delete-search ${classAbortDDL}${identifiedAborDDL}`}
                  />
                </button>
              )}
            </div>
            <div
              className={`ddl-customized-overflow ${classAbortDDL}${identifiedAborDDL}`}
            >
              {options.length > 0 && !enabledSearch && (
                <div
                  onClick={() => handleClickSelectedAllOption()}
                  className={`ddl-customized-list-option ${classAbortDDL}${identifiedAborDDL} ${
                    list.length === optionSelected.length &&
                    "ddl-customized-list-option-selected"
                  }`}
                >
                  {list.length !== optionSelected.length ? (
                    <CheckBoxOutlineBlankIcon />
                  ) : (
                    <CheckBoxIcon />
                  )}
                  Todos
                </div>
              )}
              {options.map(({ name, code }, key) => (
                <div
                  key={key}
                  className={`ddl-customized-list-option ${classAbortDDL}${identifiedAborDDL} ${
                    optionSelected
                      .map((option) => option.code)
                      .includes(code) && "ddl-customized-list-option-selected"
                  }`}
                  onClick={() => handleClickSelectedOption({ name, code })}
                >
                  {!optionSelected
                    .map((option) => option.code)
                    .includes(code) ? (
                    <CheckBoxOutlineBlankIcon />
                  ) : (
                    <CheckBoxIcon />
                  )}{" "}
                  {name}
                </div>
              ))}
              {options.length == 0 && (
                <div
                  className={`ddl-customized-option-not-data ${classAbortDDL}${identifiedAborDDL}`}
                >
                  {enabledSearch ? (
                    <>No he encontrado resultados</>
                  ) : (
                    <>Sin resultados que mostrar</>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
