import "../../../assets/css/adminModal.min.css";
import { Button } from "primereact/button";
import CloseIcon from "@mui/icons-material/Close";
interface IRenewCode {
  title: string;
  subTitle?: string;
  list?: any[];
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
}

export default (props: IRenewCode) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, width, list } =
    props;
  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle" style={{ textAlign: "start" }}>
            <span>{subTitle}</span>
          </div>
          <div
            className="modal-message"
            style={{ textAlign: "start", marginBottom: "0px" }}
          >
            <span>{message}</span>
          </div>
          <div className="modal-section-renewcode">
            {list && (
              <>
                {list.map((code, key) => {
                  return <span key={key}>N°{code}</span>;
                })}
              </>
            )}
          </div>

          <div className="modal-message" style={{ textAlign: "start" }}>
            <span>
              Al aceptar, se reactivará por 8 Hrs a partir del próximo ingreso
              del invitado.{" "}
            </span>
          </div>
          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) onChangeBack();
              }}
            >
              Cancelar
            </Button>
            <Button
              className="button-go-confirm"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
