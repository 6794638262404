import { useContext } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../assets/css/OperacionContratacion.min.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import MediaQuery from "../../../ui-responsive/MediaQuery";
import {
  IListaCandidatos,
  IToken,
  Params,
} from "../../../../interfaces/IOperacionesContratacion";
import ButtonIcon from "../../../ui/adminAssist/ButtonIcon";
import IconSolicitudRechazada from "../../../ui/kit-svg/IconSolicitudRechazada";
import IconSolicitudObservacion from "../../../ui/kit-svg/IconSolicitudObservacion";
import IconSolicitudAcept from "../../../ui/kit-svg/IconSolicitudAcept";
import IconoRegistroI from "../../../ui/kit-svg/IconoRegistroI";
import IconStop from "../../../ui/kit-svg/IconStop";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import IconPageRenova from "../../../ui/icons-operaciones/IconPageRenova";
import IconPageBaja from "../../../ui/icons-operaciones/IconPageBaja";
import IconPageNewContrato from "../../../ui/icons-operaciones/IconPageNewContrato";
import IconPageUpdate from "../../../ui/icons-operaciones/IconPageUpdate";
import SeccionActionBlockDocSolicitados from "./SeccionActionBlockDocSolicitados";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import ModalFicha from "../../SistemaValidacionGeneral/AsistenciaValidacionKam/Modals/ModalFicha";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
import IconPendienteFirma from "../../../ui/icons-operaciones/IconPendienteFirma";
import IconNewFueraPlazo from "../../../ui/icons-operaciones/IconNewFueraPlazo";
import IconPendRevision from "../../../ui/icons-operaciones/IconPendRevision";
import ModalGenerico from "../../../ui/kit-modal/ModalGenerico";
import ModalNoProcesado from "../../../ui/kit-modal/noProcesado/ModalNoProcesado";
import FooterTable from "../../../ui/kit-mdtw/table/FooterTable";
import IconPendSoftlan from "../../../ui/icons-operaciones/IconPendSoftlan";

const TablaDotacionVigente = () => {
  const {
    listaDocumentosSolicitados,
    listDocumentosSolicitados,
    setListDocumentosSolicitados,
    getDocumentosSolicitados,
  } = useContext(OperacionContratacionContext);

  const {
    setVisibleActionBlocking,
    BootstrapTooltip,
    aBusPerId,
    setABusPerId,
  } = useContext(ValidationKamContext);

  const {
    token,
    getToken,
    getRegistroCandidato,
    listaRegistroCandidato,
    listNoProcess,
    openModalNoProcess,
    setOpenModalNoProcess,
  } = useContext(SeleccionContext);

  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [openModalDetener, setOpenModalDetener] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [isOpenModalIndividual, setIsOpenModalIndividual] =
    useState<boolean>(false);
  const [gridCandidatoConDocSolicitado, setGridCandidatoConDocSolicitado] =
    useState<IListaCandidatos[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [listDocSolicitadosFull, setListDocSolicitadosFull] = useState<any[]>(
    []
  );
  const [candidateDetener, setCandidateDetener] = useState<any[]>([]);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroProcesoActivo"
  );
  const [onlyRead, setOnlyRead] = useState<boolean>(false);

  useEffect(() => {
    listDocumentosSolicitados.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listDocumentosSolicitados]);
  const [estadoRegistro, setEstadoRegistro] = useState<string>("");
  const [fechaValidacion, setFechaValidacion] = useState<string>("");
  const [formFilter, setFormFilter] = useState<any>({
    division: [],
    nombre: [],
    ficha: [],
    codCargo: [],
    areaNegocio: [],
    fechaInicio: [],
    fechaTermino: [],
    topeLegal: [],
    estado: [],
    cliente:[idCliente]
  });

  useEffect(() => {
    if (token && rutCandidate) {
      getToken();
      getRegistroCandidato({
        personaRut: rutCandidate,
        token,
      });
    }
  }, [token, listDocSolicitadosFull, listDocumentosSolicitados]);

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getDocumentosSolicitados({
          token,
          objetoSendFiltro: JSON.parse(copyStorage),
        });
      } else {
        getDocumentosSolicitados({
          token,
          objetoSendFiltro: formFilter,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    setTimeout(() => {
      const tablaElement = document.getElementById("tablaDocSolicitados");

      //const filtroVigente: any = localStorage.getItem("filtroDotacionVigente");
      const filtroProcActivo: any = localStorage.getItem("filtroProcesoActivo");

      try {
        // const estadoEnFiltroVigente = JSON.parse(filtroVigente);
        const estadofiltroProcesoActivo = JSON.parse(filtroProcActivo);
        
        if (tablaElement && estadofiltroProcesoActivo.estado[0] !== "") {
          tablaElement.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.error("Error al analizar JSON:", error);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (listaDocumentosSolicitados) {
      let arrayGridFilter = [...listaDocumentosSolicitados];
      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoConDocSolicitado(arrayGridFilter);
      setListDocSolicitadosFull(
        arrayGridFilter.map((item: IListaCandidatos) => {
          return {
            personaDNI: item.personaDNI,
            busPerId: item.ficha,
            primerNombre: item.primerNombre,
            primerApellido: item.primerApellido,
            areaNegocioCod: item.areaNegocioCod,
            division: item.division,
            tipoSolicitud: item.tipoSolicitud,
            estadoRenovacion: item.estadoRenovacion,
            fechaCreacionRenovacion: item.fechaCreacionRenovacion,
            fechaRevisionRenovacion: item.fechaRevisionRenovacion,
            ficha: item.ficha,
            letra: item.letra,
          };
        })
      );
    }
  }, [listaDocumentosSolicitados, range]);

  const handleClickAddListMasive = (
    personaDNI: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    areaNegocioCod: string,
    division: String,
    tipoSolicitud: String,
    estadoRenovacion: String,
    fechaCreacionRenovacion: String,
    fechaRevisionRenovacion: String,
    ficha: String,
    letra: string
  ) => {
    const currentService = [...listDocumentosSolicitados];
    const dataObject = {
      personaDNI,
      busPerId: ficha,
      primerNombre,
      primerApellido,
      areaNegocioCod,
      division,
      tipoSolicitud,
      estadoRenovacion,
      fechaCreacionRenovacion,
      fechaRevisionRenovacion,
      ficha,
      letra,
    };

    if (!currentService.some((c: any) => c.personaDNI === personaDNI)) {
      currentService.push(dataObject);
    } else {
      const positionDeleted = currentService.findIndex(
        (data) => data.personaDNI === personaDNI
      );
      currentService.splice(positionDeleted, 1);
    }

    setListDocumentosSolicitados(currentService);
  };

  const handleClickPersonIdMasive = (tipoDocumentoValue: string) => {
    listDocumentosSolicitados.length == listDocSolicitadosFull.length
      ? setListDocumentosSolicitados([])
      : setListDocumentosSolicitados(
          listDocSolicitadosFull.map((item: IListaCandidatos) => {
            setRutCandidate(item.personaDNI);
            return {
              personaDNI: item.personaDNI,
              busPerId: item.ficha,
              primerNombre: item.primerNombre,
              primerApellido: item.primerApellido,
              areaNegocioCod: item.areaNegocioCod,
              division: item.division,
              tipoSolicitud: item.tipoSolicitud,
              estadoRenovacion: item.estadoRenovacion,
              fechaCreacionRenovacion: item.fechaCreacionRenovacion,
              fechaRevisionRenovacion: item.fechaRevisionRenovacion,
              ficha: item.ficha,
              letra: item.letra,
            };
          })
        );
  };

  const handleClickModalDatosIndividual = ({
    personaDNI,
    busPerId,
    ficha,
    estadoRenovacion,
    fechaValidacion,
  }: any) => {
    let arr = [];
    arr.push(ficha);
    setABusPerId(arr);
    setEstadoRegistro(estadoRenovacion);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDNI);
    setFechaValidacion(fechaValidacion);
    setOnlyRead(true);
  };

  const handleClickModalDetener = (
    personaDNI: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    ficha: string,
    areaNegocioCod: string,
    estadoRenovacion: string,
    division: string,
    letra: string
  ) => {
    const arrDet = [];
    const dataObject = {
      personaDNI,
      busPerId: ficha,
      primerNombre,
      primerApellido,
      ficha,
      areaNegocioCod,
      estadoRenovacion,
      division,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      letra,
    };
    arrDet.push(dataObject);
    setCandidateDetener(arrDet);
    setOpenModalDetener(true);
  };

  return (
    <>
      <section className="content-seleccion-multipleDota action-in-line">
        <div className="content-multiple">
          <button
            className="button-multiple"
            onClick={() => {
              handleClickPersonIdMasive(
                listaRegistroCandidato?.contratacion?.tipoDocumento
              );
            }}
          >
            {listDocumentosSolicitados.length ==
              listDocSolicitadosFull.length &&
            listDocumentosSolicitados.length > 0 ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </button>
          <label className="title-multiple">
            Seleccionar Todo{" "}
            <i className="selects">
              Ítems Seleccionados: {listDocumentosSolicitados.length}
            </i>
          </label>
        </div>
        <SeccionActionBlockDocSolicitados
          token={token}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
        />
      </section>
      <div className="content-header-table">
        <span>Seguimiento Documentos Solicitados</span>
      </div>
      <div id="tablaDocSolicitados" className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th></th>

              <th>Estado</th>
              <th>Nombre</th>
              <th>Ficha</th>
              {width <= sm ? (
                <>
                  <th>Área de Negocio</th>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>
                    División
                    <br /> TW
                  </th>
                  <th>
                    Área de <br /> Negocios
                  </th>
                  <th>
                    Tipo <br /> Solicitud
                  </th>
                  <th>
                    Fecha <br /> Solicitud
                  </th>
                  <th>
                    Fecha <br /> Revisión
                  </th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoConDocSolicitado &&
              gridCandidatoConDocSolicitado.map((item: IListaCandidatos) => {
                const {
                  busPerId,
                  busquedaId,
                  areaNegocioId,
                  areaNegocioCod,
                  ficha,
                  personaDNI,
                  primerNombre,
                  primerApellido,
                  comentario,
                  division,
                  estadoRenovacion,
                  fechaValidacion,
                  tipoSolicitud,
                  fechaCreacionRenovacion,
                  fechaRevisionRenovacion,
                  letra,
                } = item;
                return (
                  <>
                    <tr
                      key={personaDNI}
                      className={
                        listDocSolicitadosFull.length > 0 &&
                        listDocumentosSolicitados.some(
                          (l: any) => l.personaDNI === personaDNI
                        )
                          ? "selected borderdashed"
                          : "borderdashed"
                      }
                    >
                      <td>
                        <ButtonIcon
                          onClick={() => {
                            setRutCandidate(personaDNI);
                            handleClickAddListMasive(
                              personaDNI,
                              busPerId,
                              primerNombre,
                              primerApellido,
                              areaNegocioCod,
                              division,
                              tipoSolicitud,
                              estadoRenovacion,
                              fechaCreacionRenovacion,
                              fechaRevisionRenovacion,
                              ficha,
                              letra
                            );
                          }}
                        >
                          {listDocumentosSolicitados.some(
                            (l: any) => l.personaDNI === personaDNI
                          ) ? (
                            <>
                              <CheckBoxIcon />
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                            </>
                          )}
                        </ButtonIcon>
                      </td>
                      <td>
                        {" "}
                        <div className="content-icon-table">
                          {estadoRenovacion == "RECHAZADO" ? (
                            <>
                              <IconSolicitudRechazada className="icon-estado" />
                              <span className="textStado">
                                Solicitud <br />
                                Rechazada
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "PROCESANDO" ? (
                            <>
                              <IconSolicitudObservacion
                                fill={"#E3A000"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Procesando en <br />
                                Sistema
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "PEND_FIRMA_DIGITAL" ? (
                            <>
                              <IconSolicitudObservacion
                                fill={"#FF4900"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Pendiente Actualizar <br />
                                Certificado Digital
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "ACEPTADO_PEND_FIRMA" ? (
                            <>
                              <IconPendienteFirma
                                fill={"#FF4900"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                               Doc. Pendiente firma <br />
                                Digital
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "FUERA_PLAZO"  ? (
                            <>
                              <IconNewFueraPlazo className="icon-estado" />
                              <span className="textStado">
                                Solicitud fuera de <br />
                                plazo pendiente
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "ACEPTADA" ? (
                            <>
                              <IconSolicitudAcept className="icon-estado" />
                              <span className="textStado">
                                Solicitud aceptada,
                                <br />
                                doc. generado
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "PEND_REVISION" ? (
                            <>
                              <IconPendRevision className="icon-estado" />
                              <span className="textStado">
                                Solicitud pendiente
                                <br />
                                de revisión
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoRenovacion == "ACEPTADO_PEND_SOFTLAND" ? (
                            <>
                              <IconPendSoftlan className="icon-estado" />
                              <span className="textStado">
                                Doc Pendiente de
                                <br />
                                Ingreso en Softland
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        {primerNombre} {primerApellido}
                      </td>
                      <td>{ficha != null ? ficha : "-"}</td>
                      {width <= sm ? (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>
                            <div className="content-icon-table">
                              <BootstrapTooltip
                                title="Renovar Contrato"
                                placement="bottom-start"
                              >
                                <button>
                                  <IconPageRenova fill="#06A502" />
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Confirmar Baja"
                                placement="bottom-start"
                              >
                                <button
                                  disabled={estadoRenovacion == "BAJA_CONFIR"}
                                >
                                  <div>
                                    <IconPageBaja
                                      fill="#BE2A2A"
                                      className={`${
                                        estadoRenovacion !== "BAJA_CONFIR"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Nuevo Contrato"
                                placement="bottom-start"
                              >
                                <button
                                  disabled={estadoRenovacion == "BAJA_CONFIR"}
                                >
                                  <div>
                                    <IconPageNewContrato
                                      className={`${
                                        estadoRenovacion !== "BAJA_CONFIR"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                              <BootstrapTooltip
                                title="Actualizar datos de contrato"
                                placement="bottom-start"
                              >
                                <button
                                  disabled={estadoRenovacion == "BAJA_CONFIR"}
                                >
                                  <div>
                                    <IconPageUpdate
                                      fill="#0065DD"
                                      className={`${
                                        estadoRenovacion !== "BAJA_CONFIR"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{division}</td>

                          <td>{areaNegocioCod}</td>
                          <td>{tipoSolicitud ?? "-"}</td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaCreacionRenovacion,
                              format: "/",
                            })}
                          </td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaRevisionRenovacion,
                              format: "/",
                            }) ?? "-"}
                          </td>

                          <td>
                            <div className="content-icon-table">
                              <BootstrapTooltip
                                title="Registro contrato Individual"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDNI,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoRenovacion,
                                    })
                                  }
                                  //   disabled={estadoRenovacion == "PROCESANDO"}
                                >
                                  {}

                                  <IconoRegistroI
                                    className={`${"active-registro"}`}
                                  />
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Detener Solictud"
                                placement="bottom-start"
                              >
                                <button
                                  className={`${
                                    estadoRenovacion == "PROCESANDO"
                                      ? "disabled"
                                      : "button-actions block"
                                  }`}
                                  onClick={() =>
                                    handleClickModalDetener(
                                      personaDNI,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      ficha,
                                      areaNegocioCod,
                                      estadoRenovacion,
                                      division,
                                      letra
                                    )
                                  }
                                  disabled={
                                    estadoRenovacion === "PROCESANDO" ||
                                    estadoRenovacion === "ACEPTADA" ||
                                    estadoRenovacion === "ACEPTADO_PEND_FIRMA"
                                  }
                                >
                                  <div>
                                    <IconStop
                                      className={`${
                                        estadoRenovacion === "PROCESANDO" ||
                                        estadoRenovacion === "ACEPTADA" ||
                                        estadoRenovacion ===
                                          "ACEPTADO_PEND_FIRMA"
                                          ? "disabled"
                                          : "button-actions block"
                                      }`}
                                      disabled={
                                        estadoRenovacion == "PROCESANDO"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalDetener && (
        <ModalDetener
          modalType="DetenerRenov"
          title="Detener solicitud de Renovación"
          subTitle="Vas a cancelar la solicitud de renovación de contrato para la siguiente selección:"
          buttonBack="Cancelar"
          onChangeBack={() => {
            setCandidateDetener([]);
            setOpenModalDetener(false);
          }}
          modalStyle={""}
          rut={rutCandidate}
          busPerId={aBusPerId}
          idCliente={idCliente}
          list={candidateDetener}
        />
      )}

      {isOpenModalIndividual && (
        <ModalFicha
          areaContrato={true}
          rut={rutCandidate}
          busPerId={aBusPerId}
          idCliente={idCliente}
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setIsOpenModalIndividual(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          setOpenModalFicha={setOpenModalFicha}
          estado={estadoRegistro}
          fechaValidacion={fechaValidacion}
          onlyRead={onlyRead}
        />
      )}

      {openModalNoProcess && (
        <ModalGenerico
          title="Solicitud NO procesada"
          iconTitle="danger"
          subtitle="La solicitud para los siguientes trabajadores no pudo ser procesada:"
          width="w-70"
          buttonBackText="Cerrar"
          buttonGoStyle="delete"
          onChangeBack={() => {
            setOpenModalNoProcess(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalNoProcesado list={listNoProcess} />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaDotacionVigente;
