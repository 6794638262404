import { IIconTw } from "../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 15"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05156 0.587695C5.23086 0.225781 5.59941 0 6.00117 0H9.99883C10.4006 0 10.7691 0.225781 10.9484 0.587695L11.1875 1.0625H14.375C14.9627 1.0625 15.4375 1.5373 15.4375 2.125C15.4375 2.7127 14.9627 3.1875 14.375 3.1875H1.625C1.0373 3.1875 0.5625 2.7127 0.5625 2.125C0.5625 1.5373 1.0373 1.0625 1.625 1.0625H4.8125L5.05156 0.587695ZM1.625 4.25H14.375V14.875C14.375 16.0471 13.4221 17 12.25 17H3.75C2.57793 17 1.625 16.0471 1.625 14.875V4.25ZM4.8125 6.375C4.52031 6.375 4.28125 6.61406 4.28125 6.90625V14.3438C4.28125 14.6359 4.52031 14.875 4.8125 14.875C5.10469 14.875 5.34375 14.6359 5.34375 14.3438V6.90625C5.34375 6.61406 5.10469 6.375 4.8125 6.375ZM8 6.375C7.70781 6.375 7.46875 6.61406 7.46875 6.90625V14.3438C7.46875 14.6359 7.70781 14.875 8 14.875C8.29219 14.875 8.53125 14.6359 8.53125 14.3438V6.90625C8.53125 6.61406 8.29219 6.375 8 6.375ZM11.1875 6.375C10.8953 6.375 10.6562 6.61406 10.6562 6.90625V14.3438C10.6562 14.6359 10.8953 14.875 11.1875 14.875C11.4797 14.875 11.7188 14.6359 11.7188 14.3438V6.90625C11.7188 6.61406 11.4797 6.375 11.1875 6.375Z"
      />
    </svg>
  );
};
