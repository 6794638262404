import DropDownListCustomized from "../../kit-mdtw/DropDownListCustomized";

interface ITablaDownloadPsicolaboral {
  busPerId: string;
  personaId: string;
  busquedaId: string;
  division: string;
  primerNombre: string;
  primerApellido: string;
  personaDNI: string;
  clienteNombre: string;
  selectCurrent: any;
  setSelectCurrent: Function;
  buttonAssignAll: boolean;
  tipoFormato: any; 
}

export default (props: ITablaDownloadPsicolaboral) => {
  const {
    busPerId, 
    personaId,
    division,
    busquedaId,
    primerNombre,
    primerApellido,
    personaDNI,
    clienteNombre,
    selectCurrent,
    buttonAssignAll,
    tipoFormato,
    setSelectCurrent,
  } = props;

  return (
    <section className="content-table-restaurar anular-documento" key={busPerId}>
      <table className="table-restaurar w-100">
        <thead className="thead-table-restaurar">
          <tr className="tr-table-restaurar">
            <th className="td-table-restaurar">Nombre</th>
            <th className="td-table-restaurar">Rut</th>
            <th className="td-table-restaurar">Proceso</th>
            <th className="td-table-restaurar">Cliente</th>
          </tr>
        </thead>
        <tbody className="tbody-table-restaurar">
          <tr className="tr-table-restaurar" key={personaDNI}>
            <td className="td-table-restaurar anular-documento">
              {primerNombre} {primerApellido}
            </td>
            <td className="td-table-restaurar anular-documento">
              {personaDNI}
            </td>
            <td className="td-table-restaurar anular-documento">
              {division}-{busquedaId}
            </td>
            <td className="td-table-restaurar anular-documento">
              {clienteNombre}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-50">
        <DropDownListCustomized
          list={tipoFormato}
          layer="Tipo de Documento"
          placeholder="Seleccione"
          required={false}
          onValueChange={setSelectCurrent}
          onPermaValues={selectCurrent}
          onPropertyChange={busPerId}
          defaultValue={
            buttonAssignAll ? selectCurrent[busPerId].value : undefined
          }
          disabled={buttonAssignAll}
        />
      </div>
    </section>
  );
};
