import { IIconTw } from "../../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 22 20"
      fill={disabled ? "#aeaeae" : fill}
      stroke={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M15.7414 4.68143C15.7625 4.70126 15.7818 4.72437 15.7988 4.75H11.625V0.572072C11.6574 0.592322 11.688 0.616744 11.7164 0.64522L15.5833 4.51211V4.53328L15.7414 4.68143ZM9.31833 0.5V5.57667C9.31833 6.38948 9.98552 7.05667 10.7983 7.05667H15.875V20.02C15.875 20.2844 15.6633 20.5 15.395 20.5H1.605C1.34059 20.5 1.125 20.2883 1.125 20.02V0.98C1.125 0.719476 1.34448 0.5 1.605 0.5H9.31833ZM14.9319 10.0531L14.0452 9.16645C13.605 8.72618 12.8917 8.72618 12.4514 9.16645L6.93667 14.6812L4.54855 12.2931C4.10829 11.8529 3.39504 11.8529 2.95478 12.2931L2.06811 13.1798C1.62785 13.62 1.62785 14.3333 2.06811 14.7736L6.13978 18.8452C6.58004 19.2855 7.29329 19.2855 7.73355 18.8452L14.9319 11.6469C15.3721 11.2066 15.3721 10.4934 14.9319 10.0531Z"
      />
    </svg>
  );
};
