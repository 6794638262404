import ItemGrupo from "./ItemGrupo";
interface ITablaGrupo {
  token: string | undefined;
  getToken: Function;
  list: any;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string;
  currentListProcess: string[]; 
  setFormData: Function; 
  formData: any
}
interface IItemGroup {
  cargoGroupId: string;
  cargoGroupName: string;
  procesos: any;
}

const TablaGrupo = (props: ITablaGrupo) => {
  const {
    token,
    getToken,
    list,
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    currentListProcess,
    setFormData, 
    formData,
  } = props;

  return (
    <>
      {list &&
        list.map(({ cargoGroupId, cargoGroupName, procesos }: IItemGroup) => (
          <ItemGrupo
            key={cargoGroupId}
            token={token}
            getToken={getToken}
            procesos={procesos}
            localStorageSelect={localStorageSelect}
            cargoGroupName={cargoGroupName}
            listadoSeleccionado={listadoSeleccionado}
            setListadoSeleccionado={setListadoSeleccionado}
            currentListProcess={currentListProcess}
            setFormData={setFormData}
            formData={formData}
          />
        ))}
    </>
  );
};

export default TablaGrupo;
