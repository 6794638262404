import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InputDateCustomized from "../../../ui/kit-mdtw/InputDateCustomized";
import IconCheckfila from "../../../ui/kit-svg/IconCheckfila";
import {
  IListaCandidatos,
  Params,
} from "../../../../interfaces/IValidationKam";
import {
  formatDateToDDMMAAAA,
  formatDateToYYYYMMDD,
} from "../../../../functions/kit-mdtw/dates";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";

interface IDetener {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  onChange?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
  rut?: string;
  busPerId?: string;
  idCliente?: string;
  onCloseModalFicha?: Function;
}

export default (props: IDetener) => {
  const {
    title,
    subTitle,
    onChangeBack,
    onChange,
    buttonBack,
    buttonGo,
    list,
    rut,
  } = props;
  const {
    token,
    getListCandidateEnviadoContrato,
    listCandidatoEnviadoContrato,
  } = useContext(ValidationKamContext);

  const { solicitudRenovacion } = useContext(OperacionContratacionContext);

  const { idCliente } = useParams<Params>();
  const [checkAplyAll, setCheckAplyAll] = useState<boolean>(false);
  const [inputForAll, setInputForAll] = useState<any>({
    date: { value: undefined },
  });
  const [inputForInd, setInputForInd] = useState<any>([
    {
      date: { value: undefined },
    },
  ]);

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [objetoData, setObjtoData] = useState<any>("");
  const [openModalFueraPlazo, setOpenModalFueraPlazo] =
    useState<boolean>(false);
  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);
  const objetoSendFiltro = {};

  useEffect(() => {
    if (token) {
      getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
    }
  }, [token]);

  useEffect(() => {
    if (token && rut) {
      listCandidatoEnviadoContrato;
    }
  }, [token, rut]);

  useEffect(() => {
    // Mapear la lista y generar los objetos
    if (list) {
      list.map((item: IListaCandidatos, index: number) => {
        let fechaRenovValue = inputForInd[index]?.date?.value ?? "";
        handleInputChange(index, "date", { date: { value: fechaRenovValue } });
      });
    }
  }, [list]);

  useEffect(() => {
    // Mapear la lista y generar los objetos
    if (list) {
      const mappedDataObjects = list.map(
        (item: IListaCandidatos, index: number) => {
          const { ficha, division } = item;

          if (inputForAll.date.value == undefined) {
            setCheckAplyAll(false);
          }
          let fechaRenovValue = inputForInd[index]?.date?.value ?? "";

          return {
            ficha: ficha + "-" + division,
            fechaTermino: formatDateToYYYYMMDD({
              date: fechaRenovValue,
              format: "-",
            }),
          };
        }
      );
      setObjtoData(mappedDataObjects);
    }
  }, [list, inputForAll.date.value, checkAplyAll, onChange, inputForInd]);

  useEffect(() => {
    if (list) {
      const listaNueva = list.map((item: any) => {
        const {
          busPerId,
          areaNegocioCod,
          ficha,
          personaDNI,
          primerNombre,
          primerApellido,
          division,
          fechaInicioContrato,
          tipoDocumentoValue,
          letra,
          fueraPlazo,
        } = item;
        return {
          busPerId: ficha, // Establece 'busPerId' en el valor de 'ficha'
          areaNegocioCod,
          ficha,
          personaDni: personaDNI,
          primerNombre,
          primerApellido,
          division,
          fechaInicioContrato,
          tipoDocumentoValue,
          letra,
          fueraPlazo,
        };
      });

      setListFueraPlazoModal([]);
      listFueraPlazo(listaNueva);
    }
  }, [list, hayFueraPlazo]);

  const listFueraPlazo = (listaNueva: any) => {
    if (list && listaNueva) {
      let nuevaListaFueraPlazo: any[] = [];
      listaNueva.map((item: any) => {
        if (item.fueraPlazo) {
          nuevaListaFueraPlazo.push({
            areaNegocioCod: item.areaNegocioCod,
            busPerId: item.busPerId,
            fechaTermino: item.fechaTerminoContrato,
            personaDni: item.personaDni,
            primerApellido: item.primerApellido,
            primerNombre: item.primerNombre,
            tipoDocumentoValue: "Renovación",
            letra: item.letra,
            ficha: item.ficha,
            division: item.division,
          });
        }
      });

      setListFueraPlazoModal(nuevaListaFueraPlazo);
      setHayFueraplazo(nuevaListaFueraPlazo.length > 0 ? true : false);
    } else {
      setHayFueraplazo(false);
    }
  };

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCheck = () => {
    setCheckAplyAll(checkAplyAll ? false : true);

    const newSelectDataFormacion: any[] = [...inputForInd];

    newSelectDataFormacion.forEach((t, sectionIndex) => {
      const value =
        !checkAplyAll && inputForAll?.date?.value !== undefined
          ? inputForAll?.date?.value
          : inputForInd[sectionIndex]?.date?.value || " ";

      newSelectDataFormacion[sectionIndex] = { date: { value: value } };
    });

    setInputForInd(newSelectDataFormacion);
  };

  const handleInputChange = (
    sectionIndex: any,
    property: string,
    value: any
  ) => {
    const newSelectDataFormacion: any[] = [...inputForInd];
    newSelectDataFormacion[sectionIndex] = {
      ...inputForInd[sectionIndex],
      [property]: value[property],
    };
    setInputForInd(newSelectDataFormacion);
  };

  const handleClickRenovar = (objetoData: any) => {
    if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
      setOpenModalFueraPlazo(true);
    } else {
      solicitudRenovacion({
        token,
        objetoForRenovacion: objetoData,
        objetoSendFiltro,
      });

      handleClickCloseModal();
      setOpenModalFueraPlazo(false);
    }
  };

  const busqValidInputDet = () => {
    if (inputForInd && inputForInd.length > 0) {
      const allInputsDefined = inputForInd.every(
        (value: any) =>
          value.date.value !== undefined && value.date.value !== ""
      );
      return allInputsDefined;
    }
    return false;
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo ">
        <div className="modal-content-validatekam-Mo w-80">
          <div className="modal-title-Mo">
            <span
              className="modal-close"
              onClick={() =>
                typeof onChangeBack == "function" && onChangeBack()
              }
            >
              <CloseIcon />
            </span>
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo px-5 md:text-center">
            <span>{subTitle}</span>
          </div>

          <div className="col-12 md:flex" style={{ alignItems: "center" }}>
            <div
              className="col-12 md:col-3 md:mx-3 md:pl-1"
              style={{ float: "left" }}
            >
              <InputDateCustomized
                required={undefined}
                layer={"Fecha Término"}
                placeholder={"DD/MM/AAAA"}
                onValueChange={setInputForAll}
                onPermaValues={inputForAll}
                onPropertyChange="date"
                maxLength={12}
                errorPosition="down"
              />
            </div>

            <div
              className="col-12 md:col-4 align-items-center flex"
              style={{ float: "left", marginTop: "1.1rem" }}
            >
              {checkAplyAll ? (
                <span
                  onClick={() => {
                    if (inputForAll.date.value !== undefined) {
                      handleClickCheck();
                    }
                  }}
                >
                  <IconCheckfila className="md:ml-0 md:mt-5 pl-1 mr-3 classSvg" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    if (inputForAll.date.value !== undefined) {
                      handleClickCheck();
                    }
                  }}
                >
                  <CheckBoxOutlineBlankIcon className="md:ml-0 md:mt-5 classSvg" />
                </span>
              )}

              <span className="md:mt-5">Asignar a todos</span>
            </div>
          </div>

          {list &&
            list.map((item: IListaCandidatos, index: any) => {
              const {
                busPerId,
                areaNegocioCod,
                ficha,
                primerNombre,
                primerApellido,
                division,
                fechaInicioContrato,
                letra,
              } = item;

              return (
                <div className="row flex flex-column md:flex-row mx-3 md:mx-1 md:pl-4 md:mr-5" key={busPerId}>
                  <div className="col-12 md:col-10 m-0 p-0 border-bottom-2 border-Seg1 borderColorGrey">
                    <div className="row flex flex-column md:flex-row">
                      <div className="col-12 md:col-3 p-0 mx-0">
                        <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                          Nombre
                        </div>
                        <div className="col-6 md:col-12 text-center responsiveDivDetener borderColorGrey textNormal">
                          {primerNombre + "  " + primerApellido}
                        </div>
                      </div>
                      <div className="col-12 md:col-2 p-0 mx-0">
                        <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                          Ficha
                        </div>
                        <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal">
                          {ficha}
                        </div>
                      </div>
                      <div className="col-12 md:col-2 p-0 mx-0">
                        <div className="col-6 md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                          División TW
                        </div>
                        <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal">
                          {division}
                        </div>
                      </div>
                      <div className="col-12 md:col-2 p-0 mx-0">
                        <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                          Área de Negocios
                        </div>
                        <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal">
                          {areaNegocioCod}
                        </div>
                      </div>
                      <div className="col-12 md:col-1 p-0 mx-0">
                        <div className="col-6  md:col-12 bg-headerGrey text-center borderColorGrey responsiveDivDetener">
                          Causal
                        </div>
                        <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal">
                          {letra}
                        </div>
                      </div>
                      <div className="col-12 md:col-2 p-0 mx-0">
                        <div className="col-6 md:col-12 bg-headerGrey text-center  borderColorGrey responsiveDivDetener">
                          Fecha de Inicio
                        </div>
                        <div className="col-6 md:col-12 text-center borderColorGrey responsiveDivDetener textNormal">
                          {formatDateToDDMMAAAA({
                            date: fechaInicioContrato,
                            format: "-",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-2 m-0 md:pl-4 pr-2 p-0">
                    <div className="row">
                      <div className="col-12 mx-0 p-0">
                        <div className="col-6 md:col-12   responsiveDivDetener text-headerWhite">
                          Fecha Término(*):
                        </div>
                        <div className="col-6 md:col-12 p-0 text-center  responsiveDivDetener ">
                          <InputDateCustomized
                            id={`arrMotivo-${index}`}
                            placeholder={"DD/MM/AAAA"}
                            onPropertyChange="date"
                            required={undefined}
                            maxLength={12}
                            onValueChange={(value: any) =>
                              handleInputChange(index, "date", value)
                            }
                            onPermaValues={inputForInd}
                            defaultValue={
                              checkAplyAll &&
                              inputForAll?.date?.value !== undefined
                                ? inputForAll?.date?.value
                                : inputForInd[index]?.date?.value || " "
                            }
                            requerido={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          <>
            <div className="modal-message-Mo mt-1">
              <p style={{ float: "none" }}>
                Al confirmar, se le enviará una notificación con la nomina al
                área correspondiente.
              </p>
              <p style={{ float: "none" }}>
                Para continuar presione Solicitar Renovación
              </p>
            </div>
          </>

          <div className="modal-button-content modal-button-content-in-modal center">
            <>
              <button
                className={`modal-button cancel`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={handleClickCloseModal}
              >
                {buttonBack ? buttonBack : "Cancelar"}
              </button>
              <button
                className={`modal-button ${
                  busqValidInputDet() ||
                  (inputForAll.date.value !== undefined && checkAplyAll)
                    ? "confirm"
                    : "disabled"
                }`}
                style={{
                  width: "256px",
                  justifyContent: "center",
                  gap: "32px",
                }}
                onClick={() => handleClickRenovar(objetoData)}
                disabled={
                  busqValidInputDet() ||
                  (inputForAll.date.value !== undefined && checkAplyAll)
                    ? false
                    : true
                }
              >
                {" "}
                {buttonGo ? buttonGo : "Solicitar Renovación"}
              </button>
            </>
          </div>
        </div>
      </div>

      {openModalFueraPlazo && (
        <ModalDetener
          modalType="FueraPlazoIndividualRenov"
          title="SOLICITUD FUERA DE PLAZO"
          subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
          buttonBack="Volver"
          onChangeBack={() => {
            setOpenModalFueraPlazo(false);
          }}
          modalStyle={""}
          list={listFueraPlazoModal}
          onCloseModalFicha={handleClickCloseModal}
          masiveRenovObjeto={objetoData}
          renovar={true}
        />
      )}
    </>
  );
};
