import CloseIcon from "@mui/icons-material/Close";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IDatum,
  IExclusion,
  IListaCandidatos,
  IListaExclusion,
  IToken,
  Params,
} from "../../../../../interfaces/IValidationKam";
import { IFormFastIncomeNoRequired } from "../../../../../interfaces/IFastIncome";
import { useParams } from "react-router-dom";
import { TYPE_LIST } from "../../../../../diccionary/Diccionary";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";

interface IModalDescartar {
  typeExclusion: "descarte" | "bloqueo";
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
  exclusiones?: any[];
  clearList?: Function;
  action?: "discart" | "block";
  listRefresh: Function;
  listadoSeleccionado: any;
  listSelect?: any;
}

export default (props: IModalDescartar) => {
  const {
    modalStyle,
    typeExclusion,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    exclusiones,
    list,
    clearList,
    action,
    listRefresh,
    listadoSeleccionado,
    listSelect
  } = props;
  const {
    listaCandidatoConfirmado,
    listaCandidatoPendiente,
    createDiscartBlockCandidate,
    token,
    getToken,
    userId,
  } = useContext(ValidationKamContext);
  const [formFastIncomeNoRequired, setFormFastIncomeNoRequired] =
    useState<any>({
      id: { value: undefined, valid: undefined },
    });
  const { idCliente } = useParams<Params>();
  const [messageE, setMessageE] = useState<boolean>(false);

  useEffect(() => {
    if (formFastIncomeNoRequired.id.value) {
      setMessageE(formFastIncomeNoRequired.id.value?.code == "17")
    }
  }, [formFastIncomeNoRequired.id.value])

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickGoDescarteBloqueo = () => {
    getToken();
    const { value, valid } = formFastIncomeNoRequired.id;
    if (!valid) {
      return;
    }
    const { code }: any = value;
    const dataExclusion = exclusiones
      ?.filter((item: IDatum) => item.excTipoId == code)
      .map((item: IDatum) => item.exclusionLevel);
    const { 0: exclusionLevel }: any = dataExclusion;
    let dataCandidatePendiente,
      dataCandidateConfirmacion: any = [];
    if (list) {
      dataCandidatePendiente = listaCandidatoPendiente
        .filter((item: IListaCandidatos) => list?.includes(item.personaDni))
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });

      dataCandidateConfirmacion = listaCandidatoConfirmado
        .filter((item: IListaCandidatos) => list?.includes(item.personaDni))
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });
    } else {
      dataCandidatePendiente = listaCandidatoPendiente
        .filter((item: IListaCandidatos) =>
          listadoSeleccionado.includes(item.personaDni)
        )
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });

      dataCandidateConfirmacion = listaCandidatoConfirmado
        .filter((item: IListaCandidatos) =>
          listadoSeleccionado.includes(item.personaDni)
        )
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });
    }

    if (modalType === TYPE_LIST.candidato_asistencia_confirmada) {
      createDiscartBlockCandidate({
        idCliente,
        dataCandidate: dataCandidateConfirmacion,
        action,
        token,
      }).then(typeof clearList == "function" && clearList);
    }
    if (modalType === TYPE_LIST.candidato_pendiente_validacion) {
      createDiscartBlockCandidate({
        idCliente,
        dataCandidate: dataCandidatePendiente,
        action,
        token,
      }).then(typeof clearList == "function" && clearList);
    }
    typeof onChangeGo === "function" && onChangeGo();
  };
  
  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-60">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title ">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle start">
            <span>{subTitle}</span>
          </div>

          <div className="project-table-Mo">
            <div className="container-table-Mo">
              <table className="content-table-Mo">
                <thead>
                  <tr>
               
                      <th>Nombre</th>
                 
                    <th>Rut</th>
                    <th>Fecha Ingreso</th>
                    <th>Ultimo Proceso</th>
                    
                  </tr>
                </thead>
                <tbody>
                {listSelect
          .filter((item: any) => list && list.includes(item.personaDni))
          .map((item: any) => (
            <tr
              key={item.personaDNI}
              className={list && list.includes(item.personaDNI) ? "" : ""}
            >
              <td>{item.primerNombre + " " + item.primerApellido}</td>
              <td>{item.personaDni}</td>
              <td>
                { formatDateToDDMMAAAA({
                      date: item.ingresadoFecha,
                      format: "/",
                    }) }
              </td>
              <td>{(item.busquedaId !== undefined && item.cargoNombre !== undefined) ? `${item.division} -${item.busquedaId} - 
              ${item.areaNegocioCod} ${item.cargoNombre}` 
              : 'sin Datos'}</td>

            </tr>
          ))}
                </tbody>
              </table>
            </div>
          </div>

 
          <div className="modal-message start">
            <span>{message}</span>
          </div>

          <div className="modal-message">
            <DropDownListCustomized
              list={
                exclusiones
                  ? exclusiones?.map((values: any) => {
                      const { excTipoId, excTipoNombre } = values;
                      return { code: excTipoId, name: excTipoNombre };
                    })
                  : []
              }
              onValueChange={setFormFastIncomeNoRequired}
              onPropertyChange="id"
              onPermaValues={formFastIncomeNoRequired}
              placeholder="Seleccionar"
              required={false}
            />
          </div>

          {typeExclusion == "bloqueo" && messageE &&(
        <div className="modal-subtitle start">
          <label className="block-message">
            Con esta opción, los candidatos además quedarán bloqueados de
            Teamwork y todos sus clientes.
          </label>
        </div>
      )}

      <div className="modal-subtitle start">
        {typeExclusion === "bloqueo" && (
          <span>
            Al bloquear los candidatos, éstos no podrán participar en procesos
            de selección para este cliente.
          </span>
        )}
        {typeExclusion === "descarte" && (
          <span>
            Al descartar candidatos, éstos no podrán continuar en el proceso de
            selección para este cargo.
          </span>
        )}
      </div>

          <div className="modal-button-content">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "confirm"
                  }`}
                  onClick={handleClickGoDescarteBloqueo}
                  disabled={!formFastIncomeNoRequired.id?.value}
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
