import React, { useContext } from "react";
import "../../../assets/css/assists.min.css";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Footer from "./Footer";
import ControlAsistencia from "../assists/ControlAsistencia";
import Target from "../assists/Target";
import AlertAssists from "../assists/alerts/AlertAssists";
import Asistencia from "../assists/Asistencia";
import Comentario from "../assists/Comentario";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";
import { IListaTarget } from "../../../interfaces/IAsistencias";
import { IToken } from "../../../interfaces/IAdminAssist";

const ListaAsistencia = (props: IToken) => {
  const { token, setToken, getToken } = props;
  const {
    asistenciaInterna,
    getListaAsistencia,
    getListaTarget,
    getListaTargetInterna,
    listaTarget,
    listaAsistencias,
    setListaAsistencia,
  } = useContext(AsistenciaContext);
  const [message, setMessage] = useState<string | undefined>();
  const [succesMessage, setSuccessMessage] = useState<boolean | undefined>();
  const [transition, setTransition] = useState<number>(1);
  let history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const servicioId = searchParams.get("serviceId");
  const invitationId = searchParams.get("invitationId");
  const userId = searchParams.get("userId");

  useEffect(() => {
    if (token) {
      if (asistenciaInterna) {
        getListaTargetInterna({ token });
        getListaAsistencia({ servicioId, invitationId, token });
      } else {
        getListaTarget({ servicioId, token });
        getListaAsistencia({ servicioId, invitationId, token });
      }
    }
  }, [token]);

  return (
    <>
      <div className="container-assist">
        <div className="content">
          {asistenciaInterna ? (
            <ControlAsistencia history={history} token={token} />
          ) : (
            <>
              <div className="content-body">
                {message && asistenciaInterna ? (
                  <AlertAssists
                    message={message ? message : ""}
                    success={succesMessage ? succesMessage : false}
                  />
                ) : ("")}
                <span
                  className="subtitle-assists"
                  style={{ textAlign: "start", marginTop: "20px" }}
                >
                  {transition === 1
                    ? "Bienvenido al Proceso de Asistencia del día:"
                    : "¿Desea agregar un Comentario?"}
                </span>
                {listaTarget.map((list: IListaTarget) => {
                  return (
                    <Target
                      servicioId={list.servicioId}
                      tipoServicio={list.tipoServicio}
                      estadoServicio={list.estadoServicio}
                      tipoTransporte={list.tipoTransporte}
                      fechaCrecion={list.fechaUso}
                      rutas={list.rutas}
                      procesos={list.procesos}
                      route="/asistencia/lista"
                      history={history}
                    />
                  );
                })}

                {transition === 1 ? (
                  <>
                    <Asistencia
                      labelButtonGo={"Confirmar"}
                      listado={listaAsistencias}
                      history={history}
                      route="/asistencia"
                      transition={transition}
                      setTransition={setTransition}
                      token={token}
                      getToken={getToken}
                    />
                  </>
                ) : (
                  <>
                    <Comentario
                      labelButtonBack={"Volver"}
                      labelButtonGo={"Enviar"}
                      history={history}
                      route="/asistencia/lista"
                      transition={transition}
                      setTransition={setTransition}
                      token={token}
                      getToken={getToken}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ListaAsistencia;
