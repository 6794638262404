import { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import AlertAssists from "./alerts/AlertAssists";
import Filter from "./Filter";
import ModalMessage from "./modals/ModalMessage";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { IListaAsistencia } from "../../../interfaces/IAsistencias";
import ButtonIcon from "../adminAssist/ButtonIcon";

interface IAsistencia {
  navText?: string;
  titleText?: string;
  labelButtonBack?: string;
  labelButtonGo?: string;
  listado: IListaAsistencia[];
  history?: any;
  route?: string;
  transition?: number;
  setTransition?: Function;
  token: string | undefined; 
  getToken: Function; 
}

export default (props: IAsistencia) => {
  const {
    navText,
    titleText,
    labelButtonBack,
    labelButtonGo,
    listado,
    history,
    route,
    transition,
    setTransition,
    token, 
    getToken, 
  } = props;
  const { handleClickAsistencia, updatePresentes, asistenciaInterna } = useContext(AsistenciaContext);
  const [message, setMessage] = useState<string | undefined>();
  const [succesMessage, setSuccessMessage] = useState<boolean | undefined>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const servicioId = searchParams.get('serviceId');
  const invitationId = searchParams.get('invitationId');
  
  const goBackToAsistencia = () => {
    if (history && route) {
      history.push(route);
    }
  };

  const handleClickTransitionGo = () => {
    updatePresentes({ 
      servicioId, 
      invitationId, 
      success: setSuccessMessage, 
      message: setMessage, 
      transition: setTransition,
      token, 
    })
  };

  const handleClickViewModal = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      {navText && <span className="nav-text">{navText}</span>}
      {titleText && (
        <span className=" title-assists" style={{ marginBottom: "50px" }}>
          {titleText}
        </span>
      )}
      {message ? (
        <AlertAssists
          message={message}
          success={succesMessage ? succesMessage : false}
        />
      ) : ("")}

      <Filter listado={listado} asistenciaInterna={asistenciaInterna}/>
      <div className="content-list">
        <div className="title-count">
          Presentes: {listado.filter((arr) => arr.presente).length}
        </div>
        <div className="sub-title">Marque solo las personas presentes</div>
      </div>
      <div className="container-table" style={{ marginTop: "0px" }}>
        <table className="content-table">
          <tr className="tr-list-title">
            <td className="td-list-title">Nombre pasajero</td>
            <td className="td-list-title">Rut</td>
            <td className="td-list-title">Asistió</td>
          </tr>
          {listado &&
            listado.map((item: any, index) => {
              const nombreCompleto = `${item.personaPrimerNombre} ${item.personaSegundoNombre} ${item.personaPrimerApe} ${item.personaSegundoApe}`
              return (
                <tr
                  className="tr-list"
                  style={{
                    backgroundColor: item.presente ? "#E2EBF9" : "#FAFAFA",
                  }}
                >
                  <td
                    className={!item.presente ? "td-list" : "td-list active"}
                  >
                    {nombreCompleto}
                  </td>
                  <td
                    className={!item.presente ? "td-list" : "td-list active"}
                  >
                    {item.personaDni}
                  </td>
                  <td
                    className={!item.presente ? "td-list" : "td-list active"}
                  >
                    <ButtonIcon>
                      {item.presente ? 
                        <CheckBoxOutlinedIcon 
                          className={`${item.presente ? 'list-presente': 'list-ausente'}`}
                          onClick={({}) => {
                            handleClickAsistencia({presente: item.presente ? false : true, index, busPerId: item.busPerId});
                          }
                        }/>  : 
                        <CheckBoxOutlineBlankOutlinedIcon 
                          className={`${item.presente ? 'list-presente': 'list-ausente'}`}
                          onClick={({}) => {
                            handleClickAsistencia({presente: item.presente ? false : true, index, busPerId: item.busPerId});
                          }
                        }/>}
                    </ButtonIcon>
                  </td>
                </tr>
              );
            })}
        </table>
      </div>

      {labelButtonBack ? (
        <div className="container-button">
          <Button
            className="button-left"
            label={labelButtonBack}
            autoFocus
            onClick={() => goBackToAsistencia()}
          />
          <Button
            className="button-right"
            label={labelButtonGo}
            autoFocus
            onClick={() => handleClickViewModal()}
          />
        </div>
      ) : (
        <div className="container-button" style={{ justifyContent: "center" }}>
          <Button
            className="button-right"
            label={labelButtonGo}
            autoFocus
            onClick={() => handleClickViewModal()}
          />
        </div>
      )}

      {isOpenModal === true && (
        <ModalMessage
          title={"CONFIRMACIÓN DE ASISTENCIA"}
          subTitle={`Vas a enviar la lista con ${
            listado.filter((arr) => arr.presente).length
          } de pasajeros, ¿está correcto?`}
          message="Para confirmar presiona continuar"
          onChangeBack={() => setIsOpenModal(false)}
          onChangeGo={() => handleClickTransitionGo()}
        />
      )}
    </>
  );
};
