import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import InputFileCustomized from "../kit-mdtw/InputFileCustomized";
import { Button } from "primereact/button";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import IconDescargaNomina from "../icons-seleccion/IconDescargaNomina";
import IconLoading from "../kit-svg/IconLoading";
import axios, { CancelToken } from "axios";

interface ICargaNomina {
  token: string;
  getToken: Function;
  uploadModalVisible: boolean;
  setUploadModalVisible: Function;
  emptyMessage?: string;
}

const CargaNomina = (props: ICargaNomina) => {
  const { token, getToken, setUploadModalVisible, uploadModalVisible, emptyMessage } = props;
  const [modalResumenProceso, setModalResumenProceso] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const { uploadNominaAzure, listResumenProcesadosNomina, toasts } =
    useContext(SeleccionContext);
  const [nominaDocumento, setNominaDocumento] = useState<any>({
    documento: {
      value: undefined,
      valid: false,
      fechaExpiracion: "",
    },
  });
  const [list, setList] = useState<any[]>([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    axios.CancelToken.source()
  );

  useEffect(() => {
    // Cuando listResumenProcesadosNomina cambia, actualiza el estado list
    if (listResumenProcesadosNomina && listResumenProcesadosNomina.summary) {
      const updatedList = [...listResumenProcesadosNomina.summary];
      setList(updatedList);
    }
  }, [listResumenProcesadosNomina, isLoadingExcel]);

  const uploadCargaNominaToAzure = () => {
    setList([]);
    setModalResumenProceso(true);
    setIsLoadingExcel(true);

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    uploadNominaAzure({
      token,
      file: nominaDocumento.documento.value,
      isLoadingExcel: setIsLoadingExcel,
      cancelToken: newCancelTokenSource.token,
    });
  };

  const descargarArchivo = () => {
    const enlaceDescarga = document.createElement("a");
    enlaceDescarga.href = `${process.env.REACT_APP_DESC_TEMP_NOMINA}`;
    enlaceDescarga.download = "TEMPLATE_NOMINA_SELECCION.xlsx";
    document.body.appendChild(enlaceDescarga);
    enlaceDescarga.click();
    document.body.removeChild(enlaceDescarga);
  };

  const descargNominaResumeProceso = () => {
    setUploadModalVisible(false);
    const enlaceDescarga = document.createElement("a");
    enlaceDescarga.href = listResumenProcesadosNomina.urlToDownload;
    enlaceDescarga.download = "Resumen_nomina_procesada.xlsx";
    document.body.appendChild(enlaceDescarga);
    enlaceDescarga.click();
    document.body.removeChild(enlaceDescarga);
  };

  const changeDocumentoValue = (docValue: any) => {
    const updateSelectDataBateria: any = { ...nominaDocumento };

    if (updateSelectDataBateria?.documento) {
      updateSelectDataBateria.documento = {
        value: docValue.value.documento.value[0],
        valid: docValue.value.documento.valid,
        fechaExpiracion: "",
      };

      setNominaDocumento(updateSelectDataBateria);
    }
  };

  const cancelRequest = () => {
    // Cancelar la solicitud al hacer clic en el botón "cancelar"
    cancelTokenSource.cancel("Solicitud cancelada por el usuario");
  };

  const closeModal = () => {
    setUploadModalVisible(false);
  };

  const obtenerClasePorEstado = (estado: string) => {
    const estadosValidos = ["ASIGNADO", "SUCCESS", "INGRESADO"];
    const estadosError = [
      "NO VALIDO",
      "SIN VALOR",
      "NO ENCONTRADO",
      "BLOQUEADO",
      "ERROR",
    ];

    if (estadosValidos.includes(estado)) {
      return "message-nuevo-in-resumen-procesado";
    } else if (estadosError.includes(estado)) {
      return "message-danger-in-resumen-procesado";
    } else {
      return "message-duplicate-in-resumen-procesado";
    }
  };

  const headerModal = () => {
    {
      toasts;
    }
    return (
      <>
        <div>
          <label className="titleInCargaNomina">
            {!modalResumenProceso ? "Cargar Nómina" : "Resumen Procesados"}
          </label>
        </div>
      </>
    );
  };
  {
    toasts;
  }
  return (
    <Dialog
      header={headerModal}
      visible={uploadModalVisible}
      onHide={() => setUploadModalVisible(false)}
      style={{ width: "1056px" }}
      closable={false}
      draggable={false}
      footer={" "}
    >
      {!modalResumenProceso && (
        <>
          <p className="pl-8">
            Selecciona el grupo y luego el archivo correspondiente para carga.
          </p>
          <div className="col-12  content-section flex pl-8">
            <div className=" col-10 flex" style={{ float: "left" }}>
              {
                <InputFileCustomized
                  layer={"Archivo (*):"}
                  placeholder={"Seleccione archivo"}
                  placeholderImg={true}
                  required={true}
                  iconFile={false}
                  iconDel={false}
                  maxSizeMb={10}
                  errorPosition="down"
                  date={false}
                  onPropertyChange="documento"
                  onPermaValues={nominaDocumento}
                  onValueChange={(value: any) => {
                    changeDocumentoValue({ value });
                  }}
                  leyenda={""}
                  requerido={false}
                  datoRequerido={false}
                  accept=".xlsx, .xls"
                />
              }

              <div className=" col-2 mt-5">
                <Button
                  className={
                    nominaDocumento?.documento?.value != undefined
                      ? "button-cargarN"
                      : "button-cargarN deshabilitado"
                  }
                  onClick={uploadCargaNominaToAzure}
                  disabled={nominaDocumento?.documento?.value === undefined}
                >
                  Cargar Nómina
                  <i
                    className="pi pi-upload border-circle m-2 text-white font-bold flex align-items-center justify-content-center"
                    style={{ color: "#FFFFFF" }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <p className="pCargaNomina pl-8">
            * La carga requiere una plantilla específica. Descárgala{" "}
            <span className="aqui" onClick={descargarArchivo}>
              {" "}
              aquí
            </span>{" "}
            si no la tienes.
          </p>
        </>
      )}
      {isLoadingExcel ? (
        <IconLoading
          iconInButom={false}
          className="container-icon-loading icon-loading"
          width="30"
          height="34"
        />
      ) : (
        list &&
        modalResumenProceso && (
          <div className="project-table-Mo pl-6 pr-6">
            <div className="container-table-Mo">
              <table className="content-table-Mo">
                <thead>
                  <tr>
                    <th>Registro</th>

                    <th>Procesado</th>
                    <th>Estado</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {list && list.length == 0 ? (
                    <tr>
                      <td className="col-12 justify-content-center" colSpan={4}>
                        {/* Formato de Archivo o plantilla no valida */}
                        {emptyMessage}
                      </td>
                    </tr>
                  ) : (
                    list.map((item: any) => {
                      const { personaDNI, procesado, estado, observacion } =
                        item;
                      return (
                        <tr>
                          <td>{personaDNI}</td>
                          <td>{procesado ? "Si" : "No"}</td>
                          <td className={obtenerClasePorEstado(estado)}>
                            {estado}
                          </td>
                          <td className={obtenerClasePorEstado(estado)}>
                            {observacion}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
      <div className="modal-button-content registro-individual">
        <button
          className={`modal-button cancel`}
          onClick={() => {
            closeModal(), cancelRequest();
          }}
        >
          Cerrar
        </button>
        {modalResumenProceso && (
          <Button
            className={
              list.length > 0 && listResumenProcesadosNomina.code !== 8000
                ? "button-descargaN"
                : "button-descargaN deshabilitado"
            }
            onClick={descargNominaResumeProceso}
            disabled={
              list.length == 0 || listResumenProcesadosNomina.code === 8000
            }
          >
            Descargar errores
            <span className="pl-4">
              <IconDescargaNomina fill="#FDFDFD" />
            </span>
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default CargaNomina;
