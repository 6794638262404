import { useState, useEffect, useRef } from "react";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import formatRUT from "../../../functions/formatRutWithOutPoints";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { useMsal } from "@azure/msal-react";

//const & functions

const EntryRutTableComponent = () => {
  const [token, setToken] = useState<any>(null);
  const history = useHistory();
  const [rut, setRut] = useState<any>("");
  const [rutResponse, setRutResponse] = useState<any>([]);
  const [flag, setFlag] = useState<any>("");
  const [flagMasive, setFlagMasive] = useState<any>("");
  const [reasonDiscard, setReasonDiscard] = useState<any>("");
  const [dateDiscard, setDateDiscard] = useState<any>("");
  const [observationsDiscard, setobservationsDiscard] = useState<any>("");
  const [clientDiscard, setClientDiscard] = useState<any>("");
  const [visibleLoading1, setVisibleLoading1] = useState<any>(false);
  const [visibleLoading2, setVisibleLoading2] = useState<any>(false);
  const [openAccordion, setOpenAccordion] = useState<any>(null);
  const [isSearching, setIsSearching] = useState<any>(false);

  const { instance } = useMsal();

  const CentralContainer = () => {
    return (
      <>
        <div className="flex justify-content-center align-items-center">
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "16px",
              lineHeight: "156%",
              alignItems: "center",
              color: "#333333",
              fontWeight: "600",
              width: "591px",
            }}
          >
            <p
              style={{
                width: "591px",
                textAlign: "center",
              }}
            >
              En esta opción puedes ingresar un RUT que tenga restricciones
              vigentes de algún tipo para colaborar con Teamwork o alguno de
              nuestros clientes.
            </p>
          </div>
        </div>
      </>
    );
  };

  const optionsReasonDiscard = [
    { name: "Proceso Judicial", code: "ET-02" },
    { name: "Comparendo", code: "ET-03" },
    { name: "Conducta Inadecuada", code: "17" },
    { name: "Antecendentes Penales", code: "15" },
  ];

  const optionsClientsDiscard = [
    { name: "Carozzi", code: "96591040-9" },
    { name: "Falabella", code: "76212492-0" },
  ];

  const onChangeReasonDiscard = (e: any) => {
    setReasonDiscard(e.value);
  };

  const onChangeObservationsDiscard = (e: any) => {
    setobservationsDiscard(e.target.value);
  };

  const cleanMassiveMessage = () => {
    setFlagMasive(null);
  };

  const saveDiscard = () => {
    setIsSearching(true);
    setVisibleLoading1(true);
    const day = dateDiscard.substring(0, 2);
    const month = dateDiscard.substring(3, 5);
    const year = dateDiscard.substring(6, 10);

    const formData = {
      excPerId: "",
      excTipoId: reasonDiscard.code,
      personaId: rut,
      clienteId: null,
      userId: instance.getActiveAccount()?.localAccountId,
      excPerInicio: year.concat("-").concat(month).concat("-").concat(day),
      excPerHabilitado: "true",
      excPerComentario: observationsDiscard,
      orgId: "tw",
    };
    api
      .post(`exclusion-persona/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisibleLoading1(false);
        setIsSearching(false);
        if (response.data.data && response.data.data[0]) {
          setFlag("99999");
        } else {
          setFlag("1");
        }
        console.log(rutResponse);
      })
      .catch((error) => {
        setVisibleLoading1(false);
        if (
          error.response.data.errors[0] &&
          error.response.data.errors[0].errorId == 1010
        ) {
          setFlag("1");
        } else if (
          error.response.data.errors[0] &&
          error.response.data.errors[0].errorId == 2999
        ) {
          setFlag("4");
        }
      });
  };

  const downloadTemplate = () => {
    api
      .get(`exclusion-persona-template/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "consultamasiva.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  const MassiveRequest = () => {
    return (
      <>
        <div className="accordionRutCandidates w-12">
          <Accordion
            activeIndex={openAccordion}
            onTabClose={(e) => setOpenAccordion(e.index)}
          >
            <AccordionTab header="Ingreso masivo">
              <div className="flex justify-content-center flex-column h-full my-6 mx-6 sm:my-6 mx-6 md:my-6 mx-6 lg:my-6 mx-6 xl:my-6 mx-6">
                <div className="h-full sm:h-auto  md:h-auto">
                  <div className="">
                    <p>
                      <b>
                        Para realizar el ingreso masivo siga las instrucciones:
                      </b>
                    </p>
                    <ul>
                      <li>Descargue la planilla y complete todos los campos</li>
                    </ul>
                  </div>

                  <div className="my-3">
                    <Button
                      label="Descargar planilla ingreso masivo"
                      icon="pi pi-download"
                      iconPos="right"
                      onClick={downloadTemplate}
                    />
                  </div>

                  <div className="my-3">
                    <p>
                      Luego seleccione el archivo y súbalo para ingresar las
                      restricciones:
                    </p>
                  </div>

                  <div className="mt-3 flex flex-row align-items-center">
                    <FileUpload
                      mode="advanced"
                      name="cargaArchivos"
                      accept=".xls,.xlsx"
                      maxFileSize={1000000}
                      customUpload={true}
                      chooseLabel="Seleccione archivo"
                      uploadLabel="Subir archivo"
                      cancelLabel="Cancelar"
                      uploadHandler={massiveIncome}
                      style={{ backgroundColor: "#398149" }}
                      className=""
                    />
                    <div className="flex align-self-end">
                      <Button
                        aria-label="Submit"
                        className="ml-3"
                        style={{ height: "40px" }}
                        onClick={cleanMassiveMessage}
                      >
                        <span className="material-symbols-outlined">
                          replay
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </>
    );
  };

  const onRutChange = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setRut(formatRUT(rutIngresado.trim()));
  };

  const setDate = (e: any) => {
    let value = e.target.value;
    value = value
      .replace(/[.-]/g, "")
      .replace(/^(\d{2})(\d{2})(\d{4})$/, "$1/$2/$3");
    setDateDiscard(value);
  };

  const ResponseSave = () => {
    return (
      <>
        {flag && flag === "99999" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 icon">
              how_to_reg
            </span>
            <p className="ml-3 messageRut">
              Rut ingresado de forma correcta a nuestra base de datos.
            </p>
          </div>
        ) : flag && flag === "1" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconYellow">
              warning
            </span>
            <p className="ml-3 messageRut">
              Candidato ya se encuentra en la base de datos.
            </p>
          </div>
        ) : flag && flag === "4" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconYellow">
              warning
            </span>
            <p className="ml-3 messageRut">RUT no es valido.</p>
          </div>
        ) : (
          ""
        )}
        {visibleLoading1 ? (
          <div className="alignIcon mt-3 loading">
            <Button loading />
            <p className="ml-3 messageRut">Consultando...</p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const cleanValidatedRut = () => {
    setIsSearching(false);
    setRutResponse("");
    setobservationsDiscard("");
    setReasonDiscard("");
    setClientDiscard("");
    setRut("");
    setFlag(null);
  };

  const ResponseSaveMasive = () => {
    return (
      <>
        {flagMasive && flagMasive === "1" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 icon">
              download_done
            </span>
            <p className="ml-3 messageRut">
              Datos ingresados exitosamente a base de datos.
            </p>
          </div>
        ) : flagMasive && flagMasive === "2" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconYellow">
              warning
            </span>
            <p className="ml-3 messageRut">
              Algunos datos no han sido ingresados. Revise su bandeja de
              descargar para revisar los Rut que no cuplen con el formato e
              inténtelo nuevamente.
            </p>
          </div>
        ) : flagMasive && flagMasive === "3" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconRed">
              warning
            </span>
            <p className="ml-3 messageRut">
              Archivo no cumple con el formato, no puede realizarse la consulta.
              Cerciorece de que cumple con las indicaciones.
            </p>
          </div>
        ) : (
          ""
        )}

        {visibleLoading2 ? (
          <div className="alignIcon mt-3 loading">
            <Button loading />
            <p className="ml-3 messageRut">Consultando...</p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  //API Call(s)

  const massiveIncome = (files: any) => {
    setFlag(null);
    setOpenAccordion(0);
    setVisibleLoading2(true);
    const userId: string = instance.getActiveAccount()
      ?.localAccountId as string;
    let formData = new FormData();
    formData.append("file", files.files[0]);
    api
      .post(`exclusion-persona-upload-create/${userId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisibleLoading2(false);
        if (response.data.data[2].total == 0) {
          setFlagMasive("3");
        } else {
          if (response.data.data[2].total == response.data.data[2].created) {
            setFlagMasive("1");
          } else {
            setFlagMasive("2");
            const link = document.createElement("a");
            link.href = response.data.data[3].urlToDownload;
            link.setAttribute("download", "reporteIngresoMasivo.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          }
        }
      })
      .catch((error) => {
        setVisibleLoading2(false);
        setFlagMasive("3");
      });
  };

  //useEffect

  useEffect(() => {
    setRutResponse(null);
    setDateDiscard(new Date().toLocaleDateString());
    callMsGraphTokens()
      .then((response) => {
        console.log(response.idToken);
        setToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
        history.push("/");
      });
  }, []);

  //main return//
  return (
    <>
      <div className="card layout-main-container">
        <div className="flex flex-column align-items-center justify-content-center card-container">
          <div className="mt-8">
            <CentralContainer />
          </div>

          <div className="col-8 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <div className="accordionRutCandidates mt-8">
              <Accordion activeIndex={0}>
                <AccordionTab header="Ingreso unitario">
                  <div className="flex justify-content-center flex-column h-full my-6 mx-6 sm:my-6 mx-6 md:my-6 mx-6 lg:my-6 mx-6 xl:my-6 mx-6">
                    <div className="h-full sm:h-auto  md:h-auto mb-4">
                      <p>Ingrese RUT con restricciones:</p>
                    </div>
                    <div className="sm:h-auto md:h-auto my-2">
                      <InputText
                        value={rut}
                        placeholder="Ingresar Rut"
                        className="w-10"
                        onChange={(e) => onRutChange(e)}
                      />
                    </div>
                    <div className="sm:h-auto md:h-auto my-2">
                      <Dropdown
                        value={reasonDiscard}
                        options={optionsReasonDiscard}
                        onChange={onChangeReasonDiscard}
                        optionLabel="name"
                        className="w-10"
                        placeholder="Seleccione motivo"
                      />
                    </div>

                    {/*
                    <div className='sm:h-auto md:h-auto my-2'>
                      <InputText  value={dateDiscard} 
                                  placeholder="Fecha Origen 10-10-202"
                                  className='w-4 mr-6'
                                  onChange={(e) => setDate(e)}
                                  disabled={true}
                        /> 

                      <Dropdown value={clientDiscard} 
                                options={optionsClientsDiscard} 
                                onChange={onChangeClientDiscard} 
                                optionLabel="name" 
                                className='w-5'
                                placeholder="Seleccione cliente" 
                      />
                    </div>
  */}

                    <div className="flex flex-direction-row h-full sm:h-auto  md:h-auto">
                      <InputTextarea
                        value={observationsDiscard}
                        onChange={onChangeObservationsDiscard}
                        className="w-10 mr-5"
                        placeholder="Observaciones"
                        autoResize
                      />

                      <div className="align-self-end">
                        {isSearching ? (
                          <Button
                            aria-label="Submit"
                            className="ml-3"
                            onClick={cleanValidatedRut}
                          >
                            <span className="material-symbols-outlined">
                              replay
                            </span>
                          </Button>
                        ) : rut && reasonDiscard && observationsDiscard ? (
                          <Button
                            aria-label="Submit"
                            onClick={saveDiscard}
                            disabled={false}
                          >
                            <span className="material-symbols-outlined">
                              cloud_upload
                            </span>
                          </Button>
                        ) : (
                          <Button
                            aria-label="Submit"
                            onClick={saveDiscard}
                            disabled={true}
                          >
                            <span className="material-symbols-outlined">
                              cloud_upload
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>

          <div className="my-8">
            <ResponseSave />
          </div>

          <div className="col-8 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <MassiveRequest />
          </div>

          <div className="my-8">
            <ResponseSaveMasive />
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryRutTableComponent;
