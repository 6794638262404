import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
<path d="M1.68339 21.4738C1.26391 21.4738 0.896868 21.3165 0.582262 21.0019C0.267655 20.6873 0.110352 20.3202 0.110352 19.9007V2.07303C0.110352 1.65356 0.267655 1.28652 0.582262 0.97191C0.896868 0.657303 1.26391 0.5 1.68339 0.5H10.5972L16.8894 6.79213V21.5H15.3163V7.84082H9.54855V2.07303H1.68339V19.9007H15.3163V21.4738H1.68339Z" fill="#06A502"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.11035 14C4.11035 16.49 6.12035 18.5 8.61035 18.5C11.1004 18.5 13.1104 16.49 13.1104 14C13.1104 11.51 11.1004 9.5 8.61035 9.5C6.12035 9.5 4.11035 11.51 4.11035 14ZM6.96899 12.6475C6.96899 12.4875 7.09899 12.3575 7.25899 12.3575H10.159C10.319 12.3575 10.449 12.4875 10.449 12.6475V15.5475C10.449 15.7075 10.319 15.8375 10.159 15.8375H7.25899C7.09899 15.8375 6.96899 15.7075 6.96899 15.5475V12.6475Z" fill="#06A502"/>
    </svg>
  );
};




