import { useEffect, useState } from "react";
import TablaDetenerSolicitud from "./TablaDetenerSolicitud";
import { IModalRechazarSolicitud } from "../../../../../interfaces/IContratos";

export default (props: IModalRechazarSolicitud) => {
  const { list, listData, setSelectCurrent, selectCurrent, fechaSolicitud } =
    props;
  const [currentList, setCurrentList] = useState<any>();

  useEffect(() => {
    try {
      if (list && listData) {
        const formSelect: any = {};
        const arrayData = listData.filter(({ baseContratoId }: any) =>
          list.includes(baseContratoId)
        );
        arrayData.forEach((item: any) => {
          formSelect[item.baseContratoId] = { value: undefined, valid: false };
        });
        setSelectCurrent(formSelect);
        setCurrentList(arrayData);
      }
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  return (
    <>
      <section className="content-modal-anular-documento">
        {currentList &&
          currentList.map((item: any) => {
            const {
              ficha,
              personaDNI,
              primerNombre,
              primerApellido,
              baseContratoId,
            } = item;

            return (
              <TablaDetenerSolicitud
                ficha={ficha}
                personaDNI={personaDNI}
                primerNombre={primerNombre}
                primerApellido={primerApellido}
                fechaSolicitud={fechaSolicitud}
                baseContratoId={baseContratoId}
                selectCurrent={selectCurrent}
                setSelectCurrent={setSelectCurrent}
              />
            );
          })}
      </section>
    </>
  );
};
