import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDateToDDMMAAAA } from "../../../../../../functions/kit-mdtw/dates";

interface ISeccionDetalleProceso {
  list: any;
  listaMasiva?: boolean | undefined;
  soloDetalle?: boolean | undefined;
}

const SeccionDetalleProceso = (props: ISeccionDetalleProceso) => {
  const { list, listaMasiva, soloDetalle } = props;
  const [hidden, setHidden] = useState<boolean>(soloDetalle || !listaMasiva ? false : true);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      {/* {!soloDetalle && (
        <>
          {listaMasiva ? (
            <>
              <section
                className="seccion-title-masiva"
                onClick={() => handleClickHidden()}
              >
                <section className="seccion-masiva">
                  <label className="label-title">PROCESO</label>
                  <label className="label-subtitle">{`${list?.divisionId}${list?.busquedaId} - ${list?.cargoMod}`}</label>
                  <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
                </section>
              </section>
            </>
          ) : (
            <>
              <section
                className={`seccion-title`}
                onClick={() => handleClickHidden()}
              >
                <span className="span-title">Detalle Proceso</span>
                <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
              </section>
            </>
          )}
        </>
      )}
      {hidden && <span className="linea-separador-titulo"></span>} */}
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-grilla">
          <div className="grilla-content">
            <div className="title">PROCESO</div>
            <div className="message">
              {list?.cargoMod === "" ? "Sin datos" : list.cargoMod}
            </div>
          </div>

          <div className="grilla-content">
            <div className="title xcuatro">CLIENTE</div>
            <div className="message xcuatro">
              {list?.clienteNombre ?? "Sin datos"}
            </div>
            <div className="title xcuatro">DIVISIÓN TW</div>
            <div className="message xcuatro">
              {list?.divisionId ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">ÁREA DE NEGOCIO</div>
            <div className="message xcuatro">
              {list?.areaNegocioNombre ?? "Sin datos"}
            </div>
            <div className="title xcuatro">CUPOS</div>
            <div className="message xcuatro">
              {list?.busquedaCantidad ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">ID</div>
            <div className="message xcuatro">
              {list?.busquedaId ?? "Sin datos"}
            </div>
            <div className="title xcuatro">SEXO</div>
            <div className="message xcuatro">
              {list?.busquedaGenero ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">KAM</div>
            <div className="message xcuatro">{list?.kam ?? "Sin datos"}</div>
            <div className="title xcuatro">EDAD</div>
            <div className="message xcuatro">
              {list?.busquedaRangoEdad ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">RENTA</div>
            <div className="message xcuatro">
              {list?.busquedaRenta ?? "Sin datos"}
            </div>
            <div className="title xcuatro">EXPERIENCIA</div>
            <div className="message xcuatro">
              {list?.busquedaAnoExperiencia ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">LUGAR DE TRABAJO</div>
            <div className="message xcuatro">{list?.lugar ?? "Sin datos"}</div>
            <div className="title xcuatro">FECHA SOLICITUD</div>
            <div className="message xcuatro">
              {formatDateToDDMMAAAA({
                date: list?.busquedaInicio,
                format: "/",
              }) ?? "Sin datos"}
            </div>
          </div>
          <div className="grilla-content">
            <div className="title xcuatro">TIPO DE EXPERIENCIA</div>
            <div className="message xcuatro">
              {list?.busquedaTipoExperiencia ?? "Sin datos"}
            </div>
            <div className="title xcuatro">FECHA ENTREGA</div>
            <div className="message xcuatro">
              {formatDateToDDMMAAAA({ date: list?.busquedaFin, format: "/" }) ??
                "Sin datos"}
            </div>
          </div>

          <div className="grilla-content">
            <div className="title">HORARIO</div>
            <div className="message">
              {list?.busquedaRangoHorario ?? "Sin datos"}
            </div>
          </div>

          <div className="grilla-content">
            <div className="title">BEBEFICIOS/BONOS</div>
            <div className="message">{list?.beneficios ?? "Sin datos"}</div>
          </div>

          <div className="grilla-content">
            <div className="title">OBSERVACIONES</div>
            <div className="message">
              {list?.busquedaObservacion ?? "Sin datos"}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeccionDetalleProceso;
