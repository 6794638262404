import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AreaPersonal from "../Secciones/AreaPersonal";
import AreaLaboral from "../Secciones/AreaLaboral";
import AreaDocumentacion from "../Secciones/AreaDocumentacion";
import AreaContratacion from "../../Contratacion/Secciones/AreaContratacion";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import ModalDetener from "../../Contratacion/Modals/ModalDetener";
import { formatDateToYYYYMMDD } from "../../../../../functions/kit-mdtw/dates";
import ModalAddendum from "../../../Operaciones/Modals/ModalAddendum";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";
import AreaNuevoPreocupacional from "../Secciones/AreaNuevoPreocupacional";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
interface IModalFicha {
  title: string;
  onChangeBack?: Function;
  buttonBack?: string;
  list?: any[];
  areaContrato?: boolean;
  rut: string;
  busPerId?: any[];
  setOpenModalFicha?: Function;
  personaId?: string;
  idCliente?: string;
  comentario?: string;
  estado?: string;
  fechaValidacion?: any;
  onCloseModalFicha?: Function;
  renovacion?: boolean;
  nuevoContrato?: boolean;
  onlyRead?: boolean;
  editar?: boolean;
  ficha?: string;
  fueraPlazoForRenov?: boolean;
}

export default (props: IModalFicha) => {
  const {
    title,
    onChangeBack,
    buttonBack,
    areaContrato,
    rut,
    setOpenModalFicha,
    busPerId,
    ficha,
    idCliente,
    comentario,
    estado,
    fechaValidacion,
    renovacion,
    nuevoContrato,
    onlyRead,
    editar,
    fueraPlazoForRenov,
  } = props;

  const {
    token,
    getToken,
    listaGeneral,
    listGroup,
    getRegistroCandidato,
    listaRegistroCandidato,
  } = useContext(SeleccionContext);
  const { solicitudRenovacion, newContrato, actualizaContrato } = useContext(
    OperacionContratacionContext
  );

  const { getRegistroContrato, getSolicitudContrato } =
    useContext(ValidationKamContext);

  const [objsendContrato, setObjsendContrato] = useState<any>();
  const [fechaInicio, setFechaInicio] = useState<any>();
  const [objsendBateria, setObjsendBateria] = useState<any>();
  const outputObject:any = {};

  const [fechaTerminoRenov, setFechaTerminoRenov] = useState<any>();
  const [validFechaContratacion, setValidFechaContratacion] =
    useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);

  const objetoSendFiltro = {};
  const [openModalFueraDePlazo, setOpenModalFueraDePlazo] =
    useState<boolean>(false);

  const [openModalFueraDePlazoEnRenov, setOpenModalFueraDePlazoEnRenov] =
    useState<boolean>(false);

  const [
    openModalFueraDePlazoNewContrato,
    setOpenModalFueraDePlazoNewContrato,
  ] = useState<boolean>(false);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);
  const [openModalAddendum, setOpenModalAddendum] = useState<boolean>(false);
  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);
  const [areaNegNewContrato, setAreaNegNewContrato] = useState<any>();
  const [codCargoNewContrato, setCodCargoNewContrato] = useState<any>();
  const [lugarTrabajoNewContrato, setLugarTrabajoNewContrato] = useState<any>();
  const [divisionNewContrato, setDivisionNewContrato] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(
    false
  );
  const [errorFechaMenorExa, setErrorFechaMenorExa] = useState<boolean[]>([false]);
  const [undefinedData, setUndefinedData] = useState<boolean>(false);
  useEffect(() => {
    try {
      if (token && setOpenModalFicha && rut) {
        getToken();
        getRegistroCandidato({
          personaRut: rut,
          token,
          setOpenModal: setOpenModalFicha,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [token, setOpenModalFicha]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    setListFueraPlazoModal([]);
    if (
      (renovacion || objsendContrato?.division?.value?.code === "TWEST") &&
      objsendContrato?.fechaTermino?.value == "" &&
      objsendContrato?.fechaTermino?.valid == false
    ) {
      setValid(false);
    } else {
      setValid(true);
    }

    listFueraPlazo(listaRegistroCandidato, objsendContrato);
    setAreaNegNewContrato(objsendContrato?.areaNegocio?.value?.name);
    setCodCargoNewContrato(objsendContrato?.codCargo?.value);
    setLugarTrabajoNewContrato(objsendContrato?.sucursal?.value?.name);
    setDivisionNewContrato(objsendContrato?.division?.value?.name);
  }, [objsendContrato, listaRegistroCandidato]);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickRegistroContrato = async () => {
    setIsLoading(true)
    await getRegistroContrato({
      objsendContrato,
      token,
      idCliente,
      objetoSendFiltro,
      formDocumentos:outputObject,
      personId:listaRegistroCandidato?.personal?.candidato?.personaId,
      setIsLoading
    });
    if (!isLoading) { 
      handleClickCloseModal();
      
  }
  }; 
  const listFueraPlazo = (item: any, objSendCt: any) => {
    let selectedDateInicio15: number | undefined;
    let selectedDateInicio30: number | undefined;

    if (
      item?.contratacion?.fechaInicio ||
      objSendCt?.fechaInicio?.value?.length > 1
    ) {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      let fechaInicioContrato;
      if (objSendCt?.fechaInicio?.value?.length > 1) {
        const splitDate = objSendCt.fechaInicio.value.split("/");
        fechaInicioContrato = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      } else {
        fechaInicioContrato = item?.contratacion?.fechaInicio;
      }

      const [yearInicio, monthInicio, dayInicio] =
        fechaInicioContrato.split("-");

      selectedDateInicio15 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) - 15
      ).setHours(0, 0, 0, 0);

      selectedDateInicio30 = new Date(
        Number(yearInicio), //yearInicio30
        Number(monthInicio) - 1, //monthInicio30
        Number(dayInicio) + 30 //dayInicio30
      ).setHours(0, 0, 0, 0);

      if (
        !(
          selectedDateInicio15 <= currentDate &&
          currentDate <= selectedDateInicio30
        )
      ) {
        setHayFueraplazo(true);
        const objeto = {
          areaNegocioCod: item.contratacion.areaNegocioCod,
          busPerId: busPerId,
          historial: fechaValidacion,
          personaDni: item.contratacion.personaDNI,
          primerApellido: item.personal.candidato.primerApellido,
          primerNombre: item.personal.candidato.primerNombre,
          division: item.contratacion.division,
          tipoDocumentoValue: item.contratacion.tipoDocumento,
          causal: item.contratacion.causalId,
        };
        setListFueraPlazoModal((prevList: any) => [...prevList, objeto]);
      }
    }
  };
  const handleClickSolicitaContrato = async () => {
    try {
      await Promise.all([
        getRegistroContrato({
          objsendContrato,
          token,
          idCliente,
          objetoSendFiltro,
          formDocumentos:outputObject,
          personId:listaRegistroCandidato?.personal?.candidato?.personaId
        }),
      ]);

      setOpenModalFueraDePlazo(false);
      //setListFueraPlazoModal([]);

      if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
        setOpenModalFueraDePlazo(true);
      } else {
        await getSolicitudContrato({
          token,
          idCliente,
          busPerId,
          objetoSendFiltro,
        });

        handleClickCloseModal();
        setOpenModalFueraDePlazo(false);
      }
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  const objetoForRenovacion = [
    {
      ficha: ficha + "-" + divisionNewContrato,
      fechaTermino: formatDateToYYYYMMDD({
        date: fechaTerminoRenov,
        format: "-",
      }),
    },
  ];

  const objetoForContratoNuevo = {
    ficha: [ficha + "-" + objsendContrato?.division?.value?.name],
    areaNegocioId: objsendContrato?.areaNegocio?.value?.code,
    causalId: objsendContrato?.causal?.value?.code,
    codCargo: objsendContrato?.codCargo?.value,
    divisionId: objsendContrato?.division?.value?.code,
    fechaInicio: formatDateToYYYYMMDD({
      date: objsendContrato?.fechaInicio?.value ?? "",
      format: "-",
    }),
    fechaTermino: formatDateToYYYYMMDD({
      date: fechaTerminoRenov ?? "",
      format: "-",
    }),
    funciones: objsendContrato?.funciones?.value,
    nombre: objsendContrato?.nombre?.value,
    rut: objsendContrato?.rut?.value,
    SCITeamwork: objsendContrato?.sucursal?.value?.code,
    horasTrabajadas: parseInt(objsendContrato?.horasTrabajadas?.value),
  };

  const objetoForContratoEdit = {
    ficha: ficha + "-" + objsendContrato?.division?.value?.name,
    areaNegocioId: objsendContrato?.areaNegocio?.value?.code,
    codCargo: objsendContrato?.codCargo?.value,
    SCITeamwork: objsendContrato?.sucursal?.value?.code,
  };

  const handleClickSolicitaRenovacion = async () => {
    try {
      setOpenModalFueraDePlazo(false);
      setListFueraPlazoModal([]);
      if (fueraPlazoForRenov) {
        const objetoFueraPlazoInd = [
          {
            busPerId: ficha,
            areaNegocioCod: listaRegistroCandidato.contratacion.areaNegocioCod,
            personaDni: listaRegistroCandidato.contratacion.personaDNI,
            ficha: ficha,
            primerApellido:
              listaRegistroCandidato.personal.candidato.primerApellido,
            primerNombre:
              listaRegistroCandidato.personal.candidato.primerNombre,
            tipoDocumentoValue: "Renovación",
            letra: listaRegistroCandidato.contratacion.causalId,
            division: listaRegistroCandidato.contratacion.division,
          },
        ];
        setListFueraPlazoModal(objetoFueraPlazoInd);
        setOpenModalFueraDePlazoEnRenov(true);
      } else {
        await solicitudRenovacion({
          token,
          objetoForRenovacion,
          objetoSendFiltro,
        });

        handleClickCloseModal();
        setOpenModalFueraDePlazo(false);
      }
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  const handleClickSolicitaContratoNuevo = async () => {
    try {
      setOpenModalFueraDePlazo(false);
      setListFueraPlazoModal([]);

      if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
        const objetoFueraPlazoInd = [
          {
            busPerId: [ficha],
            areaNegocioCod: objsendContrato?.areaNegocio?.value?.name,
            personaDni: listaRegistroCandidato.contratacion.personaDNI,
            ficha: ficha,
            primerApellido: "",
            primerNombre: objsendContrato?.nombre?.value,
            tipoDocumentoValue:
              listaRegistroCandidato.contratacion.tipoDocumento,
            causal: objsendContrato?.causal?.value?.code,
            division: objsendContrato?.division?.value?.code,
          },
        ];
        setListFueraPlazoModal(objetoFueraPlazoInd);
        // setOpenModalFueraDePlazoEnRenov(true);
        setOpenModalFueraDePlazoNewContrato(true);
      } else {
        await newContrato({
          token,
          objetoData: objetoForContratoNuevo,
          objetoSendFiltro,
        });

        handleClickCloseModal();
        setOpenModalFueraDePlazo(false);
      }
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  const handleClickActualizaContrato = () => {
    setOpenModalAddendum(true);
  };

  const handleClickSolicitarActualizaContrato = async () => {
    try {
      await actualizaContrato({
        token,
        objetoData: objetoForContratoEdit,
        objetoSendFiltro,
      });

      handleClickCloseModal();
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
    setOpenModalAddendum(false);
  };


// Recorre el objeto original y genera uno nuevo con la estructura para enviarse a guardado de documento
for (const key in objsendBateria) {
  if (objsendBateria.hasOwnProperty(key)) {
    const item = objsendBateria[key];

    if (item.examen && item.examen.length > 0) {
      item.examen.forEach((examen: { code: any; }) => {
        const examenCode = examen.code;

        const innerObject = {
          fechaExpiracion: item.documento?.fechaExpiracion,
          valid: item.documento?.valid,
          value: item.documento?.value 
        };
        outputObject[examenCode] = innerObject;
      });
    }
  }
}

  return (
    <>
      {listaGeneral && listGroup && (
        <>
          <div className="modal-container-validatekam edit">
            <div className="modal-content-validatekam w-80">
              <span
                className="modal-close"
                onClick={() =>
                  typeof onChangeBack == "function" && onChangeBack()
                }
              >
                <CloseIcon />
              </span>
              <div className="modal-title">
                <span>{title}</span>
              </div>

              <div className="secciones-modal">
                <AreaPersonal
                  areaContrato={true}
                  formCandidate={listaRegistroCandidato}
                />
              </div>

              <div className="separador-seccion"></div>

              <div className="secciones-modal">
                <AreaLaboral formCandidate={listaRegistroCandidato} />
              </div>

              <div className="separador-seccion"></div>

              <div className="secciones-modal">
                <AreaDocumentacion 
                rut={rut}
                personaId={listaRegistroCandidato?.personal?.candidato?.personaId} />
              </div>

              <div className="separador-seccion"></div>

              <div className="secciones-modal">
                <AreaNuevoPreocupacional 
                setObjsendBateria={setObjsendBateria}
                setErrorFechaMenorExa={setErrorFechaMenorExa} 
                setUndefinedData={setUndefinedData}/>
              </div>
              
             
              {areaContrato && (
                <>
                  <div className="separador-seccion"></div>
                  <AreaContratacion
                    token={token}
                    getToken={getToken}
                    rut={rut}
                    busPerId={busPerId}
                    setObjsendContrato={setObjsendContrato}
                    setFechaInicio={setFechaInicio}
                    setFechaTerminoRenov={setFechaTerminoRenov}
                    comentario={comentario}
                    renovacion={renovacion}
                    nuevoContrato={nuevoContrato}
                    editar={editar}
                    onlyRead={onlyRead}
                    idCliente={idCliente}
                    setValidFechaContratacion={setValidFechaContratacion}
                  />
                </>
              )}

                    {/* {isLoading && (
                
                      <div className="container-icon-loading">
                          <IconLoading className="icon-loading" width="26" height="30" />
                      </div>
                    )} */}

              {areaContrato && (renovacion || nuevoContrato || editar) ? (
                <>
                  <div className="modal-button-content contratacion">
                    <button
                      className={`modal-button cancel`}
                      onClick={handleClickCloseModal}
                    >
                      {buttonBack ? buttonBack : "Cancelar"}
                    </button>
                    <button
                      className={` modal-button ${
                        (estado !== "ACEPTADA" && estado == "COMPLETO") ||
                        !validFechaContratacion ||
                        valid || errorFechaMenorExa.some((value: any) => value)|| undefinedData || isLoading
                          ? "confirm contratacion"
                          : "disabled"
                      }`}
                      onClick={
                        nuevoContrato
                          ? handleClickSolicitaContratoNuevo
                          : renovacion
                          ? handleClickSolicitaRenovacion
                          : handleClickActualizaContrato
                      }
                      disabled={
                        (estado !== "COMPLETO" && estado == "ACEPTADA") ||
                        validFechaContratacion ||
                        !valid || errorFechaMenorExa.some((value: any) => value)|| undefinedData || isLoading
                      }
                    >
                      Solicitar{" "}
                      {renovacion
                        ? "Renovación"
                        : nuevoContrato
                        ? "Nuevo Contrato"
                        : "Actualización"}
                    </button>
                  </div>
                </>
              ) : areaContrato && !onlyRead ? (
                <>
                  <div className="modal-button-content contratacion">
                    <button
                      className={` modal-button ${
                        estado !== "ACEPTADA"
                          ? "naranja contratacion"
                          : "disabled"
                      }`}
                      onClick={handleClickRegistroContrato}
                      disabled={estado == "ACEPTADA" || errorFechaMenorExa.some((value: any) => value)|| undefinedData || isLoading}
                    >
                      {isLoading ? "Procesando" : "Guardar Datos" }   {isLoading && (
                
                <div className="container-icon-loading" style={{width:"20%"}}>
                    <IconLoading 
                    iconInButom={true}
                    className="icon-loading" width="26" height="30" />
                </div>
              )}
                    </button>
                    <button
                      className={` modal-button ${
                        estado == "COMPLETO" ||
                        estado !== "ACEPTADA" ||
                        !validFechaContratacion ||
                        valid || errorFechaMenorExa.some((value: any) => value)|| undefinedData || isLoading
                          ? "confirm contratacion"
                          : "disabled"
                      }`}
                      onClick={handleClickSolicitaContrato}
                      disabled={
                        (estado !== "COMPLETO" && estado == "ACEPTADA") ||
                        validFechaContratacion ||
                        !valid || errorFechaMenorExa.some((value: any) => value) ||undefinedData || isLoading
                      }
                    >
                      Solicitar Contrato
                    </button>
                  </div>
                </>
              ) : (
                <div className="modal-button-content center">
                  <button
                    className={`modal-button cancel`}
                    onClick={handleClickCloseModal}
                  >
                    {buttonBack ? buttonBack : "Cancelar"}
                  </button>
                </div>
              )}

              {openModalFueraDePlazo && (
                <ModalDetener
                  modalType="FueraPlazoIndividual"
                  title="SOLICITUD FUERA DE PLAZO"
                  subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
                  buttonBack="Volver"
                  onChangeBack={() => {
                    setOpenModalFueraDePlazo(false);
                  }}
                  modalStyle={""}
                  list={listFueraPlazoModal}
                  onCloseModalFicha={handleClickCloseModal}
                />
              )}

              {openModalFueraDePlazoEnRenov && (
                <ModalDetener
                  modalType="FueraPlazoIndividualRenov"
                  title="SOLICITUD FUERA DE PLAZO"
                  subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
                  buttonBack="Volver"
                  onChangeBack={() => {
                    setOpenModalFueraDePlazoEnRenov(false);
                  }}
                  modalStyle={""}
                  list={listFueraPlazoModal}
                  onCloseModalFicha={handleClickCloseModal}
                  masiveRenovObjeto={objetoForRenovacion}
                  renovar={true}
                />
              )}

              {openModalAddendum && (
                <ModalAddendum
                  modalType="addendum"
                  title="SOLICITUD EDICIÓN DE DOCUMENTO"
                  subTitle="Estás solicitando la siguiente edición de documento:"
                  buttonBack="Cancelar"
                  onChangeBack={() => {
                    setOpenModalAddendum(false);
                  }}
                  onChangeGo={() => {
                    handleClickSolicitarActualizaContrato(),
                      setOpenModalAddendum(false);
                  }}
                  modalStyle={""}
                  token={token}
                  getToken={getToken}
                  list={listaRegistroCandidato}
                  ficha={ficha}
                  areaNegNewContrato={areaNegNewContrato}
                  codCargoNewContrato={codCargoNewContrato}
                  lugarTrabajoNewContrato={lugarTrabajoNewContrato}
                />
              )}

              {openModalFueraDePlazoNewContrato && (
                <ModalDetener
                  modalType="FueraPlazoIndividualNewCont"
                  title="SOLICITUD FUERA DE PLAZO"
                  subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
                  buttonBack="Volver"
                  onChangeBack={() => {
                    setOpenModalFueraDePlazoNewContrato(false);
                  }}
                  modalStyle={""}
                  list={listFueraPlazoModal}
                  onCloseModalFicha={handleClickCloseModal}
                  masiveNewContratoObjeto={objetoForContratoNuevo}
                  nuevoContrato={true}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
