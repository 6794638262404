import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M7.49938 16L5.19736 13.698H1.79189C1.48749 13.698 1.22114 13.5838 0.99284 13.3555C0.76454 13.1272 0.650391 12.8609 0.650391 12.5565V1.1415C0.650391 0.827586 0.76454 0.558859 0.99284 0.335315C1.22114 0.111772 1.48749 0 1.79189 0H13.2069C13.5113 0 13.7776 0.111772 14.0059 0.335315C14.2342 0.558859 14.3484 0.827586 14.3484 1.1415V12.5565C14.3484 12.8609 14.2342 13.1272 14.0059 13.3555C13.7776 13.5838 13.5113 13.698 13.2069 13.698H9.8014L7.49938 16ZM1.79189 11.8526C2.55289 11.1423 3.41469 10.5684 4.37731 10.1308C5.33993 9.69322 6.37996 9.47443 7.4974 9.47443C8.61485 9.47443 9.65554 9.69322 10.6195 10.1308C11.5834 10.5684 12.4459 11.1423 13.2069 11.8526V1.1415H1.79189V11.8526ZM7.53743 7.97146C8.27306 7.97146 8.89454 7.7178 9.40188 7.21046C9.90921 6.70313 10.1629 6.08165 10.1629 5.34602C10.1629 4.61038 9.90921 3.9889 9.40188 3.48157C8.89454 2.97424 8.27306 2.72057 7.53743 2.72057C6.8018 2.72057 6.18032 2.97424 5.67298 3.48157C5.16565 3.9889 4.91198 4.61038 4.91198 5.34602C4.91198 6.08165 5.16565 6.70313 5.67298 7.21046C6.18032 7.7178 6.8018 7.97146 7.53743 7.97146ZM2.83826 12.5565H12.1605V12.3853C11.4756 11.8018 10.74 11.3611 9.9536 11.063C9.16724 10.765 8.34916 10.6159 7.49938 10.6159C6.6496 10.6159 5.83152 10.765 5.04516 11.063C4.25879 11.3611 3.52316 11.8018 2.83826 12.3853V12.5565ZM7.53743 6.82996C7.12522 6.82996 6.77485 6.68569 6.4863 6.39715C6.19775 6.1086 6.05348 5.75822 6.05348 5.34602C6.05348 4.93381 6.19775 4.58343 6.4863 4.29489C6.77485 4.00634 7.12522 3.86207 7.53743 3.86207C7.94964 3.86207 8.30001 4.00634 8.58856 4.29489C8.8771 4.58343 9.02138 4.93381 9.02138 5.34602C9.02138 5.75822 8.8771 6.1086 8.58856 6.39715C8.30001 6.68569 7.94964 6.82996 7.53743 6.82996Z"
      />
    </svg>
  );
};
