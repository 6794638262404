import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import SessionExpired from "../components/ui/kit-mdtw/modals/session/SessionExpired";
import { loginRequest } from "../config/authConfig";
import {
  AccountInfo,
  CacheLookupPolicy,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalInstance } from "..";

export default () => {
  const tiempoCuentaRegresiva: number = 59;
  const [lastInteraction, setLastInteraction] = useState<Date>(new Date());
  const { instance } = useMsal();
  const [isInvalid, setIsInvalid] = useState(false);
  const [token, setToken] = useState<any>(null);
  const [intervalTime, setIntervalTime] = useState<any>();
  const [mouseStop, setMouseStop] = useState<boolean>(false);
  const [mouseInactive, setMouseInactive] = useState<boolean>(false);
  const [isOpenModalSessionTimeOut, setIsOpenModalSessionTimeOut] =
    useState<boolean>(false);
  const [isOpenModalCandidate, setIsOpenModalCandidate] =
    useState<boolean>(false);
  const [contador, setContador] = useState<number>(tiempoCuentaRegresiva);

  // se escucha los eventos
  useEffect(() => {
    window.addEventListener("blur", handleEventBlur);
    window.addEventListener("focus", handleEventFocus);
    return () => {
      window.removeEventListener("blur", handleEventBlur);
      window.removeEventListener("focus", handleEventFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keypress", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keypress", handleMouseMove);
    };
  }, []);

  const handleMouseMove = () => {
    setLastInteraction(new Date());
    setMouseStop(false);
  };

  useEffect(() => {
    let interval: any;
    let intervalToken: any;
    let setSessionTimeOut: any;
    const modal = document.getElementById("ModalReclutarCandidato");
    if (modal?.style.display == "flex") {
      setIsOpenModalCandidate(true);
      intervalToken = setInterval(() => {
        refreshToken();
      }, 200000); // miliIntervalTokenRefresh
    } else {
      setIsOpenModalCandidate(false);
      if (mouseStop) {
        setSessionTimeOut = setTimeout(() => {
          setIsOpenModalSessionTimeOut(true);
        }, 500000); // miliSessionTimeOut
      } else {
        intervalToken = setInterval(() => {
          refreshToken();
        }, 200000); // miliIntervalTokenRefresh 300000
      }
    }
    return () => {
      clearInterval(interval);
      clearInterval(intervalToken);
      clearTimeout(setSessionTimeOut);
    };
  }, [mouseStop, isOpenModalCandidate]);

  const handleEventBlur = () => {
    setMouseStop(true);
  };

  const handleEventFocus = () => {
    setIntervalTime(clearTimeout(intervalTime));
    clearTimeout(intervalTime);
    setMouseStop(false);
  };

  const tokenBroken = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const refreshToken = async () => {
    const currentAccount = instance.getActiveAccount() as AccountInfo;
    const silentRequest = {
      scopes: ["User.Read"],
      account: currentAccount,
      forceRefresh: true,
      cacheLookupPolicy: CacheLookupPolicy.Default,
    };

    const request = {
      ...loginRequest,
      loginHint: currentAccount.username,
    };

    try {
      const tokenResponse = await msalInstance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          sessionStorage.setItem("token", response.idToken); 
        })
        .catch(async (error) => {
          console.log("ERROR 1", error);
          if (error instanceof InteractionRequiredAuthError) {
            console.log("ERROR 2", error);
            return await msalInstance
              .acquireTokenPopup(request)
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  return msalInstance.acquireTokenRedirect(request);
                }
              });
          }
        });
      return tokenResponse;
    } catch (error) {
      console.log(error);
    }
    setIsInvalid(false);
    localStorage.removeItem("closeAccept");
    //setCloseAccept(true)
  };

  const handleLogout = () => {
    msalInstance.logoutRedirect();
    msalInstance.logoutPopup();
    setIsOpenModalSessionTimeOut(false);
    setMouseInactive(false);
  };

  const handleEventTokenExtend = () => {
    setIsOpenModalSessionTimeOut(false);
    refreshToken();
    setContador(tiempoCuentaRegresiva);
    setMouseInactive(false);
  };

  return (
    <>
      {isOpenModalSessionTimeOut && (
        <>
          {contador == 0 ? (
            <>
              {tokenBroken()}
              <SessionExpired
                title="Su sesión expiró"
                message="Vuelve a iniciar sesión para continuar."
                onChangeBack={handleLogout}
                onChangeGo={handleLogout}
              />
            </>
          ) : (
            <SessionExpired
              title="Su sesión está a punto de expirar"
              subTitle="Por seguridad tu sesión se cerrará en:"
              message="Para mantenerse conectado, presione continuar."
              onChangeBack={handleLogout}
              onChangeGo={handleEventTokenExtend}
              titleButtonGo="Continuar"
              contador={contador}
              setContador={setContador}
            />
          )}
        </>
      )}
    </>
  );
};
