import { useContext, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { ICandidatoInhabilitado } from "../../../../../interfaces/ISeleccion";

interface IModalRestaurar {
  title: string;
  message: string;
  onChangeBack?: Function;
  buttonBack?: string;
  list?: any[];
  localStorageFilter: string;
  formFilter: any;
}

export default (props: IModalRestaurar) => {
  const {
    title,
    message,
    onChangeBack,
    buttonBack,
    list,
    localStorageFilter,
    formFilter,
  } = props;
  const {
    token,
    getToken,
    listaCandidatoInhabilitado,
    postDeshacerExclusion,
    getCandidatoInhabilitado,
  } = useContext(SeleccionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickDeshacerExclusion = async () => {
    if (token && list) {
      getToken();
      const arrayList = listaCandidatoInhabilitado
        .filter(({ personaId }: ICandidatoInhabilitado) =>
          list.includes(personaId)
        )
        .map(({ personaId, excPerId }: ICandidatoInhabilitado) => {
          return {
            personaId: personaId,
            excPerId: excPerId,
          };
        });
      await postDeshacerExclusion({ token, candidates: arrayList });
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        await getCandidatoInhabilitado({ token, filtros: JSON.parse(copyStorage) });
      } else {
        await getCandidatoInhabilitado({ token, filtros: formFilter });
      }
      handleClickCloseModal();
    }
  };

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="modal-container-seleccion">
            <div className="modal-content-seleccion w-70">
              <div className="modal-title">
                <span>{title}</span>
              </div>

              <div className="modal-message">
                <label>{message}</label>
              </div>

              {list && (
                <>
                  <section className="content-table-restaurar">
                    <table className="table-restaurar w-90">
                      <thead className="thead-table-restaurar">
                        <tr className="tr-table-restaurar">
                          <th className="td-table-restaurar">Nombre</th>
                          <th className="td-table-restaurar">Rut</th>
                          <th className="td-table-restaurar">Destino</th>
                        </tr>
                      </thead>
                      <tbody className="tbody-table-restaurar">
                        {list.map((item: any) =>
                          listaCandidatoInhabilitado
                            .filter(({ personaId }: any) => personaId === item)
                            .map((item: any) => {
                              const {
                                personaDNI,
                                primerNombre,
                                primerApellido,
                              } = item;
                              return (
                                <>
                                  <tr className="tr-table-restaurar">
                                    <td className="td-table-restaurar">
                                      {primerNombre} {primerApellido}
                                    </td>
                                    <td className="td-table-restaurar">
                                      {personaDNI}
                                    </td>
                                    <td className="td-table-restaurar">
                                      Candidatos habilitados
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                        )}
                      </tbody>
                    </table>
                  </section>
                </>
              )}

              <div className="modal-message">
                <label>
                  Al confirmar, los candidatos quedarán disponible para
                  participar en procesos.
                </label>
              </div>

              <div className="modal-message">
                <label>Para continuar presione Aceptar</label>
              </div>

              <div className="modal-button-content center">
                <>
                  <button
                    className={`modal-button cancel`}
                    onClick={handleClickCloseModal}
                  >
                    {buttonBack ? buttonBack : "Cancelar"}
                  </button>
                  <button
                    className={`modal-button confirm`}
                    onClick={handleClickDeshacerExclusion}
                  >
                    {buttonBack ? buttonBack : "Confirmar"}
                  </button>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
