import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import {
  ICandidatoSolEnviada,
  IPersonaVerificarRut,
} from "../../../../../interfaces/ISeleccion";

interface IModalListadoSolicitarCandidato {
  rut: any[];
  solicitudes: any;
  setSolicitudes: Function;
  handleClickSolicitarCandidato: Function;
  onChangeBack: Function; 
}

export default (props: IModalListadoSolicitarCandidato) => {
  const { rut, setSolicitudes, handleClickSolicitarCandidato, onChangeBack } = props;
  const { personaVerificarRut, procesoVerificarRut } =
    useContext(SeleccionContext);
  const [currentData, setCurrentData] = useState<IPersonaVerificarRut[]>([]);
  const [solEnviada, setSolEnviada] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (personaVerificarRut && procesoVerificarRut) {
      setCurrentData([{ ...personaVerificarRut, ...procesoVerificarRut }]);
      console.log(currentData);
    }
  }, [rut]);

  return (
    <>
      {rut &&
        personaVerificarRut &&
        procesoVerificarRut &&
        currentData.map((item: any) => {
          const {
            busPerId, 
            busquedaId,
            primerApellido,
            primerNombre,
            reclutador,
            fechaCreacion,
          } = item;

          return (
            <>
              <tr className="tr-table-restaurar" key={busquedaId}>
                <td className="td-table-restaurar">{rut}</td>
                <td className="td-table-restaurar">
                  {primerNombre} {primerApellido}
                </td>
                <td className="td-table-restaurar">
                  {formatDateToDDMMAAAA({
                    date: fechaCreacion,
                    format: "/",
                  })}
                </td>
                <td className="td-table-restaurar">{reclutador}</td>
                <td className="td-table-restaurar">{busquedaId}</td>
                <td className="td-table-restaurar">
                  <div className="content-icon-table">
                    {!solEnviada ? (
                      <>
                        <button
                          onClick={() => {
                            handleClickSolicitarCandidato({
                              procesoDestino: busPerId,
                            });
                            setSolEnviada(true); 
                          }}
                          className="button-solicitar-candidato solicitar"
                        >
                          Solicitar
                          <ArrowForwardIcon />
                        </button>
                        <button 
                          onClick={() => onChangeBack()}
                          className="button-delete-table-restaurar"
                        >
                          <CloseIcon />
                        </button>
                      </>
                    ) : (
                      <label className="sol-enviada">Solicitud Enviada</label>
                    )}
                  </div>
                </td>
              </tr>
            </>
          );
        })}
    </>
  );
};
