import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface ModalErrorProps {
  token: string;
  onClose: () => void;
  isModalVisible: boolean;
  //closeFullModal: (flag:any) => void;
}

const ModalError: React.FC<ModalErrorProps> = ({
  token,
  isModalVisible,
  onClose,
  //closeFullModal,
}) => {
  const buttonModalError = () => {
    return (
      <div>
        <Button
          className="flex flex-row-reverse ml-4 mb-4"
          label="Volver un paso atrás"
          onClick={() => {
            onClose();
          }}
        >
          <i className="material-symbols-outlined"> arrow_back</i>
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        header={null}
        visible={isModalVisible}
        onHide={onClose}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "454px", height: "266px" }}
        closable={false}
        draggable={false}
        footer={buttonModalError}
      >
        <div className="mt-3 mb-4 mr-4 ml-5 ">
          <div className="mb-3">
            <span
              className="material-symbols-outlined"
              style={{ color: "#D32F2F" }}
            >
              emergency_home
            </span>
            <label className="errorModalTittle ml-4">Error del Sitema</label>
          </div>
          <div>
            <label htmlFor="inputName2Ctx1">
              Algo salió mal al procesar la información. Por favor intente
              nuevamente, lamentamos lo ocurrido.
            </label>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalError;
