interface IIcondel {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IIcondel) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 15 18"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M4.55156 0.788867C4.73086 0.426953 5.09941 0.201172 5.50117 0.201172H9.49883C9.90059 0.201172 10.2691 0.426953 10.4484 0.788867L10.6875 1.26367H13.875C14.4627 1.26367 14.9375 1.73848 14.9375 2.32617C14.9375 2.91387 14.4627 3.38867 13.875 3.38867H1.125C0.537305 3.38867 0.0625 2.91387 0.0625 2.32617C0.0625 1.73848 0.537305 1.26367 1.125 1.26367H4.3125L4.55156 0.788867ZM1.125 4.45117H13.875V15.0762C13.875 16.2482 12.9221 17.2012 11.75 17.2012H3.25C2.07793 17.2012 1.125 16.2482 1.125 15.0762V4.45117ZM4.3125 6.57617C4.02031 6.57617 3.78125 6.81523 3.78125 7.10742V14.5449C3.78125 14.8371 4.02031 15.0762 4.3125 15.0762C4.60469 15.0762 4.84375 14.8371 4.84375 14.5449V7.10742C4.84375 6.81523 4.60469 6.57617 4.3125 6.57617ZM7.5 6.57617C7.20781 6.57617 6.96875 6.81523 6.96875 7.10742V14.5449C6.96875 14.8371 7.20781 15.0762 7.5 15.0762C7.79219 15.0762 8.03125 14.8371 8.03125 14.5449V7.10742C8.03125 6.81523 7.79219 6.57617 7.5 6.57617ZM10.6875 6.57617C10.3953 6.57617 10.1562 6.81523 10.1562 7.10742V14.5449C10.1562 14.8371 10.3953 15.0762 10.6875 15.0762C10.9797 15.0762 11.2188 14.8371 11.2188 14.5449"

         fill={fill}/>
      </svg>



    );
  };