import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 2L25.9174 2.08372L27.8203 2.33423L29.694 2.74963L31.5244 3.32676L33.2976 4.06123L35 4.94744L36.6187 5.97865L38.1413 7.14702L39.5564 8.44365L40.853 9.85867L42.0213 11.3813L43.0526 13L43.9388 14.7024L44.6732 16.4756L45.2504 18.306L45.6658 20.1797L45.9163 22.0826L46 24L45.9163 25.9174L45.6658 27.8203L45.2504 29.694L44.6732 31.5244L43.9388 33.2976L43.0526 35L42.0213 36.6187L40.853 38.1413L39.5564 39.5564L38.1413 40.853L36.6187 42.0213L35 43.0526L33.2976 43.9388L31.5244 44.6732L29.694 45.2504L27.8203 45.6658L25.9174 45.9163L24 46L22.0826 45.9163L20.1797 45.6658L18.306 45.2504L16.4756 44.6732L14.7024 43.9388L13 43.0526L11.3813 42.0213L9.85867 40.853L8.44365 39.5564L7.14702 38.1413L5.97865 36.6187L4.94744 35L4.06123 33.2976L3.32676 31.5244L2.74963 29.694L2.33423 27.8203L2.08372 25.9174L2 24L2.08372 22.0826L2.33423 20.1797L2.74963 18.306L3.32676 16.4756L4.06123 14.7024L4.94744 13L5.97865 11.3813L7.14702 9.85867L8.44365 8.44365L9.85867 7.14702L11.3813 5.97865L13 4.94744L14.7024 4.06123L16.4756 3.32676L18.306 2.74963L20.1797 2.33423L22.0826 2.08372L24 2Z"
        stroke-width="4"
        stroke={disabled ? "#aeaeae" : fill}
      />
      <path
        d="M28.75 31.136C30.1977 31.136 31.4347 30.6349 32.4608 29.6326C33.4869 28.6303 34 27.4053 34 25.9576C34 24.7167 33.6261 23.611 32.8784 22.6406C32.1307 21.6701 31.1523 21.0496 29.9432 20.7792C29.5455 19.3156 28.75 18.1303 27.5568 17.2235C26.3636 16.3167 25.0114 15.8633 23.5 15.8633C21.6386 15.8633 20.0597 16.5116 18.7631 17.8082C17.4665 19.1048 16.8182 20.6837 16.8182 22.5451C15.7205 22.6724 14.8097 23.1457 14.0858 23.965C13.3619 24.7843 13 25.7428 13 26.8406C13 28.0337 13.4176 29.0479 14.2528 29.8832C15.0881 30.7184 16.1023 31.136 17.2955 31.136L22.5455 31.136C23.0705 31.136 23.5199 30.9491 23.8937 30.5752C24.2676 30.2013 24.4545 29.7519 24.4545 29.2269L24.4545 24.311L25.9818 25.7906L27.3182 24.4542L23.5 20.636L19.6818 24.4542L21.0182 25.7906L22.5455 24.311L22.5455 29.2269L17.2955 29.2269C16.6273 29.2269 16.0625 28.9962 15.6011 28.5349C15.1398 28.0735 14.9091 27.5087 14.9091 26.8406C14.9091 26.1724 15.1398 25.6076 15.6011 25.1462C16.0625 24.6849 16.6273 24.4542 17.2955 24.4542L18.7273 24.4542L18.7273 22.5451C18.7273 21.2246 19.1926 20.0991 20.1233 19.1684C21.054 18.2377 22.1795 17.7724 23.5 17.7724C24.8205 17.7724 25.946 18.2377 26.8767 19.1684C27.8074 20.0991 28.2727 21.2246 28.2727 22.5451L28.75 22.5451C29.6727 22.5451 30.4602 22.8712 31.1125 23.5235C31.7648 24.1758 32.0909 24.9633 32.0909 25.886C32.0909 26.8087 31.7648 27.5962 31.1125 28.2485C30.4602 28.9008 29.6727 29.2269 28.75 29.2269L26.3636 29.2269L26.3636 31.136L28.75 31.136Z"
        fill={disabled ? "#aeaeae" : fill}
      />
    </svg>

  );
};
