import { useEffect, useState } from "react";
import DropDownListCustomized from "../../kit-mdtw/DropDownListCustomized";
import "../../../../assets/css/asignar-proceso.min.css";
import TablaDownloadPsicolaboral from "./TablaDownloadPsicolaboral";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { IListaFormatoPsicolaboral } from "../../../../interfaces/ISeleccion";

interface IModalDownloadPsicolaboral {
  token: string;
  list: any[];
  listOriginal: any[];
  selectCurrent: any;
  setSelectCurrent: Function;
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[];
}

export default (props: IModalDownloadPsicolaboral) => {
  const {
    token,
    list,
    listOriginal,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
  } = props;
  const [isLoadingProcesos, setIsLoadingProcesos] = useState<
    boolean | undefined
  >(undefined);
  const [buttonAssignAll, setButtonAssignAll] = useState<boolean>(false);
  const [gridCandidateSelect, setGridCandidateSelect] = useState<any[]>([]);
  const [tipoFormato, setTipoFormato] = useState<any>([]);

  useEffect(() => {
    if (list && listOriginal) {
      const formSelect: any = {};
      const arrayPersona: string[] = listOriginal.filter(
        ({ personaDNI }: any) => list.includes(personaDNI)
      );
      arrayPersona.forEach((item: any) => {
        formSelect[item.busPerId] = { value: undefined, valid: false };
      });
      setSelectCurrent(formSelect);
      setGridCandidateSelect(arrayPersona);
    }
  }, [list, listOriginal]);

  useEffect(() => {
    if (listaFormatoPsicolaboral) {
      const listaForm = listaFormatoPsicolaboral?.map(
        (item: IListaFormatoPsicolaboral) => {
          return {
            name: item.nombre,
            code: item.formatoPsicolaboralId,
          };
        }
      );
      setTipoFormato(listaForm);
    }
  }, [listaFormatoPsicolaboral]);

  const handleClickDocumentAll = async (buttonAssignAll: boolean) => {
    try {
      if (buttonAssignAll) {
        if (tipoDocumentoAll.motivo?.value) {
          const formSelect: any = {};
          const arrayData = listOriginal.filter(({ personaDNI }: any) =>
            list.includes(personaDNI)
          );
          arrayData.forEach((item: any) => {
            formSelect[item.busPerId] = {
              value: tipoDocumentoAll.motivo?.value,
              valid: true,
            };
          });
          return await setSelectCurrent(formSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {list && list.length > 1 && (
        <section className="modal-section-motivo-detencion w-70">
          <div className="section-detener-solicitud">
            <DropDownListCustomized
              list={tipoFormato}
              layer="Tipo Formato"
              requerido={false}
              onValueChange={setTipoDocumentoAll}
              onPermaValues={tipoDocumentoAll}
              onPropertyChange="motivo"
            />
          </div>
          <div
            className="section-checkall w-30"
            onClick={() => {
              setButtonAssignAll(!buttonAssignAll);
              handleClickDocumentAll(!buttonAssignAll);
            }}
          >
            {buttonAssignAll ? (
              <CheckBoxIcon className="svg-detener-solicitud" />
            ) : (
              <CheckBoxOutlineBlankIcon className="svg-detener-solicitud" />
            )}
            <label className="label-detener-solicitud">Asignar a todos</label>
          </div>
        </section>
      )}
      <section className="content-modal-anular-documento">
        {gridCandidateSelect &&
          gridCandidateSelect.map((item: any, i: number) => {
            const {
              busPerId,
              personaId,
              division,
              busquedaId,
              primerNombre,
              primerApellido,
              personaDNI,
              clienteNombre,
            } = item;

            return (
              <TablaDownloadPsicolaboral
                key={i}
                busPerId={busPerId}
                personaId={personaId}
                division={division}
                busquedaId={busquedaId}
                primerNombre={primerNombre}
                primerApellido={primerApellido}
                personaDNI={personaDNI}
                clienteNombre={clienteNombre}
                selectCurrent={selectCurrent}
                setSelectCurrent={setSelectCurrent}
                buttonAssignAll={buttonAssignAll}
                tipoFormato={tipoFormato}
              />
            );
          })}
      </section>
    </>
  );
};
