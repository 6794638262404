import { useEffect, useRef, useState } from "react";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { Avatar } from "primereact/avatar";
import defaultAvatar from "../../../assets/imgs/defaultAvatar.png";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Toolbar } from "primereact/toolbar";
import { useAccount, useMsal } from "@azure/msal-react";
import { Menu } from "primereact/menu";
import { getMe, getPhoto } from "../../../services/MsGraphApiCall";
import { FILTER_PROFILE } from "../../../diccionary/Diccionary";
import axios from "axios";
import { IUserAccess } from "../../../interfaces/ISeleccion";
import ModalOption from "./ModalOption";

type AppTopBarProps = {
  children?: JSX.Element | JSX.Element[];
  onToggleMenuClick: (event: React.MouseEvent) => void;
  active: string;
  itemMenu?: string;
  setItemMenu?: Function;
};

type GraphData = {
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
};

const authRequest = {
  scopes: ["User.Read"],
};

const PreveTopbar: React.FC<AppTopBarProps> = ({
  active,
  itemMenu,
  setItemMenu,
}) => {
  const { instance, accounts, inProgress } = useMsal();
  const [profile, setProfile] = useState<any>(null);
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const account = useAccount(accounts[0] || {});
  const [itemMasivo, setItemMasivo] = useState<boolean[] | undefined>(
    undefined
  );
  const [itemProf, setItemProf] = useState<boolean[] | undefined>(undefined);
  const usuarioId: string = window.sessionStorage.getItem(
    "usuarioId"
  ) as string;
  const token: string = window.sessionStorage.getItem("token") as string;
  const menu = useRef<any>(null);

  useEffect(() => {
    if (token && usuarioId) {
      getUserAccess({ token });
    }
  }, [token]);

  const getUserAccess = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/usuario/${usuarioId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setItemMasivo(data.map(({ masivo }: IUserAccess) => masivo));
      setItemProf(data.map(({ profesional }: IUserAccess) => profesional));
    } catch (error) {
      console.log("Ha ocurrido algo ", { error });
    }
  };

  const items = [
    {
      label: "Perfil",
      icon: "pi pi-fw pi-user",
      command: () => {},
    },
    {
      label: "Configuraciones",
      icon: "pi pi-fw pi-cog",
    },
    {
      separator: true,
    },
    {
      label: "Salir",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        handleLogout();
      },
    },
  ];

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  useEffect(() => {
    const acquireToken = () => {
      instance.acquireTokenRedirect({
        ...authRequest,
        account: instance.getActiveAccount() as AccountInfo,
      });
    };
    if (!graphData && inProgress === InteractionStatus.None) {
      getMe(instance)
        .then(setGraphData)
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) acquireToken();
        });
      getPhoto(instance)
        .then(setPhoto)
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) acquireToken();
        });
    }
  }, [instance, graphData, photo, inProgress]);

  const leftContents = () => {
    return (
      <>
        {profile &&
        (profile == FILTER_PROFILE.tw_sel ||
          profile == FILTER_PROFILE.tw_admsel ||
          profile == FILTER_PROFILE.adm_tw ||
          profile == FILTER_PROFILE.admin_sel ||
          profile == FILTER_PROFILE.tw_admsel_prof) ? (
          <Link
            to="/procesosasignadosactivos"
            className="layout-topbar-logo ml-4"
          >
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        ) : (
          ""
        )}

        {profile && profile == FILTER_PROFILE.admin_ops && (
          <Link to="/resumen" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        )}

        {profile && profile == FILTER_PROFILE.tw_ops && (
          <Link to="/contratacion" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        )}

        {profile && profile == FILTER_PROFILE.tw_legal && (
          <Link to="/consultaexclusionrut" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        )}

        {profile && profile == FILTER_PROFILE.tw_cliente && (
          <Link to="/validacioncliente" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        )}
        {profile && profile == FILTER_PROFILE.tw_ops && (
          <Link to="/contratos" className="layout-topbar-logo ml-4">
            <img
              src={"/assets/layout/images/logo270x55.png"}
              alt="logo"
              style={{ width: "169px", height: "33px", border: "0" }}
            />
          </Link>
        )}
      </>
    );
  };

  const rightContents = () => {
    return (
      <>
        <div className="flex  flex-wrap">
          {active === "A" ? (
            <div className="flex align-items-center mx-3">
              <a
                href="/consultaexclusionrut"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "24px",
                  alignItems: "center",
                  fontWeight: "600",
                  color: "#013776",
                }}
              >
                Consulta de Rut
              </a>
            </div>
          ) : (
            <div className="flex align-items-center mx-3">
              <a
                href="/consultaexclusionrut"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "24px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#333333",
                }}
              >
                Consulta de Rut
              </a>
            </div>
          )}

          {profile &&
          (profile == FILTER_PROFILE.tw_admsel_prof ||
            profile == FILTER_PROFILE.tw_admsel ||
            profile == FILTER_PROFILE.tw_legal ||
            profile == FILTER_PROFILE.adm_tw) ? (
            active === "B" ? (
              <div className="flex align-items-center mx-3">
                <a
                  href="/ingresoexclusionrut"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "24px",
                    alignItems: "center",
                    fontWeight: "600",
                    color: "#013776",
                  }}
                >
                  Ingreso de rut
                </a>
              </div>
            ) : (
              <div className="flex align-items-center mx-3">
                <a
                  href="/ingresoexclusionrut"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "24px",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#333333",
                  }}
                >
                  Ingreso de rut
                </a>
              </div>
            )
          ) : (
            ""
          )}

          {(profile && profile == FILTER_PROFILE.adm_tw) ||
          profile == FILTER_PROFILE.tw_admsel_prof ? (
            <div className="flex align-items-center mx-3">
              <a
                className=" with-menu"
                // href="/seleccionprofesional/porvalidar"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Selección Profesional
                <ModalOption
                  itemMenu={itemMenu}
                  setItemMenu={setItemMenu}
                  selectionType={"profesional"}
                />
              </a>
            </div>
          ) : (
            ""
          )}

          {(profile && profile == FILTER_PROFILE.adm_tw) ||
          profile == FILTER_PROFILE.tw_admsel ? (
            <div className="flex align-items-center mx-3">
              <a
                className=" with-menu"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontWeight: "400",
                  color: "#013776",
                  cursor: "pointer",
                }}
              >
                Selección Masiva
                <ModalOption
                  itemMenu={itemMenu}
                  setItemMenu={setItemMenu}
                  selectionType={"masivo"}
                />
              </a>
            </div>
          ) : (
            ""
          )}

          {profile &&
            profile == FILTER_PROFILE.tw_sel &&
            (itemProf?.includes(true) ? (
              <div className="flex align-items-center mx-3">
                <a
                  className=" with-menu"
                  // href="/seleccionprofesional/porvalidar"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "18px",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#013776",
                    cursor: "pointer",
                  }}
                >
                  Selección Profesional
                  <ModalOption
                    itemMenu={itemMenu}
                    setItemMenu={setItemMenu}
                    selectionType={"profesional"}
                  />
                </a>
              </div>
            ) : (
              ""
            ))}

          {profile &&
            profile == FILTER_PROFILE.tw_sel &&
            (itemMasivo?.includes(true) ? (
              <div className="flex align-items-center mx-3">
                <a
                  className=" with-menu"
                  // href="/seleccionprofesional/porvalidar"
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "16px",
                    lineHeight: "18px",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#013776",
                    cursor: "pointer",
                  }}
                >
                  Selección Masiva
                  <ModalOption
                    itemMenu={itemMenu}
                    setItemMenu={setItemMenu}
                    selectionType={"masivo"}
                  />
                </a>
              </div>
            ) : (
              ""
            ))}

          <div className="flex  flex-wrap">
            <div className="flex align-items-center mx-3">
              <p className="p-component2">{account?.name}</p>
            </div>
            <div className="flex align-items-center justify-content-center w-4rem  m-2">
              <button
                className="p-link layout-topbar-button"
                onClick={(event) => menu.current.toggle(event)}
              >
                <Avatar
                  image={photo || defaultAvatar}
                  size="large"
                  shape="circle"
                />
              </button>
            </div>
          </div>

          <Menu model={items} popup ref={menu} id="popup_menu" />
        </div>
      </>
    );
  };

  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  //main return
  return (
    <>
      <div className="prevetopbar stickyFunction2">
        <Toolbar left={leftContents} right={rightContents} />
        <Divider type="dashed"></Divider>
      </div>
    </>
  );
};

export default PreveTopbar;
