import { useParams } from "react-router-dom";
import SeleccionState from "../../../context/Seleccion/SeleccionState";
import SinGraficoEjecutivo from "./SinGraficoEjecutivo";
import SinGraficoLarge from "./SinGraficoLarge";
import ConGraficoEjecutivo from "./ConGraficoEjecutivo";
import ConGraficoLarge from "./ConGraficoLarge";

const GenerarPdfPsicolaboral = () => {
  const { formato, busperid }: any = useParams();
  const token = sessionStorage.getItem("token") as string;

  return (
    <SeleccionState>
      <section className="containe-pdf-psicolaboral">
        {busperid && formato == "M" && (
          <SinGraficoEjecutivo
            busperid={busperid}
            formato={formato}
            token={token}
          />
        )}
        {busperid && formato == "XL" && (
          <SinGraficoLarge
            busperid={busperid}
            formato={formato}
            token={token}
          />
        )}
        {busperid && formato == "M-GRAPHIC" && (
          <ConGraficoEjecutivo
            busperid={busperid}
            formato={formato}
            token={token}
          />
        )}
        {busperid && formato == "XL-GRAPHIC" && (
          <ConGraficoLarge
            busperid={busperid}
            formato={formato}
            token={token}
          />
        )}
      </section>
    </SeleccionState>
  );
};

export default GenerarPdfPsicolaboral;
