import { useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import "../../../assets/css/validationKam.min.css";

interface IModalSalir {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
}

export default (props: IModalSalir) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    list,
  } = props;
  

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickSalir = () => {
    typeof onChangeGo == "function" && onChangeGo();
  };

 

  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-45">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title py-3">
            <span>{title}</span>
          </div>
                   
          <div className="modal-subtitle pb-3">
            <span>{subTitle}</span>
          </div>
          <div className="modal-button-content center modalButton">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button delete ${
                    modalStyle ? modalStyle : "delete"
                  }`}
                  onClick={() =>  handleClickSalir()}
                >
                  {buttonGo ? buttonGo : "Salir"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
