import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { prettifyRut } from "../../../hooks/useRut";
import { ProgressSpinner } from "primereact/progressspinner";
import { useHistory, useParams } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AlternateEmail, TapasTwoTone } from "@mui/icons-material";
import {
  descarte_cliente,
  descarte_persona,
  descarte_perfil,
  status_asistio,
  status_confirmado,
  status_noasistio,
  status_no_confirmado,
  status_porvalidar,
} from "../../../config/constantes";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ModalError from "../../../utils/ModalError";
import { MultiSelect } from "primereact/multiselect";
import formatRUT from "../../../functions/formatRUT";
import { useMsal } from "@azure/msal-react";
import { Tooltip } from "primereact/tooltip";
import ModalChangeId from "../../../utils/ModalChangeId";
import ModalDiscard from "../../../utils/ModalDiscard";
import ModalBlocking from "../../../utils/ModalBlocking";
import ModalChangeGroup from "../../../utils/ModalChangeGroup";
import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";

const ConfirmTableComponentV2 = () => {
  const [candidatesNoAdmit2, setCandidatesNoAdmit2] = useState<any>(null);
  const [filterStatusNoAdmit2, setFilterStatusNoAdmit2] = useState<any>(null);
  const [recruitmentSourceFilterNoAdmit2, setRecruitmentSourceFilterNoAdmit2] =
    useState<any>("");
  const [fechaCreacionNoAdmit2, setFechaCreacionNoAdmit2] = useState<any>(null);
  const [filterRutNoAdmit, setFilterRutNoAdmit] = useState<any>("");
  const [filterIdNoAdmit2, setFilterIdNoAdmit2] = useState<any>("");
  const [searchIdsFilterNoAdmit2, setsearchIdsFilterNoAdmit2] = useState<any[]>(
    []
  );
  const [fechaCreacionNoAdmit, setFechaCreacionNoAdmit] = useState<any>(null);
  const [resultFechasCreacionNoAdmit2, setresultFechasCreacionNoAdmit2] =
    useState<any>(null);
  const [resultProgresosNoAdmit, setresultProgresosNoAdmit] =
    useState<any>(null);
  const [candidates, setCandidates] = useState<any>(null);
  const [candidatesTabla, setCandidatesTabla] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [token, setToken] = useState<any>(null);
  const [resultAreasNegocios, setresultAreasNegocios] = useState<any>(null);
  const [resultProgresos, setresultProgresos] = useState<any>(null);
  const [progreso, setProgreso] = useState<any>(null);
  const [resultCargos, setresultCargos] = useState<any>(null);
  const [resultFechasCreacion, setresultFechasCreacion] = useState<any>(null);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [candidateModal, setCandidateModal] = useState<any>(null);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);
  const [filterStatus, setFilterStatus] = useState<any>(null);
  const toast = useRef<Toast>(null);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [filterRut, setFilterRut] = useState<any>("");
  const [visiblePorCandidato, setVisiblePorCandidato] = useState(false);
  const [visiblePorCliente, setVisiblePorCliente] = useState(false);
  const [inputModalCandidatos, setInputModalCandidatos] = useState<any>("");
  const [inputModalClientes, setInputModalClientes] = useState<any>("");
  const [candidatosADescartar, setCandidatosADescartar] = useState<any[]>([]);
  const [filterDate, setFilterDate] = useState<any>(null);
  const { busquedaId }: any = useParams();
  const [fechaCreacion, setFechaCreacion] = useState<any>(null);
  const [group, setGroup] = useState<any>({});
  let history = useHistory();
  const { instance } = useMsal();
  const [visibleModalError, setVisibleModalError] = useState(false);
  const menuDescartar = useRef<Menu>(null);
  const [visiblePorPerfil, setVisiblePorPerfil] = useState(false);
  const [inputModalPerfiles, setInputModalPerfiles] = useState<any>("");
  const [visibleModalChangeId, setVisibleModalChangeId] = useState(false);
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);
  const [visibleModalBlocking, setVisibleModalBlocking] = useState(false);
  const [candidatosABloquear, setCandidatosABloquear] = useState<any[]>([]);
  const [searchIdsFilter, setsearchIdsFilter] = useState<any[]>([]);
  const [filterId, setFilterId] = useState<any>("");
  const [searchIds, setsearchIds] = useState<any[]>([]);
  const [visibleModalChangeGroup, setVisibleModalChangeGroup] = useState(false);
  const [visibleWarningInfo, setVisibleWarningInfo] = useState(false);
  const [warnings, setWarnings] = useState<any[]>([]);
  const { idGrupo }: any = useParams();
  const [filterUsuario, setFilterUsuario] = useState("");
  const [optionReclutador, setOptionReclutador] = useState<any>([]);
  const [dataMovilizacion, setDataMovilizacion] = useState<any>([]);
  const userId = sessionStorage.getItem("usuarioId") as string;

  const initFilters = () => {
    setFilterStatus({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });
    setFilterStatusNoAdmit2({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [
          { value: "", matchMode: FilterMatchMode.EQUALS, filtered: false },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });
  };

  const backToTable = (flag: number) => {
    if (flag == 1) {
      setVisiblePorCandidato(false);
      setInputModalCandidatos("");
    } else if (flag == 2) {
      setVisiblePorCliente(false);
      setInputModalClientes("");
    } else if (flag == 3) {
      setVisiblePorPerfil(false);
      setInputModalPerfiles("");
    }
  };

  const renderFooterCandidate = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorCandidatos(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalCandidatos.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => setVisiblePorCandidato(false)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const goToRecruit = (idGrupo: string) => {
    history.push("/reclutarporgrupos/" + idGrupo);
  };

  const goToValidation = (idGrupo: string) => {
    history.push("/validacionporgrupo/" + idGrupo);
  };

  const goToConfirm = (idGrupo: string) => {
    history.push("/confirmacionporgrupo/" + idGrupo);
  };

  const rightContents = () => {
    return (
      <>
        <div className="cardTest">
          <div className="" style={{}}>
            <Button
              label="Reclutar"
              className="p-button-text"
              onClick={() => goToRecruit(busquedaId)}
            />

            <Button
              label="Validar"
              className="p-button-text  mx-5"
              onClick={() => goToValidation(busquedaId)}
            />
            <Button
              label="Confirmar"
              className="p-button-raised p-button-text "
              onClick={() => goToConfirm(busquedaId)}
            />
          </div>
        </div>
      </>
    );
  };

  const renderFooterClient = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorClientes(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalClientes.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => setVisiblePorCliente(false)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const MenuDemo = (candidato: any) => {
    const menu = useRef<any>(null);
    const toast = useRef<any>(null);
    const mostrarMenuDescartarBarra = (e: any) => {
      if (menu != null && menu.current) {
        menu.current.toggle(e);
      }
    };
    const items = [
      {
        items: [
          {
            label: "Por Candidato",
            command: () => {
              setVisiblePorCandidato(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
          {
            label: "Por Cliente",
            command: () => {
              setVisiblePorCliente(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
          {
            label: "Conducta Inadecuada",
            command: () => {
              setVisiblePorPerfil(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
        ],
      },
    ];

    return (
      <div>
        <Toast ref={toast}></Toast>
        <Menu model={items} popup ref={menu} id="popup_menu2" />
        <Tooltip target=".des" />
        <span
          className="des material-symbols-outlined md-24 iconCRed  cursor-pointer mt-1 ml-2"
          data-pr-tooltip="Descartar"
          data-pr-position="right"
          onClick={(event) => mostrarMenuDescartarBarra(event)}
        >
          close
        </span>
      </div>
    );
  };

  const items2 = [
    {
      items: [
        {
          label: "Por Candidato",
          command: () => {
            setVisiblePorCandidato(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Por Cliente",
          command: () => {
            setVisiblePorCliente(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Conducta Inadecuada",
          command: () => {
            setVisiblePorPerfil(true);
            setCandidatosADescartar(selectedCandidates);
            //descartarPorCandidato(candidato);
            //toast.current.show({ severity: 'success', summary: 'Descartado', detail: 'Candidato descartado', life: 3000 });
          },
        },
        {
          /*
      {
        label: 'No Asistió',
        command: () => {
          changeStatusToNoAttended(selectedCandidates);
        }
      }
*/
        },
      ],
    },
  ];

  const renderFooterProfile = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorPerfil(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalPerfiles.length === 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(3)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {
        history.push("/");
      });
  };

  const cerrarModalError = () => {
    setVisibleModalError(false);
    getCandidates();
    getMovilizacion();
  };

  const toggleModal = (rowData: any) => {
    setCandidateModal(rowData);
    setIsModalVisible((wasModalVisible) => !wasModalVisible);
  };

  const cerrarModalDiscard = () => {
    setCandidatosADescartar([]);
    setVisibleModalDiscard(false);
    // buscarCandidatos();
  };

  const saveModalDiscard = () => {
    setVisibleModalDiscard(false);
    setCandidatosADescartar([]);
    getCandidates();
  };

  const openModalDiscard = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosADescartar(array);
    setVisibleModalDiscard(true);
  };
  const openModalDiscardMasive = (candidatosADescartar: any[]) => {
    setVisibleModalDiscard(true);
    setCandidatosADescartar(candidatosADescartar);
  };

  const filterbusiness_fechaSelected = (e: { value: any }) => {
    console.log(e.value);
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacion(e.value);
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const convertToDate2 = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  function eliminarObjetosDuplicados(arr: any, prop: any) {
    var nuevoArray = [];
    var lookup: any = {};
    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }
    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }
    return nuevoArray;
  }

  function SortArrayByNames(x: any, y: any) {
    return x.name.localeCompare(y.name);
  }

  const statusSelect = [
    { name: "Todos", code: "" },
    { name: "Asistentes", code: "ASISTIO" },
    { name: "Ausentes", code: "NOASISTIO" },
  ];

  const updateList = (
    list: any,
    checked_asistio: boolean,
    checked_ausente: boolean
  ) => {
    const arr = [...candidates];
    list.forEach((element: { busPerId: any }) => {
      const index = arr.findIndex((x) => x.busPerId == element.busPerId);

      if (checked_asistio) {
        arr[index].busPerStatus = "ASISTIO";
      } else if (checked_ausente) {
        arr[index].busPerStatus = "NOASISTIO";
      } else if (!checked_asistio && !checked_ausente) {
        arr[index].busPerStatus = "CONFIRMADO";
      }
    });
    setCandidates(arr);
  };

  const asistenciaTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="field-checkbox ml-3">
            {rowData.busPerStatus == "ASISTIO" ? (
              <Checkbox
                inputId="binary"
                tooltip="Confirmar Asistencia"
                tooltipOptions={{ position: "left" }}
                checked={true}
                onChange={() => changeToConfirmed(rowData)}
              />
            ) : (
              <Checkbox
                inputId="binary"
                tooltip="Confirmar Asistencia"
                tooltipOptions={{ position: "left" }}
                checked={false}
                onChange={() => changeToAttended(rowData)}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const transporteTw = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="field-checkbox ml-3">
            {rowData.movilizacion == "TEAMWORK" ? (
              <Checkbox
                inputId="binary"
                tooltip="Trasporte Team-work"
                tooltipOptions={{ position: "left" }}
                checked={true}
                onChange={() =>
                  updateCandidateTransporteTw({
                    busPerId: rowData.busPerId,
                    // movilizacion: "102e34a8-2d29-4489-8004-77d2b6e7b7e5",
                    movilizacion: "DIRECTO",
                  })
                }
              />
            ) : (
              <Checkbox
                inputId="binary"
                tooltip="Trasporte Team-work"
                tooltipOptions={{ position: "left" }}
                checked={false}
                onChange={() =>
                  updateCandidateTransporteTw({
                    busPerId: rowData.busPerId,
                    // movilizacion: "9336e953-f307-48bd-8657-d4bd01e9c9b1",
                    movilizacion: "TEAMWORK",
                  })
                }
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const ausenteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="field-checkbox ml-3">
            {rowData.busPerStatus == "NOASISTIO" ? (
              <Checkbox
                inputId="binary"
                tooltip="Candidato Ausente"
                tooltipOptions={{ position: "left" }}
                checked={true}
                onChange={() => changeToConfirmed(rowData)}
              />
            ) : (
              <Checkbox
                inputId="binary"
                tooltip="Candidato Ausente"
                tooltipOptions={{ position: "left" }}
                checked={false}
                onChange={() => changeToNoAttended(rowData)}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const changeToAttended = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusToAttended(array, false);
  };

  const changeToNoAttended = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusToNoAttended(array);
  };

  const changeStatusToAttended = (listadoValidar: any, isMasive: boolean) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/asistio`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (isMasive) {
          creartToast(
            toast,
            "success",
            "Asistencias confirmadas",
            "Candidatos confirmados por KAM",
            6000
          );
        } else {
          creartToast(
            toast,
            "success",
            "Asistencia confirmada",
            "Candidato confirmado por KAM",
            6000
          );
        }

        setSelectedCandidates([]);
        updateList(listadoValidar, true, false);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const changeStatusToNoAttended = (listadoValidar: any) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/no-asistio`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "warn",
          "Candidato ausente",
          "No se presentó en la planta",
          6000
        );
        setSelectedCandidates([]);
        updateList(listadoValidar, false, true);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const changeToConfirmed = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusToConfirmed(array);
  };

  const changeStatusToConfirmed = (listadoValidar: any) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/confirmado`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "warn",
          "Candidato no asistió",
          "Ha desmarcado el candidato seleccionado",
          6000
        );
        setSelectedCandidates([]);
        updateList(listadoValidar, false, false);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const getMovilizacion = async () => {
    getToken();
    try {
      const result: any = await axios.get(
        `${process.env.REACT_APP_API_URL}metodo-movilizacion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setDataMovilizacion(data);
      console.log({data})
    } catch (error) {
      console.log(error);
    }
  };

  const updateCandidateTransporteTw = async (values: any) => {
    const { busPerId, movilizacion } = values;
    let movilizacionSelec;   

    if (movilizacion == "DIRECTO") {
      movilizacionSelec = dataMovilizacion.find((item: any) => item.nombre == "DIRECTO")
    } else {
      movilizacionSelec = dataMovilizacion.find((item: any) => item.nombre == "TEAMWORK")
    }
    console.log({movilizacionSelec});
    const body = [
      {
        busPerId,
        movilizacionId: movilizacionSelec.movilizacionId ?? "",
      },
    ];

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}metodo-movilizacion?userId=${userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      getCandidates();
      if (movilizacion == "TEAMWORK") {
        creartToast(
          toast,
          "success",
          "Transporte Tw confirmado",
          "Candidato confirmado para transporte Tw",
          6000
        );
      } 
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const selectProgreso = (resultProgresos: any) => {
    let circleClass = "";
    if (resultProgresos.name == "circleRed") {
      circleClass = "circleRed";
    } else if (resultProgresos.name == "circleYellow") {
      circleClass = "circleYellow";
    } else if (resultProgresos.name == "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <div className={circleClass}></div>
      </>
    );
  };

  const selectionFilterStatus = (e: any) => {
    const value = e.target.value.code;
    let filter_Status = { ...filterStatus };
    filter_Status["busPerStatus"].constraints[0].value = value;
    setFilterStatus(filter_Status);
    setSelectedStatus(e.target.value);
  };

  const selectionFilterRut = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRut(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatus(filter_Status);
  };

  const selectionFilterId = (e: any) => {
    let filter_Status = { ...filterStatus };
    console.log(e.target.value);
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterId(e.target.value);
  };

  const selectFilterVariosId = (e: { value: any }) => {
    const business_IdContrains: any = [];
    const business_id = e.value;
    business_id.forEach(function (business_id: any) {
      business_IdContrains.push({
        value: business_id,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_IdContrains.length === 0) {
      business_IdContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [...business_IdContrains],
      },
    }));
    setFilterId(e.value);
  };

  const selectionFilterUsuario = (e: any) => {
    let filter_Status = { ...filterStatus };
    console.log(e.target.value);
    filter_Status["usuarioNombre"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterUsuario(e.target.value);
  };

  const selectionFilterUsuarioVarios = (e: { value: any }) => {
    const business_UsuarioContrains: any = [];
    const business_usuarios = e.value;
    business_usuarios.forEach(function (business_usuarios: any) {
      business_UsuarioContrains.push({
        value: business_usuarios,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_UsuarioContrains.length === 0) {
      business_UsuarioContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [...business_UsuarioContrains],
      },
    }));
    setFilterUsuario(e.value);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {candidates ? (
          <div className="flex flex-direction-row">
            {/*       <p className='quantityStyle mr-5 mt-2'>Q. confirmados:  {candidates.filter((candidate:any)=>candidate.busPerStatus=='CONFIRMADO').length}</p>*/}
            <p className="quantityStyle mr-5 mt-2">
              Q. asistentes:{" "}
              {
                candidates.filter(
                  (candidate: any) => candidate.busPerStatus == "ASISTIO"
                ).length
              }{" "}
            </p>
            <p className="quantityStyle mr-5 mt-2">
              Q. ausentes:{" "}
              {
                candidates.filter(
                  (candidate: any) => candidate.busPerStatus == "NOASISTIO"
                ).length
              }{" "}
            </p>
          </div>
        ) : (
          ""
        )}

        <Menu model={items2} popup ref={menuDescartar} id="popup_menu" />
        <div className="mt-4">
          <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            tooltip="Confirma asistencia"
            tooltipOptions={{
              position: "left",
              style: { backgroundColor: "#D5D5D5" },
            }}
            style={
              selectedCandidates.length > 0
                ? { color: "#0288d1" }
                : { color: "#D5D5D5" }
            }
            onClick={() => changeStatusToAttended(selectedCandidates, true)}
            disabled={selectedCandidates.length > 0 ? false : true}
          />
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
            onClick={() => openModalDiscardMasive(selectedCandidates)}
            tooltip={"Descartar seleccionados"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
            disabled={selectedCandidates.length > 0 ? false : true}
          />

          <Button
            icon="pi pi-ban"
            className=" p-button-rounded p-button-success p-button-outlined mr-3"
            data-pr-tooltip="Bloquear candidato"
            data-pr-position="left"
            aria-label="Search"
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
            onClick={() => openModalBlokingMasive(selectedCandidates)}
            tooltip={"Bloquear seleccionados"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
            disabled={selectedCandidates.length > 0 ? false : true}
          />

          <Button
            icon="pi pi-file-excel"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            onClick={exportExcelFromAzure}
            tooltip={"Descarga nómina"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
          />
        </div>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <p className="margin0 padding7y14 filter_table_text">Estado:</p>
        <Dropdown
          value={selectedStatus}
          options={statusSelect}
          onChange={(e) => selectionFilterStatus(e)}
          optionLabel="name"
          placeholder="Seleccione"
        />

        <p className="margin0 padding7y14 filter_table_text">ID:</p>
        <MultiSelect
          value={filterId}
          options={searchIdsFilter}
          onChange={(e) => {
            selectFilterVariosId(e);
          }}
          optionLabel="label"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacion}
          options={resultFechasCreacion}
          onChange={(e) => {
            filterbusiness_fechaSelected(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          id="inputTlfRutCtx1"
          value={filterRut}
          onChange={(e: any) => selectionFilterRut(e)}
          placeholder="11.234.567-8"
          maxLength={12}
        />

        {group.busquedas &&
          (group.busquedas.filter(
            (search: any) =>
              instance.getActiveAccount()?.localAccountId ===
              search.accountManager
          ).length > 0 ||
            ["adm_tw", "tw_admsel"].includes(
              String(sessionStorage.getItem("profile"))
            )) && (
            <>
              <p className="margin0 padding7y14 filter_table_text">
                Reclutador:
              </p>
              <MultiSelect
                value={filterUsuario}
                options={optionReclutador}
                onChange={(e) => selectionFilterUsuarioVarios(e)}
                optionLabel="name"
                placeholder="Seleccione"
                selectedItemsLabel="{0} áreas seleccionadas"
                maxSelectedLabels={1}
              />
            </>
          )}

        {selectedCandidates.length > 0 ? (
          selectedCandidates.length == 1 ? (
            <p className="margin0 padding7y14 filter_table_text quantityStyle  ">
              {" "}
              Q. seleccionado : {selectedCandidates.length}
            </p>
          ) : (
            <p className="margin0 padding7y14 filter_table_text quantityStyle ">
              {" "}
              Q. seleccionados : {selectedCandidates.length}
            </p>
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const circleProgress = (candidate: any) => {
    let progreso = candidate.busPerPorcCompletitud;
    let circleClass = "";
    if (progreso === "circleRed") {
      circleClass = "circleRed";
    } else if (progreso === "circleYellow") {
      circleClass = "circleYellow";
    } else if (progreso === "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <span style={{ display: "none" }}>
          {parseInt(candidate.busPerPorcCompletitud)}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={circleClass}></div>
        </div>
      </>
    );
  };

  const backToValidar = (candidate: any) => {
    callStatusUpDateIngresado(candidate);
  };

  const cerrarModalChangeId = () => {
    setVisibleModalChangeId(false);
  };

  const saveModalChangeId = () => {
    setVisibleModalChangeId(false);
    getCandidates();
  };

  const toggleModalChangeId = (rowData: any) => {
    setCandidateModal(rowData);
    setVisibleModalChangeId(true);
  };

  const callStatusUpDateIngresado = (candidate: any) => {
    api
      .put(
        `busqueda-persona/status/validado`,
        {
          orgId: candidate.orgId,
          list: [
            {
              busPerId: candidate.busPerId,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Enviado Exitosamente",
          "Revisar en bandeja validar",
          3000
        );
        getCandidates();
        setSelectedCandidates([]);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const openModalChangeGroup = (rowData: any) => {
    console.log(rowData);
    setCandidateModal(rowData);
    setVisibleModalChangeGroup(true);
  };

  const cerrarModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
  };

  const saveModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
    getCandidates();
    creartToast(
      toast,
      "success",
      "Cambio de grupo",
      "Guardado correctamente",
      3000
    );
    setSelectedCandidates([]);
  };

  const unBlocking = (candidate: any) => {
    const data = {
      excPerId: candidate.warningData[0].excPerId,
      usuarioId: instance.getActiveAccount()?.localAccountId,
      busPerId: candidate.busPerId,
    };

    api
      .put(`exclusion-persona-recover`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        getCandidates();
        getCandidatesNoAdmit2();
        creartToast(toast, "warn", "Exitoso", "Se reincorpora registro", 6000);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div className="flex justify-content-start">
          <Button
            icon="pi pi-undo"
            className="tooltip-button ml-2 p-button-rounded p-button-text p-button-info"
            tooltip="Mover a validar"
            tooltipOptions={{
              className: "bluegray-tooltip",
              position: "right",
            }}
            type="button"
            onClick={() => backToValidar(rowData)}
          />

          {instance.getActiveAccount()?.localAccountId == rowData.userId &&
          rowData.isOwner ? (
            <>
              <Button
                icon="pi pi-id-card"
                className="tooltip-button ml-2 p-button-rounded p-button-text p-button-info"
                tooltipOptions={{
                  className: "bluegray-tooltip",
                  position: "bottom",
                }}
                type="button"
                onClick={() => toggleModal(rowData)}
                disabled={true}
              />
              <Tooltip target=".des"></Tooltip>
              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Descartar"}
                data-pr-position="right"
                onClick={() => openModalDiscard(rowData)}
              >
                close
              </i>
              <i
                className="des material-symbols-outlined md-24 iconBlue mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Cambiar candidato de ID "}
                data-pr-position="right"
                onClick={() => toggleModalChangeId(rowData)}
              >
                drive_file_move
              </i>
              <i
                className=" des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Bloquear"}
                data-pr-position="right"
                onClick={() => openModalBlocking(rowData)}
              >
                block
              </i>

              <i
                className="des material-symbols-outlined md-24 iconCGreen mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
                onClick={() => openModalChangeGroup(rowData)}
              >
                groups
              </i>
            </>
          ) : (
            <>
              <Button
                style={{ color: "#D5D5D5" }}
                icon="pi pi-id-card"
                className="tooltip-button ml-2 p-button-rounded p-button-text p-button-info"
                tooltipOptions={{
                  className: "bluegray-tooltip",
                  position: "bottom",
                }}
                type="button"
                //onClick={() => toggleModal(rowData)}
                disabled={true}
              />

              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip={"Descartar"}
                data-pr-position="right"
              >
                close
              </i>
              <i
                className="des material-symbols-outlined md-24 iconBlue mx-2 mt-1 iconGray"
                data-pr-tooltip={"Cambiar candidato de ID "}
                data-pr-position="right"
              >
                drive_file_move
              </i>
              <i
                className=" des material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip={"Bloquear"}
                data-pr-position="right"
              >
                block
              </i>

              <i
                className="des material-symbols-outlined md-24 iconGray mx-2 mt-1 iconGray"
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
              >
                groups
              </i>
            </>
          )}

          {rowData.warningData[0] ? (
            instance.getActiveAccount()?.localAccountId ==
            rowData.warningData[0].userId ? (
              <i
                className="des material-symbols-outlined md-18 iconCCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Deshacer Descarte"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  };

  const headerPhoneTemplate = () => {
    return (
      <>
        Teléfono 1
        <i className="ml-3" style={{ color: "gray" }}>
          <WhatsAppIcon fontSize="small" />
        </i>
      </>
    );
  };

  const headerEmailTemplate = () => {
    return (
      <>
        Correo
        <i className="ml-3" style={{ color: "gray" }}>
          <AlternateEmail fontSize="small" />
        </i>
      </>
    );
  };

  //Principales Llamadas de Apis
  const getCandidates = () => {
    getToken();
    if (token) {
      api
        .get(`busqueda-persona-allowed`, {
          params: {
            usuarioId: instance.getActiveAccount()?.localAccountId,
            cargoGroupId: busquedaId,
            dateNow: "no",
            limit: 1000,
            clearPerson: "si",
            busPerStatus:
              status_confirmado + "," + status_asistio + "," + status_noasistio,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoadingCandidates(true);
          const results = response.data.data;
          setCandidates(results);
        })
        .catch((error) => {
          setVisibleModalError(true);
        });
    }
  };

  const getbusquedaGroup = () => {
    api
      .get(`busqueda-group/`, {
        params: {
          cargoGroupId: busquedaId,
          userId: instance.getActiveAccount()?.localAccountId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroup(response.data.data[0][0]);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  //Funciones con Llamadas de apis
  const descartarPorCandidatos = (discardedCandidates: any[]) => {
    const listaDescarte = discardedCandidates.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_persona,
        comentario: inputModalCandidatos,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Descartados exitosamente",
          "Eliminados del listado",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorCandidato(false);
        setInputModalCandidatos("");
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const descartarPorClientes = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_cliente,
        comentario: inputModalClientes,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por cliente",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorCliente(false);
        setInputModalClientes("");
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const descartarPorPerfil = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_perfil,
        comentario: inputModalPerfiles,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por Conducta Inadecuada",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorPerfil(false);
        setInputModalPerfiles("");
      })
      .catch((error) => {});
  };

  const paintId = (rowData: any) => {
    return <>{rowData.busquedaId == idGrupo ? "" : rowData.busquedaId}</>;
  };

  //Use Effect
  useEffect(() => {
    if (token) {
      getCandidates();
      getMovilizacion();
      getCandidatesNoAdmit2();
      getbusquedaGroup();
      initFilters();
    }
  }, [token]);

  useEffect(() => {
    callMsGraphTokens()
      .then((response) => {
        setToken(response.idToken);
      })
      .catch((e) => {
        history.push("/");
      });
  }, []);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 +
          " " +
          candidate.personaNombre2 +
          " " +
          candidate.personaApe1 +
          " " +
          candidate.personaApe2;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
      });
      setCandidates(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;

        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
      });

      setCandidatesTabla(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let progresos = candidates.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });

      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresos(unicosColores);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let areasNegocioNombres = candidates.map((candidate: any) => {
        return [candidate.areaNegocioCod, candidate.areaNegocioId];
      });
      let mappingNegocios = areasNegocioNombres.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasAreas = eliminarObjetosDuplicados(mappingNegocios, "name");
      unicasAreas.sort(SortArrayByNames);
      setresultAreasNegocios(unicasAreas);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let cargosNombres = candidates.map((candidate: any) => {
        return [candidate.cargoNombre, candidate.cargoId];
      });
      let mappingCargos = cargosNombres.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosCargos = eliminarObjetosDuplicados(mappingCargos, "name");
      unicosCargos.sort(SortArrayByNames);
      setresultCargos(unicosCargos);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
      });
      setCandidatesTabla(candidates);
      let progresos = candidates.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresos(unicosColores);
      let fechasCreacion = candidates.map((candidate: any) => {
        return [candidate.cdcCreateDt2, candidate.cdcCreateDt2];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(0, 2);
        let mesFechaName = x.name.substring(3, 5);
        let anioFechaName = x.name.substring(6, 10);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(0, 2);
        let mesFechaCode = x.code.substring(3, 5);
        let anioFechaCode = x.code.substring(6, 10);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacion(unicasFechasCreacion);

      let optRecluters = candidates.map((candidate: any) => {
        return {
          name: candidate.usuarioNombre,
          value: candidate.usuarioNombre,
        };
      });
      let unicoRecluter = eliminarObjetosDuplicados(optRecluters, "name");
      const newObjR = {
        value: "",
        name: "Todos",
      };
      setOptionReclutador([newObjR, ...unicoRecluter]);
    }
  }, [candidates]);

  useEffect(() => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId,
        };
        newArr = [...newArr, newObj];
      });
      const newObj2 = {
        value: "",
        label: "Todos",
      };
      setsearchIdsFilter([newObj2, ...newArr]);

      const newObj = {
        value: "",
        label: "Sin ID",
      };
      newArr = [newObj, ...newArr];
      setsearchIds(newArr);
    }
  }, [group]);

  const exportExcelFromAzure = () => {
    const filterData = {
      filtros: {
        fuentes: [],
        procesos: filterId || [],
        rut: filterRut,
        reclutadores: filterUsuario !== "" ? filterUsuario : [],
        estados: selectedStatus.code === "" ? [] : [selectedStatus.code],
        progresos: [],
        fechas:
          fechaCreacion != null ? fechaCreacion.map((f: any) => f.code) : [],
      },
    };

    getToken();
    api
      .post(`busqueda/create-excel-group/`, filterData, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: busquedaId,
          busPerStatus: status_confirmado
            .concat(",")
            .concat(status_asistio)
            .concat(",")
            .concat(status_noasistio),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "nomina.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        // setVisibleModalErrorUpload(true);
      });
  };

  const openModalBlocking = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosABloquear(array);
    setVisibleModalBlocking(true);
  };

  const openModalBlokingMasive = (candidatosABloquear: any[]) => {
    setVisibleModalBlocking(true);
    setCandidatosABloquear(candidatosABloquear);
  };

  const painWaring = (rowData: any) => {
    return (
      <>
        {rowData.warningData && rowData.warningData.length > 0 ? (
          <span
            className="material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
            onClick={() => openWarningInfo(rowData)}
          >
            warning
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const openWarningInfo = (rowData: any) => {
    setVisibleWarningInfo(true);
    setWarnings(rowData.warningData);
  };

  const saveModalBloking = () => {
    setVisibleModalBlocking(false);
    setCandidatosABloquear([]);
    getCandidates();
    creartToast(toast, "error", "Bloqueo", "Guardado correctamente", 3000);
    getCandidatesNoAdmit2();
  };

  const cerrarModalBlocking = () => {
    setCandidatosADescartar([]);
    setVisibleModalBlocking(false);
  };

  const getCandidatesNoAdmit2 = () => {
    getToken();
    api
      .get(`busqueda-persona-blocked/`, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: busquedaId,
          activeBusquedas: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCandidatesNoAdmit2(response.data.data);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  useEffect(() => {
    if (candidatesNoAdmit2) {
      candidatesNoAdmit2.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
        let status = "";
        if (
          candidate.busPerStatus === "DESCARTADO" ||
          candidate.busPerStatus === "DESVINCULADO" ||
          candidate.busPerStatus === "RECHAZADO"
        ) {
          status = "inhabilitado";
        } else {
          status = "habilitado";
        }
        candidate.status = status;
      });
      let progresos = candidatesNoAdmit2.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresosNoAdmit(unicosColores);
      let fechasCreacion = candidatesNoAdmit2.map((candidate: any) => {
        return [candidate.cdcCreateDt2, candidate.cdcCreateDt2];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      console.log(unicasFechasCreacion);
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(0, 2);
        let mesFechaName = x.name.substring(3, 5);
        let anioFechaName = x.name.substring(6, 10);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(0, 2);
        let mesFechaCode = x.code.substring(3, 5);
        let anioFechaCode = x.code.substring(6, 10);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacionNoAdmit2(unicasFechasCreacion);
    }
  }, [candidatesNoAdmit2]);

  const selectionFilterIdNoId2 = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit2 };
    console.log(e.target.value);
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatusNoAdmit2(filter_Status);
    setFilterIdNoAdmit2(e.target.value);
  };

  useEffect(() => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId,
        };
        newArr = [...newArr, newObj];
      });
      const newObj2 = {
        value: "",
        label: "Todos",
      };
      setsearchIdsFilter([newObj2, ...newArr]);
      setsearchIdsFilterNoAdmit2([newObj2, ...newArr]);
      const newObj = {
        value: "",
        label: "Sin ID",
      };
      newArr = [newObj, ...newArr];
      setsearchIds(newArr);
    }
  }, [group]);

  const recruitmentSources = [
    { name: "Todos", code: "" },
    { name: "BNE", code: "BNE" },
    { name: "Chile Trabajos", code: "Chile Trabajos" },
    { name: "CompuTrabajo", code: "CompuTrabajo" },
    { name: "Estados de WhatsApp", code: "Estados de WhatsApp" },
    { name: "Facebook pagado Team-Work", code: "Facebook pagado Team-Work" },
    { name: "Facebook personal", code: "Facebook personal" },
    { name: "Hiringroom", code: "Hiringroom" },
    { name: "Instagram Pagado Team-Work", code: "Instagram Pagado Team-Work" },
    { name: "Instagram Personal", code: "Instagram Personal" },
    { name: "Laborum", code: "Laborum" },
    { name: "OMIL", code: "OMIL" },
    { name: "Referido Candidato", code: "Referido Candidato" },
    { name: "Referido Colaborador", code: "Referido Colaborador" },
    { name: "Yapo", code: "Yapo" },
    { name: "WhatsApp", code: "WhatsApp" },
    { name: "Otro", code: "Otro" },
    { name: "Data de Respaldo", code: "DataRespaldo" },
  ];

  const leftToolbarTemplateDataTableTwo2 = () => {
    return (
      <>
        <span className="material-symbols-outlined md-24 mt-1 iconBlue">
          filter_alt
        </span>
        <p className="margin0 padding7y14 filter_table_text">
          Fuente Reclutamiento:
        </p>
        <Dropdown
          value={recruitmentSourceFilterNoAdmit2}
          options={recruitmentSources}
          onChange={(e) => selectionFilterFuenteNoAdmit(e)}
          optionLabel="name"
          placeholder="Seleccione"
        />
        {/*<p className="margin0 padding7y14 filter_table_text">ID:</p>
      <Dropdown
        value={filterIdNoAdmit2}
        options={searchIdsFilterNoAdmit2}
        onChange={(e) => selectionFilterIdNoId2(e)}
        optionLabel="label"
        placeholder="Seleccione"
  />*/}

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacionNoAdmit2}
          options={resultFechasCreacionNoAdmit2}
          onChange={(e) => {
            filterbusiness_fechaSelectedNoAdmit2(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          value={filterRutNoAdmit}
          onChange={(e: any) => selectionFilterRutNoAdmit(e)}
          placeholder="11.234.567-8"
          maxLength={12}
          keyfilter={/^[^#<>*!]+$/}
        />
      </>
    );
  };

  const selectionFilterRutNoAdmit = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRutNoAdmit(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatusNoAdmit2(filter_Status);
  };

  const filterbusiness_fechaSelectedNoAdmit2 = (e: { value: any }) => {
    console.log(e.value);
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatusNoAdmit2((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacionNoAdmit2(e.value);
  };

  const selectionFilterFuenteNoAdmit = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit2 };
    console.log(e.target.value);
    filter_Status["fuenteOrg"].constraints[0].value = e.target.value.code;
    setFilterStatusNoAdmit2(filter_Status);
    setRecruitmentSourceFilterNoAdmit2(e.target.value);
  };

  const actionsBlock = (rowData: any) => {
    return (
      <>
        {" "}
        {rowData.warningData[0] ? (
          instance.getActiveAccount()?.localAccountId ==
          rowData.warningData[0].userId ? (
            <div className="flex flex-row">
              <Tooltip target=".bloquear"></Tooltip>
              <i
                className="bloquear material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Desbloquear"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const alertasDesing = (rowData: any) => {
    return (
      <>
        {rowData.status === "inhabilitado" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            <p style={{ margin: "0", paddingLeft: "10px", color: "#D32F2F" }}>
              Candidato Inhabilitado
            </p>
            <p style={{ margin: "0", paddingLeft: "10px" }}>No Contactar</p>
          </div>
        ) : rowData.busPerStatus === "CONTRATADO" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            <p style={{ margin: "0", paddingLeft: "10px", color: "#2A63D5" }}>
              Candidato con contrato vigente
            </p>
            <p style={{ margin: "0", paddingLeft: "10px" }}>
              No disponible, no Contactar
            </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  //Main Return

  return (
    <>
      <Toast ref={toast} position="top-right"></Toast>
      <div className="layout-main-container">
        <div className="layout-main">
          <div className="grid" style={{ paddingBottom: "28px" }}>
            <div className="col">
              <a
                href="/procesosasignadosactivos"
                style={{
                  color: "#AEAEAE",
                  padding: "6px",
                  borderRadius: "50%",
                  border: "1px solid #AEAEAE",
                  width: "28px",
                }}
                aria-label="Search"
                className="flex justify-content-center align-items-center"
              >
                <i className="pi pi-arrow-left"></i>
              </a>
            </div>

            <Toolbar right={rightContents}></Toolbar>
          </div>

          <div>
            {group ? (
              <h2
                className="flex justify-content-center"
                style={{
                  height: "39px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "25px",
                  alignItems: "center",
                  color: "#333333",
                }}
              >
                {group.cargoGroupName}
              </h2>
            ) : (
              ""
            )}

            <span className="flex justify-content-center">
              En esta opción puedes armar la lista de candidatos confirmados,
              descargue la nómina
            </span>
            <span className="flex justify-content-center mt-2 mb-6 ">
              Puedes reclutar para procesos específicos y/o cambiarlos según sea
              el caso.
            </span>
          </div>

          {group.busquedas
            ? group.busquedas.map((busqueda: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="flex flex-wrap justify-content-between surface-200 pt-3 border-round my-3"
                  >
                    <div className="flex flex-row ml-8">
                      {busqueda.busquedaUrgencia === 2 ? (
                        <span className="circuloRed alignIcon"></span>
                      ) : busqueda.busquedaUrgencia === 1 ? (
                        <span className="circuloYellow alignIcon"></span>
                      ) : busqueda.busquedaUrgencia === 0 ? (
                        <span className="circuloGreen alignIcon"></span>
                      ) : (
                        ""
                      )}

                      <span className="ml-2">
                        {busqueda.busquedaId} - {busqueda.cargoNombre} -{" "}
                        {busqueda.clienteNombre}{" "}
                      </span>
                    </div>

                    <div className="flex flex-row mr-8">
                      <p className="vacantantesMsg">
                        {" "}
                        Q. Vacantes abiertas : {busqueda.busquedaCantidad}
                      </p>
                      <p className="mx-3 vacantantesMsg">
                        {" "}
                        Q. Vacantes cubiertas: 8
                      </p>
                      <p className="vacantantesMsg">
                        {" "}
                        Fecha entrega : {convertToDate2(busqueda.busquedaFin)}
                      </p>
                    </div>
                  </div>
                );
              })
            : ""}

          <div>
            <h2
              className="p-1"
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "21px",
                lineHeight: "34px",
                color: "#2A63D5",
                marginTop: "30px",
              }}
            >
              Lista de candidatos confirmados
            </h2>
          </div>

          <Toolbar
            className="mb-4 p-toolbar2 stickyFunction overflow-x-auto"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {!loadingCandidates && (
            <ProgressSpinner
              className="flex justify-content-center"
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          )}

          <div className="accordionCandidates mr-3 my-4">
            <Accordion activeIndex={0}>
              <AccordionTab header="Postulantes">
                {candidatesTabla && loadingCandidates && (
                  <DataTable
                    id="tblAddCtx2"
                    value={candidatesTabla}
                    filters={filterStatus}
                    responsiveLayout="scroll"
                    emptyMessage="No hay registros."
                    paginator={candidatesTabla.length < 15 ? false : true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                    currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                    rows={15}
                    rowsPerPageOptions={[15, 30, 50]}
                    selection={selectedCandidates}
                    onSelectionChange={(e) => setSelectedCandidates(e.value)}
                    dataKey="busPerId"
                    selectionMode="checkbox"
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3em" }}
                    ></Column>
                    <Column field="fullname" header="Nombre" sortable></Column>
                    <Column
                      field="personaDniFormat"
                      header="Rut"
                      sortable
                      style={{ width: "130px" }}
                    ></Column>
                    <Column
                      field="wspFormat"
                      header={headerPhoneTemplate}
                      style={{ minWidth: "130px" }}
                    ></Column>
                    <Column
                      field="email"
                      header={headerEmailTemplate}
                      style={{ minWidth: "130px" }}
                    ></Column>
                    <Column
                      field="busquedaId"
                      header="ID"
                      body={paintId}
                    ></Column>
                    {/*
              <Column field="cargoNombre" header="Cargo" style={{ minWidth: '5rem' }} sortable ></Column>
                        */}
                    <Column
                      field="busPerPorcCompletitud"
                      header="Progreso"
                      body={circleProgress}
                      exportable={false}
                      style={{ minWidth: "5rem" }}
                      sortable
                      filterElement={selectProgreso}
                    ></Column>
                    <Column
                      header="Acciones"
                      body={actionBodyTemplate}
                      exportable={false}
                    ></Column>
                    <Column
                      field="cdcCreateDt2"
                      header="Fecha postulación"
                      style={{ minWidth: "5rem" }}
                    ></Column>
                    {group.busquedas &&
                      (group.busquedas.filter(
                        (search: any) =>
                          instance.getActiveAccount()?.localAccountId ===
                          search.accountManager
                      ).length > 0 ||
                        ["adm_tw", "tw_admsel", "tw_admsel_prof"].includes(
                          String(sessionStorage.getItem("profile"))
                        )) && (
                        <Column
                          field="usuarioNombre"
                          header="Reclutador"
                        ></Column>
                      )}
                    <Column
                      field="warning"
                      header="Alerta"
                      body={painWaring}
                    ></Column>
                    <Column
                      field="Validado"
                      header="Asistió"
                      body={asistenciaTemplate}
                      headerStyle={{ width: "3em" }}
                    ></Column>
                    <Column
                      field="Ausente"
                      header="Ausente"
                      body={ausenteTemplate}
                      headerStyle={{ width: "3em" }}
                    ></Column>
                    <Column
                      field="TransporteTW"
                      header="Transporte TW"
                      body={transporteTw}
                      headerStyle={{ width: "3em" }}
                    ></Column>
                  </DataTable>
                )}
              </AccordionTab>
            </Accordion>
          </div>

          <Toolbar
            className="overflow-x-auto"
            style={{ marginTop: "2rem" }}
            left={leftToolbarTemplateDataTableTwo2}
            right={""}
          ></Toolbar>
          <div className="accordionCandidates mr-3 my-4">
            <Accordion activeIndex={0}>
              <AccordionTab header="Postulantes no admitidos">
                {candidatesNoAdmit2 && (
                  <DataTable
                    id="tblAddCtx2"
                    value={candidatesNoAdmit2}
                    responsiveLayout="scroll"
                    emptyMessage="No hay registros."
                    paginator={candidatesNoAdmit2.length <= 15 ? false : true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport "
                    currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                    rows={15}
                    rowsPerPageOptions={[15, 30, 50]}
                    dataKey="busPerId"
                    scrollable
                    scrollHeight="600px"
                    filters={filterStatusNoAdmit2}
                  >
                    <Column field="fullname" header="Nombre" sortable></Column>
                    <Column field="wspFormat" header="Teléfono 1"></Column>
                    <Column
                      field="personaDniFormat"
                      header="Rut"
                      style={{ width: "130px" }}
                    ></Column>
                    <Column
                      field="fuenteOrg"
                      header="Fuente Reclutamiento"
                    ></Column>
                    <Column
                      field=""
                      header="Acciones"
                      body={actionsBlock}
                    ></Column>
                    <Column
                      field="status"
                      body={alertasDesing}
                      header="Alerta"
                      style={{ minWidth: "5rem" }}
                      exportable={false}
                    ></Column>
                    {/*<Column
           field="busquedaId"
           header="ID"
           body={paintId}
          ></Column>*/}
                    <Column
                      field="cdcCreateDt2"
                      header="Fecha postulación"
                      style={{ minWidth: "5rem" }}
                    ></Column>
                    {/*  <Column field="restaurar" header="Restaurar" body={restart} style={{ minWidth: '5rem' }} ></Column> */}
                  </DataTable>
                )}
              </AccordionTab>
            </Accordion>
          </div>

          <Dialog
            header="Motivo de descarte candidatos"
            visible={visiblePorCandidato}
            onHide={() => setVisiblePorCandidato(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterCandidate()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  value={inputModalCandidatos}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalCandidatos(e.target.value)}
                  maxLength={30}
                />
                {inputModalCandidatos.length >= 50 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 50 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Motivo de descarte cliente"
            visible={visiblePorCliente}
            onHide={() => setVisiblePorCliente(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterClient()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  value={inputModalClientes}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalClientes(e.target.value)}
                  maxLength={30}
                />
                {inputModalClientes.length >= 50 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 50 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Motivo de descarte Perfil"
            visible={visiblePorPerfil}
            onHide={() => setVisiblePorPerfil(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterProfile()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex flex-column justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  autoResize
                  value={inputModalPerfiles}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalPerfiles(e.target.value)}
                  maxLength={60}
                />
                {inputModalPerfiles.length >= 60 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 60 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <ModalError
            isModalVisible={visibleModalError}
            onClose={cerrarModalError}
            token={token}
          ></ModalError>

          <ModalChangeId
            isModalVisible={visibleModalChangeId}
            onClose={cerrarModalChangeId}
            onCloseSave={() => saveModalChangeId()}
            token={token}
            candidate={candidateModal}
            group={group}
          ></ModalChangeId>

          <ModalChangeGroup
            isModalVisible={visibleModalChangeGroup}
            onClose={cerrarModalChangeGroup}
            onCloseSave={() => saveModalChangeGroup()}
            token={token}
            candidate={candidateModal}
            group={group}
          ></ModalChangeGroup>

          <ModalDiscard
            isModalVisible={visibleModalDiscard}
            onClose={cerrarModalDiscard}
            onCloseSave={() => saveModalDiscard()}
            token={token}
            candidate={candidatosADescartar}
            group={group}
          ></ModalDiscard>

          <ModalBlocking
            isModalVisible={visibleModalBlocking}
            onClose={cerrarModalBlocking}
            onCloseSave={() => saveModalBloking()}
            token={token}
            candidate={candidatosABloquear}
            group={group}
          ></ModalBlocking>
          <Toast ref={toast}></Toast>

          <Dialog
            visible={visibleWarningInfo}
            footer={" "}
            style={{ width: "30vw" }}
            closable
            onHide={() => setVisibleWarningInfo(false)}
          >
            {warnings && warnings.length > 0 ? (
              <>
                <div className="flex alignIcon mb-5">
                  <span className=" material-symbols-outlined md-36 iconCYellow mx-2 cursor-pointer ">
                    warning
                  </span>
                  <label
                    className="ml-3 "
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "",
                      fontSize: "23px",
                      lineHeight: "26px",
                      alignItems: "center",
                      fontWeight: "600",
                      color: "#333333",
                    }}
                  >
                    Candidato con Restricciones:{" "}
                  </label>
                </div>

                {warnings.map((warning: any) => {
                  return (
                    <>
                      <p className="alignIcon">
                        <span className="material-symbols-outlined md-24 iconCYellow mx-2 cursor-pointer align-self-center align-items-center">
                          chevron_right
                        </span>
                        {/*
   {warning.excTipoDescripcion} {warning.clienteNombre} 
*/}
                        Descartado en ID {warning.busquedaId} favor no
                        considerar
                      </p>
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )}

            <br />
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default ConfirmTableComponentV2;
