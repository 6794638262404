import { useContext, useEffect, useState } from "react";
import ModalFiltro from "./Modals/ModalFiltro";
import IconFilter from "../../../ui/kit-svg/IconFilter";
import IconFilterOpen from "../../../ui/kit-svg/IconFilterOpen";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import IconDescargaMasiveGren from "../../../ui/kit-svg/IconDescargaMasiveGren";
import IconDescagaMasWhite from "../../../ui/kit-svg/IconDescagaMasWhite";
import IconStop from "../../../ui/kit-svg/IconStop";
import IconStopWhite from "../../../ui/kit-svg/IconStopWhite";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ModalDetener from "./Modals/ModalDetener";
import ModalConfSolicitud from "./Modals/ModalConfSolicitud";

const SeccionActionBlock = () => {
  const [excelType, setExcelType] = useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const [stoped, setStoped] = useState<boolean>(false);
  const [onHover, setOnHover] = useState(false);
  const [filterType, setFilterType] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const {
    token, 
    getToken, 
    BootstrapTooltip,
    disButttonBlockOnly,
    listCandidatoEnviadoContrato,
  } = useContext(ValidationKamContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listCandidatoEnviadoContrato.length > 0 ? true : false
  );
  const [openModalDetener, setOpenModalDetener] = useState<boolean>(false);
  const [openModalSolicitudPend, setOpenModalSolicitudPend] =
    useState<boolean>(false);

  useEffect(() => {
    setButtonEnabled(listCandidatoEnviadoContrato.length > 0 ? true : false);
  }, [listCandidatoEnviadoContrato]);

  useEffect(() => {
    setFilterType(openModalFiltro);
  }, [openModalFiltro]);

  const handleClickModalFilterClose = () => {
    setFilterType(false);
    setOpenModalFiltro(false);
  };

  const handleClickModalDetener = () => {
    setOpenModalDetener(true);
  };
  const handleClickSolicitudPend = () => {
    setOpenModalSolicitudPend(true);
  };

  return (
    <>
      <div className="content-actions contrato">
        <div className="content-enviar"></div>

        <div className="content-actions-masive">
          <BootstrapTooltip
            title="Actualizar Solicitud Pendiente "
            placement="bottom-end"
          >
            <button
              onClick={() => handleClickSolicitudPend()}
              className={`button-actions ${
                listCandidatoEnviadoContrato.some(
                  (l: any) => l.estadoContrato == "PEND_FIRMA_DIGITAL"
                )
                  ? "active-datos"
                  : "disabled"
              }`}
              disabled={
                listCandidatoEnviadoContrato.some(
                  (l: any) => l.estadoContrato !== "PEND_FIRMA_DIGITAL"
                ) || listCandidatoEnviadoContrato == ""
                  ? true
                  : false
              }
            >
              <AutorenewIcon />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip title="Detener Solicitud" placement="top-end">
            <button
              className={`button-actions ${
                buttonEnabled && disButttonBlockOnly
                  ? "active-block"
                  : "disabled"
              }`}
              disabled={buttonEnabled && disButttonBlockOnly ? false : true}
              onClick={() => handleClickModalDetener()}
              onMouseDown={() => {
                setStoped(true);
              }}
              onMouseUp={() => {
                setStoped(false);
              }}
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
            >
              {stoped || onHover ? (
                <IconStopWhite
                  disabled={buttonEnabled && disButttonBlockOnly ? false : true}
                />
              ) : (
                <IconStop
                  disabled={buttonEnabled && disButttonBlockOnly ? false : true}
                />
              )}
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip title="Descarga Nómina" placement="bottom-start">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-file" : "disabled"
              }`}
              disabled={buttonEnabled ? false : true}
              onMouseDown={() => {
                setExcelType(true);
              }}
              onMouseUp={() => {
                setExcelType(false);
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {excelType || hover ? (
                <IconDescagaMasWhite disabled={buttonEnabled ? false : true} />
              ) : (
                <IconDescargaMasiveGren
                  disabled={buttonEnabled ? false : true}
                />
              )}
            </button>
          </BootstrapTooltip>

          <button
            className={`button-actions ${"active-filter"}`}
            onClick={() => {
              setOpenModalFiltro(true);
              setFilterType(openModalFiltro);
            }}
          >
            {filterType ? <IconFilterOpen /> : <IconFilter />}
          </button>
        </div>
      </div>

      {openModalDetener && (
        <ModalDetener
          modalType="Detener"
          title="DETENER SOLICITUD DE CONTRATO"
          subTitle="Vas a cancelar la solicitud de contrato para los siguientes candidatos:"
          buttonBack="Volver"
          onChangeBack={() => {
            setOpenModalDetener(false), setStoped(false), setOnHover(false);
          }}
          modalStyle={""}
          list={listCandidatoEnviadoContrato}
        />
      )}

      {openModalSolicitudPend && (
        <ModalConfSolicitud
          modalStyle="confirm"
          modalType="actualiza_pendiente"
          title="Actualizar Solicitud Pendiente"
          subTitle="Estás por confirmar la actualización del certificado digital de las siguientes solicitudes:  "
          list={listCandidatoEnviadoContrato}
          onChangeBack={() => setOpenModalSolicitudPend(false)}
          onChangeGo={() => setOpenModalSolicitudPend(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
        />
      )}

      {openModalFiltro && (
        <ModalFiltro
          title="Filtro"
          buttonGoEvent={handleClickModalFilterClose}
          token={token}
        />
      )}
    </>
  );
};

export default SeccionActionBlock;
