import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
       
       <path d="M7.67598 17L13.7354 10.9406L12.7947 10L7.67598 15.1187L5.0291 12.4719L4.11035 13.3906L7.67598 17Z" fill="#06A502"/>
<path d="M1.68339 20.9738C1.26391 20.9738 0.896868 20.8165 0.582262 20.5019C0.267655 20.1873 0.110352 19.8202 0.110352 19.4007V1.57303C0.110352 1.15356 0.267655 0.786517 0.582262 0.47191C0.896868 0.157303 1.26391 0 1.68339 0H10.5972L16.8894 6.29213V21H15.3163V7.34082H9.54855V1.57303H1.68339V19.4007H15.3163V20.9738H1.68339Z" fill="#06A502"/>

       
   
   
    </svg>
  );
};


