import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import { PROFILE_ADMIN_SEL } from "../../../../../diccionary/Diccionary";
import IconSolicitarCandidato from "../../../../ui/icons-seleccion/IconSolicitarCandidato";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalSolicitarRegistro from "../Modal/ModalSolicitarRegistro";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  solicitudes: any;
  setSolicitudes: Function;
  handleClickSolicitarCandidato: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    solicitudes,
    setSolicitudes,
    handleClickSolicitarCandidato,
  } = props;
  const { profile } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [isOpenModalSolicitar, setIsOpenModalSolicitar] =
    useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <section className="container-actions">
        <section className="content-actions-masive">
          {PROFILE_ADMIN_SEL.includes(profile) && (
            <>
              <Tooltip text="Solicitar Registro">
                <button
                  className={`button-actions ${
                    buttonEnabled ? "active-solicitar-candidato" : "disabled"
                  }`}
                  disabled={!buttonEnabled}
                  onClick={() => setIsOpenModalSolicitar(true)}
                >
                  <IconSolicitarCandidato
                    fill={"#E3A000"}
                    disabled={!buttonEnabled}
                  />
                </button>
              </Tooltip>
            </>
          )}

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </section>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["ENVIADA", "RECHAZADA"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
          <InputFiltro
            layer="FECHA SOLICITUD"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaSolicitud"
          />
        </FiltroGenerico>
      )}

      {isOpenModalSolicitar && (
        <ModalGenerico
          width="w-70"
          title=""
          subtitle="Selecciona la fuente de reclutamiento y luego el archivo correspondiente."
          onChangeBack={() => setIsOpenModalSolicitar(false)}
          buttonBackText="Cerrar"
        >
          <ModalSolicitarRegistro
            rut={listadoSeleccionado}
            solicitudes={solicitudes}
            setSolicitudes={setSolicitudes}
            onChangeBack={() => setIsOpenModalSolicitar(false)}
            handleClickSolicitarCandidato={handleClickSolicitarCandidato}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
