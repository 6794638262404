import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.81833 6.07667V0.5H1.605C1.06833 0.5 0.625 0.943333 0.625 1.48V20.52C0.625 21.0683 1.06833 21.5 1.605 21.5H15.395C15.9433 21.5 16.375 21.0567 16.375 20.52V7.05667H10.7983C10.2617 7.05667 9.81833 6.61333 9.81833 6.07667ZM13.68 12.295C13.75 12.5867 13.5633 12.89 13.26 12.96C12.9683 13.03 12.665 12.8433 12.595 12.54C12.0817 10.3117 9.85333 8.91167 7.625 9.425C5.39667 9.93833 3.99667 12.1667 4.51 14.395C5.02333 16.6233 7.25167 18.0233 9.48 17.51C9.77167 17.44 10.075 17.6267 10.145 17.93C10.215 18.2333 10.0283 18.525 9.725 18.595C6.90167 19.2483 4.07833 17.475 3.43667 14.6517C2.78333 11.8283 4.55667 9.005 7.38 8.36333C10.2033 7.71 13.0267 9.48333 13.6683 12.3067L13.68 12.295ZM9.66667 10.9883V13.4733C9.66667 13.6483 9.585 13.8233 9.43333 13.9283L7.49667 15.305C7.25167 15.48 6.90167 15.4217 6.72667 15.1767C6.55167 14.9317 6.61 14.5817 6.855 14.4067L8.55833 13.1933V10.9883C8.55833 10.685 8.80333 10.44 9.10667 10.44C9.41 10.44 9.655 10.685 9.655 10.9883H9.66667Z" fill="#FF4900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.0833 4.805L12.07 0.791667C11.8833 0.605 11.6383 0.5 11.37 0.5H11.125V5.75H16.375V5.505C16.375 5.24833 16.27 4.99167 16.0833 4.81667V4.805Z" fill="#FF4900"/>
    </svg>
  );
};
