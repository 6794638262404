interface IconBajaConf {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconBajaConf) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "21"}`}
        height={`${height ? height : "22"}`}
        viewBox="0 0 21 22"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       
     <path d="M2.11063 21.4738C1.69116 21.4738 1.32411 21.3165 1.00951 21.0019C0.694901 20.6873 0.537598 20.3202 0.537598 19.9007V2.07303C0.537598 1.65356 0.694901 1.28652 1.00951 0.97191C1.32411 0.657303 1.69116 0.5 2.11063 0.5H11.0245L17.3166 6.79213V13.8708H15.7436V7.84082H9.9758V2.07303H2.11063V19.9007H12.8597V21.4738H2.11063ZM19.3616 21.5L16.0058 18.1442V21.4213H14.4327V15.4438H20.4103V17.0169H17.1069L20.4627 20.3727L19.3616 21.5Z" 
     fill="#BE2A2A"/> 
      </svg>

    );
  };
