import { useState, useRef, useEffect } from "react";
import {
  ICategoriesValidationInput,
  IInputFile,
} from "../../../interfaces/IInput";
import ErrorIcon from "@mui/icons-material/Error";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default (props: IInputFile) => {
  const {
    placeholder,
    placeholderImg,
    layer,
    required,
    summary,
    multiple,
    accept,
    errorPosition,
    maxSizeMb,
    onPermaValues,
    onValueChange,
    onPropertyChange,
    enlaceDescarga,
    formatosAceptado,
    leyenda,
    requerido,
    datoRequerido,
    disabled,
    iconFile,
    error,
  } = props;
  const refFile = useRef<any>();

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(
    undefined
  );
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [file, setFile] = useState<any[]>([]);
  const [nameFile, setNameFile] = useState<string>(
    enlaceDescarga ? placeholder : "Seleccione Archivo"
  );

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, enlaceDescarga]);

  const handleChangeEventError = (event: any) => {
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";

    const files: any[] = event.files;

    if (files.length === 1) {
      const fileSizeInMB = files[0].size;
      const maxSize = maxSizeMb * 1024 * 1024;
      if (fileSizeInMB <= maxSize) {
        setFile(files);
      } else {
        setMessageError(
          `El archivo excede el tamaño máximo permitido. ${maxSizeMb}MG`
        );
        trigger = true;
        eventValidation = "error";
      }
      setNameFile(files[0].name);
    } else {
      setFile(files);
    }

    const allowedExtensions = /(\.pdf|\.xlsx|\.txt)$/i;
    if (files.length > 0) {
      const fileName = files[0].name;
      if (!allowedExtensions.test(fileName)) {
        setMessageError(
          `Solo se permiten archivos con extension: ${formatosAceptado.flat()}`
        );
        trigger = true;
        eventValidation = "error";
      }
    }

    if (datoRequerido) {
      if (requerido) {
        triggerRequired = files ? false : true;
        eventValidation = files ? "success" : "caution";
      }
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);
    setTriggerRequerido(triggerRequired);
    setNameFile(files.length === 1 ? files[0].name : "Multiples archivos");

    if (onValueChange && onPropertyChange && onPermaValues) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: files,
        valid: trigger,
      };

      onValueChange(permaValues);
    }
  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="layer-document document">
          {layer} {summary && <i>{summary}</i>}
        </label>
        {leyenda && (
          <span className="leyenda-documento">
            {`(${
              typeof leyenda === "string" &&
              leyenda.charAt(0) + leyenda.toLowerCase().slice(1)
            })`}
          </span>
        )}
      </div>
      <div className="custom-file-input">
        <input
          ref={refFile}
          type="file"
          placeholder={placeholder}
          className={
            error ? "input-error" : categoriesValidation[eventValidation]
          }
          required={required}
          disabled={disabled}
          accept={accept ? accept : ".pdf"}
          onChange={(event) => {
            handleChangeEventError(event.target);
          }}
          multiple={multiple}
        />
        <label
          onClick={() => refFile.current.click()}
          className={`${
            disabled
              ? `label-file disabled  ${placeholderImg ? "input-img" : ""}`
              : `label-file ${placeholderImg ? "input-img" : ""} ${
                  error ? "input-error" : categoriesValidation[eventValidation]
                }`
          }`}
          style={{
            justifyContent: "space-between",
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {nameFile}
        </label>
        {iconFile && (
          <AttachmentIcon
            style={{
              display: "flex",
              position: "absolute",
              right: "10px",
              margin: "0",
              fontSize: "17px",
              transform: "rotate(-45deg)",
            }}
          />
        )}
        {/* <div className="w-100">
          <label
            onClick={() => refFile.current.click()}
            className={`${
              disabled
                ? `label-file disabled  ${placeholderImg ? "input-img" : ""}`
                : `label-file ${placeholderImg ? "input-img" : ""} ${
                  error ? "input-error" : categoriesValidation[eventValidation]
                  }`
            }`}
            style={{ justifyContent: "space-between" }}
          >
            {nameFile}
            {iconFile && (
              <AttachmentIcon
                style={{
                  display: "flex",
                  margin: "0",
                  fontSize: "17px",
                  transform: "rotate(-45deg)",
                }}
              />
            )}
          </label>
        </div> */}
      </div>
      {error && !disabled && (
        <span className="layer-error">Doc. no guardado, vuelva a intentar</span>
      )}
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error">{messageError}</span>
      )}
      {triggerRequerido &&
        errorPosition === "down" &&
        !disabled &&
        eventValidation !== "success" && (
          <span className="layer-caution down">
            <ErrorIcon />
            Documento Requerido
          </span>
        )}
      {!triggerRequerido &&
        errorPosition === "down" &&
        !disabled &&
        eventValidation == "success" && (
          <span className="layer-success down">
            <CheckCircleOutlineIcon />
            Carga Correcta
          </span>
        )}
    </div>
  );
};
