import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import { formatDateToDDMMYY } from "../../../../../functions/kit-mdtw/dates";
import AccionMasive from "./AccionMasiva";
import { useParams } from "react-router-dom";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import ModalDescartarBloquear from "../Modals/ModalDescartarBloquear";
import ModalConfirm from "../Modals/ModalConfirm";
import MediaQuery from "../../../../ui-responsive/MediaQuery";
import ModalFicha from "../Modals/ModalFicha";
import { TYPE_LIST } from "../../../../../diccionary/Diccionary";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import IconCandidatoValidado from "../../../../ui/kit-svg/icon-validacionkam/IconCandidatoValidado";

interface ITablaValidacionCliente {
  dataBloqueo: string[];
  dataDescarte: string[];
}

const TablaValidacionCliente = (props: ITablaValidacionCliente) => {
  const { dataBloqueo, dataDescarte } = props;
  const { token, listaCandidatoPendiente, getListCandidatePending } =
    useContext(ValidationKamContext);
  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [openModalDescartar, setOpenModalDescartar] = useState<boolean>(false);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [openModalBloquear, setOpenModalBloquear] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [arraySeleccionado, setArraySeleccionado] = useState<any[]>([]);
  const [listDescartesBloqueo, setListDescarteBloqueo] = useState<any[]>([]);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridCandidatoPendiente, setGridCandidatoPendiente] = useState<
    IListaCandidatos[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filterTableValidClient"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    nombre: [],
    rut: [],
    areaNegocio: [],
    procesoId: [],
    fechaEnvio: [],
    asiste: [],
  });

  useEffect(() => {
    listRefresh();
  }, [token]);

  const listRefresh = () => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getListCandidatePending({
          idCliente,
          token,
          filtros: JSON.parse(copyStorage),
        });
      } else {
        getListCandidatePending({
          idCliente,
          token,
          filtros: formFilter,
        });
      }
    }
  };

  useEffect(() => {
    if (listaCandidatoPendiente) {
      let arrayGridFilter = [...listaCandidatoPendiente];
      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoPendiente(arrayGridFilter);
    }
  }, [listaCandidatoPendiente, range]);

  const clearListDescarteBloqueo = () => {
    setListDescarteBloqueo([]);
  };

  return (
    <>
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div></div>
        <AccionMasive
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
        />
      </section>
      <div className="content-header-table">
        <span>Últimos Candidatos Validados</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th>Estado</th>
              <th>Nombre</th>
              <th>Rut</th>
              {width <= sm ? (
                <>
                  <th>
                    Fecha <br></br>Validación TW
                  </th>
                </>
              ) : (
                <>
                  <th>Teléfono</th>
                  <th>
                    Área de <br></br>Negocio
                  </th>
                  <th>ID</th>
                  <th>
                    Fecha <br></br>Validación TW
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoPendiente &&
              gridCandidatoPendiente.map((list: IListaCandidatos) => {
                const {
                  busPerId,
                  busquedaId,
                  areaNegocioCod,
                  personaDni,
                  primerNombre,
                  primerApellido,
                  confirmadoFecha,
                  busPerEstado,
                  wsp,
                } = list;
                return (
                  <>
                    <tr key={busPerId}>
                      <td>
                        <section className="content-state">
                          <div className="container-state" >
                            {busPerEstado == "PEND_SOL_CONTR" && (
                              <>
                                <IconCandidatoValidado fill="#06A502" />
                                <div className="content-state-text">
                                  <label className="title registro-verde">
                                    Candidato Validado
                                  </label>
                                  <label className="message"></label>
                                </div>
                              </>
                            )}
                          </div>
                        </section>
                      </td>
                      <td>
                        {primerNombre} {primerApellido}
                      </td>
                      <td>{personaDni}</td>
                      {width <= sm ? (
                        <>
                          <td>
                            <td>
                              {formatDateToDDMMYY({
                                date: confirmadoFecha,
                                format: "/",
                              })}
                            </td>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{wsp}</td>
                          <td>{areaNegocioCod}</td>
                          <td>{busquedaId}</td>
                          <td>
                            {formatDateToDDMMYY({
                              date: confirmadoFecha,
                              format: "/",
                            })}
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalFicha && (
        <ModalFicha
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setOpenModalFicha(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          rut={rutCandidate}
          areaContrato={false}
          setOpenModalFicha={setOpenModalFicha}
        />
      )}

      {openModalRestaurar && (
        <ModalConfirm
          modalStyle="confirm"
          modalType="restaurar"
          title="Restaurar Candidato"
          subTitle="¿Estas seguro que deseas restaurar al candidato?"
          message="Al aceptar, el candidato ya no se encontrará disponible en la lista de candidatos para validación del cliente"
          onChangeBack={() => setOpenModalRestaurar(false)}
          onChangeGo={() => setOpenModalRestaurar(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
          list={arraySeleccionado}
          listRefresh={listRefresh}
          listadoSeleccionado={listadoSeleccionado}
        />
      )}

      {openModalDescartar && (
        <ModalDescartarBloquear
        typeExclusion="descarte"
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_validacion}
          title="DESCARTE DE CANDIDATOS"
          subTitle="Estás por descartar la siguiente selección de candidatos:"
          message="Seleccione el motivo del descarte:"
          onChangeBack={() => setOpenModalDescartar(false)}
          onChangeGo={() => setOpenModalDescartar(false)}
          buttonBack="Cancelar"
          buttonGo="Descartar"
          exclusiones={dataDescarte}
          list={listDescartesBloqueo}
          clearList={clearListDescarteBloqueo}
          action="discart"
          listRefresh={listRefresh}
          listadoSeleccionado={listadoSeleccionado}
        />
      )}

      {openModalBloquear && (
        <ModalDescartarBloquear
        typeExclusion="bloqueo"
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_validacion}
          title="BLOQUEO DE CANDIDATOS"
          subTitle="Estás por bloquear la siguiente selección de candidatos:"
          message="Seleccione el motivo del bloqueo:"
          onChangeBack={() => setOpenModalBloquear(false)}
          onChangeGo={() => setOpenModalBloquear(false)}
          buttonBack="Cancelar"
          buttonGo="Bloquear"
          exclusiones={dataBloqueo}
          list={listDescartesBloqueo}
          clearList={clearListDescarteBloqueo}
          action="block"
          listRefresh={listRefresh}
          listadoSeleccionado={listadoSeleccionado}
        />
      )}
    </>
  );
};

export default TablaValidacionCliente;
