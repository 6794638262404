import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ActionMassive from "./ActionMassive";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import OperacionContratacionContext from "../../../../../context/Operaciones/OperacionContratacionContext";
import { IBajasConfirmadas, Params } from "../../../../../interfaces/IOperacionesContratacion";
import IconSolicitudAceptada from "../../../../ui/kit-svg/icon-bajas-confirmadas/IconSolicitudAceptada";
import IconDetenerSolicitud from "../../../../ui/kit-svg/icon-bajas-confirmadas/IconDetenerSolicitud";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import DetenerSolicitud from "../../Modals/DetenerSolicitud";
import IconProcesando from "../../../../ui/kit-svg/icon-bajas-confirmadas/IconProcesando";
import IconSolicitudRechazada from "../../../../ui/kit-svg/IconSolicitudRechazada";
import ModalNoProcesado from "../../../../ui/kit-modal/noProcesado/ModalNoProcesado";
import { useParams } from "react-router-dom";

const TablaSinCalcular = () => {
  const {
    token,
    listaNoProcesados,
    listaBajasConfirmadas,
    getBajasConfirmadas,
    postDetenerBaja,
  } = useContext(OperacionContratacionContext);
  const { idCliente } = useParams<Params>();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [isOpenModalDetenerSolicitud, setIsOpenModalDetenerSolicitud] =
    useState<boolean>(false);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridBajasSinProcesar, setGridBajasSinProcesar] = useState<
    IBajasConfirmadas[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoPendiente"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [buttonDetener, setButtonDetener] = useState<boolean>(false);
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);
  const [isErrorPost, setIsErrorPost] = useState<boolean>(false);
  const [selectCurrent, setSelectCurrent] = useState<any>([]);
  const [reasonAll, setReasonAll] = useState<any>({
    motivoAll: { value: "" },
  });
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    ficha: [],
    codCargo: [],
    areaNegocio: [],
    fechaTerminoContrato: [],
    fechaConfirmacionBaja: [],
    division: [],
    cliente:[idCliente]
  });

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getBajasConfirmadas({
          token,
          filtros: JSON.parse(copyStorage),
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        getBajasConfirmadas({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (selectCurrent) {
      const hayTextoEnValue = Object.values(selectCurrent).some(
        (item: any) => item.value && item.value.trim() !== ""
      );
      setButtonDetener(hayTextoEnValue);
    }
  }, [selectCurrent]);

  useEffect(() => {
    if (listaBajasConfirmadas) {
      const arrayList = listaBajasConfirmadas.map(
        (item: IBajasConfirmadas) => item.divisionId + "-" + item.ficha
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaBajasConfirmadas]);

  useEffect(() => {
    if (listaBajasConfirmadas) {
      let arrayGridFilter = [...listaBajasConfirmadas];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridBajasSinProcesar(arrayGridFilter);
    }
  }, [listaBajasConfirmadas, range]);

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { divisionId, ficha } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];

    if (!arrayList.includes(divisionId + "-" + ficha)) {
      arrayList.push(divisionId + "-" + ficha);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === divisionId + "-" + ficha
      );
      arrayList.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter((item: any) => !listadoSeleccionado.includes(item))
        .map((item: any) => arrayListado.push(item));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
  };

  const handleClickDetenerSolicitud = (values: any): void => {
    const { divisionId, ficha } = values;
    setCandidates([divisionId + "-" + ficha]);
    setIsOpenModalDetenerSolicitud(true);
  };

  const handleClickDetener = async () => {
    if (token) {
      const arregloTransformado = Object.keys(selectCurrent).map(
        (clave: any) => {
          const numeroFicha = clave;
          const motivo = selectCurrent[clave].value;

          return {
            ficha: numeroFicha,
            motivo: motivo,
          };
        }
      );
      try {
        await postDetenerBaja({
          token,
          contratos: arregloTransformado,
          setIsErrorPost,
          setIsLoadingPost,
          setIsOpenModalNoProcesado,
        });

        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          JSON.parse(copyStorage);
          getBajasConfirmadas({
            token,
            filtros: JSON.parse(copyStorage),
            setIsErrorTable,
            setIsLoadingTable,
          });
        } else {
          getBajasConfirmadas({
            token,
            filtros: formFilter,
            setIsErrorTable,
            setIsLoadingTable,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <section className="content-seleccion-multiple action-in-line">
        <div className="content-multiple">
          <button onClick={handleClickAddAll} className="button-multiple">
            {listadoSeleccionado.length == currentCandidates.length &&
            listadoSeleccionado.length > 0 ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </button>
          <label className="title-multiple">
            Seleccionar Todo{" "}
            <i className="selects">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
        />
      </section>

      <section className="content-header-table">
        <label className="title-header-table">
          Bajas Confirmadas sin Procesar
        </label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros"></th>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Ficha</th>
                      <th className="th-table-registros">
                        División <br></br>TW
                      </th>
                      <th className="th-table-registros">
                        Código <br></br>Cargo
                      </th>
                      <th className="th-table-registros">
                        Área de <br></br>Negocio
                      </th>
                      <th className="th-table-registros">
                        Término de <br></br>Contrato
                      </th>
                      <th className="th-table-registros">
                        Confirmación <br></br>Baja
                      </th>
                      <th className="th-table-registros">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridBajasSinProcesar &&
                      gridBajasSinProcesar.map((item: IBajasConfirmadas) => {
                        const {
                          ficha,
                          primerNombre,
                          primerApellido,
                          divisionId,
                          division,
                          codCargo,
                          areaNegocioCod,
                          estadoSolicitud,
                          fechaTerminoContrato,
                          fechaConfirmacionBaja,
                        } = item;
                        return (
                          <>
                            <tr
                              key={divisionId + "-" + ficha}
                              className={`tr-table-registros ${
                                listadoSeleccionado.includes(
                                  divisionId + "-" + ficha
                                ) && "checkeado"
                              }`}
                            >
                              <td className="td-table-registros">
                                <button
                                  className="button-select"
                                  onClick={() =>
                                    handleClickAddOrRemoveChoise({
                                      divisionId,
                                      ficha,
                                    })
                                  }
                                >
                                  {listadoSeleccionado.includes(
                                    divisionId + "-" + ficha
                                  ) ? (
                                    <CheckBoxIcon />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon />
                                  )}
                                </button>
                              </td>
                              <td className="td-table-registros">
                                <section className="content-state">
                                  {estadoSolicitud == "PROCESANDO" && (
                                    <>
                                      <IconProcesando
                                        fill="#E3A000"
                                        className="content-icon"
                                      />
                                      <div className="content-state-text">
                                        <label className="message">
                                          Procesando en <br></br>Sistema
                                        </label>
                                      </div>
                                    </>
                                  )}
                                  {estadoSolicitud == "APROBADA" && (
                                    <>
                                      <IconSolicitudAceptada
                                        fill="#06A502"
                                        className="content-icon"
                                      />
                                      <div className="content-state-text">
                                        <label className="message">
                                          Solicitud aceptada, <br></br>pendiente
                                          calcular
                                        </label>
                                      </div>
                                    </>
                                  )}
                                  {estadoSolicitud == "RECHAZADA" && (
                                    <>
                                      <IconSolicitudRechazada
                                        fill="#BE2A2A"
                                        className="content-icon"
                                      />
                                      <div className="content-state-text">
                                        <label className="message">
                                          Solicitud <br></br>Rechazada
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </section>
                              </td>
                              <td className="td-table-registros">
                                {primerNombre} {primerApellido}
                              </td>
                              <td className="td-table-registros">{ficha}</td>
                              <td className="td-table-registros">{division}</td>
                              <td className="td-table-registros">{codCargo}</td>
                              <td className="td-table-registros">
                                {areaNegocioCod}
                              </td>
                              <td className="td-table-registros">
                                {formatDateToDDMMAAAA({
                                  date: fechaTerminoContrato,
                                  format: "/",
                                })}
                              </td>
                              <td className="td-table-registros">
                                {formatDateToDDMMAAAA({
                                  date: fechaConfirmacionBaja,
                                  format: "/",
                                })}
                              </td>
                              <td className="td-table-registros">
                                <section className="content-icon-table">
                                  <Tooltip text="Detener Solicitud">
                                    <button
                                      onClick={() =>
                                        handleClickDetenerSolicitud({
                                          divisionId,
                                          ficha,
                                        })
                                      }
                                      className={`button-content ${
                                        estadoSolicitud === "PROCESANDO"
                                          ? "disabled svg disabled"
                                          : ""
                                      }`}
                                      disabled={
                                        estadoSolicitud === "PROCESANDO"
                                      }
                                    >
                                      <IconDetenerSolicitud
                                        fill="#BE2A2A"
                                        disabled={
                                          estadoSolicitud === "PROCESANDO"
                                        }
                                      />
                                    </button>
                                  </Tooltip>
                                </section>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {isOpenModalDetenerSolicitud && (
        <ModalGenerico
          title="Detener Solicitud de Baja Confirmada"
          width="w-80"
          buttonBackText="Cancelar"
          buttonGoText="Detener Baja"
          buttonGoStyle="delete"
          buttonGoDisable={buttonDetener}
          onChangeBack={() => {
            setIsOpenModalDetenerSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickDetener();
            setIsOpenModalDetenerSolicitud(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <DetenerSolicitud
            token={token}
            list={candidates}
            listData={listaBajasConfirmadas}
            reasonAll={reasonAll}
            setReasonAll={setReasonAll}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Solicitud NO procesada"
          iconTitle="danger"
          subtitle="La solicitud para los siguientes trabajadores no pudo ser procesada:"
          width="w-80"
          buttonBackText="Cerrar"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalNoProcesado list={listaNoProcesados} />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaSinCalcular;
