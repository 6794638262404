import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import ContratosContext from "../../../../../../context/Contratos/ContratosContext";

interface ISectionActive {
  objetoFilterGeneral?: any;
  width: string;
}

const EstadoSolicitudContrato = (props: ISectionActive) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const { objetoFilterGeneral, width } = props;

  const { getDayOfWeek } = useContext(OperacionContratacionContext);
  const { token, listaEstadoSolicitud, postResumenEstadoSolicitud } =
    useContext(ContratosContext);

  const [dataChart, setDataChart] = useState<[]>([]);
  const [diasChart, setDiasChart] = useState<[]>([]);
  const [profile, setProfile] = useState<string | null>("");

  useEffect(() => {
    if (token) {
      const profileSession = window.sessionStorage.getItem("roles");
      if (profileSession !== null) {
        setProfile(JSON.parse(profileSession));
      }
    }
  }, [token]);

  useEffect(() => {
    if (token && profile) {
      const objeto = {
        contrato: true,
        renovaciones: false,
        filtros: {
          clientes: objetoFilterGeneral?.cliente[0],
          areaNegocio: objetoFilterGeneral?.areaNegocio,
          analista: objetoFilterGeneral && objetoFilterGeneral?.analista,
          periodo: {},
        },
      };

      postResumenEstadoSolicitud({ token, filtros: objeto });
    }
  }, [
    token,
    profile,
    localStorage.getItem("filterGeneralDashboard"),
    objetoFilterGeneral,
  ]);

  useEffect(() => {
    if (listaEstadoSolicitud && listaEstadoSolicitud?.ingresos) {
      const dataChart = listaEstadoSolicitud?.ingresos.map(
        (ingreso: { total: any }) => ingreso
      );
      setDataChart(dataChart.reverse());

      const dias = listaEstadoSolicitud?.ingresos.map(
        (ingreso: { fecha: any }) => {
          const dia = getDayOfWeek(ingreso.fecha);
          if (dia === "MIÉRCOLES" || dia === "MARTES") {
            return dia.slice(0, 2);
          } else {
            return dia.slice(0, 1);
          }
        }
      );

      setDiasChart(dias.reverse());
    }
  }, [listaEstadoSolicitud]);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        maxWidth: 122,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          // padding: 25,
          font: { size: 11, textWrap: "baseline" },
        },
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Mostrar líneas de la escala en el eje X
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true, // Mostrar líneas de la escala en el eje X
          color: "#D4D4D4", // Color de las líneas de la escala
          lineWidth: 1, // Grosor de las líneas de la escala
          borderWidth: 2,
          borderDash: [1, 5],
        },
      },
    },
  };

  const labels = diasChart;
  const data = {
    labels,
    datasets: [
      {
        label: "PENDIENTE REVISIÓN",
        data: dataChart.map((item: any) => item.pendienteRevision),
        backgroundColor: "#FFCA4D",
        barThickness: 25,
      },
      {
        label: "PENDIENTE ENVÍO A VALIDACIÓN",
        data: dataChart.map((item: any) => item.pendienteEnvioValidacion),
        backgroundColor: "#FF4DAB",
        barThickness: 25,
      },
      {
        label: "RECHAZADAS",
        data: dataChart.map((item: any) => item.rechazados),
        backgroundColor: "#FFAC99",
        barThickness: 25,
      },
    ],
  };

  return (
    <>
      {listaEstadoSolicitud && (
        <>
          <div className={`container-table-dashboard ${width} responsiveChart`}>
            <div className="content-title">
              <label className="title-table-dashboard">
                ESTADO SOLICITUD DE CONTRATOS
              </label>
            </div>
            <Bar options={options} data={data} height={250} />
          </div>
        </>
      )}
    </>
  );
};

export default EstadoSolicitudContrato;
