import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import {
  IDocumentoCandidate,
  IDocumentoCandidato,
  IDocumentoRegistroCandidato,
  ITipoDocCandidate,
} from "../../../../../interfaces/ISeleccion";
import ListadoDocumento from "./ListadoDocumento";
import Dropzone from "./Dropzone";
import { v4 as uuidv4 } from "uuid";
import ViewDocument from "./componentes/ViewDocument";
import useToast from "../../toasts/useToast";
interface IAreaDocumentoMasive {
  list?: any[];
  formUpDocument: IDocumentoCandidate[];
  setFormUpDocument: Function;
  formDocImage: IDocumentoCandidate[];
  setFormDocImage: Function;
  formCandidate: any;
  requerido?: boolean;
  hiddenSection?: boolean | undefined;
  isLoading?: boolean;
}

export default (props: IAreaDocumentoMasive) => {
  const {
    formUpDocument,
    setFormUpDocument,
    formCandidate,
    hiddenSection,
    isLoading,
    formDocImage,
    setFormDocImage,
  } = props;
  const {
    listDocument,
    token,
    userId,
    deleteDocumentCandidate,
    toasts,
    createToast,
  } = useContext(SeleccionContext);
  const [hidden, setHidden] = useState<boolean>(hiddenSection ?? false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<
    boolean | undefined
  >(undefined);
  const [tipoDocumentos, setTipoDocumentos] = useState<ITipoDocCandidate[]>([]);
  const [tipoDocumentosReales, setTipoDocumentosReales] = useState<
    ITipoDocCandidate[]
  >([]);
  const [files, setFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<any[]>([]);
  const [selectDocument, setSelectDocument] = useState<string | undefined>(
    undefined
  );
  const [documentosPorFormato, setDocumentosPorFormato] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (listDocument.length > 0) {
      let formOk: IDocumentoCandidate[] = [...formUpDocument];
      listDocument.forEach((iter: IDocumentoRegistroCandidato) => {
        const { documentos, descripcion } = iter;
        setDocumentosPorFormato((prev) => [...prev, descripcion]);
        let docArray: any = [{ descripcion, documentos }];
        docArray.forEach((doc: any) => {
          const { documentos: listDoc, descripcion } = doc;
          listDoc.forEach((element: IDocumentoCandidato) => {
            const {
              nombreOriginal,
              enlace,
              nombreDocumento,
              documentoId,
              fechaExpiracion,
              expira,
            } = element;
            if (enlace) {
              formOk.push({
                id: uuidv4(),
                nombreOriginal: nombreOriginal,
                formato: descripcion,
                file: { value: enlace, valid: undefined },
                tipoDocumentos: {
                  value: { name: nombreDocumento, code: documentoId },
                  valid: undefined,
                },
                fechaExpiracion: {
                  value: fechaExpiracion,
                  valid: !!fechaExpiracion,
                },
                expira,
                newFile: false,
                estado: "CARGADO",
              });
            }
          });
        });
      });
      setFormDocImage(formOk);
      setFormUpDocument(formOk);
    }
  }, [listDocument]);

  useEffect(() => {
    if (listDocument.length > 0) {
      const nuevosDocumentos: ITipoDocCandidate[] = [];
      listDocument.forEach((seccion: IDocumentoRegistroCandidato) => {
        const { documentos, descripcion } = seccion;
        documentos.forEach((doc: IDocumentoCandidato) => {
          nuevosDocumentos.push({
            code: doc.documentoId,
            name: doc.nombreDocumento,
            expira: doc.expira,
            formato: descripcion,
            ocupado: !!doc.enlace,
          });
        });
      });
      setTipoDocumentos(nuevosDocumentos);
      setTipoDocumentosReales(nuevosDocumentos);
    }
  }, [listDocument]);

  useEffect(() => {
    if (files.length > 0) {
      const formDuplicate: IDocumentoCandidate[] = [...formUpDocument];
      let formulario: any = files.map((file: any) => {
        const { name } = file;
        return {
          id: uuidv4(),
          formato: "DOCUMENTO NUEVO", // CAMBIAR CUANDO ESTE LISTO EL TIPODOCUMENTO
          nombreOriginal: name,
          file: { value: file, valid: undefined },
          tipoDocumentos: { value: undefined, valid: undefined },
          fechaExpiracion: { value: undefined, valid: undefined },
          expira: undefined,
          newFile: true,
          estado: "NUEVO",
        };
      });

      formulario.forEach((obj: any) => {
        formDuplicate[`${formDuplicate.length}`] = obj;
      });
      setFiles([]);
      setFormUpDocument(formDuplicate);
    }
  }, [files]);

  useEffect(() => {
    if (tipoDocumentos.length > 0) {
      const docActive = formUpDocument.map(
        (item: IDocumentoCandidate) => item.tipoDocumentos?.value?.code
      );
      const docFilter = tipoDocumentosReales.filter(
        (doc: any) => !docActive.includes(doc.code)
      );
      setTipoDocumentos(docFilter);
    }
  }, [formUpDocument]);

  useEffect(() => {
    if (formUpDocument) {
      setFormUpDocument((prev: IDocumentoCandidate[]) => prev);
    }
  }, [formUpDocument]);

  useEffect(() => {
    if (invalidFiles.length > 0) {
      createToast({
        title: "Problema con Documento",
        message: `El documento "${invalidFiles[0]}" no es PDF o su tamaño es mayor a 10MB`,
        type: "warning",
      });
    }
  }, [invalidFiles]);

  // useEffect(() => {
  //   console.log({ formUpDocument });
  // }, [formUpDocument]);

  const deleteDocument = (id: string, documentoId: string): void => {
    if (id) {
      deleteDocumentCandidate({
        id: id,
        documentos: [documentoId],
        token,
        personaId: formCandidate.personaId?.value ?? "",
        formUpDocument,
        setFormUpDocument,
        setIsLoading: setIsLoadingDocument,
      });
    }
  };

  const handleClickHidden = (): void => {
    setHidden(!hidden);
  };

  return (
    <>
      {toasts}
      {isLoadingDocument ? (
        <h3>Cargando Documentos...</h3>
      ) : (
        <>
          <section
            className={`seccion-title`}
            onClick={() => handleClickHidden()}
          >
            <span className="span-title">ÁREA DOCUMENTOS</span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </section>
          {hidden && <span className="linea-separador-titulo"></span>}
          <section className={`project-table ${hidden ? "disabled" : ""}`}>
            <Dropzone
              files={files}
              setFiles={setFiles}
              invalidFiles={invalidFiles}
              setInvalidFiles={setInvalidFiles}
            />
            {formUpDocument.filter((item: any) => item.newFile).length > 0 && (
              <div className="content-subtitle">
                <div>
                  <p className="title">ARCHIVOS CARGADOS</p>
                </div>
                <span className="linea"></span>
              </div>
            )}
            {formUpDocument.some(
              (objeto: IDocumentoCandidate) =>
                objeto.newFile && objeto.estado === "NUEVO"
            ) &&
              formUpDocument
                .filter((item: any) => item.newFile)
                .map((value: any, i: number) => {
                  return (
                    <>
                      <ListadoDocumento
                        key={i}
                        list={value}
                        formUpDocument={formUpDocument}
                        setFormUpDocument={setFormUpDocument}
                        documentFilter={tipoDocumentos}
                        documentosCargados={false}
                      />
                    </>
                  );
                })}

            {documentosPorFormato.map((format: string) => (
              <>
                <div className="content-subtitle">
                  <div>
                    <p className="title">{format}</p>
                  </div>
                  <span className="linea"></span>
                </div>
                {formUpDocument
                  .filter(
                    (item: IDocumentoCandidate) =>
                      !item.newFile && item.formato === format
                  )
                  .map((value: IDocumentoCandidate) => {
                    return (
                      <>
                        <ListadoDocumento
                          list={value}
                          formUpDocument={formUpDocument}
                          setFormUpDocument={setFormUpDocument}
                          documentFilter={tipoDocumentos}
                          acciones
                          documentosCargados
                          setSelectDocument={setSelectDocument}
                          deleteDocument={deleteDocument}
                        />
                      </>
                    );
                  })}
              </>
            ))}

            {formUpDocument.some(
              (item: IDocumentoCandidate) => !item.newFile
            ) &&
              selectDocument != undefined && (
                <>
                  <ViewDocument
                    title="VISTA DE DOCUMENTOS"
                    src={process.env.REACT_APP_view_document}
                    selectDocument={selectDocument}
                    personadni={formCandidate.rutCandidato?.value}
                    userId={userId}
                    token={token}
                  ></ViewDocument>
                </>
              )}
          </section>
        </>
      )}
    </>
  );
};
