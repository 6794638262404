interface IIconUserProcesoLegal {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconUserProcesoLegal) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="30"
      height="20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M13.122 1.6369H17.3297C17.9116 1.6369 18.3817 2.10699 18.3817 2.68884C18.3817 3.27069 17.9116 3.74078 17.3297 3.74078H13.5953C13.4244 4.5889 12.8425 5.2891 12.07 5.62441V15.3121H17.3297C17.9116 15.3121 18.3817 15.7822 18.3817 16.364C18.3817 16.9459 17.9116 17.416 17.3297 17.416H11.0181H4.70645C4.12459 17.416 3.65451 16.9459 3.65451 16.364C3.65451 15.7822 4.12459 15.3121 4.70645 15.3121H9.96614V5.62441C9.19362 5.28581 8.61177 4.58562 8.44083 3.74078H4.70645C4.12459 3.74078 3.65451 3.27069 3.65451 2.68884C3.65451 2.10699 4.12459 1.6369 4.70645 1.6369H8.9142C9.39415 0.999162 10.1568 0.584961 11.0181 0.584961C11.8794 0.584961 12.642 0.999162 13.122 1.6369ZM14.9497 11.1044H19.7097L17.3297 7.02151L14.9497 11.1044ZM17.3297 14.2602C15.262 14.2602 13.5427 13.1425 13.1877 11.6665C13.1022 11.3049 13.2206 10.9334 13.408 10.6113L16.5375 5.24637C16.7018 4.96366 17.0043 4.79272 17.3297 4.79272C17.6552 4.79272 17.9576 4.96695 18.122 5.24637L21.2515 10.6113C21.4389 10.9334 21.5572 11.3049 21.4717 11.6665C21.1167 13.1425 19.3974 14.2602 17.3297 14.2602ZM4.667 7.02151L2.28699 11.1044H7.0503L4.667 7.02151ZM0.528275 11.6665C0.442805 11.3049 0.561148 10.9334 0.748525 10.6113L3.87804 5.24637C4.04241 4.96366 4.34484 4.79272 4.67029 4.79272C4.99573 4.79272 5.29816 4.96695 5.46253 5.24637L8.59205 10.6113C8.77942 10.9334 8.89777 11.3049 8.81229 11.6665C8.45398 13.1425 6.73472 14.2602 4.667 14.2602C2.59928 14.2602 0.883304 13.1425 0.528275 11.6665Z"
      />
    </svg>
  );
};
