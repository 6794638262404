import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/seleccion-redesign.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import "../../assets/css/button-generico.min.css";
import "../../assets/css/loading.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import SistemaValidacionKam from "./SistemaValidacionGeneral/AsistenciaValidacionKam/SistemaValidacionKam";
import SeleccionState from "../../context/Seleccion/SeleccionState";
import CampaignState from "../../context/Campaign/CampaignState";
import SubMenu from "./Seleccion/SubMenu";
import TablaRegistroSeleccionado from "./Seleccion/RegistroSeleccionado/TablaRegistroSeleccionado";

const RegistroSeleccionado: React.FC<IPage> = (props) => {
  <Route path="/validacionkam/:id" component={SistemaValidacionKam} />;
  const [itemMenu, setItemMenu] = useState<string | undefined>("seleccion");
  const [profile, setProfile] = useState<any>();
  const [itemMenuFilter, setItemMenuFilter] = useState<string>("MASIVO");

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
            itemMenu={itemMenu}
            setItemMenu={setItemMenu}
            setItemMenuFilter={setItemMenuFilter}
          />
        ) : (
          ""
        )}

        <div className="container-redesign-seleccion">
          <div className="content-redesign-seleccion">
            <SeleccionState>
              <CampaignState>
                <>
                  <SubMenu itemMenu={itemMenu} setItemMenu={setItemMenu} itemMenuFilter={itemMenuFilter} />
                  <TablaRegistroSeleccionado />
                </>
              </CampaignState>
            </SeleccionState>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default RegistroSeleccionado;
