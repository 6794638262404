import { useContext, useEffect } from "react";
import OperacionContratacionContext from "../../../context/Operaciones/OperacionContratacionContext";
import TablaDotacionVigente from "./DotacionActiva/TablaDotacionVigente";
import TablaDocSolicitados from "./DotacionActiva/TablaDocSolicitados";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";

const SistemaOperacionContratacion = (props: any) => {
  const { token } = props;
  const { getListaGeneral } = useContext(SeleccionContext);
  const { toasts } = useContext(OperacionContratacionContext);
  useEffect(() => {
    if (token) {
      getListaGeneral({ token });
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaDotacionVigente />
      <TablaDocSolicitados />
    </>
  );
};

export default SistemaOperacionContratacion;
