import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import "../../../../../assets/css/ficha-postulacion.min.css";
import AreaPersonal from "./componentes/AreaPersonal";
import AreaLaboral from "./componentes/AreaLaboral";
import { formatAgeToAAMM } from "../../../../../functions/kit-mdtw/dates";

interface IModalFichaPostulacion {
  list: any[];
  listPostulacion: any[];
  listOriginal: any;
}

export default (props: IModalFichaPostulacion) => {
  const { list, listPostulacion, listOriginal } = props;
  const {
    token,
    listaRegistroCandidato,
    listaGeneral,
    getRegistroCandidato,
    getRegistroPostulacion,
    listaRegistroPostulacion,
  } = useContext(SeleccionContext);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isLoadingRegistroPostulacion, setIsLoadingRegistroPostulacion] =
    useState<boolean | undefined>(undefined);
  const [formCandidate, setFormCandidate] = useState<any>({});

  useEffect(() => {
    if (
      listaRegistroCandidato &&
      listaGeneral &&
      Object.values(listaGeneral).length > 0
    ) {
      const { personal = {}, laboral = {} } = listaRegistroCandidato;
      const {
        candidato = {},
        direccion = {},
        contacto = {},
        contactoEmergencia = {},
      } = personal;
      const {
        DNI = {},
        primerNombre = {},
        segundoNombre = {},
        primerApellido = {},
        segundoApellido = {},
        fechaNacimiento = {},
        estadoCivilId = {},
        sexoId = {},
        nacionalidadId = {},
      } = candidato;
      const {
        calle = {},
        numeroPrincipal = {},
        comunaId = {},
        provinciaId = {},
        regionId = {},
      } = direccion;
      const { correo = {}, fonoPrincipal = {}, fonoSecundario = {} } = contacto;
      const {
        nombreCompleto = {},
        fono = {},
        parentescoId = {},
      } = contactoEmergencia;
      const {
        experienciaEnCargo = {},
        disponibilidadId = {},
        uniforme = {},
        salud = {},
      } = laboral;
      const { tallaPoleraId = {}, tallaZapatoId = {} } = uniforme;
      const { discapacidad = {}, vacunas = {} } = salud;
      const { esquemaCovidCompleto = {}, refuerzo = {} } = vacunas;

      const naciona = listaGeneral.paises.find(
        ({ paisId }: any) => paisId === nacionalidadId
      );
      const comuna = listaGeneral.comunas.find(
        ({ comunaId: id }: any) => id === comunaId
      );
      const estCivil = listaGeneral.estadoCivil.find(
        ({ estadoCivilId: id }: any) => id === estadoCivilId
      );
      const seex = listaGeneral.sexo.find(
        ({ sexoId: id }: any) => id === sexoId
      );
      const region = listaGeneral.regiones.find(
        ({ regionId: id }: any) => id === regionId
      );
      const provincia = listaGeneral.provincias.find(
        ({ provinciaId: id }: any) => id === provinciaId
      );
      const parentesco = listaGeneral.parentesco.find(
        ({ parentescoId: id }: any) => id === parentescoId
      );
      const disponi = listaGeneral.disponibilidad.find(
        ({ disponibilidadId: id }: any) => id === disponibilidadId
      );
      const tallaPolera = listaGeneral?.tallas?.numerica.find(
        ({ tallaId: id }: any) => id === tallaPoleraId
      );
      const tallaPantalon = listaGeneral?.tallas?.numerica.find(
        ({ tallaId: id }: any) => id === tallaPoleraId
      );
      const tallaZapato = listaGeneral?.tallas?.alfanumerica.find(
        ({ tallaId: id }: any) => id === tallaZapatoId
      );

      setFormCandidate({
        DNI,
        primerNombre,
        segundoNombre,
        primerApellido,
        segundoApellido,
        fechaNacimiento,
        edad: formatAgeToAAMM({ date: fechaNacimiento }) ?? {},
        estadoCivil: estCivil?.estadoCivil ?? "",
        sexo: seex?.nombre ?? "",
        nacionalidad: naciona?.gentilicio ?? "",
        calle,
        numeroCalle: numeroPrincipal ?? "",
        comuna: comuna?.comunaNombre ?? "",
        provincia: provincia?.nombre ?? "",
        region: region?.nombre ?? "",
        pais: naciona?.nombre ?? "",
        correo,
        fonoPrincipal,
        fonoSecundario,
        nombreContactEmergencia: nombreCompleto ?? "",
        fonoContactEmergencia: fono ?? "",
        parentesco: parentesco?.nombre ?? "",
        // laboral
        experienciaEnCargo,
        disponibilidad: disponi?.nombre ?? "",
        tallaPolera: tallaPolera?.tallaNombre ?? "",
        tallaPantalon: tallaPantalon?.tallaNombre ?? "",
        tallaZapato: tallaZapato?.tallaNombre ?? "",
        discapacidad,
        esquemaCovidCompleto,
        refuerzo,
      });
    }
  }, [listaRegistroCandidato]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    try {
      if (token && listOriginal) {
        const rut = listOriginal
          .filter(({ personaId }: any) => list.includes(personaId))
          .map(({ personaDNI }: any) => personaDNI);
        getRegistroCandidato({
          token,
          personaRut: rut[0],
          setOpenModal: setIsLoading,
        });
        getRegistroPostulacion({
          token,
          postulacionId: listPostulacion[0],
          setLoading: setIsLoadingRegistroPostulacion,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  return (
    <>
      {isLoading && listaGeneral && listaRegistroCandidato ? (
        <>
          <div className="modal-ficha-postulacion">
            {!isLoadingRegistroPostulacion && listaRegistroPostulacion && (
              <>
                <div className="content-table-row">
                  <span className="title-table-row">VACANTE ASOCIADA</span>
                  <span className="subtitle-table-row">
                    {listaRegistroPostulacion.medioPostulacion}
                  </span>
                </div>
                <div className="content-table-row">
                  <span className="title-table-row">
                    PROCESO AL QUE POSTULÓ
                  </span>
                  <span className="subtitle-table-row">
                    {listaRegistroPostulacion.nombrePostulacion}
                  </span>
                </div>
              </>
            )}
            <AreaPersonal hiddenDefault={false} formCandidate={formCandidate} />
            <AreaLaboral
              hiddenDefault={false}
              formCandidate={formCandidate}
              experienciaEnCargo={
                listaRegistroPostulacion?.experienciaEnCargo ?? ""
              }
              disponibilidad={listaRegistroPostulacion?.disponibilidad ?? ""}
            />
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Cargando datos...</h3>
        </div>
      )}
    </>
  );
};
