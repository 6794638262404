import { useState, useEffect, useContext } from "react";
import ItemProceso from "./ItemProceso";

interface IItemGrupo {
  procesos: any;
  cargoGroupName: string;
  clienteNombre?: string;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string; 
  localStorageFilter: string;
  formFilter: any;
  listRefresh: Function; 
}

export default (props: IItemGrupo) => {
  const {
    procesos,
    cargoGroupName,
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    localStorageFilter, 
    formFilter,
    listRefresh,
  } = props;
  
  const [listaSeleccionProceso, setListaSeleccionProceso] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (localStorage.getItem(localStorageSelect)) {
      const multi: string = String(localStorage.getItem(localStorageSelect));
      setListaSeleccionProceso(createListMutate({ list: multi.split(",") }));
    }
  }, []);

  useEffect(() => {
    if (listadoSeleccionado) {
      setListaSeleccionProceso(createListMutate({ list: listadoSeleccionado }));
    }
  }, [listadoSeleccionado]);

  const createListMutate = (values: any): string[] => {
    const { list } = values;
    const arrayProcesos: string[] = procesos.map(
      ({ personaDNI }: any) => personaDNI
    );
    return list.filter((personaDNI: string) =>
      arrayProcesos.includes(personaDNI)
    );
  };


  return (
    <>
      {procesos &&
        procesos.map((item: any) => {
          const {
            busquedaId,
            busquedaFin,
            cargoNombre,
            cuposRestantes,
            personas,
          } = item;
          return (
            <ItemProceso
              key={busquedaId}
              cargoGroupName={cargoGroupName}
              busquedaId={busquedaId}
              personas={personas}
              busquedaFin={busquedaFin}
              cargoNombre={cargoNombre}
              cuposRestantes={cuposRestantes}
              listadoSeleccionado={listadoSeleccionado}
              setListadoSeleccionado={setListadoSeleccionado}
              localStorageSelect={localStorageSelect}
              localStorageFilter={localStorageFilter}
              formFilter={formFilter}
              listRefresh={listRefresh}
            />
          );
        })}
    </>
  );
};
