import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";

interface IModalGenerarVinculo {
  list: any[];
  listaOriginal: any;
  setFormData: Function; 
  formData: any; 
}

export default (props: IModalGenerarVinculo) => {
  const { list, listaOriginal, setFormData, formData } = props;
  const { profile, token } = useContext(SeleccionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (list && listaOriginal) {
      const formSelect: any = {};
      const arrayData = listaOriginal.filter(({ busquedaId }: any) =>
        list.includes(busquedaId)
      );
      arrayData.forEach((item: any) => {
        formSelect[item.busquedaId] = { value: undefined, valid: false };
      });
      setFormData(formSelect);
    }
  }, [list]);

  // useEffect(() => {
  //   console.log(formData); 
  //   const todosTienenTexto = Object.values(formData).every((item :any) => {
  //     return item.hasOwnProperty("value") && typeof item.value === "string" && item.value.trim() !== "";
  //   });
  //   console.log(todosTienenTexto); 
  // }, [formData]); 

  return (
    <>
      <section className="content-table-restaurar" style={{gap: "15px"}}>
        {listaOriginal.length > 0 &&
          listaOriginal
            .filter((item: any) => list.includes(item.busquedaId))
            .map((listado: any) => {
              const {busquedaId, cargoNombre, clienteNombre } = listado; 
              return (
                <>
                  <table className="table-restaurar w-100">
                    <thead className="thead-table-restaurar">
                      <tr className="tr-table-restaurar">
                        <th className="td-table-restaurar">ID</th>
                        <th className="td-table-restaurar">Proceso</th>
                        <th className="td-table-restaurar">Cliente</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-table-restaurar">
                      <tr className="tr-table-restaurar">
                        <td className="td-table-restaurar">{busquedaId}</td>
                        <td className="td-table-restaurar">{cargoNombre}</td>
                        <td className="td-table-restaurar">{clienteNombre}</td>
                      </tr>
                    </tbody>
                  </table>
                  <InputTextCustomized 
                    layer="Nombre Postulación"
                    placeholder="Ingrese el nombre que acompañará el código QR"
                    onValueChange={setFormData}
                    onPermaValues={formData}
                    onPropertyChange={busquedaId}
                    required={false}
                  />
                </>
              );
            })}
      </section>
    </>
  );
};
