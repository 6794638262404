import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  formatDateToDDMMAAAA,
} from "../../../../../../functions/kit-mdtw/dates";
interface IAreaPersonalFichaPotulacion {
  hiddenDefault: boolean;
  formCandidate: any;
}

export default (props: IAreaPersonalFichaPotulacion) => {
  const { hiddenDefault, formCandidate } = props;
  const [hidden, setHidden] = useState<boolean>(hiddenDefault); 

  return (
    <>
      {formCandidate ? (
        <>
          <div className={`area-table`} onClick={() => setHidden(!hidden)}>
            <span>ÁREA PERSONAL</span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </div>
          <div className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-table">
              <div className="title">DATOS PERSONALES</div>

              <div className="content-table">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">
                  {formCandidate.primerNombre} {formCandidate.segundoNombre}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">1° APELLIDO</span>
                <span className="subtitle-table">
                  {formCandidate.primerApellido}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">2° APELLIDO</span>
                <span className="subtitle-table">
                  {formCandidate.segundoApellido}
                </span>
              </div>
              <div className="content-table">
                <span className="title-table">GÉNERO</span>
                <span className="subtitle-table">{formCandidate.sexo}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">RUT</span>
                <span className="subtitle-table">{formCandidate.DNI}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">F. NACIMIENTO</span>
                <span className="subtitle-table">
                  {formatDateToDDMMAAAA({
                    date: formCandidate.fechaNacimiento,
                    format: "/",
                  })}
                </span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">EDAD</span>
                <div className="subtitle-content-age">
                  <span className="subtitle-table cinco">{formCandidate.edad?.annos ?? ""}</span>
                  <span className="subtitle-table cinco">AA</span>
                  <span className="subtitle-table cinco">{formCandidate.edad?.meses ?? ""}</span>
                  <span className="subtitle-table cinco">MM</span>
                </div>
              </div>
              <div className="content-table cinco">
                <span className="title-table">NACIONALIDAD</span>
                <span className="subtitle-table">{formCandidate.nacionalidad ?? ""}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">ESTADO CIVIL</span>
                <span className="subtitle-table">{formCandidate.estadoCivil ?? ""}</span>
              </div>

              <div className="content-table tres">
                <span className="title-table">CORREO</span>
                <span className="subtitle-table">{formCandidate.correo ?? ""}</span>
              </div>
              <div className="content-table tres">
                <span className="title-table">CELULAR</span>
                <span className="subtitle-table">{formCandidate.fonoPrincipal ?? ""}</span>
              </div>
              <div className="content-table tres">
                <span className="title-table">FONO 2</span>
                <span className="subtitle-table">{formCandidate.fonoSecundario ?? ""}</span>
              </div>

              <div className="title">DOMICILIO</div>

              <div className="content-table cinco">
                <span className="title-table">CALLE</span>
                <span className="subtitle-table">{formCandidate.calle ?? ""}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">NÚMERO</span>
                <span className="subtitle-table">{formCandidate.numeroCalle ?? ""}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">COMUNA</span>
                <span className="subtitle-table">{formCandidate.comuna ?? ""}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">PROVINCIA</span>
                <span className="subtitle-table">{formCandidate.provincia ?? ""}</span>
              </div>
              <div className="content-table cinco">
                <span className="title-table">REGIÓN</span>
                <span className="subtitle-table">{formCandidate.region ?? ""}</span>
              </div>

              <div className="title">CONTACTO EN CASO DE EMERGENCIA</div>

              <div className="content-table tres">
                <span className="title-table">NOMBRE</span>
                <span className="subtitle-table">{formCandidate.nombreContactEmergencia ?? ""}</span>
              </div>
              <div className="content-table tres">
                <span className="title-table">PARENTESCO</span>
                <span className="subtitle-table">{formCandidate.parentesco ?? ""}</span>
              </div>
              <div className="content-table tres">
                <span className="title-table">FONO</span>
                <span className="subtitle-table">{formCandidate.fonoContactEmergencia ?? ""}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Error al cargar los datos 😨</h3>
        </div>
      )}
    </>
  );
};
