import axios from "axios";
import { useReducer, useState } from "react";
import {
  IAdminAssistState,
  IState,
  IClickActiveDesactive,
} from "../../interfaces/IAdminAssist";
import AdminAssistContext from "./AdminAssistContext";
import AdminAssistReducer from "./AdminAssistReducer";
import {
  setAdminTable,
  setToastState,
  setListadoProcesos,
  setListadoTipoServicios,
  setListadoUsuarioTransporte,
} from "./AdminAssistSet";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";

export default (props: IAdminAssistState) => {
  const { children } = props;
  const { toasts, createToast } = useToast();
  const [listServicioId, setListServicioId] = useState<any[]>([]);
  const [visibleActionBlocking, setVisibleActionBlocking] = useState(false);
  const initialState: IState = {
    perfile: sessionStorage.getItem("perfile") as string,
    userId: sessionStorage.getItem("usuarioId") as string,
    adminTable: [],
    listadoProcesos: [],
    listadoTipoServicios: [],
    listadoUsuarioTransporte: [],
    toastState: {
      title: "",
      message: "",
      success: true,
    },
  };

  const [state, dispatch]: any = useReducer<any>(
    AdminAssistReducer,
    initialState
  );

  const getAdminTable = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/lista?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setAdminTable({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const getListadoProcesos = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/procesos?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setListadoProcesos({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const getListaTipoServicio = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/tipos?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setListadoTipoServicios({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const getListaUsuariosTransporte = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}usuario`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data.data.filter(
        (item: any) => item.perfil == "tw_gen"
      );
      setListadoUsuarioTransporte({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const crearServicio = async (values: any) => {
    const { formData, token } = values;
    const {
      procesoId,
      tipoServicio,
      usuarioServicioGeneral,
      nombreExterno,
      rutExterno,
      correoExterno,
      fonoExterno,
      rutaInicio,
      fechaInicio,
      horaInicio,
      rutaDestino,
      fechaDestino,
      horaDestino,
      rutaInicioComuna, 
      rutaDestinoComuna
    } = formData;
    let externoServicio = [];

    if (!procesoId.value?.code) {
      createToast({
        title: "Error al crear servicio",
        message: `Favor seleccionar todos los campos`,
        type: "danger",
      });
      return;
    }

    if (
      fechaDestino.value == "" ||
      fechaDestino.value == null ||
      !fechaDestino.value
    ) {
      createToast({
        title: "Error al crear servicio",
        message: `Favor llenar los campos obligatorio`,
        type: "danger",
      });
      return;
    }

    if (!tipoServicio.value?.name) {
      createToast({
        title: "Error al crear servicio",
        message: `Favor llenar los campos obligatorio: Tipo servicio`,
        type: "danger",
      });
      return;
    }

    const body = {
      procesos: [procesoId.value?.code ?? ""],
      tipoServicio: tipoServicio.value?.code ?? "",
      servicioExterno: [
        {
          nombreEmpresa: "",
          rutPersona: rutExterno.value ?? "",
          nombrePersona: nombreExterno.value ?? "",
          correoPersona: correoExterno.value ?? "",
          telefonoPersona: fonoExterno.value ?? "",
          cantidadInvitaciones: 1,
        },
      ],
      serviciosGenerales: [state.userId, usuarioServicioGeneral.value?.code],
      rutas: [
        {
          direccion: rutaInicio.value ?? "",
          comuna: rutaInicioComuna.value?.name ?? "",
          fecha: fechaFormatZ({
            fecha: fechaInicio.value,
            hora: horaInicio.value,
          }), //"2023-01-23T17:41:05.000Z",
          latitud: "-33.4116876",
          longitud: "-70.6097705",
          posicion: 1,
        },
        {
          direccion: rutaDestino.value ?? "",
          comuna: rutaDestinoComuna.value?.name ?? "",
          fecha: fechaFormatZ({
            fecha: fechaDestino.value,
            hora: horaDestino.value,
          }),
          latitud: "-33.4116876",
          longitud: "-70.6097705",
          posicion: 2,
        },
      ],
    };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}servicio?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      getAdminTable({ token });
      createToast({
        title: "Servicio Creado",
        message: `Servicio creado con exito en sistema`,
        type: "success",
      });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
      createToast({
        title: "Error al crear servicio",
        message: `Ha ocurrido un error al crear el servicio.`,
        type: "danger",
      });
    }
  };

  const updateServiceState = async (values: any) => {
    const { index, activo, token } = values;
    const arrayList = [...state.adminTable];
    arrayList[index].activo = activo;
    const arrayUpdate = arrayList.map(({ servicioId, activo }) => {
      return {
        servicioId,
        activo,
      };
    });

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}servicio/des-habilitar?userId=${state.userId}`,
        arrayUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setAdminTable({ dispatch, payload: arrayList });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const deleteService = async (values: any) => {
    const { servicioId, token } = values;
    let arrays: any;
    servicioId.length > 0 ? (arrays = servicioId) : (arrays = [servicioId]);

    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_URL}servicio?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: arrays,
        }
      );
      const data = await result.data;
      console.log(`Servicio ${servicioId} eliminado con exito!`);
      getAdminTable({ token });
      createToast({
        title: "Error al guardar documentos",
        message: `Servicio eliminado con exito`,
        type: "danger",
      });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const updateResetService = async (values: any) => {
    const { servicioId, token } = values;
    let arrays: any;
    if (servicioId === null || servicioId === "") {
      return console.log(
        `Error: servicioId vacio. Favor seleccionar un servicio`
      );
    }

    servicioId.length > 0 ? (arrays = servicioId) : (arrays = [servicioId]);
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}servicio/reset?userId=${state.userId}`,
        arrays,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      getAdminTable({ token });
      createToast({
        title: "Validez Renovada",
        message: `El tiempo de validez del servicio se renovó correctamente`,
        type: "success",
      });
    } catch (error) {
      console.log(`Ha ocurrido un error: ${error}`);
      createToast({
        title: "Error al guardar",
        message: `El tiempo de servicio no se renovó, por favor intente nuevamente`,
        type: "danger",
      });
    }
  };

  const handleClickActive = (values: IClickActiveDesactive) => {
    const { index, value } = values;
    const arrayList = [...state.adminTable];
    arrayList[index].activo = value;
    setAdminTable({ dispatch, payload: arrayList });
  };

  const fechaFormatZ = (values: any) => {
    const { fecha, hora } = values;
    // Obtener los componentes de la fecha y hora
    const [dia, mes, anio] = fecha.split("/");
    const [horaVal, minutoVal] = hora.split(":");

    // Crear un objeto Date con los componentes de fecha y hora
    const fechaHora = new Date(anio, mes - 1, dia, horaVal, minutoVal);

    // Formatear la fecha y hora en el formato deseado
    const fechaHoraFormateada = fechaHora.toISOString();
    return fechaHoraFormateada;
  };

  // TBLEFILTER

  const handleClickViewActions = () => {
    setVisibleActionBlocking(visibleActionBlocking === false ? true : false);
  };

  return (
    <AdminAssistContext.Provider
      value={{
        ...state,
        toasts,
        listServicioId,
        visibleActionBlocking,
        getAdminTable,
        getListadoProcesos,
        getListaTipoServicio,
        getListaUsuariosTransporte,
        setAdminTable,
        handleClickActive,
        handleClickViewActions,
        setListServicioId,
        setVisibleActionBlocking,
        updateServiceState,
        deleteService,
        updateResetService,
        crearServicio,
      }}
    >
      {children}
    </AdminAssistContext.Provider>
  );
};
