import { useContext, useEffect, useState } from "react";
import ActionMassive from "./ActionMassive";
import IconCandidatoReclutado from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoReclutado";
import IconCandidatoPostulado from "../../../../ui/kit-svg/icon-porvalidar-profesional/IconCandidatoPostulado";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import IconLoading from "../../../../ui/kit-svg/IconLoading";
import { IListaSolicitudesProcesadas } from "../../../../../interfaces/IContratos";
import ContratosContext from "../../../../../context/Contratos/ContratosContext";
import IconDocCheck from "../../../../ui/kit-svg/icon-contratos/procesadas/IconDocCheck";
import IconDocError from "../../../../ui/kit-svg/icon-contratos/procesadas/IconDocError";
import IconRejecVisador from "../../../../ui/kit-svg/icon-contratos/procesadas/IconRejecVisador";

const TablaSolicitudProcesadas = () => {
  const {
    listaSolicitudesProcesadas,
    postSolicitudesProcesadas,
    token,
  } = useContext(ContratosContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [gridCandidatoDisponible, setGridCandidatoDisponible] = useState<any[]>(
    []
  );
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroContratoSolProcesadas"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    fechaSolicitud: [],
    tipoDocumento: [],
    ficha: [],
    division: [],
    areaNegocio: [],
    fechaRevision: [],
    solicitante: [],
  });

  useEffect(() => {
    listRefresh();
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaSolicitudesProcesadas) {
      const arrayList = listaSolicitudesProcesadas.map(
        (item: IListaSolicitudesProcesadas) => item.baseContratoId
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaSolicitudesProcesadas]);

  useEffect(() => {
    if (listaSolicitudesProcesadas) {
      let arrayGridFilter = [...listaSolicitudesProcesadas];
      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoDisponible(arrayGridFilter);
    }
  }, [listaSolicitudesProcesadas, range]);

  useEffect(() => {
    // Código que se ejecuta después de la redirección
    setTimeout(() => {
      const tablaElement = document.getElementById("tablaSolicitudProcesadas");

      if (tablaElement && localStorage.getItem("filtroContratoSolProcesadas")) {
        tablaElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  const listRefresh = () => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        postSolicitudesProcesadas({
          token,
          filtros: JSON.parse(copyStorage),
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        postSolicitudesProcesadas({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  };

  return (
    <>
      <section className="content-seleccion-multiple action-in-line">
        <div className="content-multiple">
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado ?? []}
          setListadoSeleccionado={setListadoSeleccionado}
          localStorageFilter={localStorageFilter}
          listRefresh={listRefresh}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
          listSelect={listaSolicitudesProcesadas}
        />
      </section>
      <section id="tablaSolicitudProcesadas" className="content-header-table">
        <label className="title-header-table">Solicitudes recibidas</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Fecha <br></br>Solicitud</th>
                      <th className="th-table-registros">Tipo Documento</th>
                      <th className="th-table-registros">Ficha</th>
                      <th className="th-table-registros">
                        División <br></br>TW
                      </th>
                      <th className="th-table-registros">
                        Área de<br></br>Negocios
                      </th>
                      <th className="th-table-registros">Fecha<br></br>Revisión</th>
                      <th className="th-table-registros">Solicitante</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoDisponible &&
                      gridCandidatoDisponible.map(
                        (item: IListaSolicitudesProcesadas) => {
                          const {
                            baseContratoId,
                            fechaSolicitud,
                            fechaRevision,
                            estado,
                            tipoDocumento,
                            ficha,
                            division,
                            areaNegocioCod,
                            solicitante,
                          } = item;
                          return (
                            <>
                              <tr
                                key={baseContratoId}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(baseContratoId) &&
                                  "checkeado"
                                }`}
                              >
                                <td className="td-table-registros">
                                  <section className="content-state state-pl-35">
                                    {estado == "ACEPTADO_PEND_FIRMA" && (
                                      <>
                                        <IconDocCheck fill="#FF0081" />
                                        <div className="content-state-text">
                                          <label className="title">
                                          Documento enviado a <br />validación
                                          </label>
                                        </div>
                                      </>
                                    )}
                                    {estado == "DOC_CREADO_SIN_VALIDAR" && (
                                      <>
                                        <IconDocCheck fill="#FF4900" />
                                        <div className="content-state-text">
                                          <label className="title">
                                          Documento creado, sin <br />enviar a validar
                                          </label>
                                        </div>
                                      </>
                                    )}
                                    {estado == "ACEPTADO_SIN_FIRMA" && (
                                      <>
                                        <IconDocCheck  fill="#E3A000"/>
                                        <div className="content-state-text">
                                          <label className="title">
                                          Sol.aceptada sin crear en <br />firma digital
                                          </label>
                                        </div>
                                      </>
                                    )}
                                    {estado == "RECHAZADO_FIRMA" && (
                                      <>
                                        <IconDocError fill="#BE2A2A" />
                                        <div className="content-state-text">
                                          <label className="title">
                                           Doc. rechazado por<br />firma digital
                                          </label>
                                        </div>
                                      </>
                                    )}
                                    {estado == "RECHAZADO" && (
                                      <>
                                        <IconRejecVisador fill="#BE2A2A" />
                                        <div className="content-state-text">
                                          <label className="title">
                                          Alta rechazada por revisor
                                          </label>
                                        </div>
                                      </>
                                    )}
                                  </section>
                                </td>
                                <td className="td-table-registros">
                                {formatDateToDDMMAAAA({
                                    date: fechaSolicitud,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {tipoDocumento}
                                </td>
                                <td className="td-table-registros">
                                  {ficha}
                                </td>
                                <td className="td-table-registros">
                                  {division}
                                </td>
                                <td className="td-table-registros">
                                  {areaNegocioCod}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaRevision,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {solicitante}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}
    </>
  );
};

export default TablaSolicitudProcesadas;
