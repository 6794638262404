import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AreaAntecedentesProceso from "./AreaAntecedentesProceso";
import "../../../../../../assets/css/modal-psicolaboral.min.css";
import InformacionProfesional from "./InformacionProfesional";
import EvaluacionCompetencias from "./EvaluacionCompetencias";
import InformacionAdicional from "./InformacionAdicional";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";
import ResenaYDataRelevante from "./ResenaYDataRelevante";
interface IModalFicha {
  title?: string;
  onChangeBack?: Function;
  buttonBack?: string;
  buttonContraer?: boolean;
  subtitle?: string;
  list?: any[];
  areaContrato?: boolean;
  rut?: string;
  busPerId?: string;
  setOpenModalFicha?: Function;
  token?: string | undefined;
  setToken?: Function;
  getToken?: Function;
  personaId?: string;
  idCliente?: string;
  comentario?: string;
  estado?: string;
  fechaValidacion?: any;
  onCloseModalFicha?: Function;
  listRefresh?: Function;
}
export default (props: IModalFicha) => {
  const {
    title,
    onChangeBack,
    subtitle,
    token,
    busPerId,
    listRefresh,
  } = props;

  const { recordDataPsicolaboral } = useContext(SeleccionContext);

  const [objsendPsicolaboralProfesional, setObjsendPsicolaboralProfesional] =
    useState<any>();
  const [objsendPsicolaboralEvaluacion, setObjsendPsicolaboralEvaluacion] =
    useState<any>();
  const [objsendPsicolaboralAdicional, setObjsendPsicolaboralAdicional] =
    useState<any>();
  const [objsendPsicolaboralConclusion, setObjsendPsicolaboralConclusion] =
    useState<any>();
  const [
    objsendPsicolaboralResenaRelevante,
    setObjsendPsicolaboralResenaRelevante,
  ] = useState<any>();

  const [contraerModal, setContraerModal] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("contraerModal");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    localStorage.setItem("contraerModal", JSON.stringify(contraerModal));
  }, [contraerModal]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickGuardarPsicolaboral = async (): Promise<void> => {
    try {
      await recordDataPsicolaboral({ token, busPerId, objetoRecord });
      handleClickCloseModal();
      listRefresh && (await listRefresh());
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const nuevosResultados: {
    competenciaLaboralId: any;
    categoriaEvaluacionId: any;
  }[] = [];

  // Iterar a través de los resultados actuales y transformarlos
  objsendPsicolaboralEvaluacion?.resultados?.forEach(
    (resultado: {
      evaluacionCompetenciaId: { skill: any };
      categoriaEvaluacionId: any;
    }) => {
      nuevosResultados.push({
        competenciaLaboralId: resultado.evaluacionCompetenciaId,
        categoriaEvaluacionId: resultado.categoriaEvaluacionId,
      });
    }
  );

  const resultadosCompAdicional: {
    nombreCompetencia: any;
    categoriaEvaluacionId: any;
  }[] = [];

  // Iterar a través de los resultados actuales y transformarlos
  objsendPsicolaboralEvaluacion?.resultadoCompAdicional?.forEach(
    (resultado: {
      evaluacionCompetenciaId: { skill: any };
      categoriaEvaluacionId: any;
    }) => {
      resultadosCompAdicional.push({
        nombreCompetencia: resultado.evaluacionCompetenciaId,
        categoriaEvaluacionId: resultado.categoriaEvaluacionId,
      });
    }
  );

  const resultadosEsperados: {
    nombreCompetencia: any;
    categoriaEvaluacionId: any;
  }[] = [];

  // Iterar a través de los resultados actuales y transformarlos
  objsendPsicolaboralEvaluacion?.resultadosEsperados?.forEach(
    (resultado: {
      evaluacionCompetenciaId: { skill: any };
      categoriaEvaluacionId: any;
    }) => {
      resultadosEsperados.push({
        nombreCompetencia: resultado.evaluacionCompetenciaId,
        categoriaEvaluacionId: resultado.categoriaEvaluacionId,
      });
    }
  );

  const objetoRecord = {
    profesional: {
      fechaEvaluacion: objsendPsicolaboralProfesional?.fechaEvaluacion,
      pretensionRenta: parseInt(
        objsendPsicolaboralProfesional?.pretensionRenta
      ),
      pruebasAplicadas: objsendPsicolaboralProfesional?.pruebasAplicadas,
      titulos: objsendPsicolaboralProfesional?.titulos,
      referencias: objsendPsicolaboralProfesional?.referencias,
    },
    evaluacion: {
      conclusionInformeId:
        objsendPsicolaboralConclusion?.conclusiones?.value?.code,
      perfilEvaluacionId: objsendPsicolaboralEvaluacion?.perfilEvaluacionId,
      experienciaSimilar: objsendPsicolaboralEvaluacion?.experienciaSimilar,
      educacionEsperada: objsendPsicolaboralEvaluacion?.educacionEsperada,
      descripcionEvaluacion:
        objsendPsicolaboralEvaluacion?.descripcionEvaluacion,
      resultados: nuevosResultados,
    },

    competenciasEsperadas: resultadosEsperados,
    competenciasAdicionales: resultadosCompAdicional,

    adicional: {
      ...objsendPsicolaboralAdicional,
      ...objsendPsicolaboralResenaRelevante,
    },
  };

  return (
    <>
      <div className="modal-container-psicolaboral edit">
        <div
          className={`modal-content-psicolaboral ${
            contraerModal ? "w-45" : "w-80"
          }`}
        >
          <label className="modal-close">
            <CloseIcon onClick={handleClickCloseModal} />
          </label>

          <div className="modal-title">
            <span>{title}</span>
          </div>
          {subtitle && (
            <div className="modal-subtitle">
              <span>{subtitle}</span>
            </div>
          )}
          <div className="secciones-modal">
            <AreaAntecedentesProceso
              formCandidate={undefined}
              contraerModal={contraerModal}
              busPerId={busPerId}
              token={token}
            />
          </div>

          <div className="separador-seccion"></div>
          <InformacionProfesional
            formCandidate={undefined}
            setFormCandidate={undefined}
            contraerModal={contraerModal}
            token={token}
            busPerId={busPerId}
            setObjsendPsicolaboralProfesional={
              setObjsendPsicolaboralProfesional
            }
          />

          <ResenaYDataRelevante
            contraerModal={contraerModal}
            token={token}
            busPerId={busPerId}
            setObjsendPsicolaboralResenaRelevante={
              setObjsendPsicolaboralResenaRelevante
            }
          />
          <div className="secciones-modal">
            <EvaluacionCompetencias
              contraerModal={contraerModal}
              token={token}
              setObjsendPsicolaboralEvaluacion={
                setObjsendPsicolaboralEvaluacion
              }
            />
          </div>

          <div className="secciones-modal">
            <InformacionAdicional
              contraerModal={contraerModal}
              token={token}
              busPerId={busPerId}
              setObjsendPsicolaboralAdicional={setObjsendPsicolaboralAdicional}
              setObjsendPsicolaboralConclusion={
                setObjsendPsicolaboralConclusion
              }
            />
          </div>

          <div className="pr-5 modal-button-content modal-button-content-in-modal end">
            <button
              className={`modal-button cancel`}
              onClick={() => handleClickCloseModal()}
            >
              Cerrar
            </button>
            <button
              className={`modal-button confirm`}
              onClick={() => handleClickGuardarPsicolaboral()}
            >
              Guardar Datos
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
