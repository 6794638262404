interface IIconRegistroOcupado {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconRegistroOcupado) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M10.5527 4.76999C10.5527 4.06605 10.2686 3.39917 9.77463 2.90518C9.28065 2.41119 8.60141 2.12715 7.90983 2.12715C7.21824 2.12715 6.53901 2.41119 6.04502 2.90518C5.55103 3.39917 5.26699 4.0784 5.26699 4.76999C5.26699 5.46157 5.55103 6.14081 6.04502 6.63479C6.53901 7.12878 7.21824 7.41283 7.90983 7.41283C8.60141 7.41283 9.28065 7.12878 9.77463 6.63479C10.2686 6.14081 10.5527 5.46157 10.5527 4.76999ZM3.67388 4.76999C3.67388 3.64616 4.11847 2.57174 4.90885 1.78136C5.69923 0.990976 6.77365 0.546387 7.89748 0.546387C9.0213 0.546387 10.0957 0.990976 10.8861 1.78136C11.6765 2.57174 12.1211 3.64616 12.1211 4.76999C12.1211 5.89381 11.6765 6.96824 10.8861 7.75862C10.0957 8.549 9.0213 8.99359 7.89748 8.99359C6.77365 8.99359 5.69923 8.549 4.90885 7.75862C4.11847 6.96824 3.67388 5.89381 3.67388 4.76999ZM2.13016 15.8724H13.6771C13.3808 13.7853 11.59 12.1675 9.41649 12.1675H6.39081C4.21726 12.1675 2.42656 13.7729 2.13016 15.8724ZM0.5 16.4775C0.5 13.2172 3.14284 10.5867 6.39081 10.5867H9.41649C12.6768 10.5867 15.3073 13.2295 15.3073 16.4775C15.3073 17.0209 14.8627 17.4531 14.3317 17.4531H1.47563C0.93224 17.4531 0.5 17.0086 0.5 16.4775Z"
        />
        <path
          d="M12.3063 1.57141C12.3063 1.11447 12.6768 0.731632 13.1461 0.731632H19.8273C20.2842 0.731632 20.6671 1.10212 20.6671 1.57141C20.6671 2.0407 20.2966 2.41119 19.8273 2.41119H19.0616L19.358 6.27665C20.3213 6.79534 21.0746 7.65982 21.4328 8.7466L21.4575 8.82069C21.5439 9.08004 21.4945 9.35173 21.3463 9.57403C21.1858 9.79632 20.9388 9.91982 20.6671 9.91982H12.3187C12.047 9.91982 11.8 9.79632 11.6394 9.57403C11.4789 9.35173 11.4418 9.08004 11.5283 8.82069L11.553 8.7466C11.9111 7.65982 12.6645 6.79534 13.6277 6.27665L13.9241 2.41119H13.1585C12.7015 2.41119 12.3187 2.0407 12.3187 1.57141H12.3063ZM17.308 10.7596V13.2666C17.308 13.7235 16.9375 14.1064 16.4682 14.1064C15.9989 14.1064 15.6284 13.7359 15.6284 13.2666V10.7596H17.308Z"
        />
      </g>
    </svg>
  );
};
