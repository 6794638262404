interface IIconCheck {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconCheck) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M5.77395 12.5893L0.248949 7.06425C-0.0829829 6.73232 -0.0829829 6.19413 0.248949 5.86217L1.451 4.66008C1.78293 4.32812 2.32116 4.32812 2.65309 4.66008L6.37499 8.38195L14.3469 0.410082C14.6788 0.0781499 15.2171 0.0781499 15.549 0.410082L16.751 1.61217C17.083 1.9441 17.083 2.48229 16.751 2.81425L6.97604 12.5893C6.64407 12.9212 6.10588 12.9212 5.77395 12.5893Z"
      />
    </svg>
  );
};
