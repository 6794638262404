import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaCandidatoDisponible from "./CandidatoDisponible/TablaCandidatoDisponible";
import TablaCandidatoNoDisponible from "./CandidatoNoDisponible/TablaCandidatoNoDisponible";

const TablaRegistroHabilitado = () => {
  const {
    toasts, 
    getListaGeneral, 
    getExclusiones,
    getBusquedaProcesos,
    getToken,
    token,
  } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getExclusiones({token});
      getBusquedaProcesos({token}); 
      if (localStorage.getItem("itemSelection")) {
        const tipoProceso: any = localStorage.getItem("itemSelection");
        getListaGeneral({token, tipoProceso: tipoProceso.toUpperCase()});
      } else { 
        getListaGeneral({token});
      }
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaCandidatoDisponible /> 
      <TablaCandidatoNoDisponible /> 
    </>
  );
};

export default TablaRegistroHabilitado;
