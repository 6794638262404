import { useEffect, useState } from "react";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IFormatTime,
  IInputTime,
} from "../../../interfaces/IInput";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";

export default (props: IInputTime) => {
  const {
    required,
    layer,
    placeholder,
    maxLength,
    layerLeft,
    onValueChange,
    onPropertyChange,
    onPermaValues, 
    onValueError,
    errorPosition,
    defaultValue,
    disabled,
  } = props;

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [dateCustomized, setDateCustomized] = useState<string>(defaultValue ?? "");

  useEffect(() => {
    if(defaultValue) {
      setDateCustomized(formatDate({
        date: defaultValue.split(":").join(""),
      }))
    }
  }, [defaultValue])

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    let trigger: boolean = false;
    let eventValidation: string = "initial";
    let valid: boolean = true;
    let dateInput: string = "";

    dateInput = formatDate({
      date: value.split(":").join("").split(":").join(""),
    });

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (required) {
      if (dateInput === "") {
        setMessageError(`es obligatorio`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    if (dateInput !== "") {
      if (!dateInput.match(/^([\d]{2})+([:]{1})+([\d]{2})$/)) {
        setMessageError(`no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
        valid = false;
      }
    }

    if (dateInput !== "" && !trigger) {
      if (!validDate({ date: dateInput })) {
        setMessageError(`no es valida como hora`);
        trigger = true;
        eventValidation = "error";
      }
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);

    setDateCustomized(dateInput);
    if (onPermaValues && onValueChange && onPropertyChange) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange] = {
        value: dateInput,
        valid,
      };
      onValueChange(permaValues);
    }

    if(onValueChange && !onPermaValues && !onPropertyChange){
      onValueChange(dateInput);
      onValueError && onValueError(valid);
    }
  };

  const formatDate = (values: IFormatTime) => {
    const { date } = values;
    const includeBarra: number[] = [1, 3];
    const numberValidation: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    let formatDate: string = "";

    date.split("").map((character, index) => {
      formatDate += `${character}${includeBarra.includes(index) ? ":" : ""}`;
    });

    if (
      !numberValidation.includes(
        formatDate.slice(
          formatDate.split("").length - 1,
          formatDate.split("").length
        )
      )
    ) {
      formatDate = formatDate.slice(0, formatDate.split("").length - 1);
    }

    return formatDate;
  };

  const validDate = (values: IFormatTime) => {
    const { date } = values;
    const { 0: hour, 1: min } = date.split(":");
    //const lastDay = new Date(Number(hour), Number(min));
    if (Number(hour) > 23) {
      return false;
    }

    if (Number(min) < 0 || Number(min) > 59) {
      return false;
    }

    return true;
  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      <div style={layerLeft ? { display: "flex" } : {}}>
        <label className="layer">{layer}</label>
        {triggerError && <span className="layer-error">{messageError}</span>}
        {triggerError && ["up", undefined].includes(errorPosition) && (
          <span className="layer-error">{messageError}</span>
        )}
      </div>

      <div className="box-datepicker">
        <input
          type="text"
          placeholder={placeholder}
          className={categoriesValidation[eventValidation]}
          maxLength={maxLength}
          disabled={disabled}
          value={dateCustomized}
          onChange={({ target }) =>
            handleChangeEventError({ value: target.value })
          }
          onBlur={({ target }) =>
            handleChangeEventError({ value: target.value })
          }
        />
        <AccessTimeIcon />
      </div>
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error down">{messageError}</span>
      )}
    </div>
  );
};
