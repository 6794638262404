import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import * as msal from "@azure/msal-browser";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {
  IState,
  IAsistenciaState,
  IClickAsistencia,
  IValidationCode,
} from "../../interfaces/IAsistencias";
import AsistenciaContext from "./AsistenciaContext";
import AsistenciaReducer from "./AsistenciaReducer";
import { setListaAsistencia, setListaTarget } from "./AsistenciaSet";
import ListaAsistencia from "../../components/pages/ListaAsistencia";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";

export default (props: IAsistenciaState) => {
  const [presentes, setPresentes] = useState<any[]>([]);
  const { children } = props;
  const { toasts, createToast } = useToast();
  const history = useHistory();
  const initialState: IState = {
    servicioId: "",
    userId: sessionStorage.getItem("usuarioId") as string, // "68ff0e04-ccae-481e-a8cf-ed5ede5ac50f",
    profile: sessionStorage.getItem("profile") as string,
    invitationId: "",
    invitationCode: "",
    isActive: undefined,
    asistenciaInterna: true,
    listaAsistencias: [],
    listaTarget: [],
  };

  const [state, dispatch]: any = useReducer<any>(
    AsistenciaReducer,
    initialState
  );

  useEffect(() => {
    const perfile: string = window.sessionStorage.getItem("profile") as string;
    perfile
      ? (initialState.asistenciaInterna = true)
      : (initialState.asistenciaInterna = false);
  }, []);

  // asistencia externa

  const getCodeValidate = async (values: any) => {
    const { code, setError, token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/activar?codigo=${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      if (!data.isActive) {
        return setError(`El código de invitación esta inactivo.`);
      }
      window.location.href = `/asistencia?invitationId=${data.invitationId}&serviceId=${data.serviceId}`;
      setError();
    } catch (error) {
      setError(`Código externo incorrecto. Ingrese nuevo código`);
    }
  };

  const getListaAsistencia = async (values: any) => {
    const { servicioId, invitationId, token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/lista-asistencia?userId=${state.userId}&invitacionId=${invitationId}&servicioId=${servicioId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaAsistencia({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const getListaTarget = async (values: any) => {
    const { servicioId, token } = values;
    try {
      const result = await axios.get(
        // `https://run.mocky.io/v3/c1e984d7-a02c-43ef-91c6-07d3f7e2ee7e` // API DE REAL MOCKS
        `${process.env.REACT_APP_API_URL}servicio?servicioId=${servicioId}&userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      setListaTarget({ dispatch, payload: Array(data) });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const createComentario = async (values: any) => {
    const { comentario, serviceId, invitationId, asistenciaInterna, token } =
      values;
    if (serviceId === null || serviceId === "") {
      return console.log("Error al enviar servicioId");
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}servicio/comentario?servicioId=${serviceId}&userId=${state.userId}&invitacionId=${invitationId}`,
        {
          comentario: comentario == "" ? "Sin comentario" : comentario, // "Comentario de prueba",
          procesos: [], // [82812]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Comentario enviado exitosamente!");
          if (asistenciaInterna) {
            window.location.href = `/asistencia?userId=${state.userId}&serviceId=${serviceId}&invitationId=${invitationId}`;
          } else {
            window.location.href = `/asistencia/lista`;
          }
        }
      })
      .catch((error) => {
        console.log("Ha currió un error:", error);
      });
  };

  const updatePresentes = async (values: any) => {
    const { servicioId, invitationId, success, message, transition, token } =
      values;
    const filteredData = presentes.filter((item) => item.presente === true);
    const busPerId = filteredData.map((item) => item.busPerId);
    const hanleError = () => {
      console.log("Ha currió un error");
      success(false);
      message("Error al Enviar, por favor vuelve a intentarlo");
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}servicio/confirmar-asistencia?servicioId=${servicioId}&invitacionId=${invitationId}&userId=${state.userId}`,
        {
          empty: busPerId.length == 0 ? true : false,
          presentes: busPerId.length == 0 ? "" : busPerId, // [82812]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Asistencia enviado con exito!");
          transition(2);
        }
        success();
        message();
      })
      .catch((error) => {
        hanleError();
      });
  };

  const getLoginAsistenciaInterna = async (values: any) => {
    const { token } = values;
    const code = "0FPB0H";
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio/activar?codigo=${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      window.location.href = `/asistencia?userId=${state.userId}&serviceId=${data.serviceId}&invitationId=${data.invitationId}`;
    } catch (error) {
      console.log("Ha ocurrido un error: " + error);
    }
  };

  const getListaTargetInterna = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}servicio?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaTarget({ dispatch, payload: data });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  // const getTokenSecret = async () => {
  //   const formData = new URLSearchParams();
  //   formData.append("grant_type", "client_credentials");
  //   formData.append("client_id", String(process.env.REACT_APP_MS_CLIENT_ID));
  //   formData.append(
  //     "client_secret",
  //     String(process.env.REACT_APP_MS_CLIENT_SECRET)
  //   );
  //   formData.append("scope", String(process.env.REACT_APP_MS_SCOPE));
  //   try {
  //     const result = await axios.post(
  //       `${process.env.REACT_APP_LOGIN_MS}`,
  //       formData.toString(),
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       }
  //     );
  //     const data = result;
  //     console.log({ data });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getTokenSecret = async () => {
    const formData = new URLSearchParams();
    formData.append("grant_type", String(process.env.REACT_APP_GRANT_TYPE));
    formData.append("client_id", String(process.env.REACT_APP_MS_CLIENT_ID));
    formData.append(
      "client_secret",
      String(process.env.REACT_APP_MS_CLIENT_SECRET)
    );
    formData.append("scope", "openid profile User.Read");
    formData.append("username", String(process.env.REACT_APP_USER_INVITADO));
    formData.append("password", String(process.env.REACT_APP_PASS_INVITADO));
    // console.log(formData.toString()); 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_LOGIN_MS}`,
        formData, 
        { 
          headers: { 
            "Content-Type": "application/x-www-form-urlencoded", 
            "Access-Control-Allow-Origin": "*", 
          }, 
        } 
      ); 
      const data = result; 
      console.log({ data }); 
    } catch (error) { 
      console.log(error); 
    }
  };

  const handleClickAsistencia = (values: IClickAsistencia) => {
    const { index, presente, busPerId } = values;
    const arrayList = [...state.listaAsistencias];
    arrayList[index].presente = presente;
    arrayList[index].busPerId = busPerId;
    setListaAsistencia({ dispatch, payload: arrayList });
    setPresentes(arrayList);
  };

  return (
    <AsistenciaContext.Provider
      value={{
        ...state,
        toasts,
        getCodeValidate,
        getListaAsistencia,
        getListaTarget,
        setListaAsistencia,
        setListaTarget,
        handleClickAsistencia,
        createComentario,
        updatePresentes,
        getListaTargetInterna,
        getLoginAsistenciaInterna,
        getTokenSecret,
      }}
    >
      {children}
    </AsistenciaContext.Provider>
  );
};
