import { useContext, useEffect, useState } from "react";
import Etiquetas from "./Etiquetas";
import ContratosContext from "../../../../context/Contratos/ContratosContext";
import ContratosSolicitados from "./dashboard/contrato/ContratosSolicitados";
import RenovacionesSolicitadas from "./dashboard/contrato/RenovacionesSolicitadas";
import TitleDashboard from "./dashboard/TitleDashboard";
import EstadoSolicitudContrato from "./dashboard/contrato/EstadoSolicitudContrato";
import EstadoSolicitudRenovacion from "./dashboard/contrato/EstadoSolicitudRenovacion";
import { FILTER_PROFILE, LIST_ROLES } from "../../../../diccionary/Diccionary";
import SolicitudesProcesadas from "./dashboard/jefeContrato/SolicitudesProcesadas";
import SolicitudesAnalistas from "./dashboard/jefeContrato/SolicitudesAnalistas";
import ContratosClientes from "./dashboard/jefeContrato/ContratosClientes";
import SolicitudesCliente from "./dashboard/jefeContrato/SolicitudesCliente";

const TablaContratoResumen = () => {
  const { getToken, toasts, profile, roles } = useContext(ContratosContext);
  const [objetoFilterGeneral, setObjetoFilterGeneral] = useState<any>();
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filterContratosResumen"
  );
  const [accessTotal, setAccessTotal] = useState<boolean>(false);
  const [formFilter, setFormFilter] = useState<any>({
    cliente: {
      value: undefined,
      valid: undefined,
    },
    areaNegocio: {
      value: undefined,
      valid: undefined,
    },
    analista: {
      value: undefined,
      valid: undefined,
    },
  });

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const newFilter = JSON.parse(copyStorage);
      setObjetoFilterGeneral(newFilter);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    setAccessTotal(accessTotalContract());
  }, []);

  const accessTotalContract = (): boolean => {
    if (
      [FILTER_PROFILE.adm_tw, FILTER_PROFILE.tw_contrato].includes(profile) &&
      [LIST_ROLES.JEFE_CONTRATO].includes(roles)
    ) {
      return false;
    }

    if ([FILTER_PROFILE.adm_tw].includes(profile)) {
      return false;
    }

    return true;
  };

  return (
    <>
      {toasts}
      <TitleDashboard
        localStorageFilter={localStorageFilter}
        formFilter={formFilter}
        setFormFilter={setFormFilter}
        title="Bienvenido a la Gestión de Contratos"
      />
      <Etiquetas
        objetoFilterGeneral={objetoFilterGeneral}
        localStorageFilter={localStorageFilter}
        accessTotal={accessTotal}
      />
      <section className="container-dashboard">
        <>
          {accessTotal ? (
            <>
              <div className="section-dashboard responsiveFlex">
                <EstadoSolicitudContrato
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-40"
                />
                <ContratosSolicitados
                  objetoFilterGeneral={objetoFilterGeneral}
                />
              </div>
              <div className="section-dashboard responsiveFlex">
                <RenovacionesSolicitadas
                  objetoFilterGeneral={objetoFilterGeneral}
                />
                <EstadoSolicitudRenovacion
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-40"
                />
              </div>
            </>
          ) : (
            <>
              <div className="section-dashboard responsiveFlex">
                <SolicitudesProcesadas
                  heigthDonut={320}
                  widthDonut={380}
                  objetoFilterGeneral={objetoFilterGeneral}
                />
                <EstadoSolicitudContrato
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-30"
                />
                <SolicitudesAnalistas
                  objetoFilterGeneral={objetoFilterGeneral}
                />
              </div>
              <div className="section-dashboard responsiveFlex">
                <EstadoSolicitudRenovacion
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-30"
                />
                <ContratosClientes
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-35"
                />
                <SolicitudesCliente
                  objetoFilterGeneral={objetoFilterGeneral}
                  width="w-35"
                />
              </div>
            </>
          )}
          <p className="MessageGraf">
            El total de los porcentajes no suma 100 ya que los valores no se
            presentan con decimales / Período Gráficos: Últimos 7 días{" "}
          </p>
        </>
      </section>
    </>
  );
};

export default TablaContratoResumen;
