import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import ContratosContext from "../../../../../../context/Contratos/ContratosContext";

interface ISolicitudesAnalistas {
  objetoFilterGeneral?: any;
}

interface IObjetoSend {
  analista: boolean;
  cliente: boolean;
  limiteDias: number;
  filtros: {
    clientes: string[];
    areaNegocio: string[];
    analista: any[];
  };
}
const SolicitudesAnalistas = (props: ISolicitudesAnalistas) => {
  const { objetoFilterGeneral } = props;

  const { getDayOfWeek, listaCliente } = useContext(
    OperacionContratacionContext
  );
  const { token, listaSolicitudCliente, postSolicitudAnalistaCliente } =
    useContext(ContratosContext);

  const [dataChart, setDataChart] = useState<[]>([]);
  const [diasChart, setDiasChart] = useState<[]>([]);

  const [objetoSend, setObjetoSend] = useState<IObjetoSend>({
    analista: false,
    cliente: true,
    limiteDias: 7,
    filtros: {
      clientes: [""],
      areaNegocio: [""],
      analista: [],
    },
  });

  useEffect(() => {
    if (token) {
      const objeto = {
        analista: true,
        cliente: false,
        limiteDias: 7,
        filtros: {
          clientes: objetoFilterGeneral?.cliente,
          areaNegocio:
            objetoFilterGeneral?.areaNegocio !== undefined
              ? objetoFilterGeneral?.areaNegocio
              : [],
          analista: [],
        },
      };
      setObjetoSend(objeto);
      postSolicitudAnalistaCliente({ token, objeto });
    }
  }, [token, objetoFilterGeneral]);

  useEffect(() => {
    if (listaSolicitudCliente && listaSolicitudCliente?.analista?.ingresos) {
      const dataChart:any = listaSolicitudCliente?.analista?.ingresos.map(
        (ingreso: { total: any }) => ingreso.total
      );
      setDataChart(dataChart.reverse())
      const dias = listaSolicitudCliente?.analista?.ingresos.map(
        (ingreso: { fecha: any }) => {
          const dia = getDayOfWeek(ingreso.fecha);
          if (dia === "MIÉRCOLES" || dia === "MARTES") {
            return dia.slice(0, 2);
          } else {
            return dia.slice(0, 1);
          }
        }
      );

      setDiasChart(dias.reverse());
    }
  }, [listaSolicitudCliente]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = diasChart;
  const options = {
    responsive: true,
    padding: 5,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
      datalabels: {
        display: false,
      },
    },
  };
  const data: any = {
    labels,
    datasets: [
      {
        label:
          objetoFilterGeneral?.cliente[0] !== undefined
            ? listaCliente.find(
                (cliente: { clienteId: string | undefined }) =>
                  cliente.clienteId === objetoSend?.filtros.clientes[0]
              )?.clienteNombre
            : "Todos",
        data: dataChart,
        borderColor: "#3B6597",
        backgroundColor: "#3B6597",
      },
    ],
  };

  return (
    <div className="container-table-dashboard w-35 responsiveChart">
      <div className="content-title">
        <label
          className="title-table-dashboard"
          style={{ marginBottom: "5px" }}
        >
          SOLICITUDES POR ANALISTAS
        </label>
      </div>
      <Line options={options} data={data} height={200} />
    </div>
  );
};

export default SolicitudesAnalistas;
