import { useRef, useState  } from "react";
interface IBadd {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IBadd) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
       
       <path d="M3.85714 0.5C1.72969 0.5 0 2.22969 0 4.35714V23.6429C0 25.7703 1.72969 27.5 3.85714 27.5H23.1429C25.2703 27.5 27 25.7703 27 23.6429V4.35714C27 2.22969 25.2703 0.5 23.1429 0.5H3.85714ZM12.0536 19.3036V15.4464H8.19643C7.39487 15.4464 6.75 14.8016 6.75 14C6.75 13.1984 7.39487 12.5536 8.19643 12.5536H12.0536V8.69643C12.0536 7.89487 12.6984 7.25 13.5 7.25C14.3016 7.25 14.9464 7.89487 14.9464 8.69643V12.5536H18.8036C19.6051 12.5536 20.25 13.1984 20.25 14C20.25 14.8016 19.6051 15.4464 18.8036 15.4464H14.9464V19.3036C14.9464 20.1051 14.3016 20.75 13.5 20.75C12.6984 20.75 12.0536 20.1051 12.0536 19.3036Z" fill={fill}/>
    </svg>
  );
};

