interface IIconRegistroCandidato {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconRegistroCandidato) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 16 20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M7.0002 0.5C5.51978 0.5 4.25895 1.38652 3.79499 2.625H2.46686C1.21665 2.625 0.200195 3.57793 0.200195 4.75V15.375C0.200195 16.5471 1.21665 17.5 2.46686 17.5H11.5335C12.7837 17.5 13.8002 16.5471 13.8002 15.375V4.75C13.8002 3.57793 12.7837 2.625 11.5335 2.625H10.2054C9.74144 1.38652 8.48061 0.5 7.0002 0.5ZM7.0002 2.625C7.30077 2.625 7.58904 2.73694 7.80158 2.9362C8.01412 3.13546 8.13353 3.40571 8.13353 3.6875C8.13353 3.96929 8.01412 4.23954 7.80158 4.4388C7.58904 4.63806 7.30077 4.75 7.0002 4.75C6.69962 4.75 6.41135 4.63806 6.19881 4.4388C5.98627 4.23954 5.86686 3.96929 5.86686 3.6875C5.86686 3.40571 5.98627 3.13546 6.19881 2.9362C6.41135 2.73694 6.69962 2.625 7.0002 2.625ZM4.73353 8.625C4.73353 8.06141 4.97234 7.52091 5.39742 7.1224C5.8225 6.72388 6.39904 6.5 7.0002 6.5C7.60135 6.5 8.17789 6.72388 8.60297 7.1224C9.02805 7.52091 9.26686 8.06141 9.26686 8.625C9.26686 9.18859 9.02805 9.72909 8.60297 10.1276C8.17789 10.5261 7.60135 10.75 7.0002 10.75C6.39904 10.75 5.8225 10.5261 5.39742 10.1276C4.97234 9.72909 4.73353 9.18859 4.73353 8.625ZM3.03353 14.8438C3.03353 13.3762 4.30145 12.1875 5.86686 12.1875H8.13353C9.69895 12.1875 10.9669 13.3762 10.9669 14.8438C10.9669 15.1359 10.7119 15.375 10.4002 15.375H3.6002C3.28853 15.375 3.03353 15.1359 3.03353 14.8438Z"
      />
    </svg>
  );
};
