import { ISetRedux } from "../../interfaces/ISeleccion";

export const setListUserAccess = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListUserAccess",
    payload,
  });
};

export const setCountAssign = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetCountAssign",
    payload,
  });
};

export const setListaComprobarEstadoCandidato = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaComprobarEstadoCandidato",
    payload,
  });
};

export const setListaExclusion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaExclusion",
    payload,
  });
};

export const setProcesoVerificarRut = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetProcesoVerificarRut",
    payload,
  });
};

export const setPersonaVerificarRut = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetPersonaVerificarRut",
    payload,
  });
};

export const setListResultAsignaProceso = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListResultAsignaProceso",
    payload,
  });
};

export const setListaCandidatoDisponible = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoDisponible",
    payload,
  });
};

export const setListaCandidatoNoDisponible = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoNoDisponible",
    payload,
  });
};

export const setListaCandidatoInhabilitado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoInhabilitado",
    payload,
  });
};

export const setListaCandidatoPendiente = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPendiente",
    payload,
  });
};

export const setListaCandidatoValidado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoValidado",
    payload,
  });
};

export const setListaCandidatoPendienteMasivo = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPendienteMasivo",
    payload,
  });
};

export const setListaCandidatoValidadoMasivo = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoValidadoMasivo",
    payload,
  });
};

export const setListaCandidatoPreSeleccionPendiente = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPreSeleccionPendiente",
    payload,
  });
};

export const setListaCandidatoPreSeleccion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoPreSeleccion",
    payload,
  });
};

export const setListaCandidatoSeleccionado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoSeleccionado",
    payload,
  });
};

export const setListaCandidatoSolEnviada = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoSolEnviada",
    payload,
  });
};

export const setListaCandidatoSolRecibida = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoSolRecibida",
    payload,
  });
};

export const setListaCandidatoBloqueado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaCandidatoBloqueado",
    payload,
  });
};

export const setListaGrupo = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaGrupo",
    payload,
  });
};

export const setListaUsuarios = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaUsuarios",
    payload,
  });
};

export const setListaGeneral = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaGeneral",
    payload,
  });
};

export const setListaRegistroCandidato = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaRegistroCandidato",
    payload,
  });
};

export const setListGroup = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListGroup",
    payload,
  });
};

export const setListDocument = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListDocument",
    payload,
  });
};

export const setListProcesosActivos = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListProcesosActivos",
    payload,
  });
};

export const setListProcesos = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListProcesos",
    payload,
  });
};

export const setListEvaluacionPsicolaboral = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListEvaluacionPsicolaboral",
    payload,
  });
};

export const setMovilizacionTw = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetMovilizacionTw",
    payload,
  });
};

export const setListaNoProcesadosSeleccion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaNoProcesadosSeleccion",
    payload,
  });
};

export const setListaPostulacionRecibida = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaPostulacionRecibida",
    payload,
  });
};

export const setListaPostulacionProcesada = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaPostulacionProcesada",
    payload,
  });
};

export const setListaProcesoPostulado = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaProcesoPostulado",
    payload,
  });
};

export const setListaRegistroPostulacion = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaRegistroPostulacion",
    payload,
  });
};

export const setInfoAntecedentes = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetInfoAntecedentes",
    payload,
  });
};
export const setListaFormatoPsicolaboral = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaFormatoPsicolaboral",
    payload,
  });
};

export const setListaReportePdfPsicolaboral = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaReportePdfPsicolaboral",
    payload,
  });
};

export const SetListBateriaDocument = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListBateriaDocument",
    payload,
  });
};
export const setListResumenProcesadosNomina = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListResumenProcesadosNomina",
    payload,
  });
};
export const setEmptyMessage = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetEmptyMessage",
    payload,
  });
}