interface IIconExtenderTiempo {
  width?: number;
  height?: number;
  fill?: string;
  color?: any;  
  style?: any;
  className?: any; 
}

export default (props: IIconExtenderTiempo) => {
  const { width, height, fill, style, className, color } = props;

  return (
    <svg
      width={`${width ? width : "45"}`}
      height={`${height ? height : "46"}`}
      viewBox="0 0 45 46"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="23" r="22.5" className=""/>
      <path
        d="M33.2923 21.3824C33.2923 23.5572 32.4284 25.6429 30.8906 27.1806C29.3528 28.7184 27.2671 29.5823 25.0924 29.5823C22.9176 29.5823 20.8319 28.7184 19.2942 27.1806C17.7564 25.6429 16.8925 23.5572 16.8925 21.3824C16.8925 19.2077 17.7564 17.122 19.2942 15.5842C20.8319 14.0464 22.9176 13.1825 25.0924 13.1825C27.2671 13.1825 29.3528 14.0464 30.8906 15.5842C32.4284 17.122 33.2923 19.2077 33.2923 21.3824ZM15.0002 21.3824C15.0002 24.059 16.0635 26.626 17.9561 28.5187C19.8488 30.4114 22.4158 31.4746 25.0924 31.4746C27.769 31.4746 30.336 30.4114 32.2287 28.5187C34.1213 26.626 35.1846 24.059 35.1846 21.3824C35.1846 18.7058 34.1213 16.1388 32.2287 14.2462C30.336 12.3535 27.769 11.2902 25.0924 11.2902C22.4158 11.2902 19.8488 12.3535 17.9561 14.2462C16.0635 16.1388 15.0002 18.7058 15.0002 21.3824ZM24.1462 16.8283V21.3824C24.1462 21.6978 24.3039 21.9935 24.5681 22.1709L28.3527 24.6939C28.7863 24.9857 29.3737 24.8674 29.6654 24.4298C29.9572 23.9922 29.8389 23.4088 29.4013 23.117L26.0385 20.8778V16.8283C26.0385 16.304 25.6167 15.8822 25.0924 15.8822C24.5681 15.8822 24.1462 16.304 24.1462 16.8283Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9213 9.9679C15.2634 10.3738 15.2118 10.9801 14.8059 11.3223C9.02277 16.1973 8.26743 24.862 13.1212 30.6733C17.9729 36.482 26.5903 37.2386 32.3708 32.3657C32.7766 32.0236 33.383 32.0752 33.7251 32.4811C34.0673 32.887 34.0156 33.4934 33.6098 33.8355C27.0138 39.3958 17.1793 38.5306 11.6458 31.9056C6.11457 25.2832 6.97355 15.4106 13.5669 9.85251C13.9728 9.51037 14.5791 9.56203 14.9213 9.9679Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7115 7.70781C16.9807 7.92106 17.1156 8.2617 17.0655 8.60141L16.1365 14.9005C16.059 15.4257 15.5705 15.7886 15.0453 15.7112C14.5202 15.6337 14.1573 15.1452 14.2347 14.62L14.9213 9.9679L10.5489 11.6998C10.0554 11.8953 9.49681 11.6537 9.30131 11.1602C9.10581 10.6666 9.3474 10.1081 9.84093 9.91256L15.7606 7.56757C16.0799 7.4411 16.4424 7.49456 16.7115 7.70781Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
    </svg>
  );
};
