import React, { useContext, useEffect, useState } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import api from "../../services/api";
import { Galleria } from "primereact/galleria";
import { EventType } from "@azure/msal-browser";
import userProfile from "../../context/candidate/userProfile";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { useProfileContext } from "../../hooks/useRut/useProfileContext";

const HomePage: React.FC<IPage> = (props) => {
  const [routeProfile, setRouteProfile] = useState(false);
  const { instance } = useMsal();
  //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [images, setImages] = useState<any>([]);

  //<img src={'/assets/layout/images/logo-teamwork-chile.png'} alt="logo"/>

  const data = [
    {
      itemImageSrc: "/assets/images/slide3.png",
      thumbnailImageSrc: "images/galleria3s.jpg",
      alt: "Description for Image 3",
      textHeader: "Aquí podrás",
      textFooter:
        "Gestionar la selección de todos los candidatos en un solo lugar",
    },
  ];

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    setImages(data);
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;        
        api
          .get(`usuario/${result.account.localAccountId}`, {
            headers: {
              Authorization: `Bearer ${result.idToken}`,
            },
          })
          .then((response) => {
            setRouteProfile(true);
            const profile: userProfile = {
              usuarioId: response.data.data[0].usuarioId,
              usuarioNombre: response.data.data[0].usuarioNombre,
              perfil: response.data.data[0].perfil,
            };
            window.sessionStorage.setItem("profile",response.data.data[0].perfil );
            window.sessionStorage.setItem("usuarioId",response.data.data[0].usuarioId);
            window.sessionStorage.setItem("token", result.idToken);
            window.sessionStorage.setItem("tokenExpires", result.expiresOn);
            window.sessionStorage.setItem("roles", JSON.stringify(response.data.data[0].roles));
          }).catch((e) => {   
            if(e.response){
              if(e.response.status == 404)
                setRouteProfile(true);               
            }      
            
          });
      }
    });
  }, []);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const handleLogin = (loginType: string) => {
    //setAnchorEl(null);
    if (loginType === "popup") {
      //instance.loginPopup(loginRequest);
      instance.loginRedirect(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="cardHome">
        <div className="divTexto">
          <span className="textHeader">{item.textHeader}</span>
        </div>
        <div className="divImagen">
          <img
            src={item.itemImageSrc}
            onError={(e: any) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.alt}
            style={{ width: "396px", height: "344px", display: "flex" }}
          />
        </div>
        <div className="">
          <span className="textFooter">{item.textFooter}</span>
        </div>
      </div>
    ); /*<div className='cardHome'><div className="divTexto"><span className="textHeader">{ item.textHeader }</span></div><div className='imagen'><img className="imagen" src={item.itemImageSrc} onError={(e:any) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '396px', height: '344px', display:'flex', marginTop:'-250px' }} /></div><div className="divTexto"><span className="textFooter">{ item.textFooter }</span></div></div>;*/
  };

  const thumbnailTemplate = (item: any) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        onError={(e: any) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  return (
    <>
      <div className="grid marginPaddingBorder0">
        <div className="col-6 marginPaddingBorder0">
          <div className="flex layout-home-container">
            <div className="flex-1 flex align-items-center justify-content-center">
              <Galleria
                value={images}
                responsiveOptions={responsiveOptions}
                numVisible={5}
                circular
                style={{ maxWidth: "640px" }}
                showItemNavigators
                showThumbnails={false}
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                autoPlay={true}
              />
            </div>
          </div>
        </div>
        <div className="col-6 displayComplete marginPaddingBorder0 backgroundColorBlanco">
          <AuthenticatedTemplate>
            {routeProfile ? <Redirect to="/router" /> : ""}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="backgroundColorBlanco flex flex-column card-container justify-content-center py-8">
              <div className="surface-0 text-700 text-center pt-8">
                <div className="text-blue-600 font-bold mb-3">
                  <img
                    src="/assets/layout/images/logo-teamwork-chile.png"
                    alt=""
                  />
                </div>
                <div className="marginTop34px text-900 font-bold text-5xl color333333">
                  Especialistas en gestión de <br />
                  Recursos Humanos
                </div>
                <div className="marginTop34px text-700 text-2xl color333333">
                  Ofrecemos pago de remuneraciones, servicios transitorios,
                  consultoría,
                  <br /> capacitación, bolsa de trabajo y mucho más...
                </div>
                <div className="marginTop34px">
                  <Button
                    onClick={() => handleLogin("popup")}
                    key="loginPopup"
                    label="Ingresar"
                    className="font-bold px-5 py-3 p-button "
                    icon="pi pi-arrow-right"
                    iconPos="right"
                  ></Button>
                </div>
              </div>
            </div>
          </UnauthenticatedTemplate>
        </div>
      </div>
    </>
  );
};

export default HomePage;
