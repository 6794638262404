import { useEffect } from "react";
import { Button } from "primereact/button";
import "../../../assets/css/adminModal.min.css";
import CloseIcon from '@mui/icons-material/Close';

interface INewServiceModal {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
}

export default (props: INewServiceModal) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, width } = props;

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          {subTitle ? (
            <>
              <div className="modal-subtitle">
                <span>{subTitle}</span>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="modal-message">
            <span>{message}</span>
          </div>

          <span className="modal-title-table">Proceso Asignado</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Proceso</div>
              <div className="project-table-subtitle">
                123456 - AUXILIAR DE OLLA TARDE
              </div>
            </div>
            <div className="content">
              <div className="project-table-title">Cliente</div>
              <div className="project-table-subtitle">CAROZZI</div>
            </div>
            <div className="content">
              <div className="project-table-title">Candidatos</div>
              <div className="project-table-subtitle">10</div>
            </div>
            <div className="content">
              <div className="project-table-title">Destino</div>
              <div className="project-table-subtitle">
                Av. El Salto 4447, Huechuraba, Región Metropolitana
              </div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/23</div>
            </div>
            <div className="content">
              <div className="project-table-title">Hora</div>
              <div className="project-table-subtitle">14:15</div>
            </div>
          </div>

          <span className="modal-title-table">Transporte Asignado</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Tipo</div>
              <div className="project-table-subtitle">Externos</div>
            </div>
            <div className="content">
              <div className="project-table-title">Nombre</div>
              <div className="project-table-subtitle">Olegario Cepeda Asenjo</div>
            </div>
            <div className="content">
              <div className="project-table-title">Correo</div>
              <div className="project-table-subtitle">nombre@correo.cl</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fono</div>
              <div className="project-table-subtitle">+569 12345678</div>
            </div>
          </div>

          <span className="modal-title-table">Ruta Asignado</span>
          <div className="project-table">
            <div className="content">
              <div className="project-table-title">Inicio</div>
              <div className="project-table-subtitle">Av. El Salto 4447, Huechuraba, Región Metropolitana</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/2023</div>
            </div>
            <div className="content">
              <div className="project-table-title">Hora</div>
              <div className="project-table-subtitle">14:15</div>
            </div>
            <div className="content">
              <div className="project-table-title">Destino</div>
              <div className="project-table-subtitle">Av. El Salto 4447, Huechuraba, Región Metropolitana</div>
            </div>
            <div className="content">
              <div className="project-table-title">Fecha</div>
              <div className="project-table-subtitle">29/01/2023</div>
            </div>
            <div className="content">
              <div className="project-table-title">Hora</div>
              <div className="project-table-subtitle">15:15</div>
            </div>
          </div>

          <div className="modal-message">
            <span>
              Si la información está correcta selecciona guardar, para editar
              presiona volver.
            </span>
          </div>
          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) onChangeBack();
              }}
            >
              Cancelar
            </Button>
            <Button
              className="button-go-newservice"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Crear Servicio
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
