function formatRUT(rut: string): string {
  let rutIngresado = "";
  let rutFormateado = "";
  rutIngresado = rut;
  rutIngresado.trim();

  rutIngresado = rutIngresado.replace("Kk", "K");
  rutIngresado = rutIngresado.replace("kk", "K");
  rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");

  //console.log("rutIngresado en función:", rutIngresado)

  if (rutIngresado.length > 8) {
    rutFormateado = rutIngresado.replace(/[^kK1234567890]/g, "");
  } else {
    rutFormateado = rutIngresado.replace(/[^1234567890]/g, "");
  }

  rutFormateado = rutFormateado.replace("Kk", "K");
  rutFormateado = rutFormateado.replace("kk", "K");
  rutFormateado = rutFormateado.replace(/k[\d]/gi, "K");

  if (rutFormateado.length == 2) {
    rutFormateado =
      rutFormateado.substring(0, 1) + "-" + rutFormateado.substring(1, 2);
  } else if (rutFormateado.length == 3) {
    rutFormateado =
      rutFormateado.substring(0, 2) + "-" + rutFormateado.substring(2, 3);
  } else if (rutFormateado.length == 4) {
    rutFormateado =
      rutFormateado.substring(0, 3) + "-" + rutFormateado.substring(3, 4);
  } else if (rutFormateado.length == 5) {
    rutFormateado =
      rutFormateado.substring(0, 1) +
      rutFormateado.substring(1, 4) +
      "-" +
      rutFormateado.substring(4, 5);
  } else if (rutFormateado.length == 6) {
    rutFormateado =
      rutFormateado.substring(0, 2) +
      rutFormateado.substring(2, 5) +
      "-" +
      rutFormateado.substring(5, 6);
  } else if (rutFormateado.length == 7) {
    rutFormateado =
      rutFormateado.substring(0, 3) +
      rutFormateado.substring(3, 6) +
      "-" +
      rutFormateado.substring(6, 7);
  } else if (rutFormateado.length == 8) {
    rutFormateado = rutFormateado.toUpperCase();
    rutFormateado =
      rutFormateado.substring(0, 1) +
      rutFormateado.substring(1, 4) +
      rutFormateado.substring(4, 7) +
      "-" +
      rutFormateado.substring(7, 8);
  } else if (rutFormateado.length == 9) {
    rutFormateado = rutFormateado.toUpperCase();
    rutFormateado =
      rutFormateado.substring(0, 2) +
      rutFormateado.substring(2, 5) +
      rutFormateado.substring(5, 8) +
      "-" +
      rutFormateado.substring(8, 9);
  } else if (rutFormateado.length >= 10) {
    rutFormateado =
      rutFormateado.substring(0, 2) +
      rutFormateado.substring(2, 5) +
      rutFormateado.substring(5, 8) +
      "-" +
      rutFormateado.substring(8, 9);
  }

  return rutFormateado;
}

export default formatRUT;
