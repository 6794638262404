import { useContext, useEffect, useState, lazy, Suspense } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconDescargaNomina from "../../../../ui/icons-seleccion/IconDescargaNomina";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalAvanzarProceso from "../../../../ui/kit-modal/avanzarProceso/ModalAvanzarProceso";
import IconCheck from "../../../../ui/icons-seleccion/IconCheck";
import {
  IEstadosExcel,
  IFiltrosExcel,
  IMovilizacionTw,
} from "../../../../../interfaces/ISeleccion";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import IconCargando from "../../../../ui/icons-seleccion/IconCargando";
import IconDescargaRegistro from "../../../../ui/kit-svg/icon-preseleccionado/IconDescargaRegistro";
const LazyNoProcesados = lazy(
  () => import("../../../../ui/kit-modal/noProcesado/ModalNoProcesadoSeleccion")
);

interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  localStorageSelect: string;
  setFormFilter: Function;
  formFilter: any;
  handleAvanzarProceso: Function;
  candidates: any;
  setCandidates: Function;
  listRefresh: Function;
  setListadoSeleccionado: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    localStorageSelect,
    setFormFilter,
    formFilter,
    handleAvanzarProceso,
    setCandidates,
    candidates,
    listRefresh,
    setListadoSeleccionado,
  } = props;
  const {
    token,
    listMovilizacionTw,
    putMetodoMovilizacion,
    putConfirmarEntrevista,
    listaCandidatoSeleccionado,
    listaNoProcesadosSeleccion,
    postGenerarExcel,
    downloadRegistroCandidato,
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [buttonEnabledGo, setButtonEnabledGo] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [isOpenModalAvanzar, setIsOpenModalAvanzar] = useState<boolean>(false);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [currentProcesos, setCurrentProcesos] = useState<string[]>([]);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean | undefined>(
    undefined
  );
  const estadosExcel: IEstadosExcel = {
    porValidar: false,
    preseleccionado: false,
    validado: true,
  };
  const filtrosExcel: IFiltrosExcel = {
    busPerIdList: [],
    reclutador: [],
    estadoRegistro: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    tipoProceso: ["MASIVO"],
    fechaPostulacion: [],
    fechaValidacion: [],
    fechaPreseleccion: [],
    confirmaEntrevista: null,
    transporteTeamwork: null,
    entrevistaRealizada: null,
  };

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoSeleccionado && listaCandidatoSeleccionado.length > 0) {
      const listProcesos = [...listaCandidatoSeleccionado];
      const arrayProcesos: string[] = listProcesos
        .map(({ grupos }: any) => grupos)
        .flat()
        .map(({ procesos }: any) => procesos)
        .flat()
        .map(({ personas }: any) => personas)
        .flat()
        .map((item: any) => item);
      setCurrentProcesos(arrayProcesos);
    }
  }, [listaCandidatoSeleccionado]);

  useEffect(() => {
    if (listadoSeleccionado.length > 0) {
      const candidate = currentProcesos.filter((item: any) =>
        listadoSeleccionado.includes(item.personaDNI)
      );
      const avanza = candidate.some(
        (item: any) => item.registroCompleto && item.confirmaEntrevista
      );
      setButtonEnabledGo(avanza);
    } else {
      setButtonEnabledGo(false);
    }
  }, [listadoSeleccionado, currentProcesos]);

  const handleClickBloquearCandidato = (): void => {
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (): void => {
    setIsOpenModalDescartar(true);
  };

  const handleClickAvanzarProceso = (): void => {
    setIsOpenModalAvanzar(true);
  };

  const handleAvanzarCandidate = (): void => {
    handleAvanzarProceso({ setIsOpenModalNoProcesado, localStorageSelect });
    setIsOpenModalAvanzar(false);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "auto";
    }
    if (token) {
      listRefresh();
      setListadoSeleccionado([]);
    }
  };

  const handleClickConfirmarEntrevista = async (): Promise<void> => {
    try {
      if (token) {
        const arrayList = currentProcesos.filter((item: any) =>
          listadoSeleccionado.includes(item.personaDNI)
        );
        const personas = arrayList.map(({ busPerId }: any) => {
          return {
            busPerId,
            confirmaEntrevista: true,
          };
        });
        await putConfirmarEntrevista({ token, personas });
        await listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickTransporteTw = async (): Promise<void> => {
    try {
      if (token && listMovilizacionTw) {
        const arrayList = currentProcesos.filter((item: any) =>
          listadoSeleccionado.includes(item.personaDNI)
        );
        const personas = arrayList.map((item: any) => {
          const { busPerId, movilizacion } = item;
          const currentState = listMovilizacionTw.find(
            ({ nombre }: IMovilizacionTw) => nombre !== movilizacion
          );
          return {
            busPerId,
            movilizacionId: currentState.movilizacionId,
          };
        });

        await putMetodoMovilizacion({ token, body: personas });
        await listRefresh();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNomina = async (): Promise<void> => {
    if (token) {
      try {
        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          const dataObject = JSON.parse(copyStorage);
          filtrosExcel.proceso = dataObject.proceso?.map((valor: any) =>
            parseInt(valor)
          );
          filtrosExcel.cliente = [...dataObject.cliente];
        }
        await postGenerarExcel({
          token,
          estadosExcel,
          filtrosExcel,
          setIsLoading: setIsLoadingExcel,
        });
      } catch (error) {
        console.log("error descarga");
      }
    }
  };

  const handleDownloadRegistro = () => {
    const registroCandidato = currentProcesos
      .filter((item: any) => listadoSeleccionado.includes(item.personaDNI))
      .map(({ busPerId }: any) => busPerId);
    downloadRegistroCandidato({ token, registros: registroCandidato });
  };

  return (
    <>
      <div className="container-actions w-100">
        <div className="content-button-masive">
          <button
            className={`button-navegation ${
              buttonEnabledGo ? "verde" : "disabled"
            }`}
            onClick={() => handleClickAvanzarProceso()}
            disabled={buttonEnabledGo ? false : true}
          >
            Enviar a Operaciones
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
          <Tooltip text="Descarga Registro">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-azul" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleDownloadRegistro}
            >
              <IconDescargaRegistro fill="#0065DD" disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Confirmar Entrevista">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-naranjo" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickConfirmarEntrevista}
            >
              <IconCheck fill={"#FF4900"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Transporte TW">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-reasignar" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickTransporteTw}
            >
              <AirportShuttleIcon fill={"#FF4900"} />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickBloquearCandidato}
            >
              <IconBloquear fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Descartar">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickDescartarCandidato}
            >
              <IconDescarte fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Descargar Nómina">
            <button
              className={`button-actions ${
                isLoadingExcel ? "disabled file" : "active-file"
              }`}
              onClick={() => handleClickNomina()}
            >
              {isLoadingExcel ? (
                <IconCargando fill="#327a30" />
              ) : (
                <IconDescargaNomina fill="#327a30" />
              )}
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["COMPLETO", "INCOMPLETO", "1RA VALIDACIÓN"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="FECHA VALIDACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaValidacion"
          />
          <InputFiltro
            layer="FECHA PRESELECCIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaPreseleccion"
          />
          <StateFiltro
            layer="CONFIRMA ENTREVISTA"
            listState={["SI", "NO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="confirmaEntrevista"
          />
          <StateFiltro
            layer="TRANSPORTE TEAMWORK"
            listState={["SI", "NO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="transporteTeamwork"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAvanzar && (
        <ModalGenerico
          title="CONFIRMAR MOVIMIENTO DE ETAPA"
          width="w-70"
          subtitle="Estás por mover de etapa a la siguiente selección: "
          onChangeBack={() => {
            setIsOpenModalAvanzar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleAvanzarCandidate()}
          buttonBackText="Cancelar"
          buttonGoText="Aceptar"
        >
          <ModalAvanzarProceso
            listCandidate={listadoSeleccionado}
            listData={currentProcesos}
            setCandidates={setCandidates}
            etapa="Operaciones"
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Candidatos No Enviados"
          iconTitle="danger"
          width="w-70"
          subtitle="Los siguiente candidatos no pudieron ser procesados:"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cerrar"
        >
          <Suspense fallback={<div>Cargando pruebitas...</div>}>
            <LazyNoProcesados list={listaNoProcesadosSeleccion ?? []} />
            {/* <ModalNoProcesadoSeleccion list={listaNoProcesadosSeleccion ?? []} /> */}
          </Suspense>
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoSeleccionado ?? []}
            localStorageFilter={localStorageFilter}
            localStorageSelect={localStorageSelect}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoSeleccionado ?? []}
            localStorageFilter={localStorageFilter}
            localStorageSelect={localStorageSelect}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
