interface IIconDescarte {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconDescarte) => {
  const { disabled, fill, className } = props;

  return ( 
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M14.0857 2.39091C14.6323 1.84429 14.6323 0.956583 14.0857 0.409964C13.539 -0.136655 12.6513 -0.136655 12.1047 0.409964L7.5 5.01905L2.89091 0.414337C2.34429 -0.132282 1.45658 -0.132282 0.909964 0.414337C0.363345 0.960956 0.363345 1.84866 0.909964 2.39528L5.51905 7L0.914337 11.6091C0.367718 12.1557 0.367718 13.0434 0.914337 13.59C1.46096 14.1367 2.34866 14.1367 2.89528 13.59L7.5 8.98095L12.1091 13.5857C12.6557 14.1323 13.5434 14.1323 14.09 13.5857C14.6367 13.039 14.6367 12.1513 14.09 11.6047L9.48095 7L14.0857 2.39091Z"
      />
    </svg>
  );
};
