interface IconPageRenovacion {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconPageRenovacion) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "16"}`}
        viewBox="0 0 15 16"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M3 4.625H10.5V3.5H3V4.625ZM3 7.8125H7.2375C7.4625 7.5875 7.70625 7.3775 7.96875 7.1825C8.23125 6.9875 8.50875 6.8225 8.79375 6.6875H3V7.8125ZM3 11H5.67375C5.70375 10.8013 5.7375 10.6063 5.7825 10.4188C5.8275 10.2313 5.88 10.0513 5.94375 9.875H3V11ZM1.125 14C0.81375 14 0.55125 13.8912 0.33 13.67C0.10875 13.4487 0 13.1862 0 12.875V1.625C0 1.31375 0.10875 1.05125 0.33 0.83C0.55125 0.60875 0.81375 0.5 1.125 0.5H12.375C12.6862 0.5 12.9487 0.60875 13.17 0.83C13.3912 1.05125 13.5 1.31375 13.5 1.625V6.59375C13.3237 6.51875 13.1437 6.45125 12.9562 6.3875C12.7687 6.32375 12.5737 6.275 12.375 6.2375V1.625H1.125V12.875H5.7375C5.775 13.0737 5.82375 13.2687 5.8875 13.4562C5.95125 13.6437 6.01875 13.8237 6.09375 14H1.125ZM8.595 14.405C7.86375 13.6738 7.5 12.7887 7.5 11.75C7.5 10.7113 7.86375 9.83 8.595 9.095C9.32625 8.36375 10.2113 8 11.25 8C11.7638 8 12.2437 8.0975 12.6937 8.28875C13.1437 8.48375 13.5375 8.7425 13.875 9.06875V8H15V11H12V9.875H13.0875C12.8513 9.63875 12.5738 9.455 12.2625 9.32C11.9513 9.18875 11.6138 9.125 11.25 9.125C10.5262 9.125 9.9075 9.38 9.39375 9.89375C8.88 10.4075 8.625 11.0262 8.625 11.75C8.625 12.4738 8.88 13.0925 9.39375 13.6063C9.9075 14.12 10.5262 14.375 11.25 14.375C11.85 14.375 12.3787 14.1987 12.8363 13.85C13.2937 13.5012 13.6012 13.0513 13.7662 12.5H14.9288C14.7525 13.3625 14.325 14.0788 13.6462 14.645C12.9637 15.215 12.1687 15.5 11.2537 15.5C10.215 15.5 9.33375 15.1363 8.59875 14.405H8.595Z" 
       fill={fill}/> 
      </svg>



    );
  };
