import { useContext, useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconAsignarProceso from "../../../../ui/icons-seleccion/IconAsignarProceso";
import IconBloquearDescartar from "../../../../ui/icons-seleccion/IconBloquear";
import ModalAsignarProceso from "../Modal/ModalAsignarProceso";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
interface IActionMasive {
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
  listSelect: any;
  listRefresh: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    listSelect,
    setListadoSeleccionado,
    listRefresh,
  } = props;
  const { listaCandidatoDisponible } = useContext(SeleccionContext);

  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickAsignarProceso = () => {
    setIsOpenModalAsignarProceso(true);
  };

  const handleClickBloquearCandidato = () => {
    setIsOpenModalBloquear(true);
  };

  return (
    <>
      <div className="container-actions">
        <div className="content-actions-masive">
          <Tooltip text="Asignar Proceso">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-reasignar" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickAsignarProceso}
            >
              <IconAsignarProceso fill={"#0065DD"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickBloquearCandidato}
            >
              <IconBloquearDescartar
                fill={"#BE2A2A"}
                disabled={!buttonEnabled}
              />
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["RECLUTADO", "POSTULADO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="FUENTE DE RECLUTAMIENTO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fuenteReclutamiento"
          />
          <InputFiltro
            layer="FECHA POSTULACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaPostulacion"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAsignarProceso && (
        <ModalGenerico
          title="Asignar Proceso"
          width="w-70"
          subtitle="Selecciona Grupo y/o Proceso a asignar a la selección"
          onChangeBack={() => {
            setIsOpenModalAsignarProceso(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalAsignarProceso
            list={listadoSeleccionado}
            setListadoSeleccionado={setListadoSeleccionado}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            listSelect={listaCandidatoDisponible}
            getListRefresh={listRefresh}
            onChangeBack={() => {
              setIsOpenModalAsignarProceso(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
          />
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          tipo="bloqueo"
          width="w-60"
          subtitle="Estás bloqueando candidatos: "
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            listSelect={listSelect}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            setListadoSeleccionado={setListadoSeleccionado}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
