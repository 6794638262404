interface IFormatFirstLetterUpper {
  texto: string;
}

interface IFormatFullName {
  texto: string;
}

interface IFormatLetterCapitalize {
  texto: string;
}

export const formatFirstLetterUpper = (
  values: IFormatFirstLetterUpper
): string => {
  const { texto } = values;
  if (!texto) {
    return texto;
  }
  return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
};

export const formatNameUpper = (values: IFormatFullName): string => {
  const { texto } = values;
  if (!texto) {
    return texto;
  }
  const words = texto.split(" ");
  const formattedName = words.map((word):string => {
    return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
  return formattedName;
};

export const formatLetterCapitalize = (values: IFormatLetterCapitalize): string => {
  const { texto } = values;
  if (!texto) {
    return texto;
  }
  const words = texto.split(" ");
  const capitalizedText = words.map((word):string => {
    // Convierte las dos primeras letras a mayúsculas y el resto a minúsculas
    return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
  return capitalizedText;

  return capitalizedText;
};
