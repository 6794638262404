import { IIconTw } from "../../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 22 20"
      fill={disabled ? "#aeaeae" : fill}
      stroke={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M9.0649 9L8.85581 15.173H7.3649L7.15581 9H9.07399H9.0649ZM8.83762 17.7406C8.65581 17.9195 8.41035 18 8.11035 18C7.81035 18 7.57399 17.9105 7.38308 17.7406C7.20126 17.5616 7.11035 17.3469 7.11035 17.0696C7.11035 16.7922 7.20126 16.5775 7.38308 16.3986C7.5649 16.2197 7.81035 16.1302 8.11035 16.1302C8.41035 16.1302 8.65581 16.2197 8.83762 16.3897C9.01944 16.5686 9.11035 16.7922 9.11035 17.0606C9.11035 17.329 9.01944 17.5527 8.83762 17.7316V17.7406Z" 
    fill={fill}/>
<path d="M1.68339 20.9738C1.26391 20.9738 0.896868 20.8165 0.582262 20.5019C0.267655 20.1873 0.110352 19.8202 0.110352 19.4007V1.57303C0.110352 1.15356 0.267655 0.786517 0.582262 0.47191C0.896868 0.157303 1.26391 0 1.68339 0H10.5972L16.8894 6.29213V21H15.3163V7.34082H9.54855V1.57303H1.68339V19.4007H15.3163V20.9738H1.68339Z" 
fill={fill}/>
    </svg>
  );
};

