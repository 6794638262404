import '../../../assets/css/adminComponentUi.min.css';

interface IButtonFilter {
    key?: number;
    children?: any;
    disabled?: boolean; 
    onClick?: Function;
    extraClass?: any;
}

export default (props: IButtonFilter) => {
    const { key, children, disabled, onClick, extraClass } = props; 
    return (
        <>
        <button
            key={key}
            onClick={() => {if (onClick) onClick()}}
            disabled={disabled ? disabled : false}
            className={`button-filter ${extraClass ? extraClass : ''}`}
        >
        {children}
        </button>
        </>
    );
}