import { useState, useEffect, useContext } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IRutas, IProcesos } from "../../../interfaces/IAsistencias";
import { formatDateToDDMMAAAA } from "../../../functions/kit-mdtw/dates";
import { formatHourToMMSS } from "../../../functions/kit-mdtw/hours";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";

interface ITarget {
  servicioId: string | number;
  tipoServicio: string;
  estadoServicio: string;
  tipoTransporte: string;
  fechaCrecion: Date;
  rutas: IRutas[];
  procesos?: IProcesos[];
  history?: any;
  route?: string;
}

export default (props: ITarget) => {
  const {
    servicioId,
    estadoServicio,
    rutas,
    procesos,
  } = props;

  const { asistenciaInterna } = useContext(AsistenciaContext);
  const [primeraRuta] = useState<IRutas>(rutas[0]);
  const ultimaRuta = rutas[rutas.length - 1];
  const otrasRutas:IRutas[] = rutas.slice(1, -1);
  const searchParams = new URLSearchParams(location.search);
  const invitationId = searchParams.get('invitationId');
  const userId = searchParams.get('userId');

  const goToAsistencia = () => {
    window.location.href = `/asistencia/lista?userId=${userId}&serviceId=${servicioId}&invitationId=${invitationId}`;
  };

  const border = (value: any) => {
    const {estadoServicio} = value;
    if (asistenciaInterna) {
      if (estadoServicio === "CREADO") {
        return 'border-orange'
      } else if ( estadoServicio === "ENVIADO") {
        return 'border-red'
      } else if (estadoServicio === "PROGRAMADO" || estadoServicio === "ACTIVADO" ) {
        return 'border-red'
      } else if (estadoServicio === "FINALIZADO") {
        return 'border-green'
      } else {
        return 'border-pink'
      }
    } else {
      return 'border-pink'
    }
  } 

  const handleIconTarget = (value: any) => {
    const {estadoServicio} = value;
    if (asistenciaInterna) {
      if (estadoServicio === "FINALIZADO") {
        return (
          <div className="list-icon">
            <ChatBubbleOutlineIcon className="icon-message" />
            <CheckCircleOutlineIcon className="icon-check" />
            <ArrowForwardIcon
              className="icon-rigth"
              onClick={() => goToAsistencia()}
            />
          </div>
        )
      } else {
        return (
          <div className="list-icon">
            <ArrowForwardIcon
              className="icon-rigth"
              onClick={() => goToAsistencia()}
            />
          </div>
        )
      }
    } else {
      return (
        <></>
      )
    }
  }

  return (
    <>
      <div className="target" key={servicioId}>
        <div className={`content ${border({estadoServicio})}`}>
          <div className="list">
            <span className="descrip-black">Servicio:</span>
            <span className="descrip-grey">{servicioId}</span>
            <KeyboardArrowDownIcon className="icon-down" />
          </div>

          {Array(primeraRuta).map((ruta, i) => {
            return (
              <>
                <div className="list" key={i}>
                  <span className="descrip-black">Inicio:</span>
                  <span className="descrip-grey">{`${formatDateToDDMMAAAA({
                    date: ruta.fecha,
                  })} ${formatHourToMMSS({ date: ruta.fecha })}`}</span>
                  <span className="descrip-black">Direccion:</span>
                  <span className="descrip-grey">{`${ruta.direccion}, ${ruta.comuna}, ${ruta.region}`}</span>
                </div>
              </>
            );
          })}

          {otrasRutas.map((ruta, i) => {
            return (
              <>
                <div className="list" key={i}>
                  <span className="descrip-black">Parada:</span>
                  <span className="descrip-grey">{`${formatDateToDDMMAAAA({
                    date: ruta.fecha,
                  })} ${formatHourToMMSS({ date: ruta.fecha })}`}</span>
                  <span className="descrip-black">Direccion:</span>
                  <span className="descrip-grey">{`${ruta.direccion}, ${ruta.comuna}, ${ruta.region}`}</span>
                </div>
              </>
            );
          })}

          {Array(ultimaRuta).map((ruta, i) => {
            return (
              <>
                <div className="list" key={i}>
                  <span className="descrip-black">Destino:</span>
                  <span className="descrip-grey">{`${formatDateToDDMMAAAA({
                    date: ruta.fecha,
                  })} ${formatHourToMMSS({ date: ruta.fecha })}`}</span>
                  <span className="descrip-black">Direccion:</span>
                  <span className="descrip-grey">{`${ruta.direccion}, ${ruta.comuna}, ${ruta.region}`}</span>
                </div>
              </>
            );
          })}

          {procesos &&
            procesos.map((process, index) => (
              <div className="list" key={index}>
                <span className="descrip-black">Proceso:</span>
                <span className="descrip-grey">{`${process.busquedaId} - ${process.cargoGroupName} - ${process.areaNegocioNombre ? process.areaNegocioNombre : process.clienteNombre}`}</span>
                <span className="descrip-black">Pasajeros:</span>
                <span className="descrip-grey">{process.totalCandidatos}</span>
              </div>
            ))}

            {<>{handleIconTarget({estadoServicio})}</>}
        </div>
      </div>
    </>
  );
};
