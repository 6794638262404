import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
<path d="M9.81012 6.08V0.5H1.60012C1.06012 0.5 0.620117 0.94 0.620117 1.48V20.52C0.620117 21.07 1.06012 21.5 1.60012 21.5H15.3901C15.9401 21.5 16.3701 21.06 16.3701 20.52V7.06H10.7901C10.2501 7.06 9.81012 6.62 9.81012 6.08ZM13.5601 11.48C13.8501 12.15 13.9901 12.86 13.9901 13.62C13.9901 14.38 13.8501 15.09 13.5601 15.76C13.2701 16.43 12.8801 17.01 12.3801 17.51C11.8801 18.01 11.3001 18.4 10.6301 18.69C9.96012 18.98 9.25012 19.13 8.49012 19.13C7.73012 19.13 7.02012 18.99 6.35012 18.7C5.68012 18.41 5.10012 18.02 4.60012 17.52C4.10012 17.02 3.71012 16.44 3.42012 15.77C3.13012 15.1 2.99012 14.39 2.99012 13.63C2.99012 12.87 3.13012 12.16 3.42012 11.49C3.71012 10.82 4.10012 10.24 4.60012 9.75C5.10012 9.26 5.68012 8.86 6.35012 8.57C7.02012 8.28 7.73012 8.14 8.49012 8.14C9.25012 8.14 9.96012 8.28 10.6301 8.57C11.3001 8.86 11.8801 9.25 12.3701 9.75C12.8601 10.25 13.2601 10.83 13.5501 11.5L13.5601 11.48Z" fill="#FF4900"/>
<path d="M5.19012 10.3C4.28012 11.21 3.82012 12.31 3.82012 13.62C3.82012 14.93 4.28012 16.01 5.19012 16.93C6.10012 17.84 7.21012 18.3 8.50012 18.3C9.79012 18.3 10.9001 17.84 11.8101 16.93C12.7201 16.02 13.1701 14.91 13.1701 13.62C13.1701 12.33 12.7201 11.22 11.8101 10.31C10.9001 9.4 9.80012 8.95 8.49012 8.95C7.18012 8.95 6.10012 9.4 5.18012 10.31L5.19012 10.3ZM6.04012 14.1C5.91012 14.23 5.74012 14.3 5.55012 14.3C5.36012 14.3 5.20012 14.23 5.06012 14.1C4.93012 13.97 4.86012 13.8 4.86012 13.61C4.86012 13.42 4.93012 13.26 5.06012 13.12C5.19012 12.99 5.36012 12.92 5.55012 12.92C5.74012 12.92 5.90012 12.99 6.04012 13.12C6.17012 13.25 6.24012 13.42 6.24012 13.61C6.24012 13.8 6.17012 13.96 6.04012 14.1ZM10.9301 13.13C11.0601 13 11.2301 12.93 11.4201 12.93C11.6101 12.93 11.7701 13 11.9101 13.13C12.0401 13.26 12.1101 13.43 12.1101 13.62C12.1101 13.81 12.0401 13.97 11.9101 14.11C11.7801 14.24 11.6101 14.31 11.4201 14.31C11.2301 14.31 11.0701 14.24 10.9301 14.11C10.8001 13.98 10.7301 13.81 10.7301 13.62C10.7301 13.43 10.8001 13.27 10.9301 13.13ZM9.18012 13.61C9.18012 13.8 9.11012 13.96 8.98012 14.1C8.85012 14.23 8.68012 14.3 8.49012 14.3C8.30012 14.3 8.14012 14.23 8.00012 14.1C7.87012 13.97 7.80012 13.8 7.80012 13.61C7.80012 13.42 7.87012 13.26 8.00012 13.12C8.13012 12.99 8.30012 12.92 8.49012 12.92C8.68012 12.92 8.84012 12.99 8.98012 13.12C9.11012 13.25 9.18012 13.42 9.18012 13.61Z" fill="#FF4900"/>
<path d="M16.0801 4.82L12.0701 0.79C11.8801 0.6 11.6401 0.5 11.3701 0.5H11.1301V5.75H16.3801V5.51C16.3801 5.25 16.2801 5 16.0901 4.82H16.0801Z" fill="#FF4900"/>
    </svg>
  );
};
