import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.0801 4.82L12.0701 0.79C11.8801 0.6 11.6401 0.5 11.3701 0.5H11.1301V5.75H16.3801V5.51C16.3801 5.25 16.2801 5 16.0901 4.82H16.0801Z" 
      fill="#BE2A2A"/>
<path d="M10.7901 7.06C10.2501 7.06 9.81012 6.62 9.81012 6.08V0.5H1.60012C1.06012 0.5 0.620117 0.94 0.620117 1.48V20.52C0.620117 21.07 1.06012 21.5 1.60012 21.5H15.3901C15.9401 21.5 16.3701 21.06 16.3701 20.52V7.06H10.7901ZM7.04012 19.27C5.57012 19.27 4.32012 18.76 3.28012 17.72C2.25012 16.69 1.73012 15.43 1.73012 13.96C1.73012 12.49 2.24012 11.24 3.28012 10.2C4.31012 9.17 5.57012 8.65 7.04012 8.65C7.77012 8.65 8.45012 8.79 9.08012 9.06C9.72012 9.34 10.2701 9.7 10.7501 10.16V8.65H12.3401V12.89H8.10012V11.3H9.64012C9.31012 10.97 8.91012 10.71 8.47012 10.51C8.03012 10.32 7.55012 10.23 7.04012 10.23C6.02012 10.23 5.14012 10.59 4.41012 11.32C3.68012 12.05 3.32012 12.92 3.32012 13.95C3.32012 14.98 3.68012 15.85 4.41012 16.58C5.14012 17.31 6.01012 17.67 7.04012 17.67C7.89012 17.67 8.64012 17.42 9.28012 16.93L10.4301 18.05C9.46012 18.86 8.34012 19.26 7.04012 19.26V19.27ZM15.2201 19.03C15.3501 19.23 15.2101 19.49 14.9701 19.49H14.1101C14.0001 19.49 13.9001 19.43 13.8501 19.33C13.3701 18.44 12.9501 17.64 12.9501 17.64C12.7901 18 12.7001 18.13 12.0501 19.34C12.0001 19.44 11.9001 19.5 11.7901 19.5H10.9301C10.7001 19.5 10.5601 19.24 10.6801 19.04L12.1701 16.74L10.6801 14.44C10.5501 14.24 10.6901 13.98 10.9301 13.98H11.7901C11.9001 13.98 12.0001 14.04 12.0501 14.14C12.6901 15.34 12.5401 14.97 12.9501 15.83C12.9501 15.83 13.1001 15.54 13.8501 14.14C13.9001 14.04 14.0001 13.98 14.1101 13.98H14.9701C15.2001 13.98 15.3401 14.24 15.2201 14.43L13.7401 16.73L15.2201 19.03Z" 
fill="#BE2A2A"/>

       
   
   
    </svg>
  );
};
