import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IInputText,
} from "../../../interfaces/IInput";

export default (props: IInputText) => {
  const {
    placeholder,
    layer,
    required,
    summary,
    onValueChange,
    onPropertyChange,
    onPermaValues,
    onBlur,
    errorPosition,
    type,
    defaultValue,
    disabled,
    requerido,
    datoRequerido, 
    onKeyDown,
    onFocus, 
    maxWord
  } = props;
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(undefined);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [valueText, setValueText] = useState<string>(""); 
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      setValueText(defaultValue);
    }
  }, [defaultValue])

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;

    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";

    if (required && !requerido) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (datoRequerido) {   
      if (requerido) {
        triggerRequired = value !== "" ? false : true;
        eventValidation = value !== "" ? "success" : "caution";
      }
    }

    if (!required && !requerido) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);
    setTriggerRequerido(triggerRequired);

    if (onPermaValues && onPropertyChange && onValueChange) {
      if (typeof onPropertyChange === "string") {
        const permaValues = { ...onPermaValues };
        permaValues[onPropertyChange] = {
          value: value !== "" ? value : undefined,
          valid: trigger ? false : true,
        };
        onValueChange(permaValues);
      }
      if (typeof onPropertyChange === "object") {
        const permaValues = [...onPermaValues];
        const [position, key] = onPropertyChange;
        permaValues[position][key] = {
          value: value !== "" ? value : undefined,
          valid: trigger ? false : true,
        };
        onValueChange(permaValues);
      }
    }
    setValueText(value); 
  };


  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      {layer && (
        <>
          <div style={{ display: "flex" }}>
            <label className="layer">
              {layer}
              {summary && <i>{summary}</i>}
            </label>
            {triggerError && ["up", undefined].includes(errorPosition) && (
              <span className="layer-error"> es obligatorio</span>
            )}
            {triggerRequerido && ["up", undefined].includes(errorPosition) && (
              <span className="layer-caution"><ErrorIcon />Dato Requerido</span>
            )}
          </div>
        </>
      )}
      <input
        type={type ? type : "text"}
        placeholder={placeholder}
        value={valueText}
        className={`${
          disabled
            ? "input-initial initial"
            : categoriesValidation[eventValidation]
        }`}
        required={required}
        // defaultValue={defaultValue}
        disabled={disabled}
        onChange={(event) => {
          handleChangeEventError({ value: event.target.value });
        }}
        onBlur={(event) => {
          handleChangeEventError({ value: event.target.value });
          if (typeof onBlur == "function") {
            onBlur(event.target.value);
          }
        }}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        maxLength={maxWord}
      />
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error down">Dato obligatorio</span>
      )}

      {triggerRequerido && errorPosition === "down" && !disabled && (
        <span className="layer-caution down">
          <ErrorIcon />
          Dato Requerido
        </span>
      )}
    </div>
  );
};
