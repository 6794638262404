import "../../../../assets/css/footer-table.min.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import DropDownListCustomized from "../DropDownListCustomized";
import IconArrowDoubleLeft from "../../kit-svg/IconArrowDoubleLeft";
import IconArrowDoubleRight from "../../kit-svg/IconArrowDoubleRight";
import { IOptionDropDownList } from "../../../../interfaces/IDropDownList";

interface IFooterTable {
  length: number;
  setRange: Function;
  pageCurrent?: number;
  offsetTable?: any; 
  setOffsetTable?: Function; 
}

export default function FooterTable(props: IFooterTable) {
  const { length, setRange, pageCurrent } = props;
  const [page, setPage] = useState<number>(1);
  const [initRange, setInitRange] = useState<number>(0);
  const [endRange, setEndRange] = useState<number>(0);
  const [pages, setPages] = useState<number>(1);
  const [offset, setOffset] = useState<any>({
    offset: { value: undefined, valid: true },
  });
  const [listPageDefault, setListPageDefault] = useState<IOptionDropDownList[]>([
    { name: "10", code: "10" },
    { name: "20", code: "20" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ]);

  useEffect(() => {
    const { offset: currentOffset } = offset;
    const { value } = currentOffset;

    const rowsForPage: number = Number(value?.code ?? 10);

    const initRangeCurrent: number = page * rowsForPage - rowsForPage + 1;
    const endRangeCurrent: number =
      page * rowsForPage < length ? page * rowsForPage : length;

    setInitRange(initRangeCurrent);
    setEndRange(endRangeCurrent);

    setPages(
      parseInt(
        String(length / rowsForPage + (length % rowsForPage > 0 ? 1 : 0))
      )
    );
    setRange({ initRange: initRangeCurrent - 1, endRange: endRangeCurrent });
    // console.log({offset, page, length})
  }, [offset, page, length]);

  useEffect(() => {
    if (pageCurrent) {
      setPage(pageCurrent);
    }
  }, [pageCurrent]);

  return (
    <>
      <footer className="footer-table">
        <div className="pagination">
          <label className="pagination-text">Filas por página</label>
          <div style={{ width: "80px", margin: "0px 10px" }}>
            <DropDownListCustomized
              placeholder={"10"}
              list={listPageDefault}
              onPermaValues={offset}
              onValueChange={setOffset}
              onPropertyChange="offset"
            />
          </div>
          <label className="pagination-text">
            {initRange} - {endRange} de {length}
          </label>
        </div>
        <div className="pagination-number">
          <button
            onClick={() => setPage(1)}
            disabled={page < 2}
            className={page < 2 ? "disabled" : ""}
          >
            <IconArrowDoubleLeft disabled={page < 2} />
          </button>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page < 2}
            className={page < 2 ? "disabled" : ""}
          >
            <KeyboardArrowLeftIcon />
          </button>
          <span className="number-pagination">{page}</span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={page === pages}
            className={page === pages ? "disabled" : ""}
          >
            <KeyboardArrowRightIcon />
          </button>
          <button
            onClick={() => setPage(pages)}
            disabled={page === pages}
            className={page === pages ? "disabled" : ""}
          >
            <IconArrowDoubleRight disabled={page === pages} />
          </button>
        </div>
      </footer>
    </>
  );
}
