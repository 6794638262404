import CloseIcon from "@mui/icons-material/Close";
import DropDownListCustomized from "../.././ui/kit-mdtw/DropDownListCustomized";
import { useContext, useEffect, useState } from "react";
import InputDateCustomized from "../../ui/kit-mdtw/InputDateCustomized";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../../assets/css/validationKam.min.css";
import InputTextCustomized from "../.././ui/kit-mdtw/InputTextCustomized";
import { InputText } from "primereact/inputtext";
import IconMas from "../../ui/kit-svg/IconMas";
import { Button } from "primereact/button";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import ModalSalir from "./ModalSalir";
import { TextField } from "@mui/material";
import CampaignContext from "../../../context/Campaign/CampaignContext";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";

interface IModalcrear {
  modalStyle: string;
  title: string;
  subTitle?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: Function;
  list?: any[];
  token: string;
}

interface ITextForm {
  value: string | undefined;
  valid: boolean;
}

interface IDropDownListForm {
  value: IOptionDropDownList | undefined;
  valid: boolean;
}

interface IEncuestaForm {
  nombre: ITextForm;
  descripcion: ITextForm;
  fechaInicio: ITextForm;
  fechaTermino: ITextForm;
  grupo: IDropDownListForm;
}

export default (props: IModalcrear) => {
  const {
    modalStyle,
    title,
    subTitle,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    token,
  } = props;
  const { creaCampaign, typesCampaign, types } = useContext(CampaignContext);
  const [tipo, setTipos] = useState<any[]>([]);

  const [cantPreg, setCantPreg] = useState<any[]>([]);
  const [openModalSalir, setOpenModalSalir] = useState<boolean>(false);
  const [creaEncuesta, setcreaEncuesta] = useState<boolean>(false);
  const [descripcion, setDescripcion] = useState<String>("");
  const [pregMax, setPregMax] = useState<Number>(1);
  const [fechaInicio, setFechaInicio] = useState<string | undefined>(undefined);
  const [fechaInicioError, setFechaInicioError] = useState<boolean>(false);
  const [fechaTerminoError, setFechaTerminoError] = useState<boolean>(false);
  const [encuestaForm, setEncuestaForm] = useState<IEncuestaForm>({
    nombre: { value: undefined, valid: false },
    descripcion: { value: undefined, valid: false },
    fechaInicio: { value: undefined, valid: false },
    fechaTermino: { value: undefined, valid: false },
    grupo: { value: undefined, valid: false },
  });
  const [dataResponseEncuesta, setDataResponseEncuesta] = useState<
    number | undefined
  >(undefined);
  useEffect(() => {
    if (token) {
      typesCampaign({ token });
    }
  }, [token]);

  useEffect(() => {
    if (dataResponseEncuesta == 7000) {
      setcreaEncuesta(false);
      typeof onChangeBack == "function" && onChangeBack();
    }
  }, [dataResponseEncuesta]);

  useEffect(() => {
    setTipos([]);
    types &&
      types.map((t: any) => {
        const obj = {
          code: t.tipoId,
          name: t.tipo,
        };
        setTipos((tipo) => [...tipo, obj]);
      });
  }, [types]);

  useEffect(() => {
    let selectedDateInicio: number | undefined;

    if (
      encuestaForm.fechaInicio.value === null ||
      encuestaForm.fechaInicio.value === undefined ||
      encuestaForm.fechaInicio.value === ""
    ) {
      setFechaInicioError(false);
    } else if (encuestaForm.fechaInicio.value) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const [dayInicio, monthInicio, yearInicio] =
        encuestaForm.fechaInicio.value.split("/");

      selectedDateInicio = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio)
      ).setHours(0, 0, 0, 0);
      setFechaInicioError(selectedDateInicio < currentDate);
    }

    if (
      encuestaForm.fechaTermino.value === null ||
      encuestaForm.fechaTermino.value === undefined ||
      encuestaForm.fechaTermino.value === ""
    ) {
      setFechaTerminoError(false);
    } else if (encuestaForm.fechaTermino.value) {
      const [dayTermino, monthTermino, yearTermino] =
        encuestaForm.fechaTermino.value.split("/");
      const selectedDateTermino = new Date(
        Number(yearTermino),
        Number(monthTermino) - 1,
        Number(dayTermino)
      ).setHours(0, 0, 0, 0);
      selectedDateInicio &&
        setFechaTerminoError(selectedDateTermino < selectedDateInicio);
    }
  }, [encuestaForm.fechaInicio.value, encuestaForm.fechaTermino.value]);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickModalEncuesta = () => {
    setcreaEncuesta(true);
  };
  const handleClickModalSalir = () => {
    setOpenModalSalir(true);
  };

  const addPreg = (value: string) => {
    const arr = [];
    const preg = {
      value: value ?? "",
      id: cantPreg.length,
    };
    arr.push(preg);
    setCantPreg((current) => [...current, preg]);
  };

  const updatePregState = (index: any) => (e: any) => {
    const newArray = cantPreg.map((item, i) => {
      if (index === i) {
        return { ...item, value: e.target.value };
      } else {
        return item;
      }
    });
    setCantPreg(newArray);
  };

  const addEncuesta = () => {
    setDataResponseEncuesta(undefined);

    const formato = (fecha: string | undefined = "") => {
      const fechaOri = fecha.split("/");
      return fechaOri[2] + "-" + fechaOri[1] + "-" + fechaOri[0];
    };

    const obj = {
      nombre: encuestaForm.nombre.value,
      descripcion: descripcion,
      fechaInicio: formato(encuestaForm.fechaInicio.value),
      fechaTermino: formato(encuestaForm.fechaTermino.value),
      tipo: encuestaForm.grupo.value?.name,
      encuestas: [
        {
          preguntas: cantPreg.length > 0 ? cantPreg.map((p) => p.value) : [],
        },
      ],
    };
    creaCampaign({ campaign: obj, token, setDataResponseEncuesta });
  };

  const tiposCampaign = (estado: string) => {
    setCantPreg([]);
    const typeFilter = types.filter((t: any) => t.tipo == estado)[0];
    if (typeFilter) {
      for (var p = 0; p < typeFilter.minPregunta; p++) {
        addPreg("");
      }
      setPregMax(typeFilter.maxPregunta);
    }
  };

  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-60">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>

          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="explatituloModal md:text-left text-center">
            <span>{subTitle}</span>
          </div>

          {creaEncuesta ? (
            <>
              <div className="row-encuesta rowEncuesta">
                <div className="row col-12 p-0">
                  <div className="col-12 md:col-3 p-0">
                    <label className="col-12">NOMBRE: </label>
                  </div>
                  <div className="col-12 md:col-9 py-0 rowPadding0">
                    <InputTextCustomized
                      layer=""
                      required={undefined}
                      placeholder={"Ingrese Nombre de Campaña"}
                      onPermaValues={encuestaForm}
                      onValueChange={setEncuestaForm}
                      onPropertyChange="nombre"
                    />
                  </div>
                </div>
              </div>

              <div className="row-encuesta rowEncuesta">
                <div className="row col-12 p-0">
                  <div className="col-12 md:col-3 p-0">
                    <label className="col-12">DESCRIPCIÓN: </label>
                  </div>
                  <div className="col-12 md:col-9 py-0 rowPadding0">
                    <TextField
                      type="text"
                      className="input input-initial "
                      multiline
                      style={{ width: "100%" }}
                      onChange={(e: any) => setDescripcion(e.target.value)}
                      placeholder={
                        "Ingrese un texto introductorio que permita al entrevistador contextualizar al candidato sobre la encuesta que se realizará."
                      }
                    />
                  </div>
                </div>
              </div>

              {cantPreg.length > 0 &&
                cantPreg.map((item, index) => {
                  return (
                    <div className="row-encuesta rowEncuesta2">
                      <div className="row col-12 p-0">
                        <div className="col-12 md:col-3 p-0">
                          <label className="col-12">
                            PREGUNTA {index + 1}:{" "}
                          </label>
                        </div>
                        <div className="col-12 md:col-9 py-0 rowPadding0">
                          <InputText
                            value={item.value}
                            required={undefined}
                            placeholder={"Ingrese pregunta"}
                            className="input-initial"
                            style={{ padding: "10px 12px 10px 20px" }}
                            onChange={updatePregState(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="row">
                <div className="col-12 justify-content-center">
                  <ButtonIcon
                    disabled={pregMax === cantPreg.length ? true : false}
                    onClick={() => addPreg("")}
                  >
                    <IconMas
                      className="agregapregunta"
                      fill={pregMax === cantPreg.length ? "#AEAEAE" : "#48566B"}
                    />
                  </ButtonIcon>
                </div>
                <div className="col-12 p-0 flex justify-content-center">
                  <span
                    className={`agregar ${
                      pregMax === cantPreg.length ? "disabled" : ""
                    }`}
                  >
                    AGREGAR
                  </span>
                </div>
              </div>

              <div className="modal-botton">
                {modalStyle && (
                  <>
                    <button
                      className={`modal-button modal-button2 cancel`}
                      onClick={() => {
                        setcreaEncuesta(false);
                      }}
                    >
                      Volver
                    </button>
                    <button
                      className={`modal-button modal-button2 ${
                        encuestaForm.grupo.value?.name !== "" &&
                        cantPreg[0]?.value !== "" &&
                        descripcion !== ""
                          ? "confirm"
                          : "disabled"
                      }`}
                      onClick={addEncuesta}
                      disabled={
                        encuestaForm.grupo.value?.name !== " " &&
                        cantPreg[0]?.value !== "" &&
                        descripcion !== ""
                          ? false
                          : true
                      }
                    >
                      Crear Encuesta
                    </button>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="row-selects rowDisplay">
                <div className="row col-12 md:col-6 px-0">
                  <div className="col-6 px-0">
                    <label>Fecha Inicio:</label>
                  </div>
                  <div className="col-6">
                    <InputDateCustomized
                      required={false}
                      placeholder="Seleccione"
                      maxLength={10}
                      layerLeft={true}
                      onPermaValues={encuestaForm}
                      onValueChange={setEncuestaForm}
                      onPropertyChange="fechaInicio"
                    />
                    {fechaInicioError && encuestaForm.fechaInicio.valid ? (
                      <span className="error-message">
                        La fecha de inicio no puede ser anterior al día actual.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row col-12 md:col-6 rowPadding0">
                  <div className="col-6 px-0">
                    <label>Fecha Termino:</label>
                  </div>
                  <div className="col-6">
                    <InputDateCustomized
                      required={false}
                      placeholder="Seleccione"
                      maxLength={10}
                      layerLeft={true}
                      onPermaValues={encuestaForm}
                      onValueChange={setEncuestaForm}
                      onPropertyChange="fechaTermino"
                    />
                    {fechaTerminoError && encuestaForm.fechaTermino.valid ? (
                      <span className="error-message">
                        La fecha de término no puede ser menor a la de inicio.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="layerModal col-12 p-0">
                <div className="col-6 md:col-3 p-0">
                  <label>Grupo Selección:</label>
                </div>
                <div className="col-6 md:col-9">
                  <DropDownListCustomized
                    list={tipo.map(({ code, name }) => {
                      return { code, name };
                    })}
                    placeholder="Seleccionar"
                    onPermaValues={encuestaForm}
                    onValueChange={setEncuestaForm}
                    onPropertyChange="grupo"
                  />
                </div>
              </div>

              <div className="modal-botton">
                {modalStyle && (
                  <>
                    <button
                      className={`modal-button modal-button2 cancel`}
                      onClick={handleClickModalSalir}
                    >
                      {buttonBack ? buttonBack : "Cancelar"}
                    </button>
                    <button
                      className={`modal-button modal-button2 navigation ${
                        !encuestaForm.fechaInicio.valid ||
                        !encuestaForm.fechaTermino.valid ||
                        !encuestaForm.grupo.valid ||
                        fechaInicioError ||
                        fechaTerminoError ||
                        encuestaForm.fechaInicio.value === "" || encuestaForm.fechaTermino.value === ""
                          ? "disabled"
                          : "active"
                      }`}
                      disabled={
                        !encuestaForm.fechaInicio.valid ||
                        !encuestaForm.fechaTermino.valid ||
                        !encuestaForm.grupo.valid ||
                        fechaInicioError ||
                        fechaTerminoError ||
                        encuestaForm.fechaInicio.value === "" || encuestaForm.fechaTermino.value === ""
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleClickModalEncuesta();
                        tiposCampaign(encuestaForm.grupo.value?.name || "");
                      }}
                    >
                      Siguiente
                      <div style={{ float: "right", marginRight: "0.2rem" }}>
                        <ArrowForwardIcon style={{ color: "#FAFAFA" }} />
                      </div>
                    </button>
                  </>
                )}
              </div>
            </>
          )}
          {openModalSalir && (
            <ModalSalir
              modalStyle="salir"
              title="¿Está seguro qué desea salir de la página actual?"
              subTitle="Se perderán los cambios no guardados"
              onChangeBack={() => setOpenModalSalir(false)}
              onChangeGo={() => handleClickCloseModal()}
              buttonBack="Cancelar"
              buttonGo="Salir"
            />
          )}
        </div>
      </div>
    </>
  );
};
