import { useContext, useEffect, useState } from "react";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import ModalRestaurar from "../Modal/ModalRestaurar";
import { PROFILE_ADMIN_SEL } from "../../../../../diccionary/Diccionary";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";

interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const { listadoSeleccionado, localStorageFilter, setFormFilter, formFilter } =
    props;
  const { profile } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <div className="container-actions margin-0">
        <div className="content-actions-masive">
          {PROFILE_ADMIN_SEL.includes(profile) && (
            <Tooltip text="Restaurar">
              <button
                className={`button-actions ${
                  buttonEnabled ? "active-restore" : "disabled"
                }`}
                disabled={!buttonEnabled}
                style={{ transform: "rotateY(180deg)" }}
                onClick={() => setOpenModalRestaurar(true)}
              >
                <RefreshRoundedIcon />
              </button>
            </Tooltip>
          )}

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
          <InputFiltro
            layer="FECHA INHABILITADO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaInhabilitacion"
          />
        </FiltroGenerico>
      )}

      {openModalRestaurar && (
        <ModalRestaurar
          list={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          onChangeBack={() => setOpenModalRestaurar(false)}
          title="CONFIRMAR RESTAURAR CANDIDATOS INHABILITADOS"
          message="Estás por quitar inhabilidad a la siguiente selección:"
        />
      )}
    </>
  );
};

export default ActionMassive;
