import { ISetRedux } from "../../interfaces/IAdminAssist";

export const setAdminTable = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetAdminTable",
    payload,
  });
};

export const setListadoProcesos = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListadoProcesos",
    payload,
  });
};

export const setListadoTipoServicios = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListadoTipoServicios",
    payload,
  });
};

export const setListadoUsuarioTransporte= (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListadoUsuarioTransporte",
    payload,
  });
};

export const setToastState = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetToastState",
    payload,
  });
};
