import { IReducer } from "../../interfaces/IValidationKam";

const reduxState: any = {
  SetCountAssign: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, countAssign: payload };
  },

  SetExclusiones: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, exclusiones: payload };
  },

  SetListaCandidatoConfirmado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoConfirmado: payload };
  },

  SetListaCandidatoPendiente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPendiente: payload };
  },

  SetListaCandidatoPendienteValidacion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPendienteValidacion: payload };
  },

  SetListaCandidatoValidado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoValidado: payload };
  },

  SetListaCandidatoPendienteContrato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPendienteContrato: payload };
  },

  SetListaCandidatoEnviadoContrato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoEnviadoContrato: payload };
  },

  SetListaDataContrato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaDataContrato: payload };
  },

  SetDatosContrato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, datosContrato: payload };
  },

};


export default (state: any, action: any) => {
  const { type, payload } = action;
  return reduxState[type]({ payload, state }) ?? undefined;
}; 