interface IIconAsignar {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconAsignar) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="30"
      height="20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M14.2241 13.9606C12.5441 13.1947 11.2841 11.7 10.8394 9.87176C10.2094 9.6247 9.53 9.48882 8.82588 9.48882H6.00941C2.95824 9.48882 0.5 11.9471 0.5 14.9859C0.5 15.4924 0.907647 15.9 1.41412 15.9H13.3965C13.9029 15.9 14.3106 15.4924 14.3106 14.9859C14.3106 14.64 14.2735 14.2941 14.2118 13.9606H14.2241Z"
        />
        <path
          d="M7.40529 8.00647C8.45529 8.00647 9.45588 7.58647 10.1971 6.84529C10.9382 6.10412 11.3582 5.10353 11.3582 4.05353C11.3582 3.00353 10.9382 2.00294 10.1971 1.26176C9.45588 0.520586 8.45529 0.100586 7.40529 0.100586C6.35529 0.100586 5.35471 0.520586 4.61353 1.26176C3.87235 2.00294 3.45235 3.00353 3.45235 4.05353C3.45235 5.10353 3.87235 6.10412 4.61353 6.84529C5.35471 7.58647 6.35529 8.00647 7.40529 8.00647Z"
        />
        <path
          d="M16.6206 3.71971C13.9276 3.71971 11.7412 5.90618 11.7412 8.59912C11.7412 11.2921 13.9276 13.4785 16.6206 13.4785C19.3135 13.4785 21.5 11.2921 21.5 8.59912C21.5 5.90618 19.3135 3.71971 16.6206 3.71971ZM19.1776 6.04206C20.4624 7.32677 20.5488 9.29088 19.5853 10.6744L14.5453 5.63441C15.9288 4.65853 17.8929 4.75736 19.1776 6.04206ZM14.0635 11.1562C12.7788 9.87147 12.6924 7.90736 13.6559 6.52383L18.6959 11.5638C17.3124 12.5397 15.3482 12.4409 14.0635 11.1562Z"
        />
      </g>
    </svg>
  );
};
