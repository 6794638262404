import { useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconSolicitarCandidato from "../../../../ui/icons-seleccion/IconSolicitarCandidato";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";

interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
  } = props;
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <section className="container-actions">
        <section className="content-actions-masive">
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </section>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="procesoPostulacion"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="MEDIO POSTULACIÓN"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="medioPostulacion"
          />
          <InputFiltro
            layer="FECHA ASIGNACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaAsignacion"
          />
        </FiltroGenerico>
      )}
    </>
  );
};

export default ActionMassive;
