interface IIconUserProcesoInterno {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconUserProcesoInterno) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="30"
      height="20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M8.49 7.99C9.48628 7.99 10.4037 7.62503 11.104 7.02331C11.3112 6.71752 11.5282 6.42159 11.8142 6.23417C12.2384 5.593 12.4949 4.82359 12.4949 3.995C12.4949 1.78542 10.7094 0 8.49987 0C6.29028 0 4.50486 1.78542 4.50486 3.995C4.50486 6.20458 6.29028 7.99 8.49987 7.99H8.49Z"
        />
        <path
          d="M10.3445 9.32167H6.04368C2.97591 9.32167 0.5 11.8074 0.5 14.8653C0.5 15.4769 0.99321 15.98 1.61465 15.98H14.1225C14.014 15.7235 13.9844 15.4473 13.9844 15.1711C13.935 15.1415 13.8759 15.112 13.8167 15.0725C13.0275 15.5262 12.0312 15.4769 11.4295 14.7272C11.0054 14.2044 10.67 13.6126 10.4234 12.9911C10.0781 12.1034 10.5319 11.1959 11.3309 10.752V10.5646C10.8475 10.2785 10.4727 9.88393 10.3445 9.32167Z"
        />
        <path
          d="M21.1655 11.8272L20.406 11.3931C20.4849 10.9197 20.4849 10.4067 20.406 9.93325L21.1655 9.49922C21.4318 9.3414 21.5601 9.03561 21.4516 8.74954C21.2345 8.17742 20.623 6.72738 19.8338 7.191L19.0841 7.62503C18.7093 7.30937 18.2851 7.06277 17.8215 6.89507V6.01716C17.8215 5.71137 17.6144 5.44504 17.3185 5.39572C16.7759 5.3168 16.2038 5.3168 15.6613 5.39572C15.3653 5.44504 15.1582 5.71137 15.1582 6.01716V6.89507C14.6946 7.06277 14.2606 7.30937 13.8956 7.62503L13.136 7.191C12.8697 7.04304 12.5343 7.08249 12.3469 7.31923C12.0017 7.75326 11.7156 8.22674 11.5183 8.74954C11.4098 9.03561 11.538 9.3414 11.8044 9.49922L12.5639 9.93325C12.485 10.4067 12.485 10.9295 12.5639 11.3931L11.8044 11.8272C11.538 11.9751 11.4098 12.2908 11.5183 12.5769C11.7156 13.0997 12.0017 13.5731 12.3469 14.0072C12.5343 14.2439 12.8796 14.2834 13.136 14.1354L13.8956 13.7112C14.2704 14.0269 14.6946 14.2735 15.1582 14.4412V15.3191C15.1582 16.0195 15.9868 15.9997 16.4899 15.9997C16.9929 15.9997 17.8215 16.0195 17.8215 15.3191V14.4412C18.2851 14.2735 18.7192 14.0269 19.0841 13.7112L19.8437 14.1453C20.11 14.2932 20.4454 14.2538 20.6328 14.017C20.9781 13.583 21.2641 13.1095 21.4614 12.5769C21.5699 12.2908 21.4417 11.985 21.1754 11.8272H21.1655ZM17.6538 11.8272C17.3382 12.1428 16.914 12.3105 16.48 12.3105C16.046 12.3105 15.612 12.133 15.3062 11.8272C14.9905 11.5115 14.8228 11.0874 14.8228 10.6533C14.8228 10.2193 15.0004 9.78528 15.3062 9.47949C15.612 9.1737 16.046 8.99615 16.48 8.99615C16.914 8.99615 17.348 9.1737 17.6538 9.47949C17.9695 9.79515 18.1372 10.2193 18.1372 10.6533C18.1372 11.0874 17.9596 11.5214 17.6538 11.8272Z"
        />
      </g>
    </svg>
  );
};
