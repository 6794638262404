import { useContext, useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../InputTextCustomized";
import InputDateCustomized from "../../InputDateCustomized";
import DropDownListCustomized from "../../DropDownListCustomized";
import {
  IArrayList,
  ISelectGeneral,
} from "../../../../../interfaces/ISeleccion";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { useCreateListCustomized } from "../../../../../hooks/v2/useCreateList";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import Referir from "./componentes/Referir";
import InputTextAreaCustomized from "../../InputTextAreaCustomized";
import Referencias from "./componentes/Referencias";
interface IAreaLaboral {
  list?: any[];
  formCandidate: any;
  setFormCandidate: Function;
  requerido?: boolean; 
  hiddenSection?: boolean | undefined;
}

export default (props: IAreaLaboral) => {
  const { list, formCandidate, setFormCandidate, requerido, hiddenSection } = props;
  const { listaGeneral } = useContext(SeleccionContext);
  const [isOptions] = useState<IOptionDropDownList[]>([
    { code: "true", name: "SI" },
    { code: "false", name: "NO" },
  ]);
  const [fechaComparar, setFechaComparar] = useState<any>("");
  const [hidden, setHidden] = useState<boolean>(hiddenSection ?? false);
  const [fuenteReclutamientoSelect, setFuenteReclutamientoSelect] = useState<boolean>(false);
  const [enfermedadSelect, setEnfermedadSelect] = useState<boolean>(false);
  const [discapacidadSelect, setDiscapacidadSelect] = useState<boolean>(false);
  const [esquemaCovidSelect, setEsquemaCovidSelect] = useState<boolean>(false);
  const [selectGeneral, setSelectGeneral] = useState<any>({
    claseLicenciaConducir: [],
    disponibilidad: [],
    fuenteReclutamiento: [],
    tipoLicenciaConducir: [],
    tallaAlfanumerica: [],
    tallaNumerica: [],
    comunaOmil: [],
  });
  const fechaActual = new Date();
  fechaActual.setDate(fechaActual.getDate() + 30); 

  useEffect(() => {
    if (formCandidate) {
      handleChangeTipoLicencia();
    }
  }, [formCandidate.tipoLicenciaConducir?.value]);

  useEffect(() => {
    if (formCandidate) {
      handleChangeFuenteReclutamiento();
      handleChangeEnfermedad();
      handleChangeDiscapacidad();
      handleChangeEsquemaCovid();
      setFechaComparar(parseFecha({fechaString: formCandidate.fechaVigenciaLicenciaConducir?.value ?? ""}))
    }
  }, [formCandidate]);

  useEffect(() => {
    if (listaGeneral) {
      const {
        disponibilidad,
        fuenteReclutamiento,
        tipoLicenciaConducir,
        claseLicenciaConducir,
        comunas,
        tallas,
      }: any = listaGeneral;
      const { alfanumerica, numerica }: any = tallas;
      const listGeneral = { ...selectGeneral };
      const arrayLists: IArrayList[] = [
        {
          listChange: "disponibilidad",
          list: disponibilidad,
          keyCode: "disponibilidadId",
          keyName: "nombre",
        },
        {
          listChange: "comunaOmil",
          list: comunas,
          keyCode: "comunaId",
          keyName: "comunaNombre",
        },
        {
          listChange: "fuenteReclutamiento",
          list: fuenteReclutamiento,
          keyCode: "fuenteReclutamientoId",
          keyName: "nombre",
        },
        {
          listChange: "tipoLicenciaConducir",
          list: tipoLicenciaConducir,
          keyCode: "tipoLicenciaConducirId",
          keyName: "nombre",
        },
        {
          listChange: "claseLicenciaConducir",
          list: claseLicenciaConducir,
          keyCode: "claseLicenciaId",
          keyName: "nombre",
        },
        {
          listChange: "tallaAlfanumerica",
          list: alfanumerica,
          keyCode: "tallaId",
          keyName: "tallaNombre",
        },
        {
          listChange: "tallaNumerica",
          list: numerica,
          keyCode: "tallaId",
          keyName: "tallaNombre",
        },
      ];

      arrayLists.map((item: any) => {
        const { listChange, list, keyCode, keyName } = item;
        listGeneral[listChange] = useCreateListCustomized({
          list,
          keyCode,
          keyName,
        });
      });

      setSelectGeneral(listGeneral);
    }
  }, []);

  const parseFecha = (values:any) => {
    const {fechaString} = values;
    let partes = fechaString.split('/'); // Divide la cadena en partes separadas por "/"
  
    // El arreglo "partes" contendrá [día, mes, año]
    // Asegúrate de que haya tres partes y sean números válidos
    if (partes.length === 3 && /^\d{1,2}$/.test(partes[0]) && /^\d{1,2}$/.test(partes[1]) && /^\d{4}$/.test(partes[2])) {
      let día = parseInt(partes[0], 10); // Parsea el día a entero
      let mes = parseInt(partes[1], 10) - 1; // Parsea el mes a entero (se resta 1 ya que los meses se indexan desde 0)
      let año = parseInt(partes[2], 10); // Parsea el año a entero
  
      // Crea una nueva instancia de Date utilizando los componentes obtenidos
      let fecha = new Date(año, mes, día);
  
      // Verifica si la fecha es válida (por ejemplo, no es un día inválido como el 30 de febrero)
      if (fecha.getDate() === día && fecha.getMonth() === mes && fecha.getFullYear() === año) {
        // Formatea la fecha en el formato "mm/dd/yyyy"
        let fechaFormateada = (mes + 1).toString().padStart(2, '0') + '/' + día.toString().padStart(2, '0') + '/' + año.toString();
  
        return fechaFormateada; // Retorna la fecha formateada
      }
    }
  
    return null;
  }

  const handleChangeTipoLicencia = () => {
    const profesional = "69c659e0-6ac8-43be-aa9c-afd92b87eac8";
    const noProfesional = "e45df19e-ba4a-4030-a464-c14acd9e02f9";
    const especial = "c0939deb-0eba-4027-9e28-4872ce2d8c30";
    let listadoFiltrado: any = [];
    if (
      [profesional].includes(formCandidate.tipoLicenciaConducir?.value?.code)
    ) {
      listadoFiltrado = listaGeneral.claseLicenciaConducir.filter(
        (item: any) => item.tipoLicenciaId === profesional
      );
    }

    if (
      [noProfesional].includes(formCandidate.tipoLicenciaConducir?.value?.code)
    ) {
      listadoFiltrado = listaGeneral.claseLicenciaConducir.filter(
        (item: any) => item.tipoLicenciaId === noProfesional
      );
    }

    if ([especial].includes(formCandidate.tipoLicenciaConducir?.value?.code)) {
      listadoFiltrado = listaGeneral.claseLicenciaConducir.filter(
        (item: any) => item.tipoLicenciaId === especial
      );
    }
    if (
      ![profesional, noProfesional, especial].includes(
        formCandidate.tipoLicenciaConducir?.value?.code
      )
    ) {
      listadoFiltrado = listaGeneral.claseLicenciaConducir;
    }

    setSelectGeneral({
      ...selectGeneral,
      claseLicenciaConducir: listadoFiltrado.map((item: any) => {
        return { code: item.claseLicenciaId, name: item.nombre };
      }),
    });
  };

  const handleChangeFuenteReclutamiento = () => {
    if(!["490ea4db-a638-442c-8409-fc626486ca4b"].includes(formCandidate.fuenteReclutamiento?.value?.code)){
      setFuenteReclutamientoSelect(true);
    } else {
      setFuenteReclutamientoSelect(false);
    }
  }

  const handleChangeEnfermedad = () => {
    if(["false", null, undefined].includes(formCandidate.enfermedades?.value?.code)){
      setEnfermedadSelect(true);
    } else {
      setEnfermedadSelect(false);
    }
  }

  const handleChangeDiscapacidad = () => {
    if(["false", null, undefined].includes(formCandidate.discapacidad?.value?.code)){
      setDiscapacidadSelect(true);
    } else {
      setDiscapacidadSelect(false);
    }
  }

  const handleChangeEsquemaCovid = () => {
    if(["false", null, undefined].includes(formCandidate.esquemaCovidCompleto?.value?.code)){
      setEsquemaCovidSelect(true);
    } else {
      setEsquemaCovidSelect(false);
    }
  }

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">ÁREA LABORAL</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="content-subtitle">
          <div>
            <p className="subtitle">EXPERIENCIA</p>
          </div>
          <span className="linea"></span>
        </div>

        <div className="container-input">
          <div className="content-input">
            <div className="content-text-modal-update w-100">
              <InputTextAreaCustomized
                layer="Experiencia en relación al Cargo"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="experienciaEnCargo"
                defaultValue={formCandidate.experienciaEnCargo?.value ?? ""}
                requerido={formCandidate.experienciaEnCargo?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
          <div className="content-input">
            <div className="content-text-modal-update w-100">
              <InputTextAreaCustomized
                layer="Feedback"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="feedback"
                defaultValue={formCandidate.feedback?.value ?? ""}
                requerido={formCandidate.feedback?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
          <div className="content-input">
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.disponibilidad}
                layer="Disponibilidad"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="disponibilidad"
                defaultValue={formCandidate.disponibilidad?.value ?? ""}
                requerido={formCandidate.disponibilidad?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.fuenteReclutamiento}
                layer="Fuente de Reclutamiento"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fuenteReclutamiento"
                defaultValue={formCandidate.fuenteReclutamiento?.value ?? ""}
                requerido={formCandidate.fuenteReclutamiento?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.comunaOmil}
                layer="OMIL Registrado"
                placeholder="Ingrese"
                required={false}
                disabled={fuenteReclutamientoSelect}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="comunaOmil"
                defaultValue={formCandidate.comunaOmil?.value ?? ""}
                requerido={fuenteReclutamientoSelect || formCandidate.comunaOmil?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <Referencias
          setFormCandidate={setFormCandidate}
          formCandidate={formCandidate}
          selectGeneral={selectGeneral}
          defaultValue={formCandidate.referencias?.value ?? []}
          requerido={requerido}
        />

        <Referir
          setFormCandidate={setFormCandidate}
          formCandidate={formCandidate}
          selectGeneral={selectGeneral}
          defaultValue={formCandidate.referidos?.value ?? []}
          requerido={requerido}
        />

        <div className="content-subtitle">
          <div>
            <p className="subtitle">UNIFORME</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.tallaNumerica}
                layer="Polera"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="polera"
                defaultValue={formCandidate.polera?.value ?? ""}
                requerido={formCandidate.polera?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.tallaNumerica}
                layer="Pantalón"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="pantalon"
                defaultValue={formCandidate.pantalon?.value ?? ""}
                requerido={formCandidate.pantalon?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.tallaAlfanumerica}
                layer="Zapatos"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="zapato"
                defaultValue={formCandidate.zapato?.value ?? ""}
                requerido={formCandidate.zapato?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">SALUD</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-20">
              <DropDownListCustomized
                list={isOptions}
                layer="Enfermedades"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="enfermedades"
                defaultValue={formCandidate.enfermedades?.value ?? ""}
                requerido={formCandidate.enfermedades?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-30">
              <InputTextCustomized
                layer="Observaciones"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                disabled={enfermedadSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="observacionEnfermedad"
                defaultValue={formCandidate.observacionEnfermedad?.value ?? ""}
                requerido={enfermedadSelect || formCandidate.observacionEnfermedad?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-20">
              <DropDownListCustomized
                list={isOptions}
                layer="Discapacidad"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="discapacidad"
                defaultValue={formCandidate.discapacidad?.value ?? ""}
                requerido={formCandidate.discapacidad?.value?.code ? false : true}
                datoRequerido={requerido}
                />
            </div>
            <div className="content-text-modal-update w-30">
              <InputTextCustomized
                layer="Observaciones"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                disabled={discapacidadSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="observacionDiscapacidad"
                defaultValue={
                  formCandidate.observacionDiscapacidad?.value ?? ""
                }
                requerido={discapacidadSelect || formCandidate.observacionDiscapacidad?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>

          <div className="content-input">
            <div className="content-select-modal-update w-50">
              <DropDownListCustomized
                list={isOptions}
                layer="Esquema Vacunación Covid Completo"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="esquemaCovidCompleto"
                defaultValue={formCandidate.esquemaCovidCompleto?.value ?? ""}
                requerido={formCandidate.esquemaCovidCompleto?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-50">
              <DropDownListCustomized
                list={isOptions}
                layer="Refuerzos"
                required={false}
                errorPosition="down"
                disabled={esquemaCovidSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="refuerzo"
                defaultValue={formCandidate.refuerzo?.value ?? ""}
                requerido={formCandidate.refuerzo?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">LICENCIA DE CONDUCIR</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.tipoLicenciaConducir}
                layer="Tipo de Licencia"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tipoLicenciaConducir"
                defaultValue={formCandidate.tipoLicenciaConducir?.value ?? ""}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.claseLicenciaConducir}
                layer="Clase"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="claseLicenciaConducir"
                defaultValue={formCandidate.claseLicenciaConducir?.value ?? ""}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <InputDateCustomized
                layer="Vigencia"
                placeholder="DD/MM/AAAA"
                required={false}
                maxLength={10}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fechaVigenciaLicenciaConducir"
                defaultValue={
                  formCandidate.fechaVigenciaLicenciaConducir?.value ?? ""
                }
              />
            </div>
            <div className="content-select-modal-update w-25">
              <InputDateCustomized
                layer="Fecha Renovación"
                placeholder="DD/MM/AAAA"
                required={false}
                disabled={new Date(fechaComparar) > fechaActual ? false : true}
                maxLength={10}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fechaRenovacionLicenciaConducir"
                defaultValue={
                  formCandidate.fechaRenovacionLicenciaConducir?.value ?? ""
                }
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">EXAMEN PREOCUPACIONAL</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-35">
              <DropDownListCustomized
                list={isOptions}
                layer="Posee Examen Pre Ocupacional"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tieneExamenPreocupacional"
                defaultValue={
                  formCandidate.tieneExamenPreocupacional?.value ?? ""
                }
                // requerido={formCandidate.tieneExamenPreocupacional?.value?.code ? false : true}
                // datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-45">
              <InputTextCustomized
                layer="Tipo"
                placeholder="Ingrese"
                required={false}
                disabled={formCandidate.tieneExamenPreocupacional?.value?.name == "SI" ? false : true}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tipoExamPreocupacional"
                defaultValue={formCandidate.tipoExamPreocupacional?.value ?? ""}
                // requerido={formCandidate.tipoExamPreocupacional?.value?.code ? false : true}
                // datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-20">
              <InputDateCustomized
                layer="Vigencia"
                placeholder="DD/MM/AAAA"
                required={false}
                disabled={formCandidate.tieneExamenPreocupacional?.value?.name == "SI" ? false : true}
                maxLength={10}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fechaVigenciaExam"
                defaultValue={formCandidate.fechaVigenciaExam?.value ?? ""}
                // requerido={formCandidate.fechaVigenciaExam?.value ? false : true}
                // datoRequerido={requerido}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
