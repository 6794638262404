import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import "../../../assets/css/assists.min.css";
import Footer from "./Footer";
import Asistencia from "../assists/Asistencia";
import Comentario from "../assists/Comentario";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";
import { IToken } from "../../../interfaces/IAdminAssist";

export const ListAssists = (props: IToken) => {
  const { token, setToken, getToken } = props; 
  const { listaAsistencias, getListaAsistencia, asistenciaInterna, userId } = useContext(AsistenciaContext);
  const [transition, setTransition] = useState<number>(1);
  let history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const servicioId = searchParams.get('serviceId');
  const invitationId = searchParams.get('invitationId');

  useEffect(() => {
    if (token) {
      getListaAsistencia({servicioId, invitationId, token});
    }
  }, [token]);

  return (
    <>
      <div className="container-assist">
        <div className="content">
          <div className="content-body">
            {transition == 1 ? (
              <>
                <Asistencia
                  navText={`Asistencia / Listas pendientes / ${servicioId}`}
                  titleText={`SERVICIO N°${servicioId}`}
                  labelButtonBack={"Volver"}
                  labelButtonGo={"Confirmar Asistencia"}
                  listado={listaAsistencias}
                  history={history}
                  route={`/asistencia?userId=${userId}&serviceId=${servicioId}&invitationId=${invitationId}`}
                  transition={transition}
                  setTransition={setTransition}
                  token={token}
                  getToken={getToken}
                />
              </>
            ) : (
              " "
            )}
            {transition == 2 ? (
              <>
                <Comentario
                  navText={`Asistencia / Listas pendientes / ${servicioId}`}
                  titleText={"¿Desea agregar un Comentario?"}
                  labelButtonBack={"Volver"}
                  labelButtonGo={"Enviar"}
                  history={history}
                  route={`/asistencia?userId=${userId}&serviceId=${servicioId}&invitationId=${invitationId}`}
                  transition={transition}
                  setTransition={setTransition}
                  token={token}
                  getToken={getToken}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ListAssists;
