import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import { FILTER_PROFILE, LIST_ROLES } from "../../../../../../diccionary/Diccionary";

interface IEstadosSolicitudes {
  objetoFilterGeneral: any;
}

const EstadosSolicitudes = (props: IEstadosSolicitudes) => {
  const { objetoFilterGeneral } = props;
  const {
    token, profile,
    listaResumenEstadosOperaciones,
    postResumenEstadosOperaciones,
  } = useContext(OperacionContratacionContext);

  const [ingresosForDataEtiqueta, setIngresosForDataEtiqueta] =
    useState<boolean>();
  const [activosForDataEtiqueta, setActivosForDataEtiqueta] =
    useState<boolean>();
  const [generalForDataEtiqueta, setGeneralForDataEtiqueta] =
    useState<boolean>();
    const [rol, setRol] = useState<string | null>("");

  useEffect(() => {
    if (token) {
      const rolSession = window.sessionStorage.getItem("roles");
      if (rolSession !== null) {
        setRol(JSON.parse(rolSession));
      }
    }
  }, [token]);

  useEffect(() => {
    if (token && rol) {
      let generalValue = false;
      let ingresosValue = false;
      let activosValue = false;
      if (
        Array.isArray(rol) &&
        rol.some((rol) => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)
      ) {
        generalValue = true;
      } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
        activosValue = true;
      } else {
        ingresosValue = true;
      }
      setGeneralForDataEtiqueta(generalValue);
      setIngresosForDataEtiqueta(ingresosValue);
      setActivosForDataEtiqueta(activosValue);

      const objeto = {
        ingresos: ingresosValue,
        activos: activosValue,
        general: generalValue,
        filtros: {
          clientes: [],
          areaNegocio: objetoFilterGeneral?.areaNegocio,
          analista: [],
          periodo: {},
        },
      };

      postResumenEstadosOperaciones({ token, filtros: objeto });
    }
  }, [
    token,
    rol,
    localStorage.getItem("filterGeneralDashboard"),
    objetoFilterGeneral,
    ingresosForDataEtiqueta,
    activosForDataEtiqueta,
    generalForDataEtiqueta,
  ]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options: any = {
    responsive: true,
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        textAlign: "start",
      },
      datalabels: {
        display: false,
      },
    },
  };

  let dataLabels;
  if (
    Array.isArray(rol) &&
    rol.some((rol) => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)
  ) {
    dataLabels = [
      ["PENDIENTE DE REVISIÓN"],
      ["PENDIENTE DE FIRMA", "DIGITAL"],
      ["PENDIENTE DEINGRESO", "EN SOFTLAND"],
    ];
  } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
    dataLabels = [
      [" VIGENTES"],
      [" BAJAS  CONFIRMADAS"],
      [" PRÓXIMOS A VENCER"],
    ];
  } else {
    dataLabels = [
      ["FUERA DE", "PLAZO POR APROBAR"],
      ["PENDIENTE DE FIRMA", "DIGITAL"],
      ["PENDIENTE DEINGRESO", "EN SOFTLAND"],
    ];
  }

  const data: any = {
    labels: dataLabels,
    datasets: [],
  };

  if (
    Array.isArray(rol) &&
    rol.some((rol) => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)
  ) {
    data.datasets.push({
      label: "Cantidad",
      data: [
        listaResumenEstadosOperaciones?.general?.pendienteRevision,
        listaResumenEstadosOperaciones?.general?.pendienteFirmaDigital,
        listaResumenEstadosOperaciones?.general?.pendienteIngresoSoftland,
      ],
      backgroundColor: ["#FFCA4D", "#FF4DAB", "#FF774D"],
      borderColor: ["#FFCA4D", "#FF4DAB", "#FF774D"],
      borderRadius: [10],
      barThickness: 10,
    });
  } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
    data.datasets.push({
      label: "Cantidad",
      data: [
        listaResumenEstadosOperaciones?.activos?.activos,
        listaResumenEstadosOperaciones?.activos?.bajasConfirmadas,
        listaResumenEstadosOperaciones?.activos?.proximoVencimiento,
      ],
      backgroundColor: ["#53CC50", "#FFCA4D", "#FF774D"],
      borderColor: ["#53CC50", "#FFCA4D", "#FF774D"],
      borderRadius: [10],
      barThickness: 10,
    });
  } else {
    data.datasets.push({
      label: "Cantidad",
      data: [
        listaResumenEstadosOperaciones?.ingresos?.fueraPlazoPorAprobar,
        listaResumenEstadosOperaciones?.ingresos?.pendieteFirma,
        listaResumenEstadosOperaciones?.ingresos?.pendienteIngresoSoftland,
      ],
      borderColor: ["#B0CFF4", "#B0CFF4", "#FF774D"],
      backgroundColor: ["#B0CFF4", "#B0CFF4", "#FF774D"],
      borderRadius: [10],
      barThickness: 10,
    });
  }

  return (
    <div className="container-table-dashboard w-35 responsiveChart">
      <div className="content-title">
        <label className="title-table-dashboard">
          {Array.isArray(rol) &&
          rol.some((rol) => rol === LIST_ROLES.JEFE_OPS) || (profile === FILTER_PROFILE.admin_ops)
            ? "ESTADO SOLICITUDES"
            : objetoFilterGeneral?.ciclo[0] === "Activos"
            ? "ESTADOS DOTACIÓN"
            : "ESTADO DE SOLICITUDES"}{" "}
        </label>
      </div>
      <Bar options={options} data={data} height={195} />
    </div>
  );
};

export default EstadosSolicitudes;
