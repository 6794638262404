import { useRef } from "react";
interface IIconDownloadDocument {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?: boolean;
}

export default (props: IIconDownloadDocument) => {
  const { width, height, fill, className, color, disabled } = props;
  const refIconExcel = useRef<any>();

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 21 21"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.85938 0H12.1406C12.6861 0 13.125 0.438867 13.125 0.984375V7.875H16.7221C17.4521 7.875 17.8172 8.75684 17.3004 9.27363L11.0619 15.5162C10.7543 15.8238 10.2498 15.8238 9.94219 15.5162L3.69551 9.27363C3.17871 8.75684 3.54375 7.875 4.27383 7.875H7.875V0.984375C7.875 0.438867 8.31387 0 8.85938 0ZM21 15.4219V20.0156C21 20.5611 20.5611 21 20.0156 21H0.984375C0.438867 21 0 20.5611 0 20.0156V15.4219C0 14.8764 0.438867 14.4375 0.984375 14.4375H7.00137L9.01113 16.4473C9.83555 17.2717 11.1645 17.2717 11.9889 16.4473L13.9986 14.4375H20.0156C20.5611 14.4375 21 14.8764 21 15.4219ZM15.9141 19.0312C15.9141 18.5801 15.5449 18.2109 15.0938 18.2109C14.6426 18.2109 14.2734 18.5801 14.2734 19.0312C14.2734 19.4824 14.6426 19.8516 15.0938 19.8516C15.5449 19.8516 15.9141 19.4824 15.9141 19.0312ZM18.5391 19.0312C18.5391 18.5801 18.1699 18.2109 17.7188 18.2109C17.2676 18.2109 16.8984 18.5801 16.8984 19.0312C16.8984 19.4824 17.2676 19.8516 17.7188 19.8516C18.1699 19.8516 18.5391 19.4824 18.5391 19.0312Z"
        fill={disabled ? "#aeaeae" : "#0065DD"}
      />
    </svg>
  );
};
