import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M16.4969 2.90325C17.1606 2.2395 17.1606 1.16156 16.4969 0.497813C15.8331 -0.165938 14.7552 -0.165938 14.0914 0.497813L8.5 6.09456L2.90325 0.503123C2.2395 -0.160628 1.16156 -0.160628 0.497813 0.503123C-0.165938 1.16687 -0.165938 2.24481 0.497813 2.90856L6.09456 8.5L0.503123 14.0967C-0.160628 14.7605 -0.160628 15.8384 0.503123 16.5022C1.16687 17.1659 2.24481 17.1659 2.90856 16.5022L8.5 10.9054L14.0968 16.4969C14.7605 17.1606 15.8384 17.1606 16.5022 16.4969C17.1659 15.8331 17.1659 14.7552 16.5022 14.0914L10.9054 8.5L16.4969 2.90325Z"
      />
    </svg>
  );
};
