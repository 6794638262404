import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaCandidatoPendienteMasivo from "./CandidatoPendienteMasivo/TablaCandidatoPendienteMasivo";
import TablaCandidatoEnviadoValidacionMasivo from "./CandidatoValidadoMasivo/TablaCandidatoEnviadoValidacionMasivo";

const TablaRegistroPorValidarMasivo = () => {
  const {
    toasts, 
    getListaGeneral, 
    getExclusiones,
    getToken,
    token,
  } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getListaGeneral({token});
      getExclusiones({token});
    }
  }, [token]);

  return (
    <>
      {toasts}
      <TablaCandidatoPendienteMasivo />
      <TablaCandidatoEnviadoValidacionMasivo />
    </>
  );
};

export default TablaRegistroPorValidarMasivo;
