import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M7.02819 6.31579C7.43396 6.31579 7.7807 6.17131 8.06842 5.88234C8.35614 5.59338 8.5 5.24601 8.5 4.84023C8.5 4.43446 8.35552 4.08772 8.06655 3.8C7.77759 3.51228 7.43022 3.36842 7.02444 3.36842C6.61867 3.36842 6.27193 3.51291 5.98421 3.80187C5.69649 4.09083 5.55263 4.4382 5.55263 4.84398C5.55263 5.24975 5.69712 5.59649 5.98608 5.88421C6.27504 6.17193 6.62241 6.31579 7.02819 6.31579ZM7.03684 9.68421C7.57719 9.68421 8.10146 9.57279 8.60964 9.34996C9.11784 9.12714 9.55849 8.81048 9.93158 8.4C9.48246 8.1193 9.01228 7.91228 8.52105 7.77895C8.02982 7.64561 7.53158 7.57895 7.02632 7.57895C6.52105 7.57895 6.02281 7.64561 5.53158 7.77895C5.04035 7.91228 4.57018 8.1193 4.12105 8.4C4.51404 8.80702 4.96316 9.12281 5.46842 9.34737C5.97368 9.57193 6.49649 9.68421 7.03684 9.68421ZM15.6158 16L11.1737 11.5579C10.6123 12.007 9.97018 12.3684 9.24737 12.6421C8.52456 12.9158 7.78465 13.0526 7.02764 13.0526C5.21623 13.0526 3.67544 12.4175 2.40526 11.1474C1.13509 9.87719 0.5 8.33684 0.5 6.52632C0.5 4.71579 1.13509 3.17544 2.40526 1.90526C3.67544 0.635088 5.21579 0 7.02632 0C8.83684 0 10.3772 0.635088 11.6474 1.90526C12.9175 3.17544 13.5526 4.71623 13.5526 6.52764C13.5526 7.28465 13.4158 8.02456 13.1421 8.74737C12.8684 9.47018 12.507 10.1123 12.0579 10.6737L16.5 15.1158L15.6158 16ZM7.03684 11.7895C8.48947 11.7895 9.72807 11.2737 10.7526 10.2421C11.7772 9.21053 12.2895 7.96842 12.2895 6.51579C12.2895 5.06316 11.7778 3.82456 10.7544 2.8C9.731 1.77544 8.48831 1.26316 7.02632 1.26316C5.5807 1.26316 4.34211 1.77486 3.31053 2.79825C2.27895 3.82164 1.76316 5.06432 1.76316 6.52632C1.76316 7.97193 2.27895 9.21053 3.31053 10.2421C4.34211 11.2737 5.58421 11.7895 7.03684 11.7895Z"
      />
    </svg>
  );
};
