import { useEffect, useState } from "react";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IInputEdadHijos,
} from "../../../interfaces/IInput";
import ErrorIcon from "@mui/icons-material/Error";

export default (props: IInputEdadHijos) => {
  const {
    placeholder,
    layer,
    required,
    summary,
    onValueChange,
    onPropertyChange,
    onPermaValues,
    onBlur,
    errorPosition,
    type,
    index,
    disabled, 
    defaultValue,
    requerido, 
    datoRequerido,
  } = props;

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(undefined);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, defaultValue]);

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (datoRequerido) {
      if (requerido) {
        triggerRequired = value !== "" ? false : true;
        eventValidation = value !== "" ? "success" : "caution";
      }
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);
    setTriggerRequerido(triggerRequired); 

    if (onPermaValues && onPropertyChange && onValueChange) {
      const permaValues = { ...onPermaValues };
      const { value: permaValue } = permaValues[onPropertyChange];

      permaValue[index] = Number(value);
    
      permaValues[onPropertyChange] = {
        value: permaValue,
        valid: trigger ? false : true,
      };

      onValueChange(permaValues);
    } 

  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      {layer && (
        <>
          <div style={{ display: "flex" }}>
            <label className="layer">
              {layer} {summary && <i>{summary}</i>}
            </label>
            {triggerError && ["up", undefined].includes(errorPosition) && (
              <span className="layer-error"> es obligatorio</span>
            )}
            {triggerRequerido && ["up", undefined].includes(errorPosition) && (
              <span className="layer-error">
                <ErrorIcon />
                Dato Requerido
              </span>
            )}
          </div>
        </>
      )}
      <input
        type={type ? type : "text"}
        placeholder={placeholder}
        className={categoriesValidation[eventValidation]}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={(event) => {
          handleChangeEventError({ value: event.target.value });
        }}
        onBlur={(event) => {
          handleChangeEventError({ value: event.target.value });
          if (typeof onBlur == "function") {
            onBlur(event.target.value);
          }
        }}
      />
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error down">Dato obligatorio</span>
      )}
      {triggerRequerido && errorPosition === "down" && !disabled && (
        <span className="layer-caution down">
          <ErrorIcon />
          Dato Requerido
        </span>
      )}
    </div>
  );
};
