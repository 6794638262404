import { IReducer } from "../../interfaces/IAsistencias";

const reduxState: any = {
  SetListaAsistencia: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaAsistencias: payload };
  },

  SetListaTarget: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaTarget: payload };
  },

};

export default (state: any, action: any) => {
  const { type, payload } = action;

  return reduxState[type]({ payload, state }) ?? undefined;
};