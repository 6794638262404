interface IconDescargaWhite {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?: boolean;
  }
  
  export default (props: IconDescargaWhite) => {
    const { width, height, stroke, style, className, color, fill, disabled } = props;
  
    return (
      <svg
        width={`${width ? width : "14"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 14 18"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M10.8994 -1.85773e-07C12.0715 -8.33078e-08 13.0244 0.95293 13.0244 2.125L13.0244 14.875C13.0244 16.0471 12.0715 17 10.8994 17L2.39941 17C1.22734 17 0.274413 16.0471 0.274413 14.875L0.274414 5.3125L4.52441 5.3125C5.11211 5.3125 5.58691 4.83769 5.58691 4.25L5.58691 -6.50207e-07L10.8994 -1.85773e-07ZM4.52441 -7.43094e-07L4.52441 4.25L0.274414 4.25L4.52441 -7.43094e-07ZM5.85254 7.70312L5.85254 11.0932L4.82324 10.0639C4.51113 9.75176 4.00645 9.75176 3.69766 10.0639C3.38887 10.376 3.38555 10.8807 3.69766 11.1895L6.08828 13.5801C6.40039 13.8922 6.90508 13.8922 7.21387 13.5801L9.60449 11.1895C9.9166 10.8773 9.9166 10.3727 9.60449 10.0639C9.29238 9.75508 8.78769 9.75176 8.47891 10.0639L7.44961 11.0932L7.44961 7.70312C7.44961 7.26152 7.09434 6.90625 6.65273 6.90625C6.21113 6.90625 5.85586 7.26152 5.85586 7.70312L5.85254 7.70312Z" 
       fill= { '#FAFAFA' }/> 
       
      </svg>



    );
  };
