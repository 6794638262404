import ItemGrupo from "./ItemGrupo";
import { ICandidatoValidadoGrupo } from "../../../../../interfaces/ISeleccion";
interface ITablaGrupo {
  list: any;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  clienteNombre?: string;
  localStorageSelect: string;
  localStorageFilter: string; 
  formFilter: any;
  listRefresh: Function; 
}

const TablaGrupo = (props: ITablaGrupo) => {
  const {
    list,
    listadoSeleccionado,
    setListadoSeleccionado,
    clienteNombre,
    localStorageSelect,
    localStorageFilter,
    formFilter,
    listRefresh,
  } = props;

  return (
    <>
      {list &&
        list.map(
          ({
            cargoGroupId,
            cargoGroupName,
            procesos,
          }: ICandidatoValidadoGrupo) => (
            <ItemGrupo
              key={cargoGroupId}
              procesos={procesos}
              clienteNombre={clienteNombre}
              cargoGroupName={cargoGroupName}
              listadoSeleccionado={listadoSeleccionado}
              setListadoSeleccionado={setListadoSeleccionado}
              localStorageSelect={localStorageSelect}
              localStorageFilter={localStorageFilter}
              formFilter={formFilter}
              listRefresh={listRefresh}
            />
          )
        )}
    </>
  );
};

export default TablaGrupo;
