import { useContext, useEffect } from "react";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import TablaSinProcesar from "./SinProcesar/TablaSinProcesar";
import TablaProcesadas from "./Procesadas/TablaProcesadas";

const TablaBajasConfirmadas = () => {
  const { getToken, toasts } = useContext(OperacionContratacionContext); 

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {toasts}
      <TablaSinProcesar />
      <TablaProcesadas />
    </>
  );
};

export default TablaBajasConfirmadas;
