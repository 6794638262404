import { ISetRedux } from "../../interfaces/IAsistencias";

export const setListaAsistencia = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaAsistencia",
    payload,
  });
};

export const setListaTarget = (props: ISetRedux) => {
  const { dispatch, payload } = props;
  dispatch({
    type: "SetListaTarget",
    payload,
  });
};
