import { useContext, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IconAsignar from "../../../ui/icons-seleccion/IconAsignar";
import { formatDateToDDMMAAAA } from "../../../../functions/kit-mdtw/dates";
import ModalAsignacion from "./Modal/ModalAsignacion";
import ModalDetalleProceso from "./Modal/ModalDetalleProceso";
import Tooltip from "../../../ui/kit-mdtw/tooltip/Tooltip";
import { PROFILE_ADMIN_SEL } from "../../../../diccionary/Diccionary";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import IconVerProceso from "../../../ui/icons-seleccion/IconVerProceso";
import IconEnlaceProceso from "../../../ui/icons-seleccion/IconEnlaceProceso";
import IconQr from "../../../ui/icons-seleccion/IconQr";
import ModalGenerico from "../../../ui/kit-modal/ModalGenerico";
import ModalGenerarVinculo from "./Modal/ModalGenerarVinculo";

interface IHandleClickAddOrRemoveChoise {
  busquedaId: string;
}

interface ITablaProceso {
  token: string | undefined;
  getToken: Function;
  listaProcesos: any;
  listaSeleccionProceso: string[];
  handleClickAddOrRemoveChoise: (values: IHandleClickAddOrRemoveChoise) => void;
  currentListProcess: string[];
  setFormData: Function;
  formData: any;
}

const TablaProceso = (props: ITablaProceso) => {
  const {
    token,
    getToken,
    listaProcesos,
    handleClickAddOrRemoveChoise,
    listaSeleccionProceso,
    currentListProcess,
    setFormData,
    formData,
  } = props;
  const {
    profile,
    createLinkProcessTxt,
    createCodeQrListProcess,
    putNombrePostulacion,
  } = useContext(SeleccionContext);
  const [openModalAsignar, setOpenModalAsignar] = useState<boolean>(false);
  const [openModalDetalleProceso, setOpenModalDetalleProceso] =
    useState<boolean>(false);
  const [procesoSelect, setProcesoSelect] = useState<string[]>([]);
  const [isOpenModalQrEnlace, setIsOpenModalQrEnlace] =
    useState<boolean>(false);
  const [isOpenModalImageQr, setIsOpenModalImageQr] = useState<boolean>(false);

  const handleClickOpenModalAsignar = (values: any) => {
    const { busquedaId } = values;
    setOpenModalAsignar(true);
    setProcesoSelect([busquedaId]);
  };

  const handleClickOpenModalDetalle = (values: any) => {
    const { busquedaId } = values;
    setOpenModalDetalleProceso(true);
    setProcesoSelect([busquedaId]);
  };

  const handleClickCloseModalAsignar = () => {
    setOpenModalAsignar(false);
  };

  const handleClickCloseModalDetalle = () => {
    setOpenModalDetalleProceso(false);
  };

  const handleClickOpenGenerarEnlace = (values: any) => {
    const { busquedaId } = values;
    setIsOpenModalQrEnlace(true);
    setProcesoSelect([busquedaId]);
  };

  const handleClickOpenGenerarQr = (values: any) => {
    const { busquedaId } = values;
    setIsOpenModalImageQr(true);
    setProcesoSelect([busquedaId]);
  };

  return (
    <>
      {listaProcesos &&
        listaProcesos.map((process: any) => {
          const {
            busquedaId,
            divisionId,
            busquedaFin,
            lugar,
            busquedaCantidad,
            busquedaCuposRest,
            busquedaTipoExperiencia,
            busquedaAnalista,
            cargoNombre,
          } = process;
          return (
            <>
              <tr
                className={`tr-table-registros ${
                  listaSeleccionProceso.includes(busquedaId) && "checkeado"
                }`}
                key={busquedaId}
              >
                <td className="td-table-procesos">
                  <button
                    className="button-select"
                    onClick={() => handleClickAddOrRemoveChoise({ busquedaId })}
                  >
                    {listaSeleccionProceso.includes(busquedaId) ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </button>
                </td>
                <td className="td-table-procesos">{divisionId}</td>
                <td className="td-table-procesos">{busquedaId}</td>
                <td className="td-table-procesos procesos" title={cargoNombre}>{cargoNombre}</td>
                <td className="td-table-procesos procesos" title={lugar}>{lugar}</td>
                <td className="td-table-procesos procesos" title={busquedaAnalista}>{busquedaAnalista.join(", ")}</td>
                <td className="td-table-procesos">{busquedaCantidad}</td>
                <td className="td-table-procesos">{busquedaCuposRest}</td>
                <td className="td-table-procesos">
                  {formatDateToDDMMAAAA({
                    date: busquedaFin,
                    format: "/",
                  })}
                </td>
                <td className="td-table-procesos">
                  <div className="content-icon-table">
                    {PROFILE_ADMIN_SEL.includes(profile) && (
                      <Tooltip text="Asignar Equipo">
                        <button
                          className="button-content"
                          onClick={() =>
                            handleClickOpenModalAsignar({ busquedaId })
                          }
                        >
                          <IconAsignar fill="#0065DD" />
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip text="Información Proceso">
                      <button
                        className="button-content"
                        onClick={() =>
                          handleClickOpenModalDetalle({ busquedaId })
                        }
                      >
                        <IconVerProceso fill="#06A502" />
                      </button>
                    </Tooltip>
                    <Tooltip text="Generar QR">
                      <button
                        className="button-content"
                        onClick={() => handleClickOpenGenerarQr({ busquedaId })}
                      >
                        <IconQr fill="#0065DD" />
                      </button>
                    </Tooltip>
                    <Tooltip text="Enlace">
                      <button
                        className="button-content"
                        onClick={() =>
                          handleClickOpenGenerarEnlace({ busquedaId })
                        }
                      >
                        <IconEnlaceProceso fill="#FF0081" />
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      {openModalAsignar && (
        <ModalAsignacion
          title="ASIGNAR EQUIPO"
          onChangeBack={handleClickCloseModalAsignar}
          buttonBack="Cerrar"
          buttonGo="Asignar"
          list={procesoSelect ?? []}
          token={token}
          getToken={getToken}
        />
      )}

      {openModalDetalleProceso && (
        <ModalDetalleProceso
          title="DETALLE DE PROCESO"
          onChangeBack={handleClickCloseModalDetalle}
          buttonBack="Cerrar"
          list={procesoSelect ?? []}
          token={token}
          getToken={getToken}
        />
      )}

      {isOpenModalQrEnlace && (
        <ModalGenerico
          title="GENERAR VÍNCULO POR PROCESO"
          width="w-70"
          subtitle="Ingresa el nombre del proceso vínculo generado."
          positionSubTitle="start"
          messageBottom="Al confirmar, se descargará un archivo con los vínculo QR correspondiente(s). Para continuar presione Descargar vínculos"
          positionMessageBottom="start"
          onChangeBack={() => {
            setIsOpenModalQrEnlace(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            createLinkProcessTxt({ formData });
            putNombrePostulacion({ token, formData });
            setIsOpenModalQrEnlace(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cancelar"
          buttonGoText="Descargar Vínculos"
          buttonGoStyle="option"
        >
          <ModalGenerarVinculo
            list={procesoSelect}
            listaOriginal={currentListProcess}
            setFormData={setFormData}
            formData={formData}
          />
        </ModalGenerico>
      )}

      {isOpenModalImageQr && (
        <ModalGenerico
          title="GENERAR CÓDIGO QR POR PROCESO"
          width="w-70"
          subtitle="Ingresa el nombre del proceso que vinculará el código QR generado."
          positionSubTitle="start"
          messageBottom="Al confirmar, se descargará un archivo con los códigos QR correspondientes. Para continuar presione generar código QR."
          positionMessageBottom="start"
          onChangeBack={() => {
            setIsOpenModalImageQr(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            createCodeQrListProcess({ formData });
            putNombrePostulacion({ token, formData });
            setIsOpenModalImageQr(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cancelar"
          buttonGoText="Descargar Código QR"
          buttonGoStyle="confirm"
        >
          <ModalGenerarVinculo
            list={procesoSelect}
            listaOriginal={currentListProcess}
            setFormData={setFormData}
            formData={formData}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaProceso;
