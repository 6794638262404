import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IPage from "../../interfaces/page";
import { FILTER_PROFILE } from "../../diccionary/Diccionary";
const RouterPage: React.FC<IPage> = () => {
  const history = useHistory();
  
  // importante cambiar antes de pasar a QA y PRODU
  useEffect(() => {
    const profile = window.sessionStorage.getItem("profile") as string; 
    if (profile) {
      if (
        profile == FILTER_PROFILE.adm_tw ||
        profile == FILTER_PROFILE.tw_sel ||
        profile == FILTER_PROFILE.tw_admsel ||
        profile == FILTER_PROFILE.tw_admsel_prof ||
        profile == FILTER_PROFILE.admin_sel
      ) {
        return history.push("registros/habilitados");
      } else if (profile == FILTER_PROFILE.tw_legal) {
        return history.push("consultaexclusionrut");
      } else if (profile == FILTER_PROFILE.tw_cliente) {
        return history.push("validacioncliente");
      } else if (profile == FILTER_PROFILE.tw_ops) {
        return history.push("validacion");
      } else if (profile == FILTER_PROFILE.tw_marketing) {
        return history.push("campaign");
      } else if (profile == FILTER_PROFILE.tw_gen) {
        return history.push(`asistencia`);
      } else if (profile == FILTER_PROFILE.admin_ops) {
        return history.push(`resumen`);
      }
    } else {
      return history.push("invitacion/procesos");
    }
  }, []);

  return <></>;
};

export default RouterPage;
