interface IconCausales {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconCausales) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "38"}`}
        height={`${height ? height : "37"}`}
        viewBox="0 0 38 37"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       
    
<rect x="0.5" width="37" height="37" rx="18.5" fill="#E6A200"/>
<g clip-path="url(#clip0_3917_76549)">
<path d="M19.9745 28.5281C22.0841 25.8881 26.8954 19.4895 26.8954 15.8954C26.8954 11.5365 23.3589 8 18.9999 8C14.641 8 11.1045 11.5365 11.1045 15.8954C11.1045 19.4895 15.9158 25.8881 18.0253 28.5281C18.5311 29.1573 19.4687 29.1573 19.9745 28.5281ZM18.9999 13.2636C19.6979 13.2636 20.3673 13.5409 20.8609 14.0345C21.3545 14.528 21.6317 15.1974 21.6317 15.8954C21.6317 16.5934 21.3545 17.2628 20.8609 17.7564C20.3673 18.25 19.6979 18.5272 18.9999 18.5272C18.3019 18.5272 17.6325 18.25 17.139 17.7564C16.6454 17.2628 16.3681 16.5934 16.3681 15.8954C16.3681 15.1974 16.6454 14.528 17.139 14.0345C17.6325 13.5409 18.3019 13.2636 18.9999 13.2636Z" fill="#FAFAFA"/>
</g>
<defs>
<clipPath id="clip0_3917_76549">
<rect width="15.7909" height="21" fill="white" transform="translate(11.1045 8)"/>
</clipPath>
</defs>
</svg>



   



    );
  };
