import { useContext } from "react";
import { useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  formatDateToDDMMAAAA,
  formatDateToDDMMYY,
} from "../../../../../functions/kit-mdtw/dates";
import { useParams } from "react-router-dom";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ButtonIcon from "../../../../ui/kit-mdtw/buttons/ButtonIcon";

import MediaQuery from "../../../../ui-responsive/MediaQuery";
import FooterTable from "./FooterTable";
import { FILTER_LIST } from "../../../../../diccionary/Diccionary";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import IconoRegistroI from "../../../../ui/kit-svg/IconoRegistroI";
import IconStop from "../../../../ui/kit-svg/IconStop";
import IconSolicitudAcept from "../../../../ui/kit-svg/IconSolicitudAcept";
import IconSolicitudObservacion from "../../../../ui/kit-svg/IconSolicitudObservacion";
import IconSolicitudRechazada from "../../../../ui/kit-svg/IconSolicitudRechazada";
import IconExclamacion from "../../../../ui/kit-svg/IconExclamacion";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ModalDetener from "../Modals/ModalDetener";
import ModalFicha from "../../AsistenciaValidacionKam/Modals/ModalFicha";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ModalConfSolicitud from "../Modals/ModalConfSolicitud";
import IconPendienteFirma from "../../../../ui/icons-operaciones/IconPendienteFirma";
import IconPendRevision from "../../../../ui/icons-operaciones/IconPendRevision";
import IconNewFueraPlazo from "../../../../ui/icons-operaciones/IconNewFueraPlazo";
import IconPendSoftlan from "../../../../ui/icons-operaciones/IconPendSoftlan";

const TablaCandidatoEnviadoContrato = () => {
  const {
    exclusiones,
    listaCandidatoEnviadoContrato,
    setVisibleActionBlocking,
    getListCandidateEnviadoContrato,
    listCandidatoEnviadoContrato,
    setListCandidatoEnviadoContrato,
    disableButtonBlock,
    disableButtonBlockOnly,
    BootstrapTooltip,
    aBusPerId,
    setABusPerId,
  } = useContext(ValidationKamContext);
  const { token, getToken } = useContext(SeleccionContext);
  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  const [openModalDescartar, setOpenModalDescartar] = useState<boolean>(false);
  const [openModalDetener, setOpenModalDetener] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [listDescartesBloqueo, setListDescarteBloqueo] = useState<any[]>([]);
  const [isOpenModalIndividual, setIsOpenModalIndividual] =
    useState<boolean>(false);
  const [gridCandidatoAContratacion, setGridCandidatoAContratacion] = useState<
    IListaCandidatos[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [listCandidatoAContratoFull, setListCandidatoAContratoFull] = useState<
    any[]
  >([]);
  const [selectedCandidate, setSelectedCandidate] =
    useState<IListaCandidatos | null>(null);
  const [candidateDetener, setCandidateDetener] = useState<any[]>([]);
  const [allDni, setAllDni] = useState<any[]>([]);
  const [tipoDocumentoValue, setTipoDocumentoValue] = useState("");
  const [openModalSolicitudPend, setOpenModalSolicitudPend] =
    useState<boolean>(false);
  const [comentario, setComentario] = useState<string>("");
  const [estadoContrato, setEstadoContrato] = useState<string>("");
  const [
    candidatePorCertificadoPendiente,
    setCandidatePorCertificadoPendiente,
  ] = useState<any[]>([]);
  const [onlyRead, setOnlyRead] = useState<boolean>(false);
  const objetoSendFiltro = {};

  useEffect(() => {
    listaCandidatoEnviadoContrato.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listaCandidatoEnviadoContrato]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  useEffect(() => {
    if (token) {
      if (localStorage.getItem("Filtros2")) {
        const copyStorage: any = localStorage.getItem("Filtros2");
        getListCandidateEnviadoContrato({
          idCliente,
          token,
          objetoSendFiltro: JSON.parse(copyStorage),
        });
      } else {
        getListCandidateEnviadoContrato({ idCliente, token, objetoSendFiltro });
      }
    }
  }, [token]);

  useEffect(() => {
    let arrayGridFilter = [...listaCandidatoEnviadoContrato];
    if (filterChange) {
      const { rut, areaNegocio, grupo, fechaValidacion } = filterChange;
      if (rut.value && rut.value.length > 0) {
        const rutArray = rut.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          rutArray.includes(item.personaDni)
        );
      }
      if (areaNegocio.value && areaNegocio.value.length > 0) {
        const areaNegocioArray = areaNegocio.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          areaNegocioArray.includes(item.areaNegocioId)
        );
      }
      if (grupo.value && grupo.value.length > 0) {
        const grupoArray = grupo.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          grupoArray.includes(item.busquedaId)
        );
      }
      if (fechaValidacion.value && fechaValidacion.value.length > 0) {
        const fechaValidacionArray = fechaValidacion.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          fechaValidacionArray.includes(
            formatDateToDDMMAAAA({ date: item.ingresadoFecha })
          )
        );
      }
    }

    setLengthFilters(arrayGridFilter.length);

    if (range) {
      const { initRange, endRange } = range;
      arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
    }

    setGridCandidatoAContratacion(arrayGridFilter);

    setListCandidatoAContratoFull(
      arrayGridFilter.map((item: IListaCandidatos) => {
        return {
          personaDni: item.personaDni,
          busPerId: item.busPerId,
          primerNombre: item.primerNombre,
          primerApellido: item.primerApellido,
          fechaValidacion: item.fechaValidacion,
          ficha: item.ficha,
          areaNegocioCod: item.areaNegocioCod,
          tipoContrato:
            item.division === "TWEST"
              ? "CONTRATO PLAZO FIJO"
              : "CONTRATO INDEFINIDO",
          division: item.division,
          estadoContrato: item.estadoContrato,
          historial: item.historial[1].fecha,
          comentario: item.comentario,
        };
      })
    );

    // console.log("gridCandidatoAContratacion", gridCandidatoAContratacion);
  }, [
    filterChange,
    listaCandidatoEnviadoContrato,
    listCandidatoEnviadoContrato,
    range,
  ]);

  const clearListDescarteBloqueo = () => {
    setListDescarteBloqueo([]);
  };

  const handleClickAddListMasive = (
    personaDni: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    ficha: string,
    areaNegocioCod: string,
    estadoContrato: string,
    division: string,
    historial: Date,
    comentario: string,
    causalLetra: string
  ) => {
    const currentService = [...listCandidatoEnviadoContrato];

    const dataObject = {
      personaDni,
      busPerId,
      primerNombre,
      primerApellido,
      ficha,
      areaNegocioCod,
      division,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      estadoContrato,
      historial,
      comentario,
      causalLetra,
    };

    if (!currentService.some((c: any) => c.personaDni === personaDni)) {
      currentService.push(dataObject);
    } else {
      const positionDeleted = currentService.findIndex(
        (data) => data.personaDni === personaDni
      );
      currentService.splice(positionDeleted, 1);
    }
    setListCandidatoEnviadoContrato(currentService);
  };

  useEffect(() => {}, [listCandidatoEnviadoContrato]);

  useEffect(() => {
    // Código que se ejecuta después de la redirección
    setTimeout(() => {
      const tablaElement = document.getElementById("tablaEnviadoContrato");

      if (tablaElement && localStorage.getItem("Filtros2")) {
        tablaElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  const handleClickDescarteGo = () => {
    setOpenModalDescartar(false);
  };

  const handleClickPersonIdMasive = () => {
    listCandidatoEnviadoContrato.length == listCandidatoAContratoFull.length
      ? setListCandidatoEnviadoContrato([])
      : setListCandidatoEnviadoContrato(
          listCandidatoAContratoFull.map((item: IListaCandidatos) => {
            setRutCandidate(item.personaDni);

            return {
              personaDni: item.personaDni,
              busPerId: item.busPerId,
              primerNombre: item.primerNombre,
              primerApellido: item.primerApellido,
              historial: item.historial,
              ficha: item.ficha,
              areaNegocioCod: item.areaNegocioCod,
              division: item.division,
              tipoDocumentoValue:
                item.division === "TWEST"
                  ? "CONTRATO PLAZO FIJO"
                  : "CONTRATO INDEFINIDO",
              estadoContrato: item.estadoContrato,
              comentario: item.comentario,
              causalLetra: item.causalLetra,
            };
          })
        );
  };
  const handleClickModalDatosIndividual = ({
    personaDni,
    busPerId,
    idCliente,
    comentario,
    estadoContrato,
  }: any) => {
    setComentario(comentario);
    setEstadoContrato(estadoContrato);
    let arr = [];
    arr.push(busPerId);
    setABusPerId(arr);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDni);
    setComentario(comentario);
    setOnlyRead(
      estadoContrato == "FUERA_PLAZO" || estadoContrato == "RECHAZADO"
        ? false
        : true
    );
  };

  const handleClickModalDetener = (
    personaDni: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    ficha: string,
    areaNegocioCod: string,
    estadoContrato: string,
    division: string,
    historial: Date,
    causalLetra: string
  ) => {
    const arrDet = [];
    const dataObject = {
      personaDni,
      busPerId,
      primerNombre,
      primerApellido,
      ficha,
      areaNegocioCod,
      estadoContrato,
      division,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      historial,
      causalLetra,
    };
    arrDet.push(dataObject);
    setCandidateDetener(arrDet);
    setOpenModalDetener(true);
  };

  const handleClickSolicitudPend = (
    personaDni: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    ficha: string,
    division: string,
    historial: Date
  ) => {
    const arrPendienteCertificado = [];
    const dataObject = {
      personaDni,
      busPerId,
      primerNombre,
      primerApellido,
      ficha,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      historial,
    };
    arrPendienteCertificado.push(dataObject);
    setCandidatePorCertificadoPendiente(arrPendienteCertificado);
    setOpenModalSolicitudPend(true);
  };

  return (
    <>
      <div id="tablaEnviadoContrato" className="content-header-table">
        <span>Candidatos enviados a contrato</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th>
                <ButtonIcon
                  onClick={() => {
                    handleClickPersonIdMasive();
                  }}
                >
                  {listCandidatoEnviadoContrato.length ==
                    listCandidatoAContratoFull.length &&
                  listCandidatoEnviadoContrato.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ButtonIcon>
              </th>

              <th>Estado</th>
              <th>Nombre</th>
              <th>Rut</th>
              {width <= sm ? (
                <>
                  <th>Área de Negocio</th>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>Área de Negocio</th>
                  <th>N° Ficha</th>
                  <th>División TW</th>
                  <th>Fecha Solicitud</th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoAContratacion &&
              gridCandidatoAContratacion.map((item: IListaCandidatos) => {
                const {
                  busPerId,
                  busquedaId,
                  areaNegocioId,
                  areaNegocioCod,
                  ficha,
                  personaDni,
                  primerNombre,
                  segundoNombre,
                  primerApellido,
                  sesgundoApellido,
                  busPerStatus,
                  ingresadoFecha,
                  comentario,
                  division,
                  historial,
                  estadoContrato,
                  causalLetra,
                } = item;
                return (
                  <>
                    <tr
                      key={personaDni}
                      className={
                        listCandidatoEnviadoContrato.length > 0 &&
                        listCandidatoEnviadoContrato.some(
                          (l: any) => l.personaDni === personaDni
                        )
                          ? "selected borderdashed"
                          : "borderdashed"
                      }
                    >
                      <td>
                        <ButtonIcon
                          onClick={() => {
                            setRutCandidate(personaDni);
                            handleClickAddListMasive(
                              personaDni,
                              busPerId,
                              primerNombre,
                              primerApellido,
                              ficha,
                              areaNegocioCod,
                              estadoContrato,
                              division,
                              historial[1].fecha,
                              comentario,
                              causalLetra
                            );
                          }}
                        >
                          {listCandidatoEnviadoContrato.some(
                            (l: any) => l.personaDni === personaDni
                          ) ? (
                            <>
                              <CheckBoxIcon />

                              {disableButtonBlock(true)}
                              {listCandidatoEnviadoContrato.some(
                                (l: any) =>
                                  l.estadoContrato === "PROCESANDO" ||
                                  l.estadoContrato === "RECHAZADO"
                              )
                                ? disableButtonBlockOnly(false)
                                : disableButtonBlockOnly(true)}
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              {disableButtonBlock(false)}
                            </>
                          )}
                        </ButtonIcon>
                      </td>
                      <td>
                        {" "}
                        <div className="content-icon-table">
                          {estadoContrato == "RECHAZADO" ? (
                            <>
                              <IconSolicitudRechazada className="icon-estado" />
                              <span className="textStado">
                                Solicitud <br />
                                Rechazada
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "PROCESANDO" ? (
                            <>
                              <IconSolicitudObservacion
                                fill={"#E3A000"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Procesando en <br />
                                Sistema
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "PEND_FIRMA_DIGITAL" ? (
                            <>
                              <IconSolicitudObservacion
                                fill={"#FF4900"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Pendiente Actualizar <br />
                                Certificado Digital
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "ACEPTADO_PEND_FIRMA" ? (
                            <>
                              <IconPendienteFirma
                                fill={"#FF4900"}
                                className="icon-estado"
                              />
                              <span className="textStado">
                                Pendiente firma <br />
                                Digital
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "FUERA_PLAZO" ? (
                            <>
                              <IconNewFueraPlazo className="icon-estado" />
                              <span className="textStado">
                                Solicitud fuera de <br />
                                plazo pendiente
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "ACEPTADA" ? (
                            <>
                              <IconSolicitudAcept className="icon-estado" />
                              <span className="textStado">
                                Solicitud aceptada,
                                <br />
                                doc. generado
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "PEND_REVISION" ? (
                            <>
                              <IconPendRevision className="icon-estado" />
                              <span className="textStado">
                                Solicitud pendiente
                                <br />
                                de revisión
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {estadoContrato == "ACEPTADO_PEND_SOFTLAND" ? (
                            <>
                              <IconPendSoftlan className="icon-estado" />
                              <span className="textStado">
                                Doc Pendiente de
                                <br />
                                Ingreso en Softland
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        {primerNombre} {segundoNombre} {primerApellido}{" "}
                        {sesgundoApellido}
                      </td>
                      <td>{personaDni}</td>
                      {width <= sm ? (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>
                            <div className="content-icon-table">
                              {/*  <BootstrapTooltip
                                title="Actualizar Solicitud Pendiente "
                                placement="bottom-start"
                              >
                                <button onClick={() => handleClickSolicitudPend()}
                                >
                                  <div>
                                    <AutorenewIcon
                                      className={`button-actions ${"active-registro"}`}
                                    />
                                  </div>
                                </button>
                      </BootstrapTooltip>*/}

                              <BootstrapTooltip
                                title="Registro contrato Individual"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDni,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoContrato,
                                    })
                                  }
                                  disabled={estadoContrato == "PROCESANDO"}
                                >
                                  {
                                    <div className=" imageA">
                                      <IconoRegistroI
                                        className={
                                          estadoContrato == "PROCESANDO"
                                            ? "active-registro"
                                            : "disabled"
                                        }
                                      />
                                      <IconExclamacion
                                        naranja={true}
                                        className="imageB"
                                      />
                                    </div>
                                  }
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Detener Solictud"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDetener(
                                      personaDni,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      ficha,
                                      areaNegocioCod,
                                      estadoContrato,
                                      division,
                                      historial[1].fecha,
                                      causalLetra
                                    )
                                  }
                                  disabled={estadoContrato == "PROCESANDO"}
                                >
                                  <div>
                                    <IconStop
                                      className={`button-actions ${
                                        estadoContrato == "PROCESANDO"
                                          ? "disabled"
                                          : "block"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Actualizar Solicitud Pendiente "
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickSolicitudPend(
                                      personaDni,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      ficha,
                                      division,
                                      historial[1].fecha
                                    )
                                  }
                                  disabled={estadoContrato == "PROCESANDO"}
                                >
                                  <div>
                                    <AutorenewIcon
                                      className={`${
                                        estadoContrato == "PEND_FIRMA_DIGITAL"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>{ficha != null ? ficha : "-"}</td>
                          <td>{division}</td>
                          <td>
                            {formatDateToDDMMYY({ date: historial[1].fecha })}
                          </td>
                          <td>
                            <div className="content-icon-table">
                              <BootstrapTooltip
                                title="Registro contrato Individual"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDni,
                                      busPerId,
                                      idCliente,
                                      comentario,
                                      estadoContrato,
                                    })
                                  }
                                  //disabled={estadoContrato == "PROCESANDO"}
                                >
                                  {}
                                  {estadoContrato !== "RECHAZADO" &&
                                  estadoContrato !== "PEND_FIRMA_DIGITAL" &&
                                  estadoContrato !== "FUERA_PLAZO" ? (
                                    <IconoRegistroI
                                      className={`${"active-registro"}`}
                                    />
                                  ) : (
                                    <div className=" imageA">
                                      <IconoRegistroI
                                        className={"active-registro"}
                                      />
                                      <IconExclamacion className="imageB" />
                                    </div>
                                  )}
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Detener Solictud"
                                placement="bottom-start"
                              >
                                <button
                                  className={`${
                                    estadoContrato == "PROCESANDO"
                                      ? "disabled"
                                      : "button-actions block"
                                  }`}
                                  onClick={() =>
                                    handleClickModalDetener(
                                      personaDni,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      ficha,
                                      areaNegocioCod,
                                      estadoContrato,
                                      division,
                                      historial[1].fecha,
                                      causalLetra
                                    )
                                  }
                                  disabled={estadoContrato == "PROCESANDO"}
                                >
                                  {listCandidatoEnviadoContrato.some(
                                    (l: any) =>
                                      l.estadoContrato === "PROCESANDO"
                                  )
                                    ? disableButtonBlockOnly(false)
                                    : disableButtonBlockOnly(true)}
                                  <div>
                                    <IconStop
                                      className={`${
                                        estadoContrato === "PROCESANDO"
                                          ? "disabled"
                                          : "button-actions block"
                                      }`}
                                      disabled={
                                        estadoContrato == "PROCESANDO"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>

                              <BootstrapTooltip
                                title="Actualizar Solicitud Pendiente "
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickSolicitudPend(
                                      personaDni,
                                      busPerId,
                                      primerNombre,
                                      primerApellido,
                                      ficha,
                                      division,
                                      historial[1].fecha
                                    )
                                  }
                                  disabled={
                                    estadoContrato !== "PEND_FIRMA_DIGITAL"
                                  }
                                >
                                  <div>
                                    <AutorenewIcon
                                      className={`${
                                        estadoContrato == "PEND_FIRMA_DIGITAL"
                                          ? "button-actions active-registro"
                                          : "disabled"
                                      }`}
                                    />
                                  </div>
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalDetener && (
        <ModalDetener
          modalType="Detener"
          title="DETENER SOLICITUD DE CONTRATO"
          subTitle="Vas a cancelar la solicitud de contrato para los siguientes candidatos:"
          buttonBack="Volver"
          onChangeBack={() => {
            setCandidateDetener([]);
            setOpenModalDetener(false);
          }}
          modalStyle={""}
          rut={rutCandidate}
          busPerId={aBusPerId}
          idCliente={idCliente}
          list={candidateDetener}
        />
      )}

      {isOpenModalIndividual && (
        <ModalFicha
          areaContrato={true}
          rut={rutCandidate}
          busPerId={aBusPerId}
          idCliente={idCliente}
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setIsOpenModalIndividual(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          setOpenModalFicha={setOpenModalFicha}
          comentario={comentario}
          estado={estadoContrato}
          onlyRead={onlyRead}
        />
      )}

      {openModalSolicitudPend && (
        <ModalConfSolicitud
          modalStyle="confirm"
          modalType="actualiza_pendiente"
          title="Actualizar Solicitud Pendiente"
          subTitle="Estás por confirmar la actualización del certificado digital de las siguientes solicitudes:  "
          list={candidatePorCertificadoPendiente}
          onChangeBack={() => setOpenModalSolicitudPend(false)}
          onChangeGo={() => setOpenModalSolicitudPend(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
        />
      )}
    </>
  );
};

export default TablaCandidatoEnviadoContrato;
