import { useContext, useEffect, useState } from "react";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import SeccionDetalleProceso from "./SeccionDetalleProceso";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import SeccionAsignarPersonal from "./SeccionAsignarPersonal";

interface IModalConfirm {
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
  token: string | undefined;
  getToken: Function;
}

export default (props: IModalConfirm) => {
  const {
    title,
    subTitle,
    message,
    onChangeBack,
    buttonBack,
    buttonGo,
    list,
    token,
    getToken,
  } = props;
  const { getProcesos, listProcesos, listaUsuarios, createAsignarEquipo } =
    useContext(SeleccionContext);
  const [listadoEquipo, setListadoEquipo] = useState<any[]>();
  const [listaAnalistas, setListaAnalistas] = useState<IOptionDropDownList[]>(
    []
  );
  const [botonEncargado, setBotonEncargado] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    encargadoId: { value: undefined, valid: true },
    analistaId: { value: undefined, valid: true },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token && list) {
      getProcesos({ token, procesos: list, setIsLoading });
    }
  }, [token]);

  useEffect(() => {
    if (listaUsuarios) {
      const usuarios = listaUsuarios.map((item: any) => {
        const { usuarioId, usuarioNombre } = item;
        return {
          code: usuarioId,
          name: usuarioNombre,
        };
      });

      setListaAnalistas(usuarios);
    }
  }, [listaUsuarios]);

  useEffect(() => {
    if (listaUsuarios && listProcesos) {
      const equipo = listProcesos?.map((item: any) => item.equipo);
      const team = equipo?.flat().map((item: any) => {
        const { usuarioId, role } = item;
        return {
          usuarioId,
          role,
        };
      });
      setListadoEquipo(team);
    }
  }, [listProcesos, listaUsuarios]);

  useEffect(() => {
    if (listadoEquipo) {
      setBotonEncargado(
        listadoEquipo.some((item: any) => item.role == "accountManager")
      );
    }
  }, [listadoEquipo]);

  const handleClickAddPersonTeam = (values: any) => {
    const { type } = values;
    let newPerson = {};
    if (listadoEquipo && type === "accountManager") {
      const currentData = [...listadoEquipo];
      if (formData.encargadoId?.value) {
        newPerson = {
          usuarioId: formData.encargadoId.value.code ?? "",
          role: "accountManager",
        };
        currentData.push(newPerson);
        setListadoEquipo(currentData);
      }
      return;
    }
    if (listadoEquipo && type === "analyst") {
      const currentData = [...listadoEquipo];
      if (formData.analistaId?.value) {
        newPerson = {
          usuarioId: formData.analistaId.value.code ?? "",
          role: "analyst",
        };
        if (currentData.includes(formData.analistaId?.value.code)) {
          
        }
        currentData.push(newPerson);
      }
      setListadoEquipo(currentData);
      return;
    }
  };

  const handleClickSaveTeam = () => {
    if (token && listProcesos) {
      getToken();
      const procesos = listProcesos.map((item: any) => item.busquedaId);
      createAsignarEquipo({
        token,
        equipo: listadoEquipo,
        procesos: procesos,
      });
      handleClickCloseModal(); 
    }
  };

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="modal-container-seleccion">
            <div className="modal-content-seleccion w-80">
              {/* <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span> */}
              <div className="modal-title">
                <span>{title}</span>
              </div>
              <div className="modal-subtitle">
                <span>{subTitle}</span>
              </div>
              {message && (
                <>
                  <div className="modal-message">
                    <span>{message}</span>
                  </div>
                </>
              )}

              {list && list.length > 1 ? (
                <>
                  {listProcesos &&
                    listProcesos.map((item: any) => {
                      return <SeccionDetalleProceso list={item} listaMasiva={true} />;
                    })}
                </>
              ) : (
                <>
                  {listProcesos &&
                    listProcesos.map((item: any) => {
                      return <SeccionDetalleProceso list={item} listaMasiva={false} />;
                    })}
                </>
              )}
              <section className={`project-table`}>
                <div className="process-content">
                  <div className="section-asignar w-45">
                    <DropDownListCustomized
                      list={listaAnalistas}
                      layer="Encargado de Cuenta"
                      placeholder="Seleccione"
                      errorPosition="down"
                      onValueChange={setFormData}
                      onPermaValues={formData}
                      onPropertyChange="encargadoId"
                    />
                    <button
                      onClick={() =>
                        handleClickAddPersonTeam({ type: "accountManager" })
                      }
                      className="button-add"
                      disabled={botonEncargado}
                    >
                      <AddBoxRoundedIcon
                        className={`svg-add  ${
                          botonEncargado ? "disabled" : "active"
                        }`}
                      />
                    </button>
                  </div>
                  <div className="section-asignar w-45">
                    <DropDownListCustomized
                      list={listaAnalistas}
                      layer="Analista"
                      placeholder="Seleccione"
                      errorPosition="down"
                      onValueChange={setFormData}
                      onPermaValues={formData}
                      onPropertyChange="analistaId"
                    />
                    <button
                      onClick={() =>
                        handleClickAddPersonTeam({ type: "analyst" })
                      }
                      className="button-add"
                    >
                      <AddBoxRoundedIcon className="svg-add active" />
                    </button>
                  </div>
                </div>
              </section>

              <SeccionAsignarPersonal
                list={listProcesos}
                token={token}
                getToken={getToken}
                listadoEquipo={listadoEquipo}
                setListadoEquipo={setListadoEquipo}
              />

              <div className="modal-button-content contratacion">
                <>
                  <button
                    className={`modal-button cancel`}
                    onClick={handleClickCloseModal}
                  >
                    {buttonBack ? buttonBack : "Cerrar"}
                  </button>
                  <button
                    className={`modal-button confirm`}
                    onClick={handleClickSaveTeam}
                  >
                    {buttonGo ? buttonGo : "Aceptar"}
                  </button>
                </>
              </div>
              <section></section>
            </div>
          </div>
        </>
      )}
    </>
  );
};
