import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          d="M9.81833 6.07667V0.5H1.605C1.06833 0.5 0.625 0.943333 0.625 1.48V20.52C0.625 21.0683 1.06833 21.5 1.605 21.5H15.395C15.9433 21.5 16.375 21.0567 16.375 20.52V7.05667H10.7983C10.2617 7.05667 9.81833 6.61333 9.81833 6.07667ZM9.725 8.50333L9.45667 16.5533H7.54333L7.275 8.50333H9.73667H9.725ZM9.43333 19.9017C9.2 20.135 8.885 20.24 8.5 20.24C8.115 20.24 7.81167 20.1233 7.56667 19.9017C7.33333 19.6683 7.21667 19.3883 7.21667 19.0267C7.21667 18.665 7.33333 18.385 7.56667 18.1517C7.8 17.9183 8.115 17.8017 8.5 17.8017C8.885 17.8017 9.2 17.9183 9.43333 18.14C9.66667 18.3733 9.78333 18.665 9.78333 19.015C9.78333 19.365 9.66667 19.6567 9.43333 19.89V19.9017Z"
        />
        <path
          d="M16.0833 4.805L12.07 0.791667C11.8833 0.605 11.6383 0.5 11.37 0.5H11.125V5.75H16.375V5.505C16.375 5.24833 16.27 4.99167 16.0833 4.81667V4.805Z"
        />
      </g>
    </svg>
  );
};
