import { useState, useEffect, useContext } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TablaCandidato from "./TablaCandidato";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import TooltipMessage from "../../../../ui/kit-mdtw/tooltip/TooltipMessage";
import { IListaFormatoPsicolaboral } from "../../../../../interfaces/ISeleccion";

interface IItemGrupo {
  personas: any;
  busquedaId: string;
  busquedaFin: string;
  cargoGroupName: string;
  clienteNombre?: string;
  cargoNombre?: string;
  cuposRestantes?: string;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string;
  localStorageFilter: string;
  formFilter: string;
  refreshTable: Function;
  selectCurrent: any;
  setSelectCurrent: Function;
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[];
}

interface ISelectMasive {
  personaDNI: any;
}

export default (props: IItemGrupo) => {
  const {
    personas,
    busquedaId,
    clienteNombre,
    busquedaFin,
    cargoGroupName,
    cargoNombre,
    cuposRestantes,
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    localStorageFilter,
    formFilter,
    refreshTable,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
  } = props;
  const [hiddenTable, setHiddenTable] = useState<boolean>(false);
  const [listaSeleccionProceso, setListaSeleccionProceso] = useState<string[]>(
    []
  );
  const [gridCandidatoValidado, setGridCandidatoValidado] = useState<any[]>([]);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });

  useEffect(() => {
    if (localStorage.getItem(localStorageSelect)) {
      const multi: string = String(localStorage.getItem(localStorageSelect));
      setListaSeleccionProceso(createListMutate({ list: multi.split(",") }));
    }
  }, []);

  useEffect(() => {
    if (listadoSeleccionado) {
      setListaSeleccionProceso(createListMutate({ list: listadoSeleccionado }));
    }
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (personas) {
      let arrayGridFilter = [...personas];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoValidado(arrayGridFilter);
    }
  }, [personas, range]);

  const createListMutate = (values: any): string[] => {
    const { list } = values;
    const arrayProcesos: string[] = personas.map(
      ({ personaDNI }: any) => personaDNI
    );
    return list.filter((personaDNI: string) =>
      arrayProcesos.includes(personaDNI)
    );
  };

  const handleClickMultiChoise = (): void => {
    const arrayListaProceso: string[] = [...listaSeleccionProceso];
    const arrayFull: string[] = [...listadoSeleccionado];
    if (personas.length > arrayListaProceso.length) {
      personas
        .map(({ personaDNI }: any) => personaDNI)
        .filter((personaDNI: string) => !arrayListaProceso.includes(personaDNI))
        .map((personaDNI: string) => {
          arrayListaProceso.push(personaDNI);
          arrayFull.push(personaDNI);
        });
    } else {
      arrayListaProceso.splice(0, personas.length);
      personas
        .map(({ personaDNI }: any) => personaDNI)
        .map((personaDNI: string) => {
          const position: number = arrayFull.findIndex(
            (item: string) => item === personaDNI
          );
          arrayFull.splice(position, 1);
        });
    }

    localStorage.setItem(localStorageSelect, String(arrayFull));
    setListadoSeleccionado(arrayFull);
    setListaSeleccionProceso(arrayListaProceso);
  };

  const handleClickAddOrRemoveChoise = (prompts: ISelectMasive): void => {
    const { personaDNI } = prompts;
    const arrayFull: string[] = [...listadoSeleccionado];
    const arrayListaProceso = [...listaSeleccionProceso];

    if (!arrayListaProceso.includes(personaDNI)) {
      arrayListaProceso.push(personaDNI);
    } else {
      const position: number = arrayListaProceso.findIndex(
        (item: string) => item === personaDNI
      );
      arrayListaProceso.splice(position, 1);
    }

    if (!arrayFull.includes(personaDNI)) {
      arrayFull.push(personaDNI);
    } else {
      const position: number = arrayFull.findIndex(
        (item: string) => item === personaDNI
      );
      arrayFull.splice(position, 1);
    }

    localStorage.setItem(localStorageSelect, String(arrayFull));
    setListadoSeleccionado(arrayFull);
    setListaSeleccionProceso(arrayListaProceso);
  };

  return (
    <>
      <>
        <div className="content-header-table">
          <span className="title-header-table">
            <label className="text-proceso">GRUPO: </label>
            <label className="text-proceso proceso-content">
              {cargoGroupName}
            </label>
          </span>
          <span className="title-header-table">
            <label className="text-proceso">PROCESO: </label>
            <label className="text-proceso proceso-content">
              {busquedaId}-{cargoNombre}
            </label>
          </span>
          <span className="title-header-table">
            <label className="text-proceso">CUPOS: </label>
            <label className="text-proceso proceso-content">
              {cuposRestantes}
            </label>
          </span>
          <span className="title-header-table">
            <label className="text-proceso">FECHA ENTREGA: </label>
            <label className="text-proceso proceso-content">
              {busquedaFin}
            </label>
          </span>
          <button
            className={`button-hidden-table`}
            onClick={() => setHiddenTable(!hiddenTable)}
          >
            <KeyboardArrowDownIcon
              className={`svg-table-hidden ${
                hiddenTable ? "icon-down" : "icon-up"
              }`}
            />
          </button>
        </div>
        <div className={`container-table-list ${hiddenTable ? "hidden" : ""}`}>
          <table className="content-table">
            <thead className="thead-table-procesos">
              <tr>
                <th className="th-table-procesos">
                  <button
                    className="button-select"
                    onClick={() => handleClickMultiChoise()}
                  >
                    {listaSeleccionProceso.length == personas.length &&
                    listaSeleccionProceso.length > 0 ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </button>
                </th>
                <th className="th-table-procesos">
                  <TooltipMessage
                    title="Estado Registro Candidato"
                    page="profesional"
                  >
                    Estado <br></br>Registro
                  </TooltipMessage>
                </th>
                <th className="th-table-procesos">Nombre</th>
                <th className="th-table-procesos">Rut</th>
                <th className="th-table-procesos">Celular</th>
                <th className="th-table-procesos">
                  Fecha <br></br>Preselección
                </th>
                <th className="th-table-procesos">
                  Fecha <br></br>Selección
                </th>
                <th className="th-table-procesos">
                  Entrevista <br></br>Realizada
                </th>
                <th className="th-table-procesos">Acciones</th>
              </tr>
            </thead>
            <tbody className="tbody-table-procesos">
              <TablaCandidato
                listaPersona={gridCandidatoValidado}
                handleClickAddOrRemoveChoise={handleClickAddOrRemoveChoise}
                listaSeleccionProceso={listaSeleccionProceso}
                localStorageFilter={localStorageFilter}
                formFilter={formFilter}
                refreshTable={refreshTable}
                setListadoSeleccionado={setListadoSeleccionado}
                selectCurrent={selectCurrent}
                setSelectCurrent={setSelectCurrent}
                tipoDocumentoAll={tipoDocumentoAll}
                setTipoDocumentoAll={setTipoDocumentoAll}
                listaFormatoPsicolaboral={listaFormatoPsicolaboral}
              />
            </tbody>
          </table>
        </div>
        <FooterTable
          length={lengthFilters}
          setRange={setRange}
          pageCurrent={changePage ? 1 : undefined}
        />
      </>
    </>
  );
};
