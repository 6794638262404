import { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MediaQuery from "../../../../ui-responsive/MediaQuery";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import {
  IFiltersTable,
  IListaCandidatos,
} from "../../../../../interfaces/IValidationKam";
import DropDownListMultipleCustomized from "../../../../ui/kit-mdtw/DropDownListMultipleCustomized";

export default (props: IFiltersTable) => {
  const { width, lg } = MediaQuery();
  const { list, setFilterChange } = props;
  const [rut, setRut] = useState<IOptionDropDownList[]>([]);
  const [areaNegocio, setAreaNegocio] = useState<IOptionDropDownList[]>([]);
  const [grupo, setGrupo] = useState<IOptionDropDownList[]>([]);
  const [fechaValidacion, setFechaValidacion] = useState<IOptionDropDownList[]>(
    []
  );
  const [estado, setEstado] = useState<IOptionDropDownList[]>([]);
  const [filter, setFilter] = useState<any>({
    rut: { value: undefined, valid: true },
    areaNegocio: { value: undefined, valid: true },
    grupo: { value: undefined, valid: true },
    fechaValidacion: { value: undefined, valid: true },
    estado: { value: undefined, valid: true },
  });

  useEffect(() => {
    if (list) {
      const filterRut: string[] = [];
      const filterAreaNegocio: string[] = [];
      const filterGrupo: string[] = [];
      const filterFechaValidacion: string[] = [];
      const filterEstado: string[] = [];

      list.map((item: IListaCandidatos) => {
        if (!filterRut.includes(item.personaDni)) {
          filterRut.push(item.personaDni);
        }
        if (!filterAreaNegocio.includes(item.areaNegocioId)) {
          filterAreaNegocio.push(item.areaNegocioId);
        }
        if (!filterGrupo.includes(item.busquedaId)) {
          filterGrupo.push(item.busquedaId);
        }
        if (
          !filterFechaValidacion.includes(
            formatDateToDDMMAAAA({ date: item.confirmadoFecha })
          )
        ) {
          filterFechaValidacion.push(
            formatDateToDDMMAAAA({ date: item.confirmadoFecha })
          );
        }
      });

      setRut(
        filterRut.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );

      setAreaNegocio(
        filterAreaNegocio.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
      setGrupo(
        filterGrupo.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
      setFechaValidacion(
        filterFechaValidacion.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
      setEstado([
        { code: "A CONTRATACION", name: "A CONTRATACION" },
        { code: "DESCARTADO / BLOQUEADO", name: "DESCARTADO / BLOQUEADO" },
      ]);
    }
  }, [list]);

  useEffect(() => {
    if (typeof setFilterChange == "function") {
      setFilterChange(filter);
    }
  }, [filter]);

  return (
    <>
      {width >= lg && (
        <div className="container-filter">
          <div className="content-filter">
            <button>
              <FilterAltOutlinedIcon />
            </button>

            <div>
              <DropDownListMultipleCustomized
                list={rut}
                layer="Rut"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="rut"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={areaNegocio}
                layer="Área Negocio"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="areaNegocio"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={grupo}
                layer="Grupo"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="grupo"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={fechaValidacion}
                layer="Fecha Validación"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="fechaValidacion"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={estado}
                layer="Estado"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="estado"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
