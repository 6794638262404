import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path d="M7.60493 9.00677C8.75308 9.00677 9.86419 8.53763 10.679 7.71047C11.4938 6.88332 11.9506 5.74751 11.9506 4.57467C11.9506 3.40184 11.4938 2.27838 10.679 1.43887C9.86419 0.611714 8.76543 0.142578 7.60493 0.142578C6.44444 0.142578 5.34568 0.611714 4.53086 1.43887C3.71605 2.26603 3.25926 3.40184 3.25926 4.57467C3.25926 5.74751 3.71605 6.87097 4.53086 7.71047C5.34568 8.54998 6.44444 9.00677 7.60493 9.00677Z" />
        <path d="M14.6173 16.0315C13.8395 16.8092 12.4074 17.0685 11.3086 16.0932C10.2839 15.1796 10.5555 13.7722 11.3333 13.0068L12.5432 11.7969C11.5185 10.9821 10.5432 10.6611 9.14814 10.6611H6.04938C2.7037 10.6611 0 13.4265 0 16.8339C0 17.4018 0.45679 17.8586 1.01235 17.8586H14.1975C14.7531 17.8586 15.2099 17.4018 15.2099 16.8339C15.2099 16.3278 15.321 15.908 15.2099 15.4512L14.6296 16.0315H14.6173Z" />
        <path d="M12.037 6.94504C12.5309 6.45122 13.3457 6.45122 13.8395 6.94504L16.3333 9.43887L18.8272 6.94504C19.321 6.45122 20.1358 6.45122 20.6296 6.94504C21.1235 7.43887 21.1235 8.25368 20.6296 8.74751L18.1358 11.2413L20.6296 13.7352C21.1235 14.229 21.1235 15.0438 20.6296 15.5376C20.1358 16.0315 19.321 16.0315 18.8272 15.5376L16.3333 13.0438L13.8395 15.5376C13.3457 16.0315 12.5309 16.0315 12.037 15.5376C11.5432 15.0438 11.5432 14.229 12.037 13.7352L14.5309 11.2413L12.037 8.74751C11.5432 8.25368 11.5432 7.43887 12.037 6.94504Z" />
      </g>
    </svg>
  );
};
