import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ActionMassive from "./ActionMassive";
import {
  ICandidatoInhabilitado,
  ICandidatoNoDisponible,
} from "../../../../../interfaces/ISeleccion";
import IconRegistroCandidato from "../../../../ui/icons-seleccion/IconRegistroCandidato";
import ModalEncuesta from "../../../Campaign/ModalEncuesta";
import IconSolicitarCandidato from "../../../../ui/icons-seleccion/IconSolicitarCandidato";
import IconRegistroOcupado from "../../../../ui/icons-seleccion/IconRegistroOcupado";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalSolicitarRegistro from "../Modal/ModalSolicitarRegistro";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import ModalFichaForRead from "../../../../ui/kit-modal/ModalFichaLectura/ModalFichaForRead";
import IconLoading from "../../../../ui/kit-svg/IconLoading";

const TablaCandidatoNoDisponible = () => {
  const {
    listaCandidatoNoDisponible,
    getCandidatoNoDisponible,
    token,
    getRutVerificado,
    postSolicitarCandidato,
    getDocument,
    getToken,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [candidateSelect, setCandidateSelect] = useState<any[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [resultDNIForModal, setResultDNIForModal] = useState<string>("");
  const [resultpersonaIdForModal, setResultpersonaIdForModal] =
    useState<any>("");
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);

  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [isOpenModalSolicitar, setIsOpenModalSolicitar] =
    useState<boolean>(false);
  const [solicitudes, setSolicitudes] = useState<any>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  const [gridCandidatoNoDisponible, setGridCandidatoNoDisponible] = useState<
    any[]
  >([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoNoDisponible"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    rut: [],
    fuenteReclutamiento: [],
    fechaPostulacion: [],
    reclutador:[]
  });

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getCandidatoNoDisponible({
          token,
          filtros: JSON.parse(copyStorage),
          setIsLoadingTable,
          setIsErrorTable,
        });
      } else {
        getCandidatoNoDisponible({
          token,
          filtros: formFilter,
          setIsLoadingTable,
          setIsErrorTable,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoNoDisponible) {
      const arrayList = listaCandidatoNoDisponible.map(
        (item: ICandidatoInhabilitado) => item.personaDNI
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaCandidatoNoDisponible]);

  useEffect(() => {
    if (listaCandidatoNoDisponible) {
      let arrayGridFilter = [...listaCandidatoNoDisponible];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridCandidatoNoDisponible(arrayGridFilter);
    }
  }, [listaCandidatoNoDisponible, range]);

  const handleClickAddOrRemoveChoise = (prompts: any): void => {
    const { personaDNI } = prompts;
    const arrayList: string[] = [...listadoSeleccionado];

    if (!arrayList.includes(personaDNI)) {
      arrayList.push(personaDNI);
    } else {
      const position: number = arrayList.findIndex(
        (item: string) => item === personaDNI
      );
      arrayList.splice(position, 1);
    }
    setListadoSeleccionado(arrayList);
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];

    if (listadoSeleccionado.length < currentCandidates.length) {
      currentCandidates
        .filter(
          (personaDNI: string) => !listadoSeleccionado.includes(personaDNI)
        )
        .map((personaDNI: string) => arrayListado.push(personaDNI));
    } else {
      arrayListado.splice(0, currentCandidates.length);
    }
    setListadoSeleccionado(arrayListado);
  };

  const handleClickVerificarRut = (rowData: any): void => {
    const { personaDNI, personaId } = rowData;
    setResultpersonaIdForModal(personaId);
    setResultDNIForModal(personaDNI);

    setCandidateSelect([personaDNI]);
    getRutVerificado({
      personaRut: personaDNI,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
      token,
    });
    getDocument({ personaDNI: personaDNI, token });
  };

  const handleClickSolicitar = (values: any): void => {
    const { personaDNI } = values;
    setIsOpenModalSolicitar(true);
    setCandidateSelect([personaDNI]);
  };

  const handleClickSolicitarCandidato = async (values: any): Promise<void> => {
    const { procesoDestino, setSolEnviada } = values;
    if (token) {
      await postSolicitarCandidato({ token, procesoDestino, setSolEnviada });
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        await getCandidatoNoDisponible({
          token,
          filtros: JSON.parse(copyStorage),
          setIsLoadingTable,
          setIsErrorTable,
        });
      } else {
        await getCandidatoNoDisponible({
          token,
          filtros: formFilter,
          setIsLoadingTable,
          setIsErrorTable,
        });
      }
    }
  };

  return (
    <>
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div className="content-multiple">
          <label className="title-multiple">
            <i className="selects-table-registro">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
          solicitudes={solicitudes}
          setSolicitudes={setSolicitudes}
          handleClickSolicitarCandidato={handleClickSolicitarCandidato}
        />
      </section>

      <section className="content-header-table">
        <label className="title-header-table">Candidatos No Disponibles</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">
                        <button
                          onClick={handleClickAddAll}
                          className="button-select"
                        >
                          {listadoSeleccionado.length ==
                            currentCandidates.length &&
                          listadoSeleccionado.length > 0 ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                        </button>
                      </th>
                      <th className="th-table-registros">Estado</th>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">Proceso</th>
                      <th className="th-table-registros">Reclutador</th>
                      <th className="th-table-registros">Fecha Uso</th>
                      <th className="th-table-registros">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridCandidatoNoDisponible &&
                      gridCandidatoNoDisponible.map(
                        (item: ICandidatoNoDisponible) => {
                          const {
                            personaDNI,
                            primerNombre,
                            primerApellido,
                            busquedaId,
                            reclutador,
                            fechaPosutlacion,
                            personaId,
                          } = item;
                          return (
                            <>
                              <tr
                                key={personaDNI}
                                className={`tr-table-registros ${
                                  listadoSeleccionado.includes(personaDNI) &&
                                  "checkeado"
                                }`}
                              >
                                <td className="td-table-registros">
                                  <button
                                    className="button-select"
                                    onClick={() =>
                                      handleClickAddOrRemoveChoise({
                                        personaDNI,
                                      })
                                    }
                                  >
                                    {listadoSeleccionado.includes(
                                      personaDNI
                                    ) ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </button>
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-state">
                                    {"17" === "17" && (
                                      <IconRegistroOcupado
                                        fill="#BE2A2A"
                                        className="content-icon"
                                      />
                                    )}
                                    <div className="content-state-text">
                                      {"17" === "17" && (
                                        <>
                                          <label className="title registro-ocupado">
                                            Registro Ocupado
                                          </label>
                                          <label className="message">
                                            Registro en proceso activo<br></br>{" "}
                                            en el sistema
                                          </label>
                                        </>
                                      )}
                                    </div>
                                  </section>
                                </td>
                                <td className="td-table-registros">
                                  {primerNombre} {primerApellido}
                                </td>
                                <td className="td-table-registros">
                                  {personaDNI}
                                </td>
                                <td className="td-table-registros">
                                  {busquedaId}
                                </td>
                                <td className="td-table-registros">
                                  {reclutador}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaPosutlacion,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  <section className="content-icon-table">
                                    <Tooltip text="Ver Registro">
                                      <button
                                        onClick={() =>
                                          handleClickVerificarRut({
                                            personaDNI,
                                            personaId,
                                          })
                                        }
                                        className="button-content"
                                      >
                                        <IconRegistroCandidato
                                          fill={"#0065DD"}
                                        />
                                      </button>
                                    </Tooltip>
                                    <Tooltip text="Solicitar Registro">
                                      <button
                                        className="button-content"
                                        onClick={() =>
                                          handleClickSolicitar({ personaDNI })
                                        }
                                      >
                                        <IconSolicitarCandidato
                                          fill={"#E3A000"}
                                        />
                                      </button>
                                    </Tooltip>
                                  </section>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}

      {isOpenModalUpdateCandidate && (
        <ModalFichaForRead
          areaContrato={false}
          rut={resultDNIForModal}
          title="REGISTRO CANDIDATO"
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          setOpenModalFicha={setOpenModalFicha}
          estado={"noDisponible"}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => {
            setIsOpenModalCampaign(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}

      {isOpenModalSolicitar && (
        <ModalGenerico
          width="w-70"
          title=""
          subtitle="Selecciona la fuente de reclutamiento y luego el archivo correspondiente."
          onChangeBack={() => setIsOpenModalSolicitar(false)}
          buttonBackText="Cerrar"
        >
          <ModalSolicitarRegistro
            rut={candidateSelect}
            solicitudes={solicitudes}
            setSolicitudes={setSolicitudes}
            onChangeBack={() => setIsOpenModalSolicitar(false)}
            handleClickSolicitarCandidato={handleClickSolicitarCandidato}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaCandidatoNoDisponible;
