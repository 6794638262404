import { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";
import { formatDateToDDMMAAAA } from "../../../functions/kit-mdtw/dates";
import MediaQuery from "../../ui-responsive/MediaQuery";
import DropDownListMultipleCustomized from "../../ui/kit-mdtw/DropDownListMultipleCustomized";
import { IAdminTable, IFiltersTable } from "../../../interfaces/IAdminAssist";

export default (props: IFiltersTable) => {
  const { width, lg } = MediaQuery();
  const { list, setFilterChange } = props;
  const [fecha, setFecha] = useState<IOptionDropDownList[]>([]);
  const [cliente, setCliente] = useState<IOptionDropDownList[]>([]);
  const [idProceso, setIdProceso] = useState<IOptionDropDownList[]>([]);
  const [tipoTransporte, setTipoTransporte] = useState<IOptionDropDownList[]>([]);
  const [estado, setEstado] = useState<IOptionDropDownList[]>([]);
  const [filter, setFilter] = useState<any>({
    cliente:{value: undefined, valid: true}, 
    fecha:{value: undefined, valid: true}, 
    idProceso:{value: undefined, valid: true}, 
    tipoTransporte:{value: undefined, valid: true}, 
    estado:{value: undefined, valid: true}, 
  })

  useEffect(() => {
    if (list) {
      const filterCliente: string[] = [];
      const filterEstado: string[] = [];
      const filterIdProceso: string[] = [];
      const filterFecha: string[] = [];
      const filterTipoTransporte: string[] = [];

      list.map((item: IAdminTable) => {
        if(!filterEstado.includes(item.estadoServicio)){
          filterEstado.push(item.estadoServicio);
        }
        if(!filterCliente.includes(item.clientes[0])){
          filterCliente.push(item.clientes[0]);
        }
        if(!filterIdProceso.includes(String(item.servicioId))){
          filterIdProceso.push(String(item.servicioId));
        }
        if(!filterFecha.includes(formatDateToDDMMAAAA({ date: String(item.fechaCreacion) }))){
          filterFecha.push(formatDateToDDMMAAAA({ date: String(item.fechaCreacion) }));
        }
      })

      setCliente(
        filterCliente.map((item) => {
          return {
            code: item,
            name: item,
          }
        })
      );
      
      setEstado(
        filterEstado.map((item) => {
          return {
            code: item,
            name: item,
          }
        })
      );
      setIdProceso(
        filterIdProceso.map((item) => {
          return {
            code: item,
            name: item,
          }
        })
      );
      setFecha(
        filterFecha.map((item) => {
          return {
            code: item,
            name: item,
          }
        })
      );
      setTipoTransporte(
        filterTipoTransporte.map((item) => {
          return {
            code: item,
            name: item,
          }
        })
      );
    }
  }, [list]);

  useEffect(() => {
    if(typeof setFilterChange == "function") {
      setFilterChange(filter)
    }
  },[filter])

  return (
    <>
      {width >= lg && (
        <div className="container-filter">
          <div className="content-filter">
            <button>
              <FilterAltOutlinedIcon />
            </button>

            <div>
              <DropDownListMultipleCustomized
                list={idProceso}
                layer="ID Proceso"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="idProceso"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={cliente}
                layer="Cliente"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="cliente"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={fecha}
                layer="Fecha"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="fecha"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={tipoTransporte}
                layer="Tipo Transporte"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="tipoTransporte"
              />
            </div>

            <div>
              <DropDownListMultipleCustomized
                list={estado}
                layer="Estado"
                placeholder="Seleccione"
                layerLeft={true}
                onValueChange={setFilter}
                onPermaValues={filter}
                onPropertyChange="estado"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
