import { ReactNode, useContext, useState, Component, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../assets/css/modal-generico.min.css";
import { IContratosColaborador } from "../../../../interfaces/IContratos";
import CheckIcon from "@mui/icons-material/Check";
import IconCheck from "../../../ui/icons-seleccion/IconCheck";
import ModalGenerico from "../../../ui/kit-modal/ModalGenerico";
interface IKitModalGenerico {
  title: string;
  iconTitle?: "success" | "danger" | "caution" | "option" | undefined;
  subtitle?: string;
  positionSubTitle?: "start" | "center" | "end";
  message?: string;
  messageBottom?: string;
  positionMessageBottom?: "start" | "center" | "end";
  buttonBackText?: string;
  buttonGoText?: string;
  buttonGoStyle?: "cancel" | "confirm" | "option" | "delete" | "naranja";
  onChangeBack?: Function;
  onChangeClose?: Function;
  onChangeGo?: Function;
  width: string;
  children?: ReactNode;
  psicolaboral?: boolean;
  tipo?: string;
  buttonGoDisable?: boolean;
  positionButton?: "center" | "end" | "start" | undefined;
  tabs: IContratosColaborador[];
  activeTab: string;
  handleOpenTab: Function;
  masive?: boolean;
  isLoading?: boolean;
  listaAceptados?: string[];
}

export default (props: IKitModalGenerico) => {
  const {
    title,
    subtitle,
    positionSubTitle,
    message,
    messageBottom,
    positionMessageBottom,
    width,
    children,
    onChangeBack,
    buttonGoStyle,
    buttonBackText,
    onChangeGo,
    buttonGoText,
    tipo,
    buttonGoDisable,
    positionButton,
    tabs,
    activeTab,
    handleOpenTab,
    onChangeClose,
    masive,
    isLoading,
    listaAceptados,
  } = props;

  const [isOpenModalCerrarRegistro, setIsOpenModalCerrarRegistro] =
    useState<boolean>(false);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCloseModalClose = () => {
    typeof onChangeClose == "function" && onChangeClose();
  };

  const handleClickGoModal = () => {
    typeof onChangeGo == "function" && onChangeGo();
  };

  useEffect(() => {
    const handleUnload = (event: any) => {
      const message = "¿Estás seguro de que quieres salir?";
      event.returnValue = message; // Estándar para la mayoría de los navegadores
      alert(message); // Mostrar alerta antes de salir
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <>
      <div
        className={`modal-container-generico ${
          tabs && tabs.length > 1 && "tabs"
        }`}
      >
        <div
          className={`modal-content-generico ${width} ${
            !isLoading && tabs && tabs.length > 1 && "tabs"
          }`}
        >
          {!isLoading && tabs && tabs.length > 1 && (
            <>
              <div className="tab-container">
                <div className="tab-header">
                  {tabs.map(
                    ({
                      baseContratoId,
                      personaDNI,
                      primerNombre,
                      primerApellido,
                    }: IContratosColaborador) => (
                      <button
                        key={personaDNI}
                        className={`tab-btn ${
                          activeTab == baseContratoId ? "active" : ""
                        }`}
                        onClick={() => handleOpenTab({ baseContratoId })}
                      >
                        {primerNombre} {primerApellido} <br />
                        {personaDNI}
                      </button>
                    )
                  )}
                </div>
              </div>
            </>
          )}

          <label className="modal-close">
            <CloseIcon
              onClick={() => {
                onChangeClose
                  ? handleClickCloseModalClose
                  : handleClickCloseModal;
                setIsOpenModalCerrarRegistro(true);
              }}
            />
          </label>
          {title && (
            <div className="modal-title">
              <label>
                {title}{" "}
                {tabs && tabs.length > 0 && (
                  <>
                    {tabs.map(({ ficha, baseContratoId }: any) =>
                      activeTab == baseContratoId ? ficha : ""
                    )}
                  </>
                )}
              </label>
            </div>
          )}
          {subtitle && tipo == "bloqueo" && (
            <div className="modal-subtitle pb-3" style={{ textAlign: "start" }}>
              <span>{subtitle}</span>
            </div>
          )}
          {subtitle && tipo != "bloqueo" && (
            <div className={`modal-subtitle ${positionSubTitle}`}>
              <span>{subtitle}</span>
            </div>
          )}
          {message && (
            <div className="modal-message">
              <span>{message}</span>
            </div>
          )}
          {children}
          {messageBottom && (
            <div className={`modal-message ${positionMessageBottom}`}>
              <span>{messageBottom}</span>
            </div>
          )}
          <footer
            className={`modal-button-content  ${
              positionButton ? positionButton : "center-seleccion"
            }`}
          >
            <>
              {buttonBackText && (
                <button
                  className={`modal-button delete-white`}
                  onClick={() => handleClickCloseModal()}
                >
                  {buttonBackText}
                </button>
              )}
              {buttonGoText && (
                <>
                  {typeof buttonGoDisable == "boolean" ? (
                    <button
                      className={`modal-button ${buttonGoStyle ?? "confirm"}`}
                      onClick={handleClickGoModal}
                      disabled={!buttonGoDisable}
                    >
                      {buttonGoText}
                    </button>
                  ) : (
                    <button
                      className={`modal-button ${buttonGoStyle ?? "confirm"}`}
                      onClick={handleClickGoModal}
                    >
                      {masive &&
                        `${
                          listaAceptados && listaAceptados.length > 0
                            ? `(${listaAceptados.length}) `
                            : ""
                        }`}
                      {buttonGoText}
                      <IconCheck fill="#FDFDFD" />
                    </button>
                  )}
                </>
              )}
            </>
          </footer>
        </div>
      </div>
      {isOpenModalCerrarRegistro && (
        <>
          <ModalGenerico
            title="¿Estás seguro qué deseas salir?"
            width="w-45"
            subtitle=" "
            positionSubTitle="center"
            message="Al salir, las solicitudes aceptadas sin descargar volverán a la lista de solicitudes recibidas"
            messageBottom=" "
            positionMessage="center"
            buttonBackText="Cancelar"
            buttonGoStyle="delete"
            buttonGoText="Salir"
            onChangeBack={() => {
              setIsOpenModalCerrarRegistro(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            onChangeGo={() => {
              onChangeClose && handleClickCloseModalClose();
              setIsOpenModalCerrarRegistro(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
          ></ModalGenerico>
        </>
      )}
    </>
  );
};
