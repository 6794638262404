import { useEffect, useState } from "react";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconCheck from "../../../../ui/icons-seleccion/IconCheck";
import IconCancel from "../../../../ui/icons-seleccion/IconCancel";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalAceptarRegistro from "../Modal/ModalAceptarRegistro";
import ModalRechazarRegistro from "../Modal/ModalRechazarRegistro";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";

interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  solicitudes: any;
  setSolicitudes: Function;
  handleClickSolResponse: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    solicitudes,
    setSolicitudes,
    handleClickSolResponse,
  } = props;
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [isOpenModalAceptarRegistro, setIsOpenModalAceptarRegistro] =
    useState<boolean>(false);
  const [isOpenModalRechazarRegistro, setIsOpenModalRechazarRegistro] =
    useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickAcceptRegister = (): void => {
    setIsOpenModalAceptarRegistro(true);
  };

  const handleClickDeclineRegister = (): void => {
    setIsOpenModalRechazarRegistro(true);
  };

  return (
    <>
      <section className="container-actions">
        <section className="content-actions-masive">
          <Tooltip text="Aceptar Solicitud">
            <button
              onClick={handleClickAcceptRegister}
              className={`button-actions ${
                buttonEnabled ? "active-traspasos-check" : "disabled"
              }`}
              disabled={!buttonEnabled}
            >
              <IconCheck fill={"#06A502"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <Tooltip text="Rechazar Solicitud">
            <button
              onClick={handleClickDeclineRegister}
              className={`button-actions ${
                buttonEnabled ? "active-naranjo" : "disabled"
              }`}
              disabled={!buttonEnabled}
            >
              <IconCancel fill={"#FF4900"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </section>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="SOLICITANTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="solicitante"
          />
          <InputFiltro
            layer="FECHA SOLICITUD"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaSolicitud"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAceptarRegistro && (
        <ModalGenerico
          width="w-70"
          title="Aceptar Ceder Registro Candidato"
          subtitle="Estas cediendo la siguiente selección:"
          messageBottom="Al ceder, los candidatos desaparecerán de tu lista de disponibles. Para confirmar presiona continuar"
          onChangeBack={() => setIsOpenModalAceptarRegistro(false)}
          onChangeGo={() => {
            handleClickSolResponse({ status: true });
            setIsOpenModalAceptarRegistro(false);
          }}
          buttonBackText="Cerrar"
          buttonGoText="Continuar"
          buttonGoStyle="confirm"
        >
          <ModalAceptarRegistro
            rut={listadoSeleccionado}
            solicitudes={solicitudes}
            setSolicitudes={setSolicitudes}
          />
        </ModalGenerico>
      )}

      {isOpenModalRechazarRegistro && (
        <ModalGenerico
          width="w-70"
          title="Rechazar Ceder Registro Candidato"
          subtitle="Estas rechazando ceder la siguiente selección:"
          messageBottom="Al rechazar, el usuario será notificado del motivo y podrá generar la solicitud nuevamente"
          onChangeBack={() => setIsOpenModalRechazarRegistro(false)}
          onChangeGo={() => {
            handleClickSolResponse({ status: false });
            setIsOpenModalRechazarRegistro(false);
          }}
          buttonBackText="Cerrar"
          buttonGoText="Continuar"
          buttonGoStyle="confirm"
        >
          <ModalRechazarRegistro
            rut={listadoSeleccionado}
            solicitudes={solicitudes}
            setSolicitudes={setSolicitudes}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
