import { Component } from "react";

interface IconEye {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  style?: any;
  color?: any;
  className?: any;
}

export default (props: IconEye) => {
  const { width, height, stroke, style, className, color, fill } = props;

  return (
    <svg
      width={`${width ? width : "15"}`}
      height={`${height ? height : "18"}`}
      style={style}
      color={color}
      viewBox="0 0 15 18"
      className={className}
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.5H1C0.45 0.5 0 0.95 0 1.5V16.5C0 17.05 0.45 17.5 1 17.5H14C14.55 17.5 15 17.05 15 16.5V1.5C15 0.95 14.55 0.5 14 0.5ZM12.93 9.45C11.89 11.47 9.84 12.84 7.5 12.84C5.16 12.84 3.1 11.47 2.07 9.45C2.03 9.36 2 9.27 2 9.17C2 9.07 2.02 8.98 2.07 8.89C3.11 6.87 5.16 5.5 7.5 5.5C9.84 5.5 11.9 6.87 12.93 8.89C12.97 8.98 13 9.07 13 9.17C13 9.27 12.98 9.36 12.93 9.45Z"
        fill="#0065DD"
      />
      <path
        d="M8.55 6.63C8.05 6.42 7.49 6.37 6.96 6.47C6.43 6.58 5.94 6.84 5.55 7.22C5.16 7.6 4.9 8.09 4.8 8.63C4.69 9.16 4.75 9.72 4.96 10.22C5.17 10.72 5.52 11.15 5.97 11.45C6.42 11.75 6.95 11.91 7.5 11.91C7.86 11.91 8.22 11.84 8.55 11.7C8.88 11.56 9.19 11.36 9.44 11.1C9.7 10.84 9.9 10.54 10.04 10.21C10.18 9.88 10.25 9.52 10.25 9.16C10.25 8.62 10.09 8.08 9.79 7.63C9.49 7.18 9.06 6.83 8.56 6.62L8.55 6.63ZM9.25 9.7C9.14 10.07 8.92 10.39 8.61 10.62C8.3 10.85 7.94 10.98 7.55 11C7.17 11.01 6.79 10.9 6.47 10.69C6.15 10.48 5.91 10.17 5.78 9.81C5.65 9.45 5.63 9.06 5.73 8.69C5.91 8.82 6.12 8.88 6.34 8.87C6.56 8.86 6.76 8.76 6.92 8.6C7.08 8.44 7.17 8.24 7.19 8.02C7.21 7.8 7.14 7.59 7.01 7.41C7.17 7.36 7.33 7.34 7.49 7.34C7.87 7.34 8.25 7.46 8.56 7.68C8.87 7.9 9.11 8.22 9.23 8.58C9.35 8.94 9.36 9.33 9.25 9.7Z"
        fill="#0065DD"
      />
    </svg>
  );
};
