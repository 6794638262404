import { useContext, useEffect } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaSolicitudProcesadas from "./SolicitudesProcesadas/TablaSolicitudProcesadas";

const TablaContratoProcesadas = () => {
  const { toasts, getToken } = useContext(SeleccionContext);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {toasts}
      <TablaSolicitudProcesadas />
    </>
  );
};

export default TablaContratoProcesadas;
