import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.8125 5.57812V0H1.60938C1.06387 0 0.625 0.438867 0.625 0.984375V20.0156C0.625 20.5611 1.06387 21 1.60938 21H15.3906C15.9361 21 16.375 20.5611 16.375 20.0156V6.5625H10.7969C10.2555 6.5625 9.8125 6.11953 9.8125 5.57812ZM12.2775 9.94629L9.8125 13.7812L12.2775 17.6162C12.4867 17.9443 12.2529 18.375 11.8633 18.375H10.4318C10.2514 18.375 10.0832 18.2766 9.99707 18.1166C9.19316 16.6318 8.5 15.2988 8.5 15.2988C8.2375 15.9059 8.08984 16.1191 6.99883 18.1207C6.91269 18.2807 6.74863 18.3791 6.56816 18.3791H5.13672C4.74707 18.3791 4.51328 17.9484 4.72246 17.6203L7.1957 13.7854L4.72246 9.95039C4.50918 9.62227 4.74707 9.1916 5.13672 9.1916H6.56406C6.74453 9.1916 6.91269 9.29004 6.99883 9.45C8.06934 11.4516 7.81914 10.8281 8.5 12.2596C8.5 12.2596 8.7502 11.7797 10.0012 9.45C10.0873 9.29004 10.2555 9.1916 10.4359 9.1916H11.8633C12.2529 9.1875 12.4867 9.61816 12.2775 9.94629ZM16.375 4.9998V5.25H11.125V0H11.3752C11.6377 0 11.8879 0.102539 12.0725 0.287109L16.0879 4.30664C16.2725 4.49121 16.375 4.74141 16.375 4.9998Z" 
      fill="#D32F2F"/>
       
   
   
    </svg>
  );
};
