import { IReducer } from "../../interfaces/IAdminAssist";

const reduxState: any = {
  SetAdminTable: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, adminTable: payload };
  },

  SetToastState: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, toastState: payload };
  },

  SetListadoProcesos: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listadoProcesos: payload };
  },

  SetListadoTipoServicios: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listadoTipoServicios: payload };
  },

  SetListadoUsuarioTransporte: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listadoUsuarioTransporte: payload };
  },
};

export default (state: any, action: any) => {
  const { type, payload } = action;
  return reduxState[type]({ payload, state }) ?? undefined;
};