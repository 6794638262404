import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_clientId
      ? process.env.REACT_APP_clientId
      : "",
    authority: process.env.REACT_APP_authority,
    redirectUri: process.env.REACT_APP_redirectUri,
    postLogoutRedirectUri: process.env.REACT_APP_postLogoutRedirectUri,

    //redirectUri: "http://localhost:3000/" || "https://webapp-tw-dev-001.azurewebsites.net/",
    //postLogoutRedirectUri: "http://localhost:3000/" || "https://webapp-tw-dev-001.azurewebsites.net/"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/beta/me",
};
