import axios, { AxiosError, CancelToken } from "axios";
import { useReducer, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  ICandidatoInhabilitado,
  IDeleteDocumentCandidate,
  IDocumentoCandidate,
  IEnlaceProceso,
  IOnlyToken,
  IOriginalObject,
  ISeleccionState,
  IState,
  ITransformedObject,
} from "../../interfaces/ISeleccion";
import SeleccionContext from "./SeleccionContext";
import SeleccionReducer from "./SeleccionReducer";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";
import { useChangeStatus } from "../Set";
import { formatDateToYYYYMMDD } from "../../functions/kit-mdtw/dates";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import { IOptionDropDownList } from "../../interfaces/IDropDownList";
import {
  setEmptyMessage,
  setInfoAntecedentes,
  setListEvaluacionPsicolaboral,
  setListResumenProcesadosNomina,
} from "./SeleccionSet";
import logotw_circle from "../../assets/imgs/logotw_circle.png";

export default (props: ISeleccionState) => {
  const { children } = props;
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rolesUsers, setRolesUsers] = useState<any[]>([]);
  const { toasts, createToast } = useToast();
  const validRequered = ["", "-", "null", "false", undefined, null, false];
  const initialState: IState = {
    userId: sessionStorage.getItem("usuarioId") as string,
    profile: sessionStorage.getItem("profile") as string,
    roles: JSON.parse(sessionStorage.getItem("roles") as string),
    candidato: [],
    procesoVerificarRut: [],
    personaVerificarRut: [],
    listaExclusion: [],
    listaUsuarios: [],
    listaRegistroCandidato: [],
    listaGeneral: [],
    listGroup: [],
    listDocument: [],
    listProcesosActivos: [],
    listProcesos: [],
    listResultAsignaProceso: [],
    listaNoProcesadosSeleccion: [],
    listaCandidatoDisponible: [],
    listaCandidatoNoDisponible: [],
    listaCandidatoInhabilitado: [],
    listaCandidatoBloqueado: [],
    listaCandidatoPendiente: [],
    listaCandidatoValidado: [],
    listaCandidatoPreSeleccionPendiente: [],
    listaCandidatoPreSeleccion: [],
    listaCandidatoSeleccionado: [],
    listaCandidatoSolEnviada: [],
    listaCandidatoSolRecibida: [],
    listEvaluacionPsicolaboral: [],
    listMovilizacionTw: [],
    listaCandidatoPendienteMasivo: [],
    listaCandidatoValidadoMasivo: [],
    listaComprobarEstadoCandidato: [],
    listaPostulacionRecibida: [],
    listaPostulacionProcesada: [],
    listaProcesoPostulado: [],
    listaRegistroPostulacion: [],
    listaFormatoPsicolaboral: [],
    listaReportePdfPsicolaboral: [],
    listUserAccess: [],
    countAssign: [],
    infoAntecedentes: [],
    listBateriaDocument: [],
    listResumenProcesadosNomina: [],
  };
  const [listPerfiles, setListPerfiles] = useState<any>([]);
  const [listConclusiones, setListConclusiones] = useState<any>([]);
  const [listTipoEducacion, setListTipoEducacion] = useState<any>([]);
  //const [infoAntecedentes, setInfoAntecedentes] = useState<any>({});
  const [listaCompetenciasLaborales, setListaCompetenciasLaborales] =
    useState<any>([]);
  /* const [listResumenProcesadosNomina, setListResumenProcesadosNomina] =
    useState<any>([]);*/

  const [state, dispatch]: any = useReducer<any>(
    SeleccionReducer,
    initialState
  );

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  const createCodeQrListProcess = async (values: any) => {
    const { formData } = values;
    const zip = new JSZip();

    try {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const qrCode = new QRCodeStyling({
            width: 300,
            height: 300,
            margin: 0,
            data: `${process.env.REACT_APP_link_postulacion}/vacante/${key}`,
            image: logotw_circle,
            backgroundOptions: { color: "#fff" },
            imageOptions: {
              hideBackgroundDots: false,
              crossOrigin: "anonymous",
            },
            dotsOptions: {
              color: "#4267b2",
              type: "square",
              gradient: {
                type: "linear",
                rotation: 1.5707963267948966,
                colorStops: [
                  { offset: 0, color: "#0065DD" },
                  { offset: 1, color: "#FF0081" },
                ],
              },
            },
          });
          await qrCode.download({
            name: `qr_${formData[key].value}`,
            extension: "png",
          });
          // const imageDataUrl = await qrCode.getImageDataUrl('png');
          // const fileName = `qr_${key}.png`;

          // // Agrega el archivo al zip
          // zip.file(fileName, imageDataUrl.split("base64,")[1], { base64: true });
        }
      }

      // Genera el archivo zip
      // zip.generateAsync({ type: "blob" })
      //   .then((content) => {
      //     // Descarga el archivo zip
      //     saveAs(content, "qrcodes.zip");
      //   });
    } catch (error) {
      console.log(error + "Error al descargar QR");
    }
  };

  const createLinkProcessTxt = (values: IEnlaceProceso) => {
    const { formData } = values;
    try {
      let textContent = "";
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const entry = formData[key];
          textContent +=
            `${entry.value}\n` +
            `${process.env.REACT_APP_link_postulacion}/vacante/${key} \n\n`;
        }
      }
      const blob = new Blob([textContent], { type: "text/plain" });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Enlaces.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      createToast({
        title: "Enlaces Generados",
        message: "Enlaces de Procesos Generado exitosamente",
        type: "success",
      });
      return;
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Generar Enlace",
        message: "Ha ocurrido un problema, intente nuevamente",
        type: "danger",
      });
    }
  };

  const putNombrePostulacion = async (values: any): Promise<void> => {
    const { token, formData, setIsLoading } = values;
    setIsLoading && setIsLoading(true);
    let procesos: any;
    if (formData) {
      Object.values(formData).forEach((item: any) => {
        if (
          !item.hasOwnProperty("value") ||
          typeof item.value !== "string" ||
          item.value.trim() === ""
        ) {
          item.value = "SIN NOMBRE PROCESO";
        }
      });
      procesos = Object.keys(formData).map((procesoId) => ({
        procesoId,
        nombrePostulacion: formData[procesoId].value,
      }));
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}nombre-postulacion`,
        { procesos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.results;
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intente nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getCounts = async (values: any) => {
    const { token } = values;
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}cuentas-asignadas?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetCountAssign",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  /**
   * TODO: creando nueva logica de seleccion. validando rut;
   */

  const getUserAccess = async (values: any): Promise<void> => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/usuario/${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = result.data.data;
      useChangeStatus({
        type: "SetListUserAccess",
        dispatch,
        payload: res,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", { error });
    }
  };

  const getComprobarEstadoCandidato = async (values: any): Promise<void> => {
    const { token, busPerId } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/comprueba-estado-registro`,
        {
          params: {
            userId: state.userId,
            busPerId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      useChangeStatus({
        type: "SetListaComprobarEstadoCandidato",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", { error });
    }
  };

  const putAvanzarProceso = async (values: any): Promise<void> => {
    const { procesos, token, setIsOpenModalNoProcesado, localStorageSelect } =
      values;
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/avanzar-proceso`,
        { procesos: procesos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { noProcessed } = result.data;
      const movedProcess = result.data.results.movedProcess;
      if (movedProcess.length > 0) {
        createToast({
          title: "Registro(s) Avanza Correctamente",
          message: "Candidato avanza a siguiente proceso.",
          type: "success",
        });
      } else {
        createToast({
          title: "Error al Avanzar",
          message: "Ha ocurrido un problema, intente nuevamente",
          type: "danger",
        });
      }
      if (noProcessed.length > 0) {
        setIsOpenModalNoProcesado && setIsOpenModalNoProcesado(true);
        useChangeStatus({
          type: "SetListaNoProcesadosSeleccion",
          dispatch,
          payload: noProcessed,
        });
      }
      localStorageSelect && localStorage.removeItem(localStorageSelect);
    } catch (error) {
      createToast({
        title: "Error al Avanzar",
        message: "Ha ocurrido un problema, intente nuevamente",
        type: "danger",
      });
    }
  };

  const putRetrocederProceso = async (values: any): Promise<void> => {
    const { procesos, token } = values;
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/retroceder-proceso`,
        { procesos: procesos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const success = result.data.results.movedProcess;
      if (success.length > 0) {
        createToast({
          title: "Registro(s) Retrocede Correctamente",
          message: "Candidato retrocede en proceso.",
          type: "success",
        });
      } else {
        createToast({
          title: "Error en el envío",
          message: "Ha ocurrido un problema, intente nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error en el envío",
        message: "Ha ocurrido un problema, intente nuevamente",
        type: "danger",
      });
    }
  };

  const putEntrevistaRealizada = async (values: any): Promise<void> => {
    const { token, personas } = values;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}entrevista-realizada`,
        { personas },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intente nuevamente",
        type: "danger",
      });
    }
  };

  const getUserPerfilRol = async (values: any): Promise<void> => {
    const { token, idUser } = values;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}usuario/${idUser}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data.map(({ roles }: any) => roles);
      setRolesUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const postBlockCandidate = async (values: any): Promise<void> => {
    const { personas, token, typeExclusion } = values;
    if (!personas) {
      createToast({
        title: "Solicitud no Realizada",
        message: "Favor seleccione el motivo de bloqueo",
        type: "danger",
      });
      return;
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/bloquear-persona`,
        { personas },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.results.blockeddPeople;
      if (data.length > 0) {
        createToast({
          title: `${
            typeExclusion == "bloqueo" ? "Bloqueo" : "Descarte"
          } Correcto`,
          message: `Selección ${
            typeExclusion == "bloqueo" ? "Bloqueada" : "Descartada"
          } con exito `,
          type: "success",
        });
      } else {
        createToast({
          title: "Error Al Procesar",
          message: "Por favor, intente nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      createToast({
        title: "Solicitud no Realizada",
        message: "Error al Procesar, intente nuevamente",
        type: "danger",
      });
    }
  };

  const getExclusiones = async (values: any): Promise<void> => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/exclusion-tipo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      useChangeStatus({
        type: "SetListaExclusion",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getCandidatoHabilitado = async (values: any): Promise<void> => {
    const {
      token,
      filtros,
      setIsLoadingTable,
      setIsErrorTable,
      setPaginationData,
      page,
      limit,
    } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-habilitados`,
        { filtros },
        {
          params: {
            userId: state.userId,
            page,
            limit,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      const pagination = await response.data.pagination;
      setPaginationData && setPaginationData(pagination);
      useChangeStatus({
        type: "SetListaCandidatoDisponible",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoNoDisponible = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-no-disponibles`,
        { filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoNoDisponible",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoPendiente = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "PORVALIDAR",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoPendiente",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoValidado = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "PRESELECCIONADO",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoValidado",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoPendienteMasivo = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "PORVALIDAR",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoPendienteMasivo",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoValidadoMasivo = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "VALIDADO",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoValidadoMasivo",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoPreSeleccionPendiente = async (
    values: any
  ): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "PRESELECCIONADO",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoPreSeleccionPendiente",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoPreSeleccion = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-activos`,
        { filtros },
        {
          params: {
            userId: state.userId,
            estados: "VALIDADO",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoPreSeleccion",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoPreSolEnviada = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}solicitud-candidato-enviada`,
        { filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoSolEnviada",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const postSolicitarCandidato = async (values: any): Promise<void> => {
    const { token, procesoDestino } = values;

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/solicitar-candidato`,
        {
          personas: [procesoDestino],
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.results.sentRequest;
      if (data.length > 0) {
        createToast({
          title: "Solicitud de Registro Enviada",
          message: "Revisa en Traspaso de Registros",
          type: "success",
        });
      } else {
        createToast({
          title: "Error al Procesar",
          message: "Por favor, intenta nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intenta nuevamente",
        type: "danger",
      });
    }
  };

  const getCandidatoPreSolRecibida = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}solicitud-candidato-recibida`,
        { filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoSolRecibida",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const putResponderSolTraspaso = async (values: any): Promise<void> => {
    const { token, solicitudes, status } = values;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}responder-solicitud-traspaso`,
        { solicitudes: solicitudes },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      if (status) {
        createToast({
          title: "Registro(s) Aceptado(s)",
          message: "Revisa en candidatos habilitados",
          type: "success",
        });
      } else {
        createToast({
          title: "Registro(s) Rechazado(s)",
          message: "Revisa en candidatos habilitados",
          type: "success",
        });
      }
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intentef nuevamente",
        type: "danger",
      });
    }
  };

  const getCandidatoSeleccionado = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    const filtrasos = { ...filtros };
    if (filtros && filtrasos) {
      if (filtrasos.entrevistaRealizada == "SI")
        filtrasos.entrevistaRealizada = true;
      if (filtrasos.entrevistaRealizada == "NO")
        filtrasos.entrevistaRealizada = false;
      if (filtrasos.confirmaEntrevista == "SI")
        filtrasos.confirmaEntrevista = true;
      if (filtrasos.confirmaEntrevista == "NO")
        filtrasos.confirmaEntrevista = false;
      if (filtrasos.transporteTeamwork == "SI")
        filtrasos.transporteTeamwork = true;
      if (filtrasos.transporteTeamwork == "NO")
        filtrasos.transporteTeamwork = false;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-pre-seleccionados`,
        { filtros: filtrasos ?? filtros },
        {
          params: {
            userId: state.userId,
            estados: "VALIDADO",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaCandidatoSeleccionado",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const putAsignarProceso = async (values: any): Promise<void> => {
    const { token, procesoId, personas, setIsLoading } = values;
    setIsLoading && setIsLoading(true);
    if (!procesoId || procesoId == "" || procesoId == null) {
      createToast({
        title: "Error: Seleccione un proceso",
        message: "Favor enviar todos los campos",
        type: "danger",
      });
      return;
    }

    const body = {
      procesoId,
      personas,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}asignar-proceso`,
        body,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.results;
      useChangeStatus({
        type: "SetListResultAsignaProceso",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intente nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getCandidatoInhabilitado = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    const exclusiones = [
      "CONDUCTA",
      "LEGAL",
      "INTERNO",
      "ROL_GENERAL",
      "ROL_PRIVADO",
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-inhabilitados`,
        {
          exclusiones,
          filtros,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      const inhabilitados = data.filter(
        ({ excTipoId }: ICandidatoInhabilitado) => excTipoId == "17"
      );
      useChangeStatus({
        type: "SetListaCandidatoInhabilitado",
        dispatch,
        payload: inhabilitados,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getCandidatoOtrosInhabilitado = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    const exclusiones = [
      "CONDUCTA",
      "LEGAL",
      "INTERNO",
      "ROL_GENERAL",
      "ROL_PRIVADO",
    ];
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}candidatos-inhabilitados`,
        {
          exclusiones,
          filtros,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      const bloqueados = data.filter(
        ({ excTipoId }: ICandidatoInhabilitado) => excTipoId !== "17"
      );
      useChangeStatus({
        type: "SetListaCandidatoBloqueado",
        dispatch,
        payload: bloqueados,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const postDeshacerExclusion = async (values: any): Promise<void> => {
    const { token, candidates } = values;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}deshacer-exclusion`,
        {
          data: candidates,
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.results.undoneExclusion;
      if (data.length > 0) {
        createToast({
          title: "Candidato(s) Restaurado(s) Correctamente",
          message: "Revisa los candidatos habilitados",
          type: "success",
        });
      } else {
        createToast({
          title: "Error al Restaurar",
          message: "Por favor, intenta nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error Al Procesar",
        message: "Por favor, intenta nuevamente",
        type: "danger",
      });
    }
  };

  const getListaUsuarios = async (values: any): Promise<void> => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}usuario`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const data = await result.data.data.filter(
      //   (item: any) => item.perfil == "tw_gen"
      // );
      const data = await result.data.data;
      useChangeStatus({
        type: "SetListaUsuarios",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const createAsignarEquipo = async (values: any): Promise<void> => {
    const { procesos, equipo, token } = values;
    if (equipo.length == 0) {
      createToast({
        title: "Error al asignar equipo",
        message: "Favor agregar personal al proceso",
        type: "danger",
      });
      return;
    }
    const formBody = {
      procesos: procesos,
      equipo: equipo,
    };
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/asignar-equipo`,
        formBody,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      createToast({
        title: "Equipo asignado correctamente",
        message: "Revise los procesos activos",
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBusquedaProcesos = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    typeof setIsLoadingTable == "function" &&
      setIsLoadingTable &&
      setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}procesos-activos`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      useChangeStatus({
        type: "SetListProcesosActivos",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      typeof setIsErrorTable == "function" &&
        setIsErrorTable &&
        setIsErrorTable(true);
    } finally {
      typeof setIsLoadingTable == "function" &&
        setIsLoadingTable &&
        setIsLoadingTable(false);
    }
  };

  const getProcesos = async (values: any): Promise<void> => {
    const { token, procesos, setIsLoading } = values;
    setIsLoading && setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}procesos`,
        {
          params: {
            userId: state.userId,
            procesos: procesos && procesos.join(","),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListProcesos",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getListaGeneral = async (values: any): Promise<void> => {
    const { grupo, token, tipoProceso } = values;
    // const { personaRut, cargoGroup } = values;
    try {
      const resultListaPersonal = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/personal`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultListaUbicacion = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/ubicacion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultListaGeneral = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/general`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultListaBancoPrevicion = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/bancoprevision`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultListGroup = await axios.get(
        `${process.env.REACT_APP_API_URL}/busqueda-group`,
        {
          params: {
            userId: state.userId,
            tipoProceso: tipoProceso,
            cargoGroupId: grupo,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const dataPersonal = await resultListaPersonal.data;
      const dataUbicacion = await resultListaUbicacion.data;
      const dataGeneral = await resultListaGeneral.data;
      const dataBancoPrevision = await resultListaBancoPrevicion.data;
      const dataGroup = await resultListGroup.data.data;
      const objectGroup = dataGroup.flatMap((item: any) => item);
      // .filter((item: any) => item.cargoGroupId === grupo);
      const payload = {
        ...dataPersonal,
        ...dataUbicacion,
        ...dataGeneral,
        ...dataBancoPrevision,
      };
      useChangeStatus({
        type: "SetListaGeneral",
        dispatch,
        payload,
      });
      useChangeStatus({
        type: "SetListGroup",
        dispatch,
        payload: objectGroup,
      });
    } catch (error) {
      console.log("Ha ocurrido algo: ", error);
    }
  };

  // 2000: "Rut sin registro en el sistema: Deberá ingresar todos los datos del registro"
  // 2001: "Persona bloqueada por Teamwork, no contactar"
  // 2002: "Rut existente: Los datos existentes se importarán al registro"
  // 2003: `Persona con bloqueo por cliente ${NOMBRE_CLIENTE}, no incluir en sus procesos`,
  // 2004: `Persona ha sido descartada del proceso ${busquedaId} anteriormente`,
  // 2005: `Persona existente en el sistema: Ingresado el DD/MM/YYYY por {RECLUTADOR} y activo en el proceso ID${busquedaId} (debe solicitar registro para su uso)`,
  // 2006: `Persona ya está en el proceso ${busquedaId} y pertenece a ti`,
  // 2007: `Persona pertenece a ti y está en el proceso ${busquedaId}`,
  // 2007: `Persona pertenece a ti y está en el proceso ${busquedaId}`,

  const getProcessAssign = async (values: any) => {
    const { token, personaDNI, procesoId, setMessageError } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/verificar-persona`,
        {
          params: {
            userId: state.userId,
            personaDNI: personaDNI,
            procesoId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const {
        isFree,
        spanishMessage,
        hasBlockedByClient,
        hasBlockedByProcess,
        hasBlockedByTW,
      } = data;
      if (
        (setMessageError && hasBlockedByClient) ||
        hasBlockedByProcess ||
        hasBlockedByTW
      ) {
        setMessageError(spanishMessage);
      } else {
        setMessageError(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRutVerificado = async (values: any): Promise<void> => {
    const {
      token,
      personaRut,
      busPerId,
      procesoId,
      setIsFree,
      setIsFreeSolicitar,
      setMessageSpanish,
      setLoading,
      setButtonDisabled,
      consultaRut,
      setIsOpenModalUpdateCandidate,
      setIsLoadingDocument,
    } = values;
    setLoading && setLoading(true);
    setButtonDisabled(true);
    useChangeStatus({
      type: "SetListaRegistroCandidato",
      dispatch,
      payload: [],
    });
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/verificar-persona`,
        {
          params: {
            userId: state.userId,
            personaDNI: personaRut,
            procesoId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const { isFree, spanishMessage, code, processInfo, persona } = data;
      setIsFree(isFree);
      setMessageSpanish(spanishMessage);
      if (consultaRut && code === 2002) {
        setIsFreeSolicitar(false);
        await getRegistroCandidato({ personaRut, token });
        getDocument({ token, setIsLoading: setIsLoadingDocument });
      }
      if (consultaRut && code === 2006) {
        setIsFreeSolicitar(false);
        getRegistroCandidato({ personaRut, token, setLoading });
        getDocument({ token, setIsLoading: setIsLoadingDocument });
      }
      if (consultaRut && code === 2000) {
        setIsFreeSolicitar(false);
        getDocument({ token, setIsLoading: setIsLoadingDocument });
      }
      if (consultaRut && code === 2005) {
        await getDocument({ token, setIsLoading: setIsLoadingDocument });
        setIsFreeSolicitar(true);
        useChangeStatus({
          type: "SetProcesoVerificarRut",
          dispatch,
          payload: processInfo,
        });
        useChangeStatus({
          type: "SetPersonaVerificarRut",
          dispatch,
          payload: persona,
        });
      }
      if (!consultaRut) {
        await getRegistroCandidato({
          personaRut,
          setIsOpenModalUpdateCandidate,
          consultaRut,
          token,
          setLoading,
        });
      }

      if (busPerId) {
        await getComprobarEstadoCandidato({ token, busPerId });
      }
    } catch (error) {
      setIsFree(false);
      setIsFreeSolicitar(false);
      setMessageSpanish("Su sesión ha expirado, ingrese nuevamente");
    } finally {
      setButtonDisabled(false);
      setLoading && setLoading(false);
    }
  };

  //#region createDocumentCandidate
  /**
   * TODO: method axios.post for aplicate uploading document
   */

  const updateStatusDocument = (carga: number): string => {
    if (carga === 0) return "NUEVO";
    if (carga > 0 && carga < 100) return "CARGANDO";
    if (carga === 100) return "CARGADO";
    return "ERROR";
  };
  const createDocumentCandidate = async (values: any): Promise<void> => {
    const {
      personaId,
      formDocument,
      token,
      formUpDocument,
      setFormUpDocument,
    } = values;
    // const updatedFormUpDocument: IDocumentoCandidate[] = [...formUpDocument];
    const { id, file, tipoDocumentos, fechaExpiracion } = formDocument;
    const formData = new FormData();
    let estadoCarga: number = 0;
    try {
      if (file) {
        formData.append("file", file.value);
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/documentos`,
          formData,
          {
            params: {
              userId: state.userId,
              personaId,
              tipoDocumento: tipoDocumentos.value?.code,
              fechaExpiracion:
                formatDateToYYYYMMDD({
                  date: fechaExpiracion.value,
                  format: "-",
                }) ?? undefined,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent:any) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              estadoCarga = percentage;
            },
          }
        );
        const status = result.status;
        const index = formUpDocument.findIndex(
          (item: IDocumentoCandidate) => item.id === id
        );
        if (index !== -1) {
          formUpDocument[index] = {
            ...formUpDocument[index],
            newFile: false,
            estado: updateStatusDocument(estadoCarga),
            fechaExpiracion: {
              value:
                formatDateToYYYYMMDD({
                  date: fechaExpiracion.value,
                  format: "-",
                }) ?? undefined,
              valid: true,
            },
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCandidate = async (values: any): Promise<void> => {
    const {
      formCandidate,
      idGrupo,
      setResultBusperId,
      formDocImage,
      formUpDocument,
      setFormUpDocument,
      setIsLoading,
      setIsOpenModalUpdateCandidate,
      getCandidates,
      token,
      requerido,
    } = values;

    setIsLoading && setIsLoading(true);
    const {
      turno,
      proceso,
      primerNombreCandidato,
      segundoNombreCandidato,
      primerApellidoCandidato,
      segundoApellidoCandidato,
      rutCandidato,
      fechaNacimiento,
      fechaVencimientoVisa,
      estadoCivil,
      sexo,
      nacionalidad,
      tipoVisa,
      estadoVisa,
      hijos,
      cargas,

      // Direccion
      calle,
      nroCalle,
      nroCasa,
      comunas,
      tipoVivienda,

      //constacto
      correo,
      fonoPrincipal,
      fonoSecundario,

      //contactoemergencia
      nombreEmergencia,
      parentescoEmergencia,
      fonoEmergencia,

      //cotizaciones
      valorPlan,
      pensionado,
      exentoCotizaciones,
      sistemaSaludId,
      afp,

      //pagos
      bancos,
      tiposCuentaBanco,
      numeroCuenta,
      pagoEnCuenta,
      observacionesPago,

      //experiencia
      experienciaEnCargo,
      feedback,
      comunaOmil,
      disponibilidad,
      fuenteReclutamiento,
      referidos,
      referencias,

      //Uniforme
      polera,
      pantalon,
      zapato,

      // Salud
      enfermedades,
      observacionEnfermedad,
      discapacidad,
      observacionDiscapacidad,
      esquemaCovidCompleto,
      refuerzo,

      //Conducir
      claseLicenciaConducir,
      fechaVigenciaLicenciaConducir,
      fechaRenovacionLicenciaConducir,

      //Examen preocupacional
      tieneExamenPreocupacional,
      tipoExamPreocupacional,
      fechaVigenciaExam,
    } = formCandidate;

    if (requerido) {
      if (validRequered.includes(estadoCivil.value?.code)) {
        setIsLoading(false);
        return createToast({
          title: "Error al Guardar",
          message: "Favor llenar los campos requeridos para",
          type: "warning",
        });
      }
    }

    if (
      [null, undefined].includes(primerNombreCandidato.value) ||
      [null, undefined].includes(primerApellidoCandidato.value) ||
      ["", null, undefined].includes(fechaNacimiento.value) ||
      [null, undefined].includes(correo.value) ||
      [null, undefined].includes(fonoPrincipal.value)
    ) {
      setIsLoading(false);
      return createToast({
        title: "Error al Guardar",
        message:
          "Favor llenar campos: Nombre, Apellido, Fech. Nacimiento, Email y Teléfono",
        type: "danger",
      });
    }

    if (nacionalidad.value) {
      if (
        nacionalidad.value.name !== "CHILENA" &&
        [null, undefined].includes(tipoVisa.value.code)
      ) {
        setIsLoading(false);
        return createToast({
          title: "Error al Guardar",
          message: "Favor seleccionar Tipo de Visa",
          type: "danger",
        });
      }
    }

    const candidato: any = {
      DNI: rutCandidato.value,
      primerNombre: primerNombreCandidato.value ?? null,
      segundoNombre: [null, undefined].includes(segundoNombreCandidato.value)
        ? "."
        : segundoNombreCandidato.value,
      primerApellido: primerApellidoCandidato.value ?? null,
      segundoApellido: [null, undefined].includes(
        segundoApellidoCandidato.value
      )
        ? "."
        : segundoApellidoCandidato.value,
      fechaNacimiento: formatDateToYYYYMMDD({
        date: fechaNacimiento.value ?? "",
        format: "-",
      }),
      estadoCivilId: estadoCivil.value?.code ?? null,
      sexoId: sexo.value?.code ?? null,
      nacionalidadId: Number(nacionalidad.value?.code) ?? null,
      tipoVisaId: tipoVisa.value?.code ?? null,
      estadoVisaId: estadoVisa.value?.code ?? null,
      fechaVencimientoVisa: formatDateToYYYYMMDD({
        date: fechaVencimientoVisa.value ?? "",
        format: "-",
      }),
    };
    const direccion: any = {
      calle: calle.value ?? null,
      numeroPrincipal: nroCalle.value ?? null,
      tipoViviendaId: tipoVivienda.value?.code ?? null,
      numeroVivienda: nroCasa.value ?? null,
      comunaId: Number(comunas.value?.code) ?? null,
    };
    const contacto: any = {
      correo: correo.value ?? null,
      fonoPrincipal: fonoPrincipal.value ?? null,
      fonoSecundario: fonoSecundario.value ?? null,
    };
    const contactoEmergencia: any = {
      nombreCompleto: nombreEmergencia.value ?? null,
      parentescoId: parentescoEmergencia.value?.code ?? null,
      fono: fonoEmergencia.value ?? null,
    };
    const cotizaciones: any = {
      sistemaSaludId: sistemaSaludId.value?.code ?? null,
      valorPlan: [null, undefined].includes(valorPlan.value)
        ? 0
        : parseFloat(valorPlan.value),
      afpId: afp.value?.code ?? null,
      pensionado: pensionado.value?.code === "true" ?? null,
      exentoCotizaciones: exentoCotizaciones.value?.code === "true" ?? null,
    };
    const pagos: any = {
      bancoId: bancos.value?.code ?? null,
      tipoCuentaId: tiposCuentaBanco.value?.code ?? null,
      numeroCuenta: numeroCuenta.value ?? null,
      pagoEnCuenta: pagoEnCuenta.value?.code === "true" ?? null,
      observacionesPago: observacionesPago.value ?? null,
    };
    const uniforme: any = {
      tallaPoleraId: polera.value?.code ?? null,
      tallaPantalonId: pantalon.value?.code ?? null,
      tallaZapatoId: zapato.value?.code ?? null,
    };
    const vacunas: any = {
      esquemaCovidCompleto: esquemaCovidCompleto.value?.code === "true" ?? null,
      refuerzo: refuerzo.value?.code === "true" ?? null,
    };
    const salud: any = {
      enfermedades: enfermedades.value?.code === "true" ?? null,
      observacionEnfermedad: observacionEnfermedad.value ?? null,
      discapacidad: discapacidad.value?.code === "true" ?? null,
      observacionDiscapacidad: observacionDiscapacidad.value ?? null,
      vacunas,
    };
    const licenciaConducir: any = {
      claseLicenciaId: claseLicenciaConducir.value?.code ?? null,
      fechaRenovacion: fechaRenovacionLicenciaConducir.value
        ? formatDateToYYYYMMDD({
            date: fechaRenovacionLicenciaConducir.value,
            format: "-",
          })
        : null,
      fechaVigencia: formatDateToYYYYMMDD({
        date: fechaVigenciaLicenciaConducir.value ?? "",
        format: "-",
      }),
    };
    const examenPreocupacional: any = {
      tieneExamenPreocupacional:
        tieneExamenPreocupacional.value?.code === "true" ?? null,
      tipoExamPreocupacional: tipoExamPreocupacional.value ?? null,
      fechaVigenciaExam: formatDateToYYYYMMDD({
        date: fechaVigenciaExam.value ?? "",
        format: "-",
      }),
    };

    const personal: any = {
      candidato,
      direccion,
      contacto,
      hijos: hijos.value,
      cargas: cargas.value.map((item: any) => {
        const { DNI, nombres, primerApellido, segundoApellido, parentescoId } =
          item;
        return {
          DNI: DNI.value,
          nombres: nombres.value,
          primerApellido: primerApellido.value,
          segundoApellido: segundoApellido.value,
          parentescoId: parentescoId.value.code,
        };
      }),
      // cargas: [],
      contactoEmergencia,
      cotizaciones,
      pagos,
    };

    const laboral: any = {
      turno: turno.value ?? null,
      experienciaEnCargo: experienciaEnCargo.value ?? null,
      feedback: feedback.value ?? null,
      disponibilidadId: disponibilidad.value?.code ?? null,
      fuenteReclutamientoId: fuenteReclutamiento.value?.code ?? null,
      comunaOmilId: comunaOmil.value?.code ?? null,
      referidos: referidos.value.map((item: any) => {
        const { nombreCompleto, fono } = item;
        return {
          nombreCompleto: nombreCompleto.value,
          fono: fono.value,
        };
      }),
      referencias: referencias.value.map((item: any) => {
        const { nombreCompleto, cargo, empresa, fono } = item;
        return {
          nombreCompleto: nombreCompleto.value,
          cargo: cargo.value,
          empresa: empresa.value,
          fono: fono.value,
        };
      }),
      uniforme,
      salud,
      licenciaConducir,
      examenPreocupacional,
    };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}registro-candidato`,
        {
          personal,
          laboral,
        },
        {
          params: {
            userId: state.userId,
            procesoId: proceso.value?.code ?? idGrupo,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      const { summary, personId } = data;
      const { summaryProcess } = summary;
      const { busPerId } = summaryProcess;

      if (busPerId) {
        setResultBusperId(busPerId);
      }

      createToast({
        title: "Guardado correctamente",
        message: "Los datos se han guardado correctamente.",
        type: "success",
      });

      // #region UPDOCUMENTS
      /**
       * TODO: Refactoring function async uploding person document
       */
      const formDocTemp: IDocumentoCandidate[] = [...formUpDocument];
      let expiringDocuments: IDocumentoCandidate[] = formDocTemp.filter(
        (document) =>
          document.expira === true &&
          (!document.fechaExpiracion || !document.fechaExpiracion.valid)
      );

      if (expiringDocuments.length > 0) {
        createToast({
          title: "Error al Guardar",
          message: "Por favor, revise las fechas de los documentos",
          type: "danger",
        });
        return;
      } else {
        try {
          for (const items of formUpDocument) {
            const { estado }: any = items;
            if (estado != "CARGADO") {
              await createDocumentCandidate({
                personaId: formCandidate.personaId?.value ?? "",
                formDocument: items,
                token,
                formUpDocument: formDocTemp,
                setFormUpDocument,
              });
            }
          }

          let documentos: any = [];
          formDocImage.forEach((docAntiguo: IDocumentoCandidate) => {
            const docNuevo = formUpDocument.find(
              (doc: IDocumentoCandidate) => doc.id === docAntiguo.id
            );
            if (
              !docNuevo ||
              JSON.stringify(docAntiguo) !== JSON.stringify(docNuevo)
            ) {
              documentos.push({
                documentoIdOld: docAntiguo
                  ? docAntiguo.tipoDocumentos.value?.code
                  : null,
                documentoIdNew: docNuevo
                  ? docNuevo.tipoDocumentos.value?.code
                  : null,
                fechaExpiracion: formatDateToYYYYMMDD({
                  date: docNuevo.fechaExpiracion.value,
                  format: "-",
                }),
              });
            }
          });

          documentos.length > 0 &&
            documentos.map((doc: any) => {
              updateDocumentCandidate({
                personaId: formCandidate.personaId?.value ?? "",
                token,
                documentos: [doc],
              });
            });

          setFormUpDocument && setFormUpDocument(formDocTemp);
        } catch (error) {
          console.log("ERROR EN LOS DOCUMENTOS" + error);
        } finally {
          setTimeout(() => {
            setIsOpenModalUpdateCandidate(false);
          }, 1500);
        }
      }
    } catch (error) {
      console.log("Error: " + error);
      createToast({
        title: "Error al Guardar",
        message: "Por favor, intenta nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading(false);
      getCandidates && getCandidates({ token });
      setFormUpDocument && setFormUpDocument(formUpDocument); 
    }
  };

  const getRegistroCandidato = async (values: any): Promise<void> => {
    const {
      personaRut,
      setIsOpenModalUpdateCandidate,
      consultaRut,
      token,
      setOpenModal,
      setLoading,
    } = values;

    setLoading && setLoading(true);
    if (!consultaRut) {
      setIsOpenModalUpdateCandidate && setIsOpenModalUpdateCandidate(true);
    }
    const handleReturnValue = (valor: string | string[]) => {
      if (Array.isArray(valor)) {
        return valor.map(String).join(", ");
      } else {
        return String(valor);
      }
    };

    try {
      const result: any = await axios.get(
        `${process.env.REACT_APP_API_URL}registro-candidato`,
        {
          params: {
            userId: state.userId,
            personaDNI: handleReturnValue(personaRut),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      useChangeStatus({
        type: "SetListaRegistroCandidato",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModal && setOpenModal(true);
      setLoading && setLoading(false);
    }
  };

  const getDocument = async (values: any): Promise<void> => {
    const { personaDNI, code, token, setIsLoading } = values;
    try {
      setIsLoading && setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentos`,
        {
          params: {
            userId: state.userId,
            personaDNI: code !== 2000 ? personaDNI : undefined,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data;
      useChangeStatus({
        type: "SetListDocument",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getEvaluacionPsicolaboral = async (values: any): Promise<void> => {
    const { token, perfil } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/perfil-evaluacion?userId=${state.userId}`, //&perfilEvaluacionId=${perfil}
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result.data.nombre =
        result.data.nombre.toLowerCase().charAt(0).toUpperCase() +
        result.data.nombre.toLowerCase().substring(1);
      result.data.descripcion =
        result.data.descripcion.toLowerCase().charAt(0).toUpperCase() +
        result.data.descripcion.toLowerCase().substring(1);

      result.data.categorias.forEach(
        (categorias: { nombre: string; descripcion: string }) => {
          categorias.nombre =
            categorias.nombre.toLowerCase().charAt(0).toUpperCase() +
            categorias.nombre.toLowerCase().substring(1);
          categorias.descripcion =
            categorias.descripcion.toLowerCase().charAt(0).toUpperCase() +
            categorias.descripcion.toLowerCase().substring(1);
        }
      );

      // Formatear nombres y descripciones en competencias
      result.data.competencias.forEach(
        (competencias: { nombre: string; descripcion: string }) => {
          competencias.nombre =
            competencias.nombre.toLowerCase().charAt(0).toUpperCase() +
            competencias.nombre.toLowerCase().substring(1);
          /* competencias.descripcion =
            competencias.descripcion.toLowerCase().charAt(0).toUpperCase() +
            competencias.descripcion.toLowerCase().substring(1);*/
        }
      );

      const data = result.data;
      //console.log("data de setListEvaluacionPsicolaboral",data)
      setListEvaluacionPsicolaboral({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListasPerfil = async (values: any): Promise<void> => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/psicolaboral?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formatoPsicolaboral = result.data.formatoPsicolaboral;
      result.data.perfilEvaluacion.forEach(
        (perfilEvaluacion: { nombre: string; descripcion: string }) => {
          perfilEvaluacion.nombre =
            perfilEvaluacion.nombre.toLowerCase().charAt(0).toUpperCase() +
            perfilEvaluacion.nombre.toLowerCase().substring(1);
          perfilEvaluacion.descripcion =
            perfilEvaluacion.descripcion.toLowerCase().charAt(0).toUpperCase() +
            perfilEvaluacion.descripcion.toLowerCase().substring(1);
        }
      );
      result.data.conclusionInfome.forEach(
        (conclusionInfome: { nombre: string; descripcion: string }) => {
          conclusionInfome.nombre =
            conclusionInfome.nombre.toLowerCase().charAt(0).toUpperCase() +
            conclusionInfome.nombre.toLowerCase().substring(1);
          conclusionInfome.descripcion =
            conclusionInfome.descripcion.toLowerCase().charAt(0).toUpperCase() +
            conclusionInfome.descripcion.toLowerCase().substring(1);
        }
      );
      result.data.tipoEducacion.forEach((tipoEducacion: { nombre: string }) => {
        tipoEducacion.nombre =
          tipoEducacion.nombre.toLowerCase().charAt(0).toUpperCase() +
          tipoEducacion.nombre.toLowerCase().substring(1);
      });

      const data = result.data;

      if (data && data.perfilEvaluacion && data.perfilEvaluacion.length > 0) {
        const perfilEvaluacion: IOptionDropDownList[] =
          data.perfilEvaluacion.map((item: any) => {
            return {
              code: item.perfilEvaluacionId,
              name: item.nombre,
            };
          });
        setListPerfiles(perfilEvaluacion);
      }
      if (data && data.conclusionInfome && data.conclusionInfome.length > 0) {
        const conclusionInfome: IOptionDropDownList[] =
          data.conclusionInfome.map((item: any) => {
            return {
              code: item.conclusionInformeId,
              name: item.nombre,
            };
          });
        setListConclusiones(conclusionInfome);
      }

      if (data && data.tipoEducacion && data.tipoEducacion.length > 0) {
        const tipoEducacion: IOptionDropDownList[] = data.tipoEducacion.map(
          (item: any) => {
            return {
              code: item.tipoEducacionId,
              name: item.nombre,
            };
          }
        );
        setListTipoEducacion(tipoEducacion);
      }

      useChangeStatus({
        type: "SetListaFormatoPsicolaboral",
        dispatch,
        payload: formatoPsicolaboral,
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getInformacionPsicolaboral = async (values: any): Promise<void> => {
    const { token, busPerId } = values;
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/informe-psicolaboral?userId=${state.userId}&busPerId=${busPerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const keysToTransform = [
        "clienteNombre",
        "cargoNombre",
        "reclutador",
        "primerNombre",
        "segundoNombre",
        "primerApellido",
        "segundoApellido",
        "estadoCivil",
        "division",
      ];

      keysToTransform.forEach((key) => {
        const value = result.data.procesoPersona[key];
        if (
          value !== undefined &&
          typeof value === "string" &&
          value.trim() !== ""
        ) {
          result.data.procesoPersona[key] =
            result.data.procesoPersona[key]
              ?.toLowerCase()
              ?.charAt(0)
              .toUpperCase() +
            result.data.procesoPersona[key]?.toLowerCase()?.substring(1);
        }
      });

      // Para la sección "evaluacion"
      const keysToTransformEvaluacion = [
        "perfilEvaluacion",
        "educacionEsperada",
        "conclusionInforme",
      ];

      keysToTransformEvaluacion.forEach((key) => {
        const value = result.data.evaluacion[key];
        if (
          value !== undefined &&
          typeof value === "string" &&
          value.trim() !== ""
        ) {
          result.data.evaluacion[key] =
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
      });

      // Para la sección "adicional"
      const keysToTransformAdicional = [
        "motivacion",
        "resenia",
        "fortalezas",
        "oportunidades",
        "sintesis",
      ];

      keysToTransformAdicional.forEach((key) => {
        const value = result.data.adicional[key];
        if (
          value !== undefined &&
          typeof value === "string" &&
          value.trim() !== ""
        ) {
          result.data.adicional[key] =
            result.data.adicional[key]?.toLowerCase()?.charAt(0).toUpperCase() +
            result.data.adicional[key]?.toLowerCase()?.substring(1);
        }
      });

      const keysToTransformProfesionalT = [
        "nombre",
        "institucion",
        "tipoEducacion",
      ];

      const titulos = result.data?.profesional?.titulos;

      for (let i = 0; i < titulos.length; i++) {
        const titulo = titulos[i];
        keysToTransformProfesionalT.forEach((key) => {
          const value = titulo[key];
          if (value !== undefined && typeof value === "string") {
            const formattedValue =
              value.toLowerCase().charAt(0).toUpperCase() +
              value.toLowerCase().substring(1);
            titulo[key] = formattedValue;
          }
        });
      }

      const keysToTransformProfesionalR = [
        "nombreCompleto",
        "cargo",
        "empresa",
      ];
      const referencias = result.data?.profesional?.referencias;

      for (let i = 0; i < referencias.length; i++) {
        const referencia = referencias[i];
        keysToTransformProfesionalR.forEach((key) => {
          const value = referencia[key];
          if (value !== undefined && typeof value === "string") {
            const formattedValue =
              value.toLowerCase().charAt(0).toUpperCase() +
              value.toLowerCase().substring(1);
            referencia[key] = formattedValue;
          }
        });
      }

      const data = result.data;
      setInfoAntecedentes({ dispatch, payload: data });
      //console.log("data info antecedentes en state=", data,"infoAntecedentes=")
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const recordDataPsicolaboral = async (values: any): Promise<void> => {
    const { token, busPerId, objetoRecord } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/informe-psicolaboral?userId=${state.userId}&busPerId=${busPerId}`,
        objetoRecord,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = result.data;
      createToast({
        title: "Informe Psicolaboral Guardado correctamente",
        message: "Los datos se han guardado correctamente.",
        type: "success",
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Error al Guardar",
        message: "Por favor, intenta nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getMetodoMovilizacion = async (values: IOnlyToken): Promise<void> => {
    const { token } = values;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}metodo-movilizacion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      useChangeStatus({
        type: "SetMovilizacionTw",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const putMetodoMovilizacion = async (values: any): Promise<void> => {
    const { token, body } = values;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}metodo-movilizacion`,
        body,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const putConfirmarEntrevista = async (values: any): Promise<void> => {
    const { token, personas } = values;

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}confirmar-entrevista`,
        { personas },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postGenerarExcel = async (values: any): Promise<void> => {
    const { token, estadosExcel, filtrosExcel, setIsLoading } = values;
    setIsLoading && setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}generar-excel`,
        { estados: estadosExcel, filtros: filtrosExcel },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data[0].urlToDownload;
      const enlaceTemporal = document.createElement("a");
      enlaceTemporal.href = data;
      enlaceTemporal.target = "_blank";
      enlaceTemporal.download = "nombre-del-archivo.rar";

      enlaceTemporal.click();
      createToast({
        title: "Descarga de Nómina Exitosa",
        message: `Favor revisar el .rar en su carpeta descarga`,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: `Por favor, intenta nuevamente`,
        type: "danger",
      });
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getPostulacionRecibida = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}postulaciones`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaPostulacionRecibida",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getPostulacionProcesada = async (values: any): Promise<void> => {
    const { token, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}postulaciones-procesadas`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      useChangeStatus({
        type: "SetListaPostulacionProcesada",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const postProcesosPostulados = async (values: any): Promise<void> => {
    const { token, candidatos, setIsLoading } = values;
    setIsLoading && setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}procesos-postulados`,
        { candidatos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      useChangeStatus({
        type: "SetListaProcesoPostulado",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getRegistroPostulacion = async (values: any): Promise<void> => {
    const { token, postulacionId, setIsLoading } = values;
    setIsLoading && setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}registro-postulacion`,
        {
          params: {
            userId: state.userId,
            postulacionId: postulacionId ?? "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      useChangeStatus({
        type: "SetListaRegistroPostulacion",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const putAsignarProcesoPostulacion = async (values: any): Promise<void> => {
    const { token, formData, setIsLoading, listRefresh } = values;
    setIsLoading && setIsLoading(true);

    const transformarObjeto = (
      objetoOriginal: IOriginalObject
    ): ITransformedObject[] => {
      const arrayTransformado: ITransformedObject[] = [];

      for (const [clave, valor] of Object.entries(objetoOriginal)) {
        if (valor.value && valor.value.code) {
          const procesoId: string = valor.value.code;

          const elementoExistente = arrayTransformado.find(
            (elemento) => elemento.procesoId === procesoId
          );

          if (elementoExistente) {
            elementoExistente.personas.push(clave);
          } else {
            arrayTransformado.push({
              procesoId: procesoId,
              personas: [clave],
            });
          }
        }
      }
      return arrayTransformado;
    };
    const formDataArray = transformarObjeto(formData);

    try {
      for (const data of formDataArray) {
        const { procesoId, personas } = data;

        await Promise.all(
          personas.map((persona) =>
            putSelectPersonBusper({ formData: { procesoId, personas }, token })
          )
        );
      }
      createToast({
        title: "Proceso Asignado Correctamente",
        message: "Los datos se han guardado correctamente.",
        type: "success",
      });
      listRefresh && listRefresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const putSelectPersonBusper = async (values: any): Promise<void> => {
    const { token, formData } = values;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}asignar-proceso`,
        formData,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.results;
      useChangeStatus({
        type: "SetListResultAsignaProceso",
        dispatch,
        payload: data,
      });
    } catch (error) {
      createToast({
        title: "Error al Procesar",
        message: "Por favor, intente nuevamente",
        type: "danger",
      });
    }
  };

  const getListaCompetenciaLaboral = async (values: any): Promise<void> => {
    const { token } = values; //, perfil
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/listas/competenciaslaborales?userId=${state.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = result.data;
      setListaCompetenciasLaborales(data.competencias);
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListadoPdf = async (values: any): Promise<void> => {
    const { selectCurrent } = values;
    const urls: string[] = [];
    try {
      Object.keys(selectCurrent).map((key) => {
        if (selectCurrent[key].hasOwnProperty("value")) {
          const formatoValue = selectCurrent[key].value?.code ?? "";
          const url = `${process.env.REACT_APP_redirectUri}/pdf/psicolaboral/${formatoValue}/${key}`;
          urls.push(url);
        }
      });
      urls.forEach((url) => {
        window.open(url, "_blank");
      });
      createToast({
        title: "PDF Generado",
        message: "Revisa el formato y descargalo en la nueva pestaña",
        type: "success",
      });
    } catch (error) {
      Object.keys(selectCurrent).map((key) => {
        const item = selectCurrent[key];
        if (!item.valid) {
          createToast({
            title: "Error al Procesar PDF",
            message:
              "Por favor, seleccione tipo documento de los candidatos seleccionados",
            type: "danger",
          });
        } else {
          createToast({
            title: "Error al Procesar PDF",
            message: "Por favor, intente nuevamente",
            type: "danger",
          });
        }
      });
    }
  };

  const postReportePdfPsicolaboral = async (values: any): Promise<void> => {
    const { token, formato, busperid, setLoading, setErrorLoading } = values;
    setLoading && setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}reporte-psicolaboral`,
        {
          reportes: [
            {
              busPerId: busperid,
              formato,
            },
          ],
        },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      const noProcessed = await response.data.noProcessed;
      useChangeStatus({
        type: "SetListaReportePdfPsicolaboral",
        dispatch,
        payload: data,
      });

      noProcessed && noProcessed.length > 0
        ? createToast({
            title: "Error al Procesar PDF",
            message: "Informe no encontrado, favor llenar Informe Psicolaboral",
            type: "warning",
          })
        : createToast({
            title: "PDF Generado",
            message: "Revisa el formato y descargalo en la nueva pestaña",
            type: "success",
          });
      noProcessed &&
        noProcessed.length > 0 &&
        setErrorLoading &&
        setErrorLoading(true);
    } catch (error) {
      createToast({
        title: "Error al Procesar PDF",
        message: "Por favor, intente nuevamente",
        type: "danger",
      });
      setErrorLoading && setErrorLoading(true);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const getBateriaDocumentos = async (values: any): Promise<void> => {
    const { setIsLoading, token } = values;
    setIsLoading && setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/bateria-documentos`,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.data.documentSuite;
      useChangeStatus({
        type: "SetListBateriaDocument",
        dispatch,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const deleteBateria = async (values: any): Promise<void> => {
    const { objeto, token, personaDNI, personaId } = values;
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_URL}documentos`,
        {
          data: objeto,
          params: {
            userId: state.userId,
            personaId: personaId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await result.data;

      if (data.processed.length > 0) {
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_API_URL}/documentos`,
            {
              params: {
                userId: state.userId,
                personaDNI: personaDNI,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await result.data;
          useChangeStatus({
            type: "SetListDocument",
            dispatch,
            payload: data,
          });
        } catch (error) {
          console.log(error);
        }

        createToast({
          title: "Documento eliminado",
          message: "Documento eliminado correctamente",
          type: "success",
        });
      }

      if (data.noProcessed.length > 0) {
        createToast({
          title: "Error al eliminar",
          message:
            "Error al eliminar el documento, por favor intenta nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      console.log("Ha ocurrido algo: ", error);
    }
  };

  const uploadNominaAzure = async (values: any): Promise<void> => {
    const { file, token, isLoadingExcel, cancelToken } = values;

    const formData = new FormData();
    setListResumenProcesadosNomina({ dispatch, payload: [] });
    isLoadingExcel && isLoadingExcel(true);
    try {
      if (file) {
        formData.append("file", file);
      }
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}excel/upload/nomina-seleccion?userId=${state.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: values.cancelToken,
        }
      );
      const data = result.data;
      setListResumenProcesadosNomina({ dispatch, payload: data });
      createToast({
        title: "Carga de Nómina Exitosa",
        message: `Descarga Exitosa`,
        type: "success",
      });
    } catch (error: any) {

      if (typeof error.response?.data?.code === "number") {
        setEmptyMessage({ dispatch, payload: error.response?.data?.message });
      } else if (typeof error.response?.data?.message?.id === "string") {
        const errorCode: string = error.response.data.message.id;
        setEmptyMessage({ dispatch, payload: `Ha ocurrido un error desconocido, revisa el formato y los datos de tu plantilla o ponte en contacto con la mesa de ayuda.\nCódigo de Error: ${errorCode}` });
      } else {
        setEmptyMessage({ dispatch, payload: "Ha Ocurrido Un Error Desconocido, contáctate con la mesa de ayuda" });
      }

      if (axios.isCancel(error)) {
        // La solicitud fue cancelada
        setListResumenProcesadosNomina({ dispatch, payload: [] });
        console.log("La solicitud fue cancelada:");
      } else {
        console.log(error);
        setListResumenProcesadosNomina({ dispatch, payload: [] });
        createToast({
          title: "Error al Procesar",
          message: `Por favor, intenta nuevamente`,
          type: "danger",
        });
      }
    } finally {
      isLoadingExcel && isLoadingExcel(false);
    }
  };

  const dowloadExcelProcessActivos = async (values: any): Promise<void> => {
    const { token, filtrosExcel, setIsLoadingExcel } = values;
    setIsLoadingExcel && setIsLoadingExcel(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}excel/download/procesos-activos?userId=${state.userId}`,
        { filtros: filtrosExcel },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const urlToDownload = result.data.urlToDownload;
      urlToDownload &&
        createToast({
          title: "Descarga de Nómina Exitosa",
          message: `El enlace de descarga llegara a su correo`,
          type: "success",
        });
    } catch (error) {
      console.log(error);
      createToast({
        title: "Error al Procesar",
        message: `Por favor, intenta nuevamente`,
        type: "danger",
      });
    } finally {
      setIsLoadingExcel && setIsLoadingExcel(false);
    }
  };

  const downloadRegistroCandidato = async (values: any): Promise<void> => {
    const { token, registros, setLoading } = values;
    setLoading && setLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}archivos/download/registro-candidato?userId=${state.userId}`,
        { busPerList: registros },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const wasEnqueued = result.data.wasEnqueued;
      wasEnqueued &&
        createToast({
          title: "Descarga Registro de candidato exitosa",
          message: `El enlace de descarga llegara a su correo`,
          type: "success",
        });
    } catch (error) {
      createToast({
        title: "Error al Procesar",
        message: `Por favor, intenta nuevamente`,
        type: "danger",
      });
    } finally {
      setLoading && setLoading(false);
    }
  };

  //#region DELETE DOCUMENT
  const deleteDocumentCandidate = async (
    values: IDeleteDocumentCandidate
  ): Promise<void> => {
    const {
      id,
      documentos,
      token,
      personaId,
      setIsLoading,
      setFormUpDocument,
    } = values;
    try {
      setIsLoading && setIsLoading(true);
      const result = await axios.delete(
        `${process.env.REACT_APP_API_URL}documentos`,
        {
          data: { documentos },
          params: {
            userId: state.userId,
            personaId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const totalProcessed = result.data.totalProcessed;
      if (totalProcessed > 0) {
        setFormUpDocument((prev: IDocumentoCandidate[]) =>
          prev.filter((item: IDocumentoCandidate) => item.id !== id)
        );
        createToast({
          title: "Documento eliminado",
          message: "Documento eliminado correctamente",
          type: "success",
        });
      }
    } catch (error) {
      console.log("Ha ocurrido algo: ", error);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  //#region UPDATE DOCUMENT
  const updateDocumentCandidate = async (values: any): Promise<void> => {
    const { personaId, documentos, token, setFormUpDocument } = values;
    const formData = { personaId, documentos };
    try {
      if (documentos) {
        const result = await axios.put(
          `${process.env.REACT_APP_API_URL}/documentos`,
          formData,
          {
            params: {
              userId: state.userId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const status = result.status;
        status == 200 &&
          createToast({
            title: "Documento Actualizado",
            message: "Documento Actualizado correctamente",
            type: "success",
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SeleccionContext.Provider
      value={{
        ...state,
        toasts,
        token,
        isError,
        isLoading,
        rolesUsers,
        createToast,
        getToken,
        setIsError,
        setIsLoading,
        getUserPerfilRol,
        getExclusiones,
        putAsignarProceso,
        postBlockCandidate,
        getCandidatoHabilitado,
        getCandidatoInhabilitado,
        getCandidatoOtrosInhabilitado,
        getCandidatoNoDisponible,
        getCandidatoPendiente,
        getCandidatoValidado,
        getCandidatoPendienteMasivo,
        getCandidatoValidadoMasivo,
        getCandidatoPreSeleccionPendiente,
        getCandidatoPreSeleccion,
        getCandidatoPreSolEnviada,
        postSolicitarCandidato,
        getCandidatoPreSolRecibida,
        putResponderSolTraspaso,
        getCandidatoSeleccionado,
        getListaUsuarios,
        getBusquedaProcesos,
        getProcesos,
        getRutVerificado,
        getListaGeneral,
        getRegistroCandidato,
        getDocument,
        postDeshacerExclusion,
        createAsignarEquipo,
        createCandidate,
        createDocumentCandidate,
        listPerfiles,
        setListPerfiles,
        getEvaluacionPsicolaboral,
        getListasPerfil,
        listConclusiones,
        setListConclusiones,
        getInformacionPsicolaboral,
        //infoAntecedentes,
        //setInfoAntecedentes,
        listTipoEducacion,
        recordDataPsicolaboral,
        putAvanzarProceso,
        putRetrocederProceso,
        putEntrevistaRealizada,
        getMetodoMovilizacion,
        putMetodoMovilizacion,
        putConfirmarEntrevista,
        getComprobarEstadoCandidato,
        getPostulacionRecibida,
        getPostulacionProcesada,
        postProcesosPostulados,
        putAsignarProcesoPostulacion,
        getRegistroPostulacion,
        putNombrePostulacion,
        getUserAccess,
        postGenerarExcel,
        getCounts,
        createLinkProcessTxt,
        createCodeQrListProcess,
        getListaCompetenciaLaboral,
        listaCompetenciasLaborales,
        postReportePdfPsicolaboral,
        getListadoPdf,
        getBateriaDocumentos,
        deleteBateria,
        getProcessAssign,
        uploadNominaAzure,
        dowloadExcelProcessActivos,
        downloadRegistroCandidato,
        deleteDocumentCandidate,
        updateDocumentCandidate,
      }}
    >
      {children}
    </SeleccionContext.Provider>
  );
};
