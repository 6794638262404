import { useContext, useEffect, useState } from "react";
import MediaQuery from "../../../ui-responsive/MediaQuery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import { Params } from "../../../../interfaces/IValidationKam";
import { useParams } from "react-router-dom";
import { IClientes } from "../../../../interfaces/IValidationKam";
import { FILTER_LIST, FILTER_PROFILE } from "../../../../diccionary/Diccionary";

interface ISubMenu {
  list?: IClientes[];
  token: string | undefined;
  setToken: Function;
  getToken: Function;
}
const SubMenu = (props: ISubMenu) => {
  const { list, token, setToken, getToken } = props;
  const { countAssign, getCounts, profile } = useContext(ValidationKamContext);
  const { width, sm } = MediaQuery();
  const [isListado, setIsListado] = useState<boolean>(false);
  const [itemMenu, setItemMenu] = useState<string>("");
  let { idCliente } = useParams<Params>();
  const [pagesNav, setPagesNav] = useState<string>("");

  useEffect(() => {
    if (token) {
      getCounts({ token });
    }
  }, [token]);

  useEffect(() => {
    const urlCompleta = window.location.href;
    const partes = urlCompleta.split("/");
    const primerValor = partes[3];
    setPagesNav(primerValor);
  }, [window.location.href]);

  const handleClickGo = (values: any) => {
    const { ruta } = values;
    window.location.href = `/${pagesNav}/${ruta}`;
  };

  const handleClickGoSubMenu = (values: any) => {
    const { ruta } = values;
    window.location.href = `/${ruta}`;
  };

  return (
    <>
      {width >= sm ? (
        <>
          <ul className="content-nav">
          {["validacion"].includes(pagesNav) && (
          <>
            <li
              onClick={() => {
                handleClickGoSubMenu({
                  ruta:"validacion",
                });
              }}
              className={`nav-tag ${ pagesNav == "validacion" &&
                "active"
              }`}
            >
              Asistencia y Validación
            </li>
          </>
        )}
        {["validacioncliente"].includes(pagesNav) &&
          ["Asistencia y Validación"].map((item: any, key: number) => {
            return (
              <li
                key={item}
                onClick={() => {
                  setItemMenu(item);
                }}
                className={`nav-tag ${pagesNav == "validacioncliente" && "active"}`}
              >
                {item}
              </li>
            );
          })}
      </ul>
          {[FILTER_PROFILE.tw_cliente].includes(profile) && (
            <div className="title-tw-cliente">
              Bienvenido a Selección de Candidatos:
            </div>
          )}
          <div className="content-nav-asistencia">
            {countAssign ? (
              countAssign.map((value: IClientes) => {
                const { clienteId, clienteNombre } = value;
                return (
                  <>
                    <button
                      className={`nav-tag-button ${
                        idCliente === clienteId ? "active" : ""
                      }`}
                      key={clienteId}
                      onClick={() => {
                        handleClickGo({ ruta: clienteId });
                      }}
                    >
                      {clienteNombre ?? "Error"}
                    </button>
                  </>
                );
              })
            ) : (
              <span className="nav-tag-button">Error</span>
            )}
          </div>
        </>
      ) : (
        <>
          {/* <div className="div-menu-responsive">
            <div className="InputSelect">
              <select className="InputSelect__select">
                {list &&
                  list?.map((value: IClientes) => {
                    const { clienteId, clienteNombre } = value;
                    return (
                      <>
                        <option value="opcion1" key={clienteId}>
                          {clienteNombre}
                        </option>
                      </>
                    );
                  })}
              </select>
            </div>
            <button
              className="button-nav-menu"
              onClick={() => setIsListado(isListado ? false : true)}
            >
              <MoreVertIcon />
            </button>
            {isListado && (
              <div className="div-nav-ul">
                <ul className="list-nav">
                  <li
                    onClick={() => setIsListado(isListado ? false : true)}
                    className="li-nav"
                  >
                    Administración Servicios de Transporte
                  </li>
                  <li
                    onClick={() => setIsListado(isListado ? false : true)}
                    className="li-nav"
                  >
                    Solicitudes fuera de plazo
                  </li>
                  <li
                    onClick={() => setIsListado(isListado ? false : true)}
                    className="li-nav active"
                  >
                    Asistencia y Validación
                  </li>
                  <li
                    onClick={() => setIsListado(isListado ? false : true)}
                    className="li-nav"
                  >
                    Contrataciones
                  </li>
                </ul>
              </div>
            )}
          </div> */}
        </>
      )}
    </>
  );
};

export default SubMenu;
