import { useState } from "react";

interface IViewDocument {
  title: string;
  src: string | undefined;
  selectDocument: string;
  personadni: string;
  userId: string;
  token: string;
}

export default (props: IViewDocument) => {
  const { title, selectDocument, personadni, token, src, userId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      {/* <div className="content-subtitle">
        <div>
          <p className="title">{title}</p>
        </div>
        <span className="linea"></span>
      </div> */}
      {!isLoading && selectDocument ? (
        <>
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              height: "400px",
              overflow: "hidden",
            }}
          >
            <iframe
              src={`${src}?userid=${userId}&personadni=${personadni}&glcid=${token}&documentoId=${selectDocument}`}
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen
              // type="application/pdf"
            ></iframe>
          </div> */}
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>
            {selectDocument
              ? " Cargando documentos..."
              : "Seleccione un documento"}
          </h3>
        </div>
      )}
    </>
  );
};
