import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  ICategoriesValidationInput,
  IChangeEvent,
  IInputRut,
} from "../../../interfaces/IInput";
import { formatRut, validVerifyDigit } from "../../../functions/kit-mdtw/rut";

export default  (props: IInputRut) => {
  const {
    placeholder,
    layer,
    required,
    onValueChange,
    onPropertyChange,
    onPermaValues,
    maxLength,
    layerInvisible,
    errorPosition,
    validacion,
    disabled,
    setValue,
    rut,
    defaultValue,
    requerido,
    datoRequerido, 
  } = props;

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(undefined);
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [rutNumber, setRutNumber] = useState<string>("");

  useEffect(() => {
    if(defaultValue){
      setRutNumber(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, defaultValue]);

  useEffect(() => {
    if(rut){
      setRutNumber(rut);
      handleChangeEventError({ value: rut });
    }
  }, [rut])

  const handleChangeEventError = (event: IChangeEvent) => {
    const { value } = event;
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";
    let rutInput: string = value;

    rutInput = formatRut({ rut: value.split("-").join("") });
    rutInput = rutInput.replace(/\./g, "");

    if (required) {
      trigger = value !== "" ? false : true;
      eventValidation = value !== "" ? "success" : "error";
    }

    if (!required) {
      eventValidation = value !== "" ? "success" : "initial";
    }

    if (required) {
      if (rutInput === "") {
        setMessageError(`Dato obligatorio`);
        trigger = true;
        eventValidation = "error";
      }
    }
    
    if (rutInput !== "") {
      if (
        //   /^([\d]{1,2})+([.]{1})+([\d]{3})+([.]{1})+([\d]{3})+([-]{1})+([\dK-k]{1})$/
        !rutInput.match(/^[0-9]+-[0-9kK]{1}$/)
      ) {
        setMessageError(`no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
      } else if (rutInput.length < 8) {
        setMessageError(`no tiene el formato adecuado`);
        trigger = true;
        eventValidation = "error";
      }
    }

    if (rutInput !== "" && !trigger) {
      if (!validVerifyDigit({ rut: rutInput.split(".").join("") })) {
        setMessageError(`El digito verificador no pertenece`);
        trigger = true;
        eventValidation = "error";
      }
    }

    if (datoRequerido) {   
      if (requerido) {
        triggerRequired = value !== "" ? false : true;
        eventValidation = value !== "" ? "success" : "caution";
      }
    }

    setEventValidation(eventValidation);
    typeof validacion === "function" &&
      validacion(eventValidation == "success" ? false : true);
    setTriggerError(trigger);

    if (onPermaValues && onPropertyChange && onValueChange) {

      if(typeof onPropertyChange === "string"){
        const permaValues = { ...onPermaValues };
        permaValues[onPropertyChange] = {
          value: value !== "" ? value : undefined,
          valid: trigger,
        };
        onValueChange(permaValues);
      }
      if(typeof onPropertyChange === "object"){
        const permaValues = [ ...onPermaValues ];
        const [position, key] = onPropertyChange;
        permaValues[position][key] = {
          value: value !== "" ? value : undefined,
          valid: trigger,
        };
        onValueChange(permaValues);
      }

    }
    setRutNumber(rutInput);
    typeof setValue == "function" && setValue(rutInput); 
  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      <div style={{ display: "flex" }}>
        {!layerInvisible && <label className="layer">{`${layer}`}</label>}
        {triggerError && ["up", undefined].includes(errorPosition) && (
          <span className="layer-error">{messageError}</span>
        )}
        {triggerRequerido && ["up", undefined].includes(errorPosition) && (
          <span className="layer-caution"><ErrorIcon />Dato Requerido</span>
        )}
      </div>

      <input
        type="text"
        placeholder={placeholder}
        className={`${categoriesValidation[eventValidation]} ${disabled ? 'disabled' : ''}`}
        required={required}
        maxLength={maxLength ?? 10}
        value={rutNumber}
        onChange={({ target }) =>
          handleChangeEventError({ value: target.value })
        }
        onBlur={({ target }) => handleChangeEventError({ value: target.value })}
        disabled={disabled}
      />
      {triggerError && errorPosition == "down" && (
        <span className="layer-error down">{messageError}</span>
      )}
      {triggerRequerido && errorPosition === "down" && !disabled && (
        <span className="layer-caution down">
          <ErrorIcon />
          Dato Requerido
        </span>
      )}
    </div>
  );
};
