import { Button } from "primereact/button";
import CloseIcon from '@mui/icons-material/Close';

interface IModals {
  onChangeBack?: Function;
  onChangeGo?: Function;
  textButtonBack?: string;
  textButtonGo?: string;
}

export default (props: IModals) => {
  const { onChangeBack, onChangeGo, textButtonBack, textButtonGo } = props;
  return (
    <>
      <div className="modal-container">
        <div className="modal-content">
          <span className="modal-close" onClick={() => typeof onChangeBack === "function" && onChangeBack()}>
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>¿Está seguro qué desea salir de la página actual?</span>
          </div>
          <div className="modal-message">
            <span>Se perderán los cambios no guardados</span>
          </div>
          <div className="button-content-modal">
            <Button className="button-modal-back" onClick={() => typeof onChangeBack === "function" && onChangeBack()}>
              {textButtonBack ?? "Cancelar"}
            </Button>
            <Button className="button-modal-danger" onClick={() => typeof onChangeGo === "function" && onChangeGo()}>
              {textButtonGo ?? "Continuar"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
