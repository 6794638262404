import { useContext, useEffect, useState } from "react";
import IconDocumento from "../../../../../ui/kit-svg/icon-dashboard/graficos/IconDocumento";
import FooterTable from "../FooterTable";
import OperacionContratacionContext from "../../../../../../context/Operaciones/OperacionContratacionContext";
import {
  IListaIngresosProcesados,
  IUltimasBajasProcesadas,
} from "../../../../../../interfaces/IOperacionesContratacion";
import { formatDateToDDMMAAAA } from "../../../../../../functions/kit-mdtw/dates";

interface IUltimosIngresosProcesados {
  objetoFilterGeneral: any;
}

const UltimosIngresosProcesados = (props: IUltimosIngresosProcesados) => {
  const { objetoFilterGeneral } = props;
  const {
    token,
    listaIngresosProcesados,
    postIngresosProcesados,
    listasUltimasBajasProcesadas,
    postUltimasBajasProcesadas,
  } = useContext(OperacionContratacionContext);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 4,
  });
  const [gridList, setGridList] = useState<any[]>([]);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [formFilter, setFormFilter] = useState<any>({
    areaNegocio: [],
    analista: [],
    periodo: [],
  });

  useEffect(() => {
    if (token) {
      if (
        objetoFilterGeneral &&
        objetoFilterGeneral?.ciclo.includes("Activos")
      ) {
        postUltimasBajasProcesadas({ token, filtros: formFilter });
      } else {
        postIngresosProcesados({ token, filtros: formFilter });
      }
    }
  }, [objetoFilterGeneral, token]);

  useEffect(() => {
    if (objetoFilterGeneral && objetoFilterGeneral?.ciclo.includes("Activos")) {
      if (listasUltimasBajasProcesadas) {
        let arrayGridFilter = [...listasUltimasBajasProcesadas];
        setLengthFilters(arrayGridFilter.length);
        if (range) {
          const { initRange, endRange } = range;
          arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
        }
        setGridList(arrayGridFilter);
      }
    } else {
      if (listaIngresosProcesados) {
        let arrayGridFilter = [...listaIngresosProcesados];
        setLengthFilters(arrayGridFilter.length);
        if (range) {
          const { initRange, endRange } = range;
          arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
        }
        setGridList(arrayGridFilter);
      }
    }
  }, [
    objetoFilterGeneral,
    listaIngresosProcesados,
    listasUltimasBajasProcesadas,
    range,
  ]);

  return (
    <section className="container-table-dashboard w-65 responsiveChart">
      {objetoFilterGeneral && objetoFilterGeneral?.ciclo.includes("Activos") ? (
        <>
          <div className="content-title">
            <label className="title-table-dashboard">
              Últimas Bajas Procesadas
            </label>
          </div>
          <div className="content-table-dashboard">
            <table className="table-dashboard">
              <thead>
                <tr>
                  <th></th>
                  <th>NOMBRE</th>
                  <th>FICHA</th>
                  <th>FECHA TERMINO CONTRATO</th>
                  <th>FECHA PROCESO</th>
                </tr>
              </thead>
              <tbody>
                {gridList &&
                  gridList.map((item: IUltimasBajasProcesadas) => {
                    const {
                      primerApellido,
                      primerNombre,
                      ficha,
                      fechaDesvinculacion,
                      fechaCalculo,
                    } = item;
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="content-name">
                              <IconDocumento fill="#BE2A2A" />
                            </div>
                          </td>
                          <td>
                            {primerNombre} {primerApellido}
                          </td>
                          <td>{ficha}</td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaDesvinculacion,
                              format: "/",
                            })}
                          </td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaCalculo,
                              format: "/",
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="content-title">
            <label className="title-table-dashboard">
              Últimos Ingresos Procesados
            </label>
          </div>
          <div className="content-table-dashboard ">
            <table className="table-dashboard">
              <thead>
                <tr>
                  <th></th>
                  <th>NOMBRE</th>
                  <th>FICHA</th>
                  <th>TIPO DOCUMENTO</th>
                  <th>FECHA PROCESO</th>
                </tr>
              </thead>
              <tbody>
                {gridList &&
                  gridList.map((item: IListaIngresosProcesados) => {
                    const {
                      primerApellido,
                      primerNombre,
                      divisionId,
                      clienteId,
                      fechaProceso,
                    } = item;
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="content-name">
                              <IconDocumento fill="#0065DD" />
                            </div>
                          </td>
                          <td>
                            {primerNombre} {primerApellido}
                          </td>
                          <td>{divisionId}</td>
                          <td>{clienteId}</td>
                          <td>
                            {formatDateToDDMMAAAA({
                              date: fechaProceso,
                              format: "/",
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />
    </section>
  );
};

export default UltimosIngresosProcesados;
