import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="48"
      height="64"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.75 47.136C30.1977 47.136 31.4347 46.6349 32.4608 45.6326C33.4869 44.6303 34 43.4053 34 41.9576C34 40.7167 33.6261 39.611 32.8784 38.6406C32.1307 37.6701 31.1523 37.0496 29.9432 36.7792C29.5455 35.3156 28.75 34.1303 27.5568 33.2235C26.3636 32.3167 25.0114 31.8633 23.5 31.8633C21.6386 31.8633 20.0597 32.5116 18.7631 33.8082C17.4665 35.1048 16.8182 36.6837 16.8182 38.5451C15.7205 38.6724 14.8097 39.1457 14.0858 39.965C13.3619 40.7843 13 41.7428 13 42.8406C13 44.0337 13.4176 45.0479 14.2528 45.8832C15.0881 46.7184 16.1023 47.136 17.2955 47.136L22.5455 47.136C23.0705 47.136 23.5199 46.9491 23.8937 46.5752C24.2676 46.2013 24.4545 45.7519 24.4545 45.2269L24.4545 40.311L25.9818 41.7906L27.3182 40.4542L23.5 36.636L19.6818 40.4542L21.0182 41.7906L22.5455 40.311L22.5455 45.2269L17.2955 45.2269C16.6273 45.2269 16.0625 44.9962 15.6011 44.5349C15.1398 44.0735 14.9091 43.5087 14.9091 42.8406C14.9091 42.1724 15.1398 41.6076 15.6011 41.1462C16.0625 40.6849 16.6273 40.4542 17.2955 40.4542L18.7273 40.4542L18.7273 38.5451C18.7273 37.2246 19.1926 36.0991 20.1233 35.1684C21.054 34.2377 22.1795 33.7724 23.5 33.7724C24.8205 33.7724 25.946 34.2377 26.8767 35.1684C27.8074 36.0991 28.2727 37.2246 28.2727 38.5451L28.75 38.5451C29.6727 38.5451 30.4602 38.8712 31.1125 39.5235C31.7648 40.1758 32.0909 40.9633 32.0909 41.886C32.0909 42.8087 31.7648 43.5962 31.1125 44.2485C30.4602 44.9008 29.6727 45.2269 28.75 45.2269L26.3636 45.2269L26.3636 47.136L28.75 47.136Z"
        fill={disabled ? "#aeaeae" : fill}
      />
    </svg>
  );
};
