import axios from "axios";
import { environment } from "../config/enviroment";

const api = axios.create({
baseURL: environment.API_URL,
headers: {
    "Access-Control-Allow-Origin": "*",
},
});

export default api;
