import React, { useEffect } from "react";
import IPage from "../../../interfaces/page";
import PreveTopbar from "../../layout/app/PreveTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../../config/authConfig";
import { Loading } from "../../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../../ui/microsoft-auth/msal-template/ErrorComponent";
import AddClientComponent from "../../ui/V2/AddFormComponent";

const AddFormPage: React.FC<IPage> = (props) => {
  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <PreveTopbar
          onToggleMenuClick={onToggleMenuClick}
          active="D"
        ></PreveTopbar>
        <AddClientComponent />
      </MsalAuthenticationTemplate>
    </>
  );
};

export default AddFormPage;
