import { Button } from "primereact/button";
import "../../../../assets/css/adminModal.min.css";
import CloseIcon from "@mui/icons-material/Close";

interface IModals {
  title: string;
  subTitle?: string;
  message: string;
  success?: boolean;
  confirm: boolean;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
}

export default (props: IModals) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, success, confirm, width } = props;
  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin-message" style={{width: width}}>
          <span className="modal-close" onClick={() => {if (onChangeBack) onChangeBack()}}>
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          {subTitle ? (
            <div className="modal-subtitle">
              <span>{subTitle}</span>
            </div>
          ) : (
            ""
          )}
          <div className="modal-message">
            <span>{message}</span>
          </div>

          {success ? (
            <div className="button-content" style={{justifyContent: "center"}}>
              <Button className="button-go-confirm" onClick={() => {if (onChangeGo) onChangeGo()}}>
                Continuar
              </Button>
            </div>
          ): (
            <>
            {confirm ? (
            <div className="button-content">
              <Button className="button-back" onClick={() => {if (onChangeBack) onChangeBack()}}>
                Volver
              </Button>
              <Button className="button-go-confirm" onClick={() => {if (onChangeGo) onChangeGo()}}>
                Continuar
              </Button>
            </div>
          ) : (
            <div className="button-content">
              <Button className="button-back" onClick={() => {if (onChangeBack) onChangeBack()}}>
                Cancelar
              </Button>
              <Button className="button-go-delete" onClick={() => {if (onChangeGo) onChangeGo()}}>
                Eliminar
              </Button>
            </div>
          )}
          </>
          )}
        </div>
      </div>
    </>
  );
};
