import { useContext, useEffect, useState } from "react";
import ContratosContext from "../../../../context/Contratos/ContratosContext";

interface ISectionActive {
  localStorageFilter: string;
  objetoFilterGeneral?: any;
  accessTotal: boolean;
}

export default (props: ISectionActive) => {
  const { objetoFilterGeneral, accessTotal } = props;
  const { getDashboardResumen, listaDashboardResumen, token } =
    useContext(ContratosContext);

  const [primerLocation, setPrimerLocation] = useState<string>("");
  const [segundaLocation, setSegundaLocation] = useState<string>("");
  const [tercerLocation, setTercerLocation] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    try {
      const url: string = window.location.pathname;
      const parts: string[] = url.split("/");
      const primerValor: string = parts[1] ?? "";
      const segundoValor: string = parts[2] ?? "";
      const tercerValor: string = parts[3] ?? "";
      setPrimerLocation(primerValor);
      setSegundaLocation(segundoValor);
      setTercerLocation(tercerValor);
    } catch (error) {
      console.log("error en crear rutas");
    }
  }, [segundaLocation]);

  useEffect(() => {
    try {
      if (token) {
        const objeto = {
          ingresos: false,
          activos: false,
          general: false,
          contrato: true,
          filtros: {
            areaNegocio: objetoFilterGeneral?.areaNegocio,
            clientes: objetoFilterGeneral?.cliente,
            analista: objetoFilterGeneral?.analista,
          },
        };
        getDashboardResumen({ token, objeto, setIsLoading });
      }
    } catch (error) {
      console.log(error);
    }
  }, [token, objetoFilterGeneral]);

  const handleClickGoRuta = (values: any): void => {
    const { ruta, page, page2 } = values;

    window.location.href = `/${ruta}${page ? `/${page}` : ""}${
      page2 ? `/${page2}` : ""
    }`;
  };

  return (
    <>
      {!isLoading ? (
        <>
          <section className="row card-container">
            <div className="cards card1">
              <div className="content-etiqueta-title">
                <label className="numberCard">
                  {listaDashboardResumen?.solicitudesContratoRecibida}
                </label>
                <span className="textCard">SOLICITUD DE CONTRATO RECIBIDA</span>
              </div>

              {accessTotal && (
                <label
                  onClick={() => {
                    const array = JSON.stringify({
                      ["estado"]: ["PEND_REVISION"],
                      ["tipoDocumento"]: ["CONTRATO"],
                    });
                    handleClickGoRuta({
                      ruta: primerLocation,
                      page: "pendientes",
                    });
                    localStorage.setItem(
                      "filtroContratosSolicitudPendientes",
                      array
                    );
                  }}
                  className="verTodo"
                >
                  VER TODO
                </label>
              )}
            </div>

            <div className="cards card4">
              <div className="content-etiqueta-title">
                <label className="numberCard">
                  {listaDashboardResumen?.contratosPendienteEnvioValidacion}
                </label>
                <span className="textCard">
                  CONTRATOS PENDIENTES ENVIAR A VALIDACIÓN
                </span>
              </div>

              {accessTotal && (
                <label
                  onClick={() => {
                    const array = JSON.stringify({
                      ["estado"]: ["DOC_CREADO_SIN_VALIDAR"],
                      ["tipoDocumento"]: ["CONTRATO"],
                    });
                    handleClickGoRuta({
                      ruta: primerLocation,
                      page: "procesadas",
                    });
                    localStorage.setItem("filtroContratoSolProcesadas", array);
                  }}
                  className="verTodo"
                >
                  VER TODO
                </label>
              )}
            </div>

            <div className="cards card2">
              <div className="content-etiqueta-title">
                <label className="numberCard">
                  {listaDashboardResumen?.renovacionesRecibidas}
                </label>
                <span className="textCard">
                  SOLICITUD DE RENOVACIONES RECIBIDAS
                </span>
              </div>

              {accessTotal && (
                <label
                  onClick={() => {
                    const array = JSON.stringify({
                      ["estado"]: ["PEND_REVISION"],
                      ["tipoDocumento"]: ["RENOVACION"],
                    });
                    handleClickGoRuta({
                      ruta: primerLocation,
                      page: "pendientes",
                    });
                    localStorage.setItem(
                      "filtroContratosSolicitudPendientes",
                      array
                    );
                  }}
                  className="verTodo"
                >
                  VER TODO
                </label>
              )}
            </div>

            <div className="cards card4">
              <div className="content-etiqueta-title">
                <label className="numberCard">
                  {listaDashboardResumen?.renovacionesPendienteEnvioValidacion}
                </label>
                <span className="textCard">
                  RENOVACIONES PENDIENTES ENVIAR A VALIDACIÓN
                </span>
              </div>

              {accessTotal && (
                <label
                  onClick={() => {
                    const array = JSON.stringify({
                      ["estado"]: ["DOC_CREADO_SIN_VALIDAR"],
                      ["tipoDocumento"]: ["RENOVACION"],
                    });
                    handleClickGoRuta({
                      ruta: primerLocation,
                      page: "procesadas",
                    });
                    localStorage.setItem("filtroContratoSolProcesadas", array);
                  }}
                  className="verTodo"
                >
                  VER TODO
                </label>
              )}
            </div>
            <div className="cards card3">
              <div className="content-etiqueta-title">
                <label className="numberCard">
                  {listaDashboardResumen?.rechazadoFirmaDigital}
                </label>
                <span className="textCard">
                  DOCUMENTOS RECHAZADOS POR FIRMA DIGITAL
                </span>
              </div>
              {accessTotal && (
                <label
                  onClick={() => {
                    const array = JSON.stringify({
                      ["estado"]: ["RECHAZADO_FIRMA"],
                    });
                    handleClickGoRuta({
                      ruta: primerLocation,
                      page: "procesadas",
                    });
                    localStorage.setItem("filtroContratoSolProcesadas", array);
                  }}
                  className="verTodo"
                >
                  VER TODO
                </label>
              )}
            </div>
          </section>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <h3>Cargando tarjetas dashboard...</h3>
        </div>
      )}
    </>
  );
};
