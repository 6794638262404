import React, { useEffect } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";

const ErrorPage: React.FC<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, []);

  return <div>ErrorPage</div>;
};

export default ErrorPage;
