import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import ProcessRequestModalV2 from "./ProcessRequestModalV2";
import { useMsal } from "@azure/msal-react";
import { Toast } from "primereact/toast";

const ActiveProcessV2 = () => {
  const [token, setToken] = useState<any>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [filterByGroup, setfilterByGroup] = useState<any[]>([]);
  const [groupsReload, setGroupsReload] = useState<any[]>([]);
  const [groupFilter, setGroupFilter] = useState("");
  const [profile, setProfile] = useState<any>(null);
  const history = useHistory();
  const [busquedaModal, setBusquedaModal] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState(false);  
  const { instance } = useMsal();
  const toast = useRef<Toast>(null);

  const rightContents = () => {
    return (
      <>
        <div className="flex flex-row flex-wrap">
          {profile && (profile == "adm_tw" || profile == "tw_admsel" || profile == "tw_admsel_prof") ? (
            <div className="">
              <a
                href="/solicitudes"
                style={{
                  width: "53px",
                  height: "19px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "18px",
                  alignItems: "center",
                  color: "#AEAEAE",
                }}
              >
                Solicitudes
              </a>
            </div>
          ) : (
            ""
          )}
          <div className="ml-6">
            <a
              href="/procesosasignadosactivos"
              style={{
                width: "53px",
                height: "19px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "18px",
                alignItems: "center",
                textDecorationLine: "underline",
                color: "#013776",
              }}
            >
              Activos
            </a>
          </div>
          <div className="ml-6">
            <a
              href="/router"
              style={{
                width: "53px",
                height: "19px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                alignItems: "center",
                color: "#AEAEAE",
              }}
            >
              Finalizados
            </a>
          </div>
        </div>
      </>
    );
  };

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {        
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };
  const convertToDate = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const changeGroup = (event: any) => {
    setGroupFilter(event.target.value);
    if (event.value === "") {
      setGroups(groupsReload);
    } else {
      const clientFilter = groupsReload.filter(
        (group) => group.cargoGroupId === event.value
      );
      setGroups(clientFilter);
    }
  };

  const goRecruitByGroup = (idGroup: string) => {
    history.push("/reclutarporgrupos/" + idGroup);
  };

  const goToModal = (busqueda: any) => {
    setBusquedaModal(busqueda);    
    setIsModalVisible(true);
  };

  const onBackdropClick = () => {
    setIsModalVisible(false);
  };

  const onBackdropClickSave = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  const closeFullModal = (flag: any) => {
    if (flag == 2) {
      creartToast(
        toast,
        "success",
        "Reasignación",
        "Guardado correctamente",
        3000
      );
    }
    setIsModalVisible(false);
    getGroups();
  };

  //useEffect

  useEffect(() => {
    getToken();
  });

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
    console.log(profileA);
  }, []);

  useEffect(() => {
    getToken();
    if (token) {
      getGroups();
    }
  }, [token]);

  const getGroups = () => {
    api
      .get(`busqueda-group/`, {
        params: { 
          userId: instance.getActiveAccount()?.localAccountId 
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("grupos busqueda",response.data.data);
        setGroups(response.data.data[0]);
        setGroupsReload(response.data.data[0]);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  useEffect(() => {
    let newArr: any[] = [];
    groupsReload.map((group: any) => {
      const newObj = {
        value: group.cargoGroupId,
        label: group.cargoGroupName,
      };
      newArr = [...newArr, newObj];
    });
    const newObj = {
      value: "",
      label: "Todos",
    };
    newArr = [newObj, ...newArr];
    setfilterByGroup(newArr);
  }, [groups]);  

  //main return//
  return (
    <>
      <div className="card">
        <Toolbar right={rightContents} className="mr-3"></Toolbar>

        <p
          className="flex justify-content-center"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#333333",
          }}
        >
          En esta opción puede visualizar los procesos de reclutamiento que le
          han sido asignados.
        </p>

        <p
          className="flex justify-content-start"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#2A63D5",
          }}
        >
          Procesos Activos EST
        </p>     
        
        <div className="flex flex-row mb-5">
          <span className="material-symbols-outlined md-24 mt-1 iconBlue">
            filter_alt
          </span>
          <span className="mx-5 mt-2">Grupo:</span>
          {filterByGroup ? (
            <Dropdown
              value={groupFilter}
              options={filterByGroup}
              onChange={changeGroup}
              optionLabel="label"
              placeholder="Todos"
            />
          ) : (
            ""
          )}
        </div>

        {groups
          ? groups.map((group: any) => {
              return (
                <div className="accordionActiveProcess mt-4 mr-4">
                  <Accordion activeIndex={0}>
                    <AccordionTab
                      header={
                        <div>
                          <span className="colorTextVacantes">
                            BÚSQUEDA DE:{" "}
                            <span className="colorTextVacantesName">
                              {" "}
                              {group.cargoGroupName}
                            </span>
                          </span>
                        </div>
                      }
                    >
                      <div className="flex flex-column">
                        {group ? (
                          group.busquedas.map((busqueda: any) => {
                            return (
                              <div className="flex flex-wrap justify-content-between surface-200 pt-3 border-round my-3">
                                <div className="flex flex-row ml-8">
                                  {busqueda.busquedaUrgencia === 2 ? (
                                    <span className="circuloRed alignIcon"></span>
                                  ) : busqueda.busquedaUrgencia === 1 ? (
                                    <span className="circuloYellow alignIcon"></span>
                                  ) : busqueda.busquedaUrgencia === 0 ? (
                                    <span className="circuloGreen alignIcon"></span>
                                  ) : (
                                    ""
                                  )}

                                  <span className="ml-2">
                                    {busqueda.busquedaId} -{" "}
                                    {busqueda.cargoNombre} -{" "}
                                    {busqueda.clienteNombre}{" "}
                                  </span>
                                </div>

                                <div className="flex flex-row mr-8">
                                  <p className="vacantantesMsg">
                                    {" "}
                                    Q. Vacantes abiertas :{" "}
                                    {busqueda.busquedaCantidad}
                                  </p>
                                  <p className="mx-3 vacantantesMsg">
                                    {" "}
                                    Q. Vacantes cubiertas:{" "}
                                    {busqueda.busquedaCuposRest}
                                  </p>
                                  <p className="vacantantesMsg">
                                    {" "}
                                    Fecha entrega :{" "}
                                    {convertToDate(busqueda.busquedaFin)}
                                  </p>

                                  {profile &&
                                  (profile == "tw_admsel" ||
                                    profile == "adm_tw" || 
                                    profile == "tw_admsel_prof") ? (
                                    <Button
                                      icon="pi pi-pencil"
                                      className="p-button-rounded p-button-success ml-5"
                                      aria-label="User"
                                      onClick={() => goToModal(busqueda)}
                                      tooltip="Reasignar"
                                      tooltipOptions={{ position: "bottom" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="flex-grow-1 flex align-items-center justify-content-center mr-8"
                            style={{
                              backgroundColor: "#D8E6FB",
                              height: "60px",
                            }}
                          >
                            <h3>Esperando Asignaciones</h3>
                          </div>
                        )}

                        <div className="flex align-self-end">
                          <Button
                            className="Todos"
                            style={{ color: "#FFFFFF" }}
                            onClick={() => goRecruitByGroup(group.cargoGroupId)}
                          >
                            <span>
                              <span className="colorTextVacantes">
                                IR A RECLUTAR{" "}
                              </span>
                              <span className="colorTextVacantesName">
                                {group.cargoGroupName}
                              </span>
                              <i className="pi pi-arrow-right ml-4"></i>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              );
            })
          : ""}

        <ProcessRequestModalV2
          token={token}
          isModalVisible={isModalVisible}
          onClose={onBackdropClick}
          onCloseSave={onBackdropClickSave}
          busqueda={busquedaModal}
          closeFullModal={closeFullModal}
        />
        <Toast ref={toast} />
      </div>
    </>
  );
};

export default ActiveProcessV2;
