import React, { useContext, useRef } from "react";
import "../../../assets/css/campaign.min.css";
import IIcondel from "../../ui/kit-svg/icondel";
import IIconDescarga from "../../ui/kit-svg/iconDescarga";
import IIconDescargaSelect from "../../ui/kit-svg/IconDescargaSelect";
import { useEffect, useState } from "react";
import ButtonIcon from "../../ui/kit-mdtw/buttons/ButtonIcon";
import CampaignContext from "../../../context/Campaign/CampaignContext";
import {
  Button,
  Divider,
  Switch,
  SwitchProps,
  colors,
  styled,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IconMas from "../../ui/kit-svg/IconMas";
import FilterCampaign from "../../pages/Campaign/FilterCampaign";
import IconDelCampaigns from "../../ui/kit-svg/IconDelCampaigns";
import IconDescargaMas from "../../ui/kit-svg/IconDescargaMas";
import IconCheckfila from "../../ui/kit-svg/IconCheckfila";
import IconCheckBlank from "../../ui/kit-svg/IconCheckBlank";
import ModalCrear from "./ModalCrear";
import FooterTable from "./FooterTable";
import ModalEliminar from "./ModalEliminar";
import ModalEncuesta from "./ModalEncuesta";
import Logotipo from "../../ui/kit-svg/Logotipo";
import { IOptionDropDownList } from "../../../interfaces/IDropDownList";
import { IListaCampaignFilter } from "../../../interfaces/ICampaign";
import { Toast } from "primereact/toast";
import { formatDateToDDMMAAAA } from "../../.././functions/kit-mdtw/dates";
import { Key } from "@mui/icons-material";
import IconLoading from "../../ui/kit-svg/IconLoading";

interface ICampaignComponent {
  token: string; 
}

const Campaign = (props: ICampaignComponent) => {
  const {token} = props; 
  const {
    campaign,
    getCampaign,
    actYDesactCampaign,
    deleteCampaign,
    encuesta,
    getCampaignActiva,
    toasts,
    reporteria,
    disableReporteria,
    disableRepo,
    isLoading,
  } = useContext(CampaignContext);
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState<number[]>([]);
  const [iconDescargaAction, setIconDescargaAction] = useState<number[]>([]);
  const [openModalCrearCampaign, setOpenModalCrearCampaign] =
    useState<boolean>(false);
  const [creaEncuesta, setcreaEncuesta] = useState<boolean>(false);
  const [modalEliminarEncuesta, setmodalEliminarEncuesta] =
    useState<boolean>(false);
  const [modalEliminarEncuestaAll, setmodalEliminarEncuestaAll] =
    useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<number>(0);
  const [modalEncuesta, setmodalEncuesta] = useState<boolean>(false);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [gridCampaign, setGridCampaign] = useState<IListaCampaignFilter[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (token) {
      getCampaign({token});
    }
  }, [token]);

  useEffect(() => {
    if (campaign && campaign.length > 0) {
      let arrayGridFilterCampaign = [...campaign];
      if (filterChange) {
        const { name, fechaInicio, fechaTermino, tipo } = filterChange;
        if (name.value && name.value.length > 0) {
          const nameArray = name.value.map(
            (values: IOptionDropDownList) => values.code
          );
          arrayGridFilterCampaign = arrayGridFilterCampaign.filter(
            (item: IListaCampaignFilter) => nameArray.includes(item.nombre)
          );
        }
        if (fechaInicio.value && fechaInicio.value.length > 0) {
          const fechaInicioArray = fechaInicio.value.map(
            (values: IOptionDropDownList) => values.code
          );
          arrayGridFilterCampaign = arrayGridFilterCampaign.filter(
            (item: IListaCampaignFilter) =>
              fechaInicioArray.includes(
                formatDateToDDMMAAAA({ date: item.fechaInicio })
              )
          );
        }
        if (fechaTermino.value && fechaTermino.value.length > 0) {
          const fechaTerminoArray = fechaTermino.value.map(
            (values: IOptionDropDownList) => values.code
          );
          arrayGridFilterCampaign = arrayGridFilterCampaign.filter(
            (item: IListaCampaignFilter) =>
              fechaTerminoArray.includes(
                formatDateToDDMMAAAA({ date: item.fechaTermino })
              )
          );
        }
        if (tipo.value && tipo.value.length > 0) {
          const grupoArray = tipo.value.map(
            (values: IOptionDropDownList) => values.code
          );
          arrayGridFilterCampaign = arrayGridFilterCampaign.filter(
            (item: IListaCampaignFilter) => grupoArray.includes(item.tipo)
          );
        }
      }

      setLengthFilters(arrayGridFilterCampaign.length);

      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilterCampaign = arrayGridFilterCampaign.slice(
          initRange,
          endRange
        );
      }
      setGridCampaign(arrayGridFilterCampaign);
    }
  }, [filterChange, campaign, range]);

  const IOSSwitch = styled((props: SwitchProps & { disabled?: boolean }) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#012147",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#61738E" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleClickAllEliminar = () => {
    setmodalEliminarEncuestaAll(true);
  };

  const handleClick = (indice: number) => {
    const index = filasSeleccionadas.indexOf(indice);
    if (index !== -1) {
      setFilasSeleccionadas(
        filasSeleccionadas.filter((item) => item !== indice)
      );
    } else {
      setFilasSeleccionadas([...filasSeleccionadas, indice]);
    }
  };

  const handleClickMasive = () => {
    const arr: React.SetStateAction<number[]> = [];
    filasSeleccionadas.length > 0
      ? setFilasSeleccionadas([])
      : gridCampaign.map((item: any) => {
          if (item.estado != "DESACTIVADA") {
            arr.push(item.campaignId);
          }
        });
    setFilasSeleccionadas(arr);
  };

  const handleChangeEstado = (id: number, value: string) => {
    const campId = campaign.filter((item: any) => item.campaignId === id);

    campId.estado = value === "ACTIVADA" ? "DESACTIVADA" : "ACTIVADA";
    actYDesactCampaign({campaignId: id, estado: campId.estado, token});
  };

  const handleClickModalCrear = () => {
    setOpenModalCrearCampaign(true);
  };

  const eliminaCampaign = (id: number) => {
    deleteCampaign( {campaignId:[id], token});
    setmodalEliminarEncuesta(false);
    setIdDelete(0);
  };

  const eliminaAllCampaign = () => {
    deleteCampaign({campaignId: filasSeleccionadas, token});
    setmodalEliminarEncuestaAll(false);
  };

  const handleClickModalVerEncuesta = () => {
    setmodalEncuesta(true);
  };
  const handleClickReporteriaSend = (id: number) => {
    reporteria({id: [id], token});
    disableRepo(true);
  };

  const handleClickReporteriaMasive = () => {
    reporteria({id: filasSeleccionadas, token});
    disableRepo(true);
    iconDescargaActionMasive(filasSeleccionadas)
  };

  const IconDescargaAction = (id: number) => {
    setIconDescargaAction([id]);
  };
  const iconDescargaActionMasive = (id: number[]) => {
    setIconDescargaAction(id);
  };

  return (
    <>
      {toasts}
      <p className="titulo1">Administrador de campañas</p>
      <p className="explatitulo">
        En esta sección puede crear y administrar encuestas de campaña
      </p>

      <Divider
        variant="middle"
        style={{ borderStyle: "dashed", borderWidth: "1px" }}
      />

      <div className="botonAddCampaign mb-2">
        <Button className="textBoton" onClick={() => handleClickModalCrear()}>
          Crear campaña{" "}
          <Button>
            <IconMas fill="#FAFAFA" />
          </Button>
        </Button>
      </div>

      {/* <div>
        <Button
          className="textBoton"
          onClick={() => handleClickModalVerEncuesta()}
        >
          Ver Encuesta{" "}
        </Button>
      </div> */}

      <div className="sm:col-6 md:col-5 sm:mt-5 masiveIcon ">
        <Button onClick={ handleClickReporteriaMasive} className="pr-0">
          <IconDescargaMas className={"ml-6 mr-4"} />
        </Button>
        <Button onClick={handleClickAllEliminar}>
          <IconDelCampaigns />
        </Button>
      </div>

      <FilterCampaign
        list={campaign}
        setFilterChange={setFilterChange}
      ></FilterCampaign>

      <div className="container-validation-kam">
        <div className="content-validation-kam mt-0">
          <div className="container-table-list">
            <table className="content-table">
              <thead>
                <tr>
                  <th>
                    <ButtonIcon onClick={handleClickMasive}>
                      {filasSeleccionadas.length === campaign?.length ||
                      filasSeleccionadas.length > 0 ? (
                        <IconCheckfila />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </ButtonIcon>
                  </th>
                  <th>Nombre Campaña</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Termino</th>
                  <th>Grupo Selección</th>
                  <th>Activar / desactivar</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {gridCampaign &&
                  gridCampaign?.map((table: any, i: any) => {
                    return (
                      <>
                        <tr
                          key={table.campaignId}
                          className={
                            filasSeleccionadas.includes(table.campaignId)
                              ? "selected"
                              : ""
                          }
                        >
                          <td>
                            <ButtonIcon
                              disabled={
                                table.estado === "DESACTIVADA" ? true : false
                              }
                              onClick={() => handleClick(table.campaignId)}
                            >
                              {filasSeleccionadas.includes(table.campaignId) ? (
                                <IconCheckfila />
                              ) : (
                                <IconCheckBlank
                                  fill={
                                    table.estado === "DESACTIVADA"
                                      ? "#AEAEAE"
                                      : "#262626"
                                  }
                                />
                              )}
                            </ButtonIcon>
                          </td>
                          <td
                            className={
                              table.estado === "DESACTIVADA" ? "inactiva" : ""
                            }
                          >
                            {" "}
                            {table.nombre.toUpperCase()}
                          </td>
                          <td
                            className={
                              table.estado === "DESACTIVADA" ? "inactiva" : ""
                            }
                          >
                            {formatDateToDDMMAAAA({ date: table.fechaInicio })}
                          </td>
                          <td
                            className={
                              table.estado === "DESACTIVADA" ? "inactiva" : ""
                            }
                          >
                            {formatDateToDDMMAAAA({ date: table.fechaTermino })}
                          </td>
                          <td
                            className={
                              table.estado === "DESACTIVADA" ? "inactiva" : ""
                            }
                          >
                            {table.tipo}
                          </td>

                          <td>
                            <ButtonIcon>
                              <IOSSwitch
                                value={table.estado}
                                checked={table.estado === "ACTIVADA"}
                                onChange={(e) =>
                                  handleChangeEstado(
                                    table.campaignId,
                                    e.target.value
                                  )
                                }
                              />
                            </ButtonIcon>
                          </td>
                          <td>
                            <div className="classAction">
                              <ButtonIcon
                                onClick={() => {
                                  handleClickReporteriaSend(table.campaignId);
                                  IconDescargaAction(table.campaignId);
                                }}
                                disabled={
                                  table.estado === "DESACTIVADA" ||
                                  disableReporteria == true
                                    ? true
                                    : false
                                }
                              >
                                {isLoading &&
                                iconDescargaAction.includes(
                                  table.campaignId
                                )  ? (
                                  <div
                                    className="content-loadingSmall"
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      
                                  }}
                                  >
                                    <IconLoading
                                      className="icon-loading"
                                    />
                                  </div>
                                ) : filasSeleccionadas.includes(
                                    table.campaignId
                                  ) && table.estado !== "DESACTIVADA" ? (
                                  <IIconDescargaSelect
                                    fill={
                                      filasSeleccionadas.includes(
                                        table.campaignId
                                      ) && disableReporteria == true
                                        ? "#AEAEAE"
                                        : "#327A30"
                                    }
                                  />
                                ) : (
                                  <IIconDescarga
                                    fill={
                                      table.estado === "DESACTIVADA" ||
                                      (
                                        disableReporteria == true)
                                        ? "#AEAEAE"
                                        : "#327A30"
                                    }
                                  />
                                )}
                              </ButtonIcon>

                              <ButtonIcon
                                disabled={
                                  table.estado !== "ACTIVADA" ? true : false
                                }
                                onClick={() => {
                                  setmodalEliminarEncuesta(true);
                                  setIdDelete(table.campaignId);
                                }}
                              >
                                <IIcondel
                                  fill={
                                    table.estado === "DESACTIVADA"
                                      ? "#AEAEAE"
                                      : "#003D85"
                                  }
                                />
                              </ButtonIcon>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <FooterTable
            length={lengthFilters}
            setRange={setRange}
            pageCurrent={changePage ? 1 : undefined}
          />
          {openModalCrearCampaign && (
            <ModalCrear
              modalStyle="crear"
              title="CREAR ENCUESTA DE CAMPAÑA"
              subTitle="INGRESE LOS DATOS NECESARIOS PARA CREAR UNA ENCUESTA"
              onChangeBack={() => setOpenModalCrearCampaign(false)}
              buttonBack="Cancelar"
              token={token}
              //buttonGo="Crear encuesta"
            />
          )}

          {modalEliminarEncuesta && (
            <ModalEliminar
              modalStyle="crear"
              title="¿Está seguro que desea eliminar la campaña seleccionada?"
              subTitle="Si selecciona eliminar, se borrarán todos los datos de nuestra base de datos. "
              onChangeBack={() => setmodalEliminarEncuesta(false)}
              onChangeGo={() => {
                eliminaCampaign(idDelete);
              }}
              buttonBack="Cancelar"
              //buttonGo="Crear encuesta"
            />
          )}

          {modalEliminarEncuestaAll && (
            <ModalEliminar
              modalStyle="crear"
              title="¿Está seguro que desea eliminar la campaña seleccionada?"
              subTitle="Si selecciona eliminar, se borrarán todos los datos de nuestra base de datos. "
              onChangeBack={() => setmodalEliminarEncuestaAll(false)}
              onChangeGo={eliminaAllCampaign}
              buttonBack="Cancelar"
            />
          )}

          {/* {modalEncuesta && (
            <ModalEncuesta
              modalStyle="confirm"
              img={<Logotipo width={222} height={51} />}
              title="Solicite realizar la siguiente encuesta al candidato"
              subTitle={encuesta && encuesta.nombre}
              onChangeBack={() => setmodalEncuesta(false)}
              //onChangeGo={() => {eliminaCampaign(idDelete)}}
              buttonBack="Cancelar"
              // busPerId=""
              //buttonGo="Crear encuesta"
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default Campaign;
