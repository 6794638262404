interface IIconDescarga {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IIconDescarga) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "13"}`}
        height={`${height ? height : "18"}`}
        viewBox="0 0 13 18"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M2.25 17.2012C1.07793 17.2012 0.125 16.2482 0.125 15.0762V2.32617C0.125 1.1541 1.07793 0.201172 2.25 0.201172H10.75C11.9221 0.201172 12.875 1.1541 12.875 2.32617V11.8887H8.625C8.0373 11.8887 7.5625 12.3635 7.5625 12.9512V17.2012H2.25ZM8.625 17.2012V12.9512H12.875L8.625 17.2012ZM7.29688 9.49805V6.10801L8.32617 7.1373C8.63828 7.44941 9.14297 7.44941 9.45176 7.1373C9.76055 6.8252 9.76387 6.32051 9.45176 6.01172L7.06113 3.62109C6.74902 3.30898 6.24434 3.30898 5.93555 3.62109L3.54492 6.01172C3.23281 6.32383 3.23281 6.82852 3.54492 7.1373C3.85703 7.44609 4.36172 7.44941 4.67051 7.1373L5.6998 6.10801V9.49805C5.6998 9.93965 6.05508 10.2949 6.49668 10.2949C6.93828 10.2949 7.29355 9.93965 7.29355 9.49805H7.29688Z" 
       fill={fill}/>
      </svg>



    );
  };



