export const FILTER_LIST = Object.freeze({
  busqueda: "busqueda",
  all: "all",
  auto: "auto",
  cliente: "cliente",
});

/**
 * TODO: DICCIONARIO PARA TIPO DE LISTADO (SIRVE PARA SABER EN QUE TABLA SE REALIZA LA ACCIÓN) 
 */

export const TYPE_LIST = Object.freeze({
  candidato_asistencia_confirmada: "candidato_asistencia_confirmada", // validacionKam: lista 1 
  candidato_pendiente_validacion: "candidato_pendiente_validacion", // validacionKam: lista 2 
  candidato_pendiente_aprobacion: "candidato_pendiente_aprobacion", // validacion_cliente: lista 1 
  candidato_validados: "candidato_validados", // validacion_cliente: lista 2 

});

export const FILTER_PROFILE = Object.freeze({
  adm_tw: "adm_tw",
  tw_legal: "tw_legal", //TEAMWORK LEGAL
  tw_gen: "tw_gen", //SERVICIOS GENERALES
  tw_admsel_prof: "tw_admsel_prof", //ADMINISTRADOR DE SELECCIÓN PROFESIONAL
  tw_admsel: "tw_admsel", //ADMINISTRADOR DE SELECCIÓN MASIVA
  tw_sel: "tw-sel", //RECLUTADOR
  tw_ops: "tw_ops", //OPERACIONES
  tw_cliente: "tw_cliente",
  admin_sel: "admin_sel", 
  admin_ops: "admin_ops",
  tw_marketing: "tw_marketing",
  tw_contrato: "tw_contrato", 
});

export const LIST_ROLES = Object.freeze({
  KAM: "KAM", 
  ANALISTA_OPS: "ANALISTA_OPS", 
  SUPERVISOR_OPS: "SUPERVISOR_OPS", 
  JEFE_OPS: "JEFE_OPS", 
  JEFE_MARKETING: "JEFE_MARKETING", 
  DESIGNER: "DESIGNER", 
  JEFE_CONTRATO: "JEFE_CONTRATO", 
  ANALISTA_CONTRATO: "ANALISTA_CONTRATO", 
});

export const PROFILE_ADMIN_SEL: string[] = ([
  FILTER_PROFILE.adm_tw, 
  FILTER_PROFILE.admin_sel, 
  FILTER_PROFILE.tw_admsel, 
  FILTER_PROFILE.tw_admsel_prof, 
]);

export const PROFILE_SEL_PROF: string[] = ([
  FILTER_PROFILE.adm_tw, 
  FILTER_PROFILE.admin_sel, 
  FILTER_PROFILE.tw_admsel, 
  FILTER_PROFILE.tw_admsel_prof, 
]);

export const PROFILE_SEL: string[] = ([
  FILTER_PROFILE.adm_tw, 
  FILTER_PROFILE.admin_sel, 
  FILTER_PROFILE.tw_admsel, 
  FILTER_PROFILE.tw_admsel_prof, 
  FILTER_PROFILE.tw_sel, 
]);

export const PROFILE_SEL_MASIVA: string[] = ([
  FILTER_PROFILE.adm_tw, 
  FILTER_PROFILE.admin_sel, 
  FILTER_PROFILE.tw_admsel, 
  FILTER_PROFILE.tw_sel, 
]);

export const PROFILE_VAL_CLIENTE: string[] = ([
  FILTER_PROFILE.tw_cliente,
]);

export const PROFILE_CONTRATO: string[] = ([
  FILTER_PROFILE.tw_contrato,
]);

export const STATE_CANDIDATE = Object.freeze({
  INGRESADO: "INGRESADO", // CANDIDATO INGRESADO EN PROCESO
  CONTACTADO: "CONTACTADO", //	CANDIDATO HA SIDO CONTACTADO
  PORVALIDAR: "PORVALIDAR", //	CANDIDATO EN POR VALIDAR
  VALIDADO: "VALIDADO", //	CANDIDATO VALIDADO
  NOCONFIRMADO: "NOCONFIRMADO", //	CANDIDATO NO CONFIRMADO
  CONFIRMADO: "CONFIRMADO", //	CANDIDATO CONFIRMADO
  ASISTIO: "ASISTIO", //	CANDIDATO ASISTIÓ
  NOASISTIO: "NOASISTIO", //	CANDIDATO NO ASISTIÓ
  RECHAZADO: "RECHAZADO", //	CANDIDATO RECHAZADO
  DESCARTADO: "DESCARTADO", //	CANDIDATO DESCARTADO
  NOINTERESADO: "NOINTERESADO", //	CANDIDATO NO INTERESADO
  VALID_OPS: "VALID_OPS", //	PENDIENTE DE VALIDACIÓN DE OPERACIONES
  PEND_VAL_CLI: "PEND_VAL_CLI", //	PENDIENTE POR VALIDACIÓN DE CLIENTE
  VALID_CLI: "VALID_CLI", //	CANDIDATO VALIDADO POR CLIENTE
  PEND_SOL_CONTR: "PEND_SOL_CONTR", //	PENDIENTE DE SOLICITUD DE CONTRATO
  PEND_AUTO_OBS: "PEND_AUTO_OBS", //	PENDIENTE POR OBSERVACIONES
  PEND_VAL_FPLAZO: "PEND_VAL_FPLAZO", //	PENDIENTE DE VALIDACIÓN FUERA DE PLAZO
  SOL_CONTR: "SOL_CONTR", //	SOLICITUD DE CONTRATO ENVIADA
  SOL_CONTR_RECH: "SOL_CONTR_RECH", //	SOLICITUD DE CONTRATO RECHAZADA
});
