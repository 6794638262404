import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../../assets/css/modal-update-candidate.min.css";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AreaCargoPostula from "./AreaCargoPostula";
import AreaPersonal from "./AreaPersonal";
import AreaLaboral from "./AreaLaboral";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import {
  IDocumentoCandidate,
  IListaRegistroCandidato,
  IModalUpdateCandidate,
} from "../../../../../interfaces/ISeleccion";
import { useParams } from "react-router-dom";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import ModalCerrarModal from "../../../groups/ModalCerrarModal";
import AreaDocumentoMasive from "./AreaDocumentoMasive";
/**
 * TODO: MODIFICAR LOS DATOS Y BORRAR EL RUT POR DEFECTO
 */
export default (props: IModalUpdateCandidate) => {
  const {
    token,
    list,
    title,
    subTitle,
    onChangeBack,
    onChangeGo,
    rut,
    setIsOpenModalUpdateCandidate,
    setResultBusperId,
    getCandidates,
    requerido,
    notSaveInfo,
    isLoadingModal,
  } = props;
  const {
    createCandidate,
    listaRegistroCandidato,
    listaGeneral,
    listGroup,
    listaComprobarEstadoCandidato,
    getProcessAssign,
  } = useContext(SeleccionContext);
  const [formUpDocument, setFormUpDocument] = useState<IDocumentoCandidate[]>([]);
  const [formDocImage, setFormDocImage] = useState<IDocumentoCandidate[]>([]);
  const [messageErrorProcess, setMessageErrorProcess] = useState<
    string | undefined
  >(undefined);
  const [selectGrupo, setSelectGrupo] = useState<IOptionDropDownList[]>([]);
  const { idGrupo } = useParams<any>();
  const [isOpenModalCerrar, setIsOpenModalCerrar] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<
    boolean | undefined
  >(undefined);
  const [formCandidate, setFormCandidate] = useState<any>({
    grupo: { value: undefined, valid: false },
    proceso: { value: undefined, valid: false },
    turno: { value: undefined, valid: false },
    primerNombreCandidato: { value: undefined, valid: false },
    segundoNombreCandidato: { value: undefined, valid: false },
    primerApellidoCandidato: { value: undefined, valid: false },
    segundoApellidoCandidato: { value: undefined, valid: false },
    rutCandidato: { value: rut, valid: true },
    fechaNacimiento: { value: undefined, valid: false },
    edadCandidato: { value: undefined, valid: false },
    fechaVencimientoVisa: { value: undefined, valid: true },

    // datos direccion
    calle: { value: undefined, valid: true },
    nroCalle: { value: undefined, valid: true },
    nroCasa: { value: undefined, valid: true },

    // datos del contacto
    correo: { value: undefined, valid: false },
    fonoPrincipal: { value: undefined, valid: false },
    fonoSecundario: { value: undefined, valid: true },

    // datos del contacto emergencia
    nombreEmergencia: { value: undefined, valid: true },
    parentescoEmergencia: { value: undefined, valid: true },
    fonoEmergencia: { value: undefined, valid: true },

    // cotixzaciones obligatorias
    valorPlan: { value: undefined, valid: true },
    pensionado: { value: undefined, valid: true },
    exentoCotizaciones: { value: undefined, valid: true },

    // pago remuneraciones
    numeroCuenta: { value: undefined, valid: true },
    pagoEnCuenta: { value: undefined, valid: true },
    observacionesPago: { value: undefined, valid: true },

    // Experiencia
    experienciaEnCargo: { value: undefined, valid: true },
    feedback: { value: undefined, valid: true },
    comunaOmil: { value: undefined, valid: true },

    // Uniforme
    polera: { value: undefined, valid: true },
    pantalon: { value: undefined, valid: true },
    zapato: { value: undefined, valid: true },

    //hijos, cargas y referencias
    hijos: { value: [], valid: true },
    cargas: { value: [], valid: true },
    referidos: { value: [], valid: true },
    referencias: { value: [], valid: true },

    // Salud
    enfermedades: { value: undefined, valid: true },
    observacionEnfermedad: { value: undefined, valid: true },
    discapacidad: { value: undefined, valid: true },
    observacionDiscapacidad: { value: undefined, valid: true },
    esquemaCovidCompleto: { value: undefined, valid: true },
    refuerzo: { value: undefined, valid: true },

    // Licencia de conducir
    fechaVigenciaLicenciaConducir: { value: undefined, valid: true },
    fechaRenovacionLicenciaConducir: { value: undefined, valid: true },

    // Examen preocupacional
    tieneExamenPreocupacional: { value: undefined, valid: true },
    tipoExamPreocupacional: { value: undefined, valid: true },
    fechaVigenciaExam: { value: undefined, valid: true },

    /**
     * TODO: listados que no vienen: pensionado, exento de cotizar, pago remuneracio en cuenta.
     */

    afp: { value: undefined, valid: true },
    bancos: { value: undefined, valid: true },
    claseLicenciaConducir: { value: undefined, valid: true },
    comunas: { value: undefined, valid: true },
    disponibilidad: { value: undefined, valid: true },
    estadoCivil: { value: undefined, valid: true },
    estadoVisa: { value: undefined, valid: true },
    fuenteReclutamiento: { value: undefined, valid: true },
    nacionalidad: { value: undefined, valid: true },
    paises: { value: undefined, valid: true },
    parentescoCarga: { value: undefined, valid: true },
    ciudad: { value: undefined, valid: true },
    regiones: { value: undefined, valid: true },
    sistemaSaludId: { value: undefined, valid: true },
    sexo: { value: undefined, valid: true },
    tipoLicenciaConducir: { value: undefined, valid: true },
    tipoVisa: { value: undefined, valid: true },
    tipoVivienda: { value: undefined, valid: true },
    tiposCuentaBanco: { value: undefined, valid: true },
    motivoDevoluacion: { value: undefined, valid: true },
  });

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    if (formCandidate.proceso.value?.code && rut) {
      getProcessAssign({
        token,
        personaDNI: rut,
        procesoId: formCandidate.proceso.value?.code ?? "",
        setMessageError: setMessageErrorProcess,
      });
    }
  }, [formCandidate.proceso.value]);

  useEffect(() => {
    if (
      listaRegistroCandidato.length !== 0 &&
      Object.keys(listaGeneral).length > 0
    ) {
      const { personal, laboral }: IListaRegistroCandidato =
        listaRegistroCandidato;
      const {
        candidato,
        direccion,
        contacto,
        hijos,
        cargas,
        contactoEmergencia,
        cotizaciones,
        pagos,
      } = personal;
      const {
        personaId,
        DNI,
        primerNombre,
        segundoNombre,
        primerApellido,
        segundoApellido,
        fechaNacimiento,
        estadoCivilId,
        sexoId,
        nacionalidadId,
        tipoVisaId,
        estadoVisaId,
        fechaVencimientoVisa,
      } = candidato;
      const {
        calle,
        numeroPrincipal,
        tipoViviendaId,
        numeroVivienda,
        comunaId,
        paisId,
      } = direccion;
      const { correo, fonoPrincipal, fonoSecundario } = contacto;
      const { nombreCompleto, fono, parentescoId } = contactoEmergencia;
      const {
        sistemaSaludId,
        valorPlan,
        afpId,
        pensionado,
        exentoCotizaciones,
      } = cotizaciones;
      const {
        bancoId,
        tipoCuentaId,
        numeroCuenta,
        pagoEnCuenta,
        observacionesPago,
      } = pagos;
      const {
        procesoId,
        experienciaEnCargo,
        feedback,
        disponibilidadId,
        fuenteReclutamientoId,
        comunaOmilId,
        referidos,
        referencias,
        uniforme,
        salud,
        licenciaConducir,
        examenPreocupacional,
        turno,
        motivoDevoluacion,
      } = laboral;
      const { tallaPoleraId, tallaPantalonId, tallaZapatoId } = uniforme;
      const {
        enfermedades,
        observacionEnfermedad,
        discapacidad,
        observacionDiscapacidad,
        vacunas,
      } = salud;
      const { esquemaCovidCompleto, refuerzo } = vacunas;
      const { claseLicenciaId, fechaRenovacion, fechaVigencia } =
        licenciaConducir;
      const {
        tieneExamenPreocupacional,
        tipoExamPreocupacional,
        fechaVigenciaExam,
      } = examenPreocupacional;
      const { gentilicio } = listaGeneral.paises.find(
        (item: any) => nacionalidadId == item.paisId
      ) ?? { gentilicio: undefined };
      const { estadoCivil } = listaGeneral.estadoCivil.find(
        (item: any) => estadoCivilId == item.estadoCivilId
      ) ?? { estadoCivil: undefined };
      const { nombre: nombreSexo } = listaGeneral.sexo.find(
        (item: any) => sexoId == item.sexoId
      ) ?? { nombre: undefined };
      const { nombre: nombreTipoVisa } = listaGeneral.tipoVisa.find(
        (item: any) => tipoVisaId == item.tipoVisaId
      ) ?? { nombre: undefined };
      const { nombre: nombreEstadoVisa } = listaGeneral.estadoVisa.find(
        (item: any) => estadoVisaId == item.estadoVisaId
      ) ?? { nombre: undefined };
      const { nombre: nombreTipoVivienda } = listaGeneral.tipoVivienda.find(
        (item: any) => tipoViviendaId == item.tipoViviendaId
      ) ?? { nombre: undefined };
      const { comunaNombre, provinciaId } = listaGeneral.comunas.find(
        (item: any) => comunaId == item.comunaId
      ) ?? { nombre: undefined };
      const { nombre: nombreProvincia, regionId } =
        listaGeneral.provincias.find(
          (item: any) => provinciaId == item.provinciaId
        ) ?? { nombre: undefined };
      const { paisId: pais } = listaGeneral.regiones.find(
        (item: any) => regionId == item.regionId
      ) ?? { nombre: undefined };
      const { nombre: nombrePais } = listaGeneral.paises.find(
        (item: any) => pais == item.paisId
      ) ?? { nombre: undefined };
      const { nombre: nombreAfp } = listaGeneral.afp.find(
        (item: any) => afpId == item.afpId
      ) ?? { nombre: undefined };
      const { nombre: nombreSalud } = listaGeneral.salud.find(
        (item: any) => sistemaSaludId == item.saludId
      ) ?? { nombre: undefined };
      const { nombre: nombreBanco } = listaGeneral.bancos.find(
        (item: any) => bancoId == item.bancoId
      ) ?? { nombre: undefined };
      const { nombre: nombreTipoCuenta } = listaGeneral.tiposCuentaBanco.find(
        (item: any) => tipoCuentaId == item.tipoCuentaBancoId
      ) ?? { nombre: undefined };
      const { nombre: nombreDisponibilidad } = listaGeneral.disponibilidad.find(
        (item: any) => disponibilidadId == item.disponibilidadId
      ) ?? { nombre: undefined };
      const { nombre: nombreFuenteRecluta } =
        listaGeneral.fuenteReclutamiento.find(
          (item: any) => fuenteReclutamientoId == item.fuenteReclutamientoId
        ) ?? { nombre: undefined };
      const { tallaNombre: nombreZapato } =
        listaGeneral.tallas.alfanumerica.find(
          (item: any) => tallaZapatoId == item.tallaId
        ) ?? { tallaNombre: undefined };
      const { tallaNombre: nombrePantalon } = listaGeneral.tallas.numerica.find(
        (item: any) => tallaPantalonId == item.tallaId
      ) ?? { tallaNombre: undefined };
      const { tallaNombre: nombrePolera } = listaGeneral.tallas.numerica.find(
        (item: any) => tallaPoleraId == item.tallaId
      ) ?? { tallaNombre: undefined };

      const { nombre: nombreTipoClase, tipoLicenciaId } =
        listaGeneral.claseLicenciaConducir.find(
          (item: any) => claseLicenciaId == item.claseLicenciaId
        ) ?? { nombre: undefined };
      const { nombre: nombreTipoLicencia } =
        listaGeneral.tipoLicenciaConducir.find(
          (item: any) => tipoLicenciaId == item.tipoLicenciaConducirId
        ) ?? { nombre: undefined };
      const { nombre: nobreParentescoEmergencia } =
        listaGeneral.parentesco.find(
          (item: any) => parentescoId == item.parentescoId
        ) ?? { nombre: undefined };

      const valorBoolean = (valor: boolean) => {
        if (valor) {
          return { code: "true", name: "SI" };
        } else {
          return { code: "false", name: "NO" };
        }
      };

      const [listadoGrupo] = listGroup;
      const { busquedas, cargoGroupName, cargoGroupId } = listadoGrupo;
      setSelectGrupo([{ code: cargoGroupId, name: cargoGroupName }]);

      const listadoProceso = busquedas
        .filter((list: any) => list.busquedaId == procesoId)
        .map((item: any) => {
          return {
            code: item.busquedaId,
            name: `${item.empresa}${item.busquedaId} - ${item.cargoMod}`,
          };
        });
      const objetoListoProceso = listadoProceso[0];

      const buscarCargoGroupIdPorBusquedaId = (procesoId: any) => {
        for (const cargo of listGroup) {
          for (const busqueda of cargo.busquedas) {
            if (busqueda.busquedaId === procesoId) {
              return {
                code: cargo.cargoGroupId,
                name: cargo.cargoGroupName,
              };
            }
          }
        }
        return null; // Si no se encuentra el busquedaId
      };

      const buscarProcesoPorGrupo = (procesoId: any) => {
        for (const cargo of listGroup) {
          for (const busqueda of cargo.busquedas) {
            if (busqueda.busquedaId === procesoId) {
              return {
                code: busqueda.busquedaId,
                name: busqueda.busquedaId + " - " + busqueda.cargoMod,
              };
            }
          }
        }
        return null; // Si no se encuentra el busquedaId
      };

      setFormCandidate({
        grupo: {
          value: buscarCargoGroupIdPorBusquedaId(procesoId) ?? undefined,
          valid: false,
        },
        proceso: {
          value: buscarProcesoPorGrupo(procesoId) ?? undefined,
          valid: false,
        },
        turno: { value: turno, valid: false },
        primerNombreCandidato: { value: primerNombre, valid: false },
        segundoNombreCandidato: { value: segundoNombre, valid: false },
        primerApellidoCandidato: { value: primerApellido, valid: false },
        segundoApellidoCandidato: { value: segundoApellido, valid: false },
        rutCandidato: { value: DNI, valid: true },
        personaId: { value: personaId, valid: true },
        fechaNacimiento: {
          value: formatDateToDDMMAAAA({ date: fechaNacimiento, format: "/" }),
          valid: false,
        },
        edadCandidato: { value: undefined, valid: false },
        fechaVencimientoVisa: {
          value: formatDateToDDMMAAAA({
            date: fechaVencimientoVisa,
            format: "/",
          }),
          valid: false,
        },

        // datos direccion
        calle: { value: calle, valid: true },
        nroCalle: { value: numeroPrincipal, valid: true },
        nroCasa: { value: numeroVivienda, valid: true },

        // datos del contacto
        correo: { value: correo, valid: false },
        fonoPrincipal: { value: fonoPrincipal, valid: false },
        fonoSecundario: { value: fonoSecundario, valid: true },

        // datos del contacto emergencia
        nombreEmergencia: { value: nombreCompleto, valid: true },
        parentescoEmergencia: {
          value: { code: parentescoId, name: nobreParentescoEmergencia },
          valid: true,
        },
        fonoEmergencia: { value: fono, valid: true },

        // cotixzaciones obligatorias
        valorPlan: { value: valorPlan, valid: true },
        pensionado: { value: valorBoolean(pensionado), valid: true },
        exentoCotizaciones: {
          value: valorBoolean(exentoCotizaciones),
          valid: true,
        },

        // pago remuneraciones
        numeroCuenta: { value: numeroCuenta, valid: true },
        pagoEnCuenta: { value: valorBoolean(pagoEnCuenta), valid: true },
        observacionesPago: { value: observacionesPago, valid: true },

        // Experiencia
        experienciaEnCargo: { value: experienciaEnCargo, valid: true },
        feedback: { value: feedback, valid: true },
        comunaOmil: { value: comunaOmilId, valid: true },
        motivoDevoluacion: { value: motivoDevoluacion, valid: true },

        // Uniforme
        polera: {
          value: { code: tallaPoleraId, name: nombrePolera },
          valid: true,
        },
        pantalon: {
          value: { code: tallaPantalonId, name: nombrePantalon },
          valid: true,
        },
        zapato: {
          value: { code: tallaZapatoId, name: nombreZapato },
          valid: true,
        },

        //hijos y cargas
        hijos: {
          value: hijos
            .sort((a, b) => a.posicion - b.posicion)
            .map((item: any) => item.edad),
          valid: true,
        },
        cargas: {
          value: cargas
            .sort((a, b) => a.posicion - b.posicion)
            .map((item: any) => {
              const { parentescoId, nombre } = listaGeneral.parentesco.find(
                (list: any) => list.parentescoId === item.parentescoId
              ) ?? { parentescoId: undefined, nombre: undefined };
              const currentParentesco: IOptionDropDownList = Object.assign(
                { name: undefined, code: undefined },
                { code: parentescoId, name: nombre }
              );

              return {
                DNI: { value: item.DNI, valid: true },
                nombres: { value: item.nombres, valid: true },
                primerApellido: { value: item.primerApellido, valid: true },
                segundoApellido: { value: item.segundoApellido, valid: true },
                parentescoId: {
                  value: currentParentesco,
                  valid: true,
                },
              };
            }),
          valid: true,
        },

        referidos: {
          value: referidos
            .sort((a, b) => a.posicion - b.posicion)
            .map((item: any) => {
              return {
                nombreCompleto: { value: item.nombreCompleto, valid: true },
                fono: { value: item.fono, valid: true },
              };
            }),
          valid: true,
        },

        referencias: {
          value: referencias
            .sort((a, b) => a.posicion - b.posicion)
            .map((item: any) => {
              return {
                nombreCompleto: { value: item.nombreCompleto, valid: true },
                cargo: { value: item.cargo, valid: true },
                empresa: { value: item.empresa, valid: true },
                fono: { value: item.fono, valid: true },
              };
            }),
          valid: true,
        },

        // Salud
        enfermedades: { value: valorBoolean(enfermedades), valid: true },
        observacionEnfermedad: { value: observacionEnfermedad, valid: true },
        discapacidad: { value: valorBoolean(discapacidad), valid: true },
        observacionDiscapacidad: {
          value: observacionDiscapacidad,
          valid: true,
        },
        esquemaCovidCompleto: {
          value: valorBoolean(esquemaCovidCompleto),
          valid: true,
        },
        refuerzo: { value: valorBoolean(refuerzo), valid: true },

        // Licencia de conducir
        fechaVigenciaLicenciaConducir: {
          value: formatDateToDDMMAAAA({ date: fechaVigencia, format: "/" }),
          valid: true,
        },
        fechaRenovacionLicenciaConducir: {
          value: formatDateToDDMMAAAA({ date: fechaRenovacion, format: "/" }),
          valid: true,
        },

        // Examen preocupacional
        tieneExamenPreocupacional: {
          value: valorBoolean(tieneExamenPreocupacional),
          valid: true,
        },
        tipoExamPreocupacional: { value: tipoExamPreocupacional, valid: true },
        fechaVigenciaExam: {
          value: formatDateToDDMMAAAA({ date: fechaVigenciaExam, format: "/" }),
          valid: true,
        },

        afp: { value: { code: afpId, name: nombreAfp }, valid: true },
        bancos: { value: { code: bancoId, name: nombreBanco }, valid: true },
        claseLicenciaConducir: {
          value: { code: claseLicenciaId, name: nombreTipoClase },
          valid: true,
        },
        comunas: {
          value: comunaId
            ? { code: String(comunaId), name: comunaNombre }
            : undefined,
          valid: true,
        },
        disponibilidad: {
          value: { code: disponibilidadId, name: nombreDisponibilidad },
          valid: true,
        },
        estadoCivil: {
          value: { code: estadoCivilId, name: estadoCivil },
          valid: true,
        },
        estadoVisa: {
          value: { code: estadoVisaId, name: nombreEstadoVisa },
          valid: true,
        },
        fuenteReclutamiento: {
          value: { code: fuenteReclutamientoId, name: nombreFuenteRecluta },
          valid: true,
        },
        nacionalidad: {
          value: nacionalidadId
            ? { code: String(nacionalidadId), name: gentilicio }
            : undefined,
          valid: true,
        },
        paises: {
          value: pais ? { code: String(pais), name: nombrePais } : undefined,
          valid: true,
        },
        parentescoCarga: { value: undefined, valid: true },
        ciudad: {
          value: provinciaId
            ? { code: String(provinciaId), name: nombreProvincia }
            : undefined,
          valid: true,
        },
        regiones: { value: undefined, valid: true },
        sistemaSaludId: {
          value: { code: sistemaSaludId, name: nombreSalud },
          valid: true,
        },
        sexo: { value: { code: sexoId, name: nombreSexo }, valid: true },
        tipoLicenciaConducir: {
          value: { code: tipoLicenciaId, name: nombreTipoLicencia },
          valid: true,
        },
        tipoVisa: {
          value: { code: tipoVisaId, name: nombreTipoVisa },
          valid: true,
        },
        tipoVivienda: {
          value: { code: tipoViviendaId, name: nombreTipoVivienda },
          valid: true,
        },
        tiposCuentaBanco: {
          value: { code: tipoCuentaId, name: nombreTipoCuenta },
          valid: true,
        },
      });
    }
  }, [listaRegistroCandidato]);

  const handleClickCreateCandidate = async (values: any) => {
    const { formCandidate } = values;
    try {
      if (token) {
        await createCandidate({
          formCandidate,
          setIsOpenModalUpdateCandidate,
          idGrupo,
          setResultBusperId,
          formDocImage, 
          formUpDocument,
          setFormUpDocument, 
          setIsLoading,
          token,
          setIsLoadingDocument,
        });
        onChangeGo && (await onChangeGo());
        getCandidates && (await getCandidates());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCerrarModal = () => {
    if (onChangeBack) {
      setIsOpenModalCerrar(true);
    }
  };

  const handleClickCerrarModalSugerencia = () => {
    if (setIsOpenModalUpdateCandidate) {
      const body = document.querySelector("body");
      if (body) {
        body.style.overflowY = "auto";
      }
      setIsOpenModalCerrar(false);
      setIsOpenModalUpdateCandidate(false);
    }
  };

  return (
    <>
      <div
        className="modal-container-updateCandidate"
        id="ModalReclutarCandidato"
        style={
          setIsOpenModalUpdateCandidate
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <div className="modal-content-updateCandidate w-80">
          <span
            className="modal-close"
            onClick={() => handleClickCerrarModal()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title modal-new-candidate">
            <span>{title}</span>
          </div>
          {isLoadingModal ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>Cargando datos...</h3>
              </div>
            </>
          ) : (
            <>
              {Object.keys(listaGeneral).length > 0 && (
                <>
                  <div className="modal-subtitle">
                    <span>{subTitle}</span>
                  </div>
                  {formCandidate.motivoDevoluacion?.value && (
                    <>
                      <div className="modal-rechazado">
                        <NotificationsNoneIcon />
                        <label>
                          El registro de candidato fue devuelto desde
                          operaciones para corrección con el motivo:
                        </label>
                        <label>
                          {formCandidate.motivoDevoluacion?.value ?? ""}
                        </label>
                      </div>
                    </>
                  )}

                  <AreaCargoPostula
                    hiddenSection={false}
                    formCandidate={formCandidate}
                    setFormCandidate={setFormCandidate}
                    requerido={requerido}
                    messageErrorProcess={messageErrorProcess}
                  />
                  <AreaPersonal
                    hiddenSection={false}
                    list={list}
                    rut={rut}
                    formCandidate={formCandidate}
                    setFormCandidate={setFormCandidate}
                    requerido={requerido}
                  />
                  <AreaLaboral
                    hiddenSection={true}
                    formCandidate={formCandidate}
                    setFormCandidate={setFormCandidate}
                    requerido={requerido}
                  />
                  <AreaDocumentoMasive
                    hiddenSection={true}
                    formUpDocument={formUpDocument}
                    setFormUpDocument={setFormUpDocument}
                    formCandidate={formCandidate}
                    requerido={requerido}
                    isLoading={isLoading}
                    formDocImage={formDocImage}
                    setFormDocImage={setFormDocImage}
                  />

                  <div className="modal-button-content">
                    <button
                      className={`modal-button cancel`}
                      disabled={isLoading}
                      onClick={() => handleClickCerrarModal()}
                    >
                      Cerrar
                    </button>

                    {!notSaveInfo && (
                      <>
                        <button
                          className={`modal-button confirm`}
                          // onClick={handleClickAvanzar}
                          disabled={
                            isLoading ||
                            formCandidate.primerNombreCandidato.value ==
                              undefined ||
                            formCandidate.primerApellidoCandidato.value ==
                              undefined ||
                            formCandidate.fechaNacimiento.value == undefined ||
                            formCandidate.correo.value == undefined ||
                            formCandidate.fonoPrincipal.value == undefined
                          }
                          onClick={() =>
                            handleClickCreateCandidate({ formCandidate })
                          }
                        >
                          {isLoading ? "Guardando..." : "Guardar Datos"}
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isOpenModalCerrar && (
        <ModalCerrarModal
          onChangeBack={() => setIsOpenModalCerrar(false)}
          onChangeGo={() => handleClickCerrarModalSugerencia()}
          textButtonBack={"Cancelar"}
          textButtonGo={"Salir"}
        />
      )}
    </>
  );
};
