import { ReactNode, useState } from "react";
import "../../../../assets/css/tooltip-tw.min.css";
import IconCirculoEstado from "../../icons-seleccion/IconCirculoEstado";

interface ITooltip {
  title: string;
  page: "masiva" | "profesional";
  children: ReactNode;
}

const TooltipMessage = ({ title, page, children }: ITooltip) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className="tooltip-white">
          {/* <div className="arrow-white" /> */}
          {title}
          {page == "masiva" && (
            <section className="content-tooltip">
              <button className="button-content">
                <IconCirculoEstado fill="#06A502" />
              </button>
              <label>Completo</label>
              <button className="button-content">
                <IconCirculoEstado fill="#FF4900" />
              </button>
              <label>Incompleto</label>
            </section>
          )}
          {page == "profesional" && (
            <section className="content-tooltip">
              <button className="button-content">
                <IconCirculoEstado fill="#06A502" />
              </button>
              <label>Completo</label>
              <button className="button-content">
                <IconCirculoEstado fill="#FF4900" />
              </button>
              <label>Incompleto</label>
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default TooltipMessage;
