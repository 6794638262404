interface IIconPsicolaboral {
    width?: string;
    height?: string;
    fill: string;
    disabled?: boolean;
    className?: string;
  }
  
  export default (props: IIconPsicolaboral) => {
    const { disabled, fill, className } = props;
  
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 16 20"
        fill={disabled ? "#aeaeae" : fill}
        className={className}
      >
        <path
         
          d="M10.7499 2.62H9.49988C9.06988 1.38 7.87988 0.5 6.49988 0.5C5.11988 0.5 3.93988 1.39 3.49988 2.62H2.24988C1.07988 2.62 0.129883 3.58 0.129883 4.75V15.38C0.129883 16.55 1.07988 17.5 2.24988 17.5H10.7499C11.9199 17.5 12.8699 16.55 12.8699 15.38V4.75C12.8699 3.58 11.9199 2.63 10.7499 2.63V2.62ZM5.74988 2.93C5.94988 2.73 6.21988 2.62 6.49988 2.62C6.77988 2.62 7.04988 2.73 7.24988 2.93C7.44988 3.13 7.55988 3.4 7.55988 3.68C7.55988 3.96 7.44988 4.23 7.24988 4.43C7.04988 4.63 6.77988 4.74 6.49988 4.74C6.21988 4.74 5.94988 4.63 5.74988 4.43C5.54988 4.23 5.43988 3.96 5.43988 3.68C5.43988 3.4 5.54988 3.13 5.74988 2.93ZM10.7399 11.31C10.5899 11.77 10.3399 12.18 9.99988 12.55C9.65988 12.91 9.19988 13.2 8.62988 13.41C8.27988 13.54 7.85988 13.62 7.39988 13.67V16.55H5.57988V13.66C5.05988 13.6 4.58988 13.49 4.15988 13.33C3.48988 13.07 2.96988 12.66 2.59988 12.1C2.22988 11.54 2.04988 10.82 2.04988 9.94V6.67H3.88988V9.96C3.88988 10.52 3.99988 10.97 4.21988 11.3C4.43988 11.63 4.74988 11.86 5.12988 12C5.26988 12.05 5.42988 12.08 5.58988 12.11V6.67H7.40988V12.12C7.64988 12.07 7.85988 12 8.03988 11.91C8.43988 11.71 8.71988 11.43 8.89988 11.08C9.07988 10.73 9.16988 10.32 9.16988 9.86C9.16988 9.61 9.13988 9.35 9.10988 9.08C9.07988 8.81 9.02988 8.53 8.96988 8.25C8.90988 7.97 8.83988 7.7 8.74988 7.43C8.66988 7.16 8.57988 6.91 8.47988 6.67H10.1599C10.2699 6.85 10.3799 7.05 10.4799 7.29C10.5799 7.52 10.6699 7.78 10.7499 8.06C10.8299 8.34 10.8899 8.63 10.9299 8.93C10.9799 9.23 10.9999 9.54 10.9999 9.86C10.9999 10.37 10.9199 10.85 10.7699 11.31H10.7399Z" />
        
      </svg>
    );
  };
 