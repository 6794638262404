import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M8.5 0.5C3.80444 0.5 0 4.30444 0 9C0 13.6956 3.80444 17.5 8.5 17.5C13.1956 17.5 17 13.6956 17 9C17 4.30444 13.1956 0.5 8.5 0.5ZM11.7903 11.7419C11.7903 12.0435 11.5435 12.2903 11.2419 12.2903H5.75806C5.45645 12.2903 5.20968 12.0435 5.20968 11.7419V6.25806C5.20968 5.95645 5.45645 5.70968 5.75806 5.70968H11.2419C11.5435 5.70968 11.7903 5.95645 11.7903 6.25806V11.7419Z"
      />
    </svg>
  );
};
