interface IIconRegistroCandidato {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconRegistroCandidato) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 16"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <g id="Vector">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.92587 5.80875C8.2007 5.50339 8.67103 5.47863 8.97639 5.75346L12.5 8.92471L9.00477 12.4199C8.71427 12.7104 8.24329 12.7104 7.95279 12.4199C7.6623 12.1294 7.6623 11.6585 7.95279 11.368L9.62378 9.69698H5.24386C4.83304 9.69698 4.5 9.36394 4.5 8.95312C4.5 8.5423 4.83304 8.20926 5.24386 8.20926H9.48115L7.98116 6.85927C7.6758 6.58445 7.65105 6.11411 7.92587 5.80875Z"
        />
        <path
          d="M0.5 2.6875C0.5 1.51543 1.45293 0.5625 2.625 0.5625H7.01113C7.64531 0.5625 8.25293 0.814844 8.70117 1.26309L10.1256 2.6875H15.375C16.5471 2.6875 17.5 3.64043 17.5 4.8125V13.3125C17.5 14.4846 16.5471 15.4375 15.375 15.4375H2.625C1.45293 15.4375 0.5 14.4846 0.5 13.3125V2.6875ZM2.625 2.15625C2.33281 2.15625 2.09375 2.39531 2.09375 2.6875V13.3125C2.09375 13.6047 2.33281 13.8438 2.625 13.8438H15.375C15.6672 13.8438 15.9062 13.6047 15.9062 13.3125V4.8125C15.9062 4.52031 15.6672 4.28125 15.375 4.28125H10.016C9.66406 4.28125 9.32539 4.1418 9.07637 3.89277L7.57559 2.38867C7.42617 2.23926 7.22363 2.15625 7.01113 2.15625H2.625Z"
        />
      </g>
    </svg>
  );
};
