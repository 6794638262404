import { IReducer } from "../../interfaces/ICampaign";

const reduxState: any = {
  setcampaign: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, campaign: payload };
  },
  Settypes: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, types: payload };
  },
  Setencuesta: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, encuesta: payload };
  }
  
}



export default (state: any, action: any) => {
    const { type, payload } = action;
    return reduxState[type]({ payload, state }) ?? undefined;
  };