import { useEffect, useState } from "react";
import "../../../assets/css/menu-tw.min.css";
import { NavLink } from "react-router-dom";

interface IModalOption {
  itemMenu: string | undefined;
  setItemMenu: Function | undefined;
  selectionType: string;
  setItemMenuFilter?: Function;
}

const ModalOption = (props: IModalOption) => {
  const { itemMenu, setItemMenu, selectionType, setItemMenuFilter } = props;
  const [itemSelection, setItemSelection] = useState<string>("itemSelection");

  const handleClickGoRuta = (values: any) => {
    const { ruta, page, itemMenuGo } = values;
    const rutaActual = (window.location.href = `/${ruta}/${page}`);
    if (setItemMenu) {
      setItemMenu(itemMenuGo);
    }
    localStorage.setItem(itemSelection, JSON.stringify(selectionType));
  };

  useEffect(() => {
    if (!localStorage.getItem(itemSelection)) {
      localStorage.setItem(
        itemSelection,
        JSON.stringify(
          selectionType === "profesional" ? selectionType : "masivo"
        )
      );
    }
  }, []);

  return (
    <div className="modal-option">
      {["masivo"].includes(selectionType) && (
        <>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "procesos",
                page: "activos".toLowerCase(),
                itemMenuGo: "procesos",
              });
            }}
            className={`option ${itemMenu === "procesos" ? "active" : ""}`}
          >
            Procesos
          </button>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "registros",
                page: "habilitados".toLowerCase(),
                itemMenuGo: "registros",
              });
            }}
            className={`option ${itemMenu === "registros" ? "active" : ""}`}
          >
            Registros
          </button>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "seleccionmasiva",
                page: "porvalidar".toLowerCase(),
                itemMenuGo: "seleccionmasiva",
              });
            }}
            className={`option ${itemMenu === "seleccion" ? "active" : ""}`}
          >
            Selección
          </button>
        </>
      )}

      {["profesional"].includes(selectionType) && (
        <>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "procesos",
                page: "activos".toLowerCase(),
                itemMenuGo: "procesos",
              });
            }}
            className={`option ${itemMenu === "procesos" ? "active" : ""}`}
          >
            Procesos
          </button>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "registros",
                page: "habilitados".toLowerCase(),
                itemMenuGo: "registros",
              });
            }}
            className={`option ${itemMenu === "registros" ? "active" : ""}`}
          >
            Registros
          </button>
          <button
            onClick={() => {
              handleClickGoRuta({
                ruta: "seleccionprofesional",
                page: "porvalidar".toLowerCase(),
                itemMenuGo: "seleccionprofesional",
              });
            }}
            className={`option ${itemMenu === "seleccion" ? "active" : ""}`}
          >
            Selección
          </button>
        </>
      )}
    </div>
  );
};

export default ModalOption;