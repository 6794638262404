import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M9.24139 8.00333H7.275H6.75806L6.77528 8.51999L7.04361 16.57L7.05972 17.0533H7.54333H9.45667H9.94028L9.95639 16.57L10.2247 8.51999L9.73667 8.50372V8.00333H9.725H9.24139ZM9.78689 20.2552L9.93333 20.1088V20.0776C10.168 19.7746 10.2833 19.4132 10.2833 19.015C10.2833 18.5413 10.1201 18.1197 9.78689 17.7864L9.787 17.7863L9.77771 17.7775C9.43625 17.4531 8.99055 17.3017 8.5 17.3017C8.00867 17.3017 7.55729 17.4539 7.21311 17.7981C6.88161 18.1296 6.71667 18.5387 6.71667 19.0267C6.71667 19.5146 6.88161 19.9237 7.21311 20.2552L7.22195 20.2641L7.23121 20.2724C7.57602 20.5844 8.00207 20.74 8.5 20.74C8.97819 20.74 9.43517 20.6069 9.78689 20.2552ZM15.7414 5.18143C15.7625 5.20126 15.7818 5.22437 15.7988 5.25H11.625V1.07207C11.6574 1.09232 11.688 1.11674 11.7164 1.14522L15.5833 5.01211V5.03328L15.7414 5.18143ZM9.31833 1V6.07667C9.31833 6.88948 9.98552 7.55667 10.7983 7.55667H15.875V20.52C15.875 20.7844 15.6633 21 15.395 21H1.605C1.34059 21 1.125 20.7883 1.125 20.52V1.48C1.125 1.21948 1.34448 1 1.605 1H9.31833Z"
        fill="#BE2A2A"
        stroke="#BE2A2A"
      />
    </svg>
  );
};
