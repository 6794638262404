import React, { useEffect, useState } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import RecruitByGroupTableComponent from "../ui/V2/RecruitByGroupTableComponent";
import ReclutarPorGrupos from "../../containers/ReclutarPorGrupos";
import VacantesState from "../../context/vacantes/VacantesState";
import SeleccionState from "../../context/Seleccion/SeleccionState";
import CampaignState from "../../context/Campaign/CampaignState";

const RecruitByGroupPages: React.FC<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);
  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };
  const authRequest = {
    ...loginRequest,
  };

  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {/* <div className={wrapperClass} onClick={onWrapperClick}> */}

        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
          />
        ) : (
          ""
        )}
        {/* <VacantesState>
          <ReclutarPorGrupos />
        </VacantesState> */}
        {/**
         * TODO: SE INTEGRA CONTEXTO GLOBAL DE SELECCION PARA USOO EN MODAL NUEVO CANDIDATO
         */}
        <SeleccionState>
          <CampaignState>
            <RecruitByGroupTableComponent />
          </CampaignState>
        </SeleccionState>
        {/* <RecruitByGroupTableComponent /> */}
      </MsalAuthenticationTemplate>
    </>
  );
};

export default RecruitByGroupPages;
