import "../../../assets/css/loading.min.css"; 
interface IIconCargando {
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconCargando) => {
  const { fill, disabled } = props;

  return (
    <svg
      className="svg-loading"
      viewBox="0 0 27 38"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="autorenew"
        d="M1.85625 25.3969C1.2375 24.3281 0.773438 23.2313 0.464063 22.1063C0.154688 20.9813 0 19.8281 0 18.6469C0 14.8781 1.30781 11.6719 3.92344 9.02813C6.53906 6.38438 9.73125 5.0625 13.5 5.0625H13.7953L11.0953 2.3625L13.4578 0L20.2078 6.75L13.4578 13.5L11.0953 11.1375L13.7953 8.4375H13.5C10.6875 8.4375 8.29688 9.42863 6.32812 11.4109C4.35938 13.3943 3.375 15.8063 3.375 18.6469C3.375 19.3781 3.45938 20.0953 3.62812 20.7984C3.79688 21.5016 4.05 22.1906 4.3875 22.8656L1.85625 25.3969ZM13.5422 37.125L6.79219 30.375L13.5422 23.625L15.9047 25.9875L13.2047 28.6875H13.5C16.3125 28.6875 18.7031 27.6964 20.6719 25.7141C22.6406 23.7308 23.625 21.3188 23.625 18.4781C23.625 17.7469 23.5406 17.0297 23.3719 16.3266C23.2031 15.6234 22.95 14.9344 22.6125 14.2594L25.1437 11.7281C25.7625 12.7969 26.2266 13.8938 26.5359 15.0188C26.8453 16.1438 27 17.2969 27 18.4781C27 22.2469 25.6922 25.4531 23.0766 28.0969C20.4609 30.7406 17.2687 32.0625 13.5 32.0625H13.2047L15.9047 34.7625L13.5422 37.125Z"
      />
    </svg>
  );
};
