import axios from "axios";
import { useReducer, useRef, useState } from "react";
import { ICampaignState, ICampaign, IsTate } from "../../interfaces/ICampaign";
import CampaignContext from "./CampaignContext";
import CampaignReducer from "./CampaignReducer";
import { setcampaign, settypes, setencuesta } from "./CampaignSet";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

export default (props: ICampaignState) => {
  const { children } = props;
  const initialState: IsTate = {
    userId: sessionStorage.getItem("usuarioId") as string,
    profile: sessionStorage.getItem("profile") as string,
    roles: JSON.parse(sessionStorage.getItem("roles") as string),
    campaignId: " ",
    nombre: " ",
    descripcion: "",
    fechaInicio: " ",
    fechaTermino: " ",
    grupo: " ",
    estado: " ",
    fechaCreacion: " ",
    campaign: [],
  };

  const [state, dispatch]: any = useReducer<any>(CampaignReducer, initialState);
  const [token, setToken] = useState<string | undefined>(undefined);
  const { toasts, createToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableReporteria, setDisableReporteria] = useState<boolean>(false);
  const disableRepo = (state: boolean) => {
    setDisableReporteria(state);
  };

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  const getCampaign = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}campaign?userId=${state.userId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const data = await result.data.campaigns;

      setcampaign({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getCampaignActiva = async (values: any) => {
    const { busPerId, token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}campaign/activas?busPerId=${busPerId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const data = await result.data;

      setencuesta({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const creaCampaign = async (values: any) => {
    const { campaign, token, setDataResponseEncuesta } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}campaign?userId=${state.userId}`,
        campaign,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await result.data;
      getCampaign({ token });
      if (data.code === 7000) {
        createToast({
          title: "Encuesta creada",
          message: "Encuesta creada correctamente",
          type: "success",
        });
        setDataResponseEncuesta(data.code);
      } else {
        createToast({
          title: "Error al crear",
          message:
            "Las fechas de la campaña se superponen con otra campaña activa",
          type: "danger",
        });
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Error al crear",
        message: "Error al crear la encuesta, por favor intenta nuevamente",
        type: "danger",
      });
    }
  };

  const encuestaEnviada = async (values: any) => {
    const { respuestas, token } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}campaign/answer?userId=${state.userId}`,
        respuestas,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await result.data;
    } catch (error) {
      console.log("Ha ocurrido algo al enviar la encuesta", error);
    }
  };

  const actYDesactCampaign = async (values: any) => {
    const { campaignId, estado, token } = values;
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}campaign/des-activar?userId=${state.userId}`,
        [{ campaignId, estado }],

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await result.data;
      getCampaign({ token });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const deleteCampaign = async (values: any) => {
    const { campaignId, token } = values;
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_URL}campaign?userId=${state.userId}`,
        {
          data: campaignId,
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await result.data;
      getCampaign({ token });
      if (data.summary.totalDeletedCampaigns > 0) {
        createToast({
          title: "Encuesta eliminada",
          message: "Encuesta eliminada correctamente",
          type: "success",
        });
      } else {
        createToast({
          title: "Error al eliminar",
          message:
            "Error al eliminar la encuesta, por favor intenta nuevamente",
          type: "danger",
        });
      }
    } catch (error) {
      console.log("ha ocurrido algo ", error);
    }
  };

  const typesCampaign = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}campaign/types`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const data = await result.data;

      settypes({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const reporteria = async (values: any) => {
    const { token, id } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}campaign/reporteria?userId=${state.userId}`,
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await result.data;
      if (data.emailWasSend == true) {
        createToast({
          title: "Descarga correcta",
          message: "Nómina descargada con éxito. Enlace enviado a su correo",
          type: "success",
        });
      } else {
        createToast({
          title: "Descarga incorrecta",
          message: "Error al descargar, por favor intenta nuevamente",
          type: "danger",
        });
      }
      setDisableReporteria(false);
      setIsLoading(false);
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setDisableReporteria(false);
      setIsLoading(false);
    }
  };

  return (
    <CampaignContext.Provider
      value={{
        ...state,
        toasts,
        token,
        disableReporteria,
        isLoading,
        getToken,
        disableRepo,
        getCampaign,
        getCampaignActiva,
        actYDesactCampaign,
        deleteCampaign,
        creaCampaign,
        typesCampaign,
        encuestaEnviada,
        reporteria,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
