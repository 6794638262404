import { IIconTw } from "../../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 22 20"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M9.81833 5.57667V0H1.605C1.06833 0 0.625 0.443333 0.625 0.98V20.02C0.625 20.5683 1.06833 21 1.605 21H15.395C15.9433 21 16.375 20.5567 16.375 20.02V6.55667H10.7983C10.2617 6.55667 9.81833 6.11333 9.81833 5.57667ZM9.725 8.00333L9.45667 16.0533H7.54333L7.275 8.00333H9.73667H9.725ZM9.43333 19.4017C9.2 19.635 8.885 19.74 8.5 19.74C8.115 19.74 7.81167 19.6233 7.56667 19.4017C7.33333 19.1683 7.21667 18.8883 7.21667 18.5267C7.21667 18.165 7.33333 17.885 7.56667 17.6517C7.8 17.4183 8.115 17.3017 8.5 17.3017C8.885 17.3017 9.2 17.4183 9.43333 17.64C9.66667 17.8733 9.78333 18.165 9.78333 18.515C9.78333 18.865 9.66667 19.1567 9.43333 19.39V19.4017Z"
        />
        <path
          d="M16.0833 4.305L12.07 0.291667C11.8833 0.105 11.6383 0 11.37 0H11.125V5.25H16.375V5.005C16.375 4.74833 16.27 4.49167 16.0833 4.31667V4.305Z"
        />
      </g>
    </svg>
  );
};
