import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconAsignarProceso from "../../../../ui/icons-seleccion/IconAsignarProceso";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconDescargaNomina from "../../../../ui/icons-seleccion/IconDescargaNomina";
import ModalAsignarProceso from "../../RegistroHabilitado/Modal/ModalAsignarProceso";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalAvanzarProceso from "../../../../ui/kit-modal/avanzarProceso/ModalAvanzarProceso";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import {
  IEstadosExcel,
  IFiltrosExcel,
} from "../../../../../interfaces/ISeleccion";
import IconCargando from "../../../../ui/icons-seleccion/IconCargando";
import ModalNoProcesadoSeleccion from "../../../../ui/kit-modal/noProcesado/ModalNoProcesadoSeleccion";

interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  handleAvanzarProceso: Function;
  candidates: any;
  setCandidates: Function;
  listRefresh: Function;
  setListadoSeleccionado: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    handleAvanzarProceso,
    setCandidates,
    listRefresh,
    setListadoSeleccionado,
  } = props;
  const {
    token,
    listaCandidatoPendienteMasivo,
    getCandidatoPendienteMasivo,
    listaNoProcesadosSeleccion,
    postGenerarExcel,
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [isOpenModalAvanzar, setIsOpenModalAvanzar] = useState<boolean>(false);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [isOpenModalAsignarProceso, setIsOpenModalAsignarProceso] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean | undefined>(
    undefined
  );
  const estadosExcel: IEstadosExcel = {
    porValidar: true,
    preseleccionado: false,
    validado: false,
  };
  let filtrosExcel: IFiltrosExcel = {
    busPerIdList: [],
    reclutador: [],
    estadoRegistro: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    tipoProceso: ["MASIVO"],
    fechaPostulacion: [],
    fechaValidacion: [],
    fechaPreseleccion: [],
    confirmaEntrevista: null,
    transporteTeamwork: null,
    entrevistaRealizada: null,
  };

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickAsignarProceso = (): void => {
    setIsOpenModalAsignarProceso(true);
  };

  const handleClickBloquearCandidato = (): void => {
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (): void => {
    setIsOpenModalDescartar(true);
  };

  const handleClickAvanzarProceso = (): void => {
    setIsOpenModalAvanzar(true);
  };

  const handleAvanzarCandidate = (): void => {
    handleAvanzarProceso({ setIsOpenModalNoProcesado });
    setIsOpenModalAvanzar(false);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "auto";
    }
    if (token) {
      listRefresh();
      setListadoSeleccionado([]);
    }
  };

  const handleClickNomina = async (): Promise<void> => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        const dataObject = JSON.parse(copyStorage);
        filtrosExcel.proceso = dataObject.proceso?.map((valor: any) => parseInt(valor)) ?? [];
        filtrosExcel.cliente = [...dataObject.cliente];
        filtrosExcel.fechaPostulacion = [...dataObject.fechaPostulacion];
        filtrosExcel.reclutador = [...dataObject.reclutador];
        filtrosExcel.rut = [...dataObject.rut];
        filtrosExcel.nombre = [...dataObject.nombre];
      }
      await postGenerarExcel({
        token,
        estadosExcel,
        filtrosExcel,
        setIsLoading: setIsLoadingExcel,
      });
    }
  };

  return (
    <>
      <section className="container-actions w-100">
        <div className="content-button-masive">
          <button
            className={`button-navegation ${
              buttonEnabled ? "naranjo" : "disabled"
            }`}
            onClick={() => handleClickAvanzarProceso()}
            disabled={buttonEnabled ? false : true}
          >
            Avanzar Candidato
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
          <Tooltip text="Asignar Proceso">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-reasignar" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickAsignarProceso}
            >
              <IconAsignarProceso fill={"#0065DD"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickBloquearCandidato}
            >
              <IconBloquear fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Descartar">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickDescartarCandidato}
            >
              <IconDescarte fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <Tooltip text="Descargar Nómina">
            <button
              className={`button-actions ${
                isLoadingExcel ? "disabled file" : "active-file"
              }`}
              onClick={() => handleClickNomina()}
            >
              {isLoadingExcel ? (
                <IconCargando fill="#327a30" />
              ) : (
                <IconDescargaNomina fill="#327a30" />
              )}
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </section>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["COMPLETO", "INCOMPLETO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="FECHA POSTULACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaPostulacion"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAvanzar && (
        <ModalGenerico
          title="CONFIRMAR MOVIMIENTO"
          width="w-70"
          subtitle="Estás por mover de etapa a la siguiente selección: "
          onChangeBack={() => {
            setIsOpenModalAvanzar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleAvanzarCandidate()}
          buttonBackText="Cancelar"
          buttonGoText="Aceptar"
        >
          <ModalAvanzarProceso
            etapa="Validados"
            listCandidate={listadoSeleccionado}
            listData={listaCandidatoPendienteMasivo}
            setCandidates={setCandidates}
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Candidatos No Enviados"
          iconTitle="danger"
          width="w-70"
          subtitle="Los siguiente candidatos no pudieron ser procesados:"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cerrar"
        >
          <ModalNoProcesadoSeleccion list={listaNoProcesadosSeleccion ?? []} />
        </ModalGenerico>
      )}

      {isOpenModalAsignarProceso && (
        <ModalGenerico
          title="Asignar Proceso"
          width="w-70"
          subtitle="Selecciona Grupo y/o Proceso a asignar a la selección"
          onChangeBack={() => {
            setIsOpenModalAsignarProceso(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalAsignarProceso
            list={listadoSeleccionado}
            listSelect={listaCandidatoPendienteMasivo}
            getListRefresh={listRefresh}
            localStorageFilter={localStorageFilter}
            setListadoSeleccionado={setListadoSeleccionado}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalAsignarProceso(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
          />
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPendienteMasivo ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={listRefresh}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={listaCandidatoPendienteMasivo ?? []}
            localStorageFilter={localStorageFilter}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
