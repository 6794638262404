import { useReducer, useState } from "react";
import axios from "axios";
import {
  IOperacionesContratacion,
  IState,
} from "../../interfaces/IOperacionesContratacion";
import OperacionContratacionContext from "./OperacionContratacionContext";
import OperacionContratacionReducer from "./OperacionContratacionReducer";
import {
  setListaUsuarioAnalista,
  setListaAreaNegocio,
  setListaCliente,
  setListaDocumentosVigentes,
  setListaBajasConfirmadas,
  setListaDocumentosSolicitados,
  setListaBajasCalculadas,
  setListaNoProcesados,
  setListaEstadisticaResumenGeneral,
  setListaIngresosProcesados,
  setListaUltimosCandidatosValidados,
  setListaEstadosForGrafic,
  setListaResumenEstadosOperaciones,
  setListaAnalistaCliente,
  setListaIngresoDotacion,
  setListaUltimasSolicitudesProcesadas,
  setListasUltimasBajasProcesadas,
} from "./OperacionContratacionSet";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import useToast from "../../components/ui/kit-mdtw/toasts/useToast";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

export default (props: IOperacionesContratacion) => {
  const { children } = props;
  const initialState: IState = {
    userId: sessionStorage.getItem("usuarioId") as string,
    profile: sessionStorage.getItem("profile") as string,
    roles: JSON.parse(sessionStorage.getItem("roles") as string),
    listaCliente: [],
    listaAreaNegocio: [],
    listaUsuarioAnalista: [],
    countAssign: [],
    listaDocumentosVigentes: [],
    listaDocumentosSolicitados: [],
    subMenu: [],
    transition: 0,
    listaBajasConfirmadas: [],
    listaBajasCalculadas: [],
    listaNoProcesados: [],
    listaEstadisticaResumenGeneral: [],
    listaIngresosProcesados: [],
    listaUltimosCandidatosValidados: [],
    listaEstadosForGraficos: [],
    listaResumenEstadosOperaciones: [],
    listaResumenAnalistaCliente: [],
    listaResumenIngresoDotacion: [],
    listaUltimasSolicitudesProcesadas: [],
    listasUltimasBajasProcesadas: [],
  };
  const [token, setToken] = useState<string | undefined>(
    sessionStorage.getItem("token") as string
  );
  const { toasts, createToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listDocumentosVigentes, setListDocumentosVigentes] = useState<any[]>(
    []
  );
  const [listDocumentosSolicitados, setListDocumentosSolicitados] = useState<
    any[]
  >([]);

  const [listNoProcess, setListNoProcess] = useState<any>();
  const [openModalNoProcess, setOpenModalNoProcess] = useState<boolean>(false);

  const [state, dispatch]: any = useReducer<any>(
    OperacionContratacionReducer,
    initialState
  );

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
          sessionStorage.setItem("token", response.idToken); 
        }
      })
      .catch((e) => {});
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#404040",
      marginTop: "-0.41em !important",
      display: "flex",
      position: "relative",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#404040",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 500,
      lineHeight: "23px",
      fontStyle: "normal",
      height: "42px",
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
  }));

  const getListClient = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/cliente`,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaCliente({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getListAreaNegocio = async (values: any) => {
    const { token, cliente } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/area-negocio-cliente`,
        {
          params: {
            userId: state.userId,
            clienteId: cliente,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaAreaNegocio({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getUserAnalist = async (values: any) => {
    const { token } = values;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/usuario`,
        {
          params: {
            // roles: "KAM",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaUsuarioAnalista({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const documentosDotacionVigente = async (values: any) => {
    const {  token, objetoSendFiltro } = values;
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/dotacion-activa?userId=${state.userId}`,
        { filtros: objetoSendFiltro },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      //console.log("data endpooint", data);
      setListaDocumentosVigentes({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBajasConfirmadas = async (values: any) => {
    const { token, clienteId, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}bajas-confirmadas`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
            clienteId: clienteId, 
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaBajasConfirmadas({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const getBajasCalculadas = async (values: any) => {
    const { token, clienteId, filtros, setIsLoadingTable, setIsErrorTable } = values;
    setIsLoadingTable && setIsLoadingTable(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}bajas-calculadas`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
            clienteId: clienteId, 
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaBajasCalculadas({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsErrorTable && setIsErrorTable(true);
    } finally {
      setIsLoadingTable && setIsLoadingTable(false);
    }
  };

  const solicitudRenovacion = async (values: any) => {
    const { objetoForRenovacion, token, objetoSendFiltro } = values;
    const body = {
      contratos: objetoForRenovacion,
    };
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/renovar-contrato?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const processed = data.results.processed;
      const noProcessed = data.results.noProcessed;
      documentosDotacionVigente({ token, objetoSendFiltro });
      getDocumentosSolicitados({ token, objetoSendFiltro });
     // setListDocumentosVigentes([])
      if (processed.length > 0) {
        createToast({
          title: "Solicitud de renovación de contrato realizada con éxito",
          message: "Revisa en Seguimiento documentos solicitados",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        setListNoProcess(noProcessed), setOpenModalNoProcess(true);
      }
    } catch (error) {
      createToast({
        title: "Solicitud de renovación de contrato no realizada",
        message: "Por favor intenta realizar la solicitud nuevamente",
        type: "danger",
      });
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmarBaja = async (values: any) => {
    const { objetoData, token, filtros } = values;
    const body = objetoData;
    const objetoSendFiltro = {};

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/confirmar-baja?userId=${state.userId}`,
        body,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const processed = data.results.processedTerminations;
      const noProcessed = data.results.noProcessedTerminations;
      documentosDotacionVigente({ token, objetoSendFiltro });
      getDocumentosSolicitados({ token, objetoSendFiltro });
    //  setListDocumentosVigentes([])
      if (processed.length > 0) {
        createToast({
          title: "Baja confirmada  con Éxito",
          message: "Revisa en Bajas confirmadas",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        setListNoProcess(data.results.noProcessedTerminations),
          setOpenModalNoProcess(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Baja No confirmada",
        message: "Por favor intenta realizar la solicitud nuevamente",
        type: "danger",
      });
    }
  };

  const getDocumentosSolicitados = async (values: any) => {
    const { token, objetoSendFiltro } = values;
    const filtros = objetoSendFiltro;
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentos-renovacion-solicitados?userId=${state.userId}`,
        { filtros: filtros },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaDocumentosSolicitados({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const detenerRenovacion = async (values: any) => {
    const { objetoData, token } = values;
    const body = { contratos: objetoData };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/detener-renovacion?userId=${state.userId}`,
        body,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const processed = data.results.processed;
      const noProcessed = data.results.noProcessed;

      if (processed.length > 0) {
        createToast({
          title: "Solicitud detenida con éxito",
          message: "Revisa en dotación activa",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        setListNoProcess(noProcessed), setOpenModalNoProcess(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Solicitud no detenida",
        message: "Por favor intenta realizar nuevamente",
        type: "danger",
      });
    }
  };

  const newContrato = async (values: any) => {
    const { objetoData, token, filtros } = values;
    const body = objetoData;

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/nuevo-contrato?userId=${state.userId}`,
        body,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      const processed = data.results.processed;
      const noProcessed = data.results.noProcessed;
      documentosDotacionVigente({ token, objetoSendFiltro: filtros });
      getDocumentosSolicitados({ token, objetoSendFiltro: filtros });
   //   setListDocumentosVigentes([])
      if (processed.length > 0) {
        createToast({
          title: "Solicitud de contrato realizada con éxito",
          message: "Revisa en Seguimiento documentos solicitados",
          type: "success",
        });
      }
      if (noProcessed.length > 0) {
        setListNoProcess(noProcessed), setOpenModalNoProcess(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Solicitud de Contrato no realizada",
        message: "Por favor intenta realizar la solicitud nuevamente",
        type: "danger",
      });
    }
  };

  const actualizaContrato = async (values: any) => {
    const { objetoData, token, filtros } = values;
    const body = objetoData;

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/actualizar-contrato?userId=${state.userId}`,
        body,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;

      createToast({
        title:
          "Solicitud de actualización de datos en contrato realizada con éxito",
        message: "Revisa en Seguimiento documentos solicitados",
        type: "success",
      });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      createToast({
        title: "Solicitud de actualización de datos en contrato no realizada",
        message: "Por favor intenta realizar la solicitud nuevamente",
        type: "danger",
      });
    }
  };

  const postDetenerSolicitud = async (values: any) => {
    const {
      token,
      contratos,
      setIsLoadingPost,
      setIsErrorPost,
      setIsOpenModalNoProcesado,
    } = values;
    setIsLoadingPost && setIsLoadingPost(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}detener-renovacion`,
        { contratos: contratos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processed = result.data.results.processed;
      const noProcessed = result.data.results.noProcessed;
      if (processed.length > 0) {
        createToast({
          title: "Solicitud Detenida Con Éxito",
          message: "Revise en dotación activa",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        createToast({
          title: "Solicitud No Detenida",
          message: "Por favor intente realizar nuevamente",
          type: "danger",
        });
        setListaNoProcesados({ dispatch, payload: noProcessed });
        setIsOpenModalNoProcesado(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsErrorPost && setIsErrorPost(true);
      createToast({
        title: "Solicitud No Detenida",
        message: "Por favor intente realizar nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoadingPost && setIsLoadingPost(false);
    }
  };

  const postDetenerBaja = async (values: any) => {
    const {
      token,
      contratos,
      setIsLoadingPost,
      setIsErrorPost,
      setIsOpenModalNoProcesado,
    } = values;
    setIsLoadingPost && setIsLoadingPost(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}detener-baja`,
        { contratos: contratos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processed = result.data.results.processedSummary;
      const noProcessed = result.data.results.noProcessedSummary;
      if (processed.length > 0) {
        createToast({
          title: "Solicitud Baja Detenida Con Éxito",
          message: "Revise en dotación activa",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        createToast({
          title: "Solicitud No Detenida",
          message: "Por favor intente realizar nuevamente",
          type: "danger",
        });
        setListaNoProcesados({ dispatch, payload: noProcessed });
        setIsOpenModalNoProcesado(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsErrorPost && setIsErrorPost(true);
      createToast({
        title: "Solicitud No Detenida",
        message: "Por favor intente realizar nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoadingPost && setIsLoadingPost(false);
    }
  };

  const postAnularDocumento = async (values: any) => {
    const {
      token,
      contratos,
      setIsLoadingPost,
      setIsErrorPost,
      setIsOpenModalNoProcesado,
    } = values;
    setIsLoadingPost && setIsLoadingPost(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}anular-baja`,
        { contratos: contratos },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processed = result.data.results.processedSummary;
      const noProcessed = result.data.results.noProcessedSummary;
      if (processed.length > 0) {
        createToast({
          title: "Solicitud Existosa",
          message: "Revise en dotación activa",
          type: "success",
        });
      }

      if (noProcessed.length > 0) {
        createToast({
          title: "Solicitud No Realizada",
          message: "Por favor intente realizar nuevamente",
          type: "danger",
        });
        setListaNoProcesados({ dispatch, payload: noProcessed });
        setIsOpenModalNoProcesado(true);
      }
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
      setIsErrorPost && setIsErrorPost(true);
      createToast({
        title: "Solicitud No Realizada",
        message: "Por favor intente realizar nuevamente",
        type: "danger",
      });
    } finally {
      setIsLoadingPost && setIsLoadingPost(false);
    }
  };

  const getEstadisticaResumenGeneral = async (values: any) => {
    const { token, objeto } = values;
    const body = objeto;
    setIsLoading(true);

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/general?userId=${state.userId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaEstadisticaResumenGeneral({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const postIngresosProcesados = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/ingresos-procesados`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaIngresosProcesados({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postUltimosCandidatosValidados = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/candidatos-aprobados`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaUltimosCandidatosValidados({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postSolicitudesResumen = async (values: any) => {
    const { token, objeto } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/solicitudes?userId=`,
        objeto,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaEstadosForGrafic({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postResumenEstadosOperaciones = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/estados`,
        filtros,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaResumenEstadosOperaciones({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postResumenAnalistaCliente = async (values: any) => {
    const { token, objeto } = values;
    
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/analista-cliente`,
        objeto,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      
      setListaAnalistaCliente({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postResumenIngresoDotacion = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/estadistica/resumen/ingreso-dotacion`,
        filtros,
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data;
      setListaIngresoDotacion({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postUltimasSolicitudesProcesadas = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/documentos-procesados`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListaUltimasSolicitudesProcesadas({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const postUltimasBajasProcesadas = async (values: any) => {
    const { token, filtros } = values;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}estadistica/reportes/bajas-calculadas`,
        { filtros: filtros },
        {
          params: {
            userId: state.userId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = result.data.data;
      setListasUltimasBajasProcesadas({ dispatch, payload: data });
    } catch (error) {
      console.log("Ha ocurrido algo ", error);
    }
  };

  const getDayOfWeek = (dateString: string): string | null => {
    if (typeof dateString !== "string" || dateString.trim() === "") return null;
    const daysOfWeek = [
      "LUNES",
      "MARTES",
      "MIÉRCOLES",
      "JUEVES",
      "VIERNES",
      "SÁBADO",
      "DOMINGO",
    ];
    const date: Date = new Date(dateString);
    const dayIndex: number = date.getDay();
    return daysOfWeek[dayIndex];
  }

  return (
    <OperacionContratacionContext.Provider
      value={{
        ...state,
        BootstrapTooltip,
        toasts,
        token,
        getToken,
        getListClient,
        getListAreaNegocio,
        getUserAnalist,
        documentosDotacionVigente,
        getBajasConfirmadas,
        getBajasCalculadas,
        listDocumentosVigentes,
        setListDocumentosVigentes,
        solicitudRenovacion,
        confirmarBaja,
        listDocumentosSolicitados,
        setListDocumentosSolicitados,
        getDocumentosSolicitados,
        detenerRenovacion,
        newContrato,
        actualizaContrato,
        postDetenerSolicitud,
        postDetenerBaja,
        postAnularDocumento,
        listNoProcess,
        openModalNoProcess,
        setOpenModalNoProcess,
        getEstadisticaResumenGeneral,
        postIngresosProcesados,
        postUltimosCandidatosValidados,
        postSolicitudesResumen,
        postResumenEstadosOperaciones,
        postResumenAnalistaCliente,
        postResumenIngresoDotacion,
        postUltimasSolicitudesProcesadas,
        postUltimasBajasProcesadas,
        getDayOfWeek
      }}
    >
      {children}
    </OperacionContratacionContext.Provider>
  );
};
