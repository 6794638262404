import React, { useEffect, useRef, useState } from "react";
import api from "../services/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useMsal } from "@azure/msal-react";
import { Toast } from "primereact/toast";
import { callMsGraphTokens } from "../services/MsGraphTokens";
import { ClassNames } from "@emotion/react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

interface ModalBlockinProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisible: boolean;
  candidate: any[];
  group: any;
}

const ModalBlocking: React.FC<ModalBlockinProps> = ({
  token,
  isModalVisible,
  onClose,
  onCloseSave,
  candidate,
  group,

  //closeFullModal,
}) => {
  const [comment, setComment] = useState<any>("");
  const [positions, setPositions] = useState<any[]>([]);
  const [position, setPosition] = useState<any>("");
  const [selectedReason, setSelectedReason] = useState<any>("");
  const [enableMessageDuplicate, setEnableMessageDuplicate] =
    useState<boolean>(false);
  const [reasonsDiscard, setReasonsDiscard] = useState<any[]>([]);

  const { instance } = useMsal();

  const cerrarModal = () => {
    onClose();
    setPosition("");
    setSelectedReason("");
    setComment("");
    setEnableMessageDuplicate(false);
  };

  const searchPositions = () => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId.concat(" - ").concat(busqueda.cargoNombre),
        };
        newArr = [...newArr, newObj];
      });
      setPositions(newArr);
    }
  };

  const getReasons = () => {
    api
      .get(`/exclusion-tipo/`, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          limit: 1000,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const reasons = response.data.data.filter(
          (reason: any) =>
            reason.exclusionLevel == "all" || reason.exclusionLevel == "cliente"
        );
        setReasonsDiscard(reasons);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  //UseEffect

  useEffect(() => {
    if (group && candidate && group.busquedas) {
      searchPositions();
      getReasons();
    }
  }, [candidate]);

  //call Apis
  const blockingCandidate = (discardedCandidates: any[]) => {
    const discardData = discardedCandidates.map((item: any) => {
      return {
        busPerId: item.busPerId,
        excTipoId: selectedReason,
        usuarioId: instance.getActiveAccount()?.localAccountId,
        excPerComentario: comment,
        clienteId: item.clienteId,
        busquedaId: item.busquedaId,
      };
    });
    let request = {
      excPerList: discardData,
    };
    api
      .post(`/exclusion-persona-descarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPosition("");
        setSelectedReason("");
        setComment("");
        setEnableMessageDuplicate(false);
        onCloseSave();
      })
      .catch((error) => {
        setEnableMessageDuplicate(true);
      });
  };

  const confirm1 = () => {
    confirmDialog({
      message: "¿Estas seguro del bloqueo?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si",
      accept,
      reject,
    });
  };

  const accept = () => {
    blockingCandidate(candidate);
  };

  const reject = () => {};

  //main return

  return (
    <>
      <Dialog
        visible={isModalVisible}
        onHide={onClose}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "700px", height: "490px" }}
        closable={false}
        draggable={false}
        footer={" "}
      >
        <div className="mt-3 mb-4 mr-4 ml-5">
          <div className="mb-3">
            <span
              className="material-symbols-outlined md-24"
              style={{ color: "#D32F2F" }}
            >
              warning
            </span>
            <label
              className="errorModalTittle ml-4"
              style={{ color: "#D32F2F" }}
            >
              {" "}
              <b>Bloquear Candidato</b>
            </label>
          </div>
          <div>
            <p className="text-justify">
              Al <b>bloquear</b> un candidato este no podrá continuar en ningún
              caso.
            </p>
            <br></br>
            {candidate ? (
              candidate.length == 1 ? (
              <p className="text-justify mb-5 line-height-2">
                Por favor ingresa el motivo para bloquear{" "}
                <b>
                  {" "}
                  {candidate[0].fullname} ( {candidate[0].personaDni} )
                </b>
              </p>
            ) : (
              <p className="text-justify mb-5 line-height-2 ">
                  <b>Por favor ingresa el motivo para bloqueo masivo </b>
                </p>
            )
            ) : (
              ""
          )}
          </div>
          <div className="flex flex-column">
            <Dropdown
              value={position}
              options={positions}
              onChange={(e) => setPosition(e.target.value)}
              optionLabel="label"
              placeholder="Seleccione Id"
              appendTo="self"
            />

            <Dropdown
              value={selectedReason}
              options={reasonsDiscard}
              onChange={(e) => setSelectedReason(e.target.value)}
              optionValue="excTipoId"
              optionLabel="excTipoNombre"
              placeholder="Seleccione motivo"
              className="mt-3"
              appendTo="self"
            />

            <InputText
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="mt-3"
              placeholder="Observaciones"
            />
          </div>
          {enableMessageDuplicate ? (
            <p>Candidato ya se encuentra bloqueado</p>
          ) : (
            ""
          )}
        </div>

        <div className="flex justify-content-between mr-4 ml-5 mt-8">
          <a
            className="cursor-pointer"
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "14px",
              lineHeight: "16px",
              alignItems: "center",
              fontWeight: "600",
              color: "#2A63D5",
              textDecoration: "underline",
            }}
            onClick={() => {
              cerrarModal();
            }}
          >
            Cancelar
          </a>

          <Button
            label="Bloquear"
            className="p-button-danger"
            onClick={confirm1}
            disabled = {selectedReason == "" || position == ""}
          />
        </div>
      </Dialog>

      <ConfirmDialog />
    </>
  );
};

export default ModalBlocking;
