function validateRUT(rut: string): boolean {
  //console.log("rut función validación: ", rut)

  function dv(T: any) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "K";
  }

  rut = rut.replace(/[.]/g, "");
  rut = rut.replace("‐", "-");
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) return false;
  var tmp = rut.split("-");
  var digv = tmp[1];
  rut = tmp[0];
  //if ( digv == 'K' ) digv = 'k' ;

  //console.log("dv(rut):", dv(rut))
  //console.log("digv: ", digv)

  return dv(rut) == digv;
}

export default validateRUT;
