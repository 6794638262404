import { IFormatRut } from "../../interfaces/IInput";
import { convertThousandRut } from "./number";

export const validVerifyDigit = (values: IFormatRut) => {
  const { rut } = values;
  if (!rut) {
    return false;
  }
  let suma = 0;
  let resto = 0;
  let digitVerify = 0;
  const verifyDigits: any = {
    10: "K",
    11: 0,
  };
  const { 0: dni, 1: dv } = rut.toUpperCase().split("-");

  const digits = dni.split("");

  if (digits.length == 7) {
    digits.unshift("0");
  }

  const mathForm = [3, 2, 7, 6, 5, 4, 3, 2];

  digits.map((number, index) => {
    suma += Number(number) * Number(mathForm[index]);
  });

  resto = suma % 11;
  digitVerify = 11 - resto;

  digitVerify = verifyDigits[digitVerify] ?? digitVerify;

  if (String(digitVerify) !== dv) {
    return false;
  }

  return true;
};

export const formatRut = (values: IFormatRut) => {
  const { rut } = values;
  let formatRut: string = "";

  const arrayRut: string[] = rut.split("");

  if (arrayRut.length > 1) {
    const lastNumber: string = arrayRut[arrayRut.length - 1];

    arrayRut[arrayRut.length] = lastNumber;
    arrayRut[arrayRut.length - 2] = "-";
  }

  arrayRut.map((digits) => {
    formatRut += `${digits}`;
  });

  if (formatRut.includes("-")) {
    const { 0: digits, 1: dv } = formatRut.split("-");
    formatRut = `${convertThousandRut({
      number: digits.split("").join(""),
    })}-${dv}`;
  }

  return formatRut;
};
