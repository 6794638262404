import React, { useState, useEffect, useRef, useContext } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { prettifyRut } from "../../../hooks/useRut";
import { ProgressSpinner } from "primereact/progressspinner";
import { useHistory, useParams } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AlternateEmail } from "@mui/icons-material";
import {
  descarte_cliente,
  descarte_persona,
  descarte_perfil,
  status_no_confirmado,
  status_porvalidar,
  status_validado,
} from "../../../config/constantes";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ModalError from "../../../utils/ModalError";
import { MultiSelect } from "primereact/multiselect";
import formatRUT from "../../../functions/formatRUT";
import { useMsal } from "@azure/msal-react";
import ModalChangeId from "../../../utils/ModalChangeId";
import ModalDiscard from "../../../utils/ModalDiscard";
import ModalBlocking from "../../../utils/ModalBlocking";
import ModalChangeGroup from "../../../utils/ModalChangeGroup";
import { Accordion, AccordionTab } from "primereact/accordion";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import ModalUpdateCandidate from "../kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import ModalEncuesta from "../../pages/Campaign/ModalEncuesta";

const ValidationTableComponentV2 = () => {
  const {
    token, 
    getToken, 
    getListaGeneral,
    toasts,
    getRutVerificado,
    getDocument,
  } = useContext(SeleccionContext);
  const [candidatesNoAdmit2, setCandidatesNoAdmit2] = useState<any>(null);
  const [filterStatusNoAdmit2, setFilterStatusNoAdmit2] = useState<any>(null);
  const [recruitmentSourceFilterNoAdmit2, setRecruitmentSourceFilterNoAdmit2] =
    useState<any>("");
  const [fechaCreacionNoAdmit2, setFechaCreacionNoAdmit2] = useState<any>(null);
  const [filterRutNoAdmit, setFilterRutNoAdmit] = useState<any>("");
  const [filterIdNoAdmit2, setFilterIdNoAdmit2] = useState<any>("");
  const [searchIdsFilterNoAdmit2, setsearchIdsFilterNoAdmit2] = useState<any[]>(
    []
  );
  const [fechaCreacionNoAdmit, setFechaCreacionNoAdmit] = useState<any>(null);
  const [resultFechasCreacionNoAdmit2, setresultFechasCreacionNoAdmit2] =
    useState<any>(null);
  const [resultProgresosNoAdmit, setresultProgresosNoAdmit] =
    useState<any>(null);
  const [candidates, setCandidates] = useState<any>(null);
  const [candidatesTabla, setCandidatesTabla] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resultProgresos, setresultProgresos] = useState<any>(null);
  const [progreso, setProgreso] = useState<any>(null);
  const [resultCargos, setresultCargos] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<any>(null);
  const [resultFechasCreacion, setresultFechasCreacion] = useState<any>(null);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);
  const menuDescartar = useRef<Menu>(null);
  const toast = useRef<Toast>(null);
  const [visiblePorCandidato, setVisiblePorCandidato] = useState(false);
  const [visiblePorCliente, setVisiblePorCliente] = useState(false);
  const [inputModalCandidatos, setInputModalCandidatos] = useState<any>("");
  const [inputModalClientes, setInputModalClientes] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [candidatosADescartar, setCandidatosADescartar] = useState<any[]>([]);
  const { busquedaId }: any = useParams();
  const [filterRut, setFilterRut] = useState<any>("");
  const [visibleModalError, setVisibleModalError] = useState(false);
  const [fechaCreacion, setFechaCreacion] = useState<any>(null);
  const [group, setGroup] = useState<any>({});
  const { instance } = useMsal();
  const [visiblePorPerfil, setVisiblePorPerfil] = useState(false);
  const [inputModalPerfiles, setInputModalPerfiles] = useState<any>("");
  const [visibleModalChangeId, setVisibleModalChangeId] = useState(false);
  const [candidateModal, setCandidateModal] = useState<any>(null);
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);
  const [visibleModalBlocking, setVisibleModalBlocking] = useState(false);
  const [candidatosABloquear, setCandidatosABloquear] = useState<any[]>([]);
  const [filterId, setFilterId] = useState<any>("");
  const [searchIdsFilter, setsearchIdsFilter] = useState<any[]>([]);
  const [visibleModalChangeGroup, setVisibleModalChangeGroup] = useState(false);
  const [visibleWarningInfo, setVisibleWarningInfo] = useState(false);
  const [searchIds, setsearchIds] = useState<any[]>([]);
  const [warnings, setWarnings] = useState<any[]>([]);
  const { idGrupo }: any = useParams();
  const [filterUsuario, setFilterUsuario] = useState("");
  const [optionReclutador, setOptionReclutador] = useState<any>([]);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  let history = useHistory();

  const backToTable = (flag: number) => {
    if (flag == 1) {
      setVisiblePorCandidato(false);
      setInputModalCandidatos("");
    } else if (flag == 2) {
      setVisiblePorCliente(false);
      setInputModalClientes("");
    } else if (flag == 3) {
      setVisiblePorPerfil(false);
      setInputModalPerfiles("");
    }
  };

  const cerrarModalChangeId = () => {
    setVisibleModalChangeId(false);
  };

  const saveModalChangeId = () => {
    setVisibleModalChangeId(false);
    getCandidates();
  };

  const toggleModalChangeId = (rowData: any) => {
    setCandidateModal(rowData);
    setVisibleModalChangeId(true);
  };

  const initFilters = () => {
    setFilterStatus({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [
          { value: "", matchMode: FilterMatchMode.EQUALS, filtered: false },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });

    setFilterStatusNoAdmit2({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [
          { value: "", matchMode: FilterMatchMode.EQUALS, filtered: false },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });
  };

  const renderFooterCandidate = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorCandidatos(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalCandidatos.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(1)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const renderFooterProfile = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorPerfil(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalPerfiles.length === 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(3)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const rightContents = () => {
    return (
      <>
        <div className="cardTest">
          <div className="" style={{}}>
            <Button
              label="Reclutar"
              className="p-button-text"
              onClick={() => goToRecruit(busquedaId)}
            />

            <Button
              label="Validar"
              className="p-button-raised p-button-text mx-5"
              onClick={() => goToValidation(busquedaId)}
            />
            <Button
              label="Confirmar"
              className="p-button-text "
              onClick={() => goToConfirm(busquedaId)}
            />
          </div>
        </div>
      </>
    );
  };

  const renderFooterClient = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorClientes(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalClientes.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(2)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const filterbusiness_fechaSelected = (e: { value: any }) => {
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacion(e.value);
  };

  const cerrarModalError = () => {
    setVisibleModalError(false);
    getCandidates();
  };

  const goToRecruit = (idGrupo: string) => {
    history.push("/reclutarporgrupos/" + idGrupo);
  };

  const goToValidation = (idGrupo: string) => {
    history.push("/validacionporgrupo/" + idGrupo);
  };

  const goToConfirm = (idGrupo: string) => {
    history.push("/confirmacionporgrupo/" + idGrupo);
  };

  const items = [
    {
      items: [
        {
          label: "Por Candidato",
          command: () => {
            setVisiblePorCandidato(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Por Cliente",
          command: () => {
            setVisiblePorCliente(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Conducta Inadecuada",
          command: () => {
            setVisiblePorPerfil(true);
            setCandidatosADescartar(selectedCandidates);
            //descartarPorCandidato(candidato);
            //toast.current.show({ severity: 'success', summary: 'Descartado', detail: 'Candidato descartado', life: 3000 });
          },
        },
      ],
    },
  ];

  // cambia cuando se defina el endpoint debo crear uno por candidato y por cliente

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const cerrarModalDiscard = () => {
    setCandidatosADescartar([]);
    setVisibleModalDiscard(false);
    // buscarCandidatos();
  };

  const saveModalDiscard = () => {
    setVisibleModalDiscard(false);
    setCandidatosADescartar([]);
    getCandidates();
  };

  const convertToDate = (dateString: any) => {
    let d = dateString.split("/");
    let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
    return dat;
  };

  const convertToDate2 = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  function resetearFechaNacimiento(fechaRecibida: any) {
    let fechaNac = new Date();
    if (fechaRecibida === "") {
      fechaRecibida = "1900-01-01";
    }
    fechaNac = fechaRecibida
      ? fechaRecibida?.toString().slice(0, 10)
      : new Date(1900, 0, 1);
    let fechaString = fechaNac.toString();
    let diaFecha = fechaString.substring(8, 10);
    let mesFecha = fechaString.substring(5, 7);
    let anioFecha = fechaString.substring(0, 4);
    let fechaDeNacimientoOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
    return fechaDeNacimientoOrdenada;
  }

  const selectionFilterRut = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRut(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatus(filter_Status);
  };

  function setearExcel(candidates: any) {
    let datosExportacionExcel = [];
    function dejarInicialMayuscula(cadenaParaMayuscula: any) {
      if (cadenaParaMayuscula != null) {
        let patron = /\w\S*/g;
        let cadenaArregladaMayuscula = cadenaParaMayuscula.replace(
          patron,
          (p: any) => p.charAt(0).toUpperCase() + p.substring(1).toLowerCase()
        );
        return cadenaArregladaMayuscula;
      } else {
        let cadenaArregladaMayuscula = "";

        return cadenaArregladaMayuscula;
      }
    }
    for (let j = 0; j < candidates.length; j++) {
      let nombres =
        candidates[j].personaNombre1 + " " + candidates[j].personaNombre2;
      let nombreConMayuscula = nombres.toUpperCase();
      let apellido1 = candidates[j].personaApe1;
      let apellido2 = candidates[j].personaApe2;
      let apellidoConMayuscula1 = apellido1.toUpperCase();
      let apellidoConMayuscula2 = apellido2.toUpperCase();
      let sexo = "";
      let fechaDeNacimiento = resetearFechaNacimiento(
        candidates[j].personaFecNac
      );
      let contactoEmergencia = dejarInicialMayuscula(
        candidates[j].contactoemergencia
      );
      let telefonoEmergencia = "";
      let discapacidad = candidates[j].discapacidad;
      let descripcionDiscapacidad = candidates[j].descripciondiscapacidad;
      let paseDeMovilidad = candidates[j].busPerPaseMovilidad;
      let rut = candidates[j].personaDni.substring(
        0,
        candidates[j].personaDni.indexOf("-")
      );
      let dv = candidates[j].personaDni.substring(
        candidates[j].personaDni.length - 1
      );

      if (paseDeMovilidad === 0) {
        paseDeMovilidad = "NO";
      } else if (paseDeMovilidad === 1) {
        paseDeMovilidad = "SI";
      } else if (paseDeMovilidad === 2) {
        paseDeMovilidad = "NO SE";
      }
      if (discapacidad === 0) {
        discapacidad = "NO";
      } else if (discapacidad === 1) {
        discapacidad = descripcionDiscapacidad;
      } else {
        discapacidad = "";
      }
      if (candidates[j].telefonoemergencia == null) {
        telefonoEmergencia = "";
      } else {
        telefonoEmergencia = candidates[j].telefonoemergencia;
      }
      if (candidates[j].genero === "M") {
        sexo = "MASCULINO";
      } else if (candidates[j].genero === "F") {
        sexo = "FEMENINO";
      } else {
        sexo = "-";
      }
      let nacionalidad = "";
      if (candidates[j].paisNombre === "Chile") {
        nacionalidad = "CHILENA";
      } else {
        nacionalidad = "OTRA";
      }
      let estadoCivil = "";
      if (candidates[j].estCivilId === "est-02") {
        estadoCivil = "CASADO";
      } else if (candidates[j].estCivilId === "est-03") {
        estadoCivil = "SOLTERO";
      } else if (candidates[j].estCivilId === "est-04") {
        estadoCivil = "VIUDO";
      } else if (candidates[j].estCivilId === "isa-05") {
        estadoCivil = "DIVORCIADO";
      } else if (candidates[j].estCivilId === "est-06") {
        estadoCivil = "CONVIVIENTE CIVIL";
      }

      const calcularEdad = (fecha: any) => {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
        }
        return edad;
      };
      let edad = calcularEdad(convertToDate(fechaDeNacimiento));
      let fechaActual = new Date();
      let reclutadorConMayusculas = candidates[j].usuarioNombre.toUpperCase();
      if (candidates[j].saludNombre == null) {
        candidates[j].saludNombre = "";
      }
      if (candidates[j].enfermedadcronica == null) {
        candidates[j].enfermedadcronica = "";
      }
      {
        if (candidates[j].clienteId === "96591040-9") {
          datosExportacionExcel.push({
            CARGO: candidates[j].cargoNombre,
            NOMBRES: nombreConMayuscula.trim(),
            "APELLIDO PATERNO": apellidoConMayuscula1,
            "APELLIDO MATERNO": apellidoConMayuscula2,
            RUT: candidates[j].personaDni,
            SEXO: sexo,
            "F. DE NACIMIENTO": fechaDeNacimiento,
            NACIONALIDAD: nacionalidad,
            "ESTADO CIVIL": estadoCivil,
            "DIRECCIÓN (VERIFICADA EN GOOGLE MAPS)": candidates[j].direccion
              ? candidates[j].direccion.toUpperCase()
              : "",
            COMUNA: candidates[j].comunaNombre
              ? candidates[j].comunaNombre.toUpperCase()
              : "",
            "CORREO ELECTRÓNICO": candidates[j].email
              ? candidates[j].email.toUpperCase()
              : "",
            CELULAR: candidates[j].wsp,
            RECLUTADOR: reclutadorConMayusculas,
            "PANTALÓN (N)": candidates[j].tallaDePantalon,
            POLERA: candidates[j].tallaDePolera,
            ZAPATOS: candidates[j].tallaZapato,
            "N° DE HIJOS": candidates[j].numerohijos,
            "EDAD DE LOS HIJOS": candidates[j].edadhijoc,
            "CARGAS FAMILIARES": candidates[j].cargas,
            SALUD: candidates[j].saludNombre
              ? candidates[j].saludNombre.toUpperCase()
              : "",
            AFP: candidates[j].prevNombre,
            BANCO: candidates[j].bancoNombre,
            CUENTA: candidates[j].tipoDeCuentaBco
              ? candidates[j].tipoDeCuentaBco.toUpperCase()
              : "",
            "NUMERO DE CUENTA": candidates[j].numeroDeCuentaBco,
            "ENFERMEDAD CRONICA": candidates[j].enfermedadcronica
              ? candidates[j].enfermedadcronica.toUpperCase()
              : "",
            DISCAPACIDAD: discapacidad ? discapacidad.toUpperCase() : "",
            EDAD: edad,
            "PASE DE MOVILIDAD": paseDeMovilidad
              ? paseDeMovilidad.toUpperCase()
              : "",
            "CONTACTO EMERGENCIA": contactoEmergencia
              ? contactoEmergencia.toUpperCase() + " " + telefonoEmergencia
              : "",
          });
        } else if (candidates[j].clienteId === "76212492-0") {
          //Falabella
          datosExportacionExcel.push({
            RUT: rut,
            "DV (MAX 1)": dv,
            "NOMBRES (MAX 80)": nombres,
            "APELLIDO PATERNO OBLIGATORIO": apellidoConMayuscula1,
            "APELLIDO MATERNO (MAX 80)": apellidoConMayuscula2,
            "EMPRESA (MAX 300)": candidates[j].fal_empresa
              ? candidates[j].fal_empresa.toUpperCase()
              : "",
            "COD. CENTRO COSTO": candidates[j].fal_codcentrocosto
              ? candidates[j].fal_codcentrocosto.toUpperCase()
              : "",
            "CARGO (MAX 50)": candidates[j].cargoNombre,
            "FECHA CONTRATO (DD-MM-YYYY)": candidates[j].fal_fechacontrato
              ? resetearFechaNacimiento(candidates[j].fal_fechacontrato)
              : "",
            "FECHA TERMINO CONTRATO (DD-MM-YYYY)": candidates[j]
              .fal_fechaterminocontrato
              ? resetearFechaNacimiento(candidates[j].fal_fechaterminocontrato)
              : "",
            "ESTADO (ACTIVO-BLOQUEADO-INHABILITADO)": candidates[j].fal_estado
              ? candidates[j].fal_estado.toUpperCase()
              : "",
            "SEXO(M-F)": sexo,
            "FECHA NACIMIENTO (DD-MM-YYYY)": fechaDeNacimiento,
            "NACIONALIDAD (CHL=CHILE)": nacionalidad,
            "FECHA VENC. CÉDULA DE IDENTIDAD (DD-MM-YYYY)": candidates[j]
              .fal_fechavenccedula
              ? resetearFechaNacimiento(candidates[j].fal_fechavenccedula)
              : "",
            "CÓDIGO TARJETA DE PROXIMIDAD": candidates[j]
              .fal_codtarjetaproximidad
              ? candidates[j].fal_codtarjetaproximidad.toUpperCase()
              : "",
            "TURNO DIA-TARDE O NOCHE": candidates[j].jornada,
            "TARIFA SI NO SABE DEJELA VACÍA": candidates[j].fal_tarifa
              ? candidates[j].fal_tarifa.toUpperCase()
              : "",
            "TIPO TURNO SI NO LA SABE DEJELA VACÍA": candidates[j].turno,
            EST: candidates[j].fal_est
              ? candidates[j].fal_est.toUpperCase()
              : "",
            PANTALON: candidates[j].tallaDePantalon,
            POLERA: candidates[j].tallaDePolera,
            ZAPATOS: candidates[j].tallaZapato,
            COMUNA: candidates[j].comunaNombre
              ? candidates[j].comunaNombre.toUpperCase()
              : "",
            TELEFONO: candidates[j].wsp,
            CORREO: candidates[j].email
              ? candidates[j].email.toUpperCase()
              : "",
            DIRECCION: candidates[j].direccion
              ? candidates[j].direccion.toUpperCase()
              : "",
            VAN: candidates[j].fal_van
              ? candidates[j].fal_van.toUpperCase()
              : "",
            SALUD: candidates[j].saludNombre
              ? candidates[j].saludNombre.toUpperCase()
              : "",
            AFP: candidates[j].prevNombre,
            BANCO: candidates[j].bancoNombre,
            "TIPO DE CUENTA": candidates[j].tipoDeCuentaBco
              ? candidates[j].tipoDeCuentaBco.toUpperCase()
              : "",
            "NRO DE CUENTA": candidates[j].numeroDeCuentaBco,
            "ESTADO CIVIL": estadoCivil,
            "NRO DE HIJOS": candidates[j].numerohijos,
            "EDAD DE LOS HIJOS": candidates[j].edadhijoc,
            CARGAS: candidates[j].cargas,
            EDAD: edad,
            "ENFERMEDAD CRONICA": candidates[j].fal_enfermedadcronica
              ? candidates[j].fal_enfermedadcronica.toUpperCase()
              : "",
            DISCAPACIDAD: discapacidad ? discapacidad.toUpperCase() : "",
          });
        } else {
          //aramark
          datosExportacionExcel.push({
            RUT: candidates[j].personaDni,
            "NOMBRES (MAX 80)": nombreConMayuscula.trim(),
            "APELLIDO PATERNO": apellidoConMayuscula1,
            "APELLIDO MATERNO": apellidoConMayuscula2,
            "EMPRESA (MAX 300)": candidates[j].ce_empresa,
            "CARGO (MAX 50)": candidates[j].cargoNombre,
            SUCURSAL: candidates[j].ce_sucursal,
            "SEXO (M-F)": sexo,
            "FECHA NACIMIENTO (DD-MM-YYYY)": fechaDeNacimiento,
            "NACIONALIDAD (CHL=CHILE)": nacionalidad,
            RECLUTADOR: reclutadorConMayusculas,
            "TURNO DIA-TARDE O NOCHE": candidates[j].jornada,
            "TURNO ROTATIVOS": candidates[j].turno,
            PANTALON: candidates[j].tallaDePantalon,
            POLERA: candidates[j].tallaDePolera,
            ZAPATOS: candidates[j].tallaZapato,
            DIRECCION: candidates[j].direccion
              ? candidates[j].direccion.toUpperCase()
              : "",
            COMUNA: candidates[j].comunaNombre
              ? candidates[j].comunaNombre.toUpperCase()
              : "",
            CORREO: candidates[j].email
              ? candidates[j].email.toUpperCase()
              : "",
            TELEFONO: candidates[j].wsp,
            "PREVISION SALUD": candidates[j].tipoDeCuentaBco
              ? candidates[j].tipoDeCuentaBco.toUpperCase()
              : "",
            AFP: candidates[j].prevNombre,
            BANCO: candidates[j].bancoNombre,
            "TIPO DE CUENTA": candidates[j].tipoDeCuentaBco
              ? candidates[j].tipoDeCuentaBco.toUpperCase()
              : "",
            "NRO DE CUENTA": candidates[j].numeroDeCuentaBco,
            "ESTADO CIVIL": estadoCivil,
            "NRO DE HIJOS": candidates[j].numerohijos,
            "EDAD DE LOS HIJOS": candidates[j].edadhijoc,
            CARGAS: candidates[j].cargas,
            EDAD: edad,
            SALUD: candidates[j].ce_salud
              ? candidates[j].ce_salud.toUpperCase()
              : "",
            DISCAPACIDAD: discapacidad ? discapacidad.toUpperCase() : "",
            "PASE DE MOVILIDAD": paseDeMovilidad.toUpperCase(),
          });
        }
      }
    }
    return datosExportacionExcel;
  }

  function eliminarObjetosDuplicados(arr: any, prop: any) {
    var nuevoArray: any[] = [];
    nuevoArray = arr.filter(
      (value: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) => t.name?.substring(0, 10) === value.name?.substring(0, 10)
        )
    );
    return nuevoArray;
  }

  function SortArrayByNames(x: any, y: any) {
    return x.name.localeCompare(y.name);
  }

  const toggleModal = () => {
    setIsModalVisible((wasModalVisible) => !wasModalVisible);
  };

  const saveAsExcelFile = (buffer: any) => {
    import("file-saver").then((module: any) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        let diaActual = new Date().getDate();
        let mesActual = new Date().getMonth();

        module.default.saveAs(
          data,
          "Nomina_TW_" + diaActual + "-" + mesActual + EXCEL_EXTENSION
        );
      }
    });
  };

  const selectionFilterStatus = (e: any) => {
    const value = e.target.value.code;
    let filter_Status = { ...filterStatus };
    filter_Status["busPerStatus"].constraints[0].value = value;
    setFilterStatus(filter_Status);
    setSelectedStatus(e.target.value);
  };

  const selectProgreso = (resultProgresos: any) => {
    let circleClass = "";
    if (resultProgresos.name == "circleRed") {
      circleClass = "circleRed";
    } else if (resultProgresos.name == "circleYellow") {
      circleClass = "circleYellow";
    } else if (resultProgresos.name == "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <div className={circleClass}></div>
      </>
    );
  };

  /*const selectionFilterId = (e: any) => {
    let filter_Status = { ...filterStatus };
    console.log(e.target.value);
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterId(e.target.value);
  };*/

  const selectFilterVariosId = (e: { value: any }) => {
    const business_IdContrains: any = [];
    const business_id = e.value;
    business_id.forEach(function (business_id: any) {
      business_IdContrains.push({
        value: business_id,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_IdContrains.length === 0) {
      business_IdContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [...business_IdContrains],
      },
    }));
    setFilterId(e.value);
  };

  /*const selectionFilterUsuario = (e: any) => {
    let filter_Status = { ...filterStatus };
    console.log(e.target.value);
    filter_Status["usuarioNombre"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterUsuario(e.target.value);
  };*/

  const selectionFilterUsuarioVarios = (e: { value: any }) => {
    const business_UsuarioContrains: any = [];
    const business_usuarios = e.value;
    business_usuarios.forEach(function (business_usuarios: any) {
      business_UsuarioContrains.push({
        value: business_usuarios,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_UsuarioContrains.length === 0) {
      business_UsuarioContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [...business_UsuarioContrains],
      },
    }));
    setFilterUsuario(e.value);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Toast></Toast>

        {candidates ? (
          <div className="flex flex-direction-row">
            <p className="quantityStyle mr-3 mt-2">
              Q. total registros:{" "}
              {
                candidates.filter(
                  (candidate: any) => candidate.busPerStatus == "PORVALIDAR"
                ).length
              }
            </p>
            <p className="quantityStyle mr-5 mt-2">
              Q. validados:{" "}
              {
                candidates.filter(
                  (candidate: any) => candidate.busPerStatus == "VALIDADO"
                ).length
              }{" "}
            </p>
          </div>
        ) : (
          ""
        )}

        <Menu model={items} popup ref={menuDescartar} id="popup_menu" />
        <div className="mt-4">
          <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-info p-button-outlined mr-3"
            aria-label="Search"
            tooltip="Validar seleccionados"
            tooltipOptions={{
              position: "left",
              style: { backgroundColor: "#D5D5D5" },
            }}
            onClick={() => changeStatusValidated(selectedCandidates, true)}
            disabled={selectedCandidates.length > 0 ? false : true}
            style={
              selectedCandidates.length > 0
                ? { color: "#0288d1" }
                : { color: "#D5D5D5" }
            }
          />

          <Button
            icon=" pi pi-times"
            className="p-button-rounded p-button-danger p-button-outlined mr-3"
            aria-label="Search"
            tooltip="Descartar Seleccionados"
            tooltipOptions={{
              position: "left",
              style: { backgroundColor: "#D5D5D5" },
            }}
            onClick={() => openModalDiscardMasive(selectedCandidates)}
            aria-controls="popup_menu"
            aria-haspopup
            disabled={selectedCandidates.length > 0 ? false : true}
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
          />

          <Button
            icon="pi pi-ban"
            className=" p-button-rounded p-button-success p-button-outlined mr-3"
            data-pr-tooltip="Bloquear candidato"
            data-pr-position="right"
            aria-label="Search"
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
            onClick={() => openModalBlokingMasive(selectedCandidates)}
            tooltip={"Bloquear seleccionados"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
            disabled={selectedCandidates.length > 0 ? false : true}
          />

          {/*
            <Button
              icon="pi pi-file-excel"
              className="p-button-rounded p-button-help p-button-outlined mr-3"
              aria-label="Search"
              onClick={exportExcel}
              tooltip={'Descarga nómina'}
            />

      */}

          <Button
            icon="pi pi-file-excel"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            onClick={exportExcelFromAzure}
            tooltip={"Descarga nómina"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
          />

          {selectedCandidates.filter(
            (candidate: any) => candidate.busPerStatus == "VALIDADO"
          ).length == selectedCandidates.length &&
          selectedCandidates.length > 0 ? (
            <Button
              id="btnAddCtx2"
              className="ml-1"
              label="Enviar a Confirmar"
              icon="pi pi-arrow-right"
              iconPos="right"
              disabled={false}
              style={{ background: "#FF4900", border: "#D5D5D5" }}
              onClick={() => changeStatusConfirmed(selectedCandidates)}
            />
          ) : (
            //al momento de activar este botón tiene que pasar al color "#FF4900"
            <Button
              id="btnAddCtx2"
              className="ml-1"
              label="Enviar a Confirmar"
              icon="pi pi-arrow-right"
              iconPos="right"
              disabled={true}
              style={{
                background: "#D5D5D5",
                border: "#FF4900",
              }}
              onClick={() => toggleModal()}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <p className="margin0 padding7y14 filter_table_text">Estado:</p>
        <Dropdown
          value={selectedStatus}
          options={statusSelect}
          onChange={(e) => selectionFilterStatus(e)}
          optionLabel="name"
          placeholder="Seleccione"
        />

        <p className="margin0 padding7y14 filter_table_text">ID:</p>
        <MultiSelect
          value={filterId}
          options={searchIdsFilter}
          onChange={(e) => {
            selectFilterVariosId(e);
          }}
          optionLabel="label"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacion}
          options={resultFechasCreacion}
          onChange={(e) => {
            filterbusiness_fechaSelected(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          id="inputTlfRutCtx1"
          //className={claseInputRut + " " + classEmptyInputRUT}
          value={filterRut}
          onChange={(e: any) => selectionFilterRut(e)}
          placeholder="1.234.567-8"
          maxLength={12}
        />

        {group.busquedas &&
          (group.busquedas.filter(
            (search: any) =>
              instance.getActiveAccount()?.localAccountId ===
              search.accountManager
          ).length > 0 ||
            ["adm_tw", "tw_admsel", "tw_admsel_prof"].includes(
              String(sessionStorage.getItem("profile"))
            )) && (
            <>
              <p className="margin0 padding7y14 filter_table_text">
                Reclutador:
              </p>
              <MultiSelect
                value={filterUsuario}
                options={optionReclutador}
                onChange={(e) => selectionFilterUsuarioVarios(e)}
                optionLabel="name"
                placeholder="Seleccione"
                selectedItemsLabel="{0} áreas seleccionadas"
                maxSelectedLabels={1}
              />
            </>
          )}

        {selectedCandidates.length > 0 ? (
          selectedCandidates.length == 1 ? (
            <p className="margin0 padding7y14 filter_table_text_validar">
              Q. seleccionado : {selectedCandidates.length}
            </p>
          ) : (
            <p className="margin0 padding7y14 filter_table_text_validar">
              Q. seleccionados : {selectedCandidates.length}
            </p>
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const circleProgress = (candidate: any) => {
    let progreso = candidate.busPerPorcCompletitud;
    let circleClass = "";
    if (progreso === "circleRed") {
      circleClass = "circleRed";
    } else if (progreso === "circleYellow") {
      circleClass = "circleYellow";
    } else if (progreso === "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <span style={{ display: "none" }}>
          {parseInt(candidate.busPerPorcCompletitud)}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={circleClass}></div>
        </div>
      </>
    );
  };

  const changeToValidatedCandidate = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusValidated(array, false);
  };

  const changeToPerValidatedCandidate = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusPerValidated(array, false);
  };

  const changeStatusNoConfirmedCandidate = (candidato: any) => {
    let array = [];
    array.push(candidato);
    changeStatusNoConfirmed(array);
  };

  const changeStatusPerValidated = (listadoValidar: any, isMasive: boolean) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/por-validar`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        if (isMasive) {
          creartToast(
            toast,
            "warn",
            "Candidatos no validados",
            "Ha desmarcado el candidato seleccionado",
            6000
          );
        } else {
          creartToast(
            toast,
            "warn",
            "Candidato no validado",
            "Ha desmarcado el candidato seleccionado",
            6000
          );
        }

        setSelectedCandidates([]);
        updateList(listadoValidar, false, false);
      });
  };

  const updateList = (
    list: any,
    checked_validado: boolean,
    checked_nocontestado: boolean
  ) => {
    const arr = [...candidates];
    list.forEach((element: { busPerId: any }) => {
      const index = arr.findIndex((x) => x.busPerId == element.busPerId);

      if (checked_validado && checked_nocontestado) {
        arr[index].busPerStatus = "NOCONFIRMADO";
      } else if (checked_validado && !checked_nocontestado) {
        arr[index].busPerStatus = "VALIDADO";
      } else if (!checked_validado && checked_nocontestado) {
        arr[index].busPerStatus = "NOCONFIRMADO";
      } else {
        arr[index].busPerStatus = "PORVALIDAR";
      }
    });
    setCandidates(arr);
  };

  const changeStatusValidated = (listadoValidar: any, isMasive: boolean) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/validado`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        if (isMasive) {
          creartToast(
            toast,
            "success",
            "Candidatos validados",
            "Contacte para confirmar su asistencia",
            6000
          );
        } else {
          creartToast(
            toast,
            "success",
            "Candidato validado",
            "Contacte para confirmar su asistencia",
            6000
          );
        }
        setSelectedCandidates([]);
        updateList(listadoValidar, true, false);
      });
  };

  const changeStatusConfirmed = (listadoValidar: any) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/confirmado`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        creartToast(
          toast,
          "success",
          "Enviados exitosamente",
          "Revisar en bandeja confirmados",
          6000
        );
        setSelectedCandidates([]);
        getCandidates();
      });
  };

  const changeStatusNoConfirmed = (listadoValidar: any) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/no-confirmado`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        creartToast(
          toast,
          "info",
          "Intento de contacto registrado",
          "Candidato no confirma asistencia",
          6000
        );
        setSelectedCandidates([]);
        updateList(listadoValidar, true, true);
      });
  };

  const openModalDiscard = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosADescartar(array);
    setVisibleModalDiscard(true);
  };
  const openModalDiscardMasive = (candidatosADescartar: any[]) => {
    setVisibleModalDiscard(true);
    setCandidatosADescartar(candidatosADescartar);
  };

  const openModalChangeGroup = (rowData: any) => {
    setCandidateModal(rowData);
    setVisibleModalChangeGroup(true);
  };

  const cerrarModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
  };

  const saveModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
    getCandidates();
    creartToast(
      toast,
      "success",
      "Cambio de grupo",
      "Guardado correctamente",
      3000
    );
    setSelectedCandidates([]);
  };

  const unBlocking = (candidate: any) => {
    const data = {
      excPerId: candidate.warningData[0].excPerId,
      usuarioId: instance.getActiveAccount()?.localAccountId,
      busPerId: candidate.busPerId,
    };
    api
      .put(`exclusion-persona-recover`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getCandidates();
        getCandidatesNoAdmit2();
        creartToast(toast, "warn", "Exitoso", "Se reincorpora registro", 6000);
      })
      .catch(() => {
        setVisibleModalError(true);
      });
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <Button
            icon="pi pi-undo"
            className="tooltip-button ml-2 p-button-rounded p-button-text p-button-info"
            tooltip="Mover a reclutar"
            tooltipOptions={{
              className: "bluegray-tooltip",
              position: "right",
            }}
            type="button"
            onClick={() => backToReclutamiento(rowData)}
          />
          <Tooltip target=".des"></Tooltip>
          {instance.getActiveAccount()?.localAccountId == rowData.userId &&
          rowData.isOwner ? (
            <>
              <i
                className="des material-symbols-outlined md-24 iconBlue cursor-pointer mx-2"
                data-pr-tooltip="Editar datos candidato"
                data-pr-position="right"
                onClick={() => {
                  handleClickVerificarRut(rowData);
                }}
              >
                edit_square
              </i>
              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Descartar"}
                data-pr-position="right"
                onClick={() => openModalDiscard(rowData)}
              >
                close
              </i>

              <i
                className="des material-symbols-outlined md-24 iconBlue mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Cambiar candidato de ID "}
                data-pr-position="right"
                onClick={() => toggleModalChangeId(rowData)}
              >
                drive_file_move
              </i>
              <span
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip={"Bloquear"}
                data-pr-position="right"
                onClick={() => openModalBlocking(rowData)}
              >
                block
              </span>

              <i
                className="des material-symbols-outlined md-24 iconCGreen mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
                onClick={() => openModalChangeGroup(rowData)}
              >
                groups
              </i>
            </>
          ) : (
            <>
              <i
                className="des material-symbols-outlined md-24 iconBlue cursor-pointer mx-2"
                data-pr-tooltip="Editar datos candidato"
                data-pr-position="right"
                onClick={() => {
                  handleClickVerificarRut(rowData);
                }}
              >
                edit_square
              </i>
              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip={"Descartar"}
                data-pr-position="right"
              >
                close
              </i>

              <i
                className="des material-symbols-outlined md-24 iconBlue mx-2 mt-1 iconGray"
                data-pr-tooltip={"Cambiar candidato de ID "}
                data-pr-position="right"
              >
                drive_file_move
              </i>
              <span
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip={"Bloquear"}
                data-pr-position="right"
              >
                block
              </span>

              <i
                className="des material-symbols-outlined md-24  mx-2 mt-1 iconGray"
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
              >
                groups
              </i>
            </>
          )}

          {rowData.warningData[0] ? (
            instance.getActiveAccount()?.localAccountId ==
            rowData.warningData[0].userId ? (
              <i
                className="des material-symbols-outlined md-18 iconCCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Deshacer Descarte"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  };

  const statusSelect = [
    { name: "Todos", code: "" },
    { name: "Por Validar", code: "PORVALIDAR" },
    { name: "Validado", code: "VALIDADO" },
  ];

  const validadoTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="field-checkbox ml-3">
            {rowData.busPerStatus == "VALIDADO" ||
            rowData.busPerStatus == "NOCONFIRMADO" ? (
              <Checkbox
                inputId="binary"
                className="chkRed"
                tooltip="Validar candidato"
                tooltipOptions={{ position: "left" }}
                checked={true}
                onChange={() => changeToPerValidatedCandidate(rowData)}
                style={{ backgroundColor: "#FF4900" }}
              />
            ) : (
              <Checkbox
                inputId="binary"
                tooltip="Validar candidato"
                tooltipOptions={{ position: "left" }}
                checked={false}
                onChange={() => changeToValidatedCandidate(rowData)}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const noAnswerTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="field-checkbox ml-3">
            {rowData.busPerStatus == "NOCONFIRMADO" ? (
              <Checkbox
                inputId="binary"
                //className='chkRed'
                tooltip="Intento de contacto"
                tooltipOptions={{ position: "left" }}
                checked={true}
                onChange={() => changeToValidatedCandidate(rowData)}
                style={{ backgroundColor: "#FF4900" }}
              />
            ) : rowData.busPerStatus == "VALIDADO" ? (
              <Checkbox
                inputId="binary"
                tooltip="Intento de contacto"
                tooltipOptions={{ position: "left" }}
                checked={false}
                onChange={() => changeStatusNoConfirmedCandidate(rowData)}
              />
            ) : (
              <Checkbox
                inputId="binary"
                tooltip="Intento de contacto"
                tooltipOptions={{ position: "left" }}
                checked={false}
                disabled={true}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const headerPhoneTemplate = () => {
    return (
      <>
        Teléfono 1
        <i className="ml-3" style={{ color: "gray" }}>
          <WhatsAppIcon fontSize="small" />
        </i>
      </>
    );
  };

  const headerEmailTemplate = () => {
    return (
      <>
        Correo
        <i className="ml-3" style={{ color: "gray" }}>
          <AlternateEmail fontSize="small" />
        </i>
      </>
    );
  };

  //Llamadas a Apis

  const getCandidates = () => {
    getToken();
    if (token) {
      api
        .get(`busqueda-persona-allowed`, {
          params: {
            usuarioId: instance.getActiveAccount()?.localAccountId,
            cargoGroupId: busquedaId,
            dateNow: "no",
            limit: 1000,
            clearPerson: "si",
            busPerStatus:
              status_porvalidar +
              "," +
              status_validado +
              "," +
              status_no_confirmado,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoadingCandidates(true);
          const results = response.data.data;
          setCandidates(results);
        });
    }
  };

  const exportExcelFromAzure = () => {
    const filterData = {
      filtros: {
        fuentes: [],
        procesos: filterId || [],
        rut: filterRut,
        reclutadores: filterUsuario !== "" ? filterUsuario : [],
        estados: selectedStatus.code === "" ? [] : [selectedStatus.code],
        progresos: [],
        fechas:
          fechaCreacion != null ? fechaCreacion.map((f: any) => f.code) : [],
      },
    };

    getToken();
    api
      .post(`busqueda/create-excel-group/`, filterData, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: busquedaId,
          busPerStatus: status_validado
            .concat(",")
            .concat(status_porvalidar)
            .concat(",")
            .concat(status_no_confirmado),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "nomina.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch(() => {
        // setVisibleModalErrorUpload(true);
      });
  };

  const getbusquedaGroup = () => {
    const tokeeen = sessionStorage.getItem("token");
    api
      .get(`busqueda-group/`, {
        params: {
          cargoGroupId: busquedaId,
          userId: instance.getActiveAccount()?.localAccountId,
        },
        headers: {
          Authorization: `Bearer ${tokeeen}`,
        },
      })
      .then((response) => {
        setGroup(response.data.data[0][0]);
      })
      .catch(() => {
        console.log("Error al descargar");
      });
  };

  const descartarPorCandidatos = (discardedCandidates: any[]) => {
    const listaDescarte = discardedCandidates.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_persona,
        comentario: inputModalCandidatos,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        creartToast(
          toast,
          "error",
          "Candidatos descartados",
          "Almacenados en los registros",
          6000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorCandidato(false);
        setInputModalCandidatos("");
      })
      .catch(() => {});
  };

  const descartarPorClientes = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_cliente,
        comentario: inputModalClientes,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por cliente",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorCliente(false);
        setInputModalClientes("");
      })
      .catch(() => {});
  };

  const descartarPorPerfil = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_perfil,
        comentario: inputModalPerfiles,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por Conducta Inadecuada",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit2();
        setSelectedCandidates([]);
        setVisiblePorPerfil(false);
        setInputModalPerfiles("");
      })
      .catch(() => {});
  };

  const backToReclutamiento = (candidate: any) => {
    callStatusUpDateIngresado(candidate);
  };

  const paintId = (rowData: any) => {
    return <>{rowData.busquedaId == idGrupo ? "" : rowData.busquedaId}</>;
  };

  const callStatusUpDateIngresado = (candidate: any) => {
    getToken();
    api
      .put(
        `busqueda-persona/status/ingresado`,
        {
          orgId: candidate.orgId,
          list: [
            {
              busPerId: candidate.busPerId,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        creartToast(
          toast,
          "success",
          "Enviado Exitosamente",
          "Candidato enviado a reclutamiento",
          3000
        );
        getCandidates();
        setSelectedCandidates([]);
      })
      .catch(() => {});
  };

  const handleClickUpdateCandidate = (rowData: any) => {
    setCandidateModal(rowData);
  };

  const handleClickVerificarRut = (rowData: any) => {
    const { personaDni } = rowData;
    handleClickUpdateCandidate(rowData);
    // console.log({candidateModal})
    getRutVerificado({
      personaRut: personaDni,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
      token,
    });
    getDocument({ personaDNI: personaDni, token });
  };

  const openModalBlocking = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosABloquear(array);
    setVisibleModalBlocking(true);
  };

  const openModalBlokingMasive = (candidatosABloquear: any[]) => {
    setVisibleModalBlocking(true);
    setCandidatosABloquear(candidatosABloquear);
  };

  const saveModalBloking = () => {
    setVisibleModalBlocking(false);
    setCandidatosABloquear([]);
    getCandidates();
    creartToast(toast, "error", "Bloqueo", "Guardado correctamente", 3000);
    getCandidatesNoAdmit2();
  };

  const cerrarModalBlocking = () => {
    setCandidatosADescartar([]);
    setVisibleModalBlocking(false);
  };

  const painWaring = (rowData: any) => {
    return (
      <>
        {rowData.warningData && rowData.warningData.length > 0 ? (
          <span
            className="material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
            onClick={() => openWarningInfo(rowData)}
          >
            warning
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const openWarningInfo = (rowData: any) => {
    setVisibleWarningInfo(true);
    setWarnings(rowData.warningData);
  };

  //useEffect

  useEffect(() => {
    getCandidates();
    getCandidatesNoAdmit2();
    getbusquedaGroup();
    initFilters();
  }, [token]);

  useEffect(() => {
    if (token) {
      getListaGeneral({ grupo: idGrupo, token });
    }
  }, [token]);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 +
          " " +
          candidate.personaNombre2 +
          " " +
          candidate.personaApe1 +
          " " +
          candidate.personaApe2;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
      });
      setCandidates(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;

        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
      });

      setCandidatesTabla(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let progresos = candidates.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresos(unicosColores);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let fechasCreacion = candidates.map((candidate: any) => {
        return [candidate.cdcCreateDt, candidate.cdcCreateDt];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacion(unicasFechasCreacion);
      let optRecluters = candidates.map((candidate: any) => {
        return {
          name: candidate.usuarioNombre,
          value: candidate.usuarioNombre,
        };
      });
      let unicoRecluter = eliminarObjetosDuplicados(optRecluters, "name");
      const newObjR = {
        value: "",
        name: "Todos",
      };
      setOptionReclutador([newObjR, ...unicoRecluter]);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidates) {
      let cargosNombres = candidates.map((candidate: any) => {
        return [candidate.cargoNombre, candidate.cargoId];
      });
      let mappingCargos = cargosNombres.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosCargos = eliminarObjetosDuplicados(mappingCargos, "name");
      unicosCargos.sort(SortArrayByNames);
      setresultCargos(unicosCargos);
    }
  }, [candidates]);

  useEffect(() => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId,
        };
        newArr = [...newArr, newObj];
      });
      const newObj2 = {
        value: "",
        label: "Todos",
      };
      setsearchIdsFilter([newObj2, ...newArr]);
      const newObj = {
        value: "",
        label: "Sin ID",
      };
      newArr = [newObj, ...newArr];
      setsearchIds(newArr);
    }
  }, [group]);

  const getCandidatesNoAdmit2 = () => {
    const tokeeen = sessionStorage.getItem("token");
    api
      .get(`busqueda-persona-blocked/`, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: busquedaId,
          activeBusquedas: true,
        },
        headers: {
          Authorization: `Bearer ${tokeeen}`,
        },
      })
      .then((response) => {
        setCandidatesNoAdmit2(response.data.data);
      })
      .catch((error) => {
        console.log("Error al descargar");
      });
  };

  useEffect(() => {
    if (candidatesNoAdmit2) {
      candidatesNoAdmit2.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
        let status = "";
        if (
          candidate.busPerStatus === "DESCARTADO" ||
          candidate.busPerStatus === "DESVINCULADO" ||
          candidate.busPerStatus === "RECHAZADO"
        ) {
          status = "inhabilitado";
        } else {
          status = "habilitado";
        }
        candidate.status = status;
      });
      let progresos = candidatesNoAdmit2.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresosNoAdmit(unicosColores);
      let fechasCreacion = candidatesNoAdmit2.map((candidate: any) => {
        return [candidate.cdcCreateDt2, candidate.cdcCreateDt2];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(0, 2);
        let mesFechaName = x.name.substring(3, 5);
        let anioFechaName = x.name.substring(6, 10);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(0, 2);
        let mesFechaCode = x.code.substring(3, 5);
        let anioFechaCode = x.code.substring(6, 10);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacionNoAdmit2(unicasFechasCreacion);
    }
  }, [candidatesNoAdmit2]);

  const selectionFilterIdNoId2 = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit2 };
    console.log(e.target.value);
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatusNoAdmit2(filter_Status);
    setFilterIdNoAdmit2(e.target.value);
  };

  useEffect(() => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId,
        };
        newArr = [...newArr, newObj];
      });
      const newObj2 = {
        value: "",
        label: "Todos",
      };
      setsearchIdsFilter([newObj2, ...newArr]);
      setsearchIdsFilterNoAdmit2([newObj2, ...newArr]);
      const newObj = {
        value: "",
        label: "Sin ID",
      };
      newArr = [newObj, ...newArr];
      setsearchIds(newArr);
    }
  }, [group]);

  const recruitmentSources = [
    { name: "Todos", code: "" },
    { name: "BNE", code: "BNE" },
    { name: "Chile Trabajos", code: "Chile Trabajos" },
    { name: "CompuTrabajo", code: "CompuTrabajo" },
    { name: "Estados de WhatsApp", code: "Estados de WhatsApp" },
    { name: "Facebook pagado Team-Work", code: "Facebook pagado Team-Work" },
    { name: "Facebook personal", code: "Facebook personal" },
    { name: "Hiringroom", code: "Hiringroom" },
    { name: "Instagram Pagado Team-Work", code: "Instagram Pagado Team-Work" },
    { name: "Instagram Personal", code: "Instagram Personal" },
    { name: "Laborum", code: "Laborum" },
    { name: "OMIL", code: "OMIL" },
    { name: "Referido Candidato", code: "Referido Candidato" },
    { name: "Referido Colaborador", code: "Referido Colaborador" },
    { name: "Yapo", code: "Yapo" },
    { name: "WhatsApp", code: "WhatsApp" },
    { name: "Otro", code: "Otro" },
    { name: "Data de Respaldo", code: "DataRespaldo" },
  ];

  const leftToolbarTemplateDataTableTwo2 = () => {
    return (
      <>
        <span className="material-symbols-outlined md-24 mt-1 iconBlue">
          filter_alt
        </span>
        <p className="margin0 padding7y14 filter_table_text">
          Fuente Reclutamiento:
        </p>
        <Dropdown
          value={recruitmentSourceFilterNoAdmit2}
          options={recruitmentSources}
          onChange={(e) => selectionFilterFuenteNoAdmit(e)}
          optionLabel="name"
          placeholder="Seleccione"
        />
        {/*<p className="margin0 padding7y14 filter_table_text">ID:</p>
      <Dropdown
        value={filterIdNoAdmit2}
        options={searchIdsFilterNoAdmit2}
        onChange={(e) => selectionFilterIdNoId2(e)}
        optionLabel="label"
        placeholder="Seleccione"
  />*/}

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacionNoAdmit2}
          options={resultFechasCreacionNoAdmit2}
          onChange={(e) => {
            filterbusiness_fechaSelectedNoAdmit2(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          value={filterRutNoAdmit}
          onChange={(e: any) => selectionFilterRutNoAdmit(e)}
          placeholder="11.234.567-8"
          maxLength={12}
          keyfilter={/^[^#<>*!]+$/}
        />
      </>
    );
  };

  const selectionFilterRutNoAdmit = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRutNoAdmit(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatusNoAdmit2(filter_Status);
  };

  const filterbusiness_fechaSelectedNoAdmit2 = (e: { value: any }) => {
    console.log(e.value);
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatusNoAdmit2((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacionNoAdmit2(e.value);
  };

  const selectionFilterFuenteNoAdmit = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit2 };
    console.log(e.target.value);
    filter_Status["fuenteOrg"].constraints[0].value = e.target.value.code;
    setFilterStatusNoAdmit2(filter_Status);
    setRecruitmentSourceFilterNoAdmit2(e.target.value);
  };

  const actionsBlock = (rowData: any) => {
    return (
      <>
        {" "}
        {rowData.warningData[0] ? (
          instance.getActiveAccount()?.localAccountId ==
          rowData.warningData[0].userId ? (
            <div className="flex flex-row">
              <Tooltip target=".bloquear"></Tooltip>
              <i
                className="bloquear material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Desbloquear"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const alertasDesing = (rowData: any) => {
    return (
      <>
        {rowData.status === "inhabilitado" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            <p style={{ margin: "0", paddingLeft: "10px", color: "#D32F2F" }}>
              Candidato Inhabilitado
            </p>
            <p style={{ margin: "0", paddingLeft: "10px" }}>No Contactar</p>
          </div>
        ) : rowData.busPerStatus === "CONTRATADO" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            <p style={{ margin: "0", paddingLeft: "10px", color: "#2A63D5" }}>
              Candidato con contrato vigente
            </p>
            <p style={{ margin: "0", paddingLeft: "10px" }}>
              No disponible, no Contactar
            </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  //main return

  return (
    <>
      <Toast ref={toast} position="top-right"></Toast>
      {toasts}
      <div className="layout-main-container">
        <div className="layout-main">
          <div className="grid" style={{ paddingBottom: "28px" }}>
            <div className="col">
              <a
                href="/procesosasignadosactivos"
                style={{
                  color: "#AEAEAE",
                  padding: "6px",
                  borderRadius: "50%",
                  border: "1px solid #AEAEAE",
                  width: "28px",
                }}
                aria-label="Search"
                className="flex justify-content-center align-items-center"
              >
                <i className="pi pi-arrow-left"></i>
              </a>
            </div>

            <Toolbar right={rightContents}></Toolbar>
          </div>

          <div>
            {group ? (
              <h2
                className="flex justify-content-center"
                style={{
                  height: "39px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "25px",
                  alignItems: "center",
                  color: "#333333",
                }}
              >
                {group.cargoGroupName}
              </h2>
            ) : (
              ""
            )}

            <span className="flex justify-content-center">
              En esta opción puede armar la lista de candidatos que enviará a
              validar. Luego los candidatos que han sido
            </span>
            <span className="flex justify-content-center mt-2">
              validados y contáctelos para confirmar su asistencia. Si el
              candidato no contesta para confirmar su asistencia,
            </span>
            <span className="flex justify-content-center mb-6 mt-2">
              seleccionelo y retome ese intento de contacto más tarde.{" "}
            </span>
          </div>

          {group.busquedas
            ? group.busquedas.map((busqueda: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="flex flex-wrap justify-content-between surface-200 pt-3 border-round my-3"
                  >
                    <div className="flex flex-row ml-8">
                      {busqueda.busquedaUrgencia === 2 ? (
                        <span className="circuloRed alignIcon"></span>
                      ) : busqueda.busquedaUrgencia === 1 ? (
                        <span className="circuloYellow alignIcon"></span>
                      ) : busqueda.busquedaUrgencia === 0 ? (
                        <span className="circuloGreen alignIcon"></span>
                      ) : (
                        ""
                      )}

                      <span className="ml-2">
                        {busqueda.busquedaId} - {busqueda.cargoNombre} -{" "}
                        {busqueda.clienteNombre}{" "}
                      </span>
                    </div>

                    <div className="flex flex-row mr-8">
                      <p className="vacantantesMsg">
                        {" "}
                        Q. Vacantes abiertas : {busqueda.busquedaCantidad}
                      </p>
                      <p className="mx-3 vacantantesMsg">
                        {" "}
                        Q. Vacantes cubiertas: 8
                      </p>
                      <p className="vacantantesMsg">
                        {" "}
                        Fecha entrega : {convertToDate2(busqueda.busquedaFin)}
                      </p>
                    </div>
                  </div>
                );
              })
            : ""}

          <div>
            <h2
              className="p-1"
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "21px",
                lineHeight: "34px",
                color: "#2A63D5",
                marginTop: "30px",
              }}
            >
              Lista de candidatos para enviar a validar
            </h2>
          </div>

          <Toolbar
            className="mb-4 p-toolbar2 stickyFunction overflow-x-auto"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
            style={{ background: "#F8FCFF" }}
          ></Toolbar>

          {!loadingCandidates && (
            <ProgressSpinner
              className="flex justify-content-center"
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          )}

          <div className="accordionCandidates mr-3 my-4">
            <Accordion activeIndex={0}>
              <AccordionTab header="Postulantes">
                {candidatesTabla && loadingCandidates && (
                  <DataTable
                    id="tblAddCtx2"
                    value={candidatesTabla}
                    filters={filterStatus}
                    responsiveLayout="scroll"
                    emptyMessage="No hay registros."
                    paginator={candidatesTabla.length < 15 ? false : true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                    currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                    rows={15}
                    rowsPerPageOptions={[15, 30, 50]}
                    selection={selectedCandidates}
                    onSelectionChange={(e) => setSelectedCandidates(e.value)}
                    dataKey="busPerId"
                    selectionMode="checkbox"
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3em" }}
                    ></Column>
                    <Column
                      field="fullname"
                      header="Nombre"
                      style={{ width: "250px" }}
                      sortable
                    ></Column>
                    <Column
                      field="personaDniFormat"
                      header="Rut"
                      sortable
                      style={{ width: "130px" }}
                    ></Column>
                    <Column
                      field="wspFormat"
                      header={headerPhoneTemplate}
                      style={{ width: "150px" }}
                    ></Column>
                    <Column
                      field="email"
                      header={headerEmailTemplate}
                      style={{ width: "130px" }}
                    ></Column>
                    <Column
                      field="busquedaId"
                      header="ID"
                      body={paintId}
                    ></Column>
                    {/*
              <Column field="cargoNombre" header="Cargo" sortable style={{ width: '250px' }}></Column>
            */}
                    <Column
                      field="busPerPorcCompletitud"
                      header="Progreso"
                      body={circleProgress}
                      exportable={false}
                      style={{ width: "100px" }}
                      sortable
                      filterElement={selectProgreso}
                    ></Column>
                    <Column
                      header="Acciones"
                      body={actionBodyTemplate}
                      exportable={false}
                      style={{ width: "100px" }}
                    ></Column>
                    <Column
                      field="cdcCreateDt2"
                      header="Fecha postulación"
                      style={{ minWidth: "5rem" }}
                    ></Column>
                    {group.busquedas &&
                      (group.busquedas.filter(
                        (search: any) =>
                          instance.getActiveAccount()?.localAccountId ===
                          search.accountManager
                      ).length > 0 ||
                        ["adm_tw", "tw_admsel", "tw_admsel_prof"].includes(
                          String(sessionStorage.getItem("profile"))
                        )) && (
                        <Column
                          field="usuarioNombre"
                          header="Reclutador"
                        ></Column>
                      )}
                    <Column
                      field="warning"
                      header="Alerta"
                      body={painWaring}
                    ></Column>
                    <Column
                      field="Validado"
                      header="Validado"
                      body={validadoTemplate}
                      headerStyle={{ width: "2em" }}
                    ></Column>
                    <Column
                      field="NoContesta"
                      header="No Contesta"
                      body={noAnswerTemplate}
                      headerStyle={{ width: "3em" }}
                    ></Column>
                  </DataTable>
                )}
              </AccordionTab>
            </Accordion>
          </div>

          <Toolbar
            className="overflow-x-auto"
            style={{ marginTop: "2rem" }}
            left={leftToolbarTemplateDataTableTwo2}
            right={""}
          ></Toolbar>
          <div className="accordionCandidates mr-3 my-4">
            <Accordion activeIndex={0}>
              <AccordionTab header="Postulantes no admitidos">
                {candidatesNoAdmit2 && (
                  <DataTable
                    id="tblAddCtx2"
                    value={candidatesNoAdmit2}
                    responsiveLayout="scroll"
                    emptyMessage="No hay registros."
                    paginator={candidatesNoAdmit2.length <= 15 ? false : true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport "
                    currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                    rows={15}
                    rowsPerPageOptions={[15, 30, 50]}
                    dataKey="busPerId"
                    scrollable
                    scrollHeight="600px"
                    filters={filterStatusNoAdmit2}
                  >
                    <Column field="fullname" header="Nombre" sortable></Column>
                    <Column field="wspFormat" header="Teléfono 1"></Column>
                    <Column
                      field="personaDniFormat"
                      header="Rut"
                      style={{ width: "130px" }}
                    ></Column>
                    <Column
                      field="fuenteOrg"
                      header="Fuente Reclutamiento"
                    ></Column>
                    <Column
                      field=""
                      header="Acciones"
                      body={actionsBlock}
                    ></Column>
                    <Column
                      field="status"
                      body={alertasDesing}
                      header="Alerta"
                      style={{ minWidth: "5rem" }}
                      exportable={false}
                    ></Column>
                    <Column
                      field="cdcCreateDt2"
                      header="Fecha postulación"
                      style={{ minWidth: "5rem" }}
                    ></Column>
                  </DataTable>
                )}
              </AccordionTab>
            </Accordion>
          </div>

          <Dialog
            header="Motivo de descarte candidatos"
            visible={visiblePorCandidato}
            onHide={() => setVisiblePorCandidato(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterCandidate()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex flex-column justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  autoResize
                  value={inputModalCandidatos}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalCandidatos(e.target.value)}
                  maxLength={60}
                />
                {inputModalCandidatos.length >= 60 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 60 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Motivo de descarte cliente"
            visible={visiblePorCliente}
            onHide={() => setVisiblePorCliente(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterClient()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex flex-column justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  autoResize
                  value={inputModalClientes}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalClientes(e.target.value)}
                  maxLength={60}
                />
                {inputModalClientes.length >= 60 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 60 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Motivo de descarte Perfil"
            visible={visiblePorPerfil}
            onHide={() => setVisiblePorPerfil(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "442px", height: "395px" }}
            footer={renderFooterProfile()}
            closable={false}
            draggable={false}
          >
            <div className="flex flex-column align-items-center">
              <div className="mt-3 mb-4 mr-4 ml-5">
                <label htmlFor="inputName2Ctx1">
                  Estas descartando todos los candidatos seleccionados. Por
                  favor ingresa el motivo.
                </label>
              </div>
              <div className="flex flex-column justify-content-center">
                <InputTextarea
                  id="inputName2Ctx1"
                  rows={9}
                  cols={40}
                  autoResize
                  value={inputModalPerfiles}
                  style={{ width: "339px", height: "152px" }}
                  //disabled={isDisabled}
                  onChange={(e) => setInputModalPerfiles(e.target.value)}
                  maxLength={60}
                />
                {inputModalPerfiles.length >= 60 && (
                  <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                    Ha alcanzado el máximo de caracteres: 60 máximo.
                  </small>
                )}
              </div>
            </div>
          </Dialog>

          <ModalError
            isModalVisible={visibleModalError}
            onClose={cerrarModalError}
            token={token}
          ></ModalError>

          <ModalChangeId
            isModalVisible={visibleModalChangeId}
            onClose={cerrarModalChangeId}
            onCloseSave={() => saveModalChangeId()}
            token={token}
            candidate={candidateModal}
            group={group}
          ></ModalChangeId>

          <ModalChangeGroup
            isModalVisible={visibleModalChangeGroup}
            onClose={cerrarModalChangeGroup}
            onCloseSave={() => saveModalChangeGroup()}
            token={token}
            candidate={candidateModal}
            group={group}
          ></ModalChangeGroup>

          <ModalDiscard
            isModalVisible={visibleModalDiscard}
            onClose={cerrarModalDiscard}
            onCloseSave={() => saveModalDiscard()}
            token={token}
            candidate={candidatosADescartar}
            group={group}
          ></ModalDiscard>

          <ModalBlocking
            isModalVisible={visibleModalBlocking}
            onClose={cerrarModalBlocking}
            onCloseSave={() => saveModalBloking()}
            token={token}
            candidate={candidatosABloquear}
            group={group}
          ></ModalBlocking>
          <Toast ref={toast}></Toast>

          <Dialog
            visible={visibleWarningInfo}
            footer={" "}
            style={{ width: "30vw" }}
            closable
            onHide={() => setVisibleWarningInfo(false)}
          >
            {warnings && warnings.length > 0 ? (
              <>
                <div className="flex alignIcon mb-5">
                  <span className=" material-symbols-outlined md-36 iconCYellow mx-2 cursor-pointer">
                    warning
                  </span>
                  <label
                    className="ml-3 "
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "",
                      fontSize: "23px",
                      lineHeight: "26px",
                      alignItems: "center",
                      fontWeight: "600",
                      color: "#333333",
                    }}
                  >
                    Candidato con Restricciones:{" "}
                  </label>
                </div>

                {warnings.map((warning: any) => {
                  return (
                    <>
                      <p className="alignIcon">
                        <span className="material-symbols-outlined md-24 iconCYellow mx-2 cursor-pointer align-self-center align-items-center">
                          chevron_right
                        </span>
                        Descartado en ID {warning.busquedaId} favor no
                        considerar
                      </p>
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )}

            <br />
          </Dialog>
        </div>
      </div>
      {isOpenModalUpdateCandidate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          rut={candidateModal?.personaDni}
          setResultBusperId={setResultBusperId}
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdateCandidate}
          getCandidates={getCandidates}
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          token={token}
          requerido={true}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => setIsOpenModalCampaign(false)}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
        />
      )}
    </>
  );
};

export default ValidationTableComponentV2;
