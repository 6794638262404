import { useContext, useEffect, useState } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ModalFiltro from "../../Procesos/Modal/ModalFiltro";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";

interface IActionMasive {
  listadoSeleccionado?: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  candidates: any;
  setCandidates: Function;
}

const ActionMassive = (props: IActionMasive) => {
  const { localStorageFilter, setFormFilter, formFilter } = props;
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <div className="container-actions margin-0">
        <div className="content-actions-masive">
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="RECLUTADOR"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="reclutador"
          />
          <InputFiltro
            layer="FECHA INHABILITADO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaInhabilitacion"
          />
        </FiltroGenerico>
      )}
    </>
  );
};

export default ActionMassive;
