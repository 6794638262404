import { Button } from "primereact/button";
import "../../../assets/css/adminModal.min.css";
import { useState } from "react";
import Select from "../../ui/adminAssist/Select";
import SelectOption from "../../ui/adminAssist/SelectOption";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import CloseIcon from "@mui/icons-material/Close";

interface IReasingServiceModal {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  width?: string;
  list?: any[];
}

export default (props: IReasingServiceModal) => {
  const { title, subTitle, message, onChangeBack, onChangeGo, width, list } =
    props;
  const [visibleInfo, setVisibleInfo] = useState<boolean>(false);

  const handleChangeVisibleInfo = () => {
    setVisibleInfo(visibleInfo === true ? false : true);
  };
  return (
    <>
      <div className="modal-container-admin">
        <div className="modal-content-admin" style={{ width: width }}>
          <span
            className="modal-close"
            onClick={() => {
              if (onChangeBack) onChangeBack();
            }}
          >
            <CloseIcon /> 
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle" style={{ textAlign: "start" }}>
            <span>{subTitle}</span>
          </div>
          <div className="modal-message" style={{ textAlign: "start" }}>
            <span>{message}</span>
          </div>
          {list && (
            <>
              {list.map((code, key) => {
                return (
                <>
                  <div className="modal-section-reasing" key={key}>
                    <span>Servicio N°{code}</span>
                    <ButtonIcon onClick={handleChangeVisibleInfo}>
                      {visibleInfo ? (
                        <KeyboardArrowUpIcon style={{ fontSize: "35px" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ fontSize: "35px" }} />
                      )}
                    </ButtonIcon>
                  </div>

                  <div
                    style={
                      visibleInfo ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="modal-title-table">
                      <span>Proceso Asignado</span>
                    </div>
                    <div style={{ width: "100%",overflowX: "auto" }}>
                      <table className="modal-table">
                        <tr className="modal-tr-table">
                          <td className="modal-td-title-table">Proceso</td>
                          <td className="modal-td-title-table">Cliente</td>
                          <td className="modal-td-title-table">Candidatos</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-content-table">
                            123456 - AUXILIAR DE OLLA TARDE
                          </td>
                          <td className="modal-td-content-table">CAROZZI</td>
                          <td className="modal-td-content-table">10</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-title-table">Destino</td>
                          <td className="modal-td-title-table">Fecha</td>
                          <td className="modal-td-title-table">Hora</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-content-table">
                            Av. El Salto 4447, Huechuraba, Región Metropolitana
                          </td>
                          <td className="modal-td-content-table">29/01/2023</td>
                          <td className="modal-td-content-table">14:15</td>
                        </tr>
                      </table>
                    </div>

                    <div className="modal-title-table">
                      <span>Transporte Asignado</span>
                    </div>
                    <div style={{ width: "100%",overflowX: "auto" }}>
                      <table className="modal-table">
                        <tr className="modal-tr-table">
                          <td className="modal-td-title-table">Nombre</td>
                          <td className="modal-td-title-table">Correo</td>
                          <td className="modal-td-title-table">Fono</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-content-table">
                            Olegario Cepeda Asenjo
                          </td>
                          <td className="modal-td-content-table">
                            nombre@correo.cl
                          </td>
                          <td className="modal-td-content-table">
                            +569 12345678
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="modal-title-table">
                      <span>Ruta Asignado</span>
                    </div>
                    <div style={{ width: "100%",overflowX: "auto" }}>
                      <table className="modal-table">
                        <tr className="modal-tr-table">
                          <td className="modal-td-title-table">Inicio</td>
                          <td className="modal-td-title-table">Fecha</td>
                          <td className="modal-td-title-table">Hora</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-content-table">
                            Av. El Salto 4447, Huechuraba, Región Metropolitana
                          </td>
                          <td className="modal-td-content-table">29/01/2023</td>
                          <td className="modal-td-content-table">14:15</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-title-table">Destino</td>
                          <td className="modal-td-title-table">Fecha</td>
                          <td className="modal-td-title-table">Hora</td>
                        </tr>
                        <tr className="modal-tr-table">
                          <td className="modal-td-content-table">
                            Av. El Salto 4447, Huechuraba, Región Metropolitana
                          </td>
                          <td className="modal-td-content-table">29/01/2023</td>
                          <td className="modal-td-content-table">15:15</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </>
                )
              })}
            </>
          )}

          <div className="modal-message" style={{ textAlign: "start" }}>
            <span>
              Seleccione el Tipo y Nombre del Servicio al que reasignarás.
            </span>
          </div>
          <div className="content-bottom-send">
            <div className="content-text-date">
              <span>Tipo:</span>
              <Select style={{ width: "100%" }}>
                <SelectOption
                  value={"Seleccione"}
                  text="Seleccione"
                ></SelectOption>
              </Select>
            </div>
            <div className="content-text-date">
              <span>Nombre:</span>
              <Select style={{ width: "100%" }}>
                <SelectOption
                  value={"Seleccione"}
                  text="Seleccione"
                ></SelectOption>
              </Select>
            </div>
          </div>
          <div className="modal-message" style={{ textAlign: "start" }}>
            <span>Para confirmar presiona guardar.</span>
          </div>
          <div className="button-content">
            <Button
              className="button-back"
              onClick={() => {
                if (onChangeBack) onChangeBack();
              }}
            >
              Cancelar
            </Button>
            <Button
              className="button-go-confirm"
              onClick={() => {
                if (onChangeGo) onChangeGo();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
