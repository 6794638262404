import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
  naranja?:boolean
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover, naranja } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
     <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill={naranja ? '#BE2A2A' :"#FF4900"}/>
<path d="M9.76759 4.42182L9.64599 11.2453H8.3505L8.2289 4.42182H9.76759ZM9.00058 14.0889C8.7418 14.0889 8.52043 13.9984 8.33647 13.8176C8.15252 13.6336 8.0621 13.4123 8.06522 13.1535C8.0621 12.8978 8.15252 12.6796 8.33647 12.4987C8.52043 12.3148 8.7418 12.2228 9.00058 12.2228C9.25313 12.2228 9.47139 12.3148 9.65534 12.4987C9.8393 12.6796 9.93284 12.8978 9.93595 13.1535C9.93284 13.325 9.88763 13.4824 9.80033 13.6259C9.71614 13.7662 9.6039 13.8784 9.46359 13.9626C9.32329 14.0468 9.16895 14.0889 9.00058 14.0889Z" fill="#FAFAFA"/>
       
   
   
    </svg>
  );
};
