import { useContext, useEffect, useState } from "react";
import { formatDateToDDMMAAAA } from "../../../../../functions/kit-mdtw/dates";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ActionMassive from "./ActionMassive";
import {
  ICandidatoInhabilitado,
  ICandidatoNoDisponible,
  IListaPostulacionProcesada,
} from "../../../../../interfaces/ISeleccion";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import IconLoading from "../../../../ui/kit-svg/IconLoading";

const TablaCandidatoUltimoProcesado = () => {
  const { listaPostulacionProcesada, getPostulacionProcesada, token } =
    useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);

  const [gridPostulanteUltimoProcesado, setGridPostulanteUltimoProcesado] =
    useState<any[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroUltimoProcesado"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    nombre: [],
    proceso: [],
    procesoPostulacion: [],
    cliente: [],
    rut: [],
    medioPostulacion: [],
    fechaAsignacion: [],
  });

  useEffect(() => {
    if (token) {
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        JSON.parse(copyStorage);
        getPostulacionProcesada({
          token,
          filtros: JSON.parse(copyStorage),
          setIsLoadingTable,
          setIsErrorTable,
        });
      } else {
        getPostulacionProcesada({
          token,
          filtros: formFilter,
          setIsLoadingTable,
          setIsErrorTable,
        });
      }
    }
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaPostulacionProcesada) {
      const arrayList = listaPostulacionProcesada.map(
        (item: IListaPostulacionProcesada) => item.personaDNI
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaPostulacionProcesada]);

  useEffect(() => {
    if (listaPostulacionProcesada) {
      let arrayGridFilter = [...listaPostulacionProcesada];

      setLengthFilters(arrayGridFilter.length);
      if (range) {
        const { initRange, endRange } = range;
        arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
      }
      setGridPostulanteUltimoProcesado(arrayGridFilter);
    }
  }, [listaPostulacionProcesada, range]);

  return (
    <>
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <div className="content-multiple">
          <label className="title-multiple">
            <i className="selects-table-registro">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
        <ActionMassive
          listadoSeleccionado={listadoSeleccionado}
          localStorageFilter={localStorageFilter}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          candidates={candidates}
          setCandidates={setCandidates}
        />
      </section>

      <section className="content-header-table">
        <label className="title-header-table">Últimos Procesados</label>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <main className="container-table-list">
                <table className="content-table">
                  <thead className="thead-table-registros">
                    <tr>
                      <th className="th-table-registros">Nombre</th>
                      <th className="th-table-registros">Rut</th>
                      <th className="th-table-registros">
                        Proceso <br></br>Asignado
                      </th>
                      <th className="th-table-registros">Cliente</th>
                      <th className="th-table-registros">
                        Fecha <br></br>Postulación
                      </th>
                      <th className="th-table-registros">
                        Medio <br></br>Postulación
                      </th>
                      <th className="th-table-registros">
                        Fecha <br></br>Asignación
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody-table-registros">
                    {gridPostulanteUltimoProcesado &&
                      gridPostulanteUltimoProcesado.map(
                        (item: IListaPostulacionProcesada) => {
                          const {
                            personaId,
                            personaDNI,
                            primerNombre,
                            primerApellido,
                            busquedaId,
                            division,
                            nombrePostulacion,
                            medioPostulacion,
                            fechaPostulacion,
                            fechaAsignacion,
                          } = item;
                          return (
                            <>
                              <tr
                                key={personaId}
                                className={`tr-table-registros`}
                              >
                                <td className="td-table-registros">
                                  {primerNombre} {primerApellido}
                                </td>
                                <td className="td-table-registros">
                                  {personaDNI}
                                </td>
                                <td className="td-table-registros">
                                  {division}{busquedaId}
                                </td>
                                <td className="td-table-registros">
                                  {nombrePostulacion}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaPostulacion,
                                    format: "/",
                                  })}
                                </td>
                                <td className="td-table-registros">
                                  {medioPostulacion}
                                </td>
                                <td className="td-table-registros">
                                  {formatDateToDDMMAAAA({
                                    date: fechaAsignacion,
                                    format: "/",
                                  })}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </main>
              <FooterTable
                length={lengthFilters}
                setRange={setRange}
                pageCurrent={changePage ? 1 : undefined}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}
    </>
  );
};

export default TablaCandidatoUltimoProcesado;
