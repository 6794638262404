interface IIconEnlaceProceso {
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconEnlaceProceso) => {
  const { fill, disabled } = props;

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 16"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.51905 5.26136C8.34468 4.43517 9.6833 4.43517 10.5089 5.26136C11.3346 6.08755 11.3346 7.42707 10.5089 8.25325L8.65691 10.1065C8.4649 10.2987 8.15358 10.2987 7.96157 10.1065C7.76955 9.91437 7.76955 9.60285 7.96157 9.4107L9.81358 7.55744C10.2552 7.11554 10.2552 6.39908 9.81358 5.95718C9.37198 5.51528 8.656 5.51528 8.2144 5.95718L6.36238 7.81044C5.98414 8.18894 5.98414 8.8026 6.36238 9.18109C6.55439 9.37324 6.55439 9.68477 6.36238 9.87691C6.17036 10.0691 5.85904 10.0691 5.66703 9.87691C4.90476 9.11413 4.90476 7.87741 5.66703 7.11462L7.51905 5.26136Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.47734 12.6322C4.65171 13.4584 3.31309 13.4584 2.48746 12.6322C1.66182 11.806 1.66182 10.4665 2.48746 9.64029L4.24194 7.88463C4.43395 7.69248 4.74527 7.69248 4.93729 7.88463C5.1293 8.07677 5.1293 8.3883 4.93729 8.58045L3.18281 10.3361C2.7412 10.778 2.7412 11.4945 3.18281 11.9364C3.62441 12.3783 4.34039 12.3783 4.78199 11.9364L6.5029 10.2143C6.88114 9.83581 6.88114 9.22215 6.5029 8.84365C6.31088 8.65151 6.31088 8.33998 6.5029 8.14784C6.69491 7.95569 7.00623 7.95569 7.19825 8.14784C7.96052 8.91062 7.96052 10.1473 7.19824 10.9101L5.47734 12.6322Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 4.53341C0 3.4119 0.909169 2.50273 2.03069 2.50273H10.9657C12.0872 2.50273 12.9964 3.4119 12.9964 4.53341V13.3601C12.9964 14.4816 12.0872 15.3908 10.9657 15.3908H2.03069C0.909169 15.3908 0 14.4816 0 13.3601V4.53341ZM2.03069 3.85652C1.65685 3.85652 1.35379 4.15958 1.35379 4.53341V13.3601C1.35379 13.734 1.65685 14.037 2.03069 14.037H10.9657C11.3395 14.037 11.6426 13.734 11.6426 13.3601V4.53341C11.6426 4.15958 11.3395 3.85652 10.9657 3.85652H2.03069Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.787 2.4215C1.787 1.41961 2.59919 0.607422 3.60108 0.607422H12.9693C14.0908 0.607422 15 1.51659 15 2.63811V12.0063C15 13.0381 14.1636 13.8746 13.1318 13.8746V12.5208C13.4159 12.5208 13.6462 12.2905 13.6462 12.0063V2.63811C13.6462 2.26427 13.3432 1.96121 12.9693 1.96121H3.60108C3.34687 1.96121 3.14079 2.16729 3.14079 2.4215H1.787Z"
        />
      </g>
    </svg>
  );
};
