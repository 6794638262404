import React, { useState } from "react";
import IPage from "../../interfaces/page";
import {
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";
import InvitationExtProcessTableComponent from "../ui/V2/InvitationExtProcessTableComponent";
import AsistenciaState from "../../context/Assist/AsistenciaState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import { useMsal } from "@azure/msal-react";
const msalConfig: Configuration = {
  auth: {
    clientId: "tu_client_id",
    authority: "https://login.microsoftonline.com/tu_tenant_id",
  },
};
const msalInstance = new PublicClientApplication(msalConfig);

const Invitation: React.FC<IPage> = (props) => {
  const [userId, setUserId] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>(undefined);
  const { instance, accounts } = useMsal();
  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  // useEffect(() => {
  //   loginSilence();
  // }, []);

  // const loginSilence = async () => {
  //   const loginResponse = await msalInstance.acquireTokenSilent({
  //     scopes: ["openid", "profile", "user.read"],
  //     username: String(process.env.REACT_APP_USER_INVITADO),
  //     password: String(process.env.REACT_APP_PASS_INVITADO),
  //   });
  // };

  return (
    <>
      <AsistenciaState>
        <InvitationExtProcessTableComponent
          token={token}
          setToken={setToken}
          getToken={getToken}
        />
      </AsistenciaState>
    </>
  );
};

export default Invitation;
