import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import {
  STATE_CANDIDATE,
  TYPE_LIST,
} from "../../../../../diccionary/Diccionary";
import { useParams } from "react-router-dom";
import {
  formatDateToDDMMAAAA,
  formatDateToDDMMYY,
} from "../../../../../functions/kit-mdtw/dates";
import ModalDetener from "./ModalDetener";

interface IModalConfSolicitud {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string[][];
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any;
}

export default (props: IModalConfSolicitud) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    list,
  } = props;
  const {
    token,
    listCandidatoPendienteContrato,
    sendActualizarCertificado,
    getSolicitudContrato,
  } = useContext(ValidationKamContext);

  const { idCliente } = useParams<Params>();
  const objetoSendFiltro = {};
  const [openModalFueraDePlazo, setOpenModalFueraDePlazo] =
    useState<boolean>(false);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);

  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);

  useEffect(() => {
    setListFueraPlazoModal([]);
    setHayFueraplazo(false);
    listCandidatoPendienteContrato.map((item: any) => listFueraPlazo(item));
  }, []);

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickValidateConfirm = () => {
    if (modalType && token) {
      if (modalType === "contratacion") {
      }
    }
    typeof onChangeGo == "function" && onChangeGo();
  };

  const listFueraPlazo = (item: any) => {
    let selectedDateInicio15: number | undefined;
    let selectedDateInicio30: number | undefined;

    if (item.fechaInicio) {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      const fechaInicioContrato = item.fechaInicio;

      const [yearInicio, monthInicio, dayInicio] =
        fechaInicioContrato.split("-");
      selectedDateInicio15 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) - 15
      ).setHours(0, 0, 0, 0);

      const [yearInicio30, monthInicio30, dayInicio30] =
        fechaInicioContrato.split("-");
      selectedDateInicio30 = new Date(
        Number(yearInicio30),
        Number(monthInicio30) - 1,
        Number(dayInicio30) + 30
      ).setHours(0, 0, 0, 0);

      if (
        !(
          selectedDateInicio15 <= currentDate &&
          currentDate <= selectedDateInicio30
        )
      ) {
        setHayFueraplazo(true);
        setListFueraPlazoModal((prevList: any) => [...prevList, item]);
      }
    }
  };

  const handleClickSolicitaContrato = () => {
    const busPerId = listCandidatoPendienteContrato.map((item: any) => {
      return item.busPerId;
    });

    if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
      setOpenModalFueraDePlazo(true);
    } else {
      getSolicitudContrato({ token, idCliente, busPerId, objetoSendFiltro });
      handleClickCloseModal();
    }
  };

  const handleClickActualizarSolicitudPend = () => {
    const busPerId = list.map((item: any) => {
      return item.busPerId;
    });
    sendActualizarCertificado({ token, idCliente, busPerId, objetoSendFiltro });
    handleClickCloseModal();
  };

  return (
    <>
      <div className="modal-container-validatekam-Mo">
        <div className="modal-content-validatekam-Mo w-70">
          <div className="modal-title-Mo">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle-Mo">
            <span>{subTitle}</span>
          </div>

          <div className="project-table-Mo">
            <div className="container-table-Mo">
              <table className="content-table-Mo">
                <thead>
                  <tr>
                    {modalType == "contratacion" ? (
                      <th>Usuario</th>
                    ) : (
                      <th>Nombre</th>
                    )}
                    <th>F Solicitud</th>
                    {modalType == "contratacion" ? "" : <th>Ficha</th>}
                    <th>Tipo Contrato</th>
                    {modalType == "contratacion" ? <th>Cliente</th> : ""}
                    <th>Rut</th>
                  </tr>
                </thead>
                <tbody>
                  {modalType === "contratacion"
                    ? listCandidatoPendienteContrato.map((item: any) => {
                        const {
                          busPerId,
                          busquedaId,
                          areaNegocioId,
                          personaDni,
                          primerNombre,
                          segundoNombre,
                          primerApellido,
                          sesgundoApellido,
                          busPerStatus,
                          ingresadoFecha,
                          wsp,
                          fechaValidacion,
                          areaNegocioCod,
                          tipoDocumentoValue,
                        } = item;
                        return (
                          <tr
                            key={personaDni}
                            className={
                              listCandidatoPendienteContrato.includes(
                                personaDni
                              ) &&
                              (busPerStatus === STATE_CANDIDATE.VALIDADO
                                ? "validate"
                                : busPerStatus === STATE_CANDIDATE.CONFIRMADO
                                ? "selected"
                                : "selected")
                            }
                          >
                            <td>{primerNombre + " " + primerApellido}</td>
                            <td>
                              {formatDateToDDMMAAAA({
                                date: fechaValidacion,
                                format: "/",
                              })}
                            </td>
                            <td>{tipoDocumentoValue}</td>
                            <td>{areaNegocioCod}</td>
                            <td>{personaDni}</td>
                          </tr>
                        );
                      })
                    : list.map((item: any) => {
                        const {
                          busPerId,
                          busquedaId,
                          areaNegocioId,
                          personaDni,
                          primerNombre,
                          segundoNombre,
                          primerApellido,
                          sesgundoApellido,
                          busPerStatus,
                          ingresadoFecha,
                          ficha,
                          historial,
                          areaNegocioCod,
                          tipoDocumentoValue,
                        } = item;
                        return (
                          <tr
                            key={personaDni}
                            className={
                              list.includes(personaDni) &&
                              (busPerStatus === STATE_CANDIDATE.VALIDADO
                                ? "validate"
                                : busPerStatus === STATE_CANDIDATE.CONFIRMADO
                                ? "selected"
                                : "selected")
                            }
                          >
                            <td>{primerNombre + " " + primerApellido}</td>
                            <td>
                              {formatDateToDDMMAAAA({
                                date: historial,
                                format: "/",
                              })}
                            </td>
                            <td>{ficha}</td>
                            <td>{tipoDocumentoValue}</td>
                            <td>{personaDni}</td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>

          {modalType == "contratacion" ? (
            <>
              <div className="modal-message-Mo">
                <p className="modal-message-Mo">
                  Al confirmar, se le enviará una notificación con la nomina al
                  área correspondiente.
                </p>
                <p className="modal-message2-Mo">
                  Para continuar presione Aceptar
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="modal-message-Mo">
                <p className="modal-message-Mo">
                  Al confirmar, se enviará la selección al área de contrato.
                </p>
                <p className="modal-message2-Mo">
                  Para continuar presione Aceptar
                </p>
              </div>
            </>
          )}
          <div className="modal-button-content modal-button-content-in-modal center">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "confirm"
                  }`}
                  style={{
                    width: "256px",
                    justifyContent: "center",
                    gap: "32px",
                  }}
                  onClick={() =>
                    modalType === "contratacion"
                      ? handleClickSolicitaContrato()
                      : handleClickActualizarSolicitudPend()
                  }
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
          {openModalFueraDePlazo && (
            <ModalDetener
              modalType="FueraPlazo"
              title="SOLICITUD FUERA DE PLAZO"
              subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
              buttonBack="Volver"
              onChangeBack={() => {
                setOpenModalFueraDePlazo(false);
              }}
              modalStyle={""}
              list={listFueraPlazoModal}
              onCloseModalFicha={handleClickCloseModal}
            />
          )}
        </div>
      </div>
    </>
  );
};
