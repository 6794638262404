import { useContext, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../../../../ui/kit-mdtw/InputTextCustomized";
import InputRutCustomized from "../../../../../ui/kit-mdtw/InputRutCustomized";
import InputDateCustomized from "../../../../../ui/kit-mdtw/InputDateCustomized";
import DropDownListCustomized from "../../../../../ui/kit-mdtw/DropDownListCustomized";
import LayerAgeCustomized from "../../../../../ui/kit-mdtw/LayerAgeCustomized";
import Hijos from "../../../../../ui/kit-mdtw/modals/modalUpdateCandidate/componentes/Hijos";
import InputNumberCustomized from "../../../../../ui/kit-mdtw/InputNumberCustomized";
import InputEdadHijoCustomized from "../../../../../ui/kit-mdtw/InputEdadHijoCustomized";
import InputTextAreaCustomized from "../../../../../ui/kit-mdtw/InputTextAreaCustomized";
import { InputTextarea } from "primereact/inputtextarea";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";

interface IResenas {
  list?: any[];
  rut?: string;
  formCandidate?: any;
  setFormCandidate?: Function;
  requerido?: boolean;
  contraerModal?: boolean;
  token?: string;
  busPerId?: string;
  setObjsendPsicolaboralResenaRelevante?: any;
}

export default (props: IResenas) => {
  const {
    list,
    rut,
    formCandidate,
    setFormCandidate,
    requerido,
    contraerModal,
    token,
    busPerId,
    setObjsendPsicolaboralResenaRelevante,
  } = props;

  const {
    getEvaluacionPsicolaboral,
    listEvaluacionPsicolaboral,
    getListasPerfil,
    listConclusiones,
    getInformacionPsicolaboral,
    infoAntecedentes,
  } = useContext(SeleccionContext);

  const [hidden, setHidden] = useState<boolean>(false);
  const dataArray = [
    {
      title: "Reseña Intelectual, Emocional y Social:",
      required: false,
      key: "reseniaIntelectual",
      maxLength: 300
    },
    {
      title: "Reseña Laboral:",
      required: false,
      key: "reseniaLaboral",
      maxLength: 300
    },
    {
      title: "Experiencia relevante:",
      required: false,
      key: "experienciaRelevante",
      maxLength: 300
    },
    {
      title: "Conocimientos Técnicos:",
      required: false,
      key: "conocimientosTecnicos",
      maxLength: 300
    }
  ];

  const [inputObjetos, setInputObjetos] = useState([""]);

  

  useEffect(() => {
    if (token && busPerId) getInformacionPsicolaboral({ token, busPerId });
  }, []);

  useEffect(() => {
 
    if (infoAntecedentes && infoAntecedentes.adicional) {
      setInputObjetos(
        dataArray.map(
          (data) =>
            (infoAntecedentes?.adicional &&
              infoAntecedentes?.adicional[data.key]) ||
            ""
        )
      );
    }

  }, [infoAntecedentes]);

  //const maxLength = 1000;
  const isRequired = true;

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  const handleChange = (index: number, value: string, maxLength:number) => {
    if (value.length <= maxLength) {
      const newInputObjetos = [...inputObjetos];
      newInputObjetos[index] = value; // Actualiza solo el valor en el índice
      setInputObjetos(newInputObjetos);
    }
  };

  const objetoSendResena = useMemo(() => {
    return {
      reseniaIntelectual: inputObjetos[0],
      reseniaLaboral: inputObjetos[1],
      experienciaRelevante: inputObjetos[2],
      conocimientosTecnicos: inputObjetos[3]
    };
  }, [inputObjetos]);

  useEffect(() => {
    if (setObjsendPsicolaboralResenaRelevante && setObjsendPsicolaboralResenaRelevante) {
        setObjsendPsicolaboralResenaRelevante({ ...objetoSendResena });
      
    }
  }, [objetoSendResena ]);

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">RESEÑAS Y DATOS RELEVANTES</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}

      <div>
        {dataArray.map((data, index) => (
          <div
            className={`project-table pb-2 ${hidden ? "disabled" : ""}`}
            key={index}
          >
            <div className="col-12">
              <div className="titleInput">
                {data.title}
                {data.required ? " (*)" : ""}
              </div>
              <InputTextarea
                className="textInput"
                value={inputObjetos[index]}
                onChange={(e) => handleChange(index, e.target.value, data.maxLength)}
                autoResize
                rows={2}
                cols={15}
                placeholder="Ingresa"
                maxLength={data.maxLength}
                required={data.required}
              />
              <div style={{ marginTop: "8px", float: "right" }}>
                {inputObjetos[index] ? inputObjetos[index].length : 0}/
                {data.maxLength}
              </div>
            </div>
          </div>
        ))}
      </div>

 
    </>
  );
};
