import { useState, useRef, useEffect } from "react";
import {
  ICategoriesValidationInput,
  IInputFile,
} from "../../../interfaces/IInput";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InputDateCustomized from "./InputDateCustomized";
import IconDownloadDocument from "../kit-svg/IconDownloadDocument";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { formatDateToDDMMAAAA } from "../../../functions/kit-mdtw/dates";
import IconEye_VerDocu from "../kit-svg/icon-contratos/IconEye_VerDocu";
import IconDeleteBateria from "../kit-svg/icon-contratos/procesadas/IconDeleteBateria";

export default (props: IInputFile) => {
  const {
    placeholder,
    placeholderImg,
    layer,
    required,
    summary,
    capture,
    multiple,
    accept,
    date,
    iconFile,
    iconDownload,
    errorPosition,
    layerDown,
    maxSizeMb,
    onPermaValues,
    onValueChange,
    onPropertyChange,
    enlaceDescarga,
    formatosAceptado,
    leyenda,
    requerido,
    datoRequerido,
    disabled,
    fechaExpiracionDocumento,
    iconDel,
    funcionIconDel,
    disabledIconDel,
  } = props;
  const refFile = useRef<any>();

  const [triggerError, setTriggerError] = useState<boolean>(false);
  const [triggerRequerido, setTriggerRequerido] = useState<boolean | undefined>(
    undefined
  );
  const [eventValidation, setEventValidation] = useState<string>("initial");
  const [categoriesValidation] = useState<ICategoriesValidationInput | any>({
    success: "input-success",
    error: "input-error",
    initial: "input-initial",
    caution: "input-caution",
  });
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const [file, setFile] = useState<any[]>([]);
  const [nameFile, setNameFile] = useState<string>(
    enlaceDescarga ? placeholder : "Seleccione Archivo"
  );
  const [fechaExpiracion, setFechaExpiracion] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (fechaExpiracion && onValueChange && onPropertyChange && onPermaValues) {
      const permaValues = { ...onPermaValues };
      permaValues[onPropertyChange].fechaExpiracion = fechaExpiracion;
      onValueChange(permaValues);
    }
  }, [fechaExpiracion]);

  useEffect(() => {
    if (datoRequerido) {
      if (requerido) {
        setTriggerRequerido(true);
        setEventValidation("caution");
      } else {
        setTriggerRequerido(false);
        setEventValidation("success");
      }
    }
  }, [requerido, enlaceDescarga]);

  const handleChangeEventError = (event: any) => {
    let trigger: boolean = false;
    let triggerRequired: boolean = false;
    let eventValidation: string = "initial";

    const files: any[] = event.files;

    if (files.length === 1) {
      const fileSizeInMB = files[0].size;
      const maxSize = maxSizeMb * 1024 * 1024;
      if (fileSizeInMB <= maxSize) {
        setFile(files);
      } else {
        setMessageError(
          `El archivo excede el tamaño máximo permitido. ${maxSizeMb}MG`
        );
        trigger = true;
        eventValidation = "error";
      }
      setNameFile(files[0].name);
    } else {
      setFile(files);
    }

    const allowedExtensions = /(\.pdf|\.xlsx|\.txt)$/i;
    if (files.length > 0) {
      const fileName = files[0].name;
      if (!allowedExtensions.test(fileName)) {
        setMessageError(
          `Solo se permiten archivos con extension: ${formatosAceptado.flat()}`
        );
        trigger = true;
        eventValidation = "error";
      }
    }

    if (datoRequerido) {
      if (requerido) {
        triggerRequired = files ? false : true;
        eventValidation = files ? "success" : "caution";
      }
    }

    setEventValidation(eventValidation);
    setTriggerError(trigger);
    setTriggerRequerido(triggerRequired);
    setNameFile(files.length === 1 ? files[0].name : "Multiples archivos");

    if (onValueChange && onPropertyChange && onPermaValues) {
      const permaValues = { ...onPermaValues };
      const { fechaExpiracion: expiracion } = permaValues[onPropertyChange];
      permaValues[onPropertyChange] = {
        value: files,
        valid: trigger,
        fechaExpiracion: expiracion,
      };

      onValueChange(permaValues);
    }
  };

  const handleClickAbrirEnlace = () => {
    if (enlaceDescarga) {
      window.open(enlaceDescarga, "_blank");
    }
  };

  return (
    <div className={`${required ? "cols-required" : "cols-not-required"}`}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="layer-document">
          {layer} {summary && <i>{summary}</i>}
        </label>
        {leyenda && (
          <span className="leyenda-documento">
            {`(${
              typeof leyenda === "string" &&
              leyenda.charAt(0) + leyenda.toLowerCase().slice(1)
            })`}
          </span>
        )}
      </div>
      <div className="custom-file-input">
        <input
          ref={refFile}
          type="file"
          placeholder={placeholder}
          className={categoriesValidation[eventValidation]}
          required={required}
          disabled={disabled}
          accept={accept ? accept : ".pdf"}
          onChange={(event) => {
            handleChangeEventError(event.target);
          }}
          multiple={multiple}
        />
        <div className={`${date && iconFile ? "w-60" : "w-100"}`}>
          <label
            onClick={() => refFile.current.click()}
            // className={`label-file ${categoriesValidation[eventValidation]}`}
            className={`${
              disabled
                ? `label-file disabled  ${placeholderImg ? "input-img" : ""}`
                : `label-file ${placeholderImg ? "input-img" : ""} ${
                    categoriesValidation[eventValidation]
                  }`
            }`}
          >
            {nameFile}
          </label>
          {layerDown && <p className="layer-down">{layerDown}</p>}
        </div>

        {date && (
          <div className={`${date && iconFile ? "w-40" : "w-100"}`}>
            <InputDateCustomized
              layer=""
              placeholder="DD/MM/YYYY"
              required={true}
              disabled={disabled}
              maxLength={10}
              errorPosition="down"
              onValueChange={setFechaExpiracion}
              requerido={eventValidation === "caution" && true}
              datoRequerido={requerido}
              areaDocument={true}
              defaultValue={
                formatDateToDDMMAAAA({
                  date: fechaExpiracionDocumento,
                  format: "/",
                }) ?? ""
              }
            />
          </div>
        )}

        {iconDownload && (
          <button
            className="button-download-file"
            onClick={() => handleClickAbrirEnlace()}
            disabled={!enlaceDescarga}
          >
            <IconDownloadDocument disabled={!enlaceDescarga} />
          </button>
        )}

        {iconDel && (
          <button
            className="button-download-file"
            style={{ padding: "9.5px" }}
            onClick={() =>
              typeof funcionIconDel == "function" && funcionIconDel()
            }
            disabled={disabledIconDel}
          >
            <IconDeleteBateria disabled={disabledIconDel} />
          </button>
        )}

        {iconFile && (
          <button
            className="button-file"
            onClick={() => refFile.current.click()}
          >
            <AttachmentIcon />
          </button>
        )}
      </div>
      {triggerError && errorPosition === "down" && !disabled && (
        <span className="layer-error">{messageError}</span>
      )}
      {triggerRequerido &&
        errorPosition === "down" &&
        !disabled &&
        eventValidation !== "success" && (
          <span className="layer-caution down">
            <ErrorIcon />
            Documento Requerido
          </span>
        )}
      {!triggerRequerido &&
        errorPosition === "down" &&
        !disabled &&
        eventValidation == "success" && (
          <span className="layer-success down">
            <CheckCircleOutlineIcon />
            Carga Correcta
          </span>
        )}
    </div>
  );
};
