export const status_ingresado = "INGRESADO";
export const status_porvalidar = "PORVALIDAR";
export const status_validado = "VALIDADO";
export const status_no_confirmado = "NOCONFIRMADO";
export const status_confirmado = "CONFIRMADO";
export const status_asistio = "ASISTIO";
export const status_noasistio = "NOASISTIO";

export const status_descartado = "DESCARTADO";
export const status_rechazado = "RECHAZADO";
export const status_desvinculado = "DESVINCULADO";

export const descarte_perfil = "PERFIL";
export const descarte_persona = "PERSONA";
export const descarte_cliente = "CLIENTE";
