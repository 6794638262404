import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import api from "../../../services/api";

interface CandidateModalProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisible: boolean;
  closeFullModal: (flag: any) => void;
  busqueda: any;
}

const ProcessRequestModalV2: React.FC<CandidateModalProps> = ({
  token,
  isModalVisible,
  onClose,
  closeFullModal,
  busqueda
}) => {
  const [loadingAddCandidate, setLoadingAddCandidate] = useState(false);
  const [candidateError, setCandidateError] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<any>("");
  const [classEmptyComboBoxMovilityPass, setClassEmptyComboBoxMovilityPass] =
    useState<any>("");
  const [filterId, setFilterId] = useState<any>("");
  const [clientName, setClientName] = useState<any>("");
  const [urgencia, setUrgencia] = useState<any>(null);
  const [group, setGroup] = useState<any>(null);
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [accountManager, setAccountManager] = useState<any>("");
  const [psicology1, setPsicology1] = useState<any>("");
  const [psicology2, setPsicology2] = useState<any>("");
  const [analyst1, setAnalyst1] = useState<any>("");
  const [analyst2, setAnalyst2] = useState<any>("");
  const [cuposPorIntegrante, setCuposPorIntegrante] = useState<number>(0);
  const [groups, setGroups] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const [cantAnalyst, setCantAnalyst]=useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  //constantes y funciones

  const getUsers = () => {
    api
      .get(`usuario`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsuarios(response.data.data);        
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getGroups = () => {
    api
      .get(`/cargoGroup/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroups(response.data.data);
        console.log(busqueda);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const saveRequestModal = () => {
    let userList = [];   
    
    if (accountManager != "" && accountManager !=null && accountManager !="undefined") {      
      const user = {
        usuarioId: accountManager,
        role: "accountManager",
      };
      userList.push(user);
    }
        
    cantAnalyst.map((item, i) =>{    
      if (item.userId != "" && item.userId !=null && item.userId !="undefined"){
      const user = {
        usuarioId: item.userId,        
        role: "analyst" + (i+1)
      }      
      userList.push(user);
      } 
      });      

    const bodyModal = {
      busquedaId: busqueda.busquedaId,
      busquedaUrgencia: urgencia,
      cargoMod: busqueda.cargoMod,
      cargoGroupId: group,
      userList: userList,
    };
    console.log(bodyModal);
    api
      .put(`/busqueda-asignar/`, bodyModal, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        closeFullModal(2);
      })
      .catch((error) => {
        console.log(error);
      });
  };  

  const renderFooter = (name: string) => {
    return (
      <>
        <div className="flex justify-content-around" style={{ width: "100%" }}>
          <div>
            <Button
              id="btnContRegCtx1"
              style={{ width: "100%" }}
              label="Cancelar asignación"
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={() => closeModal()}
              loading={loadingAddCandidate}
              className="p-button-outlined p-button-danger"
              autoFocus
            />
          </div>

          <div>
            <Button
              id="btnContRegCtx1"
              style={{ width: "100%" }}
              label="Asignar y Activar"
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={saveRequestModal}
              loading={loadingAddCandidate}
              autoFocus
            />
          </div>
        </div>
      </>
    );
  };

  function enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }

  function disableScroll() {
    document.body.classList.add("stop-scrolling");
  }

  // useEffect
  useEffect(() => {
    if (token && isModalVisible) {
      getGroups();
      getUsers();
      setGroup(busqueda.cargoGroupId);
      setUrgencia(busqueda.busquedaUrgencia);
      setAccountManager(busqueda.accountManager);      
      setCantAnalyst([]);
      Object.entries(busqueda).map(([key, value]) => {
        if(key.includes("analyst")){
          moreEquip(String(value))
        }
      })
          
      disableScroll();
    } else {
      enableScroll();
    }
  }, [token, isModalVisible]);

 
  const closeModal = () => {
    onClose();
    setFilterId(null);
    setClientName(null);
  };

  const renderFooterCandidateError = () => {
    return (
      <div>
        <Button
          label="Reintentar"
          className="p-button-text"
          onClick={() => onHideErrorReintentar()}
        />
        <Button label="Cancelar" onClick={() => onHideError()} />
      </div>
    );
  };

  const onHideError = () => {
    setCandidateError(false);
  };

  const onHideErrorReintentar = () => {
    setCandidateError(false);
  };

  if (!isModalVisible) {
    return null;
  }

  const showError = () => {
    return (
      <p className="flex align-items-center justify-content-center mt-4">
        {errorModal}
      </p>
    );
  };

  const priorities = [
    {
      value: 2,
      label: "Urgente",
    },
    {
      value: 1,
      label: "Medio Plazo",
    },
    {
      value: 0,
      label: "Baja prioridad",
    },
  ];

  const multiplos = [
    {
      value: "",
      label: "Seleccione",
    },
    {
      value: 2,
      label: "x2",
    },
    {
      value: 4,
      label: "x4",
    },
    {
      value: 6,
      label: "x6",
    },
    {
      value: 8,
      label: "x8",
    },
    {
      value: 10,
      label: "x10",
    },
  ];

  const templeatePriorities = (option: any) => {
    let circleClass = "";
    let msg = "";
    if (option.label === "Urgente") {
      circleClass = "circleUrgentPriority mr-1";
      msg = "Urgente";
    } else if (option.label === "Medio Plazo") {
      circleClass = "circleMediumPriority mr-1";
      msg = "Medio Plazo";
    } else if (option.label === "Baja prioridad") {
      circleClass = "circleFreePriority mr-1";
      msg = "Baja prioridad";
    }
    return (
      <>
        <div className="flex flex-row">
          <div className={circleClass}> </div>
          <div>{msg}</div>
        </div>
      </>
    );
  };

  const convertToDate = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };


  

  const updateState = (index: any) => (e: any) => {      
    const newArray = cantAnalyst.map((item, i) => {
   
      if (index === i )  {
        return { ...item, userId: e.target.value };
      } else {
        return item;
      }
    });    
       
    setCantAnalyst(newArray)   
  };
  
  const moreEquip = (userId: string) => {   
    const arr = [];
       const user = {
           userId: userId ?? "",
           id: cantAnalyst.length,
           role: "analyst",
       }
       arr.push(user);       
       setCantAnalyst(current => [...current, user ]);             
      
  } 


  //main return
  return busqueda ? (
    <>
      <Dialog
        header=""
        visible={isModalVisible}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "75vw" }}
        footer={renderFooter("displayResponsive")}
        onHide={() => closeModal()}
      >
        <div className="flex flex-column">
          <h3 className="flex justify-content-center">Solicitud de Proceso</h3>
          <span className="flex justify-content-center">
            Puede cambiar al equipo asignado en cualquier momento, asegurese de
            que el Cargo Mod asignado coincida con la información
          </span>
          <span className="flex justify-content-center mt-2">
            proporcionada por el KAM. Una vez guarde este registro, se le
            notificará al equipo para comenzar la convocatoria.
          </span>
        </div>

        <div
          className="flex-grow-1 flex align-items-center justify-content-center"
          style={{ height: "60px" }}
        >
          <h3 style={{}}></h3>
        </div>
        <div className="grid mt-3">
          <div className="col-12">
            <div className="p-fluid formgrid grid">
              <Divider align="left" style={{ margin: 0 }}>
                <div
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                  }}
                >
                  {busqueda.busquedaId} - {busqueda.areaNegocioNombre}{" "}
                  <b>
                    {busqueda.cargoNombre} - {busqueda.cargoGroupName}{" "}
                  </b>
                </div>
              </Divider>

              <div className="flex flex-row w-8 ">
                {busqueda.cargoGroupId != "CG-000" ? (
                  <div className="field col-12 md:col-3">
                    <Dropdown
                      value={group}
                      options={groups}
                      onChange={(e) => setGroup(e.target.value)}
                      optionLabel="cargoGroupName"
                      optionValue="cargoGroupId"
                      placeholder="Asigne Grupo"
                      appendTo="self"
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div className="field col-12 md:col-3">
                    <Dropdown
                      value={group}
                      options={groups}
                      onChange={(e) => setGroup(e.target.value)}
                      optionLabel="cargoGroupName"
                      optionValue="cargoGroupId"
                      placeholder="Asigne Grupo"
                      appendTo="self"
                    />
                  </div>
                )}

                <div className="field col-12 md:col-3">
                  <Dropdown
                    value={urgencia}
                    options={priorities}
                    onChange={(event) => setUrgencia(event.target.value)}
                    placeholder="Seleccione"
                    itemTemplate={templeatePriorities}
                    appendTo="self"
                  />
                </div>
              </div>

              <Divider align="left" style={{ margin: 0 }}>
                <div
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                  }}
                >
                  <b>Información desde KAM</b>
                </div>
              </Divider>

              <table className="tablaInfo">
                <tr>
                  <td className="tablasTitle ">ID:</td>
                  <td>{busqueda.busquedaId}</td>
                  <td className="tablasTitle">Empresa:</td>
                  <td>{busqueda.empresa}</td>
                  <td className="tablasTitle">Cliente:</td>
                  <td>{busqueda.clienteNombre}</td>
                  <td className="tablasTitle">Área de negocio</td>
                  <td>{busqueda.areaNegocioNombre}</td>
                </tr>
                <tr>
                  <td className="tablasTitle">KAM:</td>
                  <td>En Desarrollo</td>
                  <td className="tablasTitle">Q. cupos:</td>
                  <td>{busqueda.busquedaCantidad}</td>
                  <td className="tablasTitle">Fecha solicitud:</td>
                  <td>{convertToDate(busqueda.busquedaInicio)}</td>
                  <td className="tablasTitle">Fecha entrega:</td>
                  <td>{convertToDate(busqueda.busquedaFin)}</td>
                </tr>
                <tr>
                  <td className="tablasTitle">Renta:</td>
                  <td>{busqueda.busquedaRenta}</td>
                  <td className="tablasTitle">Género:</td>
                  <td>{busqueda.busquedaGenero}</td>
                  <td className="tablasTitle">Edad desde:</td>
                  <td>{busqueda.busquedaRangoEdad}</td>
                  <td className="tablasTitle">Horario:</td>
                  <td>{busqueda.busquedaRangoHorario}</td>
                </tr>
                <tr>
                  <td className="tablasTitle">Lugar de trabajo:</td>
                  <td>{busqueda.lugar}</td>
                  <td className="tablasTitle">Experiencia:</td>
                  <td>{busqueda.AnoExperiencia}</td>
                  <td className="tablasTitle">Tipo de experiencia:</td>
                  <td colSpan={3}>{busqueda.busquedaTipoExperiencia}</td>
                </tr>
                <tr>
                  <td className="tablasTitle">Beneficios/Bonos:</td>
                  <td colSpan={7}>En Desarrollo</td>
                </tr>
                <tr>
                  <td className="tablasTitle">Comentarios:</td>
                  <td colSpan={7}>{busqueda.busquedaObservacion}</td>
                </tr>
              </table>

              <Divider align="left" style={{ margin: 0 }}>
                <div
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                  }}
                >
                  <b>Asignación desde equipo</b>
                </div>
              </Divider>

              <div className="field col-12 md:col-4">
                <label htmlFor="selectPrevisionCtx4">
                  Encargado de Cuenta *
                </label>
                <Dropdown
                  id="selectPrevisionCtx4"
                  value={accountManager}
                  options={usuarios}
                  onChange={(e) => setAccountManager(e.target.value)}
                  optionLabel="usuarioNombre"
                  optionValue="usuarioId"  
                  optionDisabled={(option: any) => {
                    // Mention options which needs to be disable
                    return (cantAnalyst.find(o => o.userId === option.usuarioId))?true: false                                                   
                  }}                
                  filter
                  showClear
                  filterBy="usuarioNombre"
                  appendTo="self"
                />
              </div>              

             
                { cantAnalyst.length > 0 &&
                  cantAnalyst.map((item, index)=>{
                    return(
                <div className="field col-12 md:col-4">
                  <label htmlFor="selectSaludCtx4">
                  Analista {index+1} <em>(Opcional)</em>
                  </label>
                  <Dropdown
                    id="selectSaludCtx4"
                    value={item.userId}
                    options={usuarios}
                    optionDisabled={(option: any) => {
                      // Mention options which needs to be disable
                    if(option.usuarioId === accountManager){
                      return true
                    } else{                
                    return (cantAnalyst.find(o => o.userId === option.usuarioId))?true: false
                    }}
                    } 
                    onChange={updateState(index)}
                    optionLabel="usuarioNombre"
                    optionValue="usuarioId"
                    filter
                    showClear
                    filterBy="usuarioNombre"
                    appendTo="self"
                  />
                </div>

                    )
                  })                   
              }
                <div className="field col-12 md:col-4">
                <Button icon="pi pi-plus" 
                 tooltip="Añadir  Analista"
                  className="p-button-rounded p-button-info p-button-outlined mt-4" style={{ color: "#2A63D5", border: "2px solid" }}
                  aria-label="User"
                  onClick={() => moreEquip("")}  
                  disabled={cantAnalyst[100]}  /> 
              </div>
		          

              
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={candidateError}
        style={{ width: "35vw", height: "15vw" }}
        footer={renderFooterCandidateError()}
        onHide={() => onHideError()}
      >
        <div className="flex justify-content-center flex-wrap">
          <i
            className="flex align-items-center justify-content-center pi pi-ban"
            style={{ fontSize: "3em", color: "#FF4900" }}
          ></i>
        </div>
        <div className="flex justify-content-center flex-wrap">
          <b>Error: {showError()}</b>
        </div>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default ProcessRequestModalV2;
{/*


              <div className="field col-12 md:col-4">
                <Button icon="pi pi-plus"
                  className="p-button-rounded p-button-info p-button-outlined mt-4"
                  aria-label="User" />
              </div>



              <div className="field col-12 md:col-4">
                <table className="tablaInfo">
                  <tr>
                    <td colSpan={3} className="tablasTitle ">Q. cupos por integrante:</td>
                    <td colSpan={3}>{cuposPorIntegrante}</td>
                    <td className="tablasTitle" colSpan={3}>Múltiplo de vacantes:</td>
                  </tr>
                </table>
              </div>

              <div className="field col-12 md:col-2 mt-1">
                <Dropdown id="selectTipoCuentaCtx4"
                  value={tipoCuenta}
                  options={multiplos}
                  onChange={(e) => setTipoCuenta(e.value)}
                  placeholder="Seleccione tipo cuenta"
                  appendTo="self"
                />
              </div>



              <div className="field col-12 md:col-12">
                <table className="tablaInfo">
                  <tr>
                    <td className="tablasTitle2">Q. cupos finales por integrante:</td>
                    <td>{busqueda.busquedaId}</td>
                    <td className="tablasTitle2">Q. vacantes totales:</td>
                    <td className=""></td>
                  </tr>
                </table>
              </div>

              */}