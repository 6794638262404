import ItemGrupo from "./ItemGrupo";
import { ICandidatoValidadoGrupo, IListaFormatoPsicolaboral } from "../../../../../interfaces/ISeleccion";
interface ITablaGrupo {
  list: any;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  clienteNombre?: string;
  localStorageSelect: string;
  localStorageFilter: string;
  formFilter: string; 
  refreshTable: Function; 
  selectCurrent: any;
  setSelectCurrent: Function;
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[];
}

const TablaGrupo = (props: ITablaGrupo) => {
  const {
    list,
    listadoSeleccionado,
    setListadoSeleccionado,
    clienteNombre,
    localStorageSelect,
    localStorageFilter,
    formFilter, 
    refreshTable,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
  } = props;

  return (
    <>
      {list &&
        list.map(
          ({
            cargoGroupId,
            cargoGroupName,
            procesos,
          }: ICandidatoValidadoGrupo) => (
            <ItemGrupo
              key={cargoGroupId}
              procesos={procesos}
              clienteNombre={clienteNombre}
              cargoGroupName={cargoGroupName}
              listadoSeleccionado={listadoSeleccionado}
              setListadoSeleccionado={setListadoSeleccionado}
              localStorageSelect={localStorageSelect}
              localStorageFilter={localStorageFilter}
              formFilter={formFilter}
              refreshTable={refreshTable}
              selectCurrent={selectCurrent}
              setSelectCurrent={setSelectCurrent}
              tipoDocumentoAll={tipoDocumentoAll}
              setTipoDocumentoAll={setTipoDocumentoAll}
              listaFormatoPsicolaboral={listaFormatoPsicolaboral}
            />
          )
        )}
    </>
  );
};

export default TablaGrupo;
