import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../InputTextCustomized";
import InputRutCustomized from "../../InputRutCustomized";
import InputDateCustomized from "../../InputDateCustomized";
import DropDownListCustomized from "../../DropDownListCustomized";
import LayerAgeCustomized from "../../LayerAgeCustomized";
import InputPhoneCustomized from "../../InputPhoneCustomized";
import { useCreateListCustomized } from "../../../../../hooks/v2/useCreateList";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import { IArrayList } from "../../../../../interfaces/ISeleccion";
import Hijos from "./componentes/Hijos";
import Cargas from "./componentes/Cargas";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import InputEmailCustomized from "../../InputEmailCustomized";
import InputTextAreaCustomized from "../../InputTextAreaCustomized";
interface IAreaPersonal {
  list?: any[];
  rut?: string;
  formCandidate: any;
  setFormCandidate: Function;
  requerido?: boolean;
  hiddenSection?: boolean | undefined;
}

export default (props: IAreaPersonal) => {
  const {
    list,
    rut,
    formCandidate,
    setFormCandidate,
    requerido,
    hiddenSection,
  } = props;
  const { listaGeneral, listaRegistroCandidato } = useContext(SeleccionContext);
  const [hidden, setHidden] = useState<boolean>(hiddenSection ?? false);
  const [isOptions] = useState<IOptionDropDownList[]>([
    { code: "true", name: "SI" },
    { code: "false", name: "NO" },
  ]);
  const [ageInput, setAgeInput] = useState<string>("");
  const [nacionalidadSelect, setNacionalidadSelect] = useState<boolean>(true);
  const [estVencVisaStatus, setEstVencVisaStatus] = useState<boolean>(true);
  const [tipoVencimientoVisaStatus, setTipoVencimientoVisaStatus] =
    useState<boolean>(true);
  const [comunaSelect, setComunaSelect] = useState<boolean>(true);
  const [tipoViviendaSelect, setTipoViviendaSelect] = useState<boolean>(false);
  const [sistemaSaludIdSelect, setSistemaSaludIdSelect] =
    useState<boolean>(false);
  const [pensionadoSelect, setPensionadoSelect] = useState<boolean>(false);
  const [pagoEnCuentaSelect, setPagoEnCuentaSelect] = useState<boolean>(false);
  const [selectGeneral, setSelectGeneral] = useState<any>({
    afp: [],
    bancos: [],
    claseLicenciaConducir: [],
    comunas: [],
    disponibilidad: [],
    estadoCivil: [],
    estadoVisa: [],
    fuenteReclutamiento: [],
    nacionalidad: [],
    paises: [],
    parentesco: [],
    parentescoCarga: [],
    parentescoEmergencia: [],
    provincias: [],
    ciudad: [],
    regiones: [],
    sistemaSaludId: [],
    sexo: [],
    tipoLicenciaConducir: [],
    tipoVisa: [],
    tipoVivienda: [],
    tiposCuentaBanco: [],
  });

  useEffect(() => {
    if (formCandidate) {
      const { fechaNacimiento }: any = formCandidate;
      const { value, valid } = fechaNacimiento;
      setAgeInput(valid ? value : "");
      handleChangeNacionalidad();
      handleChangeTipoVisa();
      handleChangeEstVencVisaStatus();
      handleChangeTipoViviendaSelect();
      handleChangeSistemaSalud();
      handleChangePensionado();
      handleChangePagoEnCuenta();
    }
  }, [formCandidate]);

  useEffect(() => {
    if (formCandidate) {
      handleChangeComuna();
    }
  }, [formCandidate.comunas?.value]);

  useEffect(() => {
    if (formCandidate) {
      handleChangeBanco();
    }
  }, [formCandidate.bancos?.value]);

  useEffect(() => {
    if (formCandidate) {
      handleChangeNroCuenta();
    }
  }, [formCandidate.tiposCuentaBanco?.value]);

  useEffect(() => {
    if (Object.keys(listaGeneral).length > 0) {
      const {
        afp,
        bancos,
        claseLicenciaConducir,
        comunas,
        disponibilidad,
        estadoCivil,
        estadoVisa,
        fuenteReclutamiento,
        paises,
        parentesco,
        provincias,
        regiones,
        salud,
        sexo,
        tallas,
        tipoLicenciaConducir,
        tipoVisa,
        tipoVivienda,
        tiposCuentaBanco,
      }: any = listaGeneral;
      const { alfanumerica, numerica }: any = tallas;
      const listGeneral = { ...selectGeneral };
      const arrayLists: IArrayList[] = [
        { listChange: "afp", list: afp, keyCode: "afpId", keyName: "nombre" },
        {
          listChange: "bancos",
          list: bancos,
          keyCode: "bancoId",
          keyName: "nombre",
        },
        {
          listChange: "claseLicenciaConducir",
          list: claseLicenciaConducir,
          keyCode: "tipoLicenciaId",
          keyName: "nombre",
        },
        {
          listChange: "comunas",
          list: comunas,
          keyCode: "comunaId",
          keyName: "comunaNombre",
        },
        {
          listChange: "disponibilidad",
          list: disponibilidad,
          keyCode: "disponibilidadId",
          keyName: "nombre",
        },
        {
          listChange: "estadoCivil",
          list: estadoCivil,
          keyCode: "estadoCivilId",
          keyName: "estadoCivil",
        },
        {
          listChange: "estadoVisa",
          list: estadoVisa,
          keyCode: "estadoVisaId",
          keyName: "nombre",
        },
        {
          listChange: "fuenteReclutamiento",
          list: fuenteReclutamiento,
          keyCode: "fuenteReclutamientoId",
          keyName: "nombre",
        },
        {
          listChange: "nacionalidad",
          list: paises,
          keyCode: "paisId",
          keyName: "gentilicio",
        },
        {
          listChange: "paises",
          list: paises,
          keyCode: "paisId",
          keyName: "nombre",
        },
        {
          listChange: "parentesco",
          list: parentesco,
          keyCode: "parentescoId",
          keyName: "nombre",
        },
        {
          listChange: "ciudad",
          list: provincias,
          keyCode: "provinciaId",
          keyName: "nombre",
        },
        {
          listChange: "regiones",
          list: regiones,
          keyCode: "regionId",
          keyName: "nombre",
        },
        {
          listChange: "sistemaSaludId",
          list: salud,
          keyCode: "saludId",
          keyName: "nombre",
        },
        {
          listChange: "sexo",
          list: sexo,
          keyCode: "sexoId",
          keyName: "nombre",
        },
        /**
         * TODO: ARREGLAR EL LISTADO DE TALLAS DE ROPA
         */
        {
          listChange: "tallaAlfanumerica",
          list: tallas.alfanumerica,
          keyCode: "tallaId",
          keyName: "tallaNombre",
        },
        {
          listChange: "tallaNumerica",
          list: tallas.numerica,
          keyCode: "tallaId",
          keyName: "tallaNombre",
        },
        {
          listChange: "tipoLicenciaConducir",
          list: tipoLicenciaConducir,
          keyCode: "tipoLicenciaConducirId",
          keyName: "nombre",
        },
        {
          listChange: "tipoVisa",
          list: tipoVisa,
          keyCode: "tipoVisaId",
          keyName: "nombre",
        },
        {
          listChange: "tipoVivienda",
          list: tipoVivienda,
          keyCode: "tipoViviendaId",
          keyName: "nombre",
        },
        {
          listChange: "tiposCuentaBanco",
          list: tiposCuentaBanco,
          keyCode: "tipoCuentaBancoId",
          keyName: "nombre",
        },
      ];

      arrayLists.map((item: any) => {
        const { listChange, list, keyCode, keyName } = item;
        listGeneral[listChange] = useCreateListCustomized({
          list,
          keyCode,
          keyName,
        });
      });

      setSelectGeneral(listGeneral);
    }
  }, [listaGeneral]);

  const handleChangeNacionalidad = () => {
    if (formCandidate.nacionalidad?.value?.code !== "56") {
      setNacionalidadSelect(false);
    } else {
      setNacionalidadSelect(true);
    }
  };

  const handleChangeTipoVisa = () => {
    if (["TEMPORAL"].includes(formCandidate.tipoVisa?.value?.name)) {
      setTipoVencimientoVisaStatus(false);
    } else {
      setTipoVencimientoVisaStatus(true);
    }
  };

  const handleChangeEstVencVisaStatus = () => {
    if (!["DENEGADA"].includes(formCandidate.estadoVisa?.value?.name)) {
      setEstVencVisaStatus(false);
    } else {
      setEstVencVisaStatus(true);
    }
  };

  const handleChangeComuna = () => {
    if (formCandidate.comunas?.value?.code) {
      const { provinciaId } = listaGeneral.comunas.find(
        (item: any) => item.comunaId == formCandidate.comunas.value.code
      ) ?? { provinciaId: undefined };
      const { regionId, nombre: nombreCiudad } = listaGeneral.provincias.find(
        (item: any) => item.provinciaId == provinciaId
      ) ?? { regionId: undefined, nombreCiudad: undefined };
      const { paisId } = listaGeneral.regiones.find(
        (item: any) => item.regionId == regionId
      ) ?? { paisId: undefined };
      const { nombre: nombrePais } = listaGeneral.paises.find(
        (item: any) => item.paisId == paisId
      ) ?? { nombrePais: undefined };
      const listadoComunas = {
        ...formCandidate,
        ciudad: {
          value: { code: provinciaId, name: nombreCiudad },
          valid: true,
        },
        paises: { value: { code: paisId, name: nombrePais }, valid: true },
      };
      setFormCandidate(listadoComunas);
    } else {
      const listadoComunas = {
        ...formCandidate,
        ciudad: { value: undefined, valid: false },
        paises: { value: undefined, valid: false },
      };
      setFormCandidate(listadoComunas);
    }
  };

  const handleChangeBanco = () => {
    const clistadoFiltrado = !["SBIF-012"].includes(
      formCandidate.bancos?.value?.code
    )
      ? listaGeneral.tiposCuentaBanco.filter(
          (item: any) =>
            item.tipoCuentaBancoId !== "f55353ad-7c06-446f-b923-26effd7a4684"
        )
      : listaGeneral.tiposCuentaBanco;

    setSelectGeneral({
      ...selectGeneral,
      tiposCuentaBanco: clistadoFiltrado.map((item: any) => {
        return { code: item.tipoCuentaBancoId, name: item.nombre };
      }),
    });
  };

  const handleChangeNroCuenta = () => {
    if (!formCandidate.numeroCuenta.value) {
      let nroCuenta = "";

      if (
        [process.env.REACT_APP_cuentaRut].includes(
          formCandidate.tiposCuentaBanco?.value?.code
        )
      ) {
        [nroCuenta] = formCandidate.rutCandidato?.value.split("-");
      }

      setFormCandidate({
        ...formCandidate,
        numeroCuenta: { value: nroCuenta, valid: true },
      });
    }
  };

  const handleChangeTipoViviendaSelect = () => {
    if (
      !formCandidate.tipoVivienda?.value?.code ||
      formCandidate.tipoVivienda?.value == undefined
    ) {
      setTipoViviendaSelect(true);
    } else {
      setTipoViviendaSelect(false);
    }
  };

  const handleChangeSistemaSalud = () => {
    if (
      ["isa-01", "isa-11", null, undefined].includes(
        formCandidate.sistemaSaludId?.value?.code
      )
    ) {
      setSistemaSaludIdSelect(true);
    } else {
      setSistemaSaludIdSelect(false);
    }
  };

  const handleChangePensionado = () => {
    if (
      ["false", null, undefined].includes(formCandidate.pensionado?.value?.code)
    ) {
      setPensionadoSelect(true);
    } else {
      setPensionadoSelect(false);
    }
  };

  const handleChangePagoEnCuenta = () => {
    if (["true"].includes(formCandidate.pagoEnCuenta?.value?.code)) {
      setPagoEnCuentaSelect(true);
    } else {
      setPagoEnCuentaSelect(false);
    }
  };

  // 22359218-k
  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">ÁREA PERSONAL</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}
      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="content-subtitle">
          <div>
            <p className="subtitle">DATOS PERSONALES</p>
          </div>
          <span className="linea"></span>
        </div>

        <div className="container-input">
          <div className="content-input">
            <div className="content-text-modal-update w-25">
              <InputTextCustomized
                layer="1° Nombre"
                placeholder="Ingrese"
                required={true}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="primerNombreCandidato"
                defaultValue={formCandidate.primerNombreCandidato?.value ?? ""}
                requerido={
                  formCandidate.primerNombreCandidato?.value ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-25">
              <InputTextCustomized
                layer="2° Nombre"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="segundoNombreCandidato"
                defaultValue={formCandidate.segundoNombreCandidato?.value ?? ""}
                requerido={
                  formCandidate.segundoNombreCandidato?.value ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-25">
              <InputTextCustomized
                layer="1° Apellido"
                placeholder="Ingrese"
                required={true}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="primerApellidoCandidato"
                defaultValue={
                  formCandidate.primerApellidoCandidato?.value ?? ""
                }
                requerido={
                  formCandidate.primerApellidoCandidato?.value ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-25">
              <InputTextCustomized
                layer="2° Apellido"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="segundoApellidoCandidato"
                defaultValue={
                  formCandidate.segundoApellidoCandidato?.value ?? ""
                }
                requerido={
                  formCandidate.segundoApellidoCandidato?.value ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
          </div>

          <div className="content-input">
            <div className="content-text-modal-update w-20">
              <InputRutCustomized
                layer="Rut"
                placeholder="12345678-9"
                rut={rut}
                disabled={true}
                required={true}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="rutCandidato"
              />
            </div>
            <div className="content-text-modal-update w-25">
              <InputDateCustomized
                layer="Fecha Nacimiento"
                placeholder="DD/MM/AAAA"
                required={true}
                maxLength={10}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fechaNacimiento"
                defaultValue={formCandidate.fechaNacimiento?.value ?? ""}
                requerido={formCandidate.fechaNacimiento?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-dies">
              <LayerAgeCustomized
                layer={"Edad"}
                date={ageInput}
                defaultValue={formCandidate.fechaNacimiento?.value ?? ""}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.estadoCivil}
                layer="Estado Civil"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="estadoCivil"
                defaultValue={formCandidate.estadoCivil?.value ?? ""}
                requerido={
                  formCandidate.estadoCivil?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-20">
              <DropDownListCustomized
                list={selectGeneral.sexo}
                layer="Género"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="sexo"
                defaultValue={formCandidate.sexo?.value ?? ""}
                requerido={formCandidate.sexo?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>

          <div className="content-input">
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.nacionalidad}
                layer="Nacionalidad"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="nacionalidad"
                defaultValue={formCandidate.nacionalidad?.value ?? ""}
                requerido={
                  formCandidate.nacionalidad?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.tipoVisa}
                layer="Tipo de Visa"
                required={false}
                errorPosition="down"
                disabled={nacionalidadSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tipoVisa"
                defaultValue={formCandidate.tipoVisa?.value ?? ""}
                requerido={
                  nacionalidadSelect || formCandidate.tipoVisa?.value?.code
                    ? false
                    : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.estadoVisa}
                layer="Estado Visa"
                required={false}
                errorPosition="down"
                disabled={nacionalidadSelect || tipoVencimientoVisaStatus}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="estadoVisa"
                defaultValue={formCandidate.estadoVisa?.value ?? ""}
                requerido={
                  nacionalidadSelect ||
                  tipoVencimientoVisaStatus ||
                  formCandidate.estadoVisa?.value?.code
                    ? false
                    : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-25">
              <InputDateCustomized
                layer="Fecha Vencimiento"
                placeholder="DD/MM/AAAA"
                required={false}
                maxLength={10}
                errorPosition="down"
                disabled={
                  nacionalidadSelect ||
                  tipoVencimientoVisaStatus ||
                  estVencVisaStatus
                }
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fechaVencimientoVisa"
                defaultValue={formCandidate.fechaVencimientoVisa?.value ?? ""}
                requerido={
                  nacionalidadSelect ||
                  tipoVencimientoVisaStatus ||
                  estVencVisaStatus ||
                  formCandidate.fechaVencimientoVisa?.value
                    ? false
                    : true
                }
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">DIRECCION</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-text-modal-update w-40">
              <InputTextCustomized
                layer="Calle"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="calle"
                defaultValue={formCandidate.calle?.value ?? ""}
                requerido={formCandidate.calle?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-20">
              <InputTextCustomized
                layer="Número Principal"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="nroCalle"
                defaultValue={formCandidate.nroCalle?.value ?? ""}
                requerido={formCandidate.nroCalle?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-20">
              <DropDownListCustomized
                list={selectGeneral.tipoVivienda}
                layer="Depto./ Casa"
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tipoVivienda"
                defaultValue={formCandidate.tipoVivienda?.value ?? ""}
              />
            </div>
            <div className="content-text-modal-update w-20">
              <InputTextCustomized
                layer="Número/Letra"
                placeholder="Ingrese"
                required={false}
                disabled={tipoViviendaSelect}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="nroCasa"
                defaultValue={formCandidate.nroCasa?.value ?? ""}
              />
            </div>
          </div>

          <div className="content-input">
            <div className="content-select-modal-update w-40">
              <DropDownListCustomized
                list={selectGeneral.comunas}
                layer="Comuna"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="comunas"
                defaultValue={formCandidate.comunas?.value ?? ""}
                requerido={formCandidate.comunas?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-30">
              <DropDownListCustomized
                list={selectGeneral.ciudad}
                layer="Ciudad"
                required={false}
                errorPosition="down"
                disabled={comunaSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="ciudad"
                defaultValue={formCandidate.ciudad?.value ?? ""}
              />
            </div>
            <div className="content-select-modal-update w-30">
              <DropDownListCustomized
                list={selectGeneral.paises}
                layer="Pais"
                required={false}
                errorPosition="down"
                disabled={comunaSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="paises"
                defaultValue={formCandidate.paises?.value ?? ""}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">DATOS DEL CONTACTO</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-text-modal-update w-40">
              <InputEmailCustomized
                layer="Mail"
                placeholder="Ingrese"
                required={true}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="correo"
                defaultValue={formCandidate.correo?.value ?? ""}
                requerido={formCandidate.correo?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-30">
              <InputPhoneCustomized
                required={true}
                layer="Celular"
                placeholder="56 9 1234 5678"
                maxLength={14}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fonoPrincipal"
                defaultValue={formCandidate.fonoPrincipal?.value ?? ""}
                requerido={formCandidate.fonoPrincipal?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-30">
              <InputPhoneCustomized
                required={false}
                layer="Teléfono 2"
                placeholder="56 9 1234 5678"
                maxLength={14}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fonoSecundario"
                defaultValue={formCandidate.fonoSecundario?.value ?? ""}
              />
            </div>
          </div>
        </div>

        <Hijos
          setFormCandidate={setFormCandidate}
          formCandidate={formCandidate}
          selectGeneral={selectGeneral}
          defaultValue={formCandidate.hijos?.value ?? []}
          requerido={requerido}
        />

        <Cargas
          setFormCandidate={setFormCandidate}
          formCandidate={formCandidate}
          selectGeneral={selectGeneral}
          defaultValue={formCandidate.cargas?.value ?? []}
          requerido={requerido}
        />

        <div className="content-subtitle">
          <div>
            <p className="subtitle">CONTACTO DE EMERGENCIA</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-text-modal-update w-x3">
              <InputTextCustomized
                layer="Nombre"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="nombreEmergencia"
                defaultValue={formCandidate.nombreEmergencia?.value ?? ""}
                requerido={formCandidate.nombreEmergencia?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-x3">
              <DropDownListCustomized
                list={selectGeneral.parentesco}
                layer="Parentesco"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="parentescoEmergencia"
                defaultValue={formCandidate.parentescoEmergencia?.value ?? ""}
                requerido={
                  formCandidate.parentescoEmergencia?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-x3">
              <InputPhoneCustomized
                required={false}
                layer="Celular Contacto"
                placeholder="56 9 1234 5678"
                maxLength={14}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="fonoEmergencia"
                defaultValue={formCandidate.fonoEmergencia?.value ?? ""}
                requerido={formCandidate.fonoEmergencia?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">COTIZACIONES OBLIGATORIAS</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.sistemaSaludId}
                layer="Sistema de Salud"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="sistemaSaludId"
                defaultValue={formCandidate.sistemaSaludId?.value ?? ""}
                requerido={
                  formCandidate.sistemaSaludId?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-dies">
              <InputTextCustomized
                layer="Valor Plan"
                placeholder="UF 0 %"
                required={false}
                errorPosition="down"
                disabled={sistemaSaludIdSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="valorPlan"
                defaultValue={formCandidate.valorPlan?.value ?? ""}
                requerido={
                  sistemaSaludIdSelect || formCandidate.valorPlan?.value
                    ? false
                    : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={selectGeneral.afp}
                layer="AFP"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="afp"
                defaultValue={formCandidate.afp?.value ?? ""}
                requerido={formCandidate.afp?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-15">
              <DropDownListCustomized
                list={isOptions}
                layer="Pensionado"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="pensionado"
                defaultValue={formCandidate.pensionado?.value ?? ""}
                requerido={formCandidate.pensionado?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-25">
              <DropDownListCustomized
                list={isOptions}
                layer="Exento de cotizar"
                required={!pensionadoSelect}
                errorPosition="down"
                disabled={pensionadoSelect}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="exentoCotizaciones"
                defaultValue={formCandidate.exentoCotizaciones?.value ?? ""}
                requerido={
                  pensionadoSelect || formCandidate.exentoCotizaciones?.value
                    ? false
                    : true
                }
                datoRequerido={requerido}
              />
            </div>
          </div>
        </div>

        <div className="content-subtitle">
          <div>
            <p className="subtitle">PAGO REMUNERACIONES</p>
          </div>
          <span className="linea"></span>
        </div>
        <div className="container-input">
          <div className="content-input">
            <div className="content-select-modal-update w-26">
              <DropDownListCustomized
                list={selectGeneral.bancos}
                layer="Banco"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="bancos"
                defaultValue={formCandidate.bancos?.value ?? ""}
                requerido={formCandidate.bancos?.value?.code ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-26">
              <DropDownListCustomized
                list={selectGeneral.tiposCuentaBanco}
                layer="Tipo Cta"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="tiposCuentaBanco"
                defaultValue={formCandidate.tiposCuentaBanco?.value ?? ""}
                requerido={
                  formCandidate.tiposCuentaBanco?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update w-22">
              <InputTextCustomized
                layer="Nro Cta"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="numeroCuenta"
                defaultValue={formCandidate.numeroCuenta?.value ?? ""}
                requerido={formCandidate.numeroCuenta?.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-select-modal-update w-26">
              <DropDownListCustomized
                list={isOptions}
                layer="Pago Remun. en Cta"
                required={false}
                errorPosition="down"
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="pagoEnCuenta"
                defaultValue={formCandidate.pagoEnCuenta?.value ?? ""}
                requerido={
                  formCandidate.pagoEnCuenta?.value?.code ? false : true
                }
                datoRequerido={requerido}
              />
            </div>
          </div>

          <div className="content-input w-100">
            <InputTextAreaCustomized
              layer="Observaciones"
              placeholder="Ingrese observación adicional sobre pago"
              required={false}
              errorPosition="down"
              disabled={pagoEnCuentaSelect}
              onValueChange={setFormCandidate}
              onPermaValues={formCandidate}
              onPropertyChange="observacionesPago"
              defaultValue={formCandidate.observacionesPago?.value ?? ""}
              requerido={
                pagoEnCuentaSelect || formCandidate.observacionesPago?.value
                  ? false
                  : true
              }
              datoRequerido={requerido}
            />
          </div>
        </div>
      </section>
    </>
  );
};
