import { useContext, useEffect, useRef, useState } from "react";
import "../../../assets/css/campaign.min.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MediaQuery from "../.././ui-responsive/MediaQuery";
import DropDownListCustomized from "../.././ui/kit-mdtw/DropDownListCustomized";
import { IOptionDropDownList } from "../../.././interfaces/IDropDownList";
import { formatDateToDDMMAAAA } from "../../.././functions/kit-mdtw/dates";
import { IFiltersTable } from "../../.././interfaces/IValidationKam";
import InputDateCustomized from "../../ui/kit-mdtw/InputDateCustomized";
import DropDownListMultipleCustomized from "../../ui/kit-mdtw/DropDownListMultipleCustomized";
import {
  IFiltersCampaign,
  IListaCampaignFilter,
} from "../../../interfaces/ICampaign";
import CampaignContext from "../../../context/Campaign/CampaignContext";
import { IInputDate, IInputText } from "../../../interfaces/IInput";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

export default (props: IFiltersCampaign) => {
  const { list, setFilterChange } = props;
  const { width, md } = MediaQuery();
  const [name, setName] = useState<IOptionDropDownList[]>([]);
  const [fechaInicio, setFechaInicio] = useState<IOptionDropDownList[]>([]);
  const [fechaTermino, setFechaTermino] = useState<IOptionDropDownList[]>([]);
  const [grupo, setGrupo] = useState<IOptionDropDownList[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const refDiv = useRef<any>(null);
  

  const { campaign } = useContext(CampaignContext);

  const [filters, setFilters] = useState<any>({
    name: { value: undefined, valid: true },
    fechaInicio: { value: undefined, valid: true },
    fechaTermino: { value: undefined, valid: true },
    tipo: { value: undefined, valid: true },
  });

  useEffect(() => {
    if (campaign) {
      const filterName: string[] = [];
      const filterFechaInicio: string[] = [];
      const filterFechaTermino: string[] = [];
      const filterGrupo: string[] = [];

      campaign.map((item: IListaCampaignFilter) => {
        if (!filterName.includes(item.nombre)) {
          filterName.push(item.nombre);
        }
        if (!filterFechaInicio.includes(item.fechaInicio)) {
          filterFechaInicio.push(formatDateToDDMMAAAA({date: item.fechaInicio}));
        }
        if (!filterFechaTermino.includes(item.fechaTermino)) {
          filterFechaTermino.push(formatDateToDDMMAAAA({date: item.fechaTermino}));
        }
        if (!filterGrupo.includes(item.tipo)) {
          filterGrupo.push(item.tipo);
        }
      });

      setName(
        filterName.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );

      setFechaInicio(
        filterFechaInicio.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
      setFechaTermino(
        filterFechaTermino.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
      setGrupo(
        filterGrupo.map((item) => {
          return {
            code: item,
            name: item,
          };
        })
      );
    }
  }, [list]);

  const handleClickShowFilter = () => {
    setShowFilter(showFilter ? false : true);
  };

  useEffect(() => {
    if (typeof setFilterChange == "function") {
      setFilterChange(filters);
    }
  }, [filters]);
  return (
    <>
      {width >= md && (
        <div className="containerfilter px-0">
          <div className="col-12 pr-6">
            

            <div className="containerfilter" >
                
              <FilterAltOutlinedIcon />

              <div>
                <DropDownListMultipleCustomized
                  list={name}
                  onPermaValues={filters}
                  onValueChange={setFilters}
                  onPropertyChange="name"
                  layerLeft={true}
                  layer="Nombre Campaña:"
                />
              </div>

              <div>
                <DropDownListMultipleCustomized
                  list={fechaInicio}
                  required={false}
                  placeholder="Seleccione"
                  layer="Fecha Inicio:"
                  layerLeft={true}
                  onPermaValues={filters}
                  onValueChange={setFilters}
                  onPropertyChange="fechaInicio"
                />
              </div>
              <div>
                <DropDownListMultipleCustomized
                  list={fechaTermino}
                  required={false}
                  placeholder="Seleccione"
                  layerLeft={true}
                  layer="Fecha Termino:"
                  onPermaValues={filters}
                  onValueChange={setFilters}
                  onPropertyChange="fechaTermino"
                />
              </div>

              <div>
                <DropDownListMultipleCustomized
                  list={grupo}
                  onPermaValues={filters}
                  onValueChange={setFilters}
                  onPropertyChange="tipo"
                  layerLeft={true}
                  layer="Grupo:"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {width < md &&(
        <div className="col-3 sm:mt-5 ml-2 content-filter">
          <button
            className="button-filter"
            onClick={() => handleClickShowFilter()}
          >
            <FilterAltOutlinedIcon />
            <KeyboardArrowDownOutlinedIcon />
          </button>
          <div ref={refDiv} className="div-container-filter">
            <div className={`div-filter ${showFilter ? "show" : ""}`}>
              <DropDownListMultipleCustomized 
              list={name} 
              placeholder="Nombre Campaña:"
              onPermaValues={filters}
              onValueChange={setFilters}
              onPropertyChange="name" />

              <DropDownListMultipleCustomized 
              list={fechaInicio} 
              placeholder="Fecha Inicio"
              onPermaValues={filters}
              onValueChange={setFilters}
              onPropertyChange="fechaInicio" />

              <DropDownListMultipleCustomized 
              list={fechaTermino} 
              placeholder="Fecha Termino"
              onPermaValues={filters}
              onValueChange={setFilters}
              onPropertyChange="fechaTermino" />

              <DropDownListMultipleCustomized 
              list={grupo}
              onPermaValues={filters}
              onValueChange={setFilters}
              onPropertyChange="tipo"
              placeholder="Grupo"
              layerLeft={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
