interface IIconRegistroRechazado {
  fill: string;
  disabled?: boolean;
}

export default (props: IIconRegistroRechazado) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M16.8844 12.0017C16.5579 12.0017 16.2864 11.8913 16.07 11.6704C15.8537 11.4495 15.7455 11.1759 15.7455 10.8494C15.7455 10.5228 15.8559 10.2514 16.0767 10.035C16.2976 9.81856 16.5713 9.71036 16.8978 9.71036C17.2243 9.71036 17.4957 9.82079 17.7121 10.0417C17.9285 10.2625 18.0367 10.5362 18.0367 10.8627C18.0367 11.1892 17.9263 11.4607 17.7054 11.6771C17.4845 11.8935 17.2109 12.0017 16.8844 12.0017ZM15.7455 7.419V0.544922H18.0367V7.419H15.7455Z"
        />
        <path
          d="M11.0194 4.76933C11.0194 4.06527 10.7353 3.39825 10.2413 2.90417C9.74718 2.41009 9.06781 2.12599 8.37609 2.12599C7.68438 2.12599 7.00501 2.41009 6.51093 2.90417C6.01685 3.39825 5.73275 4.07762 5.73275 4.76933C5.73275 5.46105 6.01685 6.14042 6.51093 6.6345C7.00501 7.12858 7.68438 7.41268 8.37609 7.41268C9.06781 7.41268 9.74718 7.12858 10.2413 6.6345C10.7353 6.14042 11.0194 5.46105 11.0194 4.76933ZM4.13933 4.76933C4.13933 3.64529 4.584 2.57066 5.37454 1.78013C6.16507 0.989597 7.2397 0.544922 8.36374 0.544922C9.48778 0.544922 10.5624 0.989597 11.3529 1.78013C12.1435 2.57066 12.5882 3.64529 12.5882 4.76933C12.5882 5.89337 12.1435 6.968 11.3529 7.75854C10.5624 8.54907 9.48778 8.99375 8.36374 8.99375C7.2397 8.99375 6.16507 8.54907 5.37454 7.75854C4.584 6.968 4.13933 5.89337 4.13933 4.76933ZM2.59532 15.8739H14.1445C13.8481 13.7864 12.057 12.1682 9.88305 12.1682H6.85679C4.68282 12.1682 2.89177 13.774 2.59532 15.8739ZM0.964844 16.4791C0.964844 13.2182 3.60819 10.5872 6.85679 10.5872H9.88305C13.144 10.5872 15.775 13.2305 15.775 16.4791C15.775 17.0226 15.3303 17.4549 14.7992 17.4549H1.94066C1.39717 17.4549 0.964844 17.0102 0.964844 16.4791Z"
        />
      </g>
    </svg>
  );
};
