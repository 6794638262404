import { useContext } from "react";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";
import TablaGrupo from "./TablaGrupo";
interface ITablaCliente {
  token: string | undefined;
  getToken: Function;
  listadoSeleccionado: any;
  setListadoSeleccionado: Function;
  localStorageSelect: string;
  currentListProcess: string[]; 
  setFormData: Function; 
  formData: any
}

const TablaCliente = (props: ITablaCliente) => {
  const {
    token,
    getToken,
    listadoSeleccionado,
    setListadoSeleccionado,
    localStorageSelect,
    currentListProcess, 
    setFormData, 
    formData,
  } = props;
  const { listProcesosActivos } = useContext(SeleccionContext);

  return (
    <>
      {listProcesosActivos &&
        listProcesosActivos.data?.map((item: any, key: number) => {
          const { clienteNombre, grupos } = item;
          return (
            <>
              <div className="content-header-cliente" key={key}>
                <span className="title-header-table">
                  <p className="text-cliente">{clienteNombre}</p>
                </span>
              </div>
              <TablaGrupo
                token={token}
                getToken={getToken}
                list={grupos}
                localStorageSelect={localStorageSelect}
                listadoSeleccionado={listadoSeleccionado}
                setListadoSeleccionado={setListadoSeleccionado}
                currentListProcess={currentListProcess}
                setFormData={setFormData}
                formData={formData}
              />
            </>
          );
        })}
    </>
  );
};

export default TablaCliente;
