import { useDropzone } from "react-dropzone";
import IconUpDownloads from "../../../kit-svg/IconUpDownloads";
interface IDropzone {
  files: any;
  setFiles: Function;
  invalidFiles: any;
  setInvalidFiles: Function;
}

export default (props: IDropzone) => {
  const { files, setFiles, invalidFiles, setInvalidFiles } = props;

  const onDrop = (acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter((file) => {
      if (file.type !== "application/pdf" || file.size > 10000000) {
        setInvalidFiles([file.name]);
        return false;
      }
      return true;
    });
    setFiles((prevFiles: any) => [...prevFiles, ...validFiles]);

    // const filteredFiles = acceptedFiles.filter(
    //   (file) => file.type === "application/pdf" && file.size <= 1000000
    // );
    // setFiles([...files, ...filteredFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      pdf: [],
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    multiple: true,
    // maxSize: 10000000,
  });
  return (
    <>
      <div
        {...getRootProps()}
        style={{
          borderRadius: "8px",
          background: isDragActive ? "#D6E8FF" : "#F0F0F0",
          border: "2px dashed #0065DD",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "235px",
          }}
        >
          <label
            style={{
              fontSize: "27px",
              color: "#0065DD",
              fontWeight: "700",
            }}
          >
            HAZ CLICK O ARRASTRA Y SUELTA AQUÍ
          </label>
          <IconUpDownloads fill="#B1B1B1" />
          <label
            style={{
              fontSize: "16px",
              color: "#8E8E8E",
              fontWeight: "500",
            }}
          >
            Documentos sólo en formato PDF de máx. 10MB
          </label>
        </div>
      </div>
    </>
  );
};
