import { useContext, useEffect, useState, lazy, Suspense } from "react";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import IconBloquear from "../../../../ui/icons-seleccion/IconBloquear";
import IconDescargaNomina from "../../../../ui/icons-seleccion/IconDescargaNomina";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalDescartarBloquear from "../../../../ui/kit-modal/bloquearDescartar/ModalDescartarBloquear";
import IconDescarte from "../../../../ui/icons-seleccion/IconDescarte";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalAvanzarProceso from "../../../../ui/kit-modal/avanzarProceso/ModalAvanzarProceso";
import IconCheck from "../../../../ui/icons-seleccion/IconCheck";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import Tooltip from "../../../../ui/kit-mdtw/tooltip/Tooltip";
import {
  IEstadosExcel,
  IFiltrosExcel,
  IListaFormatoPsicolaboral,
} from "../../../../../interfaces/ISeleccion";
import IconCargando from "../../../../ui/icons-seleccion/IconCargando";
import ModalDownloadPsicolaboral from "../../../../ui/kit-modal/descargaPsicolaboral/ModalDownloadPsicolaboral";
import IconDescargaPsicolaboral from "../../../../ui/kit-svg/icon-preseleccionado/IconDescargaPsicolaboral";
import IconDescargaRegistro from "../../../../ui/kit-svg/icon-preseleccionado/IconDescargaRegistro";
const LazyNoProcesados = lazy(
  () => import("../../../../ui/kit-modal/noProcesado/ModalNoProcesadoSeleccion")
);
interface IActionMasive {
  listadoSeleccionado: any;
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  handleAvanzarProceso: Function;
  candidates: any;
  setCandidates: Function;
  refreshTable: Function;
  setListadoSeleccionado: Function;
  localStorageSelect: string;
  selectCurrent: any;
  setSelectCurrent: Function;
  tipoDocumentoAll: any;
  setTipoDocumentoAll: Function;
  listaFormatoPsicolaboral: IListaFormatoPsicolaboral[];
  modalPdfDisabled: boolean; 
}

const ActionMassive = (props: IActionMasive) => {
  const {
    listadoSeleccionado,
    localStorageFilter,
    setFormFilter,
    formFilter,
    handleAvanzarProceso,
    setCandidates,
    refreshTable,
    setListadoSeleccionado,
    localStorageSelect,
    selectCurrent,
    setSelectCurrent,
    tipoDocumentoAll,
    setTipoDocumentoAll,
    listaFormatoPsicolaboral,
    modalPdfDisabled,
  } = props;
  const {
    token,
    listaCandidatoSeleccionado,
    postGenerarExcel,
    listaNoProcesadosSeleccion,
    putEntrevistaRealizada,
    getListadoPdf,
    downloadRegistroCandidato,
  } = useContext(SeleccionContext);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [buttonEnabledGo, setButtonEnabledGo] = useState<boolean>(false);
  const [isOpenModalBloquear, setIsOpenModalBloquear] =
    useState<boolean>(false);
  const [isOpenModalDescartar, setIsOpenModalDescartar] =
    useState<boolean>(false);
  const [isOpenModalAvanzar, setIsOpenModalAvanzar] = useState<boolean>(false);
  const [isOpenModalNoProcesado, setIsOpenModalNoProcesado] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [currentProcesos, setCurrentProcesos] = useState<string[]>([]);
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [isOpenModalDownPsicolaboral, setIsOpenModalDownPsicolaboral] =
    useState<boolean>(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean | undefined>(
    undefined
  );
  const estadosExcel: IEstadosExcel = {
    porValidar: false,
    preseleccionado: false,
    validado: true,
  };
  const filtrosExcel: IFiltrosExcel = {
    busPerIdList: [],
    reclutador: [],
    estadoRegistro: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    tipoProceso: ["PROFESIONAL"],
    fechaPostulacion: [],
    fechaValidacion: [],
    fechaPreseleccion: [],
    confirmaEntrevista: null,
    transporteTeamwork: null,
    entrevistaRealizada: null,
  };

  useEffect(() => {
    setButtonEnabled(listadoSeleccionado.length > 0);
  }, [listadoSeleccionado]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    if (listaCandidatoSeleccionado && listaCandidatoSeleccionado.length > 0) {
      const listProcesos = [...listaCandidatoSeleccionado];
      const arrayProcesos: string[] = listProcesos
        .map(({ grupos }: any) => grupos)
        .flat()
        .map(({ procesos }: any) => procesos)
        .flat()
        .map(({ personas }: any) => personas)
        .flat()
        .map((item: any) => item);
      setCurrentProcesos(arrayProcesos);
    }
  }, [listaCandidatoSeleccionado]);

  useEffect(() => {
    if (listadoSeleccionado.length > 0) {
      const candidate = currentProcesos.filter((item: any) =>
        listadoSeleccionado.includes(item.personaDNI)
      );
      const avanza = candidate.some(
        (item: any) => item.registroCompleto && item.entrevistaRealizada
      );
      setButtonEnabledGo(avanza);
    } else {
      setButtonEnabledGo(false);
    }
  }, [listadoSeleccionado, currentProcesos]);

  const handleClickBloquearCandidato = (): void => {
    setIsOpenModalBloquear(true);
  };

  const handleClickDescartarCandidato = (): void => {
    setIsOpenModalDescartar(true);
  };

  const handleClickAvanzarProceso = (): void => {
    setIsOpenModalAvanzar(true);
  };

  const handleAvanzarCandidate = (): void => {
    handleAvanzarProceso({ setIsOpenModalNoProcesado, localStorageSelect });
    setIsOpenModalAvanzar(false);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "auto";
    }
    if (token) {
      refreshTable();
      setListadoSeleccionado([]);
    }
  };

  const handleClickStatusEntrevista = async (): Promise<void> => {
    const arrayList = currentProcesos.filter((item: any) =>
      listadoSeleccionado.includes(item.personaDNI)
    );
    const personas = arrayList.map((item: any) => {
      const { busPerId, entrevistaRealizada } = item;
      return {
        busPerId,
        entrevistaRealizada: true,
      };
    });
    await putEntrevistaRealizada({ token, personas });
    await refreshTable();
  };

  const handleClickNomina = async (): Promise<void> => {
    if (token) {
      try {
        if (localStorage.getItem(localStorageFilter)) {
          const copyStorage: any = localStorage.getItem(localStorageFilter);
          const dataObject = JSON.parse(copyStorage);
          filtrosExcel.proceso = dataObject.proceso?.map((valor: any) =>
            parseInt(valor)
          );
          filtrosExcel.cliente = [...dataObject.cliente];
        }
        await postGenerarExcel({
          token,
          estadosExcel,
          filtrosExcel,
          setIsLoading: setIsLoadingExcel,
        });
      } catch (error) {
        console.log("error descarga");
      }
    }
  };

  const handleDownloadPsicolaboral = (): void => {
    setIsOpenModalDownPsicolaboral(true);
  };

  const handleClickViewPdf = () => {
    try {
      getListadoPdf({ selectCurrent });
      setIsOpenModalDownPsicolaboral(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRegistro = () => {
    const registroCandidato = currentProcesos
      .filter((item: any) => listadoSeleccionado.includes(item.personaDNI))
      .map(({ busPerId }: any) => busPerId);
    downloadRegistroCandidato({ token, registros: registroCandidato });
  };

  return (
    <>
      <div className="container-actions w-100">
        <div className="content-button-masive">
          <button
            className={`button-navegation ${
              buttonEnabledGo ? "verde" : "disabled"
            }`}
            onClick={() => handleClickAvanzarProceso()}
            disabled={buttonEnabledGo ? false : true}
          >
            Enviar a Operaciones
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
          <Tooltip text="Descarga Registro">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-azul" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleDownloadRegistro}
            >
              <IconDescargaRegistro fill="#0065DD" disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          {/* <Tooltip text="Descarga Informe Psicolaboral">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-verde" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleDownloadPsicolaboral}
            >
              <IconDescargaPsicolaboral
                fill="#06A502"
                disabled={!buttonEnabled}
              />
            </button>
          </Tooltip> */}

          <Tooltip text="Entrevista Realizada">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-naranjo" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickStatusEntrevista}
            >
              <IconCheck fill={"#FF4900"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Bloquear">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickBloquearCandidato}
            >
              <IconBloquear fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>
          <Tooltip text="Descartar">
            <button
              className={`button-actions ${
                buttonEnabled ? "active-block" : "disabled"
              }`}
              disabled={!buttonEnabled}
              onClick={handleClickDescartarCandidato}
            >
              <IconDescarte fill={"#BE2A2A"} disabled={!buttonEnabled} />
            </button>
          </Tooltip>

          <Tooltip text="Descargar Nómina">
            <button
              className={`button-actions ${
                isLoadingExcel ? "disabled file" : "active-file"
              }`}
              onClick={() => handleClickNomina()}
            >
              {isLoadingExcel ? (
                <IconCargando fill="#327a30" />
              ) : (
                <IconDescargaNomina fill="#327a30" />
              )}
            </button>
          </Tooltip>

          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={["COMPLETO", "INCOMPLETO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ID PROCESO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="proceso"
          />
          <InputFiltro
            layer="CLIENTE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="cliente"
          />
          <InputFiltro
            layer="FECHA VALIDACIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaValidacion"
          />
          <InputFiltro
            layer="FECHA PRESELECCIÓN"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaPreseleccion"
          />
          <StateFiltro
            layer="ENTREVISTA REALIZADA"
            listState={["SI", "NO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="entrevistaRealizada"
          />
        </FiltroGenerico>
      )}

      {isOpenModalAvanzar && (
        <ModalGenerico
          title="CONFIRMAR MOVIMIENTO DE ETAPA"
          width="w-70"
          subtitle="Estás por mover de etapa a la siguiente selección: "
          onChangeBack={() => {
            setIsOpenModalAvanzar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleAvanzarCandidate()}
          buttonBackText="Cancelar"
          buttonGoText="Aceptar"
        >
          <ModalAvanzarProceso
            listCandidate={listadoSeleccionado}
            listData={currentProcesos}
            setCandidates={setCandidates}
            etapa="Operaciones"
          />
        </ModalGenerico>
      )}

      {isOpenModalNoProcesado && (
        <ModalGenerico
          title="Candidatos No Enviados"
          iconTitle="danger"
          width="w-70"
          subtitle="Los siguiente candidatos no pudieron ser procesados:"
          onChangeBack={() => {
            setIsOpenModalNoProcesado(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBackText="Cerrar"
        >
          <Suspense fallback={<div>Cargando pruebitas...</div>}>
            <LazyNoProcesados list={listaNoProcesadosSeleccion ?? []} />
            {/* <ModalNoProcesadoSeleccion list={listaNoProcesadosSeleccion ?? []} /> */}
          </Suspense>
        </ModalGenerico>
      )}

      {isOpenModalBloquear && (
        <ModalGenerico
          title="BLOQUEO DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por bloquear la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalBloquear(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="bloqueo"
            getListRefresh={refreshTable}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={currentProcesos ?? []}
            localStorageFilter={localStorageFilter}
            localStorageSelect={localStorageSelect}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalBloquear(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDescartar && (
        <ModalGenerico
          title="DESCARTE DE CANDIDATOS"
          width="w-70"
          subtitle="Estás por descartar la siguiente selección de candidatos:"
          onChangeBack={() => {
            setIsOpenModalDescartar(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDescartarBloquear
            typeExclusion="descarte"
            getListRefresh={refreshTable}
            setListadoSeleccionado={setListadoSeleccionado}
            listSelect={currentProcesos ?? []}
            localStorageFilter={localStorageFilter}
            localStorageSelect={localStorageSelect}
            formFilter={formFilter}
            onChangeBack={() => {
              setIsOpenModalDescartar(false);
              const body = document.querySelector("body");
              if (body) {
                body.style.overflowY = "auto";
              }
            }}
            list={listadoSeleccionado}
          />
        </ModalGenerico>
      )}

      {isOpenModalDownPsicolaboral && (
        <ModalGenerico
          title="GENERAR INFORME PSICOLABORAL"
          width="w-70"
          subtitle="Estás por generar el informe psicolaboral para la siguiente selección de evaluaciones:"
          positionSubTitle="start"
          messageBottom="Puede encontrar los documentos en tu carpeta de descargas. Para continuar presione Descargar Informe."
          positionMessageBottom="start"
          buttonBackText="Cancelar"
          buttonGoText="Descargar Informe"
          buttonGoDisable={modalPdfDisabled}
          buttonDisabledTooltipText={"Favor seleccione el tipo de formato del pdf"}
          onChangeBack={() => {
            setIsOpenModalDownPsicolaboral(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            handleClickViewPdf();
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalDownloadPsicolaboral
            list={listadoSeleccionado}
            listOriginal={listaCandidatoSeleccionado}
            token={token}
            selectCurrent={selectCurrent}
            setSelectCurrent={setSelectCurrent}
            tipoDocumentoAll={selectCurrent}
            setTipoDocumentoAll={setSelectCurrent}
            listaFormatoPsicolaboral={listaFormatoPsicolaboral}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default ActionMassive;
