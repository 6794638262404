import { useEffect, useState } from "react";
import "../../../../assets/css/filtro-generico.min.css";
import DropDownListCustomizedFilter from "../../kit-mdtw/DropDownListCustomizedFilter";

interface IDropDownFiltro {
  layer: string;
  list: any;
  onValueChange?: Function;
  onPropertyChange?: string | any;
  onPermaValues?: any;
  localStorageFilter: any;
  disabled?: boolean; 
}

export default (props: IDropDownFiltro) => {
  const {
    layer,
    list,
    onValueChange,
    onPermaValues,
    onPropertyChange,
    localStorageFilter,
    disabled,
  } = props;

  return (
    <>
      <div className="section-filter">
        <span className="title">{layer}</span>
        <div className="content-input-filter">
          <DropDownListCustomizedFilter
            placeholder="Buscar"
            list={list}
            onValueChange={onValueChange}
            onPropertyChange={onPermaValues}
            onPermaValues={onPropertyChange}
            defaultValue={onPropertyChange[onPermaValues].value}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
