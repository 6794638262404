import { useContext, useEffect, useRef, useState } from "react";
import IconOrdenAlfaAscendente from "../../../../ui/kit-svg/IconOrdenAlfaAscendente";
import IconOrdenAlfaDescenciente from "../../../../ui/kit-svg/IconOrdenAlfaDescenciente";
import DropDownListMultipleCustomized from "../../../../ui/kit-mdtw/DropDownListMultipleCustomized";
import CloseIcon from "@mui/icons-material/Close";
import InputTextCustomized from "../../../../ui/kit-mdtw/InputTextCustomized";
import InputDateCustomized from "../../../../ui/kit-mdtw/InputDateCustomized";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import { useParams } from "react-router-dom";
import { Params } from "../../../../../interfaces/IValidationKam";
import { formatDateToYYYYMMDD } from "../../../../../functions/kit-mdtw/dates";
interface IModalFiltro {
  title: string;
  list?: any[];
  buttonGoEvent: Function;
  token: string | undefined;
  tipo?: string;
  openModalFiltro?:boolean;
}

export default (props: IModalFiltro) => {
  const { title, list, buttonGoEvent, token, tipo, openModalFiltro } = props;
  const { idCliente } = useParams<Params>();
  const { getListCandidatePendienteContrato, getListCandidateEnviadoContrato, cleanFilter, setCleanFilter } =
    useContext(ValidationKamContext);

  const [activeInput, setActiveInput] = useState<string | null>(null);
  const [nombreFiltro, setNombreFiltro] = useState<any>([]);
  const [dniFiltro, setDniFiltro] = useState<any>([]);
  const [areasNegocioFiltro, setAreasNegocioFiltro] = useState<any>([]);
  const [procesosFiltro, setProcesosFiltro] = useState<any>([]);
  const [fechasValidacionFiltro, setFechasValidacionFiltro] = useState<any>([]);
  const [estadoFiltro, setEstadoFiltro] = useState<any>([]);
  const [divisionFiltro, setDivisionFiltro] = useState<any>([]);
  const [fichaFiltro, setFichaFiltro] = useState<any>([]);
  const [ordenFiltro, setOrdenFiltro] = useState<any>([]);

  const [selectFiltro, setSelectFiltro] = useState<any>({
    estados: { value: undefined },
    nombres: [{ value: undefined }],
    DNI: { value: undefined },
    areasNegocio: { value: undefined },
    procesos: { value: undefined },
    division: { value: undefined },
    ficha: { value: undefined },
    fechasValidacion: { value: " " },
  });

  const [selectFiltroStorage, setSelectFiltroStorage] = useState<any>({});

  useEffect(() => {
    if (tipo == "pendiente_contrato") {
      const storedArray = localStorage.getItem("Filtros1");
      if (storedArray) {
        const objetoRecuperado = JSON.parse(storedArray);
        setSelectFiltroStorage(objetoRecuperado);
      }
    } else {
      const storedArray = localStorage.getItem("Filtros2");
      if (storedArray) {
        const objetoRecuperado = JSON.parse(storedArray);
        setSelectFiltroStorage(objetoRecuperado);
      }
    }

  }, []);

  useEffect(() => {

    if(cleanFilter){
      resetAllStates();
      setCleanFilter(false)
    }
   }, [openModalFiltro]);

  const handleEstadoClick = (estado: string) => {
    if (selectFiltroStorage?.estados?.includes(estado)) {
      return;
    }
    if (!estadoFiltro.includes(estado)) {
      setEstadoFiltro((prevEstadoFiltro: any) => [...prevEstadoFiltro, estado]);
    }
  };

  const handleDivisionClick = (division: string) => {
    if (selectFiltroStorage?.division?.includes(division)) {
      return;
    }

    if (!divisionFiltro.includes(division)) {
      setDivisionFiltro((prevDivisionFiltro: any) => [
        ...prevDivisionFiltro,
        division,
      ]);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    
    if (event.key === "Enter") {
      const inputValue = event.target;
      if (inputValue !== null && inputValue !== undefined) {
        if (activeInput === "nombre") {
          const arrayListName = [...nombreFiltro];
          arrayListName.push(selectFiltro.nombres?.value);
          setNombreFiltro(arrayListName);
        } else if (activeInput === "DNI" && (!dniFiltro.includes(selectFiltro.DNI?.value)
         )) {
          const arrayListDni = [...dniFiltro];
          arrayListDni.push(selectFiltro.DNI?.value);
          setDniFiltro(arrayListDni);
        } else if (activeInput === "areasnegocio") {
          const arrayListAreasNegocio = [...areasNegocioFiltro];
          arrayListAreasNegocio.push(selectFiltro.areasNegocio?.value);
          setAreasNegocioFiltro(arrayListAreasNegocio);
        } else if (activeInput === "procesos") {
          const arrayListProcesos = [...procesosFiltro];
          arrayListProcesos.push(selectFiltro.procesos?.value);
          setProcesosFiltro(arrayListProcesos);
        } else if (activeInput === "fechasValidacion") {
          const arrayListFechaValid = [...fechasValidacionFiltro];
          arrayListFechaValid.push(selectFiltro.fechasValidacion?.value);
          setFechasValidacionFiltro(arrayListFechaValid);
        } else if (activeInput === "ficha") {
          const arrayListFicha = [...fichaFiltro];
          arrayListFicha.push(selectFiltro.ficha?.value);
          setFichaFiltro(arrayListFicha);
        }
      }
    }
  };

  const handleRemoveEstado = (estado: string) => {
    setEstadoFiltro((prevEstadoFiltro: any) => {
      const updatedEstadoFiltro = prevEstadoFiltro.filter(
        (estadoActual: string) => estadoActual !== estado
      );
      return updatedEstadoFiltro;
    });
  };

  const handleRemoveNombre = (index: number) => {
    setNombreFiltro((prevNombreFiltro: any) => {
      const updatedNombreFiltro = [...prevNombreFiltro];
      updatedNombreFiltro.splice(index, 1);
      return updatedNombreFiltro;
    });
  };
  const handleRemoveDNI = (index: number) => {
    setDniFiltro((prevDniFiltro: any) => {
      const updatedDniFiltro = [...prevDniFiltro];
      updatedDniFiltro.splice(index, 1);
      return updatedDniFiltro;
    });
  };
  const handleRemoveAreas = (index: number) => {
    setAreasNegocioFiltro((prevareasNegocioFiltro: any) => {
      const updatedAreaFiltro = [...prevareasNegocioFiltro];
      updatedAreaFiltro.splice(index, 1);
      return updatedAreaFiltro;
    });
  };
  const handleRemoveProcesos = (index: number) => {
    setProcesosFiltro((prevareasProcesosFiltro: any) => {
      const updatedProcesoFiltro = [...prevareasProcesosFiltro];
      updatedProcesoFiltro.splice(index, 1);
      return updatedProcesoFiltro;
    });
  };
  const handleRemoveFechas = (index: number) => {
    setFechasValidacionFiltro((prevareasFechasFiltro: any) => {
      const updatedFechasFiltro = [...prevareasFechasFiltro];
      updatedFechasFiltro.splice(index, 1);
      return updatedFechasFiltro;
    });
  };

  const handleRemoveDivision = (division: string) => {
    setDivisionFiltro((prevareasDivisionFiltro: any) => {
      const updatedDivisionFiltro = prevareasDivisionFiltro.filter(
        (estadoActual: string) => estadoActual !== division
      );
      return updatedDivisionFiltro;
    });
  };

  const handleRemoveFicha = (index: number) => {
    setFichaFiltro((prevareasFichaFiltro: any) => {
      const updatedFichaFiltro = [...prevareasFichaFiltro];
      updatedFichaFiltro.splice(index, 1);
      return updatedFichaFiltro;
    });
  };
  const handleRemoveOrden = () => {
    setOrdenFiltro([]);
  };

  const handleRemoveFiltrosStorage = (field: string, index: number) => {
    setSelectFiltroStorage((prevSelectFiltroStorage: any) => {
      const updatedSelectFiltroStorage = {
        ...prevSelectFiltroStorage,
        [field]: prevSelectFiltroStorage[field].filter((_: any, i: number) => i !== index)
      };
  
      return updatedSelectFiltroStorage;
    });
  };
  

  const resetAllStates = () => {
    setActiveInput(null);
    setEstadoFiltro([]);
    setNombreFiltro([]);
    setDniFiltro([]);
    setAreasNegocioFiltro([]);
    setProcesosFiltro([]);
    setFechasValidacionFiltro([]);
    setDivisionFiltro([]);
    setFichaFiltro([]);
    setOrdenFiltro([]);
    setSelectFiltro({
      estados: { value: undefined },
      nombres: { value: undefined },
      DNI: { value: undefined },
      areasNegocio: { value: undefined },
      procesos: { value: undefined },
      division: { value: undefined },
      ficha: { value: undefined },
      fechasValidacion: { value: undefined },
    });

    setTimeout(() => {
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
    }, 500);
    setSelectFiltroStorage([]);
    localStorage.removeItem("Filtros1");
    localStorage.removeItem("Filtros2");
    
      if (tipo === "pendiente_contrato") {
        getListCandidatePendienteContrato({
          idCliente,
          token,
          objetoSendFiltro: {},
        });
        
      } else {
        getListCandidateEnviadoContrato({
          idCliente,
          token,
          objetoSendFiltro: {},
        });

      }
    
  };

  const objetoSendFiltro = {
    estados:
      selectFiltroStorage?.estados?.length > 0
        ? [...selectFiltroStorage.estados, ...estadoFiltro]
        : estadoFiltro,
    nombres:
      selectFiltroStorage?.nombres?.length > 0
        ? [...selectFiltroStorage.nombres, ...nombreFiltro]
        : nombreFiltro,
    DNI:
      selectFiltroStorage?.DNI?.length > 0 && (selectFiltroStorage &&
        selectFiltroStorage.DNI &&
        !selectFiltroStorage.DNI.includes(
          selectFiltro.DNI?.value
        ))
        ? [...selectFiltroStorage.DNI, ...dniFiltro]
        : dniFiltro,
    areasNegocio:
      selectFiltroStorage?.areasNegocio?.length > 0
        ? [...selectFiltroStorage.areasNegocio, ...areasNegocioFiltro]
        : areasNegocioFiltro,
    procesos:
      selectFiltroStorage?.procesos?.length > 0
        ? [...selectFiltroStorage.procesos, ...procesosFiltro]
        : procesosFiltro,
    fechasValidacion:
      selectFiltroStorage?.fechasValidacion?.length > 0
        ? [
            ...selectFiltroStorage.fechasValidacion,
            ...fechasValidacionFiltro.map((date: any) =>
              formatDateToYYYYMMDD({ date: date, format: "-" })
            ),
          ]
        : fechasValidacionFiltro.map((date: any) =>
            formatDateToYYYYMMDD({ date: date, format: "-" })
          ),
    division:
      selectFiltroStorage?.division?.length > 0
        ? [...selectFiltroStorage.division, ...divisionFiltro]
        : divisionFiltro,
    ficha:
      selectFiltroStorage?.ficha?.length > 0
        ? [...selectFiltroStorage.ficha, ...fichaFiltro]
        : fichaFiltro,
    orden:
      selectFiltroStorage?.orden?.length > 0
        ? [...selectFiltroStorage.orden, ...ordenFiltro]
        : ordenFiltro,
  };

  const estados0 = [
    { key: "VISTO", label: "VISTO" },
    { key: "EDITADO", label: "EDITADO" },
    { key: "COMPLETO", label: "COMPLETO" },
    { key: "DETENIDA", label: "DETENIDO" }
  ];
  const estados1 = [
    { key: "RECHAZADO", label: "RECHAZADA" },
    { key: "ACEPTADA", label: "ACEPTADA" },
    { key: "PROCESANDO", label: "PROCESANDO" },
    { key: "FUERA_PLAZO", label: "FUERA DE PLAZO" },
    { key: "PEND_FIRMA_DIGITAL", label: "PEND CERTIFICADO DIGITAL"}, 
    {key: "ACEPTADO_PEND_FIRMA", label: "PEND FIRMA DIGITAL"},
    {key: "PEND_REVISION", label: "PEND REVISION"},
    {key: "ACEPTADO_PEND_SOFTLAND", label: "ACEPTADO PEND SOFTLAND"}
    
  ];
  
  const division1 = [
    { key: "TWEST", label: "TWEST" },
    { key: "TWC", label: "TWC" },
    { key: "TWRRHH", label: "TWRRHH" },
    { key: "TWCAP", label: "TWCAP" },
  ];
 

  return (
    <>
      <div className="container-filter-newdesign">
        <div className="modal-back-box" onClick={() => buttonGoEvent()}></div>
        <div className="content-filter-newdesign w-100">
          <div className="container-title-filter">
            <span>{title}</span>
          </div>

          <section className="section-filter-aplicate">
            <div className="content-filter-aplicate">
              <span className="title-filter">Filtros Aplicados</span>
              <div className="content-button-filter-aplicate">
                {selectFiltroStorage.estados &&
                  selectFiltroStorage.estados.length > 0 &&
                  selectFiltroStorage.estados.map(
                    (estado: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{estado}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("estados", index)
                          }
                        />
                      </button>
                    )
                  )}

                {selectFiltroStorage.nombres &&
                  selectFiltroStorage.nombres.length > 0 &&
                  selectFiltroStorage.nombres.map(
                    (nombre: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{nombre}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("nombres", index)
                          }
                        />
                      </button>
                    )
                  )}

                {selectFiltroStorage.DNI &&
                  selectFiltroStorage.DNI.length > 0 &&
                  selectFiltroStorage.DNI.map((dni: string, index: number) => (
                    <button className="button-filter-aplicate">
                      <span key={index}>{dni}</span>
                      <CloseIcon
                        onClick={() => handleRemoveFiltrosStorage("DNI", index)}
                      />
                    </button>
                  ))}

                {selectFiltroStorage.areasNegocio &&
                  selectFiltroStorage.areasNegocio.length > 0 &&
                  selectFiltroStorage.areasNegocio.map(
                    (area: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{area}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("areasNegocio", index)
                          }
                        />
                      </button>
                    )
                  )}

                {selectFiltroStorage.procesos &&
                  selectFiltroStorage.procesos.length > 0 &&
                  selectFiltroStorage.procesos.map(
                    (proceso: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{proceso}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("procesos", index)
                          }
                        />
                      </button>
                    )
                  )}

                {selectFiltroStorage.fechasValidacion &&
                  selectFiltroStorage.fechasValidacion.length > 0 &&
                  selectFiltroStorage.fechasValidacion.map(
                    (fecha: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{fecha}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage(
                              "fechasValidacion",
                              index
                            )
                          }
                        />
                      </button>
                    )
                  )}
                {selectFiltroStorage.division &&
                  selectFiltroStorage.division.length > 0 &&
                  selectFiltroStorage.division.map(
                    (division: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{division}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("division", index)
                          }
                        />
                      </button>
                    )
                  )}
                {selectFiltroStorage.ficha &&
                  selectFiltroStorage.ficha.length > 0 &&
                  selectFiltroStorage.ficha.map(
                    (ficha: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{ficha}</span>
                        <CloseIcon
                          onClick={() =>
                            handleRemoveFiltrosStorage("ficha", index)
                          }
                        />
                      </button>
                    )
                  )}
                {ordenFiltro != "" ? (
                  <button className="button-filter-aplicate">
                    <span>{ordenFiltro}</span>
                    <CloseIcon onClick={() => handleRemoveOrden()} />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="content-button-filter">
              <button
                className="button-clear-left"
                onClick={() => resetAllStates()}
              >
                Limpiar Filtros
              </button>
            </div>
          </section>

          <section className="content-filter">
            <div
              className={`section-filter ${
                tipo === "pendiente_contrato" ? "" : "section-filterContrato"
              }`}
            >
              <span className="title">ESTADO</span>
              <section className="section-button">
                {tipo === "pendiente_contrato" ? (
                  <>
                    {estados0.map((estado) => (
                      <div key={estado.key}>
                        {estadoFiltro.includes(estado.key) ? (
                          <div>
                            {estadoFiltro
                              .filter((e: string) => e === estado.key)
                              .map((e: string, index: number) => (
                                <button
                                  className="button-filter-aplicate button-filter active"
                                  key={index}
                                >
                                  <span>{e}</span>
                                  <CloseIcon
                                    onClick={() =>
                                      handleRemoveEstado(estado.key)
                                    }
                                  />
                                </button>
                              ))}
                          </div>
                        ) : (
                          <button
                            className={`button-filter ${
                              estadoFiltro.includes(estado.key) ||
                              (selectFiltroStorage &&
                                selectFiltroStorage.estados &&
                                selectFiltroStorage.estados.includes(
                                  estado.key
                                ))
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleEstadoClick(estado.key)}
                          >
                            {estado.label}
                          </button>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="button-container">
                      <div className="button-pair">
                        {estados1.map((estado) => (
                          <div key={estado.key}>
                            {estadoFiltro.includes(estado.key) ? (
                              <div>
                                {estadoFiltro
                                  .filter((e: string) => e === estado.key)
                                  .map((e: string, index: number) => (
                                    <button
                                      className="button-filter-aplicate button-filter active"
                                      key={index}
                                    >
                                      <span>{e}</span>
                                      <CloseIcon
                                        onClick={() =>
                                          handleRemoveEstado(estado.key)
                                        }
                                      />
                                    </button>
                                  ))}
                              </div>
                            ) : (
                              <button
                                className={`button-filter ${
                                  estadoFiltro.includes(estado.key) ||
                                  (selectFiltroStorage &&
                                    selectFiltroStorage.estados &&
                                    selectFiltroStorage.estados.includes(
                                      estado.key
                                    ))
                                    ? "active"
                                    : ""
                                }`}
                                style={{ minWidth: "194px" }}
                                onClick={() => handleEstadoClick(estado.key)}
                              >
                                {estado.label}
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      
                    </div>
                  </>
                )}
              </section>
              {/*}  <section className="section-filter-aplicate">
                <div className="content-filter-aplicate">
                  <div className="content-button-filter-aplicate">
                    {estadoFiltro.map((estado: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{estado}</span>
                        <CloseIcon onClick={() => handleRemoveEstado(index)} />
                      </button>
                    ))}
                  </div>
                </div>
                    </section>*/}
            </div>

            <div
              className={`section-filter ${
                tipo === "pendiente_contrato"
                  ? ""
                  : "section-filterContratoOtros"
              }`}
            >
              <span className="title">NOMBRE</span>
              <div className="content-description-filter">
                {/*<section>
                  <span className="orden"
                  >Orden de A a Z</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden"
                  >Orden de Z a A</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
                  </section>*/}
              </div>
              <div className="content-input-filter">
                <InputTextCustomized
                  placeholder="Buscar"
                  required={undefined}
                  layer={""}
                  onValueChange={setSelectFiltro}
                  onPermaValues={selectFiltro}
                  onPropertyChange="nombres"
                  onKeyDown={handleInputKeyDown}
                  onFocus={() => setActiveInput("nombre")}
                />
              </div>
              <section className="section-filter-aplicate">
                <div className="content-filter-aplicate">
                  <div className="content-button-filter-aplicate">
                    {nombreFiltro.map((nombre: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{nombre}</span>
                        <CloseIcon onClick={() => handleRemoveNombre(index)} />
                      </button>
                    ))}
                  </div>
                </div>
              </section>
            </div>

            <div
              className={`section-filter ${
                tipo === "pendiente_contrato"
                  ? ""
                  : "section-filterContratoOtros"
              }`}
            >
              <span className="title">RUT</span>
              <div className="content-description-filter">
                {/*<section>
                  <span className="orden">Orden de Menor a Mayor</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden">Orden de Mayor a Menor</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
              </section>*/}
              </div>
              <div className="content-input-filter">
                <InputTextCustomized
                  placeholder="Buscar"
                  required={undefined}
                  layer={""}
                  onValueChange={setSelectFiltro}
                  onPermaValues={selectFiltro}
                  onPropertyChange="DNI"
                  onKeyDown={handleInputKeyDown}
                  onFocus={() => setActiveInput("DNI")}
                />
              </div>

              <section className="section-filter-aplicate">
                <div className="content-filter-aplicate">
                  <div className="content-button-filter-aplicate">
                    {dniFiltro.map((dni: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{dni}</span>
                        <CloseIcon onClick={() => handleRemoveDNI(index)} />
                      </button>
                    ))}
                  </div>
                </div>
              </section>
            </div>

            <div
              className={`section-filter ${
                tipo === "pendiente_contrato"
                  ? ""
                  : "section-filterContratoOtros"
              }`}
            >
              <span className="title">ÁREA DE NEGOCIO</span>
              <div className="content-description-filter">
                {/*<section>
                  <span className="orden">Orden de A a Z</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden">Orden de Z a A</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
                  </section>*/}
              </div>
              <div className="content-input-filter">
                <InputTextCustomized
                  placeholder="Buscar"
                  required={undefined}
                  layer={""}
                  onValueChange={setSelectFiltro}
                  onPermaValues={selectFiltro}
                  onPropertyChange="areasNegocio"
                  onKeyDown={handleInputKeyDown}
                  onFocus={() => setActiveInput("areasnegocio")}
                />
              </div>

              <section className="section-filter-aplicate">
                <div className="content-filter-aplicate">
                  <div className="content-button-filter-aplicate">
                    {areasNegocioFiltro.map((area: string, index: number) => (
                      <button className="button-filter-aplicate">
                        <span key={index}>{area}</span>
                        <CloseIcon onClick={() => handleRemoveAreas(index)} />
                      </button>
                    ))}
                  </div>
                </div>
              </section>
            </div>
            {tipo === "pendiente_contrato" ? (
              <div className="section-filter">
                <span className="title">ID</span>
                <div className="content-description-filter">
                  {/*} <section>
                  <span className="orden">Orden de A a Z</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden">Orden de Z a A</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
            </section>*/}
                </div>
                <div className="content-input-filter">
                  <InputTextCustomized
                    placeholder="Buscar"
                    required={undefined}
                    layer={""}
                    onValueChange={setSelectFiltro}
                    onPermaValues={selectFiltro}
                    onPropertyChange="procesos"
                    onKeyDown={handleInputKeyDown}
                    onFocus={() => setActiveInput("procesos")}
                  />
                </div>
                <section className="section-filter-aplicate">
                  <div className="content-filter-aplicate">
                    <div className="content-button-filter-aplicate">
                      {procesosFiltro.map((proceso: string, index: number) => (
                        <button className="button-filter-aplicate">
                          <span key={index}>{proceso}</span>
                          <CloseIcon
                            onClick={() => handleRemoveProcesos(index)}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <div
                className={`section-filter ${
                  tipo === "pendiente_contrato"
                    ? ""
                    : "section-filterContratoOtros"
                }`}
              >
                <span className="title">N° FICHA</span>
                <div className="content-description-filter">
                  {/*<section>
                  <span className="orden">Orden de A a Z</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden">Orden de Z a A</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
                  </section>*/}
                </div>
                <div className="content-input-filter">
                  <InputTextCustomized
                    placeholder="Buscar"
                    required={undefined}
                    layer={""}
                    onValueChange={setSelectFiltro}
                    onPermaValues={selectFiltro}
                    onPropertyChange="ficha"
                    onKeyDown={handleInputKeyDown}
                    onFocus={() => setActiveInput("ficha")}
                  />
                </div>

                <section className="section-filter-aplicate">
                  <div className="content-filter-aplicate">
                    <div className="content-button-filter-aplicate">
                      {fichaFiltro.map((ficha: string, index: number) => (
                        <button className="button-filter-aplicate">
                          <span key={index}>{ficha}</span>
                          <CloseIcon onClick={() => handleRemoveFicha(index)} />
                        </button>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            )}

            {tipo !== "pendiente_contrato" ? (
              <div className="section-filter section-filterContrato">
                <span className="title">DIVISIÓN TW</span>
                <section className="section-button">
                  <>
                    <div className="button-container">
                      <div className="button-pair">
                        {division1.map((division) => (
                          <div key={division.key}>
                            {divisionFiltro.includes(division.key) ? (
                              <div>
                                {divisionFiltro
                                  .filter((e: string) => e === division.key)
                                  .map((e: string, index: number) => (
                                    <button
                                      className="button-filter-aplicate button-filter active"
                                      key={index}
                                    >
                                      <span>{e}</span>
                                      <CloseIcon
                                        onClick={() =>
                                          handleRemoveDivision(division.key)
                                        }
                                      />
                                    </button>
                                  ))}
                              </div>
                            ) : (
                              <button
                                className={`button-filter ${
                                  divisionFiltro.includes(division.key) ||
                                  (selectFiltroStorage &&
                                    selectFiltroStorage.division &&
                                    selectFiltroStorage.division.includes(
                                      division.key
                                    ))
                                    ? "active"
                                    : ""
                                }`}
                                style={{ minWidth: "107px" }}
                                onClick={() =>
                                  handleDivisionClick(division.key)
                                }
                              >
                                {division.label}
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                 
                  </>
                </section>
                {/*<section className="section-filter-aplicate">
                  <div className="content-filter-aplicate">
                    <div className="content-button-filter-aplicate">
                      {divisionFiltro.map((division: string, index: number) => (
                        <button className="button-filter-aplicate">
                          <span key={index}>{division}</span>
                          <CloseIcon
                            onClick={() => handleRemoveDivision(index)}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                      </section>*/}
              </div>
            ) : null}

            <div className="section-filter">
              <span className="title">
                {tipo === "pendiente_contrato"
                  ? "FECHA VALIDACIÓN"
                  : "FECHA SOLICITUD"}
              </span>
              <div className="content-description-filter">
                {/*} <section>
                  <span className="orden">Orden de Menor a Mayor</span>
                  <span 
                  onClick={() => setOrdenFiltro("ASCENDENTE")}
                  >

                  <IconOrdenAlfaAscendente />
                  </span>
                </section>
                <section>
                  <span className="orden">Orden de Mayor a Menor</span>
                  <span 
                  onClick={() => setOrdenFiltro("DESCENDENTE")}
                  >
                  <IconOrdenAlfaDescenciente />
                  </span>
                  </section>*/}
              </div>
              <div className="content-input-filter">
                <InputDateCustomized
                  placeholder="Buscar"
                  required={undefined}
                  maxLength={10}
                  onValueChange={setSelectFiltro}
                  onPermaValues={selectFiltro}
                  onPropertyChange="fechasValidacion"
                  onKeyDown={handleInputKeyDown}
                  onFocus={() => setActiveInput("fechasValidacion")}
                />
              </div>
              <section className="section-filter-aplicate">
                <div className="content-filter-aplicate">
                  <div className="content-button-filter-aplicate">
                    {fechasValidacionFiltro.map(
                      (fecha: string, index: number) => (
                        <button className="button-filter-aplicate">
                          <span key={index}>{fecha}</span>
                          <CloseIcon
                            onClick={() => handleRemoveFechas(index)}
                          />
                        </button>
                      )
                    )}
                  </div>
                </div>
              </section>
            </div>
          </section>

          <div className="modal-button-content filter">
            <button
              className={`modal-button confirm`}
              onClick={() => {
                if (tipo === "pendiente_contrato") {
                  getListCandidatePendienteContrato({
                    idCliente,
                    token,
                    objetoSendFiltro,
                  });
                  buttonGoEvent();
                  const array = JSON.stringify(objetoSendFiltro);
                  localStorage.setItem("Filtros1", array);
                } else {
                  getListCandidateEnviadoContrato({
                    idCliente,
                    token,
                    objetoSendFiltro,
                  });

                  buttonGoEvent();
                  const array = JSON.stringify(objetoSendFiltro);
                  localStorage.setItem("Filtros2", array);
                }
              }}
            >
              Aplicar 
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
