import { useContext, useEffect, useState } from "react";
import { Card } from "@mui/material";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import { FILTER_PROFILE, LIST_ROLES } from "../../../../diccionary/Diccionary";

interface ISectionActive {
  localStorageFilter: string;
  setFormFilter?: Function;
  formFilter?: any;
  idCliente?: string;
  objetoFilterGeneral?: any;
}

export default (props: ISectionActive) => {
  const {
    idCliente,
    localStorageFilter,
    setFormFilter,
    formFilter,
    objetoFilterGeneral,
  } = props;

  const {
    getEstadisticaResumenGeneral,
    listaEstadisticaResumenGeneral,
    token,
    roles,
    profile,
  } = useContext(OperacionContratacionContext);

  const [urlLocation, setUrlLocation] = useState<string>("");
  const [itemLocation, setItemLocation] = useState<string>("");
  const [ingresosForDataEtiqueta, setIngresosForDataEtiqueta] =
    useState<boolean>();
  const [activosForDataEtiqueta, setActivosForDataEtiqueta] =
    useState<boolean>();
  const [generalForDataEtiqueta, setGeneralForDataEtiqueta] =
    useState<boolean>();

  useEffect(() => {
    const url = window.location.pathname;
    const parts = url.split("/");
    const ultimoValor = parts[parts.length - 1];
    const primerValor = parts[parts.length - 2];
    setUrlLocation(ultimoValor);
    setItemLocation(primerValor);
  }, [urlLocation]);

  useEffect(() => {
    if (token) {
      // Actualizaciones de estado
      let generalValue = false;
      let ingresosValue = false;
      let activosValue = false;
      if (
        (roles && roles.includes(LIST_ROLES.JEFE_OPS)) ||
        [FILTER_PROFILE.admin_ops].includes(profile)
      ) {
        generalValue = true;
      } else if (objetoFilterGeneral?.ciclo[0] === "Activos") {
        activosValue = true;
      } else {
        ingresosValue = true;
      }

      setGeneralForDataEtiqueta(generalValue);
      setIngresosForDataEtiqueta(ingresosValue);
      setActivosForDataEtiqueta(activosValue);

      // Crear el objeto con los valores actualizados
      const objeto = {
        ingresos: ingresosValue,
        activos: activosValue,
        general: generalValue,
        filtros: {
          clientes: [idCliente],
          areaNegocio: objetoFilterGeneral?.areaNegocio
        },
      };
      getEstadisticaResumenGeneral({ token, objeto });
    }
    //
  }, [
    token,
    objetoFilterGeneral,
    ingresosForDataEtiqueta,
    activosForDataEtiqueta,
    generalForDataEtiqueta,
  ]);

  const handleClickGoRuta = (values: any) => {
    const { ruta, page } = values;
    if (page) {
      window.location.href = `/${ruta}/${page}`;
    } else {
      window.location.href = `/${ruta}`;
    }
  };

  let area = "";
  return (
    <>
      <section className="row card-container">
        <div className={`cards ${activosForDataEtiqueta ? "card3" : "card1"}`}>
          <div className="content-etiqueta-title">
            <label className="numberCard">
              {generalForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.general
                    ?.aprobadoPendienteIngreso
                : area === "contrato"
                ? "30"
                : activosForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.activos?.proximosVencimientos
                : listaEstadisticaResumenGeneral?.ingresos
                    ?.pendientesSolicitudContrato}
            </label>
            <span className="textCard">
              {generalForDataEtiqueta
                ? "CANDIDATOS VALIDADOS PENDIENTES DE SOLICITUD DE INGRESO"
                : area === "contrato"
                ? "SOLICITUD DE CONTRATO RECIBIDAS"
                : activosForDataEtiqueta
                ? "CONTRATOS PRÓXIMOS A VENCER"
                : "CANDIDATOS VALIDADOS PENDIENTES DE SOLICITUD DE INGRESO"}
            </span>
          </div>

          {!generalForDataEtiqueta && area !== "contrato" && (
            <label
              onClick={() => {
                const array = JSON.stringify({
                  [activosForDataEtiqueta ? "estado" : "estados"]: [
                    activosForDataEtiqueta ? "PROX-VENCER" : "ACEPTADA",
                  ],
                });
                localStorage.setItem(
                  activosForDataEtiqueta ? "filtroDotacionVigente" : "Filtros2",
                  array
                );
                handleClickGoRuta({
                  ruta: activosForDataEtiqueta
                    ? "dotacionactiva"
                    : "contratacion",
                  page: idCliente,
                });
              }}
              className="verTodo"
            >
              VER TODO
            </label>
          )}
        </div>

        <div className={`cards ${"card2"}`}>
          <div className="content-etiqueta-title">
            <label className="numberCard">
              {generalForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.general
                    ?.ingresosFueraPlazoPendiente
                : area === "contrato"
                ? "30"
                : activosForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.activos?.contratoEnTopeLegal
                : listaEstadisticaResumenGeneral?.ingresos?.fueraPlazoPendiente}
            </label>
            <span className="textCard">
              {generalForDataEtiqueta ? (
                <>
                  INGRESOS <br />
                  FUERA DE PLAZO PENDIENTES DE APROBACIÓN
                </>
              ) : area === "contrato" ? (
                "CONTRATOS PENDIENTES ENVIAR A VALIDACIÓN "
              ) : activosForDataEtiqueta ? (
                "CONTRATOS PRÓXIMOS A VENCER EN TOPE LEGAL"
              ) : (
                "SOLICITUDES DE INGRESO FUERA DE PLAZO PENDIENTES DE APROBACIÓN"
              )}
            </span>
          </div>

          {!generalForDataEtiqueta && area !== "contrato" && (
            <label
              onClick={() => {
                const array = JSON.stringify({
                  [activosForDataEtiqueta ? "estado" : "estados"]: [
                    activosForDataEtiqueta
                      ? "PROX_VENCER_TOPELEGAL"
                      : "FUERA_PLAZO",
                  ],
                });
                localStorage.setItem(
                  activosForDataEtiqueta ? "filtroDotacionVigente" : "Filtros2",
                  array
                );
                handleClickGoRuta({
                  ruta: activosForDataEtiqueta
                    ? "dotacionactiva"
                    : "contratacion",
                  page: idCliente,
                });
              }}
              className="verTodo"
            >
              VER TODO
            </label>
          )}
        </div>

        <div
          className={`cards ${
            activosForDataEtiqueta
              ? "card1"
              : generalForDataEtiqueta
              ? "card4"
              : "card3"
          }`}
        >
          <div className="content-etiqueta-title">
            <h4 className="numberCard">
              {generalForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.general?.pendientesFirma
                : area === "contrato"
                ? "30"
                : activosForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.activos?.bajasSinProcesar
                : listaEstadisticaResumenGeneral?.ingresos?.solicitudRechazada}
            </h4>
            <span className="textCard">
              {generalForDataEtiqueta
                ? "SOLICITUDES PENDIENTES DE FIRMA DIGITAL"
                : area === "contrato"
                ? "SOLICITUD DE RENOVACIONES RECIBIDAS"
                : activosForDataEtiqueta
                ? "BAJAS CONFIRMADAS SIN PROCESAR"
                : "SOLICITUDES DE INGRESO RECHAZADAS"}
            </span>
          </div>

          {!generalForDataEtiqueta && area !== "contrato" && (
            <label
              onClick={() => {
                const array = JSON.stringify({
                  [activosForDataEtiqueta ? "estado" : "estados"]: [
                    activosForDataEtiqueta ? "BAJA" : "RECHAZADO",
                  ],
                });
                localStorage.setItem(
                  activosForDataEtiqueta ? "filtroDotacionVigente" : "Filtros2",
                  array
                );
                handleClickGoRuta({
                  ruta: activosForDataEtiqueta
                    ? "dotacionactiva"
                    : "contratacion",
                  page: idCliente,
                });
              }}
              className="verTodo"
            >
              VER TODO
            </label>
          )}
        </div>

        <div className={`cards ${generalForDataEtiqueta ? "card3" : "card4"}`}>
          <div className="content-etiqueta-title">
            <h4 className="numberCard">
              {generalForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.general?.solicitudesRechazadas
                : area === "contrato"
                ? "30"
                : activosForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.activos
                    ?.renovacionPendienteFirma
                : listaEstadisticaResumenGeneral?.ingresos?.pendienteFirma}
            </h4>
            <span className="textCard">
              {generalForDataEtiqueta
                ? "SOLICITUDES RECHAZADAS"
                : area === "contrato"
                ? "RENOVACIONES PENDIENTES ENVIAR A VALIDACIÓN "
                : activosForDataEtiqueta
                ? "SOLICITUDES PENDIENTES DE FIRMA DIGITAL"
                : "SOLICITUDES DE INGRESO PENDIENTES DE FIRMAS DIGITAL"}
            </span>
          </div>

          {!generalForDataEtiqueta && area !== "contrato" && (
            <label
              onClick={() => {
                const array = JSON.stringify({
                  [activosForDataEtiqueta ? "estado" : "estados"]: [
                    activosForDataEtiqueta
                      ? "ACEPTADO_PEND_FIRMA"
                      : "ACEPTADO_PEND_FIRMA",
                  ],
                });
                localStorage.setItem(
                  activosForDataEtiqueta ? "filtroProcesoActivo" : "Filtros2",
                  array
                );
                handleClickGoRuta({
                  ruta: activosForDataEtiqueta
                    ? "dotacionactiva"
                    : "contratacion",
                  page: idCliente,
                });
              }}
              className="verTodo"
            >
              VER TODO
            </label>
          )}
        </div>
        <div className="cards card5">
          <div className="content-etiqueta-title">
            <h4 className="numberCard">
              {generalForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.general
                    ?.pendienteIngresoSoftland
                : area === "contrato"
                ? "30"
                : activosForDataEtiqueta
                ? listaEstadisticaResumenGeneral?.activos
                    ?.renovacionPendienteSoftland
                : listaEstadisticaResumenGeneral?.ingresos
                    ?.pendienteIngresoSoftland}
            </h4>
            <span className="textCard">
              {generalForDataEtiqueta
                ? "SOLICITUDES PENDIENTES DE INGRESO EN SOFTLAND"
                : area === "contrato"
                ? "DOCUMENTOS RECHAZADOS POR FIRMA DIGITAL"
                : activosForDataEtiqueta
                ? "SOLICITUDES PENDIENTES DE INGRESO EN SOFTLAND"
                : "SOLICITUDES DE INGRESO  PENDIENTES DE INGRESO EN SOFTLAND"}
            </span>{" "}
          </div>
          {!generalForDataEtiqueta && area !== "contrato" && (
            <label
              onClick={() => {
                const array = JSON.stringify({
                  [activosForDataEtiqueta ? "estado" : "estados"]: [
                    activosForDataEtiqueta
                      ? "ACEPTADO_PEND_SOFTLAND"
                      : "ACEPTADO_PEND_SOFTLAND",
                  ],
                });
                localStorage.setItem(
                  activosForDataEtiqueta ? "filtroProcesoActivo" : "Filtros2",
                  array
                );
                handleClickGoRuta({
                  ruta: activosForDataEtiqueta
                    ? "dotacionactiva"
                    : "contratacion",
                  page: idCliente,
                });
              }}
              className="verTodo"
            >
              VER TODO
            </label>
          )}
        </div>
      </section>
    </>
  );
};
