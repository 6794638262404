import { ReactNode, useEffect, useState } from "react";
import "../../../assets/css/filtro-generico.min.css";
import CloseIcon from "@mui/icons-material/Close";

interface IModalFiltroGenerico {
  title: string;
  localStorageFilter: string | any;
  onBack: Function;
  children?: ReactNode;
  onValueChange?: Function;
  onPropertyChange?: string | any;
  medida?: number | any;
}

export default (props: IModalFiltroGenerico) => {
  const {
    title,
    localStorageFilter,
    onBack,
    children,
    onValueChange,
    onPropertyChange,
    medida,
  } = props;
  const [currenFilterStorage, setCurrentFilterStorage] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      setCurrentFilterStorage(JSON.parse(copyStorage));
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickFilterApplycate = () => {
    try {
      let resultado: any = {};
      const currentData = { ...onPropertyChange };
      const currentClean = { ...onPropertyChange };
      for (const key in currentClean) {
        if (currentClean.hasOwnProperty(key)) {
          currentClean[key] = [];
        }
      }

      const copyStorageParse: any = currenFilterStorage
        ? { ...currenFilterStorage }
        : { ...currentClean };
      for (const key in currentData) {
        if (!Array.isArray(currentData[key])) {
          if (currentData[key].value !== undefined) {
            if (Array.isArray(currentData[key].value)) {
              resultado[key] = currentData[key].value.map(
                ({ code }: any) => code
              );
            } else {
              resultado[key] = [currentData[key].value.code];
            }
          } else {
            resultado[key] = [];
          }
        } else {
          resultado[key] = currentData[key].concat(copyStorageParse[key]);
          resultado[key] = resultado[key].filter(
            (value: any) => value !== null || value !== "null" || value == value
          );
        }
      }

      localStorage.setItem(localStorageFilter, JSON.stringify(resultado));
      onValueChange && onValueChange(currentData);
      onBack();
    } catch (error) {
      console.log("Error al aplicar filtro");
    }
  };

  const handleClickClearFilter = () => {
    try {
      const currentData = { ...onPropertyChange };
      const currentDataFilter = { ...onPropertyChange };
      for (const key in currentData) {
        if (currentData.hasOwnProperty(key)) {
          currentData[key] = [];
        }
      }
      for (const key in currentDataFilter) {
        if (currentDataFilter.hasOwnProperty(key)) {
          if (!Array.isArray(currentDataFilter[key])) {
            currentDataFilter[key] = { value: undefined, valid: true };
          } else {
            currentDataFilter[key] = [];
          }
        }
      }
      localStorage.setItem(localStorageFilter, JSON.stringify(currentData));
      onValueChange && onValueChange(currentDataFilter);
    } catch (error) {
      console.log("error al limpiar fitro");
    }
  };

  const handleEventVerificateData = (arrayData: any) => {
    try {
      for (const key in arrayData) {
        if (arrayData.hasOwnProperty(key) && arrayData[key].length > 0) {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.log("error en mostrar filtros");
    }
  };

  return (
    <>
      <div className="container-filter-group">
        <div className="modal-back-box" onClick={() => onBack()}></div>
        <div
          className={`content-filter-newdesign ${
            medida !== "" ? `filter-${medida}` : "w-100"
          }`}
        >
          <header className="container-title-filter">
            <h2>{title}</h2>
          </header>
          {handleEventVerificateData(currenFilterStorage) && (
            <section className="section-filter-aplicate">
              <div className="content-filter-aplicate">
                <span className="title-filter">Filtros Aplicados</span>
                <div className="content-button-filter-aplicate">
                  {Object.keys(currenFilterStorage).map((key) => {
                    const handleClickRemoveItem = (item: any) => {
                      const currentData = { ...currenFilterStorage };
                      const position = currentData[key]?.findIndex(
                        (state: any) => state == item
                      );
                      currentData[key].splice(position, 1);
                      setCurrentFilterStorage(currentData);
                      onValueChange && onValueChange(currentData);
                    };
                    return (
                      <>
                        {key === "tipoProceso"
                          ? null
                          : currenFilterStorage[key]
                              .flat()
                              .map((item: any, key: number) => {
                                return (
                                  <button
                                    onClick={() => handleClickRemoveItem(item)}
                                    className="button-filter-aplicate"
                                    key={key}
                                  >
                                    {item}
                                    <CloseIcon />
                                  </button>
                                );
                              })}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="content-button-filter">
                <button
                  onClick={handleClickClearFilter}
                  className="button-clear-left"
                >
                  Limpiar Filtros
                </button>
              </div>
            </section>
          )}
          <section className={`content-filter filter-${medida}`}>
            {children}
          </section>

          <footer className="modal-button-content filter">
            <button
              className={`modal-button confirm`}
              onClick={handleClickFilterApplycate}
            >
              Aplicar
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};
