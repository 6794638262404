import { useContext, useEffect, useState } from "react";
import "../../../../assets/css/ficha-postulacion.min.css";
import AreaPersonal from "./AreaPersonal";
import AreaDocumentacion from "./AreaDocumentacion";
import AreaContratacion from "./AreaContratacion";
import IconCancel from "../../../ui/icons-seleccion/IconCancel";
import IconCheck from "../../../ui/icons-seleccion/IconCheck";
interface IRegistroIndividual {
  token: string;
  userId: string;
  formCandidate: any;
  isLoading: boolean;
  masive: boolean;
  onChangeRechazar?: Function;
  onChangeAprobar?: Function;
}

const RegistroIndividual = (props: IRegistroIndividual) => {
  const {
    formCandidate,
    isLoading,
    token,
    userId,
    masive,
    onChangeRechazar,
    onChangeAprobar,
  } = props;

  const handleClickAprobar = () => {
    typeof onChangeAprobar == "function" && onChangeAprobar();
  };

  const handleClickRechazar = () => {
    typeof onChangeRechazar == "function" && onChangeRechazar();
  };

  return (
    <>
      {!isLoading && formCandidate ? (
        <>
          <div className="modal-ficha-postulacion">
            <AreaContratacion
              hiddenDefault={false}
              formCandidate={formCandidate}
            />
            <AreaPersonal hiddenDefault={true} formCandidate={formCandidate} />
            <AreaDocumentacion
              hiddenDefault={true}
              formCandidate={formCandidate}
              token={token}
              userId={userId}
            />
            {masive && (
              <>
              {}
                <footer className={`modal-button-content registro-individual`}>
                  <>
                    <div className="content-button-registro-individual">
                      <button
                        className={`modal-button registro-individual delete-registro-individual`}
                        onClick={onChangeRechazar && handleClickRechazar}
                      >
                        <IconCancel fill="#FAFAFA" />
                      </button>
                      <label>
                        Rechazar <br />
                        Solicitud
                      </label>
                    </div>
                    <div className="content-button-registro-individual">
                      <button
                        className={`modal-button registro-individual confirm-registro-individual`}
                        onClick={onChangeAprobar && handleClickAprobar}
                      >
                        <IconCheck fill="#FAFAFA" />
                      </button>
                      <label>
                        Aceptar <br />
                        Solicitud
                      </label>
                    </div>
                  </>
                </footer>
              </>
            )}
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Cargando datos...</h3>
        </div>
      )}
    </>
  );
};

export default RegistroIndividual;
