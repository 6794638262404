import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { useContext, useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory, useParams } from "react-router-dom";
import formatRUT from "../../../functions/formatRUT";
import validateRUT from "../../../functions/validateRUT";
import { prettifyRut } from "../../../hooks/useRut";
import {
  descarte_perfil,
  status_ingresado,
} from "../../../config/constantes";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import ModalError from "../../../utils/ModalError";
import {
  descarte_cliente,
  descarte_persona,
} from "../../../config/constantes";
import { InputTextarea } from "primereact/inputtextarea";
import FullCandidateModalV2 from "./FullCandidateModalV2";
import { useMsal } from "@azure/msal-react";
import { Tooltip } from "primereact/tooltip";
import ModalChangeId from "../../../utils/ModalChangeId";
import ModalDiscard from "../../../utils/ModalDiscard";
import ModalBlocking from "../../../utils/ModalBlocking";
import ModalChangeGroup from "../../../utils/ModalChangeGroup";
import ModalUpdateCandidate from "../kit-mdtw/modals/modalUpdateCandidate/ModalUpdateCandidate";
import NuevoCandidato from "../groups/NuevoCandidato";
import SeleccionContext from "../../../context/Seleccion/SeleccionContext";
import ModalEncuesta from "../../pages/Campaign/ModalEncuesta";

const RecruitByGroupTableComponent = () => {
  const {
    token, 
    getToken, 
    getListaGeneral,
    toasts,
    getRutVerificado,
    getDocument,
  } = useContext(SeleccionContext);
  const [searchIds, setsearchIds] = useState<any[]>([]);
  const [searchIdsFilter, setsearchIdsFilter] = useState<any[]>([]);
  const [searchIdsFilterNoAdmit, setsearchIdsFilterNoAdmit] = useState<any[]>(
    []
  );
  const [group, setGroup] = useState<any>({});
  const { idGrupo }: any = useParams();
  const [searchId, setSearchId] = useState("");
  const [recruitmentSource, setRecruitmentSource] = useState<any>("");
  const [classEmptyInputName, setClassEmptyInputName] = useState<any>("");
  const [nombre, setNombre] = useState<any>("");
  const [isRepeated, setIsRepeated] = useState<any>(false);
  const [isValidTelf, setIsValidTelf] = useState<boolean>(false);
  const [isValidRUT, setIsValidRUT] = useState<any>(false);
  const [rut, setRut] = useState<any>("");
  let history = useHistory();
  const [classEmptyInputRUT, setClassEmptyInputRUT] = useState<any>("");
  const [classEmptyInputTelephone, setClassEmptyInputTelephone] =
    useState<any>("");
  const [classEmptyRSource, setClassEmptyRSource] = useState<any>("");
  const [telefono1, setTelefono1] = useState<any>("");
  const [candidates, setCandidates] = useState<any>(null);
  const [candidatesNoAdmit, setCandidatesNoAdmit] = useState<any>(null);
  const [candidatesTabla, setCandidatesTabla] = useState<any>(null);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);
  const [recruitmentSourceFilterNoAdmit, setRecruitmentSourceFilterNoAdmit] =
    useState<any>("");
  const [recruitmentSourceFilter, setRecruitmentSourceFilter] =
    useState<any>("");
  const [filterId, setFilterId] = useState<any>("");
  const [filterIdNoAdmit, setFilterIdNoAdmit] = useState<any>("");
  const [resultProgresos, setresultProgresos] = useState<any>(null);
  const [resultProgresosNoAdmit, setresultProgresosNoAdmit] =
    useState<any>(null);
  const [filterStatusNoAdmit, setFilterStatusNoAdmit] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<any>(null);
  const [fechaCreacion, setFechaCreacion] = useState<any>(null);
  const [fechaCreacionNoAdmit, setFechaCreacionNoAdmit] = useState<any>(null);
  const [resultFechasCreacion, setresultFechasCreacion] = useState<any>(null);
  const [resultFechasCreacionNoAdmit, setresultFechasCreacionNoAdmit] =
    useState<any>(null);
  const [filterRut, setFilterRut] = useState<any>("");
  const [filterRutNoAdmit, setFilterRutNoAdmit] = useState<any>("");
  const [progreso, setProgreso] = useState<any>(null);
  const [progresoNoAdmit, setProgresoNoAdmit] = useState<any>(null);
  const [recruitmentSourceModal, setRecruitmentSourceModal] = useState<any>("");
  const [recruitmentIdModal, setRecruitmentIdModal] = useState<any>("");
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const menuDescartar = useRef<Menu>(null);
  const toast = useRef<Toast>(null);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [responseExcel, setResponseExcel] = useState<any>(null);
  const [uploadModalCargaExitosa, setUploadModalCargaExitosa] = useState(false);
  const [visibleModalError, setVisibleModalError] = useState(false);
  const [visibleModalErrorUpload, setVisibleModalErrorUpload] = useState(false);
  const [visiblePorCandidato, setVisiblePorCandidato] = useState(false);
  const [visiblePorCliente, setVisiblePorCliente] = useState(false);
  const [candidatosADescartar, setCandidatosADescartar] = useState<any[]>([]);
  const [candidatosABloquear, setCandidatosABloquear] = useState<any[]>([]);
  const [inputModalCandidatos, setInputModalCandidatos] = useState<any>("");
  const [inputModalClientes, setInputModalClientes] = useState<any>("");
  const [candidateModal, setCandidateModal] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toastDescarte = useRef<Toast>(null);
  const [visiblePorPerfil, setVisiblePorPerfil] = useState(false);
  const [inputModalPerfiles, setInputModalPerfiles] = useState<any>("");
  const [visibleModalChangeId, setVisibleModalChangeId] = useState(false);
  const [visibleModalChangeGroup, setVisibleModalChangeGroup] = useState(false);
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);
  const [visibleModalBlocking, setVisibleModalBlocking] = useState(false);
  const [visibleWarningInfo, setVisibleWarningInfo] = useState(false);
  const [filterUsuario, setFilterUsuario] = useState("");
  const [optionReclutador, setOptionReclutador] = useState<any>([]);
  const [warnings, setWarnings] = useState<any[]>([]);
  const { instance } = useMsal();
  const [profile, setProfile] = useState<any>(null);
  const [actionMasiveDisable, setActionMasiveDisable] =
    useState<boolean>(false);
  const [isOpenModalUpdateCandidate, setIsOpenModalUpdateCandidate] =
    useState<boolean>(false);
  const [resultBusperId, setResultBusperId] = useState<string | undefined>(
    undefined
  );
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isFree, setIsFree] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageSpanish, setMessageSpanish] = useState<string>(
    "Ingrese Rut y presione Revisar Rut para consultar su estado en el sistema"
  );
  const [isOpenModalCampaign, setIsOpenModalCampaign] =
    useState<boolean>(false);

  useEffect(() => {
    if (token) {
      getListaGeneral({ grupo: idGrupo, token });
    }
  }, [token]);

  useEffect(() => {
    if (resultBusperId) {
      setIsOpenModalCampaign(true);
    }
  }, [resultBusperId]);

  const backToTable = (flag: number) => {
    if (flag == 1) {
      setVisiblePorCandidato(false);
      setInputModalCandidatos("");
    } else if (flag == 2) {
      setVisiblePorCliente(false);
      setInputModalClientes("");
    } else if (flag == 3) {
      setVisiblePorPerfil(false);
      setInputModalPerfiles("");
    }
  };

  const onBackdropClick = () => {
    setIsModalVisible(false);
  };

  const onBackdropClickSave = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  const initFilters = () => {
    setFilterStatus({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [
          { value: "", matchMode: FilterMatchMode.EQUALS, filtered: false },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });
    setFilterStatusNoAdmit({
      busPerStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rutWithoutFormat: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [
          { value: "", matchMode: FilterMatchMode.EQUALS, filtered: false },
        ],
      },
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [
          {
            value: "",
            matchMode: FilterMatchMode.STARTS_WITH,
            filtered: false,
          },
        ],
      },
    });
  };

  const recruitmentSources = [
    { name: "Todos", code: "" },
    { name: "BNE", code: "BNE" },
    { name: "Chile Trabajos", code: "Chile Trabajos" },
    { name: "CompuTrabajo", code: "CompuTrabajo" },
    { name: "Estados de WhatsApp", code: "Estados de WhatsApp" },
    { name: "Facebook pagado Team-Work", code: "Facebook pagado Team-Work" },
    { name: "Facebook personal", code: "Facebook personal" },
    { name: "Hiringroom", code: "Hiringroom" },
    { name: "Instagram Pagado Team-Work", code: "Instagram Pagado Team-Work" },
    { name: "Instagram Personal", code: "Instagram Personal" },
    { name: "Laborum", code: "Laborum" },
    { name: "OMIL", code: "OMIL" },
    { name: "Referido Candidato", code: "Referido Candidato" },
    { name: "Referido Colaborador", code: "Referido Colaborador" },
    { name: "Yapo", code: "Yapo" },
    { name: "WhatsApp", code: "WhatsApp" },
    { name: "Otro", code: "Otro" },
    { name: "Data de Respaldo", code: "DataRespaldo" },
  ];

  const closeFullModal = (flag: any) => {
    setIsModalVisible(false);
    if (flag === 1) {
      creartToast(
        toastDescarte,
        "error",
        "Registro almacenado",
        "No se incluirá en el listado",
        6000
      );
    } else {
      creartToast(
        toastDescarte,
        "success",
        "Registro exitoso",
        "Revisar en bandeja reclutados",
        6000
      );
    }
    getCandidates();
  };

  const rightContents = () => {
    return (
      <>
        <div className="cardTest">
          <div className="" style={{}}>
            <Button
              label="Reclutar"
              className="p-button-raised p-button-text"
              onClick={() => goToRecruit(idGrupo)}
            />

            <Button
              label="Validar"
              className="p-button-text mx-5"
              onClick={() => goToValidation(idGrupo)}
            />
            <Button
              label="Confirmar"
              className="p-button-text "
              onClick={() => goToConfirm(idGrupo)}
            />
          </div>
        </div>
      </>
    );
  };

  const goToActiveProcess = () => {
    return (
      <>
        <div className="col">
          <a
            href="/procesosasignadosactivos"
            style={{
              color: "#AEAEAE",
              padding: "6px",
              borderRadius: "50%",
              border: "1px solid #AEAEAE",
              width: "28px",
            }}
            aria-label="Search"
            className="flex justify-content-center align-items-center"
          >
            <i className="pi pi-arrow-left"></i>
          </a>
        </div>
      </>
    );
  };

  const goToRecruit = (idGrupo: string) => {
    history.push("/reclutarporgrupos/" + idGrupo);
  };

  const goToValidation = (idGrupo: string) => {
    history.push("/validacionporgrupo/" + idGrupo);
  };

  const goToConfirm = (idGrupo: string) => {
    history.push("/confirmacionporgrupo/" + idGrupo);
  };

  const onNombreChange = (e: any) => {
    let nombreIngresado = e.target.value;
    if (nombreIngresado.length > 0) {
      setClassEmptyInputName("input06A502Border");
    }
    let nombreApellido = "";
    let arrayNombre = nombreIngresado.split(/\s+/);

    if (!arrayNombre[1] && nombreIngresado.search(" ") === -1) {
      nombreApellido = arrayNombre[0];
    } else {
      nombreApellido = arrayNombre[0] + " " + arrayNombre[1];
    }
    setNombre(nombreApellido);
  };

  const onRutChange = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setRut(formatRUT(rutIngresado.trim()));
    setIsValidRUT(validateRUT(formatRUT(rutIngresado.trim())));
    if (
      validateRUT(formatRUT(rutIngresado.trim())) &&
      formatRUT(rutIngresado.trim()).length > 10
    ) {
      setClassEmptyInputRUT("input06A502Border");
    } else if (formatRUT(rutIngresado.trim()).length === 0) {
      setClassEmptyInputRUT("inputced4daBorder");
    } else {
      setClassEmptyInputRUT("inputD32F2FBorder");
    }
  };

  const ontelefono1Change = (value: string) => {
    let telefonoIngresado = value.replace(/[^0123456789]/g, ""); //value only numbers

    if (
      telefonoIngresado.length == 10 ||
      telefonoIngresado.length < 9 ||
      telefonoIngresado.length > 11
    ) {
      setIsValidTelf(false);
    }

    if (telefonoIngresado.length == 11) {
      setClassEmptyInputTelephone("input06A502Border");
      telefonoIngresado = telefonoIngresado.substring(
        2,
        telefonoIngresado.length
      );
    }

    if (telefonoIngresado.length == 9) {
      setIsValidTelf(true);
      setClassEmptyInputTelephone("input06A502Border");
    }

    setTelefono1(telefonoIngresado);
  };

  const handleClickUpdateCandidate = (rowData: any) => {
    setCandidateModal(rowData);
  };

  const handleClickVerificarRut = (rowData: any) => {
    const { personaDni } = rowData;
    handleClickUpdateCandidate(rowData);
    getRutVerificado({
      personaRut: personaDni,
      setIsFree,
      setMessageSpanish,
      setLoading: setIsLoading,
      setButtonDisabled,
      consultaRut: false,
      setIsOpenModalUpdateCandidate,
      token,
    });
    getDocument({ personaDNI: personaDni, token });
  };

  const cerrarModalDiscard = () => {
    setCandidatosADescartar([]);
    setVisibleModalDiscard(false);
    // buscarCandidatos();
  };

  const addList = () => {
    if (nombre) {
      setClassEmptyInputName("");
    } else {
      setClassEmptyInputName("inputD32F2FBorder");
    }
    if (rut && isValidRUT) {
      setClassEmptyInputRUT("");
    } else {
      setClassEmptyInputRUT("inputD32F2FBorder");
    }
    if (telefono1) {
      setClassEmptyInputTelephone("");
    } else {
      setClassEmptyInputTelephone("inputD32F2FBorder");
    }
    if (recruitmentSource) {
      setClassEmptyRSource("");
    } else {
      setClassEmptyRSource("inputD32F2FBorder");
    }
    if (nombre && telefono1 && recruitmentSource) {
      onSaveCandidateData();
    }
  };

  const onSaveCandidateData = () => {
    getToken();
    let rutSinPuntos = rut.replace(/[\.]/g, "");
    let nombreSeparado = nombre.split(/\s+/);
    let bdTelefono = "";
    const busquedaId = searchId == "" ? idGrupo : searchId;
    if (telefono1) bdTelefono = "56" + telefono1;

    const candidateData = {
      busquedaId: busquedaId,
      cargoGroupId: idGrupo,
      comunaId: null,
      personaId: rutSinPuntos,
      busPerPaseMovilidad: 2,
      orgId: "tw",
      fuenteOrg: recruitmentSource.code,
      personaDni: rutSinPuntos,
      personaNombre1: nombreSeparado[0],
      personaNombre2: "",
      personaApe1: nombreSeparado[1],
      personaApe2: "",
      paisId: 56,
      personaFecNac: "",
      celular: "",
      wsp: bdTelefono,
      empresa: "",
      comunaOmil: "",
    };

    api
      .post(`busqueda-persona-custom`, candidateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        setNombre("");
        setTelefono1("");
        setRut("");
        setRecruitmentSource("");
        getCandidates();
        getCandidatesNoAdmit();
      })
      .catch((error) => {
        if (
          error.response.data.errors[0].errorId == "1010" ||
          error.response.data.errors[0].errorId == "2020"
        ) {
          setIsRepeated(true);
        }
      });
  };

  const renderFieldSearch = () => {
    let claseInputRut = "";
    if (
      (isValidRUT === false && rut !== "") ||
      (rut.length < 10 && rut !== "")
    ) {
      claseInputRut = "inputD32F2FBorder";
    } else if (
      (isValidRUT === true && rut !== "") ||
      (rut.length < 10 && rut !== "")
    ) {
      claseInputRut = "input06A502Border";
    }
    return (
      <>
        <div>
          <InputText
            id="inputTlfRutCtx1"
            className={claseInputRut + " mr-1 " + classEmptyInputRUT}
            value={rut}
            onChange={(e) => onRutChange(e)}
            placeholder="11.234.567-8"
            onFocus={() => setIsRepeated(false)}
          />
        </div>
      </>
    );
  };

  const leftToolbarTemplate = () => {
    // const listBusquedasId = searchIds
    //   .filter((label) => Number(label.label))
    //   .map(({ label }) => String(label));
    // return (
    //   <>
    //     <IngresoRapidoContainer listVacantesIds={listBusquedasId} />
    //     {modalReclutar && <ModalFichaCandidato />}
    //   </>
    // );
    return (
      <>
        <div className="flex flex-direcction-row col-12 p-fluid formgrid grid">
          <div className="field col-12 md:col-3">
            <label className="mr-3">Nombre Postulante:</label>
            <InputText
              className={classEmptyInputName}
              id="inputName1Ctx1"
              value={nombre}
              placeholder="Escriba nombre y apellido"
              //disabled={isDisabled}
              type="text"
              onChange={(e) => onNombreChange(e)}
              maxLength={30}
              onFocus={() => setIsRepeated(false)}
            />
            <div>
              {nombre !== "" && nombre.split(" ").length == 1 && (
                <small style={{ color: "#D32F2F" }}>
                  Debe ingresar al menos un apellido
                </small>
              )}
            </div>
            <div className="mt-2">
              {isRepeated && (
                <small style={{ color: "#D32F2F" }}>
                  Candidato duplicado, ya se encuentra registrado en este
                  proceso.
                </small>
              )}
              {rut && !isValidRUT && (
                <small style={{ color: "#D32F2F" }}>Rut invalido</small>
              )}
              {isValidRUT && !isValidTelf && (
                <small style={{ color: "#D32F2F" }}>Teléfono invalido</small>
              )}
            </div>
          </div>

          <div className="field col-12 md:col-2">
            <label>Rut</label>
            {renderFieldSearch()}
          </div>

          <div className="field col-12 md:col-2">
            <label>Teléfono</label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <p className="ml-2">+56</p>
              </span>
              <InputText
                className={classEmptyInputTelephone}
                value={telefono1}
                id="inputTlf1Ctx1"
                placeholder="12345678"
                maxLength={12}
                onChange={(e: any) => ontelefono1Change(e.target.value)}
                //disabled={isDisabled}
                onFocus={() => setIsRepeated(false)}
              />
            </div>
          </div>

          <div className="field col-12 md:col-2">
            <label>ID al que postula:</label>
            <Dropdown
              value={searchId}
              options={searchIds}
              onChange={(e) => setSearchId(e.target.value)}
              //className={classEmptyRSource}
              optionLabel="label"
              placeholder="Seleccione"
            />
          </div>

          <div className="field col-12 md:col-2">
            <label>Fuente reclutamiento:</label>
            <Dropdown
              value={recruitmentSource}
              options={recruitmentSources}
              onChange={onRecuitmentSourcesChange}
              //className={classEmptyRSource}
              optionLabel="name"
              placeholder="Fuente de Reclutamiento"
            />
          </div>
          <div className="field col-12 md:col-1">
            <Button
              icon="pi pi-plus"
              className="p-button-rounded p-button-success p-button-outlined mt-4"
              aria-label="Search"
              onClick={() => addList()}
              tooltip="Agregar al listado"
              tooltipOptions={{
                className: "bluegray-tooltip",
                position: "left",
              }}
              disabled={
                nombre &&
                isValidRUT &&
                telefono1 &&
                isValidTelf &&
                recruitmentSource
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </>
    );
  };

  const selectionFilterRut = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRut(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatus(filter_Status);
  };

  const selectionFilterRutNoAdmit = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setFilterRutNoAdmit(formatRUT(rutIngresado.trim()));
    let value = rutIngresado.replaceAll(".", "").replace("-", "");
    let filter_Status = { ...filterStatus };
    filter_Status["rutWithoutFormat"].constraints[0].value = value;
    setFilterStatusNoAdmit(filter_Status);
  };

  const filterbusiness_fechaSelected = (e: { value: any }) => {
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacion(e.value);
  };

  const filterbusiness_fechaSelectedNoAdmit = (e: { value: any }) => {
    const business_fechasContrains: any = [];
    const business_fechas = e.value;
    business_fechas.forEach(function (business_fecha: any) {
      business_fechasContrains.push({
        value: business_fecha.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_fechasContrains.length === 0) {
      business_fechasContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatusNoAdmit((prevState: any) => ({
      ...prevState,
      cdcCreateDt2: {
        operator: FilterOperator.OR,
        constraints: [...business_fechasContrains],
      },
    }));
    setFechaCreacionNoAdmit(e.value);
  };

  const selectionFilterFuente = (e: any) => {
    let filter_Status = { ...filterStatus };
    filter_Status["fuenteOrg"].constraints[0].value = e.target.value.code;
    setFilterStatus(filter_Status);
    setRecruitmentSourceFilter(e.target.value);
  };

  const selectFilterFuenteVarios = (e: { value: any }) => {
    const business_FuenteContrains: any = [];
    const business_fuente = e.value;
    business_fuente.forEach(function (business_fuente: any) {
      business_FuenteContrains.push({
        value: business_fuente.code,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_FuenteContrains.length === 0) {
      business_FuenteContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      fuenteOrg: {
        operator: FilterOperator.OR,
        constraints: [...business_FuenteContrains],
      },
    }));
    setRecruitmentSourceFilter(e.value);
  };

  const headerCargaExitosa = () => {
    return (
      <>
        <div className="alignIcon">
          <span className="material-symbols-outlined md-40">check_circle</span>
          <label className="ml-3">Carga exitosa</label>
        </div>
      </>
    );
  };

  const renderFooterCandidate = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorCandidatos(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalCandidatos.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(1)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const renderFooterClient = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorClientes(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalClientes.length == 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(2)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  const renderFooterProfile = () => {
    return (
      <div className="pr-7">
        <Button
          label="Descartar candidatos"
          onClick={() => descartarPorPerfil(candidatosADescartar)}
          icon="pi pi-arrow-left"
          disabled={inputModalPerfiles.length === 0}
        />
        <Button
          label="Volver"
          className="p-button-text"
          onClick={() => backToTable(3)}
          icon="pi pi-arrow-right"
          iconPos="right"
        />
      </div>
    );
  };

  const footerCargaExitosa = () => {
    return (
      <>
        <div className="alignIcon flex justify-content-end mr-5">
          <Button
            label="Entendido"
            className="p-button-success"
            onClick={() => closeCargaExitosa()}
          />
        </div>
      </>
    );
  };

  const headerCargaNoExitosa = () => {
    return (
      <>
        <div className="alignIcon">
          <span className="material-symbols-outlined md-40 error">cancel</span>
          <label className="ml-3">Carga no permitida</label>
        </div>
      </>
    );
  };

  const footerNoCargaExitosa = () => {
    return (
      <>
        <div className="alignIcon flex justify-content-end mr-5">
          <Button
            label="Entendido"
            className="p-button-primary"
            onClick={() => closeCargaNoExitosa()}
          />
        </div>
      </>
    );
  };

  const selectionFilterFuenteNoAdmit = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit };
    filter_Status["fuenteOrg"].constraints[0].value = e.target.value.code;
    setFilterStatusNoAdmit(filter_Status);
    setRecruitmentSourceFilterNoAdmit(e.target.value);
  };

  /*const selectionFilterId = (e: any) => {
    let filter_Status = { ...filterStatus };
    console.log(e.target.value);
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterId(e.target.value);
  };*/

  const selectFilterVariosId = (e: { value: any }) => {
    const business_IdContrains: any = [];
    const business_id = e.value;
    business_id.forEach(function (business_id: any) {
      business_IdContrains.push({
        value: business_id,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_IdContrains.length === 0) {
      business_IdContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      busquedaId: {
        operator: FilterOperator.OR,
        constraints: [...business_IdContrains],
      },
    }));
    setFilterId(e.value);
  };

  /* const selectionFilterUsuario = (e: any) => {
    let filter_Status = { ...filterStatus };
   filter_Status["usuarioNombre"].constraints[0].value = e.target.value;
    setFilterStatus(filter_Status);
    setFilterUsuario(e.target.value);
  };*/

  const selectionFilterUsuarioVarios = (e: { value: any }) => {
    const business_UsuarioContrains: any = [];
    const business_usuarios = e.value;
    business_usuarios.forEach(function (business_usuarios: any) {
      business_UsuarioContrains.push({
        value: business_usuarios,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_UsuarioContrains.length === 0) {
      business_UsuarioContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      usuarioNombre: {
        operator: FilterOperator.OR,
        constraints: [...business_UsuarioContrains],
      },
    }));
    setFilterUsuario(e.value);
  };

  const selectionFilterIdNoId = (e: any) => {
    let filter_Status = { ...filterStatusNoAdmit };
    filter_Status["busquedaId"].constraints[0].value = e.target.value;
    setFilterStatusNoAdmit(filter_Status);
    setFilterIdNoAdmit(e.target.value);
  };

  const selectProgreso = (resultProgresos: any) => {
    let circleClass = "";
    if (resultProgresos.name === "circleRed") {
      circleClass = "circleRed";
    } else if (resultProgresos.name === "circleYellow") {
      circleClass = "circleYellow";
    } else if (resultProgresos.name === "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <div className={circleClass}></div>
      </>
    );
  };

  const toggleModalUpload = () => {
    setRecruitmentIdModal("");
    setRecruitmentSourceModal("");
    setUploadModalVisible(true);
  };

  const updateList = (
    list: any,
    checked_validado: boolean,
    checked_nocontestado: boolean
  ) => {
    const arr = [...candidates];
    list.forEach((element: { busPerId: any }) => {
      const index = arr.findIndex((x) => x.busPerId == element.busPerId);

      if (checked_validado && checked_nocontestado) {
        arr[index].busPerStatus = "NOCONFIRMADO";
      } else if (checked_validado && !checked_nocontestado) {
        arr[index].busPerStatus = "VALIDADO";
      } else if (!checked_validado && checked_nocontestado) {
        arr[index].busPerStatus = "NOCONFIRMADO";
      } else {
        arr[index].busPerStatus = "PORVALIDAR";
      }
    });
    setCandidates(arr);
  };

  const filterbusiness_progresoSelected = (e: { value: any }) => {
    const business_progresoContrains: any = [];
    const business_progresos = e.value;
    business_progresos.forEach(function (business_progresos: any) {
      business_progresoContrains.push({
        value: business_progresos.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_progresoContrains.length === 0) {
      business_progresoContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatus((prevState: any) => ({
      ...prevState,
      busPerPorcCompletitud: {
        operator: FilterOperator.OR,
        constraints: [...business_progresoContrains],
      },
    }));
    setProgreso(e.value);
  };

  const filterbusiness_progresoSelectedNoAdmit = (e: { value: any }) => {
    const business_progresoContrains: any = [];
    const business_progresos = e.value;
    business_progresos.forEach(function (business_progresos: any) {
      business_progresoContrains.push({
        value: business_progresos.name,
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: true,
      });
    });
    if (business_progresoContrains.length === 0) {
      business_progresoContrains.push({
        value: "",
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      });
    }
    setFilterStatusNoAdmit((prevState: any) => ({
      ...prevState,
      busPerPorcCompletitud: {
        operator: FilterOperator.OR,
        constraints: [...business_progresoContrains],
      },
    }));
    setProgresoNoAdmit(e.value);
  };

  const changeStatusValidated = (listadoValidar: any, isMasive: boolean) => {
    const busPerIdList = listadoValidar.map((x: any) => {
      return { busPerId: x.busPerId };
    });
    let listadoOrdenado = {
      orgId: "tw",
      list: busPerIdList,
    };
    getToken();
    api
      .put(`busqueda-persona/status/por-validar`, listadoOrdenado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (isMasive) {
          creartToast(
            toast,
            "success",
            "Candidatos validados",
            "Contacte para confirmar su asistencia",
            6000
          );
        } else {
          creartToast(
            toast,
            "success",
            "Candidato validado",
            "Contacte para confirmar su asistencia",
            6000
          );
        }
        setSelectedCandidates([]);
        getCandidates();
        //updateList(listadoValidar, true,false);
      });
  };

  const creartToast = (
    toast: any,
    severity: string,
    summary: string,
    detail: string,
    life: number
  ) => {
    if (toast != null && toast.current != null)
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
      });
  };

  const uploadFileToAzure = (files: any) => {
    getToken();
    setIsLoadingExcel(true);
    setUploadModalVisible(false);
    let id = idGrupo;
    if (recruitmentIdModal && recruitmentIdModal != "") {
      id = recruitmentIdModal;
    }
    let fileName = recruitmentSourceModal.code
      .concat("_")
      .concat(id)
      .concat("_upload.xlsx");
    let file = new File([files.files[0]], fileName, {
      type: files.files[0].type,
      lastModified: files.files[0].lastModified,
    });
    let formData = new FormData();
    formData.append("file", file);
    api
      .post(`excel-upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        getCandidates();
        getCandidatesNoAdmit();
        setResponseExcel(response.data.data);
        setUploadModalVisible(false);
        setIsLoadingExcel(false);
        setUploadModalCargaExitosa(true);
      })
      .catch((error) => {
        setVisibleModalErrorUpload(true);
        setIsLoadingExcel(false);
      });
  };

  const closeCargaExitosa = () => {
    setUploadModalCargaExitosa(false);
    //buscarCandidatos();
  };

  const closeCargaNoExitosa = () => {
    setVisibleModalErrorUpload(false);
    // buscarCandidatos();
  };

  const cerrarModalError = () => {
    setVisibleModalError(false);
    // buscarCandidatos();
  };

  const openModalDiscard = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosADescartar(array);
    setVisibleModalDiscard(true);
  };

  const openModalDiscardMasive = (candidatosADescartar: any[]) => {
    setVisibleModalDiscard(true);
    setCandidatosADescartar(candidatosADescartar);
  };

  const openModalChangeGroup = (rowData: any) => {
    setCandidateModal(rowData);
    setVisibleModalChangeGroup(true);
  };

  const cerrarModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
  };

  const saveModalChangeGroup = () => {
    setVisibleModalChangeGroup(false);
    getCandidates();
    creartToast(
      toast,
      "success",
      "Cambio de grupo",
      "Guardado correctamente",
      3000
    );
    setSelectedCandidates([]);
  };

  const openModalChangeId = (rowData: any) => {
    setCandidateModal(rowData);
    setVisibleModalChangeId(true);
  };

  const cerrarModalChangeId = () => {
    setVisibleModalChangeId(false);
  };

  const saveModalChangeId = () => {
    setVisibleModalChangeId(false);
    getCandidates();
    creartToast(
      toast,
      "success",
      "Cambio de Id",
      "Guardado correctamente",
      3000
    );
    setSelectedCandidates([]);
  };

  const saveModalDiscard = () => {
    setVisibleModalDiscard(false);
    setCandidatosADescartar([]);
    setSelectedCandidates([]);
    getCandidates();
    creartToast(toast, "success", "Descarte", "Guardado correctamente", 3000);
  };

  const openModalBlocking = (rowData: any) => {
    let array = [];
    array.push(rowData);
    setCandidatosABloquear(array);
    setVisibleModalBlocking(true);
  };

  const openModalBlokingMasive = (candidatosABloquear: any[]) => {
    setVisibleModalBlocking(true);
    setCandidatosABloquear(candidatosABloquear);
  };

  const saveModalBloking = () => {
    setVisibleModalBlocking(false);
    setCandidatosABloquear([]);
    getCandidates();
    setSelectedCandidates([]);
    creartToast(toast, "error", "Bloqueo", "Guardado correctamente", 3000);
    getCandidatesNoAdmit();
  };

  const cerrarModalBlocking = () => {
    setCandidatosADescartar([]);
    setVisibleModalBlocking(false);
  };

  const unBlocking = (candidate: any) => {
    const data = {
      excPerId: candidate.warningData[0].excPerId,
      usuarioId: instance.getActiveAccount()?.localAccountId,
      busPerId: candidate.busPerId,
    };
    api
      .put(`exclusion-persona-recover`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        getCandidates();
        getCandidatesNoAdmit();
        creartToast(toast, "warn", "Exitoso", "Se reincorpora registro", 6000);
      })
      .catch((error) => {
        setVisibleModalError(true);
      });
  };

  const leftToolbarTemplateTwo = () => {
    return (
      <>
        <span className="material-symbols-outlined md-24 mt-1 iconBlue">
          filter_alt
        </span>
        <p className="margin0 padding7y14 filter_table_text">Fuente R:</p>
        <MultiSelect
          value={recruitmentSourceFilter}
          options={recruitmentSources}
          onChange={(e) => selectFilterFuenteVarios(e)}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Progreso:</p>
        <MultiSelect
          id="muktiselectCtx1"
          className="w-9rem"
          value={progreso}
          options={resultProgresos}
          itemTemplate={selectProgreso}
          onChange={(e) => {
            filterbusiness_progresoSelected(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">ID:</p>
        <MultiSelect
          value={filterId}
          options={searchIdsFilter}
          onChange={(e) => {
            selectFilterVariosId(e);
          }}
          optionLabel="label"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacion}
          options={resultFechasCreacion}
          onChange={(e) => {
            filterbusiness_fechaSelected(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          value={filterRut}
          onChange={(e: any) => selectionFilterRut(e)}
          placeholder="11.234.567-8"
          maxLength={12}
          keyfilter={/^[^#<>*!]+$/}
        />

        {group.busquedas &&
          (group.busquedas.filter(
            (search: any) =>
              instance.getActiveAccount()?.localAccountId ===
              search.accountManager
          ).length > 0 ||
            ["adm_tw", "tw_admsel", "tw_admsel_prof"].includes(
              String(sessionStorage.getItem("profile"))
            )) && (
            <>
              <p className="margin0 padding7y14 filter_table_text">
                Reclutador:
              </p>
              <MultiSelect
                value={filterUsuario}
                options={optionReclutador}
                onChange={(e) => selectionFilterUsuarioVarios(e)}
                optionLabel="name"
                placeholder="Seleccione"
                selectedItemsLabel="{0} áreas seleccionadas"
                maxSelectedLabels={1}
              />
            </>
          )}
      </>
    );
  };

  const MenuDemo = (candidato: any) => {
    const menu = useRef<any>(null);
    const toast = useRef<any>(null);
    const mostrarMenuDescartarBarra = (e: any) => {
      if (menu != null && menu.current) {
        menu.current.toggle(e);
      }
    };
    const items = [
      {
        items: [
          {
            label: "Por Candidato",
            command: () => {
              setVisiblePorCandidato(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
          {
            label: "Por Cliente",
            command: () => {
              setVisiblePorCliente(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
          {
            label: "Conducta Inadecuada",
            command: () => {
              setVisiblePorPerfil(true);
              let array = [];
              array.push(candidato.candidato);
              setCandidatosADescartar(array);
            },
          },
        ],
      },
    ];
    return (
      <div>
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <Tooltip target=".des" />
        <span
          className="des material-symbols-outlined md-24 iconCRed  cursor-pointer"
          data-pr-tooltip="Descartar"
          data-pr-position="right"
          onClick={(event) => mostrarMenuDescartarBarra(event)}
        >
          close
        </span>
      </div>
    );
  };

  const items = [
    {
      items: [
        {
          label: "Por Candidato",
          command: () => {
            setVisiblePorCandidato(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Por Cliente",
          command: () => {
            setVisiblePorCliente(true);
            setCandidatosADescartar(selectedCandidates);
          },
        },
        {
          label: "Conducta Inadecuada",
          command: () => {
            setVisiblePorPerfil(true);
            setCandidatosADescartar(selectedCandidates);
            //descartarPorCandidato(candidato);
            //toast.current.show({ severity: 'success', summary: 'Descartado', detail: 'Candidato descartado', life: 3000 });
          },
        },
      ],
    },
  ];

  const descartarPorCandidatos = (discardedCandidates: any[]) => {
    const listaDescarte = discardedCandidates.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_persona,
        comentario: inputModalCandidatos,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "error",
          "Candidatos descartados",
          "Almacenados en los registros",
          6000
        );
        getCandidates();
        getCandidatesNoAdmit();
        setSelectedCandidates([]);
        setVisiblePorCandidato(false);
        setInputModalCandidatos("");
      })
      .catch((error) => {});
  };

  const descartarPorClientes = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_cliente,
        comentario: inputModalClientes,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por cliente",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit();
        setSelectedCandidates([]);
        setVisiblePorCliente(false);
        setInputModalClientes("");
      })
      .catch((error) => {});
  };

  const descartarPorPerfil = (discardedClients: any[]) => {
    const listaDescarte = discardedClients.map((item: any) => {
      return {
        busPerId: item.busPerId,
        orginDeDescarte: descarte_perfil,
        comentario: inputModalPerfiles,
      };
    });
    let request = {
      listaDescarte: listaDescarte,
    };
    getToken();
    api
      .put(`/busquedapersonadescarte/`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        creartToast(
          toast,
          "success",
          "Descartado",
          "Descartado por Conducta Inadecuada",
          3000
        );
        getCandidates();
        getCandidatesNoAdmit();
        setSelectedCandidates([]);
        setVisiblePorPerfil(false);
        setInputModalPerfiles("");
      })
      .catch((error) => {});
  };

  const rightToolbarTemplateTwo = () => {
    return (
      <>
        <Menu model={items} popup ref={menuDescartar} id="popup_menu" />
        <div className="mt-4">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
            onClick={() => openModalDiscardMasive(selectedCandidates)}
            tooltip={"Descartar seleccionados"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
            disabled={selectedCandidates.length > 0 ? false : true}
          />

          <Button
            icon="pi pi-ban"
            className=" p-button-rounded p-button-success p-button-outlined mr-3"
            data-pr-tooltip="Bloquear candidato"
            data-pr-position="right"
            aria-label="Search"
            style={
              selectedCandidates.length > 0
                ? { color: "#D32F2F" }
                : { color: "#D5D5D5" }
            }
            onClick={() => openModalBlokingMasive(selectedCandidates)}
            tooltip={"Bloquear seleccionados"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
            disabled={selectedCandidates.length > 0 ? false : true}
          />

          {/* CAMBIO DE ESTADO BOTON DESCARGA */}

          <Button
            icon={`${
              actionMasiveDisable === true
                ? "pi pi-spin pi-spinner"
                : "pi pi-file-excel"
            }`}
            className=" p-button-rounded p-button-help p-button-outlined mr-3"
            aria-label="Search"
            style={
              actionMasiveDisable === true
                ? { color: "#DEDEDE" }
                : { color: "#689F38" }
            }
            disabled={actionMasiveDisable === true ? true : false}
            onClick={() => {
              setActionMasiveDisable(true);
              exportExcelFromAzure();
            }}
            tooltip={"Descarga nóminas"}
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
          />

          <Button
            icon="pi pi-upload"
            className="p-button-rounded p-button-success p-button-outlined mr-3"
            aria-label="Search"
            style={
              actionMasiveDisable === true
                ? { color: "#DEDEDE" }
                : { color: "#689F38" }
            }
            disabled={actionMasiveDisable === true ? true : false}
            onClick={() => toggleModalUpload()}
            tooltip="Cargar nómina"
            tooltipOptions={{ className: "bluegray-tooltip", position: "left" }}
          />

          {selectedCandidates.filter(
            (candidate: any) =>
              candidate.busquedaId != idGrupo && !candidate.isDiscarded
          ).length == selectedCandidates.length &&
          selectedCandidates.length > 0 ? (
            <Button
              id="btnAddCtx2"
              //className={classEnviarAValidar}
              style={{ color: "white", background: "#FFB400", border: "none" }}
              label="Enviar a validar"
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={() => changeStatusValidated(selectedCandidates, true)}
            />
          ) : (
            <Button
              id="btnAddCtx2"
              //className={classEnviarAValidar}
              style={{ color: "white", background: "#D5D5D5", border: "none" }}
              label="Enviar a validar"
              icon="pi pi-arrow-right"
              iconPos="right"
              disabled={true}
            />
          )}
        </div>
      </>
    );
  };

  const leftToolbarTemplateDataTableTwo = () => {
    return (
      <>
        <span className="material-symbols-outlined md-24 mt-1 iconBlue">
          filter_alt
        </span>
        <p className="margin0 padding7y14 filter_table_text">
          Fuente Reclutamiento:
        </p>
        <Dropdown
          value={recruitmentSourceFilterNoAdmit}
          options={recruitmentSources}
          onChange={(e) => selectionFilterFuenteNoAdmit(e)}
          optionLabel="name"
          placeholder="Seleccione"
        />

        <p className="margin0 padding7y14 filter_table_text">ID:</p>
        <Dropdown
          value={filterIdNoAdmit}
          options={searchIdsFilterNoAdmit}
          onChange={(e) => selectionFilterIdNoId(e)}
          optionLabel="label"
          placeholder="Seleccione"
        />

        <p className="margin0 padding7y14 filter_table_text">Fecha:</p>
        <MultiSelect
          id="muktiselectCtx4"
          className="w-10rem"
          value={fechaCreacionNoAdmit}
          options={resultFechasCreacionNoAdmit}
          onChange={(e) => {
            filterbusiness_fechaSelectedNoAdmit(e);
          }}
          optionLabel="name"
          placeholder="Seleccione"
          selectedItemsLabel="{0} áreas seleccionadas"
          maxSelectedLabels={1}
        />

        <p className="margin0 padding7y14 filter_table_text">Rut:</p>
        <InputText
          value={filterRutNoAdmit}
          onChange={(e: any) => selectionFilterRutNoAdmit(e)}
          placeholder="11.234.567-8"
          maxLength={12}
          keyfilter={/^[^#<>*!]+$/}
        />
      </>
    );
  };

  const progress = (candidate: any) => {
    let progreso = candidate.busPerPorcCompletitud;
    let circleClass = "";
    if (progreso === "circleRed") {
      circleClass = "circleRed";
    } else if (progreso === "circleYellow") {
      circleClass = "circleYellow";
    } else if (progreso === "circleGreen") {
      circleClass = "circleGreen";
    }
    return (
      <>
        <div
          className="w-full"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={circleClass}></div>
        </div>
      </>
    );
  };

  const contactattempts = (data: any) => {
    return (
      <>
        <div className="">
          <InputNumber
            value={data.busPerReintentos}
            onValueChange={(e) => updateTries(data.busPerId, e)}
            mode="decimal"
            showButtons
            buttonLayout="horizontal"
            decrementButtonClassName="p-button-secondary"
            incrementButtonClassName="p-button-secondary"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            inputStyle={{ width: "40px" }}
            max={100}
            min={0}
          />
        </div>
      </>
    );
  };

  /**
   * TODO: MODIFICANDO NUEVO MODAL PARA EDITAR DATOS DEL CANDIDATO
   */

  const actions = (rowData: any) => {
    return (
      <>
        <div className="flex flex-row">
          <Tooltip target=".des"></Tooltip>
          {instance.getActiveAccount()?.localAccountId == rowData.userId &&
          rowData.isOwner ? (
            <>
              <i
                className="des material-symbols-outlined md-24 iconBlue cursor-pointer mx-2"
                data-pr-tooltip="Editar datos candidato"
                data-pr-position="right"
                onClick={() => {
                  handleClickVerificarRut(rowData);
                }}
              >
                edit
              </i>

              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Descartar"
                data-pr-position="right"
                onClick={() => openModalDiscard(rowData)}
              >
                close
              </i>

              <i
                className="des material-symbols-outlined md-24 iconBlue mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Cambiar candidato de id"
                data-pr-position="right"
                onClick={() => openModalChangeId(rowData)}
              >
                drive_file_move
              </i>

              <i
                className="des material-symbols-outlined md-24 iconCRed mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Bloquear candidato"
                data-pr-position="right"
                onClick={() => openModalBlocking(rowData)}
              >
                block
              </i>

              <i
                className="des material-symbols-outlined md-24 iconCGreen mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
                onClick={() => openModalChangeGroup(rowData)}
              >
                groups
              </i>
            </>
          ) : (
            <>
              <i
                className="material-symbols-outlined md-24 iconGray mx-2"
                data-pr-tooltip="Editar datos candidato"
                data-pr-position="right"
              >
                edit_square
              </i>

              <i
                className=" material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip="Descartar"
                data-pr-position="right"
              >
                close
              </i>

              <i
                className=" material-symbols-outlined md-24 iconBlue mx-2 mt-1 iconGray"
                data-pr-tooltip="Cambiar candidato de id"
                data-pr-position="right"
              >
                drive_file_move
              </i>

              <i
                className=" material-symbols-outlined md-24 iconCRed mx-2 mt-1 iconGray"
                data-pr-tooltip="Bloquear candidato"
                data-pr-position="right"
              >
                block
              </i>

              <i
                className=" material-symbols-outlined md-24 iconGray mx-2 mt-1 "
                data-pr-tooltip="Cambiar candidato de grupo"
                data-pr-position="right"
              >
                groups
              </i>
            </>
          )}

          {rowData.warningData[0] ? (
            instance.getActiveAccount()?.localAccountId ==
            rowData.warningData[0].userId ? (
              <i
                className="des material-symbols-outlined md-18 iconCCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Deshacer Descarte"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const actionsBlock = (rowData: any) => {
    return (
      <>
        {" "}
        {rowData.warningData[0] ? (
          instance.getActiveAccount()?.localAccountId ==
          rowData.warningData[0].userId ? (
            <div className="flex flex-row">
              <Tooltip target=".bloquear"></Tooltip>
              <i
                className="bloquear material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
                data-pr-tooltip="Desbloquear"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() => unBlocking(rowData)}
              >
                manage_history
              </i>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const alertasDesing = (rowData: any) => {
    return (
      <>
        {rowData.warningData[0] ? (
          <div>
            {rowData.warningData[0].excTipoId == "ET-06" ||
            rowData.warningData[0].excTipoId == "ET-01" ? (
              <>
                <p
                  style={{
                    margin: "0",
                    paddingLeft: "10px",
                    color: "#D32F2F",
                  }}
                >
                  {rowData.warningData[0].excTipoDescripcion}
                </p>
                <p style={{ margin: "0", paddingLeft: "10px" }}>No Contactar</p>
              </>
            ) : (
              <>
                <p
                  style={{
                    margin: "0",
                    paddingLeft: "10px",
                    color: "#D32F2F",
                  }}
                >
                  Candidato inhabilitado
                </p>
                <p style={{ margin: "0", paddingLeft: "10px" }}>No Contactar</p>
              </>
            )}
          </div>
        ) : (
          ""
        )}
        {/*rowData.status === "inhabilitado" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            style={{ margin: "0", paddingLeft: "10px", color: "#D32F2F" }}
            <p style={{ margin: "0", paddingLeft: "10px" }}>No Contactar</p>
          </div>
        ) : rowData.busPerStatus === "CONTRATADO" ? (
          <div style={{ borderLeft: "5px solid #D32F2F" }}>
            <p style={{ margin: "0", paddingLeft: "10px", color: "#2A63D5" }}>
              Candidato con contrato vigente
            </p>
            <p style={{ margin: "0", paddingLeft: "10px" }}>
              No disponible, no Contactar
            </p>
          </div>
        ) : (
          ""
        )*/}
      </>
    );
  };

  const convertToDate = (dateString: any) => {
    let d = dateString.split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  //llamda APIS

  const getbusquedaGroup = () => {
    getToken();
    api
      .get(`busqueda-group/`, {
        params: {
          cargoGroupId: idGrupo,
          userId: instance.getActiveAccount()?.localAccountId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroup(response.data.data[0][0]);
      })
      .catch((error) => {});
  };

  const getCandidates = () => {
    api
      .get(`busqueda-persona-allowed/`, {
        params: {
          limit: 1000,
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: idGrupo,
          busPerStatus: status_ingresado,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCandidates(response.data.data);
      })
      .catch((error) => {});
  };

  const getCandidatesNoAdmit = () => {
    getToken();
    api
      .get(`busqueda-persona-blocked/`, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: idGrupo,
          activeBusquedas: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCandidatesNoAdmit(response.data.data);
      })
      .catch((error) => {});
  };

  const exportExcelFromAzure = () => {
    const filterData = {
      filtros: {
        fuentes:
          recruitmentSourceFilter != ""
            ? recruitmentSourceFilter.map((r: any) => r.code)
            : [],
        procesos: filterId || [],
        rut: filterRut,
        reclutadores: filterUsuario !== "" ? filterUsuario : [],
        estados: [status_ingresado] || [],
        progresos: progreso != null ? progreso.map((p: any) => p.code) : [],
        fechas:
          fechaCreacion != null ? fechaCreacion.map((f: any) => f.code) : [],
      },
    };

    getToken();
    api
      .post(`busqueda/create-excel-group/`, filterData, {
        params: {
          usuarioId: instance.getActiveAccount()?.localAccountId,
          cargoGroupId: idGrupo,
          busPerStatus: status_ingresado,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "nomina.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setActionMasiveDisable(false);
      })
      .catch((error) => {
        setVisibleModalErrorUpload(true);
        setActionMasiveDisable(false);
      });
  };

  const recruitmentSourcesModal = [
    { name: "CompuTrabajo", code: "CompuTrabajo" },
    { name: "Hiringroom", code: "hiringroom" },
    { name: "Trabajando", code: "Trabajando" },
    { name: "Laborum", code: "Laborum" },
    { name: "Nomina", code: "nomina" },
  ];

  const onRecuitmentSourcesChange = (e: any) => {
    setRecruitmentSource(e.value);
  };

  const onRecuitmentSourcesChangeModal = (e: any) => {
    setRecruitmentSourceModal(e.value);
  };

  const onRecuitmentIdChangeModal = (e: any) => {
    setRecruitmentIdModal(e.value);
  };

  const updateTries = (busPerId: string, event: any) => {
    getToken();
    const arr = [...candidates];
    const index = arr.findIndex((x) => x.busPerId === busPerId);
    arr[index].busPerReintentos = event.target.value;
    const reintentosData = {
      busPerId: busPerId,
      busPerReintentos: event.target.value,
    };
    api
      .put(`/busqueda-persona/reintento/`, reintentosData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        setCandidates(arr);
      })
      .catch((error) => {});
  };

  function eliminarObjetosDuplicados(arr: any, prop: any) {
    var nuevoArray = [];
    var lookup: any = {};
    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }
    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }
    return nuevoArray;
  }

  const headerModal = () => {
    return (
      <>
        <div className="flex p-fluid formgrid grid mb-3">
          <i
            className="pi pi-upload border-circle w-3rem h-3rem m-2 text-white font-bold flex align-items-center justify-content-center"
            style={{ color: "#FFFFFF", backgroundColor: "#398149" }}
          />
          <label
            className="errorModalTittle ml-4 alignIcon"
            style={{ color: "#398149" }}
          >
            Carga Planilla
          </label>
        </div>
      </>
    );
  };

  const paintId = (rowData: any) => {
    return <>{rowData.busquedaId == idGrupo ? "" : rowData.busquedaId}</>;
  };

  const painWaring = (rowData: any) => {
    return (
      <>
        {rowData.warningData && rowData.warningData.length > 0 ? (
          <span
            className="material-symbols-outlined md-24 iconCYellow mx-2 mt-1 cursor-pointer"
            onClick={() => openWarningInfo(rowData)}
          >
            warning
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const openWarningInfo = (rowData: any) => {
    setVisibleWarningInfo(true);
    setWarnings(rowData.warningData);
  };

  //UseEffect

  useEffect(() => {
    getToken();
  });

  useEffect(() => {
    if (token) {
      getbusquedaGroup();
      getCandidates();
      getCandidatesNoAdmit();
      initFilters();
    }
  }, [token]);

  useEffect(() => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId,
        };
        newArr = [...newArr, newObj];
      });
      const newObj2 = {
        value: "",
        label: "Todos",
      };
      setsearchIdsFilter([newObj2, ...newArr]);
      setsearchIdsFilterNoAdmit([newObj2, ...newArr]);
      const newObj = {
        value: "",
        label: "Sin ID",
      };
      newArr = [newObj, ...newArr];
      setsearchIds(newArr);
    }
  }, [group]);

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate: any) => {
        let isDiscarded: boolean = false;
        if (candidate.warningData) {
          const newArr = candidate.warningData.filter((warning: any) => {
            return warning.busquedaId == candidate.busquedaId;
          });
          if (newArr.length > 0) {
            isDiscarded = true;
          }
        }
        candidate.isDiscarded = isDiscarded;
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
      });
      setCandidatesTabla(candidates);
      let progresos = candidates.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresos(unicosColores);
      let fechasCreacion = candidates.map((candidate: any) => {
        return [candidate.cdcCreateDt2, candidate.cdcCreateDt2];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(0, 2);
        let mesFechaName = x.name.substring(3, 5);
        let anioFechaName = x.name.substring(6, 10);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(0, 2);
        let mesFechaCode = x.code.substring(3, 5);
        let anioFechaCode = x.code.substring(6, 10);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacion(unicasFechasCreacion);

      let optRecluters = candidates.map((candidate: any) => {
        return {
          name: candidate.usuarioNombre,
          value: candidate.usuarioNombre,
        };
      });
      let unicoRecluter = eliminarObjetosDuplicados(optRecluters, "name");
      const newObjR = {
        value: "",
        name: "Todos",
      };
      setOptionReclutador([newObjR, ...unicoRecluter]);
    }
  }, [candidates]);

  function SortArrayByNames(x: any, y: any) {
    return x.name.localeCompare(y.name);
  }

  useEffect(() => {
    if (candidatesNoAdmit) {
      candidatesNoAdmit.forEach((candidate: any) => {
        candidate.fullname =
          candidate.personaNombre1 + " " + candidate.personaApe1;
        candidate.personaDniFormat = prettifyRut(candidate.personaDni);
        candidate.rutWithoutFormat = candidate.personaDni
          .replaceAll(".", "")
          .replace("-", "");
        if (candidate.celular)
          candidate.celularFormat = "+" + candidate.celular;
        if (candidate.wsp) candidate.wspFormat = "+" + candidate.wsp;
        if (candidate.cdcCreateDt) {
          let diaFecha = candidate.cdcCreateDt.substring(8, 10);
          let mesFecha = candidate.cdcCreateDt.substring(5, 7);
          let anioFecha = candidate.cdcCreateDt.substring(0, 4);
          let fechaTablaOrdenada = diaFecha + "/" + mesFecha + "/" + anioFecha;
          candidate.cdcCreateDt2 = fechaTablaOrdenada;
        }
        if (candidate.busPerPorcCompletitud <= 44) {
          candidate.busPerPorcCompletitud = "circleRed";
        } else if (
          candidate.busPerPorcCompletitud >= 45 &&
          candidate.busPerPorcCompletitud <= 99
        ) {
          candidate.busPerPorcCompletitud = "circleYellow";
        } else if (candidate.busPerPorcCompletitud >= 100) {
          candidate.busPerPorcCompletitud = "circleGreen";
        }
        let status = "";
        if (
          candidate.busPerStatus === "DESCARTADO" ||
          candidate.busPerStatus === "DESVINCULADO" ||
          candidate.busPerStatus === "RECHAZADO"
        ) {
          status = "inhabilitado";
        } else {
          status = "habilitado";
        }
        candidate.status = status;
      });
      let progresos = candidatesNoAdmit.map((candidate: any) => {
        return [
          String(candidate.busPerPorcCompletitud),
          String(candidate.busPerPorcCompletitud),
        ];
      });
      let mappingProgresos = progresos.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicosProgresos = eliminarObjetosDuplicados(mappingProgresos, "name");
      unicosProgresos.sort(SortArrayByNames);
      unicosProgresos.map((x: any) => {
        if (parseInt(x.name) <= 44) {
          x.name = "circleRed";
          x.code = "circleRed";
        } else if (parseInt(x.name) >= 45 && progreso <= 99) {
          x.name = "circleYellow";
          x.code = "circleYellow";
        } else if (parseInt(x.name) >= 100) {
          x.name = "circleGreen";
          x.code = "circleGreen";
        }
      });
      let set = new Set(unicosProgresos.map((e) => JSON.stringify(e)));
      let unicosColores = Array.from(set).map((e) => JSON.parse(e));
      setresultProgresosNoAdmit(unicosColores);
      let fechasCreacion = candidatesNoAdmit.map((candidate: any) => {
        return [candidate.cdcCreateDt2, candidate.cdcCreateDt2];
      });
      let mappingFechasCreacion = fechasCreacion.map((x: any) => ({
        name: x[0],
        code: x[0],
      }));
      let unicasFechasCreacion = eliminarObjetosDuplicados(
        mappingFechasCreacion,
        "name"
      );
      unicasFechasCreacion.sort(SortArrayByNames);
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(0, 2);
        let mesFechaName = x.name.substring(3, 5);
        let anioFechaName = x.name.substring(6, 10);
        x.name = anioFechaName + "-" + mesFechaName + "-" + diaFechaName;
        let diaFechaCode = x.code.substring(0, 2);
        let mesFechaCode = x.code.substring(3, 5);
        let anioFechaCode = x.code.substring(6, 10);
        x.code = anioFechaCode + "-" + mesFechaCode + "-" + diaFechaCode;
      });

      unicasFechasCreacion.sort((a: any, b: any) => {
        if (new Date(a.name).getTime() === new Date(b.name).getTime()) {
          return 0;
        }
        if (new Date(a.name).getTime() > new Date(b.name).getTime()) {
          return -1;
        }
        return 1;
      });
      unicasFechasCreacion.map((x: any) => {
        let diaFechaName = x.name.substring(8, 10);
        let mesFechaName = x.name.substring(5, 7);
        let anioFechaName = x.name.substring(0, 4);
        x.name = diaFechaName + "/" + mesFechaName + "/" + anioFechaName;
        let diaFechaCode = x.code.substring(8, 10);
        let mesFechaCode = x.code.substring(5, 7);
        let anioFechaCode = x.code.substring(0, 4);
        x.code = diaFechaCode + "/" + mesFechaCode + "/" + anioFechaCode;
      });
      setresultFechasCreacionNoAdmit(unicasFechasCreacion);
    }
  }, [candidatesNoAdmit]);

  // Main return

  return (
    <>
      {toasts}
      <div className="card">
        <Toolbar right={rightContents} left={goToActiveProcess}></Toolbar>

        {group ? (
          <h2
            className="flex justify-content-center"
            style={{
              height: "39px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "25px",
              alignItems: "center",
              color: "#333333",
            }}
          >
            {group.cargoGroupName}
          </h2>
        ) : (
          ""
        )}

        <span className="flex justify-content-center ">
          En esta opción puedes ingresar los datos básicos de un candidato para
          luego contactarlo.{" "}
        </span>
        <span className="flex justify-content-center mb-6 mt-2">
          Puedes reclutar para procesos específicos y/o cambiarlos según sea el
          caso.
        </span>

        {group.busquedas
          ? group.busquedas.map((busqueda: any, i: number) => {
              return (
                <div
                  key={i}
                  className="flex flex-wrap justify-content-between surface-200 pt-3 border-round my-3"
                >
                  <div className="flex flex-row ml-8">
                    {busqueda.busquedaUrgencia === 2 ? (
                      <span className="circuloRed alignIcon"></span>
                    ) : busqueda.busquedaUrgencia === 1 ? (
                      <span className="circuloYellow alignIcon"></span>
                    ) : busqueda.busquedaUrgencia === 0 ? (
                      <span className="circuloGreen alignIcon"></span>
                    ) : (
                      ""
                    )}
                    <div className="flex flex-row mr-5">
                      <span className="ml-2">
                        {busqueda.busquedaId} - {busqueda.cargoNombre} -{" "}
                        {busqueda.clienteNombre}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-row mr-8">
                    <p className="vacantantesMsg">
                      {" "}
                      Q. Vacantes abiertas : {busqueda.busquedaCantidad}
                    </p>
                    <p className="mx-3 vacantantesMsg">
                      {" "}
                      Q. Vacantes cubiertas: 8
                    </p>
                    <p className="vacantantesMsg">
                      {" "}
                      Fecha entrega : {convertToDate(busqueda.busquedaFin)}
                    </p>
                  </div>
                </div>
              );
            })
          : ""}

        <NuevoCandidato
          cargarNomina={toggleModalUpload}
          getCandidates={getCandidates}
        />

        <p
          className="flex justify-content-start"
          style={{
            height: "39px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            alignItems: "center",
            color: "#2A63D5",
          }}
        >
          Lista de candidatos registrados
        </p>

        <Toolbar
          left={leftToolbarTemplateTwo}
          right={rightToolbarTemplateTwo}
          className="mr-3 overflow-x-auto"
        ></Toolbar>

        <div className="accordionCandidates my-4">
          <Accordion activeIndex={0}>
            <AccordionTab header="Postulantes">
              {candidatesTabla && (
                <DataTable
                  id="tblAddCtx2"
                  value={candidates}
                  selectionMode="checkbox"
                  responsiveLayout="scroll"
                  emptyMessage="No hay registros."
                  paginator={candidatesTabla.length <= 15 ? false : true}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                  currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                  rows={15}
                  rowsPerPageOptions={[15, 30, 50]}
                  selection={selectedCandidates}
                  onSelectionChange={(e) => setSelectedCandidates(e.value)}
                  dataKey="busPerId"
                  // scrollable
                  // scrollHeight="600px"
                  filters={filterStatus}
                >
                  <Column
                    selectionMode="multiple"
                    style={{ width: "50px" }}
                  ></Column>
                  <Column
                    field="fullname"
                    header="Nombre"
                    sortable
                    style={{ width: "calc(100% / 11)" }}
                    headerStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="wspFormat"
                    header="Teléfono 1"
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                  <Column
                    field="personaDniFormat"
                    header="Rut"
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                  <Column
                    field="busPerPorcCompletitud"
                    header="Progreso"
                    body={progress}
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                  <Column
                    field="busPerReintentos"
                    header="Intentos de contacto"
                    body={contactattempts}
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                    // style={{ width: "2rem" }}
                  ></Column>
                  <Column
                    field=""
                    header="Acciones"
                    // headerStyle={{ minWidth: "15em" }}
                    body={actions}
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                    // style={{ minWidth: "15em" }}
                  ></Column>
                  <Column
                    field="fuenteOrg"
                    header="Fuente Reclutamiento"
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                  <Column
                    field="busquedaId"
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                    header="ID"
                    body={paintId}
                  ></Column>
                  {group.busquedas &&
                    (group.busquedas.filter(
                      (search: any) =>
                        instance.getActiveAccount()?.localAccountId ===
                        search.accountManager
                    ).length > 0 ||
                      ["adm_tw", "tw_admsel", "tw_admsel_prof"].includes(
                        String(sessionStorage.getItem("profile"))
                      )) && (
                      <Column
                        field="usuarioNombre"
                        header="Reclutador"
                        headerStyle={{ textAlign: "center" }}
                        style={{ width: "calc(100% / 11)" }}
                      ></Column>
                    )}

                  <Column
                    field="warning"
                    header="Alerta"
                    body={painWaring}
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                  <Column
                    field="cdcCreateDt2"
                    header="Fecha postulación"
                    // style={{ minWidth: "5rem" }}
                    headerStyle={{ textAlign: "center" }}
                    style={{ width: "calc(100% / 11)" }}
                  ></Column>
                </DataTable>
              )}
            </AccordionTab>
          </Accordion>
        </div>

        <Toolbar
          className="overflow-x-auto"
          left={leftToolbarTemplateDataTableTwo}
          right={""}
        ></Toolbar>

        <div className="accordionCandidates my-4">
          <Accordion activeIndex={0}>
            <AccordionTab header="Postulantes no admitidos">
              {candidatesNoAdmit && (
                <DataTable
                  id="tblAddCtx2"
                  value={candidatesNoAdmit}
                  responsiveLayout="scroll"
                  emptyMessage="No hay registros."
                  paginator={candidatesNoAdmit.length <= 15 ? false : true}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport "
                  currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
                  rows={15}
                  rowsPerPageOptions={[15, 30, 50]}
                  selection={selectedCandidates}
                  onSelectionChange={(e) => setSelectedCandidates(e.value)}
                  dataKey="busPerId"
                  // scrollable
                  // scrollHeight="600px"
                  filters={filterStatusNoAdmit}
                >
                  <Column field="fullname" header="Nombre" sortable></Column>
                  <Column field="wspFormat" header="Teléfono 1"></Column>
                  <Column
                    field="personaDniFormat"
                    header="Rut"
                    style={{ width: "130px" }}
                  ></Column>
                  <Column
                    field="fuenteOrg"
                    header="Fuente Reclutamiento"
                  ></Column>
                  <Column
                    field=""
                    header="Acciones"
                    body={actionsBlock}
                  ></Column>
                  <Column
                    field="status"
                    body={alertasDesing}
                    header="Alerta"
                    style={{ minWidth: "5rem" }}
                    exportable={false}
                  ></Column>
                  {/*<Column
                    field="busquedaId"
                    header="ID"
                    body={paintId}
                  ></Column>*/}
                  <Column
                    field="cdcCreateDt2"
                    header="Fecha postulación"
                    style={{ minWidth: "5rem" }}
                  ></Column>
                  {/*  <Column field="restaurar" header="Restaurar" body={restart} style={{ minWidth: '5rem' }} ></Column> */}
                </DataTable>
              )}
            </AccordionTab>
          </Accordion>
        </div>

        {/*Componentes de apertura momentanea   */}

        <Dialog
          header={headerModal}
          visible={uploadModalVisible}
          onHide={() => setUploadModalVisible(false)}
          style={{ width: "511px" }}
          closable={true}
          draggable={false}
          footer={" "}
        >
          <p>Indique fuente de reclutamiento y cargue el archivo.</p>
          <p>
            Sólo podrá agregar candidatos que tengan la información
            completa(nombre, apellido, rut y teléfono).
          </p>
          <Dropdown
            value={recruitmentSourceModal}
            options={recruitmentSourcesModal}
            onChange={onRecuitmentSourcesChangeModal}
            optionLabel="name"
            placeholder="Fuente de Reclutamiento"
            appendTo="self"
          />

          <Dropdown
            value={recruitmentIdModal}
            options={searchIds}
            onChange={onRecuitmentIdChangeModal}
            optionLabel="label"
            placeholder="ID al que postula "
            appendTo="self"
            className="ml-5"
          />

          <div className="mt-3">
            {recruitmentSourceModal ? (
              <FileUpload
                mode="advanced"
                name="cargaArchivos"
                accept=".xls,.xlsx"
                maxFileSize={1000000}
                customUpload={true}
                chooseLabel="Elegir Archivo"
                uploadLabel="Subir Archivo"
                cancelLabel="Cancelar"
                uploadHandler={uploadFileToAzure}
                style={{ backgroundColor: "#398149" }}
                className="btn"
                disabled={false}
              />
            ) : (
              <FileUpload
                mode="advanced"
                name="cargaArchivos"
                accept=".xls,.xlsx"
                maxFileSize={1000000}
                customUpload={true}
                chooseLabel="Elegir Archivo"
                uploadLabel="Subir Archivo"
                cancelLabel="Cancelar"
                uploadHandler={uploadFileToAzure}
                style={{ backgroundColor: "#398149" }}
                className="btn"
                disabled={true}
              />
            )}
          </div>
        </Dialog>

        <Dialog
          header={headerCargaExitosa}
          visible={uploadModalCargaExitosa}
          style={{ width: "562px", height: "200px" }}
          onHide={() => closeCargaExitosa()}
          footer={footerCargaExitosa}
          closable={false}
        >
          {responseExcel ? (
            <p>
              Se han agregado sólo aquellos candidatos con la información
              completa. Podrás encontrarlos filtrando por fuente de
              reclutamiento.
            </p>
          ) : (
            ""
          )}
        </Dialog>

        <Dialog
          header={headerCargaNoExitosa}
          visible={visibleModalErrorUpload}
          style={{ width: "562px", height: "200px" }}
          onHide={() => closeCargaNoExitosa()}
          footer={footerNoCargaExitosa}
          closable={false}
        >
          <p>
            La fuente de reclutamiento no corresponde. Por favor inténtelo
            nuevamente.
          </p>
        </Dialog>

        <Dialog
          header="Motivo de descarte candidatos"
          visible={visiblePorCandidato}
          onHide={() => setVisiblePorCandidato(false)}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "442px", height: "395px" }}
          footer={renderFooterCandidate()}
          closable={false}
          draggable={false}
        >
          <div className="flex flex-column align-items-center">
            <div className="mt-3 mb-4 mr-4 ml-5">
              <label htmlFor="inputName2Ctx1">
                Estas descartando todos los candidatos seleccionados. Por favor
                ingresa el motivo.
              </label>
            </div>
            <div className="flex flex-column justify-content-center">
              <InputTextarea
                id="inputName2Ctx1"
                rows={9}
                cols={40}
                autoResize
                value={inputModalCandidatos}
                style={{ width: "339px", height: "152px" }}
                //disabled={isDisabled}
                onChange={(e) => setInputModalCandidatos(e.target.value)}
                maxLength={60}
              />
              {inputModalCandidatos.length >= 60 && (
                <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                  Ha alcanzado el máximo de caracteres: 60 máximo.
                </small>
              )}
            </div>
          </div>
        </Dialog>

        <Dialog
          header="Motivo de descarte cliente"
          visible={visiblePorCliente}
          onHide={() => setVisiblePorCliente(false)}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "442px", height: "395px" }}
          footer={renderFooterClient()}
          closable={false}
          draggable={false}
        >
          <div className="flex flex-column align-items-center">
            <div className="mt-3 mb-4 mr-4 ml-5">
              <label htmlFor="inputName2Ctx1">
                Estas descartando todos los candidatos seleccionados. Por favor
                ingresa el motivo.
              </label>
            </div>
            <div className="flex flex-column justify-content-center">
              <InputTextarea
                id="inputName2Ctx1"
                rows={9}
                cols={40}
                autoResize
                value={inputModalClientes}
                style={{ width: "339px", height: "152px" }}
                //disabled={isDisabled}
                onChange={(e) => setInputModalClientes(e.target.value)}
                maxLength={60}
              />
              {inputModalClientes.length >= 60 && (
                <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                  Ha alcanzado el máximo de caracteres: 60 máximo.
                </small>
              )}
            </div>
          </div>
        </Dialog>

        <Dialog
          header="Motivo de descarte Perfil"
          visible={visiblePorPerfil}
          onHide={() => setVisiblePorPerfil(false)}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "442px", height: "395px" }}
          footer={renderFooterProfile()}
          closable={false}
          draggable={false}
        >
          <div className="flex flex-column align-items-center">
            <div className="mt-3 mb-4 mr-4 ml-5">
              <label htmlFor="inputName2Ctx1">
                Estas descartando todos los candidatos seleccionados. Por favor
                ingresa el motivo.
              </label>
            </div>
            <div className="flex flex-column justify-content-center">
              <InputTextarea
                id="inputName2Ctx1"
                rows={9}
                cols={40}
                autoResize
                value={inputModalPerfiles}
                style={{ width: "339px", height: "152px" }}
                //disabled={isDisabled}
                onChange={(e) => setInputModalPerfiles(e.target.value)}
                maxLength={60}
              />
              {inputModalPerfiles.length >= 60 && (
                <small id="msjLengthName2Ctx1" style={{ color: "#f59e0b" }}>
                  Ha alcanzado el máximo de caracteres: 60 máximo.
                </small>
              )}
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={visibleWarningInfo}
          footer={" "}
          style={{ width: "30vw" }}
          closable
          onHide={() => setVisibleWarningInfo(false)}
        >
          {warnings && warnings.length > 0 ? (
            <>
              <div className="flex alignIcon mb-5">
                <span className=" material-symbols-outlined md-36 iconCYellow mx-2 cursor-pointer ">
                  warning
                </span>
                <label
                  className="ml-3 "
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "",
                    fontSize: "23px",
                    lineHeight: "26px",
                    alignItems: "center",
                    fontWeight: "600",
                    color: "#333333",
                  }}
                >
                  Candidato con Restricciones:{" "}
                </label>
              </div>

              {warnings.map((warning: any) => {
                return (
                  <>
                    <p className="alignIcon">
                      <span className="material-symbols-outlined md-24 iconCYellow mx-2 cursor-pointer align-self-center align-items-center">
                        chevron_right
                      </span>
                      {/*
   {warning.excTipoDescripcion} {warning.clienteNombre} 
*/}
                      Descartado en ID {warning.busquedaId} favor no considerar
                    </p>
                  </>
                );
              })}
            </>
          ) : (
            ""
          )}

          <br />
        </Dialog>

        <ModalError
          isModalVisible={visibleModalError}
          onClose={cerrarModalError}
          token={token}
        ></ModalError>

        <FullCandidateModalV2
          token={token}
          isModalVisible={isModalVisible}
          onClose={onBackdropClick}
          onCloseSave={onBackdropClickSave}
          busPerId={candidateModal?.busPerId}
          group={group}
          closeFullModal={closeFullModal}
          isNew={true}
          isEnabled={true}
        />

        <Toast ref={toastDescarte} />

        <ModalChangeId
          isModalVisible={visibleModalChangeId}
          onClose={cerrarModalChangeId}
          onCloseSave={() => saveModalChangeId()}
          token={token}
          candidate={candidateModal}
          group={group}
        ></ModalChangeId>

        <ModalDiscard
          isModalVisible={visibleModalDiscard}
          onClose={cerrarModalDiscard}
          onCloseSave={() => saveModalDiscard()}
          token={token}
          candidate={candidatosADescartar}
          group={group}
        ></ModalDiscard>

        <ModalBlocking
          isModalVisible={visibleModalBlocking}
          onClose={cerrarModalBlocking}
          onCloseSave={() => saveModalBloking()}
          token={token}
          candidate={candidatosABloquear}
          group={group}
        ></ModalBlocking>

        <ModalChangeGroup
          isModalVisible={visibleModalChangeGroup}
          onClose={cerrarModalChangeGroup}
          onCloseSave={() => saveModalChangeGroup()}
          token={token}
          candidate={candidateModal}
          group={group}
        ></ModalChangeGroup>

        <Toast ref={toast}></Toast>
      </div>
      {isOpenModalUpdateCandidate && (
        <ModalUpdateCandidate
          title="Registro Candidato"
          subTitle="Puede cambiar al candidato de proceso en cualquier momento, Todos los datos con (*) son obligatorios y deben ser completados antes de avanzar al candidato a la siguiente etapa del proceso."
          rut={candidateModal?.personaDni}
          setResultBusperId={setResultBusperId}
          setIsOpenModalUpdateCandidate={setIsOpenModalUpdateCandidate}
          getCandidates={getCandidates}
          onChangeBack={() => {
            setIsOpenModalUpdateCandidate(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => {
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          token={token}
        />
      )}

      {isOpenModalCampaign && (
        <ModalEncuesta
          modalStyle="confirm"
          title="Solicite realizar la siguiente encuesta al candidato"
          onChangeBack={() => setIsOpenModalCampaign(false)}
          //onChangeGo={() => {eliminaCampaign(idDelete)}}
          buttonBack="Cancelar"
          busPerId={resultBusperId}
          //buttonGo="Crear encuesta"
        />
      )}
    </>
  );
};

export default RecruitByGroupTableComponent;
