import { IIconTw } from "../../../../interfaces/IIcons";

export default (props: IIconTw) => {
  const { disabled, fill } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M9.82246 9.70103C9.70849 10.0505 9.51855 10.362 9.26022 10.6432C9.0019 10.9167 8.6524 11.137 8.21933 11.2966C7.95341 11.3953 7.6343 11.4561 7.28481 11.4941V13.6823H5.90201V11.4865C5.50693 11.4409 5.14983 11.3573 4.82313 11.2358C4.31408 11.0382 3.91899 10.7267 3.63788 10.3013C3.35676 9.87578 3.22 9.32874 3.22 8.66013V6.17566H4.61799V8.67533C4.61799 9.1008 4.70156 9.4427 4.86872 9.69343C5.03587 9.94416 5.2714 10.1189 5.56011 10.2253C5.66648 10.2633 5.78804 10.2861 5.90961 10.3088V6.17566H7.2924V10.3164C7.47475 10.2785 7.6343 10.2253 7.77106 10.1569C8.07497 10.0049 8.28771 9.7922 8.42447 9.52628C8.56123 9.26036 8.62961 8.94885 8.62961 8.59935C8.62961 8.40941 8.60682 8.21187 8.58402 8.00673C8.56123 7.80159 8.52324 7.58885 8.47765 7.37611C8.43207 7.16337 8.37888 6.95823 8.3105 6.75309C8.24972 6.54795 8.18134 6.35801 8.10536 6.17566H9.38179C9.46536 6.31242 9.54894 6.46438 9.62492 6.64673C9.7009 6.82147 9.76927 7.01902 9.83006 7.23175C9.89084 7.44449 9.93643 7.66483 9.96682 7.89276C10.0048 8.12069 10.02 8.35622 10.02 8.59935C10.02 8.98684 9.95922 9.35153 9.84525 9.70103H9.82246Z"
          // fill="#06A502"
        />
        <path d="M1.69159 15.9435C1.37383 15.9435 1.09579 15.8244 0.857477 15.586C0.619159 15.3477 0.5 15.0697 0.5 14.7519V1.24725C0.5 0.929496 0.619159 0.651459 0.857477 0.413141C1.09579 0.174823 1.37383 0.0556641 1.69159 0.0556641H8.44393L13.2103 4.82202V8.97272H12.0187V5.61641H7.64953V1.24725H1.69159V14.7519H10.8271V15.9435H1.69159ZM14.6005 15.9435L11.7009 13.044L12.535 12.19L14.0047 13.6596V10.065H15.1963V13.6596L16.6659 12.19L17.5 13.044L14.6005 15.9435Z" />
      </g>
    </svg>
  );
};
