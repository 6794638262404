import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/modalmdtw.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import "../../assets/css/validationKam.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import CampaignComponent from "./Campaign/CampaignComponent";
import CampaignState from "../../context/Campaign/CampaignState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

const Campaign: React.FC<IPage> = (props) => {
  <Route path="/Campaign" component={CampaignComponent} />;
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);
  const [token, setToken] = useState<string>(""); 
  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };
  
  useEffect(() => {
    getToken();
  }, [])

  const authRequest = {
    ...loginRequest,
  };
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    getToken()
  }, [])
  
  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);
  
  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  return (
    <>
      {/* <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
        > */}
      {profile ? (
        <MainTopbar
          profile={profile}
          onToggleMenuClick={onToggleMenuClick}
          active={"C"}
        />
      ) : (
        ""
      )}
      {profile == "tw_marketing" && (
        <CampaignState>
          <CampaignComponent token={token}/>
        </CampaignState>
      )}
    </>
  );
};

export default Campaign;
