import { Button } from "primereact/button";
import "../../../../assets/css/assistsModals.min.css";
import CloseIcon from '@mui/icons-material/Close';

interface IModals {
  title: string;
  subTitle?: string;
  message: string;
  onChangeBack: Function;
  onChangeGo: Function;
}

export default (props: IModals) => {
  const { title, subTitle, message, onChangeBack, onChangeGo } = props;
  return (
    <>
      <div className="modal-container">
        <div className="modal-content">
          <span className="modal-close" onClick={() => onChangeBack()}>
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          {subTitle ? (
            <div className="modal-subtitle">
              <span>{subTitle}</span>
            </div>
          ) : (
            ""
          )}
          <div className="modal-message">
            <span>{message}</span>
          </div>
          <div className="button-content-modal">
            <Button className="button-modal-back" onClick={() => onChangeBack()}>
              Volver
            </Button>
            <Button className="button-modal-go" onClick={() => onChangeGo()}>
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
