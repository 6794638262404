import { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/css/modal-generico.min.css";
import IconNoProcesado from "../kit-svg/icon-bajas-confirmadas/IconNoProcesado";
import Tooltip from "../kit-mdtw/tooltip/Tooltip";

interface IKitModalGenerico {
  title: string;
  iconTitle?: "success" | "danger" | "caution" | "option" | undefined;
  subtitle?: string;
  positionSubTitle?: "start" | "center" | "end";
  message?: string;
  messageBottom?: string;
  positionMessage?: "start" | "center" | "end";
  positionMessageBottom?: "start" | "center" | "end";
  buttonBackText?: string;
  buttonGoText?: string;
  buttonGoStyle?: "cancel" | "confirm" | "option" | "delete" | "naranja";
  onChangeBack: Function;
  onChangeGo?: Function;
  onChangeClose?: Function;
  width: string;
  children?: ReactNode;
  psicolaboral?: boolean;
  tipo?: string;
  buttonGoDisable?: boolean;
  positionButton?: "center" | "end" | "start" | undefined;
  buttonDisabledTooltipText?: string; 
}

export default (props: IKitModalGenerico) => {
  const {
    title,
    iconTitle,
    subtitle,
    positionSubTitle,
    message,
    positionMessage,
    messageBottom,
    positionMessageBottom,
    width,
    children,
    onChangeClose,
    onChangeBack,
    buttonGoStyle,
    buttonBackText,
    onChangeGo,
    buttonGoText,
    psicolaboral,
    tipo,
    buttonGoDisable,
    positionButton,
    buttonDisabledTooltipText, 
  } = props;

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickCloseModalClose = () => {
    typeof onChangeClose == "function" && onChangeClose();
  };

  const handleClickGoModal = () => {
    typeof onChangeGo == "function" && onChangeGo();
  };

  return (
    <>
      <div className={`modal-container-generico`}>
        <div className={`modal-content-generico ${width}`}>
          <label className="modal-close">
            <CloseIcon
              onClick={
                onChangeClose
                  ? handleClickCloseModalClose
                  : handleClickCloseModal
              }
            />
          </label>
          {title && (
            <div className="modal-title">
              <div>
                {iconTitle && iconTitle == "success" && (
                  <IconNoProcesado fill="#BE2A2A" />
                )}
                {iconTitle && iconTitle == "danger" && (
                  <IconNoProcesado fill="#BE2A2A" />
                )}
              </div>
              <label>{title}</label>
            </div>
          )}
          {subtitle && tipo == "bloqueo" && (
            <div className="modal-subtitle pb-3" style={{ textAlign: "start" }}>
              <span>{subtitle}</span>
            </div>
          )}
          {subtitle && tipo != "bloqueo" && (
            <div className={`modal-subtitle ${positionSubTitle}`}>
              <span>{subtitle}</span>
            </div>
          )}
          {message && (
            <div className={`modal-message ${positionMessage}`}>
              <span>{message}</span>
            </div>
          )}
          {children}
          {messageBottom && (
            <div className={`modal-message ${positionMessageBottom}`}>
              <span>{messageBottom}</span>
            </div>
          )}
          <footer
            className={`modal-button-content  ${
              positionButton ? positionButton : "center-seleccion"
            }`}
          >
            <>
              {buttonBackText && (
                <button
                  className={`modal-button cancel`}
                  onClick={() => handleClickCloseModal()}
                >
                  {buttonBackText}
                </button>
              )}
              {buttonGoText && (
                <>
                  {typeof buttonGoDisable == "boolean" ? (
                    !buttonGoDisable && buttonDisabledTooltipText ? (
                      <Tooltip text={buttonDisabledTooltipText}>
                        <button
                          className={`modal-button ${
                            buttonGoStyle ?? "confirm"
                          }`}
                          onClick={handleClickGoModal}
                          disabled={!buttonGoDisable}
                        >
                          {buttonGoText}
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        className={`modal-button ${buttonGoStyle ?? "confirm"}`}
                        onClick={handleClickGoModal}
                        disabled={!buttonGoDisable}
                      >
                        {buttonGoText}
                      </button>
                    )
                  ) : (
                    <button
                      className={`modal-button ${buttonGoStyle ?? "confirm"}`}
                      onClick={handleClickGoModal}
                    >
                      {buttonGoText}
                    </button>
                  )}
                </>
              )}
            </>
          </footer>
        </div>
      </div>
    </>
  );
};
