import React, { useState, useEffect } from "react";
import IPage from "../../interfaces/page";
import { callMsGraphTokens } from "../../services/MsGraphTokens";
import api from "../../services/api";
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  useParams,
} from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCandidateState } from "../../context/candidate/store";
import candidateModel from "../../context/candidate/candidateModel";

const CandidateGetId: React.FunctionComponent<
  IPage & RouteComponentProps<any>
> = (props) => {
  const [token, setToken] = useState<any>(null);
  const [state, { setCandidate }] = useCandidateState();
  let history = useHistory();
  const { busquedaId }: any = useParams();
  useEffect(() => {
    callMsGraphTokens()
      .then((response) => {
        setToken(response.idToken);
      })
      .catch((e) => {
        history.push("/");
      });
  }, [token]);

  useEffect(() => {
    if (!token) return;

    let candidate_id = props.match.params.candidate_id;
    if (!candidate_id) return;

    api
      .get(`busqueda-persona/${candidate_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {

        let candidateResponse = response.data.data[0];

        let candidate: candidateModel = {
          busPerId: candidateResponse.busPerId,
          rut: candidateResponse.personaDni,
          personaNombre1: candidateResponse.personaNombre1,
          personaNombre2: candidateResponse.personaNombre2,
          personaApe1: candidateResponse.personaApe1,
          personaApe2: candidateResponse.personaApe2,
          wsp: candidateResponse.wsp?.slice(3),
          email: candidateResponse.email,
          celular: candidateResponse.celular?.slice(3),
          comunaNombre: candidateResponse.comunaNombre,
          comunaCode: candidateResponse.comunaId,
          busPerPaseMovilidad: candidateResponse.busPerPaseMovilidad,
          direccion: candidateResponse.direccion,
          areaNegocioNombre: candidateResponse.areaNegocioNombre,
          cargoNombre: candidateResponse.cargoNombre,
          sexo: candidateResponse.genero,
          pais: candidateResponse.paisId,
          estadoCivil: candidateResponse.estCivilId,
          fechaNacimiento: candidateResponse.personaFecNac,
          discapacidad: 0,
          discapacidadDesc: candidateResponse.descripciondiscapacidad,
          prevId: candidateResponse.prevId,
          saludId: candidateResponse.saludId,
          saludNombre: candidateResponse.saludNombre,
          tallaNombre: candidateResponse.tallaNombre,
          bancoId: candidateResponse.bancoId,
          tipoDeCuentaBco: candidateResponse.tipoDeCuentaBco,
          numeroDeCuentaBco: candidateResponse.numeroDeCuentaBco,
          cargas: candidateResponse.cargas,
          numeroDeHijos: candidateResponse.numerohijos,
          jornada: candidateResponse.jornada,
          turno: candidateResponse.turno,
          tallaIdPant: candidateResponse.tallaIdPant,
          tallaIdPol: candidateResponse.tallaIdPol,
          tallaZapato: candidateResponse.tallaZapato,
          hijoEdad1: candidateResponse.hijoEdad1,
          hijoEdad2: candidateResponse.hijoEdad2,
          hijoEdad3: candidateResponse.hijoEdad3,
          hijoEdad4: candidateResponse.hijoEdad4,
          hijoEdad5: candidateResponse.hijoEdad5,
          hijoEdad6: candidateResponse.hijoEdad6,
          hijoEdad7: candidateResponse.hijoEdad7,
          hijoEdad8: candidateResponse.hijoEdad8,
          hijoEdad9: candidateResponse.hijoEdad9,
          hijoEdad10: candidateResponse.hijoEdad10,
          enfermedadcronica: candidateResponse.enfermedadcronica,
          contactoemergencia: candidateResponse.contactoemergencia,
          telefonoemergencia: candidateResponse.telefonoemergencia?.slice(3),
          busPerPorcCompletitud: candidateResponse.busPerPorcCompletitud,
          edadhijoc: candidateResponse.edadhijoc,
          areaNegocioId: candidateResponse.areaNegocioId,
          clienteId: candidateResponse.clienteId,
        };
        setCandidate(candidate);
        history.push("/candidateMoreInfo/" + busquedaId);
      })
      .catch((error) => {
        history.push("/");
      });
  }, [token]);

  return (
    <>
      <ProgressSpinner
        className="flex justify-content-center"
        style={{ width: "50px", height: "50px" }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </>
  );
};

export default withRouter(CandidateGetId);
