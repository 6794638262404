import { useState, useEffect, useContext } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import AsistenciaContext from "../../../context/Assist/AsistenciaContext";

interface IComentario {
  titleText?: string;
  navText?: string;
  labelButtonBack: string;
  labelButtonGo: string;
  transition: number;
  setTransition: Function;
  history?: any;
  route?: string;
  token: string | undefined; 
  getToken: Function; 
}

export default (props: IComentario) => {
  const {
    titleText,
    navText,
    labelButtonBack,
    labelButtonGo,
    transition,
    setTransition,
    token, 
    getToken,
  } = props;

  const { createComentario, asistenciaInterna } = useContext(AsistenciaContext);
  const searchParams = new URLSearchParams(location.search);
  const serviceId = searchParams.get("serviceId");
  const invitationId = searchParams.get("invitationId");
  const [comentario, setComentario] = useState<string>("");

  const handleClickTransitionBack = () => {
    setTransition(transition - 1);
  };

  const handleClickAsistencia = () => {
    getToken();
    if (token) {
      createComentario({ comentario, serviceId, invitationId, token, asistenciaInterna });
    }
  };

  return (
    <>
      {navText && <span className="nav-text">{navText}</span>}
      {titleText && (
        <span className=" title-assists" style={{ marginBottom: "50px" }}>
          {titleText}
        </span>
      )}
      <span className="text-descripcion" style={{ margin: "20px 0px" }}>
        Puede agregar comentarios en relación a algún candidato y/o la gestión
        del traslado.
      </span>
      <span className="text-descripcion" style={{ margin: "15px 0px" }}>
        Este comentario no es obligatorio y sólo quedará como respaldo para
        consultas futuras.
      </span>

      <div className="container-text">
        <InputTextarea
          className="text-area"
          placeholder="Escriba aquí Su Comentario..."
          rows={12}
          cols={120}
          maxLength={1000}
          onChange={({ target }) => {
            setComentario(target.value);
          }}
        />
      </div>

      <div className="container-button">
        <Button
          className="button-left"
          label={labelButtonBack}
          autoFocus
          onClick={() => handleClickTransitionBack()}
        />
        <Button
          className="button-right"
          label={labelButtonGo}
          autoFocus
          onClick={() => handleClickAsistencia()}
        />
      </div>
    </>
  );
};
