interface IconPageUpdatecontrato {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconPageUpdatecontrato) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "16"}`}
        viewBox="0 0 15 16"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M2.96883 4.70418H10.3714V3.55466H2.96883V4.70418ZM2.96883 7.95177H7.15325C7.37922 7.71865 7.61299 7.50965 7.87792 7.30868C8.13507 7.10772 8.40779 6.93891 8.6961 6.80225H2.96883V7.95177ZM2.96883 11.1994H5.61039C5.64156 10.9984 5.67273 10.7974 5.71948 10.6045C5.76623 10.4116 5.81299 10.2267 5.87532 10.0498H2.96883V11.1994ZM1.11429 14.254C0.81039 14.254 0.545455 14.1415 0.327273 13.9164C0.109091 13.6913 0 13.426 0 13.1045V1.64952C0.00779221 1.32797 0.116883 1.0627 0.335065 0.837621C0.553247 0.61254 0.81039 0.5 1.12208 0.5H12.226C12.5299 0.5 12.7948 0.61254 13.013 0.837621C13.2312 1.0627 13.3403 1.32797 13.3403 1.64952V6.70579C13.1688 6.6254 12.9896 6.56109 12.8026 6.49678C12.6156 6.43248 12.4286 6.38424 12.226 6.34405V1.64952H1.12208V13.1045H5.67273C5.71169 13.3055 5.75844 13.5064 5.82078 13.6994C5.88312 13.8923 5.95325 14.0772 6.02338 14.254H1.11429Z" 
       fill={fill}/> 
       <path d="M7.68312 15.5V14.037L11.9922 9.59164L13.4104 11.0547L9.1013 15.5H7.68312ZM14.2675 10.1704L12.8493 8.7074L13.426 8.11254C13.5351 8 13.6753 7.95177 13.8468 7.95177C14.0182 7.95177 14.1584 8.00804 14.2675 8.11254L14.8442 8.7074C14.9532 8.81994 15 8.96463 15 9.14148C15 9.31833 14.9455 9.46302 14.8442 9.57556L14.2675 10.1704Z" 
      
       fill={fill}/> 
      </svg>



    );
  };


<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">


</svg>
