import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { useRut2 } from "../../../hooks/useRut";
import api from "../../../services/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import formatRUT from "../../../functions/formatRUT";
import validateRUT from "../../../functions/validateRUT";
import firstUpperCasse from "../../../functions/firstUpperCasse";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { GroupOutlined } from "@mui/icons-material";

interface CandidateModalProps {
  token: string;
  onClose: () => void;
  onCloseSave: () => void;
  isModalVisible: boolean;
  busPerId: string;
  closeFullModal: (flag: any) => void;
  isNew: boolean;
  group: any;
  isEnabled: boolean;
  isOpenModalCountExpiredToken?: boolean;
  setIsOpenModalCountExpiredToken?: Function;
}

const CandidateModalV2: React.FC<CandidateModalProps> = ({
  token,
  isModalVisible,
  onClose,
  onCloseSave,
  busPerId,
  closeFullModal,
  isNew,
  group,
  isEnabled,
  isOpenModalCountExpiredToken,
  setIsOpenModalCountExpiredToken,
}) => {
  const [searchField, setSearchField] = useState<any>({
    name: "Rut",
    code: "Rut",
  });
  const [radioValue, setRadioValue] = useState<any>("Nose");
  const [isDisabled, setIsDisabled] = useState(true);
  const [telefono, setTelefono] = useState<any>("");
  const [isValidTelf2, setIsValidTelf2] = useState<boolean>(true);
  const [isValidTelf, setIsValidTelf] = useState<boolean>(true);
  const [telefono1, setTelefono1] = useState<any>("");
  const [telefono2, setTelefono2] = useState<any>("");
  const [empresa, setEmpresa] = useState<any>("");
  const [displayMessage, setDisplayMessage] = useState<any>(null);
  const [displayMessageAlert, setDisplayMessageAlert] = useState<any>("");
  const [nombre, setNombre] = useState<any>("");
  const [nombre2, setNombre2] = useState<any>("");
  const [apellido, setApellido] = useState<any>("");
  const [apellido2, setApellido2] = useState<any>("");
  const [rut, setRut] = useState<any>("");
  const [isValidRUT, setIsValidRUT] = useState<any>(false);
  const { rut2, updateRut2, isValid2 } = useRut2();
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<any>(false);
  const [comunas, setComunas] = useState<any>([]);
  const [comunaCode, setComunaCode] = useState<any>("");
  const [comunaOMIL, setComunaOMIL] = useState<any>("");
  const [comunasOMIL, setComunasOMIL] = useState<any>([]);
  const [comunaCodeOMIL, setComunaCodeOMIL] = useState<any>("");
  const [areaNegocio, setAreaNegocio] = useState<any>("");
  const [areaNegocios, setAreasNegocios] = useState<any>([]);
  const [resultAreasNegocios, setResultAreasNegocios] = useState<any>(null);
  const [busquedasCargos, setBusquedasCargos] = useState<any>(null);
  const [cargo, setCargo] = useState<any>();
  const [cargosOfAreas, setCargosOfAreas] = useState<any>(null);
  const [fuenteReclutamiento, setFuenteReclutamiento] = useState<any>(null);
  const [cargoDisabled, setIsCargoDisabled] = useState(true);
  const { busquedaId }: any = useParams();
  const [busquedaPerIdSave, setBusquedaPerIdSave] = useState<any>(null);
  const [loadingAddCandidate, setLoadingAddCandidate] = useState(false);
  const [candidateError, setCandidateError] = useState<boolean>(false);
  const [clientesList, setClientesList] = useState<any>("");
  const [alertModalTitle, setAlertModalTitle] = useState<any>("");
  const [classEmptyComboBoxBusiness, setClassEmptyComboBoxBusiness] =
    useState<any>("");
  const [classEmptyComboBoxArea, setClassEmptyComboBoxArea] = useState<any>("");
  const [classEmptyComboBoxPosition, setClassEmptyComboBoxPosition] =
    useState<any>("");
  const [classEmptyInputRUT, setClassEmptyInputRUT] = useState<any>("");
  const [classEmptyInputTelephone, setClassEmptyInputTelephone] =
    useState<any>("");
  const [classEmptyInputTelephone2, setClassEmptyInputTelephone2] =
    useState<any>("");
  const [classEmptyInputName, setClassEmptyInputName] = useState<any>("");
  const [classEmptyInputLastName, setClassEmptyInputLastName] =
    useState<any>("");
  const [classEmptyComboBoxCommune, setClassEmptyComboBoxCommune] =
    useState<any>("");
  const [
    classEmptyComboBoxRecruitmentSource,
    setClassEmptyComboBoxRecruitmentSource,
  ] = useState<any>("");
  const [stateResponseModal, setStateResponseModal] = useState<any>(null);
  const [isDisabledOMIL, setIsDisabledOMIL] = useState<any>(true);
  const [classBorderModal, setClassBorderModal] = useState<any>("");
  const [flagModal, setFlagModal] = useState<any>("");
  const [errorModal, setErrorModal] = useState<any>("");
  const [busqueda, setBusqueda] = useState<any>(null);
  const [fechaNacimiento, setFechaNacimiento] = useState<Date | Date[] | any>(
    undefined
  );
  const [edadCalculada, setEdadCalculada] = useState<any>(0);
  const [email, setEmail] = useState<any>("");
  const [sexo, setSexo] = useState<any>("");
  const [pais, setPais] = useState<any>("");
  const [ecivil, setECivil] = useState<any>("");
  const [desDiscapacidad, setDesDiscapacidad] = useState<any>(null);
  const [discapacidad, setDiscapacidad] = useState<any>("");
  const [wsp, setWsp] = useState<any>(null);
  const [celular, setCelular] = useState<any>(null);
  const [direccion, setDireccion] = useState<any>("");
  const [comuna, setComuna] = useState<any>("");
  const [afp, setAfp] = useState<any>("");
  const [banco, setBanco] = useState<any>("");
  const [nHijos, setNHijos] = useState<any>(0);
  const [classEmptyComboBoxMovilityPass, setClassEmptyComboBoxMovilityPass] =
    useState<any>("");
  const [paseDeMovilidad, setPaseDeMovilidad] = useState<any>("");
  const [turno, setTurno] = useState<any>(null);
  const [jornada, setJornada] = useState<any>(null);
  let history = useHistory();
  const [paises, setPaises] = useState<any>([]);
  const [estadosCiviles, setEstadosCiviles] = useState<any>([]);
  const [afps, setAfps] = useState<any>([]);
  const [previsiones, setPrevisiones] = useState<any>([]);
  const [saludPrevision, setSaludPrevision] = useState<any>("");
  const [edadHijos, setEdadHijos] = useState<any>("");
  const [nCargas, setNCargas] = useState<any>(0);
  const [bancos, setBancos] = useState<any>([]);
  const [tipoCuenta, setTipoCuenta] = useState<any>("");
  const [nCuenta, setNCuenta] = useState<any>(null);
  const [tallaPantalon, setTallaPantalon] = useState<any>(null);
  const [tallaPolera, setTallaPolera] = useState<any>(null);
  const [tallaZapato, setTallaZapato] = useState<any>(0);
  const [tallas, setTallas] = useState<any>([]);
  const [comentarios, setComentarios] = useState<any>("");
  const [isDisabledRut, setIsDisabledRut] = useState(true);
  const [candidate, setCandidate] = useState<any>(null);
  const [camposTabEspeciales, setCamposTabEspeciales] = useState<any[]>([]);
  const [valoresCamposEspeciales, setValoresCamposEspeciales] = useState<any[]>(
    []
  );
  const [discapacidadDisabled, setDiscapacidadDisabled] =
    useState<boolean>(true);
  const [filterId, setFilterId] = useState<any>("");
  const [positions, setPositions] = useState<any[]>([]);
  const [tCuentasStates, setTCuentasStates] = useState<any[]>([]);
  const [clientName, setClientName] = useState<any>("");
  const [visibleWorkDetail, setVisibleWorkDetail] = useState(false);

  //constantes y funciones

  const fuentesReclutamiento = [
    { name: "BNE", code: "BNE" },
    { name: "Chile Trabajos", code: "Chile Trabajos" },
    { name: "CompuTrabajo", code: "CompuTrabajo" },
    { name: "Estados de WhatsApp", code: "Estados de WhatsApp" },
    { name: "Facebook pagado Team-Work", code: "Facebook pagado Team-Work" },
    { name: "Facebook personal", code: "Facebook personal" },
    { name: "Hiringroom", code: "Hiringroom" },
    { name: "Instagram Pagado Team-Work", code: "Instagram Pagado Team-Work" },
    { name: "Instagram Personal", code: "Instagram Personal" },
    { name: "Laborum", code: "Laborum" },
    { name: "OMIL", code: "OMIL" },
    { name: "Referido Candidato", code: "Referido Candidato" },
    { name: "Referido Colaborador", code: "Referido Colaborador" },
    { name: "Yapo", code: "Yapo" },
    { name: "WhatsApp", code: "WhatsApp" },
    { name: "Otro", code: "Otro" },
  ];

  const sexoStates = [
    { name: "Femenino", code: "F" },
    { name: "Masculino", code: "M" },
  ];

  const discapacidadValues = [
    { name: "Si", code: "1" },
    { name: "No", code: "0" },
  ];

  const disabilityDisabled = (e: any) => {
    setDiscapacidad(e.target.value);
    setDesDiscapacidad("");
    if (e.target.value === "0") {
      setDiscapacidadDisabled(true);
    }
    if (e.target.value === "1") {
      setDiscapacidadDisabled(false);
    }
  };

  const onSexoChange = (e: { value: any }) => {
    setSexo(e.value);
  };

  function validateEmail(value: any) {
    let error = "válido";
    if (!value) {
      error = "* Email Requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Email inválido";
    }
    return error;
  }

  const onEmailChange = (e: string) => {
    if (e.length > 0) setIsDisabled(false);
    else setIsDisabled(true);
    setEmail(e);
  };

  const getNacionalities = () => {
    api
      .get(`pais?limit=300`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPaises(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getMaritalStatus = () => {
    api
      .get(`estado-civil`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setEstadosCiviles(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChangeTurno = (e: string) => {
    if (e.length <= 40) setTurno(e);
  };

  const onTurnoChange = (e: any) => {
    let turno = e.value;
    setJornada(turno);
  };

  const getCandidatesById = () => {
    api
      .get(`busqueda-persona/${busPerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCandidate(response.data.data[0]);
      })
      .catch((error) => {});
  };

  const getAfps = () => {
    api
      .get(`prevision`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAfps(response.data.data);
      })
      .catch((error) => {});
  };

  const getPrevisionSalud = () => {
    api
      .get(`salud`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPrevisiones(response.data.data);
      })
      .catch((error) => {});
  };

  const getBanco = () => {
    api
      .get(`banco`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBancos(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getTallas = () => {
    api
      .get(`tallas`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTallas(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const zapatosTallas = [
    { name: 35, code: 35 },
    { name: 36, code: 36 },
    { name: 37, code: 37 },
    { name: 38, code: 38 },
    { name: 39, code: 39 },
    { name: 40, code: 40 },
    { name: 41, code: 41 },
    { name: 42, code: 42 },
    { name: 43, code: 43 },
    { name: 44, code: 44 },
    { name: 45, code: 45 },
  ];

  const jornadaStates = [
    { name: "Mañana", code: "mañana" },
    { name: "Tarde", code: "tarde" },
    { name: "Noche", code: "noche" },
    { name: "Rotativo", code: "rotativo" },
  ];

  const onChangeNCuenta = (e: string) => {
    if (e.length <= 15) setNCuenta(e);
  };

  const onChangeBco = (e: any) => {
    const tCuentasStatesNew = tCuentasStates.filter(
      (cuenta: any) => cuenta.code != "Cuenta Rut"
    );
    if (e.value == "SBIF-012") {
      const ctaRut = { name: "Cuenta Rut", code: "Cuenta Rut" };
      tCuentasStatesNew.push(ctaRut);
    } else {
      setNCuenta("");
    }
    setTCuentasStates(tCuentasStatesNew);
    setBanco(e.value);
  };

  const onChangeTipoCta = (e: any) => {
    setTipoCuenta(e.value);
    if (e.value == "Cuenta Rut") {
      let rutLimpio = rut.replaceAll(".", "").replaceAll("-", "");
      rutLimpio = rutLimpio.substring(0, rutLimpio.length - 1);
      setNCuenta(rutLimpio);
    } else {
      setNCuenta("");
    }
  };

  const onChangeEdadHijos = (e: any) => {
    let valorInputIngresado = e.value;
    let valorUltimoIngresado = valorInputIngresado.substr(-1);
    let valorPenultimoDigitoIngresado = valorInputIngresado.charAt(
      valorInputIngresado.length - 2
    );
    let valorInputLimpiado = "";

    //No dejamos  pasar letras  ni caracteres especiales
    if (valorInputIngresado.length == 1) {
      valorInputLimpiado = valorInputIngresado.replace(/[^0123456789]/g, "");
      e.value = valorInputLimpiado;
    } else if (
      valorPenultimoDigitoIngresado === "-" &&
      valorUltimoIngresado === "0"
    ) {
      valorInputLimpiado = valorInputIngresado.substring(
        0,
        valorInputIngresado.length - 1
      );

      e.value = valorInputLimpiado;
    } else if (
      valorPenultimoDigitoIngresado === "-" &&
      valorUltimoIngresado === "-"
    ) {
      valorInputLimpiado = valorInputIngresado.substring(
        0,
        valorInputIngresado.length - 1
      );
      e.value = valorInputLimpiado;
    } else {
      valorInputLimpiado = valorInputIngresado.replace(/[^1234567890-]/g, "");
      e.value = valorInputLimpiado;
    }
    let edadHijos = e.value;
    setEdadHijos(edadHijos);
  };

  const fillForm = (candidate: any) => {
    console.log(candidate.personaNombre2);
    setNombre(candidate.personaNombre1 ? candidate.personaNombre1 : "");
    setNombre2(candidate.personaNombre2 ? candidate.personaNombre2 : "");
    setApellido(candidate.personaApe1 ? candidate.personaApe1 : "");
    setApellido2(candidate.personaApe2 ? candidate.personaApe2 : "");
    setRut(formatRUT(candidate.personaDni ? candidate.personaDni : ""));

    if (candidate.busquedaId) {
      setFilterId(candidate.busquedaId);
      const busq = group.busquedas.filter(
        (busqueda: any) => busqueda.busquedaId === candidate.busquedaId
      );

      if (busq && busq[0]) {
        setClientName(busq[0].clienteNombre);
        setBusqueda(busq[0]);
      }
    }
    setIsValidRUT(true);
    let telefono = candidate.wsp ? candidate.wsp : "";
    if (candidate.wsp && candidate.wsp.substring(0, 2) == "56") {
      telefono = candidate.wsp.substring(2);
    }
    setTelefono1(telefono);
    let telefono2 = candidate.celular ? candidate.celular : "";
    if (candidate.celular && candidate.celular.substring(0, 2) == "56") {
      telefono2 = candidate.celular.substring(2);
    }
    setTelefono2(telefono2);
    console.log(candidate.personaFecNac);
    if (candidate.personaFecNac) {
      if (
        candidate.personaFecNac == "1900-01-01T00:00:00.000Z" ||
        candidate.personaFecNac == "1900-01-01"
      ) {
        setFechaNacimiento("");
        setEdadCalculada("");
      } else {
        setFechaNacimiento(convertToDate(candidate.personaFecNac));
        setEdadCalculada(calcularEdad(candidate.personaFecNac));
      }
    } else {
      setFechaNacimiento("");
      setEdadCalculada("");
    }
    setEmail(candidate.email ? candidate.email : "");
    setSexo(candidate.genero ? candidate.genero : "");
    setPais(candidate.paisId ? candidate.paisId : "");
    setECivil(candidate.estCivilId ? candidate.estCivilId : "");
    if (candidate.discapacidad == 0) {
      setDiscapacidad("0");
    } else {
      setDiscapacidad("1");
    }
    if (candidate.flag == "0") {
      setIsDisabledRut(false);
    } else {
      setIsDisabledRut(false);
    }
    setDesDiscapacidad(
      candidate.descripciondiscapacidad ? candidate.descripciondiscapacidad : ""
    );
    setDireccion(candidate.direccion ? candidate.direccion : "");
    if (candidate.comunaId) {
      if (comunas) {
        let currentComune = comunas.filter(
          (comuna: any) => comuna.comunaId === candidate.comunaId
        );
        setComunaCode(currentComune[0].comunaId);
        setComuna(currentComune[0].comunaNombre);
      }
    } else {
      setComuna("");
    }

    if (candidate.busPerPaseMovilidad == 1) setPaseDeMovilidad("Si");
    if (candidate.busPerPaseMovilidad == 0) setPaseDeMovilidad("No");
    if (candidate.busPerPaseMovilidad == 2) setPaseDeMovilidad("Nose");
    setAfp(candidate.prevId);
    setSaludPrevision(candidate.saludId);
    setNCargas(candidate.cargas);
    setBanco(candidate.bancoId);
    if (candidate.bancoId == "SBIF-012") {
      const ctaRut = { name: "Cuenta Rut", code: "Cuenta Rut" };
      const tCuentasStatesNew = tCuentasStates;
      tCuentasStatesNew.push(ctaRut);
      setTCuentasStates(tCuentasStatesNew);
    } else {
      setNCuenta("");
    }
    setTipoCuenta(candidate.tipoDeCuentaBco);

    setNCuenta(candidate.numeroDeCuentaBco);
    setNHijos(candidate.numerohijos);
    setEdadHijos(candidate.edadhijoc);
    setFuenteReclutamiento(candidate.fuenteOrg);
    if (candidate.fuenteOrg == "OMIL") {
      setIsDisabledOMIL(false);
    }
    setComunaOMIL(candidate.comunaOmil);
    setTallaPantalon(candidate.tallaIdPant);
    setTallaPolera(candidate.tallaIdPol);
    setTallaZapato(candidate.tallaZapato);
    setJornada(candidate.jornada);
    setTurno(candidate.turno);
    setComentarios(candidate.busPerComentario);
  };

  const onRutChange = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setRut(formatRUT(rutIngresado.trim()));
    setIsValidRUT(validateRUT(formatRUT(rutIngresado.trim())));
    let claseInputRut = "";
    if ((isValidRUT == false && rut != "") || (rut.length < 10 && rut != "")) {
      claseInputRut = "inputD32F2FBorder";
    } else if (
      (isValidRUT == true && rut != "") ||
      (rut.length < 10 && rut != "")
    ) {
      claseInputRut = "input06A502Border";
    }
    setClassEmptyInputRUT(claseInputRut);
  };

  const ontelefono1Change = (value: string) => {
    let telefonoIngresado = value.replace(/[^0123456789]/g, "");
    if (
      telefonoIngresado.length == 10 ||
      telefonoIngresado.length < 9 ||
      telefonoIngresado.length > 11
    ) {
      setIsValidTelf(false);
    }

    if (telefonoIngresado.length == 11) {
      setClassEmptyInputTelephone("input06A502Border");
      telefonoIngresado = telefonoIngresado.substring(
        2,
        telefonoIngresado.length
      );
    }

    if (telefonoIngresado.length == 9) {
      setIsValidTelf(true);
      setClassEmptyInputTelephone("input06A502Border");
    }

    setTelefono1(telefonoIngresado);
  };

  const ontelefono2Change = (value: string) => {
    let telefonoIngresado = value.replace(/[^0123456789]/g, "");
    if (
      telefonoIngresado.length == 10 ||
      telefonoIngresado.length < 9 ||
      telefonoIngresado.length > 11
    ) {
      setIsValidTelf2(false);
    }
    if (telefonoIngresado.length == 11) {
      setClassEmptyInputTelephone("input06A502Border");
      telefonoIngresado = telefonoIngresado.substring(
        2,
        telefonoIngresado.length
      );
    }
    if (telefonoIngresado.length == 9) {
      setIsValidTelf2(true);
      setClassEmptyInputTelephone("input06A502Border");
    }
    setTelefono2(telefonoIngresado);
  };

  const onComuneChange = (e: any) => {
    console.log(e.target.value);
    setClassEmptyComboBoxCommune("inputced4daBorder");
    if (e.target.value) {
      let currentComune = comunas.filter(
        (comuna: any) => comuna.comunaNombre === e.target.value
      );
      setComunaCode(currentComune[0].comunaId);
    }
    setComuna(e.target.value);
  };

  //logica para subir archivos
  const chooseOptions = {
    label: "Seleccionar Archivo",
    icon: "pi pi-fw pi-upload",
    className: "p-button-outlined p-button-info",
  };

  const onMovilityPassChange = (e: { value: any }) => {
    setClassEmptyComboBoxCommune("inputced4daBorder");
    setPaseDeMovilidad(e.value);
    setClassEmptyComboBoxMovilityPass("inputced4daBorder");
  };

  const onComuneChangeOMIL = (e: { value: any }) => {
    if (e.value) {
      let currentComuneOmil = comunas.filter(
        (comuna: any) => comuna.comunaNombre === e.value
      );
      setComunaCodeOMIL(currentComuneOmil[0].comunaId);
    }
    setComunaOMIL(e.value);
  };

  const onFuenteRecluChange = (e: { value: any }) => {
    setClassEmptyComboBoxRecruitmentSource("inputced4daBorder");
    setFuenteReclutamiento(e.value);
    if (e.value == "OMIL") {
      setIsDisabledOMIL(false);
    } else {
      setIsDisabledOMIL(true);
    }
  };

  const onNombreChange = (e: any) => {
    if (e.value) {
      setClassEmptyInputName("inputced4daBorder");
    } else {
      setClassEmptyInputName("inputD32F2FBorder");
    }
    let nombre = e.value;
    setNombre(nombre);
  };

  const onNombre2Change = (e: any) => {
    let nombre2 = e.value;
    setNombre2(nombre2);
  };

  const onApellidoChange = (e: any) => {
    if (e.value) {
      setClassEmptyInputLastName("inputced4daBorder");
    } else {
      setClassEmptyInputLastName("inputD32F2FBorder");
    }
    let apellido = e.value;
    setApellido(apellido);
  };

  const onApellido2Change = (e: any) => {
    let apellido2 = e.value;
    setApellido2(apellido2);
  };

  const renderFooter = (name: string) => {
    if (isEnabled) {
      return (
        <>
          <div className="flex " style={{ width: "100%" }}>
            <div
              className="pt-2 pr-5"
              style={{ cursor: "pointer", width: "75%" }}
            >
              <a
                id="btnCancelCtx1"
                onClick={() => closeModal()}
                style={{ color: "#2A63D5", textDecoration: "underline" }}
              >
                Cancelar registro
              </a>
            </div>

            <div style={{ width: "25%" }}>
              <Button
                id="btnContRegCtx1"
                style={{ width: "100%" }}
                label="Guardar y continuar"
                icon="pi pi-arrow-right"
                iconPos="right"
                disabled={
                  isDisabledRut ||
                  !isValidTelf ||
                  (telefono2 != "" && !isValidTelf2)
                }
                onClick={() => updateIdBusqueda(2)}
                loading={loadingAddCandidate}
                autoFocus
              />
            </div>
          </div>
        </>
      );
    } else {
      <></>;
    }
  };

  function calcularEdad(fecha: any) {
    fecha = fecha.replaceAll(" ", "");
    let d = fecha.substring(0, 10).split("/");
    let dat = d[2] + "-" + d[1] + "-" + d[0];
    var hoy = new Date();
    var cumpleanos = new Date(dat);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    console.log("fecha" + edad);
    return edad;
  }

  function enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }

  function disableScroll() {
    document.body.classList.add("stop-scrolling");
  }

  const searchPositions = () => {
    if (group.busquedas) {
      let newArr: any[] = [];
      group.busquedas.map((busqueda: any) => {
        const newObj = {
          value: busqueda.busquedaId,
          label: busqueda.busquedaId.concat(" - ").concat(busqueda.cargoNombre),
        };
        newArr = [...newArr, newObj];
      });
      setPositions(newArr);
    }
  };

  // useEffect
  useEffect(() => {
    if (token && isModalVisible) {
      getNacionalities();
      searchPositions();
      getMaritalStatus();
      getAfps();
      getPrevisionSalud();
      getBanco();
      getTallas();
      setTCuentasStates([
        { name: "Cuenta de Ahorro", code: "ahorro" },
        { name: "Cuenta Vista", code: "vista" },
        { name: "Cuenta Corriente", code: "corriente" },
        { name: "Chequera Electrónica", code: "electronica" },
      ]);
      api
        .get(`comuna?limit=400`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setComunas(response.data.data);
          setComunasOMIL(response.data.data);
        });

      //Cargos
      api
        .get(`busqueda?limit=5000`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          //setAreasNegocios(response.data.data)
          setBusquedasCargos(response.data.data);
        });

      api
        .get(`area-de-negocio?limit=5000`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAreasNegocios(response.data.data);
        });
      disableScroll();
    } else {
      enableScroll();
    }
  }, [token, isModalVisible]);

  useEffect(() => {
    if (!comunas) return;
    if (!isNew) {
      getCandidatesById();
    } else {
      setIsDisabledRut(false);
    }
    if (comunas.length > 0 && isModalVisible) {
      comunas.forEach((comuna: any) => {
        comuna.name = comuna.comunaNombre;
        comuna.code = comuna.comunaNombre;
      });
      setComunas(comunas);
      setComunasOMIL(comunas);
    }
  }, [comunas]);

  useEffect(() => {
    if (!busquedasCargos) return;
    if (busquedasCargos.length > 0 && isModalVisible) {
      busquedasCargos.forEach((busqueda: any) => {
        busqueda.name = busqueda.cargoNombre;
        busqueda.code = busqueda.cargoNombre;
      });
    }
  }, [busquedasCargos, isModalVisible]);

  useEffect(() => {
    if (candidate) {
      fillForm(candidate);
      getCamposTabEspeciales(candidate.areaNegocioId);
    }
  }, [candidate]);

  useEffect(() => {
    if (!areaNegocios) return;
    if (areaNegocios.length > 0 && isModalVisible) {
      areaNegocios.forEach((areaNegocio: any) => {
        areaNegocio.name = areaNegocio.areaNegocioNombre;
        areaNegocio.code = areaNegocio.areaNegocioCod;
      });

      let areasNegocioNombres = areaNegocios.map((candidate: any) => {
        return [candidate.areaNegocioNombre, candidate.areaNegocioCod];
      });
      let mappingNegocios = areasNegocioNombres.map((x: any) => ({
        name: x[0],
        code: x[1],
      }));
      setResultAreasNegocios(mappingNegocios);
    }
  }, [areaNegocios, isModalVisible]);

  useEffect(() => {
    if (setIsOpenModalCountExpiredToken) {
      setIsOpenModalCountExpiredToken(true);
      console.log(isOpenModalCountExpiredToken);
    }
  }, []);

  const renderFieldSearch = (field: any) => {
    let claseInputRut = "";
    if ((isValidRUT == false && rut != "") || (rut.length < 10 && rut != "")) {
      claseInputRut = "inputD32F2FBorder";
    } else if (
      (isValidRUT == true && rut != "") ||
      (rut.length < 10 && rut != "")
    ) {
      claseInputRut = "input06A502Border";
    }
    return (
      <>
        <label>&#160;</label>
        <span className="p-input-icon-right">
          <InputText
            id="inputTlfRutCtx1"
            className={claseInputRut + " " + classEmptyInputRUT}
            value={rut}
            onChange={(e) => onRutChange(e)}
            placeholder="1.234.567-8"
            disabled={true}
          />
        </span>
        {isValidRUT ? (
          ""
        ) : (
          <span className="p-danger block" style={{ color: "red" }}>
            Rut Invalido
          </span>
        )}
      </>
    );
  };

  function countNumber(str: string) {
    var acu = 0;
    Array.prototype.forEach.call(str, function (val) {
      acu += val.charCodeAt(0) > 47 && val.charCodeAt(0) < 58 ? 1 : 0;
    });
    return acu;
  }

  const setValueInSaveData = (e: any, id: any) => {
    const copy = [...camposTabEspeciales];
    const index = copy.findIndex((x) => x.campEspId == id);
    copy[index].campEspValor = e.target.value;
    setCamposTabEspeciales(copy);
  };

  const closeModal = () => {
    updateRut2("");
    setTelefono1(null);
    setTelefono(null);
    setTelefono2("");
    setDisplayMessageAlert("");
    setDisplayMessage(null);
    setRadioValue("Nose");
    setPaseDeMovilidad("");
    setAreaNegocio(null);
    setComuna("");
    setComunaOMIL("");
    setRut("");
    setEmpresa(null);
    setIsDisabled(true);
    setNombre("");
    setNombre2("");
    setApellido("");
    setApellido2("");
    setCargo("");
    setEdadCalculada("");
    setFuenteReclutamiento("");
    setSearchField({ name: "Rut", code: "Rut" });
    setIsCargoDisabled(true);
    setIsDisabledOMIL(true);
    setClassEmptyComboBoxBusiness("");
    setClassEmptyComboBoxArea("");
    setClassEmptyComboBoxPosition("");
    setClassEmptyInputRUT("");
    setClassEmptyInputTelephone("");
    setClassEmptyInputTelephone2("");
    setClassEmptyInputName("");
    setClassEmptyInputLastName("");
    setClassEmptyComboBoxCommune("");
    setClassEmptyComboBoxMovilityPass("");
    setClassEmptyComboBoxRecruitmentSource("");
    setJornada("");
    setTurno("");
    onClose();
    setFilterId(null);
    setClientName(null);
    setBusqueda(null);
    if (setIsOpenModalCountExpiredToken) {
      setIsOpenModalCountExpiredToken(false);
    }
  };

  const parcialSaveModal = () => {
    let rutSinPuntos = rut.replace(/[\.]/g, "");
    const nombreFirstUpperCase = firstUpperCasse(nombre);
    const nombre2FirstUpperCase = firstUpperCasse(nombre2);
    const apellidoFirstUpperCase = firstUpperCasse(apellido);
    const apellido2FirstUpperCase = firstUpperCasse(apellido2);
    let paseMovilidad: number = 0;
    let bdTelefono = "";
    let bdTelefono2 = "";

    if (telefono1)
      if (telefono1.substring(0, 2) != "56") {
        bdTelefono = "56" + telefono1;
      } else {
        bdTelefono = telefono1;
      }

    if (telefono2)
      if (telefono2.substring(0, 2) != "56") {
        bdTelefono2 = "56" + telefono2;
      } else {
        bdTelefono2 = telefono2;
      }

    if (paseDeMovilidad === "Si") paseMovilidad = 1;
    if (paseDeMovilidad === "No") paseMovilidad = 0;
    if (paseDeMovilidad === "No se") paseMovilidad = 2;
    console.log(filterId);
    const candidateData = {
      busquedaId: filterId,
      busPerStatus: candidate.busPerStatus,
      busPerInicio: candidate.busPerInicio,
      busPerAccion: candidate.busPerAccion,
      busPerId: candidate.busPerId,
      personaNombre1: nombre,
      personaNombre2: nombre2,
      personaApe1: apellido,
      personaApe2: apellido2,
      personaId: rutSinPuntos,
      telefono1: telefono1,
      telefono2: telefono2,
      personaFecNac: fechaNacimiento,
      edadCalculada: edadCalculada,
      email: email,
      genero: sexo,
      paisId: pais,
      estCivilId: ecivil,
      discapacidad: discapacidad,
      descripciondiscapacidad: desDiscapacidad,
      direccion: direccion,
      comunaId: comunaCode,
      busPerPaseMovilidad: paseMovilidad,
      comentario: comentarios,
      contactadoFlag: true,
    };
    console.log(candidateData);
    api
      .put(`busqueda-persona`, candidateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        closeFullModal(1);
      })
      .catch((error) => {
        setLoadingAddCandidate(false);
      });
  };

  const updateIdBusqueda = (flag: number) => {
    if (candidate.busquedaId != filterId) {
      api
        .put(`busqueda-persona-busqueda`, null, {
          params: { busPerId: candidate.busPerId, busquedaId: filterId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: any) => {
          if (flag == 1) {
            parcialSaveModal();
          } else {
            upDateRecordModal();
          }
        })
        .catch((error) => {});
    } else {
      if (flag == 1) {
        parcialSaveModal();
      } else {
        upDateRecordModal();
      }
    }

    if (setIsOpenModalCountExpiredToken) {
      setIsOpenModalCountExpiredToken(false);
    }
  };

  const upDateRecordModal = () => {
    let dat = "";
    if (fechaNacimiento && fechaNacimiento != "") {
      const fecha = fechaNacimiento.replaceAll(" ", "");
      const d = fecha.substring(0, 10).split("/");
      dat = d[2] + "-" + d[1] + "-" + d[0];
    }

    let rutSinPuntos = rut.replace(/[\.]/g, "");
    const nombreFirstUpperCase = firstUpperCasse(nombre);
    const nombre2FirstUpperCase = firstUpperCasse(nombre2);
    const apellidoFirstUpperCase = firstUpperCasse(apellido);
    const apellido2FirstUpperCase = firstUpperCasse(apellido2);
    let paseMovilidad: number = 0;
    let bdTelefono = "";
    let bdTelefono2 = "";

    if (telefono1)
      if (telefono1.substring(0, 3) != "56") {
        bdTelefono = "56" + telefono1;
      } else {
        bdTelefono = telefono1;
      }

    if (telefono2)
      if (telefono2.substring(0, 3) != "56") {
        bdTelefono2 = "56" + telefono2;
      } else {
        bdTelefono2 = telefono2;
      }

    if (paseDeMovilidad === "Si") paseMovilidad = 1;
    if (paseDeMovilidad === "No") paseMovilidad = 0;
    if (paseDeMovilidad === "Nose") paseMovilidad = 2;
    console.log(filterId);
    const candidateData = {
      busquedaId: filterId,
      busPerStatus: candidate.busPerStatus,
      busPerInicio: candidate.busPerInicio,
      busPerAccion: candidate.busPerAccion,
      busPerId: candidate.busPerId,
      personaNombre1: nombre,
      personaNombre2: nombre2,
      personaApe1: apellido,
      personaApe2: apellido2,
      personaId: rutSinPuntos,
      telefono1: telefono1,
      telefono2: telefono2,

      personaFecNac: dat,
      edadCalculada: edadCalculada,
      email: email,
      genero: sexo,
      paisId: pais,
      estCivilId: ecivil,
      discapacidad: discapacidad,
      descripciondiscapacidad: desDiscapacidad,
      direccion: direccion,
      comunaId: comunaCode,
      busPerPaseMovilidad: paseMovilidad,
      prevId: afp,
      saludId: saludPrevision,
      cargas: nCargas,
      bancoId: banco,
      tipoDeCuentaBco: tipoCuenta,
      numeroDeCuentaBco: nCuenta,
      numerohijos: nHijos,
      edadhijoc: edadHijos,
      comunaOMIL: comunaOMIL,
      tallaIdPant: tallaPantalon,
      tallaIdPol: tallaPolera,
      tallaZapato: tallaZapato,
      busPerComentario: comentarios,
      orgId: "tw",
      fuenteOrg: fuenteReclutamiento,
      personaDni: rutSinPuntos,
      celular: bdTelefono2,
      wsp: bdTelefono,
      empresa: empresa,
      comunaOmil: comunaOMIL,
      campoEspecialListado: camposTabEspeciales,
      contactadoFlag: true,
      jornada: jornada,
      turno: turno,
    };
    api
      .put(`busqueda-persona`, candidateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        closeFullModal(2);
      })
      .catch((error) => {
        setLoadingAddCandidate(false);
      });
  };

  const saveBirthDate = (e: any) => {
    let date = e.target.value;

    date = date_reformat_dd(e.target.value);

    setEdadCalculada(calcularEdad(e.target.value));
    setFechaNacimiento(date);
  };

  function checkValue(str: string, max: number) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }

  function date_reformat_dd(input: any) {
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v: any) {
      return v.replace(/\D/g, "");
    });
    if (values[1]) values[1] = checkValue(values[1], 12);
    if (values[0]) values[0] = checkValue(values[0], 31);
    var output = values.map(function (v: any, i: any) {
      return v.length == 2 && i < 2 ? v + " / " : v;
    });
    return output.join("").substr(0, 14);
  }

  const continueLater = () => {
    setLoadingAddCandidate(false);
    //setVisibleConfirm(false);
    onCloseSave();
  };

  const returnToListCandidates = () => {
    if (visibleConfirm) {
      setVisibleConfirm(false);
      onCloseSave();
    }
  };

  const getCamposTabEspeciales = (id: string) => {
    if (token) {
      api
        .get(`campoEspecialValorAsigna`, {
          params: { areaNegocioId: id },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setCamposTabEspeciales(response.data.data);
          getValuesOfFields(response.data.data);
        });
    }
  };

  const getValuesOfFields = (fields: any[]) => {
    if (!isNew && candidate.busPerId != undefined) {
      getValoresCamposEspeciales(candidate.busPerId, fields);
    }
  };

  const getValoresCamposEspeciales = (id: string, fields: any[]) => {
    setValoresCamposEspeciales(candidate.campoEspecialListado);
    const newArr = fields.map((field) => ({
      ...field,
      campEspValor: obtenerValorDeCampo(
        field.campEspId,
        candidate.campoEspecialListado
      ),
    }));
    setCamposTabEspeciales(newArr);
  };

  const obtenerValorDeCampo = (campEspId: string, valuesFields: any[]) => {
    const valueField = getValueOfField(campEspId, valuesFields);
    return valueField != null
      ? valueField.campEspValor != null
        ? valueField.campEspValor
        : ""
      : "";
  };

  const getValueOfField = (campEspId: string, valuesFields: any[]) => {
    const valueField = valuesFields.filter(
      (valueF) => valueF.campEspId == campEspId
    );
    return valueField[0];
  };

  const continuarRegistro = () => {
    if (busquedaPerIdSave)
      history.push(`/addCandidate/${busquedaPerIdSave}/${busquedaId}`);
  };
  const bodyDialogConfirm = () => {
    if (flagModal == "1" || flagModal == "2") {
      return (
        <>
          <p>Candidato no admitido por Teamwork, descartar y no contactar.</p>
          <b>No se agregará al listado.</b>
        </>
      );
    } else if (flagModal == "3") {
      return (
        <>
          <p>
            Candidato se encuentra restringido para procesos vinculados a este
            cliente.
          </p>
          <b>Admitido para otros clientes. No se agregará al listado.</b>
        </>
      );
    } else if (flagModal == "4") {
      return (
        <>
          <p>
            Candidato solicita no ser contactado por Teamwork, descartar y no
            contactar.
          </p>
          <b>No se agregará al listado.</b>
        </>
      );
    } else if (flagModal == "5") {
      return (
        <>
          <p>Candidato ya registrado en este proceso.</p>
          <b>No se agregará al listado.</b>
        </>
      );
    } else if (flagModal == "6") {
      return (
        <>
          <b>Error: </b>
          <p>{errorModal}</p>
        </>
      );
    } else if (flagModal == "0") {
      return (
        <>
          <p>Haz agregado una persona nueva a los registros de Teamwork.</p>
        </>
      );
    }
  };

  const footerDialogConfirm = () => {
    if (
      flagModal == "1" ||
      flagModal == "2" ||
      flagModal == "3" ||
      flagModal == "4" ||
      flagModal == "5" ||
      flagModal == "6"
    ) {
      return (
        <div className="flex justify-content-end flex-wrap">
          <a
            id="btnContinueCtx1"
            className="teamworkSubrayado"
            onClick={() => continueLater()}
            style={{
              width: "8vw",
              color: "#2A63D5",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Entendido
          </a>
        </div>
      );
    } else if (flagModal == "0") {
      return (
        <div className="flex justify-content-between flex-wrap">
          <Button
            id="btnContinueCtx1"
            icon="pi pi-arrow-left"
            className="flex align-items-center p-button-outlined"
            label="Volver al listado"
            onClick={() => continueLater()}
            style={{ width: "12vw" }}
          />
          <Button
            id="btnContinueCtx1"
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Continuar con el registro"
            onClick={() => continuarRegistro()}
          />
        </div>
      );
    }
    /*
    return (
    <div className="flex justify-content-between flex-wrap">
        <Button 
          id="btnContinueCtx1" 
          icon="pi pi-arrow-left" 
          className="flex align-items-center p-button-outlined"  
          label="Volver al listado"   
          onClick={()=> continueLater() } 
          style={{width: '12vw'}}
        />
        <Button 
          id="btnContinueCtx1" 
          icon="pi pi-arrow-right" 
          iconPos="right" 
          label="Continuar con el registro"  
          onClick={()=> continuarRegistro() } 
        />
    </div>
    )
    */
  };

  const renderFooterCandidateError = () => {
    return (
      <div>
        <Button
          label="Reintentar"
          className="p-button-text"
          onClick={() => onHideErrorReintentar()}
        />
        <Button label="Cancelar" onClick={() => onHideError()} />
      </div>
    );
  };

  const onHideError = () => {
    setCandidateError(false);
  };

  const onHideErrorReintentar = () => {
    setCandidateError(false);
  };

  if (!isModalVisible) {
    return null;
  }

  const paseDeMovilidadSelectItems = [
    { label: "Si", value: "Si" },
    { label: "No", value: "No" },
    { label: "No se", value: "Nose" },
  ];

  const showError = () => {
    return (
      <p className="flex align-items-center justify-content-center mt-4">
        {errorModal}
      </p>
    );
  };

  const showClientName = (value: string) => {
    const busq = group.busquedas.filter(
      (busqueda: any) => busqueda.busquedaId === value
    );
    setBusqueda(busq[0]);
    setClientName(busq[0].clienteNombre);
    setFilterId(value);
    getCamposTabEspeciales(busq[0].areaNegocioId);
  };

  const convertToDate = (dateString: any) => {
    let d = dateString.substring(0, 10).split("-");
    let dat = d[2] + "/" + d[1] + "/" + d[0];
    return dat;
  };

  //main return
  return isNew || (candidate && camposTabEspeciales) ? (
    <>
      <div style={isNew || (candidate && camposTabEspeciales) ? {display: "flex"} : {display: "none"}}>
        <Dialog
          header=""
          visible={isModalVisible}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "75vw" }}
          footer={renderFooter("displayResponsive")}
          onHide={() => closeModal()}
          className=""
        >
          <div className="flex flex-column">
            <h3 className="flex justify-content-center">Reclutar Candidato</h3>
            <span className="flex justify-content-center line-height-3">
              Puede cambiar al candidato de proceso en cualquier momento,
              asegurese de comunicar la información respectiva a cada cargo y de
            </span>
            <span className="flex justify-content-center line-height-3">
              almacenar todos los datos con (*). Una vez guarde este registro,
              esta aparecerá en el respectivo proceso seleccionado.
            </span>
          </div>

          <div
            className="flex-grow-1 flex align-items-center justify-content-center"
            style={{ height: "60px" }}
          >
            <h3 style={{}}></h3>
          </div>
          <div className="grid mt-3">
            <div className="col-12">
              <div className="p-fluid formgrid grid">
                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Cargo al que postula</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-3">
                  <Dropdown
                    value={filterId}
                    options={positions}
                    onChange={(e) => showClientName(e.target.value)}
                    optionLabel="label"
                    placeholder="Seleccione ID y Cargo"
                    appendTo="self"
                  />
                </div>

                {busqueda ? (
                  group.cargoGroupId != busqueda.busquedaId ? (
                    <div className="field col-12 md:col-3 cardmodalInfoContact">
                      <label className="cardmodalInfoContact align-self-center">
                        {clientName}
                      </label>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {busqueda ? (
                  group.cargoGroupId != busqueda.busquedaId ? (
                    <div className="field col-12 md:col-3">
                      <Button
                        label="Ver información completa"
                        onClick={() => setVisibleWorkDetail(true)}
                        className="p-button-secondary"
                      />
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* Modal info cargo */}

                {busqueda ? (
                  group.cargoGroupId != busqueda.busquedaId ? (
                    <Dialog
                      header="Información del Cargo"
                      visible={visibleWorkDetail}
                      style={{ width: "70vw" }}
                      onHide={() => setVisibleWorkDetail(false)}
                      draggable={false}
                      resizable={false}
                      closeOnEscape={true}
                      dismissableMask={true}
                      footer={" "}
                    >
                      <Divider align="left" type="dashed">
                        <span className="" style={{ color: "#2A63D5" }}>
                          <b>Solicitud</b>
                        </span>
                      </Divider>

                      <div>
                        <table className="tablaInfo">
                          <tr>
                            <td className="tablasTitle ">ID:</td>
                            <td>{busqueda.busquedaId}</td>
                            <td className="tablasTitle">Área Negocio:</td>
                            <td>{busqueda.areaNegocioNombre}</td>
                            <td className="tablasTitle">Ejecutivo: </td>
                            <td>En Desarrollo</td>
                            <td className="tablasTitle">Fecha inicio: </td>
                            <td>{convertToDate(busqueda.busquedaInicio)}</td>
                          </tr>
                          <tr>
                            <td className="tablasTitle">Fecha cierre: </td>
                            <td>{convertToDate(busqueda.busquedaFin)}</td>
                            <td className="tablasTitle">
                              Encargado de cuenta:
                            </td>
                            <td>En desarrollo</td>
                            <td className="tablasTitle">KAM:</td>
                            <td>En desarrollo</td>
                            <td className="tablasTitle">Cupos Asignados:</td>
                            <td>{busqueda.busquedaCantidad}</td>
                          </tr>
                          <tr>
                            <td className="tablasTitle">Cupos Cubiertos:</td>
                            <td>
                              {busqueda.busquedaCuposRest
                                ? busqueda.busquedaCantidad -
                                  busqueda.busquedaCuposRest
                                : 0}
                            </td>
                            <td className="tablasTitle">Cupos restantes:</td>
                            <td>
                              {busqueda.busquedaCuposRest
                                ? busqueda.busquedaCuposRest
                                : busqueda.busquedaCantidad}
                            </td>
                            <td className="tablasTitle">Duración:</td>
                            <td>En Desarrollo</td>
                            <td className="tablasTitle">Target:</td>
                            <td>En Desarrollo</td>
                          </tr>
                          <tr>
                            <td className="tablasTitle">Renta:</td>
                            <td>{busqueda.busquedaRenta}</td>
                            <td className="tablasTitle">Bonos:</td>
                            <td>En Desarrollo</td>
                            <td className="tablasTitle">Otro requisito:</td>
                            <td colSpan={3}>En Desarrollo</td>
                          </tr>
                          <tr>
                            <td className="tablasTitle">Observación:</td>
                            <td colSpan={7}>{busqueda.busquedaObservacion}</td>
                          </tr>
                          <tr>
                            <td className="tablasTitle">Horario Turno:</td>
                            <td colSpan={7}>{busqueda.busquedaRangoHorario}</td>
                          </tr>
                        </table>
                      </div>
                    </Dialog>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {candidate.warningData &&
                candidate.warningData.length > 0 &&
                candidate.warningData.filter((war: any) => war.excPerHabilitado)
                  .length > 0 ? (
                  <div
                    className="p-2 col-12 ml-2"
                    style={{ borderLeft: "13px solid #FFB400" }}
                  >
                    <div className="field col-12 md:col-10">
                      <p className="inhabilitadoBoxTittle">
                        Candidato con Restricciones
                      </p>
                    </div>

                    {candidate.warningData.map((warning: any) => {
                      return (
                        <>
                          {/*
                      {warning.excTipoDescripcion} {warning.clienteNombre}
                    */}

                          {warning.excPerHabilitado ? (
                            <p className="alignIcon ml-4">
                              Descartado en ID {warning.busquedaId} favor no
                              considerar
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Información personal</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-3">
                  <label htmlFor="inputName1Ctx1">Primer nombre</label>
                  <InputText
                    className={classEmptyInputName}
                    id="inputName1Ctx1"
                    value={nombre}
                    type="text"
                    onChange={(e) => onNombreChange(e.target)}
                    maxLength={30}
                    disabled={!isEnabled}
                  />
                  {nombre.length >= 30 && (
                    <small id="msjLengthNameCtx1" style={{ color: "#f59e0b" }}>
                      Ha alcanzado el máximo de caracteres: 30 max
                    </small>
                  )}
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="inputName2Ctx1">
                    {" "}
                    Segundo nombre
                    <span
                      className="pl-2"
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "11px",
                        color: "#AEAEAE",
                      }}
                    >
                      Opcional
                    </span>
                  </label>
                  <div>
                    <InputText
                      id="inputName2Ctx1"
                      value={nombre2}
                      //disabled={isDisabled}
                      type="text"
                      onChange={(e) => onNombre2Change(e.target)}
                      maxLength={30}
                      disabled={!isEnabled}
                    />
                    {nombre2.length >= 30 && (
                      <small
                        id="msjLengthName2Ctx1"
                        style={{ color: "#f59e0b" }}
                      >
                        Ha alcanzado el máximo de caracteres: 30 máximo.
                      </small>
                    )}
                  </div>
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="inputLastname1Ctx1">Primer apellido</label>
                  <InputText
                    className={classEmptyInputLastName}
                    disabled={!isEnabled}
                    value={apellido}
                    id="inputLastName1Ctx1"
                    type="text"
                    onChange={(e) => onApellidoChange(e.target)}
                    maxLength={30}
                  />
                  {apellido.length >= 30 && (
                    <small
                      id="msjLengthApellidoCtx1"
                      style={{ color: "#f59e0b" }}
                    >
                      Ha alcanzado el máximo de caracteres: 30 máximo.
                    </small>
                  )}
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="inputLastname2Ctx1">
                    Segundo apellido
                    <span
                      className="pl-2"
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "11px",
                        color: "#AEAEAE",
                      }}
                    >
                      Opcional
                    </span>
                  </label>
                  <InputText
                    //disabled={isDisabled}
                    value={apellido2}
                    id="inputLastName2Ctx1"
                    type="text"
                    onChange={(e) => onApellido2Change(e.target)}
                    maxLength={30}
                    disabled={!isEnabled}
                  />
                  {apellido2.length >= 30 && (
                    <small
                      id="msjLengthApellido2Ctx1"
                      style={{ color: "#f59e0b" }}
                    >
                      Ha alcanzado el maximo de caracteres: 30 max
                    </small>
                  )}
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="inputCargoCtx1">RUT </label>
                  {renderFieldSearch(searchField.code)}
                </div>

                <div className="field col-12 md:col-3">
                  <label>Teléfono 1 </label>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon ">
                      <i className="pi pi-whatsapp ml-1"></i>
                      <p className="ml-2">+56</p>
                    </span>
                    <InputText
                      className={classEmptyInputTelephone}
                      value={telefono1}
                      id="inputTlf1Ctx1"
                      placeholder="12345678"
                      maxLength={12}
                      onChange={(e: any) => ontelefono1Change(e.target.value)}
                      disabled={!isEnabled}
                    />
                  </div>
                  {!isValidTelf && (
                    <small style={{ color: "#D32F2F" }}>
                      Teléfono invalido
                    </small>
                  )}
                </div>

                <div className="field col-12 md:col-3">
                  <label>
                    Teléfono 2
                    <span
                      className="pl-2"
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "11px",
                        color: "#AEAEAE",
                      }}
                    >
                      Opcional
                    </span>
                  </label>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon ">
                      <i className="pi pi-phone ml-1"></i>
                      <p className="ml-2">+56</p>
                    </span>
                    <InputText
                      className={classEmptyInputTelephone2}
                      value={telefono2}
                      id="inputTlf2Ctx1"
                      placeholder="12345678"
                      maxLength={12}
                      onChange={(e: any) => ontelefono2Change(e.target.value)}
                      disabled={!isEnabled}
                    />
                  </div>
                  {telefono2 != "" && !isValidTelf2 && (
                    <small
                      className="p-danger block"
                      style={{ color: "#D32F2F" }}
                    >
                      Teléfono invalido
                    </small>
                  )}
                  {telefono2 && countNumber(telefono2) === 8 && !isDisabled ? (
                    <small
                      id="username2-help"
                      className="p-danger block"
                      style={{ color: "green" }}
                    >
                      Teléfono válido
                    </small>
                  ) : (
                    telefono2 &&
                    !isDisabled && (
                      <small
                        id="username2-help"
                        className="p-danger block"
                        style={{ color: "red" }}
                      >
                        Teléfono debe tener 8 dígitos
                      </small>
                    )
                  )}
                </div>
                <div className="field col-12 md:col-2">
                  <label htmlFor="iCalendarFNacimientoCtx3">
                    Fecha de nacimiento
                  </label>
                  <InputText
                    id="iCalendarFNacimientoCtx3"
                    value={fechaNacimiento}
                    onChange={(e) => saveBirthDate(e)}
                    placeholder="dd mm aaaa"
                    disabled={!isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-1">
                  <label>Edad</label>
                  <InputText
                    value={edadCalculada || ""}
                    id="inputLastName2Ctx3"
                    type="text"
                    disabled
                  />
                </div>

                <div className="field col-12 md:col-5">
                  <label>
                    Email{" "}
                    <span style={{ color: "#f59e0b", fontWeight: "bolder" }}>
                      *
                    </span>
                  </label>
                  <InputText
                    id="inputEmailCtx5"
                    value={email || ""}
                    onChange={(e: any) => onEmailChange(e.target.value)}
                    disabled={!isEnabled}
                  />
                  {validateEmail(email) !== "válido" && (
                    <small
                      id="username2-help"
                      className="p-success block"
                      style={{ color: "red" }}
                    >
                      {" "}
                      {validateEmail(email)}
                    </small>
                  )}
                  {validateEmail(email) === "válido" && (
                    <small
                      id="username2-help"
                      className="p-success block"
                      style={{ color: "green" }}
                    >
                      {" "}
                      Email {validateEmail(email)}
                    </small>
                  )}
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="selectSexoCtx3">
                    Sexo <small>(de la cédula de identidad)</small>
                  </label>
                  <Dropdown
                    id="selectSexoCtx3"
                    options={sexoStates}
                    onChange={onSexoChange}
                    value={sexo || ""}
                    optionValue="code"
                    optionLabel="name"
                    placeholder="Seleccione genero"
                    appendTo="self"
                    disabled={!isEnabled}
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="selectNacionalidadCtx3">Nacionalidad</label>
                  <Dropdown
                    id="selectNacionalidadCtx3"
                    value={pais || ""}
                    options={paises}
                    onChange={(e) => setPais(e.value)}
                    optionValue="paisId"
                    optionLabel="paisNombre"
                    filter
                    placeholder="Seleccione nacionalidad"
                    appendTo="self"
                    disabled={!isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-5">
                  <label htmlFor="selectEcivilCtx3">Estado Civil</label>
                  <Dropdown
                    id="selectEcivilCtx3"
                    value={ecivil || ""}
                    options={estadosCiviles}
                    onChange={(e) => setECivil(e.value)}
                    optionValue="estCivilId"
                    optionLabel="estCivilNombre"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Seleccione estado civil" 
                    appendTo="self"
                    disabled={!isEnabled}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="selectDiscCtx3">Discapacidad</label>
                  <Dropdown
                    id="selectDiscCtx3"
                    value={discapacidad}
                    options={discapacidadValues}
                    onChange={(e) => disabilityDisabled(e)}
                    optionValue="code"
                    optionLabel="name"
                    placeholder="Seleccione"
                    appendTo="self"
                    disabled={!isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label>&#160;</label>
                  <InputText
                    value={desDiscapacidad || ""}
                    id="inputDiscapacidadCtx3"
                    type="text"
                    placeholder="Describe la discapacidad"
                    onChange={(e) => setDesDiscapacidad(e.target.value)}
                    maxLength={50}
                    disabled={discapacidadDisabled}
                  />
                  {desDiscapacidad && desDiscapacidad.length >= 50 && (
                    <small id="msjLengthNameCtx1" style={{ color: "#f59e0b" }}>
                      máximo de caracteres: 50 max
                    </small>
                  )}
                </div>

                <div className="field col-12 md:col-5">
                  <label>Dirección</label>
                  <InputText
                    id="inputDireccionCtx5"
                    value={direccion || ""}
                    onChange={(e) => setDireccion(e.target.value)}
                    maxLength={200}
                    disabled={!isEnabled}
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="selectComunCtx1">Comuna de residencia</label>
                  <Dropdown
                    className={classEmptyComboBoxCommune}
                    id="selectComunCtx1"
                    value={comuna}
                    options={comunas}
                    onChange={(e) => onComuneChange(e)}
                    optionValue="code"
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Seleccione comuna"
                    disabled={!isEnabled}
                    emptyFilterMessage="No hay opciones disponibles"
                    appendTo="self"
                  />
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="state">Pase de movilidad </label>
                  <Dropdown
                    className={classEmptyComboBoxMovilityPass}
                    id="selectPaseDeMovilidadCtx1"
                    value={paseDeMovilidad}
                    options={paseDeMovilidadSelectItems}
                    onChange={onMovilityPassChange}
                    placeholder="Seleccione pase de movilidad"
                    disabled={!isEnabled}
                    appendTo="self"
                  />
                </div>

                {/*
              {!isNew ? isDisabledRut ?
                candidate.flag == 1 || candidate.flag == 2 ?
                  <div className="flex flex-direccion-row col-12" style={{ borderLeft: '13px solid #D32F2F' }}>
                    <div className="field col-12 md:col-10">
                      <p className='inhabilitadoBoxTittle'>Candidato restringido</p>
                      <p className='inhabilitadoBox'>No admitido por Teamwork.
                        <b> Complete el registro y almacene, no volver a contactar</b></p>
                    </div>
                    <div className="field col-12 md:col-2 mt-5" >
                      <Button id="btnContRegCtx1"
                        className='p-button-raised p-button-danger'
                        label="Almacenar Registro"
                        icon="pi pi-arrow-right"
                        iconPos="right"
                        onClick={() => updateIdBusqueda(1)}
                        loading={loadingAddCandidate}
                      />
                    </div>
                  </div>
                  : candidate.flag == 3 ?
                    <div className="flex flex-direccion-row col-12" style={{ borderLeft: '13px solid #D32F2F' }}>
                      <div className="field col-12 md:col-10">
                        <p className='inhabilitadoBoxTittle'>Candidato Inhabilitado</p>
                        <p className='inhabilitadoBox'>Inhabilitado para procesos con este cliente, disponible para otros.
                          <b> Complete el registro y almacene</b></p>
                      </div>
                      <div className="field col-12 md:col-2 mt-5" >
                        <Button id="btnContRegCtx1"
                          className='p-button-raised p-button-danger'
                          label="Almacenar Registro"
                          icon="pi pi-arrow-right"
                          iconPos="right"
                          onClick={() => parcialSaveModal()}
                          loading={loadingAddCandidate}
                        />
                      </div>
                    </div>
                    : candidate.flag == 4 ?
                      <div className="flex flex-direccion-row col-12" style={{ borderLeft: '13px solid #D32F2F' }}>
                        <div className="field col-12 md:col-10">
                          <p className='inhabilitadoBoxTittle'>Candidato No Disponible</p>
                          <p className='inhabilitadoBox'>No desea ser contactado para este proceso.
                            <b> No volver a contactar.</b></p>
                        </div>
                        <div className="field col-12 md:col-2 mt-5" >
                          <Button id="btnContRegCtx1"
                            className='p-button-raised p-button-danger'
                            label="Almacenar Registro"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            onClick={() => parcialSaveModal()}
                            loading={loadingAddCandidate}
                          />
                        </div>
                      </div>
                      : candidate.flag == 5 ?
                        <div className="flex flex-direccion-row col-12" style={{ borderLeft: '13px solid #D32F2F' }}>
                          <div className="field col-12 md:col-10">
                            <p className='inhabilitadoBoxTittle'>Candidato Duplicado</p>
                            <p className='inhabilitadoBox'>Candidato ya registrado en este proceso.
                              <b> Complete el registro y almacene, no volver a contactar.</b></p>
                          </div>
                          <div className="field col-12 md:col-2 mt-5" >
                            <Button id="btnContRegCtx1"
                              className='p-button-raised p-button-danger'
                              label="Almacenar Registro"
                              icon="pi pi-arrow-right"
                              iconPos="right"
                              onClick={() => parcialSaveModal()}
                              loading={loadingAddCandidate}
                            />
                          </div>
                        </div>
                        : ''

                : '' : ''
              }

            */}

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Información Adicional</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectPrevisionCtx4">Previsión</label>
                  <Dropdown
                    id="selectPrevisionCtx4"
                    value={afp}
                    options={afps}
                    onChange={(e) => setAfp(e.value)}
                    optionValue="prevId"
                    optionLabel="prevNombre"
                    placeholder="Seleccione previsión"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectSaludCtx4">Salud</label>
                  <Dropdown
                    id="selectSaludCtx4"
                    value={saludPrevision}
                    options={previsiones}
                    onChange={(e) => setSaludPrevision(e.value)}
                    optionValue="saludId"
                    optionLabel="saludNombre"
                    placeholder="Seleccione salud"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="txtCargasCtx4">N° de Cargas</label>
                  <br />
                  <InputNumber
                    inputId="txtCargasCtx4"
                    value={nCargas}
                    onValueChange={(e) => setNCargas(e.value)}
                    mode="decimal"
                    showButtons
                    buttonLayout="horizontal"
                    style={{ width: "130px" }}
                    min={0}
                    max={10}
                    decrementButtonClassName="p-button-primary"
                    incrementButtonClassName="p-button-primary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectBancoCtx4">Banco</label>
                  <Dropdown
                    id="selectBancoCtx4"
                    value={banco}
                    options={bancos}
                    onChange={(e) => onChangeBco(e)}
                    optionValue="bancoId"
                    optionLabel="bancoNombre"
                    placeholder="Seleccione banco"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectTijosipoCuentaCtx4">
                    Tipo de cuenta
                  </label>
                  <Dropdown
                    id="selectTipoCuentaCtx4"
                    value={tipoCuenta}
                    options={tCuentasStates}
                    onChange={(e) => onChangeTipoCta(e)}
                    optionValue="name"
                    optionLabel="name"
                    placeholder="Seleccione tipo cuenta"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="inputNumeroCuentaCtx4">N° de cuenta</label>
                  <InputText
                    value={nCuenta || ""}
                    id="inputNumeroCuentaCtx4"
                    type="number"
                    onChange={(e: any) => onChangeNCuenta(e.target.value)}
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>
                <br />

                <div className="field col-12 md:col-2">
                  <label htmlFor="txtHijosCtx4">N° de hijos</label>
                  <br />
                  <InputNumber
                    inputId="txtHijosCtx4"
                    value={nHijos}
                    onValueChange={(e) => setNHijos(e.value)}
                    mode="decimal"
                    showButtons
                    buttonLayout="horizontal"
                    style={{ width: "120px" }}
                    min={0}
                    max={10}
                    decrementButtonClassName="p-button-primary"
                    incrementButtonClassName="p-button-primary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-2">
                  <label htmlFor="txtEdadHijosCtx4">Edad de los hijos: </label>
                  <br />
                  <InputText
                    value={edadHijos || ""}
                    id="txtEdadHijosCtx4"
                    type="text"
                    placeholder="Ejemplo: 5-7-12"
                    onChange={(e) => onChangeEdadHijos(e.target)}
                    maxLength={29}
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectFuenteReclutaCtx1">
                    Fuente reclutamiento:{" "}
                  </label>
                  <Dropdown
                    className={classEmptyComboBoxRecruitmentSource}
                    id="selectFuenteReclutaCtx1"
                    value={fuenteReclutamiento}
                    options={fuentesReclutamiento}
                    onChange={onFuenteRecluChange}
                    optionValue="code"
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="code"
                    placeholder="Seleccione..."
                    disabled={isDisabledRut || !isEnabled}
                    emptyFilterMessage="No hay opciones disponibles"
                    appendTo="self"
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="selectFuenteReclutaCtx1">Comuna OMIL: </label>
                  <Dropdown
                    id="selectFuenteReclutaCtx1"
                    value={comunaOMIL}
                    options={comunasOMIL}
                    onChange={onComuneChangeOMIL}
                    optionValue="code"
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="code"
                    placeholder="Seleccione..."
                    disabled={isDisabledOMIL || !isEnabled}
                    emptyFilterMessage="No hay opciones disponibles"
                    appendTo="self"
                  />
                </div>

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Uniforme</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-4">
                  <label>Pantalón</label>
                  <Dropdown
                    id="selectPantalonCtx6"
                    value={tallaPantalon}
                    options={tallas}
                    onChange={(e) => setTallaPantalon(e.value)}
                    optionValue="tallaId"
                    optionLabel="tallaNombre"
                    placeholder="Talla Pantalón"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label>Polera</label>
                  <Dropdown
                    id="selectPoleraCtx6"
                    value={tallaPolera}
                    options={tallas}
                    onChange={(e) => setTallaPolera(e.value)}
                    optionValue="tallaId"
                    optionLabel="tallaNombre"
                    placeholder="Talla Polera"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label>Zapatos</label>
                  <Dropdown
                    id="selectZapatosCtx6"
                    value={tallaZapato}
                    options={zapatosTallas}
                    onChange={(e) => setTallaZapato(e.value)}
                    optionValue="code"
                    optionLabel="name"
                    placeholder="Talla Zapato"
                    appendTo="self"
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Información Laboral</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-4 ">
                  <label htmlFor="selectJornadaCtx4">Jornada</label>
                  <Dropdown
                    id="selectJornadaCtx4"
                    value={jornada}
                    options={jornadaStates}
                    onChange={onTurnoChange}
                    optionValue="name"
                    optionLabel="name"
                    placeholder="Seleccione jornada"
                    disabled={isDisabledRut || !isEnabled}
                    appendTo="self"
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="inputTurnoCtx4">Turno (Ejemplo 5x2)</label>
                  <InputText
                    value={turno || ""}
                    id="inputTurnoCtx4"
                    type="text"
                    placeholder="Describe el turno"
                    onChange={(e: any) => onChangeTurno(e.target.value)}
                    disabled={isDisabledRut || !isEnabled}
                  />
                  {turno && turno.toString().length >= 40 && (
                    <small id="msjLengthNameCtx1" style={{ color: "#f59e0b" }}>
                      Ha alcanzado el maximo de caracteres: 40 max
                    </small>
                  )}
                </div>

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Especial Cliente</b>
                  </div>
                </Divider>

                {camposTabEspeciales.map((field, i) => {
                  return (
                    <div className=" col-12 md:col-4 mt-2 mb-2">
                      {field.tipoCampo.toLowerCase() == "text" ? (
                        <span className="field">
                          <label className="">{field.campEspNombre}</label>
                          <InputText
                            id={field.campEspId}
                            maxLength={field.campTextMax}
                            minLength={field.campTextMin}
                            onChange={(e: any) =>
                              setValueInSaveData(e, field.campEspId)
                            }
                            value={field.campEspValor}
                            disabled={isDisabledRut || !isEnabled}
                          ></InputText>{" "}
                        </span>
                      ) : field.tipoCampo.toLowerCase() == "select" ? (
                        <span className="field">
                          <label className="">{field.campEspNombre}</label>
                          <Dropdown
                            options={field.campSelectJson}
                            value={parseInt(field.campEspValor)}
                            disabled={isDisabledRut || !isEnabled}
                            onChange={(e: any) =>
                              setValueInSaveData(e, field.campEspId)
                            }
                            placeholder="Seleccione"
                            optionLabel="valor"
                            optionValue="codigo"
                            appendTo="self"
                          />{" "}
                        </span>
                      ) : field.tipoCampo.toLowerCase() == "number" ? (
                        <span className="field">
                          <label className="">{field.campEspNombre}</label>
                          <InputNumber
                            inputId="withoutgrouping"
                            id={field.campo}
                            min={field.campNumeroMin}
                            max={field.campNumeroMax}
                            value={field.campEspValor}
                            disabled={isDisabledRut || !isEnabled}
                            onValueChange={(e: any) =>
                              setValueInSaveData(e, field.campEspId)
                            }
                            mode="decimal"
                            useGrouping={false}
                          />
                        </span>
                      ) : field.tipoCampo.toLowerCase() == "date" ? (
                        <span className="field">
                          <label className="">{field.campEspNombre}</label>
                          <Calendar
                            id={field.campEspId}
                            value={new Date(field.campEspValor)}
                            disabled={isDisabledRut || !isEnabled}
                            dateFormat="dd/mm/yy"
                            onChange={(e: any) =>
                              setValueInSaveData(e, field.campEspId)
                            }
                            showIcon
                            appendTo="self"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}

                <Divider align="left" style={{ margin: 0 }}>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    <b>Comentarios</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-12">
                  <InputText
                    id="inputEmailCtx5"
                    value={comentarios || ""}
                    type="text"
                    placeholder="Escriba aquí"
                    onChange={(e) => setComentarios(e.target.value)}
                    maxLength={600}
                    disabled={isDisabledRut || !isEnabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <ConfirmDialog
          visible={visibleConfirm}
          onHide={() => returnToListCandidates()}
          style={{ width: "35vw" }}
          className={classBorderModal}
          message={bodyDialogConfirm}
          header={alertModalTitle}
          footer={footerDialogConfirm}
        >
          <Button
            onClick={() => setVisibleConfirm(true)}
            icon="pi pi-check"
            label="Confirm"
          />
        </ConfirmDialog>

        <Dialog
          visible={candidateError}
          style={{ width: "35vw", height: "15vw" }}
          footer={renderFooterCandidateError()}
          onHide={() => onHideError()}
        >
          <div className="flex justify-content-center flex-wrap">
            <i
              className="flex align-items-center justify-content-center pi pi-ban"
              style={{ fontSize: "3em", color: "#FF4900" }}
            ></i>
          </div>
          <div className="flex justify-content-center flex-wrap">
            <b>Error: {showError()}</b>
          </div>
        </Dialog>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CandidateModalV2;

/*    


              <Divider align="left" style={{margin:0}}>
                  <div style={{ fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              fontSize: '15px',
                              lineHeight: '18px'
                              }}>
                              <b>Documentos</b>
                  </div>
              </Divider>

<div className='flex flex-row'>

              <div className="field col-12 md:col-5">
              <label>Foto de Carnet</label>
              <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        (Cara Frontal)
                    </span>
              <FileUpload mode='basic'
                          name="demo" 
                          multiple accept="image/*" 
                          maxFileSize={1000000}
                          chooseOptions={chooseOptions} 
                />
              </div>
              
              <div className="field col-12 md:col-6">
              <label>Certificado de antecedentes</label>
              <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        (PDF)
                    </span>
              <FileUpload mode='basic'
                          name="demo" 
                          multiple accept="image/*" 
                          maxFileSize={1000000}
                          chooseOptions={chooseOptions} 
                />
                  <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        Formato pdf/Maximo 1MG
                    </span>
              </div>

              <div className="field col-12 md:col-5">
              <label>Curriculum</label>
              <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        (PDF)
                    </span>
              <FileUpload mode='basic'
                          name="demo" 
                          multiple accept="image/*" 
                          maxFileSize={1000000}
                          chooseOptions={chooseOptions} 
                />
                      <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        Formato pdf/Maximo 1MG
                    </span>
                    </div>

                    
                    <div className="field col-12 md:col-5">
              <label>Hombre bajo la lluvia</label>
              <FileUpload mode='basic'
                          name="demo" 
                          multiple accept="image/*" 
                          maxFileSize={1000000}
                          chooseOptions={chooseOptions} 
                />
                  <span 
                    className='pl-2'
                    style={{  fontFamily: 'Roboto',
                              fontStyle: 'italic',
                              fontWeight: '400',
                              fontSize: '11px', 
                              color: '#AEAEAE'}}>
                        Formato pdf/Maximo 1MG
                    </span>
              </div>
              </div>
    */
