import { useEffect, useState } from "react";
import InputNumberCustomized from "../../../InputNumberCustomized";
import InputEdadHijoCustomized from "../../../InputEdadHijoCustomized";

interface IHijos {
  list?: any[]; 
  setFormCandidate: Function;
  formCandidate: any;
  selectGeneral: any;
  defaultValue?: number[];
  requerido?: boolean; 
}

interface ICountChildren {
  cantidad: number;
}

export default (props: IHijos) => {
  const { list, setFormCandidate, formCandidate, selectGeneral, defaultValue, requerido } = props;
  const [countChildren, setCountChildren] = useState<ICountChildren[]>([]);
  const [limit] = useState<number>(10);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const arrayCurrentChildren = defaultValue.map((cantidad: number) => {
        return {
          cantidad,
        };
      });

      setCountChildren(arrayCurrentChildren);
    }
  }, [defaultValue]);

  const handleChangeCountChildren = (values: any) => {
    const value = Math.floor(values);
    const arrayCountChildren: ICountChildren[] = [];
    for (let i = 0; i < (value < limit ? value : limit); i++) {
      arrayCountChildren.push({ cantidad: 0 });
    }
    setCountChildren(arrayCountChildren);

    if (formCandidate) {
      const { hijos }: any = formCandidate;
      const { value: arrayHijos }: any = hijos;
      if (value < arrayHijos.length) {
        arrayHijos.splice(value, arrayHijos.length - value);
        setFormCandidate({ ...formCandidate });
      }
    }
  };

  return (
    <>
      <div className="content-subtitle">
        <div>
          <p className="subtitle">HIJOS</p>
        </div>
        <span className="linea"></span>
      </div>
      <div className="container-input">
        <div className="content-input input-number">
          <InputNumberCustomized
            layer="Nro de Hijos"
            placeholder="0"
            maxNumber={limit}
            onChange={handleChangeCountChildren}
            defaultValue={defaultValue?.length}
          />
        </div>

        <div className="content-input wrap">
          {countChildren.map((item: ICountChildren, index: number) => (
            <div className="content-text-modal-update w-x6">
              <InputEdadHijoCustomized
                layer={`Edad ${index + 1}`}
                placeholder="0"
                required={false}
                onValueChange={setFormCandidate}
                onPermaValues={formCandidate}
                onPropertyChange="hijos"
                errorPosition="down"
                index={index}
                defaultValue={item.cantidad > 0 ? String(item.cantidad) : ""}
                requerido={item.cantidad ? false : true}
                datoRequerido={requerido}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
