interface IFormatMoneyChilean {
  texto: string;  
}

export const formatMoneyChilean = (values: IFormatMoneyChilean):string => {
  const {texto} = values; 
  if (!texto) {
    return texto
  }
  const numero = parseFloat(texto);
  const formatoMonedaChilena = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  });
  const numeroFormateado = formatoMonedaChilena.format(numero);
  return numeroFormateado; 
}