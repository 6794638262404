import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/operacion-bajas-confirmadas.min.css";
import "../../assets/css/seleccion-redesign.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import "../../assets/css/button-generico.min.css";
import "../../assets/css/loading.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import OperacionContratacionState from "../../context/Operaciones/OperacionContratacionState";
import { FILTER_PROFILE } from "../../diccionary/Diccionary";
import { Params } from "../../interfaces/IOperacionesContratacion";
import SeccionCliente from "./Operaciones/SeccionCliente";
import ValidationKamState from "../../context/ValidationKam/ValidationKamState";
import SeleccionState from "../../context/Seleccion/SeleccionState";
import SubMenu from "./Seleccion/SubMenu";
import TablaContratoPendientes from "./Contratos/Pendientes/TablaContratoPendientes";
import ContratosState from "../../context/Contratos/ContratosState";

const ContratoPendientes: React.FC<IPage> = (props) => {
  const { idCliente } = useParams<Params>();
  const [itemMenu, setItemMenu] = useState<string | undefined>("seleccion");
  const [itemMenuFilter, setItemMenuFilter] = useState<string>("MASIVO");

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const authRequest = {
    ...loginRequest,
  };

  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
            itemMenu={itemMenu}
            setItemMenu={setItemMenu}
            setItemMenuFilter={setItemMenuFilter}
          />
        ) : (
          ""
        )}

        <div className="container-redesign-seleccion">
          <div className="content-redesign-seleccion">
            <OperacionContratacionState>
              <SeleccionState>
                <ValidationKamState>
                  <ContratosState>
                    {[FILTER_PROFILE.adm_tw, FILTER_PROFILE.tw_ops].includes(
                      profile
                    ) && (
                      <>
                        <SubMenu
                          itemMenu={itemMenu}
                          setItemMenu={setItemMenu}
                          itemMenuFilter={itemMenuFilter}
                        />
                        <TablaContratoPendientes />
                      </>
                    )}
                  </ContratosState>
                </ValidationKamState>
              </SeleccionState>
            </OperacionContratacionState>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default ContratoPendientes;
