import { useEffect, useState } from "react";
import InputNumberCustomized from "../../../InputNumberCustomized";
import InputTextCustomized from "../../../InputTextCustomized";
import CloseIcon from "@mui/icons-material/Close";
import { IOptionDropDownList } from "../../../../../../interfaces/IDropDownList";
import InputPhoneCustomized from "../../../InputPhoneCustomized";

interface IReferir {
  list?: any[];
  setFormCandidate: Function;
  formCandidate: any;
  selectGeneral: any;
  defaultValue?: any;
  requerido?: boolean; 
}

interface IFieldForm {
  value: string | IOptionDropDownList | undefined;
  valid: boolean;
}
interface ICountCargas {
  nombreCompleto: IFieldForm;
  fono: IFieldForm;
}

interface IClickDeleteCarga {
  index: number;
}

export default (props: IReferir) => {
  const { list, setFormCandidate, formCandidate, selectGeneral, defaultValue, requerido } =
    props;
  const [limit] = useState<number>(10);
  const [countChildren, setCountChildren] = useState<any>([]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setCountChildren(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (countChildren) {
      const arrayCandidate = { ...formCandidate };
      arrayCandidate.referidos = { value: countChildren, valid: true };
      setFormCandidate(arrayCandidate);
    }
  }, [countChildren]);

  const handleChangeCountCargas = (values: any) => {
    const value = Math.floor(values);
    const arrayCountChildren: ICountCargas[] = [...countChildren];

    if(value > arrayCountChildren.length){
      arrayCountChildren.push({
        nombreCompleto: { value: undefined, valid: true },
        fono: { value: undefined, valid: true },
      });
    }
    
    if(value < arrayCountChildren.length){
      arrayCountChildren.splice(value, arrayCountChildren.length);
    }
    
    setCountChildren(arrayCountChildren);
  };

  const handleClickDeleteCarga = (prompt: IClickDeleteCarga) => {
    const { index } = prompt;
    const arrayCountChildren: ICountCargas[] = [...countChildren];
    arrayCountChildren.splice(index, 1);
    setCountChildren(arrayCountChildren);
  };

  return (
    <>
      <div className="content-subtitle">
        <div>
          <p className="subtitle">REFERIR</p>
        </div>
        <span className="linea"></span>
      </div>
      <div className="container-input">
        <div className="content-input input-number">
          <InputNumberCustomized
            layer="Nro de Referidos"
            placeholder="0"
            maxNumber={limit}
            onChange={handleChangeCountCargas}
            defaultValue={countChildren.length}
          />
        </div>
        {countChildren.map((item: any, index: number) => (
          <>

            <div className="content-input">
            <div className="content-text-modal-update w-47">
              <InputTextCustomized
                layer="Nombre"
                placeholder="Ingrese"
                required={false}
                errorPosition="down"
                onValueChange={setCountChildren}
                onPermaValues={countChildren}
                onPropertyChange={[index, `nombreCompleto`]}
                defaultValue={item.nombreCompleto.value ?? ""}
                requerido={item.nombreCompleto.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update end w-47">
              <InputPhoneCustomized
                layer="Celular"
                placeholder="56 9 1234 5678"
                required={false}
                maxLength={14}
                errorPosition="down"
                onValueChange={setCountChildren}
                onPermaValues={countChildren}
                onPropertyChange={[index, `fono`]}
                defaultValue={item.fono.value ?? ""}
                requerido={item.fono.value ? false : true}
                datoRequerido={requerido}
              />
            </div>
            <div className="content-text-modal-update end w-cinco">
              <button className="button-delete" onClick={() => handleClickDeleteCarga({index})}>
                <CloseIcon />
              </button>
            </div>
          </div>
          </>
        ))}
      </div>
    </>
  );
};
