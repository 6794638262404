import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import SeccionAccion from "./SeccionAccion";
import { useParams } from "react-router-dom";
import {
  IClientes,
  IMenuProfile,
  IToken,
  Params,
} from "../../../../interfaces/IValidationKam";
import { FILTER_LIST, FILTER_PROFILE } from "../../../../diccionary/Diccionary";
import TablaCandidatoPendienteContrato from "./CandidatoPendienteContrato/TablaCandidatoPendienteContrato";
import TablaCandidatoValidado from "./CandidatosValidados/TablaCandidatoValidado";
import SeccionActionBlock from "./SeccionActionBlock";
import TablaCandidatoEnviadoContrato from "./CandidatoPendienteContrato/TablaCandidatoEnviadoContrato";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";

const SistemaContratacion = (props: IToken) => {
  const { token } = props;
  const { getExclusiones, exclusiones, toasts, countAssign, getCounts } =
    useContext(ValidationKamContext);
    const { toasts:toastsSeleccion } =
    useContext(SeleccionContext);
  const { getListaGeneral, listaGeneral } = useContext(SeleccionContext);
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  let { idCliente } = useParams<Params>();

  useEffect(() => {
    if (token) {
      getListaGeneral({ token });
      getExclusiones({ token });
      getCounts({ token });
    }
  }, [token]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  return (
    <>
      {toasts} {toastsSeleccion}
      <SeccionAccion />
      <TablaCandidatoPendienteContrato />
      <SeccionActionBlock />
      <TablaCandidatoEnviadoContrato />
      {/* <TablaCandidatoValidado /> */}
    </>
  );
};

export default SistemaContratacion;
