interface IIconCheck {
  width?: string;
  height?: string;
  fill: string;
  disabled?: boolean;
  className?: string;
}

export default (props: IIconCheck) => {
  const { disabled, fill, className } = props;

  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill={disabled ? "#aeaeae" : fill}
      className={className}
    >
      <path
        id="Vector"
        d="M10.9992 0.811523C11.5817 0.811523 12.119 1.11916 12.4144 1.62369L21.2744 16.7185C21.5738 17.2272 21.5738 17.8547 21.2826 18.3634C20.9914 18.872 20.4458 19.1878 19.8592 19.1878H2.13923C1.55266 19.1878 1.00712 18.872 0.715884 18.3634C0.424653 17.8547 0.428754 17.2231 0.724088 16.7185L9.5841 1.62369C9.87943 1.11916 10.4168 0.811523 10.9992 0.811523ZM10.9992 6.0619C10.4537 6.0619 10.0148 6.5008 10.0148 7.04635V11.6404C10.0148 12.186 10.4537 12.6249 10.9992 12.6249C11.5448 12.6249 11.9837 12.186 11.9837 11.6404V7.04635C11.9837 6.5008 11.5448 6.0619 10.9992 6.0619ZM12.3118 15.2501C12.3118 14.9019 12.1735 14.5681 11.9274 14.3219C11.6812 14.0758 11.3474 13.9375 10.9992 13.9375C10.6511 13.9375 10.3173 14.0758 10.0711 14.3219C9.82493 14.5681 9.68664 14.9019 9.68664 15.2501C9.68664 15.5982 9.82493 15.932 10.0711 16.1782C10.3173 16.4244 10.6511 16.5627 10.9992 16.5627C11.3474 16.5627 11.6812 16.4244 11.9274 16.1782C12.1735 15.932 12.3118 15.5982 12.3118 15.2501Z"
      />
    </svg>
  );
};
