import { useContext, useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ModalFiltro from "./Modals/ModalFiltro";
import { Zoom } from "@mui/material";
import IconFilter from "../../../ui/kit-svg/IconFilter";
import IconFilterOpen from "../../../ui/kit-svg/IconFilterOpen";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import { TYPE_LIST } from "../../../../diccionary/Diccionary";
import ModalConfSolicitud from "./Modals/ModalConfSolicitud";
import ModalCausal from "./Modals/ModalCausal";
import ModalSalir from "../../Campaign/ModalSalir";
import { Params } from "../../../../interfaces/IValidationKam";
import { useParams } from "react-router-dom";
import ModalDetener from "./Modals/ModalDetener";
import ModalDevolverNew from "../../Operaciones/Modals/ModalDevolverNew";
import SeleccionContext from "../../../../context/Seleccion/SeleccionContext";

const SeccionAccion = () => {
  const { idCliente } = useParams<Params>();
  const {
    token,
    setToken,
    getToken,
    disableButttonAction,
    listCandidatoPendienteContrato,
    BootstrapTooltip,
    getRegistroContrato,
    getSolicitudContrato,
    restaurarCandidato,
  } = useContext(ValidationKamContext);

 // const { token, getToken, setToken } =  useContext(SeleccionContext);

  const [filterType, setFilterType] = useState<boolean>(false);
  const [openModalContratacion, setOpenModalContratacion] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);
  const [openModalCausal, setOpenModalCausal] = useState<boolean>(false);
  const [openModalSalir, setOpenModalSalir] = useState<boolean>(false);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listCandidatoPendienteContrato.length > 0 ? true : false
  );
  const [objsendContrato, setObjsendContrato] = useState<any>();
  const [listBusperSolicitudContrato, setListBusperSolicitudContrato] =
    useState<any>();
  const objetoSendFiltro = {};
  const [openModalFueraDePlazo, setOpenModalFueraDePlazo] =
    useState<boolean>(false);
  const [hayFueraPlazo, setHayFueraplazo] = useState<boolean>(false);
  const [listFueraPlazoModal, setListFueraPlazoModal] = useState<any>([]);
  const [listaSeleccionados, setListaSeleccionados] = useState<any>([]);
  const [listaBusPeIdRestaurar, setListaBusPeIdRestaurar] = useState<any>([]);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);


  useEffect(() => {
    getToken();
}, []);

  useEffect(() => {
    setButtonEnabled(listCandidatoPendienteContrato.length > 0 ? true : false);
  }, [listCandidatoPendienteContrato]);

  useEffect(() => {
    setFilterType(openModalFiltro);
  }, [openModalFiltro]);

  useEffect(() => {
    setListFueraPlazoModal([]);
    listCandidatoPendienteContrato.map((item: any) =>
      listFueraPlazo(item, objsendContrato)
    );
    
  }, [objsendContrato]);

  const handleClickContratacion = () => {
    setOpenModalContratacion(true);
  };

  const handleClickModalFilterClose = () => {
    setFilterType(false);
    setOpenModalFiltro(false);
  };

  /*const handleClickRestaurarGo = () => {
    restaurarCandidato({
      listaBusPeIdRestaurar,
      idCliente,
      token,
      objetoSendFiltro,
    });
    setOpenModalRestaurar(false);
  };*/

  const handleClickCausal = () => {
    setListFueraPlazoModal([]);
    setHayFueraplazo(false);
  /*  listCandidatoPendienteContrato.map((item: any) =>
      listFueraPlazo(item, objsendContrato)
    );*/
    //**
    setOpenModalCausal(true);
  };

  const handleClickRegistroContrato = () => {
    getRegistroContrato({
      objsendContrato,
      token,
      idCliente,
      objetoSendFiltro,
    });
  };

  const listFueraPlazo = (item: any, objSendCt: any) => {
    let selectedDateInicio15: number | undefined;
    let selectedDateInicio30: number | undefined;

    if (item.fechaInicio || objSendCt?.fechaInicio?.value?.length > 1) {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      let fechaInicioContrato;
      if (objSendCt?.fechaInicio?.value?.length > 1) {
        const splitDate = objSendCt.fechaInicio.value.split("/");
        fechaInicioContrato = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      } else {
        fechaInicioContrato = item.fechaInicio;
      }

      const [yearInicio, monthInicio, dayInicio] =
        fechaInicioContrato.split("-");
      selectedDateInicio15 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) - 15
      ).setHours(0, 0, 0, 0);

      selectedDateInicio30 = new Date(
        Number(yearInicio),
        Number(monthInicio) - 1,
        Number(dayInicio) + 30
      ).setHours(0, 0, 0, 0);

      if (
        !(
          selectedDateInicio15 <= currentDate &&
          currentDate <= selectedDateInicio30
        )
      ) {
        setHayFueraplazo(true);
        //setListFueraPlazoModal([])
        setListFueraPlazoModal((prevList: any) => [...prevList, item]);
      }
    }
  };
  
  const handleClickSolicitaContrato = () => {

    getRegistroContrato({
      objsendContrato,
      token,
      idCliente,
      objetoSendFiltro,
    });
    if (hayFueraPlazo && listFueraPlazoModal.length > 0) {
      // setListFueraPlazoModal(listFueraPlazoModal);
      setOpenModalFueraDePlazo(true);
    } else {
      getSolicitudContrato({
        token,
        idCliente,
        busPerId: objsendContrato.candidatos,
        objetoSendFiltro,
      });
      setOpenModalCausal(false);
    }
  };

  const handleClickModalRestaurarMa = () => {
    const currentRest = [...listCandidatoPendienteContrato];
    setListaSeleccionados(currentRest);
    setOpenModalRestaurar(true);
  };
  return (
    <>
      <div className="content-actions contrato">
         <div className="content-enviar ">
          <button
            className={`navigation ${
              listCandidatoPendienteContrato.length > 0  ? "active-red2"
                : "disabled"
            }`}
            onClick={() => handleClickModalRestaurarMa()}
            disabled={
              listCandidatoPendienteContrato == ""
                ? true
                : false
            }
          >
            Devolver Candidato
           
          </button>
        </div>
        <div className="content-enviar ">
          <button
            className={`navigation ${
              listCandidatoPendienteContrato.length > 0 ? "active-green"
              : "disabled"/*some(
                (l: any) =>
                  l.estadoRegistro !== "COMPLETO" &&
                  l.estadoRegistro !== "DETENIDA"
              ) && buttonEnabled
                ? "disabled"
                : !buttonEnabled
                ? "disabled"
                : "active-green"*/
            }`}
            onClick={() => handleClickContratacion()}
            disabled={
            /*  (listCandidatoPendienteContrato.some(
                (l: any) =>
                  l.estadoRegistro !== "COMPLETO" &&
                  l.estadoRegistro !== "DETENIDA"
              ) &&
                buttonEnabled) ||*/
              listCandidatoPendienteContrato == ""
                ? true
                : false
            }
          >
            Solicitar Contrato
            <ArrowForwardIcon />
          </button>
        </div>

        <div className="content-actions-masive">
        {/*  <BootstrapTooltip
            title="Restaurar Candidato"
            TransitionComponent={Zoom}
            placement="bottom-end"
          >
            <button
              className={`button-actions ${
                disableButttonAction || buttonEnabled
                  ? "active-restore "
                  : "disabled"
              }`}
              onClick={() => handleClickModalRestaurarMa()}
              disabled={disableButttonAction || buttonEnabled ? false : true}
            >
              <RefreshRoundedIcon style={{ transform: "rotateY(180deg)" }} />
            </button>
          </BootstrapTooltip>*/}

          <BootstrapTooltip
            title="Datos Contrato"
            TransitionComponent={Zoom}
            placement="top-end"
          >
            <button
              className={`button-actions ${
                disableButttonAction || buttonEnabled
                  ? "active-datos"
                  : "disabled"
              }`}
              disabled={disableButttonAction || buttonEnabled ? false : true}
              onClick={() => handleClickCausal()}
            >
              <TextSnippetIcon />
            </button>
          </BootstrapTooltip>

          <button
            className={`button-actions ${"active-filter"}`}
            onClick={() => {
              setOpenModalFiltro(true);
              setFilterType(openModalFiltro);
            }}
          >
            {filterType ? <IconFilterOpen /> : <IconFilter />}
          </button>
        </div>
      </div>

      {openModalContratacion && (
        <ModalConfSolicitud
          modalStyle="confirm"
          modalType="contratacion"
          title="CONFIRMACIÓN SOLICITUD CONTRATO"
          subTitle="Estás por enviar al área de contrato la siguiente selección: "
          message={[listCandidatoPendienteContrato]}
          onChangeBack={() => setOpenModalContratacion(false)}
          onChangeGo={() => setOpenModalContratacion(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
        />
      )}

      {openModalFiltro && (
        <ModalFiltro
          title="Filtro"
          buttonGoEvent={handleClickModalFilterClose}
          token={token}
          tipo={"pendiente_contrato"}
          openModalFiltro={openModalFiltro}
        />
      )}
        {openModalRestaurar && (
        <ModalDevolverNew
          modalStyle="confirm"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="DEVOLVER CANDIDATO"
          subTitle="Estás por devolver a reclutamiento a la siguiente selección, seleccione el motivo: "
          list={listaSeleccionados}
          onChangeBack={() => setOpenModalRestaurar(false)}
          //onChangeGo={() => handleClickRestaurarGo()}
          buttonBack="Cancelar"
          buttonGo="Aceptar"
          
        />
      )}

      {openModalCausal && (
        <ModalCausal
          modalStyle="confirm"
          modalType="contratacion"
          title="CAUSAL"
          subTitle=" "
          setObjsendContrato={setObjsendContrato}
          setListBusperSolicitudContrato={setListBusperSolicitudContrato}
          personas={listCandidatoPendienteContrato}
          onChangeBack={() => setOpenModalSalir(true)} //setOpenModalCausal(false)
          onChangeGuarda={() => {
            handleClickRegistroContrato(),
              setOpenModalCausal(false),
              localStorage.removeItem("DatosContrato");
          }}
          onChangeGo={() => {
            handleClickSolicitaContrato(),
              localStorage.removeItem("DatosContrato");
          }}
          buttonGuarda="Guardar"
          buttonGo="Solicitar Contrato"
        />
      )}
      {openModalSalir && (
        <ModalSalir
          modalStyle="salir"
          title="¿Está seguro qué desea salir de la página actual?"
          subTitle="Se perderán los cambios no guardados"
          onChangeBack={() => setOpenModalSalir(false)}
          onChangeGo={() => {
            setOpenModalCausal(false);
            setOpenModalSalir(false);
          }}
          buttonBack="Cancelar"
          buttonGo="Salir"
        />
      )}

      {openModalFueraDePlazo && (
        <ModalDetener
          modalType="FueraPlazo"
          title="SOLICITUD FUERA DE PLAZO"
          subTitle="La selección contiene la(s) siguiente(s) solicitud(es) fuera de plazo y requieren aprobación:"
          buttonBack="Volver"
          onChangeBack={() => {
            setOpenModalFueraDePlazo(false), setOpenModalCausal(false);
          }}
          modalStyle={""}
          list={listFueraPlazoModal}
        />
      )}
    </>
  );
};

export default SeccionAccion;
