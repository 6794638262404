interface IIconEdit {
  width?: number;
  height?: number;
  fill?: string;
  style?: any;
  color?: any;  
  className?: any;
}

export default (props: IIconEdit) => {
  const { width, height, fill, style, className, color } = props;

  return (
    <svg
      width={`${width ? width : "45"}`}
      height={`${height ? height : "46"}`}
      viewBox="0 0 38 38"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="18.125" className=""/>
      <path
        d="M10.9213 29.875C10.3586 29.875 9.87666 29.6745 9.47558 29.2734C9.07519 28.873 8.875 28.3914 8.875 27.8287V13.5047C8.875 12.942 9.07519 12.4601 9.47558 12.059C9.87666 11.6586 10.3586 11.4584 10.9213 11.4584H20.0528L18.0065 13.5047H10.9213V27.8287H25.2453V20.7179L27.2916 18.6716V27.8287C27.2916 28.3914 27.0914 28.873 26.691 29.2734C26.2899 29.6745 25.808 29.875 25.2453 29.875H10.9213ZM22.3549 12.0467L23.8129 13.4791L17.0601 20.2319V21.6899H18.4925L25.2709 14.9115L26.7288 16.3439L19.3622 23.7361H15.0139V19.3878L22.3549 12.0467ZM26.7288 16.3439L22.3549 12.0467L24.9128 9.48889C25.322 9.07963 25.8124 8.875 26.384 8.875C26.955 8.875 27.4365 9.07963 27.8287 9.48889L29.2611 10.9469C29.6533 11.3391 29.8494 11.8165 29.8494 12.3793C29.8494 12.942 29.6533 13.4195 29.2611 13.8117L26.7288 16.3439Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
    </svg>
  );
};
