import { useContext, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputTextCustomized from "../../../../../ui/kit-mdtw/InputTextCustomized";
import InputRutCustomized from "../../../../../ui/kit-mdtw/InputRutCustomized";
import InputDateCustomized from "../../../../../ui/kit-mdtw/InputDateCustomized";
import DropDownListCustomized from "../../../../../ui/kit-mdtw/DropDownListCustomized";
import LayerAgeCustomized from "../../../../../ui/kit-mdtw/LayerAgeCustomized";
import Hijos from "../../../../../ui/kit-mdtw/modals/modalUpdateCandidate/componentes/Hijos";
import InputNumberCustomized from "../../../../../ui/kit-mdtw/InputNumberCustomized";
import InputEdadHijoCustomized from "../../../../../ui/kit-mdtw/InputEdadHijoCustomized";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";
import { IOptionDropDownList } from "../../../../../../interfaces/IDropDownList";
import { InputTextarea } from "primereact/inputtextarea";
import IconButtonAdd from "../../../../../ui/Icon-Psicolaboral/IconButtonAdd";
import DropDownListMultipleCustomizedFilter from "../../../../../ui/kit-mdtw/DropDownListMultipleCustomizedFilter";
import DropDownListMultipleCustomized from "../../../../../ui/kit-mdtw/DropDownListMultipleCustomized";
import RadarChart from "./RadarChart";

interface ICompetencias {
  list?: any[];
  rut?: string;
  formCandidate?: any;
  setFormCandidate?: Function;
  requerido?: boolean;
  contraerModal?: boolean;
  token?: string;
  setObjsendPsicolaboralEvaluacion?: any;
}

interface ButtonState {
  value: number;
  buttonX: boolean;
}

export default (props: ICompetencias) => {
  const {
    list,
    rut,
    formCandidate,
    setFormCandidate,
    requerido,
    contraerModal,
    token,
    setObjsendPsicolaboralEvaluacion,
  } = props;

  const {
    getEvaluacionPsicolaboral,
    listPerfiles,
    setListPerfiles,
    listEvaluacionPsicolaboral,
    getListasPerfil,
    listConclusiones,
    infoAntecedentes,
    getListaCompetenciaLaboral,
    listaCompetenciasLaborales,
  } = useContext(SeleccionContext);

  const [hidden, setHidden] = useState<boolean>(false);
  const [errorWord, setErrorWord] = useState<boolean>(false);
  const [errorWordOtro, setErrorWordOtro] = useState<boolean>(false);
  const [errorWordInAdicional, setErrorWordInAdicional] =
    useState<boolean>(false);
  const [buttonX, setButtonX] = useState<boolean>(false);
  const [selectDataCompetencias, setSelectDataCompetencias] = useState<any>({
    perfil: { code: "", name: "" },
    experiencia: { code: undefined, name: undefined },
    educacionEsperada: { code: undefined, name: undefined },
    descripcionGeneral: { value: undefined },
    competenciaAdicional: { value: undefined },
    competenciaAdicionalinCopmSolicitada: { value: undefined },
    competenciaSolicitada: { code: undefined, name: undefined },
  });

  const [inputObjetos, setInputObjetos] = useState([""]);
  const [arrayCompetenciaAdicional, setArrayCompetenciaAdicional] = useState<
    any[]
  >([]);
  const [arrayCompetenciaSolicitada, setArrayCompetenciaSolicitada] = useState<
    any[]
  >([]);

  const [
    arrayCompetenciaSolicitadaSelect,
    setArrayCompetenciaSolicitadaSelect,
  ] = useState<any[]>([]);

  const dataExperiencia = [
    {
      code: "true",
      name: "Si",
    },
    {
      code: "false",
      name: "No",
    },
  ];

  const dataCompetenciaSolicitada =
    listaCompetenciasLaborales !== undefined &&
    (listaCompetenciasLaborales.map((item: any) => ({
      code: item.competenciaLaboralId,
      name: item.nombreCompetencia,
    })) ??
      []);

  const filtrarCompPorId = (id: any) => {
    return (
      listaCompetenciasLaborales.find(
        (item: any) =>
          item.competenciaLaboralId.toUpperCase() == id.toUpperCase()
      )?.nombreCompetencia ?? id
    );
  };

  /*const listaCompetenciasLaboralesFilter =
  listaCompetenciasLaborales !== undefined && (
    listaCompetenciasLaborales.filter((l:any) => (l.nombreCompetencia).toUpperCase() != "OTRO") ?? []);*/

  const [buttonValues, setButtonValues] = useState<{
    [key: string]: { skill: any; value: any; buttonX: boolean };
  }>({});

  const [buttonValuesCompAdicional, setButtonValuesCompAdicional] = useState<{
    [key: string]: { skill: any; value: any; buttonX: boolean };
  }>({});

  const [buttonValuesCopmEsperadas, setButtonValuesCopmEsperadas] = useState<{
    [key: string]: { skill: any; value: any; buttonX: boolean };
  }>({});

  //const arrayCompetenciaAdicional: any[]=[]
  interface ButtonState {
    value: number;
    buttonX: boolean;
    skill: string;
  }

  useEffect(() => {
    /* const perfil =
      selectDataCompetencias?.perfil?.value &&
      selectDataCompetencias?.perfil?.value?.code;
    if (
      selectDataCompetencias?.perfil?.value &&
      selectDataCompetencias?.perfil?.value?.code !== undefined
    ) {
      getEvaluacionPsicolaboral({ token, perfil });
      getListasPerfil({ token });
    }*/
    if (token) {
      getEvaluacionPsicolaboral({ token /*, perfil*/ });
      getListasPerfil({ token });

      /*listEvaluacionPsicolaboral.competencias !== undefined && (
      setArrayCompetenciasOriginal(
      listEvaluacionPsicolaboral?.competencias.map((item: any) => ({
        code: item.competenciaLaboralId,
        name: item.nombre,
      })) ?? []));
      const newArray = arr.concat(primerArray)*/
    }
  }, [selectDataCompetencias]);

  useEffect(() => {
    if (token) {
      getListaCompetenciaLaboral({ token }), listaCompetenciasLaborales;
    }
  }, []);

  useEffect(() => {
    if (
      selectDataCompetencias?.competenciaAdicionalinCopmSolicitada?.value !==
      undefined
    ) {
      (selectDataCompetencias?.competenciaAdicionalinCopmSolicitada?.value)
        .toUpperCase()
        .trim() === "OTRO"
        ? setErrorWordOtro(true)
        : setErrorWordOtro(false);
    }

    if (
      selectDataCompetencias?.competenciaAdicionalinCopmSolicitada?.value !==
      undefined
    ) {
      !arrayCompetenciaSolicitada.find(
        (s) =>
          removeAccents(s.name).toUpperCase().trim() ==
          removeAccents(
            selectDataCompetencias?.competenciaAdicionalinCopmSolicitada?.value
          )
            .toUpperCase()
            .trim()
      )
        ? setErrorWord(false)
        : setErrorWord(true);
    }

    if (selectDataCompetencias?.competenciaAdicional?.value !== undefined) {
      !arrayCompetenciaAdicional.find(
        (s) =>
          removeAccents(s.name).toUpperCase().trim() ==
          removeAccents(selectDataCompetencias?.competenciaAdicional?.value)
            .toUpperCase()
            .trim()
      )
        ? setErrorWordInAdicional(false)
        : setErrorWordInAdicional(true);
    }
  }, [selectDataCompetencias]);

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const objetoSendInfoEvaluacion = useMemo(() => {
    Object.values(buttonValuesCopmEsperadas).map((item: any) =>
      filtrarCompPorId(item.skill)
    );

    return {
      perfilEvaluacionId: selectDataCompetencias?.perfil?.value?.code ?? "",
      experienciaSimilar:
        selectDataCompetencias?.experiencia?.value?.code !== undefined
          ? JSON.parse(selectDataCompetencias?.experiencia?.value?.code)
          : "",
      educacionEsperada:
        selectDataCompetencias?.educacionEsperada?.value?.code !== undefined
          ? JSON.parse(selectDataCompetencias?.educacionEsperada?.value?.code)
          : "",
      descripcionEvaluacion: inputObjetos[0] ?? "",
      resultados: Object.values(buttonValues).map((item: any) => ({
        evaluacionCompetenciaId: item.skill ?? "",
        categoriaEvaluacionId: item.value ?? "",
      })),
      resultadoCompAdicional: Object.values(buttonValuesCompAdicional).map(
        (item: any) => ({
          evaluacionCompetenciaId: item.skill ?? "",
          categoriaEvaluacionId: item.value ?? "",
        })
      ),
      resultadosEsperados: Object.values(buttonValuesCopmEsperadas).map(
        (item: any) => ({
          evaluacionCompetenciaId: filtrarCompPorId(item.skill) ?? "",
          categoriaEvaluacionId: item.value ?? "",
        })
      ),
    };
  }, [
    selectDataCompetencias,
    buttonValues,
    buttonValuesCompAdicional,
    buttonValuesCopmEsperadas,
    inputObjetos,
  ]);

  useEffect(() => {
    if (setObjsendPsicolaboralEvaluacion && selectDataCompetencias) {
      setObjsendPsicolaboralEvaluacion({ ...objetoSendInfoEvaluacion });
    }
  }, [objetoSendInfoEvaluacion]);

  useEffect(() => {
    if (
      selectDataCompetencias.competenciaSolicitada &&
      selectDataCompetencias.competenciaSolicitada.value
    ) {
      const newValue = selectDataCompetencias?.competenciaSolicitada?.value;

      if (newValue) {
        let arrayNew = [...arrayCompetenciaSolicitada];
        newValue.forEach((item: any) => {
          if (
            !arrayCompetenciaSolicitada.find((s) => s.code == item.code) &&
            item.name.toUpperCase() != "OTRO"
          ) {
            arrayNew.push(item);
          }
        });
        setArrayCompetenciaSolicitada(arrayNew);
      }
    }
  }, [selectDataCompetencias.competenciaSolicitada]);

  useEffect(() => {
    if (infoAntecedentes && infoAntecedentes.evaluacion) {
      const arrSolicitada = infoAntecedentes?.competenciasEsperadas.map(
        (item: any) => ({
          code: item.competenciaLaboralId /*item.categoriaEvaluacionId*/,
          name: item.competenciaLaboral,
        })
      );

      setSelectDataCompetencias({
        perfil: {
          value: {
            code: infoAntecedentes.evaluacion.perfilEvaluacionId,
            name: infoAntecedentes.evaluacion.perfilEvaluacion,
          },
        },
        experiencia: {
          value: {
            code: infoAntecedentes.evaluacion.experienciaSimilar,
            name:
              infoAntecedentes.evaluacion.experienciaSimilar === true
                ? "Si"
                : "No",
          },
        },
        educacionEsperada: {
          value: {
            code: infoAntecedentes.evaluacion.educacionEsperada,
            name: infoAntecedentes.evaluacion.educacionEsperada ? "Si" : "No",
          },
        },
        descripcionGeneral: {
          value:
            infoAntecedentes?.evaluacion?.descripcionEvaluacion !== null
              ? infoAntecedentes?.evaluacion?.descripcionEvaluacion
              : "-",
        },
        competenciaSolicitada: {
          value: arrSolicitada,
          /* value: {
          code: arrSolicitada.map((item: any) => item.code),
          
          name: dataCompetenciaSolicitada.find(
            (item: any) =>
            arrSolicitada.code === item.code
          )?.name,
        },*/
        },
      });

      //console.log("compSolicitada: ", selectDataCompetencias, "arrSolicitada: ", arrSolicitada, listEvaluacionPsicolaboral?.competencias)
      setButtonValues(
        infoAntecedentes.evaluacion?.resultados.reduce(
          (acc: any, item: any) => ({
            ...acc,
            [item.competenciaLaboralId]: {
              skill: item.competenciaLaboralId,
              value: item.categoriaEvaluacionId,
              buttonX: true,
            },
          }),
          {}
        )
      );

      setButtonValuesCopmEsperadas(
        infoAntecedentes?.competenciasEsperadas.reduce(
          (acc: any, item: any) => ({
            ...acc,
            [item.competenciaLaboral]: {
              skill: item.competenciaLaboral,
              value: item.categoriaEvaluacionId,
              buttonX: true,
            },
          }),
          {}
        )
      );

      setButtonValuesCompAdicional(
        infoAntecedentes?.competenciasAdicionales.reduce(
          (acc: any, item: any) => ({
            ...acc,
            [item.nombre]: {
              skill: item.nombre,
              value: item.categoriaEvaluacionId,
              buttonX: true,
            },
          }),
          {}
        )
      );

      const arrAdicional = infoAntecedentes?.competenciasAdicionales.map(
        (item: any) => ({ code: item.competenciaLaboralId, name: item.nombre })
      );
      setArrayCompetenciaAdicional(arrAdicional);

      //const arrSolicitada = infoAntecedentes?.competenciasEsperadas.map((item:any) => ({ code: item.competenciaLaboralId/*item.categoriaEvaluacionId*/, name: item.competenciaLaboral}));
      setArrayCompetenciaSolicitada(arrSolicitada);
    }
  }, [infoAntecedentes]);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  const handleButtonClick = (skill: string, value: any) => {
    const newButtonValues = { ...buttonValues };

    if (newButtonValues[skill]?.value === value) {
      delete newButtonValues[skill];
    } else {
      newButtonValues[skill] = { skill, value, buttonX: true };
    }

    setButtonValues(newButtonValues);
  };

  const handleButtonClickCopmAdicional = (skill: string, value: any) => {
    const newButtonValues = { ...buttonValuesCompAdicional };

    if (newButtonValues[skill]?.value === value) {
      delete newButtonValues[skill];
    } else {
      newButtonValues[skill] = { skill, value, buttonX: true };
    }

    setButtonValuesCompAdicional(newButtonValues);
  };

  const handleButtonClickEsperado = (skill: string, value: any) => {
    const newButtonValues = { ...buttonValuesCopmEsperadas };

    if (newButtonValues[skill]?.value === value) {
      delete newButtonValues[skill];
    } else {
      newButtonValues[skill] = { skill, value, buttonX: true };
    }

    setButtonValuesCopmEsperadas(newButtonValues);
  };
  const listaNumeroCategoria = listEvaluacionPsicolaboral?.categorias
    ? listEvaluacionPsicolaboral?.categorias?.map(
        (item: any) => item.categoriaEvaluacionId
      )
    : [];

  const dataArrayTexArea = [
    {
      title: "Descripción general de evaluación por competencias:",
      required: false,
      key: "descripcionGeneral",
      maxLength: 300,
    },
  ];

  const handleChange = (index: number, value: string, maxLength: number) => {
    if (value.length <= maxLength) {
      const newInputObjetos = [...inputObjetos];
      newInputObjetos[index] = value; // Actualiza solo el valor en el índice
      setInputObjetos(newInputObjetos);
    }
  };

  const handleIconButtonAddClick = () => {
    const nuevaCompetencia = {
      code: selectDataCompetencias?.competenciaAdicional?.value + 1,
      name: selectDataCompetencias?.competenciaAdicional?.value,
    };

    if (
      nuevaCompetencia.name &&
      !arrayCompetenciaAdicional.find(
        (s) =>
          removeAccents(s.name).toUpperCase().trim() ==
          removeAccents(nuevaCompetencia.name).toUpperCase().trim()
      ) && !(listEvaluacionPsicolaboral?.competencias).find(
        (s: { nombre: string; }) =>
          removeAccents(s.nombre).toUpperCase().trim() ==
          removeAccents(nuevaCompetencia.name).toUpperCase().trim()
    ) ) {
      setErrorWordInAdicional(false);
      setArrayCompetenciaAdicional([
        ...arrayCompetenciaAdicional,
        nuevaCompetencia,
      ]);
    } else {
      setErrorWordInAdicional(true);
    }
  };

  const handleIconButtonAddAdicionalClick = () => {
    const compAdic =
      selectDataCompetencias?.competenciaAdicionalinCopmSolicitada?.value;
    if (
      compAdic &&
      !arrayCompetenciaSolicitada.find(
        (s) =>
          removeAccents(s.name).toUpperCase().trim() ==
          removeAccents(compAdic).toUpperCase().trim()
      )
    ) {
      setErrorWord(false);
      setArrayCompetenciaSolicitada([
        ...arrayCompetenciaSolicitada,
        { code: compAdic, name: compAdic },
      ]);
    } else {
      setErrorWord(true);
    }
  };

  const capitalizeWords = (str: string) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  return (
    <>
      <section className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">EVALUACIÓN POR COMPETENCIAS</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </section>
      {hidden && <span className="linea-separador-titulo"></span>}

      {/*   <div className={`project-table pb-5 ${hidden ? "disabled" : ""}`}>
        <div
          className={`content-text-modal-update ${
            contraerModal ? " w-100" : "w-45"
          }`}
        >
          <DropDownListCustomized
            layer="Perfil"
            placeholder="Ingrese"
            required={true}
            errorPosition="down"
            onValueChange={setSelectDataCompetencias}
            onPermaValues={selectDataCompetencias}
            onPropertyChange="perfil"
            defaultValue={{
              code: selectDataCompetencias?.perfil?.value?.code,
              name: selectDataCompetencias?.perfil?.value?.name,
            }}
            datoRequerido={requerido}
            list={listPerfiles}
          />
        </div>
          </div>*/}
      <div
        className={`project-table experience pb-2 pt-3 pl-1 pr-1 ${
          hidden ? "disabled" : ""
        }`}
        style={{ display: contraerModal ? "flow" : hidden ? "none" : "flex" }}
      >
        <div
          className={`content-text-modal-update ${hidden ? "disabled" : ""} ${
            contraerModal ? " w-100 mr-3" : "w-35 mr-7"
          }`}
        >
          <div className="titleInput pb-2">
            Experiencia en cargos similares:
          </div>
          <DropDownListCustomized
            layer=""
            placeholder="Ingrese"
            required={false}
            errorPosition="down"
            onValueChange={setSelectDataCompetencias}
            onPermaValues={selectDataCompetencias}
            onPropertyChange="experiencia"
            defaultValue={{
              code: selectDataCompetencias?.experiencia.value?.code,
              name: selectDataCompetencias?.experiencia.value?.name,
            }}
            datoRequerido={requerido}
            list={dataExperiencia}
          />
        </div>
        <div
          className={`content-text-modal-update ${hidden ? "disabled" : ""} ${
            contraerModal ? " w-100" : "w-55 "
          }`}
        >
          <div className="titleInput pb-2">
            Educación esperada para el cargo:
          </div>
          <DropDownListCustomized
            layer=""
            placeholder="Ingrese"
            required={false}
            errorPosition="down"
            onValueChange={setSelectDataCompetencias}
            onPermaValues={selectDataCompetencias}
            onPropertyChange="educacionEsperada"
            defaultValue={{
              code: selectDataCompetencias?.educacionEsperada.value?.code,
              name: selectDataCompetencias?.educacionEsperada.value?.name,
            }}
            datoRequerido={requerido}
            list={dataExperiencia}
          />
        </div>
      </div>

      <div>
        {dataArrayTexArea.map((data, index) => (
          <div
            className={`project-table pb-3 pt-3 ${hidden ? "disabled" : ""}`}
            key={index}
          >
            <div className="col-12">
              <div className="titleInput">
                {data.title}
                {data.required ? " (*)" : ""}
              </div>
              <InputTextarea
                className="textInput"
                value={selectDataCompetencias[index]}
                onChange={(e) =>
                  handleChange(index, e.target.value, data.maxLength)
                }
                autoResize
                rows={2}
                cols={15}
                placeholder="Ingresa"
                maxLength={data.maxLength}
                required={data.required}
                defaultValue={selectDataCompetencias.descripcionGeneral.value}
              />
              <div style={{ marginTop: "8px", float: "right" }}>
                {inputObjetos[index] ? inputObjetos[index].length : 0}/
                {data.maxLength}
              </div>
            </div>
          </div>
        ))}
      </div>

      <section className={`project-table pb-2 ${hidden ? "disabled" : ""}`}>
        <div className="container-table ">
          <div className="content-table uno">
            <span className="title-table">PERFIL DE COMPETENCIAS</span>
            <span className="subtitle-tableSin">
              En el siguiente formato, se utilizan las escalas de competencias
              para el cargo evaluado el que ayudará a determinar:
            </span>
            <span className="subtitle-tableSin">
              - Ausencia o presencia de la competencia.
            </span>
            <span className="subtitle-tableSin">
              - Nivel en función de las conductas observadas durante el
              desarrollo de una entrevista de carácter Conductual.
            </span>
          </div>
        </div>
      </section>

      <section className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table ">
          <div className="content-table uno">
            <span className="title-table">
              CATEGORÍAS EVALUACIÓN COMPETENCIAS
            </span>
          </div>

          {listEvaluacionPsicolaboral?.categorias ? (
            listEvaluacionPsicolaboral?.categorias
              ?.slice()
              .reverse()
              .map((item: any) => (
                <div className={`content-table-row ${contraerModal}`}>
                  <span
                    className={`title-table-row ${contraerModal} ${
                      contraerModal ? "col-3" : "col-4"
                    }`}
                  >
                    {item.nombre}
                  </span>
                  <span
                    className={`${contraerModal ? "col-8" : "col-7"}`}
                    style={{ border: "1px solid #b0c1d5" }}
                  >
                    {item.descripcion ?? <>-</>}
                  </span>
                  <span
                    className={`title-table-row ${contraerModal} col-1`}
                    style={{ justifyContent: "center" }}
                  >
                    {item.valor ?? <>-</>}
                  </span>
                </div>
              ))
          ) : (
            <>
              <div className={`content-table-row ${contraerModal}`}>
                <span
                  className={`title-table-row ${contraerModal} ${
                    contraerModal ? "col-3" : "col-4"
                  }`}
                >
                  Excelente
                </span>
                <span
                  className={`${
                    contraerModal
                      ? "textInTableCategoria col-8"
                      : "textInTableCategoria col-7"
                  }`}
                  style={{ border: "1px solid #b0c1d5" }}
                >
                  {"Corresponde a un nivel experto de desarrollo de la competencia" ?? (
                    <>-</>
                  )}
                </span>
                <span
                  className={`title-table-row ${contraerModal} col-1`}
                  style={{ justifyContent: "center" }}
                >
                  {"5" ?? <>-</>}
                </span>
              </div>

              <div className={`content-table-row ${contraerModal}`}>
                <span
                  className={`title-table-row ${contraerModal} ${
                    contraerModal ? "col-3" : "col-4"
                  }`}
                >
                  Más que aceptable
                </span>
                <span
                  className={`${
                    contraerModal
                      ? "textInTableCategoria col-8"
                      : "textInTableCategoria col-7"
                  }`}
                  style={{ border: "1px solid #b0c1d5" }}
                >
                  {"Corresponde a un nivel de conducta superior de desarrollo de la competencia" ?? (
                    <>-</>
                  )}
                </span>
                <span
                  className={`title-table-row ${contraerModal} col-1`}
                  style={{ justifyContent: "center" }}
                >
                  {"4" ?? <>-</>}
                </span>
              </div>

              <div className={`content-table-row ${contraerModal}`}>
                <span
                  className={`title-table-row ${contraerModal} ${
                    contraerModal ? "col-3" : "col-4"
                  }`}
                >
                  Aceptable
                </span>
                <span
                  className={`${
                    contraerModal
                      ? "textInTableCategoria col-8"
                      : "textInTableCategoria col-7"
                  }`}
                  style={{ border: "1px solid #b0c1d5" }}
                >
                  {"Corresponde al nivel esperado de desarrollo de la competencia" ?? (
                    <>-</>
                  )}
                </span>
                <span
                  className={`title-table-row ${contraerModal} col-1`}
                  style={{ justifyContent: "center" }}
                >
                  {"3" ?? <>-</>}
                </span>
              </div>

              <div className={`content-table-row ${contraerModal}`}>
                <span
                  className={`title-table-row ${contraerModal} ${
                    contraerModal ? "col-3" : "col-4"
                  }`}
                >
                  Mínimo
                </span>
                <span
                  className={`${
                    contraerModal
                      ? "textInTableCategoria col-8"
                      : "textInTableCategoria col-7"
                  }`}
                  style={{ border: "1px solid #b0c1d5" }}
                >
                  {"Demuestra las conductas mínimas de la competencia" ?? (
                    <>-</>
                  )}
                </span>
                <span
                  className={`title-table-row ${contraerModal} col-1`}
                  style={{ justifyContent: "center" }}
                >
                  {"2" ?? <>-</>}
                </span>
              </div>

              <div className={`content-table-row ${contraerModal}`}>
                <span
                  className={`title-table-row ${contraerModal} ${
                    contraerModal ? "col-3" : "col-4"
                  }`}
                >
                  No Observable
                </span>
                <span
                  className={`${
                    contraerModal
                      ? "textInTableCategoria col-8"
                      : "textInTableCategoria col-7"
                  }`}
                  style={{ border: "1px solid #b0c1d5" }}
                >
                  {"Aún no ha desarrollado las conductas presentes en la competencia" ?? (
                    <>-</>
                  )}
                </span>
                <span
                  className={`title-table-row ${contraerModal} col-1`}
                  style={{ justifyContent: "center" }}
                >
                  {"1" ?? <>-</>}
                </span>
              </div>
            </>
          )}
        </div>
      </section>

      <div>
        {listEvaluacionPsicolaboral?.competencias !== undefined ? (
          <div className="competencias2">
            <div className="md:col-6 col-12 p-0 ">
              <div
                className={`custom-component pt-2 ${hidden ? "disabled" : ""}`}
              >
                <div className="col9">
                  <div className="col-content">Competencias laborales</div>
                </div>
                <div className="col3">
                  <button className="custom-button"> 1</button>
                  <button className="custom-button"> 2</button>
                  <button className="custom-button"> 3</button>
                  <button className="custom-button"> 4</button>
                  <button className="custom-button"> 5</button>
                </div>
              </div>
              {listEvaluacionPsicolaboral?.competencias
                .slice(
                  0,
                  Math.ceil(listEvaluacionPsicolaboral?.competencias.length / 2)
                )
                .map((item: any) => (
                  <div
                    className={`custom-component ${hidden ? "disabled" : ""}`}
                    key={item.competenciaLaboralId}
                  >
                    <div className="col9B">
                      <div className="col-content">{item.nombre}</div>
                    </div>
                    <div className="col3B">
                      {listaNumeroCategoria.map((value: any) => {
                        const skill = item.competenciaLaboralId; // Usamos el id de competencia como identificador
                        return (
                          <button
                            key={value}
                            className={`custom-buttonB ${
                              buttonValues[skill]?.value === value &&
                              buttonValues[skill]?.buttonX
                                ? "custom-buttonSelect"
                                : ""
                            }`}
                            onClick={() => handleButtonClick(skill, value)}
                          >
                            {buttonValues[skill]?.value === value &&
                            buttonValues[skill]?.buttonX
                              ? "X"
                              : ""}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                ))}
            </div>
            <div className="md:col-6 col-12 p-0 ">
              <div
                className={`custom-component pt-2 ${hidden ? "disabled" : ""}`}
              >
                <div className="col9">
                  <div className="col-content">Competencias laborales</div>
                </div>
                <div className="col3">
                  <button className="custom-button"> 1</button>
                  <button className="custom-button"> 2</button>
                  <button className="custom-button"> 3</button>
                  <button className="custom-button"> 4</button>
                  <button className="custom-button"> 5</button>
                </div>
              </div>
              {listEvaluacionPsicolaboral?.competencias
                .slice(
                  Math.ceil(listEvaluacionPsicolaboral?.competencias.length / 2)
                )
                .map((item: any) => (
                  <div
                    className={`custom-component ${hidden ? "disabled" : ""}`}
                    key={item.competenciaLaboralId}
                  >
                    <div className="col9B">
                      <div className="col-content">{item.nombre}</div>
                    </div>
                    <div className="col3B">
                      {listaNumeroCategoria.map((value: any) => {
                        const skill = item.competenciaLaboralId; // Usamos el id de competencia como identificador
                        return (
                          <button
                            key={value}
                            className={`custom-buttonB ${
                              buttonValues[skill]?.value === value &&
                              buttonValues[skill]?.buttonX
                                ? "custom-buttonSelect"
                                : ""
                            }`}
                            onClick={() => handleButtonClick(skill, value)}
                          >
                            {buttonValues[skill]?.value === value &&
                            buttonValues[skill]?.buttonX
                              ? "X"
                              : ""}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          "No Data"
        )}

        <div
          className={`project-table  pb-2 pt-3 pl-4 pr-1 ${
            hidden ? "disabled" : ""
          }`}
        >
          <div className="titleInput pb-1">Competencia Adicional:</div>
          <div
            className={`content-text-modal-update ${hidden ? "disabled" : ""} ${
              contraerModal ? " w-100" : "w-45 "
            }`}
            style={{ display: "flex", float: "left" }}
          >
            <InputTextCustomized
              layer=""
              placeholder="Ingrese"
              required={false}
              errorPosition="down"
              onValueChange={setSelectDataCompetencias}
              onPermaValues={selectDataCompetencias}
              onPropertyChange="competenciaAdicional"
              maxWord={60}
              defaultValue={
                selectDataCompetencias?.competenciaAdicional?.value ?? ""
              }
              //requerido={formCandidate.primerNombreCandidato?.value ? false : true}
            />
            <div className="butonForAdd" onClick={handleIconButtonAddClick}>
              <IconButtonAdd fill={"#0065DD"} />
            </div>
          </div>
          <span className="mesageDownRed">
            {errorWordInAdicional ? "Competencia ya esta seleccionada" : ""}
          </span>
        </div>

        <div className="md:col-6 col-12 p-0 ">
          {arrayCompetenciaAdicional.length > 0 ? (
            <div
              className={`custom-component pt-2 ${hidden ? "disabled" : ""}`}
            >
              <div className="col9">
                <div className="col-content">Competencias laborales</div>
              </div>
              <div className="col3">
                <button className="custom-button"> 1</button>
                <button className="custom-button"> 2</button>
                <button className="custom-button"> 3</button>
                <button className="custom-button"> 4</button>
                <button className="custom-button"> 5</button>
              </div>
            </div>
          ) : (
            ""
          )}

          {arrayCompetenciaAdicional.map((skill, index) => (
            <div
              className={`custom-component ${hidden ? "disabled" : ""}`}
              key={index}
            >
              <div className="col9B">
                <div className="col-content">{capitalizeWords(skill.name)}</div>
              </div>
              <div className="col3B">
                {listaNumeroCategoria.map((value: any) => {
                  return (
                    <button
                      key={value}
                      className={`custom-buttonB ${
                        buttonValuesCompAdicional[skill.name]?.value ===
                          value &&
                        buttonValuesCompAdicional[skill.name]?.buttonX
                          ? "custom-buttonSelect"
                          : ""
                      }`}
                      onClick={() =>
                        handleButtonClickCopmAdicional(skill.name, value)
                      }
                    >
                      {buttonValuesCompAdicional[skill.name]?.value === value &&
                      buttonValuesCompAdicional[skill.name]?.buttonX
                        ? "X"
                        : ""}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        <div className="project-table">
          <p className="subtitle pt-4">EVALUACIÓN COMPETENCIAS ESPERADAS</p>
          <span className="linea"></span>
        </div>

        <div
          className={`project-table experience pb-2 pt-3 pl-1 pr-1  ${
            hidden ? "disabled" : ""
          }`}
          style={{ display: contraerModal ? "flow" : hidden ? "none" : "flex" }}
        >
          <div
            className={`content-text-modal-update ${
              hidden ? "disabled" : ""
            } ${"md:col-6 col-12 pl-6 pr-4"}`}
          >
            <div className="titleInput pb-2">Competencia Solicitada:</div>
            <DropDownListMultipleCustomized
              layer=""
              placeholder="Ingrese"
              required={false}
              errorPosition="down"
              onValueChange={setSelectDataCompetencias}
              onPermaValues={selectDataCompetencias}
              onPropertyChange="competenciaSolicitada"
              defaultValues={
                selectDataCompetencias?.competenciaSolicitada?.value
                //   {
                //   code: selectDataCompetencias?.competenciaSolicitada?.value
                //     ?.code,
                //   name: selectDataCompetencias?.competenciaSolicitada?.value
                //     ?.name,
                // }
              }
              //datoRequerido={requerido}
              list={dataCompetenciaSolicitada}
            />
          </div>

          <div
            className={`content-text-modal-update ${
              hidden ? "disabled" : ""
            } ${"md:col-5 col-12 pl-3"}`}
          >
            <div className="titleInput pb-1">Competencia Adicional:</div>
            <InputTextCustomized
              layer=""
              placeholder="Ingrese"
              required={false}
              errorPosition="down"
              onValueChange={setSelectDataCompetencias}
              onPermaValues={selectDataCompetencias}
              onPropertyChange="competenciaAdicionalinCopmSolicitada"
              disabled={
                selectDataCompetencias?.competenciaSolicitada?.value?.some(
                  (item: { name: string }) => item.name === "OTRO"
                )
                  ? false
                  : true
              }
              defaultValue={" "}
              maxWord={60}
              //requerido={formCandidate.primerNombreCandidato?.value ? false : true}
            />
          </div>
          <span
            className="butonForAdd col-1"
            style={{ paddingTop: "25px" }}
            onClick={() => {
              if (!errorWord) {
                handleIconButtonAddAdicionalClick();
              }
            }}
          >
            <IconButtonAdd
              fill={
                selectDataCompetencias?.competenciaSolicitada?.value?.some(
                  (item: { name: string }) => item.name === "OTRO"
                )
                  ? "#0065DD"
                  : "#aeaeae"
              }
            />
          </span>
        </div>
        <span className="mesageDownRed">
          {errorWord
            ? "Competencia ya esta seleccionada"
            : errorWordOtro
            ? "Palabra reservada"
            : ""}
        </span>

        <div className="md:col-6 col-12 p-0 ">
          {arrayCompetenciaSolicitada.length > 0 ? (
            <div
              className={`custom-component pt-2 ${hidden ? "disabled" : ""}`}
            >
              <div className="col9">
                <div className="col-content">Competencias laborales</div>
              </div>
              <div className="col3">
                <button className="custom-button"> 1</button>
                <button className="custom-button"> 2</button>
                <button className="custom-button"> 3</button>
                <button className="custom-button"> 4</button>
                <button className="custom-button"> 5</button>
              </div>
            </div>
          ) : (
            ""
          )}

          {arrayCompetenciaSolicitada.map((skill, index) => (
            <div
              className={`custom-component ${hidden ? "disabled" : ""}`}
              key={index}
            >
              <div className="col9B">
                <div className="col-content">{capitalizeWords(skill.name)}</div>
              </div>
              <div className="col3B">
                {listaNumeroCategoria.map((value: any) => {
                  return (
                    <button
                      key={value}
                      className={`custom-buttonB ${
                        buttonValuesCopmEsperadas[skill.name]?.value ===
                          value &&
                        buttonValuesCopmEsperadas[skill.name]?.buttonX
                          ? "custom-buttonSelect"
                          : ""
                      }`}
                      onClick={() =>
                        handleButtonClickEsperado(skill.name, value)
                      }
                    >
                      {buttonValuesCopmEsperadas[skill.name]?.value === value &&
                      buttonValuesCopmEsperadas[skill.name]?.buttonX
                        ? "X"
                        : ""}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        {
          <RadarChart
            arrayCompetenciaAdicional={objetoSendInfoEvaluacion.resultados.concat(
              objetoSendInfoEvaluacion.resultadoCompAdicional
            )} //
            arrayCompetenciaSolicitada={
              objetoSendInfoEvaluacion.resultadosEsperados
            }
          />
        }
      </div>
    </>
  );
};
