import { useEffect, useState } from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { stylesConGraficoXl3 } from "../styles";
import logoTeamworkAzul from "../../../../assets/images/pdfPsicolaboral/logoTeamworkAzul.png";
import {
  IPDFCategoriasEvaluacion,
  IPDFCompetenciasEvaluada,
  IPDFPagePortada,
} from "../../../../interfaces/ISeleccion";
import { formatFirstLetterUpper } from "../../../../functions/kit-mdtw/texts";

const ConGraficoXl3 = (props: IPDFPagePortada) => {
  const { formList, grafico } = props;
  const [listRender, setListRender] = useState<any>({
    sintesis: "",
    experienciaRelevante: "",
    conocimientosTecnicos: "",
    fortalezas: "",
    oportunidades: "",
    conclusionInforme: "",
    reclutador: "",
    descripcionEvaluacion: "",
    categoriasEvaluacion: [],
    competenciasEvaluadas: [],
  });

  useEffect(() => {
    if (formList) {
      const { contenido } = formList ?? { contenido: {} };
      const { portada } = formList ?? { portada: {} };
      setListRender(Object.assign(listRender, contenido));
      setListRender(Object.assign(listRender, portada));
    }
  }, [formList]);

  return (
    <>
      {formList && Object.keys(formList).length > 0 && (
        <Page size="LETTER">
          <View style={stylesConGraficoXl3.body}>
            <Image
              src={logoTeamworkAzul}
              style={stylesConGraficoXl3.logoTeamWork}
            />
            <View style={stylesConGraficoXl3.containerEvaluacion}>
              <View style={stylesConGraficoXl3.containerEvaluacionGridTitle}>
                <Text style={stylesConGraficoXl3.containerEvaluacionTitle}>
                  EVALUACIÓN POR COMPETENCIAS
                </Text>
              </View>
              <View style={stylesConGraficoXl3.containerEvaluacionGridSubtitle}>
                <Text style={stylesConGraficoXl3.containerEvaluacionSubtitle}>
                  PERFIL DE COMPETENCIAS
                </Text>
                <View
                  style={stylesConGraficoXl3.containerEvaluacionGridMessage}
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionSubtitleMessage
                    }
                  >
                    En el siguiente formato, se utilizan las escalas de
                    competencias para el cargo evaluado el que ayudará a
                    determinar:
                  </Text>
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionSubtitleMessage
                    }
                  >
                    - Ausencia o presencia de la competencia.
                  </Text>
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionSubtitleMessage
                    }
                  >
                    - Nivel en función de las conductas observadas durante el
                    desarrollo de una entrevista de carácter Conductual.
                  </Text>
                </View>
              </View>
              <View
                style={stylesConGraficoXl3.containerEvaluacionTableCompetencia}
              >
                <Text
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableCompetenciaTitle
                  }
                >
                  CATEGORÍAS EVALUACIÓN COMPETENCIAS
                </Text>
              </View>

              {listRender &&
                listRender.categoriasEvaluacion.map(
                  (item: IPDFCategoriasEvaluacion) => {
                    return (
                      <>
                        <View
                          style={
                            stylesConGraficoXl3.containerEvaluacionTableTrCompetencia
                          }
                        >
                          <View
                            style={
                              stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaTitle
                            }
                          >
                            <Text
                              style={
                                stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaTitleText
                              }
                            >
                              {formatFirstLetterUpper({ texto: item.nombre })}
                            </Text>
                          </View>
                          <View
                            style={
                              stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaDescrition
                            }
                          >
                            <Text
                              style={
                                stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaDescritionText
                              }
                            >
                              {formatFirstLetterUpper({
                                texto: item.descripcion,
                              })}
                            </Text>
                          </View>
                          <View
                            style={
                              stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaPoint
                            }
                          >
                            <Text
                              style={
                                stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaPointText
                              }
                            >
                              {item.valor}
                            </Text>
                          </View>
                        </View>
                      </>
                    );
                  }
                )}

              <View
                style={
                  stylesConGraficoXl3.containerEvaluacionTableTrCompetenciaLaboral
                }
              >
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralTitle
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralTitleText
                    }
                  >
                    Competencias laborales
                  </Text>
                </View>
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNum
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNumText
                    }
                  >
                    1
                  </Text>
                </View>
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNum
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNumText
                    }
                  >
                    2
                  </Text>
                </View>
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNum
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNumText
                    }
                  >
                    3
                  </Text>
                </View>
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNum
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNumText
                    }
                  >
                    4
                  </Text>
                </View>
                <View
                  style={
                    stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNum
                  }
                >
                  <Text
                    style={
                      stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralNumText
                    }
                  >
                    5
                  </Text>
                </View>
              </View>

              {/* containerEvaluacionTableTrCompetenciaMessage */}

              {listRender.competenciasEvaluadas &&
                listRender.competenciasEvaluadas
                  .slice(0, 9)
                  .map((item: IPDFCompetenciasEvaluada, index: number) => {
                    return (
                      <>
                        <View
                          style={
                            index % 2
                              ? stylesConGraficoXl3.containerEvaluacionTableTrCompetenciaMessage
                              : stylesConGraficoXl3.containerEvaluacionTableTrCompetenciaMessageWhite
                          }
                        >
                          <View
                            style={
                              stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralMessage
                            }
                          >
                            <Text
                              style={
                                stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralMessageText
                              }
                            >
                              {formatFirstLetterUpper({
                                texto: item.competenciaLaboral,
                              })}
                            </Text>
                          </View>
                          {[1, 2, 3, 4, 5].map((i, index: number) => (
                            <View
                              style={
                                stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralOption
                              }
                              key={index}
                            >
                              <Text
                                style={
                                  stylesConGraficoXl3.containerEvaluacionTableTdCompetenciaLaboralOptionText
                                }
                              >
                                {i == Number(item.valor) ? <>X</> : <></>}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </>
                    );
                  })}


            </View>
            <View style={stylesConGraficoXl3.containerDescripcion}>
              <View style={stylesConGraficoXl3.containerDescripText}>
                <Text style={stylesConGraficoXl3.contentDescripTitle}>
                  DESCRIPCIÓN GENERAL
                </Text>
                <Text style={stylesConGraficoXl3.contentDescripMessage}>
                  {listRender?.descripcionEvaluacion ?? " "}
                </Text>
              </View>
            </View>
            {/* <RadarChart formList={formList} /> */}
            {grafico && (
              <Image
                src={grafico}
                style={stylesConGraficoXl3.containerGrafico}
              />
            )}
          </View>
        </Page>
      )}
    </>
  );
};

export default ConGraficoXl3;
