import { useRef, useState  } from "react";
interface IIconSolicitud {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  color?: any;
  disabled?:boolean;
  hover?:boolean;
}

export default (props: IIconSolicitud) => {
  const { width, height, fill, className, color, disabled, hover } = props;
  const refIconExcel = useRef<any>();
  

  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill={fill}
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
       
      <path d="M16.0833 4.805L12.07 0.791667C11.8833 0.605 11.6383 0.5 11.37 0.5H11.125V5.75H16.375V5.505C16.375 5.24833 16.27 4.99167 16.0833 4.81667V4.805Z" fill="#06A502"/>
      <path d="M9.81833 6.07667V0.5H1.605C1.06833 0.5 0.625 0.943333 0.625 1.48V20.52C0.625 21.0683 1.06833 21.5 1.605 21.5H15.395C15.9433 21.5 16.375 21.0567 16.375 20.52V7.05667H10.7983C10.2617 7.05667 9.81833 6.61333 9.81833 6.07667ZM13.6917 10.02L14.5783 10.9067C14.8233 11.1517 14.8233 11.5483 14.5783 11.7933L7.38 18.9917C7.135 19.2367 6.73833 19.2367 6.49333 18.9917L2.42167 14.92C2.17667 14.675 2.17667 14.2783 2.42167 14.0333L3.30833 13.1467C3.55333 12.9017 3.95 12.9017 4.195 13.1467L6.93667 15.8883L12.805 10.02C13.05 9.775 13.4467 9.775 13.6917 10.02Z" fill="#06A502"/>

       
   
   
    </svg>
  );
};


