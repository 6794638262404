import React, { useEffect, useState } from "react";
import "../../assets/css/ui-mdtw.min.css";
import "../../assets/css/modalmdtw.min.css";
import "../../assets/css/page-ui-recluiting.min.css";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import MainTopbar from "../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { Loading } from "../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../ui/microsoft-auth/msal-template/ErrorComponent";
import ListaAsistencia from "../ui/V2/ListaAsistencia";
import Services from "./AdminAssist/Services";
import AdminAssistState from "../../context/AdminAssist/AdminAssistState";
import { callMsGraphTokens } from "../../services/MsGraphTokens";

const AdministrationAssist: React.FC<IPage> = (props) => {
  const [token, setToken] = useState<any>(undefined);
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    logging.info(`Loading ${props.name}`); 
  }, [props]);

  useEffect(() => {
    const profileA: string = sessionStorage.getItem("profile") as string;
    setProfile(profileA);
    getToken();
  }, []);

  const getToken = () => {
    callMsGraphTokens()
      .then((response) => {
        if (token != response.idToken) {
          setToken(response.idToken);
        }
      })
      .catch((e) => {});
  };

  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
        
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
          />
        ) : ( 
          ""
        )}
        <AdminAssistState>
          <Services token={token} setToken={setToken} getToken={getToken} />
        </AdminAssistState> 
      </MsalAuthenticationTemplate>
    </>
  );
};

export default AdministrationAssist;
