import ErrorPage from "../components/pages/ErrorPage";
import HomePage from "../components/pages/HomePage";
import LoginPage from "../components/pages/LoginPage";
import CandidateGetIdPage from "../components/pages/CandidateGetIdPage";
import IRoute from "../interfaces/route";
import EstActiveProcessV2 from "../components/pages/ProcessAsigPages/EstActiveProcessV2";
import EstEndedProcessV2 from "../components/pages/ProcessAsigPages/EstEndedProcessV2";
import PreventionPage from "../components/pages/ExclusionRutsPages/SearchRutPage";
import EntryRutPage from "../components/pages/ExclusionRutsPages/EntryRutPage";
import AddFormPage from "../components/pages/ExclusionRutsPages/AddFormPage";
import RouterPage from "../components/pages/RouterPage";
import ValidationPageV2 from "../components/pages/ValidationPageV2";
import ConfirmPageV2 from "../components/pages/ConfirmPageV2";
import RequestsProcessV2 from "../components/pages/ProcessAsigPages/RequestsProcessV2";
import EditSpecialFieldsPage from "../components/pages/ExclusionRutsPages/EditSpecialFieldsPage";
import OperacionesProcessV2 from "../components/pages/ProcessAsigPages/OperacionesProcessV2";
import ListPage from "../components/pages/ListPage";
import Invitation from "../components/pages/Invitation";
import ListaAsistencia from "../components/pages/ListaAsistencia";
import Asistencia from "../components/pages/Asistencia";
import AdministrationAssist from "../components/pages/AdministrationAssist";
import ValidationKam from "../components/pages/ValidationKam";
import RecruitByGroupPages from "../components/pages/RecruitByGroupPages";
import Campaign from "../components/pages/Campaign";
import ProcesosActivos from "../components/pages/ProcesosActivos";
import RegistroInhabilitado from "../components/pages/RegistroInhabilitado";
import RegistroHabilitado from "../components/pages/RegistroHabilitado";
import RegistroTraspaso from "../components/pages/RegistroTraspaso";
import RegistroPorValidar from "../components/pages/RegistroPorValidar";
import RegistroPreseleccionado from "../components/pages/RegistroPreseleccionado";
import RegistroSeleccionado from "../components/pages/RegistroSeleccionado";
import Contratacion from "../components/pages/Contratacion";
import ValidationCliente from "../components/pages/ValidationCliente";
import RegistroPorValidarMasivo from "../components/pages/RegistroPorValidarMasivo";
import RegistroValidadoMasivo from "../components/pages/RegistroValidadoMasivo";
import ProcesosSolicitudes from "../components/pages/ProcesosSolicitudes";
import OperacionContratacion from "../components/pages/OperacionContratacion";
import OperacionBajaConfirmada from "../components/pages/OperacionBajaConfirmada";
import OperacionResumen from "../components/pages/OperacionResumen";
import RegistroPostulaciones from "../components/pages/RegistroPostulaciones";
import GenerarPdfPsicolaboral from "../components/pages/PdfPsicolaboral/GenerarPdfPsicolaboral";
import Contratos from "../components/pages/Contratos";
import ContratoPendientes from "../components/pages/ContratoPendientes";
import ContratoProcesadas from "../components/pages/ContratoProcesadas";
const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: HomePage,
    exact: true,
    isLogin: true,
  },
  {
    path: "/router",
    name: "Home Page",
    component: RouterPage,
    exact: true,
  },
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/addCandidate/:candidate_id/:busquedaId",
    name: "Agregar Candidato",
    component: CandidateGetIdPage,
    exact: true,
  },
  {
    path: "/validacionporgrupo/:busquedaId",
    name: "Validacion",
    component: ValidationPageV2,
    exact: true,
  },
  {
    path: "/confirmacionporgrupo/:busquedaId",
    name: "Confirmacion",
    component: ConfirmPageV2,
    exact: true,
  },
  {
    path: "/consultaexclusionrut",
    name: "Prevención",
    component: PreventionPage,
    exact: true,
  },
  {
    path: "/ingresoexclusionrut",
    name: "Ingreso de Rut",
    component: EntryRutPage,
    exact: true,
  },
  {
    path: "/configurarnomina",
    name: "Crear Nomina",
    component: AddFormPage,
    exact: true,
  },

  {
    path: "/camposespeciales",
    name: "Crear campos especiales",
    component: EditSpecialFieldsPage,
    exact: true,
  },

  {
    path: "/procesosasignadosactivos",
    name: "Procesos asignados activos",
    component: EstActiveProcessV2,
    exact: true,
  },
  {
    path: "/solicitudes",
    name: "Asignación de solicitudes",
    component: RequestsProcessV2,
    exact: true,
  },
  {
    path: "/procesosasignadoscerrados",
    name: "Procesos asignados cerrados",
    component: EstEndedProcessV2,
    exact: true,
  },
  {
    path: "/reclutarporgrupos/:idGrupo",
    name: "Reclutar por grupos",
    component: RecruitByGroupPages,
    exact: true,
  },
  {
    path: "/operaciones",
    name: "Operaciones",
    component: OperacionesProcessV2,
    exact: true,
  },
  {
    path: "/lista/:areaNegocioId",
    name: "Lista de asistencia",
    component: ListPage,
    exact: true,
  },
  {
    path: "/administracion/asistencia",
    name: "Administracion de asistencia y procesos",
    component: AdministrationAssist,
    exact: true,
  },
  {
    path: "/invitacion/procesos",
    name: "Invitacion a procesos",
    component: Invitation,
    exact: true,
  },
  {
    path: "/asistencia",
    name: "Asistencia externos",
    component: Asistencia,
    exact: true,
  },
  {
    path: "/asistencia/lista",
    name: "Lista asistencia externos",
    component: ListaAsistencia,
    exact: true,
  },
  {
    path: "/validacioncliente",
    name: "Validacion del cliente",
    component: ValidationCliente,
    exact: true,
  },
  {
    path: "/validacioncliente/:idCliente",
    name: "Validacion del cliente inside",
    component: ValidationCliente,
    exact: true,
  },
  {
    path: "/validacion",
    name: "Validacion del kam",
    component: ValidationKam,
    exact: true,
  },
  {
    path: "/validacion/:idCliente",
    name: "Validacion del kam inside",
    component: ValidationKam,
    exact: true,
  },
  {
    path: "/contratacion",
    name: "Sistema de contratación",
    component: Contratacion,
    exact: true,
  },
  {
    path: "/contratacion/:idCliente",
    name: "Sistema de contratación",
    component: Contratacion,
    exact: true,
  },
  {
    path: "/campaign",
    name: "Campaña",
    component: Campaign,
    exact: true,
  },
  {
    path: "/procesos/activos",
    name: "Procesos Activos",
    component: ProcesosActivos,
    exact: true,
  },
  {
    path: "/procesos/solicitudes",
    name: "Solicitudes de Proceso",
    component: ProcesosSolicitudes,
    exact: true,
  },
  {
    path: "/registros/postulaciones",
    name: "Candidatos Registrados Por Formulario",
    component: RegistroPostulaciones,
    exact: true,
  },
  {
    path: "/registros/inhabilitados",
    name: "Candidatos Inhabilitados",
    component: RegistroInhabilitado,
    exact: true,
  },
  {
    path: "/registros/habilitados",
    name: "Candidatos Habilitados",
    component: RegistroHabilitado,
    exact: true,
  },
  {
    path: "/registros/traspasos",
    name: "Candidatos Traspasos",
    component: RegistroTraspaso,
    exact: true,
  },
  {
    path: "/seleccionprofesional/porvalidar",
    name: "Seleción Profesional Por Validar",
    component: RegistroPorValidar,
    exact: true,
  },
  {
    path: "/seleccionprofesional/preseleccionados",
    name: "Seleción Profesional Preseleccionados",
    component: RegistroPreseleccionado,
    exact: true,
  },
  {
    path: "/seleccionprofesional/seleccionados",
    name: "Seleción Profesional Seleccionados",
    component: RegistroSeleccionado,
    exact: true,
  },
  {
    path: "/seleccionmasiva/porvalidar",
    name: "Seleción Masiva Por Validar",
    component: RegistroPorValidarMasivo,
    exact: true,
  },
  {
    path: "/seleccionmasiva/validados",
    name: "Seleción Masiva Por Validados",
    component: RegistroValidadoMasivo,
    exact: true,
  },
  {
    path: "/dotacionactiva/:idCliente",
    name: "Dotacion Activa",
    component: OperacionContratacion,
    exact: true,
  },
  {
    path: "/bajasconfirmadas/:idCliente",
    name: "Bajas Confirmadas Operaciones",
    component: OperacionBajaConfirmada,
    exact: true,
  },
  {
    path: "/resumen/:idCliente",
    name: "Resumen Dashboard",
    component: OperacionResumen,
    exact: true,
  },
  {
    path: "/resumen",
    name: "Resumen Dashboard",
    component: OperacionResumen,
    exact: true,
  },
  {
    path: "/pdf/psicolaboral/:formato/:busperid",
    name: "PDF Psicolaboral",
    component: GenerarPdfPsicolaboral,
    exact: true,
  },
  {
    path: "/contratos/resumen",
    name: "Sistema de contratos",
    component: Contratos,
    exact: true,
  },
  {
    path: "/contratos/pendientes",
    name: "Sistema de contratos",
    component: ContratoPendientes,
    exact: true,
  },
  {
    path: "/contratos/procesadas",
    name: "Sistema de contratos",
    component: ContratoProcesadas,
    exact: true,
  },
  {
    path: "*",
    name: "Not Found",
    component: ErrorPage,
    exact: true,
  },
];

export default routes;
