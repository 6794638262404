import { useContext, useEffect, useState } from "react";
import "../../../assets/css/servicesAssists.min.css";
import ButtonIcon from "../../ui/adminAssist/ButtonIcon";
import IconExtenderTiempo from "../../ui/kit-svg/IconExtenderTiempo";
import IconReasignar from "../../ui/kit-svg/IconReasignar";
import IconReenviar from "../../ui/kit-svg/IconReenviar";
import IconEliminar from "../../ui/kit-svg/IconEliminar";
import ModalMessage from "../../ui/adminAssist/modals/ModalMessage";
import AdminAssistContext from "../../../context/AdminAssist/AdminAssistContext";
import ToastMessage from "../../ui/adminAssist/toasts/ToastMessage";
import ReasingServiceModal from "./ReasingServiceModal";
import RenewCode from "./RenewValidateCodeModal";
import SendCodeModal from "./SendCodeModal";
import DeleteServicesModal from "./DeleteServicesModal";
import MediaQuery from "../../ui-responsive/MediaQuery";
import ButtonFilter from "../../ui/adminAssist/ButtonFilter";
import { IToken } from "../../../interfaces/IAdminAssist";

export default (props: IToken) => {
  const { token, setToken, getToken } = props; 
  const {
    toastState,
    listServicioId, 
    setListServicioId,
    visibleActionBlocking,
    updateResetService, 
    deleteService,
  } = useContext(AdminAssistContext);
  const { width, lg, md } = MediaQuery();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalReasing, setIsOpenModalReasing] = useState<boolean>(false);
  const [isOpenModalSendCode, setIsOpenModalSendCode] =
    useState<boolean>(false);
  const [isOpenModalValidCode, setIsOpenModalValidCode] =
    useState<boolean>(false);

  // toasmessage success
  const handleClickDeleteProcess = () => {
    setIsOpenModalDelete(false);
    getToken();
    deleteService({servicioId: listServicioId, token})
  };

  const handleClickValidateProcess = () => {
    setIsOpenModalValidCode(false);
    getToken();
    updateResetService({servicioId: listServicioId, token})
  };

  const handleClickSendCodes = () => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "auto";
    }
    setIsOpenModalSendCode(false);
  };

  return (
    <>
      <div className="content-items">
        <div className="content-admin-action-massive">
          <div className="div-admin-action-massive">
            <ButtonFilter
              disabled={visibleActionBlocking ? false : true}
              // onClick={() => setIsOpenModalReasing(true)}
            >
              <IconReasignar
                color={visibleActionBlocking ? "#404040" : "#AEAEAE"}
              />
            </ButtonFilter>

            <ButtonFilter
              disabled={visibleActionBlocking ? false : true}
              onClick={() => setIsOpenModalValidCode(true)}
            >
              <IconExtenderTiempo
                color={visibleActionBlocking ? "#404040" : "#AEAEAE"}
              />
            </ButtonFilter>

            <ButtonFilter
              disabled={visibleActionBlocking ? false : true}
              // onClick={() => setIsOpenModalSendCode(true)}
            >
              <IconReenviar
                color={visibleActionBlocking ? "#404040" : "#AEAEAE"}
              />
            </ButtonFilter>

            <ButtonFilter
              disabled={visibleActionBlocking ? false : true}
              onClick={() => setIsOpenModalDelete(true)}
            >
              <IconEliminar
                color={visibleActionBlocking ? "#404040" : "#AEAEAE"}
              />
            </ButtonFilter>
          </div>
        </div>
      </div>

      {isOpenModalReasing === true && (
        <ReasingServiceModal
          title="Reasignar Servicio"
          message="Vas a reasignar los siguietes servicios"
          onChangeBack={() => setIsOpenModalReasing(false)}
          onChangeGo={() => setIsOpenModalReasing(false)}
          width={width >= lg ? "60%" : "95%"}
          list={listServicioId}
        />
      )}

      {isOpenModalValidCode === true && (
        <RenewCode
          title="Renovar validez código de acceso"
          message="Si selecciona eliminar, se borrarán todos los datos de nuestra base de datos."
          onChangeBack={() => setIsOpenModalValidCode(false)}
          onChangeGo={() => handleClickValidateProcess()}
          width={width >= lg ? "40%" : "95%"}
          list={listServicioId}
        />
      )}

      {isOpenModalSendCode === true && (
        <SendCodeModal
          title="Reenviar invitación de acceso"
          subTitle="Vas a reenviar el correo de acceso externo a los siguientes servicios: "
          message="Selecciona la cantidad de códigos de acceso desea asignar a cada destinatario."
          massive={true}
          onChangeBack={() => {
            setIsOpenModalSendCode(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          onChangeGo={() => handleClickSendCodes()}
          width={width >= lg ? "60%" : "95%"}
          list={listServicioId}
        />
      )}

      {isOpenModalDelete === true && (
        <DeleteServicesModal
          title="Eliminar Servicio"
          message="Vas a eliminar los siguientes servicios:"
          onChangeBack={() => setIsOpenModalDelete(false)}
          onChangeGo={() => handleClickDeleteProcess()}
          width={width >= lg ? "40%" : "90%"}
          list={listServicioId}
        />
      )}
    </>
  );
};
