import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconEyeDocument from "../../../ui/kit-svg/icon-contratos/IconEyeDocument";
import ContratosContext from "../../../../context/Contratos/ContratosContext";
import { IDocumentoColaborador } from "../../../../interfaces/IContratos";

interface IAreaDocumentacion {
  hiddenDefault: boolean;
  formCandidate: any;
  token: string;
  userId: string;
}

interface IDocumentSelect {
  documentoId: string;
}

export default (props: IAreaDocumentacion) => {
  const { hiddenDefault, formCandidate, token, userId } = props;
  const { getDocumentoColaborador, listDocumentoColaborador } =
    useContext(ContratosContext);
  const [hidden, setHidden] = useState<boolean>(hiddenDefault);
  const [documentos, setDocumentos] = useState<any>();
  const [isLoadingDocumento, setIsLoadingDocumento] = useState<
    boolean | undefined
  >(undefined);
  const [selectDocument, setSelectDocument] = useState<string>("");

  useEffect(() => {
    try {
      if (token) {
        getDocumentoColaborador({
          token,
          personaDNI: formCandidate.personaDNI,
          setIsLoading: setIsLoadingDocumento,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    if (listDocumentoColaborador) {
      const copia: IDocumentoColaborador[] = listDocumentoColaborador.map(
        (seccion: any) => seccion.documentos
      );
      const primerDocumento: IDocumentoColaborador | undefined = copia
        .flat()
        .find((item: any) => item.enlace);
      setDocumentos(copia.flat());
      setSelectDocument(primerDocumento?.documentoId ?? "");
    }
  }, [listDocumentoColaborador]);

  const handleClickViewDocument = (values: IDocumentSelect):void => {
    const { documentoId } = values;
    setSelectDocument(documentoId);
  };

  return (
    <>
      {formCandidate && documentos && documentos.length > 0 ? (
        <>
          <div className={`area-table`} onClick={() => setHidden(!hidden)}>
            <span>ÁREA DOCUMENTACIÓN</span>
            <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
          </div>
          <div className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-table documentacion">
              <div className="content-table-row documento-modal">
                {documentos.map((doc: any) => {
                  const { nombreDocumento, enlace, documentoId } = doc;
                  return (
                    <>
                      <div className="content-document-colaborator">
                        <label className="title-table-row documento">
                          {nombreDocumento}
                        </label>
                        <div className="subtitle-table-row dos">
                          <button
                            className="button-plus"
                            onClick={(): void =>
                              handleClickViewDocument({ documentoId })
                            }
                          >
                            <IconEyeDocument
                              fill="#0065DD"
                              disabled={!enlace}
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="title documento">DOCUMENTO</div>
              {!isLoadingDocumento && selectDocument ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "400px",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src={`http://pdf-qa.team-work.cl/Pdf/DocumentosCandidato?userid=${userId}&personadni=${formCandidate?.personaDNI}&glcid=${token}&documentoId=${selectDocument}`}
                      width="100%"
                      height="400"
                      title="Documentación de personas"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h3>Cargando documentos...</h3>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Cargando documentos...</h3>
        </div>
      )}
    </>
  );
};
