import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../../assets/css/campaign.min.css";
import InputTextCustomized from "../.././ui/kit-mdtw/InputTextCustomized";
import CampaignContext from "../../../context/Campaign/CampaignContext";
import Logotipo from "../../ui/kit-svg/Logotipo";

interface IModalEncuesta {
  modalStyle: string;
  title: any;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: Function;
  busPerId: string | undefined;
}

interface ITextForm {
  value: string | undefined;
  valid: boolean;
}

interface IEncuestaSend {
  pregunta: ITextForm;
}

export default (props: IModalEncuesta) => {
  const { modalStyle, title, onChangeBack, busPerId } = props;
  const { token, getCampaignActiva, encuesta, encuestaEnviada } =
    useContext(CampaignContext);

  const [openModalIniciar, setopenModalIniciar] = useState<boolean>(false);
  const [openModalPregunta, setopenModalPregunta] = useState<boolean>(false);
  const [openConfirmaEnvio, setopenConfirmaEnvio] = useState<boolean>(false);
  const [avisoFinalEncuesta, setAvisoFinalEncuesta] = useState<boolean>(false);
  const [sendEncuesta, setSendEncuesta] = useState<any>({});

  useEffect(() => {
    if (token) {
      getCampaignActiva({ busPerId, token });
    }
  }, [token]);

  useEffect(() => {
    if (encuesta && encuesta.encuestas.length > 0) {
      const objectEncuesta: any = {};
      const { 0: encuestas } = encuesta?.encuestas;

      encuestas.preguntas.map(({}, index: number) => {
        objectEncuesta[`pregunta${index}`] = { value: undefined, valid: false };
      });

      setSendEncuesta(objectEncuesta);
    }
  }, [encuesta]);

  const siguienteIntroduccion = () => {
    setopenModalIniciar(true);
  };

  const answerOpen = () => {
    setopenModalPregunta(true);
  };

  const objetoSendEncuesta = () => {
    const result = encuesta.encuestas.map((item: any) => {
      return {
        campaignId: encuesta.campaignId,
        busPerId,
        encuestas: [
          {
            encuestaId: item.encuestaId,
            respuestas: item.preguntas.map((itemP: any, index: number) => {
              return {
                preguntaId: itemP.preguntaId,
                respuesta: sendEncuesta[`pregunta${index}`].value,
              };
            }),
          },
        ],
      };
    })[0];
    encuestaEnviada({ respuestas: result, token });
    return result;
  };

  return (
    <>
      {encuesta && (
        <>
          <div className="modal-container-encuesta">
            <div className="modal-content-encuesta w-55">
              <div className="modal-title-encuesta">
                <span>
                  <Logotipo width={222} height={51} />
                </span>
              </div>
              <div className="modal-title-encuesta">
                <span>{title}</span>
              </div>
              <div className="explatituloModal-encuesta">
                <span>{encuesta.nombre}</span>
              </div>

              <div className=" mx-auto">
                <p className="yesOrNo">
                  El candidato desea contestar la encuesta
                </p>
              </div>

              <div className="modal-botton">
                {modalStyle && (
                  <>
                    <button
                      className={`modal-button cancel`}
                      onClick={() =>
                        typeof onChangeBack == "function" && onChangeBack()
                      }
                    >
                      No
                    </button>
                    <button
                      className={`modal-button  ${"selec"}`}
                      onClick={() => {
                        siguienteIntroduccion();
                      }}
                    >
                      Si
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {openModalIniciar && (
            <div className="modal-container-encuesta">
              <div className="modal-content-encuesta w-50">
                <div>
                  <span>
                    <Logotipo width={113} height={26} />
                  </span>
                  <span
                    className="modal-title-right"
                    style={{ float: "right" }}
                  >
                    “{encuesta.nombre}”
                  </span>
                </div>

                <div className="modal-title-encuesta">
                  <p className="modal-title-encuesta">Introducción</p>
                </div>
                <div className="modal-message">
                  <span>
                    Queremos asegurarnos de que todos los empleados estén
                    seguros y protegidos en su lugar de trabajo, y Como empresa,
                    entendemos lo importante que es crear un ambiente de trabajo
                    seguro y sano para nuestros empleados, es por eso que
                    hacemos énfasis en temas relacionados a la seguridad
                    laboral. Por esta razón, hemos elaborado una encuesta
                    relacionada con la seguridad laboral, con la finalidad de
                    conocer las experiencias y percepciones de nuestros
                    colaboradores.
                  </span>
                </div>

                <div className="mr-3">
                  {modalStyle && (
                    <>
                      <button
                        className={`navigation ${"buttonIniciar"} buttonW100`}
                        onClick={answerOpen}
                      >
                        Iniciar
                        <div style={{ float: "right" }}>
                          <ArrowForwardIcon style={{ color: "#FAFAFA" }} />
                        </div>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {openModalPregunta && (
            <div className="modal-container-encuesta">
              <div className="modal-content-encuesta w-55">
                <div className="mb-3">
                  <span className="col-12 md:col-6 sm:col-4 ml-0 pl-0 text-center floatLeft575">
                    <Logotipo width={113} height={26} />
                  </span>
                  <span
                    style={{ float: "right" }}
                    className="col-12 md:col-6 sm:col-8 modal-title-right text-center sm:text-right"
                  >
                    “{encuesta.nombre}”
                  </span>
                </div>

                {encuesta &&
                  encuesta.encuestas.map((item: any) => {
                    return (
                      //
                      item.preguntas &&
                      item.preguntas.map((itemP: any, index: any) => {
                        return (
                          <>
                            <div className="modal-title-encuesta">
                              <p className="modal-title-answer">
                                {itemP.pregunta}
                              </p>
                            </div>
                            <div className="col-12 p-0">
                              <InputTextCustomized
                                layer=""
                                required={undefined}
                                placeholder={"Escriba aquí la respuesta"}
                                onPermaValues={sendEncuesta}
                                onValueChange={setSendEncuesta}
                                onPropertyChange={`pregunta${index}`}
                              />
                            </div>
                          </>
                        );
                      })
                      //
                    );
                  })}

                <div className="modal-botton">
                  {modalStyle && (
                    <>
                      <button
                        className={`navigation  ${"active-green"} buttonW100`}
                        onClick={() => {
                          setopenConfirmaEnvio(true);
                        }}
                      >
                        Finalizar
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {openConfirmaEnvio && (
            <div className="modal-container-encuesta">
              <div className="modal-content-encuesta w-45">
                <div className="modal-title-encuesta">
                  <span>¿Está seguro que desea enviar las respuestas?</span>
                </div>

                <div className="modal-botton">
                  {modalStyle && (
                    <>
                      <div className="row">
                        <div
                          className="col-12 mb-4"
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <button
                            className={`navigation  ${"active-green"}`}
                            onClick={() => {
                              objetoSendEncuesta();
                              setAvisoFinalEncuesta(true);
                            }}
                          >
                            Enviar
                          </button>
                        </div>
                        <div
                          className={`col-12 navigation  ${"cancelConfirmaRespuesta"}`}
                          onClick={() => setopenConfirmaEnvio(false)}
                        >
                          Cancelar
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {avisoFinalEncuesta && (
            <div className="modal-container-encuesta">
              <div className="modal-content-encuesta w-55">
                <span
                  className="modal-close"
                  onClick={() =>
                    typeof onChangeBack == "function" && onChangeBack()
                  }
                >
                  <CloseIcon />
                </span>

                <div className="modal-title-encuesta">
                  <span>
                    <Logotipo width={222} height={51} />
                  </span>
                </div>
                <div className="modal-title-encuesta">
                  <span>Sus respuestas se enviaron correctamente</span>
                </div>
                <div className="explatituloModal-encuesta">
                  <span>¡GRACIAS por ayudarnos a mejorar!</span>
                </div>

                <div className="modal-botton">
                  {modalStyle && (
                    <>
                      <button
                        className={`modal-button  ${"avisoFinal"}`}
                        onClick={() => {
                          typeof onChangeBack == "function" && onChangeBack();
                        }}
                      >
                        Cerrar
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
