interface Icondelete {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
    disabled?: boolean;
  }
  
  export default (props: Icondelete) => {
    const { width, height, disabled, style, className, color, fill } = props;
  
    return (
      <svg
        width={`${width ? width : "19"}`}
        height={`${height ? height : "21"}`}
        style={style}
        color={color}
        viewBox="0 0 19 21"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
      <path d="M5.85781 0.725977C6.0793 0.278906 6.53457 0 7.03086 0H11.9691C12.4654 0 12.9207 0.278906 13.1422 0.725977L13.4375 1.3125H17.375C18.101 1.3125 18.6875 1.89902 18.6875 2.625C18.6875 3.35098 18.101 3.9375 17.375 3.9375H1.625C0.899023 3.9375 0.3125 3.35098 0.3125 2.625C0.3125 1.89902 0.899023 1.3125 1.625 1.3125H5.5625L5.85781 0.725977ZM1.625 5.25H17.375V18.375C17.375 19.8229 16.1979 21 14.75 21H4.25C2.80215 21 1.625 19.8229 1.625 18.375V5.25ZM5.5625 7.875C5.20156 7.875 4.90625 8.17031 4.90625 8.53125V17.7188C4.90625 18.0797 5.20156 18.375 5.5625 18.375C5.92344 18.375 6.21875 18.0797 6.21875 17.7188V8.53125C6.21875 8.17031 5.92344 7.875 5.5625 7.875ZM9.5 7.875C9.13906 7.875 8.84375 8.17031 8.84375 8.53125V17.7188C8.84375 18.0797 9.13906 18.375 9.5 18.375C9.86094 18.375 10.1562 18.0797 10.1562 17.7188V8.53125C10.1562 8.17031 9.86094 7.875 9.5 7.875ZM13.4375 7.875C13.0766 7.875 12.7812 8.17031 12.7812 8.53125V17.7188C12.7812 18.0797 13.0766 18.375 13.4375 18.375C13.7984 18.375 14.0938 18.0797 14.0938 17.7188V8.53125C14.0938 8.17031 13.7984 7.875 13.4375 7.875Z" 
      fill={disabled ? '#AEAEAE' :"#BE2A2A"}/>
      </svg>
    );
  };

