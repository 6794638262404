import { useState, useEffect, useRef } from "react";
import api from "../../../services/api";
import { callMsGraphTokens } from "../../../services/MsGraphTokens";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import formatRUT from "../../../functions/formatRutWithOutPoints";
import ModalError from "../../../utils/ModalError";

//const & functions

const PreventionTableComponent = () => {
  const [token, setToken] = useState<any>(null);
  const history = useHistory();
  const [rut, setRut] = useState<any>("");
  const [rutResponse, setRutResponse] = useState<any>([]);
  const [flag, setFlag] = useState<any>("");
  const [isSearching, setIsSearching] = useState<any>(false);
  const [visibleLoading1, setVisibleLoading1] = useState<any>(false);
  const [visibleLoading2, setVisibleLoading2] = useState<any>(false);
  const [flagMasive, setFlagMasive] = useState<any>("");
  const [visibleModalError, setVisibleModalError] = useState(false);
  const [openAccordion, setOpenAccordion] = useState<any>(null);

  const CentralContainer = () => {
    return (
      <>
        <div className="flex justify-content-center align-items-center">
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "",
              fontSize: "16px",
              lineHeight: "156%",
              alignItems: "center",
              color: "#333333",
              fontWeight: "600",
              width: "591px",
            }}
          >
            <p
              style={{
                width: "591px",
                textAlign: "center",
              }}
            >
              En esta opción puedes consultar si un RUT tiene algún tipo de
              restricción para colaborar con Teamwork o con alguno de nuestros
              clientes.
            </p>
          </div>
        </div>
      </>
    );
  };

  const MassiveRequest = () => {
    return (
      <>
        <div className="accordionRutCandidates w-12">
          <Accordion
            activeIndex={0}
            onTabClose={(e) => setOpenAccordion(e.index)}
          >
            <AccordionTab header="Consulta masiva">
              <div className="flex justify-content-center flex-column h-full my-6 mx-6 sm:my-6 mx-6 md:my-6 mx-6 lg:my-6 mx-6 xl:my-6 mx-6">
                <div className="h-full sm:h-auto  md:h-auto">
                  <div className="">
                    <p>
                      <b>
                        Para realizar la consulta masiva siga las instrucciones:
                      </b>
                    </p>
                    <ul>
                      <li>
                        El archivo excel a subir debe tener una columna nominada
                        como RUT
                      </li>
                    </ul>
                  </div>

                  <div className="my-3">
                    <p>
                      <b>
                        También puede descargar la planilla y completar con los
                        datos que desea consultar para asegurarse de cumplir el
                        formato
                      </b>
                    </p>
                    <Button
                      label="Descargar planilla consulta masiva"
                      icon="pi pi-download"
                      iconPos="right"
                      onClick={downloadTemplate}
                    />
                  </div>

                  <div className="my-3">
                    <p>
                      Seleccione archivo en formato excel para consultar de
                      forma masiva:
                    </p>
                  </div>

                  <div className="mt-3">
                    <FileUpload
                      mode="advanced"
                      name="cargaArchivos"
                      accept=".xls,.xlsx"
                      maxFileSize={1000000}
                      customUpload={true}
                      chooseLabel="Seleccione archivo"
                      uploadLabel="Subir archivo"
                      cancelLabel="Cancelar"
                      uploadHandler={validateRuts}
                      style={{ backgroundColor: "#398149" }}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </>
    );
  };

  const downloadTemplate = () => {
    api
      .get(`exclusion-persona-template/get/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "consultamasiva.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        console.log("Error al descargar");
        setVisibleModalError(true);
      });
  };

  const cerrarModalError = () => {
    setVisibleModalError(false);
  };

  const onRutChange = (e: any) => {
    let rutIngresado = e.target.value;
    rutIngresado = rutIngresado.replace("Kk", "K");
    rutIngresado = rutIngresado.replace("kk", "K");
    rutIngresado = rutIngresado.replace(/k[\d]/gi, "K");
    setRut(formatRUT(rutIngresado.trim()));
  };

  const ResponseRut = () => {
    return (
      <>
        {rutResponse && rutResponse.data
          ? rutResponse.data.map((data: any) => {
              if (data.excTipoId === "5") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "6") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "7") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-246 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "99") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 icon">
                      how_to_reg
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato podría ser admitido por Teamwork y todos los
                      clientes, sin restricciones.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "2") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "3") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "15") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (
                data.excTipoId === "16" ||
                data.excTipoId === "19" ||
                data.excTipoId === "4"
              ) {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconYellow">
                      warning
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con{" "}
                      {data.clients.map((client: any) => {
                        return <b> {client} </b>;
                      })}
                      . Admitido para otros clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "17") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "18") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 icon">
                      how_to_reg
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato podría ser admitido por Teamwork y todos los
                      clientes, sin restricciones.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "ET-01") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconYellow">
                      warning
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato corresponde a un funcionario de Teamwork.
                      Validar estado de su contrato en área de contrataciones
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "ET-02") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "ET-03") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "ET-04") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              } else if (data.excTipoId === "ET-05") {
                return (
                  <div className="alignIcon mb-2">
                    <span className="material-symbols-outlined md-24 iconRed">
                      do_not_disturb_on
                    </span>
                    <p className="ml-3 messageRut">
                      Candidato <b>no admitido</b> para colaborar con Teamwork o
                      sus clientes.
                    </p>
                  </div>
                );
              }
            })
          : ""}
        {flag && flag === "99999" ? (
          <div className="alignIcon">
            <span className="material-symbols-outlined md-24 icon">
              how_to_reg
            </span>
            <p className="ml-3 messageRut">
              Candidato podría ser admitido por Teamwork y todos los clientes,
              sin restricciones.
            </p>
          </div>
        ) : (
          ""
        )}
        {flag && flag === "999999" ? (
          <div className="alignIcon">
            <span className="material-symbols-outlined md-24 iconYellow">
              warning
            </span>
            <p className="ml-3 messageRut">Rut no es valido</p>
          </div>
        ) : (
          ""
        )}
        {visibleLoading1 ? (
          <div className="alignIcon mt-3 loading">
            <Button loading />
            <p className="ml-3 messageRut">Consultando...</p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  //API Call(s)
  const validatedRut = () => {
    setVisibleLoading1(true);
    setIsSearching(true);
    api
      .get(`exclusion-persona-rut/${rut.toLowerCase()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisibleLoading1(false);
        setRutResponse(response.data);
      })
      .catch((error) => {
        if (
          error.response.data.errors[0] &&
          error.response.data.errors[0].errorId == 2999
        ) {
          setFlag("999999");
        }
        setVisibleLoading1(false);
      });
  };

  const cleanValidatedRut = () => {
    setIsSearching(false);
    setRut("");
    setFlag(null);
    setVisibleLoading1(false);
    setRutResponse(null);
  };

  const validateRuts = (files: any) => {
    setOpenAccordion(0);
    setVisibleLoading2(true);
    setFlagMasive("");
    let formData = new FormData();
    formData.append("file", files.files[0]);
    api
      .post(`exclusion-persona-upload-get/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisibleLoading2(false);
        if (response.data.data) {
          setFlagMasive("1");
        } else {
          setFlagMasive("2");
        }
        const link = document.createElement("a");
        link.href = response.data.data[0].urlToDownload;
        link.setAttribute("download", "consultamasiva.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        //setVisibleModalError(true);
        setVisibleLoading2(false);
        setFlagMasive("3");
      });
  };

  const ResponseSaveMasive = () => {
    return (
      <>
        {flagMasive && flagMasive === "1" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 icon">check</span>
            <p className="ml-3 messageRut">
              Archivo descargado con consulta realizada. Revise su bandeja de
              descargas y abra archivo para ver resultados.
            </p>
          </div>
        ) : flagMasive && flagMasive === "2" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconRed">
              warning
            </span>
            <p className="ml-3 messageRut">
              Archivo descargado con consulta realizada. Revise su bandeja de
              descargas y abra archivo para ver resultados.
            </p>
          </div>
        ) : flagMasive && flagMasive === "3" ? (
          <div className="alignIcon mt-3">
            <span className="material-symbols-outlined md-24 iconRed">
              warning
            </span>
            <p className="ml-3 messageRut">
              Ha ocurrido un error, por favor cargue archivo nuevamente.
            </p>
          </div>
        ) : (
          ""
        )}

        {visibleLoading2 ? (
          <div className="alignIcon mt-3 loading">
            <Button loading />
            <p className="ml-3 messageRut">Consultando...</p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  //useEffect

  useEffect(() => {
    setRutResponse(null);
    callMsGraphTokens()
      .then((response) => {
        setToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
        history.push("/");
      });
  }, []);

  useEffect(() => {
    if (rutResponse) {
      if (rutResponse.data && rutResponse.data[0]) {
        setFlag(rutResponse.data[0].excTipoId);
      } else {
        setFlag("99999");
      }
    } else {
      setFlag(null);
    }
  }, [rutResponse]);

  //main return//
  return (
    <>
      <div className="card layout-main-container">
        <div className="flex flex-column align-items-center justify-content-center card-container">
          <div className="mt-8">
            <CentralContainer />
          </div>

          <div className="col-8 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <div className="accordionRutCandidates mt-8">
              <Accordion activeIndex={0}>
                <AccordionTab header="Consulta unitaria">
                  <div className="flex justify-content-center flex-column h-10rem">
                    <div className="col-5 sm:col-5 md:col-5 lg:col-5 xl:col-5 ml-8">
                      <p className="mb-2">Ingrese RUT a consultar:</p>
                    </div>
                    <div className="flex ml-8 field">
                      <InputText
                        value={rut}
                        placeholder="Ingresar Rut"
                        className="w-8"
                        onChange={(e) => onRutChange(e)}
                      />

                      {isSearching ? (
                        <Button
                          aria-label="Submit"
                          className="ml-3"
                          onClick={cleanValidatedRut}
                        >
                          <span className="material-symbols-outlined">
                            replay
                          </span>
                        </Button>
                      ) : rut ? (
                        <Button
                          aria-label="Submit"
                          className="ml-3"
                          onClick={validatedRut}
                          disabled={false}
                        >
                          <span className="material-symbols-outlined">
                            person_search
                          </span>
                        </Button>
                      ) : (
                        <Button
                          aria-label="Submit"
                          className="ml-3"
                          onClick={validatedRut}
                          disabled={true}
                        >
                          <span className="material-symbols-outlined">
                            person_search
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>

          <div className="my-8">
            <ResponseRut />
          </div>

          <div className="col-8 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <MassiveRequest />
          </div>

          <div className="my-8">
            <ResponseSaveMasive />
          </div>

          <ModalError
            isModalVisible={visibleModalError}
            onClose={cerrarModalError}
            token={token}
          ></ModalError>
        </div>
      </div>
    </>
  );
};

export default PreventionTableComponent;
