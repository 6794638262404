import { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import InputNumberCustomized from "../../../../ui/kit-mdtw/InputNumberCustomized";
import { v4 as uuidv4 } from "uuid";
import InputFileCustomized from "../../../../ui/kit-mdtw/InputFileCustomized";
import DropDownListMultipleCustomizedFilter from "../../../../ui/kit-mdtw/DropDownListMultipleCustomizedFilter";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import { formatDateToYYYYMMDD } from "../../../../../functions/kit-mdtw/dates";

interface IAreaPreocupacional {
  list?: any;
  rut?: string;
  setObjsendBateria: Function;
  setErrorFechaMenorExa:Function;
  setUndefinedData:Function;
}
interface BateriaDocument {
  bateriaDocumentoId: string;
  nombreBateria: string;
  documentSuite: string;
}

export default (props: IAreaPreocupacional) => {
  const { list, rut, setObjsendBateria, setErrorFechaMenorExa, setUndefinedData } = props;
  const {
    token,
    getToken,
    listDocument,
    getDocument,
    getBateriaDocumentos,
    listBateriaDocument,
  } = useContext(SeleccionContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(true);
  const [limit] = useState<number>(10);
  const [countTitle, setCountTitle] = useState<number>(0);
  const [componentMounted, setComponentMounted] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorFechaMenor, setErrorFechaMenor] = useState<boolean[]>([false]);
  const [selectDataBateria, setSelectDataBateria] = useState<any[]>([]);
  const [listaExamen, setListaExamen] = useState<any[]>([]);
  const [isUndefined, setIsUndefined] = useState<boolean>(false);
  
  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (token && rut && componentMounted) {
      getToken();
      getDocument({ personaDNI: rut, token });
    }
  }, [token, componentMounted]);

  useEffect(() => {
    if (token && componentMounted) {
      getBateriaDocumentos({ setIsLoading: setIsLoading, token });
    }
  }, [token, componentMounted]);

  useEffect(() => {
    if (setObjsendBateria) {
      setObjsendBateria({ ...selectDataBateria });
      setCountTitle(selectDataBateria.length);
    }
  }, [selectDataBateria]); 
  
  useEffect(() => {
    if(countTitle == 0){
      setErrorFechaMenor([false])
    }
    const isUndefined = selectDataBateria.some(item => ( 
      (Object.keys(item.documento).length == 0 || 
      item.documento.fechaExpiracion == undefined  ||
      item.bateria.code == undefined || item.examen.length == 0 ||
      (item.examen).some((e: { code: any; }) => e.code == undefined)) 
    ));  
    
    setIsUndefined(isUndefined);
  }, [countTitle,  selectDataBateria]);
  

  useEffect(() => {
      setErrorFechaMenorExa(errorFechaMenor)
      setUndefinedData(isUndefined)
  }, [errorFechaMenor, isUndefined]);

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  const handleChangeCountTitle = (values: any) => {
    if (values > selectDataBateria.length) {
      setSelectDataBateria((prevState) => [
        ...prevState,
        {
          id: uuidv4(),
          bateria: { code: undefined, name: undefined },
          examen: [{ code: undefined, name: undefined }],
          documento: {
            value: undefined,
            valid: false,
            fechaExpiracion: undefined,
          },
        },
      ]);
    } else {
      setSelectDataBateria((prevState) => {
        const updatedData = [...prevState];
        updatedData.splice(values, updatedData.length - values);
        return updatedData;
      });
    }
  };

  const changeDocumentoValue = (index: number, docValue: any) => {
    const updateSelectDataBateria: any[] = [...selectDataBateria];

    updateSelectDataBateria[index].documento = {
      value: docValue.documento.value,
      valid: docValue.documento.valid,
      fechaExpiracion: docValue.documento.fechaExpiracion,
    };

    setSelectDataBateria(updateSelectDataBateria);
    if (updateSelectDataBateria) {
      const updatedErrors = updateSelectDataBateria.map((item, i) => {
      
        const fechaActual = new Date().setHours(0, 0, 0, 0);
        
        const fechaIngresadaObj = new Date(
          formatDateToYYYYMMDD({
            date: updateSelectDataBateria[i].documento.fechaExpiracion,
            format: '-'
          })
        ).setHours(0, 0, 0, 0);
        return (fechaIngresadaObj < fechaActual);
      });
  
      setErrorFechaMenor(updatedErrors);
    }

  };

  const disabledDocValue = (index: any) => {
    return (selectDataBateria[index]?.documento?.value != undefined /*||
     selectDataBateria[index]?.bateria?.name != undefined*/);
    
  };

  const changelistaValue = (index: any, docValue: any) => {
    const updateSelectDataBateria: any[] = [...selectDataBateria];

    updateSelectDataBateria[index].bateria = {
      code: docValue?.bateria?.value?.code,
      name: docValue?.bateria?.value?.name,
    };

    setSelectDataBateria(updateSelectDataBateria);
  };

  const changelistaExamenValue = (index: any, docValue: any) => {
    setSelectDataBateria((prevSelectDataBateria) => {
      const updatedSelectDataBateria = [...prevSelectDataBateria];

      updatedSelectDataBateria[index] = {
        ...updatedSelectDataBateria[index],
        examen: docValue.examen.value, 
      };

      return updatedSelectDataBateria;
    });
  };

  const listaBateria1 = Object.keys(listBateriaDocument).map((key) => {
    const value = listBateriaDocument[key] as BateriaDocument;
    return { code: value.bateriaDocumentoId, name: key };
  });

  const generateListExamen = (index:any)=> {
  const selectedBateriaCode = selectDataBateria[index]?.bateria?.name;
  const selectedBateriaDocumentos = selectedBateriaCode
    ? listBateriaDocument[selectedBateriaCode]?.documentos
    : [];
  const listaExamen = selectedBateriaDocumentos
    ? selectedBateriaDocumentos.map((documento: any) => ({
        code: documento.documentoId,
        name: documento.nombreDocumento,
      }))
    : [];
    setListaExamen(listaExamen) 
}

  const deletePreocupacionalinBateria = (indexToDelete: number) => {
    setSelectDataBateria((prevSelectDataBateria) =>
      prevSelectDataBateria.filter((_, index) => index !== indexToDelete)
    );
    setCountTitle(selectDataBateria.length);
  };

  return (
    <>
      <div className={`area-table`} onClick={() => handleClickHidden()}>
        <span>ÁREA NUEVOS DOCUMENTOS PREOCUPACIONALES</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <div className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table">
          <span className="linea"></span>
          <section className={`project-table ${hidden ? "disabled" : ""}`}>
            <div className="container-input">
              <div className="content-input input-number pt-4">
                <InputNumberCustomized
                  layer="Batería de éxamenes:"
                  placeholder="0"
                  maxNumber={limit}
                  onChange={handleChangeCountTitle}
                  defaultValue={countTitle}
                />
              </div>
            </div>
          </section>
          {selectDataBateria &&
            selectDataBateria.map((id: any, index: any) => (
              <section
                className={`project-table ${hidden ? "disabled" : ""}`}
                key={id.id}
              >
                <div className="col-12 preocupacional p-0">
                  <div className="col-6 content1">
                    <DropDownListCustomized
                      layer={`Batería ${index + 1} `}
                      placeholder="Ingrese"
                      required={false}
                      errorPosition="down"
                      onValueChange={(value: any) => {
                        changelistaValue(index, value),generateListExamen(index)
                      }}
                      onPermaValues={selectDataBateria[index]}
                      onPropertyChange="bateria"
                      //defaultValue={selectDataFormacion[index]?.tipo?.value ?? ""}
                      //datoRequerido={requerido}
                      list={listaBateria1}
                    />
                  </div>
                  <div className="col-6 ">
                    <DropDownListMultipleCustomizedFilter
                      layer="Éxamen "
                      placeholder="Ingrese"
                      required={false}
                      errorPosition="down"
                      onValueChange={(value: any) => {
                        changelistaExamenValue(index, value)
                      }}
                      onPermaValues={selectDataBateria[index]}
                      onPropertyChange="examen"
                      //defaultValue={selectDataBateria[index]?.tipo?.value ?? ""}
                      //datoRequerido={requerido}
                      list={listaExamen}
                      disabled={selectDataBateria[index]?.bateria?.name != undefined ? false : true}
                    />
                  </div>
                </div>

                <div className={`col-12 p-0`}>
                  <div className="col-6 content1">
                    <InputFileCustomized
                      layer={""}
                      placeholder={"Seleccione archivo"}
                      required={true}
                      iconFile={true}
                      iconDel={true}
                      funcionIconDel={() =>
                        deletePreocupacionalinBateria(index)
                      }
                      disabledIconDel={!disabledDocValue(index)}
                      maxSizeMb={10}
                      errorPosition="down"
                      date={true}
                      index={id}
                      onPropertyChange="documento"
                      onPermaValues={selectDataBateria[index]}
                      onValueChange={(value: any) => {
                        changeDocumentoValue(index, value);
                      }}
                      leyenda={""}
                      requerido={false}
                      datoRequerido={false}
                    />
                    {errorFechaMenor[index] ? 
                    <p className="mesageDownRed pt-3" style={{paddingRight:"60px"}} >Fecha debe ser mayor a la actual</p> : ""}
                  </div>
                </div>
              </section>
            ))}
        </div>
      </div>
    </>
  );
};
