interface IIcoonReenviar {
  width?: number;
  height?: number;
  fill?: string;
  style?: any;
  className?: any;
  color?: any;
}

export default (props: IIcoonReenviar) => {
  const { width, height, fill, style, className, color } = props;

  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="23" r="22.5" className=""/>
      <path
        d="M20.9479 8.10976C20.5004 8.30824 20.2085 8.7558 20.2085 9.24617V11.7369H15.8496C12.0668 11.7369 9 14.8037 9 18.5866C9 22.996 12.1718 24.9653 12.8996 25.3623C12.9969 25.4167 13.1059 25.4362 13.2149 25.4362C13.6391 25.4362 13.9815 25.0898 13.9815 24.6695C13.9815 24.3776 13.8142 24.1091 13.6002 23.9106C13.2343 23.5642 12.7362 22.8832 12.7362 21.7C12.7362 19.6374 14.4097 17.9639 16.4723 17.9639H20.2085V20.4547C20.2085 20.945 20.4965 21.3926 20.9479 21.5911C21.3994 21.7896 21.9209 21.7078 22.2867 21.3809L28.5137 15.7767C28.7744 15.5393 28.9262 15.2046 28.9262 14.8504C28.9262 14.4963 28.7783 14.1616 28.5137 13.9242L22.2867 8.31991C21.9209 7.98911 21.3955 7.90738 20.9479 8.10976Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
      <path
        d="M17.2472 24.9234C16.9048 24.9234 16.6245 25.2036 16.6245 25.5461V26.4062L23.338 31.9171C24.1436 32.5787 25.2994 32.5787 26.1051 31.9171L32.8146 26.4062V25.5461C32.8146 25.2036 32.5344 24.9234 32.1919 24.9234H17.2472ZM16.6245 28.8231V35.5092C16.6245 35.8517 16.9048 36.1319 17.2472 36.1319H32.1919C32.5344 36.1319 32.8146 35.8517 32.8146 35.5092V28.8231L27.2882 33.3609C25.7937 34.5869 23.6415 34.5869 22.1509 33.3609L16.6245 28.8231ZM14.7565 25.5461C14.7565 24.1723 15.8734 23.0554 17.2472 23.0554H32.1919C33.5657 23.0554 34.6827 24.1723 34.6827 25.5461V35.5092C34.6827 36.883 33.5657 38 32.1919 38H17.2472C15.8734 38 14.7565 36.883 14.7565 35.5092V25.5461Z"
        fill={`${color ? color : '#AEAEAE'}`} 
      />
    </svg>
  );
};
