interface IIconDescargaSelect {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IIconDescargaSelect) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "21"}`}
        height={`${height ? height : "27"}`}
        viewBox="0 0 21 27"
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <path d="M3.375 0C1.51348 0 0 1.51348 0 3.375V23.625C0 25.4865 1.51348 27 3.375 27H16.875C18.7365 27 20.25 25.4865 20.25 23.625V8.4375H13.5C12.5666 8.4375 11.8125 7.6834 11.8125 6.75V0H3.375ZM13.5 0V6.75H20.25L13.5 0ZM11.3906 21.5156C11.3906 22.217 10.8264 22.7812 10.125 22.7812C9.42363 22.7812 8.85938 22.217 8.85938 21.5156V16.1314L7.22461 17.7662C6.72891 18.2619 5.92734 18.2619 5.43691 17.7662C4.94648 17.2705 4.94121 16.4689 5.43691 15.9785L9.23379 12.1816C9.72949 11.6859 10.5311 11.6859 11.0215 12.1816L14.8184 15.9785C15.3141 16.4742 15.3141 17.2758 14.8184 17.7662C14.3227 18.2566 13.5211 18.2619 13.0307 17.7662L11.3959 16.1314V21.5156H11.3906Z" 
       fill={fill}/>
      </svg>



    );
  };

