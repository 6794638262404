import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  formatDateToDDMMAAAA,
  formatDateToDDMMYY,
} from "../../../../../functions/kit-mdtw/dates";
import { useParams } from "react-router-dom";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ButtonIcon from "../../../../ui/kit-mdtw/buttons/ButtonIcon";
import ModalDescartarBloquear from "../Modals/ModalDescartarBloquear";

import MediaQuery from "../../../../ui-responsive/MediaQuery";
import { FILTER_LIST, TYPE_LIST } from "../../../../../diccionary/Diccionary";
import { IOptionDropDownList } from "../../../../../interfaces/IDropDownList";
import IconoRegistroI from "../../../../ui/kit-svg/IconoRegistroI";
import IconInfoCompleta from "../../../../ui/kit-svg/IconInfoCompleta";
import IconEye from "../../../../ui/kit-svg/IconEye";
import { Zoom } from "@mui/material";
import IconPen from "../../../../ui/kit-svg/IconPen";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import ModalFicha from "../../AsistenciaValidacionKam/Modals/ModalFicha";
import IconStopNew from "../../../../ui/icons-operaciones/IconStopNew";
import FooterTable from "../../../../ui/kit-mdtw/table/FooterTable";
import ModalDevolverNew from "../../../Operaciones/Modals/ModalDevolverNew";
import ModalGenerico from "../../../../ui/kit-modal/ModalGenerico";
import ModalNoProcesado from "../../../../ui/kit-modal/noProcesado/ModalNoProcesado";
import { setListaDocumentosSolicitados } from "../../../../../context/Operaciones/OperacionContratacionSet";

const TablaCandidatoPendienteContrato = () => {
  const {
    exclusiones,
    listaCandidatoPendienteContrato,
    listCandidatoPendienteContrato,
    setListCandidatoPendienteContrato,
    setVisibleActionBlocking,
    getListCandidatePendienteContrato,
    disableButton,
    BootstrapTooltip,
    aBusPerId,
    setABusPerId,
    restaurarCandidato,
    listNoProcess,
    openModalNoProcess,
    setOpenModalNoProcess,
    inSolicitudContrato,
    setInSolicitudContrato,
  } = useContext(ValidationKamContext);

  const { token, getToken, getRegistroCandidato, listaRegistroCandidato } =
    useContext(SeleccionContext);

  const {} = useContext(SeleccionContext);
  const { idCliente } = useParams<Params>();
  const { width, sm } = MediaQuery();
  const [dataBloqueo, setDataBloqueo] = useState<any[]>([]);
  const [dataDescarte, setDataDescarte] = useState<any[]>([]);
  const [openModalBloquear, setOpenModalBloquear] = useState<boolean>(false);
  const [openModalFicha, setOpenModalFicha] = useState<boolean>(false);
  const [filterChange, setFilterChange] = useState<any>(undefined);
  const [listDescartesBloqueo, setListDescarteBloqueo] = useState<any[]>([]);
  const [isOpenModalIndividual, setIsOpenModalIndividual] =
    useState<boolean>(false);
  const [gridCandidatoPendienteContrato, setGridCandidatoPendienteContrato] =
    useState<IListaCandidatos[]>([]);
  const [range, setRange] = useState<any>({
    initRange: 1,
    endRange: 10,
  });
  const [lengthFilters, setLengthFilters] = useState<number>(0);
  const [changePage, setChangePage] = useState<boolean>(false);
  const [rutCandidate, setRutCandidate] = useState<string>("");
  const [
    listCandidatoPenienteContratoFull,
    setListCandidatoPenienteContratoFull,
  ] = useState<any[]>([]);
  const [estadoRegistro, setEstadoRegistro] = useState<string>("");
  const [fechaValidacion, setFechaValidacion] = useState<string>("");
  const objetoSendFiltro = {};
  const [currentCandidates, setCurrentCandidates] = useState<string[]>([]);
  const [listaSeleccionados, setListaSeleccionados] = useState<any>([]);
  const [listaBusPeIdRestaurar, setListaBusPeIdRestaurar] = useState<any>([]);
  const [openModalRestaurar, setOpenModalRestaurar] = useState<boolean>(false);

  useEffect(() => {
    listCandidatoPendienteContrato.length > 0
      ? setVisibleActionBlocking(true)
      : setVisibleActionBlocking(false);
  }, [listCandidatoPendienteContrato]);

  useEffect(() => {
    if (exclusiones.length > 0) {
      setDataDescarte(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.busqueda].includes(list.exclusionLevel)
        )
      );
      setDataBloqueo(
        exclusiones.filter((list: any) =>
          [FILTER_LIST.cliente, FILTER_LIST.all].includes(list.exclusionLevel)
        )
      );
    }
  }, [exclusiones]);

  useEffect(() => {
    if (listaCandidatoPendienteContrato) {
      const arrayList = listaCandidatoPendienteContrato.map(
        (item: any) => item.personaDni
      );
      setCurrentCandidates(arrayList);
    }
  }, [listaCandidatoPendienteContrato]);

  useEffect(() => {
    if (token) {
      getListCandidatePendienteContrato({ idCliente, token, objetoSendFiltro });
    }
  }, [token]);

  // useEffect(() => {
  //   if (token && rutCandidate) {
  //     getToken();
  //     getRegistroCandidato({
  //       personaRut: rutCandidate,
  //       token,
  //     });
  //   }
  // }, [token]);

  useEffect(() => {
    let arrayGridFilter = [...listaCandidatoPendienteContrato];
    if (filterChange) {
      const { rut, areaNegocio, grupo, fechaValidacion } = filterChange;
      if (rut.value && rut.value.length > 0) {
        const rutArray = rut.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          rutArray.includes(item.personaDni)
        );
      }
      if (areaNegocio.value && areaNegocio.value.length > 0) {
        const areaNegocioArray = areaNegocio.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          areaNegocioArray.includes(item.areaNegocioId)
        );
      }
      if (grupo.value && grupo.value.length > 0) {
        const grupoArray = grupo.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          grupoArray.includes(item.busquedaId)
        );
      }
      if (fechaValidacion.value && fechaValidacion.value.length > 0) {
        const fechaValidacionArray = fechaValidacion.value.map(
          (values: IOptionDropDownList) => values.code
        );
        arrayGridFilter = arrayGridFilter.filter((item: IListaCandidatos) =>
          fechaValidacionArray.includes(
            formatDateToDDMMAAAA({ date: item.ingresadoFecha })
          )
        );
      }
    }

    setLengthFilters(arrayGridFilter.length);

    if (range) {
      const { initRange, endRange } = range;
      arrayGridFilter = arrayGridFilter.slice(initRange, endRange);
    }

    setGridCandidatoPendienteContrato(arrayGridFilter);

    setListCandidatoPenienteContratoFull(
      arrayGridFilter.map((item: IListaCandidatos) => {
        return {
          personaDni: item.personaDni,
          busPerId: item.busPerId,
          primerNombre: item.primerNombre,
          primerApellido: item.primerApellido,
          fechaValidacion: item.fechaValidacion,
          areaNegocioCod: item.areaNegocioCod,
          division: item.division,
          estadoRegistro: item.estadoRegistro,
          tipoContrato: listaRegistroCandidato?.contratacion?.tipoDocumento,
          fechaInicio: item.fechaInicio,
          historial: item.historial,
          causal: item.causalLetra,
        };
      })
    );
  }, [
    filterChange,
    listCandidatoPendienteContrato,
    listaCandidatoPendienteContrato,
    range,
  ]);

  const clearListDescarteBloqueo = () => {
    setListDescarteBloqueo([]);
  };

  const handleClickAddListMasive = (
    personaDni: string,
    busPerId: string,
    primerNombre: string,
    primerApellido: string,
    fechaValidacion: Date,
    areaNegocioCod: string,
    division: String,
    estadoRegistro: string,
    fechaInicio: string,
    historial: Date,
    causalLetra: string
  ) => {
    const currentService = [...listCandidatoPendienteContrato];
    const dataObject = {
      personaDni,
      busPerId,
      primerNombre,
      primerApellido,
      fechaValidacion,
      areaNegocioCod,
      tipoDocumentoValue:
        division === "TWEST" ? "CONTRATO PLAZO FIJO" : "CONTRATO INDEFINIDO",
      division,
      estadoRegistro,
      fechaInicio,
      historial,
      causal: causalLetra,
    };

    if (!currentService.some((c: any) => c.personaDni === personaDni)) {
      currentService.push(dataObject);
    } else {
      const positionDeleted = currentService.findIndex(
        (data) => data.personaDni === personaDni
      );
      currentService.splice(positionDeleted, 1);
    }

    setListCandidatoPendienteContrato(currentService);
  };

  const handleClickRestaurarGo = () => {
    restaurarCandidato({
      listaBusPeIdRestaurar,
      idCliente,
      token,
      objetoSendFiltro,
    });
    setOpenModalRestaurar(false);
  };

  const handleClickPersonIdMasive = (tipoDocumentoValue: string) => {
    listCandidatoPendienteContrato.length ==
    listCandidatoPenienteContratoFull.length
      ? setListCandidatoPendienteContrato([])
      : setListCandidatoPendienteContrato(
          listCandidatoPenienteContratoFull.map((item: IListaCandidatos) => {
            setRutCandidate(item.personaDni);
            return {
              personaDni: item.personaDni,
              busPerId: item.busPerId,
              primerNombre: item.primerNombre,
              primerApellido: item.primerApellido,
              fechaValidacion: item.fechaValidacion,
              areaNegocioCod: item.areaNegocioCod,
              division: item.division,
              tipoDocumentoValue:
                item.division === "TWEST"
                  ? "CONTRATO PLAZO FIJO"
                  : "CONTRATO INDEFINIDO",
              estadoRegistro: item.estadoRegistro,
              fechaInicio: item.fechaInicio,
              historial: item.historial,
              causal: item.causalLetra,
            };
          })
        );
  };

  const handleClickModalDatosIndividual = ({
    personaDni,
    busPerId,
    idCliente,
    estadoRegistro,
    fechaValidacion,
  }: any) => {
    let arr = [];
    arr.push(busPerId);
    setABusPerId(arr);
    setEstadoRegistro(estadoRegistro);
    setIsOpenModalIndividual(true);
    setRutCandidate(personaDni);
    setFechaValidacion(fechaValidacion);
  };

  const handleClickModalRestaurar = (
    primerNombre: string,
    primerApellido: string,
    busPerId: string,
    areaNegocioCod: string,
    personaDni: string,
    fechaValidacion: Date
  ) => {
    setListaBusPeIdRestaurar([busPerId]);
    const currentRest = {
      primerNombre: primerNombre.toLowerCase(),
      primerApellido: primerApellido.toLowerCase(),
      busPerId: busPerId,
      areaNegocioCod: areaNegocioCod.toUpperCase(),
      personaDni: personaDni,
      fechaValidacion: fechaValidacion,
    };
    setListaSeleccionados(currentRest);
    setOpenModalRestaurar(true);
  };

  return (
    <>
      <div className="content-header-table">
        <span>Candidatos pendientes solicitud de contrato</span>
      </div>
      <div className="container-table-list">
        <table className="content-table">
          <thead>
            <tr>
              <th>
                <ButtonIcon
                  onClick={() => {
                    handleClickPersonIdMasive(
                      listaRegistroCandidato?.contratacion?.tipoDocumento
                    );
                  }}
                >
                  {listCandidatoPendienteContrato.length ==
                    listCandidatoPenienteContratoFull.length &&
                  listCandidatoPendienteContrato.length > 0 ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ButtonIcon>
              </th>
              <th>Estado</th>

              <th>Nombre</th>
              <th>Rut</th>
              {width <= sm ? (
                <>
                  <th>Área de Negocio</th>
                  <th>Acciones</th>
                </>
              ) : (
                <>
                  <th>Teléfono</th>
                  <th>Área de Negocio</th>
                  <th>ID</th>
                  <th>Fecha Validación</th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gridCandidatoPendienteContrato &&
              gridCandidatoPendienteContrato.map((item) => {
                const {
                  areaNegocioCod,
                  busPerId,
                  busquedaId,
                  areaNegocioId,
                  personaDni,
                  primerNombre,
                  segundoNombre,
                  primerApellido,
                  sesgundoApellido,
                  busPerStatus,
                  ingresadoFecha,
                  fonoPrincipal,
                  division,
                  fechaValidacion,
                  estadoRegistro,
                  fechaInicio,
                  historial,
                  causalLetra,
                } = item;
                return (
                  <>
                    <tr
                      key={personaDni}
                      className={
                        listCandidatoPenienteContratoFull.length > 0 &&
                        listCandidatoPendienteContrato.some(
                          (l: any) => l.personaDni === personaDni
                        )
                          ? "selected borderdashed"
                          : "borderdashed"
                      }

                      /*className={` ${
                        listCandidatoPendienteContrato.includes(personaDni) && "selected"
                      }`}*/
                    >
                      <td>
                        <ButtonIcon
                          onClick={() => {
                            setRutCandidate(personaDni);
                            handleClickAddListMasive(
                              personaDni,
                              busPerId,
                              primerNombre,
                              primerApellido,
                              fechaValidacion,
                              areaNegocioCod,
                              division,
                              estadoRegistro,
                              fechaInicio,
                              historial[1].fecha,
                              causalLetra
                            );
                          }}
                        >
                          {listCandidatoPendienteContrato.some(
                            (l: any) => l.personaDni === personaDni
                          ) ? (
                            <>
                              <CheckBoxIcon />
                              {disableButton(true)}
                              {}
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              {disableButton(false)}
                            </>
                          )}
                        </ButtonIcon>
                      </td>
                      <td>
                        {" "}
                        <div className="content-icon-table">
                          {estadoRegistro === "COMPLETO" ? (
                            <>
                              <div className="image_1 ">
                                <IconInfoCompleta />
                              </div>
                              <span className="textStado">
                                Registro con datos <br /> completos
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                          {estadoRegistro.includes("DETENIDA") ? (
                            <>
                              <div className="image_1">
                                <IconStopNew />
                              </div>
                              <span className="textStado">
                                Registro Detenido
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                          {estadoRegistro.includes("VISTO_SIN_CAMBIOS") ? (
                            <>
                              <div className="image1">
                                <IconEye />
                              </div>
                              <span className="textStado">
                                Registro sin <br />
                                cambios
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                          {estadoRegistro.includes("INCOMPLETO") ? (
                            <>
                              <div className=" image1">
                                <IconPen />
                              </div>
                              <span className="textStado">
                                Registro con info <br /> incompleta
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        {primerNombre} {segundoNombre} {primerApellido}{" "}
                        {sesgundoApellido}
                      </td>
                      <td>{personaDni}</td>
                      {width <= sm ? (
                        <>
                          <td>{areaNegocioCod}</td>
                          <td>
                            <div className="content-icon-table">
                              {/*  <BootstrapTooltip
                                title="Restaurar Candidato"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() => {
                                    handleClickModalRestaurar(
                                      primerNombre,
                                      primerApellido,
                                      busPerId,
                                      areaNegocioCod,
                                      personaDni,
                                      fechaValidacion
                                    );
                                  }}
                                >
                                  <RefreshRoundedIcon
                                    className={`${
                                      buttonEnabled
                                        ? "active-restore"
                                        : "disabled"
                                    }`}
                                    style={{ transform: "rotateY(180deg)" }}
                                  />
                                </button>
                              </BootstrapTooltip>*/}

                              <BootstrapTooltip
                                title="Registro contrato Individual"
                                TransitionComponent={Zoom}
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDni,
                                      busPerId,
                                      idCliente,
                                      estadoRegistro,
                                      fechaValidacion,
                                    })
                                  }
                                >
                                  <IconoRegistroI
                                    className={"active-registro"}
                                  />
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{fonoPrincipal}</td>
                          <td>{areaNegocioCod}</td>
                          <td>{busquedaId}</td>
                          <td>
                            {formatDateToDDMMYY({ date: fechaValidacion })}
                          </td>
                          <td>
                            <div className="content-icon-table">
                              {/*  <BootstrapTooltip
                                title="Restaurar Candidato"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() => {
                                    handleClickModalRestaurar(
                                      primerNombre,
                                      primerApellido,
                                      busPerId,
                                      areaNegocioCod,
                                      personaDni,
                                      fechaValidacion
                                    );
                                  }}
                                >
                                  <RefreshRoundedIcon
                                    className={`${
                                      buttonEnabled
                                        ? "active-restore"
                                        : "disabled"
                                    }`}
                                    style={{ transform: "rotateY(180deg)" }}
                                  />
                                </button>
                              </BootstrapTooltip>*/}

                              <BootstrapTooltip
                                title="Registro contrato Individual"
                                placement="bottom-start"
                              >
                                <button
                                  onClick={() =>
                                    handleClickModalDatosIndividual({
                                      personaDni,
                                      busPerId,
                                      idCliente,
                                      estadoRegistro,
                                      fechaValidacion,
                                    })
                                  }
                                >
                                  <IconoRegistroI
                                    className={"active-registro"}
                                  />
                                </button>
                              </BootstrapTooltip>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <FooterTable
        length={lengthFilters}
        setRange={setRange}
        pageCurrent={changePage ? 1 : undefined}
      />

      {openModalBloquear && (
        <ModalDescartarBloquear
          modalStyle="delete"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="¿Está seguro que desea Bloquear el candidato?"
          subTitle="Al bloquear un candidato, éste no podrá participar en procesos de selección para este cliente."
          message="Seleccione el motivo del bloqueo:"
          onChangeBack={() => setOpenModalBloquear(false)}
          onChangeGo={() => setOpenModalBloquear(false)}
          buttonBack="Cancelar"
          buttonGo="Bloquear"
          exclusiones={dataBloqueo}
          list={listDescartesBloqueo}
          clearList={clearListDescarteBloqueo}
          action="block"
        />
      )}

      {openModalRestaurar && (
        <ModalDevolverNew
          modalStyle="confirm"
          modalType={TYPE_LIST.candidato_pendiente_aprobacion}
          title="DEVOLVER CANDIDATO"
          subTitle="Estás por devolver a reclutamiento a la siguiente selección, seleccione el motivo: "
          list={[listaSeleccionados]}
          onChangeBack={() => setOpenModalRestaurar(false)}
          onChangeGo={() => handleClickRestaurarGo()}
          buttonBack="Cancelar"
          buttonGo="Aceptar"
          token={token}
          //setToken={setToken}
          getToken={getToken}
          //busPerId={listaBusPeIdRestaurar}
        />
      )}

      {isOpenModalIndividual && (
        <ModalFicha
          areaContrato={true}
          rut={rutCandidate}
          busPerId={aBusPerId}
          idCliente={idCliente}
          title="REGISTRO CONTRATO INDIVIDUAL "
          onChangeBack={() => {
            setIsOpenModalIndividual(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
          buttonBack="Volver"
          setOpenModalFicha={setOpenModalFicha}
          estado={estadoRegistro}
          fechaValidacion={fechaValidacion}
        />
      )}

      {openModalNoProcess && (
        <ModalGenerico
          title={
            inSolicitudContrato
              ? "Solicitud NO procesada"
              : "Movimiento de Etapa NO procesado"
          }
          iconTitle="danger"
          subtitle={
            inSolicitudContrato
              ? "La solicitud para los siguientes trabajadores no pudo ser procesada:"
              : "El movimiento de etapa no pudo ser procesado para la siguiente selección:"
          }
          width="w-70"
          buttonBackText="Cerrar"
          buttonGoStyle="delete"
          onChangeBack={() => {
            setOpenModalNoProcess(false), setInSolicitudContrato(false);
            const body = document.querySelector("body");
            if (body) {
              body.style.overflowY = "auto";
            }
          }}
        >
          <ModalNoProcesado
            list={listNoProcess}
            retroceder={true}
            inSolicitudContrato={inSolicitudContrato}
          />
        </ModalGenerico>
      )}
    </>
  );
};

export default TablaCandidatoPendienteContrato;
