export const formatPhone = (phone: string) => {
  let phoneStandar: string = "";
  const includeSpace: number[] = [1, 2, 6];
  const numberValidation: string[] = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  if(!phone) {
    return ""
  }
  phone.split("").map((number, index) => {
    phoneStandar += `${number}${includeSpace.includes(index) ? " " : ""}`;
  });

  if (
    !numberValidation.includes(
      phoneStandar.slice(
        phoneStandar.split("").length - 1,
        phoneStandar.split("").length
      )
    )
  ) {
    phoneStandar = phoneStandar.slice(0, phoneStandar.split("").length - 1);
  }

  return phoneStandar;
};
