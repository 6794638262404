import { useContext, useEffect } from "react";
import TablaSolicitudPendientes from "./SolicitudesPendientes/TablaSolicitudPendientes";
import ContratosContext from "../../../../context/Contratos/ContratosContext";

const TablaContratoPendientes = () => {
  const { toasts, getToken } = useContext(ContratosContext);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {toasts}
      <TablaSolicitudPendientes />
    </>
  );
};

export default TablaContratoPendientes;
