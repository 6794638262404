import { Component } from "react";

interface IconPen {
    width?: number;
    height?: number;
    stroke?: string;
    fill?:string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconPen) => {
    const { width, height, stroke, style, className, color, fill } = props;
  
    return (
      <svg
        width={`${width ? width : "15"}`}
        height={`${height ? height : "18"}`}
        style={style}
        color={color}
        viewBox="0 0 15 18"
        className={className}
        fill={fill}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14 0.5H1C0.45 0.5 0 0.95 0 1.5V16.5C0 17.05 0.45 17.5 1 17.5H14C14.55 17.5 15 17.05 15 16.5V1.5C15 0.95 14.55 0.5 14 0.5ZM10.23 13.99L9.42 14.8L7.28 12.66L5.14 14.8L4.33 13.99L6.47 11.85L4.33 9.71L5.14 8.9L7.28 11.04L9.42 8.9L10.23 9.71L8.09 11.85L10.23 13.99ZM6.74 7.59L4 4.85L4.81 4.04L6.74 5.95L10.19 2.5L11 3.33L6.74 7.59Z" 
        fill="#FF4900"/>
      </svg>



    );
  };

