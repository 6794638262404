import React, { useEffect, useState } from "react";
import IPage from "../../../interfaces/page";
import logging from "../../../config/logging";
import MainTopbar from "../../layout/app/MainTopbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../../config/authConfig";
import { Loading } from "../../ui/microsoft-auth/msal-template/Loading";
import { ErrorComponent } from "../../ui/microsoft-auth/msal-template/ErrorComponent";
import RequestsProcessTableComponent from "../../ui/V2/RequestsProcessTableComponent";
import SeleccionState from "../../../context/Seleccion/SeleccionState";

const RequestsProcessV2: React.FC<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props]);
  const onToggleMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };
  const authRequest = {
    ...loginRequest,
  };

  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    const profileA: string = window.sessionStorage.getItem("profile") as string;
    setProfile(profileA);
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {profile ? (
          <MainTopbar
            profile={profile}
            onToggleMenuClick={onToggleMenuClick}
            active={"C"}
          />
        ) : (
          ""
        )}
        <SeleccionState>
          <RequestsProcessTableComponent />
        </SeleccionState>

        {/* <div className={wrapperClass} onClick={onWrapperClick}> */}
      </MsalAuthenticationTemplate>
    </>
  );
};

export default RequestsProcessV2;
