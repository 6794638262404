import { useEffect, useState } from "react";
import MediaQuery from "../../../../ui-responsive/MediaQuery";
import IconFilterFull from "../../../../ui/icons-seleccion/IconFilterFull";
import IconFilter from "../../../../ui/icons-seleccion/IconFilter";
import FiltroGenerico from "../../../../ui/kit-modal/FiltroGenerico";
import InputFiltro from "../../../../ui/kit-modal/Filtros-kit/InputFiltro";
import StateFiltro from "../../../../ui/kit-modal/Filtros-kit/StateFiltro";
interface IActionMasiva {
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
}

const AccionMasive = (props: IActionMasiva) => {
  const { localStorageFilter, setFormFilter, formFilter } = props;
  const { width, lg, md } = MediaQuery();
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  return (
    <>
      <div className="container-actions actions-old">
        <div className="content-actions-masive">
          <button
            className={`button-actions`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>
      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="RUT"
            typeFilter="rut"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="rut"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="procesoId"
          />
          <InputFiltro
            layer="FECHA ENVÍO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaEnvio"
          />
          <StateFiltro
            layer="ASISTE"
            listState={["SI", "NO"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="asiste"
          />
        </FiltroGenerico>
      )}
    </>
  );
};

export default AccionMasive;
