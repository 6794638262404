import CloseIcon from "@mui/icons-material/Close";
import DropDownListCustomized from "../../../../ui/kit-mdtw/DropDownListCustomized";
import { useContext, useEffect, useState } from "react";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IDatum,
  IExclusion,
  IListaCandidatos,
  IListaExclusion,
  Params,
} from "../../../../../interfaces/IValidationKam";
import { IFormFastIncomeNoRequired } from "../../../../../interfaces/IFastIncome";
import { useParams } from "react-router-dom";
import { TYPE_LIST } from "../../../../../diccionary/Diccionary";

interface IModalDescartar {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
  exclusiones?: any[];
  clearList?: Function;
  action?: "discart" | "block";
}

export default (props: IModalDescartar) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    exclusiones,
    list,
    clearList,
    action,
  } = props;
  const {
    token,
    getToken,
    listaCandidatoPendienteValidacion,
    listCandidatoPendienteValidacionCliente,
    createDiscartBlockCandidateValidateClient,
    userId,
  } = useContext(ValidationKamContext);
  const [formFastIncomeNoRequired, setFormFastIncomeNoRequired] =
    useState<IFormFastIncomeNoRequired>({
      id: { value: undefined, valid: undefined },
    });
  const { idCliente } = useParams<Params>();

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickGoDescarteBloqueo = () => {
    getToken();
    const { value, valid } = formFastIncomeNoRequired.id;
    const { code }: any = value;
    const dataExclusion = exclusiones
      ?.filter((item: IDatum) => item.excTipoId == code)
      .map((item: IDatum) => item.exclusionLevel);
    const { 0: exclusionLevel }: any = dataExclusion;
    let dataCandidatePendiente: any = [];

    if (list) {
      dataCandidatePendiente = listaCandidatoPendienteValidacion
        .filter((item: IListaCandidatos) => list?.includes(item.personaDni))
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });
    } else {
      dataCandidatePendiente = listaCandidatoPendienteValidacion
        .filter((item: IListaCandidatos) =>
          listCandidatoPendienteValidacionCliente.includes(item.personaDni)
        )
        .map((item: IListaCandidatos) => {
          return {
            busPerId: item.busPerId,
            excTipoId: code,
            usuarioId: userId,
            busquedaId: ["cliente", "busqueda"].includes(exclusionLevel)
              ? item.busquedaId
              : undefined,
          };
        });
    }

    if (modalType === TYPE_LIST.candidato_pendiente_aprobacion) {
      createDiscartBlockCandidateValidateClient({
        idCliente,
        dataCandidate: dataCandidatePendiente,
        action,
        token,
      }).then(typeof clearList == "function" && clearList);
    }
    typeof onChangeGo === "function" && onChangeGo();
  };

  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-60">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title start">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle start">
            <span>{subTitle}</span>
          </div>
          <div className="modal-message start">
            <span>{message}</span>
          </div>

          <div className="modal-message">
            <DropDownListCustomized
              list={
                exclusiones
                  ? exclusiones?.map((values: any) => {
                      const { excTipoId, excTipoNombre } = values;
                      return { code: excTipoId, name: excTipoNombre };
                    })
                  : []
              }
              onValueChange={setFormFastIncomeNoRequired}
              onPropertyChange="id"
              onPermaValues={formFastIncomeNoRequired}
              placeholder="Seleccionar"
              required={false}
            />
          </div>

          <div className="modal-button-content">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "disabled"
                  }`}
                  onClick={handleClickGoDescarteBloqueo}
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
