interface IconDescargaMas {
    width?: number;
    height?: number;
    fill?: string;
    style?: any;
    color?: any;  
    className?: any;
  }
  
  export default (props: IconDescargaMas) => {
    const { width, height, fill, style, className, color } = props;
  
    return (
      <svg
        width={`${width ? width : "31"}`}
        height={`${height ? height : "31"}`}
        viewBox="0 0 31 31"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
       
       <rect x="1" y="1" width="29" height="29" rx="14.5" fill="#FAFAFA"/>
<path d="M19.75 7C20.9221 7 21.875 7.95293 21.875 9.125L21.875 21.875C21.875 23.0471 20.9221 24 19.75 24L11.25 24C10.0779 24 9.125 23.0471 9.125 21.875L9.125 12.3125L13.375 12.3125C13.9627 12.3125 14.4375 11.8377 14.4375 11.25L14.4375 7L19.75 7ZM13.375 7L13.375 11.25L9.125 11.25L13.375 7ZM14.7031 14.7031L14.7031 18.0932L13.6738 17.0639C13.3617 16.7518 12.857 16.7518 12.5482 17.0639C12.2395 17.376 12.2361 17.8807 12.5482 18.1895L14.9389 20.5801C15.251 20.8922 15.7557 20.8922 16.0645 20.5801L18.4551 18.1895C18.7672 17.8773 18.7672 17.3727 18.4551 17.0639C18.143 16.7551 17.6383 16.7518 17.3295 17.0639L16.3002 18.0932L16.3002 14.7031C16.3002 14.2615 15.9449 13.9062 15.5033 13.9062C15.0617 13.9062 14.7064 14.2615 14.7064 14.7031L14.7031 14.7031Z" fill="#327A30"/>
<rect x="1" y="1" width="29" height="29" rx="14.5" stroke="#327A30" stroke-width="2"/>
      </svg>



    );
  };
  
