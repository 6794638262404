import { useEffect, useState } from "react";
import DropDownListCustomized from "../../../DropDownListCustomized";
import InputNumberCustomized from "../../../InputNumberCustomized";
import InputRutCustomized from "../../../InputRutCustomized";
import InputTextCustomized from "../../../InputTextCustomized";
import CloseIcon from "@mui/icons-material/Close";
import { IOptionDropDownList } from "../../../../../../interfaces/IDropDownList";
interface ICargas {
  list?: any[];
  setFormCandidate: Function;
  formCandidate: any;
  selectGeneral: any;
  defaultValue?: any;
  requerido?: boolean; 
}

interface IFieldForm {
  value: string | IOptionDropDownList | undefined;
  valid: boolean;
}
interface ICountCargas {
  DNI: IFieldForm;
  nombres: IFieldForm;
  primerApellido: IFieldForm;
  segundoApellido: IFieldForm;
  parentescoId: IFieldForm;
}

interface IClickDeleteCarga {
  index: number;
}

export default (props: ICargas) => {
  const { list, setFormCandidate, formCandidate, selectGeneral, defaultValue, requerido } =
    props;
  const [limit] = useState<number>(10);
  const [countChildren, setCountChildren] = useState<any>([]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setCountChildren(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (countChildren) {
      const arrayCandidate = { ...formCandidate };
      arrayCandidate.cargas = { value: countChildren, valid: true };
      setFormCandidate(arrayCandidate);
    }
  }, [countChildren]);

  const handleChangeCountCargas = (values: any) => {
    const value = Math.floor(values);
    const arrayCountChildren: ICountCargas[] = [...countChildren];

    if(value > arrayCountChildren.length){
      arrayCountChildren.push({
        DNI: { value: undefined, valid: true },
        nombres: { value: undefined, valid: true },
        primerApellido: { value: undefined, valid: true },
        segundoApellido: { value: undefined, valid: true },
        parentescoId: { value: undefined, valid: true }
      });
    }
    
    if(value < arrayCountChildren.length){
      arrayCountChildren.splice(value, arrayCountChildren.length);
    }
    
    setCountChildren(arrayCountChildren);
  };

  const handleClickDeleteCarga = (prompt: IClickDeleteCarga) => {
    const { index } = prompt;
    const arrayCountChildren: ICountCargas[] = [...countChildren];
    arrayCountChildren.splice(index, 1);
    setCountChildren(arrayCountChildren);
  };

  return (
    <>
      <div className="content-subtitle">
        <div>
          <p className="subtitle">CARGAS FAMILIARES</p>
        </div>
        <span className="linea"></span>
      </div>
      <div className="container-input">
        <div className="content-input input-number">
          <InputNumberCustomized
            layer="Nro de Cargas"
            placeholder="0"
            maxNumber={limit}
            onChange={handleChangeCountCargas}
            defaultValue={countChildren.length}
          />
        </div>
        {countChildren.map((item: any, index: number) => (
          <>
            <div className="content-input" key={index}>
              <div className="content-text-modal-update w-x5">
                <InputTextCustomized
                  layer="Nombres"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={setCountChildren}
                  onPermaValues={countChildren}
                  onPropertyChange={[index, `nombres`]}
                  defaultValue={item.nombres.value ?? ""}
                  requerido={item.nombres.value ? false : true}
                  datoRequerido={requerido}
                />
              </div>
              <div className="content-text-modal-update w-x5">
                <InputTextCustomized
                  layer="1° Apellido"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={setCountChildren}
                  onPermaValues={countChildren}
                  onPropertyChange={[index, `primerApellido`]}
                  defaultValue={item.primerApellido.value ?? ""}
                  requerido={item.primerApellido.value ? false : true}
                  datoRequerido={requerido}
                />
              </div>
              <div className="content-text-modal-update w-x5">
                <InputTextCustomized
                  layer="2° Apellido"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={setCountChildren}
                  onPermaValues={countChildren}
                  onPropertyChange={[index, `segundoApellido`]}
                  defaultValue={item.segundoApellido.value ?? ""}
                  requerido={item.segundoApellido.value ? false : true}
                  datoRequerido={requerido}
                />
              </div>
              <div className="content-text-modal-update w-x5">
                <InputRutCustomized
                  layer="Rut"
                  placeholder="Ingrese"
                  required={false}
                  errorPosition="down"
                  onValueChange={setCountChildren}
                  onPermaValues={countChildren}
                  onPropertyChange={[index, `DNI`]}
                  defaultValue={item["DNI"].value ?? ""}
                  requerido={item["DNI"].value ? false : true}
                  datoRequerido={requerido}
                />
              </div>
              <div className="content-select-modal-update w-x5">
                <DropDownListCustomized
                  list={selectGeneral && selectGeneral.parentesco}
                  layer="Parentesco"
                  required={false}
                  errorPosition="down"
                  onValueChange={setCountChildren}
                  onPermaValues={countChildren}
                  onPropertyChange={[index, `parentescoId`]}
                  defaultValue={item.parentescoId.value ?? undefined}
                  requerido={item.parentescoId.value ? false : true}
                  datoRequerido={requerido}
                />
              </div>
              <div className="content-text-modal-update end w-cinco">
                <button
                  className="button-delete"
                  onClick={() => handleClickDeleteCarga({index})}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};
