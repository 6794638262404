interface IIconQr {
  fill?: string;
  disabled?: boolean;
}

export default (props: IIconQr) => {
  const { fill, disabled } = props;

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 16"
      fill={disabled ? "#aeaeae" : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M6.66699 10.8333L7.39627 10.0318L10.3134 13.1818V6.33333H11.3552L11.3552 13.1818L14.2723 10.0318L15.0016 10.8333L10.8343 15.3333L6.66699 10.8333Z"
        />
        <path
          d="M1.66667 9.66667V8H3.33333V9.66667H1.66667ZM0 8V6.33333H1.66667V8H0ZM7.5 2.16667V0.5H9.16667V2.16667H7.5ZM1.04167 4.45833H3.95833V1.54167H1.04167V4.45833ZM0 5.5V0.5H5V5.5H0ZM1.04167 14.4583H3.95833V11.5417H1.04167V14.4583ZM0 15.5V10.5H5V15.5H0ZM11.0417 4.45833H13.9583V1.54167H11.0417V4.45833ZM10 5.5V0.5H15V5.5H10ZM5 9.66667V8H3.33333V6.33333H6.66667V9.66667H5ZM5.83333 5.5V2.16667H7.5V3.83333H9.16667V5.5H5.83333ZM1.875 3.625V2.375H3.125V3.625H1.875ZM1.875 13.625V12.375H3.125V13.625H1.875ZM11.875 3.625V2.375H13.125V3.625H11.875Z"
        />
      </g>
    </svg>
  );
};
