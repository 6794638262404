import { useContext, useEffect, useState } from "react";
import IconFilter from "../../../ui/kit-svg/IconFilter";
import ValidationKamContext from "../../../../context/ValidationKam/ValidationKamContext";
import ModalConfSolicitud from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalConfSolicitud";
import ModalDetener from "../../SistemaValidacionGeneral/Contratacion/Modals/ModalDetener";
import IconStopWhite from "../../../ui/kit-svg/IconStopWhite";
import IconStop from "../../../ui/kit-svg/IconStop";
import OperacionContratacionContext from "../../../../context/Operaciones/OperacionContratacionContext";
import IconFilterFull from "../../../ui/icons-seleccion/IconFilterFull";
import FiltroGenerico from "../../../ui/kit-modal/FiltroGenerico";
import StateFiltro from "../../../ui/kit-modal/Filtros-kit/StateFiltro";
import InputFiltro from "../../../ui/kit-modal/Filtros-kit/InputFiltro";
interface ISectionActive {
  localStorageFilter: string;
  setFormFilter: Function;
  formFilter: any;
  token: string;
}

const SeccionAccionMasiveDotacion = (props: ISectionActive) => {
  const { localStorageFilter, setFormFilter, formFilter, token } = props;
  const { listCandidatoPendienteContrato, BootstrapTooltip } =
    useContext(ValidationKamContext);
  const [hover, setHover] = useState(false);
  const [openModalContratacion, setOpenModalContratacion] =
    useState<boolean>(false);
  const [openModalFiltro, setOpenModalFiltro] = useState<boolean>(false);

  const { listDocumentosSolicitados } = useContext(
    OperacionContratacionContext
  );

  const [buttonEnabled, setButtonEnabled] = useState<boolean>(
    listDocumentosSolicitados.length > 0 ? true : false
  );
  const [dataObject, setDataObject] = useState<boolean | undefined>(undefined);
  const [openModalDetener, setOpenModalDetener] = useState<boolean>(false);

  useEffect(() => {
    setButtonEnabled(
      listDocumentosSolicitados.length > 0 &&
        listDocumentosSolicitados !== "" &&
        listDocumentosSolicitados.some(
          (l: any) => l.estadoRenovacion !== "PROCESANDO" /* &&
         l.estadoRenovacion !== "ACEPTADA" &&
          l.estadoRenovacion !== "FIRMA_PEND"*/
        )
        ? true
        : false
    );
  }, [listDocumentosSolicitados]);

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      const dataObject = Object.values(JSON.parse(copyStorage)).some(
        (value: any) => value.length > 0
      );
      setDataObject(dataObject);
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleClickModalDetener = () => {
    setOpenModalDetener(true);
  };

  return (
    <>
      <div className="content-actions-Dotacion contrato">
        <div className="content-actions-masiveDota">
          <BootstrapTooltip title="Detener Solicitud" placement="top-end">
            <button
              className={`button-actions ${
                buttonEnabled /*&& listDocumentosSolicitados.some(
                  (l: any) =>
                    l.estadoRenovacion !== "PROCESANDO" &&
                    l.estadoRenovacion !== "ACEPTADA" &&
                    l.estadoRenovacion !== "FIRMA_PEND"
                )*/
                  ? "active-block"
                  : "disabled"
              }`}
              disabled={
                buttonEnabled /*&& listDocumentosSolicitados.some(
                (l: any) =>
                  l.estadoRenovacion !== "PROCESANDO" &&
                  l.estadoRenovacion !== "ACEPTADA" &&
                  l.estadoRenovacion !== "FIRMA_PEND"
              )*/
                  ? false
                  : true
              }
              onClick={() => handleClickModalDetener()}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover ? (
                <IconStopWhite disabled={buttonEnabled ? false : true} />
              ) : (
                <IconStop disabled={buttonEnabled ? false : true} />
              )}
            </button>
          </BootstrapTooltip>

          <button
            className={`button-actions active-filter`}
            onClick={() => setOpenModalFiltro(true)}
          >
            {dataObject ? (
              <IconFilterFull fill="#3B6597" />
            ) : (
              <IconFilter fill="#3B6597" />
            )}
          </button>
        </div>
      </div>

      {openModalContratacion && (
        <ModalConfSolicitud
          modalStyle="confirm"
          modalType="contratacion"
          title="CONFIRMACIÓN SOLICITUD CONTRATO"
          subTitle="Estás por enviar al área de contrato la siguiente selección: "
          message={[listCandidatoPendienteContrato]}
          onChangeBack={() => setOpenModalContratacion(false)}
          onChangeGo={() => setOpenModalContratacion(false)}
          buttonBack="Cancelar"
          buttonGo="Continuar"
        />
      )}

      {openModalFiltro && (
        <FiltroGenerico
          title="Filtros"
          localStorageFilter={localStorageFilter}
          onBack={() => setOpenModalFiltro(false)}
          onValueChange={setFormFilter}
          onPropertyChange={formFilter}
        >
          <StateFiltro
            layer="ESTADO"
            listState={[
              "PROCESANDO",
              "RECHAZADO",
              "PEND_FIRMA_DIGITAL",
              "ACEPTADO_PEND_FIRMA",
              "FUERA_PLAZO",
              "ACEPTADA",
              "PEND_REVISION",
              "ACEPTADO_PEND_SOFTLAND",
            ]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="estado"
          />
          <InputFiltro
            layer="NOMBRE"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="nombre"
          />
          <InputFiltro
            layer="FICHA"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="ficha"
          />
          <InputFiltro
            layer="CÓDIGO CARGO"
            typeFilter="number"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="codCargo"
          />
          <InputFiltro
            layer="ÁREA DE NEGOCIO"
            typeFilter="text"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="areaNegocio"
          />
          <InputFiltro
            layer="FECHA INICIO CONTRATO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaInicio"
          />
          <InputFiltro
            layer="FECHA TERMINO CONTRATO"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="fechaTermino"
          />
          <InputFiltro
            layer="TOPE LEGAL"
            typeFilter="date"
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="topeLegal"
          />
          <StateFiltro
            layer="DIVISIÓN TW"
            listState={["TWEST", "TWC", "TWRRHH", "TWCAP"]}
            localStorageFilter={localStorageFilter}
            onValueChange={setFormFilter}
            onPropertyChange={formFilter}
            onPermaValues="division"
          />
        </FiltroGenerico>
      )}

      {openModalDetener && (
        <ModalDetener
          modalType="DetenerRenov"
          title="Detener solicitud de Renovación"
          subTitle="Vas a cancelar la solicitud de renovación de contrato para la siguiente selección:"
          buttonBack="Cancelar"
          onChangeBack={() => {
            setOpenModalDetener(false), setHover(false);
          }}
          modalStyle={""}
          list={listDocumentosSolicitados}
        />
      )}
    </>
  );
};

export default SeccionAccionMasiveDotacion;
