import { useContext, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDateToDDMMAAAA } from "../../../../../../functions/kit-mdtw/dates";
import SeleccionContext from "../../../../../../context/Seleccion/SeleccionContext";
import { format } from "path";
import { formatPhone } from "../../../../../../functions/kit-mdtw/phone";
interface IAreaAntecedentes {
  list?: any;
  formCandidate: any;
  contraerModal: boolean;
  busPerId?:string;
  token?:string
}

export default (props: IAreaAntecedentes) => {
  const { list, formCandidate, contraerModal, busPerId, token } = props;

  const {  getInformacionPsicolaboral,
    infoAntecedentes } =
  useContext(SeleccionContext);
 
  useEffect(() => {
    if(token && busPerId)(
    getInformacionPsicolaboral({token, busPerId}))
  }, []);
  
  const [hidden, setHidden] = useState<boolean>(false);

  const calcularEdad= (fecha: any) => {
    const birthYear = new Date(fecha).getFullYear();
    const birthMonth = new Date(fecha).getMonth();
    const birthDay = new Date(fecha).getDate();
    
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();
    
    let age = todayYear - birthYear;
  
    // Ajustamos la edad si aún no ha cumplido años en el mes y día actual
    if (todayMonth < birthMonth || (todayMonth === birthMonth && todayDay < birthDay)) {
      age--;
    }
  
    return age;
  }

  const handleClickHidden = () => {
    setHidden(hidden ? false : true);
  };

  return (
    <>
      <div className={`seccion-title`} onClick={() => handleClickHidden()}>
        <span className="span-title">ANTECEDENTES PROCESO/CANDIDATO</span>
        <KeyboardArrowDownIcon className={hidden ? "disabled" : ""} />
      </div>
      <div className={`project-table ${hidden ? "disabled" : ""}`}>
        <div className="container-table">
          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Nombre</span>
            <span className=" subtitle-table col-6">{infoAntecedentes?.procesoPersona?.primerNombre +" "+ 
            infoAntecedentes?.procesoPersona?.primerApellido ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
            Disponibilidad:
            </span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.disponibilidad /*"Inmediata"*/ ?? "-"}</span>
          </div>

            <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Rut:</span>
            <span className=" subtitle-table col-6">
              {infoAntecedentes?.procesoPersona?.personaDNI ?? "-"}
            </span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
            Cargo Solicitado:
            </span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.cargoNombre /*"INGENIERO DE PERMISOS"*/ ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
            Fecha de Nacimiento:
            </span>
            <span className="subtitle-table col-6">{formatDateToDDMMAAAA({ date:infoAntecedentes?.procesoPersona?.fechaNacimiento, format: "/"}) ?? "-"}</span>
          </div>


          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
            ID Procesos:
            </span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.busquedaId?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Fono:</span>
            <span className="subtitle-table col-6">{formatPhone(infoAntecedentes?.procesoPersona?.fonoPrincipal) ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
              Empresa Solicitante:
            </span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.clienteNombre ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
           Mail:
            </span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.email /*"leoR@gmail.com"*/ ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Renta:</span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.busquedaRenta ?? "-"}</span>
          </div>
         

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Comuna:</span>
            <span className="subtitle-table col-6">
              {infoAntecedentes?.procesoPersona?.comuna /*"Huechuraba"*/ ?? "-"}
            </span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Fecha Entrega:</span>
            <span className=" subtitle-table col-6">{
            formatDateToDDMMAAAA({ date:infoAntecedentes?.procesoPersona?.busquedaFin, format: "/"}) ?? "-"}</span>
          </div>

          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Estado Civil:</span>
            <span className="subtitle-table col-6">{infoAntecedentes?.procesoPersona?.estadoCivil ?? "-"}</span>
          </div>
          <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">
              Psicólogo Evaluador:
            </span>
            <span className="subtitle-table col-6">
              {infoAntecedentes?.procesoPersona?.reclutador ?? "-"}
            </span>
          </div>
          { /* <div
            className={`content-table-rowFlex ${
              contraerModal ? "col-12 p-0 m-0" : "col-6 p-0 m-0"
            }`}
          >
            <span className="title-table-rowResp col-6">Edad:</span>
            <span className=" subtitle-table col-6">{calcularEdad(infoAntecedentes?.procesoPersona?.fechaNacimiento) ?? "-"}</span>
          </div>*/}
        </div>
      </div>
    </>
  );
};
