import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import logging from "./config/logging";
import routes from "./config/routes";
import { Context } from "./context/context";
import userProfile from "./context/candidate/userProfile";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import "./assets/layout/layout.scss";
import { profile } from "console";
import { useProfileContext } from "./hooks/useRut/useProfileContext";
import SessionTimeout from "./services/sessionTimeout";
import SessionExpited from "./services/SessionExpired";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const [scale] = useState(14);

  useEffect(() => {
    logging.info("Loading application.");
  }, []);

  useEffect(() => {
    document.documentElement.style.fontSize = scale + "px";
  }, [scale]);

  return (
    <div>
      <MsalProvider instance={pca}>
        <BrowserRouter>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props: RouteComponentProps<any>) => (
                    <>
                      <route.component
                        name={route.name}
                        {...props}
                        {...route.props}
                      />
                      {!route.isLogin && (<> <SessionExpited /> </>)}
                      {/* <SessionTimeout /> */}
                    </>
                  )}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </MsalProvider>
    </div>
  );
}

export default App;
