import { IReducer } from "../../interfaces/ISeleccion";

const reduxState: any = {
  SetListUserAccess: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listUserAccess: payload };
  },

  SetCountAssign: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, countAssign: payload };
  },

  SetListaComprobarEstadoCandidato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaComprobarEstadoCandidato: payload };
  },

  SetListaExclusion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaExclusion: payload };
  },

  SetProcesoVerificarRut: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, procesoVerificarRut: payload };
  },

  SetPersonaVerificarRut: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, personaVerificarRut: payload };
  },

  SetListResultAsignaProceso: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listResultAsignaProceso: payload };
  },

  SetListaCandidatoDisponible: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoDisponible: payload };
  },

  SetListaCandidatoPendiente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPendiente: payload };
  },

  SetListaCandidatoValidado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoValidado: payload };
  },

  SetListaCandidatoPendienteMasivo: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPendienteMasivo: payload };
  },

  SetListaCandidatoValidadoMasivo: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoValidadoMasivo: payload };
  },

  SetListaCandidatoPreSeleccionPendiente: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPreSeleccionPendiente: payload };
  },

  SetListaCandidatoPreSeleccion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoPreSeleccion: payload };
  },

  SetListaCandidatoSeleccionado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoSeleccionado: payload };
  },

  SetListaCandidatoSolEnviada: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoSolEnviada: payload };
  },

  SetListaCandidatoSolRecibida: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoSolRecibida: payload };
  },

  SetListaCandidatoNoDisponible: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoNoDisponible: payload };
  },

  SetListaCandidatoInhabilitado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoInhabilitado: payload };
  },

  SetListaCandidatoBloqueado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaCandidatoBloqueado: payload };
  },

  SetListaGrupo: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaGrupo: payload };
  },

  SetListaUsuarios: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaUsuarios: payload };
  },

  SetListaGeneral: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaGeneral: payload };
  },

  SetListaRegistroCandidato: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaRegistroCandidato: payload };
  },

  SetListGroup: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listGroup: payload };
  },

  SetListDocument: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listDocument: payload };
  },

  SetListProcesosActivos: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listProcesosActivos: payload };
  },

  SetListProcesos: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listProcesos: payload };
  },

  SetListEvaluacionPsicolaboral: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listEvaluacionPsicolaboral: payload };
  },

  SetMovilizacionTw: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listMovilizacionTw: payload };
  },

  SetListaNoProcesadosSeleccion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaNoProcesadosSeleccion: payload };
  },

  SetListaPostulacionRecibida: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaPostulacionRecibida: payload };
  },

  SetListaPostulacionProcesada: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaPostulacionProcesada: payload };
  },

  SetListaProcesoPostulado: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaProcesoPostulado: payload };
  },

  SetListaRegistroPostulacion: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaRegistroPostulacion: payload };
  },
  SetInfoAntecedentes: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, infoAntecedentes: payload };
  },
  SetListaFormatoPsicolaboral: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaFormatoPsicolaboral: payload };
  },

  SetListaReportePdfPsicolaboral: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listaReportePdfPsicolaboral: payload };
  },
  SetListBateriaDocument: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listBateriaDocument: payload };
  },
  SetListResumenProcesadosNomina: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, listResumenProcesadosNomina: payload };
  },
  SetEmptyMessage: (props: IReducer) => {
    const { payload, state } = props;
    return { ...state, emptyMessage: payload };
  }
};

export default (state: any, action: any) => {
  const { type, payload } = action;
  return reduxState[type]({ payload, state }) ?? undefined;
};
