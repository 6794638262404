import { useContext, useEffect, useState } from "react";
import TablaCliente from "./TablaCliente";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ActionMassive from "./ActionMassive";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";
import IconLoading from "../../../../ui/kit-svg/IconLoading";

const ListadoCandidatoValidado = () => {
  const {
    toasts,
    listaCandidatoSeleccionado,
    getCandidatoSeleccionado,
    putAvanzarProceso,
    getMetodoMovilizacion,
    token,
  } = useContext(SeleccionContext);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorTable, setIsErrorTable] = useState<boolean | undefined>(
    undefined
  );
  const [listadoSeleccionado, setListadoSeleccionado] = useState<string[]>([]);
  const [currentProcesos, setCurrentProcesos] = useState<string[]>([]);
  const [localStorageSelect, setLocalStorageSelect] = useState<string>(
    "selectCandidatoValidadoMasivo"
  );
  const [localStorageFilter, setLocalStorageFilter] = useState<string>(
    "filtroCandidatoValidadoMasivo"
  );
  const [candidates, setCandidates] = useState<any>([]);
  const [formFilter, setFormFilter] = useState<any>({
    estado: [],
    nombre: [],
    rut: [],
    proceso: [],
    cliente: [],
    tipoProceso: ["MASIVO"],
    fechaValidacion: [],
    fechaPreseleccion: [],
    confirmaEntrevista: [],
    transporteTeamwork: [],
    entrevistaRealizada: [],
  });

  useEffect(() => {
    listRefresh();
  }, [token, localStorage.getItem(localStorageFilter)]);

  useEffect(() => {
    listRefreshLocalStorageSelect();
  }, []);

  useEffect(() => {
    if (listaCandidatoSeleccionado && listaCandidatoSeleccionado.length > 0) {
      const listProcesos = [...listaCandidatoSeleccionado];
      const arrayProcesos: string[] = listProcesos
        .map(({ grupos }: any) => grupos)
        .flat()
        .map(({ procesos }: any) => procesos)
        .flat()
        .map(({ personas }: any) => personas)
        .flat()
        .map(({ personaDNI }: any) => personaDNI);
      setCurrentProcesos(arrayProcesos);
    }
  }, [listaCandidatoSeleccionado]);

  const listRefresh = () => {
    if (token) {
      getMetodoMovilizacion({ token });
      if (localStorage.getItem(localStorageFilter)) {
        const copyStorage: any = localStorage.getItem(localStorageFilter);
        const copyStorageChange = JSON.parse(copyStorage);
        copyStorageChange.tipoProceso = ["MASIVO"];
        getCandidatoSeleccionado({
          token,
          filtros: copyStorageChange,
          setIsErrorTable,
          setIsLoadingTable,
        });
      } else {
        getCandidatoSeleccionado({
          token,
          filtros: formFilter,
          setIsErrorTable,
          setIsLoadingTable,
        });
      }
    }
  };

  const handleClickAddAll = (): void => {
    const arrayListado: string[] = [...listadoSeleccionado];
    if (listadoSeleccionado.length < currentProcesos.length) {
      currentProcesos
        .filter(
          (busquedaId: string) => !listadoSeleccionado.includes(busquedaId)
        )
        .map((busquedaId: string) => arrayListado.push(busquedaId));
    } else {
      arrayListado.splice(0, currentProcesos.length);
    }
    localStorage.setItem(localStorageSelect, String(arrayListado));
    setListadoSeleccionado(arrayListado);
  };

  const listRefreshLocalStorageSelect = () => {
    if (localStorage.getItem(localStorageSelect)) {
      const multi: string = String(localStorage.getItem(localStorageSelect));
      const listado: string[] = multi.split(",");
      setListadoSeleccionado(listado);
    }
  };

  const handleAvanzarProceso = async (values: any) => {
    const { setIsOpenModalNoProcesado, localStorageSelect } = values;
    if (token) {
      await putAvanzarProceso({
        token,
        procesos: candidates,
        setIsOpenModalNoProcesado,
        localStorageSelect,
      });
      listRefresh();
    }
  };

  return (
    <>
      {toasts}
      <section className="content-seleccion-multiple action-in-line margin-top-20">
        <ActionMassive
          localStorageFilter={localStorageFilter}
          localStorageSelect={localStorageSelect}
          listadoSeleccionado={listadoSeleccionado}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
          handleAvanzarProceso={handleAvanzarProceso}
          candidates={candidates}
          setCandidates={setCandidates}
          listRefresh={listRefresh}
          setListadoSeleccionado={setListadoSeleccionado}
        />
      </section>
      <section className="content-seleccion-multiple">
        <div className="content-multiple">
          <button onClick={handleClickAddAll} className="button-multiple">
            {listadoSeleccionado.length == currentProcesos.length &&
            listadoSeleccionado.length > 0 ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </button>
          <label className="title-multiple">
            Seleccionar Todo{" "}
            <i className="selects">
              Ítems Seleccionados: {listadoSeleccionado.length}
            </i>
          </label>
        </div>
      </section>

      {!isLoadingTable ? (
        <>
          {isErrorTable ? (
            <div className="container-is-error-table">
              Error al cargar los datos.
            </div>
          ) : (
            <>
              <TablaCliente
                localStorageSelect={localStorageSelect}
                localStorageFilter={localStorageFilter}
                listadoSeleccionado={listadoSeleccionado}
                setListadoSeleccionado={setListadoSeleccionado}
                formFilter={formFilter}
                listRefresh={listRefresh}
              />
            </>
          )}
        </>
      ) : (
        <div className="container-icon-loading">
          <IconLoading className="icon-loading" width="26" height="30" />
        </div>
      )}
    </>
  );
};

export default ListadoCandidatoValidado;
