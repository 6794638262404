import { ReactNode, useEffect, useState } from "react";
import "../../../../assets/css/filtro-generico.min.css";
import CloseIcon from "@mui/icons-material/Close";

interface IStateFilter {
  layer: string;
  listState: any;
  localStorageFilter: string;
  onValueChange?: Function;
  onPropertyChange?: string | any;
  onPermaValues?: any;
}

export default (props: IStateFilter) => {
  const {
    layer,
    listState,
    localStorageFilter,
    onValueChange,
    onPropertyChange,
    onPermaValues,
  } = props;

  const [currenFilterStorage, setCurrentFilterStorage] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem(localStorageFilter)) {
      const copyStorage: any = localStorage.getItem(localStorageFilter);
      setCurrentFilterStorage(JSON.parse(copyStorage));
    }
  }, [localStorage.getItem(localStorageFilter)]);

  const handleEventSetFormFilter = (item: any) => {
    if (
      currenFilterStorage &&
      currenFilterStorage[onPermaValues].includes(item)
    ) {
      return;
    }
    if (onPermaValues && onPropertyChange && onValueChange) {
      if (onPropertyChange[onPermaValues]?.includes(item)) {
        const current = { ...onPropertyChange };
        const position = current[onPermaValues].findIndex((state: any) => state == item);
        current[onPermaValues].splice(position, 1); 
        onValueChange(current)
        return; 
      }
      
      const currentData = { ...onPropertyChange };
      currentData[onPermaValues].push(item);
      onValueChange(currentData);
    }
  };

  return (
    <>
      <div className="section-filter">
        <span className="title">{layer}</span>
        <section className="section-button">
          {listState && onPropertyChange && onPermaValues && listState.map((item: any) => {
            return onPropertyChange[onPermaValues]?.includes(item) ? (
              <button
                onClick={() => handleEventSetFormFilter(item)}
                className={`button-filter active`}
              >
                {item}
                <CloseIcon />
              </button>
            ) : (
              <button
                onClick={() => handleEventSetFormFilter(item)}
                className={`button-filter`}
              >
                {item}
              </button>
            );
          })}
        </section>
      </div>
    </>
  );
};
