import { useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ValidationKamContext from "../../../../../context/ValidationKam/ValidationKamContext";
import {
  IListaCandidatos,
  Params,
} from "../../../../../interfaces/IValidationKam";
import {
  STATE_CANDIDATE,
  TYPE_LIST,
} from "../../../../../diccionary/Diccionary";
import { useParams } from "react-router-dom";
import SeleccionContext from "../../../../../context/Seleccion/SeleccionContext";

interface IModalConfirm {
  modalStyle: string;
  modalType?: string;
  title: string;
  subTitle?: string;
  message?: string;
  onChangeBack?: Function;
  onChangeGo?: Function;
  buttonBack?: string;
  buttonGo?: string;
  list?: any[];
  listadoSeleccionado: any;
  listRefresh: Function;
}

export default (props: IModalConfirm) => {
  const {
    modalStyle,
    modalType,
    title,
    subTitle,
    message,
    onChangeBack,
    onChangeGo,
    buttonBack,
    buttonGo,
    list,
    listadoSeleccionado,
    listRefresh,
  } = props;
  const {
    token,
    getToken,
    listaCandidatoPendiente,
    listaCandidatoConfirmado,
    updateCandidateState,
    getListCandidateConfirmed,
    getListCandidatePending,
  } = useContext(ValidationKamContext);
  const { putAvanzarProceso, putRetrocederProceso } =
    useContext(SeleccionContext);
  const { idCliente } = useParams<Params>();

  const handleClickCloseModal = () => {
    typeof onChangeBack == "function" && onChangeBack();
  };

  const handleClickValidateConfirm = () => {
    if (modalType) {
      getToken();
      if (token) {
        if (modalType === "validacion") {
          handleAvanzarProceso();
        }
        if (modalType === "validacionRetroceder") {
          handleRetrocederProceso();
        }
        if (modalType === "contratacion") {
          updateCandidateState({
            estado: STATE_CANDIDATE.PEND_SOL_CONTR,
            idCliente,
            token,
            typeList: TYPE_LIST.candidato_asistencia_confirmada,
          });
        }
      }
    }
    typeof onChangeGo == "function" && onChangeGo();
  };

  const handleClickRestart = async () => {
    if (modalType) {
      getToken();
      if (token) {
        try {
          if (modalType === "restaurar" && list) {
            await putRetrocederProceso({ token, procesos: [list] });
            typeof onChangeGo == "function" && onChangeGo();
          } else {
            const current = listaCandidatoPendiente
              .filter((item: any) =>
                listadoSeleccionado.includes(item.personaDni)
              )
              .map((item: any) => item.busPerId);
            await putRetrocederProceso({ token, procesos: current });
            typeof onChangeGo == "function" && onChangeGo();
          }
          await listRefresh();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleAvanzarProceso = async () => {
    if (token && listaCandidatoConfirmado) {
      const current = listaCandidatoConfirmado
        .filter((item: any) => listadoSeleccionado.includes(item.personaDni))
        .map((item: any) => item.asistio && item.busPerId);
      await putAvanzarProceso({ token, procesos: current });
      await listRefresh();
    }
  };

  const handleRetrocederProceso = async () => {
    if (token && listaCandidatoConfirmado) {
      const current = listaCandidatoConfirmado
        .filter((item: any) => listadoSeleccionado.includes(item.personaDni))
        .map((item: any) => item.busPerId);
      await putRetrocederProceso({ token, procesos: current });
      await listRefresh();
    }
  };

  return (
    <>
      <div className="modal-container-validatekam">
        <div className="modal-content-validatekam w-50">
          <span
            className="modal-close"
            onClick={() => typeof onChangeBack == "function" && onChangeBack()}
          >
            <CloseIcon />
          </span>
          <div className="modal-title">
            <span>{title}</span>
          </div>
          <div className="modal-subtitle">
            <span>{subTitle}</span>
          </div>
          {!list && modalType === "restaurar" && (
            <div className="modal-message-restaurar">
              {listaCandidatoPendiente
                ?.filter((item: IListaCandidatos) =>
                  listadoSeleccionado.includes(item.personaDni)
                )
                .map((item: IListaCandidatos) => {
                  const {
                    primerNombre,
                    segundoNombre,
                    primerApellido,
                    sesgundoApellido,
                  } = item;
                  return (
                    <span>
                      {primerNombre} {segundoNombre} {primerApellido}{" "}
                      {sesgundoApellido}
                    </span>
                  );
                })}
            </div>
          )}
          <div className="modal-message">
            <span>{message}</span>
          </div>
          <div className="modal-button-content separados">
            {modalStyle && (
              <>
                <button
                  className={`modal-button cancel`}
                  onClick={handleClickCloseModal}
                >
                  {buttonBack ? buttonBack : "Cancelar"}
                </button>
                <button
                  className={`modal-button ${
                    modalStyle ? modalStyle : "confirm"
                  }`}
                  onClick={() =>
                    modalType === "restaurar"
                      ? handleClickRestart()
                      : handleClickValidateConfirm()
                  }
                >
                  {buttonGo ? buttonGo : "Aceptar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
